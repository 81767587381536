<template>
<b-container fluid>
  <b-row>
    <b-col md="12">
      <iq-card>
        <template v-slot:headerTitle>
          <div class="row align-items-center py-4">
            <b-col class="col-auto">
              <span class="mx-2" style="font-size: 25px; color: black;">Follow Up Case ของคุณ {{username + ' ' + lastname}} ::: โทร. <a :href="'tel:' + formatTel(tel)"><span>{{ formatTel(tel) }}</span></a></span>
            </b-col>
            <b-col cols="" sm="2" md="2" lg="2" class="px-4">
              <div class="col-12 col-sm-auto my-1 my-sm-0 turnbackButton">
                <b-button type="button" variant="none" @click="back()" class="w-100 btn-min-size iq-bg-danger btn-cancel-border turnbackButton">ย้อนกลับ</b-button>
              </div>
            </b-col>
          </div>
        </template>
        <template v-slot:body>
          <div class="">
            <b-col cols="12" sm="12" md="8" lg="8" class="">
              <b-row class="searchres">
                <b-col lg="6" md="6" sm="12">
                  <div class="iq-email-search d-flex">
                    <div style="width: 100%;">
                      <div class="form-group mb-0">
                        <input v-model="search" class="form-control" id="exampleInputEmail1" placeholder="Search รหัส..." style="width: 100%;">
                        <a @click="getFollowCaseByPatientId" class="search-link"><i class="ri-search-line" /></a>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col cols="auto" class="my-1" style="display: flex; align-items: center;">
                  <span class="mx-2 font-size-14 text-primary">
                    {{ FollowCaseData?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > FollowCaseData?.length) ? FollowCaseData?.length : (perPage * currentPage) ) + ' of ' + AllDataLength : '0 of ' + AllDataLength }} รายการ
                  </span>
                </b-col>
              </b-row>
            </b-col>
          </div>
          <br>
          <b-col md="12" class="table-responsive">
            <b-table
              bordered hover
              :items="FollowCaseData"
              :fields="columns"
              :current-page="currentPage"
              :per-page="perPage"
              @pagination="onPagination"
              :tbody-tr-class="rowClass"
              thead-class="table-header-color"
            >
              <template v-slot:cell(hc_no)="data">
                <span>{{ data.item.hc_no || '-' }}</span>
              </template>
              <template v-slot:cell(follow_type)="data">
                <span>{{ data.item.follow_type || '-' }}</span>
              </template>
              <template v-slot:cell(fcc_detail)="data">
                <span>{{ data.item.fcc_detail || '-'}}</span>
              </template>
              <template v-slot:cell(day)="data">
                <span >{{ data.item.day || '-'}}</span>
              </template>
              <template v-slot:cell(status)="data">
                <b-button v-if="data.item.fcc_remark"
                  type="button"
                  variant="success"
                  class="mx-1"
                  style="white-space: nowrap;"
                  @click="openLastFollowModalRemark(data.item)"
                >
                  ติดตามแล้ว
                </b-button>
                <b-button v-if="!data.item.fcc_remark"
                  type="button"
                  variant="primary"
                  class="mx-1"
                  style="white-space: nowrap;"
                  @click="openFollowModalRemark(data.item)"
                >
                  ติดตาม
                </b-button>
                <b-button v-if="!data.item.fcc_remark"
                  type="button"
                  variant="danger"
                  class="mx-1"
                  style="text-align: center;"
                  @click="openCancelModalRemark(data.item)"
                >
                  ยกเลิก
                </b-button>
              </template>
            </b-table>
          </b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="FollowCaseData?.length"
            :per-page="perPage"
            align="center"
          />
        </template>
      </iq-card>
    </b-col>
  </b-row>

  <!-- Follow Modal -->
  <b-modal v-model="remarkFollowModal" :title="'ติดตามผล'" cancel-title="เพิ่ม" ok-title="ยกเลิก"
    cancel-variant="primary" ok-variant="danger" @close.prevent="remarkFollowModal = false" no-close-on-backdrop>
    <template>
      <b-row>
        <b-col>
          <b-form-group label="รายละเอียด" label-for="remark">
            <template v-slot:label>
              รายละเอียด <span class="text-danger">*</span>
            </template>
            <b-textarea id="remark" v-model="followData.remark"></b-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <template #modal-footer>
      <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
        <b-button
          variant="primary"
          class="btn-min-size btn-primary-border"
          size="sm"
          style="border-radius: 0.5rem;"
          @click="saveFollow()">ยืนยัน</b-button>
        <b-button
          variant="danger"
          class="btn-min-size btn-cancel-border"
          size="sm"
          style="border-radius: 0.5rem;"
          @click="remarkFollowModal = false">ยกเลิก</b-button>
      </div>
    </template>
  </b-modal>

  <!-- Cancel Follow -->
  <b-modal v-model="remarkCancelModal" :title="'ยกเลิกติดตาม'" cancel-title="เพิ่ม" ok-title="ยกเลิก"
    cancel-variant="primary" ok-variant="danger" @close.prevent="remarkCancelModal = false" no-close-on-backdrop>
    <template>
      <b-row>
        <b-col>
          <b-form-group label="รายละเอียด" label-for="remark">
            <template v-slot:label>
              รายละเอียด <span class="text-danger">*</span>
            </template>
            <b-textarea id="remark" v-model="cancelData.remark"></b-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <template #modal-footer>
      <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
        <b-button
          variant="primary"
          class="btn-min-size btn-primary-border"
          size="sm"
          style="border-radius: 0.5rem;"
          @click="saveCancel()">ยืนยัน</b-button>
        <b-button
          variant="danger"
          class="btn-min-size btn-cancel-border"
          size="sm"
          style="border-radius: 0.5rem;"
          @click="remarkCancelModal = false">ยกเลิก</b-button>
      </div>
    </template>
  </b-modal>

  <!-- Last Follow -->
  <b-modal v-model="remarkLastFollowModal" :title="'ติดตามผล'" cancel-title="เพิ่ม" ok-title="ยกเลิก"
    cancel-variant="primary" ok-variant="danger" @close.prevent="remarkLastFollowModal = false" no-close-on-backdrop>
    <template>
      <b-row>
        <b-col>
          <b-form-group label="รายละเอียด" label-for="remark">
            <b-textarea id="remark" v-model="LastfollowData.remark" readonly></b-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </template>
    <template #modal-footer>
      <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
        <b-button
          variant="danger"
          class="btn-min-size btn-primary-border"
          size="sm"
          style="border-radius: 0.5rem;"
          @click="remarkLastFollowModal = false">ยกเลิก</b-button>
      </div>
    </template>
  </b-modal>
</b-container>
</template>

<style>
@media (width: 700px) {
  .searchres {
    gap: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import moment from 'moment'
import Axios from 'axios'
import { configAPI } from '../../configBase'
import Vue from 'vue'

const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}

export default {
  name: 'FollowUpCaseCRM',
  async mounted () {
    this.mode = this.$route.params.setPage || null
    this.patientId = this.$route.params.id || null
    // await this.getCustomerCalStatus()
    await this.getCustomerDetail()
    await this.getFollowTypeDD()
    // await this.getAllFollowCaseByPatientId()
    await this.getFollowCaseByPatientId()
    xray.index()
  },
  data () {
    return {
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      usrId: JSON.parse(sessionStorage.getItem('user')).usr_id || null,
      mode: null,
      patientId: null,
      columns: [
        { label: 'รหัส', key: 'hc_no', class: 'text-center table-size-200-400' },
        { label: 'ลักษณะงาน', key: 'follow_type', class: 'text-center table-size-200-400' },
        { label: 'รายละเอียด', key: 'fcc_detail', class: 'text-center table-size-200-400' },
        { label: 'วันที่ต้องปฏิบัติงาน', key: 'day', class: 'text-center table-size-200-400' },
        { label: 'สถานะ', key: 'status', class: 'text-center table-size-200-400' }
      ],
      FollowCaseData: [],
      currentPage: 1,
      perPage: 10,
      AllDataLength: 0,
      search: '',
      followTypeOption: [],
      username: '',
      lastname: '',
      tel: '',
      remarkFollowModal: false,
      followData: {
        fcc_id: null,
        fcc_st_id: null,
        fcc_hc_id: null,
        remark: ''
      },
      remarkCancelModal: false,
      cancelData: {
        fcc_id: null,
        fcc_st_id: null,
        fcc_hc_id: null,
        remark: ''
      },
      remarkLastFollowModal: false,
      LastfollowData: {
        fcc_id: null,
        fcc_st_id: null,
        fcc_hc_id: null,
        remark: ''
      },
      options: {
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        sideBySide: true,
        minDate: null,
        defaultDate: null,
        icons: iconDate,
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'bottom'
        }
      }
    }
  },
  methods: {
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    onPagination (page) {
      this.currentPage = page
    },
    back () {
      router.push(`/FollowUp`)
    },
    getImagePath (relativePath) {
      if (relativePath) {
        let path = relativePath !== null ? relativePath.slice(1) : ''
        const baseURL = configAPI.MA_PORT + path
        return baseURL
      } else {
        return 'https://www.w3schools.com/howto/img_avatar.png'
      }
    },
    Formatbirthday (date) {
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      } else {
        return '-'
      }
    },
    formatTel (tel) {
      if (tel) {
        let temp = tel.split('')
        let temp2 = ''
        temp.map((item, index) => {
          if (index === 2 || index === 5) {
            temp2 += item + '-'
          } else {
            temp2 += item
          }
        })
        return temp2
      } else {
        return '-'
      }
    },
    onClickDatePicker () {
      const datePicker = document.getElementById('datepicker-custom')
      datePicker.focus()
    },
    async getCustomerDetail () {
      await Axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/register/${this.patientId}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          this.username = response.data.data[0]?.firstname
          this.lastname = response.data.data[0]?.lastname
          this.tel = response.data.data[0]?.phone
        }).catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async getFollowTypeDD () {
      let ServiceTypeDDAPI = {
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/followType`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await Axios.request(ServiceTypeDDAPI)
        .then((response) => {
          this.followTypeOption = response.data.data?.map((el) => {
            return {
              value: el.id,
              label: el.name
            }
          })
        })
    },
    async getFollowCaseByPatientId () {
      let dataSearch = this.search?.trim()
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/patient/${this.patientId}/followUpCase?search=${dataSearch}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        let temp = []
        for (let index = 0; index < response.data.data?.length; index++) {
          const data = response.data.data[index]
          const updatedDate = moment(data.fcc_actual_date)
          const today = moment().startOf('day')
          const dayDiff = today.diff(updatedDate.startOf('day'), 'days')
          // console.log('data?.fcc_ft_id :>> ', data?.fcc_ft_id);
          const followType = data.fcc_day_str || data.fcc_ft_id
            ? this.followTypeOption.find((el) => el.value === (data[`fcc_ft_${data.fcc_day_str}_id`] || data.fcc_ft_id))
            : {}
          // console.log('followType :>> ', followType);
          temp.push({
            id: data.fcc_id || null,
            hc_no: data.hc_opd_no || null,
            follow_type: followType?.label || null,
            fcc_detail: data[`fcc_${data.fcc_day_str}`] || data.fcc_follow_detail || null,
            fcc_st_id: data.fcc_st_id || null,
            fcc_hc_id: data.fcc_hc_id || null,
            fcc_remark: data.fcc_remark || null,
            day: dayDiff > 0
              ? updatedDate.format('DD/MM/YYYY') + ` (${dayDiff} days late)`
              : dayDiff === 0
                ? updatedDate.format('DD/MM/YYYY') + ' (today)'
                : updatedDate.format('DD/MM/YYYY'),
            actual_date: updatedDate.format('DD/MM/YYYY')
          })
        }
        this.FollowCaseData = temp
        this.AllDataLength = response.data.count
      }).catch((error) => {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่พบข้อมูล</span>',
          html: `${error.response.data.message}`,
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab'
        })
      })
    },
    async openFollowModalRemark (item) {
      this.followData.fcc_id = item.id
      this.followData.fcc_st_id = item.fcc_st_id
      this.followData.fcc_hc_id = item.fcc_hc_id
      this.followData.remark = ''
      this.remarkFollowModal = true
    },
    async openCancelModalRemark (item) {
      this.cancelData.fcc_id = item.id
      this.cancelData.fcc_st_id = item.fcc_st_id
      this.cancelData.fcc_hc_id = item.fcc_hc_id
      this.cancelData.remark = ''
      this.remarkCancelModal = true
    },
    async openLastFollowModalRemark (item) {
      this.LastfollowData.fcc_id = item.id
      this.LastfollowData.fcc_st_id = item.fcc_st_id
      this.LastfollowData.fcc_hc_id = item.fcc_hc_id
      this.LastfollowData.remark = item.fcc_remark
      this.remarkLastFollowModal = true
    },
    async saveFollow () {
      if (this.followData.remark?.trim() === '') {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">กรุณากรอกรายละเอียดการติดตามผล</span>',
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab'
        })
        return
      }
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจหรือไม่ว่าจะอัพเดต follow case นี้</span>',
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let dataForSave = {
            fcc_id: this.followData.fcc_id,
            remark: this.followData.remark?.trim(),
            fcc_st_id: this.followData.fcc_st_id,
            fcc_hc_id: this.followData.fcc_hc_id,
            usrId: this.usrId
          }
          await Axios.request({
            method: 'POST',
            url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/followUpCase/saveFollowUpCase`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: dataForSave
          }).then(() => {
            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">อัพเดต follow case สำเร็จ</span>',
              icon: 'success',
              showConfirmButton: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 1500
            }).then(async (response) => {
              this.remarkFollowModal = false
              await this.getFollowCaseByPatientId()
            })
          }).catch((error) => {
            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่พบข้อมูล</span>',
              html: `${error.response.data.message}`,
              icon: 'warning',
              showConfirmButton: true,
              confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
              confirmButtonColor: '#089bab'
            })
          })
        }
      })
    },
    async saveCancel () {
      if (this.cancelData.remark?.trim() === '') {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">กรุณากรอกรายละเอียดการยกเลิกติดตามผล</span>',
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab'
        })
        return
      }
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจหรือไม่ว่าจะอัพเดต follow case นี้</span>',
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let dataForSave = {
            fcc_id: this.cancelData.fcc_id,
            remark: this.cancelData.remark?.trim(),
            fcc_st_id: this.cancelData.fcc_st_id,
            fcc_hc_id: this.cancelData.fcc_hc_id,
            usrId: this.usrId
          }
          await Axios.request({
            method: 'POST',
            url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/followUpCase/cancelFollowUpCase`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: dataForSave
          }).then(() => {
            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ยกเลิก follow case สำเร็จ</span>',
              icon: 'success',
              showConfirmButton: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
              timer: 1500
            }).then(async (response) => {
              this.remarkCancelModal = false
              await this.getFollowCaseByPatientId()
            })
          }).catch((error) => {
            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่พบข้อมูล</span>',
              html: `${error.response.data.message}`,
              icon: 'warning',
              showConfirmButton: true,
              confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
              confirmButtonColor: '#089bab'
            })
          })
        }
      })
    }
  }
}
</script>
