import { render, staticRenderFns } from "./PostJob.vue?vue&type=template&id=a263a236&scoped=true&"
import script from "./PostJob.vue?vue&type=script&lang=js&"
export * from "./PostJob.vue?vue&type=script&lang=js&"
import style0 from "./PostJob.vue?vue&type=style&index=0&id=a263a236&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a263a236",
  null
  
)

export default component.exports