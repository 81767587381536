<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">วัตถุดิบ</span>
              </div>
              <div class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button @click="handleClick('add', null)" type="submit" variant="primary-custom" class="w-100 btn-min-size"><i
                    class="circle-plus_ct" />เพิ่ม</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" md="7" lg="5" class="mb-3">
                  <b-row>
                    <b-col sm="12" md class="mb-3">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input class="form-control" id="exampleInputEmail1" placeholder="Search" style="width: 100%;"
                              v-model="searchTerm" @keydown="enterSearch">
                            <a class="search-link" v-on:click="getMaterial"><i class="ri-search-line"/></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                      <span class="mx-2 font-size-14 text-primary">
                        {{ material?.length > 0 ? (((currentPage - 1) * 10) + (material?.length > 0 ? 1 : 0)) + ' - ' + (((perPage * currentPage) > material?.length) ? material?.length : (perPage * currentPage) ) + ' of ' + materialLength : '0 of ' + materialLength }} รายการ
                      </span></b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <b-col md="12" class="table-responsive">
              <b-table bordered hover :items="material" :fields="columns" :current-page="currentPage" :per-page="perPage"
                @pagination="onPagination" :tbody-tr-class="rowClass" thead-class="table-header-color">
                <template v-slot:cell(no)="data">
                  <span>{{ ((currentPage - 1) * perPage) + data.index + 1 }}</span>
                </template>
                <template v-slot:cell(variable1)="data">
                  <span v-if="!data.item.editable">{{ material[(((currentPage - 1) * 10) + data.index)].mg_name }}</span>
                  <input type="text" v-model="data.item.variable1" v-else class="form-control">
                </template>
                <template v-slot:cell(variable2)="data">
                  <span v-if="!data.item.editable">{{ material[(((currentPage - 1) * 10) + data.index)].mb_name }}</span>
                  <input type="text" v-model="data.item.variable2" v-else class="form-control">
                </template>
                <template v-slot:cell(variable3)="data">
                  <span v-if="!data.item.editable">{{ material[(((currentPage - 1) * 10) + data.index)].mt_name }}</span>
                  <input type="text" v-model="data.item.variable3" v-else class="form-control">
                </template>
                <template v-slot:cell(variable4)="data">
                  <span v-if="!data.item.editable">{{ material[(((currentPage - 1) * 10) +
                    data.index)].unit_name }}</span>
                  <input type="text" v-model="data.item.variable4" v-else class="form-control">
                </template>
                <template v-slot:cell(variable5)="data">
                  <span v-if="!data.item.editable">{{ material[(((currentPage - 1) * 10) + data.index)].fda_type }}</span>
                  <input type="text" v-model="data.item.variable5" v-else class="form-control">
                </template>
                <template v-slot:cell(variable6)="data">
                  <span v-if="!data.item.editable">{{ material[(((currentPage - 1) * 10) + data.index)].license }}</span>
                  <input type="text" v-model="data.item.variable6" v-else class="form-control">
                </template>
                <template v-slot:cell(variable7)="data">
                  <b-img :src="getFullImagePath(material[((currentPage - 1) * 10) + data.index].barcode_path)"
                    style="height: 50px; width: 200px; object-fit: contain;"></b-img>
                </template>
                <template v-slot:cell(variable8)="data">
                  <div style="display: flex; justify-content: center;">
                    <b-button class="d-flex" variant=" iq-bg-primary" size="sm"
                      @click="handleClick('view', material[((currentPage - 1) * 10) + data.index].id)"><i
                        class="ri-eye-fill m-0"></i></b-button>
                    <b-button class="d-flex" variant=" iq-bg-success mx-1" size="sm"
                      @click="handleClick('edit', material[((currentPage - 1) * 10) + data.index].id)"><i
                        class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" size="sm"
                      @click="deleteMaterial(material[((currentPage - 1) * 10) + data.index].id)"><i
                        class="ri-delete-bin-line m-0"></i></b-button>
                  </div>
                </template>
              </b-table>
              <br>
              <b-pagination v-model="currentPage" :total-rows="material.length" :per-page="perPage" align="center" />
            </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import moment from 'moment'
import { xray } from '../../config/pluginInit'
import axios from 'axios'
import Vue from 'vue'
import { configAPI } from '../../configBase'

export default {
  name: 'UiDataTable',
  mounted () {
    xray.index()
    this.getMaterial()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getMaterial()
      }
    },
    async getMaterial () {
      let search = this.searchTerm.trim().length > 0 ? this.searchTerm.trim() : ''
      let materialAPI = {
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/material?search=` + search,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      axios.request(materialAPI)
        .then((response) => {
          this.material = response.data.data
          this.materialLength = response.data.count
        })/* .catch((error) => {
          console.error(error)
        }) */
    },
    add () {
      let obj = this.default()
      this.rows.push(obj)
    },
    default () {
      return {
        id: this.material?.length,
        variable1: '',
        variable2: '',
        variable3: '',
        variable4: '',
        variable5: '',
        variable6: '',
        variable7: '',
        variable8: '',
        editable: false
      }
    },
    edit (item) {
      item.editable = true
    },
    submit (item) {
      item.editable = false
    },
    deleteMaterial (id) {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะลบข้อมูลใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let delMaterialAPI = {
            method: 'post',
            url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/material/${id}/delete`,
            headers: {
              'Content-Type': 'application/json'
            }
          }
          axios.request(delMaterialAPI)
            .then((response) => {
              Vue.swal.fire({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
                icon: 'success',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showCancelButton: false,
                showConfirmButton: false,
                timer: 2000
              }).then((result) => {
                this.getMaterial()
              })
            })
            // .catch((error) => {
            //   console.error('Error deleting product:', error)
            // })
        }
      })
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick (e, data) {
      const setPage = e
      const targetRoute = (setPage === 'add' ? `/AddMaterial/${setPage}` : `/AddMaterial/${setPage}/${data}`)
      this.$router.push(targetRoute)
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    getFullImagePath (relativePath) {
      let baseURL = ''
      if (relativePath !== null && relativePath !== undefined && relativePath !== '') {
        let path = relativePath !== null ? relativePath.slice(1) : ''
        baseURL = `${configAPI.MA_PORT}` + path
      } else {
        baseURL = require('../../assets/images/default.jpg')
      }
      return baseURL
    },
    getDate (date) {
      if (date !== null) {
        return moment(date).format('DD/MM/YYYY')
      } else {
        return ''
      }
    },
    NumberWithComma (Number) {
      if (Number !== null) {
        return Number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {
        return ''
      }
    }
  },
  data () {
    return {
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      material: [],
      materialLength: 0,
      searchTerm: '',
      columns: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        { label: 'หมวดหมู่', key: 'variable1', class: 'text-center table-size-200-400' },
        { label: 'แบรนด์', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'ประเภท', key: 'variable3', class: 'text-center table-size-200-400' },
        { label: 'หน่วยนับ', key: 'variable4', class: 'text-center table-size-200-400' },
        { label: 'ประเภท อย.', key: 'variable5', class: 'text-center table-size-200-400' },
        { label: 'เลขทะเบียน', key: 'variable6', class: 'text-center table-size-200-400' },
        { label: 'บาร์โค้ด', key: 'variable7', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'variable8', class: 'text-center table-size-200-400' }
      ],
      currentPage: 1,
      perPage: 10
    }
  }
}
</script>
