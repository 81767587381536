<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">ประวัติการปฏิบัติงาน</span>
              </div>
              <div class="col-12 col-sm-12 col-md-auto my-1 my-sm-0">
                <div class="d-flex response-flex-btn-perform" style="gap: 0.5rem;">
                  <b-button @click="modalAdd = true" type="submit" variant="primary" class="w-100 my-1"><i class="in-data_ct"></i>อัพโหลดเอกสาร</b-button>
                  <a :href="getFullImagePath(downloadFile.file_path)" :download="downloadFile.file_name" class="response-button-download">
                    <b-button type="submit" variant="primary" class="my-1 flex-fill text-center response-button-download" style="text-align: center; flex-basis: 100%; white-space: nowrap;"><i class="out-data_ct"></i>Download Template</b-button>
                  </a>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" md="7" lg="5" class="mb-3">
                  <b-row>
                    <b-col sm="12" md class="mb-3" style="padding: 0px;">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input class="form-control" id="exampleInputEmail1" @keydown="enterSearch" v-model="searchInput" placeholder="Search" style="width: 100%;">
                            <a class="search-link" @click="filterTimeAttendance"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center; padding: 0px;">
                      <span class="mx-2 font-size-14 text-primary">
                        {{ rows?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > rows?.length) ? rows?.length : (perPage * currentPage) ) + ' of ' + AllrowsLenght : "0 of " + AllrowsLenght}} รายการ
                      </span></b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="table-responsive" style="padding: 0px;">
                  <b-table bordered hover :items="rows" :fields="columns" :current-page="currentPage" :per-page="perPage"
                    @pagination="onPagination" :tbody-tr-class="rowClass" thead-class="table-header-color">
                    <template v-slot:cell(variable1)="data">
                      <span>{{ data.item.variable1 }}</span>
                    </template>
                    <template v-slot:cell(variable2)="data">
                      <span>{{ data.item.variable2 }}</span>
                    </template>
                    <template v-slot:cell(variable3)="data">
                      <span>{{ data.item.variable3 }}</span>
                    </template>
                    <template v-slot:cell(variable4)="data">
                      <span>{{ data.item.variable4 }}</span>
                    </template>
                    <template v-slot:cell(variable5)="data">
                      <span>{{ data.item.variable5 }}</span>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row style="display: flex; justify-content: center;">
                <b-pagination v-model="currentPage" :total-rows="rows.length" :per-page="perPage" />
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal v-model="modalAdd" no-close-on-backdrop centered @close="clickCancel">
      <template #modal-title>
        <span style="font-size: 18px; color: black;">อัพโหลดเอกสาร</span>
      </template>

      <b-container fluid>
        <b-form-group label-for="imageInput" label="อัปโหลดไฟล์สแกนลายนิ้วมือ">
          <b-form-file
            v-model="uploadPic"
            @change="handleImageUpload"
            id="imageInput"
            :accept="allowedTypes"
          ></b-form-file>
        </b-form-group>
      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <b-button variant="none" size="sm" class="float-right ml-2 iq-bg-danger btn-cancel-border btn-min-size" style="border-radius: 0.5rem;" @click="clickCancel">
            ยกเลิก
          </b-button>
          <b-button variant="primary" size="sm" class="float-right mr-2 btn-min-size" style="border-radius: 0.5rem;"
          @click="clickSave($event, inputData)"
          :disabled="!uploadPic ? true : false"
          >
          <i class="save-data_ct"></i>
            บันทึก
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal v-model="modalStatus" title="สถานะการ Upload" size="xl" @close.prevent="closeModalShowStatus" no-close-on-backdrop>
        <b-col cols="12" class="table-responsive" >
          <b-table bordered hover :items="rows2" :fields="columns2"
            :current-page="currentPage2"
            :per-page="perPage2"
            @pagination="onPagination2"
            :tbody-tr-class="rowClass" thead-class="table-header-color"
          >
          <template v-slot:cell(variable1)="data">
            <span v-if="!data.item.editable">{{ data.item.variable1 }}</span>
            <input type="text" v-model="data.item.variable1" v-else class="form-control">
          </template>
          <template v-slot:cell(variable2)="data">
            <span v-if="!data.item.editable">{{ data.item.variable2 }}</span>
            <input type="text" v-model="data.item.variable2" v-else class="form-control">
          </template>
          <template v-slot:cell(variable3)="data">
            <span v-if="!data.item.editable">{{ data.item.variable3 }}</span>
            <input type="text" v-model="data.item.variable4" v-else class="form-control">
          </template>
          <template v-slot:cell(variable4)="data">
            <b-button
              variant="none"
              :class="'btn-min-size ' + (data.item.status ? 'iq-bg-success btn-success-border' : 'iq-bg-danger btn-cancel-border')"
              size="sm"
              style="border-radius: 0.5rem;"
            >
              {{ data.item.variable4 }}
            </b-button>
            <!-- <span v-if="!data.item.editable">{{ data.item.variable4 }}</span>
            <input type="text" v-model="data.item.variable4" v-else class="form-control"> -->
          </template>
          <template v-slot:cell(variable5)="data">
            <span v-for="(item1, index) in data.item.variable5" :key="index" style="white-space: nowrap">
              <br v-if="index !== 0">
              {{ '-' + item1 }}
            </span>
          </template>
        </b-table>
        <br>
        <b-pagination
          v-model="currentPage2"
          :total-rows="rows2.length"
          :per-page="perPage"
          align="center"
        />
      </b-col>
      <template #modal-footer>
    <div class="w-100">
      <p class="float-left"></p>
      <b-button
        variant="none"
        size="sm"
        class="float-right iq-bg-danger btn-cancel-border btn-min-size"
        style="border-radius: 0.5rem;"
        @click="closeModalShowStatus"
      >
          ยกเลิก
      </b-button>
    </div>
      </template>
    </b-modal>
  </b-container>
</template>

<style>
@media (max-width: 601px) {
  .response-flex-btn-perform {
    flex-direction: column;
  }

}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import axios from 'axios'
import { configAPI } from '../../configBase'
import moment from 'moment'

export default {
  name: 'ServiceGroup',
  async mounted () {
    this.cln_id = JSON.parse(sessionStorage.getItem('user')).cln_id
    this.downloadFileTemplate()
    await this.filterTimeAttendance()
    await this.TimeAttendanceAll()
    xray.index()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.filterTimeAttendance()
      }
    },
    async filterTimeAttendance () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/timeAttendance?search=${this.searchInput?.trim() || ''}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status && response.data.data.length > 0) {
            const resList = response.data.data.sort((a, b) => {
              return (new Date(b.date) - new Date(a.date))
            })
            const arrTemp = []
            for (let index = 0; index < resList.length; index++) {
              arrTemp.push({
                id: resList[index].id,
                index: (index + 1),
                variable1: resList[index].emp_no,
                variable2: (resList[index].emp_name || '') + ' ' + (resList[index].emp_lastname || ''),
                variable3: resList[index].date ? moment(resList[index].date).format('DD/MM/YYYY') : '-',
                variable4: resList[index].start_date ? moment(resList[index].start_date, 'HH:mm:ss').format('HH:mm') : '-',
                variable5: resList[index].end_date ? moment(resList[index].end_date, 'HH:mm:ss').format('HH:mm') : '-'
              })
            }
            this.rows = arrTemp
            /* this.absenceData.name = (data.name + '\t' + data.lastname) */
          } else {
            this.rows = []
          }
        })
        .catch((error) => {
          // console.log('error', error)
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async TimeAttendanceAll () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/timeAttendance?search=${this.searchInput?.trim() || ''}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status && response.data.data.length > 0) {
            const resList = response.data.data.sort((a, b) => {
              return (new Date(b.date) - new Date(a.date))
            })
            const arrTemp = []
            for (let index = 0; index < resList.length; index++) {
              arrTemp.push({
                id: resList[index].id,
                index: (index + 1),
                variable1: resList[index].emp_no,
                variable2: (resList[index].emp_name || '') + ' ' + (resList[index].emp_lastname || ''),
                variable3: resList[index].date ? moment(resList[index].date).format('DD/MM/YYYY') : '-',
                variable4: resList[index].start_date ? moment(resList[index].start_date, 'HH:mm:ssZ').format('HH:mm') : '-',
                variable5: resList[index].end_date ? moment(resList[index].end_date, 'HH:mm:ssZ').format('HH:mm') : '-'
              })
            }
            this.AllrowsLenght = arrTemp.length
            /* this.absenceData.name = (data.name + '\t' + data.lastname) */
          } else {
            this.AllrowsLenght = 0
          }
        })
        .catch((error) => {
          // console.log('error', error)
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    getFullImagePath (relativePath) {
      if (relativePath) {
        relativePath = relativePath.substring(1)
      }
      const baseURL = `${configAPI.MA_PORT}`
      return baseURL + relativePath
    },
    async downloadFileTemplate () {
      let productGroupAPI = {
        method: 'post',
        url: `${configAPI.MA_PORT}/api/timeAttendance/download`
      }
      await axios.request(productGroupAPI)
        .then((response) => {
          if (response.data.status) {
            this.downloadFile = response.data.data
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    remove (item) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการลบข้อมูลใช่หรือไม่</span>',
        html: '<span class="" style="font-size: 14px; color: black; text-align: center">ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let index = this.rows.indexOf(item)
          this.rows.splice(index, 1)
          Vue.swal({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
            icon: 'success',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 2000
          })
        }
      })
    },
    async clickSave (bvModalEvent, data) {
      bvModalEvent.preventDefault()
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะนำเข้าข้อมูลใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.uploadFileTimeAtt()
        }
      })
    },
    async uploadFileTimeAtt () {
      if (!this.uploadPic) {
        this.SwalError('นำเข้าข้อมูลไม่สำเร็จ', 'ไม่พบไฟล์ที่จะนำเข้า กรุณาลองใหม่')
        return
      }
      let data = new FormData()
      data.append('file', this.uploadPic)
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/timeAttendance/import`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
        .then((response) => {
          if (response.data.data?.length > 0) {
            this.rows2 = response.data.data?.map((el, index) => {
              return {
                index: index + 1,
                variable1: el.emp_no || '-',
                variable2: (el.firstname || '-') + ' ' + (el.lastname || '-'),
                variable3: el.date || '-',
                variable4: el.status ? 'สำเร็จ' : 'ไม่สำเร็จ',
                variable5: el.error?.map((el) => {
                  return el
                }),
                status: el.status
              }
            })
            this.modalAdd = false
            this.modalStatus = true
          } else {
            this.SwalError('รูปแบบของข้อมูลในไฟล์ไม่ถูกต้อง', 'กรุณาตรวจสอบ')
          }
        })
        .catch((error) => {
          // console.log('error', error)
          this.modalAdd = false
          this.SwalError(error.message, 'นำเข้าข้อมูลไม่สำเร็จ')
        })
    },
    clickCancel (bvModalEvent) {
      bvModalEvent.preventDefault()
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.modalAdd = false
          // router.push('/PerformanceHistory')
        }
      })
    },
    onPagination (page) {
      this.currentPage = page
    },
    onPagination2 (page) {
      this.currentPage2 = page
    },
    async closeModalShowStatus () {
      this.modalStatus = false
      // window.location.reload()
      await this.filterTimeAttendance()
    },
    handleClick (e, id) {
      const setPage = e
      if (id) {
        router.push(`/AddServiceGroup/${setPage}/${id}`)
      } else {
        router.push(`/AddServiceGroup/${setPage}`)
      }
    },
    handleImageUpload (event) {
      this.uploadPic = event.target.files[0]
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    }
  },
  data () {
    return {
      uploadPic: null,
      cln_id: '',
      searchInput: '',
      downloadFile: {
        file_name: '',
        file_path: ''
      },
      columns: [
        { label: 'รหัสพนักงาน', key: 'variable1', class: 'text-center table-size-200-400' },
        { label: 'ชื่อ-นามสกุล', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'วันที่เข้างาน', key: 'variable3', class: 'text-center table-size-200-400' },
        { label: 'เวลาเข้างาน', key: 'variable4', class: 'text-center table-size-200-400' },
        { label: 'เวลาออกงาน ', key: 'variable5', class: 'text-center table-size-200-400' }
      ],
      rows: [],
      currentPage: 1,
      perPage: 10,
      rows2: [],
      columns2: [
        { label: 'รหัสพนักงาน', key: 'variable1', class: 'text-center table-size-100-300' },
        { label: 'ชื่อ-นามสกุล', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'วันที่เข้างาน', key: 'variable3', class: 'text-center table-size-100-300' },
        { label: 'สถานะ', key: 'variable4', class: 'text-center table-size-100-300' },
        { label: 'หมายเหตุ', key: 'variable5', class: 'text-center table-size-100-300' }
      ],
      currentPage2: 1,
      perPage2: 10,
      modalAdd: false,
      modalStatus: false,
      allowedTypes: [
        // 'image/png',
        // 'image/jpeg',
        'text/csv',
        // 'application/msword',
        // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        // 'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ],
      AllrowsLenght: 0
    }
  }
}
</script>
