<template>
  <b-container fluid>
    <div class="mx-3">
      <b-row>
        <b-col sm="12" md="7" lg="5" class="p-0 mr-3">
          <div class="iq-email-search d-flex">
            <div style="width: 100%;">
              <div class="form-group mb-0">
                <input class="form-control" id="searchMaterial" @keydown="enterSearch" placeholder="ค้นหา" style="width: 100%; height: 45px;" v-model="search">
                <a class="search-link" @click="filterProduct" ><i class="ri-search-line"/></a>
              </div>
            </div>
          </div>
        </b-col>
        <b-col sm="12" md="3" lg="auto" class="p-0">
          <div class="iq-email-search d-flex">
            <div style="width: 100%;">
              <b-button type="button" variant="primary-custom" class="w-100 btn-min-size" style="height: 45px;" @click="clearfilterProduct">clear</b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <br>
    <b-row>
      <b-col cols="12">
        <div class="table-responsive">
          <b-table bordered hover :items="rowsProduct" :fields="columnsProduct"
              :current-page="currentPage"
              :per-page="perPage"
              @pagination="onPagination"
              :tbody-tr-class="rowClass" thead-class="table-header-color"
          >
            <template v-slot:cell(no)="data">
              <span class="unselectable">{{ ((currentPage - 1) * perPage) + data.index + 1 }}</span>
            </template>
            <template v-slot:cell(product_no)="data">
              <span >{{ data.item.no || '-' }}</span>
            </template>
            <template v-slot:cell(product_name)="data">
              <span >{{ data.item.name || '-' }}</span>
            </template>
            <template v-slot:cell(product_img)="data">
              <b-img :src="formatImage(data.item.img_path)"
              style="height: 50px; width: 50px; object-fit: contain;"></b-img>
            </template>
            <template v-slot:cell(select)="data">
              <div style="display: flex; justify-content: center;">
                <b-button :variant="data.item.is_select ? 'danger' : 'primary-custom'" size="sm" @click="toggleAction(data.item)" style="width: 100px; border-radius: 10px;">
                  {{ data.item.is_select ? 'ยกเลิก' : 'เลือก' }}
                </b-button>
              </div>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>
    <b-row style="display: flex; justify-content: center;">
      <b-pagination
        v-model="currentPage"
        :total-rows="rowsProduct?.length"
        :per-page="perPage"
        align="center"
      />
    </b-row>
  </b-container>
</template>

<style scoped>
</style>

<script>
import Axios from 'axios'
import { xray } from '../../../config/pluginInit'
import Vue from 'vue'
import { configAPI } from '../../../configBase'
export default {
  name: 'productSelectModalQuo',
  props: {
    id: null,
    mainProductSelected: null,
    delList: []
  },
  data () {
    return {
      cln_id: JSON.parse(sessionStorage.getItem('user')).cln_id,
      usr_id: JSON.parse(sessionStorage.getItem('user')).usr_id,
      search: '',
      openModal: false,
      currentPage: 1,
      perPage: 10,
      rowsProduct: [],
      selectedTemp: [],
      selectedTemp2: [],
      columnsProduct: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        { label: 'รหัสสินค้า', key: 'product_no', class: 'text-center table-size-200-400 ellips' },
        { label: 'ชื่อสินค้า', key: 'product_name', class: 'text-center table-size-200-400 ellips' },
        { label: 'รูปสินค้า', key: 'product_img', class: 'text-center table-size-100-300' },
        { label: 'สถานะ', key: 'select', class: 'text-center table-size-200-400' }
      ]
    }
  },
  computed: {},
  async mounted () {
    await this.filterProduct()
    xray.index()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.filterProduct()
      }
    },
    async clearfilterProduct () {
      this.search = ''
      await this.filterProduct()
    },
    async filterProduct () {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/productforselect?search=${this.search}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        let tempData = res.data.data
        this.selectedTemp2 = [ ...this.selectedTemp ]
        let tempCheckSelect = [ ...this.mainProductSelected, ...this.selectedTemp2 ]
        const selectedTemp = []

        for (let item of tempData) {
          item.type = 'Product'
          item.oldNew = 'new'
          item.lp = item.price || 0
          item.quantity = ''
          const check = tempCheckSelect?.find((el) => {
            return el.id === item.id
          })
          if (check) {
            item.oldNew = check?.qp_id ? 'old' : 'new'
            item.qp_id = check?.qp_id || null
            item.is_select = true
            selectedTemp.push(item)
          } else {
            item.is_select = false
          }
        }
        this.rowsProduct = tempData
        /* this.selectedTemp = tempData.filter((el) => {
          return el.is_select
        }) */
        const checkDup = {}
        this.selectedTemp = [ ...selectedTemp, ...this.selectedTemp2 ].filter(el => {
          if (!checkDup[el.id]) {
            checkDup[el.id] = el.id
            return el
          }
        })
      }).catch((err) => {
        this.SwalError(err, 'เกิดข้อผิดพลาด')
      })
    },
    submitAction () {
      return [ this.selectedTemp, this.delList ]
    },
    toggleAction (row) {
      let indexSelected = this.selectedTemp.findIndex(el => el.id === row.id)
      if (!row.is_select) {
        row.status = row?.qp_id ? 'update' : 'add'
        if (indexSelected < 0) {
          this.selectedTemp.push(row)
        } else {
          this.selectedTemp[indexSelected] = row
        }
      } else {
        if (row.qp_id && (this.delList.find(el => el.qp_id === row.qp_id) === undefined)) {
          this.delList.push(row)
        }
        this.selectedTemp.splice(indexSelected, 1)
      }

      row.is_select = !row.is_select
    },
    onPagination (page) {
      this.currentPage = page
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    formatImage (ImgPath) {
      if (ImgPath) {
        let path = ImgPath !== null ? ImgPath.slice(1) : ''
        const baseURL = `${configAPI.MA_PORT}` + path
        return baseURL
      } else {
        return require('../../../assets/images/default.jpg')
      }
    }
  }
}
</script>
