<template>
    <b-container fluid>
      <b-row>
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <div class="row align-items-center py-4 mx-0">
                <div class="col-auto">
                  <span class="unselectable" style="font-size: 25px; color: black;">ใบขอซื้อ (PR)</span>
                </div>
                <div class="col-12 col-sm-auto my-1 my-sm-0">
                  <b-button @click="clickPath('add')" type="button" variant="primary-custom" class="w-100 btn-min-size">
                    <i class="circle-plus_ct"></i>
                    เพิ่มใบขอซื้อ
                  </b-button>
                </div>
              </div>
            </template>
            <template v-slot:body>
              <div class="mx-3">
                <b-row style="align-items: flex-end;">
                  <b-col cols="12" lg="3" class="pb-3">
                    <div class="iq-email-search d-flex">
                      <div style="width: 100%;">
                        <div class="form-group mb-0" style="height: 45px;">
                          <input v-model="search" @keydown="enterSearch" class="form-control" id="exampleInputEmail1" placeholder="Search"
                            style="width: 100%; height: 45px;">
                          <!-- <a class="search-link" @click="getQuotationBySale"><i class="ri-search-line" /></a> -->
                          <i style="color: #676767; top: 4px" class="search-link ri-search-line" />
                        </div>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="pb-3">
                    <b-text style="color: black; white-space: nowrap">วันที่ใบขอซื้อ (เริ่มต้น)</b-text>
                    <div style="position: relative; margin-top: .4rem;" class="w-res-attendance time-picker-attendance">
                      <date-picker v-if="searchEndDate" placeholder="เลือกวันที่" id="datepicker-custom-1"
                        :config="optionsDatepickerStart" v-model="searchStartDate"></date-picker>
                      <date-picker v-else placeholder="เลือกวันที่" id="datepicker-custom-1"
                        :config="optionsDatepickerDefault" v-model="searchStartDate"></date-picker>
                      <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(1)"
                        style="cursor: pointer;"></i>
                    </div>
                  </b-col>
                  <b-col cols="6" lg="2" class="pb-3">
                    <b-text style="color: black; white-space: nowrap">วันที่ใบขอซื้อ (สิ้นสุด)</b-text>
                    <div style="position: relative; margin-top: .4rem;" class="w-res-attendance time-picker-attendance">
                      <date-picker v-if="searchStartDate" placeholder="เลือกวันที่" id="datepicker-custom-2"
                        :config="optionsDatepickerEnd" v-model="searchEndDate"></date-picker>
                      <date-picker v-else placeholder="เลือกวันที่" id="datepicker-custom-2"
                        :config="optionsDatepickerDefault" v-model="searchEndDate"></date-picker>
                      <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(2)"
                        style="cursor: pointer;"></i>
                    </div>
                  </b-col>
                  <b-col cols="12" lg="auto" class="pb-3">
                    <b-button @click="filterPR" type="button" variant="primary-custom" class="w-100 btn-min-size"
                      style="height: 45px;">
                      <i class="ri-search-line"></i>
                      ค้นหา
                    </b-button>
                  </b-col>
                  <b-col cols="auto" class="pb-3">
                    <div style="display: flex; align-items: center; height: 45px;">
                      <span class="mx-2 font-size-14 text-primary-custom">
                        {{ rows_pr.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage *
                          currentPage) > rows_pr?.length) ? rows_pr.length : (perPage * currentPage)
                        ) + ' of ' + rows_pr_length : '0 of ' + rows_pr_length }} รายการ
                      </span>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-col cols="12">
                <div class="table-responsive">
                  <b-table bordered hover :items="rows_pr" :fields="columns_pr" :current-page="currentPage"
                    :per-page="perPage" @pagination="onPagination" :tbody-tr-class="rowClass"
                    thead-class="table-header-color">
                    <template v-slot:cell(no)="data">
                      <span>{{ ((currentPage - 1) * 10) + data.index + 1 }}</span>
                    </template>
                    <template v-slot:cell(pr_no)="data">
                      <span>{{ data.item.pr_no || '-' }}</span>
                      <!-- <span v-if="data.item.quo_count === '0'">{{ data.item.quo_no || '-' }}</span>
                        <a v-else style="color: #479CAC; cursor: pointer;" @click="ShowModalVersionQuo(data.item)"><span>{{ data.item.quo_no || '-' }}</span></a> -->
                    </template>
                    <template v-slot:cell(pr_date)="data">
                      <span>{{ formatDate(data.item.pr_date) || '-' }}</span>
                    </template>
                    <template v-slot:cell(pr_status)="data">
                      <b-button v-if="data.item.pr_prs_id === '1bcae035-ca58-4b05-bc05-2ad401fd743e'"
                        variant=" iq-bg-warning-custom btn-warning-border" style="min-width: 100px; cursor:default;">{{
                          data.item.prs_name }}</b-button>

                      <b-button v-else-if="data.item.pr_prs_id === '2a3f0b53-fb6f-45eb-a14a-836f06f9ebdf'"
                        variant=" iq-bg-success-custom btn-success-border" style="min-width: 100px; cursor:default;">{{
                          data.item.prs_name }}</b-button>

                      <b-button v-else-if="data.item.pr_prs_id === '0576ad6c-a0f5-4225-9511-590347a8c57c'"
                        variant=" iq-bg-info-custom btn-info-border" style="min-width: 100px; cursor:default;">{{
                          data.item.prs_name }}</b-button>

                      <b-button v-else-if="data.item.pr_prs_id === '42cc02e5-32d7-4cc0-9c6b-1eb68612b265'"
                        variant=" bg-danger-custom" style="min-width: 100px;"
                        @click.prevent="reasonModal = true, reasonTextShow = data.item.pr_reason_not_approve">{{
                          data.item.prs_name }}</b-button>

                      <span v-else>{{ '-' }}</span>
                    </template>
                    <template v-slot:cell(pr_approve_name)="data">
                      <span>{{ data.item.pr_approve_name || '-' }}</span>
                    </template>
                    <template v-slot:cell(management)="data">
                      <div style="display: flex;">
                        <b-button variant=" iq-bg-report-custom btn-report-border mx-1" size="sm"
                          style="cursor: not-allowed;">
                          <i class="ri-article-line m-0"></i>
                        </b-button>
                        <b-button variant=" iq-bg-eye-custom btn-eye-border mx-1" size="sm" @click="clickPath('view', data.item.pr_id)">
                          <i class="ri-eye-fill m-0"></i>
                        </b-button>
                        <b-button v-if="data.item.pr_prs_id === '0576ad6c-a0f5-4225-9511-590347a8c57c' || data.item.pr_prs_id === '42cc02e5-32d7-4cc0-9c6b-1eb68612b265'"
                          variant=" iq-bg-pencil-custom btn-pencil-border mx-1" size="sm" @click="clickPath('edit', data.item.pr_id)">
                          <i class="ri-pencil-fill m-0"></i>
                        </b-button>
                        <b-button v-if="data.item.pr_prs_id === '0576ad6c-a0f5-4225-9511-590347a8c57c' || data.item.pr_prs_id === '42cc02e5-32d7-4cc0-9c6b-1eb68612b265'"
                        variant=" iq-bg-bin-custom btn-bin-border mx-1" size="sm"
                          @click="deletePR(data.item.pr_id)"
                        >
                          <i class="ri-delete-bin-line m-0"></i>
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                </div>
              </b-col>
              <b-pagination v-model="currentPage" :total-rows="rows_pr?.length" :per-page="perPage" align="center" />
            </template>
          </iq-card>
        </b-col>
      </b-row>

      <!-- Reason Modal  -->
      <b-modal v-model="reasonModal" title="ไม่ผ่านการอนุมัติ" size='lg' centered @close.prevent="reasonModal = false"
        hide-footer>
        <div style="margin: 10px 30px;">
          <span style="word-break: break-word; color: black;">{{ reasonTextShow }}</span>
        </div>
      </b-modal>
    </b-container>
</template>

<style scoped>
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import Axios from 'axios'
import moment from 'moment'
import { configAPI } from '../../configBase'

const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}
export default {
  name: 'PurchaseRequisitionTable',
  data () {
    return {
      clnId: null,
      userId: null,
      search: '',
      sales: [],
      columns_pr: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        { label: 'เลขที่ใบขอซื้อ', key: 'pr_no', class: 'text-center table-size-200-400' },
        { label: 'วันที่ใบขอซื้อ', key: 'pr_date', class: 'text-center table-size-200-400' },
        { label: 'สถานะ', key: 'pr_status', class: 'text-center table-size-200-400' },
        { label: 'ชื่อผู้อนุมัติ', key: 'pr_approve_name', class: 'text-center table-size-200-400' },
        { label: 'Management', key: 'management', class: 'text-center table-size-200-400' }
      ],
      rows_pr: [],
      rows_pr_length: null,
      currentPage: 1,
      perPage: 10,
      searchStartDate: null,
      searchEndDate: null,
      optionsDatepickerDefault: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: false,
        minDate: false,
        maxDate: false,
        icons: iconDate
      },
      optionsDatepickerStart: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: false,
        minDate: false,
        maxDate: false,
        icons: iconDate
      },
      optionsDatepickerEnd: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: false,
        minDate: false,
        maxDate: false,
        icons: iconDate
      },
      reasonModal: false,
      reasonTextShow: ''
    }
  },
  computed: {
  },
  watch: {
    'searchStartDate': function (newDate) {
      this.optionsDatepickerEnd.minDate = newDate
    },
    'searchEndDate': function (newDate) {
      this.optionsDatepickerStart.maxDate = newDate
    }
  },
  async mounted () {
    this.clnId = JSON.parse(sessionStorage.getItem('user')).cln_id || null
    this.userId = JSON.parse(sessionStorage.getItem('user')).usr_id || null
    await this.filterPR()
    xray.index()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.filterPR()
      }
    },
    formatDate (date) {
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      } else {
        return '-'
      }
    },

    formatNumber (number) {
      if (number) {
        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } else {
        return '-'
      }
    },

    onClickDatePicker (number) {
      let datePicker = null
      datePicker = document.getElementById(`datepicker-custom-${number}`)
      datePicker.focus()
    },

    onPagination (page) {
      this.currentPage = page
    },

    rowClass (item, type) {
      if (!item || type !== 'row') return
      const index = this.rows_pr.indexOf(item) + 1
      if (index % 2 === 1) return 'table-body-color'
    },

    clickPath (e, data) {
      const setPage = e
      if (setPage === 'add') {
        router.push(`/PurchaseRequisitionMGT/${setPage}`)
      } else if (setPage === 'view') {
        router.push(`/PurchaseRequisitionMGT/${setPage}/${data}`)
      } else if (setPage === 'edit') {
        router.push(`/PurchaseRequisitionMGT/${setPage}/${data}`)
      } else if (setPage === 'history') {
        router.push(`/PurchaseRequisition/history/${data}`)
      }
    },

    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },

    /* filter section */
    async filterPR () {
      let dataFilter = {
        // usr_id: this.userId,
        search: this.search?.toString().trim(),
        searchStartDate: this.searchStartDate ? moment(this.searchStartDate, 'DD/MM/YYYY').startOf('day') : null,
        searchEndDate: this.searchEndDate ? moment(this.searchEndDate, 'DD/MM/YYYY').endOf('day') : null
      }
      await Axios.request({
        method: 'POST',
        url: `${configAPI.SALE_PORT}/api/purchaseRequisition/clinic/${this.clnId}/filterPR`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataFilter
      }).then((res) => {
        this.rows_pr = res.data.data
        this.rows_pr_length = res.data.dataCount
      }).catch((err) => {
        this.SwalError(err.message, 'เกิดข้อผิดพลาดในดึงข้อมูล')
      })
    },

    /* delete section */
    async deletePR (prId) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะลบใบขอซื้อนี้ใช่หรือไม่</span>`,
        // html: '<span>การลบนี้จะไม่สามารถนำใบขอซื้อนี้กลับมาได้</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonColor: '#dc3545',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-cancel-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Axios.request({
            method: 'PUT',
            url: `${configAPI.SALE_PORT}/api/purchaseRequisition/clinic/${this.clnId}/deletePR/${prId}`,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((res) => {
            Vue.swal.fire({
              title: `<span class="" style="font-size: 25px; color: black; text-align: center">ลบใบขอซื้อสำเร็จ</span>`,
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              this.filterPR()
            })
          }).catch((err) => {
            this.SwalError(err.message, 'เกิดข้อผิดพลาดในดึงข้อมูล')
          })
        }
      })
    }
  }
}
</script>
