<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="table-responsive">
        <iq-card>
          <!-- Header Section -->
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 gap-response">
              <div class="ml-4">
                <span style="font-size: 25px; color: black;">{{mode === 'add' ? 'รับตามใบสั่งซื้อ' : mode ==='edit' ? 'รับตามใบสั่งซื้อ (Edit)' : 'รับตามใบสั่งซื้อ (View)'}}</span>
              </div>
              <div class="row button-response-header" style="display: flex; align-items: center;">
                <div class="flex-response-header-quo my-2 mx-2" style="white-space: nowrap;">
                  <div v-if="mode === 'edit'">
                    <b-button @click="saveReceivePO" type="button" variant="primary" class="my-1 flex-fill text-center btn-add btn-min-size w-100" style="text-align: center;"><i class="save-data_ct"/>บันทึก</b-button>
                  </div>
                  <div>
                    <b-button type="button" variant="danger" @click="handleClickBack" class="my-1 flex-fill btn-min-size btn-cancel-border w-100" style="text-align: center;">ยกเลิก</b-button>
                  </div>
                  <div v-if="mode === 'edit'">
                    <b-button @click="closePO" type="button" variant="primary" class="my-1 flex-fill text-center btn-add btn-min-size w-100" style="text-align: center;"><i class="save-data_ct"/>ปิดใบสั่งซื้อ</b-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- Body Section -->
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col lg="6" md="6" sm="12">
                  <h3>Receive Item: {{ poDetail.po_receive }}</h3>
                </b-col>
                <b-col lg="6" md="6" sm="12">
                  <h3>PO No: {{ poDetail.po_no }}</h3>
                </b-col>
              </b-row>
              <hr>
              <!-- Product -->
              <template>
                <b-row>
                  <b-col>
                    <h3>สินค้า</h3>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" >
                    <div class="table-responsive">
                      <b-table bordered hover :items="rowProductSelected" :fields="columnsSelected"
                        :current-page="currentPDPageSelected" :per-page="perPageSelected" @pagination="onPaginationPD"
                        :tbody-tr-class="rowClass" thead-class="table-header-color">
                        <template v-for="(column) in columnsSelected" v-slot:[`cell(${column.key})`]="data">
                          <template v-if="column.key === 'no'">
                            <span :key="column.key">{{ ((currentPDPageSelected - 1) * perPageSelected) + data.index + 1
                            }}</span>
                          </template>
                          <template v-if="column.key === 'code_proser'">
                            <span :key="column.key">{{ data.item.no || '-' }}</span>
                          </template>
                          <template v-if="column.key === 'name_proser'">
                            <span :key="column.key">{{ data.item.name || '-' }}</span>
                          </template>
                          <template v-if="column.key === 'quantity'">
                            <span :key="column.key">{{ data.item.oldReceive || '0' }} / {{ data.item.quantity || '0' }}</span>
                          </template>
                          <template v-if="column.key === 'exp_date'">
                            <div :key="column.key" style="position: relative" class="d-flex align-items-center justify-content-center">
                              <flat-pickr
                                  v-model="data.item.exp_date"
                                  :config="config"
                                  class="form-control bg-white"
                                  placeholder="Select date"
                                  name="date"
                                  :id="'refFlatPicker' + data.item.id"
                                  >
                                </flat-pickr>
                                <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker2(data.item.id)"
                              style="cursor: pointer;"></i>
                            </div>
                          </template>
                          <template v-if="column.key === 'receive'">
                            <CurrencyInputCom v-model="data.item.receive" :options="optionCurrency"
                              :key="data.item.type + column.key + data.index"
                              :disabled="isDisable || data.item.quantity === 0"
                              />
                          </template>
                        </template>
                      </b-table>
                    </div>
                  </b-col>
                </b-row>
                <b-row style="display: flex; justify-content: center;">
                  <b-pagination v-model="currentPDPageSelected" :total-rows="rowProductSelected?.length"
                    :per-page="perPageSelected" align="center" />
                </b-row>
              </template>
              <hr>
              <!-- Table Material -->
              <template>
                <b-row>
                  <b-col>
                    <h3>วัตถุดิบ</h3>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" >
                    <div class="table-responsive">
                      <b-table bordered hover :items="rowRawMatSelected" :fields="columnsMatSelected"
                        :current-page="currentRMPageSelected" :per-page="perPageSelected" @pagination="onPaginationRM"
                        :tbody-tr-class="rowClass" thead-class="table-header-color">
                        <template v-for="(column) in columnsMatSelected" v-slot:[`cell(${column.key})`]="data">
                          <template v-if="column.key === 'no'">
                            <span :key="column.key">{{ ((currentRMPageSelected - 1) * perPageSelected) + data.index + 1
                            }}</span>
                          </template>
                          <template v-if="column.key === 'type_proser'">
                            <span :key="column.key">{{ data.item.mg_name || '-' }}</span>
                          </template>
                          <template v-if="column.key === 'brand'">
                            <span :key="column.key">{{ data.item.mb_name || '-' }}</span>
                          </template>
                          <template v-if="column.key === 'type'">
                            <span :key="column.key">{{ data.item.mt_name || '-' }}</span>
                          </template>
                          <template v-if="column.key === 'unit'">
                            <span :key="column.key">{{ data.item.unit_name || '-' }}</span>
                          </template>
                          <template v-if="column.key === 'quantity'">
                            <span :key="column.key">{{ data.item.oldReceive || '0' }} / {{ data.item.quantity || '0' }}</span>
                          </template>
                          <template v-if="column.key === 'exp_date'">
                            <div :key="column.key" style="position: relative;" class="w-res-attendance time-picker-attendance">
                              <flat-pickr
                                  v-model="data.item.exp_date"
                                  :config="config"
                                  class="form-control bg-white"
                                  placeholder="Select date"
                                  :name="'date' + data.item.id"
                                  :id="'refFlatPicker' + data.item.id"
                                  >
                                </flat-pickr>
                                <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker2(data.item.id)"
                              style="cursor: pointer;"></i>
                              <!-- <date-picker placeholder="เลือกวันที่" id="datepicker-custom"
                                :config="optionsDatepicker" v-model="data.item.exp_date"></date-picker>
                              <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(index)"
                                style="cursor: pointer;"></i> -->
                            </div>
                          </template>
                          <template v-if="column.key === 'receive'">
                            <CurrencyInputCom v-model="data.item.receive" :options="optionCurrency"
                              :key="data.item.type + column.key + data.index"
                              :disabled="isDisable"
                            />
                          </template>
                        </template>
                      </b-table>
                    </div>
                  </b-col>
                </b-row>
                <b-row style="display: flex; justify-content: center;">
                  <b-pagination v-model="currentRMPageSelected" :total-rows="rowRawMatSelected?.length"
                    :per-page="perPageSelected" align="center" />
                </b-row>
              </template>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped>
.checkbox-quotation-mgt > .custom-control-input:checked~.custom-control-label::before {
  border-color: #0DC268 !important;
  background-color: #0DC268 !important;
}
.defind-search-icon-cusname {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 0.5rem;
  color: #089bab !important;
}

.defind-search-icon-cusname:hover {
  color: #4e5051 !important;
}
[dir=ltr] .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #A3A3A3 !important;
  border-color: #A3A3A3 !important;
}

.flex-response-header-quo {
  display: flex;
  gap: .5rem
}

.max-width-btn-quo-mgt {
  max-width: 200px;
}

.checkbox-quo {
  padding: 0;
  margin-left: 1rem;
}
@media (max-width: 376px) {
  .flex-response-header-quo {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0;
  }
  .max-width-btn-quo-mgt {
    max-width: 376px;
    width: 100%;
  }

  .fx-gp-quo {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .checkbox-quo {
    margin-left: 0;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
// import moment from 'moment'
import { mask } from 'vue-the-mask'
import { configAPI } from '../../configBase'
import CurrencyInputCom from './CurrencyInputCom.vue'
import Axios from 'axios'
// import flatpickr from 'flatpickr'

const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}

export default {
  name: 'ReceiveByPOMGT',
  components: {
    CurrencyInputCom
  },
  directives: { mask },
  data () {
    return {
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      usrId: JSON.parse(sessionStorage.getItem('user')).usr_id || null,
      mode: 'add',
      poDetail: {
        po_receive: 0,
        po_id: null,
        po_no: null,
        po_sup_id: null
      },
      rowProductSelected: [],
      rowRawMatSelected: [],
      columnsSelected: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        { label: 'รหัสสินค้า', key: 'code_proser', class: 'text-center table-size-200-400 ellips' },
        { label: 'ชื่อสินค้า', key: 'name_proser', class: 'text-center table-size-200-400 ellips' },
        { label: 'จำนวน', key: 'quantity', class: 'text-center table-size-200-400' },
        { label: 'วันหมดอายุ', key: 'exp_date', class: 'text-center table-size-200-400' },
        { label: 'จำนวนรับเข้า', key: 'receive', class: 'text-center table-size-200-400' }
      ],
      columnsMatSelected: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        { label: 'หมวดหมู่', key: 'type_proser', class: 'text-center table-size-200-400' },
        { label: 'แบรนด์', key: 'brand', class: 'text-center table-size-200-400 ellips' },
        { label: 'ประเภท', key: 'type', class: 'text-center table-size-200-400' },
        { label: 'หน่วยนับ', key: 'unit', class: 'text-center table-size-200-400' },
        { label: 'จำนวน', key: 'quantity', class: 'text-center table-size-200-400' },
        { label: 'วันหมดอายุ', key: 'exp_date', class: 'text-center table-size-200-400' },
        { label: 'จำนวนรับเข้า', key: 'receive', class: 'text-center table-size-200-400' }
      ],
      currentPDPageSelected: 1,
      perPageSelected: 10,
      currentRMPageSelected: 1,
      perPageRMSelected: 10,
      isDisable: false,
      options: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: false,
        showClose: true,
        useStrict: true,
        minDate: false,
        icons: iconDate,
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'auto'
        }
      },
      optionCurrency: {
        currency: 'EUR',
        currencyDisplay: 'hidden',
        valueRange: {
          min: 0,
          max: 1e+53
        },
        precision: 0,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: true,
        accountingSign: false
      },
      optionsDatepicker: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: false,
        minDate: false,
        maxDate: false,
        keepOpen: true,
        allowInputToggle: true,
        icons: iconDate,
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'bottom'
        }
      },
      config: {
        mode: 'single',
        allowInput: false,
        disableMobile: true,
        dateFormat: 'd-m-Y',
        minDate: new Date()
      }
    }
  },
  async mounted () {
    // flatpickr('#flatpick-po', this.config)
    if (this.$route.params.setPage === 'edit') {
      this.mode = 'edit'
      this.isDisable = false
      this.poDetail.po_id = this.$route.params.id
      await this.getPOReceiveById()
    } else if (this.$route.params.setPage === 'view') {
      this.mode = 'view'
      this.isDisable = true
      this.poDetail.po_id = this.$route.params.id
      await this.getPOReceiveById()
    } else {
      this.mode = 'add'
      this.isDisable = false
    }
    xray.index()
  },
  watch: {
  },
  methods: {
    onClickDatePicker2 (index) {
      const datepicker = document.getElementById(`refFlatPicker${index}`)
      datepicker.focus()
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      const index = this.rowProductSelected.findIndex(el => el.id === item.id)
      if (index % 2 === 1) return 'table-body-color'
    },
    formatNumber (value) {
      if (value === null || value === undefined) {
        return ''
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getFullImagePath (relativePath) {
      if (relativePath !== null && relativePath !== '') {
        let path = relativePath !== null ? relativePath.slice(1) : ''
        const baseURL = configAPI.MA_PORT + path
        return baseURL
      } else {
        return 'https://www.w3schools.com/howto/img_avatar.png'
      }
    },
    onPaginationPD (page) {
      this.currentPDPageSelected = page
    },
    onPaginationRM (page) {
      this.currentRMPageSelected = page
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    onClickDatePicker (number) {
      const datepicker = document.getElementById(`datepicker-custom${number}`)
      datepicker.focus()
    },
    handleClickBack () {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          router.push('/ReceiveByPO')
        }
      })
    },
    async getPOReceiveById (poId) {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.SALE_PORT}/api/purchaseOrder/clinic/${this.clnId}/getPOReceiveById/${poId || this.$route.params.id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        let tempData = res.data.data[0]
        let arrList = {
          prdList: [],
          rmList: []
        }
        this.optionsDatepicker.minDate = new Date()
        for (let item of tempData?.po_item_list) {
          item.is_select = true
          item.quantity = item.poi_amount
          item.lp = item.poi_price
          item.discount = item.poi_discount
          item.row_price = (item.poi_amount * item.poi_price - item.poi_discount).toFixed(2)
          item.poi_id = poId ? null : item.poi_id
          item.receive = ''
          item.exp_date = null
          if (poId) {
            item.status = 'add'
          }
          if (item.poi_prd_id) {
            item.id = item.poi_prd_id
            // console.log('item.poi_item_data', item.poi_item_data)
            const dataName = {
              no: item.poi_item_data.no || item.poi_item_data.pri_item_data.no,
              name: item.poi_item_data.name || item.poi_item_data.pri_item_data.name
            }
            arrList.prdList.push({ ...item, ...dataName })
          } else if (item.poi_mat_id) {
            item.id = item.poi_mat_id
            const dataName = {
              mg_name: item.poi_item_data.mg_name || item.poi_item_data.pri_item_data.mg_name,
              mb_name: item.poi_item_data.mb_name || item.poi_item_data.pri_item_data.mb_name,
              mt_name: item.poi_item_data.mt_name || item.poi_item_data.pri_item_data.mt_name,
              unit_name: item.poi_item_data.unit_name || item.poi_item_data.pri_item_data.unit_name
            }
            arrList.rmList.push({ ...item, ...dataName })
          }
        }
        let oldReceive = 0
        for (let detail of tempData.receive_po_list) {
          oldReceive += detail.rp_received_amount
          for (let item of arrList.prdList) {
            if (detail.rp_prd_id === item.id) {
              item.oldReceive = (item.oldReceive || 0) + detail.rp_received_amount
            }
          }
          for (let item of arrList.rmList) {
            if (detail.rp_mat_id === item.id) {
              item.oldReceive = (item.oldReceive || 0) + detail.rp_received_amount
            }
          }
        }

        this.poDetail.po_receive = oldReceive
        this.poDetail.po_no = tempData?.po_no
        this.poDetail.po_sup_id = tempData?.po_sup_id

        this.rowProductSelected = arrList.prdList
        this.rowRawMatSelected = arrList.rmList
      }).catch((err) => {
        this.SwalError(err, 'Error')
      })
    },
    async checkSave () {
      let errList = []
      if (this.rowProductSelected.length > 0) {
        for (let row of this.rowProductSelected) {
          if (row.quantity > 0) {
            if (row.exp_date === null || row.exp_date === '') {
              errList.push(`กรุณากรอกวันหมดอายุ ${row.name}`)
            }
          }
        }
      }
      if (this.rowRawMatSelected.length > 0) {
        for (let row of this.rowRawMatSelected) {
          if (row.quantity > 0) {
            if (row.exp_date === null || row.exp_date === '') {
              errList.push(`กรุณากรอกวันหมดอายุ ${row.mg_name}`)
            }
          }
        }
      }
      return errList
    },
    async saveReceivePO () {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการบันทึกข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let errList = await this.checkSave()
          if (errList.length > 0) {
            let err = ''
            for (let item of errList) {
              err += item + '<br>'
            }
            this.SwalError(err, 'เกิดข้อผิดพลาด')
          } else {
            let data = {
              po_id: this.$route.params.id,
              po_no: this.poDetail.po_no,
              po_sup_id: this.poDetail.po_sup_id,
              po_prd_list: this.rowProductSelected,
              po_mat_list: this.rowRawMatSelected,
              usrId: this.usrId
            }
            await Axios.request({
              method: 'POST',
              url: `${configAPI.SALE_PORT}/api/purchaseOrder/clinic/${this.clnId}/saveReceivePO`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: data
            }).then((res) => {
              Vue.swal.fire({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
                icon: 'success',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 1500
              }).then((result) => {
                router.push('/ReceiveByPO')
              })
            }).catch((err) => {
              this.SwalError(err, 'Error')
            })
          }
        }
      })
    },
    async closePO () {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการปิดใบสั่งซื้อใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Axios.request({
            method: 'POST',
            url: `${configAPI.SALE_PORT}/api/purchaseOrder/clinic/${this.clnId}/closePO`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: {
              po_id: this.poDetail.po_id,
              usrId: this.usrId
            }
          }).then((res) => {
            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ปิดใบสั่งซื้อสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 1500
            }).then((result) => {
              router.push('/ReceiveByPO')
            })
          }).catch((err) => {
            this.SwalError(err, 'Error')
          })
        }
      })
    }
  }
}
</script>
