<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="table-responsive">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span class="unselectable" style="font-size: 25px; color: black;">ใบจ้างงานหมอ {{ mode === 'add' ? '' : '(ดูรายละเอียด)' }}</span>
              </div>
              <div v-if="mode === 'add' || mode === 'edit'" class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-6 col-sm-auto pl-3 pr-2 my-1 my-sm-0">
                    <b-button type="button" variant="primary" @click="handleClick(true)" class="w-100 btn-min-size"><i class="save-data_ct"></i>ยืนยัน</b-button>
                  </div>
                  <div class="col-6 col-sm-auto pl-2 pr-3 my-1 my-sm-0">
                    <b-button type="button" variant="none" @click="handleClick(false)" class="iq-bg-danger btn-cancel-border w-100 btn-min-size">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
              <div v-else class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="button" variant="none" @click="handleClick(false)" class="iq-bg-danger btn-cancel-border w-100 btn-min-size">ยกเลิก</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="px-3">
              <b-row >
                <b-col lg="12" sm="12">
                  <b-row>
                    <b-col lg="6" sm="6">
                      <b-text style="color: black;">ชื่อคลินิก</b-text>
                      <b-form-input v-if="postjobData[0]" type="text" v-model="postjobData[0].cln_name" :disabled="true" placeholder="รอดึงข้อมูลจาก back-end"></b-form-input>
                      <b-form-input v-else-if="clinic[0]" type="text" v-model="clinic[0].name" :disabled="true" placeholder="รอดึงข้อมูลจาก back-end"></b-form-input>
                      <b-form-input v-else type="text" :disabled="true" placeholder="รอดึงข้อมูลจาก back-end"></b-form-input>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-text style="color: black;">เบอร์ติดต่อ</b-text>
                      <b-form-input v-if="postjobData[0]" type="text" v-model="postjobData[0].cln_tel" :disabled="true" placeholder="รอดึงข้อมูลจาก back-end"></b-form-input>
                      <b-form-input v-else-if="clinic[0]" type="text" v-model="clinic[0].tel" :disabled="true" placeholder="รอดึงข้อมูลจาก back-end"></b-form-input>
                      <b-form-input v-else type="text" :disabled="true" placeholder="รอดึงข้อมูลจาก back-end"></b-form-input>
                    </b-col>
                  </b-row>
                  <br>
                  <b-row>
                    <b-col lg="12" sm="12">
                      <b-text style="color: black;">สถานที่ / ใกล้ BTS / MRT</b-text>
                      <b-form-textarea v-if="postjobData[0]" type="text" v-model="postjobData[0].cln_address" :disabled="true" placeholder="รอดึงข้อมูลจาก back-end" style="height: 100px;"></b-form-textarea>
                      <b-form-textarea v-else-if="clinic[0]" type="text" v-model="clinic[0].address" :disabled="true" placeholder="รอดึงข้อมูลจาก back-end" style="height: 100px;"></b-form-textarea>
                      <b-form-textarea v-else type="text" :disabled="true" placeholder="รอดึงข้อมูลจาก back-end" style="height: 100px;"></b-form-textarea>
                    </b-col>
                  </b-row>
                  <br>
                  <b-row>
                    <b-col lg="6" sm="6">
                      <b-text style="color: black;">หัวข้อการทำงาน<span v-if="!postjobData[0]" :hidden="postjobTemp.topic.trim() !== '' ? true : false" class="text-danger"> *</span></b-text>
                      <b-form-input v-if="postjobData[0]" type="text" v-model="postjobData[0].topic" :disabled="isDisable" placeholder=""></b-form-input>
                      <b-form-input v-else type="text" v-model="postjobTemp.topic" :disabled="isDisable" placeholder=""></b-form-input>
                    </b-col>
                    <b-col lg="3" sm="3">
                      <b-text style="color: black;">วันที่ต้องการแพทย์<span v-if="!postjobData[0]" :hidden="postjobTemp.start_date ? true : false" class="text-danger"> *</span></b-text>
                      <div style="justify-content: center; display: flex;" class="calender-small">
                          <flat-pickr v-if="!isDisable" type="text" v-model="selectedDateRange" ref="dateRangeInput" :config="config" class="form-control bg-white" id="flatpickrID"/>
                          <b-form-input v-else type="text" v-model="selectedDateRange" :disabled="isDisable" placeholder=""></b-form-input>
                        </div>
                      <!-- <div style="position: relative;">
                        <b-input v-if="postjobData[0]" :placeholder="dateFormat(postjobData[0].date)" id="datepicker-custom" name="date" :disabled="isDisable" :config="options"/>
                        <date-picker v-else placeholder="DD/MM/YYYY HH:mm" id="datepicker-custom" name="date" v-model="postjobTemp.date" :disabled="isDisable" :config="options"></date-picker>
                        <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker" :disabled="isDisable" style="cursor: pointer;"></i>
                      </div> -->
                    </b-col>
                    <b-col lg="3" sm="3">
                      <b-text style="color: black;">เวลาทำงานต่อวัน ( ชั่วโมง )<span v-if="!postjobData[0]" :hidden="postjobTemp.work_range !== null ? true : false" class="text-danger"> *</span></b-text>
                      <b-form-input v-if="postjobData[0]" type="text" v-model="postjobData[0].work_range" :disabled="isDisable" placeholder=""></b-form-input>
                      <CurrencyInputCom v-else :options="optionCurrency" v-model="postjobTemp.work_range" :isLimit="true" :valueLimit="24"/>
                    </b-col>
                  </b-row>
                  <br>
                  <b-row>
                    <b-col lg="6" sm="6">
                      <b-text class="" style="color: black; text-align: center; white-space: nowrap;">อัตราค่าจ้าง<span v-if="!postjobData[0]" :hidden="postjobTemp.mi_id !== null ? true : false" class="text-danger"> *</span></b-text>
                      <v-select v-if="postjobData[0]" v-model="postjobData[0].mi_name" :disabled="isDisable" :options="minincomeDD"></v-select>
                      <v-select v-else :disabled="isDisable" v-model="postjobTemp.mi_id" :options="minincomeDD"></v-select>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-text class="" style="color: black; text-align: center; white-space: nowrap;">ประเภทงานที่ต้องการ<span v-if="!postjobData[0]" :hidden="postjobTemp.is_receive !== null ? true : false" class="text-danger"> *</span></b-text>
                      <v-select v-if="postjobData[0]" v-model="postjobData[0].is_receive" :disabled="isDisable" :options="typeNeedJob"></v-select>
                      <v-select v-else :disabled="isDisable" v-model="postjobTemp.is_receive" :options="typeNeedJob"></v-select>
                    </b-col>
                  </b-row>
                  <br>
                  <b-row>
                    <b-col lg="6" sm="6">
                        <b-text class="" style="color: black; text-align: center; white-space: nowrap;">มาตรฐานยา<span v-if="!postjobData[0]" :hidden="postjobTemp.standart_work !== null ? true : false" class="text-danger"> *</span></b-text>
                        <v-select v-if="postjobData[0]" v-model="postjobData[0].standart_work" :disabled="isDisable" :options="standardOptions"></v-select>
                        <v-select v-else :disabled="isDisable" v-model="postjobTemp.standart_work" :options="standardOptions"></v-select>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <br/>
              <hr/>
              <h4 style="color: black;">รายชื่อหมอ</h4>
              <br/>
              <b-row >
                <b-col md="12" class="restable-recruit-doctor d-flex">
                <b-table bordered hover :items="postjob" :fields="columns"
                  :current-page="currentPage"
                  :per-page="perPage"
                  @pagination="onPagination"
                  :tbody-tr-class="rowClass" thead-class="table-header-color"
                >
                <template v-slot:cell(variable1)="data">
                  <span v-if="!data.item.editable">{{ postjob[((currentPage - 1) * 10) + data.index].name }}</span>
                </template>
                <template v-slot:cell(variable2)="data">
                  <span v-if="!data.item.editable">{{ postjob[((currentPage - 1) * 10) + data.index].phone }}</span>
                </template>
                <template v-slot:cell(variable3)="data">
                  <span v-if="!data.item.editable">{{ postjob[((currentPage - 1) * 10) + data.index].convenientPlace }}</span>
                </template>
                <template v-slot:cell(variable4)="data">
                  <span v-if="!data.item.editable">{{ postjob[((currentPage - 1) * 10) + data.index].is_receive }}</span>
                </template>
                <template v-slot:cell(variable5)="data">
                  <span v-if="!data.item.editable">{{ postjob[((currentPage - 1) * 10) + data.index].standard }}</span>
                </template>
                <template v-slot:cell(variable6)="data">
                  <span v-if="!data.item.editable">{{ postjob[((currentPage - 1) * 10) + data.index].convenientStartWork }}</span>
                </template>
                <template v-slot:cell(variable7)="data" style="white-space: nowrap;">
                  <span  style="white-space: nowrap;" v-if="!data.item.editable">{{ postjob[((currentPage - 1) * 10) + data.index].salaryMin }}</span>
                </template>
                <!-- <template v-slot:cell(variable8)="data">
                  <div style="display: flex; justify-content: center;">
                    <b-button variant=" iq-bg-info mr-1" size="sm" @click="handleClick('view', postjob[((currentPage - 1) * 10) + data.index].id)"><i class="ri-eye-fill m-0"></i></b-button>
                  </div>
                </template> -->
              </b-table>
              <br>
            </b-col>
            <b-col>
            <b-pagination
                class="mt-3 d-flex justify-content-center"
                v-model="currentPage"
                :total-rows="postjob.length"
                :per-page="perPage"
              />
            </b-col>

              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped>
.table-responsive {
  height: 80vh;
}
.restable-recruit-doctor {
  overflow-x: scroll;
}
.dropdown-menu {
  z-index: 10000000 !important;
}
.icon-calendar-edit {
  position: absolute;
  height: 100%;
  font-size: 1.2rem;
  padding-right: 1rem;
  font-weight: 100;
  display: flex;
  color: rgb(100, 100, 100);
  top: 0px;
  right: 0px;
  align-items: center;
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import moment from 'moment'
import axios from 'axios'
// import datePicker from 'vue-bootstrap-datetimepicker'
import Vue from 'vue'
import { configAPI } from '../../configBase'
import CurrencyInputCom from './CurrencyInputCom.vue'

const postJobDataTest = {
  nameClinic: 'หมอเซ็ง',
  phone: '082-922-2222',
  place: 'บางโพ MRT',
  dateTime: '27/06/2023 18:00',
  rateEmploy: 'option1',
  typeNeedJob: 'option2',
  standard: 'option2'
}
document.getElementById(postJobDataTest)
export default {
  name: 'UiDataTable',
  components: {
    // DatePicker
    CurrencyInputCom
    // datePicker
  },
  data () {
    return {
      cln_id: null,
      clinic: [],
      id: null,
      config: {
        mode: 'range',
        dateFormat: 'Y-m-d',
        minDate: new Date()
        // inline: true
      },
      postjob: [],
      columns: [
        { label: 'ชื่อ - นามสกุล', key: 'variable1', class: 'text-center table-size-200-400' },
        { label: 'เบอร์ติดต่อ', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'พื้นที่สะดวกรับงาน', key: 'variable3', class: 'text-center table-size-200-400' },
        { label: 'ประเภทงานที่รับ', key: 'variable4', class: 'text-center table-size-200-400' },
        { label: 'มาตรฐานรับงาน', key: 'variable5', class: 'text-center table-size-200-400' },
        { label: 'ช่วงเวลาที่สะดวกรับงาน', key: 'variable6', class: 'text-center table-size-200-400' },
        { label: 'ค่าเวรขั้นต่ำที่รับ', key: 'variable7', class: 'text-center table-size-200-400' }
        // { label: 'จัดการ', key: 'variable8', class: 'text-center' }
        /* { label: 'วันที่เข้าใช้ครั้งแรก', key: 'variable8', class: 'text-center table-size-200-400' },
        { label: 'ประวัติลูกค้าล่าสุด', key: 'variable9', class: 'text-center table-size-200-400' } */
      ],
      postjobData: [],
      optionCurrency: {
        currency: 'EUR',
        currencyDisplay: 'hidden',
        valueRange: {
          min: 0,
          max: 1e+53
        },
        precision: 0,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: true,
        accountingSign: false
      },
      isDisable: false,
      postjobTemp: {
        topic: '',
        work_range: null,
        start_date: null,
        end_date: null,
        mi_id: null,
        is_receive: null,
        standart_work: null
      },
      minincomeDD: [],
      minincomeOption: null,
      mode: 'add',
      postJobData: {
        nameClinic: '',
        phone: '',
        place: '',
        dateTime: null,
        rateEmploy: null,
        typeNeedJob: null,
        standard: null
      },
      options: {
        // https://momentjs.com/docs/#/displaying/
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        sideBySide: true,
        minDate: new Date(),
        icons: {
          time: 'fas fa-clock',
          date: 'fas fa-calendar',
          up: 'fas fa-chevron-up',
          down: 'fas fa-chevron-down',
          previous: 'fas fa-chevron-left',
          next: 'fas fa-chevron-right',
          today: 'fas fa-calendar-day',
          clear: 'fas fa-trash-alt',
          close: 'fas fa-times'
        },
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'bottom'
        }
      },
      showModal: false,
      supplierName1: '',
      standardOptions: [
        { code: 'อย.', label: 'อย. เท่านั้น' },
        { code: 'อื่นๆ', label: 'อื่นๆ' }
      ],
      rateEmployOptions: [
        { code: 'option1', label: '700 / hr + 5-10%' },
        { code: 'option2', label: '900 / hr + 5-10%' },
        { code: 'option3', label: '1100 / hr + 5-10%' }
      ],
      typeNeedJob: [
        { code: 'injection', label: 'Injection' },
        { code: 'surgery', label: 'Surgery' }
      ],
      currentPage: 1,
      perPage: 10,
      selectedDateRange: null
    }
  },
  watch: {
    minincomeOption (newValue) {
      this.postjobTemp.mi_id = newValue !== null ? newValue.value : null
      // this.OLDData.data[0].gender_id = newValue.label
    },
    selectedDateRange (val) {
      if (val !== null) {
        let DateStartEnd = val.split(' to ')
        let startDate = null
        let endDate = null
        if (DateStartEnd.length === 1) {
          startDate = new Date(DateStartEnd[0])
          endDate = new Date(DateStartEnd[0])
        } else {
          startDate = new Date(DateStartEnd[0])
          endDate = new Date(DateStartEnd[1])
        }
        this.postjobTemp.start_date = startDate
        this.postjobTemp.end_date = endDate
      }
    }
  },
  async mounted () {
    xray.index()
    this.cln_id = JSON.parse(sessionStorage.getItem('user')).cln_id
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      /* this.mode = 'view'
      this.isDisable = true
      await this.getPostJobData()
    } else { */
      this.mode = 'add'
      this.isDisable = false
      await this.getClinic()
      await this.getDetailsDoctorById()
    }
    await this.getMinIncomeDD()
  },
  methods: {
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    async getDetailsDoctorById () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/doctor/${this.id}/`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status) {
            const data = response.data.data
            if (data.length > 0) {
              const tempList = []
              for (let index = 0; index < data.length; index++) {
                tempList.push({
                  index: index,
                  id: data[index].id,
                  name: data[index].name,
                  phone: data[index].tel,
                  convenientPlace: data[index].aw_name,
                  is_receive: data[index].is_receive,
                  standard: data[index].standart_work,
                  salaryMin: data[index].mi_name,
                  convenientStartWork: data[index].work_range
                })
                this.postjobTemp.is_receive = this.typeNeedJob.find(el => el.code === data[index].is_receive?.toLowerCase()) || null
                this.postjobTemp.standart_work = this.standardOptions.find(el => el.code === data[index].standart_work) || null
              }

              this.postjob = tempList
            }
            /* this.dataDoctor = {
              name: data.name,
              lastName: null,
              gender: data.gender_name,
              age: data.age,
              numberJob: data.no,
              branchSpecail: data.specialized,
              phone: data.tel,
              email: data.email,
              convenientPlace: data.aw_name,
              numberTransfer: data.account_no,
              bank: data.bank_name,
              nameAccount: data.account_name,
              checkBInjection: data.is_receive?.toLowerCase() === 'injection',
              checkBSurgery: data.is_receive?.toLowerCase() === 'surgery',
              standard: data.standart_work,
              selectNotDo: data.no_area,
              convenientStartWork: data.work_range,
              salaryMin: data.mi_name,
              ImgCitizenName: data.citizen_img,
              ImgCitizenPath: data.citizen_path,
              ImgAccoutName: data.account_img,
              ImgAccoutPath: data.account_path,
              ImgCardJobName: data.specialized_img,
              ImgCardJobPath: data.specialized_path,
              ImgPersonName: data.person_img,
              ImgPersonPath: data.person_path
            } */
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    convertToJson () {
      let dataList = {}
      const startDateString = this.postjobTemp.start_date ? new Date(moment(this.postjobTemp.start_date, 'DD/MM/YYYY HH:mm')) : null
      const endDateString = this.postjobTemp.end_date ? new Date(moment(this.postjobTemp.end_date, 'DD/MM/YYYY HH:mm')) : null

      dataList = {
        date: {
          start_date: startDateString,
          end_date: endDateString
        },
        mi_id: this.postjobTemp.mi_id.value,
        is_receive: this.postjobTemp.is_receive.code,
        standart_work: this.postjobTemp.standart_work.code,
        topic: this.postjobTemp.topic,
        ld_id_list: [this.id],
        work_range: this.postjobTemp.work_range
      }
      // console.log(dataList)
      return dataList
    },
    getMinIncomeDD () {
      let getMinIncomeDDAPI = {
        method: 'get',
        url: `${configAPI.MD_PORT}/api/minIncome`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      axios.request(getMinIncomeDDAPI)
        .then((response) => {
          this.minincomeDD = response.data.data?.map(el => {
            return {
              value: el.id,
              label: el.name
            }
          })
          let same = this.minincomeDD?.filter(item => item.id === this.postjobTemp.mi_id)
          if (same.length > 0) {
            this.minincomeOption = { value: same[0].id, label: same[0].name }
          } else {
            this.minincomeOption = null
          }
        })
    },
    async addPostJob () {
      let addPostJobAPI = {
        method: 'post',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/job`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: this.convertToJson()
      }
      await axios.request(addPostJobAPI)
        .then((response) => {
        })/* .catch((error) => {
          console.error(error)
        }) */
    },
    async getClinic () {
      let clinicAPI = {
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(clinicAPI)
        .then((response) => {
          this.clinic = response.data.data
        })/* .catch((error) => {
          console.error(error)
        }) */
    },
    dateFormat (date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss')
    },
    async getPostJobData () {
      let id = this.$route.params.id
      let postjobDataAPI = {
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/job/${id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(postjobDataAPI)
        .then((response) => {
          this.postjobData = response.data.data
        })/* .catch((error) => {
          console.error(error)
        }) */
    },
    onClickDatePicker () {
      const datePicker = document.getElementById('datepicker-custom')
      datePicker.focus()
    },
    preventDecimalAndNegative (event) {
      if (
        event.key === '.' ||
        event.key === '-' ||
        event.key === 'e'
      ) {
        event.preventDefault()
      }
    },
    handleImageUpload (event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.imageUrl = reader.result
      }
    },
    handleImageUpload2 (event) {
      const file2 = event.target.files[0]
      const reader2 = new FileReader()
      reader2.readAsDataURL(file2)
      reader2.onload = () => {
        this.imageUrl2 = reader2.result
      }
    },
    async handleClick (status) {
      if (status) {
        let errorList = await this.checkError()
        // .__ob__.value.var_product1
        // console.log('this.product', this.products?.__ob__.value)
        // console.log('this.supplier', this.rows)
        Vue.swal.fire({
          title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.$route.params.setPage !== 'edit' ? 'คุณมั่นใจว่าคุณจะเปิดใบจ้างหมอท่านนี้ใช่หรือไม่?' : 'คุณมั่นใจว่าคุณจะแก้ไขข้อมูลใช่หรือไม่?'}</span>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            if (errorList.length > 0) {
              Vue.swal.fire({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">กรุณากรอกข้อมูลให้ครบถ้วน</span>',
                html: '<span>' + errorList.join('<br>') + '</span>',
                icon: 'warning',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
                confirmButtonColor: '#089bab',
                customClass: {
                  confirmButton: 'custom-confirm-button-class mx-1'
                }
              })
              return
            }
            // await this.addPostJob()
            // Vue.swal({
            //   title: '<span class="" style="font-size: 25px; color: black; text-align: center">ประกาศหางานสำเร็จ</span>',
            //   text: 'กรุณารอการตอบรับจากหมอ',
            //   icon: 'success',
            //   allowEscapeKey: false,
            //   allowOutsideClick: false,
            //   showConfirmButton: false,
            //   timer: 2000
            // }).then(() => {
            //   router.go(-1)
            // })
            // Show the loading screen
            Vue.swal.fire({
              title: '<span class="loading-icon"><i class="fas fa-spinner fa-spin"></i></span>',
              text: 'กำลังทำรายการ...',
              showConfirmButton: false,
              allowEscapeKey: false,
              allowOutsideClick: false,
              onOpen: () => {
                Vue.swal.getHtmlContainer().querySelector('.loading-icon').style.fontSize = '40px'
              }
            })
            this.addPostJob()
              .then(() => {
                Vue.swal.close()
                Vue.swal.fire({
                  title: '<span class="" style="font-size: 25px; color: black; text-align: center">จ้างหมอสำเร็จ</span>',
                  // text: 'กรุณารอการตอบรับจากหมอ',
                  icon: 'success',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  timer: 2000
                }).then(() => {
                  router.push('/PartTime')
                })
              })
              .catch(() => {
                Vue.swal.close()
                Vue.swal.fire({
                  title: '<span class="" style="font-size: 25px; color: black; text-align: center">จ้างหมอไม่สำเร็จ</span>',
                  icon: 'error',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  timer: 2000
                })
              })
          } else {
            if (this.mode === 'view') {
              router.push('/PostJob')
            } else {
              Vue.swal.fire({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่?</span>',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
                confirmButtonColor: '#089bab',
                cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
                cancelButtonColor: '#d33',
                customClass: {
                  confirmButton: 'custom-confirm-button-class mx-1',
                  cancelButton: 'custom-confirm-button-class mx-1'
                }
              }).then((result) => {
                if (result.isConfirmed) {
                  router.push('/PartTime')
                }
              })
            }
          }
        })
      } else {
        router.push('/ViewEmployDoctor/' + this.id)
      }
    },
    async checkError () {
      let errorList = []
      // const regex = /[ก-๙]/
      // const regexNumber = /^[0-9]+$/
      // const regex1 = /[A-Za-z]/
      if (this.postjobTemp.topic?.trim() === null || this.postjobTemp.topic?.trim() === '' || this.postjobTemp.topic?.trim() === undefined) {
        errorList.push('กรุณากรอกหัวข้อการทำงาน')
      }
      if (this.postjobTemp.start_date === null || this.postjobTemp.start_date === '' || this.postjobTemp.start_date === undefined) {
        errorList.push('กรุณาเลือกวันที่ต้องการแพทย์')
      }
      if (this.postjobTemp.work_range === null || this.postjobTemp.work_range === '' || this.postjobTemp.work_range === undefined) {
        errorList.push('กรุณากรอกเวลาทำงานต่อวัน')
      }
      if (this.postjobTemp.mi_id === null || this.postjobTemp.mi_id === '' || this.postjobTemp.mi_id === undefined) {
        errorList.push('กรุณาเลือกอัตราค่าจ้าง')
      }
      if (this.postjobTemp.is_receive === null || this.postjobTemp.is_receive === '' || this.postjobTemp.is_receive === undefined) {
        errorList.push('กรุณาเลือกประเภทงานที่ต้องการ')
      }
      if (this.postjobTemp.standart_work === null || this.postjobTemp.standart_work === '' || this.postjobTemp.standart_work === undefined) {
        errorList.push('กรุณาเลือกมาตรฐานยา')
      }
      return errorList
    },
    onPagination (page) {
      this.currentPage = page
    }
  },
  filters: {
    formatTime (value) {
      return moment(value, 'HH:mm').format('HH:mm')
    }
  }
}
</script>
