<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span class="unselectable" style="font-size: 25px; color: black;">คลังสินค้า</span>
              </div>
            </div>
          </template>

          <template v-slot:body>
            <div class="mx-3">
              <b-row>

                <b-col cols="12" md="6" lg="4" class="mb-3">
                  <div class="iq-email-search d-flex">
                    <div style="width: 100%;">
                      <div class="form-group mb-0" style="height: 45px;">
                        <input v-model="search" class="form-control" id="exampleInputEmail1" placeholder="Search"
                          style="width: 100%; height: 45px;" @keydown="enterSearch">
                        <a class="search-link" style="color: #024187; top: 4px" @click="filterProductWarehouseTable"><i
                            class="ri-search-line" /></a>
                      </div>
                    </div>
                  </div>
                </b-col>

                <b-col cols="auto" class="pb-3">
                  <div style="display: flex; align-items: center; height: 45px;">
                    <span class="mx-2 font-size-14 text-primary-custom">
                      {{ rows.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage *
                        currentPage) > rows?.length) ? rows.length : (perPage * currentPage)
                      ) + ' of ' + rows_count : '0 of ' + rows_count }} รายการ
                    </span>
                  </div>
                </b-col>

                <b-col cols="auto" class="pb-3" style="display: flex; align-items: center; justify-content: center;">
                  <b-form-checkbox size="lg" style="bottom: 1px; padding: 0; margin: 0 0 0 15px;" class="radio-disable"
                    name="redio-button-in" v-model="checkUMS" @input="filterProductWarehouseTable" />
                  <span class="unselectable" style="font-size: 16px; color: black;">Under Min Stock</span>
                </b-col>

              </b-row>
            </div>

            <b-col cols="12">
              <div class="table-responsive">
                <b-table bordered hover :items="rows" :fields="columns" :current-page="currentPage" :per-page="perPage"
                  @pagination="onPagination" :tbody-tr-class="rowClass" thead-class="table-header-color">

                  <template v-slot:cell(no)="data">
                    <span>{{ ((currentPage - 1) * perPage) + data.index + 1 }}</span>
                  </template>

                  <template v-slot:cell(product_code)="data">
                    <span>{{ data.item.prd_no || '-' }}</span>
                  </template>

                  <template v-slot:cell(name_med)="data">
                    <span>{{ data.item.prd_name || '-' }}</span>
                  </template>

                  <template v-slot:cell(compound)="data">
                    <span>{{ data.item.prd_compound || '-' }}</span>
                  </template>

                  <template v-slot:cell(trade_name)="data">
                    <span>{{ data.item.prd_brand_name || '-' }}</span>
                  </template>

                  <template v-slot:cell(barcode)="data">
                    <barcode :value="data.item.prd_barcode" format="EAN13" width="2" height="40">
                      {{ data.item.prd_barcode || '-' }}
                    </barcode>
                  </template>

                  <template v-slot:cell(min_stock)="data">
                    <span>{{ formatNumber(data.item.prd_min_stock || '-') }}</span>
                  </template>

                  <template v-slot:cell(quantity)="data">
                    <span>{{ formatNumber(data.item.sum_in_out || '-') }}</span>
                  </template>

                  <template v-slot:cell(unit)="data">
                    <span>{{ data.item.unit_name || '-' }}</span>
                  </template>

                  <template v-slot:cell(mgt)="data">
                    <b-button variant=" iq-bg-pencil-ware-custom btn-pencil-ware-border mx-1" size="sm"
                      @click="clickPath(data.item.prd_id)">
                      <i class="ri-edit-box-fill m-0"></i>
                    </b-button>
                  </template>

                </b-table>
              </div>
            </b-col>

            <b-pagination v-model="currentPage" :total-rows="rows?.length" :per-page="perPage" align="center" />

          </template>
        </iq-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<style scoped>
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import Axios from 'axios'
import moment from 'moment'
import { configAPI } from '../../configBase'
import VueBarcode from 'vue-barcode'

export default {
  name: 'ProductWarehouseTable',

  components: {
    'barcode': VueBarcode
  },

  data () {
    return {
      clnId: null,
      userId: null,
      search: null,
      checkUMS: false,
      currentPage: 1,
      perPage: 10,
      rows: [],
      rows_count: 0,
      columns: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        { label: 'รหัสสินค้า', key: 'product_code', class: 'text-center table-size-200-400 ellips' },
        { label: 'ชื่อยา', key: 'name_med', class: 'text-center table-size-200-400 ellips' },
        { label: 'สารประกอบ', key: 'compound', class: 'text-center table-size-200-400 ellips' },
        { label: 'ชื่อการค้า', key: 'trade_name', class: 'text-center table-size-200-400 ellips' },
        { label: 'บาร์โค้ด', key: 'barcode', class: 'text-center table-size-200-400' },
        { label: 'สต็อกขั้นต่ำ', key: 'min_stock', class: 'text-center table-size-200-400' },
        { label: 'จำนวน (หน่วย)', key: 'quantity', class: 'text-center table-size-200-400' },
        { label: 'หน่วยนับ', key: 'unit', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'mgt', class: 'text-center' }
      ]
    }
  },

  computed: {
  },

  watch: {
  },

  async mounted () {
    this.clnId = JSON.parse(sessionStorage.getItem('user')).cln_id || null
    this.userId = JSON.parse(sessionStorage.getItem('user')).usr_id || null
    await this.filterProductWarehouseTable()
    xray.index()
  },

  methods: {
    formatDate (date) {
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      } else {
        return '-'
      }
    },

    formatNumber (number) {
      if (number) {
        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } else {
        return '-'
      }
    },

    formatTel (tel) {
      if (tel) {
        let formatTel = tel.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        return formatTel
      } else {
        return ''
      }
    },

    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.no % 2 === 1) return 'table-body-color'
    },

    onPagination (page) {
      this.currentPage = page
    },

    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.filterProductWarehouseTable()
      }
    },

    clickPath (data) {
      router.push(`productWarehouseMgt/${data}`)
    },

    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },

    async filterProductWarehouseTable () {
      let dataFilter = {
        search: this.search?.toString().trim(),
        checkUMS: this.checkUMS
      }

      await Axios.request({
        method: 'POST',
        url: `${configAPI.MA_PORT}/api/warehouse/clinic/${this.clnId}/filterProductWarehouseTable`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataFilter
      }).then((res) => {
        this.rows = res.data.data
        this.rows_count = res.data.dataCount
      }).catch((err) => {
        this.SwalError(err.message, 'เกิดข้อผิดพลาดในดึงข้อมูล')
      })
    }
  }
}
</script>
