<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="table-responsive">
        <iq-card>
          <!-- Header Section -->
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 gap-response">
              <div class="ml-4">
                <span style="font-size: 25px; color: black;">{{mode === 'add' ? 'เพิ่มใบเสนอสินค้า' : mode ==='edit' ? 'ใบเสนอขายสินค้า/บริการ (Edit)' : 'ใบเสนอขายสินค้า/บริการ (View)'}}</span>
              </div>
              <div class="row button-response-header" style="display: flex; align-items: center;">
                <div class="flex-response-header-quo my-2 mx-2" style="white-space: nowrap;">
                  <div v-if="mode === 'add' || mode === 'edit'">
                    <b-button v-if="mode === 'add'" type="button" variant="primary" @click="saveQuotation" class="my-1 flex-fill text-center btn-add btn-min-size w-100" style="text-align: center;"><i class="save-data_ct"/>บันทึก</b-button>
                    <b-button v-else-if="mode === 'edit'" type="button" variant="primary" @click="editQuotation" class="my-1 flex-fill text-center btn-add btn-min-size w-100" style="text-align: center;"><i class="save-data_ct"/>บันทึก</b-button>
                  </div>
                  <div>
                    <b-button type="button" variant="danger" @click="handleClickBack" class="my-1 flex-fill btn-min-size btn-cancel-border w-100" style="text-align: center;">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
              <!-- <div v-else class="row button-response-header" style="display: flex; align-items: center;">
                <div class="flex-response-header my-2 mx-2" style="white-space: nowrap;">
                  <div class="response-1button-header">
                    <b-button type="button" variant="danger" @click="handleClickBack" class="my-1 mx-1 w-100 btn-min-size btn-cancel-border">ยกเลิก</b-button>
                  </div>
                </div>
              </div> -->
            </div>
          </template>
          <!-- Body Section -->
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col lg="3" md="4" sm="12">
                  <b-form-group label="หมายเลขใบเสนอราคา" label-for="quo_no">
                    <template v-if="QuoDetail.quo_no === null" v-slot:label>
                      หมายเลขใบเสนอราคา<!-- <span style="color: red;"> *</span> -->
                    </template>
                    <b-form-input v-if="mode === 'add'" placeholder="Gen Quotation No." id="quo_no" :disabled="true"></b-form-input>
                    <b-form-input v-else id="quo_no" v-model="QuoDetail.quo_no" :readonly="true"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="4" sm="6">
                  <b-form-group label-for="datepicker-custom1" label="วันที่เสนอราคา">
                    <template v-if="true" v-slot:label><!-- QuoDetail.quo_date === null || QuoDetail.quo_date?.toString().trim() === '' -->
                      วันที่เสนอราคา<span style="color: red;"> *</span>
                    </template>
                    <div style="position: relative;">
                      <date-picker id="datepicker-custom1" :config="options" v-model="QuoDetail.quo_date" :readonly="mode !== 'add'"/>
                      <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(1)"
                        :disabled="isDisable" style="cursor: pointer;" />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="4" sm="6">
                  <b-form-group label-for="datepicker-custom2" label="วันที่ยืนราคา">
                    <template v-if="true" v-slot:label><!-- QuoDetail.quo_confirm_price === null || QuoDetail.quo_confirm_price?.toString().trim() === '' -->
                      วันที่ยืนราคา<span style="color: red;"> *</span>
                    </template>
                    <div style="position: relative;">
                      <date-picker id="datepicker-custom2" :config="options2" v-model="QuoDetail.quo_confirm_price" :readonly="mode !== 'add'"/>
                      <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(2)"
                        :disabled="isDisable" style="cursor: pointer;" />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- Customer Detail Section -->
              <span style="font-size: 25px; color: rgb(37, 37, 37);">รายละเอียดลูกค้า</span>
              <b-row>
                <b-col lg="4" md="6" sm="12">
                  <b-form-group label="เบอร์โทรศัพท์" label-for="cus_tel">
                    <template v-if="true" v-slot:label><!-- searchTel === null || searchTel?.toString().trim() === '' -->
                      เบอร์โทรศัพท์<span style="color: red;"> *</span>
                    </template>
                    <div style="position: relative;">
                      <the-mask mask="###-###-####" class="form-control" v-model="searchTel" id="cus_tel" placeholder="กรอกเบอร์โทรศัพท์" :readonly="mode !== 'add'"></the-mask>
                      <a @click="searchCustomerDetailfromNumber" class="search-link defind-search-icon-cusname" v-if="mode === 'add'"><i style="font-size: 1.5rem;" class="ri-search-line" /></a>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="4" sm="12">
                  <b-form-group class="unselectable" label="ชื่อ-นามสกุล" label-for="cus_name" style="color: #3f414d;">
                    <template v-if="QuoDetail.quo_cus_name === null || QuoDetail.quo_cus_name?.toString().trim() === ''" v-slot:label>
                      ชื่อ-นามสกุล<!-- <span style="color: red;"> *</span> -->
                    </template>
                    <b-form-input id="cus_name" type="text" v-model="QuoDetail.quo_cus_name" :readonly="true"></b-form-input> <!-- placeholder="ชื่อ-นามสกุล" -->
                  </b-form-group>
                </b-col>
                <b-col lg="2" md="2" sm="12">
                  <b-form-group class="unselectable" label="ชื่อเล่น" label-for="cus_nickname" style="color: #3f414d;">
                    <template v-if="QuoDetail.quo_cus_nickname === null || QuoDetail.quo_cus_nickname?.toString().trim() === ''" v-slot:label>
                      ชื่อเล่น<!-- <span style="color: red;"> *</span> -->
                    </template>
                    <b-form-input id="cus_nickname" type="text" v-model="QuoDetail.quo_cus_nickname" :readonly="true"></b-form-input> <!-- placeholder="ชื่อเล่น" -->
                  </b-form-group>
                </b-col>
                <b-col lg="2" md="2" sm="12" v-if="mode === 'add'">
                  <b-form-group class="unselectable" label="เพศ" label-for="cus_gender" style="color: #3f414d;">
                    <template v-if="QuoDetail.quo_cus_gender === null || QuoDetail.quo_cus_gender?.toString().trim() === ''" v-slot:label>
                      เพศ<!-- <span style="color: red;"> *</span> -->
                    </template>
                    <b-form-input id="cus_gender" type="text" v-model="QuoDetail.quo_cus_gender" :readonly="true"></b-form-input><!-- placeholder="เพศ" -->
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="4" sm="12" v-if="mode === 'add'">
                  <b-form-group label-for="datepicker-custom3" label="วันเดือนปีเกิด">
                    <template v-if="QuoDetail.quo_cus_birth_day === null || QuoDetail.quo_cus_birth_day?.toString().trim() === ''" v-slot:label>
                      วันเดือนปีเกิด<!-- <span style="color: red;"> *</span> -->
                    </template>
                    <div style="position: relative;">
                      <date-picker id="datepicker-custom3" :config="options3" v-model="QuoDetail.quo_cus_birth_day" :readonly="true"/>
                      <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(3)"
                        :disabled="isDisable" style="cursor: pointer;" />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="mode === 'add'">
                <b-col lg="3" md="4" sm="12">
                  <b-form-group label="เลขบัตรประชาชน" label-for="cus_citizen_id">
                    <template v-if="QuoDetail.quo_cus_citizen_id === null || QuoDetail.quo_cus_citizen_id?.toString().trim() === ''" v-slot:label>
                      เลขบัตรประชาชน<!-- <span style="color: red;"> *</span> -->
                    </template>
                    <the-mask ref="refCitizen" mask="#-####-#####-##-#" class="form-control" v-model="QuoDetail.quo_cus_citizen_id" id="cus_citizen_id" :readonly="true"></the-mask> <!-- placeholder="กรอกเลขบัตรประชาชน" -->
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="4" sm="12">
                  <b-form-group label="ข้อมูล HN" label-for="cus_hn_no">
                    <template v-if="QuoDetail.quo_hn === null || QuoDetail.quo_hn?.toString().trim() === ''" v-slot:label>
                      ข้อมูล HN<!-- <span style="color: red;"> *</span> -->
                    </template>
                    <b-form-input id="cus_hn_no" v-model="QuoDetail.quo_hn" :readonly="true"></b-form-input> <!-- placeholder="ข้อมูล HN" -->
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- Product -->
              <span style="font-size: 25px; color: rgb(37, 37, 37);">ผลิตภัณฑ์</span>
              <br />
              <span style="font-size: 25px; color: rgb(37, 37, 37);">รวม : <span style="color: #00FF29">{{ QuoDetail.quo_total_price }}</span> บาท</span>
              <b-row>
                <b-col cols="12">
                  <div class="fx-gp-quo d-flex my-3" style="white-space: nowrap; gap: .5rem">
                    <b-button v-if="mode !== 'view'" @click="showModalProduct = true" variant="primary" class="max-width-btn-quo-mgt flex-fill text-center btn-min-size" style="text-align: center;"><i class="circle-plus_ct"/>เพิ่มสินค้า</b-button>
                    <b-button v-else class="max-width-btn-quo-mgt flex-fill text-center btn-min-size" style="text-align: center;" disabled><i class="circle-plus_ct"/>เพิ่มสินค้า</b-button>

                    <b-button v-if="mode !== 'view'" @click="showModalService = true" :disabled="isDisable" variant=" bg-success-custom" class="max-width-btn-quo-mgt flex-fill text-center btn-min-size" style="text-align: center;"><i class="circle-plus_ct"/>เพิ่มบริการ</b-button>
                    <b-button v-else class="max-width-btn-quo-mgt flex-fill text-center btn-min-size" style="text-align: center;" disabled><i class="circle-plus_ct"/>เพิ่มบริการ</b-button>

                    <b-form-checkbox  v-model="QuoDetail.quo_is_incl_vat" name="checkbox-1" size="lg" class="checkbox-quo checkbox-quotation-mgt" :disabled="isDisable">ราคารวม Vat(7%)</b-form-checkbox>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="table-responsive">
                    <b-table bordered hover :items="rowSelected.data" :fields="columnsSelected"
                      :current-page="currentPageSelected"
                      :per-page="perPageSelected"
                      :tbody-tr-class="''" thead-class="table-header-color"
                    >
                      <template v-for="(column) in columnsSelected" v-slot:[`cell(${column.key})`]="data">
                        <template v-if="column.key === 'no'">
                          <span :key="column.key">{{ ((currentPageSelected - 1) * perPageSelected) + data.index + 1  }}</span>
                        </template>
                        <template v-if="column.key === 'type_proser'">
                          <span :key="column.key">{{ data.item.type || '-' }}</span>
                        </template>
                        <template v-if="column.key === 'code_proser'">
                          <span :key="column.key">{{ data.item.no || '-' }}</span>
                        </template>
                        <template v-if="column.key === 'name_proser'">
                          <span :key="column.key">{{ data.item.name || data.item.st_name || '-' }}</span>
                        </template>
                        <template v-if="column.key === 'quantity'">
                          <CurrencyInputCom v-model="data.item.quantity" :options="optionCurrency" @change="calculateRow(data.item)" :key="data.item.type + column.key + data.index" :disabled="isDisable"/>
                        </template>
                        <template v-if="column.key === 'lp'">
                          <CurrencyInputCom v-model="data.item.lp" :options="optionCurrency" @change="calculateRow(data.item)" :key="data.item.type + column.key + data.index" :disabled="isDisable"/>
                        </template>
                        <template v-if="column.key === 'discount'">
                          <CurrencyInputCom v-model="data.item.discount" :options="optionCurrency" :isLimit="true" :valueLimit="(data.item.lp || 0) * (data.item.quantity || 0)" @change="calculateRow(data.item)" :key="data.item.type + column.key + data.index" :ref="data.item.id?.replace(/-/g, '')" :disabled="isDisable"/>
                        </template>
                        <template v-if="column.key === 'total_price'">
                          <span :key="column.key">{{ formatNumber(data.item.row_price) }}</span>
                        </template>
                        <template v-if="column.key === 'mgt'">
                          <div style="display: flex; justify-content: center;" :key="column.key">
                            <b-button @click="deleteRow(data.item)" variant=" iq-bg-bin-custom btn-bin-border" :disabled="isDisable" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                          </div>
                        </template>
                      </template>
                    </b-table>
                  </div>
                </b-col>
              </b-row>
              <b-row style="display: flex; justify-content: center;">
                <b-pagination
                  v-model="currentPageSelected"
                  :total-rows="rowSelected.data?.length"
                  :per-page="perPageSelected"
                  align="center"
                />
              </b-row>
              <b-row>
                <b-col lg="4" md="6" sm="12">
                  <b-form-group label="ข้อคิดเห็น" label-for="remark">
                    <b-textarea id="remark" :disabled="isDisable" v-model="QuoDetail.quo_cus_remark" ></b-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <span style="font-size: 25px; color: rgb(37, 37, 37); text-decoration:underline">วันที่อนุมัติ : {{QuoDetail.quo_approve_date}}</span>

              <b-modal v-model="showModalProduct" title="เลือกสินค้า" cancel-title="ยกเลิก" ok-title="เพิ่ม" @ok="onSubmitProductModal" @close="onCloseProductModal" size="xl" :no-close-on-esc="true" :no-close-on-backdrop="true">
                <productSelectModalQuo ref="productSelectedCom" :mainProductSelected="rowSelected.data" :del-list="JSON.parse(JSON.stringify(delList))"/>
                <template #modal-footer>
                  <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                    <b-button
                      style="border-radius: 0.5rem; padding: 0 1.1rem !important;"
                      variant="primary"
                      class="btn-min-size"
                      size="sm"
                      @click="onSubmitProductModal"
                    >
                    <i class="save-data_ct"/>บันทึก
                    </b-button>
                    <b-button
                      variant="danger"
                      class="btn-min-size btn-cancel-border"
                      style="border-radius: 0.5rem;"
                      size="sm"
                      @click="showModalProduct=false"
                    >
                      ยกเลิก
                    </b-button>
                  </div>
                </template>
              </b-modal>

              <b-modal v-model="showModalService" title="เลือกบริการ" cancel-title="ยกเลิก" ok-title="เพิ่ม" @ok="onSubmitServiceModal" @close="onCloseServiceModal" size="xl" :no-close-on-esc="true" :no-close-on-backdrop="true">
                <serviceSelectModalQuo ref="serviceSelectedCom" :mainServiceSelected="rowSelected.data" :del-list="JSON.parse(JSON.stringify(delList))"/>
                <template #modal-footer>
                  <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                    <b-button
                      style="border-radius: 0.5rem; padding: 0 1.1rem !important;"
                      variant="primary"
                      class="btn-min-size"
                      size="sm"
                      @click="onSubmitServiceModal"
                    >
                    <i class="save-data_ct"/>บันทึก
                    </b-button>
                    <b-button
                      variant="danger"
                      class="btn-min-size btn-cancel-border"
                      style="border-radius: 0.5rem;"
                      size="sm"
                      @click="showModalService=false"
                    >
                      ยกเลิก
                    </b-button>
                  </div>
                </template>
              </b-modal>
            </div>
            <b-row>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped>
.checkbox-quotation-mgt > .custom-control-input:checked~.custom-control-label::before {
  border-color: #0DC268 !important;
  background-color: #0DC268 !important;
}
.defind-search-icon-cusname {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 0.5rem;
  color: #089bab !important;
}

.defind-search-icon-cusname:hover {
  color: #4e5051 !important;
}
[dir=ltr] .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #A3A3A3 !important;
  border-color: #A3A3A3 !important;
}

.flex-response-header-quo {
  display: flex;
  gap: .5rem
}

.max-width-btn-quo-mgt {
  max-width: 200px;
}

.checkbox-quo {
  padding: 0;
  margin-left: 1rem;
}
@media (max-width: 376px) {
  .flex-response-header-quo {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0;
  }
  .max-width-btn-quo-mgt {
    max-width: 376px;
    width: 100%;
  }

  .fx-gp-quo {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .checkbox-quo {
    margin-left: 0;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import moment from 'moment'
import { mask } from 'vue-the-mask'
import { configAPI } from '../../configBase'
import productSelectModalQuo from './component/productSelectModalQuo.vue'
import serviceSelectModalQuo from './component/serviceSelectModalQuo.vue'
import CurrencyInputCom from './CurrencyInputCom.vue'
import Axios from 'axios'

const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}

export default {
  name: 'QuotationMGT',
  components: {
    productSelectModalQuo,
    serviceSelectModalQuo,
    CurrencyInputCom
  },
  directives: { mask },
  data () {
    return {
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      usrId: JSON.parse(sessionStorage.getItem('user')).usr_id || null,
      mode: 'add',
      showModalProduct: false,
      showModalService: false,
      QuoDetail: {
        quo_no: null,
        quo_date: null,
        quo_confirm_price: null,
        quo_cus_contact_tel: null,
        quo_cus_id: null,
        quo_cus_name: null,
        quo_cus_nickname: null,
        quo_cus_gender: null,
        quo_cus_birth_day: null,
        quo_cus_citizen_id: null,
        quo_hn: null,
        quo_total_price: null,
        quo_approve_date: null,
        quo_is_incl_vat: true,
        quo_cus_remark: null
      },
      searchTel: null,
      rowSelected: {
        data: []
      },
      productSelect: [],
      serviceSelect: [],
      delList: [],
      columnsSelected: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        { label: 'ประเภท', key: 'type_proser', class: 'text-center table-size-200-400' },
        { label: 'รหัสสินค้า/บริการ', key: 'code_proser', class: 'text-center table-size-200-400 ellips' },
        { label: 'ชื่อสินค้า/บริการ', key: 'name_proser', class: 'text-center table-size-200-400 ellips' },
        { label: 'จำนวน', key: 'quantity', class: 'text-center table-size-200-400' },
        { label: 'ราคา', key: 'lp', class: 'text-center table-size-200-400' },
        { label: 'ส่วนลด', key: 'discount', class: 'text-center table-size-200-400' },
        { label: 'ราคารวม', key: 'total_price', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'mgt', class: 'text-center my-column' }
      ],
      currentPageSelected: 1,
      perPageSelected: 10,
      isDisable: false,
      options: {
        // https://momentjs.com/docs/#/displaying/
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: false,
        showClose: true,
        useStrict: true,
        minDate: false,
        // stepping: 1,
        icons: iconDate,
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'auto'
        }
      },
      options2: {
        // https://momentjs.com/docs/#/displaying/
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: false,
        showClose: true,
        useStrict: true,
        minDate: false,
        // stepping: 1,
        icons: iconDate,
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'auto'
        }
      },
      options3: {
        // https://momentjs.com/docs/#/displaying/
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: false,
        showClose: true,
        useStrict: true,
        minDate: false,
        maxDate: moment(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
        // stepping: 1,
        icons: iconDate,
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'auto'
        }
      },
      optionCurrency: {
        currency: 'EUR',
        currencyDisplay: 'hidden',
        valueRange: {
          min: 0,
          max: 1e+53
        },
        precision: 0,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: true,
        accountingSign: false
      },
      refCitizen: null
    }
  },
  async mounted () {
    // await this.getcustomerforDD()
    if (this.$route.params.setPage === 'edit') {
      this.mode = 'edit'
      this.isDisable = false
      await this.getQuotationById()
    } else if (this.$route.params.setPage === 'view') {
      this.mode = 'view'
      this.isDisable = true
      await this.getQuotationById()
    } else {
      this.mode = 'add'
      this.isDisable = false
      this.options.minDate = moment(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate()
      this.options2.minDate = moment(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate()
      this.QuoDetail.quo_date = moment(new Date())
      setTimeout(() => {
        this.QuoDetail.quo_confirm_price = moment(new Date()).add('minute', 1)
      }, [200])
    }
    xray.index()
  },
  watch: {
    'QuoDetail.quo_total_price': {
      handler: function (val, oldVal) {
        this.QuoDetail.quo_total_price = this.formatNumber(val)
      },
      deep: true
    },
    'rowSelected.data': {
      handler: function (val, oldVal) {
        this.calculateTotal()
      },
      deep: true
    },
    'QuoDetail.quo_date': {
      handler: function (val, oldVal) {
        if (val !== null && oldVal !== null) {
          this.QuoDetail.quo_confirm_price = null
          this.options2.minDate = new Date(moment(val, 'DD/MM/YYYY'))
        } else if (val !== '' && oldVal === '') {
          this.options2.minDate = new Date(moment(val, 'DD/MM/YYYY'))
        }
      },
      deep: true
    },
    'QuoDetail.quo_is_incl_vat': {
      handler: function (val, oldVal) {
        this.calculateTotal()
      },
      deep: true
    }
  },
  methods: {
    rowClass (item, type) {
      if (!item || type !== 'row') return
      const index = this.rowSelected.data.findIndex(el => el.id === item.id)
      if (index % 2 === 1) return 'table-body-color'
    },
    formatNumber (value) {
      if (value === null || value === undefined) {
        return ''
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getFullImagePath (relativePath) {
      if (relativePath !== null && relativePath !== '') {
        let path = relativePath !== null ? relativePath.slice(1) : ''
        const baseURL = configAPI.MA_PORT + path
        return baseURL
      } else {
        return 'https://www.w3schools.com/howto/img_avatar.png'
      }
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    onClickDatePicker (number) {
      const datepicker = document.getElementById(`datepicker-custom${number}`)
      datepicker.focus()
    },
    handleClickBack () {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.$route.fullPath.includes('soAdmin')) {
            router.push('/SaleOrderApproveEdit')
          } else if (this.$route.fullPath.includes('so')) {
            router.push('/SaleOrderTable')
          } else {
            router.push('/QuotationTable')
          }
        }
      })
    },
    async searchCustomerDetailfromNumber () {
      if (this.searchTel === null || this.searchTel?.length < 10) {
        Vue.swal.fire({
          title: `<span class="" style="font-size: 25px; color: black; text-align: center">กรุณากรอกเบอร์โทรศัพท์ให้ครบถ้วน</span>`,
          icon: 'warning',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1'
          }
        })
      } else {
        await Axios.request({
          method: 'get',
          url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/getcustomerfromNumber/${this.searchTel}`,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((res) => {
          if (res.data.data.length > 0) {
            this.$refs.refCitizen.$el.value = res.data.data[0].cus_citizen_id || ''
            this.QuoDetail.quo_cus_id = res.data.data[0].cus_id || null
            this.QuoDetail.quo_cus_name = (res.data.data[0].cus_firstname + ' ' + res.data.data[0].cus_lastname) || null
            this.QuoDetail.quo_cus_nickname = res.data.data[0].cus_nickname || null
            this.QuoDetail.quo_cus_gender = res.data.data[0].cus_gender || null
            this.QuoDetail.quo_cus_birth_day = res.data.data[0].cus_birth_day !== null ? moment(res.data.data[0].cus_birth_day).format('DD/MM/YYYY') : null
            this.QuoDetail.quo_cus_citizen_id = res.data.data[0].cus_citizen_id || null
            this.QuoDetail.quo_hn = res.data.data[0].cus_hn || null
            this.QuoDetail.quo_cus_contact_tel = res.data.data[0].cus_tel || null
          } else {
            this.$refs.refCitizen.$el.value = ''
            this.QuoDetail.quo_cus_id = null
            this.QuoDetail.quo_cus_name = null
            this.QuoDetail.quo_cus_nickname = null
            this.QuoDetail.quo_cus_gender = null
            this.QuoDetail.quo_cus_birth_day = null
            this.QuoDetail.quo_cus_citizen_id = null
            this.QuoDetail.quo_hn = null
            this.QuoDetail.quo_cus_contact_tel = null
          }
        }).catch((err) => {
          this.SwalError(err, 'Error')
        })
      }
    },
    calculateTotal () {
      let total = 0
      let totalProduct = 0
      let totalService = 0
      let product = []
      let service = []
      for (let item of this.rowSelected.data) {
        if (parseFloat((((item.lp || 0) * (item.quantity || 0)) - parseFloat(item.discount?.toString().replace(/,/g, '') || 0)).toFixed(2)) < 0) {
          this.$refs[item.id?.replace(/-/g, '')][0].formattedValue = 0
          item.discount = 0
        }
        if (item.type === 'Product') {
          item.row_price = parseFloat(((item.lp || 0) * (item.quantity || 0) - (parseFloat(item.discount?.toString().replace(/,/g, '')) || 0)).toFixed(2))
          totalProduct += parseFloat(item.row_price || 0)
          product.push(item)
        } else {
          item.row_price = parseFloat(((item.lp || 0) * (item.quantity || 0) - (parseFloat(item.discount?.toString().replace(/,/g, '')) || 0)).toFixed(2))
          totalService += parseFloat(item.row_price || 0)
          service.push(item)
        }
      }
      if (this.QuoDetail.quo_is_incl_vat) {
        totalProduct = parseFloat(totalProduct || 0) * 1.07
      }
      total = parseFloat((totalProduct + totalService))
      this.productSelect = product
      this.serviceSelect = service
      total = parseFloat(total.toFixed(2))
      this.QuoDetail.quo_total_price = total
    },
    calculateRow (item) {
      if (item.qp_id) {
        item.status = 'update'
      } else {
        item.status = 'add'
      }
      const _index = this.rowSelected.data.findIndex(el => el.id === item.id)
      if (_index >= 0) {
        this.rowSelected.data[_index] = item
      }
      this.calculateTotal()
    },
    onSubmitProductModal () {
      let Old = JSON.parse(JSON.stringify(this.productSelect))
      let [New, Del] = this.$refs.productSelectedCom.submitAction()
      this.productSelect = []
      this.rowSelected.data = []
      for (let item of New) {
        const _indexDel = Del.findIndex(el => el?.id === item?.id)
        if (_indexDel !== -1) {
          item = Del[_indexDel]
          Del.splice(_indexDel, 1)
        }
        let index = Old.findIndex(el => el.id === item.id)
        if (index < 0) {
          this.productSelect.push(item)
        } else {
          this.productSelect.push(Old[index])
        }
      }
      this.delList = Del
      this.rowSelected.data = [...this.productSelect, ...this.serviceSelect]
      this.showModalProduct = false
    },
    onCloseProductModal () {
      this.showModalProduct = false
    },
    onSubmitServiceModal () {
      let [New, Del] = this.$refs.serviceSelectedCom.submitAction()
      let Old = this.serviceSelect
      this.serviceSelect = []
      this.rowSelected.data = []
      for (let item of New) {
        const _indexDel = Del.findIndex(el => el?.id === item?.id)
        if (_indexDel !== -1) {
          item = Del[_indexDel]
          Del.splice(_indexDel, 1)
        }
        let index = Old.findIndex(el => el.id === item.id)
        if (index < 0) {
          this.serviceSelect.push(item)
        } else {
          this.serviceSelect.push(Old[index])
        }
      }
      this.delList = Del
      this.rowSelected.data = [...this.productSelect, ...this.serviceSelect]
      this.showModalService = false
    },
    onCloseServiceModal () {
      this.showModalService = false
    },
    deleteRow (item) {
      let temp = this.rowSelected.data
      let tempProduct = this.productSelect
      let tempService = this.serviceSelect
      let index = temp.findIndex(el => el.id === item.id)
      let indexProduct = tempProduct.findIndex(el => el.id === item.id)
      let indexService = tempService.findIndex(el => el.id === item.id)
      if (indexProduct >= 0) {
        tempProduct.splice(indexProduct, 1)
      }
      if (indexService >= 0) {
        tempService.splice(indexService, 1)
      }
      if (index >= 0) {
        if (temp[index].qp_id && this.delList.find(el => el.qp_id === temp[index].qp_id) === undefined) {
          this.delList.push(temp[index])
        }
        temp.splice(index, 1)
      }
      this.rowSelected.data = temp
      this.calculateTotal()
    },
    async checkSave () {
      let errList = []
      if (this.QuoDetail.quo_date === null || this.QuoDetail.quo_date?.toString().trim() === '') {
        errList.push('กรุณาเลือก วันที่เสนอราคา')
      }
      if (this.QuoDetail.quo_confirm_price === null || this.QuoDetail.quo_confirm_price?.toString().trim() === '') {
        errList.push('กรุณาเลือก วันที่ยืนราคา')
      }
      if (this.QuoDetail.quo_cus_name === null || this.QuoDetail.quo_cus_name?.toString().trim() === '') {
        errList.push('กรุณา ค้นหาลูกค้า')
      }
      if (this.rowSelected.data.length <= 0) {
        errList.push('กรุณาเลือกสินค้า/บริการ อย่างน้อย 1 รายการ')
      } else {
        // filter สินค้าที่ไม่มีจำนวน หรือ ราคา
        let temp = this.rowSelected.data.filter((item) => {
          return item.quantity === null || item.quantity === '' || item.quantity === 0 || item.quantity === '0' || item.lp === null || item.lp === '' || item.lp === 0 || item.lp === '0'
        })
        if (temp.length > 0) {
          errList.push('</hr>')
          errList.push(`สินค้า/บริการที่ข้อมูลไม่ครบถ้วน`)
          for (let item of temp) {
            errList.push(`${item.type} : <span class="ellipsModal">${item.name || item.st_name}</span>`)
          }
        }
      }
      return errList
    },
    saveQuotation () {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะบันทึกใช่หรือไม่</span>`,
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let errorList = await this.checkSave()
          if (errorList.length > 0) {
            let message = ''
            errorList.forEach((item) => {
              message += `${item} <br>`
            })
            this.SwalError(message, 'กรุณากรอกข้อมลให้ครบถ้วน')
          } else {
            let dataforsave = {
              quo_no: this.QuoDetail.quo_no || null,
              quo_date: this.QuoDetail.quo_date ? new Date(moment(this.QuoDetail.quo_date, 'DD/MM/YYYY')) : null,
              quo_confirm_price: this.QuoDetail.quo_confirm_price ? new Date(moment(this.QuoDetail.quo_confirm_price, 'DD/MM/YYYY')) : null,
              quo_cus_id: this.QuoDetail.quo_cus_id || null,
              quo_cus_name: this.QuoDetail.quo_cus_name || null,
              quo_cus_contact_tel: this.QuoDetail.quo_cus_contact_tel || null,
              quo_cus_nickname: this.QuoDetail.quo_cus_nickname || null,
              quo_cus_gender: this.QuoDetail.quo_cus_gender || null,
              quo_cus_birth_day: this.QuoDetail.quo_cus_birth_day ? new Date(moment(this.QuoDetail.quo_cus_birth_day, 'DD/MM/YYYY')) : null,
              quo_hn: this.QuoDetail.quo_hn || null,
              quo_cus_citizen_id: this.QuoDetail.quo_cus_citizen_id || null,
              quo_cus_remark: this.QuoDetail.quo_cus_remark || null,
              // quo_discount: this.QuoDetail.quo_discount || null,
              quo_total_price: this.QuoDetail.quo_total_price || null,
              quo_is_incl_vat: this.QuoDetail.quo_is_incl_vat,
              usrId: this.usrId,
              clnId: this.clnId,
              quo_pro_ser: this.rowSelected.data
            }
            await Axios.request({
              method: 'POST',
              url: `${configAPI.SALE_PORT}/api/clinic/${this.clnId}/saveQuotation`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: dataforsave
            }).then((res) => {
              Vue.swal.fire({
                title: `<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกสำเร็จ</span>`,
                // html: '<span>ส่งอนุมัติสำเร็จ</span>',
                icon: 'success',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 1500
              }).then(() => {
                router.push('/QuotationTable')
              })
            }).catch((err) => {
              this.SwalError(err, 'Error')
            })
          }
        }
      })
    },
    async getQuotationById () {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.SALE_PORT}/api/clinic/${this.clnId}/quotation/${this.$route.params.id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        let tempData = res.data.data[0]
        this.QuoDetail = {
          quo_no: tempData?.quo_no || null,
          quo_date: tempData?.quo_date ? moment(tempData?.quo_date).format('DD/MM/YYYY') : '',
          quo_confirm_price: tempData?.quo_confirm_price ? moment(tempData?.quo_confirm_price).format('DD/MM/YYYY') : '',
          quo_cus_contact_tel: tempData?.quo_cus_contact_tel || null,
          quo_cus_name: tempData?.quo_cus_name || null,
          quo_cus_nickname: tempData?.quo_cus_nickname || null,
          quo_cus_gender: tempData?.quo_cus_gender || null,
          quo_cus_birth_day: tempData?.quo_cus_birth_day ? moment(tempData?.quo_cus_birth_day).format('DD/MM/YYYY') : '',
          quo_cus_citizen_id: tempData?.quo_cus_citizen_id || null,
          quo_hn: tempData?.quo_hn || null,
          quo_cus_remark: tempData?.quo_cus_remark || null,
          // quo_discount: tempData?.quo_discount || null,
          quo_total_price: tempData?.quo_total_price || null,
          quo_approve_date: tempData?.quo_approve_date ? moment(tempData?.quo_approve_date).format('DD/MM/YYYY') : '',
          quo_is_incl_vat: tempData?.quo_is_incl_vat
        }
        this.searchTel = tempData?.quo_cus_contact_tel || null
        this.options.minDate = new Date(moment(this.QuoDetail.quo_date, 'DD/MM/YYYY'))
        if (tempData.pro_ser?.length > 0) {
          for (let item of tempData?.pro_ser) {
            if (item.qp_prd_id) {
              item.id = item.qp_prd_id
              item.type = 'Product'
              item.is_select = true
              item.quantity = item.qp_amount
              item.lp = item.qp_price
              item.discount = item.qp_discount
              item.row_price = item.qp_total_price
              this.productSelect.push(item)
            } else if (item.qp_ser_id) {
              item.id = item.qp_ser_id
              item.type = 'Service'
              item.is_select = true
              item.quantity = item.qp_amount
              item.lp = item.qp_price
              item.discount = item.qp_discount
              item.row_price = item.qp_total_price
              this.serviceSelect.push(item)
            }
          }
        }
        this.rowSelected.data = tempData?.pro_ser
      }).catch((err) => {
        this.SwalError(err, 'Error')
      })
    },
    async editQuotation () {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะแก้ไขใช่หรือไม่</span>`,
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let errorList = await this.checkSave()
          if (errorList.length > 0) {
            let message = ''
            errorList.forEach((item) => {
              message += `${item} <br>`
            })
            this.SwalError(message, 'กรุณากรอกข้อมลให้ครบถ้วน')
          } else {
            let dataforEdit = {
              quo_no: this.QuoDetail.quo_no || null,
              quo_date: this.QuoDetail.quo_date ? new Date(moment(this.QuoDetail.quo_date, 'DD/MM/YYYY')) : null,
              quo_confirm_price: this.QuoDetail.quo_confirm_price ? new Date(moment(this.QuoDetail.quo_confirm_price, 'DD/MM/YYYY')) : null,
              quo_cus_id: this.QuoDetail.quo_cus_id || null,
              quo_cus_name: this.QuoDetail.quo_cus_name || null,
              quo_cus_contact_tel: this.QuoDetail.quo_cus_contact_tel || null,
              quo_cus_nickname: this.QuoDetail.quo_cus_nickname || null,
              quo_cus_gender: this.QuoDetail.quo_cus_gender || null,
              quo_cus_birth_day: this.QuoDetail.quo_cus_birth_day ? new Date(moment(this.QuoDetail.quo_cus_birth_day, 'DD/MM/YYYY')) : null,
              quo_cus_citizen_id: this.QuoDetail.quo_cus_citizen_id || null,
              quo_hn: this.QuoDetail.quo_hn || null,
              quo_cus_remark: this.QuoDetail.quo_cus_remark || null,
              // quo_discount: this.QuoDetail.quo_discount,
              quo_total_price: this.QuoDetail.quo_total_price?.toString().replace(/,/g, '') || null,
              usrId: this.usrId,
              clnId: this.clnId,
              quo_pro_ser: this.rowSelected.data,
              delList: this.delList
            }
            await Axios.request({
              method: 'POST',
              url: `${configAPI.SALE_PORT}/api/clinic/${this.clnId}/editQuotation/${this.$route.params.id}`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: dataforEdit
            }).then(async (res) => {
              Vue.swal.fire({
                title: `<span class="" style="font-size: 25px; color: black; text-align: center">แก้ไขสำเร็จ</span>`,
                icon: 'success',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 1500
              }).then(() => {
                // window.location.reload()
                router.push('/QuotationTable')
              })
            }).catch((err) => {
              this.SwalError(err, 'Error')
            })
          }
        }
      })
    }
  }
}
</script>
