<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4">
              <div class="col">
                <span class="px-3" style="font-size: 25px; color: black;">สรุปรายได้ และค่าใช้จ่าย</span>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="px-3">
              <b-row class="w-200">
                <b-col cols="6" md="auto" lg="2"  class="my-1 my-sm-0">
                  <b-text style="color: black; white-space: nowrap">วันที่เริ่มต้น</b-text>
                  <div style="position: relative; margin-top: .4rem;" class="w-res-attendance time-picker-attendance">
                    <date-picker placeholder="DD/MM/YYYY" id="datepicker-custom1" :disabled="isDisable" name="date"
                      v-model="filter.start_date" :config="startDate"></date-picker>
                    <i class="ri-calendar-line m-0 icon-calendar-edit" :disabled="isDisable" @click="onClickDatePicker(1)"
                      style="cursor: pointer;"></i>
                  </div>
                </b-col>

                <b-col cols="6" md="auto" lg="2"  class="my-1 my-sm-0">
                  <b-text style="color: black; white-space: nowrap">วันที่สิ้นสุด</b-text>
                  <div style="position: relative; margin-top: .4rem;" class="w-res-attendance time-picker-attendance">
                    <date-picker placeholder="DD/MM/YYYY" id="datepicker-custom2"
                      :disabled="isDisable/*  ? true : !filter.start_date ? true : false */" name="date"
                      v-model="filter.end_date" :config="endDate"></date-picker>
                    <i class="ri-calendar-line m-0 icon-calendar-edit"
                      :disabled="isDisable/*  ? true : !filter.start_date ? true : false */" @click="onClickDatePicker(2)"
                      style="cursor: pointer;"></i>
                  </div>
                </b-col>

                <b-col cols="12" md="auto" lg="3"  class="my-1 my-sm-0">
                  <b-form-group label-for="exampleFormControlSelect1" label="ผู้สร้าง">
                    <v-select v-model="filter.createdId" :options="createdByDD" class="selectColor"></v-select>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="auto" lg="auto" class="d-flex align-items-center justify-content-center mt-3">
                  <b-button @click="filterCostForClinic" type="button" variant="primary-custom"
                    class="w-100 btn-min-size" style="height: 45px;">
                    <i class="ri-search-line"></i>
                    ค้นหา
                  </b-button>
                </b-col>
                <b-col cols="12" md="auto" lg="auto" class="d-flex align-items-center justify-content-center mt-3">
                  <b-button @click="resetValue()" type="button" variant="primary"
                    class="w-100 btn-min-size" style="height: 45px;">
                    <i class="ri-search-line"></i>
                    ล้างข้อมูล
                  </b-button>
                </b-col>
                <b-col cols="12" md="auto" lg="auto" class="d-flex align-items-center justify-content-center mt-3">
                  <b-button @click="showModal = true, resetDate()" type="submit" variant="primary"
                    class="w-100 btn-min-size" style="height: 45px;">
                    <i class="table_ct"></i>
                    สร้างใบสรุป
                  </b-button>
                </b-col>

              </b-row>
              <br>
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table ref="table" bordered hover :items="costData" :fields="columns" :current-page="currentPage"
                    :per-page="perPage" @pagination="onPagination" :tbody-tr-class="rowClass"
                    thead-class="table-header-color">
                    <template v-slot:cell(variable1)="data">
                      <span>{{ ((currentPage - 1) * 10) + data.index + 1 }}</span>
                    </template>
                    <template v-slot:cell(variable2)="data">
                      <span>{{ getCurrentDate(data.item.start_date || '') + ' - ' + getCurrentDate(data.item.end_date ||
                        '') }}</span>
                    </template>
                    <template v-slot:cell(variable3)="data">
                      <span>{{ formatCurrency(data.item.total) }}</span>
                    </template>
                    <template v-slot:cell(variable4)="data">
                      <span>{{ data.item.created_by_name || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable5)="data">
                      <span>{{ getCurrentDate(data.item.created_date || '') }}</span>
                    </template>
                    <template v-slot:cell(variable6)="data">
                      <div style="display: flex; justify-content: center;">
                        <b-button variant=" iq-bg-primary mr-1" size="sm" @click="handleClick('view', data.item.id)"><i
                            class="ri-eye-fill m-0"></i></b-button>
                        <b-button variant=" iq-bg-success mr-1" size="sm" @click="handleClick('edit', data.item.id)"><i
                            class="ri-ball-pen-fill m-0"></i></b-button>
                        <b-button variant=" iq-bg-danger mr-1" size="sm" @click="remove(data.item.id)"><i
                            class="ri-delete-bin-line m-0"></i></b-button>
                        <b-button variant=" iq-bg-danger mr-1" size="sm" @click="generatePDF(data.item.id)"><i
                            class="ri-file-pdf-line m-0"></i></b-button>
                        <b-button variant=" iq-bg-success mr-1" size="sm" @click="generateExcel(data.item.id)"><i
                            class="ri-file-excel-line m-0"></i></b-button>
                      </div>
                    </template>
                  </b-table>
                  <br>
                  <b-pagination v-model="currentPage" :total-rows="costData?.length" :per-page="perPage" align="center" />
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <template>
      <b-modal v-model="showModal" title="วันที่สร้างใบสรุป" cancel-title="ตกลง" ok-title="ยกเลิก"
        cancel-variant="primary" ok-variant="danger" @cancel="handleClick('add')" @ok="resetDateRange()">
        <template>
          <div class="calender-small" style="justify-content: center; display: flex;">
            <flat-pickr type="text" v-model="selectedDateRange" ref="dateRangeInput" :config="config"
              class="d-none" />
          </div>
        </template>
        <template #modal-footer>
          <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
            <b-button style="border-radius: 0.5rem; padding: 0 .7rem;" variant="primary" class="btn-min-size"
              size="sm" @click="handleClick('add')">
              <i class="circle-plus_ct" />เพิ่ม
            </b-button>
            <b-button variant="none" class="iq-bg-danger btn-cancel-border btn-min-size"
              style="border-radius: 0.5rem;" size="sm" @click="resetDateRange(), showModal = false">
              ยกเลิก
            </b-button>
          </div>
        </template>
      </b-modal>
    </template>
  </b-container>
</template>

<style>
.flatpickr-innerContainer {
  justify-content: center;
}
.datepicker-popup {
  position: absolute;
  z-index: 1;
  background-color: white;
}
.modal {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5)
}

/* อย่าใช้กระทบ modal ของหน้าอื่น */
/* .modal-content {
  background-color: #fefefe;
  padding: 20px;
  border-radius: 5px;
} */
.close {
  float: right;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}
.width-label{
  width: 200px !important;
}
@media (max-width: 1000px) {
  .buttonwidth {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
  .button21width {
    flex: 0 0 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 200%;
  }
  .button22width {
    flex: 0 0 50%;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
// import flatpickr from 'flatpickr'
// import 'flatpickr/dist/flatpickr.css'
import Vue from 'vue'
// import * as XLSX from 'xlsx'
// import JsPDF from 'jspdf'
import 'jspdf-autotable'
// import genPdf from './GenPDF'
import Axios from 'axios'
import moment from 'moment'
import { configAPI } from '../../configBase'

const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}

export default {
  name: 'MounthCostTable',
  data () {
    return {
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      usrId: JSON.parse(sessionStorage.getItem('user')).usr_id || null,
      selectedDateRange: null,
      dateRangePicker: null,
      showModal: false,
      selectedDate: {
        start_date: null,
        end_date: null
      },
      config: {
        mode: 'range',
        dateFormat: 'Y-m-d',
        inline: true
      },
      filter: {
        createdId: null,
        start_date: null,
        end_date: null
      },
      startDate: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        sideBySide: true,
        icons: iconDate,
        minDate: false,
        maxDate: false
      },
      endDate: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        minDate: false,
        icons: iconDate
      },
      createdByDD: [],
      columns: [
        { label: 'รหัส', key: 'variable1', class: 'text-center' },
        { label: 'ช่วงเวลา', key: 'variable2', class: 'text-center' },
        { label: 'ยอดค่าใช้จ่าย(บาท)', key: 'variable3', class: 'text-center' },
        { label: 'ผู้สร้าง', key: 'variable4', class: 'text-center' },
        { label: 'วันที่สร้าง', key: 'variable5', class: 'text-center' },
        { label: 'จัดการ', key: 'variable6', class: 'text-center' }
      ],
      costData: [],
      currentPage: 1,
      perPage: 10
    }
  },
  async mounted () {
    await this.getEmployee()
    await this.filterCostForClinic()
    xray.index()
    // this.dateRangePicker = flatpickr(this.$refs.dateRangeInput, {
    //   mode: 'range'
    //   // Additional configuration options for flatpickr
    // })
  },
  watch: {
    'filter.start_date' (val) {
      if (val === null) {
        this.filter.end_date = null
      } else {
        this.endDate.minDate = val
      }
    },
    'filter.end_date' (val) {
      if (val === null) {
        this.filter.start_date = null
      } else {
        this.startDate.maxDate = val
      }
    },
    selectedDateRange (val) {
      let DateStartEnd = val.split(' to ')
      let startDate = null
      let endDate = null
      if (DateStartEnd.length === 1) {
        startDate = new Date(DateStartEnd[0])
        endDate = new Date(DateStartEnd[0])
      } else {
        startDate = new Date(DateStartEnd[0])
        endDate = new Date(DateStartEnd[1])
      }
      this.selectedDate.start_date = startDate
      this.selectedDate.end_date = endDate
    }
  },
  methods: {
    onClickDatePicker (num) {
      let datePicker = null
      if (num === 1) {
        datePicker = document.getElementById('datepicker-custom1')
      } else if (num === 2) {
        datePicker = document.getElementById('datepicker-custom2')
      } else {
        datePicker = document.getElementById('datepicker-custom3')
      }
      datePicker.focus()
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    async resetValue () {
      this.filter.createdId = null
      this.filter.start_date = null
      this.filter.end_date = null
      await this.filterCostForClinic()
    },
    resetDateRange () {
      this.selectedDateRange = null
    },
    async generatePDF (id) {
      await Axios.request({
        method: 'POST',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/cost/${id}/bill/export`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        let url = response.data.data?.file_path.slice(1)
        url = `${configAPI.MA_PORT}${url}`
        window.open(url, '_blank')
      }).catch((err) => {
        Vue.swal({
          title: 'เกิดข้อผิดพลาด',
          text: 'ไม่สามารถสร้างรายงานได้' + err,
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
      })
    },
    async generateExcel (id) {
      await Axios.request({
        method: 'POST',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/cost/${id}/report/export`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        let url = response.data.data?.file_path.slice(1)
        url = `${configAPI.MA_PORT}${url}`
        window.open(url, '_blank')
      }).catch((err) => {
        Vue.swal({
          title: 'เกิดข้อผิดพลาด',
          text: 'ไม่สามารถสร้างรายงานได้' + err,
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
      })
    },
    formatCurrency (value) {
      if (value === null || value === undefined) {
        return '0.00'
      }
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    remove (id) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะลบข้อมูลใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Axios.request({
            method: 'POST',
            url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/cost/${id}/delete`,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((res) => {
            if (res.data.status === true) {
              Vue.swal({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
                icon: 'success',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000
              }).then(async () => {
                await this.filterCostForClinic()
              })
            } else {
              Vue.swal({
                title: 'เกิดข้อผิดพลาด',
                text: res.data.message,
                icon: 'error',
                confirmButtonText: 'ตกลง'
              })
            }
          }).catch((err) => {
            Vue.swal({
              title: 'เกิดข้อผิดพลาด',
              text: err.response.data.message,
              icon: 'error',
              confirmButtonText: 'ตกลง'
            })
          })
        }
      })
    },
    onPagination (page) {
      this.currentPage = page
    },
    async handleClick (pageMode, id) {
      const setPage = pageMode
      if (setPage === 'add') {
        await this.addCost()
      } else if (setPage === 'view') {
        router.push(`/AddMonthCost/${setPage}/${id}`)
      } else if (setPage === 'edit') {
        router.push(`/AddMonthCost/${setPage}/${id}`)
      }
    },
    getCurrentDate (dataInput) {
      let date = new Date(dataInput)
      if (dataInput === '') {
        return ''
      }
      const today = moment(date).format('DD/MM/YYYY')
      return today
    },
    resetEndDate (e) {
      this.filter.end_date = null
    },
    resetDate () {
      this.selectedDate = {
        start_date: new Date(),
        end_date: new Date()
      }
    },
    async filterCostForClinic () {
      let filterTemp = {
        start_date: this.filter.start_date !== null ? this.filter.start_date : '',
        end_date: this.filter.end_date !== null ? this.filter.end_date : '',
        created_by: this.filter.createdId !== null ? this.filter.createdId.code : ''
      }
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/cost/?start_date=${filterTemp.start_date}&end_date=${filterTemp.end_date}&created_by=${filterTemp.created_by}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        this.costData = res.data.data
      }).catch((err) => {
        Vue.swal({
          title: 'เกิดข้อผิดพลาด',
          text: err.response.data.message,
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
      })
    },
    async getEmployee () {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/user`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        let tempData = res.data.data.results
        let temp = []
        if (tempData.length > 0) {
          tempData.forEach((item) => {
            if (item.is_use) {
              if (!item.emp_name && !item.emp_lastname) {
                temp.push({
                  label: 'System (Super Admin)',
                  code: item.id
                })
              } else {
                temp.push({
                  label: item.emp_name + ' ' + item.emp_lastname,
                  code: item.id
                })
              }
            }
          })
        }
        this.createdByDD = temp
      }).catch((err) => {
        Vue.swal({
          title: 'เกิดข้อผิดพลาด',
          text: err.response.data.message,
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
      })
    },
    async addCost () {
      let data = {
        start_date: this.selectedDate.start_date,
        end_date: this.selectedDate.end_date,
        created_by: this.usrId
      }
      await Axios.request({
        method: 'POST',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/cost`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }).then(async (res) => {
        if (res.data.data?.id) {
          router.push(`/AddMonthCost/edit/${res.data.data.id}`)
        } else {
          Vue.swal({
            title: 'เกิดข้อผิดพลาด',
            text: 'ไม่สามารถสร้างใบสรุปค่าใช้จ่ายได้',
            icon: 'error',
            confirmButtonText: 'ตกลง'
          })
        }
      }).catch((err) => {
        Vue.swal({
          title: 'เกิดข้อผิดพลาด',
          text: err.response.data.message,
          icon: 'error',
          confirmButtonText: 'ตกลง'
        })
      })
    }
  }
}
</script>
