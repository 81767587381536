<template>
  <b-container fluid>
      <form>
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:headerTitle>
                <div class="row align-items-center py-4 mx-0">
                  <div class="col-auto">
                    <span style="font-size: 25px; color: black;">ผู้ใช้งาน</span>
                    <!-- <span v-else-if="mode === 'edit'"
                      style="font-size: 25px; color: black;">ผู้ใช้งาน (แก้ไข)</span>
                    <span v-else style="font-size: 25px; color: black;">ผู้ใช้งาน (ดูรายละเอียด)</span> -->
                  </div>
                  <div v-if="mode === 'add' || mode === 'edit'" class="col-12 col-sm-auto">
                        <div v-if="edit === false">
                          <div class="row">
                            <!-- <div class="col-12 col-sm-auto px-sm-2 my-1 my-sm-0"> -->
                              <div class="col-6 col-sm-auto pl-1 px-sm-2 my-1 my-sm-0">
                                <b-button type="button" variant="none" style="background: linear-gradient(90deg, #F9CC0C 0%, #FFEA28 100%);" @click="editClick(true)" class="w-100 btn-min-size"><i class="edit_profile"/>แก้ไข</b-button>
                              </div>
                              <div class="col-6 col-sm-auto pl-1 px-sm-2 my-1 my-sm-0">
                                <b-button type="button" variant="none" @click="handleClickGoBack" class="iq-bg-danger btn-cancel-border w-100 btn-min-size">ยกเลิก</b-button>
                              </div>
                            <!-- </div> -->
                          </div>
                        </div>
                        <div v-else>
                          <div class="row">
                            <div class="col-12 col-sm-auto px-sm-2 my-1 my-sm-0">
                              <b-button type="button" variant="none" style="background: linear-gradient(90deg, #F9CC0C 0%, #FFEA28 100%);" @click="editClick(false)" class="w-100 btn-min-size"><i class="edit_profile"/>แก้ไข</b-button>
                            </div>
                            <div class="col-6 col-sm-auto pr-1 pl-sm-3 pr-sm-2 my-1 my-sm-0">
                              <b-button type="button" variant="primary" @click="handleClick" class="w-100 btn-min-size"><i class="save-data_ct"/>บันทึก</b-button>
                            </div>
                            <div class="col-6 col-sm-auto pl-1 px-sm-2 my-1 my-sm-0">
                              <b-button type="button" variant="none" @click="handleClickGoBack" class="iq-bg-danger btn-cancel-border w-100 btn-min-size">ยกเลิก</b-button>
                            </div>
                          </div>
                        </div>
                      </div>
                    <!-- </div>
                  </div> -->
                  <div v-else class="col-12 col-sm-auto my-1 my-sm-0">
                    <b-button type="button" variant="danger" @click="handleClickGoBack" class="w-100">ยกเลิก</b-button>
                  </div>
                </div>
              </template>
              <!-- <template v-slot:headerTitle style="width: 100%;">
                <div v-if="mode !== 'view'" class="row py-4">
              <div class="" style="display: flex; align-items: center;">
                <span class="px-3 margin-text-header" style="font-size: 25px; color: black;">ผู้ใช้งาน {{ mode === 'edit' ? '(แก้ไข)' : mode === 'view' ? '(ดูรายละเอียด)' : '(เพิ่ม)'}}</span>
              </div>
              <div class="col-lg-1 col-md-1 col-sm-2 col-12" style="padding-left: 0;">
                <div class="d-flex my-2 mx-2" style="white-space: nowrap;">
                  <b-button @click="handleClick2" variant="primary" class="mx-1 flex-fill text-center" style="text-align: center;">บันทึก</b-button>
                  <b-button @click="handleClickGoBack" variant="none" class="mx-2 flex-fill bg-danger text-center" style="text-align: center;">ยกเลิก</b-button>
                </div>
              </div>
                </div>
                <div v-else class="row py-4" style="display: flex; justify-content: flex-start;">
                  <div class="col-lg-3 col-md-3 col-sm-2 col-12 apg-response-header"  style="display: flex; align-items: center;">
                    <span class="padding-text-header" style="font-size: 25px; color: black; white-space: nowrap;">ผู้ใช้งาน {{ mode === 'edit' ? '(แก้ไข)' : mode === 'view' ? '(ดูรายละเอียด)' : '(เพิ่ม)'}}</span>
                    <b-button @click="handleClickGoBack" variant="none" class="flex-fill bg-danger text-center apg-response-header-btn" style="text-align: center;">ยกเลิก</b-button>
                  </div>
                </div>
              </template> -->
              <template v-slot:body>
                <div class="new-user-info mx-3">
                  <b-row>
                    <b-col lg="6" sm="12">
                      <b-form class="mt-1">
                        <b-form-group label="Username" style="color: black;">
                          <template v-if="userAdd.username === null ||userAdd.username?.trim() === ''" v-slot:label>
                            Username<span style="color: red;"> *</span>
                          </template>
                          <b-form-input type="text" :disabled="true" :value="userAdd.username" readonly placeholder=""></b-form-input>
                        </b-form-group>
                        <b-form-group label="Password" style="color: black;">
                          <template v-if="userAdd.password === null ||userAdd.password?.trim() === ''" v-slot:label>
                            Password<span style="color: red;"> *</span>
                          </template>
                          <b-form-input type="password" :disabled="edit === true ? false : true" v-model="userAdd.password" placeholder=""></b-form-input>
                        </b-form-group>
                        <!-- <b-form-group style="color: black;" label="พนักงาน *">
                          <b-form-select class="height-select" :disabled="edit === true ? false : true"  plain @change="OnSetSelect" v-model="userAdd.employee" :options="optionsSelect" size="lg">
                            <template v-slot:first>
                              <b-form-select-option :value="null">------ Select --------</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group> -->
                        <!-- <b-form-group label="รหัสพนักงาน : " style="color: black;">
                          <b-form-input type="text" v-model="userAdd.employee_no" :disabled="edit === true ? false : true"></b-form-input>
                        </b-form-group>
                        <b-form-group >
                          <b-row class="my-3 gap-respnose-adduser" style="text-align: center; align-items: center; margin-left: 0.2rem;">
                            <div style="display: flex;">
                              <span style="color: black; font-size: 1rem;">สถานะ :</span>
                            </div>
                            <div style="display: flex;">
                              <b-form-radio value="true" :disabled="edit === true ? false : true" v-model="userAdd.status_use" class="ml-3 radio-disable" name="redio-button"></b-form-radio>
                              <b-text class="margin-response-adduser check" style="color: black;">ใช้งาน</b-text>
                            </div>
                            <div style="display: flex;">
                              <b-form-radio value="false" :disabled="edit === true ? false : true" v-model="userAdd.status_use" class="ml-3 radio-disable" name="redio-button"></b-form-radio>
                              <b-text class="mr-4 check" style="color: black;">ยกเลิก</b-text>
                            </div>
                          </b-row>
                        </b-form-group> -->
                        <b-form-group label="Email" style="color: black;">
                          <template v-if="userAdd.email === null ||userAdd.email?.trim() === ''" v-slot:label>
                            Email<span style="color: red;"> *</span>
                          </template>
                          <b-form-input :disabled="edit === true ? false : true" type="text" v-model="userAdd.email" ></b-form-input>
                        </b-form-group>
                      </b-form>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form>
                        <template>
                          <div>
                            <b-form-group style="margin-top: 0.25rem; color: black;" label="อัปโหลดรูปภาพ">
                              <b-form-file
                                class="custom-file-style mb-2"
                                id="imageInput2"
                                accept="image/*"
                                :disabled="edit === true ? false : true"
                                :placeholder="userAdd.imageName ? userAdd.imageName : 'Choose a file'"
                                @change="handleImageUpload2"
                              ></b-form-file>
                            </b-form-group>
                            <div v-if="userAdd.imageUrl" style="text-align: center;">
                              <img :src="userAdd.imageUrl" alt="Uploaded Image" width="auto" height="250" @error="$event.target.src='https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png'"/>
                            </div>
                          </div>
                        </template>
                      </b-form>
                    </b-col>
                  </b-row>
              </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </form>
  </b-container>
</template>

<style>
.gap-respnose-adduser {
  gap: 1rem !important;
}
.margin-response-adduser {
  margin-right: 2.5rem !important;
}
.margin-text-header {
  margin-left: 1rem;
}
.padding-text-header {
  margin-left: 0;
  margin-right: 1rem;
}

[dir=ltr] .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #089bab !important;
}

.apg-response-header-btn {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
  width: 94% !important;
}

@media (max-width: 560px) {
  .margin-text-header {
    margin-left: 0 !important;
  }
  .padding-text-header {
    margin-left: 0.5rem !important;
    margin-right: 0 !important;
  }
  .apg-response-header {
    display: flex;
    align-items: flex-start !important;
    flex-direction: column;
  }
  .apg-response-header-btn {
    margin-top: 0.5rem !important;
    width: 100% !important;
    max-width: 100% !important;
    /* padding-left: 1rem; */
    margin-left: 0.2rem !important;
    margin-right: 0 !important;
  }
  .iq-header-title {
    width: 100% !important;
  }
}
@media (max-width: 365px) {
  .gap-respnose-adduser {
    gap: .1rem !important;
  }
  .margin-response-adduser {
    margin-right: 1rem !important;
  }
}
@media (max-width: 350px) {
  .padding-text-header {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .apg-response-header-btn {
    margin-left: 0 !important;
    width: 100% !important;
    margin-right: 0 !important;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '../../router'
import Vue from 'vue'
import axios from 'axios'
import { configAPI } from '../../configBase'

export default {
  name: 'AddUser',
  async mounted () {
    const data = JSON.parse(sessionStorage.getItem('user'))
    this.cln_id = data.cln_id
    this.user_id = data.usr_id
    await this.getUserManageById()
    xray.index()
  },
  data () {
    return {
      edit: false,
      mode: 'add',
      isDisable: false,
      userAdd: {
        username: null,
        password: null,
        employee_id: null,
        employee_no: null,
        status_use: true,
        rol_name: null,
        rol_id: null,
        user_status: null,
        user_type: null,
        email: null,
        imageName: null,
        imageUrl: null,
        fileImage: null
      },
      user: {
        fname: '',
        lname: '',
        name: '',
        username: '',
        email: '',
        password: '',
        address1: '',
        address2: '',
        company_name: '',
        profile_image: require('../../assets/images/user/11.png'),
        mobile_no: '',
        country: '',
        city: '',
        pincode: '',
        role: ''
      },
      users: []
    }
  },
  computed: {
    fullName: function () {
      return this.user.fname + ' ' + this.user.lname
    }
  },
  methods: {
    async getUserManageById () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/user/${this.user_id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            let data = response.data.data[0]
            this.userAdd.username = data.username
            this.userAdd.status_use = (data.us_name === 'ใช้งาน')
            this.userAdd.email = data.email
            this.userAdd.imageName = data.img_profile
            this.userAdd.imageUrl = this.getFullImagePath(data.img_profile_path)
            this.userAdd.rol_name = data.rol_name
            this.userAdd.rol_id = data.rol_id
            this.userAdd.fileImage = null
            this.userAdd.employee_id = data.emp_id
            this.userAdd.user_status = data.us_id
            this.userAdd.user_type = data.ut_id
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    getFullImagePath (relativePath) {
      if (relativePath) {
        let path = relativePath !== null ? relativePath.slice(1) : ''
        const baseURL = configAPI.MA_PORT + path
        return baseURL
      } else {
        return 'https://www.w3schools.com/howto/img_avatar.png'
      }
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    previewImage: function (event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.user.profile_image = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    async checkError (tab) {
      let error = []
      if (!this.userAdd.username || this.userAdd?.username === null || this.userAdd.username?.trim() === '') {
        error.push('กรุณากรอก Username')
      }
      if (!this.userAdd.password || this.userAdd?.password === null || this.userAdd.password?.trim() === '') {
        error.push('กรุณากรอก Password')
      }
      if (!this.userAdd.email || this.userAdd.email === null || this.userAdd.email?.trim() === '') {
        error.push('กรุณากรอก Email')
      }
      // if (!this.userAdd.fileImage || this.userAdd.fileImage === null) {
      //   error.push('กรุณาอัปโหลด Image')
      // }
      return error
    },
    editClick (e) {
      this.edit = e
    },
    handleClick () {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.mode !== 'edit' ? 'คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่?' : 'คุณมั่นใจว่าคุณจะแก้ไขข้อมูลใช่หรือไม่?'}</span>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          const errorList = await this.checkError()
          if (errorList.length > 0) {
            let errMessage = ''
            for (let e = 0; e < errorList.length; e++) {
              errMessage += '<br/>' + errorList[e]
            }
            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลไม่สำเร็จ</span>',
              html: errMessage,
              icon: 'error',
              showConfirmButton: true,
              confirmButtonText: '<b-button style="font-size: 14px; text-align: center">Ok</b-button>',
              confirmButtonColor: '#089bab',
              customClass: {
                confirmButton: 'custom-confirm-button-class mx-1'
              }
            })
            return
          }
          let dataForSave = {
            rol_id: this.userAdd.rol_id,
            us_id: this.userAdd.user_status,
            ut_id: this.userAdd.user_type,
            emp_id: this.userAdd.employee_id,
            username: this.userAdd.username,
            password: this.userAdd.password,
            email: this.userAdd.email
          }
          await axios.request({
            method: 'put',
            url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/user/${this.user_id}`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: dataForSave
          }).then(async (response) => {
            if (response.data.status) {
              if (this.userAdd.fileImage && response.data.data?.id) {
                await this.uploadImage(response.data.data.id)
              }
              Vue.swal.fire({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">อัพเดตข้อมูลสำเร็จ</span>',
                icon: 'success',
                showConfirmButton: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                allowEnterKey: false,
                timer: 1500
              }).then((result) => {
                window.location.reload()
              })
            } else {
              Vue.swal.fire({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลไม่สำเร็จ</span>',
                html: response.data.error,
                icon: 'error',
                showConfirmButton: true,
                confirmButtonText: '<b-button style="font-size: 14px; text-align: center">Ok</b-button>',
                confirmButtonColor: '#089bab',
                customClass: {
                  confirmButton: 'custom-confirm-button-class mx-1'
                }
              })
            }
          })
        }
      })
    },
    handleClickGoBack () {
      if (this.mode === 'view') {
        router.push('/DashboardsCus')
      } else {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            router.push('/DashboardsCus')
          }
        })
      }
    },
    handleImageUpload2 (event) {
      const file2 = event.target.files[0]
      const reader2 = new FileReader()
      reader2.readAsDataURL(file2)
      this.userAdd.fileImage = file2
      this.userAdd.imageName = file2.name
      reader2.onload = () => {
        this.userAdd.imageUrl = reader2.result
      }
    },
    async uploadImage (usrId) {
      const formData = new FormData()
      formData.append('file', this.userAdd.fileImage)
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/user/${usrId}/upload/img`,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      }).then(() => {
      }).catch((error) => {
        this.SwalError(error.message, 'เกิดข้อผิดพลาดในการอัพโหลดรูปภาพ')
      })
    }
  }
}
</script>
