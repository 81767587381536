<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">ประวัติการรับบริการ ของ {{ cusName }}</span>
              </div>
              <div v-if="mode === 'view'" class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="button" variant="none" @click="handleClickGoBack" class="iq-bg-danger w-100 btn-min-size btn-cancel-border">ยกเลิก</b-button>
              </div>
              <!-- <div class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-lg-5 col-md-5 col-sm-5 col-12 pl-3 pr-2 my-1 my-sm-0">
                    <b-button type="button" variant="primary" @click="handleClick('add')" class="w-100">เพิ่มชื่อผู้ป่วย</b-button>
                  </div>
                  <div class="col-lg-7 col-md-7 col-sm-7 col-12 pl-2 pr-3 my-1 my-sm-0">
                    <b-button type="button" variant="danger" @click="handleClick('service')" style="white-space: nowrap;" class="w-100">เพิ่มประวัติการรับบริการ</b-button>
                  </div>
                </div>
              </div> -->
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" md="7" lg="5">
                  <b-row>
                    <b-col sm="12" md class="mb-3">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input v-model="search.text" class="form-control" id="exampleInputEmail1" placeholder="Search" style="width: 100%;">
                            <a class="search-link" @click="getHistoryService"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                      <span class="mx-2 font-size-14 text-primary">
                        {{ historyServiceData?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > historyServiceData?.length) ? historyServiceData?.length : (perPage * currentPage) ) + ' of ' + AllDataLength : '0 of ' + AllDataLength }} รายการ
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              </div>
              <br>
              <b-col md="12" class="table-responsive">
                <b-table bordered hover :items="historyServiceData" :fields="columns"
                  :current-page="currentPage"
                  :per-page="perPage"
                  @pagination="onPagination"
                  :tbody-tr-class="rowClass" thead-class="table-header-color"
                >
                <template v-slot:cell(OPD_no)="data">
                  <span>{{ data.item.OPD_no }}</span>
                </template>
                <template v-slot:cell(fullname)="data">
                  <span>{{ data.item.fullname }}</span>
                </template>
                <template v-slot:cell(tel)="data">
                  <span>{{ formatTel(data.item.tel) }}</span>
                </template>
                <template v-slot:cell(service_date)="data">
                  <span>{{ formatDate(data.item.service_date) }}</span>
                </template>
                <template v-slot:cell(service_type)="data">
                  <div v-if="data.item.service_type?.length > 0">
                    <span v-for="(item1, index) in data.item.service_type" :key="index">
                      <br v-if="index !== 0">
                      {{ item1 }}
                    </span>
                  </div>
                  <div v-else>
                    <span>-</span>
                  </div>
                </template>
                <template v-slot:cell(product)="data">
                  <div v-if="data.item.product?.length > 0">
                    <span v-for="(item1, index) in data.item.product" :key="index">
                      <br v-if="index !== 0">
                      {{ item1 }}
                    </span>
                  </div>
                  <div v-else>
                    <span>-</span>
                  </div>
                </template>
                <template v-slot:cell(doctor)="data">
                  <span>{{ data.item.doctor }}</span>
                </template>
                <template v-slot:cell(management)="data">
                  <div style="display: flex; justify-content: space-evenly;">
                    <b-button variant=" iq-bg-primary ml-1" size="sm" @click="handleClick('view', data.item)"><i class="ri-eye-fill m-0"></i></b-button>
                    <!-- <b-button v-if="data.item.isClose === null" variant=" iq-bg-success mx-1" size="sm" id="show-modal" @click="handleClick('edit', data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button> -->
                  </div>
                </template>
              </b-table>
              <br>
            </b-col>
            <b-pagination
                v-model="currentPage"
                :total-rows="historyServiceData?.length"
                :per-page="perPage"
                align="center"
              />
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.input-quicksearch-history {
  width: 120px !important;
  height: 30px !important;
  margin-bottom: 1rem !important;
}
</style>
<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import Axios from 'axios'
import moment from 'moment'
import { configAPI } from '../../configBase'

export default {
  name: 'HistoryService',
  data () {
    return {
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      search: {
        id: null,
        text: ''
      },
      mode: 'view',
      columns: [
        { label: 'เลข OPD', key: 'OPD_no', class: 'text-center table-size-200-400' },
        { label: 'ชื่อ - นามสกุล', key: 'fullname', class: 'text-center table-size-200-400' },
        { label: 'เบอร์ติดต่อ', key: 'tel', class: 'text-center table-size-200-400' },
        { label: 'วันที่รับบริการ', key: 'service_date', class: 'text-center table-size-200-400' },
        { label: 'บริการ', key: 'service_type', class: 'text-center table-size-200-400' },
        { label: 'สินค้า / จำนวน', key: 'product', class: 'text-center table-size-200-400' },
        { label: 'แพทย์ที่ทำการรักษา', key: 'doctor', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'management', class: 'text-center table-size-100-300' }
      ],
      historyServiceData: [],
      currentPage: 1,
      perPage: 10,
      cusName: '',
      AllDataLength: 0
    }
  },
  computed: {
  },
  async mounted () {
    this.search.id = this.$route.params.id || null
    if (this.$route.params.setPage === 'view') {
      this.mode = 'view'
    }
    await this.getCustomer(this.$route.params.id)
    await this.getAllHistoryService()
    await this.getHistoryService()
    xray.index()
  },
  methods: {
    async getCustomer (cusId) {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/customer/${cusId}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        this.cusName = (response.data.data[0].cus_firstname ? response.data.data[0].cus_firstname : '-') + ' ' + (response.data.data[0].cus_lastname ? response.data.data[0].cus_lastname : '-')
      }).catch((err) => {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่พบข้อมูล</span>',
          icon: 'error',
          html: `<span class="" style="font-size: 18px; color: black; text-align: center">${err}</span>`,
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      })
    },
    async getHistoryService () {
      let searchData = {
        id: this.search.id || null,
        search: this.search.text.trim()
      }
      await Axios.request({
        method: 'POST',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/historyServiceCustomer`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: searchData
      }).then((response) => {
        let temp = response.data.data
        let tempData = []
        temp.map((element, index) => {
          tempData.push({
            id: element.id,
            OPD_no: element.opd_no,
            fullname: (element.firstname ? element.firstname : '-') + ' ' + (element.lastname ? element.lastname : '-'),
            tel: element.tel,
            service_date: element.created_date,
            service_type: element.saleOrder?.filter((item) => {
              if (item.ser_name !== null) {
                return item
              }
            }).map((item) => {
              return item.ser_name
            }),
            product: element.saleOrder?.filter((item) => {
              if (item.prd_name !== null) {
                return item
              }
            }).map((item) => {
              return item.prd_name + ' / ' + item.soo_use_amount + ' ' + item.prd_unit
            }),
            doctor: element.doctor_name + ' ' + element.doctor_lastname,
            isClose: element.is_close
          })
        })
        this.historyServiceData = tempData
      }).catch((err) => {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่พบข้อมูล</span>',
          icon: 'error',
          html: `<span class="" style="font-size: 18px; color: black; text-align: center">${err}</span>`,
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      })
    },
    async getAllHistoryService () {
      let searchData = {
        id: this.search.id || null,
        search: this.search.text.trim()
      }
      await Axios.request({
        method: 'POST',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/historyServiceCustomer`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: searchData
      }).then((response) => {
        this.AllDataLength = response.data.data.length
      }).catch((err) => {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่พบข้อมูล</span>',
          icon: 'error',
          html: `<span class="" style="font-size: 18px; color: black; text-align: center">${err}</span>`,
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      })
    },
    remove (item) {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะลบข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Axios.request({
            method: 'PUT',
            url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/historyServiceCustomer/${item.id}/delete`,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              timer: 1500
            }).then(async () => {
              await this.getHistoryService()
            })
          }).catch((err) => {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลไม่สำเร็จ</span>',
              icon: 'error',
              html: `<span class="" style="font-size: 18px; color: black; text-align: center">${err}</span>`,
              showCancelButton: false,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              timer: 1500
            }).then(async () => {
              await this.getHistoryService()
            })
          })
        }
      })
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick (e, data) {
      const setPage = e
      // this.updateDataAsync()
      if (setPage === 'add') {
        router.push(`/Service/${setPage}`)
      } else if (setPage === 'view') {
        router.push(`/ServiceUsage/Service/History/view/${data.id}`)
      } else if (setPage === 'edit') {
        router.push(`/ServiceUsage/Service/History/edit/${data.id}`)
      }/*  else {
        router.push(`/Patient/${setPage}/${data.id}`)
      } */
    },
    sendData () {
      const setPage = 'add'
      this.setData(setPage)
    },
    handleClickGoBack () {
      router.go(-1)
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.id % 2 === 1) return 'table-body-color'
    },
    formatDate (date) {
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      } else {
        return '-'
      }
    },
    formatTel (tel) {
      if (tel) {
        let temp = tel.split('')
        let temp2 = ''
        temp.map((item, index) => {
          if (index === 2 || index === 5) {
            temp2 += item + '-'
          } else {
            temp2 += item
          }
        })
        return temp2
      } else {
        return '-'
      }
    }
  }
}
</script>
