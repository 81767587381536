<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">อนุมัติหมอ</span>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" md="7" lg="5">
                  <b-row>
                    <b-col sm="12" md class="mb-3">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input class="form-control" id="exampleInputEmail1" placeholder="Search" style="width: 100%;" v-model="searchTerm" @keydown="enterSearch">
                            <a class="search-link" v-on:click="getApprove"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                      <span class="mx-2 font-size-14 text-primary">
                        {{ approve?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > approve?.length) ? approve?.length : (perPage * currentPage) ) + ' of ' + approveLength : '0 of ' + approveLength }} รายการ
                      </span></b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="table-responsive" style="padding: 0px;">
                  <b-table bordered hover :items="approve" :fields="columns"
                    :current-page="currentPage" :per-page="perPage" @pagination="onPagination" :tbody-tr-class="rowClass" thead-class="table-header-color">
                    <template v-slot:cell(variable1)="data">
                      <doctorModal :id="approve[((currentPage - 1) * 10) + data.index].id" :label="approve[((currentPage - 1) * 10) + data.index].name"></doctorModal>
                      <!-- <a href="#" @click.prevent="test(approve[((currentPage - 1) * 10) + data.index].id)">
                        <span style="text-decoration: underline;">{{ approve[((currentPage - 1) * 10) + data.index].name }}</span>
                      </a> -->
                    </template>
                    <template v-slot:cell(variable2)="data">
                      <span>{{ approve[((currentPage - 1) * 10) + data.index].no }}</span>
                    </template>
                    <template v-slot:cell(variable3)="data">
                      <span>{{ approve[((currentPage - 1) * 10) + data.index].gender_name }}</span>
                    </template>
                    <template v-slot:cell(variable4)="data">
                      <span>{{ approve[((currentPage - 1) * 10) + data.index].age }}</span>
                    </template>
                    <template v-slot:cell(variable5)="data">
                      <span>{{ approve[((currentPage - 1) * 10) + data.index].specialized }}</span>
                    </template>
                    <template v-slot:cell(variable6)="data">
                      <span>{{ approve[((currentPage - 1) * 10) + data.index].is_receive }}</span>
                    </template>
                    <template v-slot:cell(variable7)="data">
                      <span>{{ approve[((currentPage - 1) * 10) + data.index].tel }}</span>
                    </template>
                    <template v-slot:cell(variable8)="data">
                      <!-- <div class="custom-control-1 custom-switch custom-switch-text custom-switch-color"
                        :key="data.item.id">
                        <div class="custom-switch-inner">
                          <input type="checkbox" class="custom-control-input bg-success"
                            :id="'customSwitch-11' + data.item.id" v-model="data.item.checked" @click="checkApprove(data)">
                          <label class="custom-control-label-1 label-1" :for="'customSwitch-11' + data.item.id"
                            :data-on-label="'ON'" :data-off-label="'OFF'">
                          </label>
                        </div>
                      </div> -->
                      <div class="custom-control-1 custom-switch custom-switch-text custom-switch-color">
                        <div class="custom-switch-inner">
                          <input
                            type="checkbox"
                            class="custom-control-input bg-success"
                            :id="'customSwitch-11-' + approve[((currentPage - 1) * 10) + data.index].id"
                            v-model="approve[((currentPage - 1) * 10) + data.index].is_active"
                            @click="editStatus(approve[((currentPage - 1) * 10) + data.index])"
                          />
                          <label
                            class="custom-control-label-1 label-1"
                            :for="'customSwitch-11-' + approve[((currentPage - 1) * 10) + data.index].id"
                            :data-on-label="'ON'"
                            :data-off-label="'OFF'"
                          ></label>
                        </div>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row style="display: flex; justify-content: center;">
                <b-pagination v-model="currentPage" :total-rows="approve.length" :per-page="perPage" />
                <!-- <b-pagination v-if="activeTab === 'tab2'" v-model="currentPage" :total-rows="rows2.length" :per-page="perPage" /> -->
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import axios from 'axios'
import Vue from 'vue'
import { configAPI } from '../../configBase'
import doctorModal from './component/doctorModal.vue'

export default {
  name: 'UiDataTable',
  components: {
    doctorModal
  },
  data () {
    return {
      approve: [],
      searchTerm: '',
      columns: [
        { label: 'ชื่อ-นามสกุล', key: 'variable1', class: 'text-center table-size-200-400' },
        { label: 'เลขที่ใบประกอบวิชาชีพ', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'เพศ', key: 'variable3', class: 'text-center table-size-200-400' },
        { label: 'อายุ', key: 'variable4', class: 'text-center table-size-200-400' },
        { label: 'สาขาเฉพาะทาง', key: 'variable5', class: 'text-center table-size-200-400' },
        { label: 'ประเภทงานที่รับ', key: 'variable6', class: 'text-center table-size-200-400' },
        { label: 'เบอร์โทรศัพท์', key: 'variable7', class: 'text-center table-size-200-400' },
        { label: 'อนุมัติเข้าระบบ', key: 'variable8', class: 'text-center table-size-200-400' }
      ],
      currentPage: 1,
      perPage: 10,
      approveLength: 0
    }
  },
  async mounted () {
    xray.index()
    await this.getApprove()
    await this.getApproveAll()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getApprove()
      }
    },
    test (id) {
      router.push('/OpenLinkDoctor/' + id)
    },
    convertToJson (ap) {
      if (ap.is_active === true) {
        ap.is_active = false
      } else {
        ap.is_active = true
      }
      let dataList = {}
      dataList = {
        is_active: ap.is_active
      }
      return dataList
    },
    async getApprove () {
      let search = this.searchTerm.trim().length > 0 ? this.searchTerm.trim() : ''
      let approveAPI = {
        method: 'get',
        url: `${configAPI.MA_PORT}/api/doctor?search=${search}&ignore_active=true`,
        headers: {
          'Content-Type': 'application/json'
        }
      }

      await axios.request(approveAPI)
        .then((response) => {
          this.approve = response.data.data
        })
    },
    async getApproveAll () {
      let search = this.searchTerm.trim().length > 0 ? this.searchTerm.trim() : ''
      let approveAPI = {
        method: 'get',
        url: `${configAPI.MA_PORT}/api/doctor?search=${search}&ignore_active=true`,
        headers: {
          'Content-Type': 'application/json'
        }
      }

      await axios.request(approveAPI)
        .then((response) => {
          this.approveLength = response.data.data.length
        })
    },
    checkApprove (data) {
      const indexData = this.rows.findIndex((item) => { return item.id === data.item.id })
      if (data.item.checked) {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะยกเลิกอนุมัติใช่หรือไม่</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.rows[indexData].checked = false
          } else {
            this.rows[indexData].checked = true
          }
        })
      } else {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะอนุมัติใช่หรือไม่</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.rows[indexData].checked = true
          } else {
            this.rows[indexData].checked = false
          }
        })
      }
    },
    editStatus (ap) {
      let editstatusAPI = {
        method: 'post',
        url: `${configAPI.MA_PORT}/api/doctor/` + ap.id + '/approve',
        headers: {
          'Content-Type': 'application/json'
        },
        data: this.convertToJson(ap)
      }
      axios.request(editstatusAPI)
        .then((response) => {
          // this.getApprove()
          // this.service = response.data.data.map(item => {
          //   return { ...item, editable: false }
          // })
        })
    },
    add () {
      let obj = this.default()
      this.rows.push(obj)
    },
    default () {
      return {
        id: this.rows.length,
        variable1: '',
        variable2: '',
        variable3: '',
        variable4: '',
        variable5: '2011/04/25',
        variable6: '$0',
        editable: false
      }
    },
    edit (item) {
      item.editable = true
    },
    submit (item) {
      item.editable = false
    },
    remove (item) {
      let index = this.rows.indexOf(item)
      this.rows.splice(index, 1)
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick () {
      router.push('/AddProduct')
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    }
  }
}
</script>
