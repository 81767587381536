<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">ประเมินความพึงพอใจต่อการใช้บริการ</span>
              </div>
              <div class="col-auto my-1 my-sm-0">
                <div class="row">
                  <div v-if="checkLogin === true" class="col-6 pl-sm-3 pr-sm-1 my-1 my-sm-0">
                    <b-button type="submit" variant="none" @click="handleClickGoBack"
                      class="w-100 btn-min-size iq-bg-danger btn-cancel-border">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <div class="row">
                <div class="col-12">
                  <span style="font-weight: bold; color: black; font-size: large;">บริการ : </span>
                  <span style="color: black; font-size: large;">{{ serviceText }}</span>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <span class="text-primary">วันที่ : </span>
                  <span class="text-primary">{{ dateTimeText }} น.</span>
                </div>
              </div>

              <hr>

              <div class="row" style="align-items: center;">
                <div class="col-12 col-md-4" style="max-width: 516px;">
                  <span style="color: black; font-size: large;"></span>
                </div>
                <div class="col-12 col-md-auto score_position_center">
                  <span style="color: black; font-size: large; padding: 0px 15px;">ดีมาก</span>
                  <span style="color: black; font-size: large; padding: 0px 15px;">ดี</span>
                  <span style="color: black; font-size: large; padding: 0px 15px;">พอใช้</span>
                  <span style="color: black; font-size: large; padding: 0px 15px;">แย่</span>
                  <span style="color: black; font-size: large; padding: 0px 15px;">แย่มาก</span>
                </div>
              </div>

              <div class="row" style="align-items: center;">
                <div class="col-12 col-md-4" style="max-width: 516px;">
                  <span style="color: black; font-size: large;">คุณภาพการบริการ</span>
                </div>
                <div v-if="checkLogin === false && checkSave === false" class="col-12 col-md-auto score_position_center">
                  <i class="ri-emotion-happy-fill score_size" @click="clickChangeColor_1(5)"
                    :class="score_1 == 5 ? 'color_select_1' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-happy-fill score_size" @click="clickChangeColor_1(4)"
                    :class="score_1 == 4 ? 'color_select_2' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-normal-fill score_size" @click="clickChangeColor_1(3)"
                    :class="score_1 == 3 ? 'color_select_3' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-unhappy-fill score_size" @click="clickChangeColor_1(2)"
                    :class="score_1 == 2 ? 'color_select_4' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-unhappy-fill score_size" @click="clickChangeColor_1(1)"
                    :class="score_1 == 1 ? 'color_select_5' : 'color_not_select'" style="padding: 0px 5px;" />
                </div>

                <div v-else class="col-12 col-md-auto score_position_center">
                  <i class="ri-emotion-happy-fill score_size"
                    :class="score_1 == 5 ? 'color_select_1' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-happy-fill score_size"
                    :class="score_1 == 4 ? 'color_select_2' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-normal-fill score_size"
                    :class="score_1 == 3 ? 'color_select_3' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-unhappy-fill score_size"
                    :class="score_1 == 2 ? 'color_select_4' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-unhappy-fill score_size"
                    :class="score_1 == 1 ? 'color_select_5' : 'color_not_select'" style="padding: 0px 5px;" />
                </div>
              </div>

              <div class="row" style="align-items: center;">
                <div class="col-12 col-md-4" style="max-width: 516px;">
                  <span style="color: black; font-size: large;">ความพึงพอใจต่อหมอ</span>
                </div>
                <div v-if="checkLogin === false && checkSave === false" class="col-12 col-md-auto score_position_center">
                  <i class="ri-emotion-happy-fill score_size" @click="clickChangeColor_2(5)"
                    :class="score_2 == 5 ? 'color_select_1' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-happy-fill score_size" @click="clickChangeColor_2(4)"
                    :class="score_2 == 4 ? 'color_select_2' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-normal-fill score_size" @click="clickChangeColor_2(3)"
                    :class="score_2 == 3 ? 'color_select_3' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-unhappy-fill score_size" @click="clickChangeColor_2(2)"
                    :class="score_2 == 2 ? 'color_select_4' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-unhappy-fill score_size" @click="clickChangeColor_2(1)"
                    :class="score_2 == 1 ? 'color_select_5' : 'color_not_select'" style="padding: 0px 5px;" />
                </div>

                <div v-else class="col-12 col-md-auto score_position_center">
                  <i class="ri-emotion-happy-fill score_size"
                    :class="score_2 == 5 ? 'color_select_1' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-happy-fill score_size"
                    :class="score_2 == 4 ? 'color_select_2' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-normal-fill score_size"
                    :class="score_2 == 3 ? 'color_select_3' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-unhappy-fill score_size"
                    :class="score_2 == 2 ? 'color_select_4' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-unhappy-fill score_size"
                    :class="score_2 == 1 ? 'color_select_5' : 'color_not_select'" style="padding: 0px 5px;" />
                </div>
              </div>

              <div class="row" style="align-items: center;">
                <div class="col-12 col-md-4" style="max-width: 516px;">
                  <span style="color: black; font-size: large;">ความพึงพอใจต่อบุคลากร</span>
                </div>
                <div v-if="checkLogin === false && checkSave === false" class="col-12 col-md-auto score_position_center">
                  <i class="ri-emotion-happy-fill score_size" @click="clickChangeColor_3(5)"
                    :class="score_3 == 5 ? 'color_select_1' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-happy-fill score_size" @click="clickChangeColor_3(4)"
                    :class="score_3 == 4 ? 'color_select_2' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-normal-fill score_size" @click="clickChangeColor_3(3)"
                    :class="score_3 == 3 ? 'color_select_3' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-unhappy-fill score_size" @click="clickChangeColor_3(2)"
                    :class="score_3 == 2 ? 'color_select_4' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-unhappy-fill score_size" @click="clickChangeColor_3(1)"
                    :class="score_3 == 1 ? 'color_select_5' : 'color_not_select'" style="padding: 0px 5px;" />
                </div>

                <div v-else class="col-12 col-md-auto score_position_center">
                  <i class="ri-emotion-happy-fill score_size"
                    :class="score_3 == 5 ? 'color_select_1' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-happy-fill score_size"
                    :class="score_3 == 4 ? 'color_select_2' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-normal-fill score_size"
                    :class="score_3 == 3 ? 'color_select_3' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-unhappy-fill score_size"
                    :class="score_3 == 2 ? 'color_select_4' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-unhappy-fill score_size"
                    :class="score_3 == 1 ? 'color_select_5' : 'color_not_select'" style="padding: 0px 5px;" />
                </div>
              </div>

              <div class="row" style="align-items: center;">
                <div class="col-12 col-md-4" style="max-width: 516px;">
                  <span style="color: black; font-size: large;">ความพึงพอใจต่อสถานที่</span>
                </div>
                <div v-if="checkLogin === false && checkSave === false" class="col-12 col-md-auto score_position_center">
                  <i class="ri-emotion-happy-fill score_size" @click="clickChangeColor_4(5)"
                    :class="score_4 == 5 ? 'color_select_1' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-happy-fill score_size" @click="clickChangeColor_4(4)"
                    :class="score_4 == 4 ? 'color_select_2' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-normal-fill score_size" @click="clickChangeColor_4(3)"
                    :class="score_4 == 3 ? 'color_select_3' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-unhappy-fill score_size" @click="clickChangeColor_4(2)"
                    :class="score_4 == 2 ? 'color_select_4' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-unhappy-fill score_size" @click="clickChangeColor_4(1)"
                    :class="score_4 == 1 ? 'color_select_5' : 'color_not_select'" style="padding: 0px 5px;" />
                </div>

                <div v-else class="col-12 col-md-auto score_position_center">
                  <i class="ri-emotion-happy-fill score_size"
                    :class="score_4 == 5 ? 'color_select_1' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-happy-fill score_size"
                    :class="score_4 == 4 ? 'color_select_2' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-normal-fill score_size"
                    :class="score_4 == 3 ? 'color_select_3' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-unhappy-fill score_size"
                    :class="score_4 == 2 ? 'color_select_4' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-unhappy-fill score_size"
                    :class="score_4 == 1 ? 'color_select_5' : 'color_not_select'" style="padding: 0px 5px;" />
                </div>
              </div>

              <div class="row" style="align-items: center;">
                <div class="col-12 col-md-4" style="max-width: 516px;">
                  <span style="color: black; font-size: large;">ราคาสมเหตุสมผล</span>
                </div>
                <div v-if="checkLogin === false && checkSave === false" class="col-12 col-md-auto score_position_center">
                  <i class="ri-emotion-happy-fill score_size" @click="clickChangeColor_5(5)"
                    :class="score_5 == 5 ? 'color_select_1' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-happy-fill score_size" @click="clickChangeColor_5(4)"
                    :class="score_5 == 4 ? 'color_select_2' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-normal-fill score_size" @click="clickChangeColor_5(3)"
                    :class="score_5 == 3 ? 'color_select_3' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-unhappy-fill score_size" @click="clickChangeColor_5(2)"
                    :class="score_5 == 2 ? 'color_select_4' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-unhappy-fill score_size" @click="clickChangeColor_5(1)"
                    :class="score_5 == 1 ? 'color_select_5' : 'color_not_select'" style="padding: 0px 5px;" />
                </div>

                <div v-else class="col-12 col-md-auto score_position_center">
                  <i class="ri-emotion-happy-fill score_size"
                    :class="score_5 == 5 ? 'color_select_1' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-happy-fill score_size"
                    :class="score_5 == 4 ? 'color_select_2' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-normal-fill score_size"
                    :class="score_5 == 3 ? 'color_select_3' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-unhappy-fill score_size"
                    :class="score_5 == 2 ? 'color_select_4' : 'color_not_select'" style="padding: 0px 5px;" />
                  <i class="ri-emotion-unhappy-fill score_size"
                    :class="score_5 == 1 ? 'color_select_5' : 'color_not_select'" style="padding: 0px 5px;" />
                </div>
              </div>

              <div class="row" style="align-items: center;">
                <div class="col-12 col-md-4" style="max-width: 516px;">
                  <span style="color: black; font-size: large;">ข้อเสนอแนะอื่นๆ</span>
                </div>
                <div class="col-12 col-md-8">
                  <b-form-textarea v-model="comment_text" :disabled="checkLogin || checkSave" />
                </div>
              </div>

              <div v-if="checkLogin === false">
                <hr>

                <b-row style="justify-content: center;">
                  <div class="col-12 col-sm-auto">
                    <b-button v-if="checkSave === false" type="submit" variant="primary" @click="saveCommentCustomer()"
                      class="w-100 btn-min-size">ส่งข้อมูล</b-button>
                    <b-button v-else type="submit" variant="primary" class="w-100 btn-min-size"
                      disabled>ส่งข้อมูล</b-button>
                  </div>
                </b-row>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import moment from 'moment'
import axios from 'axios'
import { configAPI } from '../../configBase'

export default {
  name: 'CommentDetail',
  data () {
    return {
      checkLogin: false,
      checkSave: true,
      cc_id: '',
      cln_id: '',
      cus_id: '',
      score_1: 0,
      score_2: 0,
      score_3: 0,
      score_4: 0,
      score_5: 0,
      comment_text: '',
      serviceText: '',
      dateTimeText: ''
    }
  },
  methods: {
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    handleClickGoBack () {
      router.push('/comment')
    },
    clickChangeColor_1 (score) {
      this.score_1 = score
    },
    clickChangeColor_2 (score) {
      this.score_2 = score
    },
    clickChangeColor_3 (score) {
      this.score_3 = score
    },
    clickChangeColor_4 (score) {
      this.score_4 = score
    },
    clickChangeColor_5 (score) {
      this.score_5 = score
    },
    async getCommentHistoryCustomer () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/commentHistoryCustomer/${this.cc_id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status) {
            if (this.checkLogin === false && response.data.data[0]?.cc_is_save) {
              Vue.swal({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">ได้ทำการประเมินเรียบร้อยแล้ว</span>',
                icon: 'success',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000
              }).then(async () => {
                router.push('/login')
              })
            } else {
              this.checkSave = false
            }

            this.score_1 = response.data.data[0]?.cc_service_quility
            this.score_2 = response.data.data[0]?.cc_doctor
            this.score_3 = response.data.data[0]?.cc_personnel
            this.score_4 = response.data.data[0]?.cc_location
            this.score_5 = response.data.data[0]?.cc_price
            this.comment_text = response.data.data[0]?.cc_other_suggestions

            this.dateTimeText = moment(response.data.data[0]?.hc_created_date).add(543, 'year').format('DD/MM/YYYY : HH.mm')

            let text = ''

            if (response.data.data[0]?.serviceData) {
              for (let item of response.data.data[0].serviceData) {
                text += `${item.sg_name} (${item.st_name}), `
              }

              this.serviceText = text.substring(0, text.length - 2)
            }
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async saveCommentCustomer () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          Vue.swal({
            title: 'กำลังบันทึกข้อมูล',
            text: 'Loading',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Vue.swal.showLoading()
            }
          })

          await axios.request({
            method: 'post',
            url: `${configAPI.MA_PORT}/api/saveCommentCustomer/${this.cc_id}`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: {
              cc_id: this.cc_id,
              score_1: this.score_1,
              score_2: this.score_2,
              score_3: this.score_3,
              score_4: this.score_4,
              score_5: this.score_5,
              comment_text: this.comment_text
            }
          })
            .then((response) => {
              Vue.swal({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
                icon: 'success',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000
              }).then(async () => {
                router.push('/login')
              })
            })
            .catch((error) => {
              this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
            })
        }
      })
    }
  },
  async mounted () {
    xray.index()

    this.cc_id = this.$route.params.cc_id

    if (window.location.pathname.split('/')[1] === 'CommentDetail') {
      this.checkLogin = true
    }

    await this.getCommentHistoryCustomer()
  }
}
</script>

<style scoped>
.color_not_select {
  color: #9FA1A3;
}

.color_select_1 {
  color: #1ea757;
}

.color_select_2 {
  color: #73be86;
}

.color_select_3 {
  color: #f6ca11;
}

.color_select_4 {
  color: #ffa422;
}

.color_select_5 {
  color: #fa4e4e;
}

.score_size {
  font-size: 50px;
}

@media (max-width: 767px) {
  .score_position_center {
    text-align: center;
  }
}

@media (max-width: 424px) {
  .score_position_center {
    text-align: center;
  }

  .score_size {
    font-size: 35px;
  }
}
</style>
