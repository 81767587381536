<template>
  <b-container fluid>
      <form>
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:headerTitle>
                <div class="row align-items-center py-4 mx-0">
                  <div class="col-auto">
                    <span v-if="mode === 'add'" style="font-size: 25px; color: black;">ทะเบียนลูกค้า / ผู้ป่วย
                      </span>
                    <span v-else-if="mode === 'edit'"
                      style="font-size: 25px; color: black;">ทะเบียนลูกค้า / ผู้ป่วย (แก้ไข)</span>
                    <span v-else style="font-size: 25px; color: black;">ทะเบียนลูกค้า / ผู้ป่วย (ดูรายละเอียด)</span>
                  </div>
                  <div v-if="mode === 'add' || mode === 'edit'" class="col-12 col-sm-auto">
                    <div class="row">
                      <div class="col-12 col-sm-auto my-1 my-sm-0">
                        <b-button type="button" variant="primary" @click="handleClick" class="w-100 btn-min-size">บันทึก</b-button>
                      </div>
                      <!-- <div class="col-6 pl-2 pr-3 my-1 my-sm-0">
                        <b-button type="button" variant="danger" @click="handleClickGoBack" class="w-100 btn-min-size">ยกเลิก</b-button>
                      </div> -->
                    </div>
                  </div>
                  <!-- <div v-else class="col-12 col-sm-auto my-1 my-sm-0">
                    <b-button type="submit" variant="danger" @click="handleClickGoBack" class="w-100 btn-min-size">ยกเลิก</b-button>
                  </div> -->
                </div>
              </template>
              <template v-slot:body>
                <div class="new-user-info">
                  <b-row>
                    <b-col lg="6" sm="12" class="py-1" >
                      <b-form-group label="เบอร์โทรศัพท์" style="color: black;">
                      <the-mask mask="###-###-####" class="form-control" v-model="userAdd.phone" type="tel" :masked="false" placeholder="xxx-xxx-xxxx" :disabled="true"></the-mask>
                        <!-- <b-form-input type="text" :disabled="isDisable" @keypress="formatContact($event)" v-model="userAdd.phone" placeholder="xxx-xxx-xxxx" /> -->
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- <b-row>
                    <b-col lg="6" sm="12" class="py-1 mb-1" >
                      <b-form-group label="ข้อมูล HN" style="color: black;">
                        <b-form-input :disabled="true" type="text" v-model="userAdd.dataHN" ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row> -->
                  <b-row>
                    <b-col lg="6" sm="12" class="py-1 mb-1" >
                      <b-form-group label="ชื่อเล่น" style="color: black;">
                        <template v-if="userAdd.nickName === null || userAdd.nickName?.trim() === ''" v-slot:label>
                          ชื่อเล่น <span style="color: red;"> *</span>
                        </template>
                        <b-form-input :disabled="isDisable" type="text" v-model="userAdd.nickName" ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6" sm="12" class="py-1 mb-1" >
                      <div class="checkbox-address-ser">
                        <span v-if="!userAdd.isFollowAddress && (userAdd.address === null || userAdd.address?.trim() === '')" style="color: black;">ที่อยู่ปัจจุบัน<span style="color: red;"> *</span></span>
                        <span v-else style="color: black;">ที่อยู่ปัจจุบัน</span>
                        <b-form-checkbox class="" :disabled="isDisable" v-model="userAdd.isFollowAddress">
                          ตามที่อยู่บัตรประชาชน
                        </b-form-checkbox>
                      </div>
                      <b-form-input :disabled="isDisable || userAdd.isFollowAddress || isFollowAddressDisabled" type="text" v-model="userAdd.address" style=" margin: .3rem 0 1rem 0;" ></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group label="จังหวัด" label-for="province">
                        <template v-if="!userAdd.isFollowAddress && (userAdd.province === null || userAdd.province === '')" v-slot:label>
                          จังหวัด <!-- <span style="color: red;"> *</span> -->
                        </template>
                        <v-select v-model="provincesSelect" :disabled="isDisable || userAdd.isFollowAddress || isFollowAddressDisabled" :options="provinces"></v-select>
                      </b-form-group>
                      <!-- <the-mask mask="###-###-####" class="form-control" v-model="userAdd.phone" type="tel" :masked="false" placeholder="xxx-xxx-xxxx" :disabled="isDisable"></the-mask> -->
                      <!-- <label class="typo__label" style="margin: 0;">จังหวัด <span style="color: red;" v-if="userAdd.province === null || userAdd.province === ''">*</span></label> -->
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group label="อำเภอ / เขต" label-for="district">
                        <template v-if="((userAdd.district === null || userAdd.district === '') && districts?.length > 0) && !userAdd.isFollowAddress" v-slot:label>
                          อำเภอ / เขต <!-- <span style="color: red;"> *</span> -->
                        </template>
                        <v-select v-model="districtsSelect" :disabled="isDisable || provincesSelect === null || userAdd.province === null || districts?.length <= 0 || userAdd.isFollowAddress || isFollowAddressDisabled" :options="districts"></v-select>
                      </b-form-group>
                      <!-- <v-select v-model="provincesSelect" :disabled="isDisable" :options="provinces"></v-select> -->
                      <!-- <label class="typo__label" style="margin: 0;">อำเภอ / เขต <span style="color: red;" v-if="userAdd.district === null || userAdd.district === ''">*</span></label> -->
                    </b-col>
                  </b-row>
                  <!-- <br> -->
                  <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group label="ตำบล / แขวง" label-for="subdistrict">
                        <template v-if="((userAdd.subdistrict === null || userAdd.subdistrict === '') && subdistricts?.length > 0) && !userAdd.isFollowAddress" v-slot:label>
                          ตำบล / แขวง <!-- <span style="color: red;"> *</span> -->
                        </template>
                        <v-select v-model="subdistrictsSelect" :disabled="isDisable || provincesSelect === null || districtsSelect === null || userAdd.district === null || subdistricts?.length <= 0 || userAdd.isFollowAddress || isFollowAddressDisabled" :options="subdistricts"></v-select>
                      </b-form-group>
                      <!-- <v-select v-model="districtsSelect" :disabled="isDisable || provincesSelect === null" :options="districts"></v-select> -->
                      <!-- <label class="typo__label" style="margin: 0;">ตำบล / แขวง <span style="color: red;" v-if="userAdd.subdistrict === null || userAdd.subdistrict === ''">*</span></label> -->
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group label="รหัสไปรษณีย์" label-for="zipCode">
                        <template v-if="(userAdd.zipCode === null || userAdd.zipCode === '' || userAdd.zipCode?.trim().length < 5) && !userAdd.isFollowAddress" v-slot:label>
                          รหัสไปรษณีย์ <!-- <span style="color: red;"> *</span> -->
                        </template>
                        <the-mask mask="#####" class="form-control" v-model="userAdd.zipCode" :disabled="isDisable || userAdd.isFollowAddress || isFollowAddressDisabled"></the-mask>
                      </b-form-group>
                        <!-- <v-select v-model="subdistrictsSelect" :disabled="isDisable || provincesSelect === null || districtsSelect === null" :options="subdistricts"></v-select> -->
                        <!-- <v-select v-model="districtsSelect" :disabled="isDisable || provincesSelect === null" :options="districts"></v-select> -->
                      <!-- <label class="typo__label" style="margin: 0;">รหัสไปรษณีย์ <span style="color: red;" v-if="userAdd.zipCode === null || userAdd.zipCode === '' || userAdd.zipCode?.trim().length < 5">*</span></label> -->
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6" sm="12" class="py-1 mb-1" >
                      <b-form-group label="E-mail" style="color: black;">
                        <template v-if="userAdd.isEmail && (userAdd.email === null || userAdd.email?.trim() === '')" v-slot:label>
                          E-mail <span style="color: red;"> *</span>
                        </template>
                        <b-form-input :disabled="isDisable" type="text" v-model="userAdd.email" ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6" sm="12" class="py-1 mb-1" >
                      <b-form-group label="Line" style="color: black;">
                        <b-form-input :disabled="isDisable" type="text" v-model="userAdd.line" ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6" sm="12" class="py-1 mb-1" >
                      <b-form-group label="ช่องทางที่สะดวก" style="color: black;">
                        <template v-if="!userAdd.isEmail && !userAdd.isSMS && !userAdd.isMobile" v-slot:label>
                          ช่องทางที่สะดวก <span style="color: red;"> *</span>
                        </template>
                        <div class="checkbox-contact-ser">
                          <b-form-checkbox class="" :disabled="isDisable" v-model="userAdd.isEmail">
                            Email
                          </b-form-checkbox>
                          <b-form-checkbox class="" :disabled="isDisable" v-model="userAdd.isSMS">
                            SMS
                          </b-form-checkbox>
                          <b-form-checkbox class="" :disabled="isDisable" v-model="userAdd.isMobile">
                            Mobile
                          </b-form-checkbox>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                <br>
              </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </form>
  </b-container>
</template>

<style>
.checkbox-contact-ser {
  display: flex;
  gap: 4rem;
  justify-content: center;
  padding: 0 1rem;
}
.checkbox-address-ser {
  display: flex;
  gap: 1rem;
  align-items: flex-end;
}
.margin-text-header {
  margin-left: 1rem;
}
.padding-text-header {
  margin-left: 0;
  margin-right: 1rem;
}

[dir=ltr] .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #089bab !important;
}

.apg-response-header-btn {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
  width: 94% !important;
}

@media (max-width: 560px) {
  .checkbox-contact-ser {
    display: flex;
    gap: 3rem;
    justify-content: center;
    padding: 0 1rem;
  }

  .margin-text-header {
    margin-left: 0 !important;
  }
  .padding-text-header {
    margin-left: 0.5rem !important;
    margin-right: 0 !important;
  }
  .apg-response-header {
    display: flex;
    align-items: flex-start !important;
    flex-direction: column;
  }
  .apg-response-header-btn {
    margin-top: 0.5rem !important;
    width: 100% !important;
    max-width: 100% !important;
    /* padding-left: 1rem; */
    margin-left: 0.2rem !important;
    margin-right: 0 !important;
  }
  .iq-header-title {
    width: 100% !important;
  }
}
@media (max-width: 350px) {
  .checkbox-contact-ser {
    display: flex;
    gap: 1rem;
    justify-content: center;
    padding: 0 1rem;
  }
  .padding-text-header {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .apg-response-header-btn {
    margin-left: 0 !important;
    width: 100% !important;
    margin-right: 0 !important;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '../../router'
import Vue from 'vue'
import { mask } from 'vue-the-mask'
import Axios from 'axios'
import { configAPI } from '../../configBase'

export default {
  name: 'AddUseService',
  directives: { mask },
  async mounted () {
    await this.getProvinceDD()
    await this.getDistictDD()
    await this.getSubDistrictDD()
    const modeReceive = this.$route.path
    this.userAdd.phone = this.$route.params.phone
    if (modeReceive) {
      const mode = modeReceive.split('/')[1]
      if (mode === 'Register') {
        this.mode = 'add'
        this.clnId = this.$route.params.clinic
      } else if (mode === 'ReGister') {
        this.mode = 'edit'
        this.clnId = this.$route.params.clinic
        this.cusId = this.$route.params.cus_id
        await this.getCusById()
      } else if (mode === 'ReGisTer') {
        this.mode = 'view'
        this.isDisable = true
        this.clnId = this.$route.params.clinic
        this.cusId = this.$route.params.cus_id
        await this.getCusById()
      }
    }
    xray.index()
  },
  data () {
    return {
      clnId: null,
      cusId: null,
      mode: 'add',
      value: '',
      isDisable: false,
      isFollowAddressDisabled: false,
      userAdd: {
        phone: '',
        dataHN: null,
        nickName: null,
        isFollowAddress: false,
        address: null,
        email: null,
        line: null,
        isEmail: false,
        isSMS: false,
        isMobile: false,
        province: null,
        district: null,
        subdistrict: null,
        zipCode: null
      },
      provinces: [],
      provincesSelect: null,
      districts: [],
      districtAll: [],
      districtsSelect: null,
      subdistricts: [],
      subdistrictAll: [],
      subdistrictsSelect: null,
      userTemp: null
    }
  },
  watch: {
    'userAdd.province': function (newVal, oldVal) {
      if (newVal === null) {
        this.userAdd.district = null
        this.userAdd.subdistrict = null
        this.districtsSelect = null
        this.districts = []
        this.subdistrictsSelect = null
        this.subdistricts = []
      } else {
        this.districts = this.districtAll.filter(el => el.province_id === newVal)
      }
    },
    'userAdd.district': function (newVal, oldVal) {
      if (newVal === null) {
        this.userAdd.subdistrict = null
        this.subdistrictsSelect = null
        this.subdistricts = []
      } else {
        this.subdistricts = this.subdistrictAll.filter(el => el.district_id === newVal)
      }
    },
    provincesSelect (newVal, oldVal) {
      if (newVal === null) {
        this.userAdd.province = null
      } else if (newVal !== null && oldVal !== null) {
        this.userAdd.province = newVal.code
        this.districtsSelect = {
          code: null,
          label: null,
          province_id: null
        }
        this.subdistrictsSelect = {
          code: null,
          label: null,
          district_id: null
        }
        this.districts = this.districtAll.filter(el => el.province_id === newVal.code)
      } else {
        this.userAdd.province = newVal.code
        this.districts = this.districtAll.filter(el => el.province_id === newVal.code)
      }
    },
    districtsSelect (newVal, oldVal) {
      if (newVal === null) {
        this.userAdd.district = null
      } else if (newVal !== null && oldVal !== null) {
        this.userAdd.district = newVal.code
        this.subdistrictsSelect = {
          code: null,
          label: null,
          district_id: null
        }
        this.subdistricts = this.subdistrictAll.filter(el => el.district_id === newVal.code)
      } else {
        this.userAdd.district = newVal.code
        this.subdistricts = this.subdistrictAll.filter(el => el.district_id === newVal.code)
      }
    },
    subdistrictsSelect (newVal, oldVal) {
      if (newVal === null) {
        this.userAdd.subdistrict = null
      } else {
        this.userAdd.subdistrict = newVal.code
      }
    },
    'userAdd.isFollowAddress': async function (newVal, oldVal) {
      if (newVal === true) {
        this.isFollowAddressDisabled = true
        this.isFollowAddressDisabled = false
        this.userAdd.address = this.userTemp?.cus_address
        // this.userAdd.province = this.userTemp?.cus_province_id
        // this.userAdd.district = this.userTemp?.cus_district_id
        // this.userAdd.subdistrict = this.userTemp?.cus_sub_district_id
        this.userAdd.zipCode = this.userTemp?.cus_postcode
        let prov = this.provinces.find(el => el.code === this.userTemp?.cus_province_id)
        this.provincesSelect = prov
        let dist = this.districtAll.filter(el => el.province_id === this.userTemp?.cus_province_id).find(el2 => el2.code === this.userTemp?.cus_district_id)
        this.districtsSelect = dist
        let subd = this.subdistrictAll.filter(el => el.district_id === this.userTemp?.cus_district_id).find(el2 => el2.code === this.userTemp?.cus_sub_district_id)
        this.subdistrictsSelect = subd
      } else {
        this.isFollowAddressDisabled = false
        // this.userAdd.address = this.userTemp?.cus_address
        // // this.userAdd.province = this.userTemp?.cus_province_id
        // // this.userAdd.district = this.userTemp?.cus_district_id
        // // this.userAdd.subdistrict = this.userTemp?.cus_sub_district_id
        // this.userAdd.zipCode = this.userTemp?.cus_postcode
        // let prov = this.provinces.find(el => el.code === this.userTemp?.cus_province_id)
        // this.provincesSelect = prov
        // let dist = this.districtAll.filter(el => el.province_id === this.userTemp?.cus_province_id).find(el2 => el2.code === this.userTemp?.cus_district_id)
        // this.districtsSelect = dist
        // let subd = this.subdistrictAll.filter(el => el.district_id === this.userTemp?.cus_district_id).find(el2 => el2.code === this.userTemp?.cus_sub_district_id)
        // this.subdistrictsSelect = subd
      }
    }
  },
  methods: {
    async getProvinceDD () {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/province`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        // let tempDD = []
        let arr = res.data.data?.map(el => {
          return {
            label: el.name,
            code: el.id
          }
        })
        this.provinces = arr
      }).catch((err) => {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่สามารถดึงข้อมูลจังหวัดได้</span>',
          icon: 'error',
          html: `<span class="" style="font-size: 25px; color: black; text-align: center">${err}</span>`,
          showCancelButton: false,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab'
        })
      })
    },
    async getDistictDD () {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/district`,
        header: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        let temp = []
        res.data.data.map(el => {
          let temp2 = {
            label: el.name,
            code: el.id,
            province_id: el.province_id
          }
          temp.push(temp2)
        })
        this.districts = temp
        this.districtAll = temp
      }).catch((err) => {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่สามารถดึงข้อมูลอำเภอได้</span>',
          icon: 'error',
          html: `<span class="" style="font-size: 25px; color: black; text-align: center">${err}</span>`,
          showCancelButton: false,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab'
        })
      })
    },
    async getSubDistrictDD () {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/subdistrict`,
        header: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        let temp = []
        res.data.data.map(el => {
          let temp2 = {
            label: el.name,
            code: el.id,
            district_id: el.district_id
          }
          temp.push(temp2)
        })
        this.subdistricts = temp
        this.subdistrictAll = temp
      }).catch((err) => {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่สามารถดึงข้อมูลตำบลได้</span>',
          icon: 'error',
          html: `<span class="" style="font-size: 25px; color: black; text-align: center">${err}</span>`,
          showCancelButton: false,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab'
        })
      })
    },
    async getCusById () {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/customer/${this.cusId}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        let temp = res.data.data[0]
        this.userTemp = temp
        this.userAdd.nickName = temp.cus_nickname
        this.userAdd.isFollowAddress = temp.cus_is_address
        this.userAdd.address = temp.cus_address
        this.userAdd.zipCode = temp.cus_postcode
        this.userAdd.email = temp.cus_email
        this.userAdd.line = temp.cus_line
        this.provincesSelect = this.provinces.find(el => el.code === temp.cus_province_id)
        this.districtsSelect = this.districts.find(el => el.code === temp.cus_district_id)
        this.subdistrictsSelect = this.subdistricts.find(el => el.code === temp.cus_sub_district_id)
        let tempContact = temp.cus_contact?.split(',')
        if (tempContact?.includes('email')) {
          this.userAdd.isEmail = true
        }
        if (tempContact?.includes('sms')) {
          this.userAdd.isSMS = true
        }
        if (tempContact?.includes('mobile')) {
          this.userAdd.isMobile = true
        }
      }).catch((err) => {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่สามารถดึงข้อมูลลูกค้าได้</span>',
          icon: 'error',
          html: `<span class="" style="font-size: 25px; color: black; text-align: center">${err}</span>`,
          showCancelButton: false,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab'
        })
      })
    },
    async checkError () {
      let error = []
      if (this.userAdd.nickName === null || this.userAdd.nickName?.trim() === '') {
        error.push('กรุณากรอกชื่อเล่น')
      }
      if (!this.userAdd.isFollowAddress) {
        if (this.userAdd.address === null || this.userAdd.address?.trim() === '') {
          error.push('กรุณากรอกที่อยู่ปัจจุบัน')
        }
        /* if (this.userAdd.province === null || this.userAdd.province === '') {
          error.push('กรุณาเลือกจังหวัด')
        }
        if (this.userAdd.district === null || this.userAdd.district === '') {
          error.push('กรุณาเลือกอำเภอ / เขต')
        }
        if (this.userAdd.subdistrict === null || this.userAdd.subdistrict === '') {
          error.push('กรุณาเลือกตำบล / แขวง')
        }
        if (this.userAdd.zipCode === null || this.userAdd.zipCode?.trim() === '' || this.userAdd.zipCode?.trim().length < 5) {
          error.push('กรุณากรอกรหัสไปรษณีย์ให้ครบ 5 หลัก')
        } */
      }
      if (this.userAdd.isEmail === false && this.userAdd.isSMS === false && this.userAdd.isMobile === false) {
        error.push('กรุณาเลือกช่องทางที่สะดวก อย่างน้อย 1 ช่องทาง')
      }
      if (this.userAdd.isEmail) {
        if (this.userAdd.email === null || this.userAdd.email?.trim() === '') {
          error.push('กรุณากรอก E-mail')
        }
      }
      return error
    },
    async handleClick () {
      const errorList = await this.checkError()
      if (errorList.length > 0) {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลไม่สำเร็จ</span>',
          html: `<span class="" style="font-size: 25px; color: black; text-align: center">${errorList.join('<br>')}</span>`,
          icon: 'error',
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">Ok</b-button>',
          confirmButtonColor: '#089bab',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1'
          }
        })
        return
      }
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.mode !== 'edit' ? 'คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่?' : 'คุณมั่นใจว่าคุณจะแก้ไขข้อมูลใช่หรือไม่?'}</span>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Axios.request({
            method: 'GET',
            url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/checkCusByPhone/${this.userAdd.phone}/cus_id/${this.cusId}`,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(async (res) => {
            if (res.data.status) {
              // update
              let arrContact = []
              if (this.userAdd.isEmail) {
                arrContact.push('email')
              }
              if (this.userAdd.isSMS) {
                arrContact.push('sms')
              }
              if (this.userAdd.isMobile) {
                arrContact.push('mobile')
              }
              let dataForSave = {
                phone: this.userAdd.phone,
                nickName: this.userAdd.nickName,
                isFollowAddress: this.userAdd.isFollowAddress,
                address: this.userAdd.address,
                province: this.userAdd.province,
                district: this.userAdd.district,
                subdistrict: this.userAdd.subdistrict,
                zipCode: this.userAdd.zipCode === '' ? null : this.userAdd.zipCode,
                email: this.userAdd.email,
                line: this.userAdd.line,
                contact_way: arrContact.join(',')
              }
              await Axios.request({
                method: 'PUT',
                url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/register/${res.data.data.id}/phone`,
                headers: {
                  'Content-Type': 'application/json'
                },
                data: dataForSave
              }).then(() => {
                Vue.swal({
                  title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
                  icon: 'success',
                  text: 'ขอบคุณสำหรับการลงทะเบียน',
                  showConfirmButton: false,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  timer: 2000
                }).then(() => {
                  router.push(`/login`)
                })
              })
            } else {
              // insert
              // let arrContact = []
              // if (this.userAdd.isEmail) {
              //   arrContact.push('email')
              // }
              // if (this.userAdd.isSMS) {
              //   arrContact.push('sms')
              // }
              // if (this.userAdd.isMobile) {
              //   arrContact.push('mobile')
              // }
              // let dataForSave = {
              //   phone: this.userAdd.phone,
              //   nickName: this.userAdd.nickName,
              //   isFollowAddress: this.userAdd.isFollowAddress,
              //   address: this.userAdd.address,
              //   province: this.userAdd.province,
              //   district: this.userAdd.district,
              //   subdistrict: this.userAdd.subdistrict,
              //   zipCode: this.userAdd.zipCode === '' ? null : this.userAdd.zipCode,
              //   email: this.userAdd.email,
              //   line: this.userAdd.line,
              //   contact_way: arrContact.join(',')
              // }
              // await Axios.request({
              //   method: 'POST',
              //   url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/register/phone`,
              //   headers: {
              //     'Content-Type': 'application/json'
              //   },
              //   data: dataForSave
              // }).then(() => {
              //   Vue.swal({
              //     title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
              //     icon: 'success',
              //     text: 'ขอบคุณสำหรับการลงทะเบียน',
              //     showConfirmButton: false,
              //     allowEscapeKey: false,
              //     allowOutsideClick: false,
              //     timer: 2000
              //   }).then(() => {
              //     router.push(`/login`)
              //   })
              // })
              Vue.swal({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่สามารถบันทึกข้อมูลได้</span>',
                icon: 'error',
                text: `${res.data.error}`,
                showCancelButton: false,
                confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
                confirmButtonColor: '#089bab'
              })
            }
          })
        }
      })
    },
    handleClickGoBack () {
      if (this.mode === 'view') {
        router.push(`/login`)
      } else {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            router.go(-1)
          }
        })
      }
    },
    handleImageUpload2 (event) {
      const file2 = event.target.files[0]
      const reader2 = new FileReader()
      reader2.readAsDataURL(file2)
      this.userAdd.imageName = file2.name
      reader2.onload = () => {
        this.userAdd.imageUrl = reader2.result
      }
    },
    formatContact (e) {
      // ตรวจสอบว่ามีเฉพาะตัวเลขเท่านั้นในช่องกรอก
      // this.userAdd.phone = this.userAdd.phone.replace(/\D/g, '')
      let keyCode = (e.keyCode ? e.keyCode : e.which)
      if (this.userAdd.phone.length >= 12 || (keyCode < 48 || keyCode > 57)) {
        e.preventDefault()
        this.userAdd.phone = this.userAdd.phone.slice(0, 12)
      } else {
        /* this.userAdd.phone += e.key *//* .toString().replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') */
        this.userAdd.phone = this.userAdd.phone.toString().replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3')
      }
    },
    acceptNumber () {
      var x = this.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.value = !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '')
    }
  }
}
</script>
