<template>
    <b-container fluid>
      <b-row>
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <div class="row align-items-center py-4 mx-0">
                <div class="col-auto">
                  <span class="unselectable" style="font-size: 25px; color: black;">ใบสั่งซื้อ (PO)</span>

                </div>
                <div class="col-12 col-sm-auto my-1 my-sm-0">
                  <div style="" class="dflex-po-button">
                    <b-button @click="clickPath('add')" type="button" variant="primary-custom" class="w-100 btn-min-size">
                      <i class="circle-plus_ct"></i>
                      เพิ่มใบสั่งซื้อ
                    </b-button>
                    <b-button style="white-space: nowrap;" @click="prIdList = null, modelGeneratePO = true" type="button" variant="success" class="w-100">
                      <i class="circle-plus_ct"></i>
                      Generate PO
                    </b-button>
                  </div>
                </div>
                <!-- <div class="col-12 col-sm-auto my-1 my-sm-0">
                </div> -->
              </div>
            </template>
            <template v-slot:body>
              <div class="mx-3">
                <b-row style="align-items: flex-end;">
                  <b-col cols="12" lg="3" class="pb-3">
                  <div class="iq-email-search d-flex">
                    <div style="width: 100%;">
                      <div class="form-group mb-0" style="height: 45px;">
                        <input v-model="search" @keydown="enterSearch" class="form-control" id="exampleInputEmail1" placeholder="Search"
                          style="width: 100%; height: 45px;">
                        <i style="color: #676767; top: 4px" class="search-link ri-search-line" />
                      </div>
                    </div>
                  </div>
                </b-col>

                <b-col cols="6" lg="2" class="pb-3">
                  <b-text style="color: black; white-space: nowrap">วันที่ใบสั่งซื้อ (เริ่มต้น)</b-text>
                  <div style="position: relative; margin-top: .4rem;" class="w-res-attendance time-picker-attendance">
                    <date-picker v-if="searchEndDate" placeholder="เลือกวันที่" id="datepicker-custom-1"
                      :config="optionsDatepickerStart" v-model="searchStartDate"></date-picker>
                    <date-picker v-else placeholder="เลือกวันที่" id="datepicker-custom-1"
                      :config="optionsDatepickerDefault" v-model="searchStartDate"></date-picker>
                    <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(1)"
                      style="cursor: pointer;"></i>
                  </div>
                </b-col>

                <b-col cols="6" lg="2" class="pb-3">
                  <b-text style="color: black; white-space: nowrap">วันที่ใบสั่งซื้อ (สิ้นสุด)</b-text>
                  <div style="position: relative; margin-top: .4rem;" class="w-res-attendance time-picker-attendance">
                    <date-picker v-if="searchStartDate" placeholder="เลือกวันที่" id="datepicker-custom-2"
                      :config="optionsDatepickerEnd" v-model="searchEndDate"></date-picker>
                    <date-picker v-else placeholder="เลือกวันที่" id="datepicker-custom-2"
                      :config="optionsDatepickerDefault" v-model="searchEndDate"></date-picker>
                    <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(2)"
                      style="cursor: pointer;"></i>
                  </div>
                </b-col>

                <b-col cols="12" lg="auto" class="pb-3">
                  <b-button @click="getAllPurchaseOrder" type="button" variant="primary-custom"
                    class="w-100 btn-min-size" style="height: 45px;">
                    <i class="ri-search-line"></i>
                    ค้นหา
                  </b-button>
                </b-col>
                  <b-col cols="auto" class="pb-3">
                    <div style="display: flex; align-items: center; height: 45px;">
                      <span class="mx-2 font-size-14 text-primary-custom">
                        {{ rows_po.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage *
                          currentPage) > rows_po?.length) ? rows_po.length : (perPage * currentPage)
                        ) + ' of ' + rows_po_length : '0 of ' + rows_po_length }} รายการ
                      </span>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-col cols="12">
                <div class="table-responsive">
                  <b-table bordered hover :items="rows_po" :fields="columns_pr" :current-page="currentPage"
                    :per-page="perPage" @pagination="onPagination" :tbody-tr-class="rowClass"
                    thead-class="table-header-color">
                    <template v-slot:cell(no)="data">
                      <span>{{ ((currentPage - 1) * 10) + data.index + 1 }}</span>
                    </template>
                    <template v-slot:cell(po_no)="data">
                      <span>{{ data.item.po_no || '-' }}</span>
                      <!-- <span v-if="data.item.quo_count === '0'">{{ data.item.quo_no || '-' }}</span>
                        <a v-else style="color: #479CAC; cursor: pointer;" @click="ShowModalVersionQuo(data.item)"><span>{{ data.item.quo_no || '-' }}</span></a> -->
                    </template>
                    <template v-slot:cell(po_sup)="data">
                      <span>{{ data.item.po_sup_name || '-' }}</span>
                    </template>
                    <template v-slot:cell(po_created_date)="data">
                      <span>{{ formatDate(data.item.po_date) || '-' }}</span>
                    </template>
                    <template v-slot:cell(po_price)="data">
                      <span>{{ formatNumber(data.item.po_total_price) || '-' }}</span>
                    </template>
                    <template v-slot:cell(po_contact_name)="data">
                      <span>{{ data.item.po_sup_contact_name || '-' }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                      <b-button v-if="data.item.po_pos_id === 'f13f9448-c683-4149-aaaf-02bd7190f7cb'"
                        variant=" iq-bg-warning-custom btn-warning-border" style="min-width: 100px; cursor:default;">{{
                          data.item.pos_name }}</b-button>

                      <b-button v-else-if="data.item.po_pos_id === 'cb77c408-2823-4b5f-8f92-fb1c10af117b'"
                        variant=" iq-bg-success-custom btn-success-border" style="min-width: 100px; cursor:default;">{{
                          data.item.pos_name }}</b-button>

                      <b-button v-else-if="data.item.po_pos_id === 'c5de2506-fb4f-40d7-bc23-076e1d5e0b3c'"
                        variant=" iq-bg-info-custom btn-info-border" style="min-width: 100px; cursor:default;">{{
                          data.item.pos_name }}</b-button>

                      <b-button v-else-if="data.item.po_pos_id === '6ce3957c-3df2-46c4-9116-b8dcdb40a590'"
                        variant=" bg-danger-custom" style="min-width: 100px;"
                        @click.prevent="reasonModal = true, reasonTextShow = data.item.po_reason_not_approve">{{
                          data.item.pos_name }}</b-button>

                      <b-button v-else-if="data.item.po_pos_id === 'a5a10d90-ef99-4164-a9bc-d885e521d67c'"
                          variant=" iq-bg-info-custom btn-info-border" style="min-width: 100px; cursor:default;">{{
                            data.item.pos_name }}</b-button>

                      <span v-else>{{ '-' }}</span>
                    </template>
                    <template v-slot:cell(management)="data">
                      <div style="display: flex;">
                        <b-button variant=" iq-bg-eye-custom btn-eye-border mx-1" size="sm"
                          @click="clickPath('view', data.item.po_id)"
                        >
                          <i class="ri-eye-fill m-0"></i>
                        </b-button>
                        <b-button :hidden="CanClickBtn(data.item.po_pos_id)" variant=" iq-bg-pencil-custom btn-pencil-border mx-1" size="sm"
                          @click="clickPath('edit', data.item.po_id)"
                        >
                          <i class="ri-pencil-fill m-0"></i>
                        </b-button>
                        <b-button :hidden="CanClickBtn(data.item.po_pos_id)" variant=" iq-bg-bin-custom btn-bin-border mx-1" size="sm"
                          @click="deletePurchaseOrder(data.item.po_id)"
                        >
                          <i class="ri-delete-bin-line m-0"></i>
                        </b-button>
                        <b-button variant=" iq-bg-report-custom btn-report-border mx-1" size="sm"
                          style="cursor: not-allowed;">
                          <i class="ri-article-line m-0"></i>
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                </div>
              </b-col>
              <b-pagination v-model="currentPage" :total-rows="rows_po?.length" :per-page="perPage" align="center" />
            </template>

            <!-- Model Generate PO -->
            <template>
              <b-modal v-model="modelGeneratePO" title="เลือกใบขอซื้อ" cancel-title="ยกเลิก" ok-title="เพิ่ม" @ok="onSubmitProductModal" @close="onCloseProductModal" size="lg" :no-close-on-esc="true" :no-close-on-backdrop="true">
                <div class="select-muti-po">
                  <v-select multiple v-model="prIdList" :disabled="isDisable" :options="purchaseOrderDDmap"></v-select>
                </div>
                <template #modal-footer>
                  <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                    <b-button
                      style="border-radius: 0.5rem; padding: 0 1.1rem !important"
                      variant="primary-custom"
                      class="btn-min-size"
                      size="sm"
                      :disabled="!prIdList || prIdList.length === 0"
                      @click="onSubmitModelGeneratePO"
                    >
                    <i class="save-data_ct"/>บันทึก
                    </b-button>
                    <b-button
                      variant="danger"
                      class="btn-min-size btn-cancel-border"
                      style="border-radius: 0.5rem;"
                      size="sm"
                      @click="modelGeneratePO=false"
                    >
                      ยกเลิก
                    </b-button>
                  </div>
                </template>
              </b-modal>
            </template>
          </iq-card>
        </b-col>
      </b-row>

      <!-- Reason Modal  -->
      <b-modal v-model="reasonModal" title="ไม่ผ่านการอนุมัติ" size='lg' centered @close.prevent="reasonModal = false"
        hide-footer>
        <div style="margin: 10px 30px;">
          <span style="word-break: break-word; color: black;">{{ reasonTextShow }}</span>
        </div>
      </b-modal>
    </b-container>
</template>

<style>
.dflex-po-button {
  display: flex; gap: .5rem;
  flex-direction: row !important;
}

@media (max-width: 376px) {
  .dflex-po-button {
    display: flex; gap: .5rem;
    flex-direction: column !important;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import axios from 'axios'
import moment from 'moment'
import { configAPI } from '../../configBase'

const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}
export default {
  name: 'PurchaseOrderTable',
  data () {
    return {
      clnId: null,
      userId: null,
      search: '',
      modelGeneratePO: false,
      prIdList: null,
      prListDD: [],
      mode: 'showAll',
      columns_pr: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        { label: 'เลขที่ใบสั่งซื้อ', key: 'po_no', class: 'text-center table-size-200-400' },
        { label: 'ซัพพลายเออร์', key: 'po_sup', class: 'text-center table-size-200-400' },
        { label: 'วันที่ใบสั่งซื้อ', key: 'po_created_date', class: 'text-center table-size-200-400' },
        { label: 'ราคา', key: 'po_price', class: 'text-center table-size-200-400' },
        { label: 'ชื่อผู้ติดต่อ', key: 'po_contact_name', class: 'text-center table-size-200-400' },
        { label: 'สถานะ', key: 'status', class: 'text-center table-size-200-400' },
        // { label: 'Preview', key: 'preview', class: 'text-center table-size-200-400' },
        // { label: 'Report', key: 'report', class: 'text-center table-size-100-300' },
        // { label: 'Remark', key: 'remark', class: 'text-center table-size-200-400' },
        { label: 'Management', key: 'management', class: 'text-center table-size-200-400' }
      ],
      reasonModal: false,
      reasonTextShow: '',
      searchStartDate: null,
      searchEndDate: null,
      optionsDatepickerStart: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: false,
        minDate: false,
        maxDate: false,
        icons: iconDate
      },
      optionsDatepickerEnd: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: false,
        minDate: false,
        maxDate: false,
        icons: iconDate
      },
      rows_po: [
        {
          no: 1,
          pr_no: 'PR-2021-0001',
          requester: 'นาย สมชาย ใจดี',
          create_date: '01/01/2021',
          delivery_date: '01/01/2021',
          approve_name: 'นาย สมชาย ใจดี',
          status_id: 'd450c15f-5cd2-4fdd-9736-c60e73be6fbb',
          status_name: 'รออนุมัติ',
          remark: 'ไม่มี'
        },
        {
          no: 2,
          pr_no: 'PR-2021-0002',
          requester: 'นาย สมชาย ใจดี',
          create_date: '01/01/2021',
          delivery_date: '01/01/2021',
          approve_name: 'นาย สมชาย ใจดี',
          status: '91c37841-61cf-4208-8fcf-3002d46e0cd7',
          status_name: 'อนุมัติ',
          remark: 'ไม่มี'
        },
        {
          no: 3,
          pr_no: 'PR-2021-0003',
          requester: 'นาย สมชาย ใจดี',
          create_date: '01/01/2021',
          delivery_date: '01/01/2021',
          approve_name: 'นาย สมชาย ใจดี',
          status: 'b3848d1a-cc46-4744-8158-58a6f8908869',
          status_name: 'เสร็จสิ้น',
          remark: 'ไม่มี'
        },
        {
          no: 4,
          pr_no: 'PR-2021-0004',
          requester: 'นาย สมชาย ใจดี',
          create_date: '01/01/2021',
          delivery_date: '01/01/2021',
          approve_name: 'นาย สมชาย ใจดี',
          status: 'c24fdbfb-6222-4842-8fd1-98b23839ccae',
          status_name: 'ไม่อนุมัติ',
          remark: 'ไม่มี'
        }
      ],
      rows_po_length: 3,
      currentPage: 1,
      perPage: 10,
      currentPageModal: 1,
      perPageModal: 10,
      AllDataLength: null,
      optionsDatepickerDefault: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: false,
        minDate: false,
        maxDate: false,
        icons: iconDate
      }
    }
  },
  computed: {
    purchaseOrderDDmap () {
      return this.prListDD?.map(option => ({ code: option.pr_id, label: option.pr_no }))
    }
  },
  watch: {
    'searchStartDate': function (newDate) {
      this.optionsDatepickerEnd.minDate = newDate
    },
    'searchEndDate': function (newDate) {
      this.optionsDatepickerStart.maxDate = newDate
    }
  },
  async mounted () {
    this.clnId = JSON.parse(sessionStorage.getItem('user')).cln_id || null
    this.userId = JSON.parse(sessionStorage.getItem('user')).usr_id || null
    await this.getAllPurchaseOrder()
    await this.getPRForDD()
    xray.index()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getAllPurchaseOrder()
      }
    },
    async getPRForDD () {
      await axios.request({
        method: 'get',
        url: `${configAPI.SALE_PORT}/api/purchaseRequisition/clinic/${this.clnId}/getPRApprove`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          this.prListDD = response.data.data?.sort((a, b) => new Date(a.pr_create_date) - new Date(b.pr_create_date))
          /* this.dataList = response.data.data
          this.dataListLength = response.data.count || 0
          this.employeegroup = response.data
          this.dataListLength = response.data.count || 0 */
        }).catch((err) => {
          // console.log('err', err)
          this.SwalError(err, 'Error')
        })
    },
    async getAllPurchaseOrder () {
      const searchData = {
        search: this.search.trim() || null,
        searchStartDate: this.searchStartDate
          ? moment(this.searchStartDate, 'DD/MM/YYYY').startOf('day')
          : null,
        searchEndDate: this.searchEndDate
          ? moment(this.searchEndDate, 'DD/MM/YYYY').endOf('day')
          : null
      }

      await axios.request({
        method: 'post',
        url: `${configAPI.SALE_PORT}/api/purchaseOrder/clinic/${this.clnId}/getPOAllByClinic`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: searchData
      }).then((response) => {
        this.rows_po = response.data.data
        this.rows_po_length = response.data.dataCount
      }).catch((error) => {
        this.SwalError(error.message, 'เกิดข้อผิดพลาดในดึงข้อมูล')
      })
    },
    async onSubmitModelGeneratePO () {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะสร้างใบสั่งซื้อใช่หรือไม่</span>`,
        // html: '<span>สินค้าและวัตถุดิบที่คุณเลือกจะถูกล้าง</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        confirmButtonColor: '#0085FF',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.modelGeneratePO = false
          await this.generatePO()
        }
      })
    },
    async generatePO () {
      Vue.swal.fire({
        title: '<span class="loading-icon"><i class="fas fa-spinner fa-spin"></i></span>',
        text: 'กำลังสร้างใบสั่งซื้อ...',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          Vue.swal.getHtmlContainer().querySelector('.loading-icon').style.fontSize = '40px'
        }
      })
      await axios.request({
        method: 'post',
        url: `${configAPI.SALE_PORT}/api/purchaseOrder/clinic/${this.clnId}/generatePRtoPO`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: { userId: this.userId, prIdList: this.prIdList }
      })
        .then(async (response) => {
          Vue.swal.close()
          if (response.data.status) {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">สร้างใบสั่งซื้อสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(async (res) => {
              await this.getAllPurchaseOrder()
            })
          } else {
            this.SwalError(response.data.error, 'Generate PO ไม่สำเร็จ')
          }
        }).catch((err) => {
          Vue.swal.close()
          this.SwalError(err, 'เกิดข้อผิดพลาด')
        })
    },
    async deletePurchaseOrder (poId) {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะลบข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await axios.request({
            method: 'get',
            url: `${configAPI.SALE_PORT}/api/purchaseOrder/clinic/${this.clnId}/delPurchaseOrder/${poId}`,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              timer: 1500
            }).then(async () => {
              await this.getAllPurchaseOrder()
            })
          }).catch((err) => {
            this.SwalError(err.message, 'ลบข้อมูลไม่สำเร็จ')
          })
        }
      })
    },
    CanClickBtn (statusId) {
      const arr = ['cb77c408-2823-4b5f-8f92-fb1c10af117b', 'f13f9448-c683-4149-aaaf-02bd7190f7cb', 'a5a10d90-ef99-4164-a9bc-d885e521d67c']
      return arr.includes(statusId)
    },
    formatDate (date) {
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      } else {
        return '-'
      }
    },
    onPagination (page) {
      this.currentPage = page
    },
    formatNumber (number) {
      if (number) {
        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } else {
        return '-'
      }
    },
    clickPath (e, data) {
      const setPage = e
      if (setPage === 'add') {
        router.push(`/PurchaseOrder/mgt/${setPage}`)
      } else if (setPage === 'view') {
        router.push(`/PurchaseOrder/mgt/${setPage}/${data}`)
      } else if (setPage === 'edit') {
        router.push(`/PurchaseOrder/mgt/${setPage}/${data}`)
      } else if (setPage === 'history') {
        router.push(`/PurchaseOrder/history/${data}`)
      }
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return ''
      const index = this.rows_po.indexOf(item) + 1
      if (index % 2 === 1) return 'table-body-color'
    }
  }
}
</script>
