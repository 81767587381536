<template>
    <b-container fluid>
      <b-row>
        <b-col ld="12">
          <iq-card>
            <template v-slot:headerTitle >
              <div class="row align-items-center py-3">
                <div class="col-6 col-lg-3 col-sm-2">
                  <a class="px-lg-3 mb-0" style="font-size: 25px; color: black; white-space: nowrap; padding: 0 !important;">ค้นหาหมอ Part-time</a>
                </div>
              </div>
            </template>
            <template v-slot:body>
              <b-row >
                <b-col lg="2" sm="6">
                  <!-- <b-text class="" style="color: black;">สาขาเฉพาะทาง</b-text> -->
                  <b-form-group label-for="exampleFormControlSelect1" label="สาขาเฉพาะทาง">
                    <b-form-input type="text" @keydown="enterSearch" v-model="partTimeData.specialized" ></b-form-input>
                    <!-- <v-select v-model="partTimeData.item1" :options="items1"></v-select> -->
                  </b-form-group>
                  <!-- <b-form-select class="my-1 form-control d-flex" plain :options="items1" size="lg" v-model="partTimeData.item1">
                    <template v-slot:first>
                      <b-form-select-option :value="null">Select</b-form-select-option>
                    </template>
                  </b-form-select> -->
                </b-col>
                <b-col lg="2" sm="6">
                  <b-form-group label-for="exampleFormControlSelect1" label="ประเภทงานที่รับ">
                    <v-select v-model="partTimeData.is_receive" :options="typeWork"></v-select>
                  </b-form-group>
                  <!-- <b-text class="" style="color: black;">ประเภทงานที่รับ</b-text>
                  <b-form-select class="my-1 form-control d-flex" plain :options="items1" size="lg" v-model="partTimeData.item2">
                    <template v-slot:first>
                      <b-form-select-option :value="null">Select</b-form-select-option>
                    </template>
                  </b-form-select> -->
                </b-col>
                <b-col lg="2" sm="6">
                  <b-form-group label-for="exampleFormControlSelect1" label="พื้นที่สะดวกรับงาน">
                    <v-select v-model="partTimeData.aw_id" :options="areaworkDD"></v-select>
                  </b-form-group>
                  <!-- <b-text class="" style="color: black;">พื้นที่สะดวกรับงาน</b-text>
                  <b-form-select class="my-1 form-control d-flex" plain :options="items1" size="lg" v-model="partTimeData.item3">
                    <template v-slot:first>
                      <b-form-select-option :value="null">Select</b-form-select-option>
                    </template>
                  </b-form-select> -->
                </b-col>
                <b-col lg="3" sm="6">
                  <b-form-group label-for="exampleFormControlSelect1" label="ค่าเวร">
                    <v-select v-model="partTimeData.mi_id" :options="minincomeDD"></v-select>
                  </b-form-group>
                  <!-- <b-text class="" style="color: black;">ค่าเวร</b-text>
                  <b-form-select class="my-1 form-control d-flex" plain :options="items1" size="lg" v-model="partTimeData.item4">
                    <template v-slot:first>
                      <b-form-select-option :value="null">Select</b-form-select-option>
                    </template>
                  </b-form-select> -->
                </b-col>
                <b-col sm="12" lg="3" class="col-12 col-lg-3 pt-4-edit-res-partTime d-flex align-items-center justify-content-start padding-respnose-pt-left padding-respnose-pt-right gap-res-parttme" style="margin-bottom: .3rem;">
                  <b-button type="submit" variant="primary" @click="handleClickSearch" class="w-100 btn-min-size btn-defind-partTime"><i class="search_ct"/>ค้นหา</b-button>
                  <b-button type="submit" variant="none" @click="handleClickClear" class="w-100 btn-min-size btn-defind-partTime btn-reset_ct">ล้างข้อมูล</b-button>
                </b-col>
                <!-- <b-col sm="6" class="col-6 edit-col-lg-1 col-lg-2 pt-4-edit-res-partTime d-flex align-items-center justify-content-center padding-respnose-pt-right">
                </b-col> -->
              </b-row>
              <br>
                <div v-if="selectedOption === 'option1'">
                  <b-row>
                    <b-col class="col-12 col-sm-12 col-md-6 col-lg-3" v-for="doctor in doctors" :key="doctor.message">
                        <iq-card body-class="text-center" style="box-shadow: 0px 0px 10px 0px #b8babb;">
                          <template v-slot:body>
                            <div class="doc-profile">
                              <img class="rounded-circle img-fluid avatar-80" :src="getFullImagePath(doctor.image)" alt="profile" @error="$event.target.src='https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png'">
                            </div>
                            <div class="iq-doc-info mt-3">
                              <h4> {{doctor.name}}</h4>
                              <p class="mb-0" >{{doctor.specialist}}</p>
                              <p class="mb-0 mt-1" style="color: #089bab;">{{doctor.typeDoctor}}</p>
                              <h4 style="color: #089bab;"> {{doctor.priceEmploy}} &#3647;</h4>
                            </div>
                            <div class="iq-doc-social-info mt-2 mb-3">
                              <ul class="m-0 p-0" style="display: flex;
                                flex-direction: column;
                                align-items: center;"
                              >
                                <li style="display: flex; align-items: center; color: #089bab;"><a style="background: none;" ><i class="ri-mail-line"></i></a><p class="mb-0">{{doctor.mail}}</p></li>
                                <li style="display: flex; align-items: center; color: #089bab;"><a style="background: none;" ><i class="ri-phone-line"></i></a><p class="mb-0">{{doctor.phone}}</p></li>
                              </ul>
                            </div>
                            <div>
                              <button @click="handleClickViewProfile(doctor.id)" class="btn btn-primary">View Profile</button>
                            </div>
                          </template>
                        </iq-card>
                    </b-col>
                  </b-row>
                </div>
                <div v-if="selectedOption === 'option2'">
                    <b-col md="12" class="table-responsive">
                      <b-table bordered hover :items="rows" :fields="columns" :current-page="currentPage" :per-page="perPage" @pagination="onPagination">
                      <template v-slot:cell(variable1)="data">
                        <span v-if="!data.item.editable">{{ data.item.variable1 }}</span>
                        <input type="text" v-model="data.item.variable1" v-else class="form-control">
                      </template>
                      <template v-slot:cell(variable2)="data">
                        <span v-if="!data.item.editable">{{ data.item.variable2 }}</span>
                        <input type="text" v-model="data.item.variable2" v-else class="form-control">
                      </template>
                      <template v-slot:cell(variable3)="data">
                        <span v-if="!data.item.editable">{{ data.item.variable3 }}</span>
                        <input type="text" v-model="data.item.variable3" v-else class="form-control">
                      </template>
                      <template v-slot:cell(variable4)="data">
                        <span v-if="!data.item.editable">{{ data.item.variable4 }}</span>
                        <input type="text" v-model="data.item.variable4" v-else class="form-control">
                      </template>
                      <template v-slot:cell(variable5)="data">
                        <span v-if="!data.item.editable">{{ data.item.variable5 }}</span>
                        <input type="text" v-model="data.item.variable5" v-else class="form-control">
                      </template>
                      <template v-slot:cell(variable6)="data">
                        <span v-if="!data.item.editable">{{ data.item.variable6 }}</span>
                        <input type="text" v-model="data.item.variable6" v-else class="form-control">
                      </template>
                      <template v-slot:cell(variable7)="data">
                        <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                        <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submit(data.item)" v-else>Ok</b-button>
                        <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                      </template>
                    </b-table>
                    <br>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows.length"
                      :per-page="perPage"
                      align="end"
                    />
                  </b-col>
                </div>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>

<style>
.gap-res-parttme {
  gap: 1rem !important
}
.pt-4-edit-res-partTime {
  padding-top: 1.5rem !important;
}
.vs--single .vs__selected {
    background-color: transparent !important;
    border-color: transparent !important;
    display: flow-root !important;
    padding-top: .3rem !important;
}

.vs__clear {
  margin: 0 .5rem !important;
}
.edit-col-lg-1 {
  -ms-flex: 0 0 8.333333% !important;
  -webkit-box-flex: 0 !important;
  flex: 0 0 12% !important;
  max-width: 12% !important;
}

@media (min-width: 993px) {
  .btn-defind-partTime {
    max-width: 20px !important;
  }
}
@media (max-width: 992px) {
  .gap-res-parttme {
    gap: 2rem !important
  }
  .pt-4-edit-res-partTime {
    padding-top: .25rem !important;
  }
  .edit-col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}
@media (max-width: 475px) {
  .gap-res-parttme {
    gap: 2rem !important
  }
  /* .padding-respnose-pt-left {
    padding-right: 0.5rem !important;
  }
  .padding-respnose-pt-right {
    padding-left: 0.5rem !important;
  } */
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import vSelect from 'vue-select'
import axios from 'axios'
import Vue from 'vue'
import { configAPI } from '../../configBase'

export default {
  data () {
    return {
      name: 'UiDataTable',
      components: { vSelect },
      activeTab: 'tab1',
      partTimeData: {
        specialized: null,
        is_receive: null,
        aw_id: null,
        mi_id: null
      },
      typeWork: [
        { code: 'Injection', label: 'Injection' },
        { code: 'Surgery', label: 'Surgery' }
      ],
      areaworkDD: [],
      areaworkOption: null,
      minincomeDD: [],
      minincomeOption: null,
      items1: [
        { code: 'Supplier1', label: 'One' },
        { code: 'Supplier2', label: 'Two' },
        { code: 'Supplier3', label: 'Three' },
        { code: 'Supplier4', label: 'Four' },
        { code: 'Supplier5', label: 'Five' }
      ],
      selectedOption: 'option1',
      doctors: [
        { id: '1', name: 'Dr. Anna Mull', specialist: 'สาขาผิวหนัง', image: require('../../assets/images/user/1.jpg'), typeDoctor: 'Injection, Surgery', priceEmploy: '9000', phone: '099-0009999', mail: 'demo@smartClinic.com' },
        { id: '2', name: 'Dr. Bunny Joy', specialist: 'สาขาทันตแพทย์', image: require('../../assets/images/user/01.jpg'), typeDoctor: 'Injection, Surgery', priceEmploy: '9000', phone: '099-0009999', mail: 'demo@smartClinic.com' },
        { id: '3', name: 'Dr. Shonda Leer', specialist: 'สาขาผ่าตัด', image: require('../../assets/images/user/02.jpg'), typeDoctor: 'Surgery', priceEmploy: '9000', phone: '099-0009999', mail: 'demo@smartClinic.com' },
        { id: '4', name: 'Dr. Ira Membrit', specialist: 'สาขาร้อยไหม', image: require('../../assets/images/user/03.jpg'), typeDoctor: 'Injection, Surgery', priceEmploy: '9000', phone: '099-0009999', mail: 'demo@smartClinic.com' },
        { id: '5', name: 'Dr. Paul Molive', specialist: 'สาขาทันตแพทย์', image: require('../../assets/images/user/04.jpg'), typeDoctor: 'Surgery', priceEmploy: '9000', phone: '099-0009999', mail: 'demo@smartClinic.com' },
        { id: '6', name: 'Dr. Dr. Terry Aki', specialist: 'สาขาร้อยไหม', image: require('../../assets/images/user/05.jpg'), typeDoctor: 'Injection', priceEmploy: '9000', phone: '099-0009999', mail: 'demo@smartClinic.com' },
        { id: '7', name: 'Dr. Poppa Cherry', specialist: 'สาขาผ่าตัด', image: require('../../assets/images/user/06.jpg'), typeDoctor: 'Surgery', priceEmploy: '9000', phone: '099-0009999', mail: 'demo@smartClinic.com' },
        { id: '8', name: 'Dr. Dr. Saul T. Balls', specialist: 'สาขาทันตแพทย์', image: require('../../assets/images/user/07.jpg'), typeDoctor: 'Surgery', priceEmploy: '9000', phone: '099-0009999', mail: 'demo@smartClinic.com' },
        { id: '9', name: 'Dr. Hal Appeno', specialist: 'สาขาผ่าตัด', image: require('../../assets/images/user/08.jpg'), typeDoctor: 'Surgery', priceEmploy: '9000', phone: '099-0009999', mail: 'demo@smartClinic.com' },
        { id: '10', name: 'Dr. Polly Tech', specialist: 'สาขาร้อยไหม', image: require('../../assets/images/user/09.jpg'), typeDoctor: 'Injection', priceEmploy: '9000', phone: '099-0009999', mail: 'demo@smartClinic.com' },
        { id: '11', name: 'Dr. Pat Agonia', specialist: 'สาขาร้อยไหม', image: require('../../assets/images/user/10.jpg'), typeDoctor: 'Surgery', priceEmploy: '9000', phone: '099-0009999', mail: 'demo@smartClinic.com' },
        { id: '12', name: 'Dr. Barry Cade', specialist: 'สาขาผ่าตัด', image: require('../../assets/images/user/12.jpg'), typeDoctor: 'Surgery', priceEmploy: '9000', phone: '099-0009999', mail: 'demo@smartClinic.com' },
        { id: '13', name: 'Dr.Jimmy Changa', specialist: 'สาขาทันตแพทย์', image: require('../../assets/images/user/12.jpg'), typeDoctor: 'Surgery', priceEmploy: '9000', phone: '099-0009999', mail: 'demo@smartClinic.com' },
        { id: '14', name: 'Dr. Sue Vaneer', specialist: 'สาขาผ่าตัด', image: require('../../assets/images/user/13.jpg'), typeDoctor: 'Injection, Surgery', priceEmploy: '9000', phone: '099-0009999', mail: 'demo@smartClinic.com' },
        { id: '15', name: 'Dr. Monty Carlo', specialist: 'สาขาร้อยไหม', image: require('../../assets/images/user/14.jpg'), typeDoctor: 'Surgery', priceEmploy: '9000', phone: '099-0009999', mail: 'demo@smartClinic.com' },
        { id: '16', name: 'Dr. Rick O\'Shea', specialist: 'สาขาทันตแพทย์', image: require('../../assets/images/user/15.jpg'), typeDoctor: 'Injection, Surgery', priceEmploy: '9000', phone: '099-0009999', mail: 'demo@smartClinic.com' }
      ],
      columns: [
        { label: 'เลขบัตรประชาชน', key: 'variable1', class: 'text-center' },
        { label: 'ชื่อ-นามสกุล', key: 'variable2', class: 'text-center' },
        { label: 'ชื่อเล่น', key: 'variable3', class: 'text-center' },
        { label: 'เพศ', key: 'variable4', class: 'text-center' },
        { label: 'เบอร์โทร', key: 'variable5', class: 'text-center' },
        { label: 'จัดการ', key: 'variable7', class: 'text-center' }
      ],
      rows: [
        {
          id: 1,
          variable1: 'Tiger Nixon',
          variable2: 'System Architect',
          variable3: 'Edinburgh',
          variable4: '61',
          variable5: '2011/04/25',
          editable: false
        },
        {
          id: 2,
          variable1: 'Garrett Winters',
          variable2: 'Accountant',
          variable3: 'Tokyo',
          variable4: '63',
          variable5: '2011/06/19',
          editable: false
        },
        {
          id: 3,
          variable1: 'Ashton Cox',
          variable2: 'Junior Technical Author',
          variable3: 'San Francisco',
          variable4: '69',
          variable5: '2011/01/20',
          variable6: '$140,500',
          editable: false
        },
        {
          id: 4,
          variable1: 'Cedric Kelly',
          variable2: 'Senior Javascript Developer',
          variable3: 'Edinburgh',
          variable4: '42',
          variable5: '2011/02/02',
          variable6: '$360,500',
          editable: false
        },
        {
          id: 5,
          variable1: 'Airi Satou',
          variable2: 'Accountant',
          variable3: 'Tokyo',
          variable4: '39',
          variable5: '2011/08/11',
          variable6: '$170,800',
          editable: false
        },
        {
          id: 1,
          variable1: 'Tiger Nixon',
          variable2: 'System Architect',
          variable3: 'Edinburgh',
          variable4: '61',
          variable5: '2011/04/25',
          variable6: '$320,800',
          editable: false
        },

        {
          id: 5,
          variable1: 'Airi Satou',
          variable2: 'Accountant',
          variable3: 'Tokyo',
          variable4: '39',
          variable5: '2011/08/11',
          variable6: '$170,800',
          editable: false
        },
        {
          id: 1,
          variable1: 'Tiger Nixon',
          variable2: 'System Architect',
          variable3: 'Edinburgh',
          variable4: '61',
          variable5: '2011/04/25',
          variable6: '$320,800',
          editable: false
        }
      ],
      currentPage: 1,
      perPage: 10
    }
  },
  methods: {
    onPagination (page) {
      this.currentPage = page
    },
    handleClick () {
      router.push('/AddUsers')
    },
    handleClickClear () {
      this.partTimeData.specialized = null
      this.partTimeData.is_receive = null
      this.partTimeData.aw_id = null
      this.partTimeData.mi_id = null
      this.handleClickSearch()
    },
    getFullImagePath (relativePath) {
      if (relativePath) {
        let path = relativePath !== null ? relativePath.slice(1) : ''
        const baseURL = `${configAPI.MA_PORT}` + path
        return baseURL
      } else {
        return 'https://www.w3schools.com/howto/img_avatar.png'
      }
    },
    async handleClickSearch () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/doctor?${this.partTimeData.specialized?.trim() ? 'specialized=' + this.partTimeData.specialized?.trim() : ''}${this.partTimeData.is_receive?.code ? '&is_receive=' + this.partTimeData.is_receive?.code : ''}${this.partTimeData.aw_id?.value ? '&aw_id=' + this.partTimeData.aw_id?.value : ''}${this.partTimeData.mi_id?.value ? '&mi_id=' + this.partTimeData.mi_id?.value : ''}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status) {
            this.doctors = response.data.data?.map(el => {
              return {
                id: el.id,
                name: el.name || '-',
                specialist: el.specialized || '-',
                image: el.person_path ? el.person_path : null,
                typeDoctor: el.is_receive || '-',
                priceEmploy: el.mi_name || '-',
                phone: el.tel || '-',
                mail: el.email || '-'
              }
            })
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    handleClickViewProfile (id) {
      router.push('/ViewEmployDoctor/' + id)
    },
    async getAreaWorkDD () {
      let getAreaWorkDDAPI = {
        method: 'get',
        url: `${configAPI.MD_PORT}/api/areaWork`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(getAreaWorkDDAPI)
        .then((response) => {
          this.areaworkDD = response.data.data?.map(el => {
            return {
              value: el.id,
              label: el.name
            }
          })
          /* let same = this.areaworkDD?.filter(item => item.id === this.OLDData.data[0].aw_id)
          if (same.length > 0) {
            this.areaworkOption = { value: same[0].id, label: same[0].name }
          } else {
            this.areaworkOption = null
          } */
        })
    },
    async getMinIncomeDD () {
      let getMinIncomeDDAPI = {
        method: 'get',
        url: `${configAPI.MD_PORT}/api/minIncome`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(getMinIncomeDDAPI)
        .then((response) => {
          this.minincomeDD = response.data.data?.map(el => {
            return {
              value: el.id,
              label: el.name
            }
          })
          /* let same = this.minincomeDD?.filter(item => item.id === this.OLDData.data[0].mi_id)
          if (same.length > 0) {
            this.minincomeOption = { value: same[0].id, label: same[0].name }
          } else {
            this.minincomeOption = null
          } */
        })
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.handleClickSearch()
      }
    }
  },
  async mounted () {
    const elements = document.querySelectorAll('.vs__open-indicator')
    this.handleClickSearch()
    elements.forEach(element => {
      element.setAttribute('viewBox', '0 0 14 10')
      element.setAttribute('width', '10')
      element.setAttribute('height', '6')
    })
    await this.getAreaWorkDD()
    await this.getMinIncomeDD()
    xray.index()
  }
}
</script>
