<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="table-responsive">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span v-if="mode === 'add'" style="font-size: 25px; color: black;" class="unselectable">ยอดสะสม
                  (เพิ่ม)</span>
                <span v-else-if="mode === 'edit'"
                  style="font-size: 25px; color: black;" class="unselectable">ยอดสะสม (แก้ไข)</span>
                <span v-else style="font-size: 25px; color: black;" class="unselectable">ยอดสะสม (ดูรายละเอียด)</span>
              </div>
              <div v-if="mode === 'add' || mode === 'edit'" class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-6 pl-3 pr-2 my-1 my-sm-0">
                    <b-button type="submit" variant="primary" @click="handleClick(true)" class="w-100 btn-min-size"><i class="save-data_ct"/>บันทึก</b-button>
                  </div>
                  <div class="col-6 pl-2 pr-3 my-1 my-sm-0">
                    <b-button type="submit" variant="none" @click="handleClick(false)" class="iq-bg-danger w-100 btn-min-size btn-cancel-border">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
              <div v-else class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="submit" variant="none" @click="handleClick(false)" class="iq-bg-danger w-100 btn-min-size btn-cancel-border">ยกเลิก</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                  <b-col lg="6" sm="12" class="py-1">
                    <b-form-group class="unselectable" style="color: black;" label="Supplier">
                      <template  v-if="supDetails.supplier === null || supDetails.supplier === ''" v-slot:label>
                        Supplier<span class="text-danger"> *</span>
                      </template>
                      <v-select v-model="supDetails.supplier" :disabled="isDisable || SupIsDisable" :options="SupplierSelect"></v-select>
                    </b-form-group>
                </b-col>
                  <b-col lg="6" sm="12" class="py-1">
                    <b-form-group class="unselectable" label="ชื่อยอดสะสม" style="color: black;">
                      <template  v-if="supDetails.nameSummary === null || supDetails.nameSummary?.trim() === ''" v-slot:label>
                        ชื่อยอดสะสม<span class="text-danger"> *</span>
                      </template>
                      <b-form-input type="text" :disabled="isDisable" v-model="supDetails.nameSummary" ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" sm="12" class="py-1">
                    <b-form-group class="unselectable" label="Reward" :disabled="isDisable" style="color: black;">
                      <b-form-input type="text" v-model="supDetails.reward" ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12" class="py-1">
                    <b-form-group style="color: black;" class="unselectable" label="ประเภทเป้าหมาย">
                      <template  v-if="supDetails.typeTarget === null || supDetails.typeTarget === ''" v-slot:label>
                        ประเภทเป้าหมาย<span class="text-danger"> *</span>
                      </template>
                      <v-select v-model="supDetails.typeTarget" :disabled="isDisable" :options="CumulativeTargetSelect"></v-select>
                    </b-form-group>
                </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" sm="12" class="py-1">
                    <b-form-group class="unselectable" label="เป้ายอดรวม" style="color: black;">
                      <input v-model="supDetails.targetSummary" class="form-control" :disabled="isDisable" type="text" @input="isNumberComma1($event, 'summary')" @keypress="isNumberCheck($event, false)">
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" sm="12" class="py-1">
                    <b-text v-if="supDetails.start_date === null" style="color: black; white-space: nowrap">วันที่เริ่มต้น<span class="text-danger"> *</span></b-text>
                    <b-text v-else style="color: black; white-space: nowrap">วันที่เริ่มต้น</b-text>
                    <div style="position: relative;" class="w-res-attendance time-picker-attendance">
                      <date-picker placeholder="DD/MM/YYYY" :disabled="isDisable" id="datepicker-custom1" name="date" v-model="supDetails.start_date" :config="options"></date-picker>
                      <i class="ri-calendar-line m-0 icon-calendar-edit" :disabled="isDisable" @click="onClickDatePicker(1)" style="cursor: pointer;"></i>
                    </div>
                  </b-col>
                  <b-col lg="6" sm="12" class="py-1">
                    <b-text v-if="supDetails.end_date === null" style="color: black; white-space: nowrap">วันที่สิ้นสุด<span class="text-danger"> *</span></b-text>
                    <b-text v-else style="color: black; white-space: nowrap">วันที่สิ้นสุด</b-text>
                    <div style="position: relative;" class="w-res-attendance time-picker-attendance">
                      <date-picker placeholder="DD/MM/YYYY" id="datepicker-custom2" :disabled="!isOpeningTimeEntered || isDisable" name="date" v-model="supDetails.end_date" :config="options1"></date-picker>
                      <i class="ri-calendar-line m-0 icon-calendar-edit" :disabled="!isOpeningTimeEntered || isDisable" @click="onClickDatePicker(2)" style="cursor: pointer;"></i>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" sm="12" class="py-1">
                    <b-form-group class="unselectable" label="Remark" style="color: black;">
                      <b-form-textarea type="text" :disabled="isDisable" v-model="supDetails.remark" style="height: 150px; justify-items: flex-start;"></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- ********************** Table ***************** -->
                <b-row>
                  <b-col cols="12">
                      <div class="row align-items-center pb-4">
                      <div class="ml-4">
                        <span class="mx-1 unselectable" style="font-size: 20px; color: black;">Raw mat</span>
                      </div>
                      <div class="col-lg-1 col-md-1 col-sm-2 col-6">
                        <div class="d-flex my-2 mx-2" style="white-space: nowrap;">
                          <b-button @click="showModal = true, recheckRows()" :disabled="isDisable" type="submit" variant="primary" class="mx-1 flex-fill text-center btn-min-size" style="text-align: center;"><i class="circle-plus_ct"/>เพิ่ม</b-button>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <!-- Table -->
              <b-row>
                <b-col cols="12"  class="table-responsive col-lg-12 col-md-12 col-sm-12 col-12" style="overflow-x: auto;" responsive>
                    <b-table bordered hover :items="RawMatTable" :fields="columns"
                      :current-page="currentPage"
                      :per-page="perPage"
                      :tbody-tr-class="rowClass" thead-class="table-header-color"
                    >
                      <template v-slot:cell(variable1)="data">
                        <span class="unselectable">{{ data.item.mg_name }}</span>
                      </template>
                      <template v-slot:cell(variable2)="data">
                        <span class="unselectable">{{ data.item.mb_name }}</span>
                      </template>
                      <template v-slot:cell(variable4)="data">
                        <span class="unselectable">{{ data.item.unit_name }}</span>
                      </template>
                      <template v-slot:cell(variable8)="data">
                        <div style="display: flex; justify-content: center;">
                          <b-button variant=" iq-bg-danger" :disabled="isDisable" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                        </div>
                      </template>
                    </b-table>
                  </b-col>
              </b-row>
              <b-row style="display: flex; justify-content: center;">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="RawMatTable?.length"
                  :per-page="perPage"
                  align="center"
                />
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal v-model="showModal" title="Raw mat" cancel-title="ยกเลิก" ok-title="เพิ่ม" @ok="onSubmitRawmat" @close="onClose" size="xl" :no-close-on-esc="true" :no-close-on-backdrop="true">
      <div class="mx-3">
            <b-row>
              <b-col sm="12" md="7" lg="5" class="mb-3">
                <div class="iq-email-search d-flex">
                  <div style="width: 100%;">
                    <div class="form-group mb-0">
                      <input class="form-control" id="searchMaterial" placeholder="Search" style="width: 100%;" v-model="searchMaterial"  @keydown="enterSearch">
                      <a class="search-link" @click="getMaterial"><i class="ri-search-line" /></a>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            </div>
            <br>
            <b-col cols="12" class="table-responsive">
                <b-table bordered hover :items="materialData" :fields="columns"
                  :current-page="currentPage"
                  :per-page="perPage"
                  @pagination="onPagination"
                  :tbody-tr-class="rowClass" thead-class="table-header-color"
                >
                <template v-slot:cell(variable1)="data">
                  <span class="unselectable">{{ data.item.mg_name }}</span>
                </template>
                <template v-slot:cell(variable2)="data">
                  <span class="unselectable">{{ data.item.mb_name }}</span>
                </template>
                <template v-slot:cell(variable4)="data">
                  <span class="unselectable">{{ data.item.unit_name }}</span>
                </template>
                <template v-slot:cell(variable8)="data">
                  <div style="display: flex; justify-content: center;">
                    <b-button :variant="data.item.selected ? 'danger' : 'primary'" size="sm" @click="toggleAction(data.item)" style="width: 100px; border-radius: 10px;">
                      {{ data.item.selected ? 'ยกเลิก' : 'เลือก' }}
                    </b-button>
                  </div>
                </template>
              </b-table>
              <br>
            </b-col>
            <template #modal-footer>
              <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                <b-button
                  style="border-radius: 0.5rem; padding: 0 1.1rem !important;"
                  variant="primary"
                  class="btn-min-size"
                  size="sm"
                  @click="onSubmitRawmat"
                >
                <i class="save-data_ct"/>บันทึก
                </b-button>
                <b-button
                  variant="none"
                  class="btn-min-size iq-bg-danger btn-cancel-border"
                  style="border-radius: 0.5rem;"
                  size="sm"
                  @click="showModal=false, onClose()"
                >
                  ยกเลิก
                </b-button>
              </div>
            </template>
     </b-modal>
  </b-container>
</template>

<style>
.margin-text-header {
  margin-left: 1rem;
}
.Spadding-text-header {
  margin-left: 1rem;
  margin-right: 1rem;
}

.height-select {
  padding: 11px 0 !important;
}
.my-column {
  width: 200px !important;

}

.apg-response-header-btn {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

@media (max-width: 560px) {
  .margin-text-header {
    margin-left: 0 !important;
  }
  .Spadding-text-header {
    margin-left: 0.5rem !important;
    margin-right: 0 !important;
  }
  .apg-response-header {
    display: flex;
    align-items: flex-start !important;
    flex-direction: column;
  }
  .apg-response-header-btn {
    width: 100% !important;
    margin-left: 1rem !important;
  }
  .iq-header-title {
    width: 100% !important;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import axios from 'axios'
import moment from 'moment'
import { configAPI } from '../../configBase'

const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}

export default {
  name: 'UiDataTable',
  data () {
    return {
      supId: null,
      SupIsDisable: false,
      selected: false,
      showModal: false,
      isDisable: false,
      mode: 'add',
      supDetails: {
        supplier: null,
        typeTarget: null,
        end_date: null,
        start_date: null,
        targetSummary: null,
        reward: null,
        remark: null,
        nameSummary: null
      },
      options: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        sideBySide: true,
        icons: iconDate
      },
      options1: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        minDate: null,
        icons: iconDate
      },
      SupplierSelect: [],
      CumulativeTargetSelect: [],
      columns: [
        { label: 'หมวดหมู่', key: 'variable1', class: 'text-center table-size-200-400' },
        { label: 'แบรนด์', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'หน่วยนับ', key: 'variable4', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'variable8', class: 'text-center my-column' }
      ],
      RawMatTable: [],
      materialData: [],
      materialDataAll: [],
      searchMaterial: null,
      currentPage: 1,
      perPage: 10,
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null
    }
  },
  computed: {
    isOpeningTimeEntered () {
      return !!this.supDetails.start_date
    }
  },
  watch: {
    'supDetails.start_date': function (newVal, oldVal) {
      if (newVal === null) {
        this.supDetails.end_date = null
      } else if (this.mode === 'edit' || this.mode === 'view') {
        this.options1.minDate = newVal
        let ED = moment(this.supDetails.end_date, 'DD-MM-YYYY').format('DD-MM-YYYY')
        let NV = moment(newVal, 'DD-MM-YYYY').format('DD-MM-YYYY')
        let TF = moment(ED, 'DD-MM-YYYY').isBefore(moment(NV, 'DD-MM-YYYY'))
        if (TF) {
          this.supDetails.end_date = null
        }
      } else {
        this.options1.minDate = newVal
        if (newVal !== oldVal) {
          this.supDetails.end_date = null
        }
      }
    },
    'supDetails.end_date': function (newVal, oldVal) {
      if (this.mode === 'edit' || this.mode === 'view') {
        this.supDetails.end_date = newVal
      }
    }
  },
  async mounted () {
    this.mode = this.$route.params.setPage === 'edit' ? 'edit' : this.$route.params.setPage === 'view' ? 'view' : 'add'
    this.supId = this.$route.params.idSup ? this.$route.params.idSup : null
    if (this.mode === 'add') {
      await this.getSupplier()
      await this.getCumulativeTarget()
      await this.getMaterialAll()
      await this.getMaterial()
    } else {
      let idParam = this.$route.params.id
      await this.getSupplier()
      await this.getCumulativeTarget()
      await this.getMaterialAll()
      await this.getMaterial()
      await this.getCumulativeData(idParam)
    }
    if ((this.mode === 'add' || this.mode === 'edit') && this.supId !== null) {
      this.SupIsDisable = true
      this.supDetails.supplier = this.SupplierSelect.find(el => el.value === this.supId)
    }
    xray.index()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getMaterial()
      }
    },
    async getSupplier () {
      let supplierAPI = {
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/supplier`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(supplierAPI)
        .then((response) => {
          this.SupplierSelect = response.data.data?.map((item) => {
            return {
              label: item.name,
              value: item.id
            }
          })
        })
    },
    async getCumulativeTarget () {
      let cumulativeTargetAPI = {
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/cumulativeTarget`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(cumulativeTargetAPI)
        .then((response) => {
          this.CumulativeTargetSelect = response.data.data?.map((item) => {
            return {
              label: item.name,
              value: item.id
            }
          })
        })
    },
    async getMaterial () {
      let search = this.searchMaterial ? this.searchMaterial.trim() : ''
      let materialAPI = {
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/material?search=` + search,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(materialAPI)
        .then(async (response) => {
          this.materialData = response.data.data?.map((item) => {
            return {
              ...item,
              mat_id: item.id,
              selected: false
            }
          })
          let matSelected = this.materialDataAll.filter(el => el.selected === true)
          matSelected.forEach(el => {
            let index = this.materialData.findIndex(item => item.id === el.id)
            if (index >= 0) {
              this.materialData[index].selected = true
            }
          })
        })
    },
    async getMaterialAll () {
      let materialAPI = {
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/material?search=` + '',
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(materialAPI)
        .then(async (response) => {
          this.materialDataAll = response.data.data?.map((item) => {
            return {
              ...item,
              mat_id: item.id,
              selected: false
            }
          })
        })
    },
    onClickDatePicker (num) {
      let datePicker = null
      if (num === 1) {
        datePicker = document.getElementById('datepicker-custom1')
      } else if (num === 2) {
        datePicker = document.getElementById('datepicker-custom2')
      } else {
        datePicker = document.getElementById('datepicker-custom3')
      }
      datePicker.focus()
    },
    remove (item) {
      // Vue.swal.fire({
      //   title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการลบข้อมูลใช่หรือไม่?</span>',
      //   icon: 'warning',
      //   showCancelButton: true,
      //   confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
      //   confirmButtonColor: '#089bab',
      //   cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
      //   cancelButtonColor: '#d33',
      //   customClass: {
      //     confirmButton: 'custom-confirm-button-class mx-1',
      //     cancelButton: 'custom-confirm-button-class mx-1'
      //   }
      // }).then((result) => {
      // if (result.isConfirmed) {
      let index1 = this.RawMatTable.indexOf(item)
      let index2 = this.materialData.findIndex(el => el.id === item.id)
      let index3 = this.materialDataAll.findIndex(el => el.id === item.id)
      if (index2 >= 0) {
        this.materialData[index2].selected = false
        this.materialDataAll[index3].selected = false
      }
      this.RawMatTable.splice(index1, 1)
      // }
      // })
    },
    onPagination (page) {
      this.currentPage = page
    },
    async handleClick (status) {
      if (this.mode === 'add' && status) {
        let errorList = await this.checkError()
        if (errorList.length > 0) {
          Vue.swal.fire({
            html: `<span class="" style="font-size: 20px; color: black; text-align: center">${errorList.join('<br>')}</span>`,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        } else {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่?</span>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
            confirmButtonColor: '#089bab',
            cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
            cancelButtonColor: '#d33',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1',
              cancelButton: 'custom-confirm-button-class mx-1'
            }
          }).then(async (result) => {
            if (result.isConfirmed) {
              let matId = this.RawMatTable?.map(el => el.mat_id)
              let dataForSave = {
                sup_id: this.supDetails.supplier?.value || null,
                ct_id: this.supDetails.typeTarget?.value || null,
                name: this.supDetails.nameSummary?.trim() || null,
                reward: this.supDetails.reward?.trim() || null,
                total: this.supDetails.targetSummary ? parseInt(this.supDetails.targetSummary.trim().replaceAll(/,/g, '')) : null,
                remark: this.supDetails.remark?.trim(),
                start_date: this.convertToTimestamp(this.supDetails.start_date),
                end_date: this.convertToTimestamp(this.supDetails.end_date),
                mat_id_list: matId
              }
              let AddCumulativeAPI = {
                method: 'POST',
                url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/cumulative`,
                headers: {
                  'Content-Type': 'application/json'
                },
                data: dataForSave
              }
              await axios.request(AddCumulativeAPI)
                .then((response) => {
                  if (response.data.status) {
                    Vue.swal.fire({
                      title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
                      icon: 'success',
                      allowEscapeKey: false,
                      allowOutsideClick: false,
                      showConfirmButton: false,
                      timer: 2000
                    }).then(() => {
                      if (this.mode === 'add' && this.supId !== null) {
                        router.push('/SupplierDetail/edit/' + this.supId)
                      } else if (this.mode === 'add') {
                        router.push('/Cumulative')
                      }
                    })
                  }
                })
            }
          })
        }
      } else if (this.mode === 'edit' && status) {
        let errorList = await this.checkError()
        if (errorList.length > 0) {
          Vue.swal.fire({
            html: `<span class="" style="font-size: 20px; color: black; text-align: center">${errorList.join('<br>')}</span>`,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        } else {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะแก้ไขข้อมูลใช่หรือไม่?</span>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
            confirmButtonColor: '#089bab',
            cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
            cancelButtonColor: '#d33',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1',
              cancelButton: 'custom-confirm-button-class mx-1'
            }
          }).then(async (result) => {
            if (result.isConfirmed) {
              let id = this.$route.params.id
              let matId = this.RawMatTable?.map(el => el.mat_id)
              let dataForEdit = {
                sup_id: this.supDetails.supplier?.value || null,
                ct_id: this.supDetails.typeTarget?.value || null,
                name: this.supDetails.nameSummary?.trim() || null,
                reward: this.supDetails.reward?.trim() || null,
                total: this.supDetails.targetSummary ? parseInt(this.supDetails.targetSummary.trim().replaceAll(/,/g, '')) : null,
                remark: this.supDetails.remark?.trim(),
                start_date: this.convertToTimestamp(this.supDetails.start_date),
                end_date: this.convertToTimestamp(this.supDetails.end_date),
                mat_id_list: matId
              }
              let EditCumulativeAPI = {
                method: 'PUT',
                url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/cumulative/${id}`,
                headers: {
                  'Content-Type': 'application/json'
                },
                data: dataForEdit
              }
              await axios.request(EditCumulativeAPI)
                .then((response) => {
                  if (response.data.status) {
                    Vue.swal.fire({
                      title: '<span class="" style="font-size: 25px; color: black; text-align: center">แก้ไขข้อมูลสำเร็จ</span>',
                      icon: 'success',
                      allowEscapeKey: false,
                      allowOutsideClick: false,
                      showConfirmButton: false,
                      timer: 2000
                    }).then(() => {
                      if (this.mode === 'edit' && this.supId !== null) {
                        router.push('/SupplierDetail/edit/' + this.supId)
                      } else {
                        router.push('/Cumulative')
                      }
                    })
                  }
                })
            }
          })
        }
      } else if (this.mode === 'view') {
        router.go(-1)
      } else {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะยกเลิกใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            if (this.mode === 'add' && this.supId !== null) {
              router.push('/SupplierDetail/edit/' + this.supId)
            } else if (this.mode === 'edit' && this.supId !== null) {
              router.push('/SupplierDetail/edit/' + this.supId)
            } else {
              router.push('/Cumulative')
            }
          }
        })
      }
    },
    toggleAction (row) {
      row.selected = !row.selected
      let index = this.materialDataAll.findIndex(el => el.id === row.id)
      if (index >= 0) {
        this.materialDataAll[index].selected = !this.materialDataAll[index].selected
      }
    },
    onClose () {
      this.searchMaterial = null
    },
    async recheckRows () {
      if (this.mode === 'add') {
        this.searchMaterial = null
        await this.getMaterial()
        const rowsTemp = this.materialData
        this.materialData = rowsTemp.map(el => {
          const obj = this.RawMatTable.find(item => item.id === el.id)
          if (obj) {
            el.selected = true
          } else {
            el.selected = false
          }
          return el
        })
        const rowsTempAll = this.materialDataAll
        this.materialDataAll = rowsTempAll.map(el => {
          const obj = this.RawMatTable.find(item => item.id === el.id)
          if (obj) {
            el.selected = true
          } else {
            el.selected = false
          }
          return el
        })
      } else if (this.mode === 'edit') {
        this.searchMaterial = null
        await this.getMaterial()
        const rowsTemp = this.materialData
        this.materialData = rowsTemp.map(el => {
          const obj = this.RawMatTable.find(item => item.mat_id === el.id)
          if (obj) {
            el.selected = true
          } else {
            el.selected = false
          }
          return el
        })
        const rowsTempAll = this.materialDataAll
        this.materialDataAll = rowsTempAll.map(el => {
          const obj = this.RawMatTable.find(item => item.mat_id === el.id)
          if (obj) {
            el.selected = true
          } else {
            el.selected = false
          }
          return el
        })
      }
    },
    onSubmitRawmat () {
      const rowsTemp = this.materialDataAll
      const objPassToTable = rowsTemp.filter(el => el.selected === true)
      this.RawMatTable = objPassToTable
      this.showModal = false
    },
    getCurrentDate (dataInput) {
      if (dataInput === undefined) {
        return
      }
      const today = new Date(dataInput)
      let month = (today.getMonth() + 1).toString()
      let day = today.getDate().toString()

      // แก้ไขรูปแบบเดือนและวันให้เป็น 'MM' และ 'DD'
      if (month.length === 1) {
        month = '0' + month
      }
      if (day.length === 1) {
        day = '0' + day
      }

      return `${today.getFullYear()}-${month}-${day}`
    },
    async getCumulativeData (idParam) {
      if (this.mode === 'view') {
        this.isDisable = true
      }
      let getCumulativeDataAPI = {
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/cumulative/${idParam}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(getCumulativeDataAPI)
        .then((response) => {
          this.supDetails = {
            supplier: {
              label: response.data.data[0].sup_name,
              value: response.data.data[0].sup_id
            },
            typeTarget: {
              label: response.data.data[0].ct_name,
              value: response.data.data[0].ct_id
            },
            start_date: moment(response.data.data[0]?.start_date).format('DD/MM/YYYY'),
            end_date: moment(response.data.data[0]?.end_date).format('DD/MM/YYYY'),
            targetSummary: response.data.data[0].total?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','),
            reward: response.data.data[0].reward,
            remark: response.data.data[0].remark,
            nameSummary: response.data.data[0].name
          }
          this.RawMatTable = response.data.data[0].cm_list?.map((item) => {
            return {
              ...item,
              selected: true
            }
          })
          let Mat = this.materialData.map((item) => {
            const obj = response.data.data[0].cm_list?.find(el => el.mat_id === item.id)
            if (obj) {
              item.selected = true
            } else {
              item.selected = false
            }
            return item
          })
          this.materialData = Mat
          let MatAll = this.materialDataAll.map((item) => {
            const obj = response.data.data[0].cm_list?.find(el => el.mat_id === item.id)
            if (obj) {
              item.selected = true
            } else {
              item.selected = false
            }
            return item
          })
          this.materialDataAll = MatAll
        })
    },
    isNumberComma1 (event, variable) {
      if (variable === 'summary') {
        let number = event.target.value
        this.supDetails.targetSummary = number.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
        event.target.value = this.supDetails.targetSummary
      }
    },
    isNumberCheck (keyPressed, status) {
      let useData = keyPressed.target.value
      let keyCode = (keyPressed.keyCode ? keyPressed.keyCode : keyPressed.which)
      if (status) {
        if (keyCode === 46 || useData.indexOf('.') !== -1) {
          keyPressed.preventDefault()
        }
        if (keyCode < 48 || keyCode > 57) {
          keyPressed.preventDefault()
        }
      } else {
        if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || useData.indexOf('.') !== -1)) {
          keyPressed.preventDefault()
        }
      }
      if (useData !== null && useData.indexOf('.') > -1 && (useData.split('.')[1].length > 1)) {
        keyPressed.preventDefault()
      }
      if ((useData === null || useData === '') && keyCode === 46) {
        keyPressed.preventDefault()
      }
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    async checkError () {
      let errorList = []
      if (this.supDetails.supplier === null || this.supDetails.supplier === '') {
        errorList.push('กรุณาเลือก Supplier')
      }
      if (this.supDetails.nameSummary === null || this.supDetails.nameSummary?.trim() === '') {
        errorList.push('กรุณากรอก ชื่อยอดสะสม')
      }
      if (this.supDetails.typeTarget === null || this.supDetails.typeTarget === '') {
        errorList.push('กรุณาเลือก ประเภทเป้าหมาย')
      }
      if (this.supDetails.start_date === null || this.supDetails.start_date === '') {
        errorList.push('กรุณาเลือก วันที่เริ่มต้น')
      }
      if (this.supDetails.end_date === null || this.supDetails.end_date === '') {
        errorList.push('กรุณาเลือก วันที่สิ้นสุด')
      }

      if (!this.materialDataAll.find(el => el.selected)) {
        errorList.push('ต้องมี Raw mat อย่างน้อย 1 อย่าง')
      }
      return errorList
    },
    convertToTimestamp (dateString) {
      const parts = dateString.split('/')
      const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`
      const dateObject = moment(formattedDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
      return dateObject
    }
  }
}
</script>
