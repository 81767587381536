<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" lg="3">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto mb-2">
                <span v-if="mode === 'add'" style="font-size: 25px; color: black;">พนักงาน <span style="white-space: nowrap;">(เพิ่ม)</span></span>
                <span v-else-if="mode === 'edit'" style="font-size: 25px; color: black;">พนักงาน <span style="white-space: nowrap;">(แก้ไข)</span></span>
                <span v-else style="font-size: 25px; color: black;">พนักงาน <span style="white-space: nowrap;">(ดูรายละเอียด)</span></span>
              </div>
              <div v-if="mode === 'add' || mode === 'edit'" class="col-12 col-sm-auto col-lg-12">
                <div class="row">
                  <div class="col-6 pl-3 pr-2 my-1 my-sm-0">
                    <b-button type="submit" variant="primary" @click="clickSave" class="w-100"><i class="save-data_ct"></i>บันทึก</b-button>
                  </div>
                  <div class="col-6 pl-2 pr-3 my-1 my-sm-0">
                    <b-button type="submit" variant="none" @click="clickCancel" class="w-100 iq-bg-danger btn-cancel-border">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
              <div v-else class="col-12 col-sm-auto col-lg-12 my-1 my-sm-0">
                <b-button type="submit" variant="none" @click="clickCancel" class="w-100 iq-bg-danger btn-cancel-border">ยกเลิก</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <b-form-group style="text-align-last: center;">
              <div class="add-img-user profile-img-edit">
                <b-img v-if="profileImage.path !== null || profileImage.path !== ''" class="profile-pic " style="height: 150px; width: 150px; object-fit: contain;" fluid :src="profileImage.path || 'https://www.w3schools.com/howto/img_avatar.png'" alt="profile-pic" />
                <div class="p-image">
                  <b-button variant="none" @click="clickUpload" class="upload-button iq-bg-primary position-relative." :disabled="disableCheck">
                    อัปโหลดรูป
                  </b-button>
                  <b-form-file
                    v-model="profileImage.file"
                    type="file"
                    id="uploadImage"
                    @change="previewImage"
                    class="h-100 position-absolute"
                    :accept="allowedTypes"
                    style="opacity: 0; display: none;"
                    :disabled="disableCheck">
                  </b-form-file>
                </div>
              </div>
              <div class="img-extension mt-3">
                <div class="d-inline-block align-items-center">
                  <span style="font-size: 11.5px;">Only</span>
                  <span style="font-size: 11.5px;">.jpg</span>
                  <span style="font-size: 11.5px;">.png</span>
                  <span style="font-size: 11.5px;">.jpeg</span>
                  <span style="font-size: 11.5px;">Allowed</span>
                </div>
              </div>
            </b-form-group>
            <b-form-group label-for="emp_empgroup" label="เลือกกลุ่มพนักงาน">
              <template v-if="selectedEmpGroup === null || selectedEmpGroup === ''" v-slot:label>
                เลือกกลุ่มพนักงาน<span style="color: red;"> *</span>
              </template>
              <v-select v-model="selectedEmpGroup" id="emp_empgroup" :options="EmpGroupOptions" :disabled="disableCheck" :clearable="false"></v-select>
            </b-form-group>
            <b-form-group label="ชื่อเล่น" label-for="emp_nickname">
              <template v-if="user.nickname === null || user.nickname === ''" v-slot:label>
                ชื่อเล่น<span style="color: red;"> *</span>
              </template>
              <b-form-input v-model="user.nickname" id="emp_nickname" :disabled="disableCheck"></b-form-input>
            </b-form-group>
            <b-form-group label="เลขบัตรประชาชน" label-for="emp_citizen">
              <template v-if="user.citizen_id === null || user.citizen_id === '' || user.citizen_id?.length < 13" v-slot:label>
                เลขบัตรประชาชน<span style="color: red;"> *</span>
              </template>
              <the-mask v-model="user.citizen_id" id="emp_citizen" :disabled="disableCheck" mask="#-####-#####-##-#" class="form-control" :masked="false"></the-mask>
              <!-- <b-form-input v-model="user.citizen_id" id="emp_citizen" :disabled="disableCheck" @keypress="isCitizenCheck($event)" @input="formatCitizen($event)"></b-form-input> -->
            </b-form-group>
            <b-form-group label="อีเมล" label-for="emp_email">
              <template v-if="user.email === null || user.email === ''" v-slot:label>
                อีเมล<span style="color: red;"> *</span>
              </template>
              <b-form-input v-model="user.email" id="emp_email" :disabled="disableCheck"></b-form-input>
            </b-form-group>
            <b-form-group label="เบอร์โทร" label-for="emp_tel">
              <template v-if="user.tel === null || user.tel === '' || user.tel?.length < 10" v-slot:label>
                เบอร์โทร<span style="color: red;"> *</span>
              </template>
              <the-mask v-model="user.tel" id="emp_tel" :disabled="disableCheck" mask="###-###-####" class="form-control" :masked="false"></the-mask>
              <!-- <b-form-input v-model="user.tel" id="emp_tel" :disabled="disableCheck" @keypress="isPhoneCheck($event)" @input="formatContact($event)"></b-form-input> -->
            </b-form-group>
          </template>
        </iq-card>
      </b-col>
      <b-col cols="12" lg="9">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">ข้อมูลพนักงาน</span>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="ชื่อ" label-for="emp_name">
                  <template v-if="user.name === null || user.name === ''" v-slot:label>
                    ชื่อ<span style="color: red;"> *</span>
                  </template>
                  <b-form-input v-model="user.name" id="emp_name" :disabled="disableCheck"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="นามสกุล" label-for="emp_lastname">
                  <template v-if="user.lastname === null || user.lastname === ''" v-slot:label>
                    นามสกุล<span style="color: red;"> *</span>
                  </template>
                  <b-form-input v-model="user.lastname" id="emp_lastname" :disabled="disableCheck"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group label="ที่อยู่" label-for="emp_address">
                  <template v-if="user.address === null || user.address === ''" v-slot:label>
                    ที่อยู่<span style="color: red;"> *</span>
                  </template>
                  <b-form-textarea v-model="user.address" id="emp_address" :disabled="disableCheck" style="line-height: 30px;"></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label-for="emp_gender" label="เพศ">
                  <template v-if="selectedGender === null || selectedGender === ''" v-slot:label>
                    เพศ<span style="color: red;"> *</span>
                  </template>
                  <v-select v-model="selectedGender" id="emp_gender" :disabled="disableCheck" :options="genderOptions"></v-select>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label-for="emp_birthday" label="วันเกิด">
                  <template v-if="selectedBirthday === null || selectedBirthday === ''" v-slot:label>
                    วันเกิด<span style="color: red;"> *</span>
                  </template>
                  <div style="position: relative; margin-top: .4rem;" class="">
                    <date-picker v-model="selectedBirthday" placeholder="DD/MM/YYYY" id="datepicker-custom3" :disabled="disableCheck" name="date" :config="optionsDate"></date-picker>
                    <i class="ri-calendar-line m-0 icon-calendar-edit" :disabled="disableCheck" @click="onClickDatePicker()" style="cursor: pointer;"></i>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="4">
                <b-form-group label-for="emp_education" label="วุฒิการศึกษา">
                  <template v-if="selectedEducation === null || selectedEducation === ''" v-slot:label>
                    วุฒิการศึกษา<span style="color: red;"> *</span>
                  </template>
                  <v-select v-model="selectedEducation" id="emp_education" :disabled="disableCheck" :options="educationOptions"></v-select>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4">
                <b-form-group label-for="emp_crime" label="ประวัติอาชญากรรม">
                  <template v-if="selectedCrime === null || selectedCrime === ''" v-slot:label>
                    ประวัติอาชญากรรม<span style="color: red;"> *</span>
                  </template>
                  <v-select v-model="selectedCrime" id="emp_crime" :disabled="disableCheck" :options="crimeOptions" ></v-select>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4" v-if="selectedCrime?.label === 'เคย'">
                <b-form-group label-for="emp_crime_remark" label="รายละเอียด">
                  <template v-if="user.ehc_remark === null || user.ehc_remark === ''" v-slot:label>
                    รายละเอียด<span style="color: red;"> *</span>
                  </template>
                  <b-form-input v-model="user.ehc_remark" id="emp_crime_remark" :disabled="disableCheck" style="line-height: 30px;"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4" v-if="selectedGender?.label === 'ชาย'">
                <b-form-group label-for="emp_solider" label="ประวัติทางทหาร">
                  <template v-if="selectedSoldier === null || selectedSoldier === ''" v-slot:label>
                    ประวัติทางทหาร<span style="color: red;"> *</span>
                  </template>
                  <v-select v-model="selectedSoldier" id="emp_soldier" :disabled="disableCheck" :options="soldierOptions"></v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label-for="emp_start_work" label="วันที่เริ่มงาน">
                  <template v-if="user.emp_start_work === null || user.emp_start_work === ''" v-slot:label>
                    วันที่เริ่มงาน<span style="color: red;"> *</span>
                  </template>
                  <div style="position: relative; margin-top: .4rem;" class="">
                    <date-picker v-model="user.emp_start_work" placeholder="DD/MM/YYYY" id="datepicker-custom-start-work" :disabled="disableCheck" name="date" :config="optionsDate"></date-picker>
                    <i class="ri-calendar-line m-0 icon-calendar-edit" :disabled="disableCheck" @click="onClickDatePicker('start-work')" style="cursor: pointer;"></i>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label-for="emp_end_work" label="วันที่สิ้นสุดการทำงาน">
                  <template v-if="user.emp_end_work === null || user.emp_end_work === ''" v-slot:label>
                    วันที่สิ้นสุดการทำงาน
                  </template>
                  <div style="position: relative; margin-top: .4rem;" class="">
                    <date-picker v-model="user.emp_end_work" placeholder="DD/MM/YYYY" id="datepicker-custom-end-work" :disabled="disableCheck || !user.emp_start_work" name="date" :config="optionsDateEnd"></date-picker>
                    <i class="ri-calendar-line m-0 icon-calendar-edit" :disabled="disableCheck || !user.emp_start_work" @click="onClickDatePicker('end-work')" style="cursor: pointer;"></i>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group label-for="image_cv" label="แนบไฟล์ (CV, Contract, Other)">
                  <template v-if="cvFile.path === null || cvFile.path === ''" v-slot:label>
                    แนบไฟล์ (CV, Contract, Other)<span style="color: red;"> *</span>
                  </template>
                  <b-form-file
                    id="image_cv"
                    v-model="cvFile.file"
                    :accept="allowedTypes1"
                    :disabled="disableCheck"
                    class="custom-file-style"
                    @change="handleCVfile"
                    :placeholder="cvFile.name === null || cvFile.name === '' ? 'Choose a file or drop it here...' : cvFile.name"
                  ></b-form-file> <!-- multiple -->
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Line ID" label-for="Line_id">
                  <template v-if="user.line_id === null || user.line_id === ''" v-slot:label>
                    Line ID<span style="color: red;"> *</span>
                  </template>
                  <b-form-input v-model="user.line_id" id="Line_id" :disabled="disableCheck"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="รหัสพนักงาน" label-for="emp_no">
                  <template v-if="user.no === null || user.no === ''" v-slot:label>
                    รหัสพนักงาน<span style="color: red;"> *</span>
                  </template>
                  <b-form-input v-model="user.no" id="emp_no" :disabled="disableCheck"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label-for="emp_bank_no" label="Account No.">
                  <template v-if="user.account_no === null || user.account_no === '' || user.account_no?.length < 10" v-slot:label>
                    Account No.<span style="color: red;"> *</span>
                  </template>
                  <!-- <the-mask v-model="user.account_no" id="emp_bank_no" :disabled="disableCheck" class="form-control" mas></the-mask>@paste.prevent -->
                  <b-form-input v-model="user.account_no" id="emp_bank_no" :disabled="disableCheck" @keypress="isNumberCheck($event)"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label-for="emp_bank_name" label="Account Name">
                  <template v-if="user.account_name === null || user.account_name === ''" v-slot:label>
                    Account Name<span style="color: red;"> *</span>
                  </template>
                  <b-form-input v-model="user.account_name" id="emp_bank_name" :disabled="disableCheck"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group label-for="image_account" label="รูปหน้าบัญชี">
                  <template v-if="accountImage.path === null || accountImage.path === ''" v-slot:label>
                    รูปหน้าบัญชี<span style="color: red;"> *</span>
                  </template>
                  <b-form-file
                    v-model="accountImage.file"
                    id="image_account"
                    :accept="allowedTypes1"
                    :disabled="disableCheck"
                    class="custom-file-style"
                    @change="handleAccountImage"
                    ref="fileUpload"
                    :placeholder="accountImage.name === null || accountImage.name === '' ? 'Choose a file or drop it here...' : accountImage.name"
                  ></b-form-file> <!-- multiple -->
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" md="3">
                <b-form-group label-for="emp_start_break" label="ช่วงเวลาพัก(เริ่ม)">
                  <template v-if="startBreak === null || startBreak === '' || startBreak.length < 5" v-slot:label>
                    ช่วงเวลาพัก(เริ่ม)<span style="color: red;"> *</span>
                  </template>
                  <b-form-input v-model="startBreak" id="emp_start_break" :disabled="disableCheck" @input="formatTime('start')" @keypress="preventInputMore4Letter($event, 'start'), isNumberCheck($event)"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6" md="3">
                <b-form-group label-for="emp_end_break" label="ช่วงเวลาพัก(สิ้นสุด)">
                  <template v-if="endBreak === null || endBreak === '' || endBreak.length < 5" v-slot:label>
                    ช่วงเวลาพัก(สิ้นสุด)<span style="color: red;"> *</span>
                  </template>
                  <b-form-input v-model="endBreak" id="emp_end_break" :disabled="disableCheck" @input="formatTime('end')" @keypress="preventInputMore4Letter($event, 'end'), isNumberCheck($event)"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label-for="emp_periodTime" label="กำหนดกะการทำงาน">
                  <template v-if="selectedPeriodTime === null || selectedPeriodTime === ''" v-slot:label>
                    กำหนดกะการทำงาน<span style="color: red;"> *</span>
                  </template>
                  <v-select v-model="selectedPeriodTime" id="emp_periodTime" :disabled="disableCheck" :options="periodTimeOptions"></v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label-for="emp_tax" label="ค่าหักภาษี %">
                  <template v-if="user.tax_percent === null || user.tax_percent === ''" v-slot:label>
                    ค่าหักภาษี %<span style="color: red;"> *</span>
                  </template>
                  <b-form-input v-model="user.tax_percent" id="emp_tax" :disabled="disableCheck" @keypress="isMoreHundred($event), isNumberCheck($event)" @input="isOneHundred($event)"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label-for="emp_service" label="ค่าบริการ 3%">
                  <template v-if="user.service_percent === null || user.service_percent === ''" v-slot:label>
                    ค่าบริการ 3%<span style="color: red;"> *</span>
                  </template>
                  <CurrencyInputCom v-model="user.service_percent" id="emp_service" :disabled="disableCheck" :options="optionCurrency"></CurrencyInputCom>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" v-if="selectedEmpGroup?.label === 'Operation' || selectedEmpGroup?.label === 'Sale' || selectedEmpGroup?.label === 'Therapist' || selectedEmpGroup?.label === 'Physical Therapist' || selectedEmpGroup?.label === 'Nurse'">
                <b-form-group label-for="emp_sale_target" label="กำหนดยอดขาย">
                  <template v-if="user.sale_target === null || user.sale_target === ''" v-slot:label>
                    กำหนดยอดขาย<span style="color: red;"> *</span>
                  </template>
                  <b-form-input v-model="user.sale_target" id="emp_sale_target" :disabled="disableCheck" @keypress="isNumberCheck($event)"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" v-if="selectedEmpGroup?.label === 'Doctor'">
                <b-form-group label-for="emp_operation" label="ค่าดำเนินการ">
                  <template v-if="user.operation_hospital === null || user.operation_hospital === ''" v-slot:label>
                    ค่าดำเนินการ<span style="color: red;"> *</span>
                  </template>
                  <CurrencyInputCom v-model="user.operation_hospital" id="emp_operation" :disabled="disableCheck" :options="optionCurrency"></CurrencyInputCom>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" v-if="selectedEmpGroup?.label === 'Physical Therapist' || selectedEmpGroup?.label === 'Doctor' || selectedEmpGroup?.label === 'Nurse'">
                <b-form-group label-for="emp_cer_no" label="เลขที่ใบประกอบวิชาชีพ">
                  <template v-if="user.cer_no === null || user.cer_no === ''" v-slot:label>
                    เลขที่ใบประกอบวิชาชีพ<span style="color: red;"> *</span>
                  </template>
                  <b-form-input v-model="user.cer_no" id="emp_cer_no" :disabled="disableCheck" @keypress="isNumberCheck($event)"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" v-if="selectedEmpGroup?.label === 'Doctor'">
                <b-form-group label-for="emp_specialized" label="สาขาเฉพาะทาง">
                  <template v-if="user.specialized === null || user.specialized === ''" v-slot:label>
                    สาขาเฉพาะทาง<span style="color: red;"> *</span>
                  </template>
                  <b-form-input v-model="user.specialized" id="emp_specialized" :disabled="disableCheck"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" v-if="selectedEmpGroup?.label === 'Doctor'">
                <b-form-group label-for="emp_recieve" label="ประเภทงานที่รับ">
                  <template v-if="selectedIsReceive === null || selectedIsReceive === ''" v-slot:label>
                    ประเภทงานที่รับ<span style="color: red;"> *</span>
                  </template>
                  <v-select v-model="selectedIsReceive" id="emp_recieve" :disabled="disableCheck" :options="isReceiveOptions"></v-select>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" v-if="selectedEmpGroup?.label === 'Doctor'">
                <b-form-group label-for="emp_no_area" label="ไม่รับทำบริเวณ">
                  <template v-if="user.no_area === null || user.no_area === ''" v-slot:label>
                    ไม่รับทำบริเวณ<span style="color: red;"> *</span>
                  </template>
                  <b-form-input v-model="user.no_area" id="emp_no_area" :disabled="disableCheck"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import Axios from 'axios'
import moment from 'moment'
import CurrencyInputCom from './CurrencyInputCom.vue'
import { configAPI } from '../../configBase'

const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}
export default {
  name: 'EmployeeAEV',
  components: {
    CurrencyInputCom
  },
  data () {
    return {
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      usrId: JSON.parse(sessionStorage.getItem('user')).usr_id || null,
      user: {
        cpt_id: null, // 123
        eg_id: null, // "13c2a5fa-5812-4fc9-898d-b2cb477b38fe",
        no: null, //  "string",
        nickname: null, //  "Tukt",
        name: null, //  "Saksit",
        lastname: null, //  "Phoomkham",
        address: null, // "12/1 Bangkok",
        citizen_id: null, //  "1250100123123",
        email: null, // "Chris.Micel@gmail.com",
        tel: null, // "0623456789",
        gender_id: null, // "db71741c-b0c7-44e6-a103-ad801c626d13",
        birth_day: null, // "1999-07-07T03:29:30.376Z",
        education_id: null, //  "68ae3820-1045-11ee-9e90-17029eb8f270",
        line_id: null, // "chrisM",
        sale_target: null, // 0,
        salary: null, //  "string",
        start_break: null, // "string",
        end_break: null, // "string",
        account_no: null, //  "string",
        account_name: null, //  "string",
        account_img_name: null, //  "string",
        account_path: null, //  "string",
        cv_img_name: null, // "string",
        cv_path: null, // "string",
        tax_percent: null, // "7",
        service_percent: null, // "3",
        operation_hospital: null, //  0,
        cer_no: null, //  0,
        specialized: null, // "string",
        is_receive: null, //  "string",
        no_area: null, // "string"
        ehs_id: null, // "2c5e0f8e-0c74-41ee-8ebf-33ca4456dcef",
        ehc_id: null, // "de227fd6-f573-4ff8-ba6d-a6a9d6c31da8",
        ehc_remark: null, // "string",
        created_by: null, // "2f3b5310-3427-11ee-9545-9dc5200393b1"
        emp_start_work: null,
        emp_end_work: null
      },
      mode: '',
      id: '',
      allowedTypes: [
        'image/png',
        'image/jpeg'
      ],
      allowedTypes1: [
        'image/png',
        'image/jpeg',
        'text/csv',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ],
      disableCheck: false,
      optionsDate: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        sideBySide: true,
        icons: iconDate,
        maxDate: null
      },
      optionsDateEnd: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        sideBySide: true,
        icons: iconDate,
        minDate: null
      },
      selectedEmpGroup: null,
      selectedGender: null,
      selectedBirthday: null,
      selectedEducation: null,
      selectedCrime: null,
      selectedSoldier: null,
      selectedPeriodTime: null,
      selectedIsReceive: null,
      EmpGroupOptions: [
        // { code: 'option1', label: 'Operation' },
        // { code: 'option2', label: 'Sale' },
        // { code: 'option3', label: 'Therapist' },
        // { code: 'option4', label: 'Physical Therapist' },
        // { code: 'option5', label: 'Maid' },
        // { code: 'option6', label: 'Admin' },
        // { code: 'option7', label: 'Doctor' },
        // { code: 'option8', label: 'Nurse' }
      ],
      genderOptions: [],
      educationOptions: [],
      crimeOptions: [],
      soldierOptions: [],
      periodTimeOptions: [],
      isReceiveOptions: [
        {
          code: 'injection',
          label: 'Injection'
        },
        {
          code: 'sugery',
          label: 'Sugery'
        }
      ],
      startBreak: '',
      endBreak: '',
      from: '',
      profileImage: {
        name: null,
        path: null,
        file: null
      },
      oldProfileImage: {
        name: null,
        path: null,
        file: null
      },
      accountImage: {
        name: null,
        path: null,
        file: null
      },
      oldAccountImage: {
        name: null,
        path: null,
        file: null
      },
      cvFile: {
        name: null,
        path: null,
        file: null
      },
      oldCVFile: {
        name: null,
        path: null,
        file: null
      },
      optionCurrency: {
        currency: 'EUR',
        currencyDisplay: 'hidden',
        valueRange: {
          min: 0,
          max: 1e+53
        },
        precision: 2,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: true,
        accountingSign: false
      }
    }
  },
  async mounted () {
    this.mode = this.$route.params.setPage
    this.id = this.$route.params.id || null
    this.from = this.$route.params.from || null
    await this.getEmpGroupDD()
    await this.getGender()
    await this.getEducation()
    await this.getCrime()
    await this.getSoldier()
    await this.getPeriodTime()
    if (this.$route.params.setPage === 'view') {
      await this.getEmpDetail(this.$route.params.id)
      this.disableCheck = true
    } else if (this.$route.params.setPage === 'edit') {
      await this.getEmpDetail(this.$route.params.id)
      this.disableCheck = false
    } else {
      this.disableCheck = false
    }
    xray.index()
  },
  watch: {
    'user.emp_start_work': function (newVal, oldVal) {
      if (newVal) {
        this.optionsDateEnd.minDate = newVal
      }
    },
    /* 'user.emp_end_work': function(newVal, oldVal) {
      if (newVal) {
        this.optionsDateEnd.minDate = newVal
      }
    }, */
    selectedEmpGroup (val) {
      if (val.code !== this.user.eg_id) {
        this.user.eg_id = val.code
        this.user.operation_hospital = null //  0,
        this.user.cer_no = null //  0,
        this.user.specialized = null // "string",
        this.user.is_receive = null //  "string",
        this.user.no_area = null
        this.user.sale_target = null
      }
    },
    selectedGender (val) {
      if (val) {
        this.user.gender_id = val.code
      } else {
        this.user.gender_id = null
      }
    },
    selectedBirthday (val) {
      if (val) {
        this.user.birth_day = new Date(moment(val, 'DD/MM/YYYY'))
      } else {
        this.user.birth_day = null
      }
    },
    selectedEducation (val) {
      if (val) {
        this.user.education_id = val.code
      } else {
        this.user.education_id = null
      }
    },
    selectedCrime (val) {
      if (val) {
        this.user.ehc_id = val.code
      } else {
        this.user.ehc_id = null
      }
    },
    selectedSoldier (val) {
      if (val) {
        this.user.ehs_id = val.code
      } else {
        this.user.ehs_id = null
      }
    },
    selectedPeriodTime (val) {
      if (val) {
        this.user.cpt_id = val.code
      } else {
        this.user.cpt_id = null
      }
    },
    selectedIsReceive (val) {
      if (val) {
        this.user.is_receive = val.code
      } else {
        this.user.is_receive = null
      }
    },
    startBreak (val) {
      if (val) {
        this.user.start_break = val
      } else {
        this.user.start_break = null
      }
    },
    endBreak (val) {
      if (val) {
        this.user.end_break = val
      } else {
        this.user.end_break = null
      }
    }
  },
  methods: {
    async getEmpGroupDD () {
      let EmpGroupAPI = {
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/employeeGroup`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await Axios.request(EmpGroupAPI)
        .then((response) => {
          this.EmpGroupOptions = response.data.data?.map((el) => {
            return {
              code: el.id,
              label: el.name
            }
          })
        })
    },
    async getGender () {
      let GenderAPI = {
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/employeeGender`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await Axios.request(GenderAPI)
        .then((response) => {
          this.genderOptions = response.data.data?.map((el) => {
            return {
              code: el.id,
              label: el.name
            }
          })
        })
    },
    async getEducation () {
      let educationAPI = {
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/employeeEducation`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await Axios.request(educationAPI)
        .then((response) => {
          this.educationOptions = response.data.data?.map((el) => {
            return {
              code: el.id,
              label: el.name
            }
          })
        })
    },
    async getSoldier () {
      let soldierAPI = {
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/employeeHistorySoldier`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await Axios.request(soldierAPI)
        .then((response) => {
          this.soldierOptions = response.data.data?.map((el) => {
            return {
              code: el.id,
              label: el.name
            }
          })
        })
    },
    async getCrime () {
      let crimeAPI = {
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/employeeHistoryCrime`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await Axios.request(crimeAPI)
        .then((response) => {
          this.crimeOptions = response.data.data?.map((el) => {
            return {
              code: el.id,
              label: el.name
            }
          })
        })
    },
    async getPeriodTime () {
      let periodTimeAPI = {
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/periodTimeTable`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await Axios.request(periodTimeAPI)
        .then((response) => {
          let temp = []
          for (let i = 0; i < response.data.data.length; i++) {
            if (response.data.data[i].sub_period_time_list.length > 0) {
              temp.push({
                code: response.data.data[i].id,
                label: response.data.data[i].period_time_name
              })
            }
          }
          this.periodTimeOptions = temp
        })
    },
    onClickDatePicker (id) {
      let datePicker = document.getElementById(`datepicker-custom${id || '3'}`)
      datePicker.focus()
    },
    formatTime (from) {
      if (from === 'start') {
        this.startBreak = this.startBreak.replace(/\D/g, '')
        // let endBreakTemp = this.endBreak !== null && this.endBreak !== '' ? parseInt(this.endBreak.replace(/\D/g, '')) : 2359
        // if (this.startBreak.length > 4) {
        //   this.startBreak = this.startBreak.slice(0, 4)
        // }
        if (this.startBreak.length > 2) {
          if (this.startBreak.substring(0, 2) > 23) {
            this.startBreak = '23' + this.startBreak.substring(2)
          }
          if (this.startBreak.substring(2) > 59) {
            this.startBreak = this.startBreak.substring(0, 2) + '59'
          }
          this.startBreak = `${this.startBreak.substring(0, 2)}:${this.startBreak.substring(2)}`
        }
        // if (parseInt(this.startBreak.replace(/\D/g, '')) > endBreakTemp) {
        //   this.startBreak = this.endBreak.substring(0, 2) + this.endBreak.substring(2, 4)
        // }
        this.user.start_break = this.startBreak
      } else {
        this.endBreak = this.endBreak.replace(/\D/g, '')
        // let startBreakTemp = this.startBreak !== null && this.startBreak !== '' ? parseInt(this.startBreak.replace(/\D/g, '')) : 0
        // if (this.endBreak.length > 4) {
        //   this.endBreak = this.endBreak.slice(0, 4)
        // }
        if (this.endBreak.length > 2) {
          if (this.endBreak.substring(0, 2) > 23) {
            this.endBreak = '23' + this.endBreak.substring(2)
          }
          if (this.endBreak.substring(2) > 59) {
            this.endBreak = this.endBreak.substring(0, 2) + '59'
          }
          this.endBreak = `${this.endBreak.substring(0, 2)}:${this.endBreak.substring(2)}`
        }
        // if (parseInt(this.endBreak.replace(/\D/g, '')) < startBreakTemp) {
        //   this.endBreak = this.startBreak
        // }
        this.user.end_break = this.endBreak
      }
    },
    preventInputMore4Letter (event, from) {
      if (this.startBreak.length >= 5 && from === 'start') {
        event.preventDefault()
      } else if (this.endBreak.length >= 5 && from === 'end') {
        event.preventDefault()
      }
    },
    isNumberCheck (keyPressed) {
      let keyCode = (keyPressed.keyCode ? keyPressed.keyCode : keyPressed.which)
      if (keyCode < 48 || keyCode > 57) {
        keyPressed.preventDefault()
      }
    },
    isOneHundred (event) {
      if (parseInt(this.user.tax_percent) > 100) {
        this.user.tax_percent = '100'
      }
    },
    isMoreHundred (event) {
      if (this.user.tax_percent.length >= 3) {
        event.preventDefault()
      }
    },
    formatCitizen (event) {
      let x = event?.replace(/\D/g, '').match(/(\d{0,1})(\d{0,4})(\d{0,5})(\d{0,2})(\d{0,1})/)
      this.user.citizen_id = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '') + (x[4] ? '-' + x[4] : '') + (x[5] ? '-' + x[5] : '')
    },
    isCitizenCheck (keyPressed) {
      let keyCode = (keyPressed.keyCode ? keyPressed.keyCode : keyPressed.which)
      if (keyCode < 48 || keyCode > 57 || this.user.citizen_id?.length >= 17) {
        keyPressed.preventDefault()
      }
    },
    changeFormatCitizen (citizen) {
      if (citizen) {
        let x = citizen?.replace(/\D/g, '').match(/(\d{0,1})(\d{0,4})(\d{0,5})(\d{0,2})(\d{0,1})/)
        let citizenID = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '') + (x[4] ? '-' + x[4] : '') + (x[5] ? '-' + x[5] : '')
        return citizenID
      } else {
        return citizen
      }
    },
    formatContact (event) {
      let x = event?.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.user.tel = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '')
    },
    isPhoneCheck (keyPressed) {
      let keyCode = (keyPressed.keyCode ? keyPressed.keyCode : keyPressed.which)
      if ((keyCode < 48 || keyCode > 57) || this.user.tel?.length >= 12) {
        keyPressed.preventDefault()
      }
    },
    changeFormatContact (tel) {
      if (tel) {
        let x = tel?.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
        let telNumber = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '')
        return telNumber
      } else {
        return tel
      }
    },
    async clickSave () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะบันทึกข้อมูลใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let errorList = await this.checkError()
          if (errorList.length > 0) {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">กรุณากรอกข้อมูลให้ครบถ้วน</span>',
              html: `<span class="" style="font-size: 18px; color: black; text-align: center">${errorList.join('<br>')}</span>`,
              icon: 'warning',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: true,
              confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
              confirmButtonColor: '#089bab',
              customClass: {
                confirmButton: 'custom-confirm-button-class mx-1'
              }
            })
            return
          }
          if (this.mode === 'add') {
            await this.addEmployee()
          } else if (this.mode === 'edit') {
            await this.editEmployee()
          }
        }
      })
    },
    clickCancel () {
      if (this.mode === 'view') {
        router.push(`/Employee/${this.from}`)
      } else {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะยกเลิกใช่หรือไม่</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            router.push(`/Employee/${this.from}`)
          }
        })
      }
    },
    previewImage: function (event) {
      const input = event.target
      if (input.files[0] && !this.allowedTypes.includes(input.files[0].type)) {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
          html: '<span>กรุณาเลือกไฟล์รูปภาพเท่านั้น</span>',
          icon: 'warning',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          this.profileImage.name = this.oldProfileImage.name !== null && this.oldProfileImage.name !== '' ? this.oldProfileImage.name : null
          this.profileImage.path = this.oldProfileImage.path !== null && this.oldProfileImage.path !== '' ? this.oldProfileImage.path : null
          this.profileImage.file = this.oldProfileImage.file !== null && this.oldProfileImage.file !== '' ? this.oldProfileImage.file : null
        })
      }
      if (input.files && input.files[0]) {
        this.profileImage.name = input.files[0].name
        this.profileImage.path = URL.createObjectURL(input.files[0])
        this.profileImage.file = input.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(input.files[0])
        reader.onload = (e) => {
          this.profileImage.path = e.target.result
        }
      } else if (this.mode === 'add') {
        this.profileImage.name = null
        this.profileImage.path = null
        this.profileImage.file = null
      } else {
        this.profileImage.name = this.oldProfileImage.name !== null && this.oldProfileImage.name !== '' ? this.oldProfileImage.name : null
        this.profileImage.path = this.oldProfileImage.path !== null && this.oldProfileImage.path !== '' ? this.oldProfileImage.path : null
        this.profileImage.file = null
      }
    },
    clickUpload () {
      document.getElementById('uploadImage').click()
    },
    handleCVfile (event) {
      const input = event.target
      if (input.files[0] && !this.allowedTypes1.includes(input.files[0].type)) {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
          html: '<span>ประเภทไฟล์ไม่ถูกต้อง กรุณาเลือกไฟล์เป็นไฟล์ .pdf, .doc, .docx, .xls, .xlsx, .csv เท่านั้น</span>',
          icon: 'warning',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          this.cvFile.name = this.oldCVFile.name !== null && this.oldCVFile.name !== '' ? this.oldCVFile.name : null
          this.cvFile.path = this.oldCVFile.path !== null && this.oldCVFile.path !== '' ? this.oldCVFile.path : null
          this.cvFile.file = this.oldCVFile.file !== null && this.oldCVFile.file !== '' ? this.oldCVFile.file : null
        })
      }
      if (input.files && input.files[0]) {
        this.cvFile.name = input.files[0].name
        this.cvFile.path = URL.createObjectURL(input.files[0])
        this.cvFile.file = input.files[0]
      } else {
        if (this.mode === 'add') {
          this.cvFile.name = null
          this.cvFile.path = null
          this.cvFile.file = null
        } else {
          this.cvFile.name = this.oldCVFile.name
          this.cvFile.path = this.oldCVFile.path
          this.cvFile.file = null
        }
      }
    },
    handleAccountImage (event) {
      const input = event.target
      if (input.files[0] && !this.allowedTypes1.includes(input.files[0].type)) {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
          html: '<span>ประเภทไฟล์ไม่ถูกต้อง กรุณาเลือกไฟล์เป็นไฟล์ .pdf, .doc, .docx, .xls, .xlsx, .csv เท่านั้น</span>',
          icon: 'warning',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          this.accountImage.name = this.oldAccountImage.name !== null && this.oldAccountImage.name !== '' ? this.oldAccountImage.name : null
          this.accountImage.path = this.oldAccountImage.path !== null && this.oldAccountImage.path !== '' ? this.oldAccountImage.path : null
          this.accountImage.file = this.oldAccountImage.file !== null && this.oldAccountImage.file !== '' ? this.oldAccountImage.file : null
        })
      } else {
        if (input.files && input.files[0]) {
          this.accountImage.name = input.files[0].name
          this.accountImage.path = URL.createObjectURL(input.files[0])
          this.accountImage.file = input.files[0]
        } else {
          if (this.mode === 'add') {
            this.accountImage.name = null
            this.accountImage.path = null
            this.accountImage.file = null
          } else {
            this.accountImage.name = this.oldAccountImage.name
            this.accountImage.path = this.oldAccountImage.path
            this.accountImage.file = null
          }
        }
      }
    },
    async uploadImgProfile (id) {
      let data = new FormData()
      data.append('file', this.profileImage.file)
      let uploadImgProfileAPI = {
        method: 'post',
        url: `${configAPI.MA_PORT}/api/employee/${id}/upload/profile`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      await Axios.request(uploadImgProfileAPI)
        .then((response) => {})
        .catch((error) => {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
            html: '<span>' + error + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async uploadImgAccount (id) {
      let data = new FormData()
      data.append('file', this.accountImage.file)
      let uploadImgAccountAPI = {
        method: 'post',
        url: `${configAPI.MA_PORT}/api/employee/${id}/upload/account`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      await Axios.request(uploadImgAccountAPI)
        .then((response) => {})
        .catch((error) => {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
            html: '<span>' + error + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async uploadImgCV (id) {
      let data = new FormData()
      data.append('file', this.cvFile.file)
      let uploadImgCVAPI = {
        method: 'post',
        url: `${configAPI.MA_PORT}/api/employee/${id}/upload/cv`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      await Axios.request(uploadImgCVAPI)
        .then((response) => {})
        .catch((error) => {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
            html: '<span>' + error + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    getFullImagePath (relativePath) {
      if (relativePath) {
        let path = relativePath !== null ? relativePath.slice(1) : ''
        const baseURL = `${configAPI.MA_PORT}` + path
        return baseURL
      } else {
        return null
      }
    },
    async checkError () {
      const regexNumber = /^[0-9]+$/
      this.user.nickname = this.user.nickname?.trim() || null
      this.user.citizen_id = this.user.citizen_id?.replace(/-/g, '') || null
      this.user.email = this.user.email?.trim() || null
      this.user.tel = this.user.tel?.replace(/-/g, '') || null
      this.user.name = this.user.name?.trim() || null
      this.user.lastname = this.user.lastname?.trim() || null
      this.user.address = this.user.address?.trim() || null
      this.user.ehc_remark = this.user.ehc_remark?.trim() || null
      this.user.line_id = this.user.line_id?.trim() || null
      this.user.no = this.user.no?.trim() || null
      this.user.account_name = this.user.account_name?.trim() || null
      this.user.specialized = this.user.specialized?.trim() || null
      this.user.no_area = this.user.no_area?.trim() || null
      this.user.operation_hospital = this.user.operation_hospital?.toString().replace(/,/g, '') || null
      this.user.service_percent = this.user.service_percent?.toString().replace(/,/g, '') || null

      let errorList = []
      if (this.profileImage.file !== null && this.profileImage.file !== '') {
        if (!this.allowedTypes.includes(this.profileImage.file.type)) {
          errorList.push('กรุณาเลือกไฟล์รูปภาพเป็น .png หรือ .jpg เท่านั้น')
        }
      }
      if (this.user.eg_id === null) {
        errorList.push('กรุณาเลือก กลุ่มพนักงาน')
      }
      if (this.user.nickname === null || this.user.nickname === '') {
        errorList.push('กรุณากรอก ชื่อเล่น')
      }
      if (this.user.citizen_id === null || this.user.citizen_id === '' || !regexNumber.test(this.user.citizen_id)) {
        errorList.push('กรุณากรอก เลขบัตรประชาชน (เป็นตัวเลขเท่านั้น)')
      } else if (this.user.citizen_id.length < 13) {
        errorList.push('กรุณากรอก เลขบัตรประชาชนให้ครบ 13 หลัก')
      }
      if (this.user.email === null || this.user.email === '') {
        errorList.push('กรุณากรอก อีเมล')
      }
      if (this.user.tel === null || this.user.tel === '' || !regexNumber.test(this.user.tel)) {
        errorList.push('กรุณากรอก เบอร์โทรศัพท์ (เป็นตัวเลขเท่านั้น)')
      } else if (this.user.tel.length < 10) {
        errorList.push('กรุณากรอก เบอร์โทรศัพท์ให้ครบ 10 หลัก')
      }
      if (this.user.name === null || this.user.name === '') {
        errorList.push('กรุณากรอก ชื่อ')
      }
      if (this.user.lastname === null || this.user.lastname === '') {
        errorList.push('กรุณากรอก นามสกุล')
      }
      if (this.user.address === null || this.user.address === '') {
        errorList.push('กรุณากรอก ที่อยู่')
      }
      if (this.user.gender_id === null) {
        errorList.push('กรุณาเลือก เพศ')
      }
      if (this.user.birth_day === null) {
        errorList.push('กรุณาเลือก วันเกิด')
      }
      if (this.user.education_id === null) {
        errorList.push('กรุณาเลือก ระดับการศึกษา')
      }
      if (this.user.ehc_id === null) {
        errorList.push('กรุณาเลือก ประวัติอาชญากรรม')
      } else if (this.selectedCrime?.label === 'เคย' && (this.user.ehc_remark === null || this.user.ehc_remark === '')) {
        errorList.push('กรุณากรอก ประวัติอาชญากรรม(รายละเอียด)')
      }
      if (this.selectedGender?.label === 'ชาย' && (this.user.ehs_id === null || this.user.ehs_id === '')) {
        errorList.push('กรุณาเลือก ประวัติทหาร')
      }
      if (this.user.line_id === null || this.user.line_id === '') {
        errorList.push('กรุณากรอก Line ID')
      }
      if (this.user.no === null || this.user.no === '') {
        errorList.push('กรุณากรอก รหัสพนักงาน')
      }
      if (this.user.account_no === null || this.user.account_no === '' || !regexNumber.test(this.user.account_no)) {
        errorList.push('กรุณากรอก เลขบัญชี (เป็นตัวเลขเท่านั้น)')
      } else if (this.user.account_no?.length < 10) {
        errorList.push('กรุณากรอก เลขบัญชีให้ครบ 10 หลักหรือมากกว่า')
      }
      if (this.user.account_name === null || this.user.account_name === '') {
        errorList.push('กรุณากรอก ชื่อบัญชี')
      }
      if (this.user.start_break === null || this.user.start_break === '' || this.user.start_break?.length < 5) {
        errorList.push('กรุณากรอก ช่วงเวลาพัก(เริ่ม) ให้ถูกต้อง (ตัวอย่าง 09:00)')
      }
      if (this.user.end_break === null || this.user.end_break === '' || this.user.end_break?.length < 5) {
        errorList.push('กรุณากรอก ช่วงเวลาพัก(สิ้นสุด) ให้ถูกต้อง (ตัวอย่าง 09:00)')
      }
      if (this.user.start_break !== null &&
        this.user.start_break !== '' &&
        this.user.start_break?.length === 5 &&
        this.user.end_break !== null &&
        this.user.end_break !== '' &&
        this.user.end_break?.length === 5) {
        let startBreakTemp = parseInt(this.user.start_break.replace(/\D/g, ''))
        let endBreakTemp = parseInt(this.user.end_break.replace(/\D/g, ''))
        if (startBreakTemp > endBreakTemp) {
          errorList.push('ช่วงเวลาพัก(เริ่ม) ต้องน้อยกว่า ช่วงเวลาพัก(สิ้นสุด)')
        }
      }
      if (this.user.cpt_id === null) {
        errorList.push('กรุณาเลือก กะการทำงาน')
      }
      if (this.user.tax_percent === null || this.user.tax_percent === '' || !regexNumber.test(this.user.tax_percent)) {
        errorList.push('กรุณากรอก ค่าหักภาษี (เป็นตัวเลขเท่านั้น)')
      }
      if (this.user.service_percent === null || this.user.service_percent === '' || !regexNumber.test(this.user.service_percent)) {
        errorList.push('กรุณากรอก ค่าบริการ (เป็นตัวเลขเท่านั้น)')
      }
      if (this.selectedEmpGroup?.label === 'Sale' || this.selectedEmpGroup?.label === 'Operation' || this.selectedEmpGroup?.label === 'Sale' || this.selectedEmpGroup?.label === 'Therapist' || this.selectedEmpGroup?.label === 'Physical Therapist' || this.selectedEmpGroup?.label === 'Nurse') {
        if (this.user.sale_target === null || this.user.sale_target === '') {
          errorList.push('กรุณากรอก กำหนดยอดขาย')
        }
      }
      if (this.selectedEmpGroup?.label === 'Nurse') {
        if (this.user.cer_no === null || this.user.cer_no === '' || !regexNumber.test(this.user.cer_no)) {
          errorList.push('กรุณากรอก เลขที่ใบประกอบวิชาชีพ (เป็นตัวเลขเท่านั้น)')
        }
      }
      if (this.selectedEmpGroup?.label === 'Physical Therapist') {
        if (this.user.cer_no === null || this.user.cer_no === '' || !regexNumber.test(this.user.cer_no)) {
          errorList.push('กรุณากรอก เลขที่ใบประกอบวิชาชีพ (เป็นตัวเลขเท่านั้น)')
        }
      }
      if (this.selectedEmpGroup?.label === 'Doctor') {
        if (this.user.operation_hospital === null || this.user.operation_hospital === '') {
          errorList.push('กรุณากรอก ค่าดำเนินการ')
        }
        if (this.user.cer_no === null || this.user.cer_no === '' || !regexNumber.test(this.user.cer_no)) {
          errorList.push('กรุณากรอก เลขที่ใบประกอบวิชาชีพ (เป็นตัวเลขเท่านั้น)')
        }
        if (this.user.specialized === null || this.user.specialized === '') {
          errorList.push('กรุณากรอก สาขาเฉพาะทาง')
        }
        if (this.user.is_receive === null || this.user.is_receive === '') {
          errorList.push('กรุณาเลือก ประเภทงานที่รับ')
        }
        if (this.user.no_area === null || this.user.no_area === '') {
          errorList.push('กรุณากรอก ไม่รับทำบริเวณ')
        }
      }
      if (errorList.length > 0) {
        this.user.citizen_id = this.changeFormatCitizen(this.user.citizen_id)
        this.user.tel = this.changeFormatContact(this.user.tel)
      }
      return errorList
    },
    async addEmployee () {
      let addData = {
        cpt_id: this.user.cpt_id || null,
        eg_id: this.user.eg_id || null,
        no: this.user.no || null,
        nickname: this.user.nickname || null,
        name: this.user.name || null,
        lastname: this.user.lastname || null,
        address: this.user.address || null,
        citizen_id: this.user.citizen_id || null,
        email: this.user.email || null,
        tel: this.user.tel || null,
        gender_id: this.user.gender_id || null,
        birth_day: this.user.birth_day || null,
        education_id: this.user.education_id || null,
        line_id: this.user.line_id || null,
        sale_target: this.user.sale_target || null,
        salary: this.user.salary || null,
        start_break: this.user.start_break || null,
        end_break: this.user.end_break || null,
        account_no: this.user.account_no || null,
        account_name: this.user.account_name || null,
        tax_percent: this.user.tax_percent || null,
        service_percent: this.user.service_percent || null,
        operation_hospital: this.user.operation_hospital || null,
        cer_no: this.user.cer_no || null,
        specialized: this.user.specialized || null,
        is_receive: this.user.is_receive || null,
        no_area: this.user.no_area || null,
        ehs_id: this.user.ehs_id || null,
        ehc_id: this.user.ehc_id || null,
        ehc_remark: this.user.ehc_remark || null,
        created_by: this.usrId,
        emp_start_work: this.user.emp_start_work || null,
        emp_end_work: this.user.emp_end_work || null
      }
      let addEmployeeAPI = {
        method: 'post',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/employee`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: addData
      }
      await Axios.request(addEmployeeAPI)
        .then(async (response) => {
          if (response.data.status) {
            if (this.profileImage.file) {
              await this.uploadImgProfile(response.data.data.id)
            }
            if (this.accountImage.file) {
              await this.uploadImgAccount(response.data.data.id)
            }
            if (this.cvFile.file) {
              await this.uploadImgCV(response.data.data.id)
            }
            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then((result) => {
              router.push(`/Employee/${this.from}`)
            })
          } else {
            this.SwalError(response.data.error, this.mode === 'edit' ? 'แก้ไขข้อมูลไม่สำเร็จ' : 'บันทึกข้อมูลไม่สำเร็จ')
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลไม่สำเร็จ</span>',
            html: '<span>' + error + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async editEmployee () {
      let editData = {
        cpt_id: this.user.cpt_id || null,
        eg_id: this.user.eg_id || null,
        no: this.user.no || null,
        nickname: this.user.nickname || null,
        name: this.user.name || null,
        lastname: this.user.lastname || null,
        address: this.user.address || null,
        citizen_id: this.user.citizen_id || null,
        email: this.user.email || null,
        tel: this.user.tel || null,
        gender_id: this.user.gender_id || null,
        birth_day: this.user.birth_day || null,
        education_id: this.user.education_id || null,
        line_id: this.user.line_id || null,
        sale_target: this.user.sale_target || null,
        salary: this.user.salary || null,
        start_break: this.user.start_break || null,
        end_break: this.user.end_break || null,
        account_no: this.user.account_no || null,
        account_name: this.user.account_name || null,
        tax_percent: this.user.tax_percent || null,
        service_percent: this.user.service_percent || null,
        operation_hospital: this.user.operation_hospital || null,
        cer_no: this.user.cer_no || null,
        specialized: this.user.specialized || null,
        is_receive: this.user.is_receive || null,
        no_area: this.user.no_area || null,
        ehs_id: this.user.ehs_id || null,
        ehc_id: this.user.ehc_id || null,
        ehc_remark: this.user.ehc_remark || null,
        created_by: this.usrId,
        emp_start_work: this.user.emp_start_work || null,
        emp_end_work: this.user.emp_end_work || null
      }
      let editEmployeeAPI = {
        method: 'put',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/employee/${this.id}`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: editData
      }
      await Axios.request(editEmployeeAPI)
        .then(async (response) => {
          if (response.data.status) {
            if (this.profileImage.file) {
              await this.uploadImgProfile(this.id)
            }
            if (this.accountImage.file) {
              await this.uploadImgAccount(this.id)
            }
            if (this.cvFile.file) {
              await this.uploadImgCV(this.id)
            }
            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">แก้ไขข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then((result) => {
              router.push(`/Employee/${this.from}`)
            })
          } else {
            this.SwalError(response.data.error, this.mode === 'edit' ? 'แก้ไขข้อมูลไม่สำเร็จ' : 'บันทึกข้อมูลไม่สำเร็จ')
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">แก้ไขข้อมูลไม่สำเร็จ</span>',
            html: '<span>' + error + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    async getEmpDetail (EmpID) {
      let getEmpDetailAPI = {
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/employee/${EmpID}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await Axios.request(getEmpDetailAPI)
        .then((response) => {
          let empDetail = response.data.data[0]
          this.user = {
            cpt_id: null, // empDetail.cpt_id,
            eg_id: empDetail.eg_id,
            no: empDetail.no,
            nickname: empDetail.nickname,
            name: empDetail.name,
            lastname: empDetail.lastname,
            address: empDetail.address,
            citizen_id: null, // empDetail.citizen_id,
            email: empDetail.email,
            tel: null, // empDetail.tel,
            gender_id: null, // empDetail.gender_id,
            birth_day: null, //  empDetail.birth_day,
            education_id: null, // empDetail.education_id,
            line_id: empDetail.line_id,
            sale_target: empDetail.sale_target,
            salary: empDetail.salary,
            start_break: null, // empDetail.start_break,
            end_break: null, // empDetail.end_break,
            account_no: empDetail.account_no,
            account_name: empDetail.account_name,
            account_img_name: null, // empDetail.account_img_name,
            account_path: null, // empDetail.account_path,
            cv_img_name: null, // empDetail.cv_img_name,
            cv_path: null, // empDetail.cv_path,
            tax_percent: empDetail.tax_percent,
            service_percent: empDetail.service_percent?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || null,
            operation_hospital: empDetail.operation_hospital?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || null,
            cer_no: empDetail.cer_no,
            specialized: empDetail.specialized,
            is_receive: empDetail.is_receive,
            no_area: empDetail.no_area,
            ehs_id: empDetail.ehs_id,
            ehc_id: empDetail.ehc_id,
            ehc_remark: empDetail.ehc_remark,
            created_by: empDetail.created_by,
            emp_start_work: empDetail.emp_start_work ? moment(empDetail.emp_start_work).format('DD/MM/YYYY') : null,
            emp_end_work: empDetail.emp_end_work ? moment(empDetail.emp_end_work).format('DD/MM/YYYY') : null
          }
          this.selectedEmpGroup = this.EmpGroupOptions.find((el) => {
            return el.code === empDetail.eg_id
          })
          this.selectedGender = this.genderOptions.find((el) => {
            return el.code === empDetail.gender_id
          })
          this.selectedEducation = this.educationOptions.find((el) => {
            return el.code === empDetail.education_id
          })
          this.selectedCrime = this.crimeOptions.find((el) => {
            return el.code === empDetail.ehc_id
          })
          this.selectedSoldier = this.soldierOptions.find((el) => {
            return el.code === empDetail.ehs_id
          })
          this.selectedPeriodTime = this.periodTimeOptions.find((el) => {
            return el.code === empDetail.cpt_id
          })
          this.selectedIsReceive = this.isReceiveOptions.find((el) => {
            return el.code === empDetail.is_receive
          })
          this.user.citizen_id = this.changeFormatCitizen(empDetail.citizen_id)
          this.user.tel = this.changeFormatContact(empDetail.tel)
          this.selectedBirthday = moment(empDetail.birth_day).format('DD/MM/YYYY')
          this.startBreak = empDetail.start_break
          this.endBreak = empDetail.end_break
          this.profileImage.path = this.getFullImagePath(empDetail.profile_img_path)
          this.profileImage.name = empDetail.profile_img
          this.oldProfileImage.path = this.getFullImagePath(empDetail.profile_img_path)
          this.oldProfileImage.name = empDetail.profile_img
          this.accountImage.name = empDetail.account_img_name
          this.accountImage.path = empDetail.account_path
          this.oldAccountImage.name = empDetail.account_img_name
          this.oldAccountImage.path = empDetail.account_path
          this.cvFile.name = empDetail.cv_img_name
          this.cvFile.path = empDetail.cv_path
          this.oldCVFile.name = empDetail.cv_img_name
          this.oldCVFile.path = empDetail.cv_path
        })
    }
  }
}
</script>
