<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <iq-card>
          <template v-slot:headerTitle>
            <!-- <div class="row align-items-center py-4">
              <div class="col-auto">
                <span v-if="mode === 'add'" class="px-lg-3" style="font-size: 25px; color: black;">กลุ่มบริการ
                  (เพิ่ม)</span>
                <span v-else-if="mode === 'edit'" class="px-lg-3"
                  style="font-size: 25px; color: black;">กลุ่มบริการ (แก้ไข)</span>
                <span v-else class="px-lg-3" style="font-size: 25px; color: black;">กลุ่มบริการ (ดูรายละเอียด)</span>
              </div>
              <div class="col-12 col-sm pl-sm-3 pr-sm-1 my-1 my-sm-0">
                <b-button v-if="mode === 'add' || mode === 'edit'" type="submit" variant="primary" @click="clickSave"
                  class="w-100">บันทึก</b-button>
              </div>
              <div class="col-12 col-sm pl-sm-1 pr-sm-3 my-1 my-sm-0">
                <b-button type="submit" variant="danger" @click="clickCancel"
                  class="w-100">ยกเลิก</b-button>
              </div>
            </div> -->
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span v-if="mode === 'add'" style="font-size: 25px; color: black;">กลุ่มบริการ
                  (เพิ่ม)</span>
                <span v-else-if="mode === 'edit'"
                  style="font-size: 25px; color: black;">กลุ่มบริการ (แก้ไข)</span>
                <span v-else style="font-size: 25px; color: black;">กลุ่มบริการ (ดูรายละเอียด)</span>
              </div>
              <div v-if="mode === 'add' || mode === 'edit'" class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-6 pl-3 pr-2 my-1 my-sm-0 col-sm-auto">
                    <b-button type="submit" variant="primary" @click="clickSave" class="w-100 btn-min-size"><i class="save-data_ct"></i>บันทึก</b-button>
                  </div>
                  <div class="col-6 pl-2 pr-3 my-1 my-sm-0 col-sm-auto">
                    <b-button type="submit" variant="none" @click="clickCancel" class="w-100 btn-min-size iq-bg-danger btn-cancel-border">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
              <div v-else class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="submit" variant="none" @click="clickCancel" class="w-100 btn-min-size iq-bg-danger btn-cancel-border">ยกเลิก</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col sm="12" md="8" lg="6">
                  <b-form-group label-for="exampleFormControlSelect1" label="หมวดหมู่">
                    <v-select v-model="inputData.category" :disabled="mode !== 'add' && mode !== 'edit'" :options="options1"></v-select>
                    <!-- <b-form-select v-if="mode === 'add' || mode === 'edit'" plain v-model="inputData.category"
                      :options="options1" size="lg" id="exampleFormControlSelect1">
                    </b-form-select>
                    <b-form-select v-else plain v-model="inputData.category" :options="options1" size="lg"
                      id="exampleFormControlSelect1" disabled>
                    </b-form-select> -->
                  </b-form-group>
                  <b-form-group label="ชื่อประเภทการใช้งาน" label-for="exampleInputText1">
                    <b-form-input v-if="mode === 'add' || mode === 'edit'" id="exampleInputText1" type="text"
                      placeholder="" v-model="inputData.typeNname" />
                    <b-form-input v-else id="exampleInputText1" type="text" placeholder="" v-model="inputData.typeNname"
                      disabled />
                  </b-form-group>
                  <b-form-group label="ค่ามือ (บาท)" label-for="exampleInputText1">
                    <b-form-input v-if="mode === 'add' || mode === 'edit'" id="exampleInputText1" type="text"
                      placeholder="" v-model="inputData.handValue" @keypress="isNumberCheck1($event)"
                      @input="isNumberComma1($event)" />
                    <b-form-input v-else id="exampleInputText1" type="text" placeholder="" v-model="inputData.handValue"
                      @keypress="isNumberCheck1($event)" @input="isNumberComma1($event)" disabled />
                  </b-form-group>
                  <b-form-group label="เวลามาตรฐานให้บริการ" label-for="exampleInputText1">
                    <b-form-input v-if="mode === 'add' || mode === 'edit'" id="exampleInputText1" type="text"
                      placeholder="" v-model="inputData.standardTime" @keypress="isNumberCheck2($event)"
                      @input="isNumberComma2($event)" />
                    <b-form-input v-else id="exampleInputText1" type="text" placeholder=""
                      v-model="inputData.standardTime" @keypress="isNumberCheck2($event)" @input="isNumberComma2($event)"
                      disabled />
                  </b-form-group>

                  <!-- <b-button v-if="mode === 'add' || mode === 'edit'" type="submit" variant="primary" @click="handleClick"
                    class="text-center mx-1">บันทึก</b-button>
                  <b-button type="submit" variant="none" @click="handleClick"
                    class="text-center mx-1 iq-bg-danger">ยกเลิก</b-button> -->
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'

export default {
  name: 'AddServiceGroup',
  data () {
    return {
      inputData: {
        category: '',
        typeNname: '',
        handValue: '',
        standardTime: ''
      },
      options1: [
        { code: '1', label: 'TEST-1' },
        { code: '2', label: 'TEST-2' }
      ],
      mode: '',
      id: ''
    }
  },
  created () {
    this.setPage()
    this.setId()
  },
  methods: {
    setPage () {
      this.mode = this.$route.params.setPage
    },
    setId () {
      this.id = this.$route.params.id
    },
    handleImageUpload (event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.imageUrl = reader.result
      }
    },
    handleImageUpload2 (event) {
      const file2 = event.target.files[0]
      const reader2 = new FileReader()
      reader2.readAsDataURL(file2)
      reader2.onload = () => {
        this.imageUrl2 = reader2.result
      }
    },
    clickSave () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          Vue.swal({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
            icon: 'success',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 2000
          }).then(() => {
            router.push('/ServiceGroup')
          })
        }
      })
    },
    clickCancel () {
      if (this.mode === 'add' || this.mode === 'edit') {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            router.push('/ServiceGroup')
          }
        })
      } else {
        router.push('/ServiceGroup')
      }
    },
    onPagination (page) {
      this.currentPage = page
    },
    isNumberComma1 (event) {
      this.inputData.handValue = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
    },
    isNumberComma2 (event) {
      this.inputData.standardTime = event.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    isNumberCheck1 (keyPressed) {
      let useData = keyPressed.target.value

      let keyCode = (keyPressed.keyCode ? keyPressed.keyCode : keyPressed.which)

      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || useData.indexOf('.') !== -1)) {
        keyPressed.preventDefault()
      }

      if (useData !== null && useData.indexOf('.') > -1 && (useData.split('.')[1].length > 1)) {
        keyPressed.preventDefault()
      }

      if ((useData === null || useData === '') && keyCode === 46) {
        keyPressed.preventDefault()
      }
    },
    isNumberCheck2 (keyPressed) {
      let keyCode = (keyPressed.keyCode ? keyPressed.keyCode : keyPressed.which)

      if ((keyCode < 48 || keyCode > 57)) {
        keyPressed.preventDefault()
      }
    }
  },
  mounted () {
    xray.index()
  }
}
</script>
