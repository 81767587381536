<template>
  <b-container fluid>

    <b-row>
      <b-col md="12" class="table-responsive">
        <iq-card>

          <!-- Header Section -->
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 gap-response">

              <div class="ml-4">
                <span style="font-size: 25px; color: black;">{{ mode === 'add' ? 'เพิ่มใบขอซื้อ' : mode === 'edit' ?
                  'ใบขอซื้อ (Edit)' : 'ใบขอซื้อ (View)' }}</span>
              </div>

              <div class="row button-response-header" style="display: flex; align-items: center;">
                <div class="flex-response-header my-2 mx-2" style="white-space: nowrap;">

                  <div v-if="mode === 'add' || mode === 'edit'">
                    <b-button type="button" variant="primary" class="my-1 mx-1 flex-fill text-center btn-add btn-min-size"
                      style="text-align: center;" @click="savePR(false)"><i class="save-data_ct" />บันทึก</b-button>
                  </div>

                  <div>
                    <b-button type="button" variant="danger" class="my-1 mx-1 flex-fill text-center btn-add btn-min-size"
                      style="text-align: center;" @click="previousPage">ยกเลิก</b-button>
                  </div>

                  <div v-if="mode === 'add' || mode === 'edit'">
                    <b-button type="button" variant="success" class="my-1 mx-1 flex-fill text-center btn-add btn-min-size"
                      style="text-align: center;" @click="savePR(true)">ส่งอนุมัติ</b-button>
                  </div>

                </div>
              </div>

            </div>
          </template>
          <!-- <template v-slot:headerTitle>
            <b-row class="align-items-center py-4 gap-response">

              <b-col cols="12" sm="auto" class="p-0 ml-4">
                <span style="font-size: 25px; color: black;">{{ mode === 'add' ? 'เพิ่มใบขอซื้อ' : mode === 'edit' ?
                  'ใบขอซื้อ (Edit)' : 'ใบขอซื้อ (View)' }}</span>
              </b-col>

              <b-col cols="12" sm="auto" class="p-0">
                <b-row class="button-response-header" style="align-items: center;">

                  <b-col cols="12" sm="auto" class="p-0 m-1">
                    <div v-if="mode === 'add' || mode === 'edit'">
                      <b-button type="button" variant="primary" class="flex-fill text-center btn-add btn-min-size w-100"
                        style="text-align: center;" @click="savePR(false)"><i class="save-data_ct" />บันทึก</b-button>
                    </div>
                  </b-col>

                  <b-col cols="12" sm="auto" class="p-0 m-1">
                    <div>
                      <b-button type="button" variant="danger" class="flex-fill text-center btn-add btn-min-size w-100"
                        style="text-align: center;" @click="previousPage">ยกเลิก</b-button>
                    </div>
                  </b-col>

                  <b-col cols="12" sm="auto" class="p-0 m-1">
                    <div v-if="mode === 'add' || mode === 'edit'">
                      <b-button type="button" variant="success" class="flex-fill text-center btn-add btn-min-size w-100"
                        style="text-align: center;" @click="savePR(true)">ส่งอนุมัติ</b-button>
                    </div>
                  </b-col>

                </b-row>
              </b-col>

            </b-row>
          </template> -->

          <!-- Body Section -->
          <template v-slot:body>
            <div class="mx-3">

              <b-row>
                <b-col cols="12" md="4" lg="3">
                  <b-form-group label="หมายเลขใบขอซื้อ" label-for="pr_no">

                    <b-form-input v-if="mode === 'add'" placeholder="Auto Gen PR No." id="pr_no"
                      :readonly="true"></b-form-input>
                    <b-form-input v-else id="pr_no" v-model="prDetail.pr_no" :readonly="true"></b-form-input>

                  </b-form-group>
                </b-col>

                <b-col cols="12" md="4" lg="3">
                  <b-form-group label="อ้างอิงเลขใบขอซื้อ" label-for="ref_pr_no">

                    <v-select :value="prDetail.pr_ref_id" :options="ref_list_pr_map" :disabled="mode === 'view'" @input="chagePRRef"></v-select>

                  </b-form-group>
                </b-col>

                <b-col cols="12" md="4" lg="3">
                  <b-form-group label="วันที่เลขใบขอซื้อ" label-for="pr_date">

                    <div style="position: relative;">
                      <date-picker id="datepicker-custom-1" v-model="prDetail.pr_date" :config="optionsDatepickerDefault"
                        :readonly="isDisable" />
                      <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(1)"
                        style="cursor: pointer;" />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Header P && M -->
              <br />
              <span style="font-size: 25px; color: rgb(37, 37, 37);">รายการใบขอซื้อ</span>

              <!-- Product -->
              <hr />

              <template>
                <b-row>
                  <b-col cols="12">
                    <div class="d-flex my-3" style="white-space: nowrap; gap: .5rem">

                      <b-button v-if="mode !== 'view'" variant="primary" @click="modal_product = true"
                        class=" flex-fill text-center btn-min-size" style="text-align: center; max-width: 200px;"><i
                          class="circle-plus_ct" />เพิ่มสินค้า</b-button>

                      <b-button v-else class=" flex-fill text-center btn-min-size"
                        style="text-align: center; max-width: 200px;" disabled><i
                          class="circle-plus_ct" />เพิ่มสินค้า</b-button>

                    </div>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12">
                    <div class="table-responsive">

                      <b-table bordered hover :items="rows_product.data" :fields="columns_product"
                        :current-page="currentPageProduct" :per-page="perPageProduct" @pagination="onPaginationProduct"
                        :tbody-tr-class="rowClass" thead-class="table-header-color">

                        <template v-for="(column) in columns_product" v-slot:[`cell(${column.key})`]="data">

                          <template v-if="column.key === 'no'">
                            <span :key="column.key">{{ ((currentPageProduct - 1) * perPageProduct) + data.index + 1
                            }}</span>
                          </template>

                          <template v-if="column.key === 'prod_no'">
                            <span :key="column.key">{{ data.item.no }}</span>
                          </template>

                          <template v-if="column.key === 'prod_name'">
                            <span :key="column.key">{{ data.item.name }}</span>
                          </template>

                          <template v-if="column.key === 'prod_quantity'">
                            <CurrencyInputCom v-model="data.item.quantity" :options="optionCurrency"
                              @change="data.item.status = data.item?.pri_id ? 'update' : 'add'"
                              :key="column.key + data.index" :disabled="isDisable" />
                          </template>

                          <template v-if="column.key === 'mgt'">
                            <div style="display: flex; justify-content: center;" :key="column.key">
                              <b-button @click="deleteRow(data.item, 'rows_product', 'product_select', 'product_delete')"
                                variant=" iq-bg-bin-custom btn-bin-border" :disabled="isDisable" size="sm"><i
                                  class="ri-delete-bin-line m-0"></i></b-button>
                            </div>
                          </template>

                        </template>

                      </b-table>

                    </div>
                  </b-col>
                </b-row>

                <b-pagination v-model="currentPageProduct" :total-rows="rows_product.data?.length || 0"
                  :per-page="perPageProduct" align="center" />

              </template>

              <!-- rawMaterial -->
              <hr />

              <template>
                <b-row>
                  <b-col cols="12">
                    <div class="d-flex my-3" style="white-space: nowrap; gap: .5rem">

                      <b-button v-if="mode !== 'view'" :disabled="isDisable" variant=" bg-success-custom"
                        class=" flex-fill text-center btn-min-size" @click="modal_rawmat = true"
                        style="text-align: center; max-width: 200px;"><i class="circle-plus_ct" />เพิ่มวัตถุดิบ</b-button>
                      <b-button v-else class=" flex-fill text-center btn-min-size"
                        style="text-align: center; max-width: 200px;" disabled><i
                          class="circle-plus_ct" />เพิ่มวัตถุดิบ</b-button>

                    </div>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12">
                    <div class="table-responsive">

                      <b-table bordered hover :items="rows_rawmat.data" :fields="columns_rawmat"
                        :current-page="currentPageRawmat" :per-page="perPageRawmat" @pagination="onPaginationRawmat"
                        :tbody-tr-class="rowClass" thead-class="table-header-color">

                        <template v-for="(column) in columns_rawmat" v-slot:[`cell(${column.key})`]="data">

                          <template v-if="column.key === 'no'">
                            <span :key="column.key">{{ ((currentPageRawmat - 1) * perPageRawmat) + data.index + 1
                            }}</span>
                          </template>

                          <template v-if="column.key === 'rawmat_category'">
                            <span :key="column.key">{{ data.item.mg_name }}</span>
                          </template>

                          <template v-if="column.key === 'rawmat_brand'">
                            <span :key="column.key">{{ data.item.mb_name }}</span>
                          </template>

                          <template v-if="column.key === 'ramat_type'">
                            <span :key="column.key">{{ data.item.mt_name }}</span>
                          </template>

                          <template v-if="column.key === 'rawmat_unit'">
                            <span :key="column.key">{{ data.item.unit_name }}</span>
                          </template>

                          <template v-if="column.key === 'rawmat_quantity'">
                            <CurrencyInputCom v-model="data.item.quantity" :options="optionCurrency"
                              @change="data.item.status = data.item?.pri_id ? 'update' : 'add'"
                              :key="column.key + data.index" :disabled="isDisable" />
                          </template>

                          <template v-if="column.key === 'mgt'">
                            <div style="display: flex; justify-content: center;" :key="column.key">
                              <b-button @click="deleteRow(data.item, 'rows_rawmat', 'rawmat_select', 'rawmat_delete')"
                                variant=" iq-bg-bin-custom btn-bin-border" :disabled="isDisable" size="sm"><i
                                  class="ri-delete-bin-line m-0"></i></b-button>
                            </div>
                          </template>

                        </template>

                      </b-table>

                    </div>
                  </b-col>
                </b-row>

                <b-pagination v-model="currentPageRawmat" :total-rows="rows_rawmat.data?.length || 0"
                  :per-page="perPageRawmat" align="center" />

              </template>

              <b-row>
                <b-col lg="6" md="6" sm="12">
                  <b-form-group label="ข้อคิดเห็น" label-for="remark">

                    <b-textarea id="remark" v-model="prDetail.pr_remark" :readonly="isDisable"></b-textarea>

                  </b-form-group>
                </b-col>
              </b-row>

              <span style="font-size: 25px; color: rgb(37, 37, 37); text-decoration:underline">วันที่อนุมัติ :
                {{ prDetail.pr_approve_date }}</span>

            </div>
          </template>

        </iq-card>
      </b-col>
    </b-row>

    <b-modal v-model="modal_product" title="เลือกสินค้า" cancel-title="ยกเลิก" ok-title="เพิ่ม" @ok="onSubmitProductModal"
      @close="modal_product = false" size="xl" :no-close-on-esc="true" :no-close-on-backdrop="true">

      <ProductSelectModal ref="productSelectedCom" :mainProductSelected="rows_product.data"
        :deleteProductSelected="JSON.parse(JSON.stringify(product_delete))" />

      <template #modal-footer>
        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">

          <b-button style="border-radius: 0.5rem; padding: 0 1.1rem !important;" variant="primary" class="btn-min-size"
            size="sm" @click="onSubmitProductModal">
            <i class="save-data_ct" />บันทึก
          </b-button>

          <b-button variant="danger" class="btn-min-size btn-cancel-border" style="border-radius: 0.5rem;" size="sm"
            @click="modal_product = false">
            ยกเลิก
          </b-button>

        </div>
      </template>
    </b-modal>

    <b-modal v-model="modal_rawmat" title="เลือกวัตถุดิบ" cancel-title="ยกเลิก" ok-title="เพิ่ม" @ok="onSubmitRawmatModal"
      @close="modal_rawmat = false" size="xl" :no-close-on-esc="true" :no-close-on-backdrop="true">

      <rawMatSelectModal ref="rawmatSelectedCom" :mainRawMatSelected="rows_rawmat.data"
        :deleteRawMatSelected="JSON.parse(JSON.stringify(rawmat_delete))" />

      <template #modal-footer>
        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">

          <b-button style="border-radius: 0.5rem; padding: 0 1.1rem !important;" variant="primary" class="btn-min-size"
            size="sm" @click="onSubmitRawmatModal">
            <i class="save-data_ct" />บันทึก
          </b-button>

          <b-button variant="danger" class="btn-min-size btn-cancel-border" style="border-radius: 0.5rem;" size="sm"
            @click="modal_rawmat = false">
            ยกเลิก
          </b-button>

        </div>
      </template>
    </b-modal>

  </b-container>
</template>

<style scoped>
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import moment from 'moment'
import { mask } from 'vue-the-mask'
import { configAPI } from '../../configBase'
import ProductSelectModal from './component/productSelectModalPR.vue'
import rawMatSelectModal from './component/rawMatSelectModalPR.vue'
import CurrencyInputCom from './CurrencyInputCom.vue'
import Axios from 'axios'

const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}

export default {
  name: 'QuotationMGT',
  components: {
    ProductSelectModal,
    rawMatSelectModal,
    CurrencyInputCom
  },

  directives: { mask },

  data () {
    return {
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      usrId: JSON.parse(sessionStorage.getItem('user')).usr_id || null,
      mode: 'add',
      isDisable: false,
      optionsDatepickerDefault: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: false,
        showClose: true,
        useStrict: true,
        minDate: false,
        maxDate: false,
        icons: iconDate,
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'auto'
        }
      },
      optionCurrency: {
        currency: 'EUR',
        currencyDisplay: 'hidden',
        valueRange: {
          min: 0,
          max: 1e+53
        },
        precision: 0,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: true,
        accountingSign: false
      },
      columns_product: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        { label: 'รหัสสินค้า', key: 'prod_no', class: 'text-center table-size-200-400 ellips' },
        { label: 'ชื่อสินค้า', key: 'prod_name', class: 'text-center table-size-200-400 ellips' },
        { label: 'จำนวน', key: 'prod_quantity', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'mgt', class: 'text-center my-column' }
      ],
      rows_product: {
        data: [],
        oldData: []
      },
      product_select: [],
      product_delete: [],
      currentPageProduct: 1,
      perPageProduct: 10,
      modal_product: false,
      columns_rawmat: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        { label: 'หมวดหมู่', key: 'rawmat_category', class: 'text-center table-size-200-400' },
        { label: 'แบรนด์', key: 'rawmat_brand', class: 'text-center table-size-200-400 ellips' },
        { label: 'ประเภท', key: 'ramat_type', class: 'text-center table-size-200-400 ellips' },
        { label: 'หน่วยนับ', key: 'rawmat_unit', class: 'text-center table-size-200-400' },
        { label: 'จำนวน', key: 'rawmat_quantity', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'mgt', class: 'text-center my-column' }
      ],
      rows_rawmat: {
        data: [],
        oldData: []
      },
      rawmat_select: [],
      rawmat_delete: [],
      currentPageRawmat: 1,
      perPageRawmat: 10,
      modal_rawmat: false,
      prDetail: {
        pr_id: null,
        pr_no: null,
        pr_ref_id: null,
        pr_date: null,
        pr_remark: null,
        pr_approve_date: null
      },
      ref_list_pr: {
        data: []
      },
      product_list: [],
      rawmat_list: []
    }
  },

  async mounted () {
    await this.purchaseRequisitionDropdown()

    if (this.$route.params.setPage === 'edit') {
      this.mode = 'edit'
      this.isDisable = false
      this.prDetail.pr_id = this.$route.params.id
      await this.getPurchaseRequisitionById()
    } else if (this.$route.params.setPage === 'view') {
      this.mode = 'view'
      this.isDisable = true
      this.prDetail.pr_id = this.$route.params.id
      await this.getPurchaseRequisitionById()
    } else {
      this.mode = 'add'
      this.isDisable = false
      this.prDetail.pr_date = moment(new Date()).startOf('day')
    }

    this.optionsDatepickerDefault.minDate = moment(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate()
    xray.index()
  },

  computed: {
    ref_list_pr_map () {
      return this.ref_list_pr.data?.map(option => ({ code: option.pr_id, label: option.pr_no })).filter(el => el.code !== this.prDetail.pr_id)
    }
  },

  watch: {
  },

  methods: {
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },

    onClickDatePicker (number) {
      let datePicker = null
      datePicker = document.getElementById(`datepicker-custom-${number}`)
      datePicker.focus()
    },

    onPaginationProduct (page) {
      this.currentPageProduct = page
    },

    onPaginationRawmat (page) {
      this.currentPageRawmat = page
    },

    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },

    onSubmitProductModal () {
      let Old = JSON.parse(JSON.stringify(this.product_select))
      let [New, Del] = this.$refs.productSelectedCom.submitAction()

      this.product_select = []
      this.rows_product.data = []

      for (let item of New) {
        let indexDel = Del.findIndex(el => el?.id === item?.id)
        if (indexDel !== -1) {
          item = Del[indexDel]
          Del.splice(indexDel, 1)
        }

        let index = Old.findIndex(el => el.id === item.id)
        if (index < 0) {
          this.product_select.push(item)
        } else {
          this.product_select.push(Old[index])
        }
      }

      this.rows_product.data = [...this.product_select]
      this.product_delete = Del
      this.modal_product = false
    },

    onSubmitRawmatModal () {
      let Old = JSON.parse(JSON.stringify(this.rawmat_select))
      let [New, Del] = this.$refs.rawmatSelectedCom.submitAction()

      this.rawmat_select = []
      this.rows_rawmat.data = []

      for (let item of New) {
        let indexDel = Del.findIndex(el => el?.id === item?.id)
        if (indexDel !== -1) {
          item = Del[indexDel]
          Del.splice(indexDel, 1)
        }

        let index = Old.findIndex(el => el.id === item.id)
        if (index < 0) {
          this.rawmat_select.push(item)
        } else {
          this.rawmat_select.push(Old[index])
        }
      }

      this.rows_rawmat.data = [...this.rawmat_select]
      this.rawmat_delete = Del
      this.modal_rawmat = false
    },

    deleteRow (item, state, selectState, deleteState) {
      let temp = this[state].data
      let tempSelect = this[selectState]

      let index = temp.findIndex(el => el.id === item.id)
      let indexSelect = tempSelect.findIndex(el => el.id === item.id)

      if (index >= 0) {
        if (temp[index].pri_id && (this[deleteState].find(el => el.pri_id === temp[index].pri_id) === undefined)) {
          this[deleteState].push(temp[index])
        }

        temp.splice(index, 1)
      }

      if (indexSelect >= 0) {
        tempSelect.splice(indexSelect, 1)
      }
    },

    previousPage () {
      if (this.mode !== 'view') {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            router.push('/PurchaseRequisitionTable')
          }
        })
      } else {
        router.push('/PurchaseRequisitionTable')
      }
    },

    async checkSave () {
      let errList = []

      if (this.prDetail.pr_date === null || this.prDetail.pr_date?.toString().trim() === '') {
        errList.push('กรุณาเลือก วันที่เลขใบขอซื้อ')
      }

      if (this.rows_product.data.length === 0 && this.rows_rawmat.data.length === 0) {
        errList.push('กรุณาเลือกสินค้า หรือวัตถุดิบ อย่างน้อย 1 รายการ')
      } else {
        let temp1 = []

        for (let item of this.rows_product.data) {
          if (item.quantity === null || item.quantity === '') {
            temp1.push(`รหัสสินค้า <span class="ellipsModal">${item.no}</span> : กรุณากรอกจำนวน`)
          }
        }

        if (temp1.length > 0) {
          if (errList.length > 0) {
            errList.push('</hr>')
          }

          errList.push(`กรอกข้อมูลสินค้าไม่ครบถ้วน`)
          errList.push(...temp1)
        }

        let temp2 = []
        let indexTemp2 = 1

        for (let item of this.rows_rawmat.data) {
          if (item.quantity === null || item.quantity === '') {
            temp2.push(`วัตถุดิบลำดับที่ ${indexTemp2} : กรุณากรอกจำนวน`)
          }

          indexTemp2 += 1
        }

        if (temp2.length > 0) {
          if (errList.length > 0) {
            errList.push('</hr>')
          }

          errList.push(`กรอกข้อมูลวัตถุดิบไม่ครบถ้วน`)
          errList.push(...temp2)
        }
      }

      return errList
    },

    async savePR (sendApprove) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะ${sendApprove ? 'ส่งอนุมัติ' : this.mode === 'add' ? 'บันทึก' : 'แก้ไข'}ใช่หรือไม่</span>`,
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        confirmButtonColor: '#0085FF',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let errorList = await this.checkSave()

          if (errorList.length > 0) {
            let message = ''

            errorList.forEach((item) => {
              message += `${item} <br>`
            })

            this.SwalError(message, 'กรุณากรอกข้อมูลให้ครบถ้วน')
          } else {
            const dataForSave = {
              user_id: this.usrId,
              pr_id: this.prDetail.pr_id || null,
              pr_no: this.prDetail.pr_no || null,
              pr_ref_id: this.prDetail.pr_ref_id?.code || null,
              pr_date: this.prDetail.pr_date ? new Date(moment(this.prDetail.pr_date, 'DD/MM/YYYY')) : null,
              pr_remark: this.prDetail.pr_remark || null,
              pr_product_list: this.rows_product.data,
              pr_rawmat_list: this.rows_rawmat.data,
              pr_product_delete: this.product_delete,
              pr_rawmat_delete: this.rawmat_delete,
              is_sendApprove: sendApprove
            }

            await Axios.request({
              method: 'POST',
              url: `${configAPI.SALE_PORT}/api/purchaseRequisition/clinic/${this.clnId}/${this.mode === 'add' ? 'savePurchaseRequisition' : 'editPurchaseRequisition'}`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: dataForSave
            }).then((res) => {
              Vue.swal.fire({
                title: `<span class="" style="font-size: 25px; color: black; text-align: center">${sendApprove ? 'ส่งอนุมัติ' : this.mode === 'add' ? 'บันทึก' : 'แก้ไข'}สำเร็จ</span>`,
                icon: 'success',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 1500
              }).then(() => {
                router.push('/PurchaseRequisitionTable')
              })
            }).catch((err) => {
              this.SwalError(err, 'Error')
            })
          }
        }
      })
    },

    async purchaseRequisitionDropdown () {
      await Axios.request({
        method: 'get',
        url: `${configAPI.SALE_PORT}/api/purchaseRequisition/clinic/${this.clnId}/purchaseRequisitionDropdown`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        this.ref_list_pr.data = response.data.data
      }).catch((error) => {
        this.SwalError(error, 'Error')
      })
    },

    async getPurchaseRequisitionById (prId) {
      await Axios.request({
        method: 'get',
        url: `${configAPI.SALE_PORT}/api/purchaseRequisition/clinic/${this.clnId}/getPurchaseRequisitionById/${prId || this.$route.params.id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        const tempData = response.data.data[0]

        this.prDetail.pr_remark = tempData?.pr_remark || null

        if (!prId) {
          this.prDetail.pr_no = tempData?.pr_no || null
          this.prDetail.pr_date = tempData?.pr_date ? moment(tempData?.pr_date).format('DD/MM/YYYY') : ''
          this.prDetail.pr_ref_id = tempData?.pr_ref_id ? this.ref_list_pr_map.find(el => el.code === tempData?.pr_ref_id) || null : null
          this.prDetail.pr_approve_date = tempData?.pr_approve_date ? moment(tempData?.pr_approve_date).format('DD/MM/YYYY') : ''
        }

        const arrList = {
          prod_list: [],
          rm_List: []
        }

        for (let item of tempData?.pr_item_list) {
          item.is_select = true
          item.quantity = item.pri_amount
          item.pri_id = prId ? null : item.pri_id

          if (prId) {
            item.status = 'add'
          }

          if (item.pri_prd_id) {
            item.id = item.pri_prd_id

            const dataName = {
              no: item.pri_item_data.no,
              name: item.pri_item_data.name
            }

            arrList.prod_list.push({ ...item, ...dataName })
          }

          if (item.pri_mat_id) {
            item.id = item.pri_mat_id

            const dataName = {
              mg_name: item.pri_item_data.mg_name,
              mb_name: item.pri_item_data.mb_name,
              mt_name: item.pri_item_data.mt_name,
              unit_name: item.pri_item_data.unit_name
            }

            arrList.rm_List.push({ ...item, ...dataName })
          }

          this.rows_product.data = arrList.prod_list
          this.rows_rawmat.data = arrList.rm_List

          if (!prId && this.mode !== 'add') {
            this.rows_product.oldData = JSON.parse(JSON.stringify(arrList.prod_list || []))
            this.rows_rawmat.oldData = JSON.parse(JSON.stringify(arrList.rm_List || []))
          }
        }
      }).catch((error) => {
        this.SwalError(error, 'Error')
      })
    },

    GetTextConfirmSwal (text, width) {
      return {
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${text}</span>`,
        html: '<span>สินค้าและวัตถุดิบที่คุณเลือกจะถูกล้าง</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        showCancelButton: true,
        width: width,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        confirmButtonColor: '#0085FF',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }
    },

    confirmClearItemFromPRRef (refDataOld) {
      Vue.swal.fire(this.GetTextConfirmSwal('คุณมั่นใจว่าจะแก้ไขเลขอ้างอิงใบขอซื้อใช่หรือไม่', 700))
        .then(async (result) => {
          if (result.isConfirmed) {
            this.prDetail.pr_remark = null
            this.prDetail.pr_ref_id = null

            if (this.mode !== 'add') {
              this.product_delete = JSON.parse(JSON.stringify([ ...this.rows_product.oldData ]))
              this.rawmat_delete = JSON.parse(JSON.stringify([ ...this.rows_rawmat.oldData ]))
            }

            this.rows_product.data = []
            this.rows_rawmat.data = []
          } else {
            this.prDetail.pr_ref_id = refDataOld
          }
        })
    },

    async chagePRRef (newVal) {
      const oldVal = this.prDetail.pr_ref_id

      if (!newVal && (this.rows_product.data.length > 0 || this.rows_rawmat.data.length > 0)) {
        this.confirmClearItemFromPRRef(oldVal)
      } else {
        if (oldVal) {
          Vue.swal.fire(this.GetTextConfirmSwal('คุณมั่นใจว่าจะแก้ไขเลขอ้างอิงใบขอซื้อใช่หรือไม่', 600))
            .then(async (result) => {
              if (result.isConfirmed) {
                this.prDetail.pr_ref_id = newVal
                await this.getPurchaseRequisitionById(newVal.code)
              } else {
                this.prDetail.pr_ref_id = oldVal
              }
            })
        } else {
          this.prDetail.pr_ref_id = newVal
          await this.getPurchaseRequisitionById(newVal.code)
        }
      }
    }
  }
}
</script>
