<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">ลูกค้าในการดูแล (Admin)</span>
              </div>
              <div class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-12 pl-3 pr-2 my-1 my-sm-0">
                    <b-button type="button" variant="none" @click="transferModal = true;" class="w-100"
                      style="color: white; background-color: #8922EFCC;" :disabled="selectDataCheck.length === 0"><i
                        class="ri-shuffle-line" style="margin: 0;" /></b-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" md="7" lg="5">
                  <b-row>
                    <b-col sm="12" md class="mb-3">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input v-model="search" @keydown="enterSearch" class="form-control" id="exampleInputEmail1" placeholder="Search"
                              style="width: 100%;">
                            <a class="search-link" @click="getPatientsInCareAdmin"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                      <span class="mx-2 font-size-14 text-primary">
                        {{ patientsInCareData?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage *
                          currentPage) > patientsInCareData?.length) ? patientsInCareData?.length : (perPage * currentPage)
                        ) + ' of ' + AllDataLength : '0 of ' + AllDataLength }} รายการ
                        <!-- {{ (rows?.length === 0 ? 0 : ((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) >
                          rows?.length) ? rows?.length : (perPage * currentPage)) + ' of ' + countData }} -->
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <br>
            <b-col md="12" class="table-responsive">
              <b-table bordered hover :items="patientsInCareData" :fields="columns" :current-page="currentPage"
                :per-page="perPage" @pagination="onPagination" :tbody-tr-class="rowClass"
                thead-class="table-header-color">
                <template v-slot:head(select)="data">
                  <b-form-checkbox v-model="selectAllDataCheck" :value="true" :unchecked-value="false"
                    @change="selectAllData" />
                </template>
                <template v-slot:cell(select)="data">
                  <b-form-checkbox :key="data.item.cus_id" v-model="selectDataCheck"
                    :value="{ cus_id: data.item.cus_id, cus_fullname: formatFullnameDate(data.item.cus_firstname, data.item.cus_lastname) }"
                    @change="selectData" />
                </template>
                <template v-slot:cell(fullname)="data">
                  <!-- <span>{{ formatFullnameDate(data.item.cus_firstname, data.item.cus_lastname) }}</span> -->
                  <customerModal :id="data.item.cus_id" :label="formatFullnameDate(data.item.cus_firstname, data.item.cus_lastname)"></customerModal>
                </template>
                <template v-slot:cell(nickname)="data">
                  <span>{{ data.item.cus_nickname || "-" }}</span>
                </template>
                <template v-slot:cell(tel)="data">
                  <a :href="'tel:' + formatTel(data.item.cus_tel)"><span>{{ formatTel(data.item.cus_tel) }}</span></a>
                </template>
                <template v-slot:cell(follow_case)="data">
                  <span style="white-space: pre-line;">{{ formatFollowUp(data.item.follow_case) }}</span>
                </template>
                <template v-slot:cell(employeeTakeCare)="data">
                  <span style="white-space: pre-line;">{{ formatSaleName(data.item.sale_name, data.item.sale) }}</span>
                </template>
              </b-table>
              <br>
            </b-col>
            <b-pagination v-model="currentPage" :total-rows="patientsInCareData?.length" :per-page="perPage"
              align="center" />
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal v-model="transferModal" title="ย้ายการดูแลลูกค้า" cancel-title="เพิ่ม" ok-title="ยกเลิก"
      cancel-variant="primary" ok-variant="danger" @close.prevent="transferModal = false" :no-close-on-esc="true"
      :no-close-on-backdrop="true" size="lg">
      <b-row>
        <b-col cols="12" md="5">
          <b-form-group label="ชื่อลูกค้า" label-for="exampleInputText1">
            <template v-slot:label>
              ชื่อลูกค้า
            </template>
            <b-form-textarea id="exampleInputText1" type="text" placeholder="" class="hide-arrow"
              style="line-height: 30px;" disabled="true" v-model="selectDataShow" rows="2" max-rows="10" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="2" style="text-align: center; padding-top: 44px;">
          <i class="ri-shuffle-line" />
        </b-col>

        <b-col cols="12" md="5">
          <b-form-group label="ชื่อพนักงาน" label-for="exampleInputText1">
            <template v-slot:label>
              ชื่อพนักงาน
            </template>
            <v-select v-model="selectDataSave.empSale" :options="sales"></v-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="5">
          <b-form-group label="วันที่ย้ายการดูแล" label-for="exampleInputText1">
            <template v-slot:label>
              วันที่ย้ายการดูแล
            </template>
            <div style="position: relative;">
              <date-picker placeholder="DD/MM/YYYY" id="datepicker-custom1" name="date" :value="new Date()"
                disabled="true" :config="{ format: 'DD/MM/YYYY' }"></date-picker>
              <!-- <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker()" style="cursor: pointer;" /> -->
              <i class="ri-calendar-line m-0 icon-calendar-edit" />
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
          <b-button style="border-radius: 0.5rem; padding: 0 .7rem;" variant="primary" class="btn-min-size" size="sm"
            @click="permanentTransferCustomer" :disabled="selectDataSave.empSale === ''">
            <i class="save-data_ct" />บันทึก
          </b-button>
          <b-button variant="none" class="iq-bg-danger btn-cancel-border btn-min-size" style="border-radius: 0.5rem;"
            size="sm" @click="transferModal = false">
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-container>
</template>

<style>
.input-quicksearch-history {
  width: 120px !important;
  height: 30px !important;
  margin-bottom: 1rem !important;
}
</style>
<script>
import { xray } from '../../config/pluginInit'
// import router from '@/router'
import Vue from 'vue'
import Axios from 'axios'
import moment from 'moment'
import { configAPI } from '../../configBase'

import customerModal from './component/customerModal.vue'

export default {
  name: 'HistoryService',
  components: {
    customerModal
  },
  data () {
    return {
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      usrId: JSON.parse(sessionStorage.getItem('user')).usr_id || null,
      search: '',
      columns: [
        { label: '', key: 'select', class: 'text-center table-size-100-300' },
        { label: 'ชื่อ - นามสกุล (ลูกค้า)', key: 'fullname', class: 'text-center table-size-200-400' },
        { label: 'ชื่อเล่น', key: 'nickname', class: 'text-center table-size-200-400' },
        { label: 'เบอร์โทร', key: 'tel', class: 'text-center table-size-200-400' },
        { label: 'Follow Up', key: 'follow_case', class: 'text-center table-size-200-400', sortable: true, sortDirection: 'desc', sortNullLast: true },
        { label: 'พนักงานที่ดูแล', key: 'employeeTakeCare', class: 'text-center table-size-200-400' }
      ],
      patientsInCareData: [],
      selectAllDataCheck: false,
      selectDataCheck: [],
      selectDataSave: {
        cusId: [],
        empSale: ''
      },
      selectDataShow: '',
      currentPage: 1,
      perPage: 10,
      AllDataLength: 0,
      transferModal: false,
      sales: []
    }
  },
  computed: {
  },
  async mounted () {
    await this.getAllEmployee()
    await this.getPatientsInCareAdmin()
    xray.index()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getPatientsInCareAdmin()
      }
    },
    async getPatientsInCareAdmin () {
      let searchData = {
        search: this.search?.trim()
      }
      await Axios.request({
        method: 'POST',
        url: `${configAPI.MD_PORT}/api/clinic/${this.clnId}/patientInCareAdmin`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: searchData
      }).then((response) => {
        this.patientsInCareData = response.data.data
        this.AllDataLength = response.data.count

        this.selectDataCheck = []
        this.selectDataSave.cusId = []
        this.selectDataShow = ''
        this.selectAllDataCheck = false
      }).catch((err) => {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่พบข้อมูล</span>',
          icon: 'error',
          html: `<span class="" style="font-size: 18px; color: black; text-align: center">${err}</span>`,
          showCancelButton: false,
          showConfirmButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      })
    },
    async getAllEmployee () {
      let getAllEmployeeAPI = {
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/user/`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await Axios.request(getAllEmployeeAPI)
        .then((response) => {
          if (response.data.status) {
            let arrTemp = []
            const resList = response.data.data.results.sort((a, b) => {
              return (new Date(a.created_date) - new Date(b.created_date))
            })
            for (let item of resList) {
              if (item.is_use) {
                arrTemp.push({
                  code: item.id,
                  label: item.ut_id === '5c66dfdb-a9bc-4867-baa7-507175d2fc01' ? `SuperAdmin System` : `${item.emp_name} ${item.emp_lastname}`
                })
              }
            }
            this.sales = arrTemp
          }
        })
    },
    async permanentTransferCustomer () {
      let getAllEmployeeAPI = {
        method: 'POST',
        url: `${configAPI.MD_PORT}/api/user/${this.usrId}/permanentTransferCustomer`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: this.selectDataSave
      }
      await Axios.request(getAllEmployeeAPI)
        .then((response) => {
          this.transferModal = false
          window.location.reload()
        })
    },
    onClickDatePicker () {
      let datePicker = document.getElementById('datepicker-custom1')
      datePicker.focus()
    },
    onPagination (page) {
      this.currentPage = page
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.id % 2 === 1) return 'table-body-color'
    },
    formatFullnameDate (firstname, lastname) {
      const fullname = `${firstname || ''} ${lastname || ''}`
      return fullname?.trim() || '-'
    },
    formatFollowUp (itemList) {
      let temp = ''
      itemList.forEach((item) => {
        temp += `${item.st_name || ''} ${moment(item.fcr_actual_date).add(543, 'year').format('DD/MM/YY')}\n`
      })
      return temp || '-'
    },
    formatSaleName (name, list) {
      let temp = `${name}\n`
      list.forEach((item) => {
        temp += `${item.sale_name}\n`
      })
      return temp || '-'
    },
    formatTel (tel) {
      if (tel) {
        let temp = tel.split('')
        let temp2 = ''
        temp.forEach((item, index) => {
          if (index === 2 || index === 5) {
            temp2 += item + '-'
          } else {
            temp2 += item
          }
        })
        return temp2
      } else {
        return '-'
      }
    },
    selectAllData (value) {
      this.selectDataCheck = []
      this.selectDataSave.cusId = []
      this.selectDataShow = ''
      if (value) {
        this.patientsInCareData.forEach((item) => {
          this.selectDataCheck.push({ cus_id: item.cus_id, cus_fullname: this.formatFullnameDate(item.cus_firstname, item.cus_lastname) })
          this.selectDataSave.cusId.push(item.cus_id)
          this.selectDataShow += `${this.formatFullnameDate(item.cus_firstname, item.cus_lastname)}\n`
        })
        this.selectDataShow = this.selectDataShow.substring(0, this.selectDataShow.length - 1)
      }
    },
    selectData (value) {
      this.selectDataSave.cusId = []
      this.selectDataShow = ''
      if (value) {
        value.forEach((item) => {
          this.selectDataSave.cusId.push(item.cus_id)
          this.selectDataShow += `${item.cus_fullname}\n`
        })
        this.selectDataShow = this.selectDataShow.substring(0, this.selectDataShow.length - 1)
      }

      if (this.selectDataCheck.length === this.patientsInCareData.length) {
        this.selectAllDataCheck = true
      } else {
        this.selectAllDataCheck = false
      }
    }
  }
}
</script>
