<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">การปฏิบัติงาน และประวัติ KPI</span>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" sm="6" md="auto" class="mb-3 ">
                  <b-row style="align-items: baseline; flex-wrap: nowrap;">
                    <b-col cols="2" sm="auto">
                      <span style="color: black;">เดือน</span>
                    </b-col>
                    <b-col cols md="9">
                      <v-select style="min-width: 150px;" class="format-select-kpi" v-model="monthTerm" :options="monthOption" size="md"></v-select>
                      <!-- <b-form-select plain v-model="selectedOption" :options="monthOption" size="md"
                        id="exampleFormControlSelect1" style="height: 40px;">

                        <template v-slot:first>
                          <b-form-select-option :value="''">ทุกเดือน</b-form-select-option>
                        </template>
                      </b-form-select> -->
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" sm="6" md="auto" class="mb-3 col-md-auto-custom-kpi">
                  <b-row style="align-items: baseline; flex-wrap: nowrap;">
                    <b-col cols="2" sm="auto">
                      <span style="color: black;">ปี</span>
                    </b-col>
                    <b-col cols>
                      <b-form-input id="exampleInputText1" type="text" placeholder="" v-model="yearTerm"
                        @keypress="isNumberCheck($event)" style="height: 40px;" />
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="12" md="auto" class="mb-3">
                  <b-row>
                    <b-col cols="6" md="auto" class="pr-sm-1 my-1 my-sm-0">
                      <b-button type="submit" variant="primary" class="w-100 btn-min-size" @click="getKPI()"><i class="search_ct"></i>ค้นหา</b-button>
                    </b-col>
                    <b-col cols="6" md="auto" class="pr-sm-1 my-1 my-sm-0">
                      <b-button type="submit" variant="none" class="w-100 btn-min-size btn-reset_ct" @click="clearKPI()">ล้างข้อมูล</b-button>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="table-responsive">
                  <!-- <b-table bordered hover :items="kpi" :fields="columns"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @pagination="onPagination"
                    :tbody-td-class="rowClass" thead-class="table-header-color"
                  >
                    <template v-slot:cell(variable1)="data">
                      <span v-if="!data.item.editable">{{ kpi[((currentPage - 1) * 10) + data.index].first_name + ' ' + kpi[((currentPage - 1) * 10) + data.index].last_name || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable2)="data">
                      <span v-if="!data.item.editable" :class="rowClass(kpi[((currentPage - 1) * 10) + data.index].kpi_time_attendance)">{{ kpi[((currentPage - 1) * 10) + data.index].kpi_time_attendance || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable3)="data">
                      <span v-if="!data.item.editable" :class="rowClass(kpi[((currentPage - 1) * 10) + data.index].kpi_sale_target)">{{ kpi[((currentPage - 1) * 10) + data.index].kpi_sale_target || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable4)="data">
                      <span v-if="!data.item.editable" :class="rowClass(kpi[((currentPage - 1) * 10) + data.index].kpi_comment)">{{ kpi[((currentPage - 1) * 10) + data.index].kpi_comment || '-' }}</span>
                    </template>
                  </b-table> -->
                  <b-table bordered hover :items="kpi" :fields="columns"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @pagination="onPagination"
                    thead-class="table-header-color"
                    :tbody-tr-class="checkTable"
                  >
                    <template v-slot:cell(variable1)="data">
                      <span v-if="!data.item.editable">{{ kpi[((currentPage - 1) * 10) + data.index].first_name + ' ' + kpi[((currentPage - 1) * 10) + data.index].last_name || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable2)="data">
                      <span v-if="!data.item.editable">{{ kpi[((currentPage - 1) * 10) + data.index].kpi_time_attendance || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable3)="data">
                      <span v-if="!data.item.editable">{{ formatNumber(kpi[((currentPage - 1) * 10) + data.index].kpi_sale_target?.result || '-') + ' / ' + formatNumber(kpi[((currentPage - 1) * 10) + data.index].kpi_sale_target?.target || '-') }}</span>
                    </template>
                    <template v-slot:cell(variable4)="data">
                      <span v-if="!data.item.editable">{{ kpi[((currentPage - 1) * 10) + data.index].kpi_comment?.toFixed(2) || '-' }}</span>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row style="display: flex; justify-content: center;">
                <b-pagination v-model="currentPage" :total-rows="kpi.length" :per-page="perPage" />
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.format-select-kpi > .vs__dropdown-toggle {
  height: 40px !important;
}
.format-select-kpi > .vs__dropdown-toggle > .vs__selected-options > .vs__selected {
  margin-top: 0.2rem !important;
}
@media (min-width: 768px) {
  .col-md-auto-custom-kpi {
    max-width: 205.172px !important;
    max-width: 205.172px !important;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import axios from 'axios'
import { configAPI } from '../../configBase'

export default {
  name: 'UiDataTable',
  async mounted () {
    xray.index()
    this.cln_id = JSON.parse(sessionStorage.getItem('user')).cln_id
    await this.getKPI()
    this.inputValue = this.thaiYear
  },
  methods: {
    formatNumber (number) {
      if (number) {
        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } else {
        return '-'
      }
    },
    async getKPI () {
      let year = this.yearTerm?.trim().length > 0 ? this.yearTerm?.trim() : ''
      let month = this.monthTerm ? this.monthTerm.code : ''
      let KPIAPI = {
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/kpi/summary?month=${month}&year=${year}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(KPIAPI)
        .then((response) => {
          this.kpi = response.data.data
          this.checkTable()
        })/* .catch((error) => {
          console.error(error)
        }) */
    },
    async clearKPI () {
      const currentDate = new Date()
      this.yearTerm = currentDate.getFullYear().toString()
      this.monthTerm = { code: '', label: 'ทุกเดือน' }
      await this.getKPI()
    },
    checkTable () {
      this.$nextTick(() => {
        const tdElements = document.querySelectorAll('tr[aria-rowindex] td[aria-colindex][role="cell"]')
        tdElements.forEach((td) => {
          const colIndex = td.getAttribute('aria-colindex')
          if (colIndex === '2') {
            const spanElement = td.querySelector('span')
            if (spanElement) {
              td.classList.remove('iq-bg-success')
              td.classList.remove('iq-bg-warning')
              td.classList.remove('iq-bg-danger')
              const textContent = spanElement.textContent
              if (parseFloat(textContent) > 80) {
                td.classList.add('iq-bg-success', 'text-black')
              } else if (parseFloat(textContent) >= 60 && parseFloat(textContent) <= 80) {
                td.classList.add('iq-bg-warning', 'text-black')
              } else if (parseFloat(textContent) < 60) {
                td.classList.add('iq-bg-danger', 'text-black')
              }
            }
          } else if (colIndex === '3') {
            const spanElement = td.querySelector('span')
            if (spanElement) {
              td.classList.remove('iq-bg-success')
              td.classList.remove('iq-bg-warning')
              td.classList.remove('iq-bg-danger')
              const textContent = spanElement.textContent
              // console.log('textContent', textContent)
              let textContentSplit = textContent.split('/')
              // console.log('textContentSplit', textContentSplit)
              if (parseFloat(textContentSplit[0].replace(/,/g, '')) > parseFloat(textContentSplit[1].replace(/,/g, ''))) {
                td.classList.add('iq-bg-success', 'text-black')
              } else if (parseFloat(textContentSplit[0].replace(/,/g, '')) < (parseFloat(textContentSplit[1].replace(/,/g, '')))) {
                td.classList.add('iq-bg-danger', 'text-black')
              }
              // if (parseFloat(textContent) > 80) {
              //   td.classList.add('iq-bg-success', 'text-black')
              // } else if (parseFloat(textContent) >= 60 && parseFloat(textContent) <= 80) {
              //   td.classList.add('iq-bg-warning', 'text-black')
              // } else if (parseFloat(textContent) < 60) {
              //   td.classList.add('iq-bg-danger', 'text-black')
              // }
            }
          } else if (colIndex === '4') {
            const spanElement = td.querySelector('span')
            if (spanElement) {
              td.classList.remove('iq-bg-success')
              td.classList.remove('iq-bg-warning')
              td.classList.remove('iq-bg-danger')
              const textContent = spanElement.textContent
              if (parseFloat(textContent) >= 4) {
                td.classList.add('iq-bg-success', 'text-black')
              } else if (parseFloat(textContent) >= 2 && parseFloat(textContent) <= 3) {
                td.classList.add('iq-bg-warning', 'text-black')
              } else if (parseFloat(textContent) <= 1) {
                td.classList.add('iq-bg-danger', 'text-black')
              }
            }
          }
        })
      })
    },
    add () {
      let obj = this.default()
      this.rows.push(obj)
    },
    default () {
      return {
        id: this.rows.length,
        variable1: '',
        variable2: '',
        variable3: '',
        variable4: '',
        variable5: '',
        variable6: '$0',
        editable: false
      }
    },
    edit (item) {
      item.editable = true
    },
    submit (item) {
      item.editable = false
    },
    remove (item) {
      let index = this.rows.indexOf(item)
      this.rows.splice(index, 1)
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick () {
      // Handle the click event here
      router.push('/AddProduct')
    },
    isNumberCheck (keyPressed) {
      let keyCode = (keyPressed.keyCode ? keyPressed.keyCode : keyPressed.which)

      if (keyCode < 48 || keyCode > 57) {
        keyPressed.preventDefault()
      }
    },
    rowClass (value, type) {
      // if (!item || type !== 'row') return
      if (parseInt(value.kpi_sale_target) > 80) return 'iq-bg-success text-black'
      if (parseInt(value.kpi_sale_target) >= 60 && parseInt(value.kpi_sale_target) <= 80) return 'iq-bg-warning text-black'
      if (parseInt(value.kpi_sale_target) < 60) return 'iq-bg-danger text-black'
      // if (parseInt(item.kpi_sale_target) > 80) return 'iq-bg-success text-black'
      // if (parseInt(item.kpi_sale_target) >= 60 && parseInt(item.kpi_sale_target) <= 80) return 'iq-bg-warning text-black'
      // if (parseInt(item.kpi_sale_target) < 60) return 'iq-bg-danger text-black'
      // if (parseInt(item.kpi_comment) > 80) return 'iq-bg-success text-black'
      // if (parseInt(item.kpi_comment) >= 60 && parseInt(item.kpi_comment) <= 80) return 'iq-bg-warning text-black'
      // if (parseInt(item.kpi_comment) < 60) return 'iq-bg-danger text-black'
    }
  },
  computed: {
    thaiYear () {
      const currentDate = new Date()
      const thaiYear = currentDate.getFullYear() + 543
      return thaiYear.toString()
    }
  },
  watch: {
    currentPage (newPage) {
      this.checkTable(newPage)
    }
  },
  data () {
    const currentDate = new Date()
    return {
      yearTerm: currentDate.getFullYear().toString(),
      monthTerm: { code: '', label: 'ทุกเดือน' },
      kpi: [],
      inputValue: '',
      columns: [
        { label: 'ชื่อ-นามสกุล', key: 'variable1', class: `text-center table-size-200-400` },
        { label: 'ทำงานตรงเวลา', key: 'variable2', class: `text-center table-size-200-400` },
        { label: 'ยอดขายส่วนตัว / ยอดเป้าหมาย', key: 'variable3', class: `text-center table-size-300-500` },
        { label: 'การบริการลูกค้า', key: 'variable4', class: `text-center table-size-200-400` }
        // { label: 'ทำงานตรงเวลา', key: 'variable2', class: `text-center table-size-200-400 ${parseFloat(this.kpi_time_attendance) > 80 ? 'iq-bg-success text-black' : parseFloat(this.kpi_time_attendance) < 60 ? 'iq-bg-danger text-black' : 'iq-bg-warning text-black'}` },
        // { label: 'ยอดขายส่วนตัว', key: 'variable3', class: `text-center table-size-200-400 ${parseFloat(this.kpi_sale_target) > 80 ? 'iq-bg-success text-black' : parseFloat(this.kpi_sale_target) < 60 ? 'iq-bg-danger text-black' : 'iq-bg-warning text-black'}` },
        // { label: 'การบริการลูกค้า', key: 'variable4', class: `text-center table-size-200-400 ${parseFloat(this.kpi_comment) > 80 ? 'iq-bg-success text-black' : parseFloat(this.kpi_comment) < 60 ? 'iq-bg-danger text-black' : 'iq-bg-warning text-black'}` }
      ],
      // const condition = variable4 > 50;
      // if (condition) {
      // columns.push({ label: 'การบริการลูกค้า', key: 'variable4', class: 'text-center table-size-200-400 your-condition-class' });
      // } else {
      //   columns.push({ label: 'การบริการลูกค้า', key: 'variable4', class: 'text-center table-size-200-400' });
      // }
      rows: [
        {
          id: 1,
          variable1: 'TEST 1',
          variable2: '90',
          variable3: '110 %',
          variable4: '5'
        },
        {
          id: 2,
          variable1: 'TEST 2',
          variable2: '70',
          variable3: '90 %',
          variable4: '4'
        },
        {
          id: 3,
          variable1: 'TEST 3',
          variable2: '50',
          variable3: '70 %',
          variable4: '3'
        }
      ],
      monthOption: [
        { code: '', label: 'ทุกเดือน' },
        { code: '1', label: 'มกราคม' },
        { code: '2', label: 'กุมภาพันธ์' },
        { code: '3', label: 'มีนาคม' },
        { code: '4', label: 'เมษายน' },
        { code: '5', label: 'พฤษภาคม' },
        { code: '6', label: 'มิถุนายน' },
        { code: '7', label: 'กรกฎาคม' },
        { code: '8', label: 'สิงหาคม' },
        { code: '9', label: 'กันยายน' },
        { code: '10', label: 'ตุลาคม' },
        { code: '11', label: 'พฤศจิกายน' },
        { code: '12', label: 'ธันวาคม' }
      ],
      currentPage: 1,
      perPage: 10,
      selectedOption: { code: '', label: 'ทุกเดือน' },
      selectYear: new Date().getFullYear() + 543
    }
  }
}
</script>
