<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span class="unselectable" style="font-size: 25px; color: black;">คลังสินค้าทั่วไปคลินิก</span>
              </div>

              <div class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="button" variant="primary-custom" class="w-100 btn-min-size"
                  @click="mode = 'add', modal_pgr = true">
                  <i class="circle-plus_ct"></i>
                  เพิ่ม
                </b-button>
              </div>
            </div>
          </template>

          <template v-slot:body>
            <div class="mx-3">
              <b-row>

                <b-col cols="12" md="6" lg="4" class="mb-3">
                  <div class="iq-email-search d-flex">
                    <div style="width: 100%;">
                      <div class="form-group mb-0" style="height: 45px;">
                        <input v-model="search" class="form-control" id="exampleInputEmail1" placeholder="Search"
                          style="width: 100%; height: 45px;" @keydown="enterSearch">
                        <a class="search-link" style="color: #024187; top: 4px"
                          @click="filterProductGeneralWarehouseTable"><i class="ri-search-line" /></a>
                      </div>
                    </div>
                  </div>
                </b-col>

                <b-col cols="auto" class="pb-3">
                  <div style="display: flex; align-items: center; height: 45px;">
                    <span class="mx-2 font-size-14 text-primary-custom">
                      {{ rows.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage *
                        currentPage) > rows?.length) ? rows.length : (perPage * currentPage)
                      ) + ' of ' + rows_count : '0 of ' + rows_count }} รายการ
                    </span>
                  </div>
                </b-col>

                <b-col cols="auto" class="pb-3" style="display: flex; align-items: center; justify-content: center;">
                  <b-form-checkbox size="lg" style="bottom: 1px; padding: 0; margin: 0 0 0 15px;" class="radio-disable"
                    name="redio-button-in" v-model="checkUMS" @input="filterProductGeneralWarehouseTable" />
                  <span class="unselectable" style="font-size: 16px; color: black;">Under Min Stock</span>
                </b-col>

              </b-row>
            </div>

            <b-col cols="12">
              <div class="table-responsive">
                <b-table bordered hover :items="rows" :fields="columns" :current-page="currentPage" :per-page="perPage"
                  @pagination="onPagination" :tbody-tr-class="rowClass" thead-class="table-header-color">

                  <template v-slot:cell(no)="data">
                    <span>{{ ((currentPage - 1) * perPage) + data.index + 1 }}</span>
                  </template>

                  <template v-slot:cell(productgeneral_code)="data">
                    <span>{{ data.item.pgr_no || '-' }}</span>
                  </template>

                  <template v-slot:cell(productgeneral_name)="data">
                    <span>{{ data.item.pgr_name || '-' }}</span>
                  </template>

                  <template v-slot:cell(unit)="data">
                    <span>{{ data.item.pgr_counting_unit || '-' }}</span>
                  </template>

                  <template v-slot:cell(min_stock)="data">
                    <span>{{ formatNumber(data.item.pgr_min_stock || '-') }}</span>
                  </template>

                  <template v-slot:cell(quantity)="data">
                    <span>{{ formatNumber(data.item.sum_in_out || '-') }}</span>
                  </template>

                  <template v-slot:cell(mgt)="data">
                    <b-button variant=" iq-bg-pencil-ware-custom btn-pencil-ware-border mx-1" size="sm"
                      @click="clickPath(data.item.pgr_id)">
                      <i class="ri-edit-box-fill m-0"></i>
                    </b-button>

                    <b-button variant=" iq-bg-eye-custom btn-eye-border mx-1" size="sm"
                      @click="pgrId = data.item.pgr_id, getPgrById(), mode = 'view', modal_pgr = true">
                      <i class="ri-eye-fill m-0"></i>
                    </b-button>

                    <b-button variant=" iq-bg-pencil-custom btn-pencil-border mx-1" size="sm"
                      @click="pgrId = data.item.pgr_id, getPgrById(), mode = 'edit', modal_pgr = true">
                      <i class="ri-pencil-fill m-0"></i>
                    </b-button>

                    <b-button variant=" iq-bg-bin-custom btn-bin-border mx-1" size="sm"
                      @click="pgrId = data.item.pgr_id, deletePgr()">
                      <i class="ri-delete-bin-line m-0"></i>
                    </b-button>
                  </template>

                </b-table>
              </div>
            </b-col>

            <b-pagination v-model="currentPage" :total-rows="rows?.length" :per-page="perPage" align="center" />

          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal v-model="modal_pgr"
      :title="'สินค้าทั่วไปคลินิก ' + (mode === 'add' ? '(เพิ่ม)' : mode === 'edit' ? '(แก้ไข)' : '(ดูรายละเอียด)')"
      cancel-title="ยกเลิก" ok-title="เพิ่ม" @ok="onSubmitPgrModal" @close.prevent="onClosePgrModal" :no-close-on-esc="true"
      :no-close-on-backdrop="true">

      <b-row>
        <b-col cols="12">
          <b-form-group label="รหัสสินค้า" label-for="no">

            <template v-slot:label>
              รหัสสินค้า<span style="color: red;"> *</span>
            </template>
            <b-form-input id="no" v-model="saveData.pgr_no" :readonly="mode === 'view'"></b-form-input>

          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="ชื่อสินค้า" label-for="name">

            <template v-slot:label>
              ชื่อสินค้า<span style="color: red;"> *</span>
            </template>
            <b-form-input id="name" v-model="saveData.pgr_name" :readonly="mode === 'view'"></b-form-input>

          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="หน่วยนับ" label-for="unit">

            <template v-slot:label>
              หน่วยนับ<span style="color: red;"> *</span>
            </template>
            <b-form-input id="unit" v-model="saveData.pgr_counting_unit" :readonly="mode === 'view'"></b-form-input>

          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="คลังขั้นต่ำ" label-for="min_stock">

            <template v-slot:label>
              คลังขั้นต่ำ<span style="color: red;"> *</span>
            </template>
            <CurrencyInputCom :options="optionCurrency" v-model="saveData.pgr_min_stock" :readonly="mode === 'view'" />

          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">

          <b-button v-if="mode !== 'view'" style="border-radius: 0.5rem; padding: 0 1.1rem !important;" variant="primary" class="btn-min-size"
            size="sm" @click="onSubmitPgrModal">
            <i class="save-data_ct" />บันทึก
          </b-button>

          <b-button variant="danger" class="btn-min-size btn-cancel-border" style="border-radius: 0.5rem;" size="sm"
            @click="onClosePgrModal">
            ยกเลิก
          </b-button>

        </div>
      </template>

    </b-modal>

  </b-container>
</template>

<style scoped>
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import Axios from 'axios'
import moment from 'moment'
import { configAPI } from '../../configBase'
import CurrencyInputCom from './CurrencyInputCom.vue'

export default {
  name: 'GeneralWarehouseTable',

  components: {
    CurrencyInputCom
  },

  data () {
    return {
      clnId: null,
      userId: null,
      search: null,
      checkUMS: false,
      currentPage: 1,
      perPage: 10,
      rows: [],
      rows_count: 0,
      columns: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        { label: 'รหัสสินค้า', key: 'productgeneral_code', class: 'text-center table-size-200-400 ellips' },
        { label: 'ชื่อสินค้า', key: 'productgeneral_name', class: 'text-center table-size-200-400 ellips' },
        { label: 'หน่วยนับ', key: 'unit', class: 'text-center table-size-200-400 ellips' },
        { label: 'สต็อกขั้นต่ำ', key: 'min_stock', class: 'text-center table-size-200-400' },
        { label: 'จำนวน(หน่วย)', key: 'quantity', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'mgt', class: 'text-center table-size-200-400' }
      ],
      pgrId: null,
      mode: 'add',
      modal_pgr: false,
      saveData: {
        pgr_no: null,
        pgr_name: null,
        pgr_counting_unit: null,
        pgr_min_stock: null
      },
      optionCurrency: {
        currency: 'EUR',
        currencyDisplay: 'hidden',
        valueRange: {
          min: 0,
          max: 1e+53
        },
        precision: 0,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: true,
        accountingSign: false
      }
    }
  },

  computed: {
  },

  watch: {
  },

  async mounted () {
    this.clnId = JSON.parse(sessionStorage.getItem('user')).cln_id || null
    this.userId = JSON.parse(sessionStorage.getItem('user')).usr_id || null
    await this.filterProductGeneralWarehouseTable()
    xray.index()
  },

  methods: {
    formatDate (date) {
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      } else {
        return '-'
      }
    },

    formatNumber (number) {
      if (number) {
        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } else {
        return '-'
      }
    },

    formatTel (tel) {
      if (tel) {
        let formatTel = tel.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        return formatTel
      } else {
        return ''
      }
    },

    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.no % 2 === 1) return 'table-body-color'
    },

    onPagination (page) {
      this.currentPage = page
    },

    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.filterProductGeneralWarehouseTable()
      }
    },

    clickPath (data) {
      router.push(`generalWarehouseMgt/${data}`)
    },

    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },

    async getPgrById () {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/warehouse/clinic/${this.clnId}/getProductGeneralWarehouseById/${this.pgrId}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        this.saveData.pgr_no = res.data.data[0]?.pgr_no
        this.saveData.pgr_name = res.data.data[0]?.pgr_name
        this.saveData.pgr_counting_unit = res.data.data[0]?.pgr_counting_unit
        this.saveData.pgr_min_stock = res.data.data[0]?.pgr_min_stock
      }).catch((err) => {
        this.SwalError(err.message, 'เกิดข้อผิดพลาดในดึงข้อมูล')
      })
    },

    async checkSave () {
      let errList = []

      if (this.saveData.pgr_no === null || this.saveData.pgr_no?.toString().trim() === '') {
        errList.push('กรุณากรอกรหัสสินค้า')
      }

      if (this.saveData.pgr_name === null || this.saveData.pgr_name?.toString().trim() === '') {
        errList.push('กรุณากรอกชื่อสินค้า')
      }

      if (this.saveData.pgr_counting_unit === null || this.saveData.pgr_counting_unit?.toString().trim() === '') {
        errList.push('กรุณากรอกหน่วยนับ')
      }

      if (this.saveData.pgr_min_stock === null || this.saveData.pgr_min_stock?.toString().trim() === '') {
        errList.push('กรุณากรอกคลังขั้นต่ำ')
      }

      return errList
    },

    onSubmitPgrModal () {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะ${this.mode === 'add' ? 'บันทึก' : 'แก้ไข'}ใช่หรือไม่</span>`,
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        confirmButtonColor: '#0085FF',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let errorList = await this.checkSave()

          if (errorList.length > 0) {
            let message = ''

            errorList.forEach((item) => {
              message += `${item} <br>`
            })

            this.SwalError(message, 'กรุณากรอกข้อมูลให้ครบถ้วน')
          } else {
            const dataForSave = {
              user_id: this.userId || null,
              pgr_id: this.pgrId || null,
              pgr_no: this.saveData.pgr_no || null,
              pgr_name: this.saveData.pgr_name || null,
              pgr_counting_unit: this.saveData.pgr_counting_unit || null,
              pgr_min_stock: this.saveData.pgr_min_stock || null
            }

            await Axios.request({
              method: 'POST',
              url: `${configAPI.MA_PORT}/api/warehouse/clinic/${this.clnId}/${this.mode === 'add' ? 'saveProductGeneralWarehouse' : 'editProductGeneralWarehouse'}`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: dataForSave
            }).then((res) => {
              if (!res.data.status) {
                this.SwalError(
                  res.data.error,
                  this.mode === 'add' ? 'บันทึกไม่สำเร็จ' : 'แก้ไขไม่สำเร็จ',
                  'error'
                )
                return
              }
              Vue.swal.fire({
                title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.mode === 'add' ? 'บันทึก' : 'แก้ไข'}สำเร็จ</span>`,
                icon: 'success',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 1500
              }).then(() => {
                this.modal_pgr = false
                window.location.reload()
              })
            }).catch((err) => {
              this.SwalError(err, 'Error')
            })
          }
        }
      })
    },

    onClosePgrModal () {
      if (this.mode !== 'view') {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะปิดหน้านี้ใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.modal_pgr = false
            this.pgrId = null
            this.saveData = {
              pgr_no: null,
              pgr_name: null,
              pgr_counting_unit: null,
              pgr_min_stock: null
            }
          }
        })
      } else {
        this.modal_pgr = false
        this.pgrId = null
        this.saveData = {
          pgr_no: null,
          pgr_name: null,
          pgr_counting_unit: null,
          pgr_min_stock: null
        }
      }
    },

    deletePgr () {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะลบข้อมูลนี้ใช่หรือไม่</span>`,
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        confirmButtonColor: '#0085FF',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          const dataForSave = {
            user_id: this.userId || null,
            pgr_id: this.pgrId || null
          }

          await Axios.request({
            method: 'POST',
            url: `${configAPI.MA_PORT}/api/warehouse/clinic/${this.clnId}/deleteProductGeneralWarehouse`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: dataForSave
          }).then((res) => {
            Vue.swal.fire({
              title: `<span class="" style="font-size: 25px; color: black; text-align: center">ลบสำเร็จ</span>`,
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              window.location.reload()
            })
          }).catch((err) => {
            this.SwalError(err, 'Error')
          })
        }
      })
    },

    async filterProductGeneralWarehouseTable () {
      let dataFilter = {
        search: this.search?.toString().trim(),
        checkUMS: this.checkUMS
      }

      await Axios.request({
        method: 'POST',
        url: `${configAPI.MA_PORT}/api/warehouse/clinic/${this.clnId}/filterProductGeneralWarehouseTable`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataFilter
      }).then((res) => {
        this.rows = res.data.data
        this.rows_count = res.data.dataCount
      }).catch((err) => {
        this.SwalError(err.message, 'เกิดข้อผิดพลาดในดึงข้อมูล')
      })
    }
  }
}
</script>
