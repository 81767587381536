<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">กลุ่มบริการ</span>
              </div>
              <div class="col-12 col-sm-auto my-1 my-sm-0">
                <!-- <b-button @click="handleClick('add')" type="submit" variant="primary" class="w-100 btn-min-size"><i
                    class="circle-plus_ct"></i>เพิ่ม</b-button> -->
                <b-button @click="showModal = true, checkMode = 'add', resetData()" type="submit" variant="primary"
                  class="w-100 btn-min-size"><i class="circle-plus_ct"></i>เพิ่ม</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" md="7" lg="5" class="mb-3">
                  <!-- <b-input-group>
                    <b-form-input type="text" placeholder="Search" />
                    <b-input-group-append>
                      <b-button variant="outline-secondary">
                        <i class="fas fa-search"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group> -->
                  <b-row>
                    <b-col sm="12" md class="mb-3" style="padding: 0px;">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input class="form-control" id="exampleInputEmail1" placeholder="Search" style="width: 100%;"
                              v-model="searchItem" @keydown="enterSearch">
                            <a class="search-link" v-on:click="getServiceType"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center; padding: 0px;">
                      <span class="mx-2 font-size-14 text-primary">
                        {{ serviceType?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > serviceType?.length) ? serviceType?.length : (perPage * currentPage) ) + ' of ' + serviceTypeLength : '0 of ' + serviceTypeLength }} รายการ
                        <!-- {{ serviceTypeLength > 0 ? "1 - " + serviceType?.length + " of " +
                          serviceTypeLength : "0 - 0 of 0" }} -->
                      </span></b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="table-responsive" style="padding: 0px;">
                  <b-table bordered hover :items="serviceType" :fields="columns" :current-page="currentPage"
                    :per-page="perPage" :tbody-tr-class="rowClass" thead-class="table-header-color"
                    @pagination="onPagination">
                    <template v-slot:cell(variable1)="data">
                      <!-- <span>{{ ((currentPage - 1) * perPage) + data.index + 1 }}</span> -->
                      <span>{{ ((currentPage - 1) * perPage) + data.index + 1 }}</span>
                    </template>
                    <template v-slot:cell(variable2)="data">
                      <!-- <span>{{ serviceType[((currentPage - 1) * perPage) + data.index].sg_name || '-' }}</span> -->
                      <span>{{ data.item.sg_name || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable3)="data">
                      <!-- <span>{{ serviceType[((currentPage - 1) * perPage) + data.index].name || '-' }}</span> -->
                      <span>{{ data.item.name || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable4)="data">
                      <!-- <span>{{ serviceType[((currentPage - 1) * perPage) + data.index].pay || '-' }}</span> -->
                      <span>{{ data.item.pay ? numberWithComma(data.item.pay) : '-' }}</span>
                    </template>
                    <template v-slot:cell(variable5)="data">
                      <!-- <span>{{ serviceType[((currentPage - 1) * perPage) + data.index].standart_time || '-' }}</span> -->
                      <span>{{ data.item.standart_time ? numberWithComma(data.item.standart_time) : '-' }}</span>
                    </template>
                    <template v-slot:cell(variable6)="data">
                      <!-- <b-button variant=" iq-bg-primary mr-1 mb-1" size="sm"
                        @click="handleClick('view', serviceType[((currentPage - 1) * perPage) + data.index].id)"><i
                          class="ri-eye-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-success mr-1 mb-1" size="sm"
                        @click="handleClick('edit', serviceType[((currentPage - 1) * perPage) + data.index].id)"><i
                          class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-danger mr-1 mb-1" size="sm"
                        @click="removeData(serviceType[((currentPage - 1) * perPage) + data.index].id)"><i
                          class="ri-delete-bin-line m-0"></i></b-button> -->
                      <b-button variant=" iq-bg-primary mr-1 mb-1" size="sm"
                        @click="showModal = true, checkMode = 'view', setEditData(data.item)"><i
                          class="ri-eye-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-success mx-1 mb-1" size="sm"
                        @click="showModal = true, checkMode = 'edit', setEditData(data.item)"><i
                          class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-danger ml-1 mb-1" size="sm"
                        @click="removeData(data.item.id)"><i
                          class="ri-delete-bin-line m-0"></i></b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row style="display: flex; justify-content: center;">
                <b-pagination v-model="currentPage" :total-rows="serviceType?.length" :per-page="perPage" />
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal v-model="showModal"
      :title="`${checkMode === 'add' ? 'กลุ่มบริการ (เพิ่ม)' : checkMode === 'edit' ? 'กลุ่มบริการ (แก้ไข)' : 'กลุ่มบริการ (ดู)'}`"
      cancel-title="เพิ่ม" ok-title="ยกเลิก" cancel-variant="primary" ok-variant="danger" @close.prevent="closeModal"
      :no-close-on-esc="true" :no-close-on-backdrop="true">

      <b-form-group label-for="exampleFormControlSelect1" label="หมวดหมู่">
        <template v-if="inputData.st_sg === '' || inputData.st_sg === null" v-slot:label>
          หมวดหมู่<span class="text-danger"> *</span>
        </template>
        <v-select v-model="inputData.st_sg" :disabled="checkMode !== 'add' && checkMode !== 'edit'"
          :options="serviceGroup"></v-select>
      </b-form-group>

      <b-form-group label="ชื่อประเภทการใช้งาน" label-for="exampleInputText1">
        <template v-if="inputData.st_name?.trim() === '' || inputData.st_name === null" v-slot:label>
          ชื่อประเภทการใช้งาน<span class="text-danger"> *</span>
        </template>
        <b-form-input v-if="checkMode === 'add' || checkMode === 'edit'" id="exampleInputText1" type="text" placeholder=""
          v-model="inputData.st_name" />
        <b-form-input v-else id="exampleInputText1" type="text" placeholder="" v-model="inputData.st_name" disabled />
      </b-form-group>

      <b-form-group label="ค่ามือ (บาท)" label-for="exampleInputText1">
        <template v-if="inputData.st_pay?.trim() === '' || inputData.st_pay === null" v-slot:label>
          ค่ามือ (บาท)<span class="text-danger"> *</span>
        </template>
        <b-form-input v-if="checkMode === 'add' || checkMode === 'edit'" id="exampleInputText1" type="text" placeholder=""
          v-model="inputData.st_pay" @keypress="isNumberCheck($event, false)" @input="isNumberComma($event, 'pay')" />
        <b-form-input v-else id="exampleInputText1" type="text" placeholder="" v-model="inputData.st_pay"
          @keypress="isNumberCheck($event, false)" @input="isNumberComma($event)" disabled />
      </b-form-group>

      <b-form-group label="เวลามาตรฐานให้บริการ (นาที)" label-for="exampleInputText1">
        <template v-if="inputData.st_standart_time?.trim() === '' || inputData.st_standart_time === null" v-slot:label>
          เวลามาตรฐานให้บริการ (นาที)<span class="text-danger"> *</span>
        </template>
        <b-form-input v-if="checkMode === 'add' || checkMode === 'edit'" id="exampleInputText1" type="text" placeholder=""
          v-model="inputData.st_standart_time" @keypress="isNumberCheck($event, true)"
          @input="isNumberComma($event, 'standart')" />
        <b-form-input v-else id="exampleInputText1" type="text" placeholder="" v-model="inputData.st_standart_time"
          @keypress="isNumberCheck($event, true)" @input="isNumberComma($event)" disabled />
      </b-form-group>

      <template #modal-footer>
        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
          <b-button style="border-radius: 0.5rem; padding: 0 .7rem;" variant="primary" class="btn-min-size" size="sm"
            @click="addEditData"
            :disabled="inputData.st_sg && inputData.st_name?.trim() && inputData.st_pay?.trim() && inputData.st_standart_time?.trim() ? false : true"
            :hidden="checkMode === 'view' ? true : false">
            <i class="save-data_ct" />บันทึก
          </b-button>
          <b-button variant="none" class="iq-bg-danger btn-cancel-border btn-min-size" style="border-radius: 0.5rem;"
            size="sm" @click="closeModal">
            ยกเลิก
          </b-button>
        </div>
      </template>
      <!-- <b-form-group label="ชื่อระดับการบริหาร" style="color: black;">
        <template v-if="roles.role1?.trim() === '' || roles.role1 === null" v-slot:label>
          ชื่อระดับการบริหาร<span class="text-danger"> *</span>
        </template>
        <b-form-input id="" v-model="roles.role1" type="text"></b-form-input>
      </b-form-group> -->
      <!-- <template #modal-footer>
        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
          <b-button style="border-radius: 0.5rem; padding: 0 .7rem;" variant="primary" class="btn-min-size" size="sm"
            @click="addRole" :disabled="roles.role1 ? false : true" :hidden="checkmodal === 'view' ? true : false">
            <i class="save-data_ct" />บันทึก
          </b-button>
          <b-button variant="none" class="iq-bg-danger btn-cancel-border btn-min-size" style="border-radius: 0.5rem;"
            size="sm" @click="handleClick(false)">
            ยกเลิก
          </b-button>
        </div>
      </template> -->
    </b-modal>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
// import router from '@/router'
import axios from 'axios'
import Vue from 'vue'
import { configAPI } from '../../configBase'

export default {
  name: 'ServiceGroup',
  mounted () {
    xray.index()
    this.getServiceType()
    this.getServiceGroup()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getServiceType()
      }
    },
    numberWithComma (data) {
      return data.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      // return data.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    numberWithOutComma (data) {
      return data.replace(/,/g, '')
    },
    isNumberComma (event, fromInput) {
      if (fromInput === 'pay') {
        this.inputData.st_pay = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      } else if (fromInput === 'standart') {
        this.inputData.st_standart_time = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      }
    },
    isNumberCheck (keyPressed, status) {
      let useData = keyPressed.target.value

      let keyCode = (keyPressed.keyCode ? keyPressed.keyCode : keyPressed.which)

      if (status) {
        if (keyCode === 46 || useData.indexOf('.') !== -1) {
          keyPressed.preventDefault()
        }
        if (keyCode < 48 || keyCode > 57) {
          keyPressed.preventDefault()
        }
      } else {
        if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || useData.indexOf('.') !== -1)) {
          keyPressed.preventDefault()
        }
      }
      if (useData !== null && useData.indexOf('.') > -1 && (useData.split('.')[1].length > 1)) {
        keyPressed.preventDefault()
      }

      if ((useData === null || useData === '') && keyCode === 46) {
        keyPressed.preventDefault()
      }
    },
    closeModal () {
      if (this.checkMode === 'view') {
        this.showModal = false
      } else {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะยกเลิกใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.showModal = false
          }
        })
      }
    },
    removeData (id) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะลบข้อมูลใช่หรือไม่?</span>',
        // text: 'ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let delServiceTypeAPI = {
            method: 'post',
            url: `${configAPI.MD_PORT}/api/serviceType/${id}/delete`,
            headers: {
              'Content-Type': 'application/json'
            }
          }

          axios.request(delServiceTypeAPI).then(() => {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              this.getServiceType()
            })
          }).catch(() => {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลไม่สำเร็จ</span>',
              text: ``,
              icon: 'error',
              confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
              confirmButtonColor: '#089bab'
            })
          })
        }
      })
    },
    async addEditData () {
      Vue.swal({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.checkMode === 'add' ? 'คุณมั่นใจว่าจะบันทึกข้อมูลใช่หรือไม่?' : 'คุณมั่นใจว่าจะแก้ไขข้อมูลใช่หรือไม่?'}</span>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Vue.swal({
          //   title: 'กำลังบันทึกข้อมูล',
          //   text: 'Loading',
          //   allowEscapeKey: false,
          //   allowOutsideClick: false,
          //   didOpen: () => {
          //     Vue.swal.showLoading()
          //   }
          // })

          let addEditServiceTypeAPI = {}
          if (this.checkMode === 'add') {
            addEditServiceTypeAPI = {
              method: 'post',
              url: `${configAPI.MD_PORT}/api/serviceType`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                sg_id: this.inputData.st_sg?.value || null,
                name: this.inputData.st_name?.trim() || null,
                pay: this.inputData.st_pay?.replace(/,/g, '') || null,
                standart_time: this.inputData.st_standart_time?.replace(/,/g, '') || null
              }
            }
          } else if (this.checkMode === 'edit') {
            addEditServiceTypeAPI = {
              method: 'put',
              url: `${configAPI.MD_PORT}/api/serviceType/${this.inputData.st_id}`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                sg_id: this.inputData.st_sg?.value || null,
                name: this.inputData.st_name?.trim() || null,
                pay: this.inputData.st_pay?.replace(/,/g, '') || null,
                standart_time: this.inputData.st_standart_time?.replace(/,/g, '') || null
              }
            }
          }

          await axios.request(addEditServiceTypeAPI).then(async (response) => {
            if (response.data.status) {
              Vue.swal({
                title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.checkMode === 'add' ? 'บันทึกข้อมูลสำเร็จ' : 'แก้ไขข้อมูลสำเร็จ'}</span>`,
                icon: 'success',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                this.showModal = false
                this.getServiceType()
              })
            } else {
              this.SwalError(response.data.error === 'name duplicate'
                ? 'ชื่อประเภทการใช้งานในหมวดหมู่นี้ ซ้ำกับในระบบ กรุณาลองใหม่' : response.data.error,
              this.checkMode === 'edit' ? 'แก้ไขข้อมูลไม่สำเร็จ' : 'บันทึกข้อมูลไม่สำเร็จ')
            }
          }).catch((error) => {
            this.SwalError(this.checkMode === 'edit' ? 'แก้ไขข้อมูลไม่สำเร็จ' : 'บันทึกข้อมูลไม่สำเร็จ', error.message)
          })
        }
      })
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    onPagination (page) {
      this.currentPage = page
    },
    // handleClick (e, id) {
    //   const setPage = e
    //   if (id) {
    //     router.push(`/AddServiceGroup/${setPage}/${id}`)
    //   } else {
    //     router.push(`/AddServiceGroup/${setPage}`)
    //   }
    // },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    getServiceType () {
      let search = this.searchItem.trim().length > 0 ? this.searchItem.trim() : ''
      let serviceTypeAPI = {
        method: 'get',
        url: `${configAPI.MD_PORT}/api/serviceType?search=${search}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      axios.request(serviceTypeAPI)
        .then((response) => {
          if (response.data.status) {
            this.serviceType = response.data.data?.map((item, index) => {
              item.index = index
              return item
            })
            this.serviceTypeLength = response.data.count
          }
        })
        .catch((error) => {
          document.getElementById(error)
        })
    },
    getServiceGroup () {
      let serviceGroupAPI = {
        method: 'get',
        url: `${configAPI.MD_PORT}/api/serviceGroup`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      axios.request(serviceGroupAPI)
        .then((response) => {
          this.serviceGroup = response.data.data?.map(option => ({ value: option.id, label: option.name }))
        })
        .catch((error) => {
          document.getElementById(error)
        })
    },
    setEditData (data) {
      this.inputData.st_id = data.id
      this.inputData.st_sg = {
        value: data.sg_id,
        label: data.sg_name
      }
      this.inputData.st_name = data.name
      this.inputData.st_pay = data.pay?.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      this.inputData.st_standart_time = data.standart_time?.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
    },
    resetData () {
      this.inputData.st_id = ''
      this.inputData.st_sg = null
      this.inputData.st_name = ''
      this.inputData.st_pay = ''
      this.inputData.st_standart_time = ''
    }
  },
  data () {
    return {
      searchItem: '',
      columns: [
        { label: 'ลำดับ', key: 'variable1', class: 'text-center table-size-100-300' }, /* รหัสประเภทการใช้งาน */
        { label: 'หมวดหมู่', key: 'variable2', class: 'text-center table-size-200-400 ellips' },
        { label: 'ประเภทการใช้งาน', key: 'variable3', class: 'text-center table-size-200-400 ellips' },
        { label: 'ค่ามือ (บาท)', key: 'variable4', class: 'text-center table-size-200-400' },
        { label: 'เวลามาตรฐานให้บริการ ', key: 'variable5', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'variable6', class: 'text-center table-size-200-400' }
      ],
      inputData: {
        st_id: '',
        st_sg: null,
        st_name: '',
        st_pay: '',
        st_standart_time: ''
      },
      serviceTypeLength: 0,
      serviceType: [],
      serviceGroup: [],
      currentPage: 1,
      perPage: 10,
      checkMode: '',
      showModal: false
    }
  },
  created () {}
}
</script>
