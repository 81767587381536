<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">บริหารจัดการคลินิก</span>
              </div>
              <div class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button @click="addEditPage('add')" type="submit" variant="primary" class="w-100 btn-min-size"><i
                    class="circle-plus_ct"></i>เพิ่ม</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" md="7" lg="5" class="mb-3">
                  <b-row>
                    <b-col sm="12" md class="mb-3" style="padding: 0px;">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input class="form-control" id="exampleInputEmail1" placeholder="Search" style="width: 100%;"
                              v-model="searchItem" @keydown="enterSearch">
                            <a class="search-link" v-on:click="getManageClinic"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center; padding: 0px;">
                      <span class="mx-2 font-size-14 text-primary">
                        {{ manageClinic?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > manageClinic?.length) ? manageClinic?.length : (perPage * currentPage) ) + ' of ' + countClinic : 0 }}
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="table-responsive" style="padding: 0px;">
                  <b-table bordered hover :items="manageClinic" :fields="columns" :current-page="currentPage"
                    :per-page="perPage" @pagination="onPagination" :tbody-tr-class="rowClass"
                    thead-class="table-header-color">
                    <template v-slot:cell(variable1)="data">
                      <span>{{ ((currentPage - 1) * perPage) + data.index + 1 }}</span>
                    </template>
                    <template v-slot:cell(variable2)="data">
                      <span>{{ data.item.name || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable3)="data">
                      <span>{{ data.item.tel ? formatTel(data.item.tel) : '-' }}</span>
                    </template>
                    <template v-slot:cell(variable4)="data">
                      <span>{{ data.item.contract_name || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable5)="data">
                      <span>{{ data.item.contract_end ? formatDate(data.item.contract_end) : '-' }}</span>
                    </template>
                    <template v-slot:cell(variable6)="data">
                      <span>{{ data.item.user_amount || '-' }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button variant=" iq-bg-primary mr-1 mb-1" size="sm" @click="addEditPage('view', data.item.id)"><i
                          class="ri-eye-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-success mx-1 mb-1" size="sm" @click="addEditPage('edit', data.item.id)"><i
                          class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-danger ml-1 mb-1" size="sm" @click="removeData(data.item.id)"><i
                          class="ri-delete-bin-line m-0"></i></b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row style="display: flex; justify-content: center;">
                <b-pagination v-model="currentPage" :total-rows="manageClinic?.length" :per-page="perPage" />
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import axios from 'axios'
import moment from 'moment'
import { configAPI } from '../../configBase'

export default {
  name: 'Manage',
  data () {
    return {
      columns: [
        { label: 'รหัสคลินิก', key: 'variable1', class: 'text-center table-size-100-300' },
        { label: 'ชื่อคลินิก', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'เบอร์ติดต่อ', key: 'variable3', class: 'text-center table-size-200-400' },
        { label: 'ชื่อผู้ติดต่อ', key: 'variable4', class: 'text-center table-size-200-400' },
        { label: 'วันที่หมดสัญญา', key: 'variable5', class: 'text-center table-size-200-400' },
        { label: 'จำนวน user', key: 'variable6', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'action', class: 'text-center table-size-200-400' }
      ],
      manageClinic: [],
      countClinic: 0,
      currentPage: 1,
      perPage: 10,
      searchItem: ''
    }
  },
  methods: {
    formatDate (data) {
      return moment(data).format('DD/MM/YYYY')
    },
    formatTel (data) {
      return data.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
    },
    addEditPage (page, id) {
      if (page === 'add') {
        router.push(`/AddManage`)
      } else {
        router.push(`/AddManage/${page}/${id}`)
      }
    },
    getManageClinic () {
      let search = this.searchItem.trim().length > 0 ? this.searchItem.trim() : ''
      let manageClinicAPI = {
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic?search=${search}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      axios.request(manageClinicAPI)
        .then((response) => {
          if (response.data.data.length > 0) {
            this.manageClinic = response.data.data?.map((item, index) => {
              item.index = index
              return item
            })
            this.countClinic = response.data.count
          } else {
            this.manageClinic = []
          }
        })
        .catch((error) => {
          document.getElementById(error)
        })
    },
    removeData (id) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะลบข้อมูลใช่หรือไม่?</span>',
        // text: 'ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let delManageClinicAPI = {
            method: 'post',
            url: `${configAPI.MA_PORT}/api/clinic/${id}/delete`,
            headers: {
              'Content-Type': 'application/json'
            }
          }

          axios.request(delManageClinicAPI).then(() => {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              this.getManageClinic()
            })
          }).catch(() => {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลไม่สำเร็จ</span>',
              text: ``,
              icon: 'error',
              confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
              confirmButtonColor: '#089bab'
            })
          })
        }
      })
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick (mode, dataProps) {
      this.$store.dispatch('updateDataTab2', null)
      if (mode === null) {
        router.push('/AddManage')
      } else {
        router.push(`/AddManage/${mode}/${dataProps.id}`)
      }
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getManageClinic()
      }
    }
  },
  mounted () {
    xray.index()
    this.getManageClinic()
  }
}
</script>
