<template>
    <b-container fluid>
      <b-row>
        <b-col md="12" class="table-responsive">
          <iq-card>
            <template v-slot:headerTitle>
              <div class="row align-items-center py-4 gap-response">
                <div class="ml-4">
                  <span class="mx-1" style="font-size: 25px; color: black;">ผู้ใช้งาน</span>
                </div>
                <div class="row button-response-header" style="display: flex; align-items: center;">
                    <div class="flex-response-header my-2 mx-2" style="white-space: nowrap;">
                      <div class="response-button-header">
                        <b-button @click="handleClick(null, null)" type="submit" variant="primary-custom" class="my-1 flex-fill text-center btn-add btn-min-size" style="text-align: center;"><i class="circle-plus_ct"></i>เพิ่ม</b-button>
                        <b-button @click="openModalImportFile(), userImport.fileName = null" type="submit" variant="primary-custom" class="btn-import" style="text-align: center; min-width: 130px;"><i class="in-data_ct"></i>นำข้อมูลเข้า</b-button>
                      </div>
                      <a :href="getFullImagePath(downloadFile.file_path)" :download="downloadFile.file_name" class="response-button-download">
                        <b-button type="submit" variant="primary-custom" class="my-1 flex-fill text-center response-button-download" style="text-align: center; flex-basis: 100%;"><i class="out-data_ct"></i>Download Template</b-button>
                      </a>
                    </div>
                </div>
              </div>
            </template>
            <template v-slot:body>
              <div class="mx-3">
                <b-row>
                  <b-col cols="12" md="7" lg="5">
                  <b-row>
                    <b-col sm="12" md class="mb-3">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input class="form-control" v-model="searchInput" @keydown="enterSearch" id="exampleInputEmail1" placeholder="Search" style="width: 100%;">
                            <a class="search-link" @click="getUserOfClinic"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                      <span class="mx-2 font-size-14 text-primary">
                        {{ userTableAmount.amount > 0 ? (((currentPage - 1) * 10) + (rows?.length > 0 ? 1 : 0)) + ' - ' + (((perPage * currentPage) > rows?.length) ? rows?.length : (perPage * currentPage) ) + ' of ' + userTableAmount.amount : 0 }}
                      </span></b-col>
                  </b-row>
                </b-col>
                <b-col class="mb-3 col-md-5 col-lg-7 col-12" style="text-align: end; display: flex; align-items: center; justify-content: end;">
                    <span class="text-primary" style="font-size: 14px;">จำนวนผู้ใช้ที่ใช้งานได้ : {{userTableAmount.amount + '/' + userTableAmount.total}} คน</span>
                  </b-col>
                </b-row>
                </div>
              <br>
              <b-col md="12" class="table-responsive">
                <!-- :tbody-tr-class="rowClass" -->
                <b-table bordered hover :items="rows" :fields="columns"
                :current-page="currentPage"
                :per-page="perPage"
                @pagination="onPagination"
                :tbody-tr-class="rowClass" thead-class="table-header-color"
                >
                <template v-slot:cell(variable1)="data">
                  <span v-if="!data.item.editable">{{ data.item.variable1 || '-' }}</span>
                  <input type="text" v-model="data.item.variable1" v-else class="form-control">
                </template>
                <template v-slot:cell(variable2)="data">
                  <span v-if="!data.item.editable">{{ data.item.variable2 || '-' }}</span>
                  <input type="text" v-model="data.item.variable2" v-else class="form-control">
                </template>
                <template v-slot:cell(variable3)="data">
                  <!-- <select class="form-control d-flex" id="validationDefault" style="background-color: white; border: solid 1px; color: gray; justify-content: center;" required>
                    <option selected value="">เลือกตำแหน่ง</option>
                    <option value="option1">SuperAdmin</option>
                    <option value="option2">Doctor</option>
                    <option value="option3">Nurse</option>
                  </select> -->
                  <span v-if="!data.item.editable">{{ data.item.variable3 || '-'}}</span>
                  <input type="text" v-model="data.item.variable3" v-else class="form-control">
                </template>
                <template v-slot:cell(variable4)="data">
                  <!-- <select class="form-control d-flex" id="validationDefault" style="background-color: white; border: solid 1px; color: gray; justify-content: center;" required>
                    <option selected value="">ใช้งาน</option>
                    <option value="option1">ยกเลิก</option>
                  </select> -->
                  <span v-if="!data.item.editable">{{ data.item.variable4 }}</span>
                  <input type="text" v-model="data.item.variable4" v-else class="form-control">
                </template>
                <template v-slot:cell(variable5)="data">
                  <!-- || data.item.variable3 === 'SuperAdmin' -->
                  <b-button variant="none" class="mx-2 flex-fill bg-danger text-center" :disabled="isDisable" @click="resetPassword(data.item)" style="text-align: center;">Reset</b-button>
                </template>
                <template v-slot:cell(variable6)="data">
                  <div style="display: flex; justify-content: center;" :hidden="!data.item.emp_id">
                    <b-button variant=" iq-bg-info mr-1" size="sm" @click="handleClick('view', data.item)"><i class="ri-eye-fill m-0"></i></b-button>
                    <a v-if="data.item.id === 1" :disabled="data.item.id === 1 ? true : false" size="sm" @click="this.remove(data.item)" class="p-3" style="color: white !important;"><i :hidden="data.item.id === 1 ? true : false" class="ri-delete-bin-line m-0"></i></a>
                    <b-button v-else variant=" iq-bg-success mr-1" size="sm" @click="handleClick('edit', data.item)" ><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <a v-if="data.item.id === 1" :disabled="data.item.id === 1 ? true : false" size="sm" @click="this.remove(data.item)" class="p-3" style="color: white !important;"><i :hidden="data.item.id === 1 ? true : false" class="ri-delete-bin-line m-0"></i></a>
                    <b-button v-else variant=" iq-bg-danger" :hidden="data.item.id === 1 ? true : false" :disabled="data.item.id === 1 ? true : false" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </div>
                </template>
              </b-table>
              <br>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows.length"
                :per-page="perPage"
                align="center"
              />
            </b-col>
            <b-modal v-model="showModalUpload" id="modal-import" title="Upload ผู้ใช้" ok-title="บันทึก" :ok-disabled="userImport.fileName !== null ? false : true" @ok="uploadUser"  cancel-title="ยกเลิก" no-close-on-backdrop>
              <span>User file:** Excel from template **</span>
              <b-form-group
                label-for="customFile"
              >
                <b-form-file
                  id="customFile"
                  :placeholder="userImport.fileName ? userImport.fileName : 'Choose a file'"
                  accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  @change="handleImageUpload"
                ></b-form-file>
              </b-form-group>
              <template #modal-footer>
                <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                      <b-button
                        style="border-radius: 0.5rem;"
                        class="btn-min-size"
                        variant="primary"
                        size="sm"
                        @click="uploadUser"
                        :disabled="userImport.fileName !== null ? false : true"
                      >
                      <i class="save-data_ct" />บันทึก
                      </b-button>
                      <b-button
                        variant="none"
                        class="btn-min-size iq-bg-danger btn-cancel-border"
                        style="border-radius: 0.5rem;"
                        size="sm"
                        @click="cancleModalImgUpload"
                      >
                        ยกเลิก
                    </b-button>
                  </div>
              </template>
            </b-modal>
            <b-modal v-model="showModal" title="สถานะการ Upload" size="xl" @close.prevent="closeModalShowStatus" no-close-on-backdrop>
                <b-col cols="12" class="table-responsive" >
                  <b-table bordered hover :items="rows2" :fields="columns2"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @pagination="onPagination"
                    :tbody-tr-class="rowClass" thead-class="table-header-color"
                  >
                  <template v-slot:cell(variable1)="data">
                    <span v-if="!data.item.editable">{{ data.item.variable1 }}</span>
                    <input type="text" v-model="data.item.variable1" v-else class="form-control">
                  </template>
                  <template v-slot:cell(variable2)="data">
                    <span v-if="!data.item.editable" style="white-space: nowrap;">{{ data.item.variable2 }}</span>
                    <input type="text" v-model="data.item.variable2" v-else class="form-control">
                  </template>
                  <template v-slot:cell(variable3)="data">
                    <span v-if="!data.item.editable">{{ data.item.variable3 }}</span>
                    <input type="text" v-model="data.item.variable3" v-else class="form-control">
                  </template>
                  <template v-slot:cell(variable4)="data">
                    <span v-if="!data.item.editable">{{ data.item.position }}</span>
                    <input type="text" v-model="data.item.position" v-else class="form-control">
                  </template>
                  <template v-slot:cell(variable5)="data">
                    <b-button
                      variant="none"
                      :class="'btn-min-size ' + (data.item.status ? 'iq-bg-success btn-success-border' : 'iq-bg-danger btn-cancel-border')"
                      size="sm"
                      style="border-radius: 0.5rem;"
                    >
                      {{ data.item.variable4 }}
                    </b-button>
                    <!-- <span v-if="!data.item.editable">{{ data.item.variable4 }}</span>
                    <input type="text" v-model="data.item.variable4" v-else class="form-control"> -->
                  </template>
                  <template v-slot:cell(variable6)="data">
                    <span v-if="!data.item.editable" style="white-space: nowrap;">{{ data.item.error }}</span>
                    <input type="text" v-model="data.item.error" v-else class="form-control">
                  </template>
                </b-table>
                <br>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows2.length"
                  :per-page="perPage"
                  align="center"
                />
              </b-col>
              <template #modal-footer>
            <div class="w-100">
              <p class="float-left"></p>
              <b-button
                variant="none"
                size="sm"
                class="float-right iq-bg-danger btn-cancel-border btn-min-size"
                style="border-radius: 0.5rem;"
                @click="closeModalShowStatus"
              >
                  ยกเลิก
              </b-button>
            </div>
              </template>
            </b-modal>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>

<style>
.response-button-download {
  margin-left: 0;
  margin-bottom: 0 !important;
}
.response-button-header {
 margin: auto !important;
}
.font-nowrap {
  white-space: nowrap;
}
.flex-response-header {
  display: flex;
  /* gap: .5rem; */
}
.gap-response {
  gap: 2.5rem;
}
.flex-manage {
  display: flex !important;
}
.btn-import {
    margin-right: 0 !important;
}
@media (max-width: 1001px) {
  .response-button-download {
    margin-left: .5rem;
    margin-bottom: 0 !important;
  }
}

@media (max-width: 676px) {
  .response-button-download {
    margin-left: 0;
    margin-bottom: 0 !important;
  }
  .flex-response-header {
    display: flex;
    gap: .5rem;
  }
  .btn-import {
    margin-right: 0 !important;
  }
  .gap-response {
    gap: 0;
  }
  .button-response-header {
    width: 100% !important;
    margin-left: 1.2rem !important;
    margin-top: 0 !important;
    padding-right: 2rem !important;
  }
  .flex-response-header {
    display: flex;
    gap: .5rem;
    flex-direction: column;
    width: 100%;
  }
}
/* @media (max-width: 570px) {
  .btn-import {
    margin-right: 0 !important;
  }
  .gap-response {
    gap: 0;
  }
  .button-response-header {
    width: 100% !important;
    margin-left: 1.2rem !important;
    margin-top: 0 !important;
  }
  .flex-response-header {
    display: flex;
    gap: .5rem;
    flex-direction: column;
    width: 100%;
  }
} */
@media (max-width: 420px) {
  .btn-import {
    margin-right: 0 !important;
  }
  .response-button-header {
    gap: .5rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .btn-add {
    margin: 0 !important;
  }
  .btn-import {
    margin: 0.25rem 0 !important;
    padding: 0;
  }
  .response-button-download {
    width: 100% !important;
  }
  .button-response-header {
    width: 100% !important;
  }
 /*  .margin-response {
    margin: 0 .5rem !important;
  } */
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import axios from 'axios'
import { configAPI } from '../../configBase'

export default {
  data () {
    return {
      name: 'UiDataTable',
      activeTab: 'tab1',
      cln_id: null,
      id: '',
      clinic_data: null,
      downloadFile: {
        file_name: '',
        file_path: ''
      },
      searchInput: '',
      userTableAmount: {
        total: 0,
        amount: 0
      },
      rows1: [
        {
          variable1: 'variablel-1',
          index: 1
        }
      ],
      showModalUpload: false,
      selectedOption: 'option1',
      userImport: {
        fileName: null,
        file: null
      },
      showModal: false,
      columns: [
        { label: 'Username', key: 'variable1', class: 'text-center font-nowrap table-size-100-300' },
        { label: 'ชื่อผู้ใช้', key: 'variable2', class: 'text-center table-size-100-300' },
        { label: 'ตำแหน่ง', key: 'variable3', class: 'text-center table-size-100-300' },
        { label: 'สถานะ', key: 'variable4', class: 'text-center table-size-100-300' },
        { label: 'Reset password', key: 'variable5', class: 'text-center table-size-100-300' },
        { label: 'จัดการ', key: 'variable6', class: 'text-center table-size-100-150' }
      ],
      columns2: [
        { label: 'Username', key: 'variable1', class: 'text-center table-size-100-300' },
        { label: 'ชื่อผู้ใช้', key: 'variable2', class: 'text-center table-size-100-300' },
        { label: 'อีเมล', key: 'variable3', class: 'text-center table-size-100-300' },
        { label: 'ตำแหน่ง', key: 'variable4', class: 'text-center table-size-100-300' },
        { label: 'สถานะ', key: 'variable5', class: 'text-center' },
        { label: 'หมายเหตุ', key: 'variable6', class: 'text-center table-size-auto' }
      ],
      rows2: [],
      rows: [
        /* {
          id: 1,
          index: 1,
          variable1: 'sa-somchai',
          variable2: 'employee1',
          variable3: 'SuperAdmin',
          variable4: 'ใช้งาน',
          editable: false
        },
        {
          id: 2,
          index: 2,
          variable1: '111-111-1111',
          variable2: 'employee2',
          variable3: 'Doctor',
          variable4: 'ยกเลิก',
          editable: false
        },
        {
          id: 3,
          index: 3,
          variable1: '222-222-2222',
          variable2: 'employee3',
          variable3: 'Admin',
          variable4: 'ใข้งาน',
          editable: false
        } */
      ],
      currentPage: 1,
      perPage: 10
    }
  },
  methods: {
    add () {
      let obj = this.default()
      this.rows.push(obj)
    },
    edit (item) {
      item.editable = true
    },
    submit (item) {
      item.editable = false
    },
    async downloadFileTemplate () {
      let productGroupAPI = {
        method: 'post',
        url: `${configAPI.MA_PORT}/api/user/download`
      }
      await axios.request(productGroupAPI)
        .then((response) => {
          if (response.data.status) {
            this.downloadFile = response.data.data
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    cancleModalImgUpload () {
      if (this.userImport.fileName !== null) {
        this.showModalUpload = false
        return
      }
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการยกเลิก การอัปโหลดข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.showModalUpload = false
        }
      })
    },
    async getClinicData () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status) {
            this.clinic_data = response.data.data[0]
          }
        })
    },
    async getUserOfClinic () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/user?search=${this.searchInput?.trim()}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status) {
            this.userTableAmount = {
              total: response.data.data.user_amount,
              amount: response.data.data.user_total
            }
            let arrTemp = []
            let index = 0
            for (let item of response.data.data?.results) {
              if (item.is_use) {
                arrTemp.push({
                  id: item.id,
                  index: index + 1,
                  variable1: item.username,
                  variable2: item.ut_id !== `5c66dfdb-a9bc-4867-baa7-507175d2fc01`
                    ? `${item.emp_name || ''} ${item.emp_lastname || ''}`.trim() || '-'
                    : 'Admin system',
                  variable3: item.rol_name, /* ut_name */
                  variable4: item.us_name,
                  emp_id: item.emp_id,
                  editable: false
                })
              }
              index += 1
            }
            this.rows = arrTemp
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    remove (item) {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการลบข้อมูลใช่หรือไม่?</span>',
        text: 'ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteUserById(item.id)
          Vue.swal({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
            icon: 'success',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 2000
          }).then(() => {
            let index = this.rows.indexOf(item)
            this.rows.splice(index, 1)
          })
        }
      })
    },
    async uploadUser () {
      if (!this.userImport.file) {
        return
      }
      let data = new FormData()
      data.append('file', this.userImport.file)
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/user/import`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
        .then(async (response) => {
          if (response.data.data?.length > 0) {
            this.rows2 = response.data.data?.map((el, index) => {
              return {
                index: index + 1,
                variable1: el.username || '-',
                variable2: ((el.firstname || '') + ' ' + (el.lastname || '')).trim() || '-',
                variable3: el.email || '-',
                variable4: el.status ? 'สำเร็จ' : 'ไม่สำเร็จ',
                position: el.role || '-',
                status: el.status,
                error: el.errorList || '-'
              }
            })
          }
          this.showModal = true
          this.showModalUpload = false
        })
        .catch((error) => {
          this.SwalError(error.message, 'นำเข้าข้อมูลไม่สำเร็จ')
        })
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick (mode, dataProps) {
      if (mode === null) {
        if (parseInt(this.rows.length) === parseInt(this.userTableAmount.total || 0)) {
          this.SwalError('เนื่องจากจำนวนผู้ใช้ครบตามสัญญาแล้ว', 'ไม่สามารถเพิ่มข้อมูลพนักงานได้')
          return
        }
        router.push('/AddUsers')
      } else {
        router.push(`/ManageUsers/${mode}/${dataProps.id}`)
      }
    },
    closeModalShowStatus () {
      this.showModal = false
      window.location.reload()
    },
    openModalImportFile () {
      if (parseInt(this.rows.length) === parseInt(this.userTableAmount.total || 0)) {
        this.SwalError('เนื่องจากจำนวนผู้ใช้ครบตามสัญญาแล้ว', 'ไม่สามารถเพิ่มข้อมูลพนักงานได้')
        return
      }
      this.showModalUpload = true
    },
    handleImageUpload (event) {
      const file = event.target.files[0]
      document.getElementById(file)
      this.userImport.file = file
      this.userImport.fileName = file.name
    },
    async deleteUserById (userId) {
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/user/${userId}/delete/`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
        })
        .catch((error) => {
          this.SwalError(error.message, 'ลบข้อมูลไม่สำเร็จ')
        })
    },
    resetPassword (dataProps) {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการที่จะรีเซ็ตรหัสพนักงานท่านนี้ใช่หรือไม่?</span>',
        text: 'ข้อมูลที่ถูกรีเซ็ตจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนรีเซ็ตข้อมูล',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.apiResetPassword(dataProps.id)
        }
      })
    },
    async apiResetPassword (userId) {
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/user/${userId}/resetPassword/`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">รีรหัสผ่านสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            })
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'รีรหัสผ่านไม่สำเร็จ')
        })
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    getFullImagePath (relativePath) {
      if (relativePath) {
        relativePath = relativePath.substring(1)
      }
      const baseURL = `${configAPI.MA_PORT}`
      return baseURL + relativePath
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getUserOfClinic()
      }
    }
  },
  async mounted () {
    this.cln_id = JSON.parse(sessionStorage.getItem('user')).cln_id
    await this.downloadFileTemplate()
    await this.getClinicData()
    await this.getUserOfClinic()
    xray.index()
  }
}
</script>
