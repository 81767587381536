<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="table-responsive">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4">
              <div class="col">
                <span class="px-3" style="font-size: 25px; color: black;">เพิ่มข้อมูลการใช้บริการ</span>
              </div>
              <div class="col-lg-5 col-md-3 col-sm-5 col-12 mt-3 mt-md-0">
                <div class="d-flex justify-content-end">
                  <b-button type="submit" variant="primary" class="w-100 text-center" style="white-space: nowrap;">พิมพ์ OPD</b-button>
                  <b-button type="submit" variant="none" class="w-100 bg-danger text-center ml-2" style="white-space: nowrap;">พิมพ์ใบรับรองแพทย์</b-button>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col lg="8" sm="12">
                  <b-row>
                    <b-col lg="12" sm="12">
                      <b-text style="color: black;">เลข HN</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                  </b-row>
                  <br>
                  <b-row>
                    <b-col lg="5" sm="5">
                      <b-text style="color: black;">ชื่อ</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                    <b-col lg="5" sm="5">
                      <b-text style="color: black;">นามสกุล</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                    <b-col lg="2" sm="2">
                      <b-text style="color: black;">ชื่อเล่น</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                  </b-row>
                  <br>
                  <b-row>
                    <b-col lg="4" sm="4">
                      <b-text style="color: black;">ใช้บริการครั้งแรกที่</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                    <b-col lg="4" sm="4">
                      <b-text style="color: black;">สัญชาติ</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                    <b-col lg="4" sm="4">
                      <b-text style="color: black;">เลขบัตรประชาชน</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                  </b-row>
                  <br>
                  <b-row>
                    <b-col lg="8" sm="8">
                      <b-text style="color: black;">ที่อยู่</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                    <b-col lg="4" sm="4">
                      <b-text style="color: black;">แพ้ยา / อาการแพ้</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                  </b-row>
                  <br>
                  <b-row>
                    <b-col lg="2" sm="2">
                      <b-text style="color: black;">เพศ</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                    <b-col lg="2" sm="2">
                      <b-text style="color: black;">อายุ</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                    <b-col lg="4" sm="4">
                      <b-text style="color: black;">วันเกิด</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                    <b-col lg="4" sm="4">
                      <b-text style="color: black;">เบอร์โทรศัพท์</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                  </b-row>
                  <br>
                  <b-row>
                    <b-col lg="4" sm="4">
                      <b-text style="color: black;">ประเภท</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                    <b-col lg="4" sm="4">
                      <b-text style="color: black;">กรุ๊ปเลือด</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                    <b-col lg="4" sm="4">
                      <b-text style="color: black;">มาครั้งแรก</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                  </b-row>
                  <br>
                  <b-row>
                    <b-col lg="4" sm="4">
                      <b-text style="color: black;">บัตรสมาชิก</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                    <b-col lg="4" sm="4">
                      <b-text style="color: black;">เครดิตเงินคืน</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                    <b-col lg="4" sm="4">
                      <b-text style="color: black;">สิทธิการรักษา</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                  </b-row>
                  <br>
                  <b-row>
                    <b-col lg="2" sm="2">
                      <b-text style="color: black;">น้ำหนัก(กก.)</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                    <b-col lg="2" sm="2">
                      <b-text style="color: black;">ส่วนสูง</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                    <b-col lg="4" sm="4">
                      <b-text style="color: black;">BMI</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                    <b-col lg="4" sm="4">
                      <b-text style="color: black;">อัตราการเต้นหัวใจ</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                  </b-row>
                  <br>
                  <b-row>
                    <b-col lg="4" sm="4">
                      <b-text style="color: black;">อัตราการหายใจ</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                    <b-col lg="4" sm="4">
                      <b-text style="color: black;">อุณหภูมิร่างกาย</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                    <b-col lg="4" sm="4">
                      <b-text style="color: black;">ความดันโลหิต</b-text>
                      <b-form-input type="text" placeholder="" readOnly></b-form-input>
                    </b-col>
                  </b-row>
                  <br>
                </b-col>
                <b-col lg="4" sm="12">
                  <b-form>
                    <template>
                      <div style="display: flex; justify-content: center;">
                        <img src="https://i.natgeofe.com/n/548467d8-c5f1-4551-9f58-6817a8d2c45e/NationalGeographic_2572187_square.jpg" style="height: 200px; width:200px; margin-top: 10px; align-items: center;">
                      </div>
                    </template>
                  </b-form>
                </b-col>
              </b-row>
              </div>
            <b-row>

            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'

export default {
  name: 'UiDataTable',
  data () {
    return {
      showModal: false,
      imageUrl: null,
      imageUrl2: null,
      activeTab: 'tab1',
      currentPage: 1,
      perPage: 10
    }
  },
  methods: {
    handleImageUpload (event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.imageUrl = reader.result
      }
    },
    handleImageUpload2 (event) {
      const file2 = event.target.files[0]
      const reader2 = new FileReader()
      reader2.readAsDataURL(file2)
      reader2.onload = () => {
        this.imageUrl2 = reader2.result
      }
    },
    handleClick () {
      router.push('/AddSupply')
    },
    onPagination (page) {
      this.currentPage = page
    }
  },
  mounted () {
    xray.index()
  }
}
</script>
