<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">กลุ่มค่าใช้จ่าย</span>
              </div>
              <div class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="submit" variant="primary" @click="showModal = true, checkmodal = 'add', resetValue()" class="w-100 btn-min-size"><i class="circle-plus_ct"></i>เพิ่ม</b-button>
                <template v-if="checkmodal === 'add'">
                  <b-modal v-model="showModal" title="กลุ่มค่าใช้จ่าย (เพิ่ม)" cancel-title="เพิ่ม" ok-title="ยกเลิก"
                    cancel-variant="primary" ok-variant="danger" @cancel="addSupplier" @ok="handleClick()"
                    :cancel-disabled="!supplier.var_supplier1 ? true : false" @close.prevent="checkmodal === 'view' ? showModal=false : handleClick()"
                    :no-close-on-esc="true" :no-close-on-backdrop="true">
                    <b-form-group label="ชื่อกลุ่มค่าใช้จ่าย" style="color: black;">
                      <template v-if="supplier.var_supplier1 === '' || supplier.var_supplier1 === null || !supplier.var_supplier1?.trim()" v-slot:label>
                        ชื่อกลุ่มค่าใช้จ่าย<span class="text-danger"> *</span>
                      </template>
                      <b-form-input id="" v-model="supplier.var_supplier1" type="text"></b-form-input>
                    </b-form-group>
                    <template #modal-footer>
                      <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                            <b-button
                              style="border-radius: 0.5rem;"
                              class="btn-min-size"
                              variant="primary"
                              size="sm"
                              @click="addSupplier"
                              :disabled="supplier.var_supplier1?.trim() ? false : true"
                            >
                            <i class="save-data_ct"></i>
                            บันทึก
                            </b-button>
                            <b-button
                              variant="none"
                              class="iq-bg-danger btn-cancel-border btn-min-size"
                              style="border-radius: 0.5rem;"
                              size="sm"
                              @click="handleClick()"
                            >
                            ยกเลิก
                          </b-button>
                        </div>
                    </template>
                  </b-modal>
                </template>
                <template v-else>
                  <b-modal v-model="showModal" :title="`${checkmodal === 'view' ? 'กลุ่มค่าใช้จ่าย (ดูรายละเอียด)' : 'กลุ่มค่าใช้จ่าย (แก้ไข)'}`" cancel-title="แก้ไข" ok-title="ยกเลิก"
                    cancel-variant="primary" ok-variant="danger" @cancel="editSupplier" @ok="checkmodal === 'view' ? showModal=false : handleClick()"
                    :cancel-disabled="!supplier.var_supplier1 ? true : false" @close.prevent="checkmodal === 'view' ? showModal=false : handleClick()"
                    :no-close-on-esc="true" :no-close-on-backdrop="true">
                    <b-form-group label="ชื่อกลุ่มค่าใช้จ่าย" style="color: black;">
                      <template v-if="supplier.var_supplier1 === '' || supplier.var_supplier1 === null || !supplier.var_supplier1?.trim()" v-slot:label>
                        ชื่อกลุ่มค่าใช้จ่าย<span class="text-danger"> *</span>
                      </template>
                      <b-form-input :disabled="checkmodal === 'view' ? true : false" id="" v-model="supplier.var_supplier1" type="text"></b-form-input>
                    </b-form-group>
                    <template #modal-footer>
                      <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                            <b-button
                              style="border-radius: 0.5rem;"
                              class="btn-min-size"
                              variant="primary"
                              size="sm"
                              @click="editSupplier(supplier.id)"
                              :disabled="supplier.var_supplier1?.trim() ? false : true"
                              :hidden="checkmodal === 'view' ? true : false"
                            >
                              <i class="save-data_ct"/>บันทึก
                            </b-button>
                            <b-button
                              variant="none"
                              class="iq-bg-danger btn-cancel-border btn-min-size"
                              style="border-radius: 0.5rem;"
                              size="sm"
                              @click="checkmodal === 'view' ? showModal=false : handleClick()"
                            >
                              ยกเลิก
                          </b-button>
                        </div>
                    </template>
                  </b-modal>
                </template>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" md="7" lg="5" class="mb-3">
                  <b-row>
                    <b-col sm="12" md class="mb-3">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input class="form-control" id="exampleInputEmail1" placeholder="Search" style="width: 100%;" v-model="searchTerm"  @keydown="enterSearch">
                            <a class="search-link" v-on:click="getCostGroup"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                      <span class="mx-2 font-size-14 text-primary unselectable">
                        {{ costgroup.data?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > costgroup.data?.length) ? costgroup.data?.length : (perPage * currentPage) ) + ' of ' + costgroupLength : '0 of ' + costgroupLength }} รายการ
                        <!-- {{ costgroup.data?.length > 0 ? "1 -" + costgroup.data?.length + " of " + costgroup.data?.length : "0 - 0 of 0" }} -->
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <b-col md="12" class="table-responsive">
              <b-table bordered hover :items="costgroup.data" :fields="columns"
                :current-page="currentPage"
                :per-page="perPage"
                @pagination="onPagination"
                :tbody-tr-class="rowClass" thead-class="table-header-color"
                >
                <template v-slot:cell(variable1)="data">
                  <span v-if="!data.item.editable">{{ ((currentPage - 1) * 10) + data.index + 1 }}</span>
                </template>
                <template v-slot:cell(variable2)="data">
                  <span v-if="!data.item.editable">{{ costgroup.data[((currentPage - 1) * 10) + data.index].name }}</span>
                </template>
                <template v-slot:cell(variable3)="data">
                  <div style="display: flex; justify-content: center;">
                    <b-button variant=" iq-bg-info mr-1" size="sm" @click="showModal = true, checkmodal = 'view', setEditSupplier(data.item, ((currentPage - 1) * 10) + data.index)"><i class="ri-eye-fill m-0"></i></b-button>
                    <a v-if="((currentPage - 1) * 10) + data.index + 1 <= 5 " :disabled="((currentPage - 1) * 10) + data.index + 1 <= 5 ? true : false" size="sm" @click="showModal = true, checkmodal = 'edit', setEditSupplier(data.item, ((currentPage - 1) * 10) + data.index)" class="p-3" style="color: white !important;"><i :hidden="((currentPage - 1) * 10) + data.index + 1 <= 5 ? true : false" class="ri-delete-bin-line m-0"></i></a>
                    <b-button v-else variant=" iq-bg-success mr-1" size="sm" @click="showModal = true, checkmodal = 'edit', setEditSupplier(data.item, ((currentPage - 1) * 10) + data.index)" ><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <a v-if="((currentPage - 1) * 10) + data.index + 1 <= 5 " :disabled="((currentPage - 1) * 10) + data.index + 1 <= 5 ? true : false" size="sm" @click="this.remove(costgroup.data[((currentPage - 1) * 10) + data.index].id)" class="p-3" style="color: white !important;"><i :hidden="((currentPage - 1) * 10) + data.index + 1 <= 5 ? true : false" class="ri-delete-bin-line m-0"></i></a>
                    <b-button v-else variant=" iq-bg-danger" :hidden="((currentPage - 1) * 10) + data.index + 1 <= 5 ? true : false" :disabled="((currentPage - 1) * 10) + data.index + 1 <= 5 ? true : false" size="sm" @click="remove(costgroup.data[((currentPage - 1) * 10) + data.index].id)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </div>
                </template>
              </b-table>
              <br>
              <b-pagination v-model="currentPage" :total-rows="costgroup.data?.length" :per-page="perPage" align="center" />
            </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.width-label-text{
  min-width: 300px !important;
}
.num-label{
  max-width: 150px !important;
  min-width: 150px !important;
}
.width-label{
  width: 300px !important;
}
</style>

<script>
import { xray } from '../../config/pluginInit'
// import router from '@/router'
import axios from 'axios'
import Vue from 'vue'
import { configAPI } from '../../configBase'

export default {
  name: 'UiDataTable',
  async mounted () {
    xray.index()
    await this.getCostGroup()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getCostGroup()
      }
    },
    async getCostGroup () {
      let search = this.searchTerm.trim().length > 0 ? this.searchTerm.trim() : ''
      let costGroupAPI = {
        method: 'get',
        url: `${configAPI.MD_PORT}/api/costGroup?search=` + search,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(costGroupAPI)
        .then((response) => {
          this.costgroup = response.data
          this.costgroupLength = response.data.count
        })
    },
    convertToJson () {
      let dataList = {}
      dataList = {
        name: this.supplier.var_supplier1?.trim(),
        is_use: true
      }
      return dataList
    },
    add () {
      let obj = this.default()
      this.rows.push(obj)
    },
    default () {
      return {
        id: this.rows.length,
        variable1: '',
        variable2: '',
        variable3: '',
        variable4: '',
        variable5: '2011/04/25',
        variable6: '$0',
        editable: false
      }
    },
    resetValue () {
      this.supplier.var_supplier1 = null
      this.supplier.var_supplier2 = null
    },
    edit (item) {
      item.editable = true
    },
    submit (item) {
      item.editable = false
    },
    remove (id) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะลบข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let DelcostGroupAPI = {
            method: 'post',
            url: `${configAPI.MD_PORT}/api/costGroup/${id}/delete`,
            headers: {
              'Content-Type': 'application/json'
            }
          }
          axios.request(DelcostGroupAPI).then(() => {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
              text: '',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000,
              // confirmButtonText: '<b-button style="font-size: 14px; text-align: center">OK</b-button>', // Adjust the confirm button font size
              customClass: {
                confirmButton: 'custom-confirm-button-class'
              },
              confirmButtonColor: '#099FB0'
            }).then(() => {
              this.showModal = false
              this.getCostGroup()
            })
          }).catch(() => {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลไม่สำเร็จ</span>',
              text: '',
              icon: 'error',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000,
              // confirmButtonText: '<b-button style="font-size: 14px; text-align: center">OK</b-button>', // Adjust the confirm button font size
              customClass: {
                confirmButton: 'custom-confirm-button-class'
              },
              confirmButtonColor: '#099FB0'
            }).then(() => {
              this.showModal = false
              this.getCostGroup()
            })
          })
        }
      })
      // let index = this.rows.indexOf(item)
      // this.rows.splice(index, 1)
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะยกเลิกใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.showModal = false
        }
      })
    },
    addSupplier () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะบันทึกข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let AddcostGroupAPI = {
            method: 'post',
            url: `${configAPI.MD_PORT}/api/costGroup`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: this.convertToJson()
          }
          await axios.request(AddcostGroupAPI).then((response) => {
            if (response.data.status) {
              this.showModal = false
              this.getCostGroup()
              Vue.swal({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
                text: '',
                icon: 'success',
                timer: 2000,
                allowEscapeKey: false,
                allowOutsideClick: false,
                // confirmButtonText: '<b-button style="font-size: 14px; text-align: center">OK</b-button>', // Adjust the confirm button font size
                customClass: {
                  confirmButton: 'custom-confirm-button-class'
                },
                confirmButtonColor: '#099FB0'
              })
            } else {
              this.SwalError(response.data.error === 'name duplicate' ? 'กลุ่มค่าใช้จ่ายนี้ซ้ำกับในระบบ กรุณาลองใหม่' : response.data.error, 'บันทึกข้อมูลไม่สำเร็จ')
            }
          }).catch((error) => {
            this.SwalError(error.message, 'บันทึกข้อมูลไม่สำเร็จ')
            /* this.showModal = false
            this.getCostGroup() */
          })
        }
      })
    },
    setEditSupplier (data, index) {
      this.supplier.id = data.id
      this.supplier.index = data.index
      this.supplier.var_supplier1 = data.name?.trim()
    },
    resetSupplier () {
      this.supplier.id = null
      this.supplier.index = null
      this.supplier.var_supplier1 = null
      this.showModal = true
      // this.indexTableEdit = index
    },
    editSupplier (id) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะแก้ไขข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let EditcostGroupAPI = {
            method: 'put',
            url: `${configAPI.MD_PORT}/api/costGroup/` + id,
            headers: {
              'Content-Type': 'application/json'
            },
            data: this.convertToJson()
          }
          await axios.request(EditcostGroupAPI).then(async (response) => {
            if (response.data.status) {
              this.showModal = false
              this.getCostGroup()
              Vue.swal({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">แก้ไขข้อมูลสำเร็จ</span>',
                text: '',
                icon: 'success',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000,
                // confirmButtonText: '<b-button style="font-size: 14px; text-align: center">OK</b-button>', // Adjust the confirm button font size
                customClass: {
                  confirmButton: 'custom-confirm-button-class'
                },
                confirmButtonColor: '#099FB0'
              })
            } else {
              this.SwalError(response.data.error === 'name duplicate' ? 'กลุ่มค่าใช้จ่ายนี้ซ้ำกับในระบบ กรุณาลองใหม่' : response.data.error, 'แก้ไขข้อมูลไม่สำเร็จ')
            }
          }).catch((error) => {
            this.SwalError(error.message, 'แก้ไขข้อมูลไม่สำเร็จ')
          })
        }
      })
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return ''
      const index = this.costgroup.data.indexOf(item) + 1
      if (index % 2 === 1) return 'table-body-color'
    }
  },
  data () {
    return {
      searchTerm: '',
      costgroup: [],
      costgroupLength: 0,
      showModal: false,
      columns: [
        { label: 'ลำดับ', key: 'variable1', class: 'text-center table-size-100-300' }, /* รหัสกลุ่มค่าใช้จ่าย */
        { label: 'กลุ่มค่าใช้จ่าย', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'variable3', class: 'text-center table-size-200-400' }
      ],
      // rows: [
      //   {
      //     id: 1,
      //     index: 1,
      //     variable1: '',
      //     variable2: 'เงินเดือน',
      //     variable3: '',
      //     editable: false
      //   },
      //   {
      //     id: 2,
      //     index: 2,
      //     variable1: '',
      //     variable2: 'นำส่งประกันสังคม',
      //     variable3: '',
      //     editable: false
      //   },
      //   {
      //     id: 3,
      //     index: 3,
      //     variable1: '',
      //     variable2: 'VC',
      //     variable3: '',
      //     editable: false
      //   },
      //   {
      //     id: 4,
      //     index: 4,
      //     variable1: '',
      //     variable2: 'ค่ายา',
      //     variable3: '',
      //     editable: false
      //   },
      //   {
      //     id: 5,
      //     index: 5,
      //     variable1: '',
      //     variable2: 'เงิน OT',
      //     variable3: '',
      //     editable: false
      //   },
      //   {
      //     id: 6,
      //     index: 6,
      //     variable1: '',
      //     variable2: 'ค่ายา',
      //     variable3: '',
      //     editable: false
      //   },

      //   {
      //     id: 7,
      //     index: 7,
      //     variable1: '',
      //     variable2: 'อุปกรณ์ทางการแพทย์',
      //     variable3: '',
      //     editable: false
      //   },
      //   {
      //     id: 8,
      //     index: 8,
      //     variable1: '',
      //     variable2: 'งบการตลาด',
      //     variable3: '',
      //     editable: false
      //   },
      //   {
      //     id: 9,
      //     index: 9,
      //     variable1: '',
      //     variable2: 'งบการตลาด',
      //     variable3: '',
      //     editable: false
      //   },
      //   {
      //     id: 10,
      //     index: 10,
      //     variable1: '',
      //     variable2: 'งบการตลาด',
      //     variable3: '',
      //     editable: false
      //   }
      // ],
      material: {
        var_material1: null,
        var_material2: null,
        var_material3: null,
        var_material4: null,
        var_material5: null,
        var_material6: null,
        var_material7: null,
        var_material8: null,
        var_material9: null,
        imageUrl: null,
        imageUrl2: null
      },
      supplier: {
        var_supplier1: null,
        var_supplier2: null,
        var_supplier3: null,
        var_supplier4: null,
        var_supplier5: null,
        var_supplier6: null
      },
      tmpsupplier: {
        var_supplier1: null,
        var_supplier2: '',
        var_supplier3: '',
        var_supplier4: '',
        var_supplier5: '',
        var_supplier6: ''
      },
      currentPage: 1,
      perPage: 10
    }
  }
}
</script>
