<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 gap-response">

              <div class="ml-4">
                <span style="font-size: 25px; color: black;">จัดการคลังสินค้าทั่วไป</span>
              </div>

              <div class="row button-response-header" style="display: flex; align-items: center;">
                <div class="flex-response-header my-2 mx-2" style="white-space: nowrap;">

                  <div>
                    <b-button type="button" variant="primary" class="my-1 mx-1 flex-fill text-center btn-add btn-min-size"
                      style="text-align: center;" @click="saveInOut"><i class="save-data_ct" />บันทึก</b-button>
                  </div>

                  <div>
                    <b-button type="button" variant="danger" class="my-1 mx-1 flex-fill text-center btn-add btn-min-size"
                      style="text-align: center;" @click="previousPage">ยกเลิก</b-button>
                  </div>
                </div>
              </div>

            </div>
          </template>

          <template v-slot:body>
            <div class="pl-2 mx-3">
              <b-row>
                <b-col cols="12" md="auto" class="pl-1 mb-2">
                  <span class="unselectable" style="font-size: 20px; color: black;">จำนวน :</span>
                </b-col>

                <b-col cols="12" md="auto" class="pl-1 mb-2">
                  <span class="unselectable" style="font-size: 20px; color: black;">ในคลัง {{ formatNumber(pgrData.wh_amount || 0) }} รายการ,</span>
                </b-col>

                <!-- <b-col cols="12" md="auto" class="pl-1 mb-2">
                  <span class="unselectable" style="font-size: 20px; color: black;">ใบขอซื้อ {{ formatNumber(pgrData.po_amount || 0) }} รายการ,</span>
                </b-col> -->

                <!-- <b-col cols="12" md="auto" class="pl-1 mb-2">
                  <span class="unselectable" style="font-size: 20px; color: black;">จำนวนจริง {{ formatNumber(pgrData.all_amount || 0) }} รายการ</span>
                </b-col> -->
              </b-row>
            </div>

            <hr style="height: 1px; color: #CCC;" />

            <div class="pl-2 mx-3">
              <b-row>
                <b-col cols="12" md="6" class="mb-3" style="text-align: center;">
                  <span class="unselectable" style="font-size: 23px; color: black;">รหัสสินค้า : {{ pgrData.pgr_no || '-' }}</span>
                </b-col>

                <b-col cols="12" md="6" class="mb-3" style="text-align: center;">
                  <span class="unselectable" style="font-size: 23px; color: black;">ชื่อสินค้า : {{ pgrData.pgr_name || '-' }}</span>
                </b-col>
              </b-row>
            </div>

            <div class="pl-2 mx-3">
              <b-row>
                <b-col cols="6" class="mb-3" style="display: flex; align-items: center; justify-content: center;">
                  <b-form-radio :value="true" v-model="modeInOut" class="radio-disable" @input="checkFIFO = true, rows_select = []"
                    name="redio-button-in"></b-form-radio>
                  <span class="unselectable" style="font-size: 16px; color: black;">ขาเข้า</span>
                </b-col>

                <b-col cols="6" class="mb-3" style="display: flex; align-items: center; justify-content: center;">
                  <b-form-radio :value="false" v-model="modeInOut" class="radio-disable"
                    name="redio-button-out"></b-form-radio>
                  <span class="unselectable" style="font-size: 16px; color: black;">ขาออก</span>
                </b-col>
              </b-row>
            </div>

            <div v-if="!modeInOut" class="pl-2 mx-3">
              <b-row>
                <b-col cols="12" class="mb-3" style="display: flex; justify-content: center;">
                  <span class="unselectable" style="font-size: 16px; color: black; margin: 0 40px 0 0;">Manual Out</span>

                  <b-form-checkbox name="check-button" switch inline style="top: 1px; padding: 0; margin: 0 3px 0 10px;"
                    v-model="checkFIFO" @input="rows_select = []" />

                  <span class="unselectable" style="font-size: 16px; color: black;">First In First Out</span>
                </b-col>
              </b-row>
            </div>

            <hr style="height: 1px; color: #CCC;" />

            <b-row>
              <b-col cols="12">
                <div class="ml-3">
                  <b class="mr-5 unselectable" style="white-space: nowrap; cursor: pointer;"
                    @click="activeTab = 'InOut', currentPage_history = 1"
                    :style="{ color: activeTab === 'InOut' ? '#024187' : 'black' }">Lot ในสต็อก</b>

                  <b class="mr-5 unselectable" style="white-space: nowrap; cursor: pointer;"
                    @click="activeTab = 'History', currentPage_default = 1"
                    :style="{ color: activeTab === 'History' ? '#024187' : 'black' }">ประวัติ Lot</b>
                </div>
              </b-col>
            </b-row>

            <hr style="height: 1px; color: #CCC;" />

            <b-col cols="12">
              <div class="table-responsive"
                :style="{ 'display': activeTab === 'InOut' && checkFIFO === true ? 'block' : 'none' }">
                <b-table bordered hover :items="rows_default" :fields="columns_default"
                  :current-page="currentPage_default" :per-page="perPage" @pagination="onPaginationDefault"
                  :tbody-tr-class="rowClass" thead-class="table-header-color">

                  <template v-slot:cell(lot_no)="data">
                    <span>{{ data.item.lpg_lot_no }}</span>
                  </template>

                  <template v-slot:cell(amount)="data">
                    <span>{{ formatNumber(data.item.sum_in_out || 0) }}</span>
                  </template>

                  <template v-slot:cell(exp_date)="data">
                    <span>{{ data.item.lpg_exp_date ? formatDate(data.item.lpg_exp_date) : '-' }}</span>
                  </template>

                  <template v-slot:cell(remark)="data">
                    <span>{{ data.item.lpg_remark || '-' }}</span>
                  </template>

                </b-table>
              </div>

              <div class="table-responsive"
                :style="{ 'display': activeTab === 'InOut' && checkFIFO === false ? 'block' : 'none' }">
                <b-table bordered hover :items="rows_default" :fields="columns_manual" :current-page="currentPage_default"
                  :per-page="perPage" @pagination="onPaginationDefault" :tbody-tr-class="rowClass"
                  thead-class="table-header-color">

                  <template v-slot:cell(select)="data">
                    <b-form-checkbox :key="data.item.lpg_id" :value="data.item" v-model="rows_select"
                      type="checkbox"></b-form-checkbox>
                  </template>

                  <template v-slot:cell(lot_no)="data">
                    <span>{{ data.item.lpg_lot_no }}</span>
                  </template>

                  <template v-slot:cell(amount)="data">
                    <span>{{ formatNumber(data.item.sum_in_out || 0) }}</span>
                  </template>

                  <template v-slot:cell(exp_date)="data">
                    <span>{{ data.item.lpg_exp_date ? formatDate(data.item.lpg_exp_date) : '-' }}</span>
                  </template>

                  <template v-slot:cell(remark)="data">
                    <span>{{ data.item.lpg_remark || '-' }}</span>
                  </template>

                </b-table>
              </div>

              <div class="table-responsive" :style="{ 'display': activeTab === 'History' ? 'block' : 'none' }">
                <b-table bordered hover :items="rows_history" :fields="columns_history"
                  :current-page="currentPage_history" :per-page="perPage" @pagination="onPaginationHistory"
                  :tbody-tr-class="rowClass" thead-class="table-header-color">

                  <template v-slot:cell(no)="data">
                    <span>{{ ((currentPage_history - 1) * perPage) + data.index + 1 }}</span>
                  </template>

                  <template v-slot:cell(lot_no)="data">
                    <span>{{ data.item.lpg_lot_no }}</span>
                  </template>

                  <template v-slot:cell(status)="data">
                    <span>{{ data.item.lpg_edit_type ? 'ขาเข้า' : 'ขาออก' }}</span>
                  </template>

                  <template v-slot:cell(amount)="data">
                    <span>{{ formatNumber(data.item.lpg_amount || 0) }}</span>
                  </template>

                  <template v-slot:cell(exp_date)="data">
                    <span>{{ data.item.lpg_exp_date ? formatDate(data.item.lpg_exp_date) : '-' }}</span>
                  </template>

                  <template v-slot:cell(remark)="data">
                    <span>{{ data.item.lpg_remark || '-' }}</span>
                  </template>

                </b-table>
              </div>
            </b-col>

            <b-pagination v-if="activeTab === 'InOut'" v-model="currentPage_default" :total-rows="rows_default?.length"
              :per-page="perPage" align="center" />
            <b-pagination v-else-if="activeTab === 'History'" v-model="currentPage_history"
              :total-rows="rows_history?.length" :per-page="perPage" align="center" />

            <hr style="height: 1px; color: #CCC;" />

            <b-row>
              <b-col v-if="modeInOut" cols="12" md="6" lg="4">
                <b-form-group label="หมายเลข Lot" label-for="lot_no">

                  <b-form-input placeholder="Auto Gen Lot No." id="lot_no" :readonly="true" v-model="pgrData.lot_no"></b-form-input>

                </b-form-group>
              </b-col>

              <!-- <b-col v-if="modeInOut" cols="12" md="6" lg="4">
                <b-form-group label="หมายเลขใบขอซื้อ" label-for="po_no">

                  <v-select append-to-body :calculate-position="withPopper" :options="purchaseOrderDDdata"
                    v-model="po_select"></v-select>

                </b-form-group>
              </b-col> -->

              <b-col v-if="modeInOut" cols="12" md="6" lg="4">
                <b-form-group label="วันหมดอายุ" label-for="exp_date">

                  <div style="position: relative;">
                    <date-picker id="datepicker-custom-1" :config="optionsDatepickerDefault" v-model="exp_date" />
                    <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(1)"
                      style="cursor: pointer;" />
                  </div>

                </b-form-group>
              </b-col>

              <!-- <b-col cols="12" md="6" lg="4">
                <b-form-group label="ราคา" label-for="price">

                  <template v-slot:label>
                    ราคา<span style="color: red;"> *</span>
                  </template>
                  <CurrencyInputCom :options="optionCurrencyDot" v-model="price" />

                </b-form-group>
              </b-col> -->

              <b-col cols="12" md="6" lg="4">
                <b-form-group label="จำนวน" label-for="amount">

                  <template v-slot:label>
                    จำนวน<span style="color: red;"> *</span>
                  </template>
                  <CurrencyInputCom :options="optionCurrency" v-model="amount" />

                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" lg="4">
                <b-form-group label="หมายเหตุ" label-for="remark">

                  <b-form-input id="remark" v-model="remark"></b-form-input>

                </b-form-group>
              </b-col>
            </b-row>

          </template>
        </iq-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<style scoped>
/* .v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement='top'] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
} */
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import Axios from 'axios'
import moment from 'moment'
import { configAPI } from '../../configBase'
import CurrencyInputCom from './CurrencyInputCom.vue'
// import { createPopper } from '@popperjs/core'

const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}

export default {
  name: 'GeneralWarehouseMgt',

  components: {
    CurrencyInputCom
  },

  data () {
    return {
      clnId: null,
      userId: null,
      pathId: null,
      optionCurrencyDot: {
        currency: 'EUR',
        currencyDisplay: 'hidden',
        valueRange: {
          min: 0,
          max: 1e+53
        },
        precision: 2,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: true,
        accountingSign: false
      },
      optionCurrency: {
        currency: 'EUR',
        currencyDisplay: 'hidden',
        valueRange: {
          min: 0,
          max: 1e+53
        },
        precision: 0,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: true,
        accountingSign: false
      },
      optionsDatepickerDefault: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        minDate: new Date(),
        maxDate: false,
        icons: iconDate,
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'auto'
        }
      },
      currentPage_default: 1,
      currentPage_history: 1,
      perPage: 10,
      rows_default: [],
      rows_history: [],
      columns_default: [
        { label: 'หมายเลข Lot', key: 'lot_no', class: 'text-center table-size-200-400' },
        { label: 'จำนวน', key: 'amount', class: 'text-center table-size-200-400' },
        { label: 'วันหมดอายุ', key: 'exp_date', class: 'text-center table-size-200-400' },
        { label: 'หมายเหตุ', key: 'remark', class: 'text-center table-size-200-400' }
      ],
      columns_manual: [
        { label: 'เลือก', key: 'select', class: 'text-center table-size-100-300' },
        { label: 'หมายเลข Lot', key: 'lot_no', class: 'text-center table-size-200-400' },
        { label: 'จำนวน', key: 'amount', class: 'text-center table-size-200-400' },
        { label: 'วันหมดอายุ', key: 'exp_date', class: 'text-center table-size-200-400' },
        { label: 'หมายเหตุ', key: 'remark', class: 'text-center table-size-200-400' }
      ],
      columns_history: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        { label: 'หมายเลข Lot', key: 'lot_no', class: 'text-center table-size-200-400' },
        { label: 'สถานะ', key: 'status', class: 'text-center table-size-200-400' },
        { label: 'จำนวน', key: 'amount', class: 'text-center table-size-200-400' },
        { label: 'วันหมดอายุ', key: 'exp_date', class: 'text-center table-size-200-400' },
        { label: 'หมายเหตุ', key: 'remark', class: 'text-center table-size-200-400' }
      ],
      modeInOut: true,
      checkFIFO: true,
      activeTab: 'InOut',
      placement: 'top',
      pgrData: {
        pgr_no: null,
        pgr_name: null,
        wh_amount: 0,
        // po_amount: 0,
        // all_amount: 0,
        lot_no: null
      },
      // purchaseOrderDDdata: [],
      rows_select: [],
      // po_select: null,
      exp_date: null,
      // price: null,
      amount: null,
      remark: null
    }
  },

  computed: {
  },

  watch: {
  },

  async mounted () {
    this.clnId = JSON.parse(sessionStorage.getItem('user')).cln_id || null
    this.userId = JSON.parse(sessionStorage.getItem('user')).usr_id || null
    this.pathId = this.$route.params.id
    // await this.purchaseOrderDD()
    await this.getProductGeneralDataForLot()
    await this.getProductGeneralDataLog()
    await this.getProductGeneralDataLogHistory()
    xray.index()
  },

  methods: {
    // withPopper (dropdownList, component, { width }) {
    //   dropdownList.style.width = width

    //   const popper = createPopper(component.$refs.toggle, dropdownList, {
    //     placement: this.placement,
    //     modifiers: [
    //       {
    //         name: 'offset',
    //         options: {
    //           offset: [0, -1]
    //         }
    //       },
    //       {
    //         name: 'toggleClass',
    //         enabled: true,
    //         phase: 'write',
    //         fn ({ state }) {
    //           component.$el.classList.toggle(
    //             'drop-up',
    //             state.placement === 'top'
    //           )
    //         }
    //       }
    //     ]
    //   })

    //   return () => popper.destroy()
    // },

    formatDate (date) {
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      } else {
        return '-'
      }
    },

    formatNumber (number) {
      if (number) {
        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } else {
        return '0'
      }
    },

    formatTel (tel) {
      if (tel) {
        let formatTel = tel.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        return formatTel
      } else {
        return ''
      }
    },

    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.no % 2 === 1) return 'table-body-color'
    },

    onPaginationDefault (page) {
      this.currentPage_default = page
    },

    onPaginationHistory (page) {
      this.currentPage_history = page
    },

    onClickDatePicker (number) {
      let datePicker = null
      datePicker = document.getElementById(`datepicker-custom-${number}`)
      datePicker.focus()
    },

    previousPage () {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          router.push('/generalWarehouse')
        }
      })
    },

    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },

    // async purchaseOrderDD () {
    //   await Axios.request({
    //     method: 'get',
    //     url: `${configAPI.SALE_PORT}/api/purchaseOrder/clinic/${this.clnId}/getPurchaseOrderAll`,
    //     headers: {
    //       'Content-Type': 'application/json'
    //     }
    //   })
    //     .then((response) => {
    //       this.purchaseOrderDDdata = response.data.data?.map(option => ({ code: option.po_id, label: option.po_no }))
    //     })
    //     .catch((error) => {
    //       this.SwalError(error.message, 'ดึงข้อมูลไม่สำเร็จ')
    //     })
    // },

    async getProductGeneralDataForLot () {
      await Axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/warehouse/clinic/${this.clnId}/getProductGeneralDataForLot/${this.pathId}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          this.pgrData.pgr_no = response.data.data[0]?.pgr_no
          this.pgrData.pgr_name = response.data.data[0]?.pgr_name
          this.pgrData.wh_amount = response.data.data[0]?.sum_in_out
          // this.pgrData = response.data.data[0]?.sum_po
          // this.pgrData = response.data.data[0]?.sum_all
          this.pgrData.lot_no = response.data.data[0]?.lpg_lot_no
        })
        .catch((error) => {
          this.SwalError(error.message, 'ดึงข้อมูลไม่สำเร็จ')
        })
    },

    async getProductGeneralDataLog () {
      await Axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/warehouse/clinic/${this.clnId}/getProductGeneralDataLog/${this.pathId}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          this.rows_default = response.data.data
        })
        .catch((error) => {
          this.SwalError(error.message, 'ดึงข้อมูลไม่สำเร็จ')
        })
    },

    async getProductGeneralDataLogHistory () {
      await Axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/warehouse/clinic/${this.clnId}/getProductGeneralDataLogHistory/${this.pathId}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          this.rows_history = response.data.data
        })
        .catch((error) => {
          this.SwalError(error.message, 'ดึงข้อมูลไม่สำเร็จ')
        })
    },

    async checkSave () {
      let errList = []

      if (this.amount === null || this.amount?.toString().trim() === '' || this.amount === 0) {
        errList.push('กรุณากรอกจำนวน')
      }

      if (this.modeInOut === false) {
        if (this.checkFIFO) {
          if (this.amount > this.pgrData.wh_amount) {
            errList.push('จำนวนที่กรอก มีมากกว่าจำนวนที่อยู๋ในคลัง')
          }
        } else {
          if (this.rows_select.length === 0) {
            errList.push('กรุณาเลือก Lot อย่างน้อย 1 รายการ')
          } else {
            const sumAmount = this.rows_select.reduce((accumulator, currentValue) => accumulator + Number(currentValue.sum_in_out || 0), 0)

            if (this.amount > sumAmount) {
              errList.push('จำนวนที่กรอก มีมากกว่าจำนวนที่เลือก Lot')
            }
          }
        }
      }

      return errList
    },

    async saveInOut () {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะบันทึกใช่หรือไม่</span>`,
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        confirmButtonColor: '#0085FF',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let errorList = await this.checkSave()

          if (errorList.length > 0) {
            let message = ''

            errorList.forEach((item) => {
              message += `${item} <br>`
            })

            this.SwalError(message, 'กรุณากรอกข้อมูลให้ครบถ้วน')
          } else {
            const dataForSave = {
              user_id: this.userId,
              modeInOut: this.modeInOut,
              checkFIFO: this.checkFIFO,
              rows_select: this.rows_select.sort((a, b) => a.lpg_lot_no.localeCompare(b.lpg_lot_no)).map((item) => item.lpg_id),
              // po_select: this.po_select || {},
              exp_date: this.exp_date ? new Date(moment(this.exp_date, 'DD/MM/YYYY')) : null,
              amount: this.amount || null,
              remark: this.remark || null
            }

            await Axios.request({
              method: 'POST',
              url: `${configAPI.MA_PORT}/api/warehouse/clinic/${this.clnId}/saveLogProductGeneral/${this.pathId}`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: dataForSave
            }).then((res) => {
              Vue.swal.fire({
                title: `<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกสำเร็จ</span>`,
                icon: 'success',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 1500
              }).then(() => {
                window.location.reload()
              })
            }).catch((err) => {
              this.SwalError(err, 'Error')
            })
          }
        }
      })
    }
  }
}
</script>
