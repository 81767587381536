<template>
  <input
    ref="inputRef"
    class="form-control"
    v-model="formattedValue"
    outlined
  />
  <!-- :error-message="errorMessage"
    :error="!!errorMessage" -->
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'
import { watch } from 'vue'

export default {
  name: 'QCurrencyInput',
  props: {
    modelValue: Number,
    options: Object,
    isLimit: Boolean,
    valueLimit: Number,
    isLess: Boolean,
    valueLess: Number
  },
  setup (props) {
    const {
      inputRef,
      formattedValue,
      setValue
    } = useCurrencyInput(props.options)

    /* const errorMessage = computed(() =>
      numberValue.value <= 100 ? 'Value must be greater than 100' : undefined
    ) */
    watch(
      () => formattedValue.value,
      (value) => {
        // console.log('props.valueLimit :>> ', props.valueLimit);
        // console.log('value :>> ', value);
        if (props.valueLimit === undefined || props.valueLimit === null) {
          if (value > 100 && props.isLimit) {
            formattedValue.value = 100
          }
        } else {
          if (parseFloat(value?.toString().replace(/,/g, '')) > props.valueLimit && props.isLimit) {
            formattedValue.value = props.valueLimit
          }
        }
      }
    )

    watch(
      () => formattedValue.value,
      (value) => {
        if (props.isLess && props.valueLess) {
          if (parseFloat(value?.toString().replace(/,/g, '')) < props.valueLess) {
            formattedValue.value = props.valueLess
          }
        }
      }
    )

    watch(
      () => props.modelValue,
      (value) => {
        setValue(value)
      }
    )

    return { inputRef, formattedValue } /* , errorMessage */
  }
}
</script>
