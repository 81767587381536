<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="table-responsive">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; white-space: wrap; color: black;">{{ mode === 'edit'? 'บริหารจัดการคลินิก (แก้ไข)' : mode === 'view'? 'บริหารจัดการคลินิก (ดูรายละเอียด)' : 'บริหารจัดการคลินิก (เพิ่ม)' }}</span>
              </div>
              <div v-if="mode === 'add' || mode === 'edit'" class="col-12 col-sm-auto headButton" style="padding-right: 0%">
                <div class="row">
                  <div class="col-6 pl-3 pr-2 my-1 my-sm-0">
                    <b-button type="submit" variant="primary" class="w-100 btn-min-size" @click="handleClick0(true)"><i class="save-data_ct"></i>บันทึก</b-button>
                  </div>
                  <div class="col-6 pl-0 pr-4 my-1 my-sm-0">
                    <b-button type="submit" variant="none" class="w-100 btn-min-size iq-bg-danger btn-cancel-border" @click="handleClick0(false)">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
              <div v-else class="col-12 col-sm-auto headButton" style="padding-right: 0%">
                <div class="row">
                  <div class="col-12 col-sm-auto my-1 my-sm-0">
                    <b-button type="submit" variant="none" class="w-100 btn-min-size iq-bg-danger btn-cancel-border" @click="handleClick0(false)">ย้อนกลับ</b-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div>
              <tab-nav :tabs="true" id="Tab" class="Tab" align="center">
                <tab-nav-items :active="tabisActive.tab1" @click="changeTabActive(1)" id="ClinicInfoTab" role="tab" href="#ClinicInfo" :ariaSelected="tabisActive.tab1" title="ข้อมูลคลินิก" />
                <tab-nav-items :active="tabisActive.tab2" @click="changeTabActive(2)" id="UserInfoTab" role="tab" href="#UserInfo" :ariaSelected="tabisActive.tab2" title="ข้อมูลผู้ใช้" />
                <tab-nav-items :active="tabisActive.tab3" @click="changeTabActive(3)" id="MenuPermissionTab" role="tab" href="#MenuPermission" :ariaSelected="tabisActive.tab3" title="สิทธิ์การใช้งานเมนู" />
              </tab-nav>
              <tab-content id="tabs-tabContent" class="mt-0">
                <tab-content-item :active="tabisActive.tab1" id="ClinicInfo" class="Tab1" aria-labelled-by="ClinicInfo">
                  <div class="mx-3">
                    <div>
                      <b-text v-if="mode === 'add'" class="" style="white-space: nowrap; color: black; font-size: 19px;">ข้อมูลคลินิก : รหัส Auto Generate</b-text>
                      <b-text v-else-if="mode === 'edit'" class="" style="white-space: nowrap; color: black; font-size: 19px;">ข้อมูลคลินิก : รหัส&nbsp; {{ UserManage.tag }}</b-text>
                      <b-text v-else-if="mode === 'view'" class="" style="white-space: nowrap; color: black; font-size: 19px;">ข้อมูลคลินิก : รหัส&nbsp; {{ UserManage.tag }}</b-text>
                    </div><br>
                    <b-row class="mb-4">
                      <b-col lg="12" sm="12">
                        <div class="add-img-user profile-img-edit">
                          <b-img v-if="clinicImage.path !== null || clinicImage.path !== ''" class=" height-170 width-170" fluid :src="clinicImage.path || require('../../assets/images/default.jpg')" alt="profile-pic" style="object-fit: contain;" />
                          <div class="p-image d-flex mb-4" style="justify-content: center;">
                            <b-button variant="none" @click="clickUpload" class="upload-button iq-bg-primary position-relative mb-5" :disabled="isDisable">
                              อัปโหลดรูป
                            </b-button>
                            <b-form-file
                              v-model="clinicImage.file"
                              type="file"
                              id="uploadImage"
                              @change="previewImage"
                              class="h-100 position-absolute"
                              :accept="allowedTypes"
                              style="opacity: 0; display: none;"
                              :disabled="isDisable">
                            </b-form-file>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="12" sm="12">
                        <b-row>
                          <b-col lg="6" sm="6" style="margin-top: 10px;">
                            <b-text style="color: black;">ชื่อคลินิก <span style="color: red;" :hidden="UserManage.Cname?.trim() ? true : false"> *</span></b-text>
                            <b-form-input class="Cname" type="text" :disabled="isDisable" v-model="UserManage.Cname" placeholder=""></b-form-input>
                          </b-col>
                          <b-col lg="6" sm="6" style="margin-top: 10px;">
                            <b-text style="color: black;">ชื่อคลินิก(ภาษาอังกฤษ) <span style="color: red;" :hidden="UserManage.CEng?.trim() ? true : false"> *</span></b-text>
                            <b-form-input type="text" class="CnameEng form-control" :disabled="isDisable" v-model="UserManage.CEng"></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col lg="6" sm="6" style="margin-top: 10px;">
                            <b-text style="color: black;">เลขที่ใบอนุญาตคลินิก</b-text>
                            <b-form-input id="CpermissionNo" class="CpermissionNo" :disabled="isDisable" v-model="UserManage.CpermissionNo" type="text" @keypress="checktypeNumber($event)"></b-form-input>
                          </b-col>
                          <b-col lg="6" sm="6" style="margin-top: 10px;">
                            <b-text style="color: black;">เลขนิติบุคคล</b-text>
                            <b-form-input class="nitiNo" type="text" :disabled="isDisable" v-model="UserManage.nitiNo" placeholder="" @keypress="checktypeNumber($event)"></b-form-input>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col lg="6" sm="6" style="margin-top: 10px;">
                            <b-text style="color: black;">ชื่อผู้ติดต่อ <span style="color: red;" :hidden="UserManage.personName?.trim() ? true : false"> *</span></b-text>
                            <b-form-input class="personName" type="text" :disabled="isDisable" v-model="UserManage.personName" placeholder=""></b-form-input>
                          </b-col>
                          <b-col lg="6" sm="6" style="margin-top: 10px;">
                            <b-text style="color: black;">เบอร์ติดต่อ <span style="color: red;" :hidden="UserManage.Phone?.length === 10 ? true : false"> *</span></b-text>
                            <the-mask mask="###-###-####" class="form-control" id="Phone" v-model="UserManage.Phone" type="tel" :masked="false" placeholder="xxx-xxx-xxxx" :disabled="isDisable"></the-mask>
                            <!-- <b-form-input type="text" class="Phone form-control" id="Phone" :disabled="isDisable" v-model="UserManage.Phone" @keypress="formatContact($event)" supData.con_contact placeholder="Contact Tel"></b-form-input> -->
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col lg="6" sm="6" style="margin-top: 10px;">
                            <b-text style="color: black;">จำนวนผู้ใช้ตามสัญญา <span style="color: red;" :hidden="UserManage.peopleNo ? true : false"> *</span></b-text>
                            <b-form-input class="form-control" type="text" :disabled="isDisable" v-model="UserManage.peopleNo" @keypress="formatContact($event)"></b-form-input>
                          </b-col>
                          <b-col lg="3" sm="3" style="margin-top: 10px;">
                            <b-text style="color: black;">วันที่เริ่มสัญญา <span style="color: red;" :hidden="UserManage.Dstart ? true : false"> *</span></b-text>
                            <div style="position: relative;">
                              <date-picker placeholder="DD/MM/YYYY" id="datepicker-custom1" name="date" v-model="UserManage.Dstart" :disabled="isDisable" :config="options2Start"></date-picker>
                              <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(1)" :disabled="isDisable" style="cursor: pointer;"></i>
                            </div>
                            <!-- <b-text style="color: black;">วันที่เริ่มสัญญา</b-text>
                            <b-form-input class="Dstart" type="date" :disabled="isDisable" v-model="UserManage.Dstart" supData.start_date @change="resetEndDate"></b-form-input> -->
                          </b-col>
                          <b-col lg="3" sm="3" style="margin-top: 10px;">
                            <b-text style="color: black; white-space: nowrap;">วันที่สิ้นสุดสัญญา <span style="color: red;" :hidden="UserManage.Dend ? true : false"> *</span></b-text>
                            <div style="position: relative;">
                              <date-picker placeholder="DD/MM/YYYY" id="datepicker-custom2" name="date" v-model="UserManage.Dend" :disabled="isDisable ? true : !UserManage.Dstart ? true : false" :config="options2End"></date-picker>
                              <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(2)" :disabled="isDisable" style="cursor: pointer;"></i>
                            </div>
                            <!-- <b-text style="color: black; white-space: nowrap;">วันที่สิ้นสุดสัญญา</b-text>
                            <b-form-input class="Dend" type="date" v-model="UserManage.Dend" supData.end_date :disabled="isDisable ? true : !supData.start_date ? true : false" v-bind:min="getCurrentDate(supData.start_date)"></b-form-input> -->
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col lg="5" sm="5" style="margin-top: 10px;" class="form-group col-sm-5">
                            <label for="valueLat">Latitude</label>
                            <input type="number" class="form-control" id="valueLat" :disabled="isDisable" v-model="receiveValueLat" placeholder="Enter latitude">
                          </b-col>
                          <b-col lg="5" sm="5" style="margin-top: 10px;" class="beforeMapButton form-group col-sm-5">
                            <label for="valueLng">Longitude</label>
                            <input type="number" class="form-control" id="valueLng" :disabled="isDisable" v-model="receiveValueLng" placeholder="Enter longitude">
                          </b-col>
                          <b-col lg="2" sm="2"  style="margin-top: 10px;" class="MapButton form-group col-sm-2">
                            <label><br class="GapBr"></label>
                            <div>
                              <b-button @click="getInputLatLng" :disabled="isDisable" class="input-group-text btn-reset_ct" type="button" variant="none" style="width: 100%; height: 45px; border-radius: 10px; justify-content: center;">
                                &nbsp;<i class="map_ct"/><p class="textGoogleMap" style="padding-top: 1rem;">Google Map</p>
                              </b-button>
                              <b-modal v-model="showMap" id="modal-3" size="xl" title="เลือกตำแหน่งที่ตั้ง" ok-title="ตกลง" @ok="showLocation" cancel-title="ยกเลิก" @close.prevent="closeModal()" no-close-on-backdrop>
                                <template>
                                  <div>
                                    <div id="map" style="height: 600px;">
                                      <GoogleMap lat="receiveValueLat" lng="receiveValueLng" @value-updated="handleValueUpdated" ref="googleMapRef" />
                                    </div>
                                  </div>
                                </template>
                                <template #modal-footer>
                                  <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                                    <b-button
                                      style="border-radius: 0.5rem;"
                                      variant="primary"
                                      size="sm"
                                      @click="showLocation"
                                    >
                                      ตกลง
                                    </b-button>
                                    <b-button
                                      class="iq-bg-danger btn-cancel-border"
                                      variant="none"
                                      style="border-radius: 0.5rem;"
                                      size="sm"
                                      @click="closeModal"
                                    >
                                      ยกเลิก
                                    </b-button>
                                  </div>
                                </template>
                              </b-modal>
                            </div>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col lg="6" sm="6" style="margin-top: 10px;">
                            <label>จังหวัด</label>
                              <v-select v-model="UserManage.province" :disabled="isDisable"  :options="provinces"></v-select>
                              <!-- <multiselect :disabled="isDisable" v-model="province" province :options="provinces" placeholder="Select" label="name" selectLabel="" track-by="name"></multiselect> -->
                          </b-col>
                          <b-col lg="6" sm="6" style="margin-top: 10px;">
                            <label>อำเภอ/เขต</label>
                            <v-select v-model="UserManage.amphoe" :disabled="isDisable || !UserManage.province"  :options="amphoes"></v-select>
                      <!-- <multiselect :disabled="isDisable" v-model="amphoe" :options="amphoes" placeholder="Select" label="name" selectLabel="" track-by="name"></multiselect> -->
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col lg="6" sm="6" style="margin-top: 10px;">
                            <label>ตำบล/แขวง</label>
                            <v-select v-model="UserManage.district" :disabled="isDisable || !UserManage.amphoe"  :options="districts"></v-select>
                            <!-- <multiselect :disabled="isDisable" v-model="district" :options="districts" placeholder="Select" label="name" selectLabel="" track-by="name"></multiselect> -->
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col lg="12" sm="12" style="margin-top: 10px;">
                            <label>ที่อยู่</label>
                            <b-form-group label-for="exampleFormControlTextarea1">
                              <b-form-textarea id="exampleFormControlTextarea1" v-model="UserManage.Caddress" rows="2" :disabled="isDisable"></b-form-textarea>
                            </b-form-group>
                          </b-col>
                        </b-row><br>
                      </b-col>
                    </b-row>
                  </div>
                </tab-content-item>

                <tab-content-item :active="tabisActive.tab2" id="UserInfo" class="Tab2" aria-labelled-by="UserInfo">
                  <div class="row align-items-center gap-response">
                    <div class="row ml-4 button-response-header" style="display: flex; align-items: center;">
                      <div class="flex-response-header my-2 mx-2" style="white-space: nowrap;">
                        <div class="response-button-header">
                          <b-button :disabled="isDisable || mode === 'add'" @click="handleClick(null, null)" type="submit" variant="primary" class="btn-min-size my-1 flex-fill text-center btn-add" style="text-align: center;"><i class="circle-plus_ct"></i>เพิ่ม</b-button>
                          <b-button :disabled="isDisable || mode === 'add'" @click="openModalImportFile(), userImport.fileName = null" type="submit" variant="primary" class="btn-min-size bg-primary btn-import" style="text-align: center;"><i class="in-data_ct"></i>นำข้อมูลเข้า</b-button>
                        </div>
                        <div v-if="mode === 'add'">
                          <a :disable="mode === 'add' ? true : false" class="response-button-download">
                          <b-button type="submit" variant="primary" :disabled="mode === 'add' ? true : false" class="btn-min-size my-1 flex-fill text-center response-button-download" style="text-align: center; flex-basis: 100%;"><i class="out-data_ct"></i>Download Template</b-button>
                          </a>
                        </div>
                        <div v-else-if="mode === 'view'">
                          <div class="response-button-download">
                            <b-button :disabled="isDisable" type="submit" variant="primary" class="btn-min-size my-1 flex-fill text-center response-button-download" style="text-align: center; flex-basis: 100%;"><i class="out-data_ct"></i>Download Template</b-button>
                          </div>
                        </div>
                        <div v-else>
                          <a :href="getFullImagePath(downloadFile.file_path)" :download="downloadFile.file_name" class="response-button-download">
                          <b-button type="submit" variant="primary" class="btn-min-size my-1 flex-fill text-center response-button-download" style="text-align: center; flex-basis: 100%;"><i class="out-data_ct"></i>Download Template</b-button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <row v-if="mode !== 'add'">
                    <b-col class="mb-3 col-md-12 col-lg-12 col-12" style="text-align: end; display: flex; align-items: center; justify-content: end;">
                      <span class="text-primary" style="font-size: 14px;">จำนวนผู้ใช้ที่ใช้งานได้ : {{rows.length + '/' + userTableAmount.total}} คน</span>
                    </b-col>
                  </row>
                  <br v-else />
                  <b-col md="12" class="table-responsive">
                    <b-table bordered hover :items="rows" :fields="columns" :current-page="currentPage" :per-page="perPage" @pagination="onPagination" :tbody-tr-class="rowClass" thead-class="table-header-color">
                      <template v-slot:cell(variable1)="data">
                        <span v-if="!data.item.editable">{{ data.item.variable1 }}</span>
                        <input type="text" v-model="data.item.variable1" v-else class="form-control"  style="text-align: center;">
                      </template>
                      <template v-slot:cell(variable2)="data">
                        <span v-if="!data.item.editable">{{ data.item.variable2 }}</span>
                        <input type="text" v-model="data.item.variable2" v-else class="form-control" style="text-align: center;">
                      </template>
                      <template v-slot:cell(variable3)="data">
                        <span v-if="!data.item.editable">{{ data.item.variable3 }}</span>
                        <input type="text" v-model="data.item.variable3" v-else class="form-control" style="text-align: center;">
                      </template>
                      <template v-slot:cell(variable4)="data">
                        <span v-if="!data.item.editable">{{ data.item.variable4 }}</span>
                        <input type="text" v-model="data.item.variable4" v-else class="form-control" style="text-align: center;">
                      </template>
                      <template v-slot:cell(variable5)="data">
                        <b-button variant="none" class="mx-2 flex-fill bg-danger text-center" :disabled="isDisable || mode === 'add'" @click="resetPassword(data.item)" style="text-align: center;">Reset</b-button>
                      </template>
                      <template v-slot:cell(variable6)="data">
                        <div style="display: flex; text-align: center; justify-content: center;" :hidden="!data.item.emp_id">
                          <b-button variant=" iq-bg-primary mr-1" :hidden="data.item.id === 1 ? true : false" size="sm" @click="handleClick('view', data.item)"><i class="ri-eye-fill m-0"></i></b-button>
                          <a v-if="data.item.id === 1" :disabled="data.item.id === 1 ? true : false" size="sm" @click="this.remove(data.item)" class="p-3" style="color: white !important;"><i :hidden="data.item.id === 1 ? true : false" class="ri-ball-pen-fill m-0"></i></a>
                          <b-button v-else variant=" iq-bg-success mr-1" size="sm" :disabled="isDisable" @click="handleClick('edit', data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                          <a v-if="data.item.id === 1" :disabled="data.item.id === 1 ? true : false" size="sm" @click="this.remove(data.item)" class="p-3" style="color: white !important;"><i :hidden="data.item.id === 1 ? true : false" class="ri-delete-bin-line m-0"></i></a>
                          <b-button v-else variant=" iq-bg-danger" :hidden="data.item.id === 1 ? true : false" :disabled="data.item.id === 1||isDisable ? true : false" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                        </div>
                      </template>
                    </b-table>
                  </b-col>
                  <b-pagination v-model="currentPage" :total-rows="rows.length" :per-page="perPage" align="center" />
                  <b-modal v-model="showModalUpload" id="modal-import" title="Upload ผู้ใช้" ok-title="บันทึก" :ok-disabled="userImport.fileName !== null ? false : true" @ok="uploadUser"  cancel-title="ยกเลิก" no-close-on-backdrop>
                    <span>User file:** Excel from template **</span>
                    <b-form-group
                      label-for="customFile"
                    >
                      <b-form-file
                        id="customFile"
                        :placeholder="userImport.fileName ? userImport.fileName : 'Choose a file'"
                        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        @change="handleImageUpload"
                      ></b-form-file>
                    </b-form-group>
                    <template #modal-footer>
                      <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                        <b-button
                          style="border-radius: 0.5rem;"
                          variant="primary"
                          size="sm"
                          class="btn-min-size"
                          @click="uploadUser"
                          :disabled="userImport.fileName !== null ? false : true"
                        >
                        <i class="save-data_ct"/>บันทึก
                        </b-button>
                        <b-button
                          variant="none"
                          class="iq-bg-danger btn-cancel-border btn-min-size"
                          style="border-radius: 0.5rem;"
                          size="sm"
                          @click="checkUploadUser"
                        >
                          ยกเลิก
                        </b-button>
                      </div>
                    </template>
                  </b-modal>
                  <b-modal v-model="showModal" title="สถานะการ Upload" size="xl" @close.prevent="closeModalShowStatus" no-close-on-backdrop>
                    <b-col cols="12" class="table-responsive">
                      <b-table bordered hover :items="rows2" :fields="columns2" :current-page="currentPage" :per-page="perPage" @pagination="onPagination">
                        <template v-slot:cell(variable1)="data">
                          <span v-if="!data.item.editable">{{ data.item.variable1 }}</span>
                          <input type="text" v-model="data.item.variable1" v-else class="form-control">
                        </template>
                        <template v-slot:cell(variable2)="data">
                          <span v-if="!data.item.editable">{{ data.item.variable2 }}</span>
                          <input type="text" v-model="data.item.variable2" v-else class="form-control">
                        </template>
                        <template v-slot:cell(variable3)="data">
                          <span v-if="!data.item.editable">{{ data.item.variable3 }}</span>
                          <input type="text" v-model="data.item.variable4" v-else class="form-control">
                        </template>
                        <template v-slot:cell(variable4)="data">
                          <span v-if="!data.item.editable">{{ data.item.position }}</span>
                          <input type="text" v-model="data.item.position" v-else class="form-control">
                        </template>
                        <template v-slot:cell(variable5)="data">
                          <b-button
                          variant="none"
                          :class="'btn-min-size ' + (data.item.status ? 'iq-bg-success btn-success-border' : 'iq-bg-danger btn-cancel-border')"
                          size="sm"
                          style="border-radius: 0.5rem;"
                        >
                          {{ data.item.variable4 }}
                        </b-button>
                          <!-- <span v-if="!data.item.editable"></span>
                          <input type="text" v-model="data.item.variable4" v-else class="form-control"> -->
                        </template>
                        <template v-slot:cell(variable6)="data">
                          <span v-if="!data.item.editable" style="white-space: nowrap;">{{ data.item.error }}</span>
                          <input type="text" v-model="data.item.error" v-else class="form-control">
                        </template>
                      </b-table>
                      <br>
                      <b-pagination v-model="currentPage" :total-rows="rows2.length" :per-page="perPage" style="display: flex; justify-content: center;"/>
                    </b-col>
                    <template #modal-footer>
                      <div class="w-100">
                        <p class="float-left"></p>
                        <b-button
                          variant="none"
                          class="iq-bg-danger btn-cancel-border float-right btn-min-size"
                          size="sm"
                          style="border-radius: 0.5rem;"
                          @click="closeModalShowStatus"
                        >
                          ยกเลิก
                        </b-button>
                      </div>
                    </template>
                  </b-modal>
                </tab-content-item>

                <tab-content-item :active="tabisActive.tab3" id="MenuPermission" class="Tab3" aria-labelled-by="MenuPermission">
                  <b-row style="color: black;" class="py-2">
                    <b-col lg="6" md="6" sm="12">
                      <b-list-group>
                        <b-list-group-item active>
                          <b-row>
                            <b-col lg="6" md="6" sm="3" class="MCDheader" style="white-space: wrap;">ข้อมูลหลักคลินิก</b-col>
                            <b-col lg="6" md="6" sm="9" class="headSwitch">
                              <div style="display: flex; justify-content: end;">
                                <div style="display: flex; background-color: white; border-radius: 4px; padding: 0.2rem; padding-left: 0.6rem; margin-right: 0.7rem; width: 120px">
                                  <span class="mr-2" style="font-size: 13px; color: black;">{{ allChecked.Check1 ? '&nbsp;ปิด' : 'เปิด' }}ทั้งหมด</span>
                                  <div class="custom-control custom-switch custom-control-inline" style="margin-right: 0.1rem;">
                                    <input :disabled="isDisable" type="checkbox" class="custom-control-input" id="Switch1" v-model="allChecked.Check1" @change="toggleCheckboxes" style="display: flex; justify-content: end;">
                                    <label class="custom-control-label" for="Switch1"></label>
                                  </div>
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                        </b-list-group-item>
                        <b-list-group-item v-for="(item, index) in checkedBox1" :key="index">
                          <b-row>
                            <b-col v-if="item.name === 'สินค้า (Mat define, Cycle time)'" style="white-space: nowrap;">สินค้า (Mat define, <br class="NewLines"/>Cycle time)</b-col>
                            <b-col v-else style="white-space: nowrap;">{{ item.name }}</b-col>
                            <b-col>
                              <div class="checkbox1 custom-control custom-switch custom-control-inline" style="display: flex; justify-content: end;">
                                <input type="checkbox" :disabled="isDisable" :id="'customSwitch' + (index + 1)" class="custom-control-input" v-model="item.status">
                                <label class="custom-control-label" :for="'customSwitch' + (index + 1)"></label>
                              </div>
                            </b-col>
                          </b-row>
                        </b-list-group-item>
                      </b-list-group>
                      <b-list-group>
                        <b-list-group-item active>
                          <b-row>
                            <b-col lg="6" md="6" sm="3" class="MCDheader" style="white-space: wrap;">{{checkedBox2[0]?.module}}</b-col>
                            <b-col lg="6" md="6" sm="9" class="headSwitch">
                              <div style="display: flex; justify-content: end;">
                                <div style="display: flex; background-color: white; border-radius: 4px; padding: 0.2rem; padding-left: 0.6rem; margin-right: 0.7rem; width: 120px">
                                  <span class="mr-2" style="font-size: 13px; color: black;">{{ allChecked.Check2 ? '&nbsp;ปิด' : 'เปิด' }}ทั้งหมด</span>
                                  <div class="custom-control custom-switch custom-control-inline" style="margin-right: 0.1rem;">
                                    <input :disabled="isDisable" type="checkbox" class="custom-control-input" id="Switch2" v-model="allChecked.Check2" @change="toggleCheckboxes2" style="display: flex; justify-content: end;">
                                    <label class="custom-control-label" for="Switch2"></label>
                                  </div>
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                        </b-list-group-item>
                        <b-list-group-item v-for="(item ,index) in checkedBox2" :key="index">
                          <b-row>
                            <b-col v-if="item.name === 'ใบรายการขายสินค้า/บริการ'" style="white-space: wrap;">{{ item.name }}</b-col>
                            <b-col v-else-if="item.name === 'Purchase Order (PO)'" style="white-space: nowrap;">Purchase Order <br class="NewLines2"/>(PO)</b-col>
                            <b-col v-else style="white-space: nowrap;">{{ item.name }}</b-col>
                            <b-col>
                              <div class="checkbox2 custom-control custom-switch custom-control-inline" style="display: flex; justify-content: end;">
                                <input type="checkbox" :disabled="isDisable"  :id="'checkedBox2-' + index" class="custom-control-input" v-model="item.status">
                                <label class="custom-control-label" :for="'checkedBox2-' + index"></label>
                              </div>
                            </b-col>
                          </b-row>
                        </b-list-group-item>
                      </b-list-group>
                      <b-list-group>
                        <b-list-group-item active>
                          <b-row>
                            <b-col lg="6" md="6" sm="3" style="white-space: nowrap;">{{checkedBox3[0]?.module}}</b-col>
                            <b-col lg="6" md="6" sm="9" class="headSwitch">
                              <div style="display: flex; justify-content: end;">
                                <div style="display: flex; background-color: white; border-radius: 4px; padding: 0.2rem; padding-left: 0.6rem; margin-right: 0.7rem; width: 120px">
                                  <span class="mr-2" style="font-size: 13px; color: black;">{{allChecked.Check3 === true? '&nbsp;ปิด' : 'เปิด'}}ทั้งหมด</span>
                                  <div class="custom-control custom-switch custom-control-inline" style="margin-right: 0.1rem;">
                                    <input type="checkbox" :disabled="isDisable" class="custom-control-input checkbox3" id="Switch3" v-model="allChecked.Check3" @change="toggleCheckboxes3" style="display: flex; justify-content: end;">
                                    <label class="custom-control-label" for="Switch3"></label>
                                  </div>
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                        </b-list-group-item>
                        <b-list-group-item v-for="(item, index) in checkedBox3" :key="index">
                          <b-row>
                            <b-col v-if="item.name === 'รายการสินค้ารอเบิก OPD'" style="white-space: nowrap;">รายการสินค้ารอเบิก <br class="NewLines2"/>OPD</b-col>
                            <b-col v-else style="white-space: nowrap;">{{ item.name }}</b-col>
                            <b-col>
                              <div class="checkbox3 custom-control custom-switch custom-control-inline" style="display: flex; justify-content: end;">
                                <input type="checkbox" :disabled="isDisable"  :id="'checkedBox3-' + index" class="custom-control-input" v-model="item.status">
                                <label class="custom-control-label" :for="'checkedBox3-' + index"></label>
                              </div>
                            </b-col>
                          </b-row>
                        </b-list-group-item>
                      </b-list-group>
                    </b-col>
                    <b-col lg="6" md="6" sm="12">
                      <b-list-group>
                        <b-list-group-item active>
                          <b-row>
                            <b-col lg="6" md="6" sm="3" style="white-space: nowrap;">{{checkedBox4[0]?.module}}</b-col>
                            <b-col lg="6" md="6" sm="9" class="headSwitch">
                              <div style="display: flex; justify-content: end;">
                                <div style="display: flex; background-color: white; border-radius: 4px; padding: 0.2rem; padding-left: 0.6rem; margin-right: 0.7rem; width: 120px">
                                  <span class="mr-2" style="font-size: 13px; color: black;">{{allChecked.Check4 === true? '&nbsp;ปิด' : 'เปิด'}}ทั้งหมด</span>
                                  <div class="custom-control custom-switch custom-control-inline" style="margin-right: 0.1rem;">
                                    <input type="checkbox" :disabled="isDisable" class="custom-control-input checkbox4" id="Switch4" v-model="allChecked.Check4" @change="toggleCheckboxes4" style="display: flex; justify-content: end;">
                                    <label class="custom-control-label" for="Switch4"></label>
                                  </div>
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                        </b-list-group-item>
                        <b-list-group-item v-for="(item, index) in checkedBox4" :key="index">
                          <b-row>
                            <b-col v-if="item.name === 'ใบรายการขายสินค้า/บริการ'" style="white-space: wrap;">{{ item.name }}</b-col>
                            <b-col v-else-if="item.name === 'Purchase Order (PO)'" style="white-space: nowrap;">Purchase Order <br class="NewLines2"/>(PO)</b-col>
                            <b-col v-else style="white-space: nowrap;">{{ item.name }}</b-col>
                            <b-col>
                              <div class="checkbox4 custom-control custom-switch custom-control-inline" style="display: flex; justify-content: end;">
                                <input type="checkbox" :disabled="isDisable"  :id="'checkedBox4-' + index" class="custom-control-input" v-model="item.status">
                                <label class="custom-control-label" :for="'checkedBox4-' + index"></label>
                              </div>
                            </b-col>
                          </b-row>
                        </b-list-group-item>
                      </b-list-group>
                      <b-list-group>
                        <b-list-group-item active>
                          <b-row>
                            <b-col lg="6" md="6" sm="3" style="white-space: nowrap;">{{checkedBox5[0]?.module}}</b-col>
                            <b-col lg="6" md="6" sm="9" class="headSwitch">
                              <div style="display: flex; justify-content: end;">
                                <div style="display: flex; background-color: white; border-radius: 4px; padding: 0.2rem; padding-left: 0.6rem; margin-right: 0.7rem; width: 120px">
                                  <span class="mr-2" style="font-size: 13px; color: black;">{{allChecked.Check5 === true? '&nbsp;ปิด' : 'เปิด'}}ทั้งหมด</span>
                                  <div class="custom-control custom-switch custom-control-inline" style="margin-right: 0.1rem;">
                                    <input type="checkbox" :disabled="isDisable" class="custom-control-input checkbox5" id="Switch5" v-model="allChecked.Check5" @change="toggleCheckboxes5" style="display: flex; justify-content: end;">
                                    <label class="custom-control-label" for="Switch5"></label>
                                  </div>
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                        </b-list-group-item>
                        <b-list-group-item v-for="(item, index) in checkedBox5" :key="index">
                          <b-row>
                            <b-col v-if="item.name === 'รายการสินค้ารอเบิก OPD'" style="white-space: nowrap;">รายการสินค้ารอเบิก <br class="NewLines2"/>OPD</b-col>
                            <b-col v-else style="white-space: nowrap;">{{ item.name }}</b-col>
                            <b-col>
                              <div class="checkbox5 custom-control custom-switch custom-control-inline" style="display: flex; justify-content: end;">
                                <input type="checkbox" :disabled="isDisable"  :id="'checkedBox5-' + index" class="custom-control-input" v-model="item.status">
                                <label class="custom-control-label" :for="'checkedBox5-' + index"></label>
                              </div>
                            </b-col>
                          </b-row>
                        </b-list-group-item>
                      </b-list-group>
                      <b-list-group>
                        <b-list-group-item active>
                          <b-row>
                            <b-col lg="6" md="6" sm="3" style="white-space: nowrap;">{{checkedBox6[0]?.module}}</b-col>
                            <b-col lg="6" md="6" sm="9" class="headSwitch">
                              <div style="display: flex; justify-content: end;">
                                <div style="display: flex; background-color: white; border-radius: 4px; padding: 0.2rem; padding-left: 0.6rem; margin-right: 0.7rem; width: 120px">
                                  <span class="mr-2" style="font-size: 13px; color: black;">{{allChecked.Check6 === true? '&nbsp;ปิด' : 'เปิด'}}ทั้งหมด</span>
                                  <div class="custom-control custom-switch custom-control-inline" style="margin-right: 0.1rem;">
                                    <input type="checkbox" :disabled="isDisable" class="custom-control-input checkbox6" id="Switch6" v-model="allChecked.Check6" @change="toggleCheckboxes6" style="display: flex; justify-content: end;">
                                    <label class="custom-control-label" for="Switch6"></label>
                                  </div>
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                        </b-list-group-item>
                        <b-list-group-item v-for="(item, index) in checkedBox6" :key="index">
                          <span v-if="item.name === 'Open Link Doctor'"></span>
                          <b-row v-else>
                            <b-col v-if="item.name === 'สรุปรายได้ และค่าใช้จ่าย'" style="white-space: nowrap;">สรุปค่าใช้จ่าย <br class="NewLines2"/>ประจำเดือน</b-col>
                            <b-col v-else-if="item.name === 'การปฏิบัติงานและประวัติ KPI'" style="white-space: nowrap;">การปฏิบัติงาน <br class="NewLines2"/>และประวัติ KPI</b-col>
                            <b-col v-else style="white-space: nowrap;">{{ item.name }}</b-col>
                            <b-col>
                              <div class="checkbox6 custom-control custom-switch custom-control-inline" style="display: flex; justify-content: end;">
                                <input type="checkbox" :disabled="isDisable"  :id="'checkedBox6-' + index" class="custom-control-input" v-model="item.status">
                                <label class="custom-control-label" :for="'checkedBox6-' + index"></label>
                              </div>
                            </b-col>
                          </b-row>
                        </b-list-group-item>
                        <b-list-group-item active style="border-radius: 4px 4px 0 0;">
                          <b-row>
                            <b-col lg="6" md="6" sm="3" style="white-space: nowrap;">{{checkedBox7[0]?.module}}</b-col>
                            <b-col lg="6" md="6" sm="9" class="headSwitch">
                              <div style="display: flex; justify-content: end;">
                                <div style="display: flex; background-color: white; border-radius: 4px; padding: 0.2rem; padding-left: 0.6rem; margin-right: 0.7rem; width: 120px">
                                  <span class="mr-2" style="font-size: 13px; color: black;">{{allChecked.Check7 === true? '&nbsp;ปิด' : 'เปิด'}}ทั้งหมด</span>
                                  <div class="custom-control custom-switch custom-control-inline" style="margin-right: 0.1rem;">
                                    <input type="checkbox" :disabled="isDisable" class="custom-control-input checkbox7" id="Switch7" v-model="allChecked.Check7" @change="toggleCheckboxes7" style="display: flex; justify-content: end;">
                                    <label class="custom-control-label" for="Switch7"></label>
                                  </div>
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                        </b-list-group-item>
                        <b-list-group-item v-for="(item, index) in checkedBox7" :key="index">
                          <b-row>
                            <b-col v-if="item.name === 'กลุ่มวัตถุดิบ , แบรนด์ , ประเภท'" style="white-space: nowrap;">กลุ่มวัตถุดิบ , แบรนด์ , <br class="NewLines"/>ประเภท</b-col>
                            <b-col v-else style="white-space: nowrap;">{{ item.name }}</b-col>
                            <b-col>
                              <div class="checkbox7 custom-control custom-switch custom-control-inline" style="display: flex; justify-content: end;">
                                <input type="checkbox" :disabled="isDisable"  :id="'checkedBox7-' + index" class="custom-control-input" v-model="item.status">
                                <label class="custom-control-label" :for="'checkedBox7-' + index"></label>
                              </div>
                            </b-col>
                          </b-row>
                        </b-list-group-item>
                      </b-list-group>
                    </b-col>
                  </b-row>
                </tab-content-item>
              </tab-content>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import GoogleMap from './GoogleMap.vue'
// import Multiselect from 'vue-multiselect'
import axios from 'axios'
import { mask } from 'vue-the-mask'
import moment from 'moment'
import { configAPI } from '../../configBase'

const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}

export default {
  name: 'AddUser',
  directives: { mask },
  components: {
    GoogleMap
  },
  computed: {
  },
  watch: {
    'UserManage.peopleNo' (newVal) {
      this.UserManage.peopleNo = newVal?.toString()?.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
    },
    'UserManage.Dstart': function (newVal, oldVal) {
      this.options2End.minDate = newVal
      if (oldVal) {
        this.UserManage.Dend = null
      }
    },
    'UserManage.CEng': function (newVal, oldVal) {
      if (this.mode === 'add') {
        this.rows[0].variable1 = 'sa-' + newVal?.toString().trim().replace(/ /g, '.')
      }
    },
    'UserManage.personName': function (newVal, oldVal) {
      if (this.mode === 'add') {
        this.rows[0].variable2 = newVal
      }
    },
    'UserManage.province': function (newVal, oldval) {
      this.UserManage.amphoe = null
      this.UserManage.district = null
      if (newVal) {
        this.getDistrict(newVal.code)
      }
    },
    'UserManage.amphoe': function (newVal, oldVal) {
      this.UserManage.district = null
      if (newVal) {
        this.getSubDistrict(newVal.code)
      }
    },
    checkedBox1: {
      deep: true,
      handler () {
        this.handleSwitchChange()
      }
    },
    checkedBox2: {
      deep: true,
      handler () {
        this.handleSwitchChange()
      }
    },
    checkedBox3: {
      deep: true,
      handler () {
        this.handleSwitchChange()
      }
    },
    checkedBox4: {
      deep: true,
      handler () {
        this.handleSwitchChange()
      }
    },
    checkedBox5: {
      deep: true,
      handler () {
        this.handleSwitchChange()
      }
    },
    checkedBox6: {
      deep: true,
      handler () {
        this.handleSwitchChange()
      }
    },
    checkedBox7: {
      deep: true,
      handler () {
        this.handleSwitchChange()
      }
    },
    receiveAddress (val) {
      this.UserManage.Caddress = val
    }
  },
  async mounted () {
    xray.index()

    this.cln_id = JSON.parse(sessionStorage.getItem('user')).cln_id
    if (this.$store.state.DataTab2 === 'tab2') {
      this.tabisActive.tab1 = false
      this.tabisActive.tab2 = true
      this.tabisActive.tab3 = false

      document.getElementById('UserInfoTab').classList.add('active')
      document.getElementById('ClinicInfoTab').classList.remove('active', 'show')
    } else {
      this.tabisActive.tab1 = true
      document.getElementById('ClinicInfoTab').classList.add('active', 'show')
    }
    this.$store.dispatch('updateDataTab2', null)
    await this.getDistrictAll()
    await this.getAmphoeAll()
    await this.getProvice()
    if (this.$route.params.id) {
      this.rows = []
      this.id = this.$route.params.id
      this.mode = 'edit'
      if (this.$route.params.setPage === 'view') {
        this.mode = 'view'
        this.isDisable = true
      }
      this.downloadFileTemplate()
      await this.getClinicById()
      await this.getUserOfClinic()
      /* let userFind = testUser.find(el => el.id.toString() === this.$route.params.id.toString())
      // console.log('userFind', userFind)
      if (userFind) {
        this.UserManage = userFind
      } */
    } else {
      this.mode = 'add'
    }
    await this.getModuleMenuAll()
    // await this.setUserFromAddUserManage()
  },
  data () {
    return {
      id: null,
      cln_id: null,
      clinicImage: {
        file: null,
        path: null,
        name: null
      },
      oldProfileImage: {
        name: null,
        path: null,
        file: null
      },
      downloadFile: {
        file_name: '',
        file_path: ''
      }, // '${configAPI.MA_PORT}/api/user/download',
      mode: 'add',
      name: 'UiDataTable',
      userTableAmount: {
        total: null,
        amount: null
      },
      tabisActive: {
        tab1: false,
        tab2: false,
        tab3: false
      },
      CEng: '',
      CThai: '',
      isDisable: false,
      showModal: false,
      showMap: false,
      showModalUpload: false,
      options2Start: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        minDate: false,
        icons: iconDate
      },
      options2End: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        minDate: false,
        icons: iconDate
      },
      selectedOption: 'option1',
      userImport: {
        fileName: null,
        file: null
      },
      optionsSelect: [],
      UserManage: {
        tag: null,
        Cname: null,
        CEng: null,
        CpermissionNo: null,
        nitiNo: null,
        personName: null,
        Phone: null,
        peopleNo: null,
        Dstart: null,
        Dend: null,
        Lat: null,
        Lng: null,
        GapBr: null,
        province: null,
        amphoe: null,
        district: null,
        Caddress: null,
        start_time: null,
        end_time: null,
        social: null,
        pay_off: null,
        social_security: null,
        social_security_date: null,
        confirm_email: null,
        fix_pay: null,
        amount: null
      },
      inputValue: '',
      supData: {
        con_contact: '',
        end_date: null,
        start_date: null
      },
      allowedTypes: [
        'image/png',
        'image/jpeg'
      ],
      receiveValue: '',
      receiveValueLat: '',
      receiveValueLng: '',
      receiveAddress: '',
      receiveAddressComponent: '',
      district: null,
      districtName: null,
      amphoe: null,
      amphoeName: null,
      province: null,
      provinceName: null,
      zipcode: '',
      activeTab: 'tab1',
      provinces: [],
      provincesAll: [],
      amphoes: [],
      amphoesAll: [],
      districts: [],
      districtsAll: [],
      columns: [
        { label: 'Username', key: 'variable1', class: 'text-center table-size-200-400' },
        { label: 'ชื่อผู้ใช้', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'กลุ่มพนักงาน', key: 'variable3', class: 'text-center table-size-200-400' },
        { label: 'สถานะ', key: 'variable4', class: 'text-center table-size-100-300' },
        { label: 'Reset password', key: 'variable5', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'variable6', class: 'text-center table-size-200-400' }
      ],
      columns2: [
        { label: 'Username', key: 'variable1', class: 'text-center table-size-200-400' },
        { label: 'ชื่อผู้ใช้', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'อีเเมล', key: 'variable3', class: 'text-center table-size-200-400' },
        { label: 'ตำแหน่ง', key: 'variable4', class: 'text-center table-size-100-300' },
        { label: 'สถานะ', key: 'variable5', class: 'text-center' },
        { label: 'หมายเหตุ', key: 'variable6', class: 'text-center table-size-100-300' }
      ],
      rows2: [],
      rows: [
        {
          id: 1,
          index: 1,
          variable1: 'sa-',
          variable2: '',
          variable3: 'SuperAdmin',
          variable4: 'ใช้งาน',
          editable: false
        }
      ],
      userAED: {
        addList: [],
        updateList: [],
        deleteList: []
      },
      allChecked: {
        Check1: false,
        Check2: false,
        Check3: false,
        Check4: false,
        Check5: false,
        Check6: false,
        Check7: false,
        all: null
      },
      checkedBox1: [
        /* { id: 'customSwitch01', name: 'สินค้า (Mat define, Cycle time)', status: false } */
      ],
      checkedBox2: [
        /* { id: 'customSwitch13', name: 'จัดการคิวลูกค้า', status: false } */
      ],
      checkedBox3: [
        /* { id: 'customSwitch19', name: 'ข้อมูลพนักงาน', status: false } */
      ],
      checkedBox4: [
        /* { id: 'customSwitch28', name: 'ใบรายการขายสินค้า/บริการ', status: false } */
      ],
      checkedBox5: [
        /* { id: 'customSwitch36', name: 'รายการสินค้ารอเบิก OPD', status: false } */
      ],
      checkedBox6: [
        /* { id: 'customSwitch41', name: 'บริหารจัดการคลินิก', status: false } */
      ],
      checkedBox7: [
        /* { id: 'customSwitch45', name: 'กลุ่มสินค้า', status: false } */
      ],
      currentPage: 1,
      perPage: 10
    }
  },
  methods: {
    previewImage: function (event) {
      const input = event.target
      if (input.files[0] && !this.allowedTypes.includes(input.files[0].type)) {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
          html: '<span>กรุณาเลือกไฟล์รูปภาพเท่านั้น</span>',
          icon: 'warning',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          this.clinicImage.name = this.oldclinicImage.name !== null && this.oldclinicImage.name !== '' ? this.oldclinicImage.name : null
          this.clinicImage.path = this.oldclinicImage.path !== null && this.oldclinicImage.path !== '' ? this.oldclinicImage.path : null
          this.clinicImage.file = this.oldclinicImage.file !== null && this.oldclinicImage.file !== '' ? this.oldclinicImage.file : null
        })
      }
      if (input.files && input.files[0]) {
        this.clinicImage.name = input.files[0].name
        this.clinicImage.path = URL.createObjectURL(input.files[0])
        this.clinicImage.file = input.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(input.files[0])
        reader.onload = (e) => {
          this.clinicImage.path = e.target.result
        }
      } else if (this.mode === 'add') {
        this.clinicImage.name = null
        this.clinicImage.path = null
        this.clinicImage.file = null
      } else {
        this.clinicImage.name = this.oldclinicImage.name !== null && this.oldclinicImage.name !== '' ? this.oldclinicImage.name : null
        this.clinicImage.path = this.oldclinicImage.path !== null && this.oldclinicImage.path !== '' ? this.oldclinicImage.path : null
        this.clinicImage.file = null
      }
    },
    clickUpload () {
      document.getElementById('uploadImage').click()
    },
    async setUserFromAddUserManage () {
      const dataAddUser = this.$store.state.addUserManage
      if (dataAddUser.cln_id && dataAddUser.username) {
        if (dataAddUser.mode === 'add') {
          this.userAED.addList.push({
            useStatus: dataAddUser.statusUse ? 'ใช้งาน' : 'ยกเลิก',
            emp_id: dataAddUser.employee,
            username: dataAddUser.username,
            email: dataAddUser.email,
            file: dataAddUser.file || null
          })
          this.rows.push({
            id: null,
            index: this.rows.length + 1,
            variable1: dataAddUser.username,
            variable2: dataAddUser.employee,
            variable3: 'User',
            variable4: dataAddUser.statusUse ? 'ใช้งาน' : 'ยกเลิก',
            editable: false
          })
        } else if (dataAddUser.mode === 'edit') {

        }
      }
      // this.$store.dispatch('updateAddUserManage', null)
    },
    async getSubDistrict (districtId) {
      await axios.request({
        method: 'get',
        url: `${configAPI.MD_PORT}/api/district/${districtId}/subDistrict`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status) {
            this.districts = response.data.data?.map(el => {
              if (this.district && this.district === el.id) {
                this.UserManage.district = {
                  label: el.name,
                  code: el.id
                }
                this.district = null
              }
              if (this.districtName?.code && this.districtName?.code === el.id) {
                this.UserManage.district = {
                  label: el.name,
                  code: el.id
                }
                this.districtName = null
              }
              return {
                label: el.name,
                code: el.id
              }
            })
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async getDistrictAll () {
      await axios.request({
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/subdistrict`,
        header: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (response.data.status) {
          this.districtsAll = response.data.data?.map(el => {
            return {
              label: el.name,
              code: el.id
            }
          })
        }
      }).catch((error) => {
        this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
      })
    },
    async getDistrict (provinceId) {
      await axios.request({
        method: 'get',
        url: `${configAPI.MD_PORT}/api/province/${provinceId}/district`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status) {
            this.amphoes = response.data.data?.map(el => {
              if (this.amphoe && this.amphoe === el.id) {
                this.UserManage.amphoe = {
                  label: el.name,
                  code: el.id
                }
                this.amphoe = null
              }
              if (this.amphoeName?.code && this.amphoeName?.code === el.id) {
                this.UserManage.amphoe = {
                  label: el.name,
                  code: el.id
                }
                this.amphoeName = null
              }
              return {
                label: el.name,
                code: el.id
              }
            })
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async getAmphoeAll () {
      await axios.request({
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/district`,
        header: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (response.data.status) {
          this.amphoesAll = response.data.data?.map(el => {
            return {
              label: el.name,
              code: el.id
            }
          })
        }
      }).catch((error) => {
        this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
      })
    },
    async getProvice () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MD_PORT}/api/province`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status) {
            this.provinces = response.data.data?.map(el => {
              return {
                label: el.name,
                code: el.id
              }
            })
            this.provincesAll = response.data.data?.map(el => {
              return {
                label: el.name,
                code: el.id
              }
            })
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async getModuleMenuAll () {
      await axios.request({
        method: 'get',
        url: this.mode === 'add'
          ? `${configAPI.MD_PORT}/api/permissionMenu`
          : `${configAPI.MA_PORT}/api/clinic/${this.id}/permissionClinic`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status) {
            let index = 1
            for (let item of response.data.data) {
              this['checkedBox' + index] = item.menu?.filter(el => el.name !== 'Open Link Doctor')
                .map(el => {
                  return {
                    id: el.id,
                    name: el.name,
                    status: this.mode === 'add' ? false : el.is_active,
                    module: item.name
                  }
                })
                // if (el.name !== 'Open Link Doctor') {
                // }
              ++index
            }
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async downloadFileTemplate () {
      let productGroupAPI = {
        method: 'post',
        url: `${configAPI.MA_PORT}/api/user/download`
      }
      await axios.request(productGroupAPI)
        .then((response) => {
          if (response.data.status) {
            this.downloadFile = response.data.data
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async getUserOfClinic () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.id}/user/`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status) {
            this.userTableAmount = {
              total: response.data.data.user_amount,
              amount: response.data.data.user_total
            }
            let arrTemp = []
            let index = 0
            const resList = response.data.data.results.sort((a, b) => {
              return (new Date(a.created_date) - new Date(b.created_date))
            })
            for (let item of resList) {
              if (item.is_use) {
                arrTemp.push({
                  id: item.id,
                  index: index + 1,
                  variable1: item.username,
                  variable2: `${item.emp_name || ''} ${item.emp_lastname || ''}`.trim() || '-',
                  variable3: item.rol_name, /* ut_name */
                  variable4: item.us_name,
                  editable: false,
                  created_date: item.created_date,
                  emp_id: item.emp_id
                })
              }
              index += 1
            }
            this.rows = arrTemp
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async getClinicById () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            const tab1 = response.data.data[0]
            this.amphoe = tab1.district_id || null
            this.district = tab1.sd_id || null
            this.UserManage = {
              tag: tab1.no || null,
              Cname: tab1.name || null,
              CEng: tab1.name_eng || null,
              CpermissionNo: tab1.license_no || null,
              nitiNo: tab1.juristic_no || null,
              personName: tab1.contract_name || null,
              Phone: tab1.tel?.toString().replace(/-/g, '') || null,
              peopleNo: tab1.user_amount || null,
              Dstart: tab1.contract_start ? moment(tab1.contract_start).format('DD/MM/YYYY') : null,
              Dend: tab1.contract_end ? moment(tab1.contract_end).format('DD/MM/YYYY') : null,
              Lat: tab1.lattitude || null,
              Lng: tab1.longtitude || null,
              province: !tab1.province_id
                ? null
                : this.provinces.find(el => el.code === tab1.province_id),
              amphoe: null,
              district: null,
              Caddress: tab1.address || null,
              start_time: tab1.start_time,
              end_time: tab1.end_time,
              social: tab1.social,
              pay_off: tab1.pay_off,
              social_security: tab1.social_security,
              social_security_date: tab1.social_security_date,
              confirm_email: tab1.confirm_email,
              fix_pay: tab1.fix_pay,
              amount: tab1.amount
            }
            this.receiveValueLat = tab1.lattitude || null
            this.receiveValueLng = tab1.longtitude || null
            this.clinicImage.name = tab1.img || null
            this.clinicImage.path = tab1.img_path ? this.getFullImagePath(tab1.img_path) : null
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    getFullImagePath (relativePath) {
      if (relativePath) {
        relativePath = relativePath.substring(1)
      }
      const baseURL = `${configAPI.MA_PORT}`
      return baseURL + relativePath
    },
    onClickDatePicker (num) {
      let datePicker = null
      if (num === 1) {
        datePicker = document.getElementById('datepicker-custom1')
      } else {
        datePicker = document.getElementById('datepicker-custom2')
      }
      datePicker.focus()
    },
    add () {
      let obj = this.default()
      this.rows.push(obj)
    },
    edit (item) {
      item.editable = true
    },
    submit (item) {
      item.editable = false
    },
    remove (item) {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการลบข้อมูลใช่หรือไม่?</span>',
        text: 'ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteUserById(item.id)
          Vue.swal({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
            icon: 'success',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 2000
          }).then(() => {
            let index = this.rows.indexOf(item)
            this.rows.splice(index, 1)
          })
        }
      })
    },
    async deleteUserById (userId) {
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/clinic/${this.id}/user/${userId}/delete/`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
        })
        .catch((error) => {
          this.SwalError(error.message, 'ลบข้อมูลไม่สำเร็จ')
        })
    },
    resetPassword (dataProps) {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการที่จะรีเซ็ตรหัสพนักงานท่านนี้ใช่หรือไม่?</span>',
        text: 'ข้อมูลที่ถูกรีเซ็ตจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนรีเซ็ตข้อมูล',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.apiResetPassword(dataProps.id)
        }
      })
    },
    async apiResetPassword (userId) {
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/clinic/${this.id}/user/${userId}/resetPassword/`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">รีรหัสผ่านสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            })
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'รีรหัสผ่านไม่สำเร็จ')
        })
    },
    onPagination (page) {
      this.currentPage = page
    },
    openModal () {
      this.showMap = true
    },
    clearAutoName () {
      this.amphoeName = null
      this.districtName = null
      this.provinceName = null
      this.showMap = false
    },
    changeTabActive (tab) {
      let temp = {
        tab1: null,
        tab2: null,
        tab3: null
      }
      if (tab === 1 && this.tabisActive.tab1 === false) {
        temp.tab1 = true
        temp.tab2 = false
        temp.tab3 = false
      } else if (tab === 2 && this.tabisActive.tab2 === false) {
        temp.tab1 = false
        temp.tab2 = true
        temp.tab3 = false
      } else if (tab === 3 && this.tabisActive.tab3 === false) {
        temp.tab1 = false
        temp.tab2 = false
        temp.tab3 = true
      }
      this.tabisActive = temp
    },
    async addClinicTab1 () {
      this.allChecked.all = [
        ...this.checkedBox1,
        ...this.checkedBox2,
        ...this.checkedBox3,
        ...this.checkedBox4,
        ...this.checkedBox5,
        ...this.checkedBox6,
        ...this.checkedBox7
      ]
      let apiObject = null
      let temp = {
        no: this.UserManage.tag || null,
        name: this.UserManage.Cname?.trim() || null,
        name_eng: this.UserManage.CEng?.toString().trim().replace(/ /g, '.') || null,
        contract_name: this.UserManage.personName?.trim() || null,
        juristic_no: this.UserManage.nitiNo || null,
        tel: this.UserManage.Phone || null,
        user_amount: this.UserManage.peopleNo?.replace(/,/g, '') || null,
        contract_start: this.UserManage.Dstart ? new Date(moment(this.UserManage.Dstart, 'DD/MM/YYYY')) : null,
        contract_end: this.UserManage.Dend ? new Date(moment(this.UserManage.Dend, 'DD/MM/YYYY')) : null,
        lattitude: this.receiveValueLat || null,
        longtitude: this.receiveValueLng || null,
        province_id: this.UserManage.province?.code || null,
        district_id: this.UserManage.amphoe?.code || null,
        sd_id: this.UserManage.district?.code || null,
        license_no: this.UserManage.CpermissionNo || null,
        address: this.UserManage.Caddress?.trim() || null,
        start_time: this.UserManage.start_time,
        end_time: this.UserManage.end_time,
        social: this.UserManage.social,
        pay_off: this.UserManage.pay_off,
        social_security: this.UserManage.social_security,
        social_security_date: this.UserManage.social_security_date,
        confirm_email: this.UserManage.confirm_email,
        fix_pay: this.UserManage.fix_pay,
        amount: this.UserManage.amount
      }
      if (this.mode === 'add') {
        apiObject = {
          method: 'post',
          url: `${configAPI.MA_PORT}/api/clinic`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: temp
        }
      } else {
        apiObject = {
          method: 'put',
          url: `${configAPI.MA_PORT}/api/clinic/${this.id}`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: temp
        }
      }
      await axios.request(apiObject)
        .then(async (response) => {
          if (response.data.status) {
            await this.addClinicTab2(response.data.data.id)
            let idSave = null
            let userIdSave = null
            if (this.mode === 'add') {
              idSave = response.data.data.id
              userIdSave = response.data.data.user_id
            } else {
              idSave = this.id || null
              userIdSave = this.rows.find(el => (!el.emp_id && (el.variable3 === 'SuperAdmin' || el.variable3 === 'CEO')))?.id || null
            }

            if (this.clinicImage.file) {
              await this.uploadImageAddManage(idSave)
            }
            await this.addClinicTab3(idSave, userIdSave)
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(async () => {
              router.push('/Manage')
            })
          } else {
            this.SwalError(response.data.data, this.mode === 'edit' ? 'แก้ไขข้อมูลไม่สำเร็จ' : 'บันทึกข้อมูลไม่สำเร็จ')
          }
        })
        .catch((error) => {
          this.SwalError(error.message, this.mode === 'edit' ? 'แก้ไขข้อมูลไม่สำเร็จ' : 'บันทึกข้อมูลไม่สำเร็จ')
        })
    },
    async uploadImageAddManage (ID) {
      let data = new FormData()
      data.append('file', this.clinicImage.file)
      let uploadImgProfileAPI = {
        method: 'post',
        url: `${configAPI.MA_PORT}/api/clinic/${ID}/upload/img`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      await axios.request(uploadImgProfileAPI)
        .then((response) => {})
        .catch((error) => {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาดในการอัปโหลดรูปภาพ</span>',
            html: '<span>' + error + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async editClinicTab2 () {
      if (this.userAED.addList.length > 0) {
        await this.addUserList()
      }
      if (this.userAED.updateList.length > 0) {
        await this.updateUserList()
      }
      if (this.userAED.deleteList.length > 0) {
        await this.deleteUserList()
      }
    },
    async addClinicTab2 (id) {
      if (this.mode === 'add') {
        return
      }
      let apiObjectTab2 = {
        method: this.mode === 'add' ? 'post' : 'put',
        url: this.mode === 'add'
          ? `${configAPI.MA_PORT}/api/clinic/${id}/user/`
          : `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/user/${this.id}`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: this.mode === 'add'
          ? {
            rol_id: 'f5fe4850-2b98-11ee-b183-55168d97cc79',
            us_id: 'f9581f84-e150-4ee3-a4cb-c2b9208ee673',
            ut_id: '5c66dfdb-a9bc-4867-baa7-507175d2fc01',
            emp_id: null,
            username: this.rows[0].variable1,
            password: 'sc@1234',
            email: null
          }
          : {
            rol_id: 'f5fe4850-2b98-11ee-b183-55168d97cc79',
            us_id: 'f9581f84-e150-4ee3-a4cb-c2b9208ee673',
            ut_id: '5c66dfdb-a9bc-4867-baa7-507175d2fc01',
            emp_id: null,
            username: this.rows[0].variable1,
            email: null
          }
      }
      await axios.request(apiObjectTab2)
        .then(async (response) => {
        })
        .catch((error) => {
          this.SwalError(error.message, this.mode === 'edit' ? 'แก้ไขข้อมูลไม่สำเร็จ' : 'บันทึกข้อมูลไม่สำเร็จ')
        })
    },
    async addClinicTab3 (id, userId) {
      if (!id || !userId) {
        Vue.swal.fire({
          title: `<span class="" style="font-size: 25px; color: black; text-align: center">ไม่พบข้อมูลในการบันทึกข้อมูล</span>`,
          icon: 'warning',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1'
          }
        })
        return
      }
      let menuIdList = []
      for (let el of this.allChecked?.all) {
        if (el.status) {
          menuIdList.push(el.id)
        }
      }
      let adminMenuList = [{ usr_id: userId, menu_id_list: menuIdList }]
      let apiObjectTab3 = {
        method: 'post',
        url: `${configAPI.MA_PORT}/api/clinic/${id}/permissionClinic`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          menu_id_list: menuIdList,
          module_id_list: []
        }
      }
      await axios.request(apiObjectTab3)
        .then(async (response) => {
          if (response.data.status) {
            await axios.request({
              method: 'post',
              url: `${configAPI.MA_PORT}/api/clinic/${id}/permissionUser`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: adminMenuList
            })
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.mode === 'edit' ? 'แก้ไขข้อมูลไม่สำเร็จ' : 'บันทึกข้อมูลไม่สำเร็จ'}</span>`,
            html: '<span>' + error.message + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    handleClick0 (status) {
      /* console.log('UserManage', this.UserManage)
      console.log('receiveValueLat', this.receiveValueLat, this.receiveValueLng)
      console.log('this.rows', this.rows)
      console.log('checkbox',
        this.checkedBox1,
        this.checkedBox2,
        this.checkedBox3,
        this.checkedBox4,
        this.checkedBox5,
        this.checkedBox6,
        this.checkedBox7
      ) */
      if (status) {
        Vue.swal.fire({
          title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.$route.params.setPage !== 'edit' ? 'คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่?' : 'คุณมั่นใจว่าคุณจะแก้ไขข้อมูลใช่หรือไม่?'}</span>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            const errorList = this.checkError()
            if (errorList.length > 0) {
              let errMessage = ''
              for (let e = 0; e < errorList.length; e++) {
                errMessage += '<br/>' + errorList[e]
              }
              Vue.swal.fire({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลไม่สำเร็จ</span>',
                html: errMessage,
                icon: 'error',
                showConfirmButton: true,
                confirmButtonText: '<b-button style="font-size: 14px; text-align: center">Ok</b-button>',
                confirmButtonColor: '#089bab',
                customClass: {
                  confirmButton: 'custom-confirm-button-class mx-1'
                }
              })
            } else {
              await this.addClinicTab1()
            }
          }
        })
      } else {
        if (this.mode === 'view') {
          router.go(-1)
        } else {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่?</span>',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
            confirmButtonColor: '#089bab',
            cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
            cancelButtonColor: '#d33',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1',
              cancelButton: 'custom-confirm-button-class mx-1'
            }
          }).then((result) => {
            if (result.isConfirmed) {
              router.go(-1)
            }
          })
        }
      }
    },
    handleClick (mode, dataProps) {
      this.$store.dispatch('updateAddUserManage', { cln_id: this.id })
      this.$store.dispatch('updateDataTab2', 'tab2')
      if (mode === null) {
        if (parseInt(this.rows.length) >= parseInt(this.userTableAmount.total || 0)) {
          this.SwalError('เนื่องจากจำนวนผู้ใช้ครบตามสัญญาแล้ว', 'ไม่สามารถเพิ่มข้อมูลพนักงานได้')
          this.$store.dispatch('updateDataTab2', null)
          return
        }
        router.push(`/AddUserManage/${this.id}`)
      } else {
        router.push(`/AddUserManage/${mode}/${this.id}/${dataProps.id}`) // employee_id
      }
    },
    openModalImportFile () {
      if (parseInt(this.rows.length) === parseInt(this.userTableAmount.total || 0)) {
        this.SwalError('เนื่องจากจำนวนผู้ใช้ครบตามสัญญาแล้ว', 'ไม่สามารถเพิ่มข้อมูลพนักงานได้')
        return
      }
      this.showModalUpload = true
    },
    async addUserList () {
      for (let item of this.userAED.addList) {
        await axios.request({
          method: 'post',
          url: `${configAPI.MA_PORT}/api/clinic/${this.id}/user/`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            rol_id: '17c725d0-2994-11ee-9a93-ff4859fd1258',
            us_id: item.useStatus === 'ใช้งาน'
              ? 'f9581f84-e150-4ee3-a4cb-c2b9208ee673'
              : '1050f018-930e-4fea-ae8d-88e4b4c5c7e6',
            ut_id: '7bde4d32-981c-49f8-9fc0-4a689765416f',
            emp_id: item.emp_id,
            username: item.username,
            password: 'sc@1234',
            email: item.email ? item.email?.trim() : null
          }
        })
          .then(async (response) => {
          })
          .catch((error) => {
            this.SwalError(error.message, 'บันทึกข้อมูลไม่สำเร็จ')
          })
      }
    },
    async updateUserList () {
      for (let item of this.userAED.updateList) {
        await axios.request({
          method: 'put',
          url: `${configAPI.MA_PORT}/api/clinic/${this.id}/user/${item.id}`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            rol_id: '17c725d0-2994-11ee-9a93-ff4859fd1258',
            us_id: item.useStatus === 'ใช้งาน'
              ? 'f9581f84-e150-4ee3-a4cb-c2b9208ee673'
              : '1050f018-930e-4fea-ae8d-88e4b4c5c7e6',
            ut_id: '7bde4d32-981c-49f8-9fc0-4a689765416f',
            emp_id: item.emp_id,
            username: item.username,
            // password: 'sc@1234',
            email: item.email ? item.email?.trim() : null
          }
        })
          .then(async (response) => {
          })
          .catch((error) => {
            this.SwalError(error.message, 'แก้ไขข้อมูลไม่สำเร็จ')
          })
      }
    },
    async deleteUserList () {
      for (let item of this.userAED.deleteList) {
        await axios.request({
          method: 'post',
          url: `${configAPI.MA_PORT}/api/clinic/${this.id}/user/${item.id}/delete/`,
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(async (response) => {
          })
          .catch((error) => {
            this.SwalError(error.message, 'ลบข้อมูลไม่สำเร็จ')
          })
      }
    },
    async uploadUser () {
      if (!this.userImport.file) {
        return
      }
      let data = new FormData()
      data.append('file', this.userImport.file)
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/clinic/${this.id}/user/import`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
        .then(async (response) => {
          if (response.data.data?.length > 0) {
            this.rows2 = response.data.data?.map((el, index) => {
              return {
                index: index + 1,
                variable1: el.username || '-',
                variable2: el.firstname || '-',
                variable3: el.email || '-',
                variable4: el.status ? 'สำเร็จ' : 'ไม่สำเร็จ',
                position: el.role || '-',
                status: el.status,
                error: el.errorList || '-'
              }
            })
          }
          this.showModal = true
          this.showModalUpload = false
        })
        .catch((error) => {
          this.SwalError(error.message, 'นำเข้าข้อมูลไม่สำเร็จ')
        })
    },
    async closeModalShowStatus () {
      this.showModal = false
      await this.getUserOfClinic()
      // window.location.reload()
    },
    checkUploadUser () {
      if (this.userImport.fileName !== null) {
        this.showModalUpload = false
        return
      }
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการยกเลิก การอัปโหลดข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.showModalUpload = false
        }
      })
    },
    handleImageUpload (event) {
      const file = event.target.files[0]
      document.getElementById(file)
      this.userImport.file = file
      this.userImport.fileName = file.name
    },
    handleImageUpload2 (event) {
      const file2 = event.target.files[0]
      const reader2 = new FileReader()
      reader2.readAsDataURL(file2)
      reader2.onload = () => {
        this.imageUrl2 = reader2.result
      }
    },
    handleClick1 () {
      router.push('/Manage')
    },
    isNumberComma1 (event, variable) {
      if (variable === '2') {
        this.UserManage.peopleNo = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
        event = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      }
    },
    formatContact (e) {
      let keyCode = (e.keyCode ? e.keyCode : e.which)
      if ((keyCode < 48 || keyCode > 57) || (!this.UserManage.peopleNo && keyCode === 48)) {
        e.preventDefault()
      }
    },
    isNumberCheck (keyPressed, id) {
      // let indexData = this.rows1.findIndex((item) => { return item.id === id })
      let useData = keyPressed.target.value

      let keyCode = (keyPressed.keyCode ? keyPressed.keyCode : keyPressed.which)

      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || useData.indexOf('.') !== -1)) {
        keyPressed.preventDefault()
      }

      if (useData !== null && useData.indexOf('.') > -1 && (useData.split('.')[1].length > 1)) {
        keyPressed.preventDefault()
      }

      if ((useData === null || useData === '') && keyCode === 46) {
        keyPressed.preventDefault()
      }
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    showLocation () {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${'คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่?'}</span>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (!this.$store.state.LatLngData.lat) {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลไม่สำเร็จ</span>',
              text: 'กรุณาเลือกตำแหน่งก่อนกดบันทึก',
              icon: 'warning',
              showConfirmButton: true,
              confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
              confirmButtonColor: '#089bab',
              customClass: {
                confirmButton: 'custom-confirm-button-class mx-1'
              }
            })
          } else {
            this.receiveValueLat = this.$store.state.LatLngData.lat
            this.receiveValueLng = this.$store.state.LatLngData.lng
            this.receiveAddress = this.$store.state.address
            let tempAddress = this.$store.state.addressComponent
            let TempAddressArr = []
            for (let item of tempAddress) {
              if (item.types.includes('administrative_area_level_1')) {
                let formatlongName = item.long_name.replace('จังหวัด', '').replace('จ.', '')
                let provinceTemp = this.provincesAll.find(el => el.label === formatlongName?.trim())
                TempAddressArr.push({ province: provinceTemp })
              } else if (item.types.includes('administrative_area_level_2') || item.types.includes('sublocality_level_1')) {
                let formatlongName = item.long_name.replace('อำเภอ', '').replace('เขต', '').replace('อ.', '')
                let amphoeTemp = this.amphoesAll.find(el => el.label === formatlongName?.trim())
                TempAddressArr.push({ amphoe: amphoeTemp })
              } else if (item.types.includes('administrative_area_level_3') || item.types.includes('sublocality_level_2')) {
                let formatlongName = item.long_name.replace('ตำบล', '').replace('แขวง', '').replace('ต.', '')
                let districtTemp = this.districtsAll.find(el => el.label === formatlongName?.trim())
                TempAddressArr.push({ district: districtTemp })
              }
            }
            let temp = {
              province: null,
              amphoe: null,
              district: null
            }
            for (let item of TempAddressArr) {
              if (item.province) {
                temp.province = item.province
              } else if (item.amphoe) {
                temp.amphoe = item.amphoe
              } else if (item.district) {
                temp.district = item.district
              }
            }
            let temp2 = JSON.parse(JSON.stringify(temp))
            this.districtName = temp2.district
            this.amphoeName = temp2.amphoe
            this.provinceName = temp2.province
            this.UserManage.province = temp2.province
            this.showMap = false
          }
        }
      })
    },
    getInputLatLng () {
      let temp = {
        receiveValueLat: this.receiveValueLat ? this.receiveValueLat : NaN,
        receiveValueLng: this.receiveValueLng ? this.receiveValueLng : NaN
      }
      // console.log('temp :>> ', temp)
      this.$store.dispatch('updategetLatLngData', temp)

      this.showMap = true
    },
    checktypeNumber (e) {
      let keyCode = (e.keyCode ? e.keyCode : e.which)
      if (this.supData.con_contact.length >= 12 || (keyCode < 48 || keyCode > 57)) {
        e.preventDefault()
        this.supData.con_contact = this.supData.con_contact.slice(0, 12)
      }
    },
    checkError (tab) {
      let error = []
      /* if (value > 15 || value === 0) {
        error.push('กรุณากรอกจำนวนผู้ใช้ตามสัญญาใหม่อีกครั้ง ไม่เป็น 0 และสูงสุด 10 คน')
      } */
      const regex = /[ก-๙]/
      const regexNumber = /^[0-9]+$/
      const regex1 = /[A-Za-z]/
      if (!this.UserManage.Cname?.trim()) {
        error.push('กรุณากรอกชื่อคลินิก')
      } else if (regex1.test(this.UserManage.Cname)) {
        error.push('ชื่อคลินิก ต้องเป็นภาษาไทยเท่านั้น')
      }
      if (!this.UserManage.CEng?.trim()) {
        error.push('กรุณากรอกชื่อคลินิก(ภาษาอังกฤษ)')
      } else if (regex.test(this.UserManage.CEng)) {
        error.push('ชื่อคลินิก(ภาษาอังกฤษ) ต้องเป็นภาษาอังกฤษเท่านั้น')
      }
      if (this.UserManage.CpermissionNo && !regexNumber.test(this.UserManage.CpermissionNo)) {
        error.push('เลขที่ใบอนุญาตคลินิก ต้องเป็นตัวเลขเท่านั้น')
      }
      if (this.UserManage.nitiNo && !regexNumber.test(this.UserManage.nitiNo)) {
        error.push('เลขนิติบุคคล ต้องเป็นตัวเลขเท่านั้น')
      }
      if (!this.UserManage.personName?.trim()) {
        error.push('กรุณากรอกชื่อผู้ติดต่อ')
      }
      if (!this.UserManage.Phone) {
        error.push('กรุณากรอกเบอร์โทรติดต่อ')
      } else if (this.UserManage.Phone && this.UserManage.Phone?.length !== 10) {
        error.push('เบอร์โทรติดต่อต้องมี 10 หลัก')
      } else if (!regexNumber.test(this.UserManage.Phone)) {
        error.push('เบอร์โทรติดต่อ ต้องเป็นตัวเลขเท่านั้น')
      }
      if (!this.UserManage.peopleNo) {
        error.push('กรุณากรอกจำนวนผู้ใช้ตามสัญญา')
      } else if (!regexNumber.test(this.UserManage.peopleNo)) {
        error.push('จำนวนผู้ใช้ตามสัญญา ต้องเป็นตัวเลขเท่านั้น')
      }
      if (!this.UserManage.Dstart) {
        error.push('กรุณาเลือกวันที่เริ่มสัญญา')
      }
      if (!this.UserManage.Dend) {
        error.push('กรุณาเลือกวันที่สิ้นสุดสัญญา')
      }
      return error
    },
    getCurrentDate (dataInput) {
      if (dataInput === undefined) {
        return
      }
      const today = new Date(dataInput)
      let month = (today.getMonth() + 1).toString()
      let day = today.getDate().toString()

      // แก้ไขรูปแบบเดือนและวันให้เป็น 'MM' และ 'DD'
      if (month.length === 1) {
        month = '0' + month
      }
      if (day.length === 1) {
        day = '0' + day
      }
      return `${today.getFullYear()}-${month}-${day}`
    },
    resetEndDate (e) {
      this.supData.end_date = null
    },
    toggleCheckboxes () {
      const checkboxes1 = document.getElementsByClassName('checkbox1')
      for (let i = 0; i < checkboxes1.length; i++) {
        checkboxes1[i].checked = this.allChecked.Check1
        if (this.allChecked.Check1 === true) {
          this.checkedBox1[i].status = true
        } else if (this.allChecked.Check1 === false) {
          this.checkedBox1[i].status = false
        }
      }
    },
    toggleCheckboxes2 () {
      const checkboxes2 = document.getElementsByClassName('checkbox2')
      for (let i = 0; i < checkboxes2.length; i++) {
        checkboxes2[i].checked = this.allChecked.Check2
        if (this.allChecked.Check2 === true) {
          this.checkedBox2[i].status = true
        } else if (this.allChecked.Check2 === false) {
          this.checkedBox2[i].status = false
        }
      }
    },
    toggleCheckboxes3 () {
      const checkboxes3 = document.getElementsByClassName('checkbox3')
      for (let i = 0; i < checkboxes3.length; i++) {
        checkboxes3[i].checked = this.allChecked.Check3
        if (this.allChecked.Check3 === true) {
          this.checkedBox3[i].status = true
        } else if (this.allChecked.Check3 === false) {
          this.checkedBox3[i].status = false
        }
      }
    },
    toggleCheckboxes4 () {
      const checkboxes4 = document.getElementsByClassName('checkbox4')
      for (let i = 0; i < checkboxes4.length; i++) {
        checkboxes4[i].checked = this.allChecked.Check4
        if (this.allChecked.Check4 === true) {
          this.checkedBox4[i].status = true
        } else if (this.allChecked.Check4 === false) {
          this.checkedBox4[i].status = false
        }
        // console.log('check4 :>> ', this.checkedBox4[i].status)
      }
    },
    toggleCheckboxes5 () {
      const checkboxes5 = document.getElementsByClassName('checkbox5')
      for (let i = 0; i < checkboxes5.length; i++) {
        checkboxes5[i].checked = this.allChecked.Check5
        if (this.allChecked.Check5 === true) {
          this.checkedBox5[i].status = true
        } else if (this.allChecked.Check5 === false) {
          this.checkedBox5[i].status = false
        }
        // console.log('check5 :>> ', this.checkedBox5[i].status)
      }
    },
    toggleCheckboxes6 () {
      const checkboxes6 = document.getElementsByClassName('checkbox6')
      for (let i = 0; i < checkboxes6.length; i++) {
        checkboxes6[i].checked = this.allChecked.Check6
        if (this.allChecked.Check6 === true) {
          this.checkedBox6[i].status = true
        } else if (this.allChecked.Check6 === false) {
          this.checkedBox6[i].status = false
        }
      }
    },
    toggleCheckboxes7 () {
      const checkboxes7 = document.getElementsByClassName('checkbox7')
      for (let i = 0; i < checkboxes7.length; i++) {
        checkboxes7[i].checked = this.allChecked.Check7
        if (this.allChecked.Check7 === true) {
          this.checkedBox7[i].status = true
        } else if (this.allChecked.Check7 === false) {
          this.checkedBox7[i].status = false
        }
      }
    },
    handleSwitchChange () {
      const allSwitchesOn1 = this.checkedBox1.every((item) => item.status)
      // const allSwitchesOff1 = this.checkedBox1.some((item) => !item.status)
      if (allSwitchesOn1 === true) {
        this.allChecked.Check1 = true
      } else {
        this.allChecked.Check1 = false
      }

      const allSwitchesOn2 = this.checkedBox2.every((item) => item.status)
      // const allSwitchesOff2 = this.checkedBox2.every((item) => !item.status)
      if (allSwitchesOn2 === true) {
        this.allChecked.Check2 = true
      } else {
        this.allChecked.Check2 = false
      }

      const allSwitchesOn3 = this.checkedBox3.every((item) => item.status)
      // const allSwitchesOff3 = this.checkedBox3.every((item) => !item.status)
      if (allSwitchesOn3 === true) {
        this.allChecked.Check3 = true
      } else {
        this.allChecked.Check3 = false
      }

      const allSwitchesOn4 = this.checkedBox4.every((item) => item.status)
      // const allSwitchesOff4 = this.checkedBox4.every((item) => !item.status)
      if (allSwitchesOn4 === true) {
        this.allChecked.Check4 = true
      } else {
        this.allChecked.Check4 = false
      }

      const allSwitchesOn5 = this.checkedBox5.every((item) => item.status)
      // const allSwitchesOff5 = this.checkedBox5.every((item) => !item.status)
      if (allSwitchesOn5 === true) {
        this.allChecked.Check5 = true
      } else {
        this.allChecked.Check5 = false
      }

      const allSwitchesOn6 = this.checkedBox6.every((item) => item.status)
      // const allSwitchesOff6 = this.checkedBox6.every((item) => !item.status)
      if (allSwitchesOn6 === true) {
        this.allChecked.Check6 = true
      } else {
        this.allChecked.Check6 = false
      }

      const allSwitchesOn7 = this.checkedBox7.every((item) => item.status)
      // const allSwitchesOff7 = this.checkedBox7.every((item) => !item.status)
      if (allSwitchesOn7 === true) {
        this.allChecked.Check7 = true
      } else {
        this.allChecked.Check7 = false
      }
    },
    checkNumberOnly (tel) {
      let regex = /^[0-9]+$/
      let have = regex.test(tel)
      return have
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    closeModal () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะยกเลิกใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.showMap = false
        }
      })
    }
  }
}
</script>

<style scoped>
.response-button-download {
  margin-left: .25rem;
  margin-bottom: 0 !important;
}
/* .btn-add {
    margin: 0 !important;
  } */
.response-button-header {
 margin: auto !important;
}
.flex-response-header {
  display: flex;
  /* gap: .5rem; */
}
.gap-response {
  gap: 1rem;
}
.btn-import {
    margin-right: 0 !important;
}
.textGoogleMap {
  visibility: visible !important;
  display: block;
}
.GapBr {
  visibility: visible !important;
  display: block
}
.NewLines {
  visibility: hidden !important;
  display: none;
}
.NewLines2 {
  visibility: hidden !important;
  display: none;
}
.NewLines3 {
  visibility: hidden !important;
  display: none;
}
@media (max-width: 1000px) {
  .textGoogleMap {
    visibility: hidden !important;
    display: none;
  }
  .btn-import {
    margin-right: 0 !important;
  }
}
@media (max-width: 991px) {
  #map {
    height: 400px !important;
  }
}
@media (max-width: 767px) {
  .MCDheader {
    white-space: nowrap !important;
  }
}
@media (max-width: 676px) {
  .response-button-download {
    margin-left: 0;
    margin-bottom: 0 !important;
  }
  .flex-response-header {
    display: flex;
    gap: .5rem;
  }
  .btn-import {
    margin-right: 0 !important;
    width: 100% !important;
  }
  .gap-response {
    gap: 0;
  }
  .button-response-header {
    width: 100% !important;
    margin-left: 1.2rem !important;
    margin-top: 0 !important;
    padding-right: 2rem !important;
  }
  .flex-response-header {
    display: flex;
    gap: .5rem;
    flex-direction: column;
    width: 100%;
  }
}
@media (max-width: 602.5px) {
  .headButton {
    width: 100% !important;
  }
}
@media (max-width: 575px) {
  .textGoogleMap {
  visibility: visible !important;
  display: block
  }
  .GapBr {
  visibility: hidden !important;
  display: none
  }
  .beforeMapButton {
    margin-bottom: 0px !important;
  }
  .headSwitch {
    justify-content: start !important;
    display: flex !important;
  }
}
@media (max-width: 569px) {
  /* .t-Button {
    margin-left: 0 !important;
  } */
  .btn-import {
    margin-right: 0 !important;
  }
  .button-response-header {
    width: 100% !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
  }
  .flex-response-header {
    display: flex;
    gap: .5rem;
    flex-direction: column;
    width: 100%;
  }
}
@media (max-width: 420px) {
  .btn-import {
    margin-right: 0 !important;
  }
  .response-button-header {
    gap: .5rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .btn-add {
    margin: 0 !important;
  }
  .btn-import {
    margin: 0.25rem 0 !important;
    padding: 0;
  }
  .response-button-download {
    width: 100% !important;
  }
  .button-response-header {
    width: 100% !important;
  }
}
@media (max-width: 387.5px) {
  .NewLines {
    visibility: visible !important;
    display: block;
  }
}
@media (max-width: 344.5px) {
  .NewLines3 {
    visibility: visible !important;
    display: block;
  }
}
@media (max-width: 326.5px) {
  .NewLines2 {
    visibility: visible !important;
    display: block;
  }
}
</style>
