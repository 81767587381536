<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">อนุมัติแก้ไขหัตถการ</span>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" md="7" lg="5" class="mb-3">
                  <b-row>
                    <b-col sm="12" md class="mb-3">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input v-model="searchData" type="text" class="form-control" id="exampleInputEmail1"
                            @keydown="enterSearch" placeholder="Search" style="width: 100%;">
                            <a class="search-link" @click="getAllSaleOrder()"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                      <span class="mx-2 font-size-14 text-primary">
                        {{ dataList?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > dataList?.length) ? dataList?.length : (perPage * currentPage) ) + ' of ' + dataListLength : '0 of ' + dataListLength }} รายการ
                      </span></b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <b-col md="12" >
              <div class="table-responsive">
                <b-table bordered :items="dataList" :fields="columnsMain"
                  :current-page="currentPage"
                  :per-page="perPage"
                  @pagination="onPagination"
                  :tbody-tr-class="rowClass" thead-class="table-header-color"
                >
                  <!-- <template v-slot:cell(variable1)="data">
                    <span>{{ ((currentPage - 1) * 10) + data.index + 1 }}</span>
                  </template> -->
                  <template v-slot:cell(cus_name)="data">
                    <span>{{ `${data.item.cus_firstname} ${data.item.cus_lastname} (${data.item.cus_nickname})` }}</span>
                  </template>
                  <template v-slot:cell(cus_tel)="data">
                    <a :href="'tel:' + formatTel(data.item.cus_tel)"><span style="text-decoration: underline;">{{ formatTel(data.item.cus_tel) }}</span></a>
                  </template>
                  <template v-slot:cell(cus_latest_date)="data">
                    <span v-if="!data.item.currect_hc_date">-</span>
                    <a v-else  @click="viewOPD(data.item.currect_hc_id)"><span class="text-primary" style="text-decoration: underline; cursor: pointer;">{{ formatDate(data.item.currect_hc_date, 'DD/MM/YYYY') }}</span></a>
                  </template>
                  <template v-slot:cell(management)="data">
                    <div style="display: flex; justify-content: center; gap: .5rem;">
                      <b-button  type="button" variant="primary-custom" class="w-100 btn-min-size">
                        {{ data.item.so_list?.length || 0 }} รายการ
                      </b-button>
                      <b-button variant="primary-custom mr-1" size="sm" class="px-2 py-0" style="border-radius: 10px;"
                        :disabled="data.item.so_list?.length === 0"
                        @click="data.toggleDetails(), data.item.toggle = !data.item.toggle, openCusFunc(data.item)"
                      >
                        <i style="font-size: 1.5rem;" v-if="data.item.toggle" class="ri-arrow-down-s-fill m-0"/>
                        <i style="font-size: 1.5rem;" v-else class="ri-arrow-up-s-fill m-0"/>
                      </b-button>
                      <b-button variant="warning mr-1" size="sm" class="px-2 py-0" style="border-radius: 10px;"
                          :disabled="data.item.so_list?.length === 0"
                          @click="viewOPD(data.item.cus_id, 'all')"
                        >
                        <i style="font-size: 1.5rem;" class="ri-history-fill m-0"/>
                      </b-button>
                      <b-button variant="success mr-1" size="sm" class="px-2 py-0" style="border-radius: 10px; border-color: green !important;"
                        :disabled="data.item.so_list?.length === 0"
                        @click="openAddModal(data.item)"
                      >
                        <i style="font-size: 1.5rem;" class="ri-add-line m-0"/>
                      </b-button>
                    </div>
                  </template>
                  <template #row-details="data">
                    <b-col md="12" class="table-responsive sub-table-wrapper-so">
                      <b-table hover :items="data.item.so_list" :fields="columnsSub"
                        :current-page="currentPageSub"
                        :per-page="1000000"
                        @pagination="onPaginationSub"
                        class="sub-table"
                        :tbody-tr-class="rowClassSub"
                      >
                        <template v-slot:cell(no)="data">
                          <span>{{ data.index + 1 }}</span>
                        </template>
                        <template v-slot:cell(saleorder_no)="data">
                          <span>{{ data.item.so_no }}</span>
                        </template>
                        <template v-slot:cell(quo_no)="data">
                          <a :href="`/Quotation/soAdmin/mgt/view/${data.item.so_quo_id}`"><u><span>{{ data.item.quo_no }}</span></u></a>
                          <!-- <span>{{ data.item.quo_no }}</span> -->
                        </template>
                        <template v-slot:cell(saleorder_date)="data">
                          <span>{{ formatDate(data.item.so_date, 'DD/MM/YYYY') }}</span>
                        </template>
                        <template v-slot:cell(pro_ser_name)="data">
                          <span>{{ `${data.item.prodSer_name}` }}</span>
                        </template>
                        <template v-slot:cell(balance)="data">
                          <span v-if="!data.item.isEdit">{{ `${data.item.sum_quantity}/${data.item.soi_amount} ${data.item?.prodUnit_name || 'ครั้ง'}` }}</span>
                          <div v-else>
                            <b-row>
                              <b-col cols="4" style="display: flex; align-items:center; justify-content: flex-end;">
                                <span>{{ `${data.item.sum_quantity} / ` }}</span>
                              </b-col>
                              <b-col cols="8">
                                <CurrencyInputCom v-model="data.item.soi_amount" :options="optionCurrency" :key="data.index" :is-less="true" :value-less="parseInt(data.item.sum_quantity)"/>
                              </b-col>
                            </b-row>
                          </div>
                        </template>
                        <template v-slot:cell(management)="data">
                          <div v-if="!data.item.isEdit" style="display: flex; justify-content: center; gap: .5rem;">
                            <b-button
                              variant=" iq-bg-pencil-custom btn-pencil-border mx-1" size="sm"
                              @click="editSoItem(data.item)">
                              <i class="ri-pencil-fill m-0"></i>
                            </b-button>
                            <b-button
                              variant=" iq-bg-bin-custom btn-bin-border mx-1" size="sm"
                              @click="openRemarkDeleteModal(data.item)">
                              <i class="ri-delete-bin-line m-0"></i>
                            </b-button>
                          </div>
                          <div style="display: flex; justify-content: center; gap: .5rem;" v-else>
                            <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submitEdit(data.item)">บันทึก</b-button>
                            <b-button variant=" iq-bg-danger mr-1 mb-1" size="sm" @click="redoEdit(data.item)">ยกเลิก</b-button>
                          </div>
                        </template>
                      </b-table>
                    </b-col>
                  </template>
                </b-table>
              </div>
            </b-col>
            <b-pagination v-model="currentPage" :total-rows="dataList?.length" :per-page="perPage" align="center" />
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <!-- modal add item -->
    <b-modal v-model="showAddModal" title="เพิ่มรายการ สินค้า/บริการ" :size="'xl'" @close.prevent="showAddModal = false"
      no-close-on-backdrop>
      <div>
        <b-row class="mx-1">
          <b-col cols="12" md="12" lg="4" class="mb-3">
            <b-form-group label-for="exampleFormControlSelect1" label="">
              <label>หมายเลขใบสั่งซื้อ <span style="color: red;">*</span></label>
              <v-select v-model="modalAddData.so_id" :options="modalAddData.soDD"></v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mx-4">
          <span style="font-size: 25px; color: rgb(37, 37, 37);">ผลิตภัณฑ์</span>
        </b-row>
        <b-row class="mx-1">
          <b-col cols="12">
            <div class="fx-gp-quo d-flex my-3" style="white-space: nowrap; gap: .5rem">
              <b-button @click="showModalProduct = true" variant="primary-custom" class="max-width-btn-quo-mgt flex-fill text-center btn-min-size w-100" style="text-align: center;"><i class="circle-plus_ct"/>เพิ่มสินค้า</b-button>
              <b-button @click="showModalService = true" variant=" bg-success-custom" class="max-width-btn-quo-mgt flex-fill text-center btn-min-size w-100" style="text-align: center;"><i class="circle-plus_ct"/>เพิ่มบริการ</b-button>
            </div>
          </b-col>
        </b-row>
        <b-row class="mx-1">
          <b-col cols="12">
            <div class="table-responsive">
              <b-table bordered hover :items="rowSelected.data" :fields="columnsSelected"
                :current-page="currentPageSelected"
                :per-page="perPageSelected"
                :tbody-tr-class="rowClass" thead-class="table-header-color"
              >
                <template v-for="column in columnsSelected" v-slot:[`cell(${column.key})`]="data">
                  <template v-if="column.key === 'no'">
                    <span :key="column.key">{{ ((currentPageSelected - 1) * perPageSelected) + data.index + 1  }}</span>
                  </template>
                  <template v-if="column.key === 'type_proser'">
                    <span :key="column.key">{{ data.item.type || '-' }}</span>
                  </template>
                  <template v-if="column.key === 'code_proser'">
                    <span :key="column.key">{{ data.item.no || '-' }}</span>
                  </template>
                  <template v-if="column.key === 'name_proser'">
                    <span :key="column.key">{{ data.item.name || data.item.st_name || '-' }}</span>
                  </template>
                  <template v-if="column.key === 'quantity'">
                      <CurrencyInputCom v-model="data.item.quantity" :options="optionCurrency" :key="data.item.type + column.key + data.index"/><!-- @change="calculateRow('quantity', data.item)" -->
                  </template>
                  <template v-if="column.key === 'mgt'">
                    <div style="display: flex; justify-content: center;" :key="column.key">
                      <b-button @click="deleteRow(data.item)" variant=" iq-bg-bin-custom btn-bin-border" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </div>
                  </template>
                </template>
              </b-table>
            </div>
          </b-col>
        </b-row>
        <b-row style="display: flex; justify-content: center;">
          <b-pagination
            v-model="currentPageSelected"
            :total-rows="rowSelected.data?.length"
            :per-page="perPageSelected"
            align="center"
          />
        </b-row>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="primary" @click="openRemarkAddModal">บันทึก</b-button>
        <b-button variant="danger" @click="cancelAddSoItem">ยกเลิก</b-button>
      </template>
    </b-modal>

    <!-- product component -->
    <b-modal v-model="showModalProduct" title="เลือกสินค้า" cancel-title="ยกเลิก" ok-title="เพิ่ม" @ok="onSubmitProductModal" @close="onCloseProductModal" size="xl" :no-close-on-esc="true" :no-close-on-backdrop="true">
      <productSelectModalSOAE ref="productSelectedCom" :mainProductSelected="rowSelected.data" :del-list="JSON.parse(JSON.stringify(delList))" :old-item="JSON.parse(JSON.stringify(modalAddData.oldProSer))"/>
      <template #modal-footer>
        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
          <b-button
            style="border-radius: 0.5rem; padding: 0 1.1rem !important;"
            variant="primary"
            class="btn-min-size"
            size="sm"
            @click="onSubmitProductModal"
          >
          <i class="save-data_ct"/>บันทึก
          </b-button>
          <b-button
            variant="danger"
            class="btn-min-size btn-cancel-border"
            style="border-radius: 0.5rem;"
            size="sm"
            @click="showModalProduct=false"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- service component -->
    <b-modal v-model="showModalService" title="เลือกบริการ" cancel-title="ยกเลิก" ok-title="เพิ่ม" @ok="onSubmitServiceModal" @close="onCloseServiceModal" size="xl" :no-close-on-esc="true" :no-close-on-backdrop="true">
      <serviceSelectModalSOAE ref="serviceSelectedCom" :mainServiceSelected="rowSelected.data" :del-list="JSON.parse(JSON.stringify(delList))" :old-item="JSON.parse(JSON.stringify(modalAddData.oldProSer))"/>
      <template #modal-footer>
        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
          <b-button
            style="border-radius: 0.5rem; padding: 0 1.1rem !important;"
            variant="primary"
            class="btn-min-size"
            size="sm"
            @click="onSubmitServiceModal"
          >
            <i class="save-data_ct"/>บันทึก
          </b-button>
          <b-button
            variant="danger"
            class="btn-min-size btn-cancel-border"
            style="border-radius: 0.5rem;"
            size="sm"
            @click="showModalService=false"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- remark Add modal -->
    <b-modal v-model="remarkAddModal" title="หมายเหตุ (เพิ่มรายการในใบสั่งซื้อ)" size='md' centered
      @close.prevent="remarkAddModal = false" no-close-on-backdrop>
      <template #modal-title>
        หมายเหตุ (เพิ่มรายการในใบสั่งซื้อ) <span style="color: red;">*</span>
      </template>
        <b-form-textarea type="text" v-model="remarkAdd" placeholder="กรอกหมายเหตุ"
          style="padding-right: 1rem; height: 100px; justify-items: flex-start;">
        </b-form-textarea>
      <template #modal-footer>
        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
          <b-button
            style="border-radius: 0.5rem; padding: 0 1.1rem !important;"
            variant="primary"
            class="btn-min-size"
            size="sm"
            @click="addSoItem"
          >
            <i class="save-data_ct"/>บันทึก
          </b-button>
          <b-button
            variant="danger"
            class="btn-min-size btn-cancel-border"
            style="border-radius: 0.5rem;"
            size="sm"
            @click="remarkAddModal = false"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- remark Edit modal -->
    <b-modal v-model="remarkEditModal" title="หมายเหตุ (แก้ไขรายการในใบสั่งซื้อ)" size='md' centered
      @close.prevent="remarkEditModal = false" no-close-on-backdrop>
      <template #modal-title>
        หมายเหตุ (แก้ไขรายการในใบสั่งซื้อ) <span style="color: red;">*</span>
      </template>
        <b-form-textarea type="text" v-model="remarkEdit" placeholder="กรอกหมายเหตุ"
          style="padding-right: 1rem; height: 100px; justify-items: flex-start;">
        </b-form-textarea>
      <template #modal-footer>
        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
          <b-button
            style="border-radius: 0.5rem; padding: 0 1.1rem !important;"
            variant="primary"
            class="btn-min-size"
            size="sm"
            @click="onSubmitEditModal"
          >
            <i class="save-data_ct"/>บันทึก
          </b-button>
          <b-button
            variant="danger"
            class="btn-min-size btn-cancel-border"
            style="border-radius: 0.5rem;"
            size="sm"
            @click="remarkEditModal = false"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- remark Delete modal -->
    <b-modal v-model="remarkDeleteModal" title="หมายเหตุ (ลบรายการในใบสั่งซื้อ)" size='md' centered
      @close.prevent="remarkDeleteModal = false" no-close-on-backdrop>
      <template #modal-title>
        หมายเหตุ (ลบรายการในใบสั่งซื้อ) <span style="color: red;">*</span>
      </template>
        <b-form-textarea type="text" v-model="remarkDelete" placeholder="กรอกหมายเหตุ"
          style="padding-right: 1rem; height: 100px; justify-items: flex-start;">
        </b-form-textarea>
      <template #modal-footer>
        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
          <b-button
            style="border-radius: 0.5rem; padding: 0 1.1rem !important;"
            variant="primary"
            class="btn-min-size"
            size="sm"
            @click="deleteSoItem"
          >
            <i class="save-data_ct"/>บันทึก
          </b-button>
          <b-button
            variant="danger"
            class="btn-min-size btn-cancel-border"
            style="border-radius: 0.5rem;"
            size="sm"
            @click="remarkDeleteModal = false"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>

  </b-container>
</template>

<style scoped>
.width-label{
  width: 200px !important;
}

.sub-table-wrapper-so {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2) !important;
  border-radius: 10px;
  padding: 0 !important;
}
.sub-table-wrapper .sub-table table {
    border: none;
}

.max-width-btn-quo-mgt {
  max-width: 200px;
}

.checkbox-quo {
  padding: 0;
  margin-left: 1rem;
}

@media (max-width: 376px) {
  .fx-gp-quo {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
  .checkbox-quo {
    margin-left: 0;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import axios from 'axios'
import Vue from 'vue'
import { configAPI } from '../../configBase'
import moment from 'moment'
import productSelectModalSOAE from './component/productSelectModalSOAE.vue'
import serviceSelectModalSOAE from './component/serviceSelectModalSOAE.vue'
import CurrencyInputCom from './CurrencyInputCom.vue'

export default {
  name: 'SaleOrderTable',
  components: {
    productSelectModalSOAE,
    serviceSelectModalSOAE,
    CurrencyInputCom
  },
  data () {
    return {
      clnId: null,
      userId: null,
      searchData: '',
      showAddModal: false,
      columnsMain: [
        { label: 'ชื่อลูกค้า', key: 'cus_name', class: 'text-center table-size-200-400 ellips' },
        { label: 'เบอร์โทรติดต่อ', key: 'cus_tel', class: 'text-center table-size-200-400' },
        { label: 'วันที่ใช้บริการล่าสุด', key: 'cus_latest_date', class: 'text-center table-size-200-400' },
        { label: 'รายการหัตถการคงค้าง', key: 'management', class: 'text-center table-size-200-400' }
      ],
      columnsSub: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-200-400' },
        { label: 'เลขใบคำสั่งซื้อ', key: 'saleorder_no', class: 'text-center table-size-200-400' },
        { label: 'เลขใบขอซื้อ', key: 'quo_no', class: 'text-center table-size-200-400' },
        { label: 'วันที่สั่งซื้อ', key: 'saleorder_date', class: 'text-center table-size-200-400' },
        { label: 'ชื่อผลิตภัณฑ์', key: 'pro_ser_name', class: 'text-center table-size-200-400 ellips' },
        { label: 'ยอดคงค้าง', key: 'balance', class: 'text-center table-size-300-500' },
        { label: 'จัดการ', key: 'management', class: 'text-center table-size-200-400' }
      ],
      dataListLength: 0,
      dataList: [],
      dataListTemp: [],
      currentPage: 1,
      perPage: 10,
      currentPageSub: 1,
      optionCurrency: {
        currency: 'EUR',
        currencyDisplay: 'hidden',
        valueRange: {
          min: 0,
          max: 1e+53
        },
        precision: 0,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: true,
        accountingSign: false
      },
      rowSelected: {
        data: []
      },
      productSelect: [],
      serviceSelect: [],
      showModalProduct: false,
      showModalService: false,
      columnsSelected: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        { label: 'ประเภท', key: 'type_proser', class: 'text-center table-size-200-400' },
        { label: 'รหัสสินค้า/บริการ', key: 'code_proser', class: 'text-center table-size-200-400 ellips' },
        { label: 'ชื่อสินค้า/บริการ', key: 'name_proser', class: 'text-center table-size-200-400 ellips' },
        { label: 'จำนวน', key: 'quantity', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'mgt', class: 'text-center my-column' }
      ],
      currentPageSelected: 1,
      perPageSelected: 10,
      delList: [],
      modalAddData: {
        soDD: [],
        so_id: null,
        is_incl_vat: true,
        oldProSer: []
      },
      remarkAddModal: false,
      remarkAdd: '',
      modalEditData: {
        soi_id: null,
        soi_amount: null
      },
      remarkEditModal: false,
      remarkEdit: '',
      modalDeleteData: {
        soi_id: null
      },
      remarkDeleteModal: false,
      remarkDelete: '',
      openCusArr: []
    }
  },
  async mounted () {
    this.clnId = JSON.parse(sessionStorage.getItem('user')).cln_id || null
    this.userId = JSON.parse(sessionStorage.getItem('user')).usr_id || null
    await this.getAllSaleOrder()
    xray.index()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getAllSaleOrder()
      }
    },
    async getAllSaleOrder () {
      let soApi = {
        method: 'get',
        url: `${configAPI.SALE_PORT}/api/saleOrder/clinic/${this.clnId}/getAllSaleOrder?search=` + this.searchData,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(soApi)
        .then((response) => {
          let temp = response.data.data
          temp.map((item, index) => {
            if (this.openCusArr.includes(item.cus_id)) {
              item._showDetails = true
            }
            if (item.so_list.length > 0) {
              item.so_list.map((item2, index2) => {
                item2.index = index2
                item2.isEdit = false
              })
            }
          })
          this.dataList = response.data.data
          this.dataListLength = response.data.count || 0
          this.dataListTemp = JSON.parse(JSON.stringify(this.dataList))
        })
    },
    onPagination (page) {
      this.currentPage = page
    },
    onPaginationSub (page) {
      this.currentPageSub = page
    },
    viewOPD (id, historyAll) {
      if (historyAll) {
        router.push(`/HistoryService/view/${id}/SaleOrder`)
      } else {
        router.push(`/ServiceUsage/SaleOrder/History/view/${id}`)
      }
    },
    managePermission (id) {
      router.push('EmployeeGroup/Permission/' + id)
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return ''
      const index = this.dataList.indexOf(item) + 1
      if (index % 2 === 1) return 'table-body-color'
    },
    rowClassSub (item, type, test) {
      if (!item || type !== 'row') return ''
      if (item.sum_quantity === item.soi_amount) return 'table-body-color-green'
      if (item.index % 2 === 1) return 'table-body-color-default'
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    formatTel (tel) {
      if (tel) {
        let temp = tel.split('')
        let temp2 = ''
        temp.map((item, index) => {
          if (index === 2 || index === 5) {
            temp2 += item + '-'
          } else {
            temp2 += item
          }
        })
        return temp2
      } else {
        return '-'
      }
    },
    formatDate (date, format) {
      return moment(date).format(format)
    },
    formatNumber (value) {
      if (value === null || value === undefined) {
        return ''
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    openCusFunc (item) {
      this.openCusArr.push(item.cus_id)
    },
    /* Add Section */
    openAddModal (item) {
      this.modalAddData.soDD = item.so_list_dd
      this.modalAddData.oldProSer = item.so_list
      this.modalAddData.so_id = null
      this.modalAddData.is_incl_vat = true
      this.rowSelected.data = []
      this.productSelect = []
      this.serviceSelect = []
      this.showAddModal = true
    },
    calculateTotal () {
      let product = []
      let service = []
      for (let item of this.rowSelected.data) {
        if (item.type === 'Product') {
          product.push(item)
        } else {
          service.push(item)
        }
      }
      this.productSelect = product
      this.serviceSelect = service
    },
    calculateRow (item) {
      const _index = this.rowSelected.data.findIndex(el => el.id === item.id)
      if (_index >= 0) {
        this.rowSelected.data[_index] = item
      }
      this.calculateTotal()
    },
    onSubmitProductModal () {
      let Old = JSON.parse(JSON.stringify(this.productSelect))
      let [New, Del] = this.$refs.productSelectedCom.submitAction()
      this.productSelect = []
      this.rowSelected.data = []
      for (let item of New) {
        const _indexDel = Del.findIndex(el => el?.id === item?.id)
        if (_indexDel !== -1) {
          item = Del[_indexDel]
          Del.splice(_indexDel, 1)
        }
        let index = Old.findIndex(el => el.id === item.id)
        if (index < 0) {
          this.productSelect.push(item)
        } else {
          this.productSelect.push(Old[index])
        }
      }
      this.delList = Del
      this.rowSelected.data = [...this.productSelect, ...this.serviceSelect]
      this.showModalProduct = false
    },
    onCloseProductModal () {
      this.showModalProduct = false
    },
    onSubmitServiceModal () {
      let [New, Del] = this.$refs.serviceSelectedCom.submitAction()
      let Old = this.serviceSelect
      this.serviceSelect = []
      this.rowSelected.data = []
      for (let item of New) {
        const _indexDel = Del.findIndex(el => el?.id === item?.id)
        if (_indexDel !== -1) {
          item = Del[_indexDel]
          Del.splice(_indexDel, 1)
        }
        let index = Old.findIndex(el => el.id === item.id)
        if (index < 0) {
          this.serviceSelect.push(item)
        } else {
          this.serviceSelect.push(Old[index])
        }
      }
      this.delList = Del
      this.rowSelected.data = [...this.productSelect, ...this.serviceSelect]
      this.showModalService = false
    },
    onCloseServiceModal () {
      this.showModalService = false
    },
    deleteRow (item) {
      let temp = this.rowSelected.data
      let tempProduct = this.productSelect
      let tempService = this.serviceSelect
      let index = temp.findIndex(el => el.id === item.id)
      let indexProduct = tempProduct.findIndex(el => el.id === item.id)
      let indexService = tempService.findIndex(el => el.id === item.id)
      if (indexProduct >= 0) {
        tempProduct.splice(indexProduct, 1)
      }
      if (indexService >= 0) {
        tempService.splice(indexService, 1)
      }
      if (index >= 0) {
        if (temp[index].soi_id && this.delList.find(el => el.soi_id === temp[index].soi_id) === undefined) {
          this.delList.push(temp[index])
        }
        temp.splice(index, 1)
      }
      this.rowSelected.data = temp
      this.calculateTotal()
    },
    async checkError () {
      let errList = []
      if (this.modalAddData.so_id === null) {
        errList.push('กรุณาเลือกเลขที่ใบสั่งซื้อ')
      }
      if (this.rowSelected.data.length <= 0) {
        errList.push('กรุณาเลือกสินค้า/บริการ อย่างน้อย 1 รายการ')
      } else {
        // filter สินค้าที่ไม่มีจำนวน หรือ ราคา
        let temp = this.rowSelected.data.filter((item) => {
          return item.quantity === null || item.quantity === '' || item.quantity === 0 || item.quantity === '0'
        })
        if (temp.length > 0) {
          errList.push('</hr>')
          errList.push(`สินค้า/บริการที่ข้อมูลไม่ครบถ้วน`)
          for (let item of temp) {
            errList.push(`${item.type} : <span class="ellipsModal">${item.name || item.st_name}</span>`)
          }
        }
      }
      return errList
    },
    async openRemarkAddModal (item) {
      let errorList = await this.checkError()
      if (errorList.length > 0) {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">กรุณากรอกข้อมูลให้ครบถ้วน</span>',
          html: `<span class="" style="font-size: 18px; color: black; text-align: center">${errorList.join('<br>')}</span>`,
          icon: 'warning',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1'
          }
        })
      } else {
        this.remarkAddModal = true
        this.remarkAdd = ''
      }
    },
    async addSoItem () {
      if (this.remarkAdd === null || this.remarkAdd?.toString().trim() === '') {
        this.SwalError('', 'กรุณากรอกหมายเหตุ')
      } else {
        Vue.swal.fire({
          title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจหรือไม่ที่จะเพิ่มรายการสั่งซื้อ</span>`,
          icon: 'warning',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonColor: '#dc3545',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-cancel-button-class mx-1'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            let dataForSave = {
              so_id: this.modalAddData.so_id?.value || null,
              so_no: this.modalAddData.so_id?.label || null,
              soi_list: this.rowSelected.data,
              remark: this.remarkAdd || null
            }
            await axios.request({
              method: 'post',
              url: `${configAPI.SALE_PORT}/api/saleOrder/clinic/${this.clnId}/addSaleOrderItem`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: dataForSave
            }).then((response) => {
              if (response.data.status) {
                Vue.swal.fire({
                  title: `<span class="" style="font-size: 25px; color: black; text-align: center">เพิ่มรายการสั่งซื้อสำเร็จ</span>`,
                  html: '<span>เพิ่มรายการสั่งซื้อสำเร็จ</span>',
                  icon: 'success',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  timer: 1500
                }).then(() => {
                  this.showAddModal = false
                  this.remarkAddModal = false
                  this.getAllSaleOrder()
                })
              } else {
                this.SwalError(response.data.message, 'เพิ่มรายการสั่งซื้อไม่สำเร็จ')
              }
            }).catch((error) => {
              this.SwalError(error, 'เพิ่มรายการสั่งซื้อไม่สำเร็จ')
            })
          }
        })
      }
    },
    cancelAddSoItem () {
      this.showAddModal = false
    },
    /* Edit Section */
    editSoItem (item) {
      item.isEdit = true
    },
    submitEdit (item) {
      if (parseInt(item.soi_amount || 0) <= 0 || parseInt(item.soi_amount || 0) < parseInt(item.sum_quantity || 0)) {
        this.SwalError('ไม่ต่ำกว่าจำนวนที่ใช้ไป และ ไม่ต่ำกว่าหรือเท่ากับ 0', 'กรุณากรอกจำนวนสินค้าให้ถูกต้อง')
      } else {
        this.remarkEditModal = true
        this.remarkEdit = ''
        this.modalEditData.soi_id = item.soi_id
        this.modalEditData.soi_amount = item.soi_amount
      }
    },
    redoEdit (newItem) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะยกเลิกการบันทึกข้อมูลใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          for (let oldItem of this.dataListTemp) {
            if (oldItem.so_list.length > 0) {
              for (let oldItem2 of oldItem.so_list) {
                if (oldItem2.soi_id === newItem.soi_id) {
                  newItem.soi_amount = oldItem2.soi_amount
                  newItem.isEdit = false
                  break
                }
              }
            }
          }
        }
      })
    },
    onSubmitEditModal () {
      if (this.remarkEdit === null || this.remarkEdit?.toString().trim() === '') {
        this.SwalError('', 'กรุณากรอกหมายเหตุ')
      } else {
        Vue.swal.fire({
          title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจหรือไม่ที่จะแก้ไขรายการสั่งซื้อ</span>`,
          icon: 'warning',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonColor: '#dc3545',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-cancel-button-class mx-1'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            let dataForSave = {
              soi_id: this.modalEditData.soi_id,
              soi_amount: this.modalEditData.soi_amount,
              remark: this.remarkEdit?.trim() || null
            }
            await axios.request({
              method: 'post',
              url: `${configAPI.SALE_PORT}/api/saleOrder/clinic/${this.clnId}/editSaleOrderItem`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: dataForSave
            }).then((response) => {
              if (response.data.status) {
                Vue.swal.fire({
                  title: `<span class="" style="font-size: 25px; color: black; text-align: center">แก้ไขรายการสั่งซื้อสำเร็จ</span>`,
                  html: '<span>แก้ไขรายการสั่งซื้อสำเร็จ</span>',
                  icon: 'success',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  timer: 1500
                }).then(() => {
                  this.remarkEditModal = false
                  this.getAllSaleOrder()
                })
              } else {
                this.SwalError(response.data.message, 'แก้ไขรายการสั่งซื้อไม่สำเร็จ')
              }
            }).catch((error) => {
              this.SwalError(error, 'แก้ไขรายการสั่งซื้อไม่สำเร็จ')
            })
          }
        })
      }
    },
    /* Delete Section */
    openRemarkDeleteModal (item) {
      this.remarkDeleteModal = true
      this.remarkDelete = ''
      this.modalDeleteData.soi_id = item.soi_id
    },
    deleteSoItem () {
      if (this.remarkDelete === null || this.remarkDelete?.toString().trim() === '') {
        this.SwalError('', 'กรุณากรอกหมายเหตุ')
      } else {
        Vue.swal.fire({
          title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจหรือไม่ที่จะลบรายการสั่งซื้อ</span>`,
          icon: 'warning',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ยกเลิก</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonColor: '#dc3545',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-cancel-button-class mx-1'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            let dataForDelete = {
              soi_id: this.modalDeleteData.soi_id,
              remark: this.remarkDelete?.trim() || null
            }
            await axios.request({
              method: 'post',
              url: `${configAPI.SALE_PORT}/api/saleOrder/clinic/${this.clnId}/deleteSaleOrderItem`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: dataForDelete
            }).then((response) => {
              if (response.data.status) {
                Vue.swal.fire({
                  title: `<span class="" style="font-size: 25px; color: black; text-align: center">ลบรายการสั่งซื้อสำเร็จ</span>`,
                  html: '<span>ลบรายการสั่งซื้อสำเร็จ</span>',
                  icon: 'success',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  timer: 1500
                }).then(() => {
                  this.remarkDeleteModal = false
                  this.getAllSaleOrder()
                })
              } else {
                this.SwalError(response.data.message, 'ลบรายการสั่งซื้อไม่สำเร็จ')
              }
            }).catch((error) => {
              this.SwalError(error, 'ลบรายการสั่งซื้อไม่สำเร็จ')
            })
          }
        })
      }
    }
  }
}
</script>
