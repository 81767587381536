<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4">
              <div class="ml-4">
                <span class="mx-2" style="font-size: 25px; color: black;">Follow Up (ลูกค้า)</span>
              </div>
            </div>
          </template>
          <template v-slot:body>
          <div class="mx-3">
            <b-row>
              <b-col cols="12" sm="12" md="8" lg="8" class="">
                <b-row class="searchres">
                  <b-col lg="6" md="6" sm="12">
                    <div class="iq-email-search d-flex">
                      <div style="width: 100%;">
                        <div class="form-group mb-0">
                          <input v-model="search" @keydown="enterSearch" class="form-control" id="exampleInputEmail1" placeholder="Search" style="width: 100%;">
                          <a class="search-link" @click="getPatientData"><i class="ri-search-line" /></a>
                        </div>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="auto" class="my-1" style="display: flex; align-items: center;">
                    <span class="mx-2 font-size-14 text-primary">
                      {{ PatientData?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > PatientData?.length) ? PatientData?.length : (perPage * currentPage) ) + ' of ' + AllDataLength : '0 of ' + AllDataLength }} รายการ
                    </span></b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <br>
              <b-col md="12" class="table-responsive">
                <b-table bordered hover :items="PatientData" :fields="columns"
                  :current-page="currentPage"
                  :per-page="perPage"
                  @pagination="onPagination"
                  :tbody-tr-class="rowClass" thead-class="table-header-color"
                >
                 <template v-slot:cell(HN_no)="data">
                  <span>{{ data.item.HN_no }}</span>
                </template>
                <!-- <template v-slot:cell(history_service)="data">
                  <span v-for="(item1, index) in data.item.history_service" :key="index">
                    <br v-if="index !== 0">
                    {{ item1 }}
                  </span>
                </template> -->
                <template v-slot:cell(fullname)="data">
                  <span>{{ data.item.fullname }}</span>
                </template>
                <template v-slot:cell(gender)="data">
                  <span>{{ data.item.gender }}</span>
                </template>
                <template v-slot:cell(tel)="data">
                  <span>{{ formatTel(data.item.tel) }}</span>
                </template>
                <template v-slot:cell(birthday)="data">
                  <span>{{ Formatbirthday(data.item.birthday) }}</span>
                </template>
                <template v-slot:cell(img_path)="data">
                  <b-img :src="getImagePath(data.item.img_path)" style="height: auto; width:100px;"></b-img>
                </template>
                <template v-slot:cell(nickname)="data">
                  <span>{{ data.item.nickname }}</span>
                </template>
                <template v-slot:cell(entry_date)="data">
                  <span>{{ data.item.entry_date }}</span>
                </template>
                <template v-slot:cell(follow_date)="data">
                  <span :style="{ color: getColorFollowDate(data.item.follow_date) }">{{ Formatbirthday(data.item.follow_date, true) }}</span>
                </template>
                <template v-slot:cell(management)="data">
                  <div style="display: flex; justify-content: center;">
                    <b-button variant=" iq-bg-info ml-1" size="sm" @click="handleClick('view', data.item)"><i class="ri-eye-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-success mx-1" size="sm" id="show-modal" @click="handleClick('edit', data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                  </div>
                </template>
                </b-table>
                <br>
              </b-col>
            <b-pagination
                v-model="currentPage"
                :total-rows="PatientData?.length"
                :per-page="perPage"
                align="center"
              />
        </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
@media (width: 700px) {
  .searchres {
    gap: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import moment from 'moment'
import Axios from 'axios'
import { configAPI } from '../../configBase'
import Vue from 'vue'

export default {
  name: 'FollowUpCaseCRM',
  async mounted () {
    await this.getPatientData()
    xray.index()
  },
  data () {
    return {
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      columns: [
        { label: 'HN', key: 'HN_no', class: 'text-center table-size-200-400' },
        { label: 'รูป', key: 'img_path', class: 'text-center table-size-200-400' },
        { label: 'ชื่อ - นามสกุล', key: 'fullname', class: 'text-center table-size-200-400' },
        { label: 'ชื่อเล่น', key: 'nickname', class: 'text-center table-size-200-400' },
        { label: 'เพศ', key: 'gender', class: 'text-center table-size-200-400' },
        { label: 'วันเดือนปีเกิด', key: 'birthday', class: 'text-center table-size-200-400' },
        { label: 'เบอร์ติดต่อ', key: 'tel', class: 'text-center table-size-200-400' },
        { label: 'วันที่ต้องติดตามเคส', key: 'follow_date', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'management', class: 'text-center' }
      ],
      rows: [],
      PatientData: [],
      currentPage: 1,
      perPage: 10,
      AllDataLength: 0,
      search: ''
    }
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getPatientData()
      }
    },
    SortDateActual (dateList) {
      // const testData = [
      //   { fcc_actual_date: '2024-03-20T03:56:00+00:00' },
      //   { fcc_actual_date: '2024-07-25T02:26:00+00:00' },
      //   { fcc_actual_date: '2024-07-30T02:19:00+00:00' },
      //   { fcc_actual_date: '2024-08-01T03:40:00+00:00' },
      //   { fcc_actual_date: '2024-08-02T03:46:00+00:00' },
      //   { fcc_actual_date: '2024-08-03T02:19:00+00:00' },
      //   { fcc_actual_date: '2024-08-06T03:50:00+00:00' },
      //   { fcc_actual_date: '2024-08-04T03:52:00+00:00' },
      //   { fcc_actual_date: '2024-08-05T03:54:00+00:00' },
      //   { fcc_actual_date: '2024-08-07T03:58:00+00:00' },
      //   { fcc_actual_date: '2024-08-09T04:01:00+00:00' },
      //   { fcc_actual_date: '2024-08-21T03:17:00+00:00' }
      // ].sort((a, b) => {
      //   // Function to compare dates with null values last
      //   const getDateValue = (date) => date ? new Date(date) : null

      //   const dateA = getDateValue(a.fcc_actual_date)
      //   const dateB = getDateValue(b.fcc_actual_date)

      //   const today = new Date()
      //   today.setHours(0, 0, 0, 0) // Set today's time to midnight for comparison

      //   // If both dates are null
      //   if (dateA === null && dateB === null) {
      //     return 0
      //   }
      //   // If one of the dates is null
      //   if (dateA === null) {
      //     return 1
      //   }
      //   if (dateB === null) {
      //     return -1
      //   }

      //   // Compare dates to today
      //   const isToday = (date) => moment(date).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')

      //   if (isToday(dateA) && isToday(dateB)) {
      //     return 0
      //   }
      //   if (isToday(dateA)) {
      //     return -1
      //   }
      //   if (isToday(dateB)) {
      //     return 1
      //   }

      //   // Compare future and past dates
      //   if (dateA < today && dateB < today) {
      //     // console.log('dateA - dateB :>> ', dateA, dateB)
      //     return dateA - dateB // Both in the past, sort descending
      //   }
      //   if (dateA > today && dateB > today) {
      //     return dateA - dateB // Both in the future, sort ascending
      //   }

      //   return dateA - dateB // Default case
      // })
      // console.log('testData :>> ', testData)
      let currentDate = new Date()
      let dataReturn = null
      let dateDiff = Number.MAX_SAFE_INTEGER
      for (let item of dateList) {
        if (Math.abs(moment(currentDate).diff(moment(item.fcc_actual_date), 'milliseconds')) < dateDiff) {
          dateDiff = Math.abs(moment(currentDate).diff(moment(item.fcc_actual_date), 'milliseconds'))
          dataReturn = item.fcc_actual_date
        }
      }
      return dataReturn
    },
    async getPatientData () {
      let dataSearch = this.search?.trim()
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/patient/followUpCase?search=${dataSearch}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        let temp = []
        response.data.data.map((item) => {
          if (item.history_customer?.length > 0) {
            let temp2 = {
              id: item.id,
              HN_no: item.hn_no ? item.hn_no : '-',
              fullname: (item.firstname ? item.firstname : '-') + ' ' + (item.lastname ? item.lastname : '-'),
              gender: item.gender ? item.gender : '-',
              tel: item.phone ? item.phone : '-',
              birthday: item.birthday ? item.birthday : null,
              img_path: item.img_path ? item.img_path : null,
              nickname: item.nickname ? item.nickname : '-',
              follow_date: this.SortDateActual(item.actual_date),
              history_service: item.history_customer
            }
            temp.push(temp2)
          }
        })
        this.PatientData = temp.sort((a, b) => {
          // Function to compare dates with null values last
          const getDateValue = (date) => date ? new Date(date) : null

          const dateA = getDateValue(a.follow_date)
          const dateB = getDateValue(b.follow_date)

          const today = new Date()
          today.setHours(0, 0, 0, 0) // Set today's time to midnight for comparison

          // If both dates are null
          if (dateA === null && dateB === null) {
            return 0
          }
          // If one of the dates is null
          if (dateA === null) {
            return 1
          }
          if (dateB === null) {
            return -1
          }

          // Compare dates to today
          const isToday = (date) => moment(date).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')

          if (isToday(dateA) && isToday(dateB)) {
            return 0
          }
          if (isToday(dateA)) {
            return -1
          }
          if (isToday(dateB)) {
            return 1
          }

          // Compare future and past dates
          if (dateA < today && dateB < today) {
            return dateA - dateB // Both in the past, sort descending
          }
          if (dateA > today && dateB > today) {
            return dateA - dateB // Both in the future, sort ascending
          }

          return dateA - dateB // Default case
        })
        // console.log('this.PatientData :>> ', this.PatientData)
        this.AllDataLength = response.data.count
      }).catch((error) => {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่พบข้อมูล</span>',
          html: `${error.response.data.message}`,
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab'
        })
      })
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick (mode, item) {
      const setPage = mode
      router.push(`/FollowUpCase/${setPage}/${item.id}`)
    },
    getImagePath (relativePath) {
      if (relativePath) {
        let path = relativePath !== null ? relativePath.slice(1) : ''
        const baseURL = configAPI.MA_PORT + path
        return baseURL
      } else {
        return 'https://www.w3schools.com/howto/img_avatar.png'
      }
    },
    Formatbirthday (date, isFollowDate) {
      if (date) {
        return isFollowDate
          ? moment(date).diff(moment(), 'milliseconds') < 0
            ? `${moment(date).format('DD/MM/YYYY')} (late)`
            : moment(date).format('DD/MM/YYYY')
          : moment(date).format('DD/MM/YYYY')
      } else {
        return '-'
      }
    },
    getColorFollowDate (date) {
      let strColor = '#000'
      if (date) {
        if (moment(date).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
          strColor = '#0f0'
        } else if (moment(date).diff(moment(), 'milliseconds') < 0) {
          strColor = '#f00'
        } else {
          strColor = '#000'
        }
      }
      return strColor
    },
    formatTel (tel) {
      if (tel) {
        let temp = tel.split('')
        let temp2 = ''
        temp.map((item, index) => {
          if (index === 2 || index === 5) {
            temp2 += item + '-'
          } else {
            temp2 += item
          }
        })
        return temp2
      } else {
        return '-'
      }
    }
  }
}
</script>
