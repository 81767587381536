<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <b-row>
          <b-col lg="2" sm="5" class="my-1 my-sm-0">
            <b-form-group label="วันที่เริ่มต้น" style="color: black;">
              <div style="position: relative;" class="">
                <date-picker placeholder="DD/MM/YYYY" id="datepicker-custom3" v-model="variable.start_date" style="background-color: white;" name="date" :config="options"></date-picker>
                <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(3)" style="cursor: pointer;"></i>
              </div>
              <!-- <b-form-input type="date" :disabled="isDisable" style="height: 40px; background-color: rgb(255, 255, 255);" v-model="variable.start_date"  @change="resetEndDate" placeholder="วันที่เริ่มต้น"></b-form-input>v-bind:max="subtractMonths(new Date(new Date().getFullYear(),new Date().getMonth(),1), 1)" -->
            </b-form-group>
          </b-col>
          <b-col lg="2" sm="5" class="my-1 my-sm-0">
            <b-form-group label="วันที่สิ้นสุด" style="color: black;">
              <div style="position: relative;" class="">
                <date-picker placeholder="DD/MM/YYYY" id="datepicker-custom2" v-model="variable.end_date" name="date" style="background-color: white;" :config="options2"></date-picker>
                <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(2)" style="cursor: pointer;"></i>
              </div>
              <!-- <b-form-input type="date" v-model="variable.end_date" style="height: 40px; background-color: rgb(255, 255, 255);" :disabled="isDisable ? true : !variable.start_date ? true : false" placeholder="วันที่สิ้นสุด"></b-form-input> v-bind:min="getCurrentDate(new Date())" -->
            </b-form-group>
          </b-col>
          <div lg="1" sm="2" md="3" class="d-flex align-items-center justify-content-center my-1 pl-3" style="white-space: nowrap;">
            <b-button type="submit" @click="GetDataDashboardRange" variant="primary" class="w-250 se-pading btn-min-size"  style="white-space: nowrap; text-align: center;"><i class="search_ct" />ค้นหา</b-button>
          </div>
        </b-row>
        <b-row class="mt-3">
          <b-col sm="12" md="6" lg="3">
            <iq-card class-name="iq-card iq-card-block iq-card-stretch"><!--  iq-bg-success -->
              <template v-slot:body>
                <div class="iq-info-box d-flex align-items-center p-3">
                  <div class="info-image mr-3">
                    <img src="../../assets/images/page-img/30.png" class="img-fluid" alt="image-box">
                  </div>
                  <div class="info-text">
                    <h3>{{ info.new ? info.new : 0 }}</h3>
                    <h5 style="white-space: nowrap;">ลูกค้าใหม่</h5>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="12" md="6" lg="3">
            <iq-card class-name="iq-card iq-card-block iq-card-stretch"><!--  iq-bg-success -->
              <template v-slot:body>
                <div class="iq-info-box d-flex align-items-center p-3">
                  <div class="info-image mr-3">
                    <img src="../../assets/images/page-img/49.png" class="img-fluid" alt="image-box">
                  </div>
                  <div class="info-text">
                    <h3>{{ info.old ? info.old : 0 }}</h3>
                    <h5 style="white-space: nowrap;">ลูกค้าเก่า</h5>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="12" md="6" lg="3">
            <iq-card class-name="iq-card iq-card-block iq-card-stretch"><!--  iq-bg-success -->
              <template v-slot:body>
                <div class="iq-info-box d-flex align-items-center p-3">
                  <div class="info-image mr-3">
                    <img src="../../assets/images/page-img/31.png" class="img-fluid" alt="image-box">
                  </div>
                  <div class="info-text">
                    <h3>{{ info.appointment ? info.appointment : 0 }}</h3>
                    <h5 style="white-space: nowrap;">ลูกค้ารอการนัดหมาย</h5>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="12" md="6" lg="3">
            <iq-card class-name="iq-card iq-card-block iq-card-stretch"><!--  iq-bg-success -->
              <template v-slot:body>
                <div class="iq-info-box d-flex align-items-center p-3">
                  <div class="info-image mr-3">
                    <img src="../../assets/images/page-img/32.png" class="img-fluid" alt="image-box">
                  </div>
                  <div class="info-text">
                    <h3>{{ info.reAppointemnt ? info.reAppointemnt : 0 }}</h3>
                    <h5 style="white-space: nowrap;">ลูกค้ายืนยันเข้ารับบริการ</h5>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="6">
        <iq-card class-name="iq-card iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">TOP 5 พนักงานยอดขายสูงสุด</h4>
          </template>
          <template v-slot:body class="iq-card-body">
            <div class="table-responsive">
              <b-table  hover :items="seller" :fields="sellerRows" @row-clicked="sellerhandleClick"  class="pointer-hover">
                  <template v-slot:cell(name)="data">
                    <span>{{ data.item.name }}</span>
                  </template>
                <template v-slot:cell(sales)="data">
                    <span>{{ data.item.sales }}</span>
                </template>
              </b-table>
              <!-- <table class="table mb-0 table-borderless">
                <tbody>
                  <tr v-for="(item, index) in seller" :key="item.id">
                    <td>{{ index + 1 }}.</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.lastname }}</td>
                    <td>{{ numberWithCommasfunc(item.sales) }}</td>
                  </tr>
                </tbody>
              </table> -->
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="6">
        <iq-card class-name="iq-card iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">TOP 5 ลูกค้ายอดซื้อสูงสุด</h4>
          </template>
          <template v-slot:body class="iq-card-body">
            <div class="table-responsive">
              <b-table hover  :items="mostPurchaseOder" :fields="mostPurchaseOderRows" @row-clicked="mostPurchaseOderhandleClick" class="pointer-hover">
                <template v-slot:cell(name)="data">
                  <span>{{ data.item.name }}</span>
                </template>
                <template v-slot:cell(price)="data">
                  <span>{{ data.item.price }}</span>
                </template>
              </b-table>
              <!-- <table class="table mb-0 table-borderless">
                <tbody>
                  <tr v-for="(item, index) in mostPurchaseOder" :key="item.id">
                    <td>{{ index + 1 }}.</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.lastname }}</td>
                    <td>{{ numberWithCommasfunc(item.price) }}</td>
                  </tr>
                </tbody>
              </table> -->
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <iq-card class-name="iq-card iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">ภาพรวมสินค้า</h4>
          </template>
          <template v-slot:body class="iq-card-body">
            <b-row>
            <b-col lg="6">
                <div class="media mb-4">
                  <div class="media-body">
                    <h4 class="mt-0 pr-5 buttonall">สินค้าที่กำลังจะหมด
                      <div class="col-2 pl-3 pr-2 my-1 my-sm-0">
                        <b-button @click="productAmountColhandleClick" variant="none" class="btn-min-size btn-view-all" style="white-space: nowrap; justify-content: center; text-align: center;">ดูทั้งหมด</b-button>
                      </div>
                    </h4>
                  </div>
              </div>
                <div class="table-responsive">
                  <b-table  hover :items="productMinStockAmountCol" :fields="productAmountRows">
                  </b-table>
                  <!-- <table class="table mb-0 table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">ลำดับ</th>
                        <th scope="col">รหัส</th>
                        <th scope="col">ชื่อยา</th>
                        <th scope="col">สารประกอบ</th>
                        <th scope="col">ชื่อการค้า</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in productAmount" :key="item.id">
                        <td>{{ index + 1 }}.</td>
                        <td>{{ item.code }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.brand }}</td>
                        <td>{{ item.onwer }}</td>
                      </tr>
                    </tbody>
                  </table> -->
              </div>
            </b-col>
            <b-col lg="6">
                <div class="media mb-4">
                <div class="media-body">
                  <h4 class="mt-0 pr-5 buttonall">สินค้าที่ใช้มากที่สุด
                    <div class="col-2 pl-3 pr-2 my-1 my-sm-0" >
                      <b-button @click="productAmountRowshandleClick" variant="outline-primary" class="btn-min-size btn-view-all"  style="white-space: nowrap; text-align: center;">ดูทั้งหมด</b-button>
                    </div>
                  </h4>
                </div>
              </div>
              <div class="table-responsive">
                <b-table hover  :items="productAmountCol" :fields="productAmountRows"></b-table>
                <!-- <table class="table mb-0 table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">ลำดับ</th>
                        <th scope="col">รหัส</th>
                        <th scope="col">ชื่อยา</th>
                        <th scope="col">สารประกอบ</th>
                        <th scope="col">ชื่อการค้า</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in productAmount" :key="item.id">
                        <td>{{ index + 1 }}.</td>
                        <td>{{ item.code }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.brand }}</td>
                        <td>{{ item.onwer }}</td>
                      </tr>
                  </tbody>
                </table> -->
              </div>
            </b-col>
          </b-row>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="6" v-for="(item,index) in chartsTrue" :key="index">
        <iq-card>
          <template v-slot:headerTitle>
            <h4>{{ item.title === 'อัตราส่วนกำไรตามช่วง'
              ? item.title + getTextMonth(dateDisplay)
              : item.title
              }}</h4>
          </template>
          <template v-slot:body >
            <ApexChart class="chartcenter" :element="item.type" :chartOption="item.bodyData"/>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <iq-card class-name="iq-card iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">ข้อเสนอล่าสุด</h4>
          </template>
          <template v-slot:body class="iq-card-body">
            <b-table hover :items="comment" :fields="commentRows"  @row-clicked="casehandleClick" class="pointer-hover">
              <template v-slot:cell(case)="data">
                <span>{{ data.item.case }}</span>
              </template>
            </b-table>
            <!-- <div class="table-responsive">
              <table class="table mb-0 table-borderless">
                <tbody>
                  <tr v-for="(item, index) in comment" :key="item.id">
                    <td>{{ index + 1 }}.</td>
                    <td>{{ item.details }}-{{ item.case }}</td>
                  </tr>
                </tbody>
              </table>
            </div> -->
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style scoped>
.customercard {
  display: flex !important;
  flex-direction: column;
}
.chartcenter {
  display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-around;
}

.pointer-hover {
  cursor:default;
}

@media (min-width: 375px) {
  .buttonall{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}

@media (min-width: 414px) {
  .buttonall{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}

@media (min-width: 576px) {
  .se-pading{
    margin-top: 0.7rem!important
  }
  .buttonall{
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

@media (min-width: 768px) {
  .se-pading{
    margin-top: 0.7rem!important
  }
  .buttonall{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 992px) {
  .se-pading{
    margin-top: 0.7rem!important
  }
  .buttonall{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (min-width: 1200px) {
  .se-pading{
    margin-top: 0.7rem!important
  }
  .buttonall{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
<script>
import axios from 'axios'
import IqCard from '../../components/xray/cards/iq-card-dashboard'
import { xray } from '../../config/pluginInit'
import { dashboardData } from '../../FackApi/api/dashboardCus'
import { numberWithCommas } from '../../Utils/Common'
import { configAPI } from '../../configBase'
import Vue from 'vue'
import router from '@/router'
import moment from 'moment'

const body = document.getElementsByTagName('body')
const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}
export default {
  name: 'DashboardsCus',
  props: ['element', 'styles'],
  components: { IqCard },
  watch: {
    'variable.start_date': function (newVal, oldVal) {
      this.options2.minDate = newVal
    },
    'variable.end_date': function (newVal, oldVal) {
      this.options.maxDate = newVal
    }
  },
  data () {
    return {
      selectedDateRange: null,
      dateRangePicker: null,
      selectedDate: null,
      info: dashboardData.custommerCount,
      config: {
        mode: 'range',
        dateFormat: 'Y-m-d',
        inline: true
      },
      variable: {
        start_date: null, // this.subtractMonths(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 1),
        end_date: null
      },
      dateDisplay: null,
      seller: dashboardData.seller,
      options: {
        // https://momentjs.com/docs/#/displaying/
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: false,
        showClose: true,
        useStrict: true,
        sideBySide: true,
        icons: iconDate,
        maxDate: false
      },
      options2: {
        // https://momentjs.com/docs/#/displaying/
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: false,
        showClose: true,
        useStrict: true,
        sideBySide: true,
        minDate: false,
        icons: iconDate
      },
      sellerRows: [
        { label: 'ลำดับ', key: 'id', class: 'text-center' },
        { label: 'ชื่อ-นามสกุล', key: 'name', class: 'text-left' },
        { label: 'ยอดขาย', key: 'sales', class: 'text-right pr-5' }
      ],
      mostPurchaseOder: dashboardData.mostPurchaseOder,
      mostPurchaseOderRows: [
        { label: 'ลำดับ', key: 'id', class: 'text-center' },
        { label: 'ชื่อ-นามสกุล', key: 'name', class: 'text-left pl-5' },
        { label: 'ยอดซื้อ', key: 'price', class: 'text-right pr-5' }
      ],
      productAmountCol: dashboardData.productAmount,
      productMinStockAmountCol: dashboardData.productAmount,
      productAmountRows: [
        { label: 'ลำดับ', key: 'id', class: 'text-center ellips' },
        { label: 'รหัส', key: 'code', class: 'text-center ellips' },
        { label: 'ชื่อยา', key: 'name', class: 'text-center ellips' },
        { label: 'สารประกอบ', key: 'brand', class: 'text-center ellips' },
        { label: 'ชื่อการค้า', key: 'onwer', class: 'text-center ellips' }
      ],
      comment: dashboardData.comment,
      commentRows: [
        { label: 'ลำดับ', key: 'id', class: 'text-center' },
        { label: 'รายละเอียด', key: 'details', class: 'text-center ellips' },
        { label: 'เคส', key: 'case', class: 'text-center' }
      ],
      chartsTrue: [],
      charts: [{
        title: 'จำนวนเคสแยกตามพนักงาน',
        type: 'column1',
        bodyData: {
          chart: {
            height: 350,
            type: 'bar'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#17a2b8'],
          series: [{
            name: 'จำนวนเคสแยกตามพนักงาน',
            data: [/* 44, 55, 57, 56, 61 */]
          }],
          xaxis: {
            categories: [/* 'ธีวินท์', 'ภูบดี', 'ปูน', 'ปัญญา', 'เอกราช', 'อิศรา' */]
          },
          yaxis: {
            title: {
              text: ''
            }
          },
          fill: {
            opacity: 1

          },
          tooltip: {
            y: {
              formatter: function (val) {
                return 'จำนวนเคส ' + val + ' เคส'
              }
            }
          }
        }
      },
      {
        title: 'จำนวนกลุ่มบริการ',
        type: 'column2',
        bodyData: {
          chart: {
            height: 350,
            type: 'bar'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          colors: ['#17a2b8'],
          series: [{
            name: 'จำนวนกลุ่มบริการ',
            data: [/* 44, 55, 57, 56, 61 */]
          }],
          xaxis: {
            categories: [/* 't1', 't2', 't3', 't4', 't5' */]
          },
          yaxis: {
            title: {
              text: ''
            }
          },
          fill: {
            opacity: 1

          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + ' service'
              }
            }
          }
        }
      },
      {
        title: 'อัตราส่วนกำไรตามช่วง',
        type: 'pie1',
        bodyData: {
          chart: {
            width: 380,
            type: 'pie'
          },
          labels: ['รายรับ', 'รายจ่าย', 'กำไร'],
          series: [/* 150, 50, 30 */],
          colors: ['#58B053', '#f35448', '#fbc647'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 270
              },
              legend: {
                position: 'bottom'
              }
            }
          }],
          tooltip: {
            y: {
              formatter: function (val) {
                return 'จำนวน ' + val + ' บาท'
              }
            }
          }
        }
      },
      {
        title: 'ภาพรวมความพึงพอใจ',
        type: 'pie2',
        bodyData: {
          chart: {
            width: 420,
            type: 'pie'
          },
          labels: ['พอใจมาก', 'พอใจปานกลาง', 'ไม่พอใจ'],
          series: [],
          colors: ['#58B053', '#fbc647', '#f35448'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 270
              },
              legend: {
                position: 'bottom'
              }
            }
          }],
          tooltip: {
            y: {
              formatter: function (val) {
                return 'จำนวน ' + val + ' คน'
              }
            }
          }
        }
      }
      ]
    }
  },
  methods: {
    getTextMonth (date) {
      return `เดือน ${moment(date, 'DD/MM/YYYY').format('MMMM')} ปี ${moment(date, 'DD/MM/YYYY').format('YYYY')}`
    },
    onClickDatePicker (num) {
      let datePicker = null
      if (num === 1) {
        datePicker = document.getElementById('datepicker-custom1')
      } else if (num === 2) {
        datePicker = document.getElementById('datepicker-custom2')
      } else {
        datePicker = document.getElementById('datepicker-custom3')
      }
      datePicker.focus()
    },
    numberWithCommasfunc (number) {
      return numberWithCommas(number)
    },
    getCurrentDate (dataInput) {
      if (dataInput === undefined) {
        return
      }
      const today = new Date(dataInput)
      let month = (today.getMonth() + 1).toString()
      let day = today.getDate().toString()
      // แก้ไขรูปแบบเดือนและวันให้เป็น 'MM' และ 'DD'
      if (month.length === 1) {
        month = '0' + month
      }
      if (day.length === 1) {
        day = '0' + day
      }
      return `${today.getFullYear()}-${month}-${day}`
    },
    resetValue () {
      this.variable.start_date = null
      this.variable.end_date = null
    },
    resetEndDate (e) {
      this.variable.end_date = null
    },
    AddEmployeehandleClick () {
      router.push(`/AddEmployee/Design/view/3`)
    },
    casehandleClick (data) {
      router.push(`/CommentDetail/${data.cc_id}`)
    },
    sellerhandleClick (data) {
      router.push(`/AddEmployee/Design/view/${data.emp_id}`)
    },
    mostPurchaseOderhandleClick (data) {
      router.push(`/Patient/view/${data.cus_id}`)
    },
    productAmountColhandleClick () {
      router.push(`/productWarehouse`)
    },
    productAmountRowshandleClick () {
      router.push(`/productWarehouse`)
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.id % 2 === 1) return 'iq-bg-info text-black'
    },
    subtractMonths (date, months) {
      date.setMonth(date.getMonth() - months)
      return this.getCurrentDate(date)
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    async GetDataDashboardRange () {
      this.dateDisplay = moment(this.variable.start_date, 'DD/MM/YYYY')
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/dashboards`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          start: new Date(moment(this.variable.start_date, 'DD/MM/YYYY')),
          end: new Date(moment(this.variable.end_date, 'DD/MM/YYYY'))
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            const data = response.data.data
            const chart = JSON.parse(JSON.stringify(this.charts))

            chart[1].bodyData.series[0].data = data?.serviceGroupList?.countList
            chart[1].bodyData.xaxis.categories = data?.serviceGroupList?.nameList
            this.info.new = data?.countCustomerNew
            this.info.old = data?.countCustomerOld
            this.info.appointment = data?.countCusFA
            this.info.reAppointemnt = data?.countCusDA
            this.seller = data?.empSaleBestList
            this.mostPurchaseOder = data?.cusBuyBestList
            this.productMinStockAmountCol = data?.prodDownMinList
            this.productAmountCol = data?.prodMostUseList
            chart[0].bodyData.series[0].data = data?.empCaseBestList?.countList
            chart[0].bodyData.xaxis.categories.push(...data.empCaseBestList?.nameList)
            chart[3].bodyData.series.push(...data.satisCusList.countList)
            chart[2].bodyData.series.push(Number(data.CostIncomeProfit?.income || 0))
            chart[2].bodyData.series.push(Number(data.CostIncomeProfit?.cost || 0))
            chart[2].bodyData.series.push(Number(data.CostIncomeProfit?.profit || 0))
            this.comment = data?.suggestionCus

            this.chartsTrue = JSON.parse(JSON.stringify(chart))
          }
        }).catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    }
  },
  mounted () {
    xray.index()
    /* const dateTest = moment(new Date()).endOf('M').format('DD/MMM/YYYY')
    console.log('dateTest', dateTest) */
    const data = JSON.parse(sessionStorage.getItem('user'))
    this.cln_id = data.cln_id
    this.user_id = data.usr_id
    this.variable.start_date = moment().startOf('y').format('DD/MM/YYYY')
    this.variable.end_date = moment().endOf('y').format('DD/MM/YYYY')
    this.GetDataDashboardRange()
    body[0].classList.remove('sidebar-main-menu')
    /* axios.get('https://corona.lmao.ninja/all').then(response => {
      this.info = response.data
    })
    axios.get('https://corona.lmao.ninja/states').then(response => {
      this.state = response.data
    }) */
  },
  destroyed () {
    body[0].classList.remove('sidebar-main-menu')
  }
}
</script>
