<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">ข้อมูลเงินเดือน</span>
              </div>
              <div class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-12 pl-sm-3 pr-sm-1 my-1 my-sm-0">
                    <b-button type="submit" variant="primary" @click="clickSave" class="w-100 btn-min-size"><i class="save-data_ct"></i>บันทึก</b-button>
                  </div>
                  <!-- <div class="col-6 pl-sm-3 pr-sm-1 my-1 my-sm-0">
                    <b-button type="submit" variant="none" @click="clickCancel" class="w-100 btn-min-size iq-bg-danger btn-cancel-border">ยกเลิก</b-button>
                  </div> -->
                </div>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" class="table-responsive" style="padding: 0px;">
                  <b-table bordered hover :items="rows" :fields="columns" :current-page="currentPage" :per-page="perPage"
                    @pagination="onPagination" :tbody-tr-class="rowClass" thead-class="table-header-color">
                    <template v-slot:cell(variable1)="data">
                      <span :key="currentPage + data.index">{{ data.item.variable1 }}</span>
                    </template>
                    <template v-slot:cell(variable2)="data">
                      <span  :key="currentPage + data.index">{{ data.item.variable2 }}</span>
                    </template>
                    <template v-slot:cell(variable3)="data">
                      <span  :key="currentPage + data.index">{{ data.item.variable3 }}</span>
                    </template>
                    <template v-slot:cell(variable4)="data">
                      <CurrencyInputCom v-model="data.item.variable4" :options="optionCurrency"  :key="currentPage + data.index"/>
                      <!-- @input="isNumberComma($event, data.item.id)" @keypress="isNumberCheck($event)" -->
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row style="display: flex; justify-content: center;">
                <b-pagination v-model="currentPage" :total-rows="rows.length" :per-page="perPage" />
                <!-- <b-pagination v-if="activeTab === 'tab2'" v-model="currentPage" :total-rows="rows2.length" :per-page="perPage" /> -->
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import axios from 'axios'
import { configAPI } from '../../configBase'
import CurrencyInputCom from './CurrencyInputCom.vue'

export default {
  name: 'UiDataTable',
  components: {
    CurrencyInputCom
  },
  async mounted () {
    this.cln_id = JSON.parse(sessionStorage.getItem('user')).cln_id
    await this.getSalaryOfEmployee()
    xray.index()
  },
  methods: {
    formatNumber (value) {
      if (value === null || value === undefined) {
        return ''
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    async getSalaryOfEmployee () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/salary`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status) {
            const arrList = response.data.data.sort((a, b) => {
              return (new Date(a.created_date) - new Date(b.created_date))
            })
            this.rows = arrList?.map((el, index) => {
              return {
                id: el.id,
                index: index + 1,
                variable1: this.getFormatCitizen(el.citizen_id),
                variable2: el.name + ' ' + el.lastname,
                variable3: el.nickname,
                variable4: el.salary ? this.formatNumber(el.salary) : null
              }
            })
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    getFormatCitizen (citizenId) {
      const regexNumber = /^[0-9]+$/
      if (regexNumber.test(citizenId)) {
        return citizenId?.replace(/(\d{3})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4')
      } else {
        return citizenId
      }
    },
    remove (item) {
      let index = this.rows.indexOf(item)
      this.rows.splice(index, 1)
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick () {
      router.push('/AddProduct')
    },
    isNumberComma (event, id) {
      let indexData = this.rows.findIndex((item) => { return item.id === id })
      let useData = event.target.value

      this.rows[indexData].variable4 = useData.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
    },
    isNumberCheck (event) {
      let useData = event.target.value
      let keyCode = event.keyCode || event.which
      // console.log('useData', useData)
      // Check for valid numeric input
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || useData.indexOf('.') !== -1)
      ) {
        event.preventDefault()
      }

      // Check for more than one decimal point
      /* if (
        useData !== null &&
        useData.indexOf('.') > -1 &&
        useData.split('.')[1].length > 1
      ) {
        event.preventDefault()
      } */

      // Check for leading decimal point
      if ((useData === null || useData === '') && keyCode === 46) {
        event.preventDefault()
      }
    },
    checkError () {
      let error = []
      // const regex1 = /[A-Za-zก-๙]/
      let dataForSave = []
      for (let item of this.rows) {
        /* if (!item.variable4) {
          error.push(`${item.variable2}, กรุณากรอก เงินเดือน`)
        } else if (regex1.test(item.variable4)) {
          error.push(`${item.variable2}, เงินเดือน ต้องเป็นตัวเลขเท่านั้น`)
        } */
        dataForSave.push({
          emp_id: item.id,
          salary: item.variable4 ? item.variable4?.toString().replace(/,/g, '') : null
        })
      }
      this.dataForSave = dataForSave
      return error
    },
    clickSave () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          const errorList = this.checkError()
          if (errorList.length > 0) {
            this.dataForSave = []
            let errMessage = ''
            for (let e = 0; e < errorList.length; e++) {
              errMessage += '<br/>' + errorList[e]
            }
            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลไม่สำเร็จ</span>',
              html: errMessage,
              icon: 'error',
              showConfirmButton: true,
              confirmButtonText: '<b-button style="font-size: 14px; text-align: center">Ok</b-button>',
              confirmButtonColor: '#089bab',
              customClass: {
                confirmButton: 'custom-confirm-button-class mx-1'
              }
            })
            return
          }
          await this.saveSalaryEmployee()
        }
      })
    },
    async saveSalaryEmployee () {
      await axios.request({
        method: 'put',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/salary/`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: this.dataForSave
      })
        .then(async (response) => {
          if (response.data.status) {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              window.location.reload()
            })
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'บันทึกข้อมูลไม่สำเร็จ')
        })
    },
    clickCancel () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะยกเลิกการบันทึกข้อมูลใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          // router.push('/Salary')
          router.reload()
        }
      })
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.id % 2 === 1) return 'table-body-color'
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    }
  },
  data () {
    return {
      cln_id: null,
      optionCurrency: {
        currency: 'EUR',
        currencyDisplay: 'hidden',
        valueRange: {
          min: 0,
          max: 1e+53
        },
        precision: 2,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: true,
        accountingSign: false
      },
      number: {
        decimal: '.',
        separator: ',',
        precision: 2,
        masked: false
      },
      dataForSave: [],
      columns: [
        { label: 'เลขบัตรประจำตัวประชาชน', key: 'variable1', class: 'text-center table-size-200-400' },
        { label: 'ชื่อ-นามสกุล', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'ชื่อเล่น', key: 'variable3', class: 'text-center table-size-200-400' },
        { label: 'เงินเดือน', key: 'variable4', class: 'text-center table-size-200-400' }
      ],
      rows: [
        /* {
          id: 5,
          index: 5,
          variable1: '555-555-555-5555',
          variable2: 'TEST TEST',
          variable3: 'TEST 5',
          variable4: '25,000'
        } */
      ],
      currentPage: 1,
      perPage: 10
    }
  }
}
</script>
