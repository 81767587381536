<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">ประวัติข้อคิดเห็นลูกค้า</span>
              </div>
              <!-- <div class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-lg-5 col-md-5 col-sm-5 col-12 pl-3 pr-2 my-1 my-sm-0">
                    <b-button type="button" variant="primary" @click="handleClick('add')" class="w-100">เพิ่มชื่อผู้ป่วย</b-button>
                  </div>
                  <div class="col-lg-7 col-md-7 col-sm-7 col-12 pl-2 pr-3 my-1 my-sm-0">
                    <b-button type="button" variant="danger" @click="handleClick('service')" style="white-space: nowrap;" class="w-100">เพิ่มประวัติการรับบริการ</b-button>
                  </div>
                </div>
              </div> -->
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row style="justify-content: space-between;">
                <b-col cols="12" md="7" lg="5">
                  <b-row>
                    <b-col sm="12" md class="mb-3">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input v-model="searchData" @keydown="enterSearch" class="form-control" id="exampleInputEmail1" placeholder="Search"
                              style="width: 100%;">
                            <a class="search-link" @click="getCommentHistory"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                      <span class="mx-2 font-size-14 text-primary unselectable">
                        <!-- {{ (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) >
                          rows?.length) ? rows?.length : (perPage * currentPage)) }} -->
                        {{ (rows?.length === 0 ? 0 : ((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) >
                          rows?.length) ? rows?.length : (perPage * currentPage)) + ' of ' + countData }}
                        รายการ
                      </span>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="12" md="auto">
                  <b-row style="justify-content: space-between; align-items: baseline;">
                    <!-- <div class="col-6 pl-sm-3 pr-sm-1 my-1 my-sm-0"> -->
                    <b-col cols="12" sm="8" md="auto" class="mb-3">
                      <b-button type="submit" variant="primary" :disabled="countNotSendMail === 0" @click="sendMail()" class="w-100 btn-min-size"><i
                          class="ri-mail-send-fill"></i>ส่งเมล</b-button>
                    </b-col>
                    <!-- </div> -->

                    <b-col cols="auto" class="mb-3">
                      <span class="unselectable" style="color: black;">( OPD ที่ยังไม่ส่ง : {{ countNotSendMail }} )</span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="table-responsive" style="padding: 0px;">
                  <b-table bordered hover :items="rows" :fields="columns" :current-page="currentPage" :per-page="perPage"
                    @pagination="onPagination" :tbody-tr-class="rowClass" thead-class="table-header-color">
                    <template v-slot:cell(variable1)="data">
                      <span>{{ dateFormat(data.item.hc_created_date) }}</span>
                    </template>
                    <template v-slot:cell(variable2)="data">
                      <span>{{ serviceNameFormat(data.item.serviceData) }}</span>
                    </template>
                    <template v-slot:cell(variable3)="data">
                      <span>{{ nameFormat(data.item.cus_firstname, data.item.cus_lastname) }}</span>
                    </template>
                    <template v-slot:cell(variable4)="data">
                      <span>{{ telephoneFormat(data.item.cus_tel) }}</span>
                    </template>
                    <template v-slot:cell(variable5)="data">
                      <span>{{ nameFormat(data.item.doc_name, data.item.doc_lastname) }}</span>
                    </template>
                    <template v-slot:cell(variable6)="data">
                      <span>{{ data.item.hc_assistant_name || nameFormat(data.item.ass_name, data.item.ass_lastname) }}</span>
                    </template>
                    <template v-slot:cell(variable7)="data">
                      <span v-if="data.item.cc_is_save === false">-</span>

                      <i v-if="data.item.cc_sum_number >= 0 && data.item.cc_sum_number < 5 && data.item.cc_is_save"
                        class="ri-emotion-unhappy-fill score_size color_select_5" />
                      <i v-if="data.item.cc_sum_number >= 5 && data.item.cc_sum_number < 10 && data.item.cc_is_save"
                        class="ri-emotion-unhappy-fill score_size color_select_4" />
                      <i v-if="data.item.cc_sum_number >= 10 && data.item.cc_sum_number < 15 && data.item.cc_is_save"
                        class="ri-emotion-normal-fill score_size color_select_3" />
                      <i v-if="data.item.cc_sum_number >= 15 && data.item.cc_sum_number < 20 && data.item.cc_is_save"
                        class="ri-emotion-happy-fill score_size color_select_2" />
                      <i v-if="data.item.cc_sum_number >= 20 && data.item.cc_sum_number <= 25 && data.item.cc_is_save"
                        class="ri-emotion-happy-fill score_size color_select_1" />
                      <p style="padding: 0; margin: 0;" v-if="data.item.cc_sum_number >= 0 && data.item.cc_sum_number < 5 && data.item.cc_is_save">แย่มาก</p>
                      <p style="padding: 0; margin: 0;" v-if="data.item.cc_sum_number >= 5 && data.item.cc_sum_number < 10 && data.item.cc_is_save">แย่</p>
                      <p style="padding: 0; margin: 0;" v-if="data.item.cc_sum_number >= 10 && data.item.cc_sum_number < 15 && data.item.cc_is_save">พอใช้</p>
                      <p style="padding: 0; margin: 0;" v-if="data.item.cc_sum_number >= 15 && data.item.cc_sum_number < 20 && data.item.cc_is_save">ดี</p>
                      <p style="padding: 0; margin: 0;" v-if="data.item.cc_sum_number >= 20 && data.item.cc_sum_number <= 25 && data.item.cc_is_save">ดีมาก</p>
                    </template>
                    <template v-slot:cell(variable8)="data">
                      <span>{{ data.item.cc_other_suggestions ? data.item.cc_other_suggestions : '-' }}</span>
                    </template>
                    <template v-slot:cell(variable9)="data">
                      <b-button variant=" iq-bg-primary mr-1" size="sm" @click="handleClick(data.item.cc_id)"><i
                          class="ri-eye-fill m-0" /></b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>

              <b-row style="display: flex; justify-content: center;">
                <b-pagination v-model="currentPage" :total-rows="rows?.length" :per-page="perPage" />
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import Axios from 'axios'
import moment from 'moment'
import { configAPI } from '../../configBase'

export default {
  name: 'Comment',
  data () {
    return {
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      usrId: JSON.parse(sessionStorage.getItem('user')).usr_id || null,
      searchData: '',
      columns: [
        { label: 'วันที่รับบริการ', key: 'variable1', class: 'text-center table-size-200-400' },
        { label: 'ประเภทการบริการ', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'ชื่อลูกค้า', key: 'variable3', class: 'text-center table-size-200-400' },
        { label: 'เบอร์ติดต่อ', key: 'variable4', class: 'text-center table-size-200-400' },
        { label: 'แพทย์', key: 'variable5', class: 'text-center table-size-200-400' },
        { label: 'ผู้ช่วย', key: 'variable6', class: 'text-center table-size-200-400' },
        { label: 'ผลการประเมิน', key: 'variable7', class: 'text-center table-size-200-400' },
        { label: 'ข้อเสนอแนะ', key: 'variable8', class: 'text-center table-size-200-400' },
        { label: 'ดูข้อมูล', key: 'variable9', class: 'text-center table-size-100-300' }
      ],
      rows: [],
      currentPage: 1,
      perPage: 10,
      countData: 0,
      countNotSendMail: 0
    }
  },
  methods: {
    dateFormat (data) {
      if (data) {
        return moment(data).add(543, 'year').format('DD/MM/YY : HH.mm น.')
      } else {
        return '-'
      }
    },
    serviceNameFormat (data) {
      if (data.length > 0) {
        let text = ''

        for (let item of data) {
          text += `${item.sg_name} (${item.st_name}), `
        }

        return text.substring(0, text.length - 2)
      } else {
        return '-'
      }
    },
    telephoneFormat (data) {
      if (data) {
        let temp = data.split('')
        let temp2 = ''

        temp.map((item, index) => {
          if (index === 2 || index === 5) {
            temp2 += item + '-'
          } else {
            temp2 += item
          }
        })

        return temp2
      } else {
        return '-'
      }
    },
    nameFormat (data1, data2) {
      return `${data1} ${data2}`
    },
    numberWithCommas (data) {
      return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick (id) {
      router.push(`/CommentDetail/${id}`)
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    async getCommentHistory () {
      let dataSearch = this.searchData.trim()

      await Axios.request({
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/customerFeedback?search=${dataSearch}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        this.rows = response.data.data?.map(el => {
          el.cc_sum_number = Number(el.cc_sum)
          return el
        })
        this.countData = response.data.data.length // response.data.count
        this.countNotSendMail = response.data.count_yetmail
      }).catch((error) => {
        this.SwalError(error.response.data.message, 'ไม่พบข้อมูล')
      })
    },
    async sendMail () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะส่งอีเมลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.confirmSendMail()
        }
      }).catch((error) => {
        this.SwalError(error.message, 'แก้ไขข้อมูลไม่สำเร็จ')
      })
    },
    async confirmSendMail () {
      Vue.swal.fire({
        title: '<span class="loading-icon"><i class="fas fa-spinner fa-spin"></i></span>',
        text: 'กำลังส่งอีเมล...',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          Vue.swal.getHtmlContainer().querySelector('.loading-icon').style.fontSize = '40px'
        }
      })
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/feedback/${this.usrId}/sendMail`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(async (response) => {
        Vue.swal.close()
        if (response.data.status) {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">ส่งอีเมลสำเร็จ</span>',
            // text: 'กรุณารอการตอบรับจากหมอ',
            icon: 'success',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 2000
          }).then(async () => {
            await this.getCommentHistory()
          })
        } else {
          this.SwalError(response.data.error, 'ส่งอีเมลไม่สำเร็จ')
        }
      }).catch((error) => {
        Vue.swal.close()
        this.SwalError(error.response.data.message, 'ส่งอีเมลไม่สำเร็จ')
      })
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getCommentHistory()
      }
    }
  },
  async mounted () {
    xray.index()
    await this.getCommentHistory()
  }
}
</script>

<style scoped>
.color_not_select {
  color: #9FA1A3;
}

.color_select_1 {
  color: #1ea757;
}

.color_select_2 {
  color: #73be86;
}

.color_select_3 {
  color: #f6ca11;
}

.color_select_4 {
  color: #ffa422;
}

.color_select_5 {
  color: #fa4e4e;
}

.score_size {
  font-size: 50px;
}
</style>
