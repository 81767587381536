<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="table-responsive">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span v-if="setPage() === 'add'" style="font-size: 25px; color: black;">กลุ่มวัตถุดิบ
                  (เพิ่ม)</span>
                <span v-else-if="setPage() === 'edit'"
                  style="font-size: 25px; color: black;">กลุ่มวัตถุดิบ (แก้ไข)</span>
                <span v-else style="font-size: 25px; color: black;">กลุ่มวัตถุดิบ (ดูรายละเอียด)</span>
              </div>
              <div v-if="setPage() === 'add' || setPage() === 'edit'" class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-6 pl-3 pr-2 my-1 my-sm-0">
                    <b-button type="submit" variant="primary" @click="handleClick(true)" class="w-100">บันทึก</b-button>
                  </div>
                  <div class="col-6 pl-2 pr-3 my-1 my-sm-0">
                    <b-button type="submit" variant="danger" @click="handleClick(false)" class="w-100">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
              <div v-else class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="submit" variant="danger" @click="cancelClick()" class="w-100">ยกเลิก</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div>
              <b-row>
                <b-col class="">
                  <b-col lg="6" sm="6">
                    <b-text style="color: black;">รหัสวัตถุดิบ</b-text>
                  </b-col>
                  <b-col lg="4" sm="8">
                    <b-input v-if="setPage() === 'add'" v-model="supplier.var_supplier1" class="my-3"></b-input>
                    <b-input v-else-if="setPage() === 'view'" v-model="rows[0].variable1" class="my-3" disabled></b-input>
                    <b-input v-else v-model="rows[0].variable1" class="my-3"></b-input>
                  </b-col>
                  <b-col lg="6" sm="6">
                    <b-text style="color: black;">ชื่อกลุ่มวัตถุดิบ</b-text>
                  </b-col>
                  <b-col lg="4" sm="8">
                    <b-input v-if="setPage() === 'add'" v-model="supplier.var_supplier2" class="my-3"></b-input>
                    <b-input v-else-if="setPage() === 'view'" v-model="rows[0].variable2" class="my-3" disabled></b-input>
                    <b-input v-else v-model="rows[0].variable2" class="my-3"></b-input>
                  </b-col>
                  <br>
                  <br>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
</b-container></template>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'

export default {
  name: 'UiDataTable',
  data () {
    return {
      showModal: false,
      imageUrl: null,
      imageUrl2: null,
      activeTab: 'tab1',
      currentPage: 1,
      perPage: 10,
      supplier: {
        var_supplier1: null,
        var_supplier2: null,
        var_supplier3: null
      },
      rows: [
        {
          id: 1,
          index: 1,
          variable1: '1',
          variable2: 'Botulinum Toxin',
          variable3: '',
          variable4: '',
          variable5: '',
          variable6: '',
          editable: false
        },
        {
          id: 2,
          index: 2,
          variable1: '2',
          variable2: 'Chlohexidine',
          variable3: '',
          variable4: '',
          variable5: '',
          variable6: '',
          editable: false
        },
        {
          id: 3,
          index: 3,
          variable1: '3',
          variable2: 'Filler',
          variable3: '',
          variable4: '',
          variable5: '',
          variable6: '',
          editable: false
        },
        {
          id: 4,
          index: 4,
          variable1: '4',
          variable2: 'Gauze',
          variable3: '',
          variable4: '',
          variable5: '',
          variable6: '',
          editable: false
        },
        {
          id: 5,
          index: 5,
          variable1: '5',
          variable2: 'Iodine',
          variable3: '',
          variable4: '',
          variable5: '',
          variable6: '',
          editable: false
        },
        {
          id: 6,
          index: 6,
          variable1: '6',
          variable2: 'Meso Fat',
          variable3: '',
          variable4: '',
          variable5: '',
          variable6: '',
          editable: false
        },

        {
          id: 7,
          index: 7,
          variable1: '7',
          variable2: 'Meso หน้าใส',
          variable3: '',
          variable4: '',
          variable5: '',
          variable6: '',
          editable: false
        },
        {
          id: 8,
          index: 8,
          variable1: '8',
          variable2: 'Silicone',
          variable3: '',
          variable4: '',
          variable5: '',
          variable6: '',
          editable: false
        }
      ]
    }
  },
  methods: {
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    setPage () {
      return this.$route.params.setPage
    },
    handleImageUpload (event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.imageUrl = reader.result
      }
    },
    handleImageUpload2 (event) {
      const file2 = event.target.files[0]
      const reader2 = new FileReader()
      reader2.readAsDataURL(file2)
      reader2.onload = () => {
        this.imageUrl2 = reader2.result
      }
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick (status) {
      if (status) {
        // if (!this.supData.sup_name?.trim()) {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            // let index = this.rows.indexOf(item)
            // this.rows.splice(index, 1)
            router.push('/MaterialGroup')
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
              text: '',
              icon: 'success',
              confirmButtonText: '<b-button style="font-size: 14px; text-align: center">OK</b-button>', // Adjust the confirm button font size
              customClass: {
                confirmButton: 'custom-confirm-button-class'
              },
              confirmButtonColor: '#099FB0'
            })
          }
        })
        // }
      } else {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            router.push('/MaterialGroup')
          }
        })
      }
    },
    cancelClick () {
      router.push('/MaterialGroup')
    },
    addSupplier () {
      let temp = {
        variable1: this.supplier.var_supplier1,
        variable2: this.supplier.var_supplier2,
        variable3: this.supplier.var_supplier3,
        editable: false
      }
      this.rows.unshift(temp)
      // console.log('temp', temp)
    },
    setEditSupplier (data, index) {
      this.supplier.var_supplier1 = data.variable1
      this.supplier.var_supplier2 = data.variable2
      this.supplier.var_supplier3 = data.variable3
      this.indexTableEdit = index
    },
    editSupplier () {
      this.rows[this.indexTableEdit].id = this.rows[this.indexTableEdit].id || ''
      this.rows[this.indexTableEdit].variable1 = this.supplier.var_supplier1
      this.rows[this.indexTableEdit].variable2 = this.supplier.var_supplier2
      this.rows[this.indexTableEdit].variable3 = this.supplier.var_supplier3
      this.rows[this.indexTableEdit].editable = this.rows[this.indexTableEdit].editable || false
      // console.log('editSupplier', this.rows)
    }
  },
  mounted () {
    xray.index()
  }
}
</script>
