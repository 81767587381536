<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="table-responsive">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span v-if="mode === 'add'" style="font-size: 25px; color: black;">ทะเบียนลูกค้า / ผู้ป่วย
                  (เพิ่ม)</span>
                <span v-else-if="mode === 'edit'"
                  style="font-size: 25px; color: black;">ทะเบียนลูกค้า / ผู้ป่วย (แก้ไข)</span>
                <span v-else style="font-size: 25px; color: black;">ทะเบียนลูกค้า / ผู้ป่วย (ดูรายละเอียด)</span>
                <!-- <span class="px-3" style="font-size: 25px; color: black;">ทะเบียนลูกค้า / ผู้ป่วย</span> -->
              </div>
              <div v-if="mode === 'add' || mode === 'edit'" class="col-12 col-md-auto col-sm-12 col-lg-auto col-res-register">
                <div class="row">
                  <div class="res-50-flex-register col-6 col-sm-auto pr-1 pad-custom-register pr-sm-2 my-1 my-sm-0 d-flex align-items-center justifiy-items-center">
                    <b-button v-if="mode === 'add'" type="button" variant="primary" @click="handleClick" class="w-100 btn-min-size"><i class="save-data_ct"/>บันทึก</b-button>
                    <b-button v-else-if="mode === 'edit'" type="button" variant="primary" @click="handleClickEdit" class="w-100 btn-min-size"><i class="save-data_ct"/>บันทึก</b-button>
                  </div>
                  <div class="res-50-flex-register col-6 col-sm-auto pl-1 px-sm-2 my-1 my-sm-0 d-flex align-items-center justifiy-items-center">
                    <b-button type="button" variant="none" @click="handleClickBack" class="iq-bg-danger w-100 btn-min-size btn-cancel-border">ยกเลิก</b-button>
                  </div>
                  <div class="col-12 col-sm-auto px-sm-2 my-1 res-100-flex-register" style="padding-left: .5rem !important;">
                    <b-button variant="primary" @click="fetchData"  class="my-1 flex-fill text-center w-100 mar-t-register" style="text-align: center;"><i class="credit-card_ct"/>อ่านบัตรประชาชน</b-button>
                  </div>
                </div>
              </div>
              <div v-else class="col-12 col-edit-response-register col-sm-auto my-1 my-sm-0">
                <div class="d-flex flex-wrap my-2" style="white-space: nowrap;">
                  <div class="response-1button-header">
                    <b-button type="submit" variant="none" @click="handleClickBack" class="iq-bg-danger w-100 btn-min-size btn-cancel-border">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col lg="8" sm="12" class="format-header-citizen-details">
                  <div style="color: black; font-size: large; white-space: nowrap;">ข้อมูลผู้แล</div>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="8" sm="12">
                  <b-row>
                    <b-col lg="6" sm="10">
                      <hr>
                      <b-form-group label="ผู้ดูแล" label-for="province">
                        <template v-if="registerData.sale === null || registerData.sale === ''" v-slot:label>
                          ผู้ดูแล <span style="color: red;"> *</span>
                        </template>
                        <v-select v-model="registerData.sale" :disabled="isDisable" :options="sales"></v-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <br>
              <b-row>
                <b-col lg="8" sm="12" class="format-header-citizen-details">
                  <div style="color: black; font-size: large; white-space: nowrap;">ข้อมูลบัตรประชาชน</div>
                  <b-col lg="1" sm="1" class="format-col-pencil">
                    <b-button :disabled="isDisable" type="submit" variant=" iq-bg-success mr-1" @click="editCitizenDetails = !editCitizenDetails" size="sm" class="format-col-pencil-btn"><i class="ri-ball-pen-fill m-0"></i></b-button>
                  </b-col>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="8" sm="12" class="format-header-citizen-details">
                  <!-- <b-form-group label="Individual radios" v-slot="{ ariaDescribedby }">
                  </b-form-group> -->
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="8" sm="12">
                  <hr>
                  <b-row>
                    <b-col lg="12" sm="12">
                      <div style="display: flex; gap: 10px; margin-bottom: 10px;">
                        <b-form-radio v-model="selected" :disabled="editCitizenDetails" :aria-describedby="ariaDescribedby" name="some-radios" value="citizenId">เลขบัตรประชาชน</b-form-radio>
                        <b-form-radio v-model="selected" :disabled="editCitizenDetails" :aria-describedby="ariaDescribedby" name="some-radios" value="passport">พาสปอร์ต</b-form-radio>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="4" sm="4">
                      <b-form-group label="ชื่อ" label-for="name">
                        <template v-if="registerData.name === null || registerData.name?.trim() === ''" v-slot:label>
                          ชื่อ<span style="color: red;"> *</span>
                        </template>
                        <b-form-input v-model="registerData.name" id="name" :disabled="editCitizenDetails"></b-form-input>
                      </b-form-group>
                      <!-- <b-text style="color: black;">ชื่อ <span style="color: red;" v-if="registerData.name === null || registerData.name?.trim() === ''">*</span></b-text>
                      <b-form-input type="text" v-model="registerData.name" placeholder="" :disabled="editCitizenDetails"></b-form-input> -->
                    </b-col>
                    <b-col lg="4" sm="4">
                    <b-form-group label="นามสกุล" label-for="lastName">
                        <template v-if="registerData.lastName === null || registerData.lastName === ''" v-slot:label>
                          นามสกุล<span style="color: red;"> *</span>
                        </template>
                        <b-form-input v-model="registerData.lastName" id="lastName" :disabled="editCitizenDetails"></b-form-input>
                      </b-form-group>
                      <!-- <b-text style="color: black;">นามสกุล <span style="color: red;" v-if="registerData.lastName === null || registerData.lastName?.trim() === ''">*</span></b-text>
                      <b-form-input type="text" placeholder="" v-model="registerData.lastName" :disabled="editCitizenDetails"></b-form-input> -->
                    </b-col>
                    <b-col lg="2" sm="2">
                    <b-form-group label="ชื่อเล่น" label-for="nickName">
                        <!-- <template v-if="registerData.nickName === null || registerData.nickName?.trim() === ''" v-slot:label>
                          ชื่อเล่น<span style="color: red;"> *</span>
                        </template> -->
                        <b-form-input v-model="registerData.nickName" id="nickName" :disabled="isDisable"></b-form-input>
                      </b-form-group>
                      <!-- <b-text style="color: black;">ชื่อเล่น <span style="color: red;" v-if="registerData.nickName === null || registerData.nickName?.trim() === ''">*</span></b-text>
                      <b-form-input :disabled="isDisable" type="text" v-model="registerData.nickName" placeholder=""></b-form-input> -->
                    </b-col>
                    <b-col lg="2" sm="2">
                      <b-form-group label="เพศ" label-for="gender">
                        <template v-if="registerData.gender === null || registerData.gender?.trim() === ''" v-slot:label>
                          เพศ<span style="color: red;"> *</span>
                        </template>
                        <v-select v-model="gendersSelect" :disabled="isDisable" :options="genderList"></v-select>
                        <!-- <select style="form-control" v-model="registerData.gender" :disabled="editCitizenDetails">
                          <option value="ชาย">ชาย</option>
                          <option value="หญิง">หญิง</option>
                        </select> -->
                        <!-- <b-form-input v-model="registerData.gender" id="gender" :disabled="editCitizenDetails"></b-form-input> -->
                      </b-form-group>
                      <!-- <b-text style="color: black;">เพศ <span style="color: red;" v-if="registerData.gender === null || registerData.gender?.trim() === ''">*</span></b-text>
                      <b-form-input type="text" placeholder="" v-model="registerData.gender" :disabled="editCitizenDetails"></b-form-input> -->
                    </b-col>
                  </b-row>
                  <b-row class="">
                    <b-col lg="6" sm="6">
                      <b-form-group label="วันเดือนปีเกิด" label-for="birthday">
                        <template v-if="registerData.birthday === null || registerData.birthday?.trim() === ''" v-slot:label>
                          วันเดือนปีเกิด<span style="color: red;"> *</span>
                        </template>
                        <div style="position: relative;" class="">
                          <date-picker placeholder="DD/MM/YYYY" id="datepicker-custom3" v-model="registerData.birthday" :disabled="editCitizenDetails" name="date" :config="options"></date-picker>
                          <i class="ri-calendar-line m-0 icon-calendar-edit" :disabled="editCitizenDetails" @click="onClickDatePicker(3)" style="cursor: pointer;"></i>
                        </div>
                      </b-form-group>
                      <!-- <b-text style="color: black; white-space: nowrap">วันเดือนปีเกิด <span style="color: red;" v-if="registerData.birthday === null || registerData.birthday?.trim() === ''">*</span></b-text> -->
                    </b-col>
                    <b-col v-if="selected === 'citizenId'" lg="6" sm="6">
                      <b-form-group label="เลขบัตรประชาชน" label-for="citizenId">
                        <template v-if="registerData.citizenId === null || registerData.citizenId === '' || registerData.citizenId?.trim().replace(/-/g, '').length < 4" v-slot:label>
                          เลขบัตรประชาชน<span style="color: red;"> *</span>
                        </template>
                        <the-mask mask="#-####-#####-##-#" class="form-control" v-model="registerData.citizenId" type="tel" :masked="false" placeholder="" :disabled="editCitizenDetails"></the-mask>
                      </b-form-group>
                    </b-col>

                    <b-col v-else lg="6" sm="6">
                      <b-form-group label="พาสปอร์ต" label-for="passport">
                        <template v-if="registerData.passport === null || registerData.passport === ''" v-slot:label>
                          พาสปอร์ต<span style="color: red;"> *</span>
                        </template>
                        <b-form-input type="text" v-model="registerData.passport" :disabled="editCitizenDetails" placeholder=""></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="6" sm="6">
                      <b-form-group label="เบอร์โทรศัพท์" label-for="phone">
                        <template v-if="registerData.phone === null || registerData.phone?.trim() === '' || registerData.phone?.trim().replace(/-/g, '').length < 10" v-slot:label>
                          เบอร์โทรศัพท์ <span style="color: red;"> *</span>
                        </template>
                        <div style="position: relative;">
                          <the-mask mask="###-###-####" class="form-control" v-model="registerData.phone" type="tel" :masked="false" placeholder="xxx-xxx-xxxx" :disabled="isDisable"></the-mask>
                          <button
                            class="btn btn-primary"
                            style="position: absolute; right: 0; top: 0; height: 100%; border-radius: 10px;"
                            @click="genQRCode()"
                            > Gen QR </button>
                            <!-- :disabled="registerData.phone === null || registerData.phone?.trim() === '' || registerData.phone?.trim().replace(/-/g, '').length < 10" -->
                        </div>
                      </b-form-group>
                      <!-- <b-text style="color: black;">เบอร์โทรศัพท์ <span style="color: red;" v-if="registerData.phone === null || registerData.phone?.trim() === '' || registerData.phone?.trim().replace(/-/g, '').length < 10">*</span></b-text> -->
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-group label="ข้อมูล HN" label-for="phone">
                        <!-- <template v-if="registerData.phone === null || registerData.phone?.trim() === '' || registerData.phone?.trim().replace(/-/g, '').length < 10" v-slot:label>
                          เบอร์โทรศัพท์ <span style="color: red;"> *</span>
                        </template> -->
                        <b-form-input type="text" v-model="registerData.HNData" :disabled="true" placeholder=""></b-form-input>
                      </b-form-group>
                        <!-- <the-mask mask="###-###-####" class="form-control" v-model="registerData.phone" type="tel" :masked="false" placeholder="xxx-xxx-xxxx" :disabled="isDisable"></the-mask> -->
                      <!-- <b-text style="color: black;">ข้อมูล HN</b-text> -->
                    </b-col>
                  </b-row>
                  <br>
                  <div style="color: black; font-size: large;">ข้อมูลส่วนบุคคล</div>
                  <hr>
                  <!-- <br> -->
                  <b-row>
                    <b-col lg="6" sm="6">
                      <b-form-group label="จังหวัด" label-for="province">
                        <template v-if="registerData.province === null || registerData.province === ''" v-slot:label>
                          จังหวัด <!-- <span style="color: red;"> *</span> -->
                        </template>
                        <v-select v-model="provincesSelect" :disabled="isDisable" :options="provinces"></v-select>
                      </b-form-group>
                      <!-- <the-mask mask="###-###-####" class="form-control" v-model="registerData.phone" type="tel" :masked="false" placeholder="xxx-xxx-xxxx" :disabled="isDisable"></the-mask> -->
                      <!-- <label class="typo__label" style="margin: 0;">จังหวัด <span style="color: red;" v-if="registerData.province === null || registerData.province === ''">*</span></label> -->
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-group label="อำเภอ / เขต" label-for="district">
                        <template v-if="(registerData.district === null || registerData.district === '') && districts?.length > 0" v-slot:label>
                          อำเภอ / เขต <!-- <span style="color: red;"> *</span> -->
                        </template>
                        <v-select v-model="districtsSelect" :disabled="isDisable || provincesSelect === null || registerData.province === null || districts?.length <= 0" :options="districts"></v-select>
                      </b-form-group>
                      <!-- <v-select v-model="provincesSelect" :disabled="isDisable" :options="provinces"></v-select> -->
                      <!-- <label class="typo__label" style="margin: 0;">อำเภอ / เขต <span style="color: red;" v-if="registerData.district === null || registerData.district === ''">*</span></label> -->
                    </b-col>
                  </b-row>
                  <!-- <br> -->
                  <b-row>
                    <b-col lg="6" sm="6">
                      <b-form-group label="ตำบล / แขวง" label-for="subdistrict">
                        <template v-if="(registerData.subdistrict === null || registerData.subdistrict === '') && subdistricts?.length > 0" v-slot:label>
                          ตำบล / แขวง <!-- <span style="color: red;"> *</span> -->
                        </template>
                        <v-select v-model="subdistrictsSelect" :disabled="isDisable || provincesSelect === null || districtsSelect === null || registerData.district === null || subdistricts?.length <= 0" :options="subdistricts"></v-select>
                      </b-form-group>
                      <!-- <v-select v-model="districtsSelect" :disabled="isDisable || provincesSelect === null" :options="districts"></v-select> -->
                      <!-- <label class="typo__label" style="margin: 0;">ตำบล / แขวง <span style="color: red;" v-if="registerData.subdistrict === null || registerData.subdistrict === ''">*</span></label> -->
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-group label="รหัสไปรษณีย์" label-for="zipCode">
                        <template v-if="registerData.zipCode === null || registerData.zipCode === '' || registerData.zipCode?.trim().length < 5" v-slot:label>
                          รหัสไปรษณีย์ <!-- <span style="color: red;"> *</span> -->
                        </template>
                        <the-mask mask="#####" class="form-control" v-model="registerData.zipCode" :disabled="isDisable"></the-mask>
                      </b-form-group>
                        <!-- <v-select v-model="subdistrictsSelect" :disabled="isDisable || provincesSelect === null || districtsSelect === null" :options="subdistricts"></v-select> -->
                        <!-- <v-select v-model="districtsSelect" :disabled="isDisable || provincesSelect === null" :options="districts"></v-select> -->
                      <!-- <label class="typo__label" style="margin: 0;">รหัสไปรษณีย์ <span style="color: red;" v-if="registerData.zipCode === null || registerData.zipCode === '' || registerData.zipCode?.trim().length < 5">*</span></label> -->
                    </b-col>
                  </b-row>
                  <!-- <br> -->
                  <b-row>
                    <b-col lg="6" sm="6">
                      <b-form-group label="E-mail" label-for="email">
                        <!-- <template v-if="registerData.email === null || registerData.email === ''" v-slot:label>
                          E-mail<span style="color: red;"> *</span>
                        </template> -->
                        <b-form-input v-model="registerData.email" id="emp_no" :disabled="isDisable"></b-form-input>
                      </b-form-group>
                      <!-- <b-text style="color: black;">E-mail</b-text>
                      <b-form-input :disabled="isDisable" type="text" v-model="registerData.email" placeholder=""></b-form-input> -->
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-group label="Line" label-for="line">
                        <!-- <template v-if="registerData.line === null || registerData.line === ''" v-slot:label>
                          Line<span style="color: red;"> *</span>
                        </template> -->
                        <b-form-input v-model="registerData.line" id="emp_no" :disabled="isDisable"></b-form-input>
                      </b-form-group>
                      <!-- <b-text style="color: black;">Line</b-text>
                      <b-form-input :disabled="isDisable" type="text" v-model="registerData.line" placeholder=""></b-form-input> -->
                    </b-col>
                  </b-row>
                  <!-- <br> -->
                  <b-row>
                    <b-col lg="6" sm="6">
                      <b-form-group label="น้ำหนัก" label-for="weigth">
                        <template v-if="registerData.weigth === null || registerData.weigth === ''" v-slot:label>
                          น้ำหนัก<!-- <span style="color: red;"> *</span> -->
                        </template>
                        <!-- <b-form-input v-model="registerData.weigth" id="emp_no" :disabled="isDisable"></b-form-input> -->
                        <CurrencyInputCom v-model="registerData.weigth" :options="optionCurrency0" :disabled="isDisable"/>
                      </b-form-group>
                      <!-- <b-text style="color: black;">น้ำหนัก <span style="color: red;" v-if="registerData.weigth === null || registerData.weigth?.trim() === ''">*</span></b-text>
                      <b-form-input :disabled="isDisable" type="text" v-model="registerData.weigth" @keypress="isNumberCheck($event, false)"></b-form-input> -->
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-group label="ส่วนสูง" label-for="height">
                        <template v-if="registerData.height === null || registerData.height === ''" v-slot:label>
                          ส่วนสูง<!-- <span style="color: red;"> *</span> -->
                        </template>
                        <!-- <b-form-input v-model="registerData.height" id="emp_no" :disabled="isDisable"></b-form-input> -->
                        <CurrencyInputCom v-model="registerData.height" :options="optionCurrency0" :disabled="isDisable"/>
                      </b-form-group>
                      <!-- <b-text style="color: black;">ส่วนสูง <span style="color: red;" v-if="registerData.height === null || registerData.height?.trim() === ''">*</span></b-text>
                      <b-form-input :disabled="isDisable" type="text" v-model="registerData.height" @keypress="isNumberCheck($event, false)"></b-form-input> -->
                    </b-col>
                  </b-row>
                  <!-- <br> -->
                  <b-row>
                    <b-col lg="6" sm="6">
                      <b-form-group label="ศาสนา" label-for="religion">
                        <template v-if="registerData.religion === null || registerData.religion === ''" v-slot:label>
                          ศาสนา<!-- <span style="color: red;"> *</span> -->
                        </template>
                        <v-select v-model="religionsSelect" :disabled="isDisable" :options="religions"></v-select>
                      </b-form-group>
                      <!-- <b-form-input v-model="registerData.height" id="emp_no" :disabled="isDisable"></b-form-input> -->
                      <!-- <label class="typo__label" style="margin: 0;">ศาสนา <span style="color: red;" v-if="registerData.religion === null || registerData.religion === ''">*</span></label> -->
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-group label="กรุ๊ปเลือด" label-for="blood">
                        <template v-if="registerData.blood === null || registerData.blood === ''" v-slot:label>
                          กรุ๊ปเลือด<!-- <span style="color: red;"> *</span> -->
                        </template>
                        <v-select v-model="bloodsSelect" :disabled="isDisable" :options="bloods"></v-select>
                      </b-form-group>
                      <!-- <b-form-input v-model="registerData.height" id="emp_no" :disabled="isDisable"></b-form-input> -->
                      <!-- <label class="typo__label" style="margin: 0;">กรุ๊ปเลือด <span style="color: red;" v-if="registerData.blood === null || registerData.blood === ''">*</span></label> -->
                    </b-col>
                  </b-row>
                  <!-- <br> -->
                  <b-row>
                    <b-col lg="6" sm="6">
                      <b-form-group label="ประวัติการแพ้ยา" label-for="allergic">
                        <!-- <template v-if="registerData.allergic === null || registerData.allergic === ''" v-slot:label>
                          ประวัติการแพ้ยา<span style="color: red;"> *</span>
                        </template>
                        <v-select v-model="bloodsSelect" :disabled="isDisable" :options="bloods"></v-select> -->
                        <b-form-input :disabled="isDisable" type="text" v-model="registerData.allergic" placeholder=""></b-form-input>
                      </b-form-group>
                      <!-- <b-text style="color: black;">ประวัติการแพ้ยา</b-text> -->
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-group label="ประวัติการแพ้อาหาร" label-for="foodAllergies">
                        <!-- <template v-if="registerData.blood === null || registerData.blood === ''" v-slot:label>
                          ประวัติการแพ้อาหาร<span style="color: red;"> *</span>
                        </template> -->
                        <!-- <v-select v-model="bloodsSelect" :disabled="isDisable" :options="bloods"></v-select> -->
                        <b-form-input :disabled="isDisable" type="text" v-model="registerData.foodAllergies" placeholder=""></b-form-input>
                      </b-form-group>
                      <!-- <b-text style="color: black;">ประวัติการแพ้อาหาร</b-text> -->
                    </b-col>
                  </b-row>
                  <!-- <br> -->
                  <b-row>
                    <b-col lg="6" sm="6">
                      <b-form-group label="ความดัน(mmhg)" label-for="blood">
                        <template v-if="registerData.bloodPressure === null || registerData.bloodPressure?.toString().trim() === ''" v-slot:label>
                          ความดัน(mmhg)<!-- <span style="color: red;"> *</span> -->
                        </template>
                        <!-- <the-mask mask="######" :tokens="hexTokens" class="form-control" v-model="registerData.bloodPressure" :disabled="isDisable"></the-mask> -->
                        <b-form-input :disabled="isDisable" v-model="registerData.bloodPressure" type="text" placeholder=""></b-form-input>
                        <!-- <CurrencyInputCom v-model="registerData.bloodPressure" :options="optionCurrency0" :disabled="isDisable"/> -->
                      </b-form-group>
                        <!-- <v-select v-model="bloodsSelect" :disabled="isDisable" :options="bloods"></v-select> -->
                      <!-- <b-text style="color: black;">ความดัน(mmhg) <span style="color: red;" v-if="registerData.bloodPressure === null || registerData.bloodPressure?.trim() === ''">*</span></b-text> -->
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-group label="อุณหภูมิร่างกาย(°C)" label-for="blood">
                        <template v-if="registerData.bodyTem === null || registerData.bodyTem?.toString().trim() === ''" v-slot:label>
                          อุณหภูมิร่างกาย(°C)<!-- <span style="color: red;"> *</span> -->
                        </template>
                        <!-- <b-form-input :disabled="isDisable" type="text" v-model="registerData.bodyTem" @keypress="isNumberCheck($event, false)" placeholder=""></b-form-input> -->
                        <CurrencyInputCom v-model="registerData.bodyTem" :options="optionCurrency0" :disabled="isDisable"/>
                      </b-form-group>
                      <!-- <b-form-input :disabled="isDisable" type="text" v-model="registerData.bloodPressure" @keypress="isNumberCheck($event, false)" placeholder=""></b-form-input> -->
                      <!-- <b-text style="color: black;">อุณหภูมิร่างกาย(°C) <span style="color: red;" v-if="registerData.bodyTem === null || registerData.bodyTem?.trim() === ''">*</span></b-text> -->
                    </b-col>
                  </b-row>
                  <!-- <br> -->
                  <b-row>
                    <b-col lg="6" sm="6">
                      <b-form-group label="ไขมัน(%)" label-for="blood">
                        <template v-if="registerData.fat === null || registerData.fat?.toString().trim() === ''" v-slot:label>
                          ไขมัน(%)<!-- <span style="color: red;"> *</span> -->
                        </template>
                        <!-- <b-form-input :disabled="isDisable" type="text" v-model="registerData.fat" @keypress="isNumberCheck($event, false)" /> -->
                        <CurrencyInputCom v-model="registerData.fat" :options="optionCurrency0" :isLimit="true" :disabled="isDisable"/>
                      </b-form-group>
                      <!-- <b-form-input :disabled="isDisable" type="text" v-model="registerData.bodyTem" @keypress="isNumberCheck($event, false)" placeholder=""></b-form-input> -->
                      <!-- <b-text style="color: black;">ไขมัน(%) <span style="color: red;" v-if="registerData.fat === null || registerData.fat?.trim() === ''">*</span></b-text> -->
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-group label="กล้ามเนื้อ(%)" label-for="blood">
                        <template v-if="registerData.muscle === null || registerData.muscle?.toString().trim() === ''" v-slot:label>
                          กล้ามเนื้อ(%)<!-- <span style="color: red;"> *</span> -->
                        </template>
                        <CurrencyInputCom v-model="registerData.muscle" :options="optionCurrency0" :isLimit="true" :disabled="isDisable"/>
                        <!-- <b-form-input :disabled="isDisable" type="text" v-model="registerData.muscle" @keypress="isNumberCheck($event, false)" /> -->
                      </b-form-group>
                      <!-- <b-text style="color: black;">กล้ามเนื้อ(%) <span style="color: red;" v-if="registerData.muscle === null || registerData.muscle?.trim() === ''">*</span></b-text>
                      <b-form-input :disabled="isDisable" type="text" v-model="registerData.muscle" @keypress="isNumberCheck($event, false)"></b-form-input> -->
                    </b-col>
                  </b-row>
                  <!-- <br> -->
                  <b-row>
                    <b-col lg="6" sm="6">
                      <b-form-group label="ค่า BMI" label-for="blood">
                        <template v-if="registerData.BMI === null || registerData.BMI?.toString().trim() === ''" v-slot:label>
                          ค่า BMI<!-- <span style="color: red;"> *</span> -->
                        </template>
                        <CurrencyInputCom v-model="registerData.BMI" :options="optionCurrency" :disabled="isDisable" />
                      </b-form-group>
                      <!-- <b-form-input :disabled="isDisable" type="text" v-model="registerData.muscle" @keypress="isNumberCheck($event, false)" /> -->
                       <!-- <b-text style="color: black;">ค่า BMI <span style="color: red;" v-if="registerData.BMI === null || registerData.BMI?.toString().trim() === ''">*</span></b-text> -->
                    </b-col>
                  </b-row>
                  <br>
                </b-col>
                <b-col lg="4" sm="12">
                  <b-form class="file-response-form">
                    <template>
                      <div class="mt-2">
                        <b-form-group label-for="imageInput2" class="del-mar-fileinput-user" label="อัปโหลดรูปภาพ">
                          <b-form-file
                          class="custom-file-style"
                            id="imageInput2"
                            accept="image/*"
                            :placeholder="registerData.imageName ? registerData.imageName : 'Choose a file'"
                            @change="handleImageUpload"
                            :disabled="isDisable"
                          ></b-form-file>
                        </b-form-group>
                        <div v-if="(registerData.imageUrl !== null || registerData.imageUrl !== '') && (registerData.imageFile !== null || imageUrlbase64 !== null)" style="text-align: center; margin-top: 2rem;">
                          <img :src="registerData.imageUrl" alt="Uploaded Image" width="auto" height="200" style="max-width:350px"/>
                        </div>
                        <!-- <div v-else-if="(registerData.imageUrl !== null || registerData.imageUrl !== '') && registerData.imageFile !== null" style="text-align: center; margin-top: 2rem;">
                          <img :src="registerData.imageUrl" alt="Uploaded Image" width="auto" height="200" style="max-width:350px"/>
                        </div> -->
                        <div v-else style="text-align: center; margin-top: 2rem;">
                          <img :src="getFullImagePath(registerData.imageUrl)" alt="Uploaded Image" width="auto" height="200" style="max-width:350px"/>
                        </div>
                      </div>
                    </template>
                    <template>
                      <div style="display: flex; justify-content: center;">
                        <canvas ref="qrcodeCanvas"></canvas>
                      </div>
                    </template>
                  </b-form>
                </b-col>
              </b-row>
            </div>
            <b-row>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/socket.io/1.7.3/socket.io.slim.js"></script>
<style>

.multiselect--disabled .multiselect__current, .multiselect--disabled .multiselect__select {
  background: none !important;
}
.multiselect {
  height: 10px !important;
  border-radius: 50px !important;
}
.multiselect__tags {
  border: 1px solid #d9d9d9;
  border-radius: 10px !important;
}
.file-response-form {
  margin-top: 1.4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.format-header-citizen-details {
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  display: flex;
}
.format-col-pencil-btn {
  padding: .5rem;
  width: 40px;
  height: 40px;
}
.format-col-pencil {
  padding: 0 !important;
  width: 100%;
  display: flex;
  align-items: flex-end;
}
@media (max-width: 800px) {
  .col-res-register {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .mar-t-register {
    margin-top: .7rem !important;
  }
  .pad-custom-register {
    padding-left: .5rem !important;
  }
  .res-50-flex-register {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .res-100-flex-register {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
@media (max-width: 580px) {
  .col-edit-response-register {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
@media (max-width: 600px) {
  .t-button {
    width: 100%;
  }
  .response-1button-header {
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }
  .response-2button-header {
    gap: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import QRCode from 'qrcode'
import Vue from 'vue'
import moment from 'moment'
import Axios from 'axios'
import { mask } from 'vue-the-mask'
import { configAPI } from '../../configBase'
import CurrencyInputCom from './CurrencyInputCom.vue'

const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}

export default {
  name: 'PatientRegister',
  components: {
    CurrencyInputCom
  },
  directives: { mask },
  data () {
    return {
      selected: 'citizenId',
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      usrId: JSON.parse(sessionStorage.getItem('user')).usr_id || null,
      optionCurrency: {
        currency: 'EUR',
        currencyDisplay: 'hidden',
        valueRange: {
          min: 0,
          max: 1e+53
        },
        precision: 2,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: true,
        accountingSign: false
      },
      optionCurrency0: {
        currency: 'EUR',
        currencyDisplay: 'hidden',
        valueRange: {
          min: 0,
          max: 1e+53
        },
        precision: 0,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: true,
        accountingSign: false
      },
      isDisable: false,
      registerData: {
        name: null,
        lastName: null,
        nickName: null,
        gender: null,
        birthday: null,
        citizenId: null,
        passport: null,
        phone: null,
        HNData: null,
        address: null,
        subdistrict: null,
        district: null,
        province: null,
        zipCode: null,
        email: null,
        line: null,
        weigth: null,
        height: null,
        religion: null,
        blood: null,
        allergic: null,
        foodAllergies: null,
        bloodPressure: null,
        bodyTem: null,
        fat: null,
        muscle: null,
        BMI: null,
        imageName: null,
        imageUrl: '',
        imageFile: null,
        oldimagename: '',
        oldimageUrl: '',
        sale: null
      },
      sales: [],
      hexTokens: {
        F: {
          pattern:  /^\d*\.?\d{0,2}$/,
          transform: v => v.toLocaleUpperCase()
        }
      },
      genderList: [{code: 'ชาย', label: 'ชาย'}, {code: 'หญิง', label: 'หญิง'}],
      options: {
        // https://momentjs.com/docs/#/displaying/
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        sideBySide: true,
        // horizontal: auto,
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'bottom'
        },
        icons: iconDate,
        maxDate: new Date().setHours(23, 59, 59, 999)
      },
      provinces: [],
      provincesSelect: null,
      districts: [],
      districtAll: [],
      districtsSelect: null,
      subdistricts: [],
      subdistrictAll: [],
      subdistrictsSelect: null,
      zipCodes: '',
      religions: [
        { code: 'พุทธ', label: 'พุทธ' },
        { code: 'อิสลาม', label: 'อิสลาม' },
        { code: 'คริสต์', label: 'คริสต์' },
        { code: 'อื่น ๆ', label: 'อื่น ๆ' },
        { code: 'ไม่ระบุ', label: 'ไม่ระบุ' }
      ],
      religionsSelect: null,
      bloods: [
        { code: 'A', label: 'A' },
        { code: 'B', label: 'B' },
        { code: 'AB', label: 'AB' },
        { code: 'O', label: 'O' },
        { code: 'ไม่ทราบ', label: 'ไม่ทราบ' }
      ],
      bloodsSelect: null,
      gendersSelect: null,
      editCitizenDetails: true,
      mode: 'add',
      phoneNumber: '',
      showModal: false,
      imageUrlbase64: null,
      imageUrl2: null,
      activeTab: 'tab1',
      currentPage: 1,
      perPage: 10
    }
  },
  async mounted () {
    await this.getAllEmployee()
    await this.getProvinceDD()
    await this.getDistictDD()
    await this.getSubDistrictDD()
    if (this.$route.params.setPage === 'edit') {
      this.mode = 'edit'
      this.isDisable = false
      await this.getPatientData(this.$route.params.id)
      this.generateQRCode(false, this.$route.params.id)
    } else if (this.$route.params.setPage === 'view') {
      this.mode = 'view'
      this.isDisable = true
      await this.getPatientData(this.$route.params.id)
      this.generateQRCode(false, this.$route.params.id)
    } else {
      this.mode = 'add'
      this.isDisable = false
    }
    xray.index()
  },
  watch: {
    'selected': function (newVal, oldVal) {
      if (newVal === 'citizenId') {
        this.registerData.passport = null
      } else {
        this.registerData.citizenId = null
      }
    },
    'registerData.citizenId': function (newVal, oldVal) {
      if (this.mode === 'add') {
        if (newVal?.replace(/-/g, '').length < 4) {
          this.registerData.HNData = ''
        } else {
          this.generateHNCode()
        }
      }
    },
    'registerData.passport': function (newVal, oldVal) {
      if (this.mode === 'add') {
        if (newVal?.replace(/-/g, '').length < 4) {
          this.registerData.HNData = ''
        } else {
          this.generateHNCode()
        }
      }
    },
    // 'registerData.phone': function (newVal, oldVal) {
    //   if (this.mode !== 'add') {
    //     if (newVal.length === 10) {
    //       this.generateQRCode(false, this.$route.params.id)
    //     } else {
    //       this.generateQRCode(true)
    //     }
    //   }
    // },
    'registerData.province': function (newVal, oldVal) {
      if (newVal === null) {
        this.registerData.district = null
        this.registerData.subdistrict = null
        this.districtsSelect = null
        this.districts = []
        this.subdistrictsSelect = null
        this.subdistricts = []
      } else {
        this.districts = this.districtAll.filter(el => el.province_id === newVal)
      }
    },
    'registerData.district': function (newVal, oldVal) {
      if (newVal === null) {
        this.registerData.subdistrict = null
        this.subdistrictsSelect = null
        this.subdistricts = []
      } else {
        this.subdistricts = this.subdistrictAll.filter(el => el.district_id === newVal)
      }
    },
    provincesSelect (newVal, oldVal) {
      if (newVal === null) {
        this.registerData.province = null
      } else if (newVal !== null && oldVal !== null) {
        this.registerData.province = newVal.code
        this.districtsSelect = {
          code: null,
          label: null,
          province_id: null
        }
        this.subdistrictsSelect = {
          code: null,
          label: null,
          district_id: null
        }
        this.districts = this.districtAll.filter(el => el.province_id === newVal.code)
      } else {
        this.registerData.province = newVal.code
        this.districts = this.districtAll.filter(el => el.province_id === newVal.code)
      }
    },
    districtsSelect (newVal, oldVal) {
      if (newVal === null) {
        this.registerData.district = null
      } else if (newVal !== null && oldVal !== null) {
        this.registerData.district = newVal.code
        this.subdistrictsSelect = {
          code: null,
          label: null,
          district_id: null
        }
        this.subdistricts = this.subdistrictAll.filter(el => el.district_id === newVal.code)
      } else {
        this.registerData.district = newVal.code
        this.subdistricts = this.subdistrictAll.filter(el => el.district_id === newVal.code)
      }
    },
    subdistrictsSelect (newVal, oldVal) {
      if (!newVal) {
        this.registerData.subdistrict = null
      } else {
        this.registerData.subdistrict = newVal.code
      }
    },
    religionsSelect (newVal, oldVal) {
      if (!newVal) {
        this.registerData.religion = null
      } else {
        this.registerData.religion = newVal.code
      }
    },
    bloodsSelect (newVal, oldVal) {
      if (!newVal) {
        this.registerData.blood = null
      } else {
        this.registerData.blood = newVal.code
      }
    },
    gendersSelect (newVal, oldVal) {
      if (!newVal) {
        this.registerData.gender = null
      } else {
        this.registerData.gender = newVal.code
      }
    }
  },
  methods: {
    async getAllEmployee () {
      let getAllEmployeeAPI = {
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/user/`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await Axios.request(getAllEmployeeAPI)
        .then((response) => {
          if (response.data.status) {
            let arrTemp = []
            const resList = response.data.data.results.sort((a, b) => {
              return (new Date(a.created_date) - new Date(b.created_date))
            })
            for (let item of resList) {
              if (item.is_use) {
                arrTemp.push({
                  code: item.id,
                  label: item.emp_id ? `${item.emp_name} ${item.emp_lastname}` : `${item.ut_name} System`
                })
              }
            }
            this.sales = arrTemp
          }
        })
    },
    async getProvinceDD () {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/province`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        let tempDD = []
        res.data.data?.map(el => {
          let temp = {
            label: el.name,
            code: el.id
          }
          tempDD.push(temp)
        })
        this.provinces = tempDD
      }).catch((err) => {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่สามารถดึงข้อมูลจังหวัดได้</span>',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
        })
      })
    },
    async getDistictDD () {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/district`,
        header: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        let temp = []
        res.data.data.map(el => {
          let temp2 = {
            label: el.name,
            code: el.id,
            province_id: el.province_id
          }
          temp.push(temp2)
        })
        this.districts = temp
        this.districtAll = temp
      }).catch((err) => {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่สามารถดึงข้อมูลอำเภอได้</span>',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
        })
      })
    },
    async getSubDistrictDD () {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/subdistrict`,
        header: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        let temp = []
        res.data.data.map(el => {
          let temp2 = {
            label: el.name,
            code: el.id,
            district_id: el.district_id
          }
          temp.push(temp2)
        })
        this.subdistricts = temp
        this.subdistrictAll = temp
      }).catch((err) => {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่สามารถดึงข้อมูลตำบลได้</span>',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
        })
      })
    },
    onClickDatePicker (num) {
      let datePicker = null
      if (num === 1) {
        datePicker = document.getElementById('datepicker-custom1')
      } else if (num === 2) {
        datePicker = document.getElementById('datepicker-custom2')
      } else {
        datePicker = document.getElementById('datepicker-custom3')
      }
      datePicker.focus()
    },
    handleImageUpload (event) {
      if (event.target.files[0] === undefined) {
        this.registerData.imageFile = null
        this.registerData.imageName = this.registerData.oldimagename ? this.registerData.oldimagename : null
        this.registerData.imageUrl = this.registerData.oldimageUrl ? this.registerData.oldimageUrl : null
      } else if (event.target.files[0] !== undefined) {
        const file = event.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        this.registerData.imageFile = file
        this.registerData.imageName = file.name
        reader.onload = () => {
          this.registerData.imageUrl = reader.result
        }
      }
    },
    // async checkHasSameNumber () {
    //   await Axios.request({
    //     method: 'GET',
    //     url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/checkCusByPhone/${this.registerData.phone}`,
    //     headers: {
    //       'Content-Type': 'application/json'
    //     }
    //   }).then((res) => {
    //     if (res.data.data?.id !== null && res.data.data?.id !== undefined) {
    //       return res.data.data?.id
    //     } else {
    //       return null
    //     }
    //   })
    // },
    // async checkHasSameCitizen () {
    //   await Axios.request({
    //     method: 'GET',
    //     url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/checkCusByCitizen/${this.registerData.citizenId}`,
    //     headers: {
    //       'Content-Type': 'application/json'
    //     }
    //   }).then((res) => {
    //     if (res.data.data?.id !== null && res.data.data?.id !== undefined) {
    //       return res.data.data?.id
    //     } else {
    //       return null
    //     }
    //     return temp
    //   }).catch((err) => {
    //     return null
    //   })
    // },
    async handleClick () {
      let errList = await this.checkError()
      if (errList.length > 0) {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">กรุณากรอกข้อมูลให้ครบถ้วน</span>',
          icon: 'error',
          html: `<span class="" style="font-size: 20px; color: black; text-align: center">${errList.join('<br>')}</span>`,
          showCancelButton: false,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
        })
        return
      }
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Axios.request({
            method: 'POST',
            url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/checkCusByCitizen/${this.registerData.citizenId?.trim().replace(/-/g, '')}`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: {
              name: this.registerData.name ? this.registerData.name?.trim() : null,
              lastname: this.registerData.lastName ? this.registerData.lastName?.trim() : null,
              citizen_id: this.registerData.citizenId?.trim().replace(/-/g, '') || null,
              passport: this.registerData.passport?.trim() || null,
              checkFrom: this.selected
            }
          }).then(async (res) => {
            if (!res.data.status) {
              Vue.swal({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่สามารถบันทึกข้อมูลได้</span>',
                text: `${res.data.error}`,
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
                confirmButtonColor: '#089bab',
              })
            } else {
              // if (res.data.data?.id !== null) {
              //   await this.editCustomer(res.data.data?.id)
              //   router.push('/Patient')
              // } else {
              await this.addCustomer().then(() => {
                Vue.swal({
                  title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
                  icon: 'success',
                  showCancelButton: false,
                  showConfirmButton: false,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  timer: 1500
                }).then(() => {
                  router.push('/Patient')
                })
              })
              // }
            }
          })
        }
      })
    },
    async handleClickEdit () {
      let errList = await this.checkError()
      if (errList.length > 0) {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">กรุณากรอกข้อมูลให้ครบถ้วน</span>',
          icon: 'error',
          html: `<span class="" style="font-size: 20px; color: black; text-align: center">${errList.join('<br>')}</span>`,
          showCancelButton: false,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
        })
        return
      }
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะแก้ไขข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.editCustomer(this.$route.params.id)
        }
      })
    },
    handleChangeInput(event, value) {

      console.log('event :>> ', event)
      event.preventDefault()
      console.log('this.registerData.bloodPressure :>> ', this.registerData.bloodPressure)
      this.registerData.bloodPressure = event.replace(/[^\d.]/g, '')
      const inputValue = event
      const isValid = /^\d*\.?\d{0,2}$/.test(inputValue)
      if (isValid || inputValue === '') {
        console.log('inputValue :>> ', inputValue)
        this.registerData.bloodPressure = inputValue
      } else {
        event.preventDefault()
      }
    },
    async addCustomer () {
      let data = {
        citizen_id: this.registerData.citizenId ? this.registerData.citizenId?.trim().replace(/-/g, '') : null,
        passport: this.registerData.passport?.trim() ? this.registerData.passport?.trim() : null,
        name: this.registerData.name ? this.registerData.name?.trim() : null,
        lastname: this.registerData.lastName ? this.registerData.lastName?.trim() : null,
        nickname: this.registerData.nickName ? this.registerData.nickName?.trim() : null,
        gender: this.registerData.gender ? this.registerData.gender?.trim() : null,
        birth_day: this.registerData.birthday ? moment(this.registerData.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        tel: this.registerData.phone ? this.registerData.phone?.trim() : null,
        hn: this.registerData.HNData ? this.registerData.HNData?.trim() : null,
        address: this.registerData.address ? this.registerData.address?.trim() : null,
        province_id: this.registerData.province ? this.registerData.province : null,
        district_id: this.registerData.district ? this.registerData.district : null,
        sub_district_id: this.registerData.subdistrict ? this.registerData.subdistrict : null,
        postcode: this.registerData.zipCode ? this.registerData.zipCode?.trim() : null,
        email: this.registerData.email ? this.registerData.email?.trim() : null,
        line: this.registerData.line ? this.registerData.line?.trim() : null,
        weight: this.registerData.weigth ? this.registerData.weigth?.toString().trim().replace(/,/g, '') : null,
        height: this.registerData.height ? this.registerData.height?.toString().trim().replace(/,/g, '') : null,
        religion: this.registerData.religion ? this.registerData.religion?.trim() : null,
        blood: this.registerData.blood ? this.registerData.blood?.trim() : null,
        drug_allergy: this.registerData.allergic ? this.registerData.allergic?.trim() : null,
        food_allergy: this.registerData.foodAllergies ? this.registerData.foodAllergies?.trim() : null,
        pressure: this.registerData.bloodPressure ? this.registerData.bloodPressure?.toString().trim().replace(/,/g, '') : null,
        body_temperature: this.registerData.bodyTem ? this.registerData.bodyTem?.toString().trim().replace(/,/g, '') : null,
        fat: this.registerData.fat ? this.registerData.fat?.toString().trim().replace(/,/g, '') : null,
        muscle: this.registerData.muscle ? this.registerData.muscle?.toString().trim().replace(/,/g, '') : null,
        bmi: this.registerData.BMI ? this.registerData.BMI?.toString().trim().replace(/,/g, '') : null,
        created_by: this.usrId || null,
        updated_by: this.usrId || null,
        sale: this.registerData.sale?.code || null,
        checkFrom: this.selected
      }
      return await Axios.request({
        method: 'POST',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/register`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }).then(async (res) => {
        if (res.data.data.id) {
          await this.newFormData(res.data.data.id)
        }
        return res.data.data.id
      }).catch((err) => {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่สามารถบันทึกข้อมูลได้</span>',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
        })
      })
    },
    async editCustomer (id, from) {
      let data = {
        citizen_id: this.registerData.citizenId ? this.registerData.citizenId?.trim().replace(/-/g, '') : null,
        passport: this.registerData.passport?.trim() ? this.registerData.passport?.trim() : null,
        name: this.registerData.name ? this.registerData.name?.trim() : null,
        lastname: this.registerData.lastName ? this.registerData.lastName?.trim() : null,
        nickname: this.registerData.nickName ? this.registerData.nickName?.trim() : null,
        gender: this.registerData.gender ? this.registerData.gender?.trim() : null,
        birth_day: this.registerData.birthday ? moment(this.registerData.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        tel: this.registerData.phone ? this.registerData.phone?.trim() : null,
        hn: this.registerData.HNData ? this.registerData.HNData?.trim() : null,
        address: this.registerData.address ? this.registerData.address?.trim() : null,
        province_id: this.registerData.province ? this.registerData.province : null,
        district_id: this.registerData.district ? this.registerData.district : null,
        sub_district_id: this.registerData.subdistrict ? this.registerData.subdistrict : null,
        postcode: this.registerData.zipCode ? this.registerData.zipCode?.trim() : null,
        email: this.registerData.email ? this.registerData.email?.trim() : null,
        line: this.registerData.line ? this.registerData.line?.trim() : null,
        weight: this.registerData.weigth ? this.registerData.weigth?.toString().trim().replace(/,/g, '') : null,
        height: this.registerData.height ? this.registerData.height?.toString().trim().replace(/,/g, '') : null,
        religion: this.registerData.religion ? this.registerData.religion?.trim() : null,
        blood: this.registerData.blood ? this.registerData.blood?.trim() : null,
        drug_allergy: this.registerData.allergic ? this.registerData.allergic?.trim() : null,
        food_allergy: this.registerData.foodAllergies ? this.registerData.foodAllergies?.trim() : null,
        pressure: this.registerData.bloodPressure ? this.registerData.bloodPressure?.toString().trim().replace(/,/g, '') : null,
        body_temperature: this.registerData.bodyTem ? this.registerData.bodyTem?.toString().trim().replace(/,/g, '') : null,
        fat: this.registerData.fat ? this.registerData.fat?.toString().trim().replace(/,/g, '') : null,
        muscle: this.registerData.muscle ? this.registerData.muscle?.toString().trim().replace(/,/g, '') : null,
        bmi: this.registerData.BMI ? this.registerData.BMI?.toString().trim().replace(/,/g, '') : null,
        created_by: this.usrId || null,
        updated_by: this.usrId || null,
        sale: this.registerData.sale?.code || null,
        checkFrom: this.selected
      }
      await Axios.request({
        method: 'PUT',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/register/${id}`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }).then(async (res) => {
        if (!res.data.status) {
          Vue.swal({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่สามารถแก้ไขข้อมูลได้</span>',
            text: `${res.data.error}`,
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
          })
        } else {
          if (res.data.data.id) {
            await this.newFormData(res.data.data.id)
          }
          if (from === 'genqr') {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">แก้ไขข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              window.location.reload()
            })
          } else {
              Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">แก้ไขข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              router.push('/Patient')
            })
          }
        }
      }).catch((err) => {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่สามารถแก้ไขข้อมูลได้</span>',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
        })
      })
    },
    handleClickBack () {
      if (this.mode === 'view') {
        router.push('/Patient')
      } else {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            router.push('/Patient')
          }
        })
      }
    },
    async getPatientData (id) {
      await Axios.request({
        mothod: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/register/${id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        let temp = res.data.data[0]
        this.registerData.name = temp.firstname,
        this.registerData.lastName = temp.lastname,
        this.registerData.nickName = temp.nickname,
        this.gendersSelect = this.genderList.find(el => el.code === temp.gender) || null
        // this.registerData.gender = temp.gender,
        this.registerData.birthday = temp.birthday ? moment(temp.birthday).format('DD/MM/YYYY') : null,
        this.registerData.citizenId = temp.citizen_id,
        this.registerData.passport = temp.cus_passport,
        this.registerData.phone = temp.phone,
        this.registerData.HNData = temp.hn_no,
        this.registerData.address = temp.address,
        this.registerData.subdistrict = temp.sub_district_id,
        this.registerData.district = temp.district_id,
        this.registerData.province = temp.province_id,
        this.registerData.zipCode = temp.postcode,
        this.registerData.email = temp.email,
        this.registerData.line = temp.line,
        this.registerData.weigth = temp.weight?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || null,
        this.registerData.height = temp.height?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || null,
        this.registerData.religion = temp.religion
        this.registerData.blood = temp.blood_type,
        this.registerData.allergic = temp.drug_allergy,
        this.registerData.foodAllergies = temp.food_allergy,
        this.registerData.bloodPressure = temp.pressure?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || null,
        this.registerData.bodyTem = temp.body_temp?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || null,
        this.registerData.fat = temp.fat?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || null,
        this.registerData.muscle = temp.muscle?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || null,
        this.registerData.BMI = temp.bmi?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') || null,
        this.registerData.imageName = temp.img_name,
        this.registerData.imageUrl = temp.img_path,
        this.registerData.oldimagename = temp.img_name,
        this.registerData.oldimageUrl = temp.img_path,
        this.religionsSelect = this.religions.find(el => el.code === temp.religion)
        this.bloodsSelect = this.bloods.find(el => el.code === temp.blood_type)
        this.provincesSelect = this.provinces.find(el => el.code === temp.province_id)
        this.districtsSelect = this.districts.find(el => el.code === temp.district_id)
        this.subdistrictsSelect = this.subdistricts.find(el => el.code === temp.sub_district_id)
        this.registerData.sale = this.sales.find(el => el.code === temp.cus_sale_id) || null
        this.selected = this.registerData.citizenId ? 'citizenId' : 'passport'
      }).catch((err) => {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่สามารถดึงข้อมูลลูกค้าได้</span>',
          icon: 'error',
          html: `<span class="" style="font-size: 18px; color: black; text-align: center">${err}</span>`,
          showCancelButton: false,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
        })
      })
    },
    onPagination (page) {
      this.currentPage = page
    },
    generateQRCode (status) {
      const canvas = this.$refs.qrcodeCanvas
      const context = canvas.getContext('2d')
      context.clearRect(0, 0, canvas.width, canvas.height)
      if (status === true) {
        return
      } // ล้างรูปภาพเก่า
      if (this.registerData.phone) {
        let url = ''
        // if (this.mode === 'add') {
        //   url = `http://192.168.0.176:8080/Register/${this.clnId}/${this.registerData.phone?.replace(/-/g, '')}`
        // } else if (this.mode === 'edit') {
        //   url = `http://192.168.0.176:8080/ReGister/${this.clnId}/${this.$route.params.id}/${this.registerData.phone?.replace(/-/g, '')}`
        // } else if (this.mode === 'view') {
        //   url = `http://192.168.0.176:8080/ReGisTer/${this.clnId}/${this.$route.params.id}/${this.registerData.phone?.replace(/-/g, '')}`
        // }
        // if (this.mode === 'add') {
        //   url = `http://203.151.56.27:6001/Register/${this.clnId}/${this.registerData.phone?.replace(/-/g, '')}`
        // } else
        if (this.mode === 'edit') {
          url = `http://203.151.56.27/ReGister/${this.clnId}/${this.$route.params.id}/${this.registerData.phone?.replace(/-/g, '')}`
        } else if (this.mode === 'view') {
          url = `http://203.151.56.27/ReGisTer/${this.clnId}/${this.$route.params.id}/${this.registerData.phone?.replace(/-/g, '')}`
        }
        const options = {
          width: 240, // กำหนดความกว้างของรหัส QR code เป็น 240 pixel
          height: 240,
          color: {
            dark: '#000000', // กำหนดสีเข้มของรหัส QR code เป็นสีดำ
            light: '#FFFFFF' // กำหนดสีสว่างของรหัส QR code เป็นสีขาว
          },
          background: '#FF0000' // กำหนดสีพื้นหลังของรหัส QR code เป็นสีแดง
        }
        QRCode.toCanvas(canvas, url, options, (error) => {
          if (error) {
            alert(error)
          } else {
            const context = canvas.getContext('2d')
            const imageData = context.getImageData(0, 0, canvas.width, canvas.height)
            const data = imageData.data

            // ปรับเปลี่ยนสีของ QR code
            for (let i = 0; i < data.length; i += 4) {
              const r = data[i]
              const g = data[i + 1]
              const b = data[i + 2]

              // เช็คสีดำ
              if (r === 0 && g === 0 && b === 0) {
                data[i] = 94 // ปรับค่าสีแดงเป็น 0
                data[i + 1] = 190 // ปรับค่าสีเขียวเป็น 0
                data[i + 2] = 201 // ปรับค่าสีน้ำเงินเป็น 255
              }

              // เช็คสีขาว
              /* if (r === 255 && g === 255 && b === 255) {
                data[i] = 255 // ปรับค่าสีแดงเป็น 255
                data[i + 1] = 0 // ปรับค่าสีเขียวเป็น 0
                data[i + 2] = 0 // ปรับค่าสีน้ำเงินเป็น 0
              } */
            }

            context.putImageData(imageData, 0, 0)
          }
        })
      }
    },
    generateHNCode () {
      let str_data = this.selected === 'citizenId'
        ? this.registerData.citizenId?.replace(/-/g, '')?.slice(-4)
        : this.registerData.passport?.replace(/-/g, '')?.slice(-4)
      this.registerData.HNData = `HN${moment(new Date()).format('YYMM')}${str_data}`
    },
    isNumberCheck (keyPressed, status) {
      let useData = keyPressed.target.value

      let keyCode = (keyPressed.keyCode ? keyPressed.keyCode : keyPressed.which)

      if (status) {
        if (keyCode === 46 || useData.indexOf('.') !== -1) {
          keyPressed.preventDefault()
        }
        if (keyCode < 48 || keyCode > 57) {
          keyPressed.preventDefault()
        }
      } else {
        if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || useData.indexOf('.') !== -1)) {
          keyPressed.preventDefault()
        }
      }
      if (useData !== null && useData.indexOf('.') > -1 && (useData.split('.')[1].length > 1)) {
        keyPressed.preventDefault()
      }

      if ((useData === null || useData === '') && keyCode === 46) {
        keyPressed.preventDefault()
      }
    },
    isNumberComma1 (event, variable) {
      // let useData = event.target.value
      if (variable === '2') {
        this.registerData.var_registerData2 = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === '3') {
        this.registerData.var_registerData3 = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === '4') {
        this.registerData.var_registerData4 = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === 'stock') {
        this.registerData.var_registerData10 = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === 'price') {
        this.registerData.var_registerData11 = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === 'buy') {
        this.registerData.var_registerData12 = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      }
    },
    // checkGenQrCode (event, type) {
    //   if (type === 'phone') {
    //     if (this.registerData.phone?.replace(/-/g, '').length === 10) {
    //       this.generateQRCode(false)
    //     } else {
    //       this.generateQRCode(true)
    //     }
    //   } else if (type === 'citizenId') {
    //     if (this.registerData.citizenId?.replace(/-/g, '').length === 13) {
    //       this.generateHNCode()
    //     } else {
    //       // this.generateHNCode()
    //     }
    //   }
    // },
    formatContact (e, value) {
      let keyCode = (e.keyCode ? e.keyCode : e.which)
      if (value === 'phone') {
        if (this.registerData.phone?.replace(/-/g, '').length >= 10 || (keyCode < 48 || keyCode > 57)) {
          this.registerData.phone = this.registerData.phone?.replace(/-/g, '').replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3')
          e.preventDefault()
        } else {
          this.registerData.phone = this.registerData.phone.toString().replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3')
        }
      } else if (value === 'citizenId') {
        if (this.registerData.citizenId?.replace(/-/g, '').length >= 13 || (keyCode < 48 || keyCode > 57)) {
          this.registerData.citizenId = this.registerData.citizenId?.replace(/-/g, '').replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, '$1-$2-$3-$4-$5')
          e.preventDefault()
        } else {
          this.registerData.citizenId = this.registerData.citizenId.toString()?.replace(/-/g, '').replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, '$1-$2-$3-$4-$5')
        }
      } else if (value === 'zipCode') {
        if (this.registerData.zipCode.length === 5 || (keyCode < 48 || keyCode > 57)) {
          e.preventDefault()
        }
      } else if (value === 'perzen_fat') {
        if (parseFloat(this.registerData.fat) >= 100) {
          e.preventDefault()
          e.target.value = 100
          this.registerData.fat = 100
        }
      } else if (value === 'perzen_muscle') {
        if (parseFloat(this.registerData.muscle) >= 100) {
          e.preventDefault()
          e.target.value = 100
          this.registerData.muscle = 100
        }
      }
    },
    getCurrentDate (dataInput) {
      if (dataInput === undefined) {
        return
      }
      const today = new Date(dataInput)
      let month = (today.getMonth() + 1).toString()
      let day = today.getDate().toString()

      // แก้ไขรูปแบบเดือนและวันให้เป็น 'MM' และ 'DD'
      if (month.length === 1) {
        month = '0' + month
      }
      if (day.length === 1) {
        day = '0' + day
      }

      return `${today.getFullYear()}-${month}-${day}`
    },
    checkperCom () {
      const regex = /^\d+(\.\d{0,2})?%?$/
      let value = this.inputValue
      if (!regex.test(value) || parseFloat(value) > 5.00) {
        value = value.slice(0, -1)
      }
      this.inputValue = value
    },
    async getIPs () {
      return new Promise(function (resolve, reject) {
        try {
          var ipDups = {}
          var RTCPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection
          var mediaConstraints = {
            optional: [{ RtpDataChannels: true }]
          }
          var servers = { iceServers: [{ urls: 'stun:stun.services.mozilla.com' }] }
          var pc = new RTCPeerConnection(servers, mediaConstraints)
          function handleCandidate (candidate) {
            var ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/
            console.log('ipRegex.exec(candidate) :>> ', ipRegex.exec(candidate));
            var ipAddr = ipRegex.exec(candidate)[1]
            if (ipDups[ipAddr] === undefined) {
              return ipAddr
            }
            ipDups[ipAddr] = true
          }
          pc.onicecandidate = async function (ice) {
            if (ice.candidate) {
              resolve(await handleCandidate(ice.candidate.candidate))
            }
          }
          pc.createDataChannel('')
          pc.createOffer(function (result) {
            pc.setLocalDescription(result, function () {}, function () {})
          }, function () {})
          setTimeout(function () {
            var lines = pc.localDescription.sdp.split('\n')
            lines.forEach(function (line) {
              if (line.indexOf('a=candidate:') === 0) {
                handleCandidate(line)
              }
            })
          }, 1000)
        } catch (error) {
          Vue.swal({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่สามารถดึงข้อมูลได้</span>',
            icon: 'error',
            html: `<span class="" style="font-size: 18px; color: black; text-align: center">${error}</span>`,
            showCancelButton: false,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
          })
        }
      })
    },
    async fetchData (){ //ต้อง npm i ใน localtunel ก่อน และ npm run dev
      const getIP = await this.getIPs()
      Vue.swal.fire({
        title: '<span class="loading-icon"><i class="fas fa-spinner fa-spin"></i></span>',
        text: 'กำลังดึงข้อมูลจากบัตรประชาชน',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          Vue.swal.getHtmlContainer().querySelector('.loading-icon').style.fontSize = '40px'
        }
      })
      console.log('getIP :>> ', getIP);
      await this.clearData()
      const response = await Axios.get(`http://${getIP}:8808`, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Bypass-Tunnel-Reminder': true,
          'Content-Type': 'application/json'
        },
        withCredentials: false // Enable sending cookies and other credentials
      }).catch((err) => {
        console.log('err :>> ', err);
        Vue.swal.close()
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่สามารถดึงข้อมูลได้</span>',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
        })
      })
      try {
        if (response.data.status === 'fail') {
          Vue.swal.close()
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณไม่ได้เสียบบัตร?</span>`,
            icon: 'error',
            cancelButtonColor: '#d33'
          })
        } else {
          const cardData = response.data.data
          let addArr = cardData.address.split(' ')
          let address = null
          for (let index = 0; index < addArr.length; index++) {
            if (index < addArr.length - 3 && addArr[index] !== '') {
              if (address === null) {
                address = addArr[index]
              } else {
                address = address + ' ' + addArr[index]
              }
            }
          }
          this.registerData.address = address ? address.trim() : null
          let provinceCard = cardData.address.split(' ')[addArr.length - 1].trim().replace('จังหวัด', '')
          let districtCard = cardData.address.split(' ')[addArr.length - 2].trim().replace('อำเภอ', '')
          let subdistrictCard = cardData.address.split(' ')[addArr.length - 3].trim().replace('ตำบล', '')
          const province1 = new Promise((resolve, reject) => {
            this.provincesSelect = this.provinces.find(el => {
              return el.label === provinceCard
            }) || null
            resolve(this.provincesSelect)
          })
          province1.then((value) => {
            this.districtsSelect = this.districtAll.find(el => {
              return el.label === districtCard && el.province_id === value.code
            }) || null
            return this.districtsSelect
          }).then((value2) => {
            this.subdistrictsSelect = this.subdistrictAll.find(el => {
              return el.label === subdistrictCard && el.district_id === value2.code
            }) || null
          })
          this.registerData.name = cardData.thainame.split(' ')[1].trim()
          this.registerData.lastName = cardData.thainame.split(' ')[3].trim()
          this.registerData.birthday = `${cardData.birthday.slice(6)}/${cardData.birthday.slice(4, 6)}/${parseInt(cardData.birthday.slice(0, 4)) - 543}`
          this.registerData.citizenId = cardData.thai_id?.replace(/-/g, '').replace(/(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/, '$1-$2-$3-$4-$5')
          // this.registerData.subdistrict = this.subdistrictAll.find(el => el.label === subdistrictCard)?.code
          // this.registerData.district = this.districtAll.find(el => el.label === districtCard)?.code
          // this.registerData.province = this.provincesAll.find(el => el.label === provinceCard)?.code
          // this.registerData.gender = (cardData.gender === '1' ? 'ชาย' : 'หญิง')
          this.gendersSelect = this.genderList.find(el => el.code === (cardData.gender === '1' ? 'ชาย' : 'หญิง')) || null
          this.registerData.HNData = `HN${moment(new Date()).format('YYMM')}${cardData.thai_id?.replace(/-/g, '')?.slice(9, 13)}`
          this.registerData.imageUrl = 'data:image/jpeg;base64,' + cardData.img
          this.registerData.imageName = cardData.engname.split(' ')[1].trim() + '_' + cardData.engname.split(' ')[3].trim() + '.jpeg'
          this.imageUrlbase64 =  cardData.img
          this.generateQRCode(false)
          Vue.swal.close()
        }
      } catch (error) {
        console.log('error catch :>> ', error);
      }
    },
    async checkError () {
      let errList = []
      if (this.registerData.sale === null || this.registerData.sale?.toString().trim() === '') {
        errList.push('กรุณาเลือกผู้ดูแล')
      }
      if (this.registerData.name === null || this.registerData.name?.toString().trim() === '') {
        errList.push('กรุณากรอกชื่อ')
      }
      if (this.registerData.lastName === null || this.registerData.lastName?.toString().trim() === '') {
        errList.push('กรุณากรอกนามสกุล')
      }
      if (this.registerData.birthday === null || this.registerData.birthday?.toString().trim() === '') {
        errList.push('กรุณากรอกวันเกิด')
      }
      if (this.selected === 'citizenId') {

        if (!this.registerData.citizenId?.trim()) {
          errList.push('กรุณากรอกเลขบัตรประชาชน')
        } else if (this.registerData.citizenId?.trim() && this.registerData.citizenId?.replace(/-/g, '').length != 13) {
          errList.push('กรุณากรอกเลขบัตรประชาชนให้ครบ 13 หลัก')
        }
      } else if (!this.registerData.passport?.trim()) {
        errList.push('กรุณากรอกเลขพาสปอร์ต')
      }
      if (this.registerData.phone === null || this.registerData.phone?.toString().trim() === '') {
        errList.push('กรุณากรอกเบอร์โทรศัพท์')
      } else if (this.registerData.phone?.replace(/-/g, '').length !== 10) {
        errList.push('กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลัก')
      }
      // if (this.registerData.HNData === null || this.registerData.HNData?.toString().trim() === '') {
      //   errList.push('กรุณากรอกเลข HN')
      // }
      /* if (this.registerData.subdistrict === null || this.registerData.subdistrict?.toString().trim() === '') {
        errList.push('กรุณากรอกตำบล')
      }
      if (this.registerData.district === null || this.registerData.district?.toString().trim() === '') {
        errList.push('กรุณากรอกอำเภอ')
      }
      if (this.registerData.province === null || this.registerData.province?.toString().trim() === '') {
        errList.push('กรุณากรอกจังหวัด')
      } */
      if (this.registerData.zipCode === null || this.registerData.zipCode?.toString().trim() === '') {
        // errList.push('กรุณากรอกรหัสไปรษณีย์')
      } else if (this.registerData.zipCode && this.registerData.zipCode?.length !== 5) {
        errList.push('กรุณากรอกรหัสไปรษณีย์ให้ครบ 5 หลัก')
      }
      // if (this.registerData.email === null || this.registerData.email?.toString().trim() === '') {
      //   errList.push('กรุณากรอกอีเมล')
      // }
      // if (this.registerData.line === null || this.registerData.line?.toString().trim() === '') {
      //   errList.push('กรุณากรอกไลน์')
      // }
      // if (this.registerData.weigth === null || this.registerData.weigth?.toString().trim() === '') {
      //   errList.push('กรุณากรอกน้ำหนัก')
      // }
      // if (this.registerData.height === null || this.registerData.height?.toString().trim() === '') {
      //   errList.push('กรุณากรอกส่วนสูง')
      // }
      // if (this.registerData.religion === null || this.registerData.religion?.toString().trim() === '') {
      //   errList.push('กรุณากรอกศาสนา')
      // }
      // if (this.registerData.blood === null || this.registerData.blood?.toString().trim() === '') {
      //   errList.push('กรุณากรอกกรุ๊ปเลือด')
      // }
      // if (this.registerData.allergic === null || this.registerData.allergic?.toString().trim() === '') {
      //   errList.push('กรุณากรอกประวัติการแพ้ยา')
      // }
      // if (this.registerData.foodAllergies === null || this.registerData.foodAllergies?.toString().trim() === '') {
      //   errList.push('กรุณากรอกประวัติการแพ้อาหาร')
      // }
      // if (this.registerData.bloodPressure === null || this.registerData.bloodPressure?.toString().trim() === '') {
      //   errList.push('กรุณากรอกความดันโลหิต')
      // }
      // if (this.registerData.bodyTem === null || this.registerData.bodyTem?.toString().trim() === '') {
      //   errList.push('กรุณากรอกอุณหภูมิร่างกาย')
      // }
      // if (this.registerData.fat === null || this.registerData.fat?.toString().trim() === '') {
      //   errList.push('กรุณากรอกไขมัน')
      // }
      // if (this.registerData.muscle === null || this.registerData.muscle?.toString().trim() === '') {
      //   errList.push('กรุณากรอกกล้ามเนื้อ')
      // }
      // if (this.registerData.BMI === null || this.registerData.BMI?.toString().trim() === '') {
      //   errList.push('กรุณากรอกดัชนีมวลกาย')
      // }
      return errList
    },
    getFullImagePath (relativePath) {
      if (relativePath !== null && relativePath !== '') {
        let path = relativePath !== null ? relativePath.slice(1) : ''
        const baseURL = configAPI.MA_PORT + path
        return baseURL
      } else {
        return 'https://www.w3schools.com/howto/img_avatar.png'
      }
    },
    async newFormData (id) {
      if (this.registerData.imageFile !== null) {
        const formData = new FormData()
        formData.append('file', this.registerData.imageFile)
        await Axios.request({
          method: 'post',
          url: `${configAPI.MA_PORT}/api/register/${id}/upload/img`,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: formData
        })
      } else if (this.imageUrlbase64 !== null) {
        // base64 img
        const binaryImage = atob(this.imageUrlbase64)
        const byteArray = new Uint8Array(binaryImage.length);
        for (let i = 0; i < binaryImage.length; i++) {
          byteArray[i] = binaryImage.charCodeAt(i);
        }
        const formData = new FormData()
        formData.append('file', new Blob([byteArray], { type: 'image/jpeg' }) , this.registerData.imageName)
        await Axios.request({
          method: 'post',
          url: `${configAPI.MA_PORT}/api/register/${id}/upload/img`,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: formData
        })
      }
    },
    async checkQRError () {
      let errList = []
      // if (this.registerData.name === null || this.registerData.name === '') {
      //   errList.push('กรุณากรอกชื่อ')
      // }
      // if (this.registerData.lastName === null || this.registerData.lastName === '') {
      //   errList.push('กรุณากรอกนามสกุล')
      // }
      // if (this.registerData.birthday === null || this.registerData.birthday === '') {
      //   errList.push('กรุณากรอกวันเกิด')
      // }
      if (this.selected === 'citizenId') {

        if (!this.registerData.citizenId?.trim()) {
          errList.push('กรุณากรอกเลขบัตรประชาชน')
        } else if (this.registerData.citizenId?.trim() && this.registerData.citizenId?.replace(/-/g, '').length != 13) {
          errList.push('กรุณากรอกเลขบัตรประชาชนให้ครบ 13 หลัก')
        }
      } else if (!this.registerData.passport?.trim()) {
        errList.push('กรุณากรอกเลขพาสปอร์ต')
      }

      if (this.registerData.phone === null || this.registerData.phone === '') {
        errList.push('กรุณากรอกเบอร์โทรศัพท์')
      } else if (this.registerData.phone?.replace(/-/g, '').length !== 10) {
        errList.push('กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลัก')
      }
      // if (this.registerData.HNData === null || this.registerData.HNData === '') {
      //   errList.push('กรุณากรอกเลข HN')
      // }
      // if (this.registerData.subdistrict === null || this.registerData.subdistrict === '') {
      //   errList.push('กรุณากรอกตำบล')
      // }
      // if (this.registerData.district === null || this.registerData.district === '') {
      //   errList.push('กรุณากรอกอำเภอ')
      // }
      // if (this.registerData.province === null || this.registerData.province === '') {
      //   errList.push('กรุณากรอกจังหวัด')
      // }
      // if (this.registerData.zipCode === null || this.registerData.zipCode === '') {
      //   errList.push('กรุณากรอกรหัสไปรษณีย์')
      // } else if (this.registerData.zipCode?.length !== 5) {
      //   errList.push('กรุณากรอกรหัสไปรษณีย์ให้ครบ 5 หลัก')
      // }
      return errList
    },
    async genQRCode () {
      let errorArr = await this.checkQRError()
      if (errorArr.length > 0) {
        Vue.swal.fire({
          title: `<span class="" style="font-size: 25px; color: black; text-align: center">กรุณากรอกข้อมูลให้ครบถ้วน</span>`,
          icon: 'error',
          html: `<span class="" style="font-size: 18px; color: black; text-align: center">${errorArr.join('<br>')}</span>`,
          showCancelButton: false,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
        })
      } else {
        if (this.mode === 'add') {
          // await Axios.request({
          //   method: 'POST',
          //   url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/checkCusByCitizen/${this.registerData.citizenId?.trim().replace(/-/g, '')}`,
          //   headers: {
          //     'Content-Type': 'application/json'
          //   },
          //   data: {
          //     name: this.registerData.name.trim(),
          //     lastname: this.registerData.lastName.trim()
          //   }
          // }).then(async (res) => {
          // if (!res.data.status) {
          //   Vue.swal({
          //     title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่สามารถ Gen QR ได้</span>',
          //     text: `${res.data.error}`,
          //     icon: 'error',
          //     showCancelButton: false,
          //     confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          //     confirmButtonColor: '#089bab',
          //   })
          // } else {
          await Axios.request({
            method: 'GET',
            url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/checkCusByPhone/${this.registerData.phone}`,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(async (res) => {
            if (!res.data.status) {
              Vue.swal({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่สามารถ Gen QR ได้</span>',
                text: `${res.data.error}`,
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
                confirmButtonColor: '#089bab',
              })
            } else {
              let cusid = await this.addCustomer()
              Vue.swal({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
                icon: 'success',
                showCancelButton: false,
                showConfirmButton: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                timer: 1500
              }).then(() => {
                router.push(`/Patient/edit/${cusid}`)
                window.location.reload()
              })
            }
          })
          // }
          // })
        } else if (this.mode === 'edit') {
          await this.editCustomer(this.$route.params.id, "genqr")
        }
      }
    },
    async clearData () {
      this.provincesSelect = null
      this.districtsSelect = null
      this.subdistrictsSelect = null
      // this.provincesSelect = {
      //   code: null,
      //   label: null
      // }
      // this.districtsSelect = {
      //   code: null,
      //   label: null,
      //   province_id: null
      // }
      // this.subdistrictsSelect = {
      //   code: null,
      //   label: null,
      //   district_id: null
      // }
      this.registerData.name = null
      this.registerData.lastName = null
      this.registerData.birthday = null
      this.registerData.citizenId = null
      this.registerData.passport = null
      this.registerData.HNData = null
      this.registerData.subdistrict = null
      this.registerData.district = null
      this.registerData.province = null
      this.registerData.zipCode = null
    }
  }
}
</script>
