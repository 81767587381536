<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>

          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">

              <div class="col-auto">
                <span class="unselectable" style="font-size: 25px; color: black;">รับตามใบสั่ง (Receive By PO)</span>
              </div>

            </div>
          </template>

          <template v-slot:body>
            <div class="mx-3">
              <b-row style="align-items: flex-end;">

                <b-col cols="12" lg="3" class="pb-3">
                  <div class="iq-email-search d-flex">
                    <div style="width: 100%;">
                      <div class="form-group mb-0" style="height: 45px;">
                        <input v-model="search" @keydown="enterSearch" class="form-control" id="exampleInputEmail1" placeholder="Search"
                          style="width: 100%; height: 45px;">
                        <!-- <a class="search-link" @click="filterPOReceive"><i class="ri-search-line" /></a> -->
                        <i style="color: #676767; top: 4px" class="search-link ri-search-line" />
                      </div>
                    </div>
                  </div>
                </b-col>

                <b-col cols="6" lg="2" class="pb-3">
                  <b-text style="color: black; white-space: nowrap">วันที่จัดส่ง (เริ่มต้น)</b-text>
                  <div style="position: relative; margin-top: .4rem;" class="w-res-attendance time-picker-attendance">
                    <date-picker v-if="searchEndDate" placeholder="เลือกวันที่" id="datepicker-custom-1"
                      :config="optionsDatepickerStart" v-model="searchStartDate"></date-picker>
                    <date-picker v-else placeholder="เลือกวันที่" id="datepicker-custom-1"
                      :config="optionsDatepickerDefault" v-model="searchStartDate"></date-picker>
                    <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(1)"
                      style="cursor: pointer;"></i>
                  </div>
                </b-col>

                <b-col cols="6" lg="2" class="pb-3">
                  <b-text style="color: black; white-space: nowrap">วันที่จัดส่ง (สิ้นสุด)</b-text>
                  <div style="position: relative; margin-top: .4rem;" class="w-res-attendance time-picker-attendance">
                    <date-picker v-if="searchStartDate" placeholder="เลือกวันที่" id="datepicker-custom-2"
                      :config="optionsDatepickerEnd" v-model="searchEndDate"></date-picker>
                    <date-picker v-else placeholder="เลือกวันที่" id="datepicker-custom-2"
                      :config="optionsDatepickerDefault" v-model="searchEndDate"></date-picker>
                    <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(2)"
                      style="cursor: pointer;"></i>
                  </div>
                </b-col>

                <b-col cols="12" lg="auto" class="pb-3">
                  <b-button @click="filterPOReceive" type="button" variant="primary-custom" class="w-100 btn-min-size"
                    style="height: 45px;">
                    <i class="ri-search-line"></i>
                    ค้นหา
                  </b-button>
                </b-col>

                <b-col cols="auto" class="pb-4">
                  <b-form-checkbox v-model="isPOclose" name="isPOclose" class="checkbox-quo checkbox-quotation-mgt" size="lg">
                      <span style="color: black;">PO close</span>
                  </b-form-checkbox>
                </b-col>

                <b-col cols="auto" class="pb-3">
                  <div style="display: flex; align-items: center; height: 45px;">
                    <span class="mx-2 font-size-14 text-primary-custom">
                      {{ rows_po_receive.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage *
                        currentPage) > rows_po_receive?.length) ? rows_po_receive.length : (perPage * currentPage)
                      ) + ' of ' + rows_po_receive_length : '0 of ' + rows_po_receive_length }} รายการ
                    </span>
                  </div>
                </b-col>
              </b-row>
            </div>

            <b-col cols="12">
              <div class="table-responsive">
                <b-table bordered hover :items="rows_po_receive" :fields="columns_receive_po" :current-page="currentPage"
                  :per-page="perPage" @pagination="onPagination" :tbody-tr-class="rowClass"
                  thead-class="table-header-color">

                  <template v-slot:cell(no)="data">
                    <span>{{ ((currentPage - 1) * 10) + data.index + 1 }}</span>
                  </template>

                  <template v-slot:cell(po_no)="data">
                    <span>{{ data.item.po_no || '-' }}</span>
                  </template>

                  <template v-slot:cell(supplier_name)="data">
                    <span>{{ data.item.po_sup_name || '-' }}</span>
                  </template>

                  <template v-slot:cell(po_date)="data">
                    <span>{{ formatDate(data.item.po_date) || '-' }}</span>
                  </template>

                  <template v-slot:cell(price)="data">
                    <span>{{ formatNumber(data.item.po_total_price) || '-' }}</span>
                  </template>

                  <template v-slot:cell(receive_date)="data">
                    <div v-if=" data.item.po_receive_date.length > 0">
                      <div :key="index" v-for="(item, index) in data.item.po_receive_date">
                        <span :key="index">{{ formatDate(item) || '-' }}</span>
                      </div>
                    </div>
                    <div v-else-if="data.item.po_receive_date.length === 0">
                      <span>-</span>
                    </div>
                  </template>

                  <template v-slot:cell(receive_amount_percent)="data">
                      <span v-if="data.item.po_receive_amount_percent > 100"  style="color: red;">{{ data.item.po_receive_amount_percent || '-' }} %</span>
                      <span v-else-if="data.item.po_receive_amount_percent <= 100">{{ data.item.po_receive_amount_percent || '-' }} %</span>
                  </template>

                  <template v-slot:cell(receive_detail)="data">
                    <div v-if="data.item.po_receive_detail.prd.length > 0" style="text-align: left;">
                      <span>Product</span>
                      <div :key="index" v-for="(item, index) in data.item.po_receive_detail.prd">
                        <span :key="index">{{ item.prd_name || '-' }} : {{ formatNumber(item.rp_amount || '0') }} / {{ formatNumber(item.poi_amount || '0') }} {{ item.unit }}</span>
                      </div>
                    </div>
                    <div v-else-if="data.item.po_receive_detail.prd.length === 0" style="text-align: left;">
                      <span>Product</span>
                      <div>
                        <span>No Data</span>
                      </div>
                    </div>
                    <br>
                    <div v-if="data.item.po_receive_detail.mat.length > 0" style="text-align: left;">
                      <span>Material</span>
                      <div :key="index" v-for="(item, index) in data.item.po_receive_detail.mat">
                        <span :key="index">{{ item.mat_name || '-' }} : {{ formatNumber(item.rp_amount || '0') }} / {{ formatNumber(item.poi_amount || '0') }} {{ item.unit }}</span>
                      </div>
                    </div>
                    <div v-else-if="data.item.po_receive_detail.mat.length === 0" style="text-align: left;">
                      <span>Material</span>
                      <div>
                        <span>No Data</span>
                      </div>
                    </div>
                  </template>

                  <template v-slot:cell(receive_status)="data">
                    <b-button v-if="data.item.po_rs_id === 'bd5d0669-6e5b-405f-860b-20c3c0026e35'"
                      variant=" iq-bg-dark-custom btn-dark-border" style="min-width: 100px; cursor:default;">
                      {{ data.item.rs_name || '-' }}
                    </b-button>
                    <b-button v-else-if="data.item.po_rs_id === 'd29cbb2c-c5e2-46cd-9b31-168af017fa0a'"
                      variant=" iq-bg-bin-custom btn-bin-border" style="min-width: 100px; cursor:default;">
                      {{  data.item.rs_name || '-' }}
                    </b-button>
                    <b-button v-else-if="data.item.po_rs_id === 'ba220c69-3395-4020-ab45-2578f9f54e06'"
                      variant=" iq-bg-success-custom btn-success-border" style="min-width: 100px; cursor:default;">
                      {{  data.item.rs_name || '-' }}
                    </b-button>
                    <span v-else >-</span>
                  </template>

                  <template v-slot:cell(management)="data">
                    <div style="display: flex;">

                      <!-- <b-button variant=" iq-bg-eye-custom btn-eye-border mx-1" size="sm"
                        @click="clickPath('view', data.item.po_id)">
                        <i class="ri-eye-fill m-0"></i>
                      </b-button> -->

                      <b-button v-if="!data.item.po_is_close"
                        variant=" iq-bg-pencil-custom btn-pencil-border mx-1" size="sm"
                        @click="clickPath('edit', data.item.po_id)">
                        <i class="ri-pencil-fill m-0"></i>
                      </b-button>

                    </div>
                  </template>

                </b-table>
              </div>
            </b-col>

            <b-pagination v-model="currentPage" :total-rows="rows_po_receive?.length" :per-page="perPage" align="center" />

          </template>
        </iq-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<style scoped>
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import Axios from 'axios'
import moment from 'moment'
import { configAPI } from '../../configBase'

const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}

export default {
  name: 'ReceiveByPO',

  data () {
    return {
      clnId: null,
      userId: null,
      search: '',
      searchStartDate: null,
      searchEndDate: null,
      supplierselect: null,
      supplierDD: [],
      isPOclose: false,
      columns_receive_po: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        { label: 'เลขที่ใบสั่งซื้อ', key: 'po_no', class: 'text-center table-size-200-400' },
        { label: 'ชื่อซัพพลายเออร์', key: 'supplier_name', class: 'text-center table-size-200-400' },
        { label: 'ราคา', key: 'price', class: 'text-center table-size-200-400' },
        { label: 'วันที่รับเข้า', key: 'receive_date', class: 'text-center table-size-200-400' },
        { label: 'จำนวนที่รับเข้า(%)', key: 'receive_amount_percent', class: 'text-center table-size-200-400' },
        { label: 'รายละเอียด', key: 'receive_detail', class: 'text-center table-size-300-500 ellips' },
        { label: 'สถานะ', key: 'receive_status', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'management', class: 'text-center table-size-100-300' }
      ],
      rows_po_receive: [],
      rows_po_receive_length: 0,
      currentPage: 1,
      perPage: 10,
      optionsDatepickerDefault: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: false,
        minDate: false,
        maxDate: false,
        icons: iconDate
      },
      optionsDatepickerStart: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: false,
        minDate: false,
        maxDate: false,
        icons: iconDate
      },
      optionsDatepickerEnd: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: false,
        minDate: false,
        maxDate: false,
        icons: iconDate
      }
    }
  },

  computed: {
  },

  watch: {
    'searchStartDate': function (newDate) {
      this.optionsDatepickerEnd.minDate = newDate
    },
    'searchEndDate': function (newDate) {
      this.optionsDatepickerStart.maxDate = newDate
    },
    'isPOclose': function (newVal) {
      this.filterPOReceive()
    }
  },

  async mounted () {
    this.clnId = JSON.parse(sessionStorage.getItem('user')).cln_id || null
    this.userId = JSON.parse(sessionStorage.getItem('user')).usr_id || null
    await this.getSupplierDD()
    await this.filterPOReceive()
    xray.index()
  },

  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.filterPOReceive()
      }
    },
    formatDate (date) {
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      } else {
        return '-'
      }
    },

    formatNumber (number) {
      if (number) {
        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } else {
        return '-'
      }
    },

    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },

    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.no % 2 === 1) return 'table-body-color'
    },

    onClickDatePicker (number) {
      let datePicker = null
      datePicker = document.getElementById(`datepicker-custom-${number}`)
      datePicker.focus()
    },

    onPagination (page) {
      this.currentPage = page
    },

    clickPath (e, data) {
      const setPage = e
      if (setPage === 'add') {
        router.push(`/ReceiveByPOMGT/${setPage}`)
      } else if (setPage === 'view') {
        router.push(`/ReceiveByPOMGT/${setPage}/${data}`)
      } else if (setPage === 'edit') {
        router.push(`/ReceiveByPOMGT/${setPage}/${data}`)
      }
    },

    async getSupplierDD () {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/supplier`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        let data = response.data.data
        let temp = []
        data.forEach((element) => {
          temp.push({
            value: element.id,
            label: element.name
          })
        })
        this.supplierDD = temp
      }).catch((error) => {
        this.SwalError(error.message, 'เกิดข้อผิดพลาดในดึงข้อมูล')
      })
    },

    async clecrFilter () {
      this.search = ''
      this.searchStartDate = null
      this.searchEndDate = null
      this.supplierselect = null
      this.isPOclose = false
      await this.filterPOReceive()
    },

    async filterPOReceive () {
      const searchData = {
        search: this.search.trim() || null,
        searchStartDate: this.searchStartDate
          ? moment(this.searchStartDate, 'DD/MM/YYYY').startOf('day')
          : null,
        searchEndDate: this.searchEndDate
          ? moment(this.searchEndDate, 'DD/MM/YYYY').endOf('day')
          : null,
        supplierId: this.supplierselect?.value || null,
        isPOclose: this.isPOclose || false
      }

      await Axios.request({
        method: 'post',
        url: `${configAPI.SALE_PORT}/api/purchaseOrder/clinic/${this.clnId}/filterPOReceive`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: searchData
      }).then((response) => {
        let temp = response.data.data
        for (let item of temp) {
          // cal percent
          let sumreceive = item.receive_po_list.reduce((a, b) => a + parseFloat(b.rp_received_amount), 0)
          let sumamount = item.po_items.reduce((a, b) => a + parseFloat(b.poi_amount), 0)
          item.po_receive_amount_percent = ((sumreceive / sumamount) * 100).toFixed(2)

          // detail section
          let dateArr = []
          let detailArr = {
            prd: [],
            mat: []
          }
          for (let item2 of item.po_items) {
            if (item2.poi_prd_id) {
              let index = detailArr.prd.findIndex((x) => x.rp_prd_id === item2.poi_prd_id)
              if (index === -1) {
                detailArr.prd.push({
                  prd_id: item2.poi_prd_id,
                  prd_name: item2.prd_name,
                  poi_amount: item2.poi_amount,
                  unit: item2.prd_unit,
                  rp_amount: 0
                })
              } else {
                detailArr.prd[index].poi_amount += item2.poi_amount
              }
            } else if (item2.poi_mat_id) {
              let index = detailArr.mat.findIndex((x) => x.rp_mat_id === item2.poi_mat_id)
              if (index === -1) {
                detailArr.mat.push({
                  mat_id: item2.poi_mat_id,
                  mat_name: item2.mat_name,
                  poi_amount: item2.poi_amount,
                  unit: item2.mat_unit,
                  rp_amount: 0
                })
              } else {
                detailArr.mat[index].poi_amount += item2.poi_amount
              }
            }
          }
          for (let detail of item.receive_po_list) {
            dateArr.push(detail.rp_received_date)
            if (detail.rp_prd_id) {
              let index = detailArr.prd.findIndex((x) => x.prd_id === detail.rp_prd_id)
              if (index !== -1) {
                detailArr.prd[index].rp_amount += detail.rp_received_amount || 0
              }
            } else {
              let index = detailArr.mat.findIndex((x) => x.mat_id === detail.rp_mat_id)
              if (index !== -1) {
                detailArr.mat[index].rp_amount += detail.rp_received_amount || 0
              }
            }
          }
          item.po_receive_date = dateArr.sort((a, b) => new Date(b) - new Date(a))
          item.po_receive_detail = detailArr
        }
        this.rows_po_receive = temp
        this.rows_po_receive_length = response.data.dataCount
      }).catch((error) => {
        this.SwalError(error.message, 'เกิดข้อผิดพลาดในดึงข้อมูล')
      })
    }
  }
}
</script>
