<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span class="unselectable" style="font-size: 25px; color: black;">กลุ่มสินค้า</span>
              </div>
              <div class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button @click="checkmodal = 'add', handleClick()" type="button" variant="primary" class="w-100 btn-min-size"><i class="circle-plus_ct"></i>เพิ่ม</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="ml-3">
              <b-text class="mr-5" style="white-space: nowrap; cursor: pointer;" @click="activeTab = 'tab1'" :style="{ color: activeTab === 'tab1' ? 'rgb(8, 159, 175)' : 'black' }">ชื่อกลุ่มสินค้าหลัก</b-text>
              <b-text class="mr-5" style="white-space: nowrap; cursor: pointer;" @click="activeTab = 'tab2'" :style="{ color: activeTab === 'tab2' ? 'rgb(8, 159, 175)' : 'black' }">ชื่อกลุ่มสินค้าย่อย</b-text>
            </div>
            <hr>
            <br>

            <div v-if="activeTab === 'tab1'">
              <div class="mx-3">
                <b-row>
                  <b-col cols="12" md="7" lg="5">
                    <b-row>
                      <b-col sm="12" md class="mb-3">
                        <div class="iq-email-search d-flex">
                          <div style="width: 100%;">
                            <div class="form-group mb-0">
                              <input class="form-control" v-model="searchText1" @keydown="enterSearch1" id="exampleInputEmail1" placeholder="Search" style="width: 100%;">
                              <a class="search-link" style="cursor: pointer;" v-on:click="filterProductGroupT1" ><i class="ri-search-line" /></a>
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                        <span class="mx-2 font-size-14 text-primary">
                          {{ rowsT1?.length > 0 ? (((currentPage1 - 1) * 10) + 1) + ' - ' + (((perPage * currentPage1) > rowsT1?.length) ? rowsT1?.length : (perPage * currentPage1) ) + ' of ' + rowsT1Length : '0 of ' + rowsT1Length }} รายการ
                          <!-- {{ rowsT1Length > 0 ? (((currentPage1 - 1) * 10) + (rowsT1?.length > 0 ? 1 : 0)) + ' - ' + (((perPage * currentPage1) > rowsT1?.length) ? rowsT1?.length : (perPage * currentPage1) ) + ' of ' + rowsT1Length : 0 }} รายการ -->
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                </div>
                <br>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="rowsT1" :fields="columnsT1"
                  :current-page="currentPage1"
                  :per-page="perPage"
                  @pagination="onPagination1"
                  :tbody-tr-class="rowClass" thead-class="table-header-color"
                  >
                  <template v-slot:cell(variable1)="data">
                    <span v-if="!data.item.editable">{{ ((currentPage1 - 1) * 10) + data.index + 1 }}</span>
                    <input type="text" v-model="data.item.variable1" v-else class="form-control">
                  </template>
                  <template v-slot:cell(pg_name)="data">
                    <span v-if="!data.item.editable">{{ data.item.pg_name }}</span>
                    <input type="text" v-model="data.item.pg_name" v-else class="form-control">
                  </template>
                  <template v-slot:cell(name)="data">
                    <span v-if="!data.item.editable">{{ data.item.name }}</span>
                    <input type="text" v-model="data.item.name" v-else class="form-control">
                  </template>
                  <template v-slot:cell(variable4)="data">
                    <span v-if="!data.item.editable">{{ data.item.variable4 }}</span>
                    <input type="text" v-model="data.item.variable4" v-else class="form-control">
                  </template>
                  <template v-slot:cell(variable5)="data">
                    <span v-if="!data.item.editable">{{ data.item.variable5 }}</span>
                    <input type="text" v-model="data.item.variable5" v-else class="form-control">
                  </template>
                  <template v-slot:cell(variable6)="data">
                    <span v-if="!data.item.editable">{{ data.item.variable6 }}</span>
                    <input type="text" v-model="data.item.variable6" v-else class="form-control">
                  </template>
                  <template v-slot:cell(variable7)="data">
                    <div style="display: flex; justify-content: center;">
                      <b-button variant=" iq-bg-info mx-1" size="sm" @click="checkmodal = 'view', handleClick(data.item)"><i class="ri-eye-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-success mx-1" size="sm" @click="checkmodal = 'edit', handleClick(data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </div>
                  </template>
                </b-table>
                <br>
              </b-col>
              <b-pagination
                v-model="currentPage1"
                :total-rows="rowsT1.length"
                :per-page="perPage"
                align="center"
              />
            </div>

            <b-modal v-model="showModalT1" :title="'กลุ่มสินค้าหลัก ' + (checkmodal === 'add' ? '(เพิ่ม)' : checkmodal === 'edit' ? '(แก้ไข)' : '(ดูรายละเอียด)')" cancel-title="เพิ่ม" ok-title="ยกเลิก"
                cancel-variant="primary" ok-variant="danger" @close.prevent="closeModal('t1')" no-close-on-backdrop>
                <b-row>
                  <b-col class="">
                    <!-- <b-col lg="12" sm="12">
                        <b-text style="color: black;">รหัสกลุ่มสินค้าหลัก <span v-if="!productGroup1.code_product?.trim()" style="color: red;"> *</span></b-text>
                    </b-col>
                    <b-col lg="12" sm="12">
                      <b-form-input type="text" :disabled="checkmodal === 'view'" v-model="productGroup1.code_product" placeholder=""></b-form-input>
                    </b-col> -->
                    <br>
                    <b-col lg="12" sm="12">
                        <b-text style="color: black;">ชื่อกลุ่มสินค้าหลัก <span v-if="!productGroup1.name_product?.trim()" style="color: red;"> *</span></b-text>
                    </b-col>
                    <b-col lg="12" sm="12">
                      <b-form-input type="text" :disabled="checkmodal === 'view'" v-model="productGroup1.name_product" placeholder=""></b-form-input>
                    </b-col>
                    <br>
                  </b-col>
                </b-row>
                <template #modal-footer>
                    <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                      <b-button
                        style="border-radius: 0.5rem; padding: 0 .7rem;"
                        variant="primary"
                        class="btn-min-size"
                        size="sm"
                        @click="handleSubmitClick"
                        :disabled="productGroup1.name_product ? false : true"
                        :hidden="checkmodal === 'view' ? true : false"
                      >
                        <i class="save-data_ct"/>บันทึก
                      </b-button>
                      <b-button
                        variant="none"
                        class="iq-bg-danger btn-cancel-border btn-min-size"
                        style="border-radius: 0.5rem;"
                        size="sm"
                        @click="closeModal('t1')"
                      >
                        ยกเลิก
                      </b-button>
                    </div>
                  </template>
            </b-modal>

            <b-modal v-model="showModal" :title="'กลุ่มสินค้าย่อย ' + (checkmodal === 'add' ? '(เพิ่ม)' : checkmodal === 'edit' ? '(แก้ไข)' : '(ดูรายละเอียด)')" cancel-title="เพิ่ม" ok-title="ยกเลิก"
                cancel-variant="primary" ok-variant="danger" @close.prevent="closeModal('t2')" no-close-on-backdrop>
                <b-row>
                  <b-col class="">
                    <b-col lg="12" sm="12" class="">
                      <label class="pb-0 mb-0" style="color: black;">กลุ่มสินค้าหลัก <span v-if="!productGroup2.group_product_main" style="color: red;"> *</span></label>
                      <b-form-group style="color: black;" >
                      <v-select v-model="productGroup2.group_product_main" :disabled="checkmodal === 'view'" :options="optionsSelect"></v-select>
                      </b-form-group>
                  </b-col>
                    <!-- <b-col lg="12" sm="12">
                        <b-text style="color: black;">รหัสกลุ่มสินค้าย่อย <span v-if="!productGroup2.group_supply_code?.trim()" style="color: red;"> *</span></b-text>
                    </b-col>
                    <b-col lg="12" sm="12">
                      <b-form-input type="text" :disabled="checkmodal === 'view'" v-model="productGroup2.group_supply_code" placeholder=""></b-form-input>
                    </b-col> -->
                    <br>
                    <b-col lg="12" sm="12">
                        <b-text style="color: black;">ชื่อกลุ่มสินค้าย่อย <span v-if="!productGroup2.group_supply_name?.trim()" style="color: red;"> *</span></b-text>
                    </b-col>
                    <b-col lg="12" sm="12">
                      <b-form-input type="text" :disabled="checkmodal === 'view'" v-model="productGroup2.group_supply_name" placeholder=""></b-form-input>
                    </b-col>
                    <br>
                  </b-col>
                </b-row>
                <template #modal-footer>
                    <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                      <b-button
                        style="border-radius: 0.5rem; padding: 0 .7rem;"
                        variant="primary"
                        class="btn-min-size"
                        size="sm"
                        @click="handleSubmitClick"
                        :disabled="productGroup2.group_product_main && productGroup2.group_supply_name ? false : true"
                        :hidden="checkmodal === 'view' ? true : false"
                      >
                        <i class="save-data_ct"/>บันทึก
                      </b-button>
                      <b-button
                        variant="none"
                        class="iq-bg-danger btn-cancel-border btn-min-size"
                        style="border-radius: 0.5rem;"
                        size="sm"
                        @click="closeModal('t2')"
                      >
                        ยกเลิก
                      </b-button>
                    </div>
                  </template>
            </b-modal>

            <div v-if="activeTab === 'tab2'">
              <div class="mx-3">
                <b-row>
                  <b-col cols="12" md="7" lg="5">
                    <b-row>
                      <b-col sm="12" md class="mb-3">
                        <div class="iq-email-search d-flex">
                          <div style="width: 100%;">
                            <div class="form-group mb-0">
                              <input class="form-control" v-model="searchText2" @keydown="enterSearch2" id="exampleInputEmail1" placeholder="Search" style="width: 100%;">
                              <a class="search-link" style="cursor: pointer;" v-on:click="filterProductGroup" ><i class="ri-search-line" /></a>
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                        <span class="mx-2 font-size-14 text-primary">
                          {{ rows?.length > 0 ? (((currentPage2 - 1) * 10) + 1) + ' - ' + (((perPage * currentPage2) > rows?.length) ? rows?.length : (perPage * currentPage2) ) + ' of ' + rowsLength : '0 of ' + rowsLength }} รายการ
                          <!-- {{ rowsLength > 0 ? (((currentPage2 - 1) * 10) + (rows?.length > 0 ? 1 : 0)) + ' - ' + (((perPage * currentPage2) > rows?.length) ? rows?.length : (perPage * currentPage2) ) + ' of ' + rowsLength : 0 }} รายการ -->
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                </div>
                <br>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="rows" :fields="columns"
                  :current-page="currentPage2"
                  :per-page="perPage"
                  @pagination="onPagination2"
                  :tbody-tr-class="rowClass" thead-class="table-header-color"
                  >
                  <template v-slot:cell(variable1)="data">
                    <span v-if="!data.item.editable">{{ ((currentPage2 - 1) * 10) + data.index + 1 }}</span>
                    <input type="text" v-model="data.item.variable1" v-else class="form-control">
                  </template>
                  <template v-slot:cell(pg_name)="data">
                    <span v-if="!data.item.editable">{{ data.item.pg_name }}</span>
                    <input type="text" v-model="data.item.pg_name" v-else class="form-control">
                  </template>
                  <template v-slot:cell(name)="data">
                    <span v-if="!data.item.editable">{{ data.item.name }}</span>
                    <input type="text" v-model="data.item.name" v-else class="form-control">
                  </template>
                  <template v-slot:cell(variable4)="data">
                    <span v-if="!data.item.editable">{{ data.item.variable4 }}</span>
                    <input type="text" v-model="data.item.variable4" v-else class="form-control">
                  </template>
                  <template v-slot:cell(variable5)="data">
                    <span v-if="!data.item.editable">{{ data.item.variable5 }}</span>
                    <input type="text" v-model="data.item.variable5" v-else class="form-control">
                  </template>
                  <template v-slot:cell(variable6)="data">
                    <span v-if="!data.item.editable">{{ data.item.variable6 }}</span>
                    <input type="text" v-model="data.item.variable6" v-else class="form-control">
                  </template>
                  <template v-slot:cell(variable7)="data">
                    <div style="display: flex; justify-content: center;">
                      <b-button variant=" iq-bg-info mx-1" size="sm" @click="checkmodal = 'view', handleClick(data.item)"><i class="ri-eye-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-success mx-1" size="sm" @click="checkmodal = 'edit', handleClick(data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </div>
                  </template>
                </b-table>
                <br>
              </b-col>
              <b-pagination
                v-model="currentPage2"
                :total-rows="rows.length"
                :per-page="perPage"
                align="center"
              />
            </div>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
// import router from '@/router'
import Vue from 'vue'
import axios from 'axios'
import { configAPI } from '../../configBase'

export default {
  name: 'UiDataTable',
  async mounted () {
    await this.filterProductGroup()
    await this.filterProductGroupT1()
    xray.index()
  },
  watch: {
    activeTab () {
      this.searchText1 = ''
      this.searchText2 = ''
      if (this.activeTab === 'tab1') {
        this.filterProductGroupT1()
      } else {
        this.filterProductGroup()
      }
    }
  },
  methods: {
    async enterSearch1 (e) {
      if (e.key === 'Enter') {
        await this.filterProductGroupT1()
      }
    },
    async enterSearch2 (e) {
      if (e.key === 'Enter') {
        await this.filterProductGroup()
      }
    },
    async filterProductGroup () {
      let search = this.searchText2.trim().length > 0 ? this.searchText2.trim() : ''
      let productGroupAPI = {
        method: 'get',
        url: `${configAPI.MD_PORT}/api/productSubGroup/?search=` + search,
        headers: {
          'Content-Type': 'application/json'
        }
      }

      await axios.request(productGroupAPI)
        .then((response) => {
          if (response.data.status) {
            this.rows = response.data.data?.map((el, index) => {
              el.index = index
              return el
            })
            this.rowsLength = response.data.count
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาดในการดึงข้อมูล</span>`,
            html: '<span>' + error.message + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async filterProductGroupT1 () {
      let search = this.searchText1.trim().length > 0 ? this.searchText1.trim() : ''
      let productGroupAPI = {
        method: 'get',
        url: `${configAPI.MD_PORT}/api/productGroup/?search=` + search,
        headers: {
          'Content-Type': 'application/json'
        }
      }

      await axios.request(productGroupAPI)
        .then((response) => {
          if (response.data.status) {
            let optionList = []
            this.rowsT1 = response.data.data?.map((el, index) => {
              optionList.push({
                code: el.id,
                label: el.name
              })
              el.index = index
              return el
            })
            this.rowsT1Length = response.data.count
            this.optionsSelect = optionList
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาดในการดึงข้อมูล</span>`,
            html: '<span>' + error.message + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    closeModal (tab) {
      if (this.checkmodal !== 'view') {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะยกเลิกใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.showModalT1 = false
            this.showModal = false
          }
        })
      } else {
        this.showModalT1 = false
        this.showModal = false
      }
    },
    remove (item) {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะลบข้อมูลใช่หรือไม่?</span>',
        // text: 'ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (this.activeTab === 'tab1') {
            const errorList = this.checkDelError(item.id)
            if (errorList.length > 0) {
              Vue.swal.fire({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลไม่สำเร็จ</span>',
                html: 'เนื่องจากกลุ่มสินค้าหลักชนิดนี้ มีกลุ่มสินค้าย่อยอยู่แล้ว',
                icon: 'error',
                showConfirmButton: true,
                confirmButtonText: '<b-button style="font-size: 14px; text-align: center">Ok</b-button>',
                confirmButtonColor: '#089bab',
                customClass: {
                  confirmButton: 'custom-confirm-button-class mx-1'
                }
              })
              return
            }
          }
          await this.delProductGroup(item.id)

          if (this.activeTab === 'tab1') {
            let index = this.rowsT1.indexOf(item)
            this.rowsT1.splice(index, 1)
          } else {
            let index = this.rows.indexOf(item)
            this.rows.splice(index, 1)
          }
        }
      })
    },
    async delProductGroup (id) {
      let apiDetails = {
        method: 'post',
        url: `${configAPI.MD_PORT}/api/productSubGroup/${id}/delete/`,
        headers: {
          'Content-Type': 'application/json'
        }
      }

      if (this.activeTab === 'tab1') {
        apiDetails = {
          method: 'post',
          url: `${configAPI.MD_PORT}/api/productGroup/${id}/delete/`,
          headers: {
            'Content-Type': 'application/json'
          }
        }
      }

      await axios.request(apiDetails)
        .then((response) => {
          Vue.swal({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
            icon: 'success',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 2000
          })
        })
        .catch((error) => {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลไม่สำเร็จ</span>',
            html: '<span>' + error.message + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    onPagination1 (page) {
      this.currentPage1 = page
    },
    onPagination2 (page) {
      this.currentPage2 = page
    },
    handleClick (data) {
      if (this.activeTab === 'tab1') {
        this.productGroup1.id = this.checkmodal === 'add' ? '' : data.id
        this.productGroup1.name_product = this.checkmodal === 'add' ? '' : data.name
        this.showModalT1 = true
      } else {
        this.productGroup2.id = this.checkmodal === 'add' ? '' : data.id
        this.productGroup2.group_product_main = this.checkmodal === 'add' ? '' : this.optionsSelect.find(el => el.code === data.pg_id)
        this.productGroup2.group_supply_name = this.checkmodal === 'add' ? '' : data.name
        this.showModal = true
      }
    },
    async saveProductGroupTab1 () {
      let apiData = null
      if (this.checkmodal === 'add') {
        apiData = {
          method: 'post',
          url: `${configAPI.MD_PORT}/api/productGroup`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            no: null,
            name: this.productGroup1.name_product
          }
        }
      } else {
        apiData = {
          method: 'put',
          url: `${configAPI.MD_PORT}/api/productGroup/${this.productGroup1.id}/`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            no: null,
            name: this.productGroup1.name_product
          }
        }
      }
      await axios.request(apiData)
        .then((response) => {
          this.showModalT1 = false
          if (response.data.status) {
            this.SwalSaveSuccess()
          } else {
            this.SwalError(response.data.error === 'name duplicate' ? 'ชื่อประเภทซ้ำกับในระบบ กรุณาลองใหม่' : response.data.error, 'บันทึกข้อมูลไม่สำเร็จ')
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.checkmodal === 'add' ? 'บันทึกข้อมูลไม่สำเร็จ' : 'แก้ไขข้อมูลไม่สำเร็จ'}</span>`,
            html: '<span>' + error.message + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async saveProductGroupTab2 () {
      let apiData = null
      if (this.checkmodal === 'add') {
        apiData = {
          method: 'post',
          url: `${configAPI.MD_PORT}/api/productSubGroup`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            pg_id: this.productGroup2.group_product_main.code,
            no: null,
            name: this.productGroup2.group_supply_name
          }
        }
      } else {
        apiData = {
          method: 'put',
          url: `${configAPI.MD_PORT}/api/productSubGroup/${this.productGroup2.id}/`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            pg_id: this.productGroup2.group_product_main.code,
            no: null,
            name: this.productGroup2.group_supply_name
          }
        }
      }
      await axios.request(apiData)
        .then((response) => {
          this.showModal = false
          if (response.data.status) {
            this.SwalSaveSuccess()
          } else {
            this.SwalError(response.data.error === 'name duplicate' ? 'ชื่อประเภทซ้ำย่อยกับในระบบ กรุณาลองใหม่' : response.data.error, 'บันทึกข้อมูลไม่สำเร็จ')
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.checkmodal === 'add' ? 'บันทึกข้อมูลไม่สำเร็จ' : 'แก้ไขข้อมูลสำเร็จ'}</span>`,
            html: '<span>' + error.message + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    handleSubmitClick () {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.checkmodal !== 'edit' ? 'คุณมั่นใจว่าจะบันทึกข้อมูลใช่หรือไม่?' : 'คุณมั่นใจว่าจะแก้ไขข้อมูลใช่หรือไม่?'}</span>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          const errorList = this.checkError(this.activeTab)
          if (errorList.length > 0) {
            let errMessage = ''
            for (let e = 0; e < errorList.length; e++) {
              errMessage += '<br/>' + errorList[e]
            }
            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลไม่สำเร็จ</span>',
              html: errMessage,
              icon: 'error',
              showConfirmButton: true,
              confirmButtonText: '<b-button style="font-size: 14px; text-align: center">Ok</b-button>',
              confirmButtonColor: '#089bab',
              customClass: {
                confirmButton: 'custom-confirm-button-class mx-1'
              }
            })
            return
          }
          if (this.activeTab === 'tab1') {
            await this.saveProductGroupTab1()
          } else {
            await this.saveProductGroupTab2()
          }
        }
      })
    },
    SwalSaveSuccess () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
        icon: 'success',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        timer: 2000
      }).then(async () => {
        await this.filterProductGroup()
        await this.filterProductGroupT1()
        // window.location.reload()
      })
    },
    checkError (tab) {
      let error = []
      if (tab === 'tab1') {
        /* if (!this.productGroup1.code_product || this.productGroup1.code_product?.trim() === '') {
          error.push('กรุณากรอกรหัสกลุ่มสินค้าหลัก')
        } else {
          this.productGroup1.code_product = this.productGroup1.code_product?.trim()
        } */
        if (!this.productGroup1.name_product || this.productGroup1.name_product?.trim() === '') {
          error.push('กรุณากรอกชื่อกลุ่มสินค้าหลัก')
        } else {
          this.productGroup1.name_product = this.productGroup1.name_product?.trim()
        }
      } else {
        if (!this.productGroup2.group_product_main || this.productGroup2.code_product === null) {
          error.push('กรุณาเลือก กลุ่มสินค้าหลัก')
        }
        /* if (!this.productGroup2.group_supply_code || this.productGroup2.group_supply_code?.trim() === '') {
          error.push('กรุณากรอกรหัสกลุ่มสินค้าย่อย')
        } else {
          this.productGroup2.group_supply_code = this.productGroup2.group_supply_code?.trim()
        } */
        if (!this.productGroup2.group_supply_name || this.productGroup2.group_supply_name?.trim() === '') {
          error.push('กรุณากรอกชื่อกลุ่มสินค้าย่อย')
        } else {
          this.productGroup2.group_supply_name = this.productGroup2.group_supply_name?.trim()
        }
      }
      return error
    },
    checkDelError (pgId) {
      return this.rows.filter(el => el.pg_id === pgId)
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    }
  },
  data () {
    return {
      searchText1: '',
      searchText2: '',
      activeTab: 'tab1',
      productGroup1: {
        id: null,
        code_product: null,
        name_product: null
      },
      productGroup2: {
        id: null,
        group_product_main: null,
        group_supply_code: null,
        group_supply_name: null
      },
      showModalT1: false,
      showModal: false,
      checkmodal: 'add',
      columns: [
        { label: 'ลำดับ', key: 'variable1', class: 'text-center table-size-50-150 ellips' },
        { label: 'ชื่อประเภทหลัก', key: 'pg_name', class: 'text-center table-size-200-400 ellips' },
        { label: 'ชื่อประเภทย่อย', key: 'name', class: 'text-center table-size-200-400 ellips' },
        { label: 'จัดการ', key: 'variable7', class: 'text-center table-size-200-400 ellips' }
      ],
      rowsT1: [],
      rowsT1Length: 0,
      columnsT1: [
        { label: 'ลำดับ', key: 'variable1', class: 'text-center table-size-50-150 ellips' },
        { label: 'ชื่อประเภทหลัก', key: 'name', class: 'text-center table-size-200-400 ellips' },
        { label: 'จัดการ', key: 'variable7', class: 'text-center table-size-100-300 ellips' }
      ],
      rows: [],
      rowsLength: 0,
      currentPage1: 1,
      currentPage2: 1,
      perPage: 10,
      optionsSelect: []
    }
  }
}
</script>
