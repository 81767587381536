<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span v-if="setPage() === 'add'" style="font-size: 25px; color: black;">Follow Up Case
                  (เพิ่ม)</span>
                <span v-else-if="setPage() === 'edit'"
                  style="font-size: 25px; color: black;">Follow Up Case (แก้ไข)</span>
                <span v-else style="font-size: 25px; color: black;">Follow Up Case (ดูรายละเอียด)</span>
              </div>
              <div v-if="setPage() === 'add' || setPage() === 'edit'" class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-6 pl-3 pr-2 my-1 my-sm-0 col-sm-auto">
                    <b-button type="button" variant="primary" @click="handleClick(true)" class="w-100 btn-min-size"><i class="save-data_ct"></i>บันทึก</b-button>
                  </div>
                  <div class="col-6 pl-2 pr-3 my-1 my-sm-0 col-sm-auto">
                    <b-button type="button" variant="none" @click="handleClick(false)" class="w-100 btn-min-size iq-bg-danger btn-cancel-border">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
              <div v-else class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="button" variant="none" @click="cancelClick()" class="w-100 btn-min-size iq-bg-danger btn-cancel-border">ยกเลิก</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col class="">
                <div class="mx-3" style="color: black; font-size: large;">บริการ : {{ rows[0].st_name }}</div>
                <br>
                <div class="row">
                  <b-col xl="4" lg="6" md="6" sm="12" style="display: flex; align-items: center; white-space: nowrap;">
                    <b-text class="mx-3" style="color: black;">Day 0</b-text>
                    <v-select v-model="selectDay0" :disabled="setPage() === 'view' ? true : false" :options="followType" style="min-width: 130px; max-width: 130px;"></v-select>
                    <!-- <b-form-select v-if="setPage() === 'edit'" class="mx-3" v-model="selectDay0" :options="supplierOptions1" placeholder="Select Supplier" style="min-width: 130px; max-width: 130px;"></b-form-select>
                    <b-form-select v-else class="mx-3" v-model="selectDay0" :options="supplierOptions1" placeholder="Select Supplier" disabled style="min-width: 130px; max-width: 130px;"></b-form-select> -->
                  </b-col>
                </div>
                <b-col lg="12" sm="12" class="my-2">
                  <b-form-textarea
                    v-if="setPage() === 'edit'"
                    type="text"
                    placeholder="รายละเอียด"
                    v-model="rows[0].day0"
                  ></b-form-textarea>
                  <b-form-textarea
                    v-else
                    type="text"
                    placeholder="รายละเอียด"
                    v-model="rows[0].day0"
                    readonly
                  ></b-form-textarea>
                </b-col>
                <br>
                <div class="row">
                  <b-col xl="4" lg="6" md="6" sm="12" style="display: flex; align-items: center; white-space: nowrap;">
                    <b-text class="mx-3" style="color: black;">Day 1</b-text>
                    <v-select v-model="selectDay1" :disabled="setPage() === 'view' ? true : false" :options="followType" style="min-width: 130px; max-width: 130px;"></v-select>
                    <!-- <b-form-select v-if="setPage() === 'edit'" class="mx-3" v-model="selectDay1" :options="supplierOptions2" placeholder="Select Supplier" style="min-width: 130px; max-width: 130px;"></b-form-select>
                    <b-form-select v-else class="mx-3" v-model="selectDay1" :options="supplierOptions2" placeholder="Select Supplier" disabled style="min-width: 130px; max-width: 130px;"></b-form-select> -->
                  </b-col>
                </div>
                <b-col lg="12" sm="12" class="my-2">
                  <b-form-textarea
                    v-if="setPage() === 'edit'"
                    type="text"
                    placeholder="รายละเอียด"
                    v-model="rows[0].day1"
                  ></b-form-textarea>
                  <b-form-textarea
                    v-else
                    type="text"
                    placeholder="รายละเอียด"
                    v-model="rows[0].day1"
                    readonly
                  ></b-form-textarea>
                </b-col>
                <br>
                <div class="row">
                  <b-col xl="4" lg="6" md="6" sm="12" style="display: flex; align-items: center; white-space: nowrap;">
                    <b-text class="mx-3" style="color: black;">Day 3</b-text>
                    <v-select v-model="selectDay3" :disabled="setPage() === 'view' ? true : false" :options="followType" style="min-width: 130px; max-width: 130px;"></v-select>
                    <!-- <b-form-select v-if="setPage() === 'edit'" class="mx-3" v-model="selectDay3" :options="supplierOptions3" placeholder="Select Supplier" style="min-width: 130px; max-width: 130px;"></b-form-select>
                    <b-form-select v-else class="mx-3" v-model="selectDay3" :options="supplierOptions3" placeholder="Select Supplier" disabled style="min-width: 130px; max-width: 130px;"></b-form-select> -->
                  </b-col>
                </div>
                <b-col lg="12" sm="12" class="my-2">
                  <b-form-textarea
                    v-if="setPage() === 'edit'"
                    type="text"
                    placeholder="รายละเอียด"
                    v-model="rows[0].day3"
                  ></b-form-textarea>
                  <b-form-textarea
                    v-else
                    type="text"
                    placeholder="รายละเอียด"
                    v-model="rows[0].day3"
                    readonly
                  ></b-form-textarea>
                </b-col>
                <br>
                <div class="row">
                  <b-col xl="4" lg="6" md="6" sm="12" style="display: flex; align-items: center; white-space: nowrap;">
                    <b-text class="mx-3" style="color: black;">1 Week</b-text>
                    <v-select v-model="selectWeek1" :disabled="setPage() === 'view' ? true : false" :options="followType" style="min-width: 130px; max-width: 130px;"></v-select>
                    <!-- <b-form-select v-if="setPage() === 'edit'" class="mx-3" v-model="selectWeek1" :options="supplierOptions4" placeholder="Select Supplier" style="min-width: 130px; max-width: 130px;"></b-form-select>
                    <b-form-select v-else class="mx-3" v-model="selectWeek1" :options="supplierOptions4" placeholder="Select Supplier" disabled style="min-width: 130px; max-width: 130px;"></b-form-select> -->
                  </b-col>
                </div>
                <b-col lg="12" sm="12" class="my-2">
                  <b-form-textarea
                    v-if="setPage() === 'edit'"
                    type="text"
                    placeholder="รายละเอียด"
                    v-model="rows[0].week1"
                  ></b-form-textarea>
                  <b-form-textarea
                    v-else
                    type="text"
                    placeholder="รายละเอียด"
                    v-model="rows[0].week1"
                    readonly
                  ></b-form-textarea>
                </b-col>
                <br>
                <div class="row">
                  <b-col xl="4" lg="6" md="6" sm="12" style="display: flex; align-items: center; white-space: nowrap;">
                    <b-text class="mx-3" style="color: black;">2 Week</b-text>
                    <v-select v-model="selectWeek2" :disabled="setPage() === 'view' ? true : false" :options="followType" style="min-width: 130px; max-width: 130px;"></v-select>
                    <!-- <b-form-select v-if="setPage() === 'edit'" class="mx-3" v-model="selectWeek2" :options="supplierOptions5" placeholder="Select Supplier" style="min-width: 130px; max-width: 130px;"></b-form-select>
                    <b-form-select v-else class="mx-3" v-model="selectWeek2" :options="supplierOptions5" placeholder="Select Supplier" disabled style="min-width: 130px; max-width: 130px;"></b-form-select> -->
                  </b-col>
                </div>
                <b-col lg="12" sm="12" class="my-2">
                  <b-form-textarea
                    v-if="setPage() === 'edit'"
                    type="text"
                    placeholder="รายละเอียด"
                    v-model="rows[0].week2"
                  ></b-form-textarea>
                  <b-form-textarea
                    v-else
                    type="text"
                    placeholder="รายละเอียด"
                    v-model="rows[0].week2"
                    readonly
                  ></b-form-textarea>
                </b-col>
                <br>
                <div class="row">
                  <b-col xl="4" lg="6" md="6" sm="12" style="display: flex; align-items: center; white-space: nowrap;">
                    <b-text class="mx-3" style="color: black;">4 Week</b-text>
                    <v-select v-model="selectWeek4" :disabled="setPage() === 'view' ? true : false" :options="followType" style="min-width: 130px; max-width: 130px;"></v-select>
                    <!-- <b-form-select v-if="setPage() === 'edit'" class="mx-3" v-model="selectWeek4" :options="supplierOptions6" placeholder="Select Supplier" style="min-width: 130px; max-width: 130px;"></b-form-select>
                    <b-form-select v-else class="mx-3" v-model="selectWeek4" :options="supplierOptions6" placeholder="Select Supplier" disabled style="min-width: 130px; max-width: 130px;"></b-form-select> -->
                  </b-col>
                </div>
                <b-col lg="12" sm="12" class="my-2">
                  <b-form-textarea
                    v-if="setPage() === 'edit'"
                    type="text"
                    placeholder="รายละเอียด"
                    v-model="rows[0].week4"
                  ></b-form-textarea>
                  <b-form-textarea
                    v-else
                    type="text"
                    placeholder="รายละเอียด"
                    v-model="rows[0].week4"
                    readonly
                  ></b-form-textarea>
                </b-col>
                <br>
                <div class="row">
                  <b-col xl="4" lg="6" md="6" sm="12" style="display: flex; align-items: center; white-space: nowrap;">
                    <b-text class="mx-3" style="color: black;">3 month (12 weeks)</b-text>
                    <v-select v-model="selectMonth3" :disabled="setPage() === 'view' ? true : false" :options="followType" style="min-width: 130px; max-width: 130px;"></v-select>
                    <!-- <b-form-select v-if="setPage() === 'edit'" class="mx-3" v-model="selectMonth3" :options="supplierOptions7" placeholder="Select Supplier" style="min-width: 130px; max-width: 130px;"></b-form-select>
                    <b-form-select v-else class="mx-3" v-model="selectMonth3" :options="supplierOptions7" placeholder="Select Supplier" disabled style="min-width: 130px; max-width: 130px;"></b-form-select> -->
                  </b-col>
                </div>
                <b-col lg="12" sm="12" class="my-2">
                  <b-form-textarea
                    v-if="setPage() === 'edit'"
                    type="text"
                    placeholder="รายละเอียด"
                    v-model="rows[0].month3"
                  ></b-form-textarea>
                  <b-form-textarea
                    v-else
                    type="text"
                    placeholder="รายละเอียด"
                    v-model="rows[0].month3"
                    readonly
                  ></b-form-textarea>
                </b-col>
                <br>
                <div class="row">
                  <b-col xl="4" lg="6" md="6" sm="12" style="display: flex; align-items: center; white-space: nowrap;">
                    <b-text class="mx-3" style="color: black;">6 month (24 weeks)</b-text>
                    <v-select v-model="selectMonth6" :disabled="setPage() === 'view' ? true : false" :options="followType" style="min-width: 130px; max-width: 130px;"></v-select>
                    <!-- <b-form-select v-if="setPage() === 'edit'" class="mx-3" v-model="selectMonth6" :options="supplierOptions8" placeholder="Select Supplier" style="min-width: 130px; max-width: 130px;"></b-form-select>
                    <b-form-select v-else class="mx-3" v-model="selectMonth6" :options="supplierOptions8" placeholder="Select Supplier" disabled style="min-width: 130px; max-width: 130px;"></b-form-select> -->
                  </b-col>
                </div>
                <b-col lg="12" sm="12" class="my-2">
                  <b-form-textarea
                    v-if="setPage() === 'edit'"
                    type="text"
                    placeholder="รายละเอียด"
                    v-model="rows[0].month6"
                  ></b-form-textarea>
                  <b-form-textarea
                    v-else
                    type="text"
                    placeholder="รายละเอียด"
                    v-model="rows[0].month6"
                    readonly
                  ></b-form-textarea>
                </b-col>
                <br>
                <div class="row">
                  <b-col xl="4" lg="6" md="6" sm="12" style="display: flex; align-items: center; white-space: nowrap;">
                    <b-text class="mx-3" style="color: black;">1 year (52 weeks)</b-text>
                    <v-select v-model="selectYear1" :disabled="setPage() === 'view' ? true : false" :options="followType" style="min-width: 130px; max-width: 130px;"></v-select>
                    <!-- <b-form-select v-if="setPage() === 'edit'" class="mx-3" v-model="selectYear1" :options="supplierOptions9" placeholder="Select Supplier" style="min-width: 130px; max-width: 130px;"></b-form-select>
                    <b-form-select v-else class="mx-3" v-model="selectYear1" :options="supplierOptions9" placeholder="Select Supplier" disabled style="min-width: 130px; max-width: 130px;"></b-form-select> -->
                  </b-col>
                </div>
                <b-col lg="12" sm="12" class="my-2">
                  <b-form-textarea
                    v-if="setPage() === 'edit'"
                    type="text"
                    placeholder="รายละเอียด"
                    v-model="rows[0].year1"
                  ></b-form-textarea>
                  <b-form-textarea
                    v-else
                    type="text"
                    placeholder="รายละเอียด"
                    v-model="rows[0].year1"
                    readonly
                  ></b-form-textarea>
                </b-col>
                <br>
                <div class="row">
                  <b-col xl="4" lg="6" md="6" sm="12" style="display: flex; align-items: center; white-space: nowrap;">
                    <b-text class="mx-3" style="color: black;">2 year (104 weeks)</b-text>
                    <v-select v-model="selectYear2" :disabled="setPage() === 'view' ? true : false" :options="followType" style="min-width: 130px; max-width: 130px;"></v-select>
                    <!-- <b-form-select v-if="setPage() === 'edit'" class="mx-3" v-model="selectYear2" :options="supplierOptions10" placeholder="Select Supplier" style="min-width: 130px; max-width: 130px;"></b-form-select>
                    <b-form-select v-else class="mx-3" v-model="selectYear2" :options="supplierOptions10" placeholder="Select Supplier" disabled style="min-width: 130px; max-width: 130px;"></b-form-select> -->
                  </b-col>
                </div>
                <b-col lg="12" sm="12" class="my-2">
                  <b-form-textarea
                    v-if="setPage() === 'edit'"
                    type="text"
                    placeholder="รายละเอียด"
                    v-model="rows[0].year2"
                  ></b-form-textarea>
                  <b-form-textarea
                    v-else
                    type="text"
                    placeholder="รายละเอียด"
                    v-model="rows[0].year2"
                    readonly
                  ></b-form-textarea>
                </b-col>
                <br>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import axios from 'axios'
import { configAPI } from '../../configBase'

export default {
  name: 'UiDataTable',
  async mounted () {
    await this.getFollowDropDown()
    await this.getCaseTrackMaster()
    xray.index()
  },
  methods: {
    async getFollowDropDown () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MD_PORT}/api/followType`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          // console.log('response.data.data', response.data.data)
          if (response.data.data.length) {
            this.followType = response.data.data?.map(el => {
              return {
                code: el.id,
                label: el.name
              }
            })
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาดในการดึงข้อมูล</span>`,
            html: '<span>' + error.message + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    getFollowTypeById (ftId) {
      if (!ftId) {
        return null
      } else if (ftId === '9fdc7e7b-498c-4be4-af36-60632dbfe1d2') {
        return { code: '9fdc7e7b-498c-4be4-af36-60632dbfe1d2', label: 'ติดตามผล' }
      } else if (ftId === 'c9b06d17-788d-44fd-946c-a49013dcaf2f') {
        return { code: 'c9b06d17-788d-44fd-946c-a49013dcaf2f', label: 'นัดหมาย' }
      }
    },
    getFollowTypeForSave (obj, type) {
      if (type === 'code') {

      } else if (type === 'label') {

      }
    },
    async getCaseTrackMaster () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MD_PORT}/api/followCase/` + this.getId(),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          // console.log('response.data.data', response.data.data)
          if (response.data.data.length > 0) {
            this.rows = response.data.data
            this.selectDay0 = this.getFollowTypeById(this.rows[0].ft_day0_id)
            this.selectDay1 = this.getFollowTypeById(this.rows[0].ft_day1_id)
            this.selectDay3 = this.getFollowTypeById(this.rows[0].ft_day3_id)
            this.selectWeek1 = this.getFollowTypeById(this.rows[0].ft_week1_id)
            this.selectWeek2 = this.getFollowTypeById(this.rows[0].ft_week2_id)
            this.selectWeek4 = this.getFollowTypeById(this.rows[0].ft_week4_id)
            this.selectMonth3 = this.getFollowTypeById(this.rows[0].ft_month3_id)
            this.selectMonth6 = this.getFollowTypeById(this.rows[0].ft_month6_id)
            this.selectYear1 = this.getFollowTypeById(this.rows[0].ft_year1_id)
            this.selectYear2 = this.getFollowTypeById(this.rows[0].ft_year2_id)
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาดในการดึงข้อมูล</span>`,
            html: '<span>' + error.message + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    setPage () {
      return this.$route.params.setPage
    },
    getId () {
      return this.$route.params.id
    },
    handleClick (status) {
      if (status) {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะบันทึกข้อมูลใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.saveCaseTrackMaster()
          }
        })
        // }
      } else {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะยกเลิกใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            router.push('/CaseTrackMaster')
          }
        })
      }
    },
    cancelClick () {
      router.push('/CaseTrackMaster')
    },
    async saveCaseTrackMaster () {
      const temp = {
        st_id: this.rows[0].st_id,
        day0: this.rows[0].day0 ? this.rows[0].day0?.trim() || null : null,
        ft_day0_id: this.selectDay0 ? this.selectDay0.code : null,
        ft_day0_name: this.selectDay0 ? this.selectDay0.label : null,
        dey1: this.rows[0].day1 ? this.rows[0].day1?.trim() || null : null,
        ft_day1_id: this.selectDay1 ? this.selectDay1.code : null,
        ft_day1_name: this.selectDay1 ? this.selectDay1.label : null,
        day3: this.rows[0].day3 ? this.rows[0].day3?.trim() || null : null,
        ft_day3_id: this.selectDay3 ? this.selectDay3.code : null,
        ft_day3_name: this.selectDay3 ? this.selectDay3.label : null,
        week1: this.rows[0].week1 ? this.rows[0].week1?.trim() || null : null,
        ft_week1_id: this.selectWeek1 ? this.selectWeek1.code : null,
        ft_week1_name: this.selectWeek1 ? this.selectWeek1.label : null,
        week3: this.rows[0].week2 ? this.rows[0].week2?.trim() || null : null,
        ft_week3_id: this.selectWeek2 ? this.selectWeek2.code : null,
        ft_week3_name: this.selectWeek2 ? this.selectWeek2.label : null,
        week4: this.rows[0].week4 ? this.rows[0].week4?.trim() || null : null,
        ft_week4_id: this.selectWeek4 ? this.selectWeek4.code : null,
        ft_week4_name: this.selectWeek4 ? this.selectWeek4.label : null,
        month3: this.rows[0].month3 ? this.rows[0].month3?.trim() || null : null,
        ft_month3_id: this.selectMonth3 ? this.selectMonth3.code : null,
        ft_month3_name: this.selectMonth3 ? this.selectMonth3.label : null,
        month6: this.rows[0].month6 ? this.rows[0].month6?.trim() || null : null,
        ft_month6_id: this.selectMonth6 ? this.selectMonth6.code : null,
        ft_month6_name: this.selectMonth6 ? this.selectMonth6.label : null,
        year1: this.rows[0].year1 ? this.rows[0].year1?.trim() || null : null,
        ft_year1_id: this.selectYear1 ? this.selectYear1.code : null,
        ft_year1_name: this.selectYear1 ? this.selectYear1.label : null,
        year2: this.rows[0].year2 ? this.rows[0].year2?.trim() || null : null,
        ft_year2_id: this.selectYear2 ? this.selectYear2.code : null,
        ft_year2_name: this.selectYear2 ? this.selectYear2.label : null
      }
      await axios.request({
        method: 'put',
        url: `${configAPI.MD_PORT}/api/followCase/${this.getId()}`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: temp
      })
        .then((response) => {
          if (response.data.status) {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(async () => {
              router.push('/CaseTrackMaster')
            })
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลไม่สำเร็จ</span>`,
            html: '<span>' + error.message + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    }
  },
  data () {
    return {
      placeholderText: 'test',
      followType: [],
      selectDay0: null,
      selectDay1: null,
      selectDay3: null,
      selectWeek1: null,
      selectWeek2: null,
      selectWeek4: null,
      selectMonth3: null,
      selectMonth6: null,
      selectYear1: null,
      selectYear2: null,
      rows: [
        {
          id: null,
          st_id: null,
          st_name: null,
          day0: null,
          ft_day0_id: null,
          ft_day0_name: null,
          day1: null,
          ft_day1_id: null,
          ft_day1_name: null,
          day3: null,
          ft_day3_id: null,
          ft_day3_name: null,
          week1: null,
          ft_week1_id: null,
          ft_week1_name: null,
          week2: null,
          ft_week2_id: null,
          ft_week2_name: null,
          week4: null,
          ft_week4_id: null,
          ft_week4_name: null,
          month3: null,
          ft_month3_id: null,
          ft_month3_name: null,
          month6: null,
          ft_month6_id: null,
          ft_month6_name: null,
          year1: null,
          ft_year1_id: null,
          ft_year1_name: null,
          year2: null,
          ft_year2_id: null,
          ft_year2_name: null,
          editable: false
        }
      ]
    }
  }
}
</script>
