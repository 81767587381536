<template>
  <b-container fluid>
      <form>
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:headerTitle>
                <div class="row align-items-center py-4 mx-0">
                  <div class="col-auto">
                    <span v-if="mode === 'add'" style="font-size: 25px; color: black;">ผู้ใช้งาน
                      (เพิ่ม)</span>
                    <span v-else-if="mode === 'edit'"
                      style="font-size: 25px; color: black;">ผู้ใช้งาน (แก้ไข)</span>
                    <span v-else style="font-size: 25px; color: black;">ผู้ใช้งาน (ดูรายละเอียด)</span>
                  </div>
                  <div v-if="mode === 'add' || mode === 'edit'" class="col-12 col-sm-auto">
                    <div class="row">
                      <div class="col-6 pl-3 pr-2 my-1 my-sm-0 col-sm-auto">
                        <b-button type="button" variant="primary" @click="handleClick" class="w-100 btn-min-size"><i class="save-data_ct"></i>บันทึก</b-button>
                      </div>
                      <div class="col-6 pl-2 pr-3 my-1 my-sm-0 col-sm-auto">
                        <b-button type="button" variant="none" @click="handleClickGoBack" class="w-100 btn-cancel-border btn-min-size iq-bg-danger">ยกเลิก</b-button>
                      </div>
                    </div>
                  </div>
                  <div v-else class="col-12 col-sm-auto my-1 my-sm-0">
                    <b-button type="button" variant="none" @click="handleClickGoBack" class="w-100 btn-cancel-border btn-min-size iq-bg-danger">ยกเลิก</b-button>
                  </div>
                </div>
              </template>
              <!-- <template v-slot:headerTitle style="width: 100%;">
                <div v-if="mode !== 'view'" class="row py-4">
              <div class="" style="display: flex; align-items: center;">
                <span class="px-3 margin-text-header" style="font-size: 25px; color: black;">ผู้ใช้งาน {{ mode === 'edit' ? '(แก้ไข)' : mode === 'view' ? '(ดูรายละเอียด)' : '(เพิ่ม)'}}</span>
              </div>
              <div class="col-lg-1 col-md-1 col-sm-2 col-12" style="padding-left: 0;">
                <div class="d-flex my-2 mx-2" style="white-space: nowrap;">
                  <b-button @click="handleClick2" variant="primary" class="mx-1 flex-fill text-center" style="text-align: center;">บันทึก</b-button>
                  <b-button @click="handleClickGoBack" variant="none" class="mx-2 flex-fill bg-danger text-center" style="text-align: center;">ยกเลิก</b-button>
                </div>
              </div>
                </div>
                <div v-else class="row py-4" style="display: flex; justify-content: flex-start;">
                  <div class="col-lg-3 col-md-3 col-sm-2 col-12 apg-response-header"  style="display: flex; align-items: center;">
                    <span class="padding-text-header" style="font-size: 25px; color: black; white-space: nowrap;">ผู้ใช้งาน {{ mode === 'edit' ? '(แก้ไข)' : mode === 'view' ? '(ดูรายละเอียด)' : '(เพิ่ม)'}}</span>
                    <b-button @click="handleClickGoBack" variant="none" class="flex-fill bg-danger text-center apg-response-header-btn" style="text-align: center;">ยกเลิก</b-button>
                  </div>
                </div>
              </template> -->
              <template v-slot:body>
                <div class="new-user-info">
                  <b-row>
                    <b-col lg="6" sm="12">
                      <b-form>
                        <!-- <b-form-input type="text" :disabled="isDisable" v-on:keypress="handleChange($event)" placeholder="xxx-xxx-xxxx"></b-form-input> -->

                        <b-form-group style="color: black;">
                        <label class="pb-0 mb-0" style="color: black;">Username <span :hidden="userAdd.username?.length === 10" style="color: red;"> *</span></label>
                          <the-mask mask="###-###-####" class="form-control" id="Phone" v-model="userAdd.username" type="tel" :masked="false" placeholder="xxx-xxx-xxxx" :disabled="isDisable"></the-mask>
                        </b-form-group>
                        <b-form-group style="color: black;">
                          <label class="pb-0 mb-0" style="color: black;">พนักงาน <span :hidden="userAdd.employee" style="color: red;"> *</span></label>
                          <v-select v-model="userAdd.employee" :disabled="isDisable" @change="OnSetSelect" :options="optionsSelect"></v-select>
                          <!-- <b-form-select class="height-select" :disabled="isDisable"  plain @change="OnSetSelect" v-model="userAdd.employee" :options="optionsSelect" size="lg">
                            <template v-slot:first>
                              <b-form-select-option :value="null">------ Select --------</b-form-select-option>
                            </template>
                          </b-form-select> -->
                        </b-form-group>
                        <b-form-group label="รหัสพนักงาน : " style="color: black;">
                          <b-form-input type="text" v-model="userAdd.employee_no" disabled="true"></b-form-input>
                        </b-form-group>
                        <b-form-group >
                          <b-row class="my-3 gap-respnose-adduser" style="text-align: center; align-items: center; margin-left: 0.2rem;">
                            <div style="display: flex;">
                              <span style="color: black; font-size: 1rem;">สถานะ :</span>
                            </div>
                            <div style="display: flex;">
                              <b-form-radio :value="true" :disabled="isDisable" v-model="userAdd.status_use" class="ml-3 radio-disable" name="redio-button"></b-form-radio>
                              <b-text class="margin-response-adduser check" style="color: black;">ใช้งาน</b-text>
                            </div>
                            <div style="display: flex;">
                              <b-form-radio :value="false" :disabled="isDisable" v-model="userAdd.status_use" class="ml-3 radio-disable" name="redio-button"></b-form-radio>
                              <b-text class="mr-4 check" style="color: black;">ยกเลิก</b-text>
                            </div>
                          </b-row>
                        </b-form-group>
                        <b-form-group style="color: black;">
                          <label class="pb-0 mb-0" style="color: black;">Email <span :hidden="userAdd.email" style="color: red;"> *</span></label>
                          <b-form-input :disabled="isDisable" type="text" v-model="userAdd.email" ></b-form-input>
                        </b-form-group>
                      </b-form>
                    </b-col>
                    <b-col lg="6" sm="12" >
                      <b-form-group class="del-mar-fileinput-user" label-for="imageInput2" label="อัปโหลดรูปภาพ">
                        <b-form-file
                          class="custom-file-style"
                          id="imageInput2"
                          accept="image/*"
                          :disabled="isDisable"
                          :placeholder="userAdd.imageName ? userAdd.imageName : 'Choose a file'"
                          @change="handleImageUpload2"
                        ></b-form-file>
                      </b-form-group>
                      <div v-if="userAdd.imageUrl" style="text-align: center;">
                        <img :src="userAdd.imageUrl" alt="Uploaded Image" width="250" height="250"/>
                      </div>
                    </b-col>
                  </b-row>
              </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </form>
  </b-container>
</template>

<style>
.del-mar-fileinput-user .d-block {
  margin-bottom: 0.2rem;
}

.gap-respnose-adduser {
  gap: 1rem !important;
}
.margin-response-adduser {
  margin-right: 2.5rem !important;
}
.margin-text-header {
  margin-left: 1rem;
}
.padding-text-header {
  margin-left: 0;
  margin-right: 1rem;
}

[dir=ltr] .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #089bab !important;
}

.apg-response-header-btn {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
  width: 94% !important;
}

@media (max-width: 560px) {
  .margin-text-header {
    margin-left: 0 !important;
  }
  .padding-text-header {
    margin-left: 0.5rem !important;
    margin-right: 0 !important;
  }
  .apg-response-header {
    display: flex;
    align-items: flex-start !important;
    flex-direction: column;
  }
  .apg-response-header-btn {
    margin-top: 0.5rem !important;
    width: 100% !important;
    max-width: 100% !important;
    /* padding-left: 1rem; */
    margin-left: 0.2rem !important;
    margin-right: 0 !important;
  }
  .iq-header-title {
    width: 100% !important;
  }
}
@media (max-width: 365px) {
  .gap-respnose-adduser {
    gap: .1rem !important;
  }
  .margin-response-adduser {
    margin-right: 1rem !important;
  }
}
@media (max-width: 350px) {
  .padding-text-header {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .apg-response-header-btn {
    margin-left: 0 !important;
    width: 100% !important;
    margin-right: 0 !important;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '../../router'
import Vue from 'vue'
import axios from 'axios'
import { configAPI } from '../../configBase'

export default {
  name: 'AddUser',
  async mounted () {
    this.cln_id = JSON.parse(sessionStorage.getItem('user')).cln_id
    await this.getEmployeeByClinic()
    if (this.$route.params.id) {
      this.mode = 'edit'
      this.id = this.$route.params.id
      if (this.$route.params.setPage === 'view') {
        this.mode = 'view'
        this.isDisable = true
      }
      await this.getUserManageById()
    }
    xray.index()
  },
  data () {
    return {
      mode: 'add',
      id: null,
      cln_id: null,
      isDisable: false,
      userAdd: {
        username: null,
        employee: null,
        employee_no: null,
        status_use: true,
        email: null,
        imageName: null,
        imageUrl: null,
        fileImage: null
      },
      user: {
        fname: '',
        lname: '',
        name: '',
        username: '',
        email: '',
        password: '',
        address1: '',
        address2: '',
        company_name: '',
        profile_image: require('../../assets/images/user/11.png'),
        mobile_no: '',
        country: '',
        city: '',
        pincode: '',
        role: ''
      },
      optionsSelect: [
        /* { code: 'employee1', label: 'Employee1' },
        { code: 'employee2', label: 'Employee2' },
        { code: 'employee3', label: 'Employee3' } */
      ],
      roles: [
        { text: 'Web Designer', value: 'Web Designer' },
        { text: 'Web Developer', value: 'Web Developer' },
        { text: 'Tester', value: 'Tester' },
        { text: 'Php Developer', value: 'Php Developer' },
        { text: 'Ios Developer', value: 'Ios Developer' }
      ],
      countries: [
        { value: 'Canada', text: 'Canada' },
        { value: 'Niada', text: 'Niada' },
        { value: 'USA', text: 'USA' },
        { value: 'India', text: 'India' },
        { value: 'Africa', text: 'Africa' }
      ],
      users: [],
      phoneFormat: ''
    }
  },
  computed: {
    fullName: function () {
      return this.user.fname + ' ' + this.user.lname
    }
  },
  watch: {
    'userAdd.employee': function (newVal, oldVal) {
      const data = this.optionsSelect.find(el => el.code === newVal.code).no_emp
      // console.log('data', data)
      if (data) {
        this.userAdd.employee_no = data
      }
    }
  },
  methods: {
    async getEmployeeByEmpId (empId) {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/employee/${empId}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status) {
            let data = response.data.data[0]
            this.optionsSelect.push({
              code: data.id,
              label: data.name + ' ' + data.lastname,
              no_emp: data.no || null
            })
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async getUserManageById () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/user/${this.id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            let data = response.data.data[0]
            // console.log('this.optionsSelect', this.optionsSelect)
            await this.getEmployeeByEmpId(data.emp_id)
            let empObject = {
              code: data.emp_id,
              label: data.emp_name + ' ' + data.emp_lastname
            }
            // console.log('data.emp_id ? this.optionsSelect.find(el => el.code === data.emp_id) || null : null', data.emp_id ? this.optionsSelect.find(el => el.code === data.emp_id) || null : null)
            this.userAdd = {
              username: data.username,
              employee: empObject,
              employee_no: null,
              status_use: data.us_name === 'ใช้งาน',
              email: data.email,
              imageName: data.img_profile || null,
              imageUrl: !data.img_profile_path ? null : this.getFullImagePath(data.img_profile_path) || null,
              fileImage: null
            }
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async getEmployeeByClinic () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/employee?is_user=false`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status) {
            this.optionsSelect = response.data.data?.map(el => {
              return {
                code: el.id,
                label: (el.name || '') + '\t\t' + (el.lastname || ''),
                no_emp: el.no || ''
              }
            })
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    getFullImagePath (relativePath) {
      if (relativePath) {
        relativePath = relativePath.substring(1)
      }
      const baseURL = `${configAPI.MA_PORT}`
      return baseURL + relativePath
    },
    previewImage: function (event) {
      const input = event.target

      if (input.files && input.files[0]) {
        const reader = new FileReader()

        reader.onload = (e) => {
          this.user.profile_image = e.target.result
        }

        reader.readAsDataURL(input.files[0])
      }
    },
    checkError (tab) {
      let error = []
      if (!this.userAdd.username || this.userAdd?.username === null) {
        error.push('กรุณากรอก Username')
      }
      if (this.userAdd.username) {
        if (this.userAdd.username.length !== 10) {
          error.push('Username ต้องมีตัวเลข 10 หลัก')
        }
      }
      if (!this.userAdd.employee || this.userAdd?.employee === null) {
        error.push('กรุณาเลือก พนักงาน')
      }
      if (!this.userAdd.email || this.userAdd?.email === null) {
        error.push('กรุณากรอก Email')
      }
      return error
    },
    handleClick () {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.mode !== 'edit' ? 'คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่?' : 'คุณมั่นใจว่าคุณจะแก้ไขข้อมูลใช่หรือไม่?'}</span>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          const errorList = this.checkError()
          if (errorList.length > 0) {
            let errMessage = ''
            for (let e = 0; e < errorList.length; e++) {
              errMessage += '<br/>' + errorList[e]
            }
            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลไม่สำเร็จ</span>',
              html: errMessage,
              icon: 'error',
              showConfirmButton: true,
              confirmButtonText: '<b-button style="font-size: 14px; text-align: center">Ok</b-button>',
              confirmButtonColor: '#089bab',
              customClass: {
                confirmButton: 'custom-confirm-button-class mx-1'
              }
            })
            return
          }
          if (this.mode !== 'edit') {
            await this.addUser()
          } else {
            await this.updateUser()
          }
          this.$store.dispatch('updateAddUserManage', null)
          Vue.swal({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
            icon: 'success',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 2000
          }).then(() => {
            router.go(-1)
          })
        }
      })
    },
    async addUser () {
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/user/`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          rol_id: '17c725d0-2994-11ee-9a93-ff4859fd1258',
          us_id: this.userAdd.status_use === true
            ? 'f9581f84-e150-4ee3-a4cb-c2b9208ee673'
            : '1050f018-930e-4fea-ae8d-88e4b4c5c7e6',
          ut_id: '7bde4d32-981c-49f8-9fc0-4a689765416f',
          emp_id: this.userAdd.employee?.code,
          username: this.userAdd.username,
          password: 'sc@1234',
          email: this.userAdd.email ? this.userAdd.email?.trim() : null
        }
      })
        .then(async (response) => {
          if (response.data.status && this.userAdd.fileImage && response.data.data.id) {
            await this.saveImageUserById(response.data.data.id)
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'บันทึกข้อมูลไม่สำเร็จ')
        })
    },
    async updateUser () {
      await axios.request({
        method: 'put',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/user/${this.id}`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          rol_id: '17c725d0-2994-11ee-9a93-ff4859fd1258',
          us_id: this.userAdd.status_use === true
            ? 'f9581f84-e150-4ee3-a4cb-c2b9208ee673'
            : '1050f018-930e-4fea-ae8d-88e4b4c5c7e6',
          ut_id: '7bde4d32-981c-49f8-9fc0-4a689765416f',
          emp_id: this.userAdd.employee?.code,
          username: this.userAdd.username,
          // password: 'sc@1234',
          email: this.userAdd.email ? this.userAdd.email?.trim() : null
        }
      })
        .then(async (response) => {
          if (response.data.status && this.userAdd.fileImage && this.id) {
            await this.saveImageUserById(this.id)
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'บันทึกข้อมูลไม่สำเร็จ')
        })
    },
    async saveImageUserById (userId) {
      let data = new FormData()
      data.append('file', this.userAdd.fileImage)
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/user/${userId}/upload/img`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
        .then(async (response) => {
        })
        .catch((error) => {
          this.SwalError(error.message, 'บันทึกรูปภาพไม่สำเร็จ')
        })
    },
    handleClickGoBack () {
      if (this.mode === 'view') {
        router.go(-1)
      } else {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            router.go(-1)
          }
        })
      }
    },
    handleImageUpload2 (event) {
      const file2 = event.target.files[0]
      const reader2 = new FileReader()
      reader2.readAsDataURL(file2)
      this.userAdd.imageName = file2.name
      this.userAdd.fileImage = file2
      reader2.onload = () => {
        this.userAdd.imageUrl = reader2.result
      }
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    }
  }
}
</script>
