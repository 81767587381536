<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="table-responsive">
        <iq-card>
          <!-- Header Section -->
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 gap-response">
              <div class="ml-4">

                <span style="font-size: 25px; color: black;">{{ mode === 'add' ? 'เพิ่มใบสั่งซื้อ' : mode === 'edit' ?
                  'ใบสั่งซื้อ (Edit)' : 'ใบสั่งซื้อ (View)' }}</span>
              </div>
              <div class="row button-response-header" style="display: flex; align-items: center;">
                <div class="flex-response-header-po my-2 mx-2" style="white-space: nowrap;">

                  <div v-if="mode === 'add' || mode === 'edit'">
                    <b-button type="button" variant="primary"
                      class="my-1 flex-fill text-center btn-add w-100 btn-min-size" style="text-align: center;"
                      @click="handleClick(false)"><i class="save-data_ct" />บันทึก</b-button>
                  </div>

                  <div>
                    <b-button type="button" variant="danger" class="my-1 flex-fill text-center btn-add w-100 btn-min-size"
                      style="text-align: center;" @click="handleClickBack">ยกเลิก</b-button>
                  </div>

                  <div v-if="mode === 'add' || mode === 'edit'">
                    <b-button type="button" variant="success" class="my-1 flex-fill text-center btn-add w-100 btn-min-size"
                      style="text-align: center;" @click="handleClick(true)">ส่งอนุมัติ</b-button>
                  </div>

                </div>
              </div>

            </div>
          </template>
          <!-- Body Section -->
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col lg="3" md="4" sm="12">
                  <b-form-group label="หมายเลขใบสั่งซื้อ" label-for="po_no">
                    <template v-if="PoDetail.po_no === null" v-slot:label>
                      หมายเลขใบสั่งซื้อ<!-- <span style="color: red;"> *</span> -->
                    </template>
                    <b-form-input v-if="mode === 'add'" placeholder="Auto Gen PO No." id="po_no"
                      :readonly="true"></b-form-input>
                    <b-form-input v-else id="po_no" v-model="PoDetail.po_no" :readonly="true"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="4" sm="6">
                  <b-form-group label="อ้างอิงเลขใบสั่งซื้อ" label-for="province">
                    <v-select :value="PoDetail.po_ref_po_id" @input="ChagePORef" :disabled="mode === 'view'"
                      :options="purchaseOrderDDmap"></v-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="4" sm="6">
                  <b-form-group label-for="datepicker-custom1" label="วันที่เลขใบสั่งซื้อ">
                    <template v-if="PoDetail.po_date === null || PoDetail.po_date?.toString().trim() === ''" v-slot:label>
                      วันที่เลขใบสั่งซื้อ<!-- <span style="color: red;"> *</span> -->
                    </template>
                    <div style="position: relative;">
                      <date-picker id="datepicker-custom2" :config="options" v-model="PoDetail.po_date"
                        :readonly="isDisable" />
                      <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(2)" :disabled="true"
                        style="cursor: pointer;" />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="4" sm="6">
                  <b-form-group label-for="datepicker-custom1" label="วันที่จัดส่ง">
                    <template
                      v-if="PoDetail.po_delivery_date === null || PoDetail.po_delivery_date?.toString().trim() === ''"
                      v-slot:label>
                      วันที่จัดส่ง<!-- <span style="color: red;"> *</span> -->
                    </template>
                    <div style="position: relative;">
                      <date-picker id="datepicker-custom1" :config="options" v-model="PoDetail.po_delivery_date"
                        :readonly="isDisable" />
                      <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(1)" :disabled="true"
                        style="cursor: pointer;" />
                    </div>
                  </b-form-group>
                </b-col>
                <b-col lg="4" md="4" sm="6">
                  <b-form-group label="เลขที่อ้างอิง" label-for="po_ref_no">
                    <b-form-input id="po_ref_no" type="text" v-model="PoDetail.po_ref_no"
                      :readonly="isDisable"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="10" md="10" sm="12">
                  <b-form-group label="อ้างอิงเลขใบสั่งซื้อ" label-for="po_ref_detail">
                    <b-textarea id="po_ref_detail" :readonly="true" v-model="PoDetail.po_ref_detail"
                      max-rows="5"></b-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <span style="font-size: 25px; color: rgb(37, 37, 37);">รายการใบสั่งซื้อ</span>
              <hr />
              <b-row>
                <b-col lg="4" md="6" sm="12">
                  <b-form-group label="ซัพพลายเออร์" label-for="province">
                    <!-- v-model="PoDetail.po_sup_id" -->
                    <template v-slot:label>
                      ซัพพลายเออร์<span style="color: red;"> *</span>
                    </template>
                    <v-select :value="PoDetail.po_sup_id" @input="ChangeSup" :disabled="isDisable" :options="supplierDDmap"></v-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="4" sm="12">
                  <b-form-group class="unselectable" label="ชื่อผู้ติดต่อ" label-for="po_sup_con_name"
                    style="color: #3f414d;">
                    <template
                      v-if="PoDetail.po_sup_con_name === null || PoDetail.po_sup_con_name?.toString().trim() === ''"
                      v-slot:label>
                      ชื่อผู้ติดต่อ<!-- <span style="color: red;"> *</span> -->
                    </template>
                    <b-form-input id="po_sup_con_name" type="text" v-model="PoDetail.po_sup_con_name"
                      :readonly="true"></b-form-input> <!-- placeholder="ชื่อ-นามสกุล" -->
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="4" sm="12">
                  <b-form-group class="unselectable" label="ไอดีไลน์" label-for="po_sup_line" style="color: #3f414d;">
                    <template v-if="PoDetail.po_sup_line === null || PoDetail.po_sup_line?.toString().trim() === ''"
                      v-slot:label>
                      ไอดีไลน์<!-- <span style="color: red;"> *</span> -->
                    </template>
                    <b-form-input id="po_sup_line" type="text" v-model="PoDetail.po_sup_line"
                      :readonly="true"></b-form-input> <!-- placeholder="ชื่อ-นามสกุล" -->
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="4" sm="12">
                  <b-form-group label="เบอร์โทรศัพท์" label-for="cus_tel">
                    <div style="position: relative;">
                      <the-mask mask="###-###-####" class="form-control" v-model="PoDetail.po_sup_tel" id="cus_tel"
                        placeholder="" :readonly="true"></the-mask>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col lg="10" md="10" sm="12">
                  <b-form-group label="รายละเอียดซัพพลายเออร์" label-for="po_details_sup">
                    <b-textarea id="po_details_sup" :readonly="true" v-model="PoDetail.po_details_sup"
                      max-rows="5"></b-textarea>
                  </b-form-group>
                </b-col>
              </b-row>

              <br />
              <!-- Header P && M -->
              <span style="font-size: 25px; color: rgb(37, 37, 37);">รายการใบสั่งซื้อ</span>
              <hr />
              <b-form-checkbox v-model="PoDetail.quo_is_incl_vat" name="checkbox-1" size="lg"
                class="checkbox-quotation-mgt mt-1" :disabled="isDisable">ราคารวม Vat(7%)</b-form-checkbox>
              <br/>
              <!-- <span style="font-size: 25px; color: rgb(37, 37, 37);">รวม : <span style="color: #00FF29">{{
                PoDetail.po_total_price || 0 }}</span> บาท</span>
              <b-form-checkbox v-model="PoDetail.quo_is_incl_vat" name="checkbox-1" size="lg"
                class="checkbox-quotation-mgt mt-1" :disabled="isDisable">ราคารวม Vat(7%)</b-form-checkbox> -->
              <!-- Product -->
              <template>
                <!-- Price Product -->
                <span style="font-size: 20px; color: rgb(37, 37, 37);">รวมราคาสินค้า : <span style="color: #00FF29">{{
                formatNumber(PoDetail.po_sum_price_prd || 0) }}</span> บาท</span>
                <!-- ************* -->
                <br />
                <b-row>
                  <b-col cols="12">
                    <div class="d-flex my-3" style="white-space: nowrap; gap: .5rem">
                      <b-button v-if="mode !== 'view'" @click="onClickShowModalProduct('showModalProduct')"
                        variant="primary" class="max-width-btn-po-mgt flex-fill text-center btn-min-size"
                        style="text-align: center;"><i class="circle-plus_ct" />เพิ่มสินค้า</b-button>
                      <b-button v-else class="max-width-btn-po-mgt flex-fill text-center btn-min-size"
                        style="text-align: center;" disabled><i
                          class="circle-plus_ct" />เพิ่มสินค้า</b-button>

                      <!-- <b-button v-if="mode !== 'view'" @click="showModalService = true" :disabled="isDisable" variant=" bg-success-custom" class=" flex-fill text-center btn-min-size" style="text-align: center; max-width: 200px;"><i class="circle-plus_ct"/>เพิ่มบริการ</b-button>
                      <b-button v-else class=" flex-fill text-center btn-min-size" style="text-align: center; max-width: 200px;" disabled><i class="circle-plus_ct"/>เพิ่มบริการ</b-button> -->
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" >
                    <div class="table-responsive">
                      <b-table bordered hover :items="rowSelected.data" :fields="columnsSelected"
                        :current-page="currentPageSelected" :per-page="perPageSelected" @pagination="onPagination"
                        :tbody-tr-class="rowClass" thead-class="table-header-color">
                        <template v-for="(column) in columnsSelected" v-slot:[`cell(${column.key})`]="data">
                          <template v-if="column.key === 'no'">
                            <span :key="column.key">{{ ((currentPageSelected - 1) * perPageSelected) + data.index + 1
                            }}</span>
                          </template>
                          <!-- <template v-if="column.key === 'type_proser'">
                              <span :key="column.key">{{ data.item.type || '-' }}</span>
                            </template> -->
                          <template v-if="column.key === 'code_proser'">
                            <span :key="column.key">{{ data.item.no || '-' }}</span>
                          </template>
                          <template v-if="column.key === 'name_proser'">
                            <span :key="column.key">{{ data.item.name || data.item.st_name || '-' }}</span>
                          </template>
                          <template v-if="column.key === 'quantity'">
                            <!-- <b-form-group :key="data.item.type + column.key + data.index" class="d-flex align-items-center"> -->
                            <CurrencyInputCom v-model="data.item.quantity" :options="optionCurrency"
                              @change="calculateRow('quantity', data.item, 'rowSelected')"
                              :key="data.item.type + column.key + data.index" :disabled="isDisable" />
                            <!-- <span class="text-center" style="font-size: 12px;">{{ data.item.unit_name || '' }}</span> -->
                            <!-- </b-form-group> -->
                          </template>
                          <template v-if="column.key === 'lp'">
                            <CurrencyInputCom v-model="data.item.lp" :options="optionCurrency2"
                              @change="calculateRow('lp', data.item, 'rowSelected')"
                              :key="data.item.type + column.key + data.index" :disabled="isDisable" />
                          </template>
                          <template v-if="column.key === 'discount'">
                            <CurrencyInputCom v-model="data.item.discount" :options="optionCurrency2" :isLimit="true"
                              :valueLimit="(data.item.lp || 0) * (data.item.quantity || 0)"
                              @change="calculateRow('discount', data.item, 'rowSelected')"
                              :key="data.item.type + column.key + data.index" :ref="data.item.id?.replace(/-/g, '')"
                              :disabled="isDisable" />
                            <!-- <b-form-input v-model="data.item.discount" :disabled="isDisable" :key="column.key" @input="isNumberComma($event, data.item)" @keypress="isNumberCheck($event, true)"></b-form-input> -->
                          </template>
                          <template v-if="column.key === 'total_price'">
                            <span :key="column.key">{{ formatNumber(data.item.row_price) }}</span>
                          </template>
                          <template v-if="column.key === 'mgt'">
                            <div style="display: flex; justify-content: center;" :key="column.key">
                              <b-button @click="deleteRow(data.item, 'rowSelected', 'productSelect')"
                                variant=" iq-bg-bin-custom btn-bin-border" :disabled="isDisable" size="sm"><i
                                  class="ri-delete-bin-line m-0"></i></b-button>
                            </div>
                          </template>
                        </template>
                      </b-table>
                    </div>
                  </b-col>
                </b-row>
                <b-row style="display: flex; justify-content: center;">
                  <b-pagination v-model="currentPageSelected" :total-rows="rowSelected.data?.length"
                    :per-page="perPageSelected" align="center" />
                </b-row>
              </template>
              <hr />
              <!-- rawMaterial -->
              <template>
                <!-- Price rawMaterial -->
                <span style="font-size: 20px; color: rgb(37, 37, 37);">รวมราคาวัตถุดิบ : <span style="color: #00FF29">{{
                formatNumber(PoDetail.po_sum_price_rm || 0) }}</span> บาท</span>
                <!-- ************* -->
                <br />
                <b-row>
                  <b-col cols="12">
                    <div class="d-flex my-3" style="white-space: nowrap; gap: .5rem">
                      <!-- <b-button v-if="mode !== 'view'" @click="showModalProduct = true" variant="primary" class=" flex-fill text-center btn-min-size" style="text-align: center; max-width: 200px;"><i class="circle-plus_ct"/>เพิ่มสินค้า</b-button>
                      <b-button v-else class=" flex-fill text-center btn-min-size" style="text-align: center; max-width: 200px;" disabled><i class="circle-plus_ct"/>เพิ่มวัตถุดิบ</b-button> -->

                      <b-button v-if="mode !== 'view'" @click="onClickShowModalProduct('showModalService')"
                        :disabled="isDisable" variant=" bg-success-custom" class="max-width-btn-po-mgt flex-fill text-center btn-min-size"
                        style="text-align: center;"><i class="circle-plus_ct" />เพิ่มวัตถุดิบ</b-button>
                      <b-button v-else class="max-width-btn-po-mgt flex-fill text-center btn-min-size"
                        style="text-align: center;" disabled><i
                          class="circle-plus_ct" />เพิ่มวัตถุดิบ</b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" >
                    <div class="table-responsive">
                      <b-table bordered hover :items="rowRawMatSelected.data" :fields="columnsMatSelected"
                        :current-page="currentRMPageSelected" :per-page="perPageSelected" @pagination="onPaginationRM"
                        :tbody-tr-class="rowClass" thead-class="table-header-color">
                        <template v-for="(column) in columnsMatSelected" v-slot:[`cell(${column.key})`]="data">
                          <template v-if="column.key === 'no'">
                            <span :key="column.key">{{ ((currentRMPageSelected - 1) * perPageSelected) + data.index + 1
                            }}</span>
                          </template>
                          <template v-if="column.key === 'type_proser'">
                            <span :key="column.key">{{ data.item.mg_name || '-' }}</span>
                          </template>
                          <template v-if="column.key === 'brand'">
                            <span :key="column.key">{{ data.item.mb_name || '-' }}</span>
                          </template>
                          <template v-if="column.key === 'type'">
                            <span :key="column.key">{{ data.item.mt_name || '-' }}</span>
                          </template>
                          <template v-if="column.key === 'unit'">
                            <span :key="column.key">{{ data.item.unit_name || '-' }}</span>
                          </template>
                          <template v-if="column.key === 'quantity'">
                            <!-- <b-form-group :key="data.item.type + column.key + data.index" class="d-flex align-items-center"> -->
                            <CurrencyInputCom v-model="data.item.quantity" :options="optionCurrency"
                              @change="calculateRow('quantity', data.item, 'rowRawMatSelected')"
                              :key="data.item.type + column.key + data.index" :disabled="isDisable" />
                            <!-- <span class="text-center" style="font-size: 12px;">{{ data.item.unit_name || '' }}</span> -->
                            <!-- </b-form-group> -->
                          </template>
                          <template v-if="column.key === 'lp'">
                            <CurrencyInputCom v-model="data.item.lp" :options="optionCurrency2"
                              @change="calculateRow('lp', data.item, 'rowRawMatSelected')"
                              :key="data.item.type + column.key + data.index" :disabled="isDisable" />
                          </template>
                          <template v-if="column.key === 'discount'">
                            <CurrencyInputCom v-model="data.item.discount" :options="optionCurrency2" :isLimit="true"
                              :valueLimit="(data.item.lp || 0) * (data.item.quantity || 0)"
                              @change="calculateRow('discount', data.item, 'rowRawMatSelected')"
                              :key="data.item.type + column.key + data.index" :ref="data.item.id?.replace(/-/g, '')"
                              :disabled="isDisable" />
                            <!-- <b-form-input v-model="data.item.discount" :disabled="isDisable" :key="column.key" @input="isNumberComma($event, data.item)" @keypress="isNumberCheck($event, true)"></b-form-input> -->
                          </template>
                          <template v-if="column.key === 'total_price'">
                            <span :key="column.key">{{ formatNumber(data.item.row_price) }}</span>
                          </template>
                          <template v-if="column.key === 'mgt'">
                            <div style="display: flex; justify-content: center;" :key="column.key">
                              <b-button @click="deleteRow(data.item, 'rowRawMatSelected', 'rawMatSelect')"
                                variant=" iq-bg-bin-custom btn-bin-border" :disabled="isDisable" size="sm"><i
                                  class="ri-delete-bin-line m-0"></i></b-button>
                            </div>
                          </template>
                        </template>
                      </b-table>
                    </div>
                  </b-col>
                </b-row>
                <b-row style="display: flex; justify-content: center;">
                  <b-pagination v-model="currentRMPageSelected" :total-rows="rowRawMatSelected.data?.length"
                    :per-page="perPageSelected" align="center" />
                </b-row>
              </template>
              <b-row>
                <b-col lg="6" md="6" sm="12">
                  <b-form-group label="ข้อคิดเห็น" label-for="remark">
                    <b-textarea id="remark" :disabled="isDisable" v-model="PoDetail.po_remark"></b-textarea>
                  </b-form-group>
                  <span style="font-size: 25px; color: rgb(37, 37, 37); text-decoration:underline">วันที่อนุมัติ :
                    {{ PoDetail.po_approve_date }}</span>
                </b-col>
                <b-col lg="6" md="6" sm="12" class="pt-4" style="gap: .5rem; display: grid;">
                  <template>
                    <div class="cash-down-table-po">
                      <div style="min-width: 8rem;">
                        <label>ราคารวม</label>
                      </div>
                      <div>
                        <b-form-input id="po_sup_con_mail" type="text" v-model="PoDetail.quo_sup_total"
                          :readonly="true"></b-form-input>
                      </div>
                      <div style="padding-left: 0.5rem;">
                        <label>บาท</label>
                      </div>
                    </div>
                  </template>
                  <template>
                    <div class="cash-down-table-po">
                      <div style="min-width: 8rem;">
                        <label>ส่วนลด</label>
                      </div>
                      <div>
                        <CurrencyInputCom :ref="'po_final_discountRef'" v-model="PoDetail.po_final_discount"
                          :options="optionCurrency2" :disabled="isDisable" />
                        <!-- <b-form-input id="po_sup_con_mail" type="text" v-model="PoDetail.po_final_discount" :readonly="isDisable"></b-form-input> -->
                      </div>
                      <div style="padding-left: 0.5rem;">
                        <label>บาท</label>
                      </div>
                    </div>
                  </template>
                  <template>
                    <div class="cash-down-table-po">
                      <div style="min-width: 8rem;">
                        <label>ภาษี</label>
                      </div>
                      <div>
                        <b-form-input id="po_sup_con_mail" type="text" v-model="PoDetail.po_incl_vat"
                          :readonly="true"></b-form-input>
                      </div>
                      <div style="padding-left: 0.5rem;">
                        <label>บาท</label>
                      </div>
                    </div>
                  </template>
                  <template>
                    <div class="cash-down-table-po">
                      <div style="min-width: 8rem;">
                        <label>ราคาสุทธิ</label>
                      </div>
                      <div>
                        <b-form-input id="po_sup_con_mail" type="text" v-model="PoDetail.po_total_price"
                          :readonly="true"></b-form-input>
                      </div>
                      <div style="padding-left: 0.5rem;">
                        <label>บาท</label>
                      </div>
                    </div>
                  </template>
                </b-col>
              </b-row>

              <b-modal v-model="showModalProduct" title="เลือกสินค้า" cancel-title="ยกเลิก" ok-title="เพิ่ม"
                @ok="onSubmitProductModal" @close="onCloseProductModal" size="xl" :no-close-on-esc="true"
                :no-close-on-backdrop="true">
                <productSelectModalPO ref="productSelectedCom" :poDelList="JSON.parse(JSON.stringify(poDelList))"
                  :mainProductSelected="rowSelected.data" :sup_id="PoDetail.po_sup_id?.code" />
                <template #modal-footer>
                  <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                    <b-button style="border-radius: 0.5rem; padding: 0 1.1rem !important;" variant="primary"
                      class="btn-min-size" size="sm" @click="onSubmitProductModal">
                      <i class="save-data_ct" />บันทึก
                    </b-button>
                    <b-button variant="danger" class="btn-min-size btn-cancel-border" style="border-radius: 0.5rem;"
                      size="sm" @click="onCloseProductModal">
                      ยกเลิก
                    </b-button>
                  </div>
                </template>
              </b-modal>
              <b-modal v-model="showModalService" title="เลือกวัตถุดิบ" cancel-title="ยกเลิก" ok-title="เพิ่ม"
                @ok="onSubmitServiceModal" @close="onCloseServiceModal" size="xl" :no-close-on-esc="true"
                :no-close-on-backdrop="true">
                <rawMatSelectModalPO ref="rawMatSelectedCom" :poDelList="JSON.parse(JSON.stringify(poDelList))"
                  :mainRawMatSelected="rowRawMatSelected.data" :sup_id="PoDetail.po_sup_id?.code" />
                <template #modal-footer>
                  <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                    <b-button style="border-radius: 0.5rem; padding: 0 1.1rem !important;" variant="primary"
                      class="btn-min-size" size="sm" @click="onSubmitServiceModal">
                      <i class="save-data_ct" />บันทึก
                    </b-button>
                    <b-button variant="danger" class="btn-min-size btn-cancel-border" style="border-radius: 0.5rem;"
                      size="sm" @click="showModalService = false">
                      ยกเลิก
                    </b-button>
                  </div>
                </template>
              </b-modal>
            </div>
            <b-row>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped>
/* .select-muti-po > .vs--multiple > .vs__dropdown-toggle {
  height: 60px !important;
} */
.flex-response-header-po {
  display: flex;
  gap: .5rem
}
.cash-down-table-po {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.max-width-btn-po-mgt {
  max-width: 200px;
}

@media (max-width: 376px) {
  .flex-response-header-po {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0;
  }
  .max-width-btn-po-mgt {
    max-width: 376px;
    width: 100%;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import moment from 'moment'
import { mask } from 'vue-the-mask'
import { configAPI } from '../../configBase'
import CurrencyInputCom from './CurrencyInputCom.vue'
import productSelectModalPO from './component/productSelectModalPO.vue'
// import ServiceSelectModal from './component/serviceSelectModal.vue'
import rawMatSelectModalPO from './component/rawMatSelectModalPO.vue'
import Axios from 'axios'

const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}

const configCurrency = {
  currency: 'EUR',
  currencyDisplay: 'hidden',
  valueRange: {
    min: 0,
    max: 1e+53
  },
  hideCurrencySymbolOnFocus: true,
  hideGroupingSeparatorOnFocus: false,
  hideNegligibleDecimalDigitsOnFocus: false,
  autoDecimalDigits: false,
  useGrouping: true,
  accountingSign: false
}

export default {
  name: 'PurchaseOrderMGT',
  components: {
    productSelectModalPO,
    rawMatSelectModalPO,
    // ServiceSelectModal,
    CurrencyInputCom
  },
  directives: { mask },
  data () {
    return {
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      usrId: JSON.parse(sessionStorage.getItem('user')).usr_id || null,
      id: null,
      mode: 'add',
      from: null,
      modelSupplier: false,
      showModalProduct: false,
      showModalService: false,
      showModalRawMat: false,
      poDelList: [],
      PoDetail: {
        po_id: null,
        po_no: null,
        po_date: null,
        po_delivery_date: null,
        po_ref_no: null,
        po_sup_id: null,
        po_ref_po_id: null,
        po_ref_detail: null,
        po_sup_data: null,
        po_incl_vat: null,
        quo_cus_citizen_id: null,
        po_total_price: null,
        quo_approve_date: null,
        quo_is_incl_vat: true,
        po_remark: null,
        po_sup_line: null,
        po_approve_date: null,
        po_sum_price_prd: null,
        po_sum_price_rm: null,
        po_final_discount: null
      },
      rowSelected: {
        data: [],
        oldData: []
      },
      rowRawMatSelected: {
        data: [],
        oldData: []
      },
      productSelect: [],
      rawMatSelect: [],
      columnsSelected: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        // { label: 'ประเภท', key: 'type_proser', class: 'text-center table-size-200-400' },
        { label: 'รหัสสินค้า', key: 'code_proser', class: 'text-center table-size-200-400 ellips' },
        { label: 'ชื่อสินค้า', key: 'name_proser', class: 'text-center table-size-200-400 ellips' },
        { label: 'จำนวน', key: 'quantity', class: 'text-center table-size-200-400' },
        { label: 'ราคา', key: 'lp', class: 'text-center table-size-200-400' },
        { label: 'ส่วนลด', key: 'discount', class: 'text-center table-size-200-400' },
        { label: 'ราคารวม', key: 'total_price', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'mgt', class: 'text-center my-column' }
      ],
      columnsMatSelected: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        { label: 'หมวดหมู่', key: 'type_proser', class: 'text-center table-size-200-400' },
        { label: 'แบรนด์', key: 'brand', class: 'text-center table-size-200-400 ellips' },
        { label: 'ประเภท', key: 'type', class: 'text-center table-size-200-400' },
        { label: 'หน่วยนับ', key: 'unit', class: 'text-center table-size-200-400' },
        { label: 'จำนวน', key: 'quantity', class: 'text-center table-size-200-400' },
        { label: 'ราคา', key: 'lp', class: 'text-center table-size-200-400' },
        { label: 'ส่วนลด', key: 'discount', class: 'text-center table-size-200-400' },
        { label: 'ราคารวม', key: 'total_price', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'mgt', class: 'text-center my-column' }
      ],
      currentPageSelected: 1,
      currentRMPageSelected: 1,
      perPageSelected: 10,
      isDisable: false,
      options: {
        // https://momentjs.com/docs/#/displaying/
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: false,
        showClose: true,
        useStrict: true,
        minDate: false,
        // stepping: 1,
        icons: iconDate,
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'auto'
        }
      },
      options2: {
        // https://momentjs.com/docs/#/displaying/
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: false,
        showClose: true,
        useStrict: true,
        minDate: false,
        // stepping: 1,
        icons: iconDate,
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'auto'
        }
      },
      options3: {
        // https://momentjs.com/docs/#/displaying/
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: false,
        showClose: true,
        useStrict: true,
        minDate: false,
        maxDate: moment(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
        // stepping: 1,
        icons: iconDate,
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'auto'
        }
      },
      optionCurrency: {
        precision: 0,
        ...configCurrency
      },
      optionCurrency2: {
        precision: 2,
        ...configCurrency
      },
      customerDD: [],
      customerData: null,
      supplierDDdata: [],
      purchaseOrderDDdata: [],
      canChangePORef: false
    }
  },
  async mounted () {
    this.setFrom()
    this.setPage()
    this.setId()
    await this.supplierDD()
    await this.purchaseOrderDD()
    if (this.$route.params.setPage === 'add') {
      this.PoDetail.po_date = moment(new Date()).format('DD/MM/YYYY')
      this.options.minDate = moment(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate()
      this.options2.minDate = moment(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate()
    } else {
      await this.getPurchaseOrderById()
    }
    this.canChangePORef = true
    xray.index()
  },
  computed: {
    supplierDDmap () {
      return this.supplierDDdata.data?.map(option => ({ code: option.id, label: option.name }))
    },
    purchaseOrderDDmap () {
      return this.purchaseOrderDDdata.data?.map(option => ({ code: option.po_id, label: option.po_no })).filter(el => el.code !== this.PoDetail.po_id)
    }
  },
  watch: {
    'PoDetail.po_total_price': {
      handler: function (val, oldVal) {
        this.PoDetail.po_total_price = this.formatNumber(val)
      },
      deep: true
    },
    'rowSelected.data': {
      handler: function (val, oldVal) {
        // this.rowSelected.data = val
        this.calculateTotal()
      },
      deep: true
    },
    'rowRawMatSelected.data': {
      handler: function (val, oldVal) {
        // this.rowRawMatSelected.data = val
        this.calculateTotal()
      },
      deep: true
    },
    'PoDetail.po_date': {
      handler: function (val, oldVal) {
        if (val !== null && oldVal !== null) {
          this.options2.minDate = new Date(moment(val, 'DD/MM/YYYY'))
        } else if (val !== '' && oldVal === '') {
          this.options2.minDate = new Date(moment(val, 'DD/MM/YYYY'))
        }
      },
      deep: true
    },
    'PoDetail.po_final_discount': function (newVal, oldVal) {
      this.calculateTotal()
    },
    'PoDetail.quo_is_incl_vat': {
      handler: function (val, oldVal) {
        this.calculateTotal()
      },
      deep: true
    }
  },
  methods: {
    onClickShowModalProduct (state) {
      if (!this.PoDetail.po_sup_id) {
        this.SwalError('กรุณาเลือกซัพพลายเออร์ก่อนทำรายการ', 'ไม่สามารถเพิ่มข้อมูลได้')
        return
      }
      this[state] = true
    },
    calculateTotal () {
      let total = 0
      let totalSaparete = {
        rawmat: 0,
        product: 0
      }
      let subTotal = 0
      let includeVat = 0
      let product = []
      let rawMat = []

      for (let item of this.rowSelected.data) {
        if (parseFloat((((item.lp || 0) * (item.quantity || 0)) - parseFloat(item.discount?.toString().replace(/,/g, '') || 0)).toFixed(2)) < 0) {
          this.$refs[item.id?.replace(/-/g, '')][0].formattedValue = 0
          item.discount = 0
        }

        subTotal += parseFloat((((item.lp || 0) * (item.quantity || 0)) - parseFloat(item.discount?.toString().replace(/,/g, '') || 0)).toFixed(2))
        totalSaparete.product += parseFloat((((item.lp || 0) * (item.quantity || 0)) - parseFloat(item.discount?.toString().replace(/,/g, '') || 0)).toFixed(2))
        item.row_price = parseFloat((((item.lp || 0) * (item.quantity || 0) - (parseFloat(item.discount?.toString().replace(/,/g, '')) || 0))).toFixed(2))
        product.push(item)
      }

      for (let item of this.rowRawMatSelected.data) {
        if (parseFloat((((item.lp || 0) * (item.quantity || 0)) - parseFloat(item.discount?.toString().replace(/,/g, '') || 0)).toFixed(2)) < 0) {
          this.$refs[item.id?.replace(/-/g, '')][0].formattedValue = 0
          item.discount = 0
        }

        subTotal += parseFloat((((item.lp || 0) * (item.quantity || 0)) - parseFloat(item.discount?.toString().replace(/,/g, '') || 0)).toFixed(2))
        totalSaparete.rawmat += parseFloat((((item.lp || 0) * (item.quantity || 0)) - parseFloat(item.discount?.toString().replace(/,/g, '') || 0)).toFixed(2))
        item.row_price = parseFloat(((((item.lp || 0) * (item.quantity || 0)) - (parseFloat(item.discount?.toString().replace(/,/g, '') || 0)))).toFixed(2))
        rawMat.push(item)
      }

      if ((subTotal - parseFloat(this.PoDetail.po_final_discount || 0)) < 0) {
        this.$refs['po_final_discountRef'].formattedValue = subTotal
        this.PoDetail.po_final_discount = subTotal
      }
      subTotal = parseFloat(subTotal.toFixed(2))
      totalSaparete.rawmat = parseFloat(totalSaparete.rawmat.toFixed(2)) * (this.PoDetail.quo_is_incl_vat ? 1 : 1)
      totalSaparete.product = parseFloat(totalSaparete.product.toFixed(2)) * (this.PoDetail.quo_is_incl_vat ? 1 : 1)
      if (this.PoDetail.quo_is_incl_vat) {
        includeVat = (subTotal - parseFloat(this.PoDetail.po_final_discount || 0)) * 0.07
        total = (subTotal - parseFloat(this.PoDetail.po_final_discount || 0)) * 1.07
      } else {
        includeVat = 0
        total = (subTotal - parseFloat(this.PoDetail.po_final_discount || 0))
      }
      total = parseFloat(total.toFixed(2))
      /* this.rowSelected.data = product
      this.rowRawMatSelected.data = rawMat */
      this.productSelect = product
      this.rawMatSelect = rawMat
      this.PoDetail.po_total_price = total
      this.PoDetail.quo_sup_total = subTotal
      this.PoDetail.po_incl_vat = parseFloat(includeVat.toFixed(2))
      this.PoDetail.po_sum_price_prd = totalSaparete.product
      this.PoDetail.po_sum_price_rm = totalSaparete.rawmat
    },
    calculateRow (from, item, state) {
      if (item?.poi_id) {
        item.status = 'update'
      } else {
        item.status = 'add'
      }
      const _index = this[state].data.findIndex(el => el.id === item.id)
      if (_index >= 0) {
        this[state].data[_index] = item
      }
      // rowRawMatSelected or rowSelected
      this.calculateTotal()
    },
    onSubmitProductModal () {
      let Old = JSON.parse(JSON.stringify(this.productSelect))
      let [New, Del] = this.$refs.productSelectedCom.submitAction()

      this.productSelect = []
      this.rowSelected.data = []
      for (let item of New) {
        const _indexDel = Del.findIndex(el => el?.id === item?.id)
        if (_indexDel !== -1) {
          item = Del[_indexDel]
          Del.splice(_indexDel, 1)
        }

        let index = Old.findIndex(el => el.id === item.id)
        if (index < 0) {
          this.productSelect.push(item)
        } else {
          this.productSelect.push(Old[index])
        }

        /* const _indexDel = Del.indexOf(item.poi_id);
        if (_indexDel >= 0) {
          Del.splice(_indexDel, 1)
        } */
      }
      this.poDelList = Del
      // console.log('Del2', Del)
      this.rowSelected.data = [...this.productSelect]
      this.showModalProduct = false
    },
    onCloseProductModal () {
      this.showModalProduct = false
    },
    onSubmitServiceModal () {
      let [New, Del] = this.$refs.rawMatSelectedCom.submitAction()
      let Old = JSON.parse(JSON.stringify(this.rawMatSelect))
      this.rawMatSelect = []
      this.rowRawMatSelected.data = []
      for (let item of New) {
        const _indexDel = Del.findIndex(el => el?.id === item?.id)
        if (_indexDel !== -1) {
          item = Del[_indexDel]
          Del.splice(_indexDel, 1)
        }

        let index = Old.findIndex(el => el.id === item.id)
        if (index < 0) {
          this.rawMatSelect.push(item)
        } else {
          this.rawMatSelect.push(Old[index])
        }
      }
      this.poDelList = Del
      this.rowRawMatSelected.data = [...this.rawMatSelect]
      this.showModalService = false
    },
    onCloseServiceModal () {
      this.showModalService = false
    },
    onCloseModalSupplier () {
      this.modelSupplier = false
    },
    onClickDatePicker (number) {
      const datepicker = document.getElementById(`datepicker-custom${number}`)
      datepicker.focus()
    },
    handleClickBack () {
      if (this.mode !== 'view') {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            router.push('/PurchaseOrderTable')
          }
        })
      } else {
        router.push('/PurchaseOrderTable')
      }
    },
    handleClick (sendApprove) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะ${sendApprove ? 'ส่งอนุมัติ' : this.mode === 'add' ? 'บันทึก' : 'แก้ไข'}ใช่หรือไม่</span>`,
        // html: '<span>ยืนยันการส่งอนุมัติ</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        confirmButtonColor: '#0085FF',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let errorList = await this.checkSave()
          if (errorList.length > 0) {
            let message = ''
            errorList.forEach((item) => {
              message += `${item} <br>`
            })
            this.SwalError(message, 'กรุณากรอกข้อมูลให้ครบถ้วน')
            return
          }
          let dataforsave = {
            po_id: this.PoDetail.po_id || null,
            po_no: this.PoDetail.po_no || null,
            po_date: this.PoDetail.po_date ? new Date(moment(this.PoDetail.po_date, 'DD/MM/YYYY')) : null,
            po_sup_id: this.PoDetail.po_sup_id?.code || null,
            po_ref_po_id: this.PoDetail.po_ref_po_id?.code || null,
            po_ref_no: this.PoDetail.po_ref_no || null,
            po_ref_detail: this.PoDetail.po_ref_detail || null,
            po_sup_data: this.PoDetail.po_sup_data || null,
            po_incl_vat: this.PoDetail.quo_is_incl_vat,
            po_total_price: this.PoDetail.po_total_price || null,
            po_final_discount: this.PoDetail.po_final_discount,
            // quo_cus_citizen_id: this.PoDetail.quo_cus_citizen_id || null,
            po_remark: this.PoDetail.po_remark || null,
            po_delivery_date: this.PoDetail.po_delivery_date ? new Date(moment(this.PoDetail.po_delivery_date, 'DD/MM/YYYY')) : null,
            // quo_discount: this.PoDetail.quo_discount || null,
            // po_total_price: this.PoDetail.po_total_price || null,
            // quo_is_incl_vat: this.PoDetail.quo_is_incl_vat,
            usrId: this.usrId,
            clnId: this.clnId,
            po_product_list: this.rowSelected.data,
            po_rawmat_list: this.rowRawMatSelected.data,
            is_sendApprove: sendApprove,
            poDelList: this.poDelList
          }
          // console.log('dataforsave', dataforsave)
          await Axios.request({
            method: 'POST',
            url: `${configAPI.SALE_PORT}/api/purchaseOrder/clinic/${this.clnId}/${this.mode === 'add' ? 'savePurchaseOrder' : 'editPurchaseOrder'}`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: dataforsave
          }).then((res) => {
            Vue.swal.fire({
              title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.mode === 'add' ? 'บันทึก' : 'แก้ไข'}สำเร็จ</span>`,
              // html: '<span>ส่งอนุมัติสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              router.push('/PurchaseOrderTable')
            })
          }).catch((err) => {
            this.SwalError(err, 'Error')
          })
        }
      })
    },
    async purchaseOrderDD () {
      await Axios.request({
        method: 'get',
        url: `${configAPI.SALE_PORT}/api/purchaseOrder/clinic/${this.clnId}/getPurchaseOrderAll`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          this.purchaseOrderDDdata = response.data
        })
        .catch((error) => {
          this.SwalError(error.message, 'ลบข้อมูลไม่สำเร็จ')
        })
    },
    async supplierDD () {
      let supplierDDAPI = {
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/supplier`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await Axios.request(supplierDDAPI)
        .then((response) => {
          this.supplierDDdata = response.data
        })
        .catch((error) => {
          this.SwalError(error.message, 'ลบข้อมูลไม่สำเร็จ')
        })
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    async getPurchaseOrderById (poId) {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.SALE_PORT}/api/purchaseOrder/clinic/${this.clnId}/getPurchaseOrder/${poId || this.$route.params.id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        let tempData = res.data.data[0]
        this.PoDetail.po_ref_no = tempData?.po_ref_no || null
        this.PoDetail.po_ref_detail = tempData?.po_ref_detail || null
        if (tempData?.po_sup_id) {
          this.SetSupplierDetails(tempData?.po_sup_id)
        }
        // this.PoDetail.po_sup_id = tempData?.po_sup_id ? this.supplierDDmap.find(el => el.code === tempData?.po_sup_id) : null
        // this.PoDetail.po_sup_data = tempData?.po_sup_data || null
        // this.PoDetail.po_incl_vat = tempData?.po_incl_vat ? moment(tempData?.po_incl_vat).format('DD/MM/YYYY') : ''
        this.PoDetail.quo_is_incl_vat = tempData?.po_incl_vat
        // this.PoDetail.quo_cus_citizen_id = tempData?.quo_cus_citizen_id || null
        this.PoDetail.po_total_price = tempData?.po_total_price || null
        this.PoDetail.po_remark = tempData?.po_remark || null
        this.PoDetail.po_final_discount = tempData?.po_final_discount || null
        // this.PoDetail.quo_discount = tempData?.quo_discount || null
        // this.PoDetail.po_total_price = tempData?.po_total_price || null
        // this.PoDetail.quo_is_incl_vat = tempData?.quo_is_incl_vat
        if (!poId) {
          this.PoDetail.po_id = tempData?.po_id || null
          this.PoDetail.po_no = tempData?.po_no || null
          this.PoDetail.po_date = tempData?.po_date ? moment(tempData?.po_date).format('DD/MM/YYYY') : ''
          this.PoDetail.po_delivery_date = tempData?.po_delivery_date ? moment(tempData?.po_delivery_date).format('DD/MM/YYYY') : ''
          this.PoDetail.po_ref_po_id = tempData?.po_ref_po_id ? this.purchaseOrderDDmap.find(el => el.code === tempData?.po_ref_po_id) || null : null
          this.PoDetail.po_approve_date = tempData?.po_approve_date ? moment(tempData?.po_approve_date).format('DD/MM/YYYY') : ''
        }
        // this.options.minDate = new Date(moment(this.PoDetail.po_date, 'DD/MM/YYYY'))
        let arrList = {
          prdList: [],
          rmList: []
        }
        for (let item of tempData?.po_item_list) {
          item.is_select = true
          item.quantity = item.poi_amount
          item.lp = item.poi_price
          item.discount = item.poi_discount

          item.poi_id = poId ? null : item.poi_id
          if (poId) {
            item.status = 'add'
          }
          if (item.poi_prd_id) {
            item.id = item.poi_prd_id
            const dataName = {
              no: item.poi_item_data.no || item.poi_item_data.pri_item_data.no,
              name: item.poi_item_data.name || item.poi_item_data.pri_item_data.name
            }
            arrList.prdList.push({ ...item, ...dataName })
          } else if (item.poi_mat_id) {
            item.id = item.poi_mat_id
            const dataName = {
              mg_name: item.poi_item_data.mg_name || item.poi_item_data.pri_item_data.mg_name,
              mb_name: item.poi_item_data.mb_name || item.poi_item_data.pri_item_data.mb_name,
              mt_name: item.poi_item_data.mt_name || item.poi_item_data.pri_item_data.mt_name,
              unit_name: item.poi_item_data.unit_name || item.poi_item_data.pri_item_data.unit_name
            }
            arrList.rmList.push({ ...item, ...dataName })
          }
        }

        this.rowSelected.data = arrList.prdList
        this.rowRawMatSelected.data = arrList.rmList
        if (!poId && this.mode !== 'add') {
          this.rowSelected.oldData = JSON.parse(JSON.stringify(arrList.prdList || []))
          this.rowRawMatSelected.oldData = JSON.parse(JSON.stringify(arrList.rmList || []))
        }
        this.calculateTotal()
      }).catch((err) => {
        // console.log('err', err)
        this.SwalError(err, 'Error')
      })
    },
    SetSupplierDetails (supId) {
      const _find = this.supplierDDdata.data.find(el => el.id === supId)
      this.PoDetail.po_sup_id = this.supplierDDmap.find(el => el.code === supId)
      this.PoDetail.po_sup_con_name = _find ? _find.constact_name || null : null
      this.PoDetail.po_sup_line = _find ? _find.line_id || null : null
      this.PoDetail.po_sup_tel = _find ? _find.contact_tel || null : null
      this.PoDetail.po_details_sup = _find ? _find.detail || null : null
      this.PoDetail.po_sup_data = _find ? JSON.stringify(_find) : null
    },
    getFullImagePath (relativePath) {
      if (relativePath !== null && relativePath !== '') {
        let path = relativePath !== null ? relativePath.slice(1) : ''
        const baseURL = configAPI.MA_PORT + path
        return baseURL
      } else {
        return 'https://www.w3schools.com/howto/img_avatar.png'
      }
    },
    async checkSave () {
      let errList = []
      if (!this.PoDetail.po_sup_id) {
        errList.push('กรุณาเลือก ซัพพลายเออร์')
      }

      if (this.rowSelected.data.length === 0 && this.rowRawMatSelected.data.length === 0) {
        errList.push('กรุณาเลือกสินค้า หรือวัตถุดิบ อย่างน้อย 1 รายการ')
      } else {
        let temp1 = []

        for (let item of this.rowSelected.data) {
          if (item.quantity === null || item.quantity === '') {
            temp1.push(`รหัสสินค้า <span class="ellipsModal">${item.no}</span> : กรุณากรอกจำนวน`)
          }
        }

        if (temp1.length > 0) {
          if (errList.length > 0) {
            errList.push('</hr>')
          }

          errList.push(`กรอกข้อมูลสินค้าไม่ครบถ้วน`)
          errList.push(...temp1)
        }

        let temp2 = []
        let indexTemp2 = 1

        for (let item of this.rowRawMatSelected.data) {
          if (item.quantity === null || item.quantity === '') {
            temp2.push(`วัตถุดิบลำดับที่ ${indexTemp2} : กรุณากรอกจำนวน`)
          }

          indexTemp2 += 1
        }

        if (temp2.length > 0) {
          if (errList.length > 0) {
            errList.push('</hr>')
          }

          errList.push(`กรอกข้อมูลวัตถุดิบไม่ครบถ้วน`)
          errList.push(...temp2)
        }
      }
      /* if (this.rowSelected.data.length === 0 && this.rowRawMatSelected.data.length === 0) {
        errList.push(`กรุณาเลือกสินค้า หรือวัตถุดิบอย่างน้อย 1 รายการ`)
      }

      for (let item of this.rowSelected.data) {
        if (!item.lp) {
          errList.push(`รหัสสินค้า: ${item.no} กรุณากรอกจำนวน`)
        }

        if (!item.quantity) {
          errList.push(`รหัสสินค้า: ${item.no} กรุณากรอกราคา`)
        }
      }

      let indexRun = 1
      for (let item of this.rowRawMatSelected.data) {
        if (!item.lp) {
          errList.push(`วัตถุดิบลำดับที่: ${indexRun} กรุณากรอกราคา`)
        }

        if (!item.quantity) {
          errList.push(`วัตถุดิบลำดับที่: ${indexRun} กรุณากรอกราคา`)
        }
        indexRun += 1
      } */

      if (this.PoDetail.po_total_price < 0) {
        errList.push(`ราคาสุทธิต้องไม่เป็นค่าติดลบ`)
      }
      return errList
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    formatNumber (value) {
      if (value === null || value === undefined) {
        return ''
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    deleteRow (item, state, selectState) {
      let temp = this[state].data // rowSelected or rowRMSelected
      let tempSelect = this[selectState] // productSelect or rawmatSelect
      let index = temp.findIndex(el => el.id === item.id)
      let indexSelect = tempSelect.findIndex(el => el.id === item.id)
      if (indexSelect >= 0) {
        tempSelect.splice(indexSelect, 1)
      }
      if (index >= 0) {
        if (temp[index].poi_id && (this.poDelList.find(el => el.poi_id === temp[index].poi_id) === undefined)) {
          this.poDelList.push(temp[index])
        }
        temp.splice(index, 1)
      }
      this.calculateTotal()
    },
    confirmClearItemFromSup (supDataOld) {
      Vue.swal.fire(this.GetTextConfirmSwal('คุณมั่นใจว่าจะเปลี่ยนซัพพลายเออร์ใช่หรือไม่', 600))
        .then(async (result) => {
          if (result.isConfirmed) {
            this.PoDetail.po_sup_id = null
            this.PoDetail.po_sup_con_name = null
            this.PoDetail.po_sup_line = null
            this.PoDetail.po_sup_tel = null
            this.PoDetail.po_details_sup = null
            this.PoDetail.po_sup_data = null

            if (this.mode !== 'add') {
              this.poDelList = JSON.parse(JSON.stringify([ ...this.rowSelected.oldData, ...this.rowRawMatSelected.oldData ]))
            }
            this.rowSelected.data = []
            this.rowRawMatSelected.data = []
            // this.rowSelected.oldData = JSON.parse(JSON.stringify([]))
            // this.rowRawMatSelected.oldData = JSON.parse(JSON.stringify([]))
          } else {
            this.PoDetail.po_sup_id = supDataOld
          }
        })
    },
    async ChangeSup (newVal) {
      const oldVal = this.PoDetail.po_sup_id
      const _find = this.supplierDDdata.data.find(el => el.id === (newVal ? newVal.code : ''))
      if (!newVal && (this.rowSelected.data.length > 0 || this.rowRawMatSelected.data.length > 0)) {
        this.confirmClearItemFromSup(oldVal)
      } else {
        if (oldVal) {
          Vue.swal.fire(this.GetTextConfirmSwal('คุณมั่นใจว่าจะเปลี่ยนซัพพลายเออร์ใช่หรือไม่', 600))
            .then(async (result) => {
              if (result.isConfirmed) {
                this.PoDetail.po_sup_id = newVal
                this.SetDataSupplierNew(_find)
              } else {
                this.PoDetail.po_sup_id = oldVal
              }
            })
        } else {
          this.PoDetail.po_sup_id = newVal
          this.SetDataSupplierNew(_find)
        }
      }
    },
    async ChagePORef (newVal) {
      const oldVal = this.PoDetail.po_ref_po_id
      if (!newVal && (this.rowSelected.data.length > 0 || this.rowRawMatSelected.data.length > 0)) {
        this.confirmClearItemFromPORef(oldVal)
      } else {
        if (oldVal) {
          Vue.swal.fire(this.GetTextConfirmSwal('คุณมั่นใจว่าจะแก้ไขเลขอ้างอิงใบสั่งซื้อใช่หรือไม่', 600))
            .then(async (result) => {
              if (result.isConfirmed) {
                this.PoDetail.po_ref_po_id = newVal
                await this.getPurchaseOrderById(newVal.code)
              } else {
                this.PoDetail.po_ref_po_id = oldVal
              }
            })
        } else {
          this.PoDetail.po_ref_po_id = newVal
          await this.getPurchaseOrderById(newVal.code)
        }
      }
    },
    SetDataSupplierNew (_find) {
      this.PoDetail.po_sup_con_name = _find ? _find.constact_name || null : null
      this.PoDetail.po_sup_line = _find ? _find.line_id || null : null
      this.PoDetail.po_sup_tel = _find ? _find.contact_tel || null : null
      this.PoDetail.po_details_sup = _find ? _find.detail || null : null
      this.PoDetail.po_sup_data = _find ? JSON.stringify(_find) : null

      if (this.mode !== 'add') {
        this.poDelList = JSON.parse(JSON.stringify([ ...this.rowSelected.oldData, ...this.rowRawMatSelected.oldData ]))
      }
      this.rowSelected.data = []
      this.rowRawMatSelected.data = []
      // this.rowSelected.oldData = JSON.parse(JSON.stringify([]))
      // this.rowRawMatSelected.oldData = JSON.parse(JSON.stringify([]))
    },
    confirmClearItemFromPORef (refDataOld) {
      Vue.swal.fire(this.GetTextConfirmSwal('คุณมั่นใจว่าจะแก้ไขเลขอ้างอิงใบสั่งซื้อใช่หรือไม่', 700))
        .then(async (result) => {
          if (result.isConfirmed) {
            this.PoDetail.po_ref_po_id = null
            this.PoDetail.po_ref_no = null
            this.PoDetail.po_ref_detail = null
            this.PoDetail.po_sup_id = null
            this.PoDetail.po_sup_data = null
            this.PoDetail.quo_is_incl_vat = null
            this.PoDetail.po_total_price = null
            this.PoDetail.po_remark = null
            this.PoDetail.po_final_discount = null

            if (this.mode !== 'add') {
              this.poDelList = JSON.parse(JSON.stringify([ ...this.rowSelected.oldData, ...this.rowRawMatSelected.oldData ]))
            }
            this.rowSelected.data = []
            this.rowRawMatSelected.data = []
            // this.rowSelected.oldData = JSON.parse(JSON.stringify([]))
            // this.rowRawMatSelected.oldData = JSON.parse(JSON.stringify([]))
          } else {
            this.PoDetail.po_ref_po_id = refDataOld
          }
        })
    },
    GetTextConfirmSwal (text, width) {
      return {
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${text}</span>`,
        html: '<span>สินค้าและวัตถุดิบที่คุณเลือกจะถูกล้าง</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        showCancelButton: true,
        width: width,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }
    },
    setFrom () {
      this.from = this.$route.params.from
    },
    setPage () {
      this.mode = this.$route.params.setPage
      if (this.mode === 'view') {
        this.isDisable = true
      }
    },
    setId () {
      this.id = this.$route.params.id
    },
    onPagination (page) {
      this.currentPageSelected = page
    },
    onPaginationRM (page) {
      this.currentRMPageSelected = page
    }
  }
}
</script>
