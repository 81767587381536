<template>
    <b-container fluid>
      <b-row>
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <div class="row align-items-center py-3">
                <div class="col-6 col-lg-3 col-sm-2">
                  <a class="px-lg-3 mb-0" style="font-size: 25px; color: black; white-space: nowrap;">หมอตอบรับ</a>
                </div>
              </div>
            </template>
            <template v-slot:body>
                <div>
                  <b-row>
                    <b-col sm="12" md="3" v-for="doctor in doctors" :key="doctor.message">
                        <iq-card body-class="text-center">
                          <template v-slot:body>
                            <div class="doc-profile">
                              <img class="rounded-circle img-fluid avatar-80" :src="doctor.image" alt="profile">
                            </div>
                            <div class="iq-doc-info mt-3">
                              <h4> {{doctor.name}}</h4>
                              <p class="mb-0" >{{doctor.specialist}}</p>
                              <a href="javascript:void(0);">{{doctor.website}}</a>
                            </div>
                            <div class="iq-doc-description mt-2">
                              <p class="mb-0">{{doctor.description}}</p>
                            </div>
                            <div class="iq-doc-social-info mt-3 mb-3">
                              <ul class="m-0 p-0 list-inline">
                                <li><a href="#"><i class="ri-facebook-fill"></i></a></li>
                                <li><a href="#"><i class="ri-twitter-fill"></i></a> </li>
                                <li><a href="#"><i class="ri-google-fill"></i></a></li>
                              </ul>
                            </div>
                            <a href="#" class="btn btn-primary">View Profile</a>
                          </template>
                        </iq-card>
                    </b-col>
                  </b-row>
                </div>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'

export default {
  data () {
    return {
      name: 'UiDataTable',
      activeTab: 'tab1',
      selectedOption: 'option1',
      doctors: [
        { name: 'Dr. Anna Mull', specialist: 'Cardiologists', image: require('../../assets/images/user/1.jpg'), website: 'www.demo.com', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { name: 'Dr. Bunny Joy', specialist: 'Gynaecology', image: require('../../assets/images/user/01.jpg'), website: 'www.demo.com', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { name: 'Dr. Shonda Leer', specialist: 'Orthopedics Special', image: require('../../assets/images/user/02.jpg'), website: 'www.demo.com', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { name: 'Dr. Ira Membrit', specialist: 'MD', image: require('../../assets/images/user/03.jpg'), website: 'www.demo.com', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { name: 'Dr. Paul Molive', specialist: 'Medicine Specialists', image: require('../../assets/images/user/04.jpg'), website: 'www.demo.com', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { name: 'Dr. Dr. Terry Aki', specialist: 'Heart Surgeons', image: require('../../assets/images/user/05.jpg'), website: 'www.demo.com', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { name: 'Dr. Poppa Cherry', specialist: 'Family Physicians', image: require('../../assets/images/user/06.jpg'), website: 'www.demo.com', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { name: 'Dr. Dr. Saul T. Balls', specialist: 'Gynaecology', image: require('../../assets/images/user/07.jpg'), website: 'www.demo.com', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { name: 'Dr. Hal Appeno', specialist: 'MD', image: require('../../assets/images/user/08.jpg'), website: 'www.demo.com', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { name: 'Dr. Polly Tech', specialist: 'Eye Special', image: require('../../assets/images/user/09.jpg'), website: 'www.demo.com', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { name: 'Dr. Pat Agonia', specialist: 'Therapy Special', image: require('../../assets/images/user/10.jpg'), website: 'www.demo.com', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { name: 'Dr. Barry Cade', specialist: 'Heart Surgeons', image: require('../../assets/images/user/12.jpg'), website: 'www.demo.com', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { name: 'Dr.Jimmy Changa', specialist: 'Cardiologists', image: require('../../assets/images/user/12.jpg'), website: 'www.demo.com', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { name: 'Dr. Sue Vaneer', specialist: 'Orthopedics Special', image: require('../../assets/images/user/13.jpg'), website: 'www.demo.com', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { name: 'Dr. Monty Carlo', specialist: 'Anesthesiologists', image: require('../../assets/images/user/14.jpg'), website: 'www.demo.com', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { name: 'Dr. Rick O\'Shea', specialist: 'General', image: require('../../assets/images/user/15.jpg'), website: 'www.demo.com', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' }
      ],
      columns: [
        { label: 'เลขบัตรประชาชน', key: 'variable1', class: 'text-center' },
        { label: 'ชื่อ-นามสกุล', key: 'variable2', class: 'text-center' },
        { label: 'ชื่อเล่น', key: 'variable3', class: 'text-center' },
        { label: 'เพศ', key: 'variable4', class: 'text-center' },
        { label: 'เบอร์โทร', key: 'variable5', class: 'text-center' },
        { label: 'จัดการ', key: 'variable6', class: 'text-center' }
      ],
      rows: [
        {
          id: 1,
          variable1: 'Tiger Nixon',
          variable2: 'System Architect',
          variable3: 'Edinburgh',
          variable4: '61',
          variable5: '2011/04/25',
          editable: false
        },
        {
          id: 2,
          variable1: 'Garrett Winters',
          variable2: 'Accountant',
          variable3: 'Tokyo',
          variable4: '63',
          variable5: '2011/06/19',
          editable: false
        },
        {
          id: 3,
          variable1: 'Ashton Cox',
          variable2: 'Junior Technical Author',
          variable3: 'San Francisco',
          variable4: '69',
          variable5: '2011/01/20',
          variable6: '$140,500',
          editable: false
        },
        {
          id: 4,
          variable1: 'Cedric Kelly',
          variable2: 'Senior Javascript Developer',
          variable3: 'Edinburgh',
          variable4: '42',
          variable5: '2011/02/02',
          variable6: '$360,500',
          editable: false
        },
        {
          id: 5,
          variable1: 'Airi Satou',
          variable2: 'Accountant',
          variable3: 'Tokyo',
          variable4: '39',
          variable5: '2011/08/11',
          variable6: '$170,800',
          editable: false
        },
        {
          id: 1,
          variable1: 'Tiger Nixon',
          variable2: 'System Architect',
          variable3: 'Edinburgh',
          variable4: '61',
          variable5: '2011/04/25',
          variable6: '$320,800',
          editable: false
        },

        {
          id: 5,
          variable1: 'Airi Satou',
          variable2: 'Accountant',
          variable3: 'Tokyo',
          variable4: '39',
          variable5: '2011/08/11',
          variable6: '$170,800',
          editable: false
        },
        {
          id: 1,
          variable1: 'Tiger Nixon',
          variable2: 'System Architect',
          variable3: 'Edinburgh',
          variable4: '61',
          variable5: '2011/04/25',
          variable6: '$320,800',
          editable: false
        }
      ],
      currentPage: 1,
      perPage: 10
    }
  },
  methods: {
    onPagination (page) {
      this.currentPage = page
    },
    handleClick () {
      router.push('/AddUsers')
    }
  },
  mounted () {
    xray.index()
  }
}
</script>
