<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">จัดการบันทึกการลางาน</span>
              </div>
              <!-- <div class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="button" variant="primary" @click="handleClickGoBack" class="w-100">เพิ่ม</b-button>
              </div> -->
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col lg="2" sm="6">
                  <b-text class="" style="color: black;">ข้อมูลลาของปี</b-text>
                  <v-select v-model="searchData.year" :disabled="isDisable" :options="items1" style="margin-top: .2rem;"></v-select>
                  <!-- <b-form-select style="padding-bottom: 0.6rem; padding-top: 0.6rem;" class="my-1 form-control d-flex" plain :options="items1" size="lg" v-model="searchData.year">
                    <template v-slot:first>
                      <b-form-select-option :value="null">Select</b-form-select-option>
                    </template>
                  </b-form-select> -->
                </b-col>
                <b-col lg="2" sm="6">
                  <b-form-group class="unselectable" label="ชื่อพนักงาน" :disabled="isDisable" style="color: black;">
                      <b-form-input type="text"  @keydown="enterSearch" v-model="searchData.nameEmp" ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col sm="6" class="col-6 edit-col-lg-1 col-lg-2 pt-4-edit-res-absenceT d-flex align-items-center justify-content-center padding-respnose-pt-left">
                  <b-button type="submit" variant="primary" @click="handleClickSearch" class="w-100 margin-res-edit-absenceT" style="height: 2.6rem"><i class="search_ct"></i>ค้นหา</b-button>
                </b-col>
                <b-col sm="6" class="col-6 edit-col-lg-1 col-lg-2 pt-4-edit-res-absenceT d-flex align-items-center justify-content-center padding-respnose-pt-right">
                  <b-button type="submit" variant="none" @click="onClickClear(true)" class="w-100 margin-res-edit-absenceT btn-reset_ct" style="height: 2.6rem">ล้างข้อมูล</b-button>
                </b-col>
              </b-row>
              </div>
              <br>
              <b-col md="12" class="table-responsive">
                <b-table bordered hover :items="rows" :fields="columns"
                  :current-page="currentPageMain"
                  :per-page="perPage"
                  @pagination="onPagination(true)"
                  :tbody-tr-class="rowClass" thead-class="table-header-color"
                >
                <template v-slot:cell(variable1)="data">
                  <span v-if="!data.item.editable">{{ ((currentPageMain - 1) * 10) + data.index + 1 }}</span>
                  <input type="text" v-model="data.item.variable1" v-else class="form-control">
                </template>
                <template v-slot:cell(variable2)="data" style="text-align: start;">
                  <span v-if="!data.item.editable" style="white-space: pre-line;">{{ data.item.variable2 }}</span>
                  <input type="text" v-model="data.item.variable2" v-else class="form-control">
                </template>
                <template v-slot:cell(variable3)="data">
                  <span v-if="!data.item.editable">{{ data.item.variable3 }}</span>
                  <input type="text" v-model="data.item.variable3" v-else class="form-control">
                </template>
                <template v-slot:cell(variable4)="data">
                  <span v-if="!data.item.editable">{{ data.item.variable4 }}</span>
                  <input type="text" v-model="data.item.variable4" v-else class="form-control">
                </template>
                <template v-slot:cell(variable5)="data">
                  <span v-if="!data.item.editable">{{ data.item.variable5 }}</span>
                  <input type="text" v-model="data.item.variable5" v-else class="form-control">
                </template>
                <template v-slot:cell(variable6)="data">
                  <div style="display: flex; justify-content: center;">
                    <b-button variant=" iq-bg-info mr-1" size="sm" @click="GetLeaveAllOfEmp(data.item)/* handleClick('view', data.item) */"><i class="ri-eye-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-success mx-1" size="sm" @click="handleClick('add', data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                  </div>
                </template>
              </b-table>
              <br>
            </b-col>
            <b-pagination
                v-model="currentPageMain"
                :total-rows="rows.length"
                :per-page="perPage"
                align="center"
              />
              <b-modal v-model="showModal" title="สถานะการ Upload" id="modal-xl" size="xl" no-close-on-backdrop>
                <template #modal-header style="margin-left: 1rem;">
                  <div>
                    <p  style="font-size: 18px; color: black; margin: 0">ประวัติการลาของ [{{ EmpModal.name }}]</p>
                    <p  style="font-size: 18px; color: black">Mode : {{ modeTable ? 'View' : 'Edit' }} History</p>
                  </div>
                  <div style="cursor: pointer;" @click="closeModal()">
                    <i class="ri-close-line"></i>
                  </div>
                </template>
                <div v-if="modeTable === true">
                <b-row style="margin-left: .1rem;">
                    <b-col lg="2" sm="12">
                      <b-text class="" style="color: black;">ประเภทการลา</b-text>
                      <v-select v-model="modalSearchData.typeLeave" :disabled="isDisable" :options="leaveDropdown"></v-select>
                      <!-- <b-form-select style="padding-bottom: 0.6rem; padding-top: 0.6rem;" class="my-1 form-control d-flex" plain :options="leaveDropdown" size="lg" v-model="modalSearchData.typeLeave">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select</b-form-select-option>
                        </template>
                      </b-form-select> -->
                    </b-col>
                    <b-col lg="2" sm="6">
                      <b-text style="color: black;">วันที่เริ่มต้น</b-text>
                      <div style="position: relative;">
                        <date-picker placeholder="DD/MM/YYYY" id="datepicker-custom1" name="date" v-model="modalSearchData.dateTimeStart" :disabled="isDisable" :config="optionsModal"></date-picker>
                        <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(1)" :disabled="isDisable" style="cursor: pointer;"></i>
                      </div>
                    </b-col>
                    <b-col lg="2" sm="6">
                      <b-text style="color: black;">วันที่สิ้นสุด</b-text>
                      <div style="position: relative;">
                        <date-picker placeholder="DD/MM/YYYY" id="datepicker-custom2" name="date" v-model="modalSearchData.dateTimeEnd" :disabled="isDisable" :config="options2Modal"></date-picker>
                        <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(2)" :disabled="isDisable" style="cursor: pointer;"></i>
                      </div>
                    </b-col>
                    <b-col sm="6" class="col-6 edit-col-lg-1 col-lg-2 pt-4-edit-res-partTime d-flex align-items-start justify-content-center padding-respnose-pt-left">
                      <b-button type="submit" variant="primary" class="w-100 margin-edit-modal-absenceT" @click="onClickSearchModal()" style="height: 2.6rem;"><i class="search_ct"/>ค้นหา</b-button>
                    </b-col>
                    <b-col sm="6" class="col-6 edit-col-lg-1 col-lg-2 pt-4-edit-res-partTime d-flex align-items-start justify-content-center padding-respnose-pt-right">
                      <b-button type="submit" variant="none" class="w-100 margin-edit-modal-absenceT btn-reset_ct" style="height: 2.6rem;" @click="onClickClear(false)">ล้างข้อมูล</b-button>
                    </b-col>
                  </b-row>
                  <br>
                  <b-col cols="12" class="table-responsive">
                    <b-table bordered hover :items="rows2" :fields="columns2"
                      :current-page="currentPage"
                      :per-page="perPage"
                      @pagination="onPagination(false)"
                      :tbody-tr-class="rowClass" thead-class="table-header-color"
                    >
                    <template v-slot:cell(variable1)="data">
                      <span v-if="!data.item.editable">{{ ((currentPage - 1) * 10) + data.index + 1 }}</span>
                    </template>
                    <template v-slot:cell(variable2)="data">
                      <span style="white-space: nowrap;" v-if="!data.item.editable">{{ data.item.variable2 }}</span>
                      <input type="text" v-model="data.item.variable2" v-else class="form-control">
                    </template>
                    <template v-slot:cell(variable3)="data">
                      <span style="white-space: nowrap;" v-if="!data.item.editable">{{ data.item.variable3 }}</span>
                      <input type="text" v-model="data.item.variable4" v-else class="form-control">
                    </template>
                    <template v-slot:cell(variable4)="data">
                      <span v-if="!data.item.editable">{{ data.item.variable4 /* getLeaveTypeValue(data.item.variable4, 1) */ }}</span>
                      <input type="text" v-model="data.item.variable4" v-else class="form-control">
                    </template>
                    <template v-slot:cell(variable5)="data">
                      <span v-if="!data.item.editable">{{ data.item.variable5 /* getLeaveTypeValue(data.item.variable5, 2) */ }}</span>
                      <input type="text" v-model="data.item.variable5" v-else class="form-control">
                    </template>
                    <template v-slot:cell(variable6)="data">
                      <span v-if="!data.item.editable" style="white-space: nowrap;">{{ data.item.variable6 }}</span>
                      <input type="text" v-model="data.item.variable6" v-else class="form-control">
                    </template>
                    <template v-slot:cell(variable7)="data">
                      <b-button type="submit" variant="primary" :hidden="!data.item.variable7" @click="downloadFile(data.item)" class="btn-min-size my-1 flex-fill text-center response-button-download" style="text-align: center; flex-basis: 100%;"><i class="out-data_ct"></i>ดาวน์โหลดเอกสาร</b-button>
                      <!-- <a v-if="data.item.variable7"
                      :href="getFullImagePath(data.item.doc_path)" target="_blank" id="downloadfile" :download="data.item.doc_name" class="response-button-download">
                      </a> -->
                      <!-- <span v-if="!data.item.editable">{{ data.item.variable7 }}</span>
                      <input type="text" v-model="data.item.variable7" v-else class="form-control"> -->
                    </template>
                    <template v-slot:cell(variable8)="data">
                      <div style="display: flex; align-items: center; justify-content: center;">
                        <b-button variant=" iq-bg-success mx-1" size="sm" @click="absenceData.dateTimeStart = null, editLeaveModal(data.item, data.index)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                        <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                      </div>
                    </template>
                  </b-table>
                  <br>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows2.length"
                    :per-page="perPage"
                    align="center"
                  />
                  </b-col>
                </div>
                <div v-else>
                  <div class="px-2">
                    <div class="row">
                      <div class="col-12 col-sm-auto">
                        <div class="row align-items-center">
                          <div class="col-6 pl-sm-3 pr-sm-1 my-1 my-sm-0">
                            <b-button type="submit" variant="primary" @click="updateLeaveModal" class="w-100 btn-min-size"><i class="save-data_ct"/>บันทึก</b-button>
                          </div>
                          <div class="col-6 pl-sm-3 pr-sm-1 my-1 my-sm-0">
                            <b-button type="submit" variant="none" @click="handleClickModal(false)" class="iq-bg-danger w-100 btn-cancel-border btn-min-size">ยกเลิก</b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br>
                    <b-row class="">
                      <b-col lg="2" sm="6" class="col-6" style="align-items: center; display: flex;">
                        <b-text style="color: black; white-space: nowrap;">สิทธิการลา</b-text>
                      </b-col>
                      <b-col lg="2" sm="6" class="col-6 d-flex align-items-center" style="white-space: nowrap;">
                        <b-text style="color: green">ลาพักร้อน {{ summaryLeave.entitlement.annual_leave }} วัน</b-text>
                      </b-col>
                      <b-col lg="2" sm="6" class="col-6 d-flex align-items-center" style="white-space: nowrap;">
                        <b-text style="color: blue">ลากิจ {{ summaryLeave.entitlement.business_leave }} วัน</b-text>
                      </b-col>
                      <b-col lg="2" sm="6" class="col-6 d-flex align-items-center" style="white-space: nowrap;">
                        <b-text style="color: red">ลาป่วย {{ summaryLeave.entitlement.sick_leave }} วัน</b-text>
                      </b-col>
                      <b-col lg="2" sm="6" class="col-6 d-flex align-items-center" style="white-space: nowrap;">
                        <b-text style="color: black; margin-right: 1rem;">ประจำปี {{ (getYearCurrent() + 543) }}</b-text>
                        <b-col lg="1" sm="1" class="format-col-pencil" hidden>
                          <b-button :disabled="isDisable" type="submit" variant=" iq-bg-success mr-1" size="sm" @click="modalLeave = true" style="min-width: 35px; min-height: 35px; max-height: 35px;" class="format-col-pencil-btn"><i class="ri-ball-pen-fill m-0"></i></b-button>
                        </b-col>
                      </b-col>
                    </b-row>
                    <hr>
                    <b-row class="">
                      <b-col lg="2" sm="6" class="col-6" style="align-items: center;">
                        <b-text style="color: black;">วันลาคงเหลือ</b-text>
                      </b-col>
                      <b-col lg="2" sm="6" class="col-6 d-flex align-items-center" style="white-space: nowrap;">
                        <b-text style="color: green">ลาพักร้อน {{ summaryLeave.balance.annual_leave }} วัน</b-text>
                      </b-col>
                      <b-col lg="2" sm="6" class="col-6 d-flex align-items-center" style="white-space: nowrap;">
                        <b-text style="color: blue">ลากิจ {{ summaryLeave.balance.business_leave }} วัน</b-text>
                      </b-col>
                      <b-col lg="2" sm="6" class="col-6 d-flex align-items-center" style="white-space: nowrap;">
                        <b-text style="color: red">ลาป่วย {{ summaryLeave.balance.sick_leave }} วัน</b-text>
                      </b-col>
                      <b-col lg="2" sm="6" class="col-6 d-flex align-items-center" style="white-space: nowrap;">
                        <b-text style="color: black">ประจำปี {{ (getYearCurrent() + 543) }}</b-text>
                      </b-col>
                    </b-row>
                    <hr>
                    <b-row >
                      <b-col lg="2" sm="6" class="col-6 col-12-response-edit-abs d-flex align-items-center justify-content-start" style="white-space: nowrap;">
                        <div style="display: flex;">
                          <b-form-radio :value="employeeStatus.lapakron" :disabled="isDisable" v-model="absenceData.typeLeave" class="radio-disable" name="redio-button2"></b-form-radio>
                          <b-text class="margin-response-adduser check" style="color: black;">ลาพักร้อน</b-text>
                        </div>
                      </b-col>
                      <b-col lg="2" sm="6" class="col-6 col-12-response-edit-abs d-flex align-items-center justify-content-start" style="white-space: nowrap;">
                        <div style="display: flex;">
                          <b-form-radio :value="employeeStatus.lakit" :disabled="isDisable" v-model="absenceData.typeLeave" class="radio-disable" name="redio-button2"></b-form-radio>
                          <b-text class="margin-response-adduser check" style="color: black;">ลากิจ</b-text>
                        </div>
                      </b-col>
                      <b-col lg="2" sm="6" class="col-6 col-12-response-edit-abs d-flex align-items-center justify-content-start" style="white-space: nowrap;">
                        <div style="display: flex;">
                          <b-form-radio :value="employeeStatus.lasick" :disabled="isDisable" v-model="absenceData.typeLeave" class="radio-disable" name="redio-button2"></b-form-radio>
                          <b-text class="margin-response-adduser check" style="color: black;">ลาป่วย</b-text>
                        </div>
                      </b-col>
                      <b-col lg="2" sm="6" class="col-6 col-12-response-edit-abs d-flex align-items-center justify-content-start" style="white-space: nowrap;">
                        <div style="display: flex;">
                          <b-form-radio :value="employeeStatus.workout" :disabled="isDisable" v-model="absenceData.typeLeave" class="radio-disable" name="redio-button2"></b-form-radio>
                          <b-text class="margin-response-adduser check" style="color: black;">ปฏิบัติงานนอกสถานที่</b-text>
                        </div>
                      </b-col>
                    </b-row>
                    <br>
                    <b-row >
                      <b-col lg="8" sm="12" md="10" class="col-12 padding-custom-pj-details" >
                        <b-form-group class="unselectable" label="" style="color: black;">
                            <b-text class="" style="color: black;">รายละเอียด <span v-if="!absenceData.details" style="color: red;">*</span></b-text>
                            <b-form-textarea :disabled="isDisable" id="" v-model="absenceData.details" type="text" style="padding-right: 1rem; height: 100px; justify-items: flex-start;"></b-form-textarea>
                          </b-form-group>
                      </b-col>
                    </b-row>
                    <br>
                    <b-row >
                      <b-col lg="2" sm="6" class="col-6 col-12-response-edit-abs d-flex align-items-center justify-content-start" style="white-space: nowrap;">
                        <div style="display: flex;">
                          <b-form-radio :value="employeeLeaveStatus.dayShift" :disabled="isDisable" v-model="absenceData.typeRangeLeave" class="radio-disable" name="redio-button"></b-form-radio>
                          <b-text class="margin-response-adduser check" style="color: black;">ลาทั้งวัน</b-text>
                        </div>
                      </b-col>
                      <b-col lg="2" sm="6" class="col-6 col-12-response-edit-abs d-flex align-items-center justify-content-start" style="white-space: nowrap;">
                        <div style="display: flex;">
                          <b-form-radio :value="employeeLeaveStatus.dayShiftTime" :disabled="isDisable" v-model="absenceData.typeRangeLeave" class="radio-disable" name="redio-button"></b-form-radio>
                          <b-text class="margin-response-adduser check" style="color: black;">ลาช่วงเวลา</b-text>
                        </div>
                      </b-col>
                      <b-col lg="2" sm="6" class="col-6 col-12-response-edit-abs d-flex align-items-center justify-content-start" style="white-space: nowrap;">
                        <div style="display: flex;">
                          <b-form-radio :value="employeeLeaveStatus.morningShift" :disabled="isDisable" v-model="absenceData.typeRangeLeave" class="radio-disable" name="redio-button"></b-form-radio>
                          <b-text class="margin-response-adduser check" style="color: black;">ลาครึ่งเช้า</b-text>
                        </div>
                      </b-col>
                      <b-col lg="2" sm="6" class="col-6 col-12-response-edit-abs d-flex align-items-center justify-content-start" style="white-space: nowrap;">
                        <div style="display: flex;">
                          <b-form-radio :value="employeeLeaveStatus.afternoonShift" :disabled="isDisable" v-model="absenceData.typeRangeLeave" class="radio-disable" name="redio-button"></b-form-radio>
                          <b-text class="margin-response-adduser check" style="color: black;">ลาครึ่งบ่าย</b-text>
                        </div>
                      </b-col>
                    </b-row>
                    <br>
                    <b-row class="d-flex align-items-center">
                      <b-col lg="4" sm="6">
                        <b-text style="color: black;">วันที่เริ่มลา {{ absenceData.typeRangeLeave === employeeLeaveStatus.dayShiftTime ? ' - เวลาเริ่มต้น' : '' }} <span v-if="!absenceData.dateTimeStart" style="color: red;">*</span></b-text>
                          <div style="position: relative;">
                            <date-picker v-if="absenceData.typeRangeLeave === employeeLeaveStatus.dayShiftTime" placeholder="DD/MM/YYYY HH:mm" id="datepicker-custom3" name="date" v-model="absenceData.dateTimeStart" :disabled="isDisable" :config="options"></date-picker>
                            <date-picker v-else placeholder="DD/MM/YYYY" id="datepicker-custom3" name="date" v-model="absenceData.dateTimeStart" :disabled="isDisable" :config="options2"></date-picker>
                            <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(3)" :disabled="isDisable" style="cursor: pointer;"></i>
                          </div>
                      </b-col>
                      <b-col lg="4" sm="6">
                        <b-text style="color: black;">วันที่สิ้นสุดการลา {{ absenceData.typeRangeLeave === employeeLeaveStatus.dayShiftTime ? ' - เวลาสิ้นสุด' : '' }} <span v-if="!absenceData.dateTimeEnd" style="color: red;">*</span></b-text>
                          <div style="position: relative;">
                            <date-picker v-if="absenceData.typeRangeLeave === employeeLeaveStatus.dayShiftTime" placeholder="DD/MM/YYYY HH:mm" id="datepicker-custom4" name="date" v-model="absenceData.dateTimeEnd" :disabled="isDisable === false ? absenceData.dateTimeStart === null ? true : false : true" :config="optionsEnd"></date-picker>
                            <date-picker v-else placeholder="DD/MM/YYYY" id="datepicker-custom4" name="date" v-model="absenceData.dateTimeEnd" :disabled="isDisable === false ? absenceData.dateTimeStart === null ? true : false : true" :config="options2End"></date-picker>
                            <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(4)" :disabled="isDisable" style="cursor: pointer;"></i>
                          </div>
                      </b-col>
                    </b-row>
                    <br>
                    <b-row >
                      <b-col lg="4" sm="12" class="d-flex custom-flex-abs" style="white-space: nowrap;">
                        <b-text style="color: black">แนบไฟล์ที่เกี่ยวข้อง</b-text>
                        <b-form-file
                        class="custom-file-style"
                        ref="imageInputRef"
                        id="imageInput"
                        accept="image/*"
                        @change="handleImageUpload"
                        :disabled="isDisable"
                        :placeholder="absenceData.file ? absenceData.file : ''"
                        browse-text="เลือกไฟล์"
                      ></b-form-file>
                      </b-col>
                    </b-row>
                    <br>
                  </div>
                </div>
              <template #modal-footer>
            <div class="w-100">
                  <p class="float-left"></p>
                  <b-button
                    variant="none"
                    size="sm"
                    class="iq-bg-danger float-right btn-min-size btn-cancel-border"
                    style="border-radius: 0.5rem;"
                    @click="closeModal()"
                  >
                  ยกเลิก
              </b-button>
            </div>
              </template>
            </b-modal>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.margin-res-edit-absenceT {
  margin-top: 0rem !important;
}
.pt-4-edit-res-absenceT {
  padding-top: .5rem !important;
}

@media (max-width: 992px) {
  .pt-4-edit-res-absenceT {
    padding-top: .25rem !important;
  }
  .margin-res-edit-absenceT {
    margin-top: 0 !important;
  }
  .margin-edit-modal-absenceT {
    margin-top: 1rem !important;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import moment from 'moment'
import Vue from 'vue'
import datePicker from 'vue-bootstrap-datetimepicker'
import axios from 'axios'
import { configAPI } from '../../configBase'
import 'moment/locale/th'
const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}

export default {
  name: 'AbsenceTableMaster',
  components: {
    datePicker
  },
  watch: {
    'searchData.year': function (newVal, oldVal) {
      if (newVal === null && oldVal !== null) {
        const yearCurrent = this.getYearCurrent()
        this.searchData.year = { code: yearCurrent, label: (yearCurrent + 543) }
      }
    },
    'absenceData.dateTimeStart': function (newVal, oldVal) {
      if (/* newVal !== null &&  */oldVal !== null && !this.is_edit) {
        // console.log('newVal', newVal)
        this.absenceData.dateTimeEnd = null
      }
      this.is_edit = false
      this.optionsEnd.minDate = new Date(moment(newVal, 'DD/MM/YYYY HH:mm'))
      this.options2End.minDate = new Date(moment(newVal, 'DD/MM/YYYY'))
    },
    'modalSearchData.dateTimeStart': function (newVal, oldVal) {
      if (newVal === null && oldVal !== null) {
        this.modalSearchData.dateTimeEnd = null
      } else {
        const start_ = moment(new Date(moment(newVal, 'DD/MM/YYYY')))
        const end_ = moment(new Date(moment(this.modalSearchData.dateTimeEnd, 'DD/MM/YYYY')))
        if (end_.diff(start_, 'd') < 0) {
          this.modalSearchData.dateTimeEnd = newVal
        }
        this.options2Modal.minDate = new Date(moment(newVal, 'DD/MM/YYYY'))
      }
    },
    'modalSearchData.dateTimeEnd': function (newVal, oldVal) {
      this.optionsModal.maxDate = new Date(moment(newVal, 'DD/MM/YYYY'))
    },
    'modalSearchData.typeLeave': function (newVal, oldVal) {
      if (!newVal) {
        this.modalSearchData.typeLeave = { code: null, label: 'ทั้งหมด' }
      }
    }
  },
  computed: {
    sharedData () {
      return this.$store.state.sharedData
    },
    getCurrentYear () {
      return (parseInt(moment(new Date()).format('YYYY')) + 543) + ''
    }
  },
  async mounted () {
    this.cln_id = JSON.parse(sessionStorage.getItem('user')).cln_id
    await this.setYearSearch()
    await this.handleClickSearch()
    xray.index()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.handleClickSearch()
      }
    },
    async GetLeaveSummaryOfEmployeeById () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/employee/${this.EmpModal.id}/dayOff/?year=${this.getYearCurrent() || ''}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status) {
            const data = response.data.data.length > 0 ? response.data.data[0] : {}
            this.summaryLeave = {
              balance: {
                sick_leave: data.sick_leave || 0,
                business_leave: data.person_leave || 0,
                annual_leave: data.vacation_leave || 0
              },
              entitlement: {
                sick_leave: data.sick || 0,
                business_leave: data.person || 0,
                annual_leave: data.vacation || 0
              }
            }
            // console.log('this.enti', this.entitlement)
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async GetLeaveAllOfEmp (emp) {
      this.EmpModal = {
        name: emp.variable2,
        id: emp.id
      }
      this.modalSearchData.typeLeave = { code: null, label: 'ทั้งหมด' }
      this.modalSearchData.dateTimeStart = moment().startOf('year').format('DD/MM/YYYY')
      this.modalSearchData.dateTimeEnd = moment().endOf('year').format('DD/MM/YYYY')
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/employee/${emp.id}/leave/`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          const arrTemp = []
          if (response.data.status) {
            const resList = response.data.data/* .sort((a, b) => {
              return (new Date(a.start_date) - new Date(b.start_date))
            }) */
            for (let index = 0; index < resList.length; index++) {
              const element = resList[index]
              const start = element.start_date ? moment(element.start_date).format('DD/MM/YYYY') : ''
              const end = element.end_date ? moment(element.end_date).format('DD/MM/YYYY') : ''
              let time = '-'
              if (element.els_id === this.employeeLeaveStatus.dayShiftTime && (element.start_time && element.end_time)) {
                // time = moment(element.start_date).format('HH:mm') + ' - ' + moment(element.end_date).format('HH:mm')
                time = moment(element.start_time).format('HH:mm') + ' - ' + moment(element.end_time).format('HH:mm')
              }
              arrTemp.push({
                ...element,
                index: (index + 1),
                variable2: start + ' - ' + end,
                variable3: time,
                variable4: element.es_name,
                variable5: element.els_name,
                variable6: element.detail || null,
                variable7: element.doc_path ? this.getFullImagePath(element.doc_path) : null,
                fileName: element.doc_name || null,
                start_date_format: start,
                end_date_format: end
              })
            }
            this.rows2All = arrTemp
            this.rows2 = this.filterRowsByTime()
            await this.GetLeaveSummaryOfEmployeeById()
            this.modeTable = true
            this.showModal = true
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    getFullImagePath (relativePath) {
      if (relativePath) {
        relativePath = relativePath.substring(1)
      }
      const baseURL = `${configAPI.MA_PORT}`
      return baseURL + relativePath
    },
    async handleClickSearch () {
      let manageClinicAPI = {
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/leave/summary?year=${this.searchData.year?.code || ''}&name=${this.searchData.nameEmp?.trim() || ''}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(manageClinicAPI)
        .then((response) => {
          const arrTemp = []
          if (response.data.status && response.data.data.length > 0) {
            for (let index = 0; index < response.data.data.length; index++) {
              const element = response.data.data[index]
              arrTemp.push({
                id: element.emp_id,
                index: (index + 1),
                variable2: (element.name + '\t' + element.lastname),
                variable3: element.total_annual_leave || 0,
                variable4: element.total_sick_leave || 0,
                variable5: element.total_business_leave || 0
              })
            }
          }
          this.rows = arrTemp
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    onClickDatePicker (id) {
      const datePicker = document.getElementById('datepicker-custom' + id)
      datePicker.focus()
    },
    closeModal () {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการออกจากหน้านี้ใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.handleClickSearch()
          this.showModal = false
        }
      })
    },
    remove (item) {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการลบข้อมูลใช่หรือไม่?</span>',
        text: 'ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.removeEmpLeave(item)
        }
      })
    },
    async removeEmpLeave (item) {
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/employee/${this.EmpModal.id}/leave/${item.id}/delete/`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(async () => {
              let index = this.rows2.indexOf(item)
              let index2 = this.rows2All.indexOf(item)

              if (index2 >= 0) await this.GetLeaveSummaryOfEmployeeById()

              this.rows2.splice(index, 1)
              this.rows2All.splice(index2, 1)
            })
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'ลบข้อมูลไม่สำเร็จ')
        })
    },
    async downloadFile (items) {
      await axios({
        url: this.getFullImagePath(items.doc_path),
        method: 'GET',
        responseType: 'blob'
      })
        .then((response) => {
          let fileUrl = window.URL.createObjectURL(new Blob([response.data]))
          let fileLink = document.createElement('a')
          fileLink.href = fileUrl

          fileLink.setAttribute('download', items.doc_name)
          document.body.appendChild(fileLink)

          fileLink.click()
        })
        .catch(() => {
          this.SwalError('ไม่พบไฟล์นี้ในฐานข้อมูล', 'ดาวน์โหลดเอกสารไม่สำเร็จ')
        })
    },
    onPagination (page, isMain) {
      if (isMain) {
        this.currentPageMain = page
      } else {
        this.currentPage = page
      }
    },
    handleClick (setPage, data) {
      // this.updateDataAsync()
      this.$store.dispatch('updateSharedData', data.variable2)
      router.push(`/Absence/${setPage}/${data.id}`)
      /* if (setPage === 'view') {
      } else if (setPage === 'edit') {
        router.push(`/Absence/${setPage}/${data.id}`)
      } */
    },
    handleClickModal (status) {
      if (!status) {
        if (this.mode === 'view') {
          router.go(-1)
        } else {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะยกเลิกการแก้ไขใช่หรือไม่?</span>',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
            confirmButtonColor: '#089bab',
            cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
            cancelButtonColor: '#d33',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1',
              cancelButton: 'custom-confirm-button-class mx-1'
            }
          }).then((result) => {
            if (result.isConfirmed) {
              this.absenceData.dateTimeStart = null
              this.modeTable = true
            }
          })
        }
      }
    },
    editLeaveModal (item, idx) {
      this.is_edit = true
      this.absenceData.id = item.id
      this.absenceData.index = idx
      // this.absenceData.dateTimeStart = moment(item.start_date).format(item.els_id === this.employeeLeaveStatus.dayShiftTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY')
      this.absenceData.dateTimeStart = (item.els_id === this.employeeLeaveStatus.dayShiftTime)
        ? item.start_time ? moment(item.start_time).format('DD/MM/YYYY HH:mm') : null
        : moment(item.start_date).format('DD/MM/YYYY')
      // this.absenceData.dateTimeEnd = moment(item.end_date).format(item.els_id === this.employeeLeaveStatus.dayShiftTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY')
      this.absenceData.dateTimeEnd = (item.els_id === this.employeeLeaveStatus.dayShiftTime)
        ? item.end_time ? moment(item.end_time).format('DD/MM/YYYY HH:mm') : null
        : moment(item.end_date).format('DD/MM/YYYY')
      this.absenceData.details = item.detail
      this.absenceData.typeLeave = item.es_id
      this.absenceData.typeRangeLeave = item.els_id
      this.absenceData.file = item.variable7 ? item.doc_name : null
      this.modeTable = false
    },
    updateLeaveModal () {
      // console.log('new Date(moment(this.absenceData.dateTimeStart, this', new Date(moment(this.absenceData.dateTimeStart, this.absenceData.typeRangeLeave === this.employeeLeaveStatus.dayShiftTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY')))
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะแก้ไขข้อมูลการทำงานใช่หรือไม่?</span>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          const errorList = await this.checkError()
          if (errorList.length > 0) {
            let errMessage = ''
            for (let e = 0; e < errorList.length; e++) {
              errMessage += '<br/>' + errorList[e]
            }
            this.SwalError(errMessage, 'บันทึกข้อมูลไม่สำเร็จ')
            return
          }
          await this.EditTimeWorking()
        }
      })
    },
    async EditTimeWorking () {
      Vue.swal.fire({
        title: '<span class="loading-icon"><i class="fas fa-spinner fa-spin"></i></span>',
        text: 'กำลังประกาศหางาน...',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          Vue.swal.getHtmlContainer().querySelector('.loading-icon').style.fontSize = '40px'
        }
      })
      await axios.request({
        method: 'put',
        url: `${configAPI.MA_PORT}/api/employee/${this.EmpModal.id}/leave/${this.absenceData.id}/`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          cln_id: this.cln_id,
          emp_id: this.EmpModal.id,
          es_id: this.absenceData.typeLeave,
          els_id: this.absenceData.typeRangeLeave,
          start_date: new Date(moment.utc(this.absenceData.dateTimeStart, 'DD/MM/YYYY')),
          end_date: new Date(moment.utc(this.absenceData.dateTimeEnd, 'DD/MM/YYYY')),
          detail: this.absenceData.details?.trim() || null,
          start_time: this.absenceData.typeRangeLeave === this.employeeLeaveStatus.dayShiftTime ? new Date(moment(this.absenceData.dateTimeStart, 'DD/MM/YYYY HH:mm')) : null,
          end_time: this.absenceData.typeRangeLeave === this.employeeLeaveStatus.dayShiftTime ? new Date(moment(this.absenceData.dateTimeEnd, 'DD/MM/YYYY HH:mm')) : null
        }
      })
        .then(async (response) => {
          Vue.swal.close()
          if (response.data.status) {
            if (this.absenceData.file && typeof this.absenceData.file !== 'string') {
              await this.uploadFileLeaveEmployee()
            }
            await this.GetLeaveAllOfEmp({ variable2: this.EmpModal.name, id: this.EmpModal.id })
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">แก้ไขข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              this.modeTable = true
            })
          }
        })
        .catch((error) => {
          Vue.swal.close()
          this.SwalError(error.message, 'แก้ไขข้อมูลไม่สำเร็จ')
        })
    },
    handleImageUpload (event) {
      const file2 = event.target.files[0]
      this.absenceData.file = file2
    },
    async uploadFileLeaveEmployee () {
      let data = new FormData()
      data.append('file', this.absenceData.file)
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/leave/${this.absenceData.id}/upload/doc`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
        .then(async (response) => {
        })
        .catch((error) => {
          this.SwalError(error.message, 'บันทึกรูปภาพไม่สำเร็จ')
        })
    },
    filterRowsByTime () {
      const filterStartDate = new Date(moment(this.modalSearchData.dateTimeStart, 'DD/MM/YYYY'))
      const filterEndDate = new Date(moment(this.modalSearchData.dateTimeEnd, 'DD/MM/YYYY'))
      return this.rows2All.filter(row => {
        const rowDate = new Date(moment(row.start_date_format, 'DD/MM/YYYY'))

        return (rowDate >= filterStartDate && rowDate <= filterEndDate && (row.es_id === this.modalSearchData.typeLeave.code || this.modalSearchData.typeLeave.code === null))
      })
    },
    onClickSearchModal () {
      this.rows2 = this.filterRowsByTime()
    },
    async onClickClear (status) {
      if (status) {
        this.searchData.year = { code: this.getYearCurrent(), label: (this.getYearCurrent() + 543) }
        this.searchData.nameEmp = ''
        await this.handleClickSearch()
      } else {
        this.modalSearchData.typeLeave = { code: null, label: 'ทั้งหมด' }
        this.modalSearchData.dateTimeEnd = moment().endOf('year').format('DD/MM/YYYY')
        this.modalSearchData.dateTimeStart = moment().startOf('year').format('DD/MM/YYYY')
        this.rows2 = this.filterRowsByTime()
      }
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    getYearCurrent () {
      return (parseInt(moment(new Date()).format('YYYY')))
    },
    async setYearSearch () {
      const curentYear = this.getYearCurrent()
      let _10yearBefore = (curentYear - 5)
      for (let index = 1; index <= 10; index++) {
        this.items1.push({
          code: _10yearBefore,
          label: (_10yearBefore + 543)
        })
        if (_10yearBefore === curentYear) {
          this.searchData.year = { code: _10yearBefore, label: (_10yearBefore + 543) }
        }
        _10yearBefore += 1
      }
    },
    async checkError () {
      let error = []
      if (!this.absenceData.typeLeave) {
        error.push('กรุณาเลือกประเภทการลา')
      }
      if (!this.absenceData.details?.trim()) {
        error.push('กรุณากรอกรายละเอียด')
      }
      if (!this.absenceData.typeRangeLeave) {
        error.push('กรุณาเลือกช่วงเวลาที่จะลา')
      }
      if (!this.absenceData.dateTimeStart) {
        error.push('กรุณาเลือกวันที่เริ่มลา')
      }
      if (!this.absenceData.dateTimeEnd) {
        error.push('กรุณาเลือกวันที่สิ้นสุดการลา')
      }

      /* if (this.absenceData.typeLeave && this.absenceData.typeRangeLeave &&
      this.absenceData.dateTimeStart && this.absenceData.dateTimeEnd
      ) {
        const errorStr = await this.CalRangeTime()
        if (errorStr) {
          error.push(errorStr)
        }
      } */
      return error
    },
    async CalRangeTime () {
      let str = ''
      let sum = 1
      if (this.absenceData.typeRangeLeave === this.employeeLeaveStatus.afternoonShift ||
      this.absenceData.typeRangeLeave === this.employeeLeaveStatus.morningShift
      ) {
        sum = 0.5
      }

      if (this.absenceData.typeLeave === this.employeeStatus.lapakron) {
        if (this.summaryLeave.balance.annual_leave === 0 || (this.summaryLeave.balance.annual_leave < (this.GetRangeTime() * sum))) {
          str = 'สิทธิการลาพักร้อนคงเหลือน้อยกว่าจำนวนวันที่ลา'
        }
      } else if (this.absenceData.typeLeave === this.employeeStatus.lakit) {
        if (this.summaryLeave.balance.business_leave === 0 || (this.summaryLeave.balance.business_leave < (this.GetRangeTime() * sum))) {
          str = 'สิทธิการลากิจคงเหลือน้อยกว่าจำนวนวันที่ลา'
        }
      } else if (this.absenceData.typeLeave === this.employeeStatus.lasick) {
        if (this.summaryLeave.balance.sick_leave === 0 || (this.summaryLeave.balance.sick_leave < (this.GetRangeTime() * sum))) {
          str = 'สิทธิการลาป่วยคงเหลือน้อยกว่าจำนวนวันที่ลา'
        }
      }
      return str
    },
    GetRangeTime () {
      return (moment(this.absenceData.dateTimeEnd, 'DD/MM/YYYY').diff(moment(this.absenceData.dateTimeStart, 'DD/MM/YYYY'), 'days'))
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    }
  },
  data () {
    return {
      is_edit: true,
      EmpModal: {
        name: '',
        id: ''
      },
      mode: 'showAll',
      cln_id: '',
      isDisable: false,
      selectedOption: null,
      summaryLeave: {
        balance: {
          sick_leave: 0,
          business_leave: 0,
          annual_leave: 0
        },
        entitlement: {
          sick_leave: 0,
          business_leave: 0,
          annual_leave: 0
        }
      },
      modeTable: true,
      modalSearchData: {
        dateTimeStart: null,
        dateTimeEnd: null,
        typeLeave: { code: null, label: 'ทั้งหมด' }
      },
      searchData: {
        year: '',
        nameEmp: ''
      },
      absenceData: {
        id: null,
        details: '',
        typeLeave: null,
        typeRangeLeave: null,
        dateTimeStart: null,
        dateTimeEnd: null,
        file: null,
        dayShift: false,
        dayShiftTime: false,
        morningShift: false,
        afternoonShift: false
      },
      options: {
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        icons: iconDate
      },
      optionsEnd: {
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        minDate: false,
        icons: iconDate
      },
      options2: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        icons: iconDate
      },
      optionsModal: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: false,
        showClose: true,
        useStrict: true,
        maxDate: false,
        icons: iconDate
      },
      options2Modal: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: false,
        showClose: true,
        useStrict: true,
        minDate: false,
        icons: iconDate
      },
      options2End: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        minDate: false,
        icons: iconDate
      },
      items1: [
      ],
      employeeStatus: {
        lakit: '3ba75645-e7a2-4ba8-9d04-ce767ced0876',
        lasick: 'e2882a4a-8877-44d7-8f07-f0fbba99a99c',
        lapakron: '7e7f89fa-c22c-478c-9d62-bfe45fdc8aa6',
        workout: 'c8a177b0-08fc-11ee-8530-53148e6b848d'
      },
      employeeLeaveStatus: {
        morningShift: '253e3639-065c-4b46-88ee-d47279b37e6b',
        dayShift: '4dac0add-831c-491c-8917-b8b1ce84c157',
        afternoonShift: 'fa61040e-435c-4fb2-ab68-187ffc2cc167',
        dayShiftTime: '6a944216-39f2-11ee-be56-0242ac120002'
      },
      leaveDropdown: [
        { code: null, label: 'ทั้งหมด' },
        { code: 'e2882a4a-8877-44d7-8f07-f0fbba99a99c', label: 'ลาป่วย' },
        { code: '7e7f89fa-c22c-478c-9d62-bfe45fdc8aa6', label: 'ลาพักร้อน' },
        { code: '3ba75645-e7a2-4ba8-9d04-ce767ced0876', label: 'ลากิจ' },
        { code: 'c8a177b0-08fc-11ee-8530-53148e6b848d', label: 'ปฏิบัติงานนอกสถานที่' }
      ],
      showModal: false,
      columns: [
        { label: 'ลำดับ', key: 'variable1', class: 'text-center' },
        { label: 'ชื่อ - นามสกุล', key: 'variable2', class: 'text-center px-4 table-size-200-400' },
        { label: 'พักร้อน', key: 'variable3', class: 'text-center table-size-200-400' },
        { label: 'ลาป่วย', key: 'variable4', class: 'text-center table-size-200-400' },
        { label: 'ลากิจ', key: 'variable5', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'variable6', class: 'text-center table-size-200-400' }
        /* { label: 'วันที่เข้าใช้ครั้งแรก', key: 'variable8', class: 'text-center table-size-200-400' },
        { label: 'ประวัติลูกค้าล่าสุด', key: 'variable9', class: 'text-center table-size-200-400' } */
      ],
      columns2: [
        { label: 'ลำดับ', key: 'variable1', class: 'text-center' },
        { label: 'ช่วงวันที่ลา', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'ช่วงเวลา', key: 'variable3', class: 'text-center table-size-200-400' },
        { label: 'ประเภทการลา', key: 'variable4', class: 'text-center table-size-200-400' },
        { label: 'เต็มวัน/ช่วงเวลา', key: 'variable5', class: 'text-center table-size-200-400' },
        { label: 'รายละเอียด', key: 'variable6', class: 'text-center table-size-100-300' },
        { label: 'เอกสาร', key: 'variable7', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'variable8', class: 'text-center table-size-100-300' }
      ],
      rows2All: [],
      rows2: [
        {
          id: '',
          index: 1,
          variable2: '07/06/2023 - 20/06/2023',
          variable3: '16:30 - 17:30',
          variable4: 'lapaklon',
          variable5: 'dayShiftTime',
          variable6: 'remark',
          variable7: '',
          start: '07/06/2023'
        },
        {
          id: '',
          index: 2,
          variable2: '08/06/2023 - 20/06/2023',
          variable3: '16:30 - 17:30',
          variable4: 'lapaklon',
          variable5: 'dayShiftTime',
          variable6: 'remark',
          variable7: 'testfile',
          start: '08/06/2023'
        }
      ],
      rows: [],
      currentPage: 1,
      currentPageMain: 1,
      perPage: 10
    }
  }
}
</script>
