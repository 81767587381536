<template>
  <b-container fluid>
    <a href="#" @click.prevent="getData">
      <span style="text-decoration: underline;">{{ label }}</span>
    </a>

    <b-modal v-model="openModal" title="ข้อมูลหมอ" cancel-title="เพิ่ม" ok-title="ยกเลิก" cancel-variant="primary"
      ok-variant="danger" @close.prevent="openModal = false" :no-close-on-esc="false" :no-close-on-backdrop="false"
      size="xl" hide-footer>
      <b-row>
        <b-col cols="12" md="4">
          <b-row style="align-items: center; margin: 0px;">
            <b-col cols="12" xl="auto" style="text-align: center; margin: 0 0 10px 0;">
              <img style="width: 60px; aspect-ratio: 1/1; border-radius: 100%; object-fit: contain;" :src="getFullImagePath(doctorDataModal.person_path)">
            </b-col>
            <b-col cols style="margin: 0 0 10px 0;">
              <span style="color: black; font-size: medium; font-weight: 500; word-break: break-word;">{{ doctorDataModal.name || '-' }}</span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12">
              <span style="display: block; margin: 10px 0 0 0;">รูปบัตรประชาชน</span>
              <div v-if="doctorDataModal.citizen_path !== null" style="margin: 0 0 10px 0;">
                <a style="cursor: pointer; display: block; word-break: break-word;" @click="downloadFile(doctorDataModal.citizen_path, doctorDataModal.citizen_img)">
                  <span style="color: #089bab; text-decoration: underline solid 1px #089bab;">{{doctorDataModal.citizen_img}}</span>
                </a>
              </div>
              <span v-else style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;"> - </span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12">
              <span style="display: block; margin: 10px 0 0 0;">รูปหน้าบัญชี</span>
              <div v-if="doctorDataModal.account_path !== null" style="margin: 0 0 10px 0;">
                <a style="cursor: pointer; display: block; word-break: break-word;" @click="downloadFile(doctorDataModal.account_path, doctorDataModal.account_img)">
                  <span style="color: #089bab; text-decoration: underline solid 1px #089bab;">{{doctorDataModal.account_img}}</span>
                </a>
              </div>
              <span v-else style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;"> - </span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12">
              <span style="display: block; margin: 10px 0 0 0;">รูปใบประกอบวิชาชีพ</span>
              <div v-if="doctorDataModal.specialized_path !== null" style="margin: 0 0 10px 0;">
                <a style="cursor: pointer; display: block; word-break: break-word;" @click="downloadFile(doctorDataModal.specialized_path, doctorDataModal.specialized_img)" >
                  <span style="color: #089bab; text-decoration: underline solid 1px #089bab;">{{doctorDataModal.specialized_img}}</span>
                </a>
              </div>
              <span v-else style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;"> - </span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12">
              <span style="display: block; margin: 10px 0 0 0;">เลขบัตรประจำตัวประชาชน</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ doctorDataModal.citizen_id || '-' }}</span>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12" md="auto">
          <hr class="separate_line" />
        </b-col>

        <b-col cols="12" md>
          <b-row style="margin: 0px;">
            <b-col cols="12" style="margin: 16px 0 25px 0;">
              <span style="color: black; font-size: medium; font-weight: 500; word-break: break-word;">ข้อมูลส่วนบุคคล</span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">เพศ</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ doctorDataModal.gender_name || '-' }}</span>
            </b-col>
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">อายุ</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ doctorDataModal.age || '-' }}</span>
            </b-col>
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">เลขที่ใบประกอบวิชาชีพ</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ doctorDataModal.no || '-' }}</span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">สาขาเฉพาะทาง</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ doctorDataModal.specialized || '-' }}</span>
            </b-col>
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">เบอร์โทรศัพท์ติดต่อ</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ doctorDataModal.phone || doctorDataModal.tel || '-' }}</span>
            </b-col>
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">Email</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ doctorDataModal.email || '-' }}</span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">พื้นที่สะดวกรับงาน</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ doctorDataModal.aw_name || '-' }}</span>
            </b-col>
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">เลขบัญชีสำหรับโอนเงิน</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ doctorDataModal.account_no || '-' }}</span>
            </b-col>
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">ธนาคาร</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ doctorDataModal.bank_name || '-' }}</span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">ชื่อบัญชี</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ doctorDataModal.account_name || '-' }}</span>
            </b-col>
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">ประเภทงานที่รับ</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ doctorDataModal.is_receive || '-' }}</span>
            </b-col>
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">มาตรฐานรับงาน</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ doctorDataModal.standart_work || '-' }}</span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">ไม่รับทำบริเวณ</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ doctorDataModal.no_area || '-' }}</span>
            </b-col>
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">ช่วงเวลาที่สะดวกรับงาน</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ doctorDataModal.work_range || '-' }}</span>
            </b-col>
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">ค่าเวรขั้นต่ำที่รับ</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ doctorDataModal.mi_name || '-' }}</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>

<style scoped>
.separate_line {
  margin: 0 10px 0 10px;
  height: 100%;
  width: 1px;
  background-color: #a09e9e;
}

@media (max-width: 767px) {
  .separate_line {
    margin: 20px 0 10px 0;
    height: 1px;
    width: 100%;
    background-color: #a09e9e;
  }
}
</style>

<script>
import { xray } from '../../../config/pluginInit'
// import router from '@/router'
import Vue from 'vue'
import axios from 'axios'
// import moment from 'moment'
import { configAPI } from '../../../configBase'

export default {
  name: 'doctorModal',
  props: {
    id: { type: String },
    label: { type: String }
  },
  data () {
    return {
      openModal: false,
      doctorDataModal: {
        name: '',
        citizen_id: '',
        gender_name: '',
        age: '',
        no: '',
        specialized: '',
        phone: '',
        email: '',
        aw_name: '',
        account_no: '',
        bank_name: '',
        account_name: '',
        is_receive: '',
        standart_work: '',
        no_area: '',
        work_range: '',
        mi_name: '',
        person_img: '',
        person_path: '',
        account_img: '',
        account_path: '',
        citizen_img: '',
        citizen_path: '',
        specialized_img: '',
        specialized_path: ''
      }
    }
  },
  computed: {},
  async mounted () {
    xray.index()
  },
  methods: {
    async getData () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/doctor/${this.id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        // console.log(response.data.data)
        let tempData = response.data.data[0]
        this.doctorDataModal = {
          name: tempData.name,
          citizen_id: tempData.citizen_id,
          gender_name: tempData.gender_name,
          age: tempData.age,
          no: tempData.no,
          specialized: tempData.specialized,
          phone: tempData.phone,
          tel: tempData.tel,
          email: tempData.email,
          aw_name: tempData.aw_name,
          account_no: tempData.account_no,
          bank_name: tempData.bank_name,
          account_name: tempData.account_name,
          is_receive: tempData.is_receive,
          standart_work: tempData.standart_work,
          no_area: tempData.no_area,
          work_range: tempData.work_range,
          mi_name: tempData.mi_name,
          person_img: tempData.person_img,
          person_path: tempData.person_path,
          account_img: tempData.account_img,
          account_path: tempData.account_path,
          citizen_img: tempData.citizen_img,
          citizen_path: tempData.citizen_path,
          specialized_img: tempData.specialized_img,
          specialized_path: tempData.specialized_path
        }
      })
      this.openModal = true
    },
    getFullImagePath (relativePath) {
      if (relativePath) {
        let path = relativePath !== null ? relativePath.slice(1) : ''
        const baseURL = configAPI.MA_PORT + path // 'http://150.95.25.8:8112' + path
        return baseURL
      } else {
        return 'https://www.w3schools.com/howto/img_avatar.png'
      }
    },
    async downloadFile (pathFile, nameFile) {
      let pathApi = configAPI.MA_PORT + pathFile.slice(1) // 'http://150.95.25.8:8112' + pathFile.slice(1)
      await axios({
        method: 'get',
        url: pathApi,
        responseType: 'blob'
      })
        .then((response) => {
          let fileUrl = window.URL.createObjectURL(new Blob([response.data]))
          let fileLink = document.createElement('a')
          fileLink.href = fileUrl

          fileLink.setAttribute('download', nameFile)
          document.body.appendChild(fileLink)

          fileLink.click()
        })
        .catch(() => {
          this.SwalError('ไม่พบไฟล์นี้ในฐานข้อมูล', 'ดาวน์โหลดเอกสารไม่สำเร็จ')
        })
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span style="display: block; margin: 10px 0 0 0;">' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    }
  }
}
</script>
