<template>
    <b-container fluid>
        <div class="mx-3">
            <b-row>
              <b-col sm="12" md="7" lg="5" class="mb-3 p-0 mr-3">
                  <div class="iq-email-search d-flex">
                    <div style="width: 100%;">
                      <div class="form-group mb-0">
                        <input class="form-control" id="searchMaterial" @keypress="enterSearch" placeholder="Search" style="width: 100%;" v-model="search">
                        <a class="search-link" style="color: #024187;" @click="filterRawMat" ><i class="ri-search-line"/></a>
                      </div>
                    </div>
                  </div>
              </b-col>
              <b-col sm="12" md="3" lg="2" class="mb-3 p-0">
                <div class="iq-email-search d-flex btn-min-size">
                  <div style="width: 100%;">
                    <b-button variant="primary-custom" class="px-4" style="min-height: 40px" @click="clearfilterProduct">clear</b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
        </div>
        <br>
        <b-col cols="12" class="table-responsive p-0">
            <b-table bordered hover :items="rowsRawMat" :fields="columnsProduct"
                :current-page="currentPage"
                :per-page="perPage"
                @pagination="onPagination"
                :tbody-tr-class="rowClass" thead-class="table-header-color"
            >
            <template v-slot:cell(no)="data">
                <span class="unselectable">{{ ((currentPage - 1) * perPage) + data.index + 1 }}</span>
            </template>
            <template v-slot:cell(category)="data">
                <span>{{ data.item.mg_name || '-' }}</span>
            </template>
            <template v-slot:cell(brand)="data">
                <span>{{ data.item.mb_name || '-' }}</span>
            </template>
            <template v-slot:cell(unit)="data">
                <span>{{ data.item.unit_name || '-' }}</span>
            </template>
            <template v-slot:cell(product_img)="data">
                <b-img :src="formatImage(data.item.img_path)"
                style="height: 50px; width: 50px; object-fit: contain;"></b-img>
            </template>
            <template v-slot:cell(select)="data">
                <div style="display: flex; justify-content: center;">
                <b-button :variant="data.item.is_select ? 'danger' : 'primary-custom'" size="sm" @click="toggleAction(data.item)" style="width: 100px; border-radius: 10px;">
                    {{ data.item.is_select ? 'ยกเลิก' : 'เลือก' }}
                </b-button>
                </div>
            </template>
            </b-table>
        </b-col>
        <b-pagination v-model="currentPage" :total-rows="rowsRawMat?.length" :per-page="perPage" align="center" />
    </b-container>
</template>

<style scoped>
</style>

<script>
import Axios from 'axios'
import { xray } from '../../../config/pluginInit'
import Vue from 'vue'
import { configAPI } from '../../../configBase'
export default {
  name: 'rawMatSelectModalPO',
  props: {
    id: null,
    mainRawMatSelected: null,
    sup_id: null,
    poDelList: []
  },
  data () {
    return {
      cln_id: JSON.parse(sessionStorage.getItem('user')).cln_id,
      usr_id: JSON.parse(sessionStorage.getItem('user')).usr_id,
      search: '',
      openModal: false,
      currentPage: 1,
      perPage: 10,
      all: [],
      rowsRawMat: [],
      selectedTemp: [],
      selectedTemp2: [],
      columnsProduct: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        { label: 'หมวดหมู่', key: 'category', class: 'text-center table-size-200-400 ellips' },
        { label: 'แบรนด์', key: 'brand', class: 'text-center table-size-200-400 ellips' },
        { label: 'หน่วย', key: 'unit', class: 'text-center table-size-200-400 ellips' },
        { label: 'Barcode', key: 'product_img', class: 'text-center table-size-200-400' },
        { label: 'สถานะ', key: 'select', class: 'text-center table-size-200-400' }
      ]
    }
  },
  computed: {},
  async mounted () {
    await this.filterRawMat()
    // await this.getAllProduct()
    xray.index()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.filterRawMat()
      }
    },
    async clearfilterProduct () {
      this.search = ''
      await this.filterRawMat()
    },
    async getAllProduct () {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/product?search=`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        let tempData = res.data.data
        this.selectedTemp2 = [ ...this.selectedTemp ]
        let tempCheckSelect = [ ...this.mainRawMatSelected, ...this.selectedTemp2 ]
        const selectedTemp = []

        for (let item of tempData) {
          item.type = 'Product'
          item.oldNew = 'new'
          // item.status = 'add'
          const check = tempCheckSelect?.find((el) => {
            return el.id === item.id
          })
          if (check) {
            item.oldNew = 'old'
            item.poi_id = check.poi_id
            // item.status = 'update'
            item.is_select = true
            selectedTemp.push(item)
          } else {
            item.is_select = false
          }
        }
        this.all = tempData
        /* this.selectedTemp = tempData.filter((el) => {
          return el.is_select
        }) */
        const checkDup = {}
        this.selectedTemp = [ ...selectedTemp, ...this.selectedTemp2 ].filter(el => {
          if (!checkDup[el.id]) {
            checkDup[el.id] = el.id
            return el
          }
        })
      }).catch((err) => {
        this.SwalError(err, 'Error')
      })
    },
    async filterRawMat () {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/${this.sup_id}/material?search=${this.search || ''}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        let tempData = res.data.data
        this.selectedTemp2 = [ ...this.selectedTemp ]
        let tempCheckSelect = [ ...this.mainRawMatSelected, ...this.selectedTemp2 ]
        const selectedTemp = []

        for (let item of tempData) {
          // item.type = 'Product'
          item.oldNew = 'new'
          item.lp = item.ps_buy_price || 0
          // item.status = 'add'
          const check = tempCheckSelect?.find((el) => {
            return el.id === item.id
          })
          if (check) {
            item.oldNew = check?.poi_id ? 'old' : 'new'
            item.poi_id = check?.poi_id || null
            // item.status = 'update'
            item.is_select = true
            selectedTemp.push(item)
          } else {
            item.is_select = false
          }
        }
        this.rowsRawMat = tempData
        /* this.selectedTemp = tempData.filter((el) => {
          return el.is_select
        }) */
        const checkDup = {}
        this.selectedTemp = [ ...selectedTemp, ...this.selectedTemp2 ].filter(el => {
          if (!checkDup[el.id]) {
            checkDup[el.id] = el.id
            return el
          }
        })
      }).catch((err) => {
        this.SwalError(err, 'Error')
      })
    },
    submitAction () {
      // return { data: this.selectedTemp, delData: this.poDelList }
      return [ this.selectedTemp, this.poDelList ]
    },
    toggleAction (row) {
      let indexSelected = this.selectedTemp.findIndex(el => el.id === row.id)
      if (!row.is_select) {
        row.status = row?.poi_id ? 'update' : 'add'
        // this.poDelList = this.poDelList.filter(el => el !== (row?.poi_id || ''))
        if (indexSelected < 0) {
          this.selectedTemp.push(row)
        } else {
          this.selectedTemp[indexSelected] = row
        }
      } else {
        if (row.poi_id && (this.poDelList.find(el => el.poi_id === row.poi_id) === undefined)) {
          this.poDelList.push(row)
        }
        this.selectedTemp.splice(indexSelected, 1)
      }
      row.is_select = !row.is_select
    },
    onPagination (page) {
      this.currentPage = page
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    formatImage (ImgPath) {
      if (ImgPath) {
        let path = ImgPath !== null ? ImgPath.slice(1) : ''
        const baseURL = `${configAPI.MA_PORT}` + path
        return baseURL
      } else {
        return require('../../../assets/images/default.jpg')
      }
    }
  }
}
</script>
