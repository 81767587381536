<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 my-3">
              <div class="">
                <span v-if="setPage() === 'add'" style="font-size: 25px; color: black;">สรุปรายได้ และค่าใช้จ่าย
                  (เพิ่ม)</span>
                <span v-else-if="setPage() === 'edit'"
                  style="font-size: 25px; color: black;">สรุปรายได้ และค่าใช้จ่าย (แก้ไข)</span>
                <span v-else style="font-size: 25px; color: black;">สรุปรายได้ และค่าใช้จ่าย (ดูรายละเอียด)</span>
                <!-- <span class="px-3" style="font-size: 25px; color: black;">สรุปรายได้ และค่าใช้จ่าย</span> -->
              </div>
              <div class="d-flex flex-wrap my-1" style="white-space: wrap;">
                <div class="row align-items-center">
                </div>
                <div class="mx-3"></div>
                <a class="response-button-download1 mx-1" style="justify-content: center;">
                  <b-button
                    @click="showModal = true, resetSupplier(), checkmodal = 'add'"
                    :disabled="setPage() === 'view' ? true : false"
                    type="submit"
                    variant="primary"
                    class="my-1 flex-fill text-center response-button-download1 btn-min-size"
                    style="text-align: center;"
                  >
                    <i class="add-doc_ct"></i>เพิ่มรายการ
                  </b-button>
                </a>
                <div class="response-button-header1" style="justify-content: center;">
                  <b-button @click="beforeRefresh()" type="submit" variant="primary" class="mx-1 my-1 flex-fill text-center btn-min-size" style="text-align: center;"><i class="refresh_ct"></i>Refresh</b-button>
                  <b-button @click="generateExcel()" type="submit" variant="primary" class="mx-1 my-1 flex-fill text-center btn-min-size" style="text-align: center;"><i class="excel_ct"></i>Excel</b-button>
                  <b-button :disabled="setPage() === 'view' ? true : false" @click="handleClick(true)" type="submit" variant="primary" class="mx-1 my-1 flex-fill text-center btn-min-size" style="text-align: center;"><i class="save-data_ct"></i>บันทึก</b-button>
                  <b-button @click="handleClick(false)" type="submit" variant="none" class="mx-1 my-1 flex-fill iq-bg-danger btn-cancel-border text-center btn-min-size" style="text-align: center;">ยกเลิก</b-button>
                </div>
                  <div class="d-flex align-items-center newline ml-auto">
                    <span style="font-size: medium; color: black;">ค่าใช้จ่ายรวมทั้งหมด : {{ formatCurrency(sumTotal) }} บาท</span>
                  </div>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" class="table-responsive px-3">
                  <b-table bordered hover
                    :fields="columns2"
                    :items="costDetailData?.cost_detail_list"
                    :tbody-tr-class="rowClass"
                    thead-class="table-header-color"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @pagination="onPagination"
                  >
                    <template v-slot:thead-top="data">
                      <b-tr>
                        <b-th class="text-center" :colspan="12">ห้วงเวลา: {{ getCurrentDate(costDetailData?.start_date || '...') + ' - ' + getCurrentDate(costDetailData?.end_date || '...') }}</b-th>
                      </b-tr>
                      <b-tr>
                        <b-th class="text-center">วันที่</b-th>
                        <b-th class="text-center" colspan="2">รายการ</b-th>
                        <b-th class="text-center">จำนวนเงิน</b-th>
                        <b-th class="text-center">Grouping</b-th>
                        <b-th class="text-center">จัดการ</b-th>
                      </b-tr>
                    </template>
                    <template v-slot:cell(variable1)="data">
                      <span>{{ getCurrentDate(data.item.date || '') }}</span>
                    </template>
                    <template v-slot:cell(variable2)="data">
                      <span>{{ data.item.name || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable3)="data">
                      <span>{{ data.item.menu_name || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable4)="data">
                      <span>{{ Number(data.item.total) > 0 ? (data.item.is_cost ? "-" : "") + formatCurrency(data.item.total) : "0.00" }}</span>
                    </template>
                    <template v-slot:cell(variable5)="data">
                      <span>{{ data.item.cg_name || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable6)="data">
                      <div v-if="data.item.cg_id" style="display: flex; justify-content: center;">
                        <b-button variant=" iq-bg-primary mr-1" size="sm" @click="showModal = true, checkmodal = 'view', setEditCostDetail(data.item, data.index)">
                          <i class="ri-eye-fill m-0"></i>
                        </b-button>
                        <b-button v-if="setPage() === 'view'" :disabled="true" variant=" iq-bg-success mr-1" size="sm" @click="showModal = true, checkmodal = 'edit', setEditCostDetail(data.item, data.index)">
                          <i class="ri-ball-pen-fill m-0"></i>
                        </b-button>
                        <b-button v-else variant=" iq-bg-success mr-1" size="sm" @click="showModal = true, checkmodal = 'edit', setEditCostDetail(data.item, data.index)">
                          <i class="ri-ball-pen-fill m-0"></i>
                        </b-button>
                        <b-button v-if="setPage() === 'view'" :disabled="true" variant=" iq-bg-danger" size="sm" @click="remove(data.item)">
                          <i class="ri-delete-bin-line m-0"></i>
                        </b-button>
                        <b-button v-else variant=" iq-bg-danger" size="sm" @click="remove(data.item)">
                          <i class="ri-delete-bin-line m-0"></i>
                        </b-button>
                      </div>
                      <div v-else style="display: flex; justify-content: center;">
                        <b-button v-if="setPage() === 'view'" :disabled="true" variant=" iq-bg-danger" size="sm" @click="remove(data.item)">
                          <i class="ri-delete-bin-line m-0"></i>
                        </b-button>
                        <b-button v-else variant=" iq-bg-danger" size="sm" @click="remove(data.item)">
                          <i class="ri-delete-bin-line m-0"></i>
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                  <br>
                  <b-pagination v-model="currentPage" :total-rows="costDetailData?.cost_detail_list?.length" :per-page="perPage" align="center" />
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-bind:ok-only="checkmodal === 'view' ? true : false"
      v-model="showModal"
      :title="`เพิ่มรายการ (${checkmodal === 'view' ? 'ดูรายละเอียด' : checkmodal === 'add' ? 'เพิ่ม' : 'แก้ไข' })`"
    ><!-- :cancel-title="`${checkmodal === 'edit' ? 'แก้ไข' : 'บันทึก' }`"
      ok-title="ยกเลิก" -->
      <b-form-group label="รายการ" style="color: black;">
        <template v-if="costDetail.name === null || costDetail.name?.trim() === ''" v-slot:label>
          รายการ<span class="text-danger"> *</span>
        </template>
        <b-form-input :disabled="checkmodal === 'view' ? true : false" id="" v-model="costDetail.name" type="text"></b-form-input>
      </b-form-group>
      <b-form-group label="วันที่" style="color: black;">
        <template v-if="costDetail.date === null || costDetail.date === ''" v-slot:label>
          วันที่<span class="text-danger"> *</span>
        </template>
        <div style="position: relative;" class="">
          <date-picker placeholder="DD/MM/YYYY" id="datepicker-custom3" v-model="costDetail.date" :disabled="checkmodal === 'view' ? true : false" name="date" :config="optionsDate"></date-picker>
          <i class="ri-calendar-line m-0 icon-calendar-edit" :disabled="checkmodal === 'view' ? true : false" @click="onClickDatePicker(3)" style="cursor: pointer;"></i>
        </div>
      </b-form-group>
      <b-form-group style="color: black;" label="กลุ่มค่าใช้จ่าย">
        <template v-if="costDetail.costGroup === null || costDetail.costGroup === ''" v-slot:label>
          กลุ่มค่าใช้จ่าย<span class="text-danger"> *</span>
        </template>
        <v-select v-model="costDetail.costGroup" :disabled="checkmodal === 'view' ? true : false" :options="costGroupDD"></v-select>
      </b-form-group>
      <b-form-group label="เลขที่ IV" style="color: black;">
        <template v-if="costDetail.invoice === null || costDetail.invoice === ''" v-slot:label>
          เลขที่ IV<span class="text-danger"> *</span>
        </template>
        <b-form-input :disabled="checkmodal === 'view' ? true : false" id="" v-model="costDetail.invoice"  @keypress="isNumberCheck($event, true)" type="text"></b-form-input>
      </b-form-group>
      <b-form-group label="ยอดทั้งหมด" style="color: black;">
        <template v-if="costDetail.amount === null || costDetail.amount === ''" v-slot:label>
          ยอดทั้งหมด<span class="text-danger"> *</span>
        </template>
        <CurrencyInputCom :disabled="checkmodal !== 'view' ? false : true" v-model="costDetail.amount" :options="optionCurrency"></CurrencyInputCom>
      </b-form-group>
      <div class="radio-group" style="justify-content: space-evenly;">
        <div class="radio-item">
          <b-form-radio :disabled="checkmodal === 'view'" v-model="costDetail.is_service" value="false" name="supplierStandard1" key="option2"></b-form-radio>
          <b-text class="check ml-0 mb-0" style="color: black;">สินค้า</b-text>
        </div>
        <div class="radio-item">
          <b-form-radio :disabled="checkmodal === 'view'" v-model="costDetail.is_service" value="true" name="supplierStandard2" key="option1"></b-form-radio>
          <b-text class="check ml-0 mb-0" style="color: black;">บริการ</b-text>
        </div>
      </div>
      <b-form-group label="Vat 7%" style="color: black;">
        <b-form-input :disabled="true" id="vat7" :value="formatCurrency(vat7)" @input="isNumberComma1($event, '6')" @keypress="isNumberCheck($event, true)" type="text"></b-form-input>
      </b-form-group>
      <b-form-group label="หัก 3%" style="color: black;">
        <b-form-input :disabled="true" id="minus3" :value="formatCurrency(vat3)" @input="isNumberComma1($event, '7')" @keypress="isNumberCheck($event, true)" type="text"></b-form-input>
      </b-form-group>
      <b-form-group label="ยอดรวม" style="color: black;">
        <b-form-input :disabled="true" :value="costDetail.is_service === 'true' ? formatCurrency(plusvat7) : formatCurrency(minusvat3)" id="" @input="isNumberComma1($event, '8')" @keypress="isNumberCheck($event, true)" type="text"></b-form-input>
      </b-form-group>
      <template #modal-footer>
        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
          <b-button
            style="border-radius: 0.5rem; padding: 0 .7rem;"
            variant="primary"
            class="btn-min-size"
            size="sm"
            @click="checkAddOrEdit"
          >
            <i class="save-data_ct"/>บันทึก
          </b-button> <!-- costDetail.total = parseFloat(checkradio2 === 'option1' ? formatCurrency(plusvat7)?.replace(/,/g, '')  : formatCurrency(minusvat3)?.replace(/,/g, '')), costDetail.amount = formatCurrency(costDetail.amount), costDetail.vat = formatCurrency(vat7), costDetail.deduct = formatCurrency(vat3) -->
          <b-button
            variant="none"
            class="iq-bg-danger btn-cancel-border btn-min-size"
            style="border-radius: 0.5rem;"
            size="sm"
            @click="showModal=false"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-container>
</template>

<style>
th.width-label-3head {
  display: none
}

.radio-group {
  display: flex;
  flex-wrap: wrap;
}

.radio-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}
@media (max-width: 1000px) {
  .t-button {
    width: 100%;
  }
  .response-button-header1 {
    gap: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .btn-add1 {
    margin: 0 !important;
  }
  .btn-add2 {
    margin: 0.25rem 0 0.25rem 0.25rem !important;
  }
  .btn-import {
    margin: 0.25rem !important;
    padding: 0;
  }
  .response-button-download1 {
    gap: 10px;
    width: 100% !important;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 10px;
  }
}
@media (max-width: 1000px) {
  .newline {
    display: flex;
    margin-top: 20px;
    padding-left: 10px;
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 1000px) {
  .newline {
    text-align: center;
    white-space: wrap;
  }
}
.no-header thead {
  display: none;
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
// import * as XLSX from 'xlsx'
import moment from 'moment'
import Axios from 'axios'
import CurrencyInputCom from './CurrencyInputCom.vue'
import { configAPI } from '../../configBase'

const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}

export default {
  name: 'AddMonthCost',
  components: {
    CurrencyInputCom
  },
  data () {
    return {
      checkmodal: '',
      costId: this.$route.params.id || null,
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      usrId: JSON.parse(sessionStorage.getItem('user')).usr_id || null,
      checkradio1: 'option2',
      checkradio2: 'option2',
      showModal: false,
      selectedOption: '',
      optionsDate: {
        format: 'DD/MM/YYYY',
        useCurrent: true,
        showClear: true,
        showClose: true,
        useStrict: false,
        sideBySide: true,
        icons: iconDate,
        minDate: null,
        maxDate: null
      },
      costDetail: {
        id: null,
        name: null,
        date: null,
        costGroup: null,
        invoice: null,
        amount: null,
        before_vat: null,
        vat: null,
        deduct: null,
        total: null,
        is_service: null
      },
      indexTableEdit: null,
      columns: [
        { label: 'วันที่', key: 'variable1', class: 'text-center table-nowarp width-label-3head' },
        { label: 'รายการ', key: 'variable2', class: 'text-center table-nowarp width-label-3head' },
        { label: 'จำนวนเงิน', key: 'variable3', class: 'text-center table-nowarp width-label-3head' },
        { label: 'Grouping', key: 'variable4', class: 'text-center table-nowarp width-label-3head' },
        { label: 'จัดการ', key: 'variable5', class: 'text-center table-nowarp width-label-3head' }
      ],
      columns2: [
        { label: null, key: 'variable1', class: 'text-center table-nowarp width-label-3head' },
        { label: null, key: 'variable2', class: 'text-center table-nowarp width-label-3head' },
        { label: null, key: 'variable3', class: 'text-center table-nowarp width-label-3head' },
        { label: null, key: 'variable4', class: 'text-center table-nowarp width-label-3head' },
        { label: null, key: 'variable5', class: 'text-center table-nowarp width-label-3head' },
        { label: null, key: 'variable6', class: 'text-center table-nowarp width-label-3head' }
      ],
      costDetailData: [],
      countRows: 0,
      costGroupDD: [],
      deleteCostDetail: [],
      optionCurrency: {
        currency: 'EUR',
        currencyDisplay: 'hidden',
        valueRange: {
          min: 0,
          max: 1e+53
        },
        precision: 2,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: true,
        accountingSign: false
      },
      currentPage: 1,
      perPage: 10
    }
  },
  async mounted () {
    await this.getCostDetailById()
    await this.getCostGroup()
    xray.index()
  },
  watch: {
    // 'costDetail.is_service' (val) {
    //   if (val) {
    //     this.checkradio1 = 'option1'
    //   } else {
    //     this.checkradio1 = 'option2'
    //   }
    // },
    'deleteCostDetail' (val) {
      // console.log(val)
    }
  },
  computed: {
    getModalTitle () {
      return 'เพิ่มรายการ (' + this.checkmodal + ')'
    },
    sumTotal () {
      let total = 0
      if (this.costDetailData?.cost_detail_list) {
        for (let item of this.costDetailData?.cost_detail_list) {
          if (item.is_cost) {
            total -= parseFloat(item.total?.toString().replace(/,/g, '') || 0)
          } else {
            total += parseFloat(item.total?.toString().replace(/,/g, '') || 0)
          }
        }
      }
      return total
    },
    vat7 () {
      return parseFloat(this.costDetail.amount?.toString().replace(/,/g, '') || 0) * 7 / 100
    },
    vat3 () {
      return parseFloat(this.costDetail.amount?.toString().replace(/,/g, '') || 0) * 3 / 100
    },
    minusvat3 () {
      return parseFloat(this.costDetail.amount?.toString().replace(/,/g, '') || 0) - this.vat3
    },
    plusvat7 () {
      return parseFloat(this.costDetail.amount?.toString().replace(/,/g, '') || 0) + this.vat7
    }
  },
  methods: {
    onPagination (page) {
      this.currentPage = page
    },
    async getCostDetailById () {
      if (this.costId) {
        await Axios.request({
          method: 'GET',
          url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/cost/${this.costId}`,
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((res) => {
          // console.log(res.data.data[0])
          this.costDetailData = res.data.data[0]
          this.optionsDate.minDate = moment(this.costDetailData.start_date).format('YYYY-MM-DD')
          this.optionsDate.maxDate = moment(this.costDetailData.end_date).format('YYYY-MM-DD')
        }).catch((err) => {
          Vue.swal({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: err.response.data.message
          })
        })
      }
    },
    async getCostGroup () {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/costGroup`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        let temp = res.data.data
        let tempArr = []
        temp.forEach((item) => {
          tempArr.push({
            label: item.name,
            code: item.id
          })
        })
        this.costGroupDD = tempArr
      }).catch((err) => {
        Vue.swal.close()
        Vue.swal({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: err.response.data.message
        })
      })
    },
    onClickDatePicker (num) {
      let datePicker = null
      if (num === 1) {
        datePicker = document.getElementById('datepicker-custom1')
      } else if (num === 2) {
        datePicker = document.getElementById('datepicker-custom2')
      } else {
        datePicker = document.getElementById('datepicker-custom3')
      }
      datePicker.focus()
    },
    async checkAddOrEdit () {
      if (this.checkmodal === 'add') {
        await this.addCostDetail()
      } else {
        await this.editCostDetail()
      }
    },
    async addCostDetail () {
      let errList = await this.checkError()
      if (errList.length > 0) {
        Vue.swal({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          html: `<span>${errList.join('<br>')}</span>`
        })
        return
      }
      let temp = []
      temp.push({
        id: null,
        cg_name: this.costDetail.costGroup?.label || null,
        cg_id: this.costDetail.costGroup?.code || null,
        menu_name: null,
        date: new Date(moment(this.costDetail.date, 'DD/MM/YYYY').format('YYYY-MM-DD')),
        name: this.costDetail.name,
        amount: this.costDetail.amount?.toString().replace(/,/g, '') || 0,
        iv_no: this.costDetail.invoice || null,
        before_vat: this.costDetail.amount?.toString().replace(/,/g, '') || 0,
        vat: this.vat7.toString(),
        deduct: this.vat3.toString(),
        total: this.costDetail.total,
        is_service: this.costDetail.is_service,
        created_by: this.usrId,
        status: 'add',
        is_cost: true
      })
      this.costDetailData.cost_detail_list = this.costDetailData.cost_detail_list.concat(temp)
      await this.resetSupplier()
      this.showModal = false
    },
    setEditCostDetail (data, index) {
      this.costDetail.id = data.id || null
      this.costDetail.name = data.name
      this.costDetail.date = moment(data.date).format('DD/MM/YYYY')
      this.costDetail.costGroup = {
        label: data.cg_name,
        code: data.cg_id
      }
      this.costDetail.invoice = data.iv_no
      this.costDetail.amount = data.amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
      this.costDetail.vat = data.vat
      this.costDetail.deduct = data.deduct
      this.costDetail.total = data.total
      this.costDetail.is_service = data.is_service ? 'true' : 'false'
      this.indexTableEdit = index
    },
    async resetSupplier () {
      this.costDetail.name = null
      this.costDetail.date = null
      this.costDetail.costGroup = null
      this.costDetail.invoice = null
      this.costDetail.amount = null
      this.costDetail.vat = null
      this.costDetail.deduct = null
      this.costDetail.total = null
      this.costDetail.is_service = 'false'
      this.costDetail.before_vat = null
    },
    async editCostDetail () {
      let errList = await this.checkError()
      if (errList.length > 0) {
        Vue.swal({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          html: `<span>${errList.join('<br>')}</span>`
        })
        return
      }
      this.costDetailData.cost_detail_list[this.indexTableEdit].id = this.costDetailData.cost_detail_list[this.indexTableEdit].id || null
      this.costDetailData.cost_detail_list[this.indexTableEdit].date = new Date(moment(this.costDetail.date, 'DD/MM/YYYY').format('YYYY-MM-DD'))
      this.costDetailData.cost_detail_list[this.indexTableEdit].name = this.costDetail.name || null
      this.costDetailData.cost_detail_list[this.indexTableEdit].iv_no = this.costDetail.invoice || null
      this.costDetailData.cost_detail_list[this.indexTableEdit].amount = this.costDetail.amount?.toString().replace(/,/g, '') || 0
      this.costDetailData.cost_detail_list[this.indexTableEdit].cg_name = this.costDetail.costGroup.label
      this.costDetailData.cost_detail_list[this.indexTableEdit].cg_id = this.costDetail.costGroup.code
      this.costDetailData.cost_detail_list[this.indexTableEdit].before_vat = this.costDetail.amount?.toString().replace(/,/g, '') || 0
      this.costDetailData.cost_detail_list[this.indexTableEdit].vat = this.vat7.toString()
      this.costDetailData.cost_detail_list[this.indexTableEdit].deduct = this.vat3.toString()
      this.costDetailData.cost_detail_list[this.indexTableEdit].total = this.costDetail.is_service === 'true' ? this.plusvat7 : this.minusvat3
      this.costDetailData.cost_detail_list[this.indexTableEdit].is_service = this.costDetail.is_service === 'true'
      this.costDetailData.cost_detail_list[this.indexTableEdit].menu_name = this.costDetailData.cost_detail_list[this.indexTableEdit].menu_name || null
      this.costDetailData.cost_detail_list[this.indexTableEdit].status = this.costDetailData.cost_detail_list[this.indexTableEdit].id ? 'update' : 'add'
      this.showModal = false
    },
    preventDecimalAndNegative (event) {
      const disallowedKeys = ['-', '.', 'e', 'E', '+', '=', '*']
      const maxLength = 13
      if (disallowedKeys.includes(event.key)) {
        event.preventDefault()
      }
      if (event.target.value.length >= maxLength) {
        event.preventDefault()
      }
    },
    isNumberComma1 (event, variable) {
      if (variable === '8') {
        this.costDetail.total = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === '5') {
        this.costDetail.amount = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === '6') {
        this.costDetail.vat = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === '7') {
        this.costDetail.deduct = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      }
    },
    isNumberCheck (keyPressed, status) {
      let useData = keyPressed.target.value
      const disallowedKeys = ['-', '.', 'e', 'E', '+', '=', '*']
      if (disallowedKeys.includes(keyPressed.key)) {
        keyPressed.preventDefault()
      }
      let keyCode = (keyPressed.keyCode ? keyPressed.keyCode : keyPressed.which)
      if (status) {
        if (keyCode === 46 || useData.indexOf('.') !== -1) {
          keyPressed.preventDefault()
        }
        if (keyCode < 48 || keyCode > 57) {
          keyPressed.preventDefault()
        }
      } else {
        if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || useData.indexOf('.') !== -1)) {
          keyPressed.preventDefault()
        }
      }
      if (useData !== null && useData.indexOf('.') > -1 && (useData.split('.')[1].length > 1)) {
        keyPressed.preventDefault()
      }
      if ((useData === null || useData === '') && keyCode === 46) {
        keyPressed.preventDefault()
      }
    },
    setPage () {
      return this.$route.params.setPage
    },
    remove (item) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะลบข้อมูลใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteCostDetail.push(item.id || null)
          let index = this.costDetailData.cost_detail_list.findIndex((x) => x === item)
          this.costDetailData.cost_detail_list.splice(index, 1)
          Vue.swal({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
            icon: 'success',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 2000
          })
        }
      })
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    async beforeRefresh () {
      Vue.swal.fire({
        icon: 'warning',
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจที่จะดึงข้อมูลใหม่ใช่หรือไม่</span>',
        text: 'ข้อมูลที่แก้ไขจะหายไป',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          // ข้อมูลเก่า ที่จะไป is_use = false
          let deleteTempData = this.costDetailData.cost_detail_list.filter((item) => item.cg_id === null)
          let delArr = []
          deleteTempData.map((item) => {
            delArr.push(item.id)
          })
          // ข้อมูลที่มี cg_id ยังต้องอยู่
          this.deleteCostDetail = [...this.deleteCostDetail, ...delArr]
          await this.refreshClick()
        }
      })
    },
    async refreshClick () {
      // let newData = this.costDetailData.cost_detail_list.filter((item) => item.id === null)
      // if (newData.length > 0) {
      //   Vue.swal({
      //     icon: 'warning',
      //     title: '<span class="" style="font-size: 25px; color: black; text-align: center">มีการเพิ่มรายการค่าใช้จ่ายใหม่</span>',
      //     text: 'ต้องการบันทึกข้อมูลก่อน Refresh หรือไม่',
      //     showCancelButton: true,
      //     confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
      //     confirmButtonColor: '#089bab',
      //     cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
      //     cancelButtonColor: '#d33',
      //     customClass: {
      //       confirmButton: 'custom-confirm-button-class mx-1',
      //       cancelButton: 'custom-confirm-button-class mx-1'
      //     }
      //   }).then(async (result) => {
      //     if (result.isConfirmed) {
      //       if (this.deleteCostDetail.length > 0) {
      //         await this.deleteCostDetailFunc()
      //       }
      //       let temp = this.costDetailData.cost_detail_list
      //       await Axios.request({
      //         method: 'POST',
      //         url: `${configAPI.MA_PORT}/api/cost/${this.costId}/costDetail/`,
      //         headers: {
      //           'Content-Type': 'application/json'
      //         },
      //         data: temp
      //       }).then(async (res) => {
      //         Vue.swal.fire({
      //           title: '<span class="loading-icon"><i class="fas fa-spinner fa-spin"></i></span>',
      //           text: 'Refreshing...',
      //           showConfirmButton: false,
      //           allowEscapeKey: false,
      //           allowOutsideClick: false,
      //           onOpen: () => {
      //             Vue.swal.getHtmlContainer().querySelector('.loading-icon').style.fontSize = '40px'
      //           }
      //         })
      //         await this.getCostDetailById()
      //         // await this.getCostDetailRefresh()
      //         await this.getCostGroup()
      //         this.deleteCostDetail = []
      //         Vue.swal.close()
      //       }).catch((err) => {
      //         Vue.swal({
      //           icon: 'error',
      //           title: 'เกิดข้อผิดพลาด',
      //           text: err.response.data.message
      //         })
      //       })
      //     }
      //   })
      // } else {
      Vue.swal.fire({
        title: '<span class="loading-icon"><i class="fas fa-spinner fa-spin"></i></span>',
        text: 'Refreshing...',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          Vue.swal.getHtmlContainer().querySelector('.loading-icon').style.fontSize = '40px'
        }
      })
      // await this.getCostDetailById()
      await this.getCostDetailRefresh()
      await this.getCostGroup()
      // this.deleteCostDetail = []
      Vue.swal.close()
      // }
    },
    handleClick (status) {
      if (status) {
        if (this.costDetailData.cost_detail_list.length === 0) {
          Vue.swal({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด',
            text: 'กรุณาเพิ่มรายการค่าใช้จ่าย อย่างน้อย 1 รายการ'
          })
          return
        }
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            if (this.deleteCostDetail.length > 0) {
              await this.deleteCostDetailFunc()
            }
            let temp = this.costDetailData.cost_detail_list
            await Axios.request({
              method: 'POST',
              url: `${configAPI.MA_PORT}/api/cost/${this.costId}/costDetail/`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: temp
            }).then((res) => {
              Vue.swal({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
                icon: 'success',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                router.push('/MonthCost')
              })
            }).catch((err) => {
              Vue.swal({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: err.response.data.message
              })
            })
          }
        })
      } else {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            router.push('/MonthCost')
          }
        })
      }
    },
    formatCurrency (value) {
      if (value === null || value === undefined) {
        return '-'
      } else {
        let numberTemp = value !== null && value !== undefined ? parseFloat(value) : 0
        let NumberWithComma = (numberTemp?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return NumberWithComma
      }
    },
    async generateExcel () {
      let newData = this.costDetailData.cost_detail_list.filter((item) => item.id === null)
      if (newData.length > 0) {
        Vue.swal({
          icon: 'warning',
          title: 'มีการเพิ่มรายการค่าใช้จ่ายใหม่',
          text: 'ต้องการบันทึกข้อมูลก่อนดาวน์โหลดไฟล์หรือไม่',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            if (this.deleteCostDetail.length > 0) {
              await this.deleteCostDetailFunc()
            }
            let temp = this.costDetailData.cost_detail_list
            await Axios.request({
              method: 'POST',
              url: `${configAPI.MA_PORT}/api/cost/${this.costId}/costDetail/`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: temp
            }).then(async (res) => {
              await this.downloadFile()
            }).catch((err) => {
              Vue.swal({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: err.response.data.message
              })
            })
          }
        })
      } else {
        await this.downloadFile()
      }
    },
    getCurrentDate (date) {
      if (date === '') {
        return ''
      } else {
        return moment(date).format('DD/MM/YYYY')
      }
    },
    async checkError () {
      let errList = []
      if (this.costDetail.name === null || this.costDetail.name?.trim() === '') {
        errList.push('กรุณากรอก รายการ')
      }
      if (this.costDetail.date === null || this.costDetail.date === '') {
        errList.push('กรุณาเลือก วันที่')
      }
      if (this.costDetail.costGroup === null || this.costDetail.costGroup === '') {
        errList.push('กรุณาเลือก กลุ่มค่าใช้จ่าย')
      }
      if (this.costDetail.amount === null || this.costDetail.amount === '') {
        errList.push('กรุณากรอก ยอดทั้งหมด')
      }
      if (this.costDetail.invoice === null || this.costDetail.invoice === '') {
        errList.push('กรุณากรอก เลขที่ IV')
      }
      return errList
    },
    async deleteCostDetailFunc () {
      this.deleteCostDetail.forEach(async (item) => {
        if (item !== null) {
          await Axios.request({
            method: 'POST',
            url: `${configAPI.MA_PORT}/api/cost/${this.costId}/costDetail/${item}/delete`,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((res) => {})
            .catch((err) => {
              Vue.swal({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: err.response.data.message
              })
            })
        }
      })
    },
    async downloadFile () {
      await Axios.request({
        method: 'POST',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/cost/${this.costId}/report/export`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        let url = res.data.data?.file_path.slice(1)
        url = `${configAPI.MA_PORT}${url}`
        window.open(url, '_blank')
      }).catch((err) => {
        Vue.swal({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: err.response.data.message
        })
      })
    },
    async getCostDetailRefresh () {
      await Axios.request({
        method: 'POST',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/cost/${this.costId}/getCostDetailRefresh`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          start_date: this.costDetailData.start_date,
          end_date: this.costDetailData.end_date
        }
      }).then((res) => {
        // console.log(res.data.data)
        let temp = res.data.data[0]
        let oldCostDetailData = JSON.parse(JSON.stringify(this.costDetailData))
        let costDetailDataFilter = oldCostDetailData.cost_detail_list.filter((item) => item.cg_id !== null)
        let tempArr = temp.cost_detail_list.concat(costDetailDataFilter)
        temp = {
          ...temp,
          cost_detail_list: tempArr
        }
        this.costDetailData = temp
      }).catch((error) => {
        Vue.swal.close()
        Vue.swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: error.response.data.message
        })
      })
    }
  }
}
</script>
