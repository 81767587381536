<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">ข้อมูลเครดิตระบบ</span>
              </div>
              <div class="col-12 col-sm-auto my-1 my-sm-0">
                <div class="row">
                  <div class="col-6 pl-sm-3 pr-sm-1 my-1 my-sm-0">
                    <b-button type="submit" variant="primary" @click="saveConfig(false)" class="w-100 btn-min-size"><i
                        class="save-data_ct"></i>บันทึก</b-button>
                  </div>
                  <div class="col-6 pl-sm-3 pr-sm-1 my-1 my-sm-0">
                    <b-button type="submit" variant="primary" @click="toAddPage('add')" class="w-100 btn-min-size"><i
                        class="circle-plus_ct"></i>เพิ่ม</b-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col>
                  <b-row>
                    <b-col lg="4" md="4" sm="4">
                      <!-- <b-text style="white-space: nowrap; color: black;">ชื่อคลินิก</b-text> -->
                      <b-form-group label-for="exampleFormControlSelect1" label="ชื่อคลินิก">
                        <v-select v-model="selectClinic" :options="clinicData"></v-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4" md="4" sm="4">
                      <b-form-group label-for="exampleFormControlSelect1" label="เมนู">
                        <v-select v-model="selectMenu" :options="menuData"></v-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4" md="4" sm="4">
                      <b-form-group label-for="exampleFormControlSelect1" label="สถานะ">
                        <v-select v-model="selectStatus" :options="statusData"></v-select>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <div class="row pt-3">
                    <div class="col">
                      <div style="color: black; font-size: 19px;">
                        <label class="mr-2" style="white-space: nowrap;">วงเงินเครดิตที่คงค้างในระบบ</label>
                        <label class="mr-2" style="font-size: large;">{{ numberWithCommas(total_credit_approve
                          || 0)
                        }}</label>
                        <label>บาท</label>
                      </div>
                    </div>
                  </div>

                  <div class="row pb-3">
                    <div class="col">
                      <div style="color: black; font-size: 19px;">
                        <label class="mr-2" style="white-space: nowrap;">วงเงินเครดิตที่รอยืนยันในระบบ</label>
                        <label class="mr-2" style="font-size: large;">{{
                          numberWithCommas(total_credit_waiting_approve || 0)
                        }}</label>
                        <label>บาท</label>
                      </div>
                    </div>
                  </div>

                </b-col>
                <b-col lg="3" sm="3" style="display: flex; flex-direction: column; align-items: center;">
                  <label for="imageUpload" style="cursor: pointer;">
                    <img :src="imageUrl ? imageUrl : qr_path || require('../../assets/images/default.jpg')"
                      style="min-height: 150px; max-height: 150px; min-width: 150px; max-width: 150px; object-fit: contain;"
                      :alt="imageUrl ? '' : qr_name">
                  </label>
                  <input type="file" id="imageUpload" style="display: none" :accept="fileFormat"
                    @change="handleFileUpload">
                  <b-text class=""
                    style="min-width: 70%; max-width: 70%; text-align: center; color: black;">คลิกเพื่ออัพโหลด QR
                    เพื่อให้ผู้ใช้โอนเงิน</b-text>
                </b-col>
              </b-row>

              <div class="pb-1" style="color: black; font-size: 19px;">ประวัติการใช้วงเงิน</div>

              <b-row>
                <b-col cols="12" class="table-responsive" style="padding: 0px;">
                  <b-table bordered hover :items="rows" :fields="columns" :current-page="currentPage" :per-page="perPage"
                    @pagination="onPagination" :tbody-tr-class="rowClass" thead-class="table-header-color">
                    <template v-slot:cell(variable1)="data">
                      <span>{{ data.item.created_date ? dateFormat(data.item.created_date) : '-' }}</span>
                    </template>
                    <template v-slot:cell(variable2)="data">
                      <span>{{ data.item.fs_status || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable3)="data">
                      <span>{{ data.item.cln_name || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable4)="data">
                      <span>{{ data.item.menu_name || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable5)="data">
                      <span>{{ data.item.amount ? numberWithCommas(data.item.amount) : '-' }}</span>
                    </template>

                    <template v-slot:cell(variable6)="data">
                      <b-button v-if="data.item.fta_id === '8351b794-07b0-11ee-be56-0242ac120002'"
                        @click="approveCredit(data.item.id)" variant="primary" class="out-data_ct">
                        {{ data.item.fsa_status }}
                      </b-button>
                      <b-text v-else>{{ data.item.fsa_status }}</b-text>
                    </template>

                    <template v-slot:cell(variable7)="data">
                      <b-button v-if="data.item.upload_path" type="submit"
                        @click="downloadFile(data.item.upload_path, data.item.upload_name)" variant="primary"><i
                          class="out-data_ct"></i>{{ 'ดาวน์โหลด' }}</b-button>
                      <b-button v-else disabled type="submit" variant="primary"><i class="out-data_ct"></i>{{ 'ดาวน์โหลด'
                      }}</b-button>
                    </template>
                    <template v-slot:cell(variable8)="data">
                      <span>{{ data.item.created_by_fullname }}</span>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row style="display: flex; justify-content: center;">
                <b-pagination v-model="currentPage" :total-rows="rows?.length" :per-page="perPage" />
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import axios from 'axios'
import { configAPI } from '../../configBase'
import moment from 'moment'
// import { configProd } from '../../configBase'

export default {
  name: 'UiDataTable',
  data () {
    return {
      columns: [
        { label: 'วันที่', key: 'variable1', class: 'text-center table-size-200-400' },
        { label: 'ประเภท', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'ชื่อคลินิก', key: 'variable3', class: 'text-center table-size-200-400' },
        { label: 'เมนู', key: 'variable4', class: 'text-center table-size-200-400' },
        { label: 'ยอดเงิน', key: 'variable5', class: 'text-center table-size-200-400' },
        { label: 'สถานะ', key: 'variable6', class: 'text-center table-size-200-400' },
        { label: 'หลักฐานการโอน', key: 'variable7', class: 'text-center table-size-200-400' },
        { label: 'ผู้บันทึก', key: 'variable8', class: 'text-center table-size-200-400' }
      ],
      rows: [],
      fileFormat: [
        'image/jpeg',
        'image/png',
        'image/svg+xml',
        'image/webp'
      ],
      currentPage: 1,
      perPage: 10,
      imageUrl: null,
      qr_name: '',
      qr_path: '',
      total_credit_approve: 0,
      total_credit_waiting_approve: 0,
      saveImage: null,
      clinicData: [],
      menuData: [],
      statusData: [],
      selectClinic: null,
      selectMenu: null,
      selectStatus: null
    }
  },
  methods: {
    dateFormat (data) {
      return moment(data).format('DD/MM/YYYY')
    },
    numberWithCommas (data) {
      return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    async getConfig () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/credit/detail/?cln_id=${this.selectClinic?.value || ''}&fta_id=${this.selectStatus?.value || ''}&menu_id=${this.selectMenu?.value || ''}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          this.qr_name = response.data.data.gc_qrcode
          this.qr_path = configAPI.MA_PORT + response.data.data.gc_qrcode_path?.substring(1)
          this.total_credit_approve = response.data.data.total_credit_approve
          this.total_credit_waiting_approve = response.data.data.total_credit_waiting_approve
        })
        .catch((error) => {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาดในการดึงข้อมูล</span>`,
            html: '<span>' + error.message + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async getClinic () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          this.clinicData = response.data.data.map((item) => {
            return { value: item.id, label: item.name }
          })
        })
        .catch((error) => {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาดในการดึงข้อมูล</span>`,
            html: '<span>' + error.message + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async getMenu () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MD_PORT}/api/menu`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          this.menuData = response.data.data.map((item) => {
            return { value: item.id, label: item.name }
          })
        })
        .catch((error) => {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาดในการดึงข้อมูล</span>`,
            html: '<span>' + error.message + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async getStatus () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MD_PORT}/api/financeApproveStatus`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          this.statusData = response.data.data.map((item) => {
            return { value: item.id, label: item.status }
          })
        })
        .catch((error) => {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาดในการดึงข้อมูล</span>`,
            html: '<span>' + error.message + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async getCreditAll () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/credit/?cln_id=${this.selectClinic?.value || ''}&fta_id=${this.selectStatus?.value || ''}&menu_id=${this.selectMenu?.value || ''}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          if (response.data.data.length > 0) {
            this.rows = response.data.data?.map((item, index) => {
              item.index = index
              return item
            })
          } else {
            this.rows = []
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาดในการดึงข้อมูล</span>`,
            html: '<span>' + error.message + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    saveConfig (status) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const tempData = new FormData()
          tempData.append('file', this.saveImage)

          const axiosConfig = {
            method: 'post',
            url: `${configAPI.MA_PORT}/api/config/account`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: tempData
          }

          axios.request(axiosConfig).then(() => {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              if (status) {
                router.push(`/AddCredit`)
              } else {
                window.location.reload()
              }
            })
          })
        }
      })
    },
    approveCredit (creditId) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะยืนยันรายการใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const axiosConfig = {
            method: 'post',
            url: `${configAPI.MA_PORT}/api/credit/${creditId}/approve`,
            headers: {
              'Content-Type': 'application/json'
            }
          }

          axios.request(axiosConfig).then(() => {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              window.location.reload()
            })
          })
        }
      })
    },
    async downloadFile (pathFile, nameFile) {
      let pathApi = configAPI.MA_PORT + pathFile?.substring(1)

      await axios({
        method: 'get',
        url: pathApi,
        responseType: 'blob'
      })
        .then((response) => {
          let fileUrl = window.URL.createObjectURL(new Blob([response.data]))
          let fileLink = document.createElement('a')
          fileLink.href = fileUrl

          fileLink.setAttribute('download', nameFile)
          document.body.appendChild(fileLink)

          fileLink.click()
        })
        .catch(() => {
          this.SwalError('ไม่พบไฟล์นี้ในฐานข้อมูล', 'ดาวน์โหลดเอกสารไม่สำเร็จ')
        })
    },
    onPagination (page) {
      this.currentPage = page
    },
    toAddPage () {
      if (!this.imageUrl) {
        router.push(`/AddCredit`)
      } else {
        this.saveConfig(true)
      }
    },
    handleFileUpload (event) {
      const file = event.target.files[0]
      if (event.target.files[0] && this.fileFormat.includes(file.type)) {
        this.imageUrl = URL.createObjectURL(file)
        this.saveImage = file
      } else {
        this.imageUrl = null
        this.saveImage = null
      }
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    }
  },
  watch: {
    'selectClinic': async function () {
      await this.getCreditAll()
    },
    'selectMenu': async function () {
      await this.getCreditAll()
    },
    'selectStatus': async function () {
      await this.getCreditAll()
    }
  },
  async mounted () {
    xray.index()
    await this.getConfig()
    await this.getCreditAll()
    await this.getClinic()
    await this.getMenu()
    await this.getStatus()
  }
}
</script>

<style scoped>
.iq-header-title {
  width: 100%;
}
.font-nowrap {
  white-space: nowrap;
}
.flex-manage {
  display: flex !important;
}
.btn-import {
  margin: 0.25rem 0.5rem !important;
}
.status-width {
  min-width: 150px;
}
@media (max-width: 1000px) {
  .t-button {
    width: 100%;
  }
  .response-button-header {
    gap: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .btn-add {
    margin: 0 !important;
  }
  .btn-import {
    margin: 0.25rem 0 !important;
    padding: 0;
  }
  .response-button-download {
    width: 100% !important;
  }
  .barcoderes {
    width: 100% !important;
  }
  /*  .margin-response {
    margin: 0 .5rem !important;
  } */
}
</style>
