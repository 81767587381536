<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">Follow Up Case</span>
              </div>
              <!-- <div class="col-12 col-sm-auto my-1 my-sm-0">
                <b-row class="searchres">
                  <b-col lg="6" md="6" sm="12">
                    <div class="iq-email-search d-flex">
                      <div style="width: 100%;">
                        <div class="form-group mb-0">
                          <input class="form-control" id="exampleInputEmail1" placeholder="Search" style="width: 100%;">
                          <a class="search-link"><i class="ri-search-line" /></a>
                        </div>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="auto" class="mb-3" style="display: flex; align-items: center; padding: 0px;">
                    <span class="mx-2 font-size-14 text-primary">
                      {{ followCase?.length > 0 ? "1 - " + followCase?.length + " of " +
                        followCase?.length : "0 - 0 of 0" }}
                    </span></b-col>
                </b-row>
              </div> -->
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" md="7" lg="5" class="mb-3">
                  <!-- <b-input-group>
                    <b-form-input type="text" placeholder="Search" />
                    <b-input-group-append>
                      <b-button variant="outline-secondary">
                        <i class="fas fa-search"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group> -->
                  <b-row>
                    <b-col sm="12" md class="mb-3" style="padding: 0px;">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input class="form-control" id="exampleInputEmail1" placeholder="Search" style="width: 100%;"
                              v-model="searchItem" @keydown="enterSearch">
                            <a class="search-link" v-on:click="getFollowCase"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center; padding: 0px;">
                      <span class="mx-2 font-size-14 text-primary">
                        {{ followCase?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > followCase?.length) ? followCase?.length : (perPage * currentPage) ) + ' of ' + followCaseLength : '0 of ' + followCaseLength }} รายการ
                        <!-- {{ followCase?.length > 0 ? "1 - " + followCase?.length + " of " +
                          followCase?.length : "0 - 0 of 0" }} -->
                      </span></b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="table-responsive" style="padding: 0px;">
                  <b-table bordered hover :items="followCase" :fields="columns" :current-page="currentPage"
                    :per-page="perPage" @pagination="onPagination" :tbody-tr-class="rowClass"
                    thead-class="table-header-color">
                    <template v-slot:cell(variable1)="data">
                      <b-button variant=" iq-bg-primary mr-1 mb-1" size="sm" @click="addEditPage('view', data.item.id)"><i
                          class="ri-eye-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-success ml-1 mb-1" size="sm" @click="addEditPage('edit', data.item.id)"><i
                          class="ri-ball-pen-fill m-0"></i></b-button>
                    </template>
                    <template v-slot:cell(variable2)="data">
                      <span>{{ data.item.st_name || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable3)="data">
                      <span>{{ data.item.day0 || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable4)="data">
                      <span>{{ data.item.day1 || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable5)="data">
                      <span>{{ data.item.day3 || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable6)="data">
                      <span>{{ data.item.week1 || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable7)="data">
                      <span>{{ data.item.week2 || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable8)="data">
                      <span>{{ data.item.week4 || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable9)="data">
                      <span>{{ data.item.month3 || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable10)="data">
                      <span>{{ data.item.month6 || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable11)="data">
                      <span>{{ data.item.year1 || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable12)="data">
                      <span>{{ data.item.year2 || '-' }}</span>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row style="display: flex; justify-content: center;">
                <b-pagination v-model="currentPage" :total-rows="followCase?.length" :per-page="perPage" />
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
@media (width: 700px) {
  .searchres {
    gap: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import axios from 'axios'
import { configAPI } from '../../configBase'
import Vue from 'vue'

export default {
  name: 'CaseTrackMaster',
  data () {
    return {
      columns: [
        { label: 'จัดการ', key: 'variable1', class: 'text-center table-size-200-400' },
        { label: 'ประเภทการใช้งาน', key: 'variable2', class: 'text-center table-size-200-400 ellips' },
        { label: 'Day 0', key: 'variable3', class: 'text-center table-size-200-400 ellips' },
        { label: 'Day 1', key: 'variable4', class: 'text-center table-size-200-400 ellips' },
        { label: 'Day 3', key: 'variable5', class: 'text-center table-size-200-400 ellips' },
        { label: '1 week', key: 'variable6', class: 'text-center table-size-200-400 ellips' },
        { label: '2 week', key: 'variable7', class: 'text-center table-size-200-400 ellips' },
        { label: '4 week', key: 'variable8', class: 'text-center table-size-200-400 ellips' },
        { label: '3 month(12 weeks)', key: 'variable9', class: 'text-center table-size-200-400 ellips' },
        { label: '6 month(24 weeks)', key: 'variable10', class: 'text-center table-size-200-400 ellips' },
        { label: '1 year(52 weeks)', key: 'variable11', class: 'text-center table-size-200-400 ellips' },
        { label: '2 year(104 weeks)', key: 'variable12', class: 'text-center table-size-200-400 ellips' }
      ],
      followCase: [],
      followCaseLength: 0,
      currentPage: 1,
      perPage: 10,
      searchItem: ''
    }
  },
  mounted () {
    xray.index()
    this.getFollowCase()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getFollowCase()
      }
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    onPagination (page) {
      this.currentPage = page
    },
    addEditPage (page, id) {
      router.push(`/CaseTrackMasterUpdate/${page}/${id}`)
    },
    async getFollowCase () {
      let search = this.searchItem.trim().length > 0 ? this.searchItem.trim() : ''
      let followCaseAPI = {
        method: 'get',
        url: `${configAPI.MD_PORT}/api/followCase?search=${search}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(followCaseAPI)
        .then((response) => {
          if (response.data.status) {
            this.followCase = response.data.data?.map((item, index) => {
              item.index = index
              return item
            })
            this.followCaseLength = response.data.count || 0
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    }
  }
}
</script>
