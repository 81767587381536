<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">บริการ</span>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" md="7" lg="5">
                  <!-- <b-input-group>
                    <b-form-input type="text" placeholder="Search" />
                    <b-input-group-append>
                      <b-button variant="outline-secondary">
                        <i class="fas fa-search"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group> -->
                  <b-row>
                    <b-col sm="12" md class="mb-3">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input class="form-control" id="exampleInputEmail1" placeholder="Search" style="width: 100%;" @keydown="enterSearch" v-model="searchTerm">
                            <a class="search-link" v-on:click="getService"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                      <span class="mx-2 font-size-14 text-primary">
                        {{ service?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > service?.length) ? service?.length : (perPage * currentPage) ) + ' of ' + serviceAllLength : '0 of ' + serviceAllLength }} รายการ
                      </span></b-col>
                  </b-row>
                  <b-row>
                      <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                        <b-form-checkbox v-model="filter.include_active" class="mx-2 mb-1"
                          style="white-space: nowrap;"
                          >Include active</b-form-checkbox>
                      </b-col>
                      <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                        <b-form-checkbox v-model="filter.include_unactive" class="mx-2 mb-1"
                          style="white-space: nowrap;"
                          >Include unactive</b-form-checkbox>
                      </b-col>
                  </b-row>
                </b-col>
                <!-- <b-col cols="auto" class="mb-3">
                  <b-text class="mx-2" style="white-space: nowrap;">ค่าคอมมิชชั่น</b-text>
                  <b-col>
                    <b-row>
                      <b-form-radio class="mx-2 mb-1" style="white-space: nowrap;" v-model="activeTab"
                        v-bind="changeTab()" value="tab1">คิดเป็น
                        % ราคาขาย</b-form-radio>
                      <b-form-radio class="mx-2" style="white-space: nowrap;" v-model="activeTab" v-bind="changeTab()"
                        value="tab2">อัตราคงที่
                        (Fix Rate)</b-form-radio>
                    </b-row>
                  </b-col>
                </b-col> -->
              </b-row>
              <b-row>
                <b-col cols="12" class="table-responsive">
                  <b-table bordered hover :items="service" :fields="columns1"
                    :current-page="currentPage" :per-page="perPage" @pagination="onPagination" :tbody-tr-class="rowClass" thead-class="table-header-color">
                    <template v-slot:cell(no)="data">
                      <span>{{ ((currentPage - 1) * perPage) + data.index + 1 }}</span>
                    </template>
                    <template v-slot:cell(variable1)="data">
                      <span>{{ service[((currentPage - 1) * 10) + data.index].no || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable2)="data">
                      <span>{{ service[((currentPage - 1) * 10) + data.index].sg_name || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable3)="data">
                      <span>{{ service[((currentPage - 1) * 10) + data.index].st_name || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable4)="data">
                      <span v-if="!service[((currentPage - 1) * 10) + data.index].editable">{{ addCommas(service[((currentPage - 1) * 10) + data.index].price) }}</span>
                      <input type="text" v-model="service[((currentPage - 1) * 10) + data.index].price" v-else class="form-control"
                        @input="isNumberComma1($event, service[((currentPage - 1) * 10) + data.index].id)" @keypress="isNumberCheck($event)">
                    </template>
                    <template v-slot:cell(variable5)="data">
                      <span v-if="!service[((currentPage - 1) * 10) + data.index].editable">{{ addCommas(service[((currentPage - 1) * 10) + data.index].normal_price) }}</span>
                      <input type="text" v-model="service[((currentPage - 1) * 10) + data.index].normal_price" v-else class="form-control"
                        @input="isNumberComma4($event, service[((currentPage - 1) * 10) + data.index].id)" @keypress="isNumberCheck($event)">
                    </template>
                    <template v-slot:cell(variable6)="data">
                      <span v-if="!service[((currentPage - 1) * 10) + data.index].editable">{{ addCommas(service[((currentPage - 1) * 10) + data.index].minimum_price) }}</span>
                      <input type="text" v-model="service[((currentPage - 1) * 10) + data.index].minimum_price" v-else class="form-control"
                        @input="isNumberComma5($event, service[((currentPage - 1) * 10) + data.index].id)" @keypress="isNumberCheck($event)">
                    </template>
                    <template v-slot:cell(variable7)="data">
                      <span v-if="!service[((currentPage - 1) * 10) + data.index].editable" style="width: 200px">{{ service[((currentPage - 1) * 10) + data.index].cot_name?.label || service[((currentPage - 1) * 10) + data.index].cot_name }}</span>
                      <v-select v-else style="width: 200px" v-model="service[((currentPage - 1) * 10) + data.index].cot_name" :options="commissionDD" @change="handleCommissionOptionChange(data.index)"></v-select>
                    </template>
                    <template v-slot:cell(variable8)="data">
                      <span v-if="!service[((currentPage - 1) * 10) + data.index].editable">{{ addCommas(service[((currentPage - 1) * 10) + data.index].pay) }}</span>
                      <input type="text" v-model="service[((currentPage - 1) * 10) + data.index].pay" v-else class="form-control"
                        @input="isNumberComma2($event, service[((currentPage - 1) * 10) + data.index].id)" @keypress="isNumberCheck($event)">
                    </template>
                    <template v-slot:cell(variable9)="data">
                      <span>{{ addCommas(service[((currentPage - 1) * 10) + data.index].standart_time) }}</span>
                    </template>
                    <template v-slot:cell(variable10)="data">
                      <span v-if="!service[((currentPage - 1) * 10) + data.index].editable">{{ service[((currentPage - 1) * 10) + data.index].time }}</span>
                      <input type="text" v-model="service[((currentPage - 1) * 10) + data.index].time" v-else class="form-control"
                        @input="isNumberComma3($event, service[((currentPage - 1) * 10) + data.index].id)" @keypress="isNumberCheck($event)">
                    </template>
                    <template v-slot:cell(variable11)="data">
                      <div class="custom-control-1 custom-switch custom-switch-text custom-switch-color">
                        <div class="custom-switch-inner">
                          <input
                            type="checkbox"
                            class="custom-control-input bg-success"
                            :id="'customSwitch-11-' + service[((currentPage - 1) * 10) + data.index].id"
                            @change="editStatus(service[((currentPage - 1) * 10) + data.index], $event.target.checked)"
                            v-model="service[((currentPage - 1) * 10) + data.index].is_active"
                          />
                          <label
                            class="custom-control-label-1 label-1"
                            :for="'customSwitch-11-' + service[((currentPage - 1) * 10) + data.index].id"
                            :data-on-label="'ON'"
                            :data-off-label="'OFF'"
                          ></label>
                        </div>
                      </div>
                    </template>
                    <template v-slot:cell(variable12)="data">
                      <div v-if="!service[((currentPage - 1) * 10) + data.index].editable">
                        <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(service[((currentPage - 1) * 10) + data.index])"><i
                            class="ri-ball-pen-fill m-0"></i></b-button>
                      </div>
                      <div style="display: flex;" v-else>
                        <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submit(service[((currentPage - 1) * 10) + data.index])">บันทึก</b-button>
                        <b-button variant=" iq-bg-danger mr-1 mb-1" size="sm" @click="redo(service[((currentPage - 1) * 10) + data.index])">ยกเลิก</b-button>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row style="display: flex; justify-content: center;">
                <b-pagination v-model="currentPage" :total-rows="service?.length" :per-page="perPage" />
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 17px;
  transition: 0.4s;
}

.slider.round {
  border-radius: 17px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

input[type="checkbox"]:checked + .slider {
  background-color: #2196f3;
}

input[type="checkbox"]:checked + .slider:before {
  transform: translateX(26px);
}

.custom-custom-control-label {
  width: 60px !important;
}

.custom-custom-control-label::before {
  height: 30px !important;
  border-radius: 100px !important;
}

.custom-custom-control-label::after {
  width: 20px !important;
  height: 20px !important;
  border-radius: 100px !important;
  top: 5px !important;
  left: 6px !important;
  transform: translateX(1.9rem) !important;
  transition: all 0.25ms ease !important;
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import axios from 'axios'
import Vue from 'vue'
import { configAPI } from '../../configBase'

export default {
  name: 'Service',
  data () {
    return {
      cln_id: null,
      filter: {
        include_active: true,
        include_unactive: true
      },
      searchTerm: '',
      service: {
        id: null,
        clinic_id: null,
        st_id: null,
        sc_id: null,
        cot_id: null,
        no: null,
        price: null,
        pay: null,
        standart_time: null,
        time: null,
        is_use: null,
        is_active: null,
        created_by: null,
        updated_by: null,
        created_date: null,
        updated_date: null,
        st_name: null,
        sg_id: null,
        sg_name: null,
        sc_color: null,
        cot_name: null,
        editable: false
      },
      serviceTemp: [
        // id: null,
        // price: null,
        // pay: null,
        // time: null
      ],
      state: [
        {
          label: 'True/Active',
          checked: true,
          disabled: false
        }
      ],
      commissionDD: [],
      commissionOption: null,
      numberOfToggleSwitches: 1,
      toggleStates: [],
      toggleState: false,
      showModal: false,
      imageUrl: null,
      imageUrl2: null,
      // activeTab: 'tab2',
      columns1: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        { label: 'รหัสบริการ', key: 'variable1', class: 'text-center table-size-200-400' },
        { label: 'หมวดหมู่', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'ประเภทการใช้งาน', key: 'variable3', class: 'text-center table-size-200-400' },
        { label: 'ราคาขาย', key: 'variable4', class: 'text-center table-size-200-400' },
        { label: 'ราคาขายปกติ', key: 'variable5', class: 'text-center table-size-200-400' },
        { label: 'ราคาขายขั้นต่ำ', key: 'variable6', class: 'text-center table-size-200-400' },
        { label: 'ค่าคอมมิชชั่น', key: 'variable7', class: 'text-center table-size-200-400' },
        { label: 'ค่ามือต่อครั้ง', key: 'variable8', class: 'text-center table-size-200-400' },
        { label: 'เวลามาตรฐานให้บริการ (นาที)', key: 'variable9', class: 'text-center table-size-200-400' },
        { label: 'เวลาให้บริการ (นาที)', key: 'variable10', class: 'text-center table-size-200-400' },
        { label: 'สถานะ', key: 'variable11', class: 'text-center' },
        { label: 'จัดการ', key: 'variable12', class: 'text-center' }
      ],
      // columns2: [
      //   { label: 'รหัสบริการ', key: 'variable1', class: 'text-center table-size-200-400' },
      //   { label: 'หมวดหมู่', key: 'variable2', class: 'text-center table-size-200-400' },
      //   { label: 'ประเภทการใช้งาน', key: 'variable3', class: 'text-center table-size-200-400' },
      //   { label: 'ราคาขาย', key: 'variable4', class: 'text-center table-size-200-400' },
      //   { label: 'ค่าบิล (บาท)/ต่อครั้ง', key: 'variable5', class: 'text-center table-size-200-400' },
      //   { label: 'เวลามาตรฐานให้บริการ (นาที)', key: 'variable6', class: 'text-center table-size-200-400' },
      //   { label: 'เวลาให้บริการ', key: 'variable7', class: 'text-center table-size-200-400' },
      //   { label: 'สถานะ', key: 'variable8', class: 'text-center' },
      //   { label: 'จัดการ', key: 'variable9', class: 'text-center' }
      // ],
      // options: [
      //   { value: 'percent', name: 'ค่าบิล (%)/ต่อครั้ง' },
      //   { value: 'baht', name: 'ค่าบิล (บาท)/ต่อครั้ง' }
      // ],
      currentPage: 1,
      perPage: 10,
      serviceAllLength: 0
    }
  },
  created () {
    this.initializeToggleStates()
  },
  watch: {
    'filter.include_active': function (newVal) {
      this.getService()
    },
    'filter.include_unactive': function (newVal) {
      this.getService()
    }
  },
  async mounted () {
    xray.index()
    this.cln_id = JSON.parse(sessionStorage.getItem('user')).cln_id
    await this.getService()
    await this.getServiceAll()
    await this.getCommissionDD()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getService()
      }
    },
    handleCommissionOptionChange (index, newValue) {
      this.service[index].cot_id = newValue !== null ? newValue.value : null
      this.service[index].cot_name = newValue !== null ? newValue.label : null
    },
    addCommas (number) {
      return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    convertToJson (item) {
      let dataList = {}
      dataList = {
        price: parseFloat(item.price.replace(/,/g, '')),
        pay: parseFloat(item.pay.replace(/,/g, '')),
        normal_price: parseFloat(item.normal_price.replace(/,/g, '')),
        minimum_price: parseFloat(item.minimum_price.replace(/,/g, '')),
        time: item.time,
        cot_id: item.cot_name.value || item.cot_id || null,
        cot_name: item.cot_name.label || item.cot_name || null
      }
      return dataList
    },
    convert1ToJson (check) {
      let dataList = {}
      dataList = {
        is_active: check
      }
      return dataList
    },
    async getService () {
      let search = this.searchTerm.trim().length > 0 ? this.searchTerm.trim() : ''
      let serviceAPI = {
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/service?search=${search}&active=${this.filter.include_active}&unactive=${this.filter.include_unactive}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      axios.request(serviceAPI)
        .then((response) => {
          this.service = response.data.data.map(item => {
            return { ...item, editable: false }
          })
          this.serviceTemp = response.data.data.map(item => {
            return { ...item, editable: false }
          })
        })/* .catch((error) => {
          console.error(error)
        }) */
    },
    async getServiceAll () {
      let search = this.searchTerm.trim().length > 0 ? this.searchTerm.trim() : ''
      let serviceAPI = {
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/service?search=` + search,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      axios.request(serviceAPI)
        .then((response) => {
          this.serviceAllLength = response.data.data.length
        })/* .catch((error) => {
          console.error(error)
        }) */
    },
    editService (item) {
      let editserviceAPI = {
        method: 'put',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/service/` + item.id,
        headers: {
          'Content-Type': 'application/json'
        },
        data: this.convertToJson(item)
      }
      axios.request(editserviceAPI)
        .then((response) => {
          // this.getService()
          // this.service = response.data.data.map(item => {
          //   return { ...item, editable: false }
          // })
        })
    },
    editStatus (item, check) {
      let editstatusAPI = {
        method: 'put',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/service/` + item.id,
        headers: {
          'Content-Type': 'application/json'
        },
        data: this.convert1ToJson(check)
      }
      axios.request(editstatusAPI)
        .then((response) => {
          // this.service = response.data.data.map(item => {
          //   return { ...item, editable: false }
          // })
        })
    },
    async getCommissionDD () {
      let getCommissionDDAPI = {
        method: 'get',
        url: `${configAPI.MD_PORT}/api/commissionType`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      axios.request(getCommissionDDAPI).then((response) => {
        this.commissionDD = response.data.data?.map(el => {
          return {
            value: el.id,
            label: el.name
          }
        })
        let same = this.commissionDD?.filter(item => item.id === this.OLDData?.data[0]?.commission_id)
        if (same.length > 0) {
          this.commissionOption = { value: same[0].id, label: same[0].name }
        } else {
          this.commissionOption = null
        }
      })
    },
    setCot (item, cot) {
      if (cot) {
        item.cot_id = cot.value
        item.cot_name = cot.label
      }
    },
    initializeToggleStates () {
      this.toggleStates = Array(this.numberOfToggleSwitches).fill(true)
    },
    toggle () {
      this.toggleState = !this.toggleState
    },
    handleImageUpload (event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.imageUrl = reader.result
      }
    },
    handleImageUpload2 (event) {
      const file2 = event.target.files[0]
      const reader2 = new FileReader()
      reader2.readAsDataURL(file2)
      reader2.onload = () => {
        this.imageUrl2 = reader2.result
      }
    },
    handleClick () {
      router.push('/Product')
    },
    onPagination (page) {
      this.currentPage = page
    },
    add () {
      let obj = this.default()
      this.rows1.push(obj)
    },
    edit (item) {
      this.serviceTemp.map((st) => {
        if (st.id === item.id) {
          // this.serviceTemp.id = item.id
          st.price = item.price
          st.pay = item.pay
          st.time = item.time
          st.cot_id = item.cot_id
          st.cot_name = item.cot_name
          st.normal_price = item.normal_price
          st.minimum_price = item.minimum_price
        }
      })
      // this.serviceTemp.id = item.id
      // this.serviceTemp.price = item.price
      // this.serviceTemp.pay = item.pay
      // this.serviceTemp.time = item.time
      item.editable = true
    },
    submit (item) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const errorList = []
          if (item.price === '' || item.price === null) {
            errorList.push({
              message: 'กรุณากรอกราคาขาย'
            })
          }
          if (item.pay === '' || item.pay === null) {
            errorList.push({
              message: 'กรุณากรอกค่ามือต่อครั้ง'
            })
          }
          if (item.cot_name === '' || item.cot_name === null || item.cot_name === undefined) {
            errorList.push({
              message: 'กรุณาเลือกค่าคอมมิชชั่น'
            })
          }
          if (item.normal_price === '' || item.normal_price === null) {
            errorList.push({
              message: 'กรุณากรอกราคาขายปกติ'
            })
          }
          if (item.minimum_price === '' || item.minimum_price === null) {
            errorList.push({
              message: 'กรุณากรอกราคาขายขั้นต่ำ'
            })
          }
          // console.log(item.pay, item.cot_name)
          if (item.cot_name) {
            if (item.cot_name.value === 'f33ea86c-d9f1-4a64-a97b-ba30074f0fd7' && item.cot_name.label === 'คิดเป็น % ราคาขาย' && item.pay > 100) {
              errorList.push({
                message: 'ไม่สามารถกรอก % เกิน 100'
              })
            }
          }
          if (item.time === '' || item.time === null) {
            errorList.push({
              message: 'กรุณากรอกเวลาให้บริการ'
            })
          }
          if (errorList.length > 0) {
            let errorMessage = ''
            for (let e = 0; e < errorList.length; e++) {
              errorMessage += '<br/>' + errorList[e].message
            }

            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">Error</span>',
              html: errorMessage,
              icon: 'error',
              confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
              confirmButtonColor: '#089bab',
              customClass: {
                confirmButton: 'custom-confirm-button-class mx-1'
              }
            })
          } else {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              this.editService(item)
              item.editable = false
            })
          }
        }
      })
    },
    remove (item) {
      let index = this.service.indexOf(item)
      item.splice(index, 1)
    },
    redo (item) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะยกเลิกการบันทึกข้อมูลใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.serviceTemp.map((s) => {
            if (item.id === s.id) {
              item.price = s.price
              item.pay = s.pay
              item.time = s.time
              item.cot_id = s.cot_id
              item.cot_name = s.cot_name
              item.editable = false
              item.normal_price = s.normal_price
              item.minimum_price = s.minimum_price
            }
          })
          // if (index !== -1) {
          //   console.log('Index found:', index)
          // } else {
          //   console.log('Index not found')
          // }
          // item.price = this.serviceTemp.price
          // item.pay = this.serviceTemp.pay
          // item.time = this.serviceTemp.time
          // item.editable = false
        }
      })
    },
    changeTab () {
      // console.log(this.service)
      // this.service.map(item => {
      //   item.editable = false
      // })
    },
    isNumberComma1 (event, id) {
      let indexData = this.service.findIndex((item) => { return item.id === id })
      let useData = event.target.value

      this.service[indexData].price = useData.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
    },
    isNumberComma2 (event, id) {
      let indexData = this.service.findIndex((item) => { return item.id === id })
      let useData = event.target.value

      this.service[indexData].pay = useData.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
    },
    isNumberComma3 (event, id) {
      let indexData = this.service.findIndex((item) => { return item.id === id })
      let useData = event.target.value

      this.service[indexData].time = useData.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    isNumberComma4 (event, id) {
      let indexData = this.service.findIndex((item) => { return item.id === id })
      let useData = event.target.value

      this.service[indexData].normal_price = useData.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
    },
    isNumberComma5 (event, id) {
      let indexData = this.service.findIndex((item) => { return item.id === id })
      let useData = event.target.value

      this.service[indexData].minimum_price = useData.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
    },

    isNumberCheck (keyPressed) {
      // let indexData = this.rows1.findIndex((item) => { return item.id === id })
      let useData = keyPressed.target.value

      let keyCode = (keyPressed.keyCode ? keyPressed.keyCode : keyPressed.which)

      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || useData.indexOf('.') !== -1)) {
        keyPressed.preventDefault()
      }

      if (useData !== null && useData.indexOf('.') > -1 && (useData.split('.')[1].length > 1)) {
        keyPressed.preventDefault()
      }

      if ((useData === null || useData === '') && keyCode === 46) {
        keyPressed.preventDefault()
      }
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    }
  }
}
</script>
