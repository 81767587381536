<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">ซัพพลายเออร์</span>
              </div>
              <div class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button @click="addEditPage('add')" type="submit" variant="primary-custom" class="w-100 btn-min-size"><i
                    class="circle-plus_ct"></i>เพิ่ม</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" md="7" lg="5" class="mb-3">
                  <b-row>
                    <b-col sm="12" md class="mb-3" style="padding: 0px;">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input class="form-control" id="exampleInputEmail1" placeholder="Search" style="width: 100%;"
                              v-model="searchItem" @keydown="enterSearch">
                            <a class="search-link" v-on:click="getSupplier"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center; padding: 0px;">
                      <span class="mx-2 font-size-14 text-primary">
                        {{ supplier?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > supplier?.length) ? supplier?.length : (perPage * currentPage) ) + ' of ' + supplierLength : '0 of ' + supplierLength }} รายการ
                        <!-- {{ supplier?.length > 0 ? "1 - " + supplier?.length + " of " +
                          supplier?.length : "0 - 0 of 0" }} -->
                      </span></b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="table-responsive" style="padding: 0px;">
                  <b-table bordered hover :items="supplier" :fields="columns" :current-page="currentPage"
                    :per-page="perPage" @pagination="onPagination" :tbody-tr-class="rowClass"
                    thead-class="table-header-color">
                    <template v-slot:cell(variable1)="data">
                      <span>{{ ((currentPage - 1) * perPage) + data.index + 1 }}</span>
                    </template>
                    <template v-slot:cell(variable2)="data">
                      <span>{{ data.item.name || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable3)="data">
                      <span>{{ data.item.detail || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable4)="data">
                      <span>{{ data.item.line_id || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable5)="data">
                      <span>{{ data.item.constact_name || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable6)="data">
                      <span>{{ data.item.contact_tel ? formatTel(data.item.contact_tel) : '-' }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button variant=" iq-bg-primary mr-1 mb-1" size="sm" @click="addEditPage('view', data.item.id)"><i
                          class="ri-eye-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-success mx-1 mb-1" size="sm" @click="addEditPage('edit', data.item.id)"><i
                          class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-danger ml-1 mb-1" size="sm" @click="removeData(data.item.id)"><i
                          class="ri-delete-bin-line m-0"></i></b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row style="display: flex; justify-content: center;">
                <b-pagination v-model="currentPage" :total-rows="supplier?.length" :per-page="perPage" />
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import axios from 'axios'
import { configAPI } from '../../configBase'

export default {
  name: 'Supplier',
  data () {
    return {
      supplierLength: 0,
      columns: [
        { label: 'ลำดับ', key: 'variable1', class: 'text-center table-size-100-300' },
        { label: 'ซัพพลายเออร์', key: 'variable2', class: 'text-center table-size-200-400 ellips' },
        { label: 'รายละเอียดซัพพลายเออร์', key: 'variable3', class: 'text-center table-size-200-400 ellips' },
        { label: 'ไลน์ ไอดี', key: 'variable4', class: 'text-center table-size-200-400 ellips' },
        { label: 'ชื่อ - นามสกุล', key: 'variable5', class: 'text-center table-size-200-400 ellips' },
        { label: 'เบอร์โทรศัพท์', key: 'variable6', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'action', class: 'text-center table-size-200-400' }
      ],
      supplier: [],
      currentPage: 1,
      perPage: 10,
      searchItem: '',
      userData: {}
    }
  },
  mounted () {
    xray.index()
    this.userData = JSON.parse(sessionStorage.getItem('user'))
    this.getSupplier()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getSupplier()
      }
    },
    formatTel (data) {
      return data.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
    },
    addEditPage (page, id) {
      if (page === 'add') {
        router.push(`/SupplierDetail`)
      } else {
        router.push(`/SupplierDetail/${page}/${id}`)
      }
    },
    async getSupplier () {
      let search = this.searchItem.trim().length > 0 ? this.searchItem.trim() : ''
      let supplierAPI = {
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.userData.cln_id}/supplier?search=${search}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(supplierAPI)
        .then((response) => {
          if (response.data.status) {
            this.supplier = response.data.data?.map((item, index) => {
              item.index = index
              return item
            })
            this.supplierLength = response.data.count || 0
          }
        })
        .catch((error) => {
          document.getElementById(error)
        })
    },
    removeData (id) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะลบข้อมูลใช่หรือไม่?</span>',
        // text: 'ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let delSupplierAPI = {
            method: 'post',
            url: `${configAPI.MA_PORT}/api/clinic/${this.userData.cln_id}/supplier/${id}/delete`,
            headers: {
              'Content-Type': 'application/json'
            }
          }

          axios.request(delSupplierAPI).then(() => {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              this.getSupplier()
            })
          }).catch(() => {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลไม่สำเร็จ</span>',
              text: ``,
              icon: 'error',
              confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
              confirmButtonColor: '#089bab'
            })
          })
        }
      })
    },
    onPagination (page) {
      this.currentPage = page
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    }
  }
}
</script>
