import Vue from 'vue'
import Vuex from 'vuex'
import Setting from './Setting/index'
import Chat from './Chat/index'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    Setting,
    Chat
  },
  state: {
    addUserManage: '',
    sharedData: '',
    LatLngData: '',
    getLatLngData: '',
    DataTab2: '',
    address: '',
    addressComponent: ''
  },
  mutations: {
    setSharedData (state, newData) {
      state.sharedData = newData
    },
    setLatLngData (state, newData) {
      state.LatLngData = newData
    },
    setgetLatLngData (state, newData) {
      state.getLatLngData = newData
    },
    setDataTab2 (state, newData) {
      state.DataTab2 = newData
    },
    setAddUserManage (state, newData) {
      state.addUserManage = newData
    },
    setAddress (state, newData) {
      state.address = newData
    },
    setAddressComponent (state, newData) {
      state.addressComponent = newData
    }
  },
  actions: {
    updateAddUserManage ({ commit }, newData) {
      commit('setAddUserManage', newData)
    },
    updateSharedData ({ commit }, newData) {
      commit('setSharedData', newData)
    },
    updateLatLngData ({ commit }, newData) {
      commit('setLatLngData', newData)
    },
    updategetLatLngData ({ commit }, newData) {
      commit('setgetLatLngData', newData)
    },
    updateDataTab2 ({ commit }, newData) {
      commit('setDataTab2', newData)
    },
    updateAddress ({ commit }, newData) {
      commit('setAddress', newData)
    },
    updateAddressComponent ({ commit }, newData) {
      commit('setAddressComponent', newData)
    }
  },
  getters: {
  },
  strict: debug
})
