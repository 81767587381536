<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">อัปโหลดเอกสารการเงิน</span>
              </div>
              <div class="col-12 col-sm-auto my-1 my-sm-0">
                <div class="row">
                  <div class="col col-sm-7 pl-sm-2 pr-sm-2 my-1 my-sm-0">
                    <b-button type="submit" @click="clickSave" variant="primary-custom" class="w-100 btn-min-size"><i
                        class="send-data_ct"></i>ส่งเอกสาร</b-button>
                  </div>
                  <div class="col col-sm-5 pl-sm-2 pr-sm-2 my-1 my-sm-0">
                    <b-button type="submit" @click="resetInput" variant="none"
                      class="w-100 btn-min-size btn-reset_ct">ล้างข้อมูล</b-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <div class="row">
                <div class="col">
                  <div class="lock-image">
                    <img class="Image1" :src="imgPath || defaultImg"
                      style="height: 150px; width:150px; margin: 10px 30px 10px 0px;" :alt="imgName">
                    <img class="Image2" :src="qr_path || defaultImg"
                      style="height: 150px; width:150px; margin: 10px 0px 10px 30px;" :alt="qr_name">
                  </div>
                </div>

                <div class="col">
                  <div class="lock-image2 my-3">
                    <div class="image-input">
                      <span style="color: black; white-space: nowrap; padding-right: 20px;">อัปโหลดเอกสาร</span>
                      <b-form-group label-for="imageInput">
                        <b-form-file id="imageInput" accept="*"
                          v-model="uploadDoc" placeholder="" browse-text="เลือกไฟล์"></b-form-file>
                      </b-form-group>
                    </div>

                    <div class="image-input">
                      <span style="color: black; white-space: nowrap; padding-right: 53px; margin-bottom: 25px;">จำนวนเงิน</span>
                      <CurrencyInputCom v-model="amount" :modelValue="amount" :options="optionCurrency" class="size-input-box"/>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div style="color: black; font-size: 15px;">
                    <label class="mr-2" style="white-space: nowrap;">วงเงินเครดิตที่คงค้างในระบบ</label>
                    <label class="mr-2" style="font-size: large;">{{ numberWithCommas(cln_detail.total_credit_approve || 0)
                    }}</label>
                    <label>บาท</label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div style="color: black; font-size: 15px;">
                    <label class="mr-2" style="white-space: nowrap;">วงเงินเครดิตที่รอยืนยันในระบบ</label>
                    <label class="mr-2" style="font-size: large;">{{ numberWithCommas(cln_detail.total_credit_waiting_approve || 0)
                    }}</label>
                    <label>บาท</label>
                  </div>
                </div>
              </div>

              <label style="color: black; font-size: 20px;">ประวัติการใช้วงเงิน</label>

              <b-row>
                <b-col cols="12" class="table-responsive" style="padding: 0px;">
                  <b-table bordered hover :items="rows" :fields="columns" :current-page="currentPage" :per-page="perPage"
                    @pagination="onPagination" :tbody-tr-class="rowClass" thead-class="table-header-color">
                    <template v-slot:cell(variable1)="data">
                      <span>{{ data.item.created_date ? dateFormat(data.item.created_date) : '-' }}</span>
                    </template>
                    <template v-slot:cell(variable2)="data">
                      <span>{{ data.item.fs_status || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable3)="data">
                      <span>{{ data.item.menu_name || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable4)="data">
                      <span>{{ data.item.amount ? numberWithCommas(data.item.amount) : '-' }}</span>
                    </template>
                    <template v-slot:cell(variable5)="data">
                      <span>{{ data.item.fsa_status }}</span>
                    </template>
                    <template v-slot:cell(variable6)="data">
                      <!-- <a v-if="data.item.upload_path" :href="downloadFile(data.item.upload_path, data.item.upload_name)"
                        :download="data.item.upload_name"> -->
                        <b-button v-if="data.item.upload_path" type="submit" @click="downloadFile(data.item.upload_path, data.item.upload_name)" variant="primary-custom"><i class="in-data_ct"></i>{{ 'ดาวน์โหลด' }}</b-button>
                      <!-- </a> -->
                      <b-button v-else disabled type="submit" variant="primary"><i class="in-data_ct"></i>{{ 'ดาวน์โหลด'
                      }}</b-button>
                    </template>
                    <template v-slot:cell(variable7)="data">
                      <span>{{ data.item.created_by_fullname }}</span>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row style="display: flex; justify-content: center;">
                <b-pagination v-model="currentPage" :total-rows="rows?.length" :per-page="perPage" />
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
// import router from '@/router'
import Vue from 'vue'
import axios from 'axios'
import moment from 'moment'
import CurrencyInputCom from './CurrencyInputCom.vue'
import { configAPI } from '../../configBase'
// import { configProd } from '../../configBase'

export default {
  name: 'UiDataTable',
  components: {
    CurrencyInputCom
  },
  data () {
    return {
      optionCurrency: {
        currency: 'EUR',
        currencyDisplay: 'hidden',
        valueRange: {
          min: 0,
          max: 1e+53
        },
        precision: 2,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: true,
        accountingSign: false
      },
      user_id: '',
      cln_id: '',
      cln_detail: {},
      defaultImg: require('../../assets/images/default.jpg'),
      uploadDoc: '',
      imgPath: '',
      imgName: '',
      qr_name: '',
      qr_path: '',
      amount: '',
      columns: [
        { label: 'วันที่', key: 'variable1', class: 'text-center table-size-200-400' },
        { label: 'ประเภท', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'เมนู', key: 'variable3', class: 'text-center table-size-200-400' },
        { label: 'ยอดเงิน', key: 'variable4', class: 'text-center table-size-200-400' },
        { label: 'สถานะตรวจสอบ', key: 'variable5', class: 'text-center table-size-200-400' },
        { label: 'หลักฐานการโอน', key: 'variable6', class: 'text-center table-size-200-400' },
        { label: 'ผู้บันทึก', key: 'variable7', class: 'text-center table-size-200-400' }
      ],
      rows: [],
      currentPage: 1,
      perPage: 10
    }
  },
  methods: {
    dateFormat (data) {
      return moment(data).format('DD/MM/YYYY')
    },
    numberWithCommas (data) {
      return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    async getCreditDetail () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/credit/detail`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          this.cln_detail = response.data.data
          this.imgPath = configAPI.MA_PORT + response.data.data.cln_img_path?.substring(1)
          this.imgName = response.data.data.cln_img
          this.qr_path = configAPI.MA_PORT + response.data.data.gc_qrcode_path?.substring(1)
          this.qr_name = response.data.data.gc_qrcode
        })
        .catch((error) => {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาดในการดึงข้อมูล</span>`,
            html: '<span>' + error.message + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async getCreditClinic () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/credit`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          if (response.data.data.length > 0) {
            this.rows = response.data.data?.map((item, index) => {
              item.index = index
              return item
            })
          } else {
            this.rows = []
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาดในการดึงข้อมูล</span>`,
            html: '<span>' + error.message + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async clickSave () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          const errorList = []

          if (this.uploadDoc === '' || this.uploadDoc === null) {
            errorList.push('กรุณาเลือกเอกสารที่ต้องการจะอัปโหลด')
          }

          if (this.amount === 0 || this.amount === '' || this.amount === null) {
            errorList.push('กรุณาระบุจำนวนเงินที่ต้องการ')
          }

          if (errorList.length > 0) {
            let errorMessage = ''

            for (let e = 0; e < errorList.length; e++) {
              errorMessage += errorList[e] + '<br/>'
            }

            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลไม่สำเร็จ</span>',
              html: errorMessage,
              icon: 'error',
              showConfirmButton: true,
              confirmButtonText: '<b-button style="font-size: 14px; text-align: center">Ok</b-button>',
              confirmButtonColor: '#089bab',
              customClass: {
                confirmButton: 'custom-confirm-button-class mx-1'
              }
            })
          } else {
            let uploadAPI = {}

            const tempData1 = {
              amount: this.amount,
              menu_id: 'a96035d8-5758-4300-929c-bd98c8bcb7e0',
              created_by: this.user_id
            }

            uploadAPI = {
              method: 'post',
              url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/credit`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: tempData1
            }

            await axios.request(uploadAPI).then(async (response1) => {
              const tempData2 = new FormData()
              tempData2.append('file', this.uploadDoc)

              const axiosConfig = {
                method: 'post',
                url: `${configAPI.MA_PORT}/api/credit/${response1.data.data.id}/upload/doc`,
                headers: {
                  'Content-Type': 'application/json'
                },
                data: tempData2
              }

              await axios.request(axiosConfig).then(async () => {})

              await Vue.swal({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
                icon: 'success',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000
              }).then(() => {
                window.location.reload()
              })
            })
          }
        }
      })
    },
    async downloadFile (pathFile, nameFile) {
      let pathApi = configAPI.MA_PORT + pathFile?.substring(1)

      await axios({
        method: 'get',
        url: pathApi,
        responseType: 'blob'
      })
        .then((response) => {
          let fileUrl = window.URL.createObjectURL(new Blob([response.data]))
          let fileLink = document.createElement('a')
          fileLink.href = fileUrl

          fileLink.setAttribute('download', nameFile)
          document.body.appendChild(fileLink)

          fileLink.click()
        })
        .catch(() => {
          this.SwalError('ไม่พบไฟล์นี้ในฐานข้อมูล', 'ดาวน์โหลดเอกสารไม่สำเร็จ')
        })
    },
    handleImageUpload (event) {
      const file = event.target.files[0]
      // if (this.fileFormat.includes(file.type)) {
      //   const reader = new FileReader()
      //   reader.onload = () => {
      //     this.imageUrl = reader.result
      //   }
      //   reader.readAsDataURL(file)
      //   this.saveImage = file
      // }
      this.uploadDoc = file || ''
    },
    onPagination (page) {
      this.currentPage = page
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    resetInput () {
      this.amount = null
      this.uploadDoc = ''
    }
  },
  async mounted () {
    xray.index()

    this.user_id = JSON.parse(sessionStorage.getItem('user')).usr_id
    this.cln_id = JSON.parse(sessionStorage.getItem('user')).cln_id

    await this.getCreditDetail()
    await this.getCreditClinic()
  }
}
</script>

<style scoped>
.lock-image {
  display: inline-flex;
  flex-direction: row;
}
.lock-image2 {
  flex-direction: column;
  display: flex;
}
.image-input {
  display: flex;
  align-items: baseline;
}
/* .image-input-box {
  border-radius: 10px;
  color: black;
  width: 100%;
  height: 40px;
  min-width: 228px;
  border-color: black;
} */
.size-input-box {
  /* color: black !important;
  border-radius: 5px !important;
  text-align: center !important; */
  width: 100% !important;
  max-width: 300px !important;
  /* height: 38px !important;
  border: 1px solid #ced4da !important; */
}
@media (max-width: 454px) {
  .image-input {
    width: 100%;
    flex-direction: column;
  }
}
@media (max-width: 451px) {
  .Image1 {
    width: 130px !important;
    height: 130px !important;
  }
  .Image2 {
    width: 130px !important;
    height: 130px !important;
  }
  .lock-image {
    width: 100%;
    justify-content: space-evenly;
  }
}
@media (max-width: 425px) {
  .Image1 {
    width: 180px !important;
    height: 180px !important;
    margin: 0px 20px 15px 20px !important;
  }
  .Image2 {
    width: 180px !important;
    height: 180px !important;
    margin: 15px 20px 0px 20px !important;
  }
  .lock-image {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 411px) {
  .Image1 {
    width: 180px !important;
    height: 180px !important;
    margin: 0px 20px 15px 20px !important;
  }
  .Image2 {
    width: 180px !important;
    height: 180px !important;
    margin: 15px 20px 0px 20px !important;
  }
  .lock-image {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}
</style>
