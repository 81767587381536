<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">ยอดสะสม</span>
              </div>
              <div class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="button" variant="primary-custom" @click="handleClick('add')" class="w-100 btn-min-size"><i class="circle-plus_ct"/>เพิ่ม</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col cols="12" md="7" lg="5" class="mb-3">
                <b-row>
                  <b-col sm="12" md class="mb-3">
                    <div class="iq-email-search d-flex">
                      <div style="width: 100%;">
                        <div class="form-group mb-0">
                          <input class="form-control" id="exampleInputEmail1" placeholder="Search" style="width: 100%;"
                            v-model="searchTerm" @keydown="enterSearch">
                          <a class="search-link" v-on:click="getCumulative"><i class="ri-search-line"/></a>
                        </div>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                    <span class="mx-2 font-size-14 text-primary">
                      {{ cumulativeData?.length > 0 ? (((currentPage - 1) * 10) + (cumulativeData?.length > 0 ? 1 : 0)) + ' - ' + (((perPage * currentPage) > cumulativeData?.length) ? cumulativeData?.length : (perPage * currentPage) ) + ' of ' + cumulativeLength : '0 of ' + cumulativeLength }} รายการ
                    </span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table bordered hover :items="cumulativeData" :fields="columns"
                  :current-page="currentPage"
                  :per-page="perPage"
                  @pagination="onPagination"
                  :tbody-tr-class="rowClass" thead-class="table-header-color"
                >
                  <template v-slot:cell(variable1)="data">
                    <span>{{ data.item.name || '-' }}</span>
                  </template>
                  <template v-slot:cell(variable2)="data">
                    <span>{{ data.item.reward || '-' }}</span>
                  </template>
                  <template v-slot:cell(variable3)="data">
                    <span>{{ thousandSeperate(data.item.total ? parseFloat(data.item.total).toFixed(2) : '') || '-' }}</span>
                  </template>
                  <template v-slot:cell(sum_price)="data">
                    <span>{{ thousandSeperate(data.item.sum_price ? data.item.sum_price : '') || '-' }}</span>
                  </template>
                  <template v-slot:cell(variable4)="data">
                    <span>{{ thousandSeperate(data.item.sum_amount ? data.item.sum_amount : '') || '-' }}</span>
                  </template>
                  <template v-slot:cell(variable5)="data">
                    <span>{{ thousandSeperate(data.item.sum_per_price ? data.item.sum_per_price : '') || '-' }}</span>
                  </template>
                  <template v-slot:cell(variable6)="data">
                    <span>{{ data.item.remark || '-' }}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <div style="display: flex; justify-content: center;">
                      <b-button variant=" iq-bg-info mx-1" size="sm" @click="handleClick('view', data.item.id)"><i class="ri-eye-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-success mr-1" size="sm" @click="handleClick('edit', data.item.id)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item.id)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </div>
                  </template>
                </b-table>
                <br>
              </b-col>
            </b-row>
            <b-pagination
                v-model="currentPage"
                :total-rows="cumulativeData?.length"
                :per-page="perPage"
                align="center"
              />
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.group-response-header {
  align-items: center !important;
}
.button-response-header-item {
  widows: 100% !important;
}
.Cbutton-response-header {
  margin-left: 0;
  width: 70px !important;
}
@media (max-width: 768px) {
  .Cbutton-response-header {
  margin-left: 1rem;
  width: 70px !important;
}
}
@media (max-width: 570px) {
  .text-response-header {
    margin-left: 0.5rem !important;
  }
  .group-response-header {
    flex-direction: column;
    display: flex;
    align-items: start !important;
  }
  .Cbutton-response-header {
    margin-top: 0.5rem !important;
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
    width: 96% !important;
  }
  .button-response-header-item {
    width: 96% !important;
  }
}
@media (max-width: 480px) {
  .text-response-header {
    margin-left: 0.5rem !important;
  }
  .group-response-header {
    flex-direction: column;
    display: flex;
    align-items: start !important;
  }
  .button-response-header {
    margin-top: 0.5rem !important;
    margin-left: 0;
    width: 100% !important;
  }
  .button-response-header-item {
    width: 96% !important;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import axios from 'axios'
import { configAPI } from '../../configBase'

export default {
  name: 'UiDataTable',
  data () {
    return {
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      searchTerm: '',
      cumulativeData: null,
      cumulativeLength: 0,
      columns: [
        { label: 'ชื่อยอดสะสม', key: 'variable1', class: 'text-center table-size-200-400 ellips' },
        { label: 'Reward', key: 'variable2', class: 'text-center table-size-200-400 ellips' },
        { label: 'เป้ายอดรวม', key: 'variable3', class: 'text-center table-size-200-400' },
        { label: 'ยอดรวม', key: 'sum_price', class: 'text-center table-size-200-400' },
        { label: 'จำนวน', key: 'variable4', class: 'text-center table-size-200-400' },
        { label: 'เปอร์เซ็นต์ความสำเร็จ', key: 'variable5', class: 'text-center table-size-200-400' },
        { label: 'Remark', key: 'variable6', class: 'text-center table-size-200-400 ellips' },
        { label: 'จัดการ', key: 'action', class: 'text-center' }
      ],
      currentPage: 1,
      perPage: 10
    }
  },
  async mounted () {
    xray.index()
    await this.getCumulative()
  },
  methods: {
    default () {
      return {
        variable1: null,
        variable2: null,
        variable3: null,
        variable4: null,
        variable5: null,
        variable6: null
      }
    },
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getCumulative()
      }
    },
    async getCumulative () {
      let cumulativeAPI = {
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/cumulative?search=${this.searchTerm?.trim()}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(cumulativeAPI)
        .then((response) => {
          this.cumulativeData = response.data.data
          this.cumulativeLength = response.data.count
        })
    },
    remove (item) {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะลบข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let deleteCumulativeAPI = {
            method: 'POST',
            url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/cumulative/${item}/delete`,
            headers: {
              'Content-Type': 'application/json'
            }
          }
          axios.request(deleteCumulativeAPI)
            .then((response) => {
              if (response.data.status === true) {
                Vue.swal({
                  title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
                  icon: 'success',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  timer: 2000
                }).then(async () => {
                  await this.getCumulative()
                })
              }
            })
        }
      })
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick (mode, itemSend) {
      if (mode === 'add') {
        router.push('/AddCumulative')
      } else {
        router.push(`/CumulativeEV/${mode}/${itemSend}`)
      }
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    thousandSeperate (number) {
      return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}
</script>
