<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">ทะเบียนลูกค้า / ผู้ป่วย</span>
              </div>
              <div class="col-12 col-sm-12 col-md-auto">
                <div class="row res-flex-header">
                  <div class="col-lg-5 col-md-5 col-sm-6 col-6 col-6-to-12 pl-2 pr-2 my-1 my-sm-0">
                    <b-button type="button" variant="primary" @click="handleClick('add')" class="w-100 btn-min-size"><i class="add-cus_ct"/>เพิ่มชื่อผู้ป่วย</b-button>
                  </div>
                  <div class="col-lg-7 col-md-7 col-sm-6 col-6 col-6-to-12 pl-2 pr-2 my-1 my-sm-0">
                    <b-button type="button" variant="primary" @click="handleClick('service')" style="white-space: nowrap;" class="w-100 padding-patient-header btn-min-size"><i class="add-doc_ct"/>เพิ่มประวัติการรับบริการ</b-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!--  <template v-slot:headerTitle>
            <div class="row align-items-center py-4">
              <div class="ml-4">
                <span class="mx-1" style="font-size: 25px; color: black;">ทะเบียนลูกค้า / ผู้ป่วย</span>
              </div>
              <div class="col-lg-1 col-md-1 col-sm-2 col-12">
                <div class="d-flex my-2" style="white-space: nowrap;">
                  <b-button  @click="handleClick('add')" type="submit" variant="primary" class="mx-1 flex-fill text-center" style="text-align: center;">เพิ่ม</b-button>
                </div>
              </div>
            </div>
          </template> -->
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <!-- <b-col cols="12" md="7" lg="5">
                  <b-row> -->
                    <b-col sm="12" md="4" xl="4" class="mb-3">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input v-model="search" @keydown="enterSearch" class="form-control" id="exampleInputEmail1" placeholder="Search" style="width: 100%;">
                            <a class="search-link" @click="getPatientData"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                      <span class="mx-2 font-size-14 text-primary">
                        {{ PatientData?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > PatientData?.length) ? PatientData?.length : (perPage * currentPage) ) + ' of ' + AllDataLength : '0 of ' + AllDataLength }} รายการ
                      </span>
                    <!-- </b-col>
                  </b-row> -->
                </b-col>
              </b-row>
              </div>
              <br>
              <div style="text-align: end;">
                <span style="color: rgb(251, 0, 0);">หมายเหตุ: <i style="color: #024187;" class="ri-circle-fill"></i> <span style="color: black;">OPD ที่ปิดแล้ว</span>, <i style="color: #27b345;" class="ri-circle-fill"></i> <span style="color: black;">OPD ที่ยังไม่ได้ปิด</span></span>
              </div>
              <b-col md="12" class="table-container p-0">
                <div class="table-wrapper-patient">
                  <b-table bordered hover :items="PatientData" :fields="columns"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @pagination="onPagination"
                    :tbody-tr-class="rowClass" thead-class="table-header-color"
                  >
                  <template v-slot:cell(HN_no)="data">
                    <span>{{ data.item.HN_no }}</span>
                  </template>
                  <template v-slot:cell(fullname)="data">
                    <div class="div-patient-table">
                      <span>{{ data.item.fullname }}</span>
                    </div>
                  </template>
                  <template v-slot:cell(gender)="data">
                    <span>{{ data.item.gender }}</span>
                  </template>
                  <template v-slot:cell(tel)="data">
                    <span>{{ formatTel(data.item.tel) }}</span>
                  </template>
                  <template v-slot:cell(birthday)="data">
                    <span>{{ Formatbirthday(data.item.birthday) }}</span>
                  </template>
                  <template v-slot:cell(img_path)="data">
                    <b-img :src="getImagePath(data.item.img_path)" style="height: auto; width:100px;"></b-img>
                  </template>
                  <template v-slot:cell(nickname)="data">
                    <div class="div-patient-table">
                      <span>{{ data.item.nickname }}</span>
                    </div>
                  </template>
                  <template v-slot:cell(entry_date)="data">
                    <span>{{ data.item.entry_date }}</span>
                  </template>
                  <template v-slot:cell(history_service)="data">
                    <div style="max-height: 120px; overflow: auto;">
                      <span v-for="(item1, index) in data.item.history_service" :key="index">
                        <br v-if="index !== 0">
                        <a @click="ViewOrEditHisOPD(item1)" :style="'color: ' + (item1.is_close ? '#024187;' : '#27b345;') + 'cursor: pointer;'"><u>{{ item1.no }}</u></a>
                      </span>
                    </div>
                  </template>
                  <template v-slot:cell(management)="data">
                    <div style="display: flex; justify-content: center;">
                      <b-button variant=" iq-bg-primary ml-1" size="sm" @click="handleClick('viewHistory', data.item)"><i class="ri-database-2-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-info ml-1" size="sm" @click="handleClick('view', data.item)"><i class="ri-eye-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-success mx-1" size="sm" id="show-modal" @click="handleClick('edit', data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <!-- <b-button variant=" iq-bg-success mx-1" size="sm" @click="submit(data.item)" v-else>Ok</b-button> -->
                      <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </div>
                  </template>
                </b-table>
              </div>
              <br>
            </b-col>
            <b-pagination
                v-model="currentPage"
                :total-rows="PatientData?.length"
                :per-page="perPage"
                align="center"
              />
              <!-- <div>
                <multiselect
                  v-model="selected"
                  label="name"
                  track-by="name"
                  placeholder="Select"
                  :options="options">
                </multiselect>
                <button type="button" @click="testLog">click</button>
              </div> -->
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.table-container {
  overflow-x: auto;
}

.table-wrapper-patient {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-wrapper-patient .b-table tbody tr td:first-child,
.table-wrapper-patient .b-table thead tr th:first-child {
  position: sticky;
  left: 0;
  background: transparent;
  z-index: 2;
  padding: 0px;
}

.table-wrapper-patient .b-table tbody tr td:nth-child(2),
.table-wrapper-patient .b-table thead tr th:nth-child(2) {
  position: sticky;
  left: 199px;
  background: transparent;
  z-index: 2;
  padding: 0px;
}
/* #eff7f8 */
.table-wrapper-patient .b-table thead tr th:first-child,
.table-wrapper-patient .b-table thead tr th:first-child,
.table-wrapper-patient .b-table thead tr th:nth-child(2),
.table-wrapper-patient .b-table thead tr th:nth-child(2) {
  padding: 12px;
  background: #024187;
  border-left: px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

.table-wrapper-patient .b-table tbody tr td:first-child .div-patient-table{
  padding: 60px 18px;
  background: white;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  white-space: nowrap;
}

.table-wrapper-patient .b-table tbody tr td:nth-child(2) .div-patient-table {
  padding: 60px 18px;
  background: white;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  white-space: nowrap;
}

.table-wrapper-patient .b-table tbody .table-body-color td:first-child .div-patient-table,
.table-wrapper-patient .b-table tbody .table-body-color td:nth-child(2) .div-patient-table {
  padding: 30px 18px;
  background: #F1FBFC;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  white-space: nowrap;
}

.multiselect__option--highlight {
    background: #089bab !important;
    outline: none;
    color: #fff;
}
.padding-patient-header {
  padding-right: 1.3rem !important;
}
/* @media (max-width: 614px) {
  .col-sm-5 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .col-sm-7 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
} */
@media (max-width: 520px) {
  .padding-patient-header {
    padding-right: 1rem;
  }
}
@media (max-width: 420px) {
  .res-flex-header {
    display: flex;
    flex-direction: column;
  }
  .col-6-to-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import Axios from 'axios'
import moment from 'moment'
import { configAPI } from '../../configBase'

export default {
  name: 'Patient',
  data () {
    return {
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      search: '',
      columns: [
        { label: 'ชื่อ - นามสกุล', key: 'fullname', class: 'text-center table-size-200-400' },
        { label: 'ชื่อเล่น', key: 'nickname', class: 'text-center table-size-200-400' },
        { label: 'HN', key: 'HN_no', class: 'text-center table-size-200-400' },
        { label: 'รูป', key: 'img_path', class: 'text-center table-size-200-400' },
        { label: 'เพศ', key: 'gender', class: 'text-center table-size-200-400' },
        { label: 'วันเดือนปีเกิด', key: 'birthday', class: 'text-center table-size-200-400' },
        { label: 'เบอร์ติดต่อ', key: 'tel', class: 'text-center table-size-200-400' },
        { label: 'วันที่เข้าใช้ครั้งแรก', key: 'entry_date', class: 'text-center table-size-200-400' },
        { label: 'ประวัติลูกค้าล่าสุด', key: 'history_service', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'management', class: 'text-center' }
        /* { label: 'HN', key: 'HN_no', class: 'text-center table-size-200-400' },
        { label: 'ชื่อ - นามสกุล', key: 'fullname', class: 'text-center table-size-200-400' },
        { label: 'เพศ', key: 'gender', class: 'text-center table-size-200-400' },
        { label: 'เบอร์ติดต่อ', key: 'tel', class: 'text-center table-size-200-400' },
        { label: 'วันเดือนปีเกิด', key: 'birthday', class: 'text-center table-size-200-400' },
        { label: 'รูป', key: 'img_path', class: 'text-center table-size-200-400' },
        { label: 'ชื่อเล่น', key: 'nickname', class: 'text-center table-size-200-400' },
        { label: 'วันที่เข้าใช้ครั้งแรก', key: 'entry_date', class: 'text-center table-size-200-400' },
        { label: 'ประวัติลูกค้าล่าสุด', key: 'history_service', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'management', class: 'text-center' } */
      ],
      PatientData: [],
      currentPage: 1,
      perPage: 10,
      AllDataLength: 0
    }
  },
  async mounted () {
    await this.getAllPatientData()
    await this.getPatientData()

    xray.index()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getPatientData()
      }
    },
    async getPatientData () {
      let dataSearch = this.search.trim()
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/patient?search=${dataSearch}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        let temp = []
        response.data.data.map((item) => {
          let temp2 = {
            id: item.id,
            HN_no: item.hn_no ? item.hn_no : '-',
            fullname: (item.firstname ? item.firstname : '-') + ' ' + (item.lastname ? item.lastname : '-'),
            gender: item.gender ? item.gender : '-',
            tel: item.phone ? item.phone : '-',
            birthday: item.birthday ? item.birthday : null,
            img_path: item.img_path ? item.img_path : null,
            nickname: item.nickname ? item.nickname : '-',
            entry_date: moment(item.created_date).format('DD/MM/YYYY'),
            history_service: item.history_customer
          }
          temp.push(temp2)
        })
        this.PatientData = temp
        this.calWidthTalbeFix()
      }).catch((error) => {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่พบข้อมูล</span>',
          html: `${error.response.data.message}`,
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab'
        })
      })
    },
    calWidthTalbeFix () {
      setTimeout(() => {
        const firstChild = document.querySelector('.table-wrapper-patient .b-table thead tr th:first-child')
        const firstChildWidth = firstChild.offsetWidth
        const secondChilds = document.querySelectorAll('.table-wrapper-patient .b-table tbody tr td:nth-child(2), .table-wrapper-patient .b-table thead tr th:nth-child(2)')
        secondChilds.forEach(secondChild => {
          secondChild.style.left = `${firstChildWidth - 1}px`
        })
      }, 50)
    },
    async getAllPatientData () {
      let dataSearch = this.search.trim()
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/patient?search=${dataSearch}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        this.AllDataLength = response.data.data.length
      }).catch((error) => {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่พบข้อมูล</span>',
          html: `${error.response.data.message}`,
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab'
        })
      })
    },
    remove (item) {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะลบข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Axios.request({
            method: 'PUT',
            url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/patient/${item.id}/delete`,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(async () => {
              await this.getAllPatientData()
              await this.getPatientData()
            })
          }).catch((error) => {
            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่สามารถลบข้อมูลได้</span>',
              html: `${error.response.data.message}`,
              icon: 'warning',
              showConfirmButton: true,
              confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
              confirmButtonColor: '#089bab'
            })
          })
        }
      })
    },
    onPagination (page) {
      this.currentPage = page
      this.calWidthTalbeFix()
    },
    handleClick (e, data) {
      const setPage = e
      // this.updateDataAsync()
      if (setPage === 'add') {
        router.push(`/Patient/${setPage}`)
      } else if (setPage === 'viewHistory') {
        router.push(`/HistoryService/view/${data.id}`)
      } else if (setPage === 'service') {
        router.push(`/ServiceUsage/Patient/add`)
      } else {
        router.push(`/Patient/${setPage}/${data.id}`)
      }
    },
    ViewOrEditHisOPD (data) {
      if (data.is_close) {
        router.push(`/ServiceUsage/Patient/History/view/${data.id}`)
      } else {
        router.push(`/ServiceUsage/Patient/History/edit/${data.id}`)
      }
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    getImagePath (relativePath) {
      if (relativePath) {
        let path = relativePath !== null ? relativePath.slice(1) : ''
        const baseURL = configAPI.MA_PORT + path
        return baseURL
      } else {
        return 'https://www.w3schools.com/howto/img_avatar.png'
      }
    },
    Formatbirthday (date) {
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      } else {
        return '-'
      }
    },
    formatTel (tel) {
      if (tel) {
        let temp = tel.split('')
        let temp2 = ''
        temp.map((item, index) => {
          if (index === 2 || index === 5) {
            temp2 += item + '-'
          } else {
            temp2 += item
          }
        })
        return temp2
      } else {
        return '-'
      }
    }
  }
}
</script>
