<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">ข้อมูลพนักงาน</span>
              </div>
              <div class="col-12 col-sm-auto mt-1 mb-2 my-sm-0">
                <b-button @click="handleClick('add')" type="submit" variant="primary" class="w-100"
                  style="min-width: 110px; min-height: 37px;"><i class="circle-plus_ct"></i>เพิ่ม</b-button>
                <!-- <b-button @click="handleClick('add')" type="submit" variant="primary" class="w-100 btn-min-size">เพิ่ม</b-button> -->
              </div>
              <div class="col mt-2 mb-1 my-sm-0">
                <div class="row align-items-center" style="justify-content: flex-end;">
                  <div class="col col-sm-auto">
                    <div style="min-width: 130px;">
                      <v-select v-model="selectedOption" :options="options" :clearable="false" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row v-if="selectedOption.label === 'Design'">
                <b-col cols="12" md="6" lg="4" xl="3" v-for="doctor in doctors" :key="doctor.message">
                  <iq-card body-class="text-center" style="box-shadow: 0px 0px 10px 0px #b8babb;">
                    <template v-slot:body>
                      <div class="doc-profile">
                        <img class="rounded-circle img-fluid avatar-80" :src="getFullImagePath(doctor?.profile_img_path)"
                          alt="profile"
                          @error="$event.target.src = 'https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png'"
                          style="object-fit: cover; width: 80px; height: 80px;"
                        >
                      </div>
                      <div class="iq-doc-info mt-3">
                        <h4> {{ doctor?.name + ' ' + doctor?.lastname }}</h4>
                        <p class="mb-0">{{ doctor?.nickname }}</p>
                        <p class="mb-0 mt-1" style="color: #089bab;">เพศ : {{ doctor?.eg_name }}</p>
                      </div>
                      <div class="iq-doc-social-info mt-2 mb-3">
                        <ul class="m-0 p-0" style="display: flex;
                          flex-direction: column;
                          align-items: center;">
                          <li style="display: flex; align-items: center; color: #089bab;"><a style="background: none;"><i
                                class="ri-mail-line"></i></a>
                            <p class="mb-0">{{ doctor?.email }}</p>
                          </li>
                          <li style="display: flex; align-items: center; color: #089bab;"><a style="background: none;"><i
                                class="ri-phone-line"></i></a>
                            <p class="mb-0">{{ formatTel(doctor?.tel) }}</p>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <button @click="handleClick('view', doctor.id)" class="btn btn-primary">View Profile</button>
                      </div>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
              <b-row v-else-if="selectedOption.label === 'Table'">
                <b-col cols="12" class="table-responsive" style="padding: 0px;">
                  <b-table bordered hover :items="doctors" :fields="columns" :current-page="currentPage"
                    :per-page="perPage" @pagination="onPagination">
                    <template v-slot:cell(cid)="data">
                      <span class="text-center">{{ data.item?.citizen_id || '' }}</span>
                    </template>
                    <template v-slot:cell(name)="data">
                      <span class="text-center">{{ (data.item?.name + ' ' + data.item?.lastname) || '' }}</span>
                    </template>
                    <template v-slot:cell(nickname)="data">
                      <span class="text-center">{{ data.item?.nickname || '' }}</span>
                    </template>
                    <template v-slot:cell(gender)="data">
                      <span class="text-center">{{ data.item?.eg_name || '' }}</span>
                    </template>
                    <template v-slot:cell(phone)="data">
                      <span class="text-center">{{ data.item?.tel || '' }}</span>
                    </template>
                    <template v-slot:cell(variable6)="data">
                      <b-button variant=" iq-bg-primary mr-1 mb-1" size="sm" @click="handleClick('view', data.item.id)"><i
                          class="ri-eye-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="handleClick('edit', data.item.id)"><i
                          class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-danger mr-1 mb-1" size="sm" @click="remove(data.item)"><i
                          class="ri-delete-bin-line m-0"></i></b-button>
                    </template>
                  </b-table>
                </b-col>
                <b-col cols="12">
                  <b-row style="display: flex; justify-content: center;">
                    <b-pagination v-model="currentPage" :total-rows="doctors?.length" :per-page="perPage" />
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Axios from 'axios'
import Vue from 'vue'
import { configAPI } from '../../configBase'

export default {
  data () {
    return {
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      name: 'UiDataTable',
      activeTab: 'tab1',
      selectedOption: { label: 'Design', code: 'Design' },
      options: [
        { label: 'Design', code: 'Design' },
        { label: 'Table', code: 'Table' }
      ],
      doctors: [],
      columns: [
        { label: 'เลขบัตรประชาชน', key: 'cid', class: 'text-center table-size-200-400' },
        { label: 'ชื่อ-นามสกุล', key: 'name', class: 'text-center table-size-200-400' },
        { label: 'ชื่อเล่น', key: 'nickname', class: 'text-center table-size-200-400' },
        { label: 'เพศ', key: 'gender', class: 'text-center table-size-200-400' },
        { label: 'เบอร์โทร', key: 'phone', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'variable6', class: 'text-center table-size-200-400' }
      ],
      rows: [],
      currentPage: 1,
      perPage: 10
    }
  },
  methods: {
    async getAllEmployee () {
      let getAllEmployeeAPI = {
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/employee`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await Axios.request(getAllEmployeeAPI)
        .then((response) => {
          this.doctors = response.data.data
        })
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick (mode, id) {
      if (id) {
        router.push(`/AddEmployee/${this.selectedOption.label}/${mode}/${id}`)
      } else {
        router.push(`/AddEmployee/${this.selectedOption.label}/${mode}`)
      }
    },
    remove (item) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะลบข้อมูลใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let deleteEmployeeAPI = {
            method: 'POST',
            url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/employee/${item.id}/delete`,
            headers: {
              'Content-Type': 'application/json'
            }
          }
          Axios.request(deleteEmployeeAPI)
            .then((response) => {
              if (response.data.status === true) {
                Vue.swal({
                  title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
                  icon: 'success',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  timer: 2000
                }).then(() => {
                  this.getAllEmployee()
                })
              }
            })
        }
      })
    },
    setMode () {
      if (this.$route.params.mode) {
        this.selectedOption = { label: this.$route.params.mode, code: this.$route.params.mode }
      }
    },
    getFullImagePath (relativePath) {
      if (relativePath) {
        let path = relativePath !== null ? relativePath.slice(1) : ''
        const baseURL = `${configAPI.MA_PORT}` + path
        return baseURL
      } else {
        return 'https://www.w3schools.com/howto/img_avatar.png'
      }
    },
    formatTel (tel) {
      if (tel) {
        let formatTel = tel.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        return formatTel
      } else {
        return ''
      }
    }
  },
  created () {
    this.setMode()
  },
  async mounted () {
    await this.getAllEmployee()
    xray.index()
  }
}
</script>
