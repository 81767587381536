<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">กำหนดสิทธิ์การใช้งาน</span>
              </div>
              <div class="col-12 col-sm-auto my-1 my-sm-0">
                <div class="row">
                  <div class="col-6 pl-sm-3 pr-sm-1 my-1 my-sm-0">
                    <b-button type="submit" variant="primary" @click="clickSave" class="w-100 btn-min-size"><i
                        class="save-data_ct"></i>บันทึก</b-button>
                  </div>
                  <div class="col-6 pl-sm-3 pr-sm-1 my-1 my-sm-0">
                    <b-button type="submit" variant="danger" @click="clickCancel"
                      class="w-100 btn-min-size btn-cancel-border">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row style="align-items: baseline;" class="mb-3">
                <b-col cols="auto" class="mb-2">
                  <span style="color: black;">เลือกกลุ่ม</span>
                </b-col>
                <b-col cols="12" sm="6" md="5" lg="4" xl="4">
                  <v-select v-model="selectedOption" :options="selectDropDown"></v-select>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="table-responsive table-container" style="padding: 0px;">
                  <div class="table-wrapper">
                    <b-table bordered hover :items="rowUser" :fields="columnMenu" :tbody-tr-class="rowClass"
                      thead-class="table-header-color">
                      <template v-slot:thead-top>
                        <b-tr>
                          <b-th />
                          <b-th v-for="(item) in columnModule" :key="item.key" :class="item.class"
                            :colspan="item.colspan">{{
                              item.label }}</b-th>
                        </b-tr>
                      </template>
                      <template v-for="(column) in columnMenu" v-slot:[`cell(${column.key})`]="data">
                        <template v-if="column.key === 'default'">
                          <div class="div-username-table" :key="column.key">{{ data.item.username || '-' }}</div>
                        </template>
                        <template v-else>
                          <b-form-checkbox :key="column.key" :value="column.key" v-model="data.item.menu"
                            type="checkbox"></b-form-checkbox>
                        </template>
                      </template>
                    </b-table>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.table-container {
  overflow-x: auto;
}

.table-wrapper {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-wrapper .b-table tbody tr td:first-child,
.table-wrapper .b-table thead tr th:first-child {
  position: sticky;
  left: 0;
  background: transparent;
  z-index: 2;
  padding: 0px;
}
/* #eff7f8 */
.table-wrapper .b-table thead tr th:first-child {
  padding: 12px;
  background: #024187;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

.table-wrapper .b-table tbody tr td:first-child .div-username-table {
  padding: 17px;
  background: white;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  white-space: nowrap;
}

.table-wrapper .b-table tbody .table-body-color td:first-child .div-username-table {
  padding: 17px;
  background: #F1FBFC;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  white-space: nowrap;
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import axios from 'axios'
import { configAPI } from '../../configBase'
// import { configProd } from '../../configBase'

export default {
  data () {
    return {
      moduleData: [],
      selectedOption: { value: 0, label: 'ทุกกลุ่ม' },
      selectDropDown: [
        { value: 0, label: 'ทุกกลุ่ม' }
      ],
      columnModule: [],
      columnMenu: [
        { label: '', key: 'default', class: 'text-center table-size-200-400' }
      ],
      rowUser: [],
      rows1: [
        {
          variable1: 'variablel-1',
          select: [],
          index: 1
        }
      ],
      countRows: 0,
      id: '',
      menuEdit: [],
      userData: {}
    }
  },
  watch: {
    'selectedOption': function () {
      const moduleList = []
      const menuList = [{ label: '', key: 'default', class: 'text-center table-size-200-400' }]

      if (this.selectedOption === null) {
        this.selectedOption = { value: 0, label: 'ทุกกลุ่ม' }
      } else {
        if (this.selectedOption?.value === 0) {
          for (let index = 0; index < this.moduleData.length; index++) {
            let countMenu = 0

            for (let indexIn = 0; indexIn < this.moduleData[index].menu.length; indexIn++) {
              if (this.moduleData[index].menu[indexIn].is_active) {
                menuList.push({ label: this.moduleData[index].menu[indexIn].name, key: this.moduleData[index].menu[indexIn].id, class: 'text-center table-nowarp table-size-200-400' })
                countMenu += 1
              }
            }

            if (countMenu > 0) {
              moduleList.push({ label: this.moduleData[index].name, key: this.moduleData[index].id, colspan: countMenu, class: 'text-center table-nowarp table-size-200-400' })
            }
          }
        } else {
          const module = this.moduleData?.find(item => item.id === this.selectedOption.value)
          let countMenu = 0

          for (let indexIn = 0; indexIn < module.menu.length; indexIn++) {
            if (module.menu[indexIn].is_active) {
              menuList.push({ label: module.menu[indexIn].name, key: module.menu[indexIn].id, class: 'text-center table-nowarp table-size-200-400' })
              countMenu += 1
            }
          }

          if (countMenu > 0) {
            moduleList.push({ label: module.name, key: module.id, colspan: countMenu, class: 'text-center table-nowarp table-size-200-400' })
          }
        }

        this.columnModule = moduleList
        this.columnMenu = menuList
      }
    }
  },
  methods: {
    async getPermissionMenuAll () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MD_PORT}/api/permissionMenu`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            this.moduleData = response.data.data
            const data = response.data.data
            for (let index = 0; index < data.length; index++) {
              this.selectDropDown.push({
                value: data[index].id,
                label: data[index].name
              })

              this.columnModule.push({ label: data[index].name, key: data[index].id, colspan: data[index].menu.length, class: 'text-center table-nowarp table-size-200-400' })

              for (let indexIn = 0; indexIn < data[index].menu.length; indexIn++) {
                this.columnMenu.push({ label: data[index].menu[indexIn].name, key: data[index].menu[indexIn].id, class: 'text-center table-nowarp table-size-200-400' })
              }
            }
          } else {
            this.selectedOption = { value: 0, label: 'ทุกกลุ่ม' }
            this.selectDropDown = [{ value: 0, label: 'ทุกกลุ่ม' }]
            this.columnModule = []
            this.columnMenu = [{ label: '', key: 'default', class: 'text-center table-size-200-400' }]
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาดในการดึงข้อมูล</span>`,
            html: '<span>' + error.message + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async getPermissionMenu () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.userData.cln_id}/permissionClinic`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            this.moduleData = response.data.data
            const data = response.data.data
            for (let index = 0; index < data.length; index++) {
              let countMenu = 0

              for (let indexIn = 0; indexIn < data[index].menu.length; indexIn++) {
                if (data[index].menu[indexIn].is_active) {
                  this.columnMenu.push({ label: data[index].menu[indexIn].name, key: data[index].menu[indexIn].id, class: 'text-center table-nowarp table-size-200-400' })
                  countMenu += 1
                }
              }

              if (countMenu > 0) {
                this.selectDropDown.push({
                  value: data[index].id,
                  label: data[index].name
                })

                this.columnModule.push({ label: data[index].name, key: data[index].id, colspan: countMenu, class: 'text-center table-nowarp table-size-200-400' })
              }
            }
          } else {
            this.selectedOption = { value: 0, label: 'ทุกกลุ่ม' }
            this.selectDropDown = [{ value: 0, label: 'ทุกกลุ่ม' }]
            this.columnModule = []
            this.columnMenu = [{ label: '', key: 'default', class: 'text-center table-size-200-400' }]
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาดในการดึงข้อมูล</span>`,
            html: '<span>' + error.message + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async getPermissionEmpGroup () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MD_PORT}/api/permissionEmployeeGroup?eg_id=${this.id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            const data = response.data.data
            for (let index = 0; index < data.length; index++) {
              const menuList = data[index].menu?.filter((item) => item.is_active)

              this.rowUser.push({
                id: data[index].id,
                name: data[index].name,
                username: data[index].name,
                menu: menuList?.map(el => {
                  return el.id
                }) || [],
                index: index
              })
              document.getElementById(menuList)
            }
          } else {
            this.rowUser = []
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาดในการดึงข้อมูล</span>`,
            html: '<span>' + error.message + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async getPermissionUser () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.userData.cln_id}/permissionUser`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            const data = response.data.data
            for (let index = 0; index < data.length; index++) {
              const menuList = []

              if (data[index]) {
                for (const item of data[index].menu) {
                  if (item.is_active) menuList.push(item.id)
                }
              }

              this.rowUser.push({
                id: data[index].id,
                name: data[index].full_name,
                username: data[index].username,
                menu: menuList,
                index: index
              })
            }
          } else {
            this.rowUser = []
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาดในการดึงข้อมูล</span>`,
            html: '<span>' + error.message + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    clickSave () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let addEditPermissionAPI = {}
          if (this.id) {
            addEditPermissionAPI = {
              method: 'post',
              url: `${configAPI.MD_PORT}/api/permissionEmployeeGroup`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: [{
                eg_id: this.id,
                menu_id_list: this.rowUser[0].menu?.length > 0 ? this.rowUser[0].menu : []
              }]
            }
          } else {
            const tempData = []

            for (let item of this.rowUser) {
              tempData.push({
                usr_id: item.id,
                menu_id_list: item.menu
              })
            }

            addEditPermissionAPI = {
              method: 'post',
              url: `${configAPI.MA_PORT}/api/clinic/${this.userData.cln_id}/permissionUser`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: tempData
            }
          }

          axios.request(addEditPermissionAPI).then(() => {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              if (this.id) {
                router.push('/EmployeeGroup')
              } else {
                window.location.reload()
              }
            })
          })
        }
      })
    },
    clickCancel () {
      if (this.id) {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            router.push('/EmployeeGroup')
          }
        })
      } else {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะยกเลิกการบันทึกข้อมูลใช่หรือไม่</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        })
      }
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    setData () {
      this.id = this.$route.params.id
      this.userData = JSON.parse(sessionStorage.getItem('user'))
    }
  },
  created () {
    this.setData()
  },
  async mounted () {
    xray.index()
    if (this.id) {
      await this.getPermissionMenuAll()
      await this.getPermissionEmpGroup()
    } else {
      await this.getPermissionMenu()
      await this.getPermissionUser()
    }
  }
}
</script>
