<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span v-if="mode === 'add'" class="unselectable" style="font-size: 25px; color: black;">สินค้า
                  (เพิ่ม)</span>
                <span v-else-if="mode === 'edit'" class="unselectable" style="font-size: 25px; color: black;">สินค้า
                  (แก้ไข)</span>
                <span v-else class="unselectable" style="font-size: 25px; color: black;">สินค้า (ดูรายละเอียด)</span>
              </div>
              <div v-if="mode === 'add' || mode === 'edit'" class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-6 col-sm-auto pl-3 pr-2 my-1 my-sm-0">
                    <b-button type="submit" variant="primary" @click="handleClick(true)" class="w-100 btn-min-size"><i
                        class="save-data_ct" />บันทึก</b-button>
                  </div>
                  <div class="col-6 col-sm-auto pl-2 pr-3 my-1 my-sm-0">
                    <b-button type="submit" variant="none" @click="handleClick(false)"
                      class="iq-bg-danger w-100 btn-min-size btn-cancel-border">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
              <div v-else class="col-12 col-sm-auto col-sm-auto my-1 my-sm-0">
                <b-button type="submit" variant="none" @click="handleClick(false)"
                  class="iq-bg-danger w-100 btn-min-size btn-cancel-border">ยกเลิก</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div>
              <div class="ml-3">
                <b class="mr-5 unselectable" style="white-space: nowrap; cursor: pointer;" @click="activeTab = 'tab1'"
                  :style="{ color: activeTab === 'tab1' ? 'rgb(8, 159, 175)' : 'black' }">ข้อมูลพื้นฐาน</b>
                <b class="mr-5 unselectable" style="white-space: nowrap; cursor: pointer;" @click="activeTab = 'tab2'"
                  :style="{ color: activeTab === 'tab2' ? 'rgb(8, 159, 175)' : 'black' }">ข้อมูลรายละเอียด</b>
                <b class="mr-5 unselectable" style="white-space: nowrap; cursor: pointer;" @click="activeTab = 'tab3'"
                  :style="{ color: activeTab === 'tab3' ? 'rgb(8, 159, 175)' : 'black' }">ซัพพลายเออร์</b>
              </div>
              <hr>

              <div v-if="activeTab === 'tab1'">
                <b-row class="mx-1">
                  <b-col lg="4" sm="8">
                    <b-form-group class="unselectable" label="รหัสสินค้า" label-for="exampleProductNo">
                      <template v-if="productData.data[0].no === '' || productData.data[0].no === null" v-slot:label>
                        รหัสสินค้า<span class="text-danger"> *</span>
                      </template>
                      <b-form-input id="exampleProductNo" type="text" :disabled="isDisable"
                        v-model="productData.data[0].no" placeholder=""></b-form-input>
                    </b-form-group>
                    <b-form-group class="unselectable" label="ชื่อยา" label-for="exampleProductName">
                      <template v-if="productData.data[0].name === '' || productData.data[0].name === null" v-slot:label>
                        ชื่อยา<span class="text-danger"> *</span>
                      </template>
                      <b-form-input id="exampleProductName" type="text" :disabled="isDisable"
                        v-model="productData.data[0].name" placeholder=""></b-form-input>
                    </b-form-group>
                    <b-form-group class="unselectable" label="ชื่อการค้า" label-for="exampleProductSaleName">
                      <template v-if="productData.data[0].brand_name === '' || productData.data[0].brand_name === null"
                        v-slot:label>
                        ชื่อการค้า<span class="text-danger"> *</span>
                      </template>
                      <b-form-input id="exampleProductSaleName" type="text" :disabled="isDisable"
                        v-model="productData.data[0].brand_name" placeholder=""></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <div v-if="activeTab === 'tab2'">
                <b-row class="mx-1">
                  <b-col lg="6" sm="12">
                    <b-form-group class="unselectable" style="color: black;" label="ประเภทสินค้า">
                      <template v-if="productData.data[0].psg_pg_id === '' || productData.data[0].psg_pg_id === null"
                        v-slot:label>
                        ประเภทสินค้า<span class="text-danger"> *</span>
                      </template>
                      <v-select v-model="selectedOptionPG" :disabled="isDisable" :options="productDataDD.data"></v-select>
                    </b-form-group>
                    <b-form-group class="unselectable" style="color: black;" label="ประเภทย่อย">
                      <template v-if="productData.data[0].psg_id === '' || productData.data[0].psg_id === null"
                        v-slot:label>
                        ประเภทย่อย<span class="text-danger"> *</span>
                      </template>
                      <v-select v-model="selectedOptionPSG"
                        :disabled="isDisable || selectedOptionPG === null ? true : false || productDataSubDD.data.length > 0 ? false : true"
                        :options="productDataSubDD.data"></v-select>
                    </b-form-group>
                    <b-form-group class="unselectable" label="สารประกอบ" label-for="exampleInputurl">
                      <b-form-input id="exampleInputurl" :disabled="isDisable" v-model="productData.data[0].compound"
                        type="text"></b-form-input>
                    </b-form-group>
                    <b-form-group class="unselectable" label="ความเข้มข้น/ขนาด" label-for="exampleInputphone">
                      <b-form-input id="exampleInputphone" :disabled="isDisable" v-model="productData.data[0].intensity"
                        type="text"></b-form-input>
                    </b-form-group>
                    <b-form-group class="unselectable" label="เลขทะเบียน" label-for="exampleInputNumber1">
                      <b-form-input id="exampleInputNumber1" :disabled="isDisable"
                        v-model="productData.data[0].license_no" type="text"></b-form-input>
                    </b-form-group>
                    <b-form-group class="unselectable" label="หน่วยนับ" label-for="exampleInputPassword3">
                      <template v-if="productData.data[0].unit_id === '' || productData.data[0].unit_id === null"
                        v-slot:label>
                        หน่วยนับ<span class="text-danger"> *</span>
                      </template>
                      <v-select v-model="unitOption" :disabled="isDisable" :options="unitDD.data"></v-select>
                    </b-form-group>
                    <b-form-group class="unselectable" label="สต๊อกขั้นต่ำ" label-for="exampleInputdate">
                      <b-form-input id="exampleInputdate" :disabled="isDisable" v-model="productData.data[0].min_stock"
                        type="text" @input="isNumberComma1($event, 'stock')"
                        @keypress="isNumberCheck($event, true)"></b-form-input>
                    </b-form-group>
                    <b-form-group class="unselectable" label="ราคาขาย" label-for="exampleInputmonth">
                      <b-form-input id="exampleInputmonth" :disabled="isDisable" v-model="productData.data[0].price"
                        type="text" @input="isNumberComma1($event, 'price')"
                        @keypress="isNumberCheck($event, false)"></b-form-input>
                    </b-form-group>
                    <b-form-group class="unselectable" label="ราคาขายปกติ" label-for="exampleInputweek">
                      <b-form-input id="exampleInputweek" :disabled="isDisable" v-model="productData.data[0].normal_price"
                        type="text" @input="isNumberComma1($event, 'normal')"
                        @keypress="isNumberCheck($event, false)"></b-form-input>
                    </b-form-group>
                    <b-form-group class="unselectable" label="ราคาขายขั้นต่ำ" label-for="exampleInputweek">
                      <b-form-input id="exampleInputweek" :disabled="isDisable"
                        v-model="productData.data[0].minimum_price" type="text" @input="isNumberComma1($event, 'minimum')"
                        @keypress="isNumberCheck($event, false)"></b-form-input>
                    </b-form-group>
                    <b-form-group class="unselectable" label="บาร์โค้ด" label-for="exampleInputweek">
                      <b-form-input id="exampleInputweek" :disabled="isDisable"
                        v-model="productData.data[0].barcode" type="text" @input="preventDecimalAndNegative"
                        @keypress="preventDecimalAndNegative($event)"></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col lg="6" sm="12">
                    <b-form>
                      <template>
                        <div>
                          <b-form-group class="unselectable" label-for="imageInput" label="อัปโหลดรูปภาพ">
                            <b-form-file class="custom-file-style" id="imageInput"
                              :placeholder="productData.data[0].img_name ? productData.data[0].img_name : products.imageName ? products.imageName : 'Choose a file'"
                              accept="image/*" @change="handleImageUpload" :disabled="isDisable"></b-form-file>
                          </b-form-group>
                          <div
                            v-if="productData.data[0].img_path !== null && (products.file1 === null || products.file1 === undefined)"
                            style="text-align: center; object-fit:contain">
                            <img :src="getFullImagePath(productData.data[0].img_path)" alt="Uploaded Image" width="350px"
                              height="300px" style="object-fit: contain;" />
                          </div>
                          <div v-else-if="products.file1 === undefined || products.file1"
                            style="text-align: center; object-fit:contain">
                            <img :src="products.imageUrl" alt="Uploaded Image" width="350px" height="300px"
                              style="object-fit: contain;" />
                          </div>
                        </div>
                      </template>
                      <template>
                        <div class="mt-2">
                          <b-form-group class="unselectable" label-for="imageInput2" label="อัปโหลดบาร์โค้ด">
                            <b-form-file class="custom-file-style" id="imageInput2" accept="image/*"
                              :placeholder="productData.data[0].qr_name ? productData.data[0].qr_name : products.imageName2 ? products.imageName2 : 'Choose a file'"
                              @change="handleImageUpload2" :disabled="isDisable"></b-form-file>
                          </b-form-group>
                          <div
                            v-if="productData.data[0].qr_path !== null && (products.file2 === null || products.file2 === undefined)"
                            style="text-align: center; object-fit:contain">
                            <img :src="getFullImagePath(productData.data[0].qr_path)" alt="Uploaded Barcode" width="350"
                              height="300px" style="object-fit: contain;" />
                          </div>
                          <div v-else-if="products.file2 === undefined || products.file2"
                            style="text-align: center; object-fit:contain">
                            <img :src="products.imageUrl2" alt="Uploaded Barcode" width="350" height="300px"
                              style="object-fit: contain;" />
                          </div>
                        </div>
                      </template>
                    </b-form>
                  </b-col>
                </b-row>
              </div>

              <div v-if="activeTab === 'tab3'">
                <div class="row response-select-supplier"
                  style="align-items: center; text-align: center; justify-content: space-between; padding: 0 1rem;">
                  <h5 class="my-1 response-select-supplier-text unselectable">เลือกซัพพลายเออร์</h5>
                  <b-button class="d-flex response-select-supplier-btn" id="show-modal"
                    @click="setDataSupplierModal(), page = 'add'" type="submit" variant="primary" style="border: none;"
                    :disabled="isDisable"><i class="circle-plus_ct"></i>เพิ่มซัพพลายเออร์</b-button>
                </div>
                <hr>
                <template v-if="page === 'add'">
                  <b-modal v-model="showModal" title="เพิ่มข้อมูลซัพพลายเออร์" cancel-title="ยกเลิก" @ok="addSupplier()"
                    :ok-disabled="!supplier.var_supplier1 || !supplier.var_supplier2 ? true : false" ok-title="เพิ่ม">
                    <b-form-group class="unselectable" style="color: black;" label="ซัพพลายเออร์">
                      <template v-if="supplier.var_supplier1 === ''" v-slot:label>
                        ซัพพลายเออร์<span class="text-danger"> *</span>
                      </template>
                      <v-select v-model="selectedOptionSup" :options="supplierDDmap"></v-select>
                    </b-form-group>
                    <b-form-group class="unselectable" label="ราคาซื้อต่อชิ้น" style="color: black;">
                      <template v-if="supplier.var_supplier2 === ''" v-slot:label>
                        ราคาซื้อต่อชิ้น<span class="text-danger"> *</span>
                      </template>
                      <b-form-input id="" v-model="supplier.var_supplier2" type="text"
                        @input="isNumberComma1($event, '2')" @keypress="isNumberCheck($event, false)"></b-form-input>
                    </b-form-group>
                    <b-form-group class="unselectable" style="color: black;">
                      <template v-slot:label>
                        รายการที่สั่งขั้นต่ำ<span v-if="!supplier.var_supplier3" class="text-danger"> *</span>
                      </template>
                      <b-form-input id="" v-model="supplier.var_supplier3" type="text"
                        @input="isNumberComma1($event, '3')" @keypress="isNumberCheck($event, true)"></b-form-input>
                    </b-form-group>
                    <b-form-group class="unselectable" style="color: black;">
                      <template v-slot:label>
                        เวลาในการจัดส่ง(วัน)<span v-if="!supplier.var_supplier4" class="text-danger"> *</span>
                      </template>
                      <b-form-input v-model="supplier.var_supplier4" type="text" @input="isNumberComma1($event, '4')"
                        @keypress="isNumberCheck($event, true)"></b-form-input>
                    </b-form-group>
                    <b-form-group class="unselectable" label="ข้อคิดเห็น" style="color: black;">
                      <b-form-textarea id="" v-model="supplier.var_supplier5" type="text"
                        style="height: 100px; justify-items: flex-start;"></b-form-textarea>
                    </b-form-group>
                    <b-form-group class="unselectable" label="มาตรฐานรองรับ อย." style="color: black;">
                      <template v-if="supplier.var_supplier6 === null" v-slot:label>
                        มาตรฐานรองรับ อย.<span class="text-danger"> *</span>
                      </template>
                      <b-row class="" style="text-align: center; justify-content: center;">
                        <b-form-radio-group class="mt-3">
                          <b-form-radio v-model="supplier.var_supplier6" :value=true class="mr-3">อย.
                            เท่านั้น</b-form-radio>
                          <b-form-radio v-model="supplier.var_supplier6" :value=false class="ml-3">อื่น ๆ</b-form-radio>
                        </b-form-radio-group>
                      </b-row>
                    </b-form-group>
                    <template #modal-footer>
                      <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                        <b-button style="border-radius: 0.5rem; padding: 0 1rem;" variant="primary" class="btn-min-size"
                          size="sm" @click="addSupplier">
                          <i class="circle-plus_ct" />เพิ่ม
                        </b-button>
                        <b-button variant="none" class="btn-min-size iq-bg-danger btn-cancel-border"
                          style="border-radius: 0.5rem;" size="sm" @click="closeModal">
                          ยกเลิก
                        </b-button>
                      </div>
                    </template>
                  </b-modal>
                </template>
                <template v-else>
                  <b-modal v-model="showModal"
                    :title="page === 'view' ? 'ดูรายละเอียดซัพพลายเออร์' : 'แก้ไขข้อมูลซัพพลายเออร์'"
                    cancel-title="ยกเลิก" :ok-disabled="supplier.var_supplier1 && supplier.var_supplier2 ? false : true"
                    @ok="editSupplier" ok-title="แก้ไข">
                    <b-form-group class="unselectable" style="color: black;" label="ซัพพลายเออร์">
                      <template v-if="supplier.var_supplier1 === '' || supplier.var_supplier1 === null" v-slot:label>
                        ซัพพลายเออร์<span class="text-danger"> *</span>
                      </template>
                      <v-select :disabled="page === 'view' ? true : false" v-model="selectedOptionSup"
                        :options="supplierDDmap"></v-select>
                    </b-form-group>
                    <b-form-group class="unselectable" label="ราคาซื้อต่อชิ้น" style="color: black;">
                      <template v-if="supplier.var_supplier2 === '' || supplier.var_supplier2 === null" v-slot:label>
                        ราคาซื้อต่อชิ้น<span class="text-danger"> *</span>
                      </template>
                      <b-form-input :disabled="page === 'view' ? true : false" id="" v-model="supplier.var_supplier2"
                        type="text" @input="isNumberComma1($event, '2')"
                        @keypress="isNumberCheck($event, false)"></b-form-input>
                    </b-form-group>
                    <b-form-group class="unselectable" label="รายการที่สั่งขั้นต่ำ" style="color: black;">
                      <template v-if="supplier.var_supplier3 === '' || supplier.var_supplier3 === null" v-slot:label>
                        รายการที่สั่งขั้นต่ำ<span class="text-danger"> *</span>
                      </template>
                      <b-form-input :disabled="page === 'view' ? true : false" id="" v-model="supplier.var_supplier3"
                        type="text" @input="isNumberComma1($event, '3')"
                        @keypress="isNumberCheck($event, true)"></b-form-input>
                    </b-form-group>
                    <b-form-group class="unselectable" label="เวลาในการจัดส่ง(วัน)" style="color: black;">
                      <template v-if="supplier.var_supplier4 === '' || supplier.var_supplier4 === null" v-slot:label>
                        เวลาในการจัดส่ง(วัน)<span class="text-danger"> *</span>
                      </template>
                      <b-form-input :disabled="page === 'view' ? true : false" v-model="supplier.var_supplier4"
                        type="text" @input="isNumberComma1($event, '4')"
                        @keypress="isNumberCheck($event, true)"></b-form-input>
                    </b-form-group>
                    <b-form-group class="unselectable" label="ข้อคิดเห็น" style="color: black;">
                      <b-form-textarea :disabled="page === 'view' ? true : false" id="" v-model="supplier.var_supplier5"
                        type="text" style="height: 100px; justify-items: flex-start;"></b-form-textarea>
                    </b-form-group>
                    <b-form-group :disabled="page === 'view' ? true : false" class="unselectable"
                      label="มาตรฐานรองรับ อย." style="color: black;">
                      <template v-if="supplier.var_supplier6 === null" v-slot:label>
                        มาตรฐานรองรับ อย.<span class="text-danger"> *</span>
                      </template>
                      <b-row class="" style="text-align: center; justify-content: center;">
                        <b-form-radio v-model="supplier.var_supplier6" :value=true class="mr-3">อย.
                          เท่านั้น</b-form-radio>
                        <b-form-radio v-model="supplier.var_supplier6" :value=false class="ml-3">อื่น ๆ</b-form-radio>
                      </b-row>
                    </b-form-group>
                    <template #modal-footer>
                      <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                        <b-button class="btn-min-size" style="border-radius: 0.5rem; padding: 0 .7rem;" variant="primary"
                          size="sm" @click="editSupplier" :hidden="page === 'view' ? true : false"
                          :disabled="supplier.var_supplier1 && supplier.var_supplier2 ? false : true">
                          <i class="save-data_ct" />บันทึก
                        </b-button>
                        <b-button class="btn-min-size iq-bg-danger btn-cancel-border" variant="none"
                          style="border-radius: 0.5rem;" size="sm" @click="closeModal">
                          ยกเลิก
                        </b-button>
                      </div>
                    </template>
                  </b-modal>
                </template>
                <!-- <hr> -->
                <!-- <b-col lg="4" sm="4">
                <div style="position: relative;">
                  <b-form-input type="text" style="padding-right: 30px;" placeholder="Search..."></b-form-input>
                  <i style="position: absolute; top: 50%; right: 10px; transform: translateY(-50%); font-size: 18px; color: #999;" class="fas fa-search"></i>
                </div>
              </b-col> -->
                <br>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="productData.data[0].supplier_list" :fields="columns"
                    :current-page="currentPage" :per-page="perPage" @pagination="onPagination" :tbody-tr-class="rowClass"
                    thead-class="table-header-color">
                    <template v-slot:cell(variable1)="data">
                      <span v-if="productData.data[0].supplier_list" class="unselectable">{{ data.index + 1 }}</span>
                      <span v-else class="unselectable">{{ data.index + 1 }}</span>
                    </template>
                    <template v-slot:cell(variable2)="data">
                      <span v-if="productData.data[0].supplier_list" class="unselectable">{{
                        productData.data[0].supplier_list[((currentPage - 1) * 10) + data.index].sup_name }}</span>
                      <span v-else class="unselectable"></span>
                    </template>
                    <template v-slot:cell(variable3)="data">
                      <span v-if="!data.item.editable" class="unselectable">{{
                        returnComma(productData.data[0].supplier_list[((currentPage - 1) * 10) + data.index].buy_price)
                      }}</span>
                      <input type="text" v-model="data.item.variable3" v-else class="form-control">
                    </template>
                    <template v-slot:cell(variable4)="data">
                      <span v-if="!data.item.editable" class="unselectable">{{
                        returnComma(productData.data[0].supplier_list[((currentPage - 1) * 10) +
                          data.index].minimum_order) }}</span>
                      <input type="text" v-model="data.item.variable4" v-else class="form-control">
                    </template>
                    <template v-slot:cell(variable5)="data">
                      <span v-if="!data.item.editable" class="unselectable">{{
                        returnComma(productData.data[0].supplier_list[((currentPage - 1) * 10) + data.index].lead_time)
                      }}</span>
                      <input type="text" v-model="data.item.variable5" v-else class="form-control">
                    </template>
                    <template v-slot:cell(variable6)="data">
                      <span v-if="!data.item.editable" class="unselectable">{{
                        productData.data[0].supplier_list[((currentPage - 1) * 10) + data.index].remark }}</span>
                      <input type="text" v-model="data.item.variable6" v-else class="form-control">
                    </template>
                    <template v-slot:cell(variable7)="data">
                      <span v-if="!data.item.editable" class="unselectable" style="white-space: nowrap;">{{
                        formatdate(productData.data[0].supplier_list[((currentPage - 1) * 10) + data.index].updated_date)
                      }}</span>
                      <input type="text" v-model="data.item.variable7" v-else class="form-control">
                    </template>
                    <template v-slot:cell(variable8)="data">
                      <span v-if="!data.item.editable" class="unselectable">{{
                        productData.data[0].supplier_list[((currentPage - 1) * 10) + data.index].is_certificate ? "อย." :
                        "อื่นๆ" }}</span>
                      <input type="text" v-model="data.item.variable8" v-else class="form-control">
                    </template>
                    <template v-slot:cell(variable9)="data">
                      <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                        <div>
                          <b-button class="d-flex" :disabled="isDisable" id="set-active"
                            @click="SetActiveSupProduct(productData.data[0].supplier_list[((currentPage - 1) * 10) + data.index])/* , productData.data[0].supplier_list[((currentPage - 1) * 10) + data.index].is_true = !productData.data[0].supplier_list[((currentPage - 1) * 10) + data.index].is_true */"
                            type="submit"
                            :variant="productData.data[0].supplier_list[((currentPage - 1) * 10) + data.index].is_true === true ? 'success' : 'primary'"
                            style="border: none;">active</b-button>
                        </div>
                        <div
                          v-if="data.item.variable9 === false || productData.data[0].supplier_list[((currentPage - 1) * 10) + data.index].is_true === false || productData.data[0].supplier_list[((currentPage - 1) * 10) + data.index].is_true === null">
                          <hr class="pd-0 m-2" />
                          <div style="display: flex; justify-content: center;">
                            <b-button variant=" iq-bg-primary" :disabled="isDisable" size="sm"
                              @click="showModal = true, page = 'view', setEditSupplier(data.item, data.index)"
                              v-if="!data.item.editable"><i class="ri-eye-fill m-0"></i></b-button>
                            <b-button variant=" iq-bg-success mx-1" :disabled="isDisable" size="sm"
                              @click="showModal = true, page = 'edit', setEditSupplier(data.item, data.index)"
                              v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                            <b-button variant=" iq-bg-danger" size="sm" :disabled="isDisable"
                              @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                          </div>
                        </div>
                      </div>
                    </template>
                  </b-table>
                  <br>
                  <b-pagination v-model="currentPage" :total-rows="productData.data[0].supplier_list?.length"
                    :per-page="perPage" align="center" />
                </b-col>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.margin-text-header {
  margin-left: 1rem;
}
.padding-text-header {
  margin-left: 1rem;
  margin-right: 1rem;
}
.response-select-supplier-text {
  margin-left: 1rem;
  margin-right: 1rem;
}

.response-select-supplier-text {
    margin-left: 1rem !important;
  }

@media (max-width: 560px) {
  .apg-response-header {
    display: flex;
    align-items: flex-start !important;
    flex-direction: column;
  }
  .apg-response-header-btn {
    width: 100% !important;
  }
  .iq-header-title {
    width: 100% !important;
  }
  .margin-text-header {
    margin-left: 0 !important;
  }
  .padding-text-header {
    margin-left: 0.5rem !important;
    margin-right: 0 !important;
  }
}
@media (max-width: 390px) {
  .response-select-supplier {
    flex-direction: column;
    align-items: start !important;
    widows: 100% !important;
    padding: 0 2rem !important;
  }
  .response-select-supplier-text {
    margin: 0;
  }
  .response-select-supplier-btn {
    margin-top: 0.5rem !important;
    width: 100%;
    justify-content: center;
  }
}
</style>

<script>
import moment from 'moment'
import { xray } from '../../config/pluginInit'
import router from '@/router'
import axios from 'axios'
import Vue from 'vue'
import { configAPI } from '../../configBase'

export default {
  name: 'UiDataTable',
  data () {
    return {
      cln_id: null,
      productData: {
        data: [
          {
            psg_id: '',
            no: '',
            barcode: '',
            name: '',
            brand_name: '',
            compound: '',
            intensity: '',
            license_no: '',
            unit_id: '',
            unit_name: '',
            min_stock: null,
            price: null,
            sale_price: null,
            is_use: '',
            is_active: '',
            supplier_list: [],
            psg_pg_id: '',
            img_name: '',
            img_path: null,
            pg_name: '',
            pg_no: '',
            psg_name: '',
            psg_no: '',
            qr_name: '',
            qr_path: null
          }
        ]
      },
      productDataDD: {
        data: []
      },
      productDataSubDD: {
        data: []
      },
      unitDD: {
        data: []
      },
      unitOption: null,
      selectedOptionPG: null,
      selectedOptionPSG: null,
      selectedOptionSup: {
        code: '',
        label: ''
      },
      mode: this.$route.params.setPage === 'add' ? 'add' : this.$route.params.setPage === 'edit' ? 'edit' : 'view',
      isDisable: this.$route.params.setPage === 'view',
      showModal: false,
      products: {
        file1: null,
        file2: null,
        imageName: null,
        imageUrl: null,
        imageName2: null,
        imageUrl2: null,
        var_product4: null,
        var_product5: null
      },
      supplier: {
        var_supplier1: '',
        var_supplier2: '',
        var_supplier3: '',
        var_supplier4: '',
        var_supplier5: '',
        var_supplier6: null,
        var_supplier7: ''
      },
      resetSupplier: {
        var_supplier1: '',
        var_supplier2: '',
        var_supplier3: '',
        var_supplier4: '',
        var_supplier5: '',
        var_supplier6: null,
        var_supplier7: ''
      },
      indexTableEdit: '',
      activeTab: 'tab1',
      page: 'add',
      columns: [
        { label: 'ลำดับ', key: 'variable1', class: 'text-center table-size-100-300' },
        { label: 'ซัพพลายเออร์', key: 'variable2', class: 'text-center table-size-100-300' },
        { label: 'ราคาซื้อต่อชิ้น', key: 'variable3', class: 'text-center table-size-100-300' },
        { label: 'รายการที่สั่งขั้นต่ำ', key: 'variable4', class: 'text-center table-size-100-300' },
        { label: 'เวลาในการจัดส่ง(วัน)', key: 'variable5', class: 'text-center table-size-100-300' },
        { label: 'ข้อคิดเห็น', key: 'variable6', class: 'text-center table-size-100-300' },
        { label: 'วันที่อัปเดต', key: 'variable7', class: 'text-center table-size-100-300' },
        { label: 'มาตรฐานรองรับ อย.', key: 'variable8', class: 'text-center table-size-100-300' },
        { label: 'จัดการ', key: 'variable9', class: 'text-center' }
      ],
      currentPage: 1,
      perPage: 10
    }
  },
  watch: {
    'products.var_product4': function (newVal, oldVal) {
      if (newVal === null) {
        this.products.var_product5 = null
      }
    },
    selectedOptionPG (newVal) {
      if (newVal !== null) {
        this.getPSGdropdown(newVal.code)
        this.productData.data[0].psg_pg_id = newVal.code ? newVal.code : null
        this.productData.data[0].pg_name = newVal.label ? newVal.label : null
      } else {
        this.getPSGdropdown(null)
        this.productData.data[0].psg_id = null
        this.productData.data[0].psg_name = null
      }
    },
    selectedOptionPSG (newVal) {
      if (newVal !== null) {
        this.productData.data[0].psg_id = newVal.code ? newVal.code : null
        this.productData.data[0].psg_name = newVal.label ? newVal.label : null
      }
    },
    selectedOptionSup (newVal) {
      if (newVal !== null) {
        this.supplier.var_supplier1 = newVal.code ? newVal.code : null
        this.supplier.var_supplier7 = newVal.label ? newVal.label : null
      }
    },
    unitOption (newValue) {
      this.productData.data[0].unit_id = newValue !== null ? newValue.code : null
      this.productData.data[0].unit_name = newValue !== null ? newValue.label : null
    }
  },
  async mounted () {
    xray.index()
    this.cln_id = JSON.parse(sessionStorage.getItem('user')).cln_id
    let id = this.$route.params.id
    if (this.$route.params.setPage === 'view' || this.$route.params.setPage === 'edit') {
      let productDataAPI = {
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/product/` + id,
        headers: {
          'Content-Type': 'application/json'
        }
      }

      await axios.request(productDataAPI)
        .then((response) => {
          response.data.data[0].sale_price = response.data.data[0].sale_price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          response.data.data[0].price = response.data.data[0].price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          response.data.data[0].min_stock = response.data.data[0].min_stock?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          response.data.data[0].normal_price = response.data.data[0].normal_price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          response.data.data[0].minimum_price = response.data.data[0].minimum_price?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          this.productData = response.data
        })
        .catch((error) => {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
            html: '<span>' + error + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    }
    await this.getPGdropdown()
    await this.supplierDD()
    await this.getUnitDD()
  },
  computed: {
    productDataDDoption () {
      return this.productDataDD.data?.map(option => ({ code: option.id, label: option.name }))
    },
    productDataSubDDoption () {
      return this.productDataSubDD.data?.map(option => ({ code: option.id, label: option.name }))
    },
    supplierDDmap () {
      return this.supplierDDdata.data?.map(option => ({ code: option.id, label: option.name }))
    },
    unitDDmap () {
      return this.unitDD.data?.map(option => ({ code: option.id, label: option.name }))
    }
  },
  methods: {
    async getUnitDD () {
      let unitAPI = {
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/unit`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      axios.request(unitAPI)
        .then((response) => {
          this.unitDD.data = response.data.data?.map(el => {
            return {
              code: el.id,
              label: el.name
            }
          })
          let same = response.data.data?.filter(item => item.id === this.productData.data[0].unit_id)
          if (same.length > 0) {
            this.unitOption = { code: same[0].id, label: same[0].name }
          } else {
            this.unitOption = null
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            icon: 'error',
            html: '<span>' + error + '</span>',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async getPGdropdown () {
      let productDataDDAPI = {
        method: 'get',
        url: `${configAPI.MD_PORT}/api/productGroup`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(productDataDDAPI)
        .then((response) => {
          this.productDataDD.data = response.data.data.map(el => {
            return {
              code: el.id,
              label: el.name
            }
          })
          let same = response.data.data?.filter(item => item.id === this.productData.data[0].psg_pg_id)
          if (same.length > 0) {
            this.selectedOptionPG = { code: same[0].id, label: same[0].name }
          } else {
            this.selectedOptionPG = null
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
            html: '<span>' + error + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async getPSGdropdown (id) {
      if (id === null) {
        this.selectedOptionPSG = null
      }
      let productDataSubDDAPI = {
        method: 'get',
        url: `${configAPI.MD_PORT}/api/productSubGroup?pg_id=` + id,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(productDataSubDDAPI)
        .then((response) => {
          this.productDataSubDD.data = response.data.data?.map(el => {
            return {
              code: el.id,
              label: el.name
            }
          })
          let same = response.data.data?.filter(item => item.id === this.productData.data[0].psg_id)
          if (same.length > 0) {
            this.selectedOptionPSG = { code: same[0].id, label: same[0].name }
          } else {
            this.selectedOptionPSG = null
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
            html: '<span>' + error + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    convertToJson () {
      let dataList = {}
      if (this.$route.params.setPage === 'edit') {
        dataList = {
          barcode: this.productData.data[0].barcode,
          brand_name: this.productData.data[0].brand_name,
          clinic_id: this.productData.data[0].clinic_id,
          compound: this.productData.data[0].compound,
          created_by: null,
          created_date: this.productData.data[0].created_date,
          id: this.productData.data[0].id,
          img_name: this.productData.data[0].img_name,
          img_path: this.productData.data[0].img_path,
          intensity: this.productData.data[0].intensity,
          is_active: true,
          is_use: true,
          license_no: this.productData.data[0].license_no,
          min_stock: this.productData.data[0].min_stock?.replaceAll(/,/g, ''),
          name: this.productData.data[0].name,
          no: this.productData.data[0].no,
          pg_name: this.productData.data[0].pg_name,
          pg_no: this.productData.data[0].pg_no,
          price: this.productData.data[0].price?.replaceAll(/,/g, ''),
          psg_id: this.productData.data[0].psg_id,
          psg_name: this.productData.data[0].psg_name,
          psg_no: this.productData.data[0].psg_no,
          psg_pg_id: this.productData.data[0].psg_pg_id,
          qr_name: this.productData.data[0].qr_name,
          qr_path: this.productData.data[0].qr_path,
          sale_price: this.productData.data[0].sale_price?.replaceAll(/,/g, ''),
          normal_price: this.productData.data[0].normal_price?.replaceAll(/,/g, ''),
          minimum_price: this.productData.data[0].minimum_price?.replaceAll(/,/g, ''),
          supplier_list: this.productData.data[0].supplier_list,
          unit_id: this.productData.data[0].unit_id,
          updated_by: null,
          updated_date: new Date()
        }
      } else if (this.$route.params.setPage === 'add') {
        dataList = {
          barcode: this.productData.data[0].barcode,
          brand_name: this.productData.data[0].brand_name,
          compound: this.productData.data[0].compound,
          img_name: this.productData.data[0].img_name,
          img_path: this.productData.data[0].img_path,
          intensity: this.productData.data[0].intensity,
          is_active: true,
          is_use: true,
          license_no: this.productData.data[0].license_no,
          min_stock: this.productData.data[0].min_stock?.replaceAll(/,/g, ''),
          name: this.productData.data[0].name,
          no: this.productData.data[0].no,
          pg_name: this.productData.data[0].pg_name,
          pg_no: this.productData.data[0].pg_no,
          price: this.productData.data[0].price?.replaceAll(/,/g, ''),
          psg_id: this.productData.data[0].psg_id,
          psg_name: this.productData.data[0].psg_name,
          psg_no: this.productData.data[0].psg_no,
          psg_pg_id: this.productData.data[0].psg_pg_id,
          qr_name: this.productData.data[0].qr_name,
          qr_path: this.productData.data[0].qr_path,
          sale_price: this.productData.data[0].sale_price?.replaceAll(/,/g, ''),
          normal_price: this.productData.data[0].normal_price?.replaceAll(/,/g, ''),
          minimum_price: this.productData.data[0].minimum_price?.replaceAll(/,/g, ''),
          supplier_list: this.productData.data[0].supplier_list,
          unit_id: this.productData.data[0].unit_id
        }
      }
      return dataList
    },
    formatdate (date) {
      return moment(date).format('YYYY-MM-DD')
    },
    async addProduct () {
      let addproductAPI = {
        method: 'post',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/product`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: this.convertToJson()
      }

      await axios.request(addproductAPI)
        .then((response) => {
          if (this.products.file1) {
            this.uploadImg(response.data.data.id)
          }
          if (this.products.file2) {
            this.uploadQR(response.data.data.id)
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
            html: '<span>' + error + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async editProduct () {
      let id = this.$route.params.id
      let editproductAPI = {
        method: 'put',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/product/${id}`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: this.convertToJson()
      }
      axios.request(editproductAPI)
        .then((response) => {
          if (this.products.file1) {
            this.uploadImg(response.data.data.id)
          }
          if (this.products.file2) {
            this.uploadQR(response.data.data.id)
          }
        })
        .catch((error) => {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
            html: '<span>' + error + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    uploadImg (id) {
      let data = new FormData()
      data.append('file', this.products.file1)
      let uploadImgAPI = {
        method: 'post',
        url: `${configAPI.MA_PORT}/api/product/${id}/upload/img/`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      axios.request(uploadImgAPI)
        .then((response) => {})
        .catch((error) => {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
            html: '<span>' + error + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    SetActiveSupProduct (supplier) {
      const supTemp = this.productData.data[0].supplier_list
      for (let item of supTemp) {
        if (item === supplier) {
          item.is_true = true
        } else {
          item.is_true = false
        }
      }
      supTemp.sort((a, b) => b.is_true - a.is_true)
    },
    uploadQR (id) {
      let data2 = new FormData()
      data2.append('file', this.products.file2)
      let uploadQRAPI = {
        method: 'post',
        url: `${configAPI.MA_PORT}/api/product/${id}/upload/qrcode/`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data2
      }
      axios.request(uploadQRAPI)
        .then((response) => {})
        .catch((error) => {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
            html: '<span>' + error + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async supplierDD () {
      let supplierDDAPI = {
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/supplier`,
        headers: {
          'Content-Type': 'application/json'
        }
      }

      await axios.request(supplierDDAPI)
        .then((response) => {
          this.supplierDDdata = response.data
        })
        .catch((error) => {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
            html: '<span>' + error + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    setPage () {
      return this.$route.params.setPage
    },
    remove (item) {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการลบข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let index = this.productData.data[0].supplier_list.indexOf(item)
          this.productData.data[0].supplier_list.splice(index, 1)
        }
      })
    },
    handleImageUpload (event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      this.products.file1 = file
      reader.readAsDataURL(file)
      this.products.imageName = file.name
      reader.onload = () => {
        this.products.imageUrl = reader.result
      }
    },
    handleImageUpload2 (event) {
      const file2 = event.target.files[0]
      const reader2 = new FileReader()
      this.products.file2 = file2
      reader2.readAsDataURL(file2)
      this.products.imageName2 = file2.name
      reader2.onload = () => {
        this.products.imageUrl2 = reader2.result
      }
    },
    closeModal () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะยกเลิกใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.showModal = false
        }
      })
    },
    handleClick (status) {
      if (status) {
        Vue.swal.fire({
          title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.$route.params.setPage !== 'edit' ? 'คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่?' : 'คุณมั่นใจว่าคุณจะแก้ไขข้อมูลใช่หรือไม่?'}</span>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            let errorList = await this.checkError()
            if (errorList.length > 0) {
              Vue.swal.fire({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลไม่สำเร็จ</span>',
                html: '<span>' + errorList.join('<br>') + '</span>',
                icon: 'warning',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
                confirmButtonColor: '#089bab',
                customClass: {
                  confirmButton: 'custom-confirm-button-class mx-1'
                }
              })
              return
            }
            const hasDup = await this.checkDupProduct(this.$route.params.setPage, this.$route.params.id)
            if (hasDup) {
              this.SwalError('รหัสสินค้าซ้ำกับในระบบ กรุณาลองใหม่', 'บันทึกข้อมูลไม่สำเร็จ')
              return
            }
            if (this.$route.params.setPage === 'add') {
              this.addProduct()
            } else if (this.$route.params.setPage === 'edit') {
              this.editProduct()
            }
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              router.push(`/Product`)
            })
          }
        })
      } else {
        if (this.mode === 'view') {
          router.go(-1)
        } else {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่?</span>',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
            confirmButtonColor: '#089bab',
            cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
            cancelButtonColor: '#d33',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1',
              cancelButton: 'custom-confirm-button-class mx-1'
            }
          }).then((result) => {
            if (result.isConfirmed) {
              router.push(`/Product`)
            }
          })
        }
      }
    },
    async checkDupProduct (mode, id) {
      return axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/checkProduct`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: { mode, prd_no: this.productData.data[0].no, prd_id: id }
      })
        .then((response) => {
          return (response.data.data.length > 0)
        })
        .catch(() => {
          return true
        })
    },
    onPagination (page) {
      this.currentPage = page
    },
    addSupplier () {
      let errorList = this.checkErrorSupplier()
      if (errorList.length > 0) {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">กรุณากรอกข้อมูลให้ครบถ้วน</span>',
          html: '<span>' + errorList.join('<br>') + '</span>',
          icon: 'warning',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1'
          }
        })
        return
      }
      let temp = {
        id: '',
        sup_id: this.selectedOptionSup.code,
        sup_name: this.selectedOptionSup.label,
        buy_price: this.supplier.var_supplier2?.trim().replace(/,/g, ''),
        minimum_order: this.supplier.var_supplier3?.trim().replace(/,/g, ''),
        lead_time: this.supplier.var_supplier4?.trim().replace(/,/g, ''),
        remark: this.supplier.var_supplier5,
        updated_date: new Date(),
        is_certificate: this.supplier.var_supplier6,
        is_true: false
      }
      this.productData.data[0].supplier_list.push(temp)
      this.showModal = false
      this.supplier = this.resetSupplier
      this.selectedOptionSup = null
    },
    setEditSupplier (data, index) {
      this.selectedOptionSup = {
        code: data.sup_id,
        label: data.sup_name
      }
      this.supplier.var_supplier1 = data.sup_id
      this.supplier.var_supplier2 = data.buy_price.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      this.supplier.var_supplier3 = data.minimum_order.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      this.supplier.var_supplier4 = data.lead_time.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      this.supplier.var_supplier5 = data.remark
      this.supplier.var_supplier6 = data.is_certificate
      this.supplier.var_supplier7 = data.sup_name
      this.indexTableEdit = index
    },
    editSupplier () {
      this.productData.data[0].supplier_list[this.indexTableEdit].sup_id = this.supplier.var_supplier1
      this.productData.data[0].supplier_list[this.indexTableEdit].buy_price = this.supplier.var_supplier2?.trim().replaceAll(/,/g, '')
      this.productData.data[0].supplier_list[this.indexTableEdit].minimum_order = this.supplier.var_supplier3?.trim().replaceAll(/,/g, '')
      this.productData.data[0].supplier_list[this.indexTableEdit].lead_time = this.supplier.var_supplier4?.trim().replaceAll(/,/g, '')
      this.productData.data[0].supplier_list[this.indexTableEdit].remark = this.supplier.var_supplier5
      this.productData.data[0].supplier_list[this.indexTableEdit].is_certificate = this.supplier.var_supplier6
      this.productData.data[0].supplier_list[this.indexTableEdit].sup_name = this.supplier.var_supplier7
      this.showModal = false
    },
    isNumberComma1 (event, variable) {
      if (variable === '2') {
        this.supplier.var_supplier2 = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === '3') {
        this.supplier.var_supplier3 = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === '4') {
        this.supplier.var_supplier4 = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === 'stock') {
        this.productData.data[0].min_stock = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === 'price') {
        this.productData.data[0].price = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === 'buy') {
        this.productData.data[0].sale_price = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === 'normal') {
        this.productData.data[0].normal_price = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === 'minimum') {
        this.productData.data[0].minimum_price = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      }
    },
    isNumberCheck (keyPressed, status) {
      let useData = keyPressed.target.value

      let keyCode = (keyPressed.keyCode ? keyPressed.keyCode : keyPressed.which)

      if (status) {
        if (keyCode === 46 || useData.indexOf('.') !== -1) {
          keyPressed.preventDefault()
        }
        if (keyCode < 48 || keyCode > 57) {
          keyPressed.preventDefault()
        }
      } else {
        if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || useData.indexOf('.') !== -1)) {
          keyPressed.preventDefault()
        }
      }
      if (useData !== null && useData.indexOf('.') > -1 && (useData.split('.')[1].length > 1)) {
        keyPressed.preventDefault()
      }

      if ((useData === null || useData === '') && keyCode === 46) {
        keyPressed.preventDefault()
      }
    },
    setDataSupplierModal () {
      this.selectedOptionSup = null
      this.supplier.var_supplier1 = ''
      this.supplier.var_supplier2 = ''
      this.supplier.var_supplier3 = null
      this.supplier.var_supplier4 = null
      this.supplier.var_supplier5 = null
      this.supplier.var_supplier6 = null
      this.supplier.var_supplier7 = ''
      this.showModal = true
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.id % 2 === 1) return 'table-body-color'
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    getFullImagePath (relativePath) {
      if (relativePath) {
        relativePath = relativePath.substring(1)
      }
      const baseURL = `${configAPI.MA_PORT}`
      return baseURL + relativePath
    },
    async checkError () {
      let errorList = []
      if (this.productData.data[0].no?.trim() === null || this.productData.data[0].no?.trim() === '') {
        errorList.push('กรุณากรอกรหัสสินค้า')
      }
      if (this.productData.data[0].name?.trim() === null || this.productData.data[0].name?.trim() === '') {
        errorList.push('กรุณากรอกชื่อยา')
      }
      if (this.productData.data[0].brand_name?.trim() === null || this.productData.data[0].brand_name?.trim() === '') {
        errorList.push('กรุณากรอกชื่อการค้า')
      }
      if (this.productData.data[0].psg_pg_id === null || this.productData.data[0].psg_pg_id === '') {
        errorList.push('กรุณาเลือกประเภทหลัก')
      }
      if (this.productData.data[0].psg_id === null || this.productData.data[0].psg_id === '') {
        errorList.push('กรุณาเลือกประเภทย่อย')
      }
      if (this.productData.data[0].unit_id === null || this.productData.data[0].unit_id === '') {
        errorList.push('กรุณาเลือกหน่วยนับ')
      }
      // if (this.productData.data[0].license_no?.trim() === null || this.productData.data[0].license_no?.trim() === '') {
      //   errorList.push('กรุณากรอก เลขทะเบียน')
      // }
      // if (this.productData.data[0].min_stock === null || this.productData.data[0].min_stock === '') {
      //   errorList.push('กรุณากรอก สต๊อกขั้นต่ำ')
      // }
      // if (this.productData.data[0].price === null || this.productData.data[0].price === '') {
      //   errorList.push('กรุณากรอกราคาขาย')
      // }
      // if (this.productData.data[0].sale_price?.trim() === null || this.productData.data[0].sale_price?.trim() === '') {
      //   errorList.push('กรุณากรอกราคาซื้อ')
      // }
      return errorList
    },
    checkErrorSupplier () {
      let errorList = []
      if (this.supplier.var_supplier1 === null || this.supplier.var_supplier1 === '') {
        errorList.push('กรุณาเลือก ซัพพลายเออร์')
      }
      if (this.supplier.var_supplier2 === null || this.supplier.var_supplier2 === '') {
        errorList.push('กรุณากรอก ราคาซื้อต่อชิ้น')
      }
      if (this.supplier.var_supplier3 === null || this.supplier.var_supplier3 === '') {
        errorList.push('กรุณากรอก รายการที่สั่งขั้นต่ำ')
      }
      if (this.supplier.var_supplier4 === null || this.supplier.var_supplier4 === '') {
        errorList.push('กรุณากรอก เวลาในการจัดส่ง(วัน)')
      }
      if (this.supplier.var_supplier6 === null || this.supplier.var_supplier6 === '') {
        errorList.push('กรุณาเลือก มาตรฐานรองรับ อย.')
      }
      return errorList
    },
    preventDecimalAndNegative (event) {
      const disallowedKeys = ['-', '.', 'e', 'E', '+', '=', '*']
      const maxLength = 13

      if (disallowedKeys.includes(event.key)) {
        event.preventDefault()
      }

      if (event.target.value.length >= maxLength) {
        event.preventDefault()
      }
    },
    returnComma (value) {
      return value.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}
</script>
