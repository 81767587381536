<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4">
              <div class="ml-4">
                <span class="mx-2" style="font-size: 25px; color: black;">ติดตามเคส</span>
              </div>
              <b-col cols="12" sm="12" md="8" lg="8" class="px-4">
                  <b-row class="searchres">
                    <b-col lg="6" md="6" sm="12">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input v-model="serach" @keydown="enterSearch" class="form-control" id="exampleInputEmail1" placeholder="Search" style="width: 100%;">
                            <a @click="getCaseTrackData" class="search-link"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="my-1" style="display: flex; align-items: center;">
                      <span class="mx-2 font-size-14 text-primary">
                        {{ caseTrackData?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > caseTrackData?.length) ? caseTrackData?.length : (perPage * currentPage) ) + ' of ' + caseTrackLength : '0 of ' + caseTrackLength }} รายการ
                        <!-- {{ caseTrackData?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > caseTrackData?.length) ? caseTrackData?.length : (perPage * currentPage) ) + ' of ' + caseTrackData?.length : 0 }} รายการ -->
                      </span></b-col>
                  </b-row>
                </b-col>
            </div>
          </template>
          <template v-slot:body>
              <b-col md="12" class="table-responsive">
                <b-table bordered hover :items="caseTrackData" :fields="columns" :current-page="currentPage" :per-page="perPage" @pagination="onPagination" style="min-width: 2000px; max-width: 2000px;" :tbody-tr-class="rowClass" thead-class="table-header-color">
                <template v-slot:cell(variable1)="data">
                  <div style="display: flex; justify-content: center;">
                    <b-button class="d-flex" variant=" iq-bg-primary mx-1" size="sm" @click="handleClick('view', data.item.id)"><i class="ri-eye-line m-0"></i></b-button>
                    <b-button class="d-flex" variant=" iq-bg-success mx-1" size="sm" @click="handleClick('edit', data.item.id)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                  </div>
                </template>
                <template v-slot:cell(variable2)="data">
                  <span>{{ data.item.st_name || '-' }}</span>
                </template>
                <template v-slot:cell(variable3)="data">
                  <span>{{ data.item.day0 || '-' }}</span>
                </template>
                <template v-slot:cell(variable4)="data">
                  <span>{{ data.item.day1 || '-' }}</span>
                </template>
                <template v-slot:cell(variable5)="data">
                  <span>{{ data.item.day3 || '-' }}</span>
                </template>
                <template v-slot:cell(variable6)="data">
                  <span>{{ data.item.week1 || '-' }}</span>
                </template>
                <template v-slot:cell(variable7)="data">
                  <span>{{ data.item.week2 || '-' }}</span>
                </template>
                <template v-slot:cell(variable8)="data">
                  <span>{{ data.item.week4 || '-' }}</span>
                </template>
                <template v-slot:cell(variable9)="data">
                  <span>{{ data.item.month3 || '-' }}</span>
                </template>
                <template v-slot:cell(variable10)="data">
                  <span>{{ data.item.month6 || '-' }}</span>
                </template>
                <template v-slot:cell(variable11)="data">
                  <span>{{ data.item.year1 || '-' }}</span>
                </template>
                <template v-slot:cell(variable12)="data">
                  <span>{{ data.item.year2 || '-' }}</span>
                </template>
              </b-table>
              <br>
            </b-col>
            <b-pagination
              v-model="currentPage"
              :total-rows="caseTrackData?.length"
              :per-page="perPage"
              align="center"
            />
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
@media (width: 700px) {
  .searchres {
    gap: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import axios from 'axios'
import { configAPI } from '../../configBase'

export default {
  name: 'UiDataTable',
  async mounted () {
    await this.getCaseTrackData()
    xray.index()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getCaseTrackData()
      }
    },
    async getCaseTrackData () {
      let CaseTrackDataAPI = {
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/followCase?search=${this.serach?.trim()}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(CaseTrackDataAPI)
        .then((response) => {
          this.caseTrackData = response.data.data
          this.caseTrackLength = response.data.count || 0
        })
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    default () {
      return {
        variable1: '',
        variable2: '',
        variable3: '',
        variable4: '',
        variable5: '',
        variable6: ''
      }
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick (e, id) {
      const setPage = e
      router.push(`/CaseTrackUpdate/${setPage}/${id}`)
    }
  },
  data () {
    return {
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      serach: '',
      caseTrackLength: 0,
      columns: [
        { label: '', key: 'variable1', class: 'text-center' },
        { label: 'ประเภทการใช้งาน', key: 'variable2', class: 'text-center' },
        { label: 'Day 0', key: 'variable3', class: 'text-center' },
        { label: 'Day 1', key: 'variable4', class: 'text-center' },
        { label: 'Day 3', key: 'variable5', class: 'text-center' },
        { label: '1 week', key: 'variable6', class: 'text-center' },
        { label: '2 week', key: 'variable7', class: 'text-center' },
        { label: '4 week', key: 'variable8', class: 'text-center' },
        { label: '3 month(12 weeks)', key: 'variable9', class: 'text-center' },
        { label: '6 month(24 weeks)', key: 'variable10', class: 'text-center' },
        { label: '1 year(52 weeks)', key: 'variable11', class: 'text-center' },
        { label: '2 year(104 weeks)', key: 'variable12', class: 'text-center' }
      ],
      currentPage: 1,
      perPage: 10,
      caseTrackData: null
    }
  }
}
</script>
