<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="table-responsive">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span v-if="mode === 'add'" style="font-size: 25px; color: black;" class="unselectable">ซัพพลายเออร์
                  (เพิ่ม)</span>
                <span v-else-if="mode === 'edit'"
                  style="font-size: 25px; color: black;" class="unselectable">ซัพพลายเออร์ (แก้ไข)</span>
                <span v-else style="font-size: 25px; color: black;" class="unselectable">ซัพพลายเออร์ (ดูรายละเอียด)</span>
              </div>
              <div v-if="mode === 'add' || mode === 'edit'" class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-6 pl-3 pr-2 my-1 my-sm-0">
                    <b-button type="submit" variant="primary" @click="AddEditSupplier(true)" class="w-100 btn-min-size"><i class="save-data_ct"></i>บันทึก</b-button>
                  </div>
                  <div class="col-6 pl-2 pr-3 my-1 my-sm-0">
                    <b-button type="submit" variant="none" @click="AddEditSupplier(false)" class="iq-bg-danger btn-cancel-border w-100 btn-min-size">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
              <div v-else class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="submit" variant="none" @click="AddEditSupplier(false)" class="iq-bg-danger btn-cancel-border w-100 btn-min-size">ยกเลิก</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col class="table-responsive mx-4">
                <div style="color: black; font-size: large;" class="unselectable">รายละเอียดซัพพลายเออร์</div>
                <hr style="margin-bottom: 1rem;">
                <b-row>
                  <b-col lg="6" sm="12" class="py-2">
                    <b-form-group class="unselectable" style="color: black;" label="ชื่อซัพพลายเออร์">
                      <template v-if="supData.sup_name === null || supData.sup_name?.trim() === ''" v-slot:label>
                        ชื่อซัพพลายเออร์<span style="color: red;"> *</span>
                      </template>
                      <b-form-input type="text" :disabled="isDisable" v-model="supData.sup_name"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12" class="py-2">
                    <b-form-group class="unselectable" style="color: black;" label="ชื่อเต็มซัพพลายเออร์">
                      <template v-if="supData.sup_fullname === null || supData.sup_fullname?.trim() === ''" v-slot:label>
                        ชื่อเต็มซัพพลายเออร์<span style="color: red;"> *</span>
                      </template>
                      <b-form-input type="text" :disabled="isDisable" v-model="supData.sup_fullname"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form-group class="unselectable" style="color: black;" label="รายละเอียดซัพพลายเออร์">
                  <template v-if="supData.sup_details === null || supData.sup_details?.trim() === ''" v-slot:label>
                    รายละเอียดซัพพลายเออร์<span style="color: red;"> *</span>
                  </template>
                  <b-form-textarea class="" :disabled="isDisable" type="text" v-model="supData.sup_details" style="height: 200px; justify-items: flex-start;"></b-form-textarea>
                </b-form-group>
                <b-row>
                  <b-col lg="6" sm="12" class="py-2">
                    <b-form-group class="unselectable" style="color: black;" label="อีเมลซัพพลายเออร์">
                      <b-form-input type="text" :disabled="isDisable" v-model="supData.sup_email"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12" class="py-2">
                    <b-form-group class="unselectable" style="color: black;" label="ไอดีไลน์">
                      <template v-if="supData.sup_line === null || supData.sup_line?.trim() === ''" v-slot:label>
                        ไอดีไลน์<span style="color: red;"> *</span>
                      </template>
                      <b-form-input type="text" :disabled="isDisable" v-model="supData.sup_line"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <br>
                <div style="color: black; font-size: large;" class="unselectable">รายละเอียดผู้ติดต่อ</div>
                <hr style="margin-bottom: 1rem;">
                <b-row>
                  <b-col lg="6" sm="12" class="py-2">
                    <b-form-group class="unselectable" style="color: black;" label="ชื่อผู้ติดต่อ">
                      <template v-if="supData.con_name === null || supData.con_name?.trim() === ''" v-slot:label>
                        ชื่อผู้ติดต่อ<span style="color: red;"> *</span>
                      </template>
                      <b-form-input type="text" :disabled="isDisable" v-model="supData.con_name"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12" class="py-2">
                    <b-form-group class="unselectable" style="color: black;" label="อีเมลส่วนบุคคล">
                      <b-form-input type="text" :disabled="isDisable" v-model="supData.con_email"></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6" sm="12" class="py-2">
                    <b-form-group class="unselectable" style="color: black;" label="เบอร์โทรศัพท์">
                      <template v-if="supData.con_tel === null || supData.con_tel?.trim() === '' || (supData.con_tel?.trim().replaceAll(/-/g, '')).length < 9" v-slot:label>
                        เบอร์โทรศัพท์<span style="color: red;"> *</span>
                      </template>
                      <b-form-input type="text" :disabled="isDisable" v-model="supData.con_tel" @keypress="formatContact($event)"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" sm="12" class="py-2">
                    <b-form-group class="unselectable" style="color: black;" label="ไอดีไลน์">
                      <b-form-input type="tel" :disabled="isDisable" v-model="supData.con_line"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col lg="12" sm="12" class="py-2">
                    <b-form-group class="unselectable" style="color: black;" label="ข้อคิดเห็น">
                      <b-form-textarea type="text" :disabled="isDisable" v-model="supData.con_remark" style="height: 150px; justify-items: flex-start;"></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
                <br>
                <div class="row align-items-center">
                  <div class="row align-items-center pb-4">
                  <div class="ml-4">
                    <span class="mx-1 unselectable" style="font-size: 20px; color: black;">ยอดสะสม</span>
                  </div>
                  <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div class="d-flex my-2 mx-2" style="white-space: nowrap;">
                      <b-button @click="AddCumSup('')" :disabled="isDisable" type="submit" variant="primary" class="mx-1 flex-fill text-center btn-min-size" style="text-align: center;"><i class="circle-plus_ct"></i>เพิ่ม</b-button>
                    </div>
                  </div>
                </div>
                <br>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="cumulativeData" :fields="columns"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @pagination="onPagination"
                    :tbody-tr-class="rowClass" thead-class="table-header-color"
                  >
                    <template v-slot:cell(variable1)="data">
                      <span class="unselectable">{{ data.item.name }}</span>
                    </template>
                    <template v-slot:cell(variable2)="data">
                      <span class="unselectable">{{ TransformDate(data.item.start_date) }}</span>
                    </template>
                    <template v-slot:cell(variable3)="data">
                      <span class="unselectable">{{ TransformDate(data.item.end_date) }}</span>
                    </template>
                    <template v-slot:cell(variable4)="data">
                      <span class="unselectable">{{ data.item.total?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</span>
                    </template>
                    <template v-slot:cell(variable5)="data">
                      <span class="unselectable">{{ data.item.sum_price?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '-' }}</span>
                    </template>
                    <template v-slot:cell(variable6)="data">
                      <span class="unselectable" style="white-space: pre-line;">{{ ListName(data.item.cm_list) }}</span>
                    </template>
                    <template v-slot:cell(variable7)="data">
                      <div style="display: flex; justify-content: center;" >
                        <b-button class="d-flex" :disabled="isDisable" variant=" iq-bg-success mx-1" size="sm" id="edit-summary" @click="AddCumSup(data.item)"><i class="ri-ball-pen-fill m-0" id="show-modal" @click="showModal"></i></b-button>
                        <b-button variant=" iq-bg-danger" :disabled="isDisable" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                      </div>
                    </template>
                  </b-table>
                  <br>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="cumulativeData?.length"
                    :per-page="perPage"
                    align="center"
                  />
                </b-col>
              </div>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>
  </b-row>
  </b-container>
</template>

<style>
.margin-text-header {
  margin-left: 1rem;
}
.Spadding-text-header {
  margin-left: 1rem;
  margin-right: 1rem;
}
.apg-response-header-btn {
  margin-left: .5rem;
  margin-right: .5rem;
}
@media (max-width: 560px) {
  .Spadding-text-header {
    margin-left: 0.5rem !important;
    margin-right: 0 !important;
  }
  .margin-text-header {
    margin-left: 1rem;
  }
  .apg-response-header {
    display: flex;
    align-items: flex-start !important;
    flex-direction: column;
  }
  .apg-response-header-btn {
    width: 100% !important;
    margin-left: 0;
    margin-right: 0;
  }
  .iq-header-title {
    width: 100% !important;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import axios from 'axios'
import moment from 'moment'
import Vue from 'vue'
import { configAPI } from '../../configBase'

export default {
  name: 'UiDataTable',
  data () {
    return {
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      supId: this.$route.params.id || null,
      mode: 'add',
      isDisable: false,
      supData: {
        sup_name: null,
        sup_fullname: null,
        sup_details: null,
        sup_email: null,
        sup_line: null,
        con_name: null,
        con_email: null,
        con_tel: null,
        con_line: null,
        con_remark: null
      },
      showModal: false,
      columns: [
        { label: 'ชื่อยอดสะสม', key: 'variable1', class: 'text-center table-size-200-400' },
        { label: 'วันที่เริ่มต้น', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'วันที่สิ้นสุด', key: 'variable3', class: 'text-center table-size-200-400' },
        { label: 'จำนวน', key: 'variable4', class: 'text-center table-size-200-400' },
        { label: 'ยอดขาย', key: 'variable5', class: 'text-center table-size-200-400' },
        { label: 'Raw mat', key: 'variable6', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'variable7', class: 'text-center' }
      ],
      cumulativeData: null,
      currentPage: 1,
      perPage: 10,
      deleteCumulative: []
    }
  },
  async mounted () {
    this.mode = this.$route.params.setPage === 'edit' ? 'edit' : this.$route.params.setPage === 'view' ? 'view' : 'add'
    this.supId = this.$route.params.id
    if (this.mode === 'view') {
      this.isDisable = true
    }
    if (this.mode === 'edit' || this.mode === 'view') {
      await this.getSupplierData()
      await this.getCumulativeSupplier()
    }
    xray.index()
  },
  methods: {
    async getSupplierData () {
      let SupplierDataAPI = {
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/supplier/${this.supId}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(SupplierDataAPI)
        .then((response) => {
          this.supData = {
            sup_name: response.data.data[0].name,
            sup_fullname: response.data.data[0].full_name,
            sup_details: response.data.data[0].detail,
            sup_email: response.data.data[0].email,
            sup_line: response.data.data[0].line_id,
            con_name: response.data.data[0].constact_name,
            con_email: response.data.data[0].person_email,
            con_tel: response.data.data[0].contact_tel ? this.formatTel(response.data.data[0].contact_tel) : null,
            con_line: response.data.data[0].contact_line_id,
            con_remark: response.data.data[0].remark
          }
        })
    },
    async getCumulativeSupplier () {
      let CumulativeSupplierAPI = {
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/cumulative?sup_id=${this.supId}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(CumulativeSupplierAPI)
        .then((response) => {
          this.cumulativeData = response.data.data
        })
    },
    formatContact (e) {
      let keyCode = (e.keyCode ? e.keyCode : e.which)
      if (this.supData.con_tel.length >= 12 || (keyCode < 48 || keyCode > 57)) {
        e.preventDefault()
        this.supData.con_tel = this.supData.con_tel.slice(0, 12)
      } else {
        let inputValue = this.supData.con_tel.toString().replace(/[^0-9]/g, '')
        if (inputValue.length > 12) {
          inputValue = inputValue.slice(0, 12)
        }
        let formattedValue = inputValue.match(/.{1,3}/g).join('-')
        this.supData.con_tel = formattedValue
      }
    },
    remove (item) {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะลบข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteCumulative.push(item.id)
          let index = this.cumulativeData.indexOf(item)
          this.cumulativeData.splice(index, 1)
        }
      })
    },
    onPagination (page) {
      this.currentPage = page
    },
    async CheckError () {
      let errorList = []
      if (this.supData.sup_name === null || this.supData.sup_name?.trim() === '') {
        errorList.push('กรุณากรอก ชื่อซัพพลายเออร์')
      }
      if (this.supData.sup_fullname === null || this.supData.sup_fullname?.trim() === '') {
        errorList.push('กรุณากรอก ชื่อเต็มซัพพลายเออร์')
      }
      if (this.supData.sup_details === null || this.supData.sup_details?.trim() === '') {
        errorList.push('กรุณากรอก รายละเอียดซัพพลายเออร์')
      }
      if (this.supData.sup_line === null || this.supData.sup_line?.trim() === '') {
        errorList.push('กรุณากรอก ไอดีไลน์ (ซัพพลายเออร์)')
      }
      if (this.supData.con_name === null || this.supData.con_name?.trim() === '') {
        errorList.push('กรุณากรอก ชื่อผู้ติดต่อ')
      }
      if (this.supData.con_tel === null || this.supData.con_tel?.trim() === '') {
        errorList.push('กรุณากรอก เบอร์โทรศัพท์ (ผู้ติดต่อ)')
      } else if (this.checkNumberOnly(this.supData.con_tel?.trim().replaceAll(/-/g, '')) === false) {
        errorList.push('กรุณากรอก เบอร์โทรศัพท์ (ผู้ติดต่อ) เป็นตัวเลขเท่านั้น')
      } else if (this.supData.con_tel?.trim().replaceAll(/-/g, '').length < 9) {
        errorList.push('กรุณากรอก เบอร์โทรศัพท์ (ผู้ติดต่อ) ให้ครบ 9 ถึง 10 หลัก')
      }
      return errorList
    },
    async AddEditSupplier (status) {
      if (status && this.mode === 'add') {
        let errorList = await this.CheckError()
        if (errorList.length > 0) {
          Vue.swal.fire({
            html: `<span class="" style="font-size: 20px; color: black; text-align: center">${errorList.join('<br>')}</span>`,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
          return
        }
        Vue.swal.fire({
          title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะบันทึกข้อมูลใช่หรือไม่?</span>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            let dataForAdd = {
              st_id: null,
              no: null,
              name: this.supData.sup_name?.trim() || null,
              full_name: this.supData.sup_fullname?.trim() || null,
              detail: this.supData.sup_details?.trim() || null,
              email: this.supData.sup_email?.trim() || null,
              line_id: this.supData.sup_line?.trim() || null,
              contact_fax: null,
              constact_name: this.supData.con_name?.trim() || null,
              position: null,
              person_email: this.supData.con_email?.trim() || null,
              contact_tel: this.supData.con_tel?.trim().replaceAll(/-/g, '') || null,
              contact_line_id: this.supData.con_line?.trim() || null,
              mobile: null,
              remark: this.supData.con_remark?.trim() || null
            }
            let addSupplierAPI = {
              method: 'POST',
              url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/supplier`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: dataForAdd
            }
            await axios.request(addSupplierAPI)
              .then((response) => {
                if (response.data.status === true) {
                  Vue.swal({
                    title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
                    icon: 'success',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    router.push(`/Supplier`)
                  })
                } else {
                  this.SwalError(response.data.error, 'บันทึกข้อมูลไม่สำเร็จ')
                }
              })
          }
        })
      } else if (status && this.mode === 'edit') {
        let errorList = await this.CheckError()
        if (errorList.length > 0) {
          Vue.swal.fire({
            html: `<span class="" style="font-size: 20px; color: black; text-align: center">${errorList.join('<br>')}</span>`,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
          return
        }
        Vue.swal.fire({
          title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะแก้ไขข้อมูลใช่หรือไม่?</span>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            if (this.deleteCumulative.length > 0) {
              this.deleteCumulative.forEach(async (item) => {
                let deleteCumulativeAPI = {
                  method: 'POST',
                  url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/cumulative/${item}/delete`,
                  headers: {
                    'Content-Type': 'application/json'
                  }
                }
                await axios.request(deleteCumulativeAPI)
              })
            }
            let dataForEdit = {
              st_id: null,
              no: null,
              name: this.supData.sup_name?.trim() || null,
              full_name: this.supData.sup_fullname?.trim() || null,
              detail: this.supData.sup_details?.trim() || null,
              email: this.supData.sup_email?.trim() || null,
              line_id: this.supData.sup_line?.trim() || null,
              contact_fax: null,
              constact_name: this.supData.con_name?.trim() || null,
              position: null,
              person_email: this.supData.con_email?.trim() || null,
              contact_tel: this.supData.con_tel?.trim().replaceAll(/-/g, '') || null,
              contact_line_id: this.supData.con_line?.trim() || null,
              mobile: null,
              remark: this.supData.con_remark?.trim() || null
            }
            let editSupplierAPI = {
              method: 'PUT',
              url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/supplier/${this.supId}`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: dataForEdit
            }
            await axios.request(editSupplierAPI)
              .then((response) => {
                if (response.data.status === true) {
                  Vue.swal({
                    title: '<span class="" style="font-size: 25px; color: black; text-align: center">แก้ไขข้อมูลสำเร็จ</span>',
                    icon: 'success',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    router.push(`/Supplier`)
                  })
                } else {
                  this.SwalError(response.data.error, 'แก้ไขข้อมูลไม่สำเร็จ')
                }
              })
          }
        })
      } else {
        if (this.mode === 'view') {
          router.push(`/Supplier`)
        } else {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะยกเลิกใช่หรือไม่?</span>',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
            confirmButtonColor: '#089bab',
            cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
            cancelButtonColor: '#d33',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1',
              cancelButton: 'custom-confirm-button-class mx-1'
            }
          }).then((result) => {
            if (result.isConfirmed) {
              router.push(`/Supplier`)
            }
          })
        }
      }
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    async AddCumSup (idCum) {
      let errorList = await this.CheckError()
      if (errorList.length > 0) {
        Vue.swal.fire({
          html: `<span class="" style="font-size: 20px; color: black; text-align: center">${errorList.join('<br>')}</span>`,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1'
          }
        })
        return
      }
      if (this.mode === 'add') {
        Vue.swal.fire({
          title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะบันทึกข้อมูลใช่หรือไม่?</span>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            let dataForAdd = {
              st_id: null,
              no: null,
              name: this.supData.sup_name?.trim() || null,
              full_name: this.supData.sup_fullname?.trim() || null,
              detail: this.supData.sup_details?.trim() || null,
              email: this.supData.sup_email?.trim() || null,
              line_id: this.supData.sup_line?.trim() || null,
              contact_fax: null,
              constact_name: this.supData.con_name?.trim() || null,
              position: null,
              person_email: this.supData.con_email?.trim() || null,
              contact_tel: this.supData.con_tel?.trim().replaceAll(/-/g, '') || null,
              contact_line_id: this.supData.con_line?.trim() || null,
              mobile: null,
              remark: this.supData.con_remark?.trim() || null
            }
            let addSupplierAPI = {
              method: 'POST',
              url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/supplier`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: dataForAdd
            }
            await axios.request(addSupplierAPI)
              .then((response) => {
                if (response.data.status === true) {
                  Vue.swal({
                    title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
                    icon: 'success',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    router.push(`/AddSupplierDetail/${response.data.data.id}`)
                  })
                }
              })
          }
        })
      } else {
        Vue.swal.fire({
          title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะแก้ไขข้อมูลใช่หรือไม่?</span>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            let dataForEdit = {
              st_id: null,
              no: null,
              name: this.supData.sup_name?.trim() || null,
              full_name: this.supData.sup_fullname?.trim() || null,
              detail: this.supData.sup_details?.trim() || null,
              email: this.supData.sup_email?.trim() || null,
              line_id: this.supData.sup_line?.trim() || null,
              contact_fax: null,
              constact_name: this.supData.con_name?.trim() || null,
              position: null,
              person_email: this.supData.con_email?.trim() || null,
              contact_tel: this.supData.con_tel?.trim().replaceAll(/-/g, '') || null,
              contact_line_id: this.supData.con_line?.trim() || null,
              mobile: null,
              remark: this.supData.con_remark?.trim() || null
            }
            let editSupplierAPI = {
              method: 'PUT',
              url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/supplier/${this.supId}`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: dataForEdit
            }
            await axios.request(editSupplierAPI)
              .then((response) => {
                if (response.data.status === true) {
                  Vue.swal({
                    title: '<span class="" style="font-size: 25px; color: black; text-align: center">แก้ไขข้อมูลสำเร็จ</span>',
                    icon: 'success',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    if (idCum !== '') {
                      router.push(`/SupplierDetailEV/edit/${idCum.id}/${this.supId}`)
                    } else {
                      router.push(`/AddSupplierDetail/${this.supId}`)
                    }
                  })
                }
              })
          }
        })
      }
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    TransformDate (date) {
      if (date !== null) {
        return moment(date).format('DD/MM/YYYY')
      } else {
        return ''
      }
    },
    SumAmount (data) {
      let sum = 0
      if (data !== null) {
        data.forEach(element => {
          sum += (element.amount ? parseInt(element.amount) : 0)
        })
        return sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {
        return ''
      }
    },
    ListName (data) {
      let list = []
      if (data !== null) {
        data.forEach(element => {
          list.push(element.mt_name)
        })
        return list.join('\n')
      } else {
        return ''
      }
    },
    formatTel (data) {
      return data.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
    },
    checkNumberOnly (tel) {
      let regex = /^[0-9]+$/
      let have = regex.test(tel)
      return have
    }
  }
}
</script>
