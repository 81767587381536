<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="table-responsive">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; white-space: nowrap; color: black;">ตั้งค่าระบบ</span>
              </div>
              <div class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-6 pl-3 pr-2 my-1 my-sm-0">
                    <b-button type="submit" variant="primary" class="w-100 btn-min-size" @click="handleClick(true)"><i class="save-data_ct"></i>บันทึก</b-button>
                  </div>
                  <div class="col-6 pl-2 pr-3 my-1 my-sm-0">
                    <b-button type="submit" variant="danger" class="btn-cancel-border w-100 btn-min-size" @click="handleClick(false)">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="iq-card-body">
              <form>
                <b-row class="mb-4">
                  <b-col lg="12" sm="12">
                    <div class="add-img-user profile-img-edit">
                      <b-img v-if="clinicImage.path !== null || clinicImage.path !== ''" class=" height-170 width-170" fluid :src="clinicImage.path || require('../../assets/images/default.jpg')" alt="profile-pic" style="object-fit: contain;" />
                      <div class="p-image d-flex mb-4" style="justify-content: center;">
                        <b-button variant="none" @click="clickUpload" class="upload-button iq-bg-primary position-relative mb-5" :disabled="isDisable">
                          อัปโหลดรูป
                        </b-button>
                        <b-form-file
                          v-model="clinicImage.file"
                          type="file"
                          id="uploadImage"
                          @change="previewImage"
                          class="h-100 position-absolute"
                          :accept="allowedTypes"
                          style="opacity: 0; display: none;"
                          :disabled="isDisable">
                        </b-form-file>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <!-- <div class="form-group row align-items-center">
                  <b-img v-if="setting[0]"
                    :src="getFullImagePath(setting[0].img_path)"
                    style="height: 100px; width: 100px; align-items: center; margin-left: 15px;"
                  ></b-img>
                  <b-img v-else
                    :src="require('../../assets/images/default.jpg')"
                    style="height: 100px; width: 100px; align-items: center; margin-left: 15px"
                  ></b-img>
                </div> -->
                <div class="align-items-center">
                  <b-row>
                    <b-col lg="12" sm="12">
                      <b-row>
                        <b-col lg="6" sm="6" style="margin-top: 10px;" class="form-group col-sm-6">
                          <label for="Cname">ชื่อคลินิก</label>
                          <input v-if="setting[0]" type="text" class="form-control" id="Cname" v-model="setting[0].name" disabled>
                          <input v-else type="text" class="form-control" id="Cname" disabled>
                        </b-col>
                        <b-col lg="6" sm="6" style="margin-top: 10px;" class="form-group col-sm-6">
                          <label for="Phone">เบอร์ติดต่อ</label>
                          <template v-if="setting[0] && (setting[0].tel === '' || setting[0].tel === null  || setting[0].tel === undefined || setting[0].tel?.length < 12)">
                            <span class="text-danger"> *</span>
                          </template>
                          <input v-if="setting[0]" type="text" class="form-control" id="Phone" @keypress="formatContact($event)" v-model="setting[0].tel" v-mask="'###-###-####'">
                          <input v-else type="text" class="form-control" id="Phone" @keypress="formatContact($event)" v-mask="'###-###-####'">
                          <!-- <input v-else type="text" class="form-control" id="Phone" @keypress="formatContact($event)" v-model="setting[0].tel" placeholder="Contact Tel" v-mask="'###-###-####'"> -->
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="12" sm="12" style="margin-top: 10px;" class="form-group col-sm-6">
                          <label for="address">ที่อยู่คลินิก</label>
                          <template v-if="setting[0] && (setting[0].address === '' || setting[0].address === null  || setting[0].address === undefined)">
                            <span class="text-danger"> *</span>
                          </template>
                          <b-form-textarea rows="3" v-if="setting[0]" type="text" class="form-control" id="address" v-model="setting[0].address"></b-form-textarea>
                          <input v-else type="text" class="form-control" id="address">
                        </b-col>
                        <b-col lg="6" md="4" sm="3" style="margin-top: 10px;" class="form-group col-sm-6">
                          <b-text style="color: black; white-space: nowrap">เวลาเปิด</b-text>
                          <div style="position: relative;" class="w-res-attendance time-picker-attendance">
                            <date-picker v-if="setting[0]" :placeholder="setting[0].start_time" id="datepicker-custom1" name="date" v-model="openingTime" :config="options"></date-picker>
                            <date-picker v-else placeholder="HH:mm" id="datepicker-custom1" name="date" v-model="openingTime" :config="options"></date-picker>
                            <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(1)" style="cursor: pointer;"></i>
                          </div>
                          <!-- <label for="timeOpen">เวลาเปิด</label>
                          <input type="time" class="form-control" id="timeOpen" v-model="openingTime"> -->
                        </b-col>
                        <b-col lg="6" md="4" sm="3" style="margin-top: 10px;" class="form-group col-sm-6">
                          <b-text style="color: black; white-space: nowrap">เวลาปิด</b-text>
                          <div style="position: relative;" class="w-res-attendance time-picker-attendance">
                            <date-picker v-if="setting[0]" :placeholder="setting[0].end_time" id="datepicker-custom2" name="date" v-model="closingTime" :config="options1"></date-picker>
                            <date-picker v-else placeholder="HH:mm" id="datepicker-custom2" :disabled="!isOpeningTimeEntered" name="date" v-model="closingTime" :config="options1"></date-picker>
                            <i class="ri-calendar-line m-0 icon-calendar-edit" :disabled="!isOpeningTimeEntered" @click="onClickDatePicker(2)" style="cursor: pointer;"></i>
                          </div>
                          <!-- <label for="timeClose">เวลาปิด</label>
                          <input type="time" class="form-control" id="timeClose" v-model="closingTime" :disabled="!isOpeningTimeEntered"> -->
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="5" sm="5" style="margin-top: 10px;" class="form-group col-sm-5">
                          <label for="valueLat">Latitude</label>
                          <input v-if="setting[0]" type="text" class="form-control" id="valueLat" v-model="receiveValueLat" :placeholder="setting[0].lattitude">
                          <input v-else type="text" class="form-control" id="valueLat" v-model="receiveValueLat" placeholder="Enter latitude">
                        </b-col>
                        <b-col lg="5" sm="5" style="margin-top: 10px;" class="beforeMapButton form-group col-sm-5">
                          <label for="valueLng">Longitude</label>
                          <input v-if="setting[0]" type="text" class="form-control" id="valueLat" v-model="receiveValueLng" :placeholder="setting[0].longtitude">
                          <input v-else type="text" class="form-control" id="valueLng" v-model="receiveValueLng" placeholder="Enter longitude">
                        </b-col>
                        <b-col lg="2" sm="2"  style="margin-top: 10px;" class="MapButton form-group col-sm-2">
                          <label><br class="GapBr"></label>
                          <div>
                            <b-button @click="getInputLatLng" class="input-group-text btn-reset_ct" type="button" variant="none" style="width: 100%; height: 45px; border-radius: 10px; justify-content: center;">
                              <i class="map_ct"/><p class="textGoogleMap" style="padding-top: 1rem;">Google Map</p>
                            </b-button>
                            <b-modal v-model="showModal" id="modal-3" size="xl" title="เลือกตำแหน่งที่ตั้ง" ok-title="ตกลง" @ok="showLocation" cancel-title="ยกเลิก">
                              <template>
                                <div>
                                  <div id="map" style="height: 600px;">
                                    <GoogleMap lat="receiveValueLat" lng="receiveValueLng" @value-updated="handleValueUpdated" ref="googleMapRef" />
                                  </div>
                                </div>
                              </template>
                              <template #modal-footer>
                                <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                                  <b-button
                                    style="border-radius: 0.5rem;"
                                    variant="primary"
                                    size="sm"
                                    @click="showLocation"
                                  >
                                    ตกลง
                                  </b-button>
                                  <b-button
                                    class="iq-bg-danger btn-cancel-border"
                                    variant="none"
                                    style="border-radius: 0.5rem;"
                                    size="sm"
                                    @click="showModal=false"
                                  >
                                    ยกเลิก
                                  </b-button>
                                </div>
                              </template>
                            </b-modal>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" sm="6" style="margin-top: 10px;" class="form-group col-sm-6">
                          <label for="Dpay">Social ทั้งหมด</label>
                          <input v-if="setting[0]" v-model="setting[0].social" type="url" class="form-control" id="Social">
                          <input v-else type="url" class="form-control" id="Social">
                        </b-col>
                        <b-col lg="6" sm="6" style="margin-top: 10px;">
                          <b-form-group label-for="exampleFormControlSelect1" label="วันที่ปิดยอดเงินเดือน" style="color: black; margin-bottom: 0%;">
                            <v-select v-if="setting[0]" v-model="setting[0].pay_off" :options="select1"></v-select>
                            <v-select v-else v-model="selectedDate" :options="select1"></v-select>
                            <!-- <v-select v-model="selectedDate" :options="select1"></v-select> -->
                            <!-- <b-form-select class="height-select" plain v-model="selectedDate">
                              <template v-slot:first>
                                <b-form-select-option :value="null">&nbsp;&nbsp;&nbsp;Select</b-form-select-option>
                                <b-form-select-option v-for="date in dates" :key="date" :value="date">&nbsp;&nbsp;&nbsp;{{ date }}</b-form-select-option>
                              </template>
                            </b-form-select> -->
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" sm="6" style="margin-top: 10px;" class="form-group col-sm-6">
                          <label for="inputField">ค่า % ประกันสังคม</label>
                          <CurrencyInputCom v-if="setting[0]" id="exampleInputText1" v-model="setting[0].social_security" :disabled="disableCheck" :isLimit="true" :options="optionCurrency"></CurrencyInputCom>
                          <!-- <input type="text" id="inputField" class="form-control" v-model="" @input="checkperCom" @keypress="isNumberCheck($event,inputValue)" inputmode="decimal" pattern="[0-9]*"> -->
                          <input v-else type="text" id="inputField" class="form-control" @input="checkperCom" @keypress="isNumberCheck($event,inputValue)" inputmode="decimal" pattern="[0-9]*">
                        </b-col>
                        <b-col lg="6" sm="6" style="margin-top: 10px;">
                          <b-form-group label-for="exampleFormControlSelect1" label="วันที่จ่ายประกันสังคม" style="color: black; margin-bottom: 0%;">
                            <v-select v-if="setting[0]" v-model="setting[0].social_security_date" :options="select2"></v-select>
                            <v-select v-else v-model="selectedDate2" :options="select2"></v-select>
                            <!-- <v-select v-model="selectedDate2" style="height: 45px;" class="test" :options="select2"></v-select> -->
                            <!-- <b-form-select class="height-select" plain v-model="selectedDate2">
                              <template v-slot:first>
                                <b-form-select-option :value="null">&nbsp;&nbsp;&nbsp;Select</b-form-select-option>
                                <b-form-select-option v-for="date in dates2" :key="date" :value="date">&nbsp;&nbsp;&nbsp; {{ date }}</b-form-select-option>
                              </template>
                            </b-form-select> -->
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" sm="6" style="margin-top: 10px;" class="form-group col-sm-6">
                          <b-text style="color: black; white-space: nowrap">ช่วงเวลาส่ง Email confirm นัด</b-text>
                          <div style="position: relative;" class="w-res-attendance time-picker-attendance">
                            <date-picker v-if="setting[0]" :placeholder="setting[0].confirm_email" id="datepicker-custom3" v-model="emailTime" name="date" :config="options2"></date-picker>
                            <date-picker v-else placeholder="HH:mm" id="datepicker-custom3" name="date" v-model="emailTime" :config="options2"></date-picker>
                            <!-- <date-picker placeholder="HH:mm" id="datepicker-custom3" name="date" :config="options"></date-picker> -->
                            <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(3)" style="cursor: pointer;"></i>
                          </div>
                          <!-- <label for="t-email">ช่วงเวลาส่ง Email confirm นัด</label>
                          <input type="time" class="form-control" id="t-email"> -->
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </form>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
// eslint-disable-next-line no-unused-vars
import router from '@/router'
import axios from 'axios'
import Vue from 'vue'
import moment from 'moment'
import GoogleMap from './GoogleMap.vue'
import CurrencyInputCom from './CurrencyInputCom.vue'
import { configAPI } from '../../configBase'

const arrList = []
for (let i = 1; i <= 31; i++) {
  let temp = {
    code: i,
    label: i
  }
  arrList.push(temp)
}

export default {
  components: {
    GoogleMap,
    CurrencyInputCom
  },
  computed: {
    isOpeningTimeEntered () {
      return !!this.openingTime
    }
  },
  watch: {
    openingTime (newOpeningTime) {
      this.options1.minDate = newOpeningTime
      if (this.closingTime && newOpeningTime > this.closingTime) {
        this.closingTime = ''
      }
    }
  },
  data () {
    return {
      cln_id: null,
      setting: [],
      select1: [
        ...arrList
      ],
      select2: [
        ...arrList
      ],
      clinicImage: {
        file: null,
        path: null,
        name: null
      },
      oldProfileImage: {
        name: null,
        path: null,
        file: null
      },
      showModal: false,
      imageUrl: null,
      imageUrl2: null,
      Dclose: '',
      Dpay: '',
      openingTime: '',
      closingTime: '',
      emailTime: '',
      inputValue: '',
      supData: {
        con_contact: ''
      },
      allowedTypes: [
        'image/png',
        'image/jpeg'
      ],
      optionCurrency: {
        currency: 'EUR',
        currencyDisplay: 'hidden',
        valueRange: {
          min: 0,
          max: 1e+53
        },
        precision: 2,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: true,
        accountingSign: false
      },
      options: {
        // https://momentjs.com/docs/#/displaying/
        format: 'HH:mm',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        sideBySide: true,
        icons: {
          time: 'fas fa-clock',
          date: 'fas fa-calendar',
          up: 'fas fa-chevron-up',
          down: 'fas fa-chevron-down',
          previous: 'fas fa-chevron-left',
          next: 'fas fa-chevron-right',
          today: 'fas fa-calendar-day',
          clear: 'fas fa-trash-alt',
          close: 'fas fa-times'
        }
      },
      options1: {
        // https://momentjs.com/docs/#/displaying/
        format: 'HH:mm',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        minDate: false,
        icons: {
          time: 'fas fa-clock',
          date: 'fas fa-calendar',
          up: 'fas fa-chevron-up',
          down: 'fas fa-chevron-down',
          previous: 'fas fa-chevron-left',
          next: 'fas fa-chevron-right',
          today: 'fas fa-calendar-day',
          clear: 'fas fa-trash-alt',
          close: 'fas fa-times'
        }
      },
      options2: {
        // https://momentjs.com/docs/#/displaying/
        format: 'HH:mm',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        minDate: false,
        icons: {
          time: 'fas fa-clock',
          date: 'fas fa-calendar',
          up: 'fas fa-chevron-up',
          down: 'fas fa-chevron-down',
          previous: 'fas fa-chevron-left',
          next: 'fas fa-chevron-right',
          today: 'fas fa-calendar-day',
          clear: 'fas fa-trash-alt',
          close: 'fas fa-times'
        }
      },
      activeTab: 'tab1',
      currentPage: 1,
      perPage: 10,
      resultLocation: null,
      receiveValue: '',
      receiveValueLat: '',
      receiveValueLng: '',
      selectedDate: null,
      dates: Array.from({ length: 31 }, (_, index) => index + 1),
      selectedDate2: null,
      dates2: Array.from({ length: 31 }, (_, index) => index + 1)
    }
  },
  async mounted () {
    xray.index()
    this.cln_id = JSON.parse(sessionStorage.getItem('user')).cln_id
    await this.getSetting()
  },
  methods: {
    previewImage: function (event) {
      const input = event.target
      if (input.files[0] && !this.allowedTypes.includes(input.files[0].type)) {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
          html: '<span>กรุณาเลือกไฟล์รูปภาพเท่านั้น</span>',
          icon: 'warning',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          this.clinicImage.name = this.oldclinicImage.name !== null && this.oldclinicImage.name !== '' ? this.oldclinicImage.name : null
          this.clinicImage.path = this.oldclinicImage.path !== null && this.oldclinicImage.path !== '' ? this.oldclinicImage.path : null
          this.clinicImage.file = this.oldclinicImage.file !== null && this.oldclinicImage.file !== '' ? this.oldclinicImage.file : null
        })
      }
      if (input.files && input.files[0]) {
        this.clinicImage.name = input.files[0].name
        this.clinicImage.path = URL.createObjectURL(input.files[0])
        this.clinicImage.file = input.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(input.files[0])
        reader.onload = (e) => {
          this.clinicImage.path = e.target.result
        }
      } else if (this.mode === 'add') {
        this.clinicImage.name = null
        this.clinicImage.path = null
        this.clinicImage.file = null
      } else {
        this.clinicImage.name = this.oldclinicImage.name !== null && this.oldclinicImage.name !== '' ? this.oldclinicImage.name : null
        this.clinicImage.path = this.oldclinicImage.path !== null && this.oldclinicImage.path !== '' ? this.oldclinicImage.path : null
        this.clinicImage.file = null
      }
    },
    clickUpload () {
      document.getElementById('uploadImage').click()
    },
    async getSetting () {
      let settingAPI = {
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(settingAPI)
        .then((response) => {
          // console.log(response.data.data)
          this.setting = response.data.data
          this.receiveValueLat = this.setting[0].lattitude || null
          this.receiveValueLng = this.setting[0].longtitude || null
          if (response.data.data[0]) {
            this.clinicImage.name = response.data.data[0].img
            this.clinicImage.path = this.getFullImagePath(response.data.data[0].img_path)
          }
        })/* .catch((error) => {
          console.error(error)
        }) */
    },
    convertToJson () {
      let dataList = {}
      // const [hours1, minutes1] = this.openingTime.split(':').map(Number)
      // const currentDate1 = new Date()
      // currentDate1.setHours(hours1)
      // currentDate1.setMinutes(minutes1)
      // const formattedDate1 = `${currentDate1.getFullYear()}-${(currentDate1.getMonth() + 1).toString().padStart(2, '0')}-${currentDate1.getDate().toString().padStart(2, '0')} ${currentDate1.getHours().toString().padStart(2, '0')}:${currentDate1.getMinutes().toString().padStart(2, '0')}:${currentDate1.getSeconds().toString().padStart(2, '0')}.${currentDate1.getMilliseconds().toString().padStart(6, '0')}+07`
      // const [hours2, minutes2] = this.closingTime.split(':').map(Number)
      // const currentDate2 = new Date()
      // currentDate2.setHours(hours2)
      // currentDate2.setMinutes(minutes2)
      // const formattedDate2 = `${currentDate2.getFullYear()}-${(currentDate2.getMonth() + 2).toString().padStart(2, '0')}-${currentDate2.getDate().toString().padStart(2, '0')} ${currentDate2.getHours().toString().padStart(2, '0')}:${currentDate2.getMinutes().toString().padStart(2, '0')}:${currentDate2.getSeconds().toString().padStart(2, '0')}.${currentDate2.getMilliseconds().toString().padStart(6, '0')}+07`
      // const [hours3, minutes3] = this.emailTime.split(':').map(Number)
      // const currentDate3 = new Date()
      // currentDate3.setHours(hours3)
      // currentDate3.setMinutes(minutes3)
      // const formattedDate3 = `${currentDate3.getFullYear()}-${(currentDate3.getMonth() + 3).toString().padStart(2, '0')}-${currentDate3.getDate().toString().padStart(2, '0')} ${currentDate3.getHours().toString().padStart(2, '0')}:${currentDate3.getMinutes().toString().padStart(2, '0')}:${currentDate3.getSeconds().toString().padStart(2, '0')}.${currentDate3.getMilliseconds().toString().padStart(6, '0')}+07`
      dataList = {
        no: this.setting[0].no,
        name: this.setting[0].name,
        name_eng: this.setting[0].name_eng,
        contract_name: this.setting[0].contract_name,
        juristic_no: this.setting[0].juristic_no,
        tel: this.setting[0].tel,
        user_amount: this.setting[0].user_amount,
        contract_start: this.setting[0].contract_start,
        contract_end: this.setting[0].contract_end,
        lattitude: parseFloat(this.receiveValueLat) || parseFloat(this.setting[0].lattitude),
        longtitude: parseFloat(this.receiveValueLng) || parseFloat(this.setting[0].longtitude),
        province_id: this.setting[0].province_id,
        district_id: this.setting[0].district_id,
        sd_id: this.setting[0].sd_id,
        license_no: this.setting[0].license_no,
        amount: this.setting[0].amount,
        address: this.setting[0].address,
        start_time: this.openingTime || this.setting[0].start_time,
        end_time: this.closingTime || this.setting[0].end_time,
        social: this.setting[0].social,
        pay_off: this.setting[0].pay_off ? parseFloat(this.setting[0].pay_off.label) || parseFloat(this.setting[0].pay_off) : null,
        social_security: parseFloat(this.setting[0].social_security),
        social_security_date: this.setting[0].social_security_date ? parseFloat(this.setting[0].social_security_date.label) || parseFloat(this.setting[0].social_security_date) : null,
        confirm_email: this.emailTime || this.setting[0].confirm_email,
        fix_pay: this.setting[0].fix_pay
      }
      // console.log(dataList)
      return dataList
    },
    async editSetting () {
      let editsettingAPI = {
        method: 'put',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: this.convertToJson()
      }
      await axios.request(editsettingAPI)
        .then(async (response) => {
          // this.setting = response.data.data
          if (this.clinicImage.file) {
            await this.uploadImageAddManage(this.cln_id)
          }
          this.getSetting()
        })/* .catch((error) => {
          console.error(error)
        }) */
    },
    async uploadImageAddManage (ID) {
      let data = new FormData()
      data.append('file', this.clinicImage.file)
      let uploadImgProfileAPI = {
        method: 'post',
        url: `${configAPI.MA_PORT}/api/clinic/${ID}/upload/img`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      await axios.request(uploadImgProfileAPI)
        .then((response) => {})
        .catch((error) => {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาดในการอัปโหลดรูปภาพ</span>',
            html: '<span>' + error + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async checkError () {
      let errorList = []
      // const regex = /[ก-๙]/
      // const regexNumber = /^[0-9]+$/
      // const regex1 = /[A-Za-z]/
      if (this.setting[0].tel?.trim() === null || this.setting[0].tel?.trim() === '' || this.setting[0].tel?.trim() === undefined || this.setting[0].tel?.length === 0) {
        errorList.push('กรุณากรอกเบอร์โทรศัพท์ติดต่อ')
      }
      if (this.setting[0].tel?.length < 12 && this.setting[0].tel?.length !== 0 && (this.setting[0].tel?.trim() !== null || this.setting[0].tel?.trim() !== '' || this.setting[0].tel?.trim() !== undefined)) {
        errorList.push('กรุณากรอกเบอร์โทรศัพท์ติดต่อให้ครบ 10 หลัก')
      }
      if (this.setting[0].address?.trim() === null || this.setting[0].address?.trim() === '' || this.setting[0].address?.trim() === undefined || this.setting[0].address?.length === 0) {
        errorList.push('กรุณากรอกที่อยู่คลินิก')
      }
      return errorList
    },
    getFullImagePath (relativePath) {
      if (relativePath) {
        relativePath = relativePath.substring(1)
      }
      const baseURL = `${configAPI.MA_PORT}`
      return baseURL + relativePath
    },
    formatTime (time) {
      return moment(time).format('HH:mm')
    },
    onClickDatePicker (num) {
      let datePicker = null
      if (num === 1) {
        datePicker = document.getElementById('datepicker-custom1')
      } else if (num === 2) {
        datePicker = document.getElementById('datepicker-custom2')
      } else {
        datePicker = document.getElementById('datepicker-custom3')
      }
      datePicker.focus()
    },
    handleImageUpload (event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.imageUrl = reader.result
      }
    },
    handleImageUpload2 (event) {
      const file2 = event.target.files[0]
      const reader2 = new FileReader()
      reader2.readAsDataURL(file2)
      reader2.onload = () => {
        this.imageUrl2 = reader2.result
      }
    },
    async handleClick (status) {
      let errorList = await this.checkError()
      if (status) {
        Vue.swal.fire({
          title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะบันทึกข้อมูลใช่หรือไม่?</span>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            if (errorList.length > 0) {
              Vue.swal.fire({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">กรุณากรอกข้อมูลให้ครบถ้วน</span>',
                html: '<span>' + errorList.join('<br>') + '</span>',
                icon: 'warning',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true,
                confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
                confirmButtonColor: '#089bab',
                customClass: {
                  confirmButton: 'custom-confirm-button-class mx-1'
                }
              })
              return
            }
            // let value = this.Dclose
            // let value2 = this.Dpay
            // if (value > 31) {
            //   Vue.swal({
            //     title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลไม่สำเร็จ</span>',
            //     text: 'กรุณากรอกวันที่ปิดยอดเงินเดือนให้ถูกต้อง',
            //     icon: 'warning',
            //     showConfirmButton: true,
            //     confirmButtonText: '<b-button style="font-size: 14px; text-align: center">Ok</b-button>',
            //     confirmButtonColor: '#089bab',
            //     customClass: {
            //       confirmButton: 'custom-confirm-button-class mx-1'
            //     }
            //   })
            //   return
            // }
            // if (value2 > 31) {
            //   Vue.swal({
            //     title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลไม่สำเร็จ</span>',
            //     text: 'กรุณากรอกวันที่จ่ายประกันสังคมให้ถูกต้อง',
            //     icon: 'warning',
            //     showConfirmButton: true,
            //     confirmButtonText: '<b-button style="font-size: 14px; text-align: center">Ok</b-button>',
            //     confirmButtonColor: '#089bab',
            //     customClass: {
            //       confirmButton: 'custom-confirm-button-class mx-1'
            //     }
            //   })
            // } else {
            this.editSetting()
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(() => { /* window.location.reload() */ })
            // }
          }
        })
      } else {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        })
      }
    },
    onPagination (page) {
      this.currentPage = page
    },
    openModal () {
      this.showModal = true
    },
    cancelInput () {
      this.showPopup = false
      window.location.reload()
    },
    isNumberCheck (keyPressed, id) {
      // let indexData = this.rows1.findIndex((item) => { return item.id === id })
      let useData = keyPressed.target.value

      let keyCode = (keyPressed.keyCode ? keyPressed.keyCode : keyPressed.which)

      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || useData.indexOf('.') !== -1)) {
        keyPressed.preventDefault()
      }

      if (useData !== null && useData.indexOf('.') > -1 && (useData.split('.')[1].length > 1)) {
        keyPressed.preventDefault()
      }

      if ((useData === null || useData === '') && keyCode === 46) {
        keyPressed.preventDefault()
      }
    },
    formatContact (e) {
      // let useData = e.target.value

      let keyCode = (e.keyCode ? e.keyCode : e.which)
      if (this.supData.con_contact.length >= 12 || (keyCode < 48 || keyCode > 57)) {
        e.preventDefault()
        this.supData.con_contact = this.supData.con_contact.slice(0, 12)
      } else {
        /* this.supData.con_contact += e.key *//* .toString().replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') */
        this.supData.con_contact = this.supData.con_contact.toString().replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3')
      }
    },
    limitDate () {
      let value = this.Dclose
      // Remove any non-digit characters
      value = value.replace(/[^0-9]/g, '')
      // Restrict the length of the value to 2
      if (value.length > 2) {
        value = value.slice(0, 2)
      }
      // Restrict the first decimal to 1, 2, or 3
      if (value.length > 0) {
        const firstDecimal = parseInt(value.charAt(0))
        if (![1, 2, 3].includes(firstDecimal)) {
          value = value.slice(0, 1)
        }
      }
      // Restrict the second decimal based on the value of the first decimal
      if (value.length > 1) {
        const firstDecimal = parseInt(value.charAt(0))
        const secondDecimal = parseInt(value.charAt(1))
        if (firstDecimal === 3 && ![0, 1].includes(secondDecimal)) {
          value = value.slice(0, 1) + value.slice(1, 2)
        }
      }
      this.Dclose = value
    },
    limitDate2 () {
      let value = this.Dpay
      // Remove any non-digit characters
      value = value.replace(/[^0-9]/g, '')
      // Restrict the length of the value to 2
      if (value.length > 2) {
        value = value.slice(0, 2)
      }
      // Restrict the first decimal to 1, 2, or 3
      if (value.length > 0) {
        const firstDecimal = parseInt(value.charAt(0))
        if (![1, 2, 3].includes(firstDecimal)) {
          value = value.slice(0, 1)
        }
      }
      // Restrict the second decimal based on the value of the first decimal
      if (value.length > 1) {
        const firstDecimal = parseInt(value.charAt(0))
        const secondDecimal = parseInt(value.charAt(1))
        if (firstDecimal === 3 && ![0, 1].includes(secondDecimal)) {
          value = value.slice(0, 1) + value.slice(1, 2)
        }
      }
      this.Dpay = value
    },
    checkperCom () {
      const regex = /^\d+(\.\d{0,2})?%?$/
      let value = this.inputValue

      if (!regex.test(value) || parseFloat(value) > 5.00) {
        value = value.slice(0, -1)
      }
      this.inputValue = value
    },
    showLocation () {
      if (!this.$store.state.LatLngData.lat) {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลไม่สำเร็จ</span>',
          text: 'กรุณาเลือกตำแหน่งก่อนกดบันทึก',
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1'
          }
        })
      } else {
        // this.receiveValue = 'Latitude: ' + this.$store.state.LatLngData.lat + ', Longitude: ' + this.$store.state.LatLngData.lng
        this.receiveValueLat = this.$store.state.LatLngData.lat
        this.receiveValueLng = this.$store.state.LatLngData.lng
        this.showModal = false
      }
    },
    getInputLatLng () {
      let temp = {
        receiveValueLat: this.receiveValueLat ? this.receiveValueLat : NaN,
        receiveValueLng: this.receiveValueLng ? this.receiveValueLng : NaN
      }
      // console.log('temp :>> ', temp)
      this.$store.dispatch('updategetLatLngData', temp)

      this.showModal = true
    }
  }
}
</script>

<style scoped>
.textGoogleMap {
  visibility: visible !important;
  display: block;
}
.GapBr {
  visibility: visible !important;
  display: block
  }
@media (max-width: 1000px) {
  .textGoogleMap {
    visibility: hidden !important;
    display: none;
  }
}
@media (max-width: 991px) {
  #map {
    height: 400px !important;
  }
}
@media (max-width: 575px) {
  .textGoogleMap {
  visibility: visible !important;
  display: block
  }
  .GapBr {
  visibility: hidden !important;
  display: none
  }
  .beforeMapButton {
    margin-bottom: 0px !important;
  }
}
@media (max-width: 560px) {
  .Head {
    margin-left: 15px;
  }
}
@media (max-width: 310px) {
  .profilePic {
    margin-left: 10px !important;
  }
}
</style>
