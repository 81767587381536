<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span class="" style="font-size: 25px; color: black;">ข้อมูลการทำงาน</span>
              </div>
              <div class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="button" @click="saveOption" variant="primary" class="w-100 btn-min-size"><i class="save-data_ct" />บันทึก</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
            <!-- <b-row>
              <b-col lg="2" md="3" sm="12">
                <div class="d-flex justify-content-end mb-3">
                  <b-button type="button" @click="saveOption" variant="primary" class="w-100 text-center btn-min-size">บันทึก</b-button>
                </div>
              </b-col>
            </b-row> -->
            <br>
            <b-row class="res-row-date-attendance">
              <!-- <b-col lg="2" sm="2" style="display: flex; justify-content: center; align-items: center;">
             </b-col> -->
             <b-col lg="5" md="6" sm="12" class="format-picker-group-attendance jus-res-attendance" style="">
               <!-- <b-form-input type="time" placeholder=""></b-form-input> -->
               <!-- <b-time v-model="timeInput" :formatter="formatTime"></b-time> -->
                <b-text style="color: black; white-space: nowrap">เวลาเริ่มงาน(ชั่วโมง:นาที)</b-text>
                <div style="position: relative;" class="w-res-attendance">
                  <date-picker placeholder="HH:mm" id="datepicker-custom" name="date" v-model="timeWorkLate.startWork" :config="options"></date-picker>
                  <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker" style="cursor: pointer;"></i>
                </div>
               <!-- <b-form-timepicker class="form-input format-picker-attendance w-res-attendance" v-model="timeWorkLate.startWork" placeholder="" locale="th"></b-form-timepicker> -->
             </b-col>
            <!--  <b-col lg="2" sm="2" style="display: flex; justify-content: center; align-items: center;">
             </b-col> -->
             <b-col lg="5" md="6" sm="12" class="format-picker-group-attendance jus-res-attendance" style="">
              <b-text style="color: black; white-space: nowrap; padding-right: .5rem;" class="pad-res-attendance">เวลามาสาย(นาที)</b-text>
               <b-form-input type="number" v-model="timeWorkLate.lateWork" placeholder="" class="w-res-attendance" ></b-form-input>
             </b-col>
            </b-row>
            <br>
            <b-row>
              <b-col lg="2" md="6" sm="12" style="min-width: 13rem;">
                <b-button type="submit" @click="handleClick" variant="primary" style="white-space: nowrap;" class="w-100 text-center btn-min-size"><i class="clock_ct" />เพิ่มช่วงเวลาการทำงาน</b-button>
             </b-col>
            </b-row>
            <br>
            <b-row class="direction-col-res-attendance">
              <b-col cols="6" lg="4" md="4" sm="6" class="jus-attendance" style="display: flex; align-items: center;">
                <div class="path-color-attendance att-green">
                </div>
                <p class="nowarp-attendance">Normal</p>
              </b-col>
              <!-- <b-col cols="6" lg="2" md="4" sm="6" class="jus-attendance" style="display: flex; align-items: center;">
                <div class="path-color-attendance att-brown">
                </div>
                <p class="nowarp-attendance">Late</p>
              </b-col>
              <b-col cols="6" lg="2" md="4" sm="6" class="jus-attendance" style="display: flex; align-items: center;">
                <div class="path-color-attendance att-pink">
                </div>
                <p class="nowarp-attendance">Lunch break</p>
              </b-col> -->
              <b-col cols="6" lg="4" md="4" sm="6" class="jus-attendance" style="display: flex; align-items: center;">
                <div class="path-color-attendance att-yellow">
                </div>
                <p class="nowarp-attendance">OT</p>
              </b-col>
              <b-col cols="6" lg="4" md="4" sm="6" class="jus-attendance" style="display: flex; align-items: center;">
                <div class="path-color-attendance att-orange">
                </div>
                <p class="nowarp-attendance">Extra OT</p>
              </b-col>
              <!-- <b-col cols="6" lg="2" md="4" sm="6" class="jus-attendance" style="display: flex; align-items: center;">
                <div class="path-color-attendance att-blue">
                </div>
                <p class="nowarp-attendance">Other</p>
              </b-col> -->
            </b-row>
            <br>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table bordered hover :items="rows" :fields="columns" :current-page="currentPage"
                  :per-page="perPage" @pagination="onPagination"
                  thead-class="table-header-color"
                  :tbody-tr-class="rowClass"
                >
                  <template #thead-top="data">
                    <b-tr>
                      <b-th class="delete-border-bot-attandence" colspan="1"><span class="sr-only">Name and ID</span></b-th>
                      <b-th colspan="24" class="table-header-color" style="text-align: center;">{{ getDateCurrent() }}</b-th>
                      <b-th class="delete-border-bot-attandence" colspan="1"></b-th>
                    </b-tr>
                  </template>
                  <template v-slot:cell(variable1)="data">
                    <span :hidden="true" class="unselectable">{{ data.index + 1 }}</span>
                    <span v-if="!data.item.editable">{{ data.item.variable1 }}</span>
                    <input type="text" v-model="data.item.variable1" v-else class="form-control">
                  </template>
                  <template v-slot:cell(variable26)="data">
                    <b-button variant=" iq-bg-success mr-1" size="sm" @click="openModal(data.index)" ><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item, true)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
                <br>
              </b-col>
            </b-row>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows.length"
              :per-page="perPage"
              align="center"
            />
          </div>
          <b-modal v-model="showModal" :title="'ประเภททำงาน ' + headerModal" size="lg" cancel-title="ยกเลิก" >
              <b-col cols="12" class="table-responsive">
                <b-table bordered hover :items="rows2" :fields="columns2"
                  :current-page="currentPage"
                  :per-page="perPage"
                  @pagination="onPagination"
                  :tbody-tr-class="rowClass" thead-class="table-header-color"
                >
                <template v-slot:cell(variable1)="data">
                  <span v-if="!data.item.editable">{{ data.index + 1 }}</span>
                  <input type="text" v-model="data.item.variable1" v-else class="form-control">
                </template>
                <template v-slot:cell(variable2)="data">
                  <span v-if="!data.item.editable">{{ data.item.variable2 }}</span>
                  <input type="text" v-model="data.item.variable2" v-else class="form-control">
                </template>
                <template v-slot:cell(variable3)="data">
                  <span v-if="!data.item.editable">{{ data.item.variable3 }}</span>
                  <input type="text" v-model="data.item.variable4" v-else class="form-control">
                </template>
                <template v-slot:cell(variable4)="data">
                  <span v-if="!data.item.editable">{{ data.item.variable4 }}</span>
                  <input type="text" v-model="data.item.variable4" v-else class="form-control">
                </template>
                <template v-slot:cell(variable5)="data">
                  <b-button variant=" iq-bg-info mr-1" size="sm" @click="handleClick('view', data.item)"><i class="ri-eye-fill m-0"></i></b-button>
                  <b-button variant=" iq-bg-success mr-1" size="sm" @click="handleClick('edit', data.item)" ><i class="ri-ball-pen-fill m-0"></i></b-button>
                  <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                </template>
              </b-table>
              <br>
          </b-col>
          <b-pagination
              v-model="currentPage"
              :total-rows="rows2.length"
              :per-page="perPage"
              align="center"
            />
            <template #modal-footer>
              <div class="w-100">
                <p class="float-left"></p>
                <b-button
                  variant="none"
                  size="sm"
                  class="float-right iq-bg-danger btn-cancel-border"
                  style="border-radius: 0.5rem;"
                  @click="showModal=false"
                >
                    ยกเลิก
                </b-button>
              </div>
            </template>
          </b-modal>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.jus-res-attendance {
  justify-content: start;
}
.vue__time-picker {
    display: inline-block;
    position: relative;
    font-size: 1em;
    width: 50% !important;
    font-family: sans-serif;
    vertical-align: middle;
}

.vue__time-picker input.display-time {
    color: #9b9797;
    border: 1px solid #d2d2d2 !important;
    width: 100% !important;
    height: 45px !important;
    padding: .3em .5em !important;
    font-size: 1em !important;
    border-radius: 10px !important;
}
.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active, .vue__time-picker
.dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
    background: #0cafc2 !important;
    color: #fff !important;
}
.w-res-attendance {
  width: 65% !important;
}
.format-picker-group-attendance {
  display: flex;
  flex-wrap: nowrap;
  justify-content: start !important;
  align-items: center;
  gap: 1rem;
}
.format-picker-attendance {
    display: flex !important;
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: center !important;
    padding: 0 !important;
    height: 3rem !important;
    background-image: none !important;
}
.att-green {
  background-color: rgb(66, 187, 117);
}
.att-brown {
  background-color: rgb(186 90 90);
}
.att-pink {
  background-color: rgb(203, 95, 200);
}
.att-yellow {
  background-color: rgb(252, 239, 90);
}
.att-orange {
  background-color: rgb(255, 146, 38);
}
.att-blue {
  background-color: rgb(63, 107, 252);
}
.nowarp-attendance {
  white-space: nowrap;
  margin: 0 !important;
}
.path-color-attendance {
  width: 40px;
  height: 20px;
  border-radius: 50px;
  /* background-color: rgb(251, 84, 84); */
  margin-right: .5rem;
}
.border-top-attendence {
  border-width: 3px 0px 0px 0px !important;
  border-style: solid !important;
  border-color: #024187 !important;
  position: relative !important;
}
.format-text-attendence {
  position: absolute !important;
  top: -13px !important;
  left: 40px !important;
}
.format-text2-attendence {
  position: absolute !important;
  top: -13px !important;
  left: 75px !important;
}
.delete-border-bot-attandence {
  border-width: 0px 0px 0px 0px !important;
  border-style: solid !important;
  border-color: #024187 !important;
  /* border: 3px 0 3px 3px solid #479CAC !important; */
}
.col-size-teble-attendance {
  min-width: 60px !important;
  max-width: 60px !important;
}
@media (max-width: 768px) {
  .vue__time-picker {
    display: inline-block;
    position: relative;
    font-size: 1em;
    width: 100% !important;
    font-family: sans-serif;
    vertical-align: middle;
  }
  .pad-res-attendance {
    margin-right: 2.1rem !important;
  }
  .w-res-attendance {
    width: 100% !important;
  }
  .res-row-date-attendance {
    gap: 1rem;
  }
  .jus-attendance {
    display: flex;
    align-items: center;
    /* justify-content: center !important; */
  }
}
@media (max-width: 440px) {
  .format-picker-group-attendance {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: start;
    justify-content: start !important;
    gap: .5rem;
  }
  .direction-col-res-attendance {
    flex-direction: column !important;
  }
}
</style>

<script>
import moment from 'moment'
import { xray } from '../../config/pluginInit'
import router from '@/router'
import axios from 'axios'
import Vue from 'vue'
import 'moment/locale/th' // นำเข้า locale ของภาษาไทย
import { configAPI } from '../../configBase'
moment.locale('th')

const normal = [
  {
    id: 1,
    type: 'ปกติ',
    value: 'normal',
    start: '8.30',
    end: '17.30'
  },
  {
    id: 2,
    type: 'ปกติ',
    value: 'OT',
    start: '17.30',
    end: '18.30'
  },
  {
    id: 3,
    type: 'WFH',
    value: 'normal',
    start: '9.30',
    end: '18.30'
  },
  {
    id: 4,
    type: 'WFH',
    value: 'OT',
    start: '19.30',
    end: '23.00'
  }
]
document.getElementById(normal)
export default {
  name: 'UiDataTable',
  components: {
  },
  async mounted () {
    this.cln_id = JSON.parse(sessionStorage.getItem('user'))?.cln_id
    await this.getTimeWorking()
    await this.getPeriodTime()
    await this.loadTableTime()
    await this.setHeaderTableFormat()
    /* this.timeWorkLate.lateWork = 180
    this.timeWorkLate.startWork = '08:30' */
    /* element.style.borderTop = 'none'
    element.style.position = 'relative' */
    /* const element = document.querySelector('tr[role="row"][aria-rowindex="1"] td[aria-colindex="3"][role="cell"]')
    element.style.backgroundColor = 'pink'
    element.style.width = '1000px'
    element.style.maxWidth = '1000px'
    element.style.display = 'table-caption' */
    xray.index()
  },
  methods: {
    async loadTableTime () {
      if (this.rows.length > 0) {
        const temp = this.rows.map((el, index) => {
          const arr = this.subRows.filter(el2 => el2.type === el.id)
          let obj = null
          if (arr.length > 0) {
            obj = {
              normal: arr,
              maintype: el.variable1,
              index: index
            }
          } else {
            obj = {
              normal: [],
              maintype: el.variable1,
              index: index
            }
          }
          return obj
        })
        // console.log('temp :>> ', temp)
        if (temp) {
          this.dataForSave = temp
          for (let index = 0; index < temp.length; index++) {
            const element = temp[index]
            await this.addTimeline(element.index + 1, element.normal)
          }
        }
      }
    },
    onClickDatePicker () {
      const datePicker = document.getElementById('datepicker-custom')
      datePicker.focus()
    },
    saveOption () {
      // console.log('-->', this.timeWorkLate.startWork)
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะบันทึกข้อมูลการทำงานใช่หรือไม่?</span>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.saveTimeWorking()
        }
      })
    },
    async getTimeWorking () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/WorkingTime`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            const data = response.data.data[0]
            this.timeWorkLate = {
              lateWork: data.late_time,
              startWork: data.start_work ? moment(data.start_work, 'HH:mm:ss.SSSZ').format('HH:mm') : null
            }
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async getPeriodTime () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/periodTime`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            let arrList = []
            let subArrList = []
            for (let index = 0; index < response.data.data.length; index++) {
              const el = response.data.data[index]
              if (!el.parent_period_time_id) {
                arrList.push({
                  variable1: el.period_time_name,
                  id: el.id
                })
              } else {
                subArrList.push({
                  id: el.id,
                  variable1: el.period_time_name,
                  type: el.parent_period_time_id,
                  value: el.mtw_name,
                  start: el.begin_time,
                  end: el.finish_time
                })
              }
            }
            this.rows = arrList?.map((el, idx) => {
              return {
                ...el,
                index: (idx + 1)
              }
            })
            this.subRows = subArrList
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async saveTimeWorking () {
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/workingTime`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          start_work: this.timeWorkLate.startWork || null,
          late_time: this.timeWorkLate.lateWork || null
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              // router.go(-1)
            })
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'บันทึกข้อมูลไม่สำเร็จ')
        })
    },
    openModal (idx) {
      this.rows2 = []
      // console.log('data :>> ', data)
      const datTable = this.dataForSave[idx].normal
      this.headerModal = this.dataForSave[idx].maintype
      for (let i = 0; i < datTable.length; i++) {
        const temp = {
          index: i + 1,
          id: datTable[i].id,
          variable2: datTable[i].value,
          variable3: datTable[i].start,
          variable4: datTable[i].end
        }
        this.rows2.push(temp)
      }
      this.showModal = true
    },
    async setHeaderTableFormat () {
      const element = document.querySelector(`th[role="columnheader"][aria-colindex="1"]`)
      const element2 = document.querySelector(`th[role="columnheader"][aria-colindex="26"]`)
      element.classList.add('border-top-attendence')
      element2.classList.add('border-top-attendence')
      let divElement = element.querySelector('div')
      let divElement2 = element2.querySelector('div')
      divElement.classList.add('format-text-attendence')
      divElement2.classList.add('format-text2-attendence')
    },
    getDateCurrent () {
      return moment(new Date()).format('dddd, DD MMMM YYYY')
    },
    async addTimeline (rowIdx, arrData) {
      // console.log('arrData :>> ', arrData)
      let lineTop = 5
      let lineRow = 3
      for (let i = 0; i < arrData.length; i++) {
        let colIdx = arrData[i].start.split(':') /* ['23', '30'] */
        let endTime = arrData[i].end.split(':') /* ['03', '30'] */
        const startMinute = (parseInt(colIdx[1]))
        const startWidth = (parseInt(colIdx[0]))

        const endMinute = (parseInt(endTime[1]))
        let endWidth = (parseInt(endTime[0]))
        // console.log('endTime', endTime);
        // console.log('parseInt(colIdx[0]) ', parseInt(colIdx[0]))
        colIdx = (startWidth + 2)
        if (startWidth > endWidth) {
          this.cutTimeToDisplay({
            value: arrData[i].value,
            lineTop,
            startMinute,
            startWidth,
            endWidth,
            rowIdx,
            colIdx,
            lineRow,
            i,
            endMinute,
            start: arrData[i].start,
            end: arrData[i].end
          })
        } else {
          if (endMinute !== startMinute) {
            endWidth = ((endWidth + (endMinute / 60)))
          }

          const element = document.querySelector(`tr[role="row"][aria-rowindex="${rowIdx}"] td[aria-colindex="${colIdx}"][role="cell"]`)
          element.style.position = 'relative'
          if (i > 0) {
            element.style.paddingBottom = `${lineRow}em`
            lineRow += 2
          }
          // console.log('endWidth - startWidth', endWidth - startWidth);
          // console.log('star', endWidth, startWidth)
          // console.log('startMinute', startMinute)
          var divElement = document.createElement('div')
          divElement.classList.add('tooltip-del')
          divElement.setAttribute('id', 'my-tooltip-attd')
          divElement.style.position = 'absolute'
          divElement.style.backgroundColor = this.getColorBG(arrData[i].value)
          // divElement.style.color = 'white'
          divElement.style.borderRadius = '50px'
          divElement.style.height = '20px'
          divElement.style.width = '100%' // `${(endWidth - startWidth) * 60}px`
          divElement.style.maxWidth = `${(endWidth - startWidth) * 60}px`
          divElement.style.minWidth = `${(endWidth - startWidth) * 60}px`
          // divElement.style.left = `${startMinute}px !important`
          divElement.style.setProperty('top', lineTop + 'px', 'important')
          divElement.style.setProperty('left', startMinute + 'px', 'important')
          divElement.style.display = 'table-caption'
          // console.log('arrData[i].value', arrData[i].value)
          // divElement.textContent = arrData[i].value
          divElement.style.textAlign = 'start'
          divElement.style.paddingLeft = '1rem'

          // divElement.style.overflow = 'hidden'
          divElement.style.whiteSpace = 'nowrap'

          element.appendChild(divElement)
          this.AddToolTipEl(divElement, arrData[i].value, arrData[i].start, arrData[i].end)
        }

        lineTop += 25
      }
    },
    AddToolTipEl (element, text, timeStart, timeEnd) {
      // Create the tooltip element
      var tooltipElement = document.createElement('div')
      tooltipElement.classList.add('tooltip2')
      tooltipElement.textContent = 'Hello'
      tooltipElement.style.display = 'none'
      tooltipElement.style.top = '-30px'
      tooltipElement.style.left = '0px'
      tooltipElement.style.background = 'white'
      tooltipElement.style.position = 'absolute'
      tooltipElement.style.height = '20px'
      // tooltipElement.style.width = '100px'
      tooltipElement.style.borderRadius = '50px'
      tooltipElement.style.border = '1px solid #999999'
      tooltipElement.textContent = `${text}, ${timeStart} - ${timeEnd}`
      tooltipElement.style.paddingLeft = '1rem'
      tooltipElement.style.paddingRight = '1rem'
      tooltipElement.style.paddingBottom = '1.5rem'

      /*
        display: block;
        background: blue;
        position: fixed;
        padding: 1rem !important;
        width: 50px !important;
        z-index: 1000000000 !important;
        color: red !important;
        height: 100px !important;
      */

      // Append the tooltip element as a child of the div element
      element.appendChild(tooltipElement)

      element.addEventListener('mouseenter', function () {
        tooltipElement.style.display = 'block'
      })

      element.addEventListener('mouseleave', function () {
        tooltipElement.style.display = 'none'
      })
    },
    async addTimeline2 (rowIdx, arrData) {
      // console.log('arrData :>> ', arrData)
      let lineTop = 5
      let lineRow = 3
      for (let i = 0; i < arrData.length; i++) {
        let colIdx = arrData[i].start.split(':')
        let endWidth = arrData[i].end.split(':')
        const startMinute = (parseInt(colIdx[1]))
        const startWidth = (parseInt(colIdx[0]))
        // console.log('parseInt(colIdx[0]) ', parseInt(colIdx[0]))
        colIdx = (parseInt(colIdx[0]) + 2)
        if (parseInt(endWidth[1]) === startMinute) {
          endWidth = (parseInt(endWidth[0]))
        } else {
          endWidth = ((parseInt(endWidth[0]) + (parseInt(endWidth[1]) / 60)))
        }
        const element = document.querySelector(`tr[role="row"][aria-rowindex="${rowIdx}"] td[aria-colindex="${colIdx}"][role="cell"]`)
        element.style.position = 'relative'
        if (i > 0) {
          // console.log('in :>> ')
          element.style.paddingBottom = `${lineRow}em`
          lineRow += 2
        }
        // console.log('star', endWidth, startWidth)
        // console.log('startMinute', startMinute)
        var divElement = document.createElement('div')
        divElement.setAttribute('id', 'my-tooltip-attd')
        divElement.style.position = 'absolute'
        divElement.style.backgroundColor = this.getColorBG(arrData[i].value)
        // divElement.style.color = 'white'
        divElement.style.borderRadius = '50px'
        divElement.style.height = '20px'
        divElement.style.width = `${(endWidth - startWidth) * 60}px`
        divElement.style.maxWidth = `${(endWidth - startWidth) * 60}px`
        // divElement.style.left = `${startMinute}px !important`
        divElement.style.setProperty('top', lineTop + 'px', 'important')
        divElement.style.setProperty('left', startMinute + 'px', 'important')
        divElement.style.display = 'table-caption'
        divElement.textContent = arrData[i].value
        divElement.style.textAlign = 'start'
        divElement.style.paddingLeft = '1rem'

        divElement.style.overflow = 'hidden'
        divElement.style.whiteSpace = 'nowrap'

        element.appendChild(divElement)
        lineTop += 25
      }
    },
    getColorBG (type) {
      if (type === 'Normal') {
        return 'rgb(66, 187, 117)'
      } else if (type === 'Late') {
        return 'rgb(186, 90, 90)'
      } else if (type === 'Lunch break') {
        return 'rgb(203, 95, 200)'
      } else if (type === 'OT') {
        return 'rgb(252, 239, 90)'
      } else if (type === 'Extra OT') {
        return 'rgb(255, 146, 38)'
      } else if (type === 'Other') {
        return 'rgb(63, 107, 252)'
      }
    },
    add () {
      let obj = this.default()
      this.rows.push(obj)
    },
    default () {
      return {
        id: this.rows.length,
        variable1: '',
        variable2: '',
        variable3: '',
        variable4: '',
        variable5: '2011/04/25',
        variable6: '$0',
        editable: false
      }
    },
    edit (item) {
      item.editable = true
    },
    submit (item) {
      item.editable = false
    },
    handleTimeChange (time, status) {
      if (time && time.includes(':')) {
        const [hours, minutes] = time.split(':')
        if (minutes === 'mm') {
          this.timeWorkLate.startWork = hours + ':00'
        }
      }
    },
    remove (item, status) {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการลบข้อมูลใช่หรือไม่?</span>',
        text: 'ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.deletePeriodTime(item, status)
        }
      })
    },
    async deletePeriodTime (item, status) {
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/periodTime/${item.id}/delete`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          Vue.swal({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
            icon: 'success',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 2000
          }).then(async () => {
            const _div = document.getElementsByClassName('tooltip-del')
            Array.from(_div).forEach(element => {
              element.remove()
            })
            if (status === true) {
              let index = this.rows.indexOf(item)
              this.rows.splice(index, 1)
            } else {
              let index = this.rows2.indexOf(item)
              this.rows2.splice(index, 1)
            }
            await this.getTimeWorking()
            await this.getPeriodTime()
            await this.loadTableTime()
            await this.setHeaderTableFormat()
          })
        })
        .catch((error) => {
          this.SwalError(error.message, 'ลบข้อมูลไม่สำเร็จ')
        })
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick (setPage, data) {
      if (!data) {
        router.push(`/AddAttendance`)
      } else {
        router.push(`/AddAttendance/${setPage}/${data.id}`)
      }
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    cutTimeToDisplay (params) {
      // value, lineTop, startMinute, startWidth, endWidth, rowIdx, colIdx, lineRow, i, endMinute
      const element = document.querySelector(`tr[role="row"][aria-rowindex="${params.rowIdx}"] td[aria-colindex="${params.colIdx}"][role="cell"]`)
      element.style.position = 'relative'
      if (params.i > 0) {
        element.style.paddingBottom = `${params.lineRow}em`
        params.lineRow += 2
      }
      // console.log('(23 - startWidth) - startMinute)', startMinute - (24 - startWidth))
      var divElement = document.createElement('div')
      divElement.setAttribute('id', 'my-tooltip-attd')
      divElement.style.position = 'absolute'
      divElement.style.backgroundColor = this.getColorBG(params.value)
      // divElement.style.color = 'white'
      divElement.style.borderRadius = '50px 0px 0px 50px'
      divElement.style.height = '20px'
      divElement.style.width = `${(24 - (params.startWidth + (params.startMinute / 60))) * 60}px`
      divElement.style.maxWidth = `${(24 - (params.startWidth + (params.startMinute / 60))) * 60}px`
      // divElement.style.left = `${startMinute}px !important`
      divElement.style.setProperty('top', params.lineTop + 'px', 'important')
      divElement.style.setProperty('left', params.startMinute + 'px', 'important')
      divElement.style.display = 'table-caption'
      // divElement.textContent = (params.value + 1)
      divElement.style.textAlign = 'start'
      divElement.style.paddingLeft = '1rem'

      divElement.style.overflow = 'hidden'
      divElement.style.whiteSpace = 'nowrap'

      element.appendChild(divElement)

      const element2 = document.querySelector(`tr[role="row"][aria-rowindex="${params.rowIdx}"] td[aria-colindex="${2}"][role="cell"]`)
      element2.style.position = 'relative'
      if (params.i > 0) {
        element2.style.paddingBottom = `${params.lineRow}em`
        params.lineRow += 2
      }

      var divElement2 = document.createElement('div')
      divElement2.setAttribute('id', 'my-tooltip-attd')
      divElement2.style.position = 'absolute'
      divElement2.style.backgroundColor = this.getColorBG(params.value)
      // divElement2.style.color = 'white'
      divElement2.style.borderRadius = '0px 50px 50px 0px'
      divElement2.style.height = '20px'
      divElement2.style.width = `${((params.endWidth + (params.endMinute / 60))) * 60}px`
      divElement2.style.maxWidth = `${((params.endWidth + (params.endMinute / 60))) * 60}px`
      // divElement2.style.left = `${startMinute}px !important`
      divElement2.style.setProperty('top', params.lineTop + 'px', 'important')
      divElement2.style.setProperty('left', 0 + 'px', 'important')
      divElement2.style.display = 'table-caption'
      // divElement2.textContent = params.value
      divElement2.style.textAlign = 'start'
      divElement2.style.paddingLeft = '1rem'

      divElement2.style.overflow = 'hidden'
      divElement2.style.whiteSpace = 'nowrap'

      element2.appendChild(divElement2)

      this.AddToolTipEl(divElement, params.value, params.start, params.end)
      this.AddToolTipEl(divElement2, params.value, params.start, params.end)
    }
  },
  data () {
    return {
      cln_id: null,
      headerModal: '',
      dataForSave: null,
      timeWorkLate: {
        startWork: '',
        lateWork: ''
      },
      value: '',
      showModal: false,
      options: {
        // https://momentjs.com/docs/#/displaying/
        format: 'HH:mm',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        sideBySide: true,
        icons: {
          time: 'fas fa-clock',
          date: 'fas fa-calendar',
          up: 'fas fa-chevron-up',
          down: 'fas fa-chevron-down',
          previous: 'fas fa-chevron-left',
          next: 'fas fa-chevron-right',
          today: 'fas fa-calendar-day',
          clear: 'fas fa-trash-alt',
          close: 'fas fa-times'
        },
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'bottom'
        }
      },
      columns2: [
        { label: 'ลำดับ', key: 'variable1', class: 'text-center' },
        { label: 'ประเภทการทำงาน', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'เวลาเริ่ม', key: 'variable3', class: 'text-center table-size-100-300' },
        { label: 'เวลาสิ้นสุด', key: 'variable4', class: 'text-center table-size-100-300' },
        { label: 'จัดการ', key: 'variable5', class: 'text-center table-size-200-400' }
      ],
      rows2: [],
      columns: [
        { label: 'ประเภทการทำงาน', key: 'variable1', class: 'text-center table-size-200-400' },
        { label: '00:00', key: 'variable2', class: 'text-center col-size-teble-attendance' },
        { label: '01:00', key: 'variable3', class: 'text-center col-size-teble-attendance' },
        { label: '02:00', key: 'variable4', class: 'text-center col-size-teble-attendance' },
        { label: '03:00', key: 'variable5', class: 'text-center col-size-teble-attendance' },
        { label: '04:00', key: 'variable6', class: 'text-center col-size-teble-attendance' },
        { label: '05:00', key: 'variable7', class: 'text-center col-size-teble-attendance' },
        { label: '06:00', key: 'variable8', class: 'text-center col-size-teble-attendance' },
        { label: '07:00', key: 'variable9', class: 'text-center col-size-teble-attendance' },
        { label: '08:00', key: 'variable10', class: 'text-center col-size-teble-attendance' },
        { label: '09:00', key: 'variable11', class: 'text-center col-size-teble-attendance' },
        { label: '10:00', key: 'variable12', class: 'text-center col-size-teble-attendance' },
        { label: '11:00', key: 'variable13', class: 'text-center col-size-teble-attendance' },
        { label: '12:00', key: 'variable14', class: 'text-center col-size-teble-attendance' },
        { label: '13:00', key: 'variable15', class: 'text-center col-size-teble-attendance' },
        { label: '14:00', key: 'variable16', class: 'text-center col-size-teble-attendance' },
        { label: '15:00', key: 'variable17', class: 'text-center col-size-teble-attendance' },
        { label: '16:00', key: 'variable18', class: 'text-center col-size-teble-attendance' },
        { label: '17:00', key: 'variable19', class: 'text-center col-size-teble-attendance' },
        { label: '18:00', key: 'variable20', class: 'text-center col-size-teble-attendance' },
        { label: '19:00', key: 'variable21', class: 'text-center col-size-teble-attendance' },
        { label: '20:00', key: 'variable22', class: 'text-center col-size-teble-attendance' },
        { label: '21:00', key: 'variable23', class: 'text-center col-size-teble-attendance' },
        { label: '22:00', key: 'variable24', class: 'text-center col-size-teble-attendance' },
        { label: '23:00', key: 'variable25', class: 'text-center col-size-teble-attendance' },
        { label: 'จัดการ', key: 'variable26', class: 'text-center table-size-200-400' }
      ],
      /*
        { label: 'ประเภทการทำงาน', key: 'variable1', class: 'text-center table-size-200-400' },
        { label: '01:00', key: 'variable2', class: 'text-center col-size-teble-attendance' },
        { label: '02:00', key: 'variable3', class: 'text-center col-size-teble-attendance' },
        { label: '03:00', key: 'variable4', class: 'text-center col-size-teble-attendance' },
        { label: '04:00', key: 'variable5', class: 'text-center col-size-teble-attendance' },
        { label: '05:00', key: 'variable6', class: 'text-center col-size-teble-attendance' },
        { label: '06:00', key: 'variable7', class: 'text-center col-size-teble-attendance' },
        { label: '07:00', key: 'variable8', class: 'text-center col-size-teble-attendance' },
        { label: '08:00', key: 'variable9', class: 'text-center col-size-teble-attendance' },
        { label: '09:00', key: 'variable10', class: 'text-center col-size-teble-attendance' },
        { label: '10:00', key: 'variable11', class: 'text-center col-size-teble-attendance' },
        { label: '11:00', key: 'variable12', class: 'text-center col-size-teble-attendance' },
        { label: '12:00', key: 'variable13', class: 'text-center col-size-teble-attendance' },
        { label: '13:00', key: 'variable14', class: 'text-center col-size-teble-attendance' },
        { label: '14:00', key: 'variable15', class: 'text-center col-size-teble-attendance' },
        { label: '15:00', key: 'variable16', class: 'text-center col-size-teble-attendance' },
        { label: '16:00', key: 'variable17', class: 'text-center col-size-teble-attendance' },
        { label: '17:00', key: 'variable18', class: 'text-center col-size-teble-attendance' },
        { label: '18:00', key: 'variable19', class: 'text-center col-size-teble-attendance' },
        { label: '19:00', key: 'variable20', class: 'text-center col-size-teble-attendance' },
        { label: '20:00', key: 'variable21', class: 'text-center col-size-teble-attendance' },
        { label: '21:00', key: 'variable22', class: 'text-center col-size-teble-attendance' },
        { label: '22:00', key: 'variable23', class: 'text-center col-size-teble-attendance' },
        { label: '23:00', key: 'variable24', class: 'text-center col-size-teble-attendance' },
        { label: 'จัดการ', key: 'variable25', class: 'text-center table-size-200-400' }
      */
      subRows: [],
      rows: [
        {
          index: 1,
          variable1: 'ปกติ'
        },
        {
          index: 2,
          variable1: 'WFH'
        }/* ,
        {
          variable1: 'ผลัดกลางคืน'
        },
        {
          variable1: 'ผลัด OT พิเศษ'
        },
        {
          variable1: 'งานวันหยุด'
        },
        {
          variable1: 'ส่วน สนง'
        },
        {
          variable1: 'ปกติ รง'
        } */
      ],
      currentPage: 1,
      perPage: 10
    }
  }
}
</script>
