<template>
  <div class="iq-sidebar">
    <div class="iq-sidebar-logo d-flex justify-content-between my-2">
      <router-link :to="homeURL" class="mx-1">
        <img :src="logo" class="img-fluid" alt="logo">
        <span style="font-size: 22px; white-space: nowrap; justify-content: center;">Smart Clinic</span>
      </router-link>
      <div class="iq-menu-bt-sidebar" style="margin-top: 2px;">
        <div class="iq-menu-bt align-self-center">
          <div class="wrapper-menu" @click="miniSidebar">
            <div class="main-circle"><i class="ri-more-fill"></i></div>
            <div class="hover-circle"><i class="ri-more-2-fill"></i></div>
          </div>
        </div>
      </div>
    </div>
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu" :class="horizontal ? 'd-xl-none' : ''">
        <List :items="items" :open="true" :horizontal="horizontal" />
      </nav>
      <!-- <div class="p-3"></div> -->
    </div>
  </div>
  <!-- TOP Nav Bar -->
</template>

<script>
import List from '../menus/ListStyle1'
export default {
  name: 'SideBarStyle1',
  props: {
    homeURL: { type: Object, default: () => ({ name: 'dashboard.DashboardsCus' }) },
    items: { type: Array },
    logo: { type: String, default: require('../../../assets/images/logo.png') },
    horizontal: { type: Boolean },
    toggleButton: { type: Boolean, default: true }
  },
  components: {
    List
  },
  async updated () {
    // const element1 = document.querySelectorAll('.iq-sidebar-menu li a')
    // Array.from(element1, (elem) => {
    const element = document.querySelectorAll('.iq-sidebar-menu li a')
    Array.from(element, (elem) => {
      elem.addEventListener('click', (e) => {
        const prevMenu = window.$('.iq-sidebar-menu li').has('.iq-submenu').find('ul.show').attr('id')
        const prevMenuElem = document.getElementById(prevMenu)
        if (prevMenuElem !== null) {
          Array.from(prevMenuElem.closest('li').getElementsByClassName('not-collapsed'), (el) => {
            if (elem.closest('ul').getAttribute('id') !== prevMenu) {
              this.$root.$emit('bv::toggle::collapse', prevMenu)
              prevMenuElem.closest('li').classList.remove('active')
              window.$(elem).closest('li').has('.iq-submenu').addClass('active')
            }
            if (elem.getAttribute('aria-controls') === prevMenu) {
              this.$root.$emit('bv::toggle::collapse', prevMenu)
              prevMenuElem.closest('li').classList.remove('active')
            }
          })
        } else {
          window.$(elem).closest('li').has('.iq-submenu').addClass('active')
        }
      })
    })

    // const element2 = document.querySelectorAll('.iq-sidebar-menu')
    // const element3 = document.querySelectorAll('#sidebar-scrollbar')

    // if (element3[0].offsetHeight >= element2[0].offsetHeight) {
    //   element3[0].style.height = `calc(${element2[0].offsetHeight}px - (100vh - ${element2[0].offsetHeight}px + 20px))`
    // } else {
    //   element3[0].style.height = 'max-content'
    // }
  },
  methods: {
    miniSidebar () {
      this.$emit('toggle')
    }
  },
  data () {
    return {
    }
  }
}
</script>
