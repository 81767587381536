import { numberWithCommas } from '../../Utils/Common'
export const dashboardData = {
  custommerCount: {
    new: numberWithCommas(0/* 124 */),
    old: numberWithCommas(0/* 15004 */),
    appointment: numberWithCommas(0/* 95 */),
    reAppointemnt: numberWithCommas(0/* 30 */)
  },
  seller: [
    /* {
      id: 1,
      name: 'ปัญญา ทองเมือง',
      sales: numberWithCommas(1500)
    },
    {
      id: 2,
      name: 'ปูน มาคอส',
      sales: numberWithCommas(3500)
    },
    {
      id: 3,
      name: 'ปัญญา ทองเมือง',
      sales: numberWithCommas(1500)
    },
    {
      id: 4,
      name: 'ปูน มาคอส',
      sales: numberWithCommas(3500)
    },
    {
      id: 5,
      name: 'ปัญญา ทองเมือง',
      sales: numberWithCommas(1500)
    } */
  ],
  mostPurchaseOder: [
    /* {
      id: 1,
      name: 'ธีวินท์ นิ่มกิตติกุล',
      price: numberWithCommas(340000)
    },
    {
      id: 2,
      name: 'ภูบดี วายพา',
      price: numberWithCommas(230500)
    },
    {
      id: 3,
      name: 'ธีวินท์ นิ่มกิตติกุล',
      price: numberWithCommas(24111)
    },
    {
      id: 4,
      name: 'ปูน มาคอส',
      lastname: '',
      price: numberWithCommas(21241)
    },
    {
      id: 5,
      name: 'ปัญญา ทองเมือง',
      price: numberWithCommas(151100)
    } */
  ],
  productAmount: [
    /* {
      id: 1,
      code: '0001',
      name: 'เข็มฉีดยา1',
      brand: 'Botox1',
      onwer: 'Zyfer1'
    },
    {
      id: 2,
      code: '0002',
      name: 'เข็มฉีดยา2',
      brand: 'Botox2',
      onwer: 'Zyfer2'
    },
    {
      id: 3,
      code: '0003',
      name: 'เข็มฉีดยา3',
      brand: 'Botox3',
      onwer: 'Zyfer3'
    } */
  ],
  comment: [
    /* {
      id: 1,
      details: 'คลินิกแอร์จะเย็นไปไหน',
      case: 'Case00453'
    },
    {
      id: 2,
      details: 'น้ำไม่อร่อย',
      case: 'Case00645'
    },
    {
      id: 3,
      details: 'หาคลืนิกไม่เจอ',
      case: 'Case00841'
    },
    {
      id: 4,
      details: 'มีแมลง',
      case: 'Case00944'
    },
    {
      id: 5,
      details: 'รอคิวนาน',
      case: 'Case001444'
    } */
  ]
}
