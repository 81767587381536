<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">ลูกค้าในการดูแล</span>
              </div>
              <!-- <div class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-12 pl-3 pr-2 my-1 my-sm-0">
                    <b-button type="button" variant="none" @click="transferModal = true;" class="w-100"
                      style="color: white; background-color: #8922EFCC;"><i class="ri-arrow-left-right-line"
                        style="margin: 0;" /></b-button>
                  </div>
                </div>
              </div> -->
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" md="7" lg="5">
                  <b-row>
                    <b-col sm="12" md class="mb-3">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input v-model="search" class="form-control" id="exampleInputEmail1" placeholder="Search"
                              style="width: 100%;" @keydown="enterSearch">
                            <a class="search-link" @click="getPatientCustomer"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                      <span class="mx-2 font-size-14 text-primary">
                        {{ historyServiceData.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage *
                          currentPage) > historyServiceData?.length) ? historyServiceData.length : (perPage * currentPage)
                        ) + ' of ' + AllDataLength : '0 of ' + AllDataLength }} รายการ
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              </div>
              <br>
              <b-col md="12" class="table-responsive">
                <b-table bordered hover :items="historyServiceData" :fields="columns"
                  :current-page="currentPage"
                  :per-page="perPage"
                  @pagination="onPagination"
                  :tbody-tr-class="rowClass" thead-class="table-header-color"
                >
                <template v-slot:cell(fullname_cus)="data">
                  <!-- <span>{{ data.item.fullname_cus || '-' }}</span> -->
                  <customerModal :id="data.item.id" :label="data.item.fullname_cus"></customerModal>
                </template>
                <template v-slot:cell(nickname)="data">
                  <span>{{ data.item.nickname || '-' }}</span>
                </template>
                <template v-slot:cell(tel)="data">
                  <a :href="'tel:' + formatTel(data.item.tel)"><span>{{ formatTel(data.item.tel) }}</span></a>
                </template>
                <template v-slot:cell(follow_up)="data">
                  <span style="white-space: pre;">{{ data.item.follow_up || '-' }}</span>
                </template>
                <template v-slot:cell(sale_name)="data">
                  <span style="white-space: pre;">{{ data.item.sale_name || '-' }}</span>
                </template>
                <!-- <template v-slot:cell(manage)="data">
                  <div style="display: flex; justify-content: space-evenly;">
                    <b-button variant=" iq-bg-primary ml-1" size="sm" @click="transferModal = true"><i class="ri-shuffle-line m-0"></i></b-button>
                  </div>
                </template>
                <template v-slot:cell(sale_name)="data">
                  <span>{{ data.item.sale_name }}</span>
                </template> -->
                <template v-slot:cell(manage)="data">
                  <b-button type="button" variant="none" :disabled="data.item.cant_edit" @click="SetTranferCustomer(data.item)" class=""
                      style="color: white; background-color: #8922EFCC;"><i class="ri-shuffle-line"
                        style="margin: 0;" /></b-button>
                </template>
              </b-table>
              <br>
            </b-col>
            <b-pagination
                v-model="currentPage"
                :total-rows="historyServiceData.length"
                :per-page="perPage"
                align="center"
              />
            </template>
            <b-modal v-model="transferModal" title="ย้ายการดูแลลูกค้า" cancel-title="เพิ่ม" ok-title="ยกเลิก"
              cancel-variant="primary" size="lg" ok-variant="danger" @close.prevent="transferModal = false" :no-close-on-esc="true"
              :no-close-on-backdrop="true">
              <b-row>
                <b-col cols="12" md="5">
                  <b-form-group label="ชื่อลูกค้า" label-for="exampleInputText1">
                    <template v-slot:label>
                      ชื่อลูกค้า
                    </template>
                    <b-form-input id="exampleInputText1" v-model="detailsMoveCus.cus_name" type="text" placeholder="" class="hide-arrow" :disabled="true"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="2" style="text-align: center; padding-top: 44px;">
                  <i class="ri-shuffle-line" />
                </b-col>
                <b-col cols="12" md="5">
                  <b-form-group label="ชื่อพนักงาน" label-for="ชื่อพนักงาน">
                    <template v-if="detailsMoveCus.cus_sale === null || detailsMoveCus.cus_sale === ''" v-slot:label>
                      ชื่อพนักงาน <span style="color: red;"> *</span>
                    </template>
                    <v-select v-model="detailsMoveCus.cus_sale" :options="sales" ></v-select>
                  </b-form-group>
                  <!-- <b-form-group label="ชื่อพนักงาน" label-for="exampleInputText1">
                    <template v-slot:label>
                      ชื่อพนักงาน
                    </template>
                    <b-form-input id="exampleInputText1" v-model="detailsMoveCus.cus_sale" type="text" placeholder="" class="hide-arrow" />
                  </b-form-group> -->
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="12">
                  <b-form-group label="วันที่ย้ายการดูแล" label-for="exampleInputText1">
                    <template v-slot:label>
                      วันที่ย้ายการดูแล <span v-if="detailsMoveCus.cus_date === null || detailsMoveCus.cus_date === ''" style="color: red;"> *</span>
                    </template>
                  </b-form-group>
                  <template >
                  <div style="justify-content: center; display: flex;" class="calender-small">
                    <flat-pickr
                        v-model="detailsMoveCus.cus_date"
                        :config="config"
                        class="form-control bg-white"
                        placeholder="Select date"
                        name="date">
                    </flat-pickr>
                  </div>
                </template>
                </b-col>
              </b-row>
              <!-- <b-form-group label="ลาพักร้อน" label-for="exampleInputText1">
                <template v-slot:label>
                  ลาพักร้อน
                  <span
                    :hidden="summaryLeave.entitlement.annual_leave || summaryLeave.entitlement.annual_leave === 0 ? true : false"
                    class="text-danger"> *</span>
                </template>
                <b-form-input id="exampleInputText1" type="number" placeholder="" class="hide-arrow"
                  v-model="summaryLeave.entitlement.annual_leave" @keypress="checktypeNumber($event)" />
              </b-form-group>
              <b-form-group label="ลากิจ" label-for="exampleInputText1">
                <template v-slot:label>
                  ลากิจ
                  <span
                    :hidden="summaryLeave.entitlement.business_leave || summaryLeave.entitlement.business_leave === 0 ? true : false"
                    class="text-danger"> *</span>
                </template>
                <b-form-input id="exampleInputText1" type="number" placeholder="" class="hide-arrow"
                  v-model="summaryLeave.entitlement.business_leave" @keypress="checktypeNumber($event)" />
              </b-form-group>
              <b-form-group label="ลาป่วย" label-for="exampleInputText1">
                <template v-slot:label>
                  ลาป่วย
                  <span :hidden="summaryLeave.entitlement.sick_leave || summaryLeave.entitlement.sick_leave === 0 ? true : false"
                    class="text-danger"> *</span>
                </template>
                <b-form-input id="exampleInputText1" type="number" placeholder="" class="hide-arrow"
                  v-model="summaryLeave.entitlement.sick_leave" @keypress="checktypeNumber($event)" />
              </b-form-group> -->
              <template #modal-footer>
                <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                  <b-button :disabled="!detailsMoveCus.cus_date || !detailsMoveCus.cus_sale" style="border-radius: 0.5rem; padding: 0 .7rem;" variant="primary" class="btn-min-size" size="sm"
                    @click="confirmMoveCus">
                    <i class="save-data_ct" />บันทึก
                  </b-button>
                  <b-button variant="none" class="iq-bg-danger btn-cancel-border btn-min-size" style="border-radius: 0.5rem;"
                    size="sm" @click="transferModal = false">
                    ยกเลิก
                  </b-button>
                </div>
              </template>
            </b-modal>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>

.startRange, .endRange {
  background: #479CAC !important;
}

.patient-cus-icon-flex {
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0;
}

.input-quicksearch-history {
  width: 120px !important;
  height: 30px !important;
  margin-bottom: 1rem !important;
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import Axios from 'axios'
import moment from 'moment'
import { configAPI } from '../../configBase'
import customerModal from './component/customerModal.vue'

export default {
  name: 'HistoryService',
  components: {
    customerModal
  },
  data () {
    return {
      clnId: null,
      userId: null,
      search: '',
      config: {
        mode: 'range',
        dateFormat: 'd-m-Y',
        minDate: new Date()
      },
      mode: 'showAll',
      detailsMoveCus: {
        cus_id: null,
        cus_name: null,
        cus_sale: null,
        cus_date: null
      },
      sales: [],
      columns: [
        { label: 'ชื่อ - นามสกุล (ลูกค้า)', key: 'fullname_cus', class: 'text-center table-size-200-400' },
        { label: 'ชื่อเล่น', key: 'nickname', class: 'text-center table-size-200-400' },
        { label: 'เบอร์โทร', key: 'tel', class: 'text-center table-size-200-400' },
        { label: 'Follow Up', key: 'follow_up', class: 'text-center table-size-200-400', sortable: true, sortDirection: 'desc', sortNullLast: true },
        { label: 'พนักงานที่ดูแล', key: 'sale_name', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'manage', class: 'text-center table-size-200-400' }/* ,
        { label: 'สินค้า / จำนวน', key: 'product', class: 'text-center table-size-200-400' },
        { label: 'แพทย์ที่ทำการรักษา', key: 'doctor', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'management', class: 'text-center table-size-100-300' } */
      ],
      historyServiceData: [],
      currentPage: 1,
      perPage: 10,
      AllDataLength: null,
      transferModal: false
    }
  },
  computed: {
  },
  async mounted () {
    this.clnId = JSON.parse(sessionStorage.getItem('user')).cln_id || null
    this.userId = JSON.parse(sessionStorage.getItem('user')).usr_id || null
    await this.getPatientCustomer()
    await this.getAllEmployee()
    xray.index()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getPatientCustomer()
      }
    },
    async getAllEmployee () {
      let getAllEmployeeAPI = {
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/user/`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await Axios.request(getAllEmployeeAPI)
        .then((response) => {
          if (response.data.status) {
            let arrTemp = []
            const resList = response.data.data.results.sort((a, b) => {
              return (new Date(a.created_date) - new Date(b.created_date))
            })
            for (let item of resList) {
              if (item.is_use && item.id !== this.userId) {
                arrTemp.push({
                  code: item.id,
                  label: item.ut_id === '5c66dfdb-a9bc-4867-baa7-507175d2fc01' ? `SuperAdmin System` : `${item.emp_name} ${item.emp_lastname}`
                })
              }
            }
            this.sales = arrTemp
          }
        })
    },
    async SetTranferCustomer (cusDetails) {
      // await this.getAllEmployee()
      this.detailsMoveCus = {
        cus_id: cusDetails.id,
        cus_name: cusDetails.fullname_cus,
        cus_sale: null,
        cus_date: null
      }
      this.transferModal = true
    },
    async confirmMoveCus () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะย้ายข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Axios.request({
            method: 'POST',
            url: `${configAPI.MD_PORT}/api/user/${this.userId}/tranfer/patientInCare`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: this.detailsMoveCus
          }).then(async (response) => {
            if (response.data.status) {
              Vue.swal({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
                text: '',
                icon: 'success',
                timer: 2000,
                allowEscapeKey: false,
                allowOutsideClick: false,
                // confirmButtonText: '<b-button style="font-size: 14px; text-align: center">OK</b-button>', // Adjust the confirm button font size
                customClass: {
                  confirmButton: 'custom-confirm-button-class'
                },
                confirmButtonColor: '#099FB0'
              })
              await this.getPatientCustomer()
            }
            this.transferModal = false
          }).catch((error) => {
            this.SwalError(error.message, 'เกิดข้อผิดพลาดในการย้ายข้อมูล')
          })
        }
      })
    },
    handleClickModel () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะยกเลิกใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.transferModal = false
        }
      })
    },
    async getPatientCustomer () {
      let searchData = {
        search: this.search.trim() || ''
      }
      await Axios.request({
        method: 'POST',
        url: `${configAPI.MD_PORT}/api/clinic/${this.clnId}/user/${this.userId}/patientInCare`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: searchData
      }).then((response) => {
        const arr = []
        for (let el of response.data.data) {
          let arrTemp = el.sale.map(tl => `${tl.sale_name}`)
          arrTemp.unshift(el.sale_name)
          arr.push({
            id: el.cus_id,
            fullname_cus: `${el.cus_firstname} ${el.cus_lastname}`,
            nickname: el.cus_nickname || '',
            sale_name: arrTemp.join('\n'),
            tel: el.cus_tel || '',
            follow_up: el.follow_case.map(tl => `${tl.st_name} ${tl.fcr_actual_date ? moment(tl.fcr_actual_date).add(543, 'y').format('DD/MM/YY') : tl.fcr_actual_date || ''}`).join('\n'),
            cant_edit: el.cant_edit || false
          })
        }
        this.historyServiceData = arr
        this.AllDataLength = response.data.count || 0
      }).catch((error) => {
        this.SwalError(error.message, 'เกิดข้อผิดพลาดในดึงข้อมูล')
      })
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    remove (item) {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะลบข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Axios.request({
            method: 'PUT',
            url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/historyServiceCustomer/${item.id}/delete`,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              timer: 1500
            }).then(async () => {
              await this.getAllHistoryService()
              await this.getHistoryService()
            })
          }).catch((err) => {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลไม่สำเร็จ</span>',
              icon: 'error',
              html: `<span class="" style="font-size: 18px; color: black; text-align: center">${err}</span>`,
              showCancelButton: false,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              timer: 1500
            }).then(async () => {
              await this.getHistoryService()
            })
          })
        }
      })
    },
    onClickDatePicker () {
      let datePicker = document.getElementById('datepicker-custom1')
      datePicker.focus()
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick (e, data) {
      const setPage = e
      // this.updateDataAsync()
      if (setPage === 'add') {
        router.push(`/Service/${setPage}`)
      } else if (setPage === 'view') {
        router.push(`/ServiceUsage/Services/History/view/${data.id}`)
      } else if (setPage === 'edit') {
        router.push(`/ServiceUsage/Services/History/edit/${data.id}`)
      }/*  else {
        router.push(`/Patient/${setPage}/${data.id}`)
      } */
    },
    sendData () {
      const setPage = 'add'
      this.setData(setPage)
    },
    handleClickGoBack () {
      router.go(-1)
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.id % 2 === 1) return 'table-body-color'
    },
    formatDate (date) {
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      } else {
        return '-'
      }
    },
    formatTel (tel) {
      if (tel) {
        let temp = tel.split('')
        let temp2 = ''
        temp.map((item, index) => {
          if (index === 2 || index === 5) {
            temp2 += item + '-'
          } else {
            temp2 += item
          }
        })
        return temp2
      } else {
        return '-'
      }
    }
  }
}
</script>
