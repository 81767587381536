<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Line Awesome Icon</h4>
          </template>
          <template v-slot:body>
            <p>Use Class <code>&lt;i class="las la-bell"&gt;&lt;/i&gt;</code></p>
            <b-row class="mt-3">
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-bell"></i>bell</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-phone-volume"></i>phone</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-radiation"></i>radiation</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-dog"></i>dog</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-fish"></i>fish</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-spider"></i>la-spider</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-angle-down"></i>angle-down</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-angle-left"></i>angle-left</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-angle-right"></i>angle-right</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-angle-up"></i>angle-up</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-caret-left"></i>caret-left</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-caret-right"></i>caret-right</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-download"></i>download</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-location-arrow"></i>location-arrow</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-share"></i>share</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-backward"></i>backward</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-play"></i>play</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-pause"></i>pause</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-sync"></i>sync</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-volume-down"></i>volume-down</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-volume-mute"></i>volume-mute</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-volume-off"></i>volume-off</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-volume-up"></i>volume-up</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="lab la-youtube"></i>youtube</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-car"></i>car</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-truck"></i>truck</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-tree"></i>tree</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="lab la-pinterest-p"></i>pinterest</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="lab la-java"></i>java</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-city"></i>city</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-edit"></i>edit</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-copy"></i>copy</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-cut"></i>cut</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-pen"></i>pen</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-tag"></i>tag</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-save"></i>save</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-tasks"></i>tasks</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-comment"></i>comment</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-video"></i>video</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-smile"></i>smile</b-link>
              </b-col>
              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-smile"></i>smile</b-link>
              </b-col>

              <b-col lg="2" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"><i class="las la-smile"></i>smile</b-link>
              </b-col>

              <b-col sm="12" class="text-center mt-3">
                <b-link href="https://icons8.com/line-awesome" target="blank" class="btn btn-primary">View All Icon</b-link>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
export default {
  name: 'IconLineAwesome',
  mounted () {
    xray.index()
  }
}
</script>
