<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span class="unselectable" style="font-size: 25px; color: black;">เพิ่มข้อมูลวันลาพนักงาน [ {{ absenceData.name }} ]</span>
              </div>
              <div v-if="mode === 'add' || mode === 'edit'" class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-6 col-sm-auto pl-3 pr-2 my-1 my-sm-0">
                    <b-button type="button" variant="primary" @click="handleClick(true)" class="w-100 btn-min-size"><i class="save-data_ct"></i>ยืนยัน</b-button>
                  </div>
                  <div class="col-6 col-sm-auto pl-2 pr-3 my-1 my-sm-0">
                    <b-button type="button" variant="none" @click="handleClick(false)" class="iq-bg-danger btn-cancel-border w-100 btn-min-size">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
              <div v-else class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="button" variant="none" @click="handleClick(false)" class="iq-bg-danger btn-cancel-border w-100 btn-min-size">ยกเลิก</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="px-4">
            <b-row class="">
              <b-col lg="2" sm="6" class="col-6" style="align-items: center; display: flex;">
                <b-text style="color: black; white-space: nowrap;">สิทธิการลา</b-text>
              </b-col>
              <b-col lg="2" sm="6" class="col-6 d-flex align-items-center" style="white-space: nowrap;">
                <b-text style="color: green">ลาพักร้อน {{ summaryLeave.entitlement.annual_leave }} วัน</b-text>
              </b-col>
              <b-col lg="2" sm="6" class="col-6 d-flex align-items-center" style="white-space: nowrap;">
                <b-text style="color: blue">ลากิจ {{ summaryLeave.entitlement.business_leave }} วัน</b-text>
              </b-col>
              <b-col lg="2" sm="6" class="col-6 d-flex align-items-center" style="white-space: nowrap;">
                <b-text style="color: red">ลาป่วย {{ summaryLeave.entitlement.sick_leave }} วัน</b-text>
              </b-col>
              <b-col lg="2" sm="6" class="col-6 d-flex align-items-center" style="white-space: nowrap;">
                <b-text style="color: black; margin-right: 1rem;">ประจำปี {{ (getYearCurrent() + 543) }}</b-text>
                <b-col lg="1" sm="1" class="format-col-pencil">
                  <b-button :disabled="isDisable" type="submit" variant=" iq-bg-success mr-1" size="sm" @click="OpenMadalSummaryLeave" style="min-width: 35px; min-height: 35px; max-height: 35px;" class="format-col-pencil-btn"><i class="ri-ball-pen-fill m-0"></i></b-button>
                </b-col>
              </b-col>
            </b-row>
            <hr>
            <b-row class="">
              <b-col lg="2" sm="6" class="col-6" style="align-items: center;">
                <b-text style="color: black;">วันลาคงเหลือ</b-text>
              </b-col>
              <b-col lg="2" sm="6" class="col-6 d-flex align-items-center" style="white-space: nowrap;">
                <b-text style="color: green">ลาพักร้อน {{ summaryLeave.balance.annual_leave }} วัน</b-text>
              </b-col>
              <b-col lg="2" sm="6" class="col-6 d-flex align-items-center" style="white-space: nowrap;">
                <b-text style="color: blue">ลากิจ {{ summaryLeave.balance.business_leave }} วัน</b-text>
              </b-col>
              <b-col lg="2" sm="6" class="col-6 d-flex align-items-center" style="white-space: nowrap;">
                <b-text style="color: red">ลาป่วย {{ summaryLeave.balance.sick_leave }} วัน</b-text>
              </b-col>
              <b-col lg="2" sm="6" class="col-6 d-flex align-items-center" style="white-space: nowrap;">
                <b-text style="color: black">ประจำปี {{ (getYearCurrent() + 543) }}</b-text>
              </b-col>
            </b-row>
            <hr>
            <b-modal v-model="modalLeave"
              :title="`แก้ไขสิทธิวันลา ปี ${(getYearCurrent() + 543)}`"
              cancel-title="เพิ่ม" ok-title="ยกเลิก" cancel-variant="primary" ok-variant="danger" @close.prevent="closeModal"
              :no-close-on-esc="true" :no-close-on-backdrop="true">

              <b-form-group label="ลาพักร้อน" label-for="exampleInputText1">
                <template v-slot:label>
                  ลาพักร้อน
                  <span :hidden="summaryLeave.entitlement.annual_leave || summaryLeave.entitlement.annual_leave === 0 ? true : false" class="text-danger"> *</span>
                </template>
                <b-form-input id="exampleInputText1" type="number" placeholder="" class="hide-arrow"
                  v-model="summaryLeave.entitlement.annual_leave" @keypress="checktypeNumber($event)" />
              </b-form-group>

              <b-form-group label="ลากิจ" label-for="exampleInputText1">
                <template v-slot:label>
                  ลากิจ
                  <span :hidden="summaryLeave.entitlement.business_leave || summaryLeave.entitlement.business_leave === 0 ? true : false" class="text-danger"> *</span>
                </template>
                <b-form-input id="exampleInputText1" type="number" placeholder="" class="hide-arrow"
                  v-model="summaryLeave.entitlement.business_leave" @keypress="checktypeNumber($event)" />
              </b-form-group>

              <b-form-group label="ลาป่วย" label-for="exampleInputText1">
                <template v-slot:label>
                  ลาป่วย
                  <span :hidden="summaryLeave.entitlement.sick_leave || summaryLeave.entitlement.sick_leave === 0 ? true : false" class="text-danger"> *</span>
                </template>
                <b-form-input id="exampleInputText1" type="number" placeholder="" class="hide-arrow"
                  v-model="summaryLeave.entitlement.sick_leave" @keypress="checktypeNumber($event)" />
              </b-form-group>

              <template #modal-footer>
                <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                  <b-button style="border-radius: 0.5rem; padding: 0 .7rem;" variant="primary" class="btn-min-size" size="sm"
                    @click="AddLeaveSummary"
                    :disabled="IsAddLeaveSummary()"
                    :hidden="checkMode === 'view' ? true : false">
                    <i class="save-data_ct" />บันทึก
                  </b-button>
                  <b-button variant="none" class="iq-bg-danger btn-cancel-border btn-min-size" style="border-radius: 0.5rem;"
                    size="sm" @click="closeModal">
                    ยกเลิก
                  </b-button>
                </div>
              </template>
            </b-modal>
            <b-row >
              <b-col lg="2" sm="6" class="col-6 col-12-response-edit-abs d-flex align-items-center justify-content-start" style="white-space: nowrap;">
                <div style="display: flex;">
                  <b-form-radio :value="employeeStatus.lapakron" :disabled="isDisable" v-model="absenceData.typeLeave" class="radio-disable" name="redio-button2"></b-form-radio>
                  <b-text class="margin-response-adduser check" style="color: black;">ลาพักร้อน</b-text>
                </div>
              </b-col>
              <b-col lg="2" sm="6" class="col-6 col-12-response-edit-abs d-flex align-items-center justify-content-start" style="white-space: nowrap;">
                <div style="display: flex;">
                  <b-form-radio :value="employeeStatus.lakit" :disabled="isDisable" v-model="absenceData.typeLeave" class="radio-disable" name="redio-button2"></b-form-radio>
                  <b-text class="margin-response-adduser check" style="color: black;">ลากิจ</b-text>
                </div>
              </b-col>
              <b-col lg="2" sm="6" class="col-6 col-12-response-edit-abs d-flex align-items-center justify-content-start" style="white-space: nowrap;">
                <div style="display: flex;">
                  <b-form-radio :value="employeeStatus.lasick" :disabled="isDisable" v-model="absenceData.typeLeave" class="radio-disable" name="redio-button2"></b-form-radio>
                  <b-text class="margin-response-adduser check" style="color: black;">ลาป่วย</b-text>
                </div>
              </b-col>
              <b-col lg="2" sm="6" class="col-6 col-12-response-edit-abs d-flex align-items-center justify-content-start" style="white-space: nowrap;">
                <div style="display: flex;">
                  <b-form-radio :value="employeeStatus.workout" :disabled="isDisable" v-model="absenceData.typeLeave" class="radio-disable" name="redio-button2"></b-form-radio>
                  <b-text class="margin-response-adduser check" style="color: black;">ปฏิบัติงานนอกสถานที่</b-text>
                </div>
              </b-col>
            </b-row>
            <br>
            <b-row >
              <b-col lg="8" sm="10" md="12" class="col-12 padding-custom-pj-details" >
                <b-form-group class="unselectable" label="" style="color: black;">
                    <b-text class="" style="color: black;">รายละเอียด <span v-if="!absenceData.details" style="color: red;">*</span></b-text>
                    <b-form-textarea :disabled="isDisable" id="" v-model="absenceData.details" type="text" style="padding-right: 1rem; height: 100px; justify-items: flex-start;"></b-form-textarea>
                  </b-form-group>
              </b-col>
            </b-row>
            <br>
            <b-row >
              <b-col lg="2" sm="6" class="col-6 col-12-response-edit-abs d-flex align-items-center justify-content-start" style="white-space: nowrap;">
                <div style="display: flex;">
                  <b-form-radio :value="employeeLeaveStatus.dayShift" :disabled="isDisable" v-model="absenceData.typeRangeLeave" class="radio-disable" name="redio-button"></b-form-radio>
                  <b-text class="margin-response-adduser check" style="color: black;">ลาทั้งวัน</b-text>
                </div>
              </b-col>
              <b-col lg="2" sm="6" class="col-6 col-12-response-edit-abs d-flex align-items-center justify-content-start" style="white-space: nowrap;">
                <div style="display: flex;">
                  <b-form-radio :value="employeeLeaveStatus.dayShiftTime" :disabled="isDisable" v-model="absenceData.typeRangeLeave" class="radio-disable" name="redio-button"></b-form-radio>
                  <b-text class="margin-response-adduser check" style="color: black;">ลาช่วงเวลา</b-text>
                </div>
              </b-col>
              <b-col lg="2" sm="6" class="col-6 col-12-response-edit-abs d-flex align-items-center justify-content-start" style="white-space: nowrap;">
                <div style="display: flex;">
                  <b-form-radio :value="employeeLeaveStatus.morningShift" :disabled="isDisable" v-model="absenceData.typeRangeLeave" class="radio-disable" name="redio-button"></b-form-radio>
                  <b-text class="margin-response-adduser check" style="color: black;">ลาครึ่งเช้า</b-text>
                </div>
              </b-col>
              <b-col lg="2" sm="6" class="col-6 col-12-response-edit-abs d-flex align-items-center justify-content-start" style="white-space: nowrap;">
                <div style="display: flex;">
                  <b-form-radio :value="employeeLeaveStatus.afternoonShift" :disabled="isDisable" v-model="absenceData.typeRangeLeave" class="radio-disable" name="redio-button"></b-form-radio>
                  <b-text class="margin-response-adduser check" style="color: black;">ลาครึ่งบ่าย</b-text>
                </div>
              </b-col>
            </b-row>
            <br>
            <b-row class="d-flex align-items-center">
              <b-col lg="4" sm="6">
                <b-text style="color: black;">วันที่เริ่มลา {{ absenceData.typeRangeLeave === employeeLeaveStatus.dayShiftTime ? ' - เวลาเริ่มต้น' : '' }} <span v-if="!absenceData.dateTimeStart" style="color: red;">*</span></b-text>
                  <div style="position: relative;">
                    <date-picker v-if="absenceData.typeRangeLeave === employeeLeaveStatus.dayShiftTime" placeholder="DD/MM/YYYY HH:mm" id="datepicker-custom1" name="date" v-model="absenceData.dateTimeStart" :disabled="isDisable" :config="optionsStart"></date-picker>
                    <date-picker v-else placeholder="DD/MM/YYYY" id="datepicker-custom1" name="date" v-model="absenceData.dateTimeStart" :disabled="isDisable" :config="options2Start"></date-picker>
                    <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(1)" :disabled="isDisable" style="cursor: pointer;"></i>
                  </div>
              </b-col>
              <b-col lg="4" sm="6">
                <b-text style="color: black;">วันที่สิ้นสุดการลา {{ absenceData.typeRangeLeave === employeeLeaveStatus.dayShiftTime ? ' - เวลาสิ้นสุด' : '' }} <span v-if="!absenceData.dateTimeEnd" style="color: red;">*</span></b-text>
                  <div style="position: relative;">
                    <date-picker v-if="absenceData.typeRangeLeave === employeeLeaveStatus.dayShiftTime" placeholder="DD/MM/YYYY HH:mm" id="datepicker-custom2" name="date" v-model="absenceData.dateTimeEnd" :disabled="isDisable === false ? getDateTimeStart === null ? true : false : true" :config="options"></date-picker>
                    <date-picker v-else placeholder="DD/MM/YYYY" id="datepicker-custom2" name="date" v-model="absenceData.dateTimeEnd" :disabled="isDisable === false ? getDateTimeStart === null ? true : false : true" :config="options2"></date-picker>
                    <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(2)" :disabled="isDisable" style="cursor: pointer;"></i>
                  </div>
              </b-col>
            </b-row>
            <br>
            <b-row >
              <b-col lg="4" sm="12" class="d-flex custom-flex-abs" style="white-space: nowrap;">
                <b-text style="color: black">แนบไฟล์ที่เกี่ยวข้อง</b-text>
                <b-form-file
                class="custom-file-style"
                id="imageInput"
                accept="image/*"
                @change="handleImageUpload"
                :disabled="isDisable"
                placeholder=""
                browse-text="เลือกไฟล์"
              ></b-form-file>
              </b-col>
            </b-row>
            <br>
          </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
/* .px-3-edit-response-abs {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
} */
.hide-arrow::-webkit-inner-spin-button,
.hide-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* .hide-arrow {
  -moz-appearance: textfield;
} */

[dir=ltr] .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #089bab !important;
}
.custom-flex-abs {
  white-space: nowrap;
  flex-direction: column;
  align-items: start !important;
  justify-content: start;
}
.icon-calendar-edit {
  position: absolute;
  height: 100%;
  font-size: 1.2rem;
  padding-right: 1rem;
  font-weight: 100;
  display: flex;
  color: rgb(100, 100, 100);
  top: 0px;
  right: 0px;
  align-items: center;
}

.padding-left-custom-pj-leave {
  padding-left: 2rem !important;
}
.margin-lefe-custom-pj {
  margin-left: 5rem !important;
}
@media (max-width: 991px) {
  .margin-lefe-custom-pj {
    margin-left: 2rem !important;
  }
}

@media (max-width: 576px) {
  .padding-left-custom-pj-leave {
    padding-left: 0 !important;
  }
  .margin-lefe-custom-pj {
    margin-left: 0 !important;
  }
  .padding-custom-pj-details {
    padding: 0 !important;
  }
}

@media (max-width: 350px) {
  /* .px-3-edit-response-abs {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  } */
  .padding-left-custom-pj-leave {
    padding-left: 1rem !important;
  }
  .col-12-response-edit-abs {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import datePicker from 'vue-bootstrap-datetimepicker'
import Vue from 'vue'
import moment from 'moment'
import axios from 'axios'
import { configAPI } from '../../configBase'
import 'moment/locale/th'

const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}

const dataTest = {
  details: 'เป็นโควิด ปวดตัวสุดๆ ไปโลยยย',
  typeRangeLeave: 'afternoonShift',
  typeLeave: 'WorkOut',
  dateTimeStart: '27/06/2023',
  dateTimeEnd: '29/06/2023'
}
document.getElementById(dataTest, axios, configAPI)

export default {
  name: 'UiDataTable',
  components: {
    datePicker
  },
  watch: {
    'summaryLeave.entitlement.annual_leave': function (newVal, oldVal) {
      if (newVal && newVal?.toString().indexOf('-') >= 0) {
        this.summaryLeave.entitlement.annual_leave = parseInt(newVal?.toString().replace(/-/g, '') || 0)
      }
    },
    'summaryLeave.entitlement.business_leave': function (newVal, oldVal) {
      if (newVal && newVal?.toString().indexOf('-') >= 0) {
        this.summaryLeave.entitlement.business_leave = parseInt(newVal?.toString().replace(/-/g, '') || 0)
      }
    },
    'summaryLeave.entitlement.sick_leave': function (newVal, oldVal) {
      if (newVal && newVal?.toString().indexOf('-') >= 0) {
        this.summaryLeave.entitlement.sick_leave = parseInt(newVal?.toString().replace(/-/g, '') || 0)
      }
    },
    'absenceData.typeRangeLeave': function (newVal, oldVal) {
      if (newVal === this.employeeLeaveStatus.dayShiftTime) {
        this.absenceData.dateTimeStart = null
        this.absenceData.dateTimeEnd = null
      }
    },
    'absenceData.dateTimeStart': function (newVal, oldVal) {
      // console.log('newVal :>> ', moment(new Date(newVal)).format('DD/MM/YYYY'))
      if (newVal === null) {
      }
      this.absenceData.dateTimeEnd = null
      this.options2.minDate = newVal
      this.options.minDate = newVal
      // console.log('this.options :>> ', this.options2)
    }
  },
  mounted () {
    // this.absenceData.name = this.$store.state.sharedData
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      this.mode = 'add'
      if (this.$route.params.setPage === 'view') {
        this.mode = 'view'
        this.isDisable = true
      }
      // this.postJobData = postJobDataTest
    }
    this.cln_id = JSON.parse(sessionStorage.getItem('user')).cln_id
    this.GetLeaveSummaryOfEmployeeById()
    this.GetDetailsOfEmployeeById()
    xray.index()
  },
  computed: {
    getDateTimeStart () {
      return this.absenceData.dateTimeStart
    }
  },
  methods: {
    async GetLeaveSummaryOfEmployeeById () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/employee/${this.id}/dayOff/?year=${this.getYearCurrent() || ''}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status) {
            const data = response.data.data.length > 0 ? response.data.data[0] : {}
            this.summaryLeave = {
              id: data.id || null,
              balance: {
                sick_leave: data.sick_leave || 0,
                business_leave: data.person_leave || 0,
                annual_leave: data.vacation_leave || 0
              },
              entitlement: {
                sick_leave: data.sick || 0,
                business_leave: data.person || 0,
                annual_leave: data.vacation || 0
              }
            }
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async GetDetailsOfEmployeeById () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/employee/${this.id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status && response.data.data.length) {
            const data = response.data.data[0]
            this.absenceData.name = (data.name + '\t' + data.lastname)
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    getYearCurrent () {
      return (parseInt(moment(new Date()).format('YYYY')))
    },
    onClickDatePicker (num) {
      let datePicker = null
      if (num === 1) {
        datePicker = document.getElementById('datepicker-custom1')
      } else {
        datePicker = document.getElementById('datepicker-custom2')
      }
      datePicker.focus()
    },
    onPagination (page) {
      this.currentPage = page
    },
    async saveLeaveEmployee () {
      Vue.swal.fire({
        title: '<span class="loading-icon"><i class="fas fa-spinner fa-spin"></i></span>',
        text: 'กำลังประกาศหางาน...',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          Vue.swal.getHtmlContainer().querySelector('.loading-icon').style.fontSize = '40px'
        }
      })
      if (!this.summaryLeave.id) {
        await this.saveLeaveSummary()
      }
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/employee/${this.id}/leave/`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          cln_id: this.cln_id,
          emp_id: this.id,
          es_id: this.absenceData.typeLeave,
          els_id: this.absenceData.typeRangeLeave,
          start_date: new Date(moment.utc(this.absenceData.dateTimeStart, 'DD/MM/YYYY')),
          end_date: new Date(moment.utc(this.absenceData.dateTimeEnd, 'DD/MM/YYYY')),
          detail: this.absenceData.details?.trim() || null,
          start_time: this.absenceData.typeRangeLeave === this.employeeLeaveStatus.dayShiftTime ? new Date(moment(this.absenceData.dateTimeStart, 'DD/MM/YYYY HH:mm')) : null,
          end_time: this.absenceData.typeRangeLeave === this.employeeLeaveStatus.dayShiftTime ? new Date(moment(this.absenceData.dateTimeEnd, 'DD/MM/YYYY HH:mm')) : null
        }
      })
        .then(async (response) => {
          Vue.swal.close()
          if (response.data.status) {
            if (this.absenceData.file) {
              await this.uploadFileLeaveEmployee(response.data.data.id)
            }
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              // console.log('save success ->', this.absenceData)
              router.push('/Absence')
            })
          }
        })
        .catch((error) => {
          Vue.swal.close()
          this.SwalError(error.message, 'บันทึกข้อมูลไม่สำเร็จ')
        })
    },
    async uploadFileLeaveEmployee (id) {
      let data = new FormData()
      data.append('file', this.absenceData.file)
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/leave/${id}/upload/doc`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
        .then(async (response) => {
        })
        .catch((error) => {
          this.SwalError(error.message, 'บันทึกรูปภาพไม่สำเร็จ')
        })
    },
    async checkError () {
      let error = []
      if (!this.absenceData.typeLeave) {
        error.push('กรุณาเลือกประเภทการลา')
      }
      if (!this.absenceData.details?.trim()) {
        error.push('กรุณากรอกรายละเอียด')
      }
      if (!this.absenceData.typeRangeLeave) {
        error.push('กรุณาเลือกช่วงเวลาที่จะลา')
      }
      if (!this.absenceData.dateTimeStart) {
        error.push('กรุณาเลือกวันที่เริ่มลา')
      }
      if (!this.absenceData.dateTimeEnd) {
        error.push('กรุณาเลือกวันที่สิ้นสุดการลา')
      }

      /* if (this.absenceData.typeLeave && this.absenceData.typeRangeLeave &&
      this.absenceData.dateTimeStart && this.absenceData.dateTimeEnd
      ) {
        const errorStr = await this.CalRangeTime()
        if (errorStr) {
          error.push(errorStr)
        }
      } */
      return error
    },
    async CalRangeTime () {
      let str = ''
      let sum = 1
      if (this.absenceData.typeRangeLeave === this.employeeLeaveStatus.afternoonShift ||
      this.absenceData.typeRangeLeave === this.employeeLeaveStatus.morningShift
      ) {
        sum = 0.5
      }

      if (this.absenceData.typeLeave === this.employeeStatus.lapakron) {
        if (this.summaryLeave.entitlement.annual_leave === 0 || (this.summaryLeave.entitlement.annual_leave < (this.GetRangeTime() * sum))) {
          str = 'สิทธิการลาพักร้อนคงเหลือน้อยกว่าจำนวนวันที่ลา'
        }
      } else if (this.absenceData.typeLeave === this.employeeStatus.lakit) {
        if (this.summaryLeave.entitlement.business_leave === 0 || (this.summaryLeave.entitlement.business_leave < (this.GetRangeTime() * sum))) {
          str = 'สิทธิการลากิจคงเหลือน้อยกว่าจำนวนวันที่ลา'
        }
      } else if (this.absenceData.typeLeave === this.employeeStatus.lasick) {
        if (this.summaryLeave.entitlement.sick_leave === 0 || (this.summaryLeave.entitlement.sick_leave < (this.GetRangeTime() * sum))) {
          str = 'สิทธิการลาป่วยคงเหลือน้อยกว่าจำนวนวันที่ลา'
        }
      }
      return str
    },
    GetRangeTime () {
      return (moment(this.absenceData.dateTimeEnd, 'DD/MM/YYYY').diff(moment(this.absenceData.dateTimeStart, 'DD/MM/YYYY'), 'days'))
    },
    handleClick (status) {
      if (status) {
        // .__ob__.value.var_product1
        // console.log('this.product', this.products?.__ob__.value)
        // console.log('this.supplier', this.rows)
        Vue.swal.fire({
          title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.$route.params.setPage !== 'edit' ? 'คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่?' : 'คุณมั่นใจว่าคุณจะแก้ไขข้อมูลใช่หรือไม่?'}</span>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            const errorList = await this.checkError()
            if (errorList.length > 0) {
              let errMessage = ''
              for (let e = 0; e < errorList.length; e++) {
                errMessage += '<br/>' + errorList[e]
              }
              this.SwalError(errMessage, 'บันทึกข้อมูลไม่สำเร็จ')
              return
            }
            await this.saveLeaveEmployee()
          }
        })
      } else {
        if (this.mode === 'view') {
          router.push('/Absence')
        } else {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่?</span>',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
            confirmButtonColor: '#089bab',
            cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
            cancelButtonColor: '#d33',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1',
              cancelButton: 'custom-confirm-button-class mx-1'
            }
          }).then((result) => {
            if (result.isConfirmed) {
              router.push('/Absence')
            }
          })
        }
      }
    },
    closeModal () {
      // console.log('this.summaryLeaveSupply', this.summaryLeaveSupply)
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะยกเลิกใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.modalLeave = false
          this.summaryLeave = { ...this.summaryLeaveSupply }
          this.summaryLeaveSupply = null
        }
      })
    },
    async AddLeaveSummary () {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.mode !== 'edit' ? 'คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่?' : 'คุณมั่นใจว่าคุณจะแก้ไขข้อมูลใช่หรือไม่?'}</span>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.saveLeaveSummary()
        }
      })
    },
    async saveLeaveSummary () {
      let temp = {
        sick: this.summaryLeave.entitlement.sick_leave || 0,
        person: this.summaryLeave.entitlement.business_leave || 0,
        vacation: this.summaryLeave.entitlement.annual_leave || 0
      }
      if (!this.summaryLeave.id) {
        temp.year = (this.getYearCurrent() + '')
      }
      await axios.request({
        method: this.summaryLeave.id ? 'put' : 'post',
        url: this.summaryLeave.id
          ? `${configAPI.MA_PORT}/api/employee/${this.id}/dayOff/${this.summaryLeave.id}/`
          : `${configAPI.MA_PORT}/api/employee/${this.id}/dayOff/`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: temp
      })
        .then(async (response) => {
          if (response.data.status) {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(async () => {
              await this.GetLeaveSummaryOfEmployeeById()
              this.modalLeave = false
            })
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'บันทึกข้อมูลไม่สำเร็จ')
        })
    },
    IsAddLeaveSummary () {
      let isAdd = false
      if (
        (this.summaryLeave.entitlement.annual_leave === '') ||
        (this.summaryLeave.entitlement.business_leave === '') ||
        (this.summaryLeave.entitlement.sick_leave === '')
      ) {
        isAdd = true
      }
      return isAdd
    },
    handleImageUpload (event) {
      const file2 = event.target.files[0]
      this.absenceData.file = file2
      /* const reader2 = new FileReader()
      reader2.readAsDataURL(file2)
      this.userAdd.imageName = file2.name
      reader2.onload = () => {
        this.userAdd.imageUrl = reader2.result
      } */
    },
    checktypeNumber (e) {
      let keyCode = (e.keyCode ? e.keyCode : e.which)
      if ((keyCode < 48 || keyCode > 57)) {
        e.preventDefault()
      }
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    OpenMadalSummaryLeave () {
      this.summaryLeaveSupply = JSON.parse(JSON.stringify(this.summaryLeave)) // structuredClone(this.summaryLeave)
      this.modalLeave = true
    }
  },
  data () {
    return {
      cln_id: '',
      id: '',
      mode: 'add',
      modalLeave: false,
      isDisable: false,
      summaryLeaveSupply: null,
      summaryLeave: {
        id: null,
        balance: {
          sick_leave: 0,
          business_leave: 0,
          annual_leave: 0
        },
        entitlement: {
          sick_leave: 0,
          business_leave: 0,
          annual_leave: 0
        }
      },
      employeeStatus: {
        lakit: '3ba75645-e7a2-4ba8-9d04-ce767ced0876',
        lasick: 'e2882a4a-8877-44d7-8f07-f0fbba99a99c',
        lapakron: '7e7f89fa-c22c-478c-9d62-bfe45fdc8aa6',
        workout: 'c8a177b0-08fc-11ee-8530-53148e6b848d'
      },
      employeeLeaveStatus: {
        morningShift: '253e3639-065c-4b46-88ee-d47279b37e6b',
        dayShift: '4dac0add-831c-491c-8917-b8b1ce84c157',
        afternoonShift: 'fa61040e-435c-4fb2-ab68-187ffc2cc167',
        dayShiftTime: '6a944216-39f2-11ee-be56-0242ac120002'
      },
      absenceData: {
        name: '',
        details: '',
        typeLeave: null,
        typeRangeLeave: null,
        dateTimeStart: null,
        dateTimeEnd: null,
        file: null,
        dayShift: false,
        dayShiftTime: false,
        morningShift: false,
        afternoonShift: false
      },
      optionsStart: {
        // https://momentjs.com/docs/#/displaying/
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        minDate: false
      },
      options2Start: {
        // https://momentjs.com/docs/#/displaying/
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        minDate: false,
        icons: iconDate
      },
      options: {
        // https://momentjs.com/docs/#/displaying/
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        minDate: false
      },
      options2: {
        // https://momentjs.com/docs/#/displaying/
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        minDate: false,
        icons: iconDate
      },
      currentPage: 1,
      perPage: 10
    }
  }
}
</script>
