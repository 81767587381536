<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4">
              <div class="col-12 col-md-4 col-xl-3">
                <span style="font-size: 25px; color: black;">ข้อมูลค่าคอมมิชชั่น</span>
              </div>
              <div class="col-12 col-md-8 col-xl-9">
                <div class="row">
                  <div class="col-12 col-md-3 col-xl-2 pl-sm-3 pr-sm-1 my-1 my-sm-0">
                    <b-button type="submit" variant="primary" @click="saveData" class="w-100 btn-min-size"><i
                        class="save-data_cd"></i>บันทึก</b-button>
                  </div>
                  <div v-if="search.label === 'ค่าคอมมิชชั่นแบบขั้นบันได'" class="col-12 col-md-3 col-xl-2 pl-sm-3 pr-sm-1 my-1 my-sm-0">
                    <b-button type="add" variant="primary" @click="addData()" class="w-100 btn-min-size"><i
                        class="add-data_cd"></i>เพิ่มข้อมูล</b-button>
                  </div>
                  <div v-if="search.label === 'ค่าคอมมิชชั่นแบบขั้นบันได'" class="col-12 col-md-4 col-xl-3 pl-sm-3 pr-sm-1 my-1 my-sm-0">
                    <b-button type="add" variant="danger" @click="delData()" class="w-100 btn-min-size"><i
                        class="add-data_cd"></i>ลบข้อมูลล่าสุด</b-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <b-col lg="3" md="4" sm="12" class="my-1 my-sm-0 mb-2 p-0">
              <b-form-group label-for="exampleFormControlSelect1" class="mb-0 w-100" label="">
                <v-select v-model="search" :options="searchDD" :clearable="false"></v-select>
              </b-form-group>
            </b-col>
            <div v-if="search.label === 'ค่าคอมมิชชั่นแบบขั้นบันได'">
              <div class="mx-3 mt-4">
                <b-row>
                  <b-col cols="12" class="table-responsive" style="padding: 0px;">
                    <b-table bordered hover :items="rows" :fields="columns" :current-page="currentPage" :per-page="perPage"
                      @pagination="onPagination" :tbody-tr-class="rowClass" thead-class="table-header-color">
                      <template #thead-top="data">
                      <b-tr>
                        <b-th class="" colspan="2" style="height: 50px; text-align: center;"><span class="">ยอดขาย</span></b-th>
                        <b-th class="delete-border-bot-attandence" colspan="1" style="height: 50px; text-align: center; "><span class=""></span></b-th>
                      </b-tr>
                    </template>
                    <template v-for="column in columns" v-slot:[`cell(${column.key})`]="data">
                      <template v-if="column.key === 'variable1'">
                        <CurrencyInputCom :disabled="true" v-model="data.item.variable1" :options="optionCurrency" :key="column.key + data.item.index"/>
                      </template>
                      <template v-if="column.key === 'variable2'">
                        <CurrencyInputCom v-model="data.item.variable2" :options="optionCurrency" :key="column.key + data.item.index"/> <!-- :isLess="true" :valueLess="parseFloat(data.item.variable1)" -->
                      </template>
                      <template v-if="column.key === 'variable3'">
                        <CurrencyInputCom v-model="data.item.variable3" :options="optionCurrency" :isLimit="true" :valueLimit="100" :key="column.key + data.item.index"/>
                      </template>
                    </template>
                    </b-table>
                  </b-col>
                </b-row>
                <b-row v-if="rows.length > 0">
                  <b-col cols="12" >
                    <span style="color: red;"><b>หมายเหตุ: </b>ช่องสิ้นสุดถ้าไม่กรอกจะหมายถึง ยอดขายเริ่มต้นขึ้นไป</span>
                  </b-col>
                </b-row>
                <b-row style="display: flex; justify-content: center;">
                  <b-pagination v-model="currentPage" :total-rows="rows.length" :per-page="perPage" />
                </b-row>
              </div>
            </div>
            <div v-else-if="search.label === 'ค่าคอมมิชชั่นแบบทั้งยอดขาย'">
              <div class="mt-4">
                <b-row>
                  <b-col cols="12" md="6" lg="4">
                    <b-form-group label="ค่าคอมมิชชั่นแบบทั้งยอดขาย (%)" style="color: black; padding: 0px; margin-left: 0px" >
                      <CurrencyInputCom :key="'amount1'" v-model="amount1" :options="optionCurrency" :isLimit="true" :valueLimit="100"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div v-else-if="search.label === 'ค่าคอมมิชชั่นแบบเหมา'">
              <div class="mt-4">
                <b-row>
                  <b-col cols="12" md="6" lg="4">
                    <b-form-group label="ค่าคอมมิชชั่นแบบเหมา (บาท)" style="color: black; padding: 0px; margin-left: 0px" >
                      <CurrencyInputCom :key="'amount2'" v-model="amount2" :options="optionCurrency"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.format-text2-attendence-test {
  position: absolute !important;
  top: -20% !important;
  left: 45% !important;
}
.table-size-200-300 {
  min-width: 200px;
  max-width: 300px;
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import Vue from 'vue'
import Axios from 'axios'
import { configAPI } from '../../configBase'
import CurrencyInputCom from './CurrencyInputCom.vue'
export default {
  name: 'CommissionData',
  components: {
    CurrencyInputCom
  },
  data () {
    return {
      clnId: null,
      usrId: null,
      rows: [],
      currentPage: 1,
      perPage: 10,
      columns: [
        { label: 'เริ่มต้น', key: 'variable1', class: 'text-center table-size-200-300' },
        { label: 'สิ้นสุด', key: 'variable2', class: 'text-center table-size-200-300' },
        { label: '%com', key: 'variable3', class: 'text-center table-size-200-300' }
      ],
      columns2: [
        { label: 'เริ่มต้น', key: 'variable1', class: 'text-center table-size-200-300' },
        { label: 'สิ้นสุด', key: 'variable2', class: 'text-center table-size-200-300' },
        { label: 'แบบเหมา', key: 'variable3', class: 'text-center table-size-200-300' }
      ],
      search: {
        // label: 'ค่าคอมมิชชั่นทั้งยอดขาย', value: '1'
      },
      searchDD: [
        // { label: 'ค่าคอมมิชชั่นทั้งยอดขาย', value: '1' },
        // { label: 'ค่าคอมมิชชั่นตามขั้นบันได', value: '2' },
        // { label: 'ค่าคอมมิชชั่นแบบเหมา', value: '3' }
      ],
      // rate: [],
      // rateSelect: null,
      amount1: null,
      amount2: null,
      optionCurrency: {
        currency: 'EUR',
        currencyDisplay: 'hidden',
        valueRange: {
          min: 0,
          max: 1e+53
        },
        precision: 2,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: true,
        accountingSign: false
      }
    }
  },
  watch: {
    // When data seach had changing it's call function
    'search': function (newVal, oldVal) {
      this.GetCommissionDataByClinic()
    },
    'rows': {
      deep: true,
      handler: function (newVal, oldVal) {
        if (newVal.length > 0) {
          for (let index = 0; index < newVal.length; index++) {
            // if newVal[index].variable2 change then newVal[index + 1].variable1 = newVal[index].variable2
            if (index < newVal.length - 1) {
              if (parseFloat(newVal[index].variable2?.toString().replace(/,/g, '') || 0) !== parseFloat(newVal[index + 1].variable1?.toString().replace(/,/g, ''))) {
                newVal[index + 1].variable1 = (parseFloat(newVal[index].variable2?.toString().replace(/,/g, '') || 0) + 1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
            }
          }
        }
      }
    }
  },
  async mounted () {
    const sessionData = JSON.parse(sessionStorage.getItem('user'))
    this.clnId = sessionData.cln_id || null
    this.usrId = sessionData.usr_id || null
    // Set Header of %COM
    await this.getCommissionTypeSale()
    await this.getClinicCommissionType()
    // this.SetHeaderTable()
    // await this.GetCommissionType()
    await this.GetCommissionDataByClinic()
    xray.index()
  },
  methods: {
    // SetHeaderTable () {
    //   const element2 = document.querySelector(`th[role="columnheader"][aria-colindex="3"]`)
    //   element2.classList.add('border-top-attendence')
    //   let divElement2 = element2.querySelector('div')
    //   divElement2.classList.add('format-text2-attendence-test')
    // },
    async getCommissionTypeSale () {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/GetCommissionTypeSale`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (response.data.data.length > 0) {
          let temp = []
          for (let item of response.data.data) {
            temp.push({
              label: item.type_sale,
              value: item.id
            })
          }

          this.searchDD = temp
          this.search = temp[0]
        }
      }).catch((err) => {
        this.SwalError(err.message, 'เกิดข้อผิดพลาดในดึงข้อมูล')
      })
    },
    async GetCommissionDataByClinic () {
      // console.log('this.search', this.search);
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/${this.search?.value}/GetCommissionData`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        if (response.data.data.length > 0) {
          if (this.search.label === 'ค่าคอมมิชชั่นแบบขั้นบันได') {
            let temp = []
            let index = 0
            for (let item of response.data.data) {
              temp.push({
                variable1: this.isNumberComma(parseFloat(item.begin_sell).toFixed(2)),
                variable2: this.isNumberComma(parseFloat(item.end_sell).toFixed(2)),
                variable3: this.isNumberComma(parseFloat(item.amount).toFixed(2)),
                index: index
              })
              index++
            }
            this.rows = temp
          } else if (this.search.label === 'ค่าคอมมิชชั่นแบบทั้งยอดขาย') {
            this.amount1 = this.isNumberComma(parseFloat(response.data.data[0].amount).toFixed(2))
          } else if (this.search.label === 'ค่าคอมมิชชั่นแบบเหมา') {
            this.amount2 = this.isNumberComma(parseFloat(response.data.data[0].amount).toFixed(2))
          }
        } else {
          this.rows = []
          this.amount1 = null
          this.amount2 = null
        }
      }).catch((err) => {
        this.SwalError(err.message, 'เกิดข้อผิดพลาดในดึงข้อมูล')
      })
    },
    async getClinicCommissionType () {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        let temp = this.searchDD.filter((item) => {
          return item.value === response.data.data[0]?.cts_id
        })
        if (temp.length > 0) {
          this.search = temp[0]
        }
      }).catch((err) => {
        this.SwalError(err.message, 'เกิดข้อผิดพลาดในดึงข้อมูล')
      })
    },
    delData () {
      this.rows.pop()
    },
    addData () {
      if (this.rows.length > 0 && (this.rows[this.rows.length - 1].variable1 === '' || !this.rows[this.rows.length - 1].variable2)) {
        this.SwalError('กรุณากรอกยอดขายเริ่มต้น และสิ้นสุด', 'ไม่สามารถเพิ่มข้อมูลได้')
        return
      }
      if (this.rows.length > 0 && parseFloat(this.rows[this.rows.length - 1].variable2?.toString().replace(/,/g, '') || 0) < parseFloat(this.rows[this.rows.length - 1].variable1?.toString().replace(/,/g, '') || 0)) {
        this.SwalError('กรุณากรอกยอดขายสิ้นสุด มากกว่า ยอดขายเริ่มต้น', 'ไม่สามารถเพิ่มข้อมูลได้')
        return
      }
      const newRow = {
        variable1: this.rows.length === 0 ? 0 : this.isNumberComma((parseFloat(this.rows[this.rows.length - 1].variable2) + 1).toFixed(2)),
        variable2: '',
        variable3: '',
        index: this.rows.length
      }
      this.rows.push(newRow)
    },
    saveData () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let errorList = await this.checkError()
          if (errorList.length > 0) {
            this.SwalError(errorList.join('<br>'), 'บันทึกข้อมูลไม่สำเร็จ')
          } else {
            await this.SaveDataApi()
          }
        }
      })
    },
    async checkError () {
      let errorList = []
      if (this.search.label === 'ค่าคอมมิชชั่นแบบขั้นบันได') {
        if (this.rows.length === 0) {
          errorList.push(`กรุณาเพิ่มข้อมูล`)
        } else {
          for (let index = 0; index < this.rows.length; index++) {
            // if it is not the last row and variable2 is empty or null or zero then push error
            if (index < this.rows.length - 1 && (this.rows[index].variable2 === '' || this.rows[index].variable2 === null || parseFloat(this.rows[index].variable2).toFixed(2) === '0.00')) {
              if (index === 0) {
                errorList.push(`แถวที่ ${index + 1} : กรุณากรอกข้อมูล สิ้นสุด และมากกว่า 0`)
              } else {
                errorList.push(`แถวที่ ${index + 1} : กรุณากรอกข้อมูล สิ้นสุด`)
              }
            }
            // if it is not the last row and variable2 is not empty or null then check variable2 > variable1
            if (index < this.rows.length - 1 && this.rows[index].variable2 !== '' && this.rows[index].variable2 !== null) {
              if (parseFloat(this.rows[index].variable2?.toString().replace(/,/g, '')) < parseFloat(this.rows[index].variable1?.toString().replace(/,/g, ''))) {
                errorList.push(`แถวที่ ${index + 1} : กรุณากรอกข้อมูล สิ้นสุด มากกว่า เริ่มต้น`)
              }
            }
            // if it is the last row and variable2 is not empty or null then check variable2 > variable1
            if (index === this.rows.length - 1 && this.rows[index].variable2 !== '' && this.rows[index].variable2 !== null) {
              if (parseFloat(this.rows[index].variable2?.toString().replace(/,/g, '')) < parseFloat(this.rows[index].variable1?.toString().replace(/,/g, ''))) {
                errorList.push(`แถวที่ ${index + 1} : กรุณากรอกข้อมูล สิ้นสุด มากกว่า เริ่มต้น`)
              }
            }
            if (this.rows[index].variable3 === '' || this.rows[index].variable3 === null) {
              errorList.push(`แถวที่ ${index + 1} : กรุณากรอกข้อมูล %com`)
            }
          }
        }
      } else if (this.search.label === 'ค่าคอมมิชชั่นแบบทั้งยอดขาย') {
        if (this.amount1 === '' || this.amount1 === null) {
          errorList.push(`กรุณากรอกข้อมูล`)
        }
      } else if (this.search.label === 'ค่าคอมมิชชั่นแบบเหมา') {
        if (this.amount2 === '' || this.amount2 === null) {
          errorList.push(`กรุณากรอกข้อมูล`)
        }
      }
      // for (let item of this.rows) {
      //   if (item.variable1 === '' || item.variable1 === null) {
      //     errorList.push(`แถวที่ ${idx} : กรุณากรอกข้อมูล เริ่มต้น`)
      //   }
      //   if (item.variable2 !== '' && (item.variable2 < item.variable1)) {
      //     errorList.push(`แถวที่ ${idx} : กรุณากรอกข้อมูล สิ้นสุด มากกว่า เริ่มต้น`)
      //   }
      //   if (item.variable3 === '' || item.variable3 === null) {
      //     errorList.push(`แถวที่ ${idx} : กรุณากรอกข้อมูล %com`)
      //   }
      //   idx++
      // }
      return errorList
    },
    async SaveDataApi () {
      let dataForSave = {
        cts_id: this.search.value,
        cts_name: this.search.label,
        commissionrows: this.rows,
        amount: this.search.label === 'ค่าคอมมิชชั่นแบบทั้งยอดขาย' ? this.amount1 : this.search.label === 'ค่าคอมมิชชั่นแบบเหมา' ? this.amount2 : null
      }
      await Axios.request({
        method: 'POST',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/createCommission`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataForSave
      }).then((response) => {
        Vue.swal.fire({
          title: `<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>`,
          icon: 'success',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          timer: 1500
        }).then((result) => {
          window.location.reload()
        })
      }).catch((err) => {
        this.SwalError(err.message, 'บันทึกข้อมูลไม่สำเร็จ')
      })
    },
    onPagination (page) {
      this.currentPage = page
    },
    isNumberComma (value) {
      return value?.toString().replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
    },
    isNumberCheck (event) {
      let useData = event.target.value
      let keyCode = event.keyCode || event.which
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 46 || useData.indexOf('.') !== -1)
      ) {
        event.preventDefault()
      }
      if ((useData === null || useData === '') && keyCode === 46) {
        event.preventDefault()
      }
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    SwalError (message, title) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${title}</span>`,
        html: '<span>' + message + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    }
  }
}

</script>
