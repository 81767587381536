<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">รายการหัตถการคงค้าง</span>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" md="7" lg="5" class="mb-3">
                  <b-row>
                    <b-col sm="12" md class="mb-3">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input v-model="searchData" type="text" class="form-control" id="exampleInputEmail1"
                            @keydown="enterSearch" placeholder="Search" style="width: 100%;">
                            <a class="search-link" @click="getAllSaleOrder()"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                      <span class="mx-2 font-size-14 text-primary">
                        <!-- dataListLength -->
                        {{ dataList?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > dataList?.length) ? dataList?.length : (perPage * currentPage) ) + ' of ' + dataListLength : '0 of ' + dataListLength }} รายการ
                        <!-- {{ dataList?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > dataList?.length) ? dataList?.length : (perPage * currentPage) ) + ' of ' + dataList?.length : 0 }} รายการ -->
                      </span></b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <b-col md="12" class="table-responsive">
              <b-table bordered :items="dataList" :fields="columnsMain"
                :current-page="currentPage"
                :per-page="perPage"
                @pagination="onPagination"
                :tbody-tr-class="rowClass" thead-class="table-header-color"
                >
                <!-- <template v-slot:cell(variable1)="data">
                  <span>{{ ((currentPage - 1) * 10) + data.index + 1 }}</span>
                </template> -->
                <template v-slot:cell(variable1)="data">
                  <span>{{ `${data.item.cus_firstname} ${data.item.cus_lastname} (${data.item.cus_nickname})` }}</span>
                </template>
                <template v-slot:cell(variable2)="data">
                  <a :href="'tel:' + formatTel(data.item.cus_tel)"><span style="text-decoration: underline;">{{ formatTel(data.item.cus_tel) }}</span></a>
                </template>
                <template v-slot:cell(variable3)="data">
                  <span v-if="!data.item.currect_hc_date">-</span>
                  <a v-else  @click="viewOPD(data.item.currect_hc_id)"><span class="text-primary" style="text-decoration: underline; cursor: pointer;">{{ formatDate(data.item.currect_hc_date, 'DD/MM/YYYY') }}</span></a>
                </template>
                <template v-slot:cell(variable4)="data">
                  <div style="display: flex; justify-content: center; gap: .5rem;">
                    <b-button  type="button" variant="primary-custom" class="w-100 btn-min-size">
                      {{ data.item.so_list?.length || 0 }} รายการ
                    </b-button>
                    <b-button variant="primary-custom mr-1" size="sm" class="px-2 py-0" style="border-radius: 10px;"
                      :disabled="data.item.so_list?.length === 0"
                      @click="data.toggleDetails(), data.item.toggle = !data.item.toggle"
                    >
                    <i style="font-size: 1.5rem;" v-if="data.item.toggle" class="ri-arrow-down-s-fill m-0"/>
                    <i style="font-size: 1.5rem;" v-else class="ri-arrow-up-s-fill m-0"/>
                  </b-button>
                  <b-button variant="warning mr-1" size="sm" class="px-2 py-0" style="border-radius: 10px;"
                      :disabled="data.item.so_list?.length === 0"
                      @click="viewOPD(data.item.cus_id, 'all')"
                    >
                    <i style="font-size: 1.5rem;" class="ri-history-fill m-0"/>
                  </b-button>
                  </div>
                </template>
                <template #row-details="data">
                  <b-col md="12" class="table-responsive sub-table-wrapper-so">
                    <b-table hover :items="data.item.so_list" :fields="columnsSub"
                      :current-page="currentPageSub"
                      :per-page="1000000"
                      @pagination="onPaginationSub"
                      class="sub-table"
                      :tbody-tr-class="rowClassSub"
                    >
                      <template v-slot:cell(variable1)="data">
                        <span>{{ data.index + 1 }}</span>
                      </template>
                      <template v-slot:cell(variable2)="data">
                        <span>{{ data.item.so_no }}</span>
                      </template>
                      <template v-slot:cell(quo_no)="data">
                        <a :href="`/Quotation/so/mgt/view/${data.item.so_quo_id}`"><u><span>{{ data.item.quo_no }}</span></u></a>
                        <!-- <span>{{ data.item.quo_no }}</span> -->
                      </template>
                      <template v-slot:cell(variable3)="data">
                        <span>{{ formatDate(data.item.so_date, 'DD/MM/YYYY') }}</span>
                      </template>
                      <template v-slot:cell(variable4)="data">
                        <span>{{ `${data.item.prodSer_name}` }}</span>
                      </template>
                      <template v-slot:cell(variable5)="data">
                        <span>{{ `${data.item.sum_quantity}/${data.item.soi_amount} ${data.item?.prodUnit_name || 'ครั้ง'}` }}</span>
                      </template>
                    </b-table>
                    <!-- <br> -->
                    <!-- <b-pagination v-model="currentPageSub" :total-rows="data.item.so_list?.length" :per-page="perPage" align="center" /> -->
                  </b-col>
                </template>
              </b-table>
              <br>
              <b-pagination v-model="currentPage" :total-rows="dataList?.length" :per-page="perPage" align="center" />
            </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.width-label{
  width: 200px !important;
}

.sub-table-wrapper-so {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2) !important;
  border-radius: 10px;
  padding: 0 !important;
}
.sub-table-wrapper .sub-table table {
    border: none;
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import axios from 'axios'
import Vue from 'vue'
import { configAPI } from '../../configBase'
import moment from 'moment'

export default {
  name: 'SaleOrderTable',
  async mounted () {
    this.clnId = JSON.parse(sessionStorage.getItem('user')).cln_id || null
    this.userId = JSON.parse(sessionStorage.getItem('user')).usr_id || null
    await this.getAllSaleOrder()
    xray.index()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getAllSaleOrder()
      }
    },
    async getAllSaleOrder () {
      let soApi = {
        method: 'get',
        url: `${configAPI.SALE_PORT}/api/clinic/${this.clnId}/${this.userId}/getSale_order?search=` + this.searchData,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(soApi)
        .then((response) => {
          this.dataList = response.data.data?.map(el => {
            el.so_list.map((tl, index2) => {
              tl.index = index2
              return tl
            })
            return el
          })
          this.dataListLength = response.data.count || 0
          /* this.employeegroup = response.data
          this.dataListLength = response.data.count || 0 */
        })
    },
    resetValue () {
      this.employeeGroup.emp1 = null
      this.employeeGroup.emp2 = null
    },
    edit (item) {
      item.editable = true
    },
    submit (item) {
      item.editable = false
    },
    remove (id) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะลบข้อมูลใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let DelemployeeGroup = {
            method: 'delete',
            url: `${configAPI.MD_PORT}/api/employeeGroup/` + id,
            headers: {
              'Content-Type': 'application/json'
            }
          }
          axios.request(DelemployeeGroup)
            .then(async () => {
              Vue.swal({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                timer: 2000
              })
              this.searchData = ''
              await this.getEmployeeGroup()
            })
        }
      })
    },
    onPagination (page) {
      this.currentPage = page
    },
    onPaginationSub (page) {
      this.currentPageSub = page
    },
    handleClick () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะยกเลิกใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.showModal = false
        }
      })
    },
    addEmployeeGroup () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะบันทึกข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let temp = {
            name: this.employeeGroup.emp1?.trim()
          }
          let AddemployeeGroup = {
            method: 'post',
            url: `${configAPI.MD_PORT}/api/employeeGroup`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: temp
          }
          await axios.request(AddemployeeGroup)
            .then(async (response) => {
              if (response.data.status === false) {
                this.SwalError(response.data.error === 'name duplicate' ? 'กลุ่มพนักงานนี้ซ้ำกับในระบบ กรุณาลองใหม่' : response.data.error, 'บันทึกข้อมูลไม่สำเร็จ')
              } else {
                Vue.swal({
                  title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
                  icon: 'success',
                  showCancelButton: false,
                  showConfirmButton: false,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  timer: 2000
                }).then(() => {
                  this.showModal = false
                })
                this.searchData = ''
                await this.getEmployeeGroup()
              }
            }).catch((error) => {
              this.SwalError(error.message, 'บันทึกข้อมูลไม่สำเร็จ')
            })
        }
      })
    },
    setEditEmployeeGroup (data, index) {
      this.employeeGroup.id = data.id
      this.employeeGroup.index = data.index
      this.employeeGroup.emp1 = data.name
    },
    resetEmployeeGroup () {
      this.employeeGroup.id = null
      this.employeeGroup.index = null
      this.employeeGroup.emp1 = null
      this.showModal = true
    },
    editEmployeeGroup (id) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะแก้ไขข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let temp = {
            name: this.employeeGroup.emp1?.trim()
          }
          let EditemployeeGroup = {
            method: 'put',
            url: `${configAPI.MD_PORT}/api/employeeGroup/` + id,
            headers: {
              'Content-Type': 'application/json'
            },
            data: temp
          }
          axios.request(EditemployeeGroup)
            .then(async (response) => {
              if (response.data.status === false) {
                this.SwalError(response.data.error === 'name duplicate' ? 'กลุ่มพนักงานนี้ซ้ำกับในระบบ กรุณาลองใหม่' : response.data.error, 'แก้ไขข้อมูลไม่สำเร็จ')
              } else {
                Vue.swal({
                  title: '<span class="" style="font-size: 25px; color: black; text-align: center">แก้ไขข้อมูลสำเร็จ</span>',
                  icon: 'success',
                  showCancelButton: false,
                  showConfirmButton: false,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  timer: 2000
                }).then(() => {
                  this.showModal = false
                })
                this.searchData = ''
                await this.getEmployeeGroup()
              }
            }).catch((error) => {
              this.SwalError(error.message, 'แก้ไขข้อมูลไม่สำเร็จ')
            })
        }
      })
    },
    viewOPD (id, historyAll) {
      if (historyAll) {
        router.push(`/HistoryService/view/${id}/SaleOrder`)
      } else {
        router.push(`/ServiceUsage/SaleOrder/History/view/${id}`)
      }
    },
    managePermission (id) {
      router.push('EmployeeGroup/Permission/' + id)
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return ''
      const index = this.dataList.indexOf(item) + 1
      if (index % 2 === 1) return 'table-body-color'
    },
    rowClassSub (item, type) {
      if (!item || type !== 'row') return ''
      if (item.sum_quantity === item.soi_amount) return 'table-body-color-green'
      if (item.index % 2 === 1) return 'table-body-color-default'
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    formatTel (tel) {
      if (tel) {
        let temp = tel.split('')
        let temp2 = ''
        temp.map((item, index) => {
          if (index === 2 || index === 5) {
            temp2 += item + '-'
          } else {
            temp2 += item
          }
        })
        return temp2
      } else {
        return '-'
      }
    },
    formatDate (date, format) {
      return moment(date).format(format)
    }
  },
  data () {
    return {
      clnId: null,
      userId: null,
      searchData: '',
      employeegroup: [],
      showModal: false,
      columnsMain: [
        { label: 'ชื่อลูกค้า', key: 'variable1', class: 'text-center table-size-200-400 ellips' },
        { label: 'เบอร์โทรติดต่อ', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'วันที่ใช้บริการล่าสุด', key: 'variable3', class: 'text-center table-size-200-400' },
        { label: 'รายการหัตถการคงค้าง', key: 'variable4', class: 'text-center table-size-200-400' }
      ],
      columnsSub: [
        { label: 'ลำดับ', key: 'variable1', class: 'text-center table-size-200-400' },
        { label: 'เลขใบคำสั่งซื้อ', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'เลขใบขอซื้อ', key: 'quo_no', class: 'text-center table-size-200-400' },
        { label: 'วันที่สั่งซื้อ', key: 'variable3', class: 'text-center table-size-200-400' },
        { label: 'ชื่อผลิตภัณฑ์', key: 'variable4', class: 'text-center table-size-200-400 ellips' },
        { label: 'ยอดคงค้าง', key: 'variable5', class: 'text-center table-size-200-400' }
      ],
      dataListLength: 0,
      dataList: [
        {
          cus_name: 'test1',
          cus_tel: '08292929299',
          cus_date: '02/01/2024',
          toggle: false,
          so_list: [
            { no: '', so_no: '0000001', so_date: '02/01/2024', so_name: 'botox', so_amout: '50/100 unit' },
            { no: '', so_no: '0000002', so_date: '02/01/2024', so_name: 'botox', so_amout: '50/100 unit' }
          ]
        },
        {
          cus_name: 'test2',
          cus_tel: '08297777777',
          cus_date: '03/01/2024',
          toggle: false,
          so_list: [
            { no: '', so_no: '0000003', so_date: '02/01/2024', so_name: 'botox', so_amout: '50/100 unit' },
            { no: '', so_no: '0000004', so_date: '02/01/2024', so_name: 'botox', so_amout: '50/100 unit' }
          ]
        }
      ],
      employeeGroup: {
        emp1: null,
        emp2: null,
        emp3: null
      },
      currentPage: 1,
      perPage: 10,
      currentPageSub: 1
    }
  }
}
</script>
