<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span v-if="setPage() === 'add'" style="font-size: 25px; color: black;">Follow Up Case
                  (เพิ่ม)</span>
                <span v-else-if="setPage() === 'edit'"
                  style="font-size: 25px; color: black;">Follow Up Case (แก้ไข)</span>
                <span v-else style="font-size: 25px; color: black;">Follow Up Case (ดูรายละเอียด)</span>
              </div>
              <div v-if="setPage() === 'add' || setPage() === 'edit'" class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-6 pl-3 pr-2 my-1 my-sm-0">
                    <b-button type="submit" variant="primary" @click="handleClick(true)" class="w-100 btn-min-size"><i class="save-data_ct"></i>บันทึก</b-button>
                  </div>
                  <div class="col-6 pl-2 pr-3 my-1 my-sm-0">
                    <b-button type="submit" variant="none" @click="handleClick(false)" class="w-100 btn-min-size iq-bg-danger btn-cancel-border">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
              <div v-else class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="submit" variant="none" @click="cancelClick()" class="w-100 btn-min-size iq-bg-danger btn-cancel-border">ยกเลิก</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col class="">
                <!-- <div class="mx-3" style="color: black; font-size: large;">บริการ : {{ caseTrackService }}</div>
                <br> -->
                <div class="row">
                  <b-col xl="1" lg="1" md="2" sm="2" style="display: flex; align-items: center; white-space: nowrap;">
                    <b-text class="mx-3" style="color: black;">Day 0</b-text>
                  </b-col>
                  <b-col xl="2" lg="2" md="2" sm="2" style="display: flex; align-items: center; white-space: nowrap;">
                    <v-select v-model="followTypeDay0" :disabled="setPage() === 'view' ? true : false" :options="followTypeOption" style="min-width: 200px; max-width: 200px;"></v-select>
                  </b-col>
                </div>
                  <b-col lg="12" sm="12" class="my-2">
                    <b-form-textarea type="text" v-model="caseTrackDay0" :disabled="setPage() === 'view' ? true : false"></b-form-textarea>
                  </b-col>
                <br>
                <div class="row">
                  <b-col xl="1" lg="1" md="2" sm="2" style="display: flex; align-items: center; white-space: nowrap;">
                    <b-text class="mx-3" style="color: black;">Day 1</b-text>
                  </b-col>
                  <b-col xl="2" lg="2" md="2" sm="2" style="display: flex; align-items: center; white-space: nowrap;">
                    <v-select v-model="followTypeDay1" :disabled="setPage() === 'view' ? true : false" :options="followTypeOption" style="min-width: 200px; max-width: 200px;"></v-select>
                  </b-col>
                </div>
                <b-col lg="12" sm="12" class="my-2">
                  <b-form-textarea type="text" v-model="caseTrackDay1" :disabled="setPage() === 'view' ? true : false"></b-form-textarea>
                </b-col>
                <br>
                <div class="row">
                  <b-col xl="1" lg="1" md="2" sm="2" style="display: flex; align-items: center; white-space: nowrap;">
                    <b-text class="mx-3" style="color: black;">Day 3</b-text>
                  </b-col>
                  <b-col xl="2" lg="2" md="2" sm="2" style="display: flex; align-items: center; white-space: nowrap;">
                    <v-select v-model="followTypeDay3" :disabled="setPage() === 'view' ? true : false" :options="followTypeOption" style="min-width: 200px; max-width: 200px;"></v-select>
                  </b-col>
                </div>
                <b-col lg="12" sm="12" class="my-2">
                  <b-form-textarea type="text" v-model="caseTrackDay3" :disabled="setPage() === 'view' ? true : false"></b-form-textarea>
                </b-col>
                <br>
                <div class="row">
                  <b-col xl="1" lg="1" md="2" sm="2" style="display: flex; align-items: center; white-space: nowrap;">
                    <b-text class="mx-3" style="color: black;">1 Week</b-text>
                  </b-col>
                  <b-col xl="2" lg="2" md="2" sm="2" style="display: flex; align-items: center; white-space: nowrap;">
                    <v-select v-model="followTypeWeek1" :disabled="setPage() === 'view' ? true : false" :options="followTypeOption" style="min-width: 200px; max-width: 200px;"></v-select>
                  </b-col>
                </div>
                <b-col lg="12" sm="12" class="my-2">
                  <b-form-textarea type="text" v-model="caseTrackWeek1" :disabled="setPage() === 'view' ? true : false"></b-form-textarea>
                </b-col>
                <br>
                <div class="row">
                  <b-col xl="1" lg="1" md="2" sm="2" style="display: flex; align-items: center; white-space: nowrap;">
                    <b-text class="mx-3" style="color: black;">2 Week</b-text>
                  </b-col>
                  <b-col xl="2" lg="2" md="2" sm="2" style="display: flex; align-items: center; white-space: nowrap;">
                    <v-select v-model="followTypeWeek2" :disabled="setPage() === 'view' ? true : false" :options="followTypeOption" style="min-width: 200px; max-width: 200px;"></v-select>
                  </b-col>
                </div>
                <b-col lg="12" sm="12" class="my-2">
                  <b-form-textarea type="text" v-model="caseTrackWeek2" :disabled="setPage() === 'view' ? true : false"></b-form-textarea>
                </b-col>
                <br>
                <div class="row">
                  <b-col xl="1" lg="1" md="2" sm="2" style="display: flex; align-items: center; white-space: nowrap;">
                    <b-text class="mx-3" style="color: black;">4 Week</b-text>
                  </b-col>
                  <b-col xl="2" lg="2" md="2" sm="2" style="display: flex; align-items: center; white-space: nowrap;">
                    <v-select v-model="followTypeWeek4" :disabled="setPage() === 'view' ? true : false" :options="followTypeOption" style="min-width: 200px; max-width: 200px;"></v-select>
                  </b-col>
                </div>
                <b-col lg="12" sm="12" class="my-2">
                  <b-form-textarea type="text" v-model="caseTrackWeek4" :disabled="setPage() === 'view' ? true : false"></b-form-textarea>
                </b-col>
                <br>
                <div class="row">
                  <b-col xl="2" lg="2" md="3" sm="4" style="display: flex; align-items: center; white-space: nowrap;">
                    <b-text class="mx-3" style="color: black;">3 month (12 weeks)</b-text>
                  </b-col>
                  <b-col xl="2" lg="2" md="2" sm="2" style="display: flex; align-items: center; white-space: nowrap;">
                    <v-select v-model="followTypeMonth3" :disabled="setPage() === 'view' ? true : false" :options="followTypeOption" style="min-width: 200px; max-width: 200px;"></v-select>
                  </b-col>
                </div>
                <b-col lg="12" sm="12" class="my-2">
                  <b-form-textarea type="text" v-model="caseTrackMonth3" :disabled="setPage() === 'view' ? true : false"></b-form-textarea>
                </b-col>
                <br>
                <div class="row">
                  <b-col xl="2" lg="2" md="3" sm="4" style="display: flex; align-items: center; white-space: nowrap;">
                    <b-text class="mx-3" style="color: black;">6 month (24 weeks)</b-text>
                  </b-col>
                  <b-col xl="2" lg="2" md="2" sm="2" style="display: flex; align-items: center; white-space: nowrap;">
                    <v-select v-model="followTypeMonth6" :disabled="setPage() === 'view' ? true : false" :options="followTypeOption" style="min-width: 200px; max-width: 200px;"></v-select>
                  </b-col>
                </div>
                <b-col lg="12" sm="12" class="my-2">
                  <b-form-textarea type="text" v-model="caseTrackMonth6" :disabled="setPage() === 'view' ? true : false"></b-form-textarea>
                </b-col>
                <br>
                <div class="row">
                  <b-col xl="2" lg="2" md="3" sm="4" style="display: flex; align-items: center; white-space: nowrap;">
                    <b-text class="mx-3" style="color: black;">1 year (52 weeks)</b-text>
                  </b-col>
                  <b-col xl="2" lg="2" md="2" sm="2" style="display: flex; align-items: center; white-space: nowrap;">
                    <v-select v-model="followTypeYear1" :disabled="setPage() === 'view' ? true : false" :options="followTypeOption" style="min-width: 200px; max-width: 200px;"></v-select>
                  </b-col>
                </div>
                <b-col lg="12" sm="12" class="my-2">
                  <b-form-textarea type="text" v-model="caseTrackYear1" :disabled="setPage() === 'view' ? true : false"></b-form-textarea>
                </b-col>
                <br>
                <div class="row">
                  <b-col xl="2" lg="2" md="3" sm="4" style="display: flex; align-items: center; white-space: nowrap;">
                    <b-text class="mx-3" style="color: black;">2 year (104 weeks)</b-text>
                  </b-col>
                  <b-col xl="2" lg="2" md="2" sm="2" style="display: flex; align-items: center; white-space: nowrap;">
                    <v-select v-model="followTypeYear2" :disabled="setPage() === 'view' ? true : false" :options="followTypeOption" style="min-width: 200px; max-width: 200px;"></v-select>
                  </b-col>
                </div>
                <b-col lg="12" sm="12" class="my-2">
                  <b-form-textarea type="text" v-model="caseTrackYear2" :disabled="setPage() === 'view' ? true : false"></b-form-textarea>
                </b-col>
                <br>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Axios from 'axios'
import Vue from 'vue'
import { configAPI } from '../../configBase'

export default {
  name: 'UiDataTable',
  async mounted () {
    this.id = this.$route.params.id || null
    await this.getServiceTypeDD()
    await this.getCaseTrackData(this.id)
    xray.index()
  },
  methods: {
    async getServiceTypeDD () {
      let ServiceTypeDDAPI = {
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/followType`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await Axios.request(ServiceTypeDDAPI)
        .then((response) => {
          this.followTypeOption = response.data.data?.map((el) => {
            return {
              value: el.id,
              label: el.name
            }
          })
        })
    },
    async getCaseTrackData (id) {
      let CaseTrackDataAPI = {
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/followUpCase/${id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await Axios.request(CaseTrackDataAPI)
        .then((response) => {
          // this.caseTrackService = response.data.data[0]?.st_name
          // this.caseTrackServiceId = response.data.data[0]?.st_id
          this.caseTrackDay0 = response.data.data[0]?.fcr_day0
          this.caseTrackDay1 = response.data.data[0]?.fcr_day1
          this.caseTrackDay3 = response.data.data[0]?.fcr_day3
          this.caseTrackWeek1 = response.data.data[0]?.fcr_week1
          this.caseTrackWeek2 = response.data.data[0]?.fcr_week2
          this.caseTrackWeek4 = response.data.data[0]?.fcr_week4
          this.caseTrackMonth3 = response.data.data[0]?.fcr_month3
          this.caseTrackMonth6 = response.data.data[0]?.fcr_month6
          this.caseTrackYear1 = response.data.data[0]?.fcr_year1
          this.caseTrackYear2 = response.data.data[0]?.fcr_year2
          this.followTypeDay0 = this.followTypeOption?.find((el) => el.value === response.data.data[0]?.fcr_ft_day0_id)
          this.followTypeDay1 = this.followTypeOption?.find((el) => el.value === response.data.data[0]?.fcr_ft_day1_id)
          this.followTypeDay3 = this.followTypeOption?.find((el) => el.value === response.data.data[0]?.fcr_ft_day3_id)
          this.followTypeWeek1 = this.followTypeOption?.find((el) => el.value === response.data.data[0]?.fcr_ft_week1_id)
          this.followTypeWeek2 = this.followTypeOption?.find((el) => el.value === response.data.data[0]?.fcr_ft_week2_id)
          this.followTypeWeek4 = this.followTypeOption?.find((el) => el.value === response.data.data[0]?.fcr_ft_week4_id)
          this.followTypeMonth3 = this.followTypeOption?.find((el) => el.value === response.data.data[0]?.fcr_ft_month3_id)
          this.followTypeMonth6 = this.followTypeOption?.find((el) => el.value === response.data.data[0]?.fcr_ft_month6_id)
          this.followTypeYear1 = this.followTypeOption?.find((el) => el.value === response.data.data[0]?.fcr_ft_year1_id)
          this.followTypeYear2 = this.followTypeOption?.find((el) => el.value === response.data.data[0]?.fcr_ft_year2_id)
        })
    },
    setPage () {
      return this.$route.params.setPage
    },
    onPagination (page) {
      this.currentPage = page
    },
    async handleClick (status) {
      if (status) {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะแก้ไขข้อมูลใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            let data = {
              // st_id: this.caseTrackServiceId,
              day0: this.caseTrackDay0,
              dey1: this.caseTrackDay1,
              day3: this.caseTrackDay3,
              week1: this.caseTrackWeek1,
              week3: this.caseTrackWeek2,
              week4: this.caseTrackWeek4,
              month3: this.caseTrackMonth3,
              month6: this.caseTrackMonth6,
              year1: this.caseTrackYear1,
              year2: this.caseTrackYear2,
              ft_day0_id: this.followTypeDay0?.value,
              ft_day1_id: this.followTypeDay1?.value,
              ft_day3_id: this.followTypeDay3?.value,
              ft_week1_id: this.followTypeWeek1?.value,
              ft_week3_id: this.followTypeWeek2?.value,
              ft_week4_id: this.followTypeWeek4?.value,
              ft_month3_id: this.followTypeMonth3?.value,
              ft_month6_id: this.followTypeMonth6?.value,
              ft_year1_id: this.followTypeYear1?.value,
              ft_year2_id: this.followTypeYear2?.value,
              updated_by: this.usrId
            }
            let updateCaseTrackAPI = {
              method: 'PUT',
              url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/followUpCase/${this.id}`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: data
            }
            await Axios.request(updateCaseTrackAPI)
              .then((response) => {
                if (response.data.status === true) {
                  Vue.swal({
                    title: '<span class="" style="font-size: 25px; color: black; text-align: center">แก้ไขข้อมูลสำเร็จ</span>',
                    icon: 'success',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    timer: 2000
                  }).then(() => {
                    router.go(-1)
                  })
                }
              })
          }
        })
      } else {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะยกเลิกใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            router.go(-1)
          }
        })
      }
    },
    cancelClick () {
      router.go(-1)
    }
  },
  data () {
    return {
      id: null,
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      usrId: JSON.parse(sessionStorage.getItem('user')).usr_id || null,
      caseTrackService: null,
      caseTrackServiceId: null,
      followTypeOption: [],
      followTypeDay0: null,
      caseTrackDay0: null,
      followTypeDay1: null,
      caseTrackDay1: null,
      followTypeDay3: null,
      caseTrackDay3: null,
      followTypeWeek1: null,
      caseTrackWeek1: null,
      followTypeWeek2: null,
      caseTrackWeek2: null,
      followTypeWeek4: null,
      caseTrackWeek4: null,
      followTypeMonth3: null,
      caseTrackMonth3: null,
      followTypeMonth6: null,
      caseTrackMonth6: null,
      followTypeYear1: null,
      caseTrackYear1: null,
      followTypeYear2: null,
      caseTrackYear2: null,
      currentPage: 1,
      perPage: 10
    }
  }
}
</script>
