<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" lg="3">
        <b-row>
          <b-col cols="12" sm="6" lg="12">
            <iq-card body-class="text-center" style="min-height: 400px;">
              <template v-if="mode === 'add'" v-slot:headerTitle>
                <b-row >
                  <b-col sm="12" md class="mx-2 my-2" style="padding: 0px;">
                    <div class="iq-email-search d-flex">
                      <div style="width: 100%;">
                        <div class="form-group mb-0">
                          <!-- <input class="form-control" id="exampleInputEmail1" placeholder="Search Phone" > -->
                          <the-mask mask="###-###-####" class="form-control" v-model="phoneSearch" @keydown.native="enterSearch" id="Phone" type="tel" style="width: 100%;" :masked="true" placeholder="xxx-xxx-xxxx"></the-mask>
                          <a class="search-link" @click="searchPhoneCustomer(true)"><i class="ri-search-line" /></a>
                          <span style="color: red; font-size: .7rem;" v-if="canSearch" >กรุณากรอกให้ครบ 10 หลัก</span>
                          <!-- <v-select v-model="searchData" placeholder="Select customer" :options="customerDD" :get-option-label="(option) => option.fullname">
                            <template #option="item">
                              <span style="font-size: 1rem;">{{ ` ${item.fullname}` }}</span>
                              <br />
                              <span style="opacity: 0.9;">{{ ` ${item.hn_no}` }}</span>
                              <hr class="mb-0" />
                            </template>
                          </v-select> -->
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </template>
              <template v-slot:body>
                <div v-if="!searchData" class="d-flex" style="justify-content: center; align-items: center; height: 300px;">
                  <h3>ไม่พบข้อมูล Customer</h3>
                </div>
                <div v-else>
                  <div class="doc-profile">
                    <img class="rounded-circle img-fluid avatar-80" style="object-fit: cover;" :src="getFullImagePath(searchData.image)" alt="profile">
                  </div>
                  <div class="iq-doc-info mt-3">
                    <h4>{{searchData?.fullname || ''}} ({{searchData?.nickname || ''}})</h4>
                    <p class="mb-0">ID card : {{searchData?.id_card || ''}}</p>
                    <p class="mb-0" style="color: #089bab;">{{searchData?.hn_no || ''}}</p>
                  </div>
                  <div class="iq-doc-social-info mt-2 mb-2">
                    <ul class="m-0 p-0" style="display: flex;
                    flex-direction: column;
                    align-items: center;">
                      <li style="display: flex; align-items: center;">
                        <p class="mb-0">Mobile : {{searchData?.tel || ''}}</p>
                      </li>
                      <li style="display: flex; align-items: center;">
                        <p class="mb-0">{{ getFormatBirthday(searchData?.birthday) }}</p>
                        <!-- 17 ก.ย. 2542 (24Y 9D) -->
                      </li>
                      <li style="display: flex; align-items: center;">
                        <p class="mb-0">เพศ : {{searchData?.gender}} | กรุ๊ปเลือด : {{searchData?.blood}}</p>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <button @click="viewProfile('view', searchData?.id)" class="btn btn-primary">View Profile</button>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col cols="12" sm="6" lg="12">
            <iq-card body-class="text-center" header-class="text-center">
              <template v-slot:headerTitle>
                <div class="iq-doc-info mt-3">
                  <h4>ประวัติการรักษา</h4>
                </div>
              </template>
              <template v-slot:body>
                <div class="iq-doc-social-info mt-0 mb-3">
                  <ul class="m-0 p-0" style="display: flex;
                  flex-direction: column;
                  align-items: center;">
                  <div v-for="(item, index) in historyTreat" :key="index">
                    <!-- {{ dataForSave.OPD_no }}
                    {{ item.hn_no }} -->
                    <a
                      @click="viewPatientById(item.id, (item.hn_no === dataForSave.OPD_no), item.is_close)"
                      :style="'cursor: ' + (item.hn_no === dataForSave.OPD_no ? 'inherit;' : 'pointer;')"
                    >
                      <li style="display: flex; align-items: center;">
                        <p class="mb-0" :style="`color: ${(item.hn_no === dataForSave.OPD_no ? 'black;' : '#089bab;')} text-decoration: underline solid 1px ${(item.hn_no === dataForSave.OPD_no ? 'black;' : '#089bab;')} `">OPD :
                          {{ item?.hn_no }}</p>
                      </li>
                    </a>
                  </div>
                    <!-- <a href="../Patient/view/1">
                      <li style="display: flex; align-items: center;">
                        <p class="mb-0" style="color: #089bab; text-decoration: underline solid 1px #089bab;">OPD :
                          HNxxxxxxxx-xxxxxx</p>
                      </li>
                    </a> -->
                    <!-- <a href="../Patient/view/1">
                      <li style="display: flex; align-items: center;">
                        <p class="mb-0" style="color: #089bab; text-decoration: underline solid 1px #089bab;">OPD :
                          HNxxxxxxxx-xxxxxx</p>
                      </li>
                    </a> -->
                  </ul>
                </div>
                <div>
                  <button @click="historyService('view', searchData.id)" class="btn btn-primary">ข้อมูลทั้งหมด</button>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" lg="9">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span v-if="mode === 'add'" style="font-size: 25px; color: black;">ข้อมูลการใช้บริการ
                  (เพิ่ม)</span>
                <span v-else-if="mode === 'edit'" style="font-size: 25px; color: black;">ข้อมูลการใช้บริการ (แก้ไข)</span>
                <span v-else style="font-size: 25px; color: black;">ข้อมูลการใช้บริการ (ดูรายละเอียด)</span>
              </div>
              <div v-if="mode === 'add' || mode === 'edit'" class="col-12 col-md-auto">
                <div class="row">
                  <div class="col-6 pl-3 pr-2 my-1 my-sm-0">
                    <b-button type="submit" variant="primary" @click="clickSave"
                      class="w-100 btn-min-size"><i class="save-data_ct"/>บันทึก</b-button>
                  </div>
                  <div class="col-6 pl-2 pr-3 my-1 my-sm-0">
                    <b-button type="submit" variant="none" @click="backPage"
                      class="iq-bg-danger w-100 btn-min-size btn-cancel-border">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
              <div v-else class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="submit" variant="none" @click="backPage"
                  class="iq-bg-danger w-100 btn-min-size btn-cancel-border">ยกเลิก</b-button>
              </div>
            </div>
            <div class="row align-items-center pb-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">OPD : {{ dataForSave.OPD_no || 'AutoGen' }}</span>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row class="mb-3"
                style="flex-wrap: nowrap; overflow: overlay; border-bottom: 1px solid rgba(0, 0, 0, 0.1);">
                <b-text class="mr-4 px-3 pb-2 unselectable" style="white-space: nowrap; cursor: pointer;"
                  :style="{ color: activeTab === 'tab1' ? 'rgb(8, 159, 175)' : 'black', borderBottom: activeTab === 'tab1' ? 'rgb(8, 159, 175) solid 2px' : '' }"
                  @click="activeTab = 'tab1'">ข้อมูลพื้นฐานก่อนตรวจ <span :hidden="dataForSave.weight && dataForSave.height" style="color: red;">*</span></b-text>
                <b-text class="mr-4 px-3 pb-2 unselectable" style="white-space: nowrap; cursor: pointer;"
                  :style="{ color: activeTab === 'tab2' ? 'rgb(8, 159, 175)' : 'black', borderBottom: activeTab === 'tab2' ? 'rgb(8, 159, 175) solid 2px' : '' }"
                  @click="activeTab = 'tab2'">หัตถการ / บริการ</b-text>
                <b-text class="mr-4 px-3 pb-2 unselectable" style="white-space: nowrap; cursor: pointer;"
                  :style="{ color: activeTab === 'tab3' ? 'rgb(8, 159, 175)' : 'black', borderBottom: activeTab === 'tab3' ? 'rgb(8, 159, 175) solid 2px' : '' }"
                  @click="activeTab = 'tab3'">การรักษา <span :hidden="dataForSave.doctor && dataForSave.assistDoctor" style="color: red;">*</span></b-text>
              </b-row><!-- , refAddImageClick() -->

              <div v-if="activeTab === 'tab1'">
                <b-row>
                  <b-col cols="12" sm="6">
                    <b-form-group label="" label-for="exampleInputText1">
                      <label>น้ำหนัก (กิโลกรัม) <span :hidden="dataForSave.weight" style="color: red;">*</span></label>
                      <CurrencyInputCom id="exampleInputText1" v-model="dataForSave.weight" :disabled="disableCheck" :options="optionCurrency"></CurrencyInputCom>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="6">
                    <b-form-group label="" label-for="exampleInputText1">
                      <label>ส่วนสูง (เซนติเมตร) <span :hidden="dataForSave.height" style="color: red;">*</span></label>
                      <CurrencyInputCom id="exampleInputText1" v-model="dataForSave.height" :disabled="disableCheck" :options="optionCurrency"></CurrencyInputCom>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" sm="6">
                    <b-form-group label="อุณหภูมิร่างกาย (องศา)" label-for="exampleInputText1">
                      <CurrencyInputCom id="exampleInputText1" v-model="dataForSave.body_temp" :disabled="disableCheck" :options="optionCurrency"></CurrencyInputCom>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="6">
                    <b-form-group label="ความดันโลหิต (มิลลิเมตรปรอท)" label-for="exampleInputText1">
                      <!-- <CurrencyInputCom id="exampleInputText1" v-model="dataForSave.blood_pressure" :disabled="disableCheck" :options="optionCurrencyNoDecimal"></CurrencyInputCom> -->
                      <b-form-input :disabled="disableCheck" v-model="dataForSave.blood_pressure" type="text" placeholder=""></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="6">
                    <b-form-group label="อัตราชีพจร (ครั้งต่อนาที)" label-for="exampleInputText1">
                      <CurrencyInputCom id="exampleInputText1" v-model="dataForSave.pulse_rate" :disabled="disableCheck" :options="optionCurrencyNoDecimal"></CurrencyInputCom>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="6">
                    <b-form-group label="อัตราการหายใจ (ครั้งต่อนาที)" label-for="exampleInputText1">
                      <CurrencyInputCom id="exampleInputText1" v-model="dataForSave.breathing_rate" :disabled="disableCheck" :options="optionCurrencyNoDecimal"></CurrencyInputCom>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" sm="6">
                    <b-form-group label="ไขมัน (%)" label-for="exampleInputText1">
                      <CurrencyInputCom id="exampleInputText1" v-model="dataForSave.fat" :disabled="disableCheck" :isLimit="true" :options="optionCurrency"></CurrencyInputCom>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="6">
                    <b-form-group label="กล้ามเนื้อ (%)" label-for="exampleInputText1">
                      <CurrencyInputCom id="exampleInputText1" v-model="dataForSave.muscle" :disabled="disableCheck" :isLimit="true" :options="optionCurrency"></CurrencyInputCom>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <div v-if="activeTab === 'tab2'">
                <b-row>
                  <b-col cols="12" md="8">
                    <b-row style="align-items: baseline;">
                      <b-col cols="auto">
                        <span style="font-size: 16px; color: black; text-decoration: underline solid 1px #000000;">หัตถการ
                          / บริการ คงค้าง</span>
                      </b-col>
                      <b-col cols="12" sm="auto">
                        <b-row>
                          <b-col cols="6" sm="auto" class="pl-3 pr-2 my-1 my-sm-0">
                            <b-button type="submit" :disabled="disableCheck" :variant="statusButton1 ? 'none' : 'primary'" @click="ChangeStatus1"
                              class="w-100" :class="statusButton1 ? 'iq-bg-primary' : ''">ใบขาย</b-button>
                          </b-col>
                          <b-col cols="6" sm="auto" class="pl-2 pr-3 my-1 my-sm-0">
                            <b-button type="submit" :disabled="disableCheck || mode === 'edit'" :variant="statusButton2 ? 'none' : 'primary'" @click="ChangeStatus2"
                              class="w-100" :class="statusButton2 ? 'iq-bg-primary' : ''">ไม่มีใบขาย</b-button>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row v-if="statusButton1">
                      <b-col cols>
                        <div style="white-space: nowrap; overflow: overlay; max-height: 164px;">
                          <table>
                            <tbody>
                              <tr v-for="(item, index) in serviceBillList" :key="index">
                                <td style="padding: 10px 20px 10px 0px;"><span>{{ item.no }}</span></td>
                                <td style="padding: 10px 20px;"><span>{{ item.date }}</span></td>
                                <td style="padding: 10px 20px;"><span>{{ item.name }}</span></td>
                                <td style="padding: 10px 0px 10px 20px;">
                                  <b-button type="submit" :disabled="disableCheck || item.isDisable" :variant="dataSelect.includes(item.id) ? 'none' : 'primary'"
                                    :class="dataSelect.includes(item.id) ? 'iq-bg-primary' : ''"
                                    @click="SelectBill(item.id, 'tab1', index)">เลือก</b-button>
                                </td>
                              </tr>
                              <!-- <tr>
                                <td style="padding: 10px 20px 10px 0px;"><span>000002</span></td>
                                <td style="padding: 10px 20px;"><span>3/7/23</span></td>
                                <td style="padding: 10px 20px;"><span>ฉีดโบท็อก</span></td>
                                <td style="padding: 10px 0px 10px 20px;">
                                  <b-button type="submit" :variant="dataSelect.includes(2) ? 'none' : 'primary'"
                                    :class="dataSelect.includes(2) ? 'iq-bg-primary' : ''"
                                    @click="SelectBill(2, 'tab2')">เลือก</b-button>
                                </td>
                              </tr> -->
                            </tbody>
                          </table>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" md="4">
                    <span style="font-size: 16px; color: black;">แพ้ยา / แพ้อาหาร</span>
                    <span style="font-size: 14px; color: black; display: block;">{{ searchData.drug_allergy || '-' }}</span>
                    <span style="font-size: 14px; color: black; display: block;">{{ searchData.food_allergy || '-' }}</span>
                  </b-col>
                </b-row>
                <b-row v-if="dataSelect.length !== 0 && statusButton1" class="my-3"
                  style="flex-wrap: nowrap; border-bottom: 1px solid rgba(0, 0, 0, 0.1);">
                  <div v-for="(item, index) in serviceBillList" :key="index">
                    <b-text v-if="dataSelect.includes(item.id)" class="mr-4 px-3 pb-1 unselectable"
                      style="white-space: nowrap; cursor: pointer;"
                      :style="{ color: item.check ? 'rgb(8, 159, 175)' : 'black', borderBottom: item.check ? 'rgb(8, 159, 175) solid 2px' : '' }"
                      @click="onChangeActiveBillList(item.id)">{{ item.no }}</b-text>

                  </div>
                </b-row>

                <div v-for="(item, index) in serviceBillList" :key="index">
                  <b-row v-if="dataSelect.includes(item.id) && statusButton1 && item.check" style="margin-bottom: 0.5rem; align-items: baseline; margin-top: 2rem">
                      <b-form-radio class="mx-2 mb-1" style="white-space: nowrap;" v-model="item.is_prodcut"
                        :value="true">สินค้า</b-form-radio>
                      <b-form-radio class="mx-2 mb-1" style="white-space: nowrap;" v-model="item.is_prodcut"
                        :value="false">บริการ</b-form-radio>
                    </b-row>
                </div>

                <div v-for="(item, index) in serviceBillList" :key="index">
                  <div v-if="dataSelect.includes(item.id) && statusButton1 && item.check">
                      <div v-if="item.is_prodcut">
                        <b-row>
                          <b-col cols="12" class="table-responsive" style="padding: 0px;">
                            <b-table bordered hover :items="rows1" :fields="columns1" :current-page="currentPage"
                              :per-page="perPage" @pagination="onPagination" :tbody-tr-class="rowClass"
                              thead-class="table-header-color">
                              <template v-slot:cell(variable5)="data">
                                <b-img
                                  v-if="data.item.variable5"

                                  :src="getFullImagePath(data.item.variable5)"
                                  style="height: 100px; width:100px;"></b-img>
                                <b-img v-else :src="require('../../assets/images/default.jpg')"
                                  style="height: 100px; width: 100px;"></b-img>
                              </template>
                              <template v-slot:cell(input_used)="data">
                                <CurrencyInputCom v-if="data.item.editable" id="input_used" v-model="data.item.input_used_sup" :isLimit="true" :valueLimit="data.item.balance" :options="optionCurrencyNoDecimal"></CurrencyInputCom>
                                <label v-else>{{ data.item.input_used_sup }}</label>
                              </template>
                              <template v-slot:cell(variable8)="data" v-if="mode !== 'view'">
                                <div v-if="!data.item.editable" style="display: flex; justify-content: center;">
                                  <b-button variant=" iq-bg-success mx-1" size="sm" id="show-modal"
                                    @click="data.item.editable = true"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                  <b-button variant=" iq-bg-danger" size="sm" @click="remove1(data.item)"><i
                                      class="ri-delete-bin-line m-0"></i></b-button>
                                </div>
                                <div style="display: flex;" v-else>
                                  <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submit(data.item)">บันทึก</b-button>
                                  <b-button variant=" iq-bg-danger mr-1 mb-1" size="sm" @click="redo(data.item)">ยกเลิก</b-button>
                                </div>
                              </template>
                              <!-- <template v-slot:cell(variable8)="data">
                                <div style="display: flex; justify-content: center;">
                                  <b-button variant=" iq-bg-success mx-1" size="sm" id="show-modal"
                                    @click="ClickEditModal(data.item, item.is_prodcut)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                  <b-button variant=" iq-bg-danger" size="sm" @click="remove1(data.item)"><i
                                      class="ri-delete-bin-line m-0"></i></b-button>
                                </div>
                              </template> -->
                            </b-table>
                          </b-col>
                        </b-row>
                        <b-row style="display: flex; justify-content: center;">
                          <b-pagination v-model="currentPage" :total-rows="rows1.length" :per-page="perPage" />
                          <!-- <b-pagination v-if="activeTab === 'tab2'" v-model="currentPage" :total-rows="rows2.length" :per-page="perPage" /> -->
                        </b-row>
                      </div>

                      <div v-else>
                        <b-row>
                          <b-col cols="12" class="table-responsive" style="padding: 0px;">
                            <b-table bordered hover :items="rows2" :fields="columns2" :current-page="currentPage"
                              :per-page="perPage" @pagination="onPagination" :tbody-tr-class="rowClass"
                              thead-class="table-header-color">
                              <!-- <template v-slot:cell(variable7)="data">
                                <div style="display: flex; justify-content: center;">
                                  <b-button variant=" iq-bg-success mx-1" size="sm" id="show-modal"
                                  @click="ClickEditModal(data.item, item.is_prodcut)" ><i class="ri-ball-pen-fill m-0"></i></b-button>
                                  <b-button variant=" iq-bg-danger" size="sm" @click="remove2(data.item)"><i
                                      class="ri-delete-bin-line m-0"></i></b-button>
                                </div>
                              </template> -->
                              <template v-slot:cell(input_used)="data">
                                <CurrencyInputCom v-if="data.item.editable" id="input_used" v-model="data.item.input_used_sup" :isLimit="true" :valueLimit="data.item.balance" :options="optionCurrencyNoDecimal"></CurrencyInputCom>
                                <label v-else>{{ data.item.input_used_sup }}</label>
                              </template>
                              <template v-slot:cell(variable8)="data" v-if="mode !== 'view'">
                                <div v-if="!data.item.editable" style="display: flex; justify-content: center;">
                                  <b-button variant=" iq-bg-success mx-1" size="sm" id="show-modal"
                                    @click="data.item.editable = true"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                  <b-button variant=" iq-bg-danger" size="sm" @click="remove2(data.item)"><i
                                      class="ri-delete-bin-line m-0"></i></b-button>
                                </div>
                                <div style="display: flex; justify-content: center;" v-else>
                                  <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="submit(data.item)">บันทึก</b-button>
                                  <b-button variant=" iq-bg-danger mr-1 mb-1" size="sm" @click="redo(data.item)">ยกเลิก</b-button>
                                </div>
                                <!-- <div style="display: flex; justify-content: center;">
                                  <b-button variant=" iq-bg-success mx-1" size="sm" id="show-modal"
                                    @click="data.item.editable = true"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                </div> -->
                              </template>
                            </b-table>
                          </b-col>
                        </b-row>
                        <b-row style="display: flex; justify-content: center;">
                          <b-pagination v-model="currentPage" :total-rows="rows2.length" :per-page="perPage" />
                          <!-- <b-pagination v-if="activeTab === 'tab2'" v-model="currentPage" :total-rows="rows2.length" :per-page="perPage" /> -->
                        </b-row>
                      </div>
                    </div>
                </div>
                <!-- <div v-if="dataSelect.length !== 0 && statusButton1 && activeTab2 === 'tab2'">
                  <b-row style="margin-bottom: 0.5rem; align-items: baseline;">
                    <b-form-radio class="mx-2 mb-1" style="white-space: nowrap;" v-model="mode2"
                      :value="true">สินค้า</b-form-radio>
                    <b-form-radio class="mx-2 mb-1" style="white-space: nowrap;" v-model="mode2"
                      :value="false">บริการ</b-form-radio>
                    <b-col v-if="mode2" cols="12" sm="auto" class="pl-0 pl-sm-3 pr-0 my-1 my-sm-0">
                      <b-button type="submit" variant="primary" @click="modalAdd1 = true"
                        class="w-100 btn-min-size"><i class="circle-plus_ct"/>เพิ่ม</b-button>
                    </b-col>
                    <b-col v-else cols="12" sm="auto" class="pl-0 pl-sm-3 pr-0 my-1 my-sm-0">
                      <b-button type="submit" variant="primary" @click="modalAdd2 = true"
                        class="w-100 btn-min-size"><i class="circle-plus_ct"/>เพิ่ม</b-button>
                    </b-col>
                  </b-row>

                  <div v-if="mode2">
                    <b-row>
                      <b-col cols="12" class="table-responsive" style="padding: 0px;">
                        <b-table bordered hover :items="rows3" :fields="columns1" :current-page="currentPage"
                          :per-page="perPage" @pagination="onPagination" :tbody-tr-class="rowClass"
                          thead-class="table-header-color">
                          <template v-slot:cell(variable5)="data">
                            <b-img
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1200px-QR_code_for_mobile_English_Wikipedia.svg.png"
                              style="height: 100px; width:100px;"></b-img>
                          </template>
                          <template v-slot:cell(variable8)="data">
                            <div style="display: flex; justify-content: center;">
                              <b-button variant=" iq-bg-success mx-1" size="sm" id="show-modal"
                                @click="() => {modalMode = true; modalAdd1 = true;}"><i class="ri-ball-pen-fill m-0"></i></b-button>
                              <b-button variant=" iq-bg-danger" size="sm" @click="remove3(data.item)"><i
                                  class="ri-delete-bin-line m-0"></i></b-button>
                            </div>
                          </template>
                        </b-table>
                      </b-col>
                    </b-row>
                    <b-row style="display: flex; justify-content: center;">
                      <b-pagination v-model="currentPage" :total-rows="rows3.length" :per-page="perPage" />
                    </b-row>
                  </div>

                  <div v-else>
                    <b-row>
                      <b-col cols="12" class="table-responsive" style="padding: 0px;">
                        <b-table bordered hover :items="rows4" :fields="columns2" :current-page="currentPage"
                          :per-page="perPage" @pagination="onPagination" :tbody-tr-class="rowClass"
                          thead-class="table-header-color">
                          <template v-slot:cell(variable7)="data">
                            <div style="display: flex; justify-content: center;">
                              <b-button variant=" iq-bg-success mx-1" size="sm" id="show-modal"
                                @click="() => {modalMode = true; modalAdd2 = true;}"><i class="ri-ball-pen-fill m-0"></i></b-button>
                              <b-button variant=" iq-bg-danger" size="sm" @click="remove4(data.item)"><i
                                  class="ri-delete-bin-line m-0"></i></b-button>
                            </div>
                          </template>
                        </b-table>
                      </b-col>
                    </b-row>
                    <b-row style="display: flex; justify-content: center;">
                      <b-pagination v-model="currentPage" :total-rows="rows4.length" :per-page="perPage" />
                    </b-row>
                  </div>
                </div> -->
              </div>

              <div v-if="activeTab === 'tab3'">
                <b-row>
                  <b-col cols="12" md="7">
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label-for="exampleFormControlSelect1" label="">
                          <label>แพทย์ <span :hidden="dataForSave.doctor" style="color: red;">*</span></label>
                          <v-select v-model="dataForSave.doctor" :options="doctorDD" :disabled="disableCheck"></v-select>
                          <!-- <b-form-select plain size="lg" id="exampleFormControlSelect1" :disabled="disableCheck">
                            <template>
                            </template>
                          </b-form-select> -->
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label-for="exampleFormControlSelect1" label="">
                          <label>ผู้ช่วยแพทย์ <span :hidden="dataForSave.assistDoctor" style="color: red;">*</span></label>
                          <v-select v-model="dataForSave.assistDoctor" :options="assistDoctorDD" :disabled="disableCheck"></v-select>
                          <!-- <b-form-select plain size="lg" id="exampleFormControlSelect1" :disabled="disableCheck">
                            <template>
                            </template>
                          </b-form-select> -->
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row v-if="dataForSave.assistDoctor?.value === 'other'">
                      <b-col cols="12">
                        <b-form-group label="" label-for="exampleInputText123">
                          <label>ชื่อ - นามสกุล ผู้ช่วยแพทย์ <span :hidden="inputAssistDoctor !== ''" style="color: red;">*</span></label>
                          <b-input id="exampleInputText123" v-model="inputAssistDoctor" :disabled="disableCheck" ></b-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label="Note" label-for="exampleInputText1">
                          <b-form-textarea v-model="dataForSave.note" id="exampleInputText1" :disabled="disableCheck"
                            style="line-height: 30px;"></b-form-textarea>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label-for="imageInput1" label="รูปภาพการรักษา">
                          <b-form-file
                            class="custom-file-style"
                            id="imageInput1"
                            :accept="allowedTypes2"
                            :disabled="disableCheck"
                            :placeholder="dataForSave.img_treat_name ? dataForSave.img_treat_name : 'Choose a file'"
                            @change="handleImageUpload($event, 'treat')"
                          ></b-form-file>
                          <!-- products.imageName ? products.imageName : -->
                          <!-- {{ dataForSave.img_treat_path2?.toString() || '-' }} -->
                          <div v-if="dataForSave.img_treat_path2 || dataForSave.img_treat_path">
                            <div style="text-align: center; margin-top: 2rem">
                              <img
                              v-if="dataForSave.img_treat_path2 && !dataForSave.img_treat_path"
                              :src="dataForSave.img_treat_path2" alt="Uploaded Barcode" width="250" height="250"/>
                              <img
                              v-else-if="dataForSave.img_treat_path !== null"
                              :src="getFullImagePath(dataForSave.img_treat_path)" alt="Uploaded Image" width="250" height="250"/>
                            </div>
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label-for="imageInput2" label="รูปอื่นๆ">
                          <b-form-file
                            class="custom-file-style"
                            id="imageInput2"
                            :accept="allowedTypes2"
                            :disabled="disableCheck"
                            :placeholder="dataForSave.img_other_name ? dataForSave.img_other_name : 'Choose a file'"
                            @change="handleImageUpload($event, 'other')"
                          ></b-form-file>
                          <!-- products.imageName ? products.imageName : -->
                          <div v-if="dataForSave.img_other_path2 || dataForSave.img_other_path">
                            <div style="text-align: center; margin-top: 2rem">
                              <img
                              v-if="dataForSave.img_other_path2 && !dataForSave.img_other_path"
                              :src="dataForSave.img_other_path2" alt="Uploaded Barcode" width="250" height="250"/>
                              <img
                              v-else-if="dataForSave.img_other_path !== null"
                              :src="getFullImagePath(dataForSave.img_other_path)" alt="Uploaded Image" width="250" height="250"/>
                            </div>
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label-for="imageInput3" label="Upload file">
                          <!-- <b-form-file class="custom-file-style" id="imageInput3" multiple :accept="allowedTypes1"
                            :disabled="disableCheck"></b-form-file> -->
                            <b-form-file
                            class="custom-file-style"
                            id="imageInput3"
                            :accept="allowedTypes1"
                            :disabled="disableCheck"
                            :placeholder="dataForSave.img_file_name ? dataForSave.img_file_name : 'Choose a file'"
                            @change="handleImageUpload($event, 'file')"
                          ></b-form-file>
                        </b-form-group>
                        <button :hidden="disableCheck || !dataForSave.img_file_path" @click="downloadFile(dataForSave)" class="btn btn-info mb-2">Download File</button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label-for="datepicker-custom" label="ระยะเวลาติดตามผล">
                          <div style="position: relative;">
                            <date-picker id="datepicker-custom" v-model="dataForSave.rangeFollow" :disabled="disableCheck" :config="options" />
                            <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker"
                              :disabled="disableCheck" style="cursor: pointer;" />
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-button type="submit" :variant="dataForSave.is_close ? 'danger' : 'success'" :disabled="disableCheck" @click="closeService" v-model="dataForSave.is_close" class="w-100"
                          style="margin-bottom: 1rem;">{{ dataForSave.is_close ? 'ปิดการให้บริการแล้ว' : 'ปิดการให้บริการ' }}</b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" md="5">
                    <b-form-group label-for="canvas-custom1" label="">
                      <label>ลายเซ็น <span v-if="(yetDrawSign)" style="color: red;">*</span></label>
                      <br>
                      <!-- <b-button :variant="eraser1 ? 'primary' : 'none'" class="mr-1 mb-2"
                        :class="eraser1 ? '' : 'iq-bg-primary'" size="sm" @click="ChangeDraw1"><i
                          class="ri-ball-pen-fill m-0" /></b-button>
                      <b-button :variant="eraser1 ? 'none' : 'primary'" class="ml-1 mb-2"
                        :class="eraser1 ? 'iq-bg-primary' : ''" size="sm" @click="ChangeEraser1"><i
                          class="ri-eraser-fill m-0" /></b-button> -->
                      <b-button variant="primary" class="mb-2 mr-2" size="sm" style="border-radius: 10px" @click="isEraserSign = !isEraserSign" :disabled="disableCheck">{{ isEraserSign ? 'Pen' : 'Eraser' }}</b-button>
                      <b-button variant="primary" class="mb-2 mr-2" size="sm" style="border-radius: 10px" @click="undoLineSign" :disabled="drawLinesSign.length === 0 || disableCheck">Undo</b-button>
                      <b-button variant="danger" class="mb-2" size="sm" style="border-radius: 10px" @click="resetLineSign" :disabled="drawLinesSign.length === 0 || disableCheck">Reset</b-button>
                      <div id="canvas-custom1" style="overflow: overlay;">
                        <div>
                          <v-stage
                            ref="stageSign"
                            :config="{ width: 250, height: 150 }"
                            @mousedown="startDrawingSign"
                            @mousemove="drawSign"
                            @mouseup="endDrawingSign"
                            @touchstart="startDrawingSign"
                            @touchmove="drawSign"
                            @touchend="endDrawingSign"
                          >
                            <v-layer ref="layerSign">
                              <v-line
                                v-for="(line, index) in drawLinesSign"
                                :key="index"
                                :config="line.config"
                              />
                            </v-layer>
                            <v-layer ref="borderLayer">
                              <v-rect :config="{ x: 0, y: 0, width: 250, height: 150, stroke: 'black', strokeWidth: 3 }" />
                            </v-layer>
                          </v-stage>
                        </div>
                        <!-- <vue-drawing-canvas @mousedown.native="getPositionMouseDown($event)" @mouseup.native="getPositionMouseUp($event)" canvasId="VueCanvasDrawing1" ref="VueCanvasDrawing1" :image="initialImage1"
                          :width="250" :height="150" :lineJoin="'round'" :eraser="eraser1" :lineWidth="eraser1 ? 10 : 1"
                          :initialImage="initialImage1" :styles="{ border: '1px solid black' }" /> -->
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label-for="canvas-custom2" label="พื้นที่สำหรับเขียนเพิ่มเติม">
                      <b-button :variant="addImageObj.man_pic ? 'none' : 'primary'" class="ml-1 mb-2"
                        :class="addImageObj.man_pic ? 'iq-bg-primary' : ''" size="sm" @click="ChangeBack(false, true, 'man_pic')"><i
                          class="ri-men-line m-0" /></b-button>
                      <b-button :variant="addImageObj.wom_pic ? 'none' : 'primary'" class="ml-1 mb-2"
                        :class="addImageObj.wom_pic ? 'iq-bg-primary' : ''" size="sm" @click="ChangeBack(false, true, 'wom_pic')"><i
                          class="ri-women-line m-0" /></b-button>
                      <b-button :variant="addImageObj.lip_pic ? 'none' : 'primary'" class="ml-1 mb-2"
                        :class="addImageObj.lip_pic ? 'iq-bg-primary' : ''" size="sm" @click="ChangeBack(false, true, 'lip_pic')"><i
                          class="ri-speak-line m-0" /></b-button>
                      <div class="canvas-display-serviceusage" style="z-index: 1001; overflow-x: scroll; height: 700px; display: flex; align-items: center;">
                        <v-stage
                          ref="stage_konva_view"
                          :config="configKonva"
                        >
                          <v-layer ref="backgroundLayer">
                            <v-image :config="{ preventDefault: false, x: 0, y: 0, image: backgroundImageKonva, width: 800, height: 600 }" />
                          </v-layer>
                          <v-layer ref="layer_konva_view">
                            <v-line
                              v-for="(line, index) in addImageObj[`${addImageKey}_draw`]"
                              :key="index"
                              :config="{ preventDefault: false, ...line.config}"
                            />
                          </v-layer>
                          <v-layer ref="borderLayer">
                            <v-rect :config="{ preventDefault: false, x: 0, y: 0, width: 800, height: 600, stroke: 'black', strokeWidth: 5 }" />
                          </v-layer>
                        </v-stage>
                        <!-- <div id="canvas-custom2" v-if="showCanvas2">
                          <div v-if="canvasDetails[addImageKey].is_show">
                          <vue-drawing-canvas class="unselectable" id="VueCanvasDrawing_man_pic" canvasId="VueCanvasDrawing_man_pic" ref="VueCanvasDrawing_man_pic" :image="canvasDetails[addImageKey].initial_img"
                            :width="canvasModalWidth" :height="canvasModalWidth - 200" :lineJoin="'round'" :eraser="eraser2" :lineWidth="eraser2 ? 10 : 1"
                            :initialImage="canvasDetails[addImageKey].initial_img" :backgroundImage="canvasDetails[addImageKey].bg_img"
                            :styles="{ border: '1px solid black', display: addImageKey === 'man_pic' ? undefined : 'none'/* , pointerEvents: 'none' */ }"
                            :lock="true"
                          />
                          </div>
                          <div v-if="canvasDetails[addImageKey].is_show">
                          <vue-drawing-canvas class="unselectable" id="VueCanvasDrawing_wom_pic" canvasId="VueCanvasDrawing_wom_pic" ref="VueCanvasDrawing_wom_pic" :image="canvasDetails[addImageKey].initial_img"
                            :width="canvasModalWidth" :height="canvasModalWidth - 200.1" :lineJoin="'round'" :eraser="eraser2" :lineWidth="eraser2 ? 10 : 1"
                            :initialImage="canvasDetails[addImageKey].initial_img" :backgroundImage="canvasDetails[addImageKey].bg_img"
                            :styles="{ border: '1px solid black', display: addImageKey === 'wom_pic' ? undefined : 'none'/* , pointerEvents: 'none' */ }"
                            :lock="true"
                          />
                          </div>
                          <div v-if="canvasDetails[addImageKey].is_show">
                            <vue-drawing-canvas class="unselectable" id="VueCanvasDrawing_lip_pic" canvasId="VueCanvasDrawing_lip_pic" ref="VueCanvasDrawing_lip_pic" :image="canvasDetails[addImageKey].initial_img"
                            :width="canvasModalWidth" :height="canvasModalWidth - 200.01" :lineJoin="'round'" :eraser="eraser2" :lineWidth="eraser2 ? 10 : 1"
                            :initialImage="canvasDetails[addImageKey].initial_img" :backgroundImage="canvasDetails[addImageKey].bg_img"
                            :styles="{ border: '1px solid black', display: addImageKey === 'lip_pic' ? undefined : 'none'/* , pointerEvents: 'none' */ }"
                            :lock="true"
                          />
                          </div>
                        </div> -->
                      </div>
                      </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal modal-class="full-width-modal" class="full-width-modal" v-model="showModalCanvas" :title="`Diagnose Symptoms ${addImageKey === 'man_pic' ? 'Male' : addImageKey === 'wom_pic' ? 'Female' : 'Lip'}`" cancel-title="ยกเลิก" ok-title="เพิ่ม" @ok="onSubmitCanvasModal" @close="onCloseCanvasModal" size="xl" :no-close-on-esc="true" :no-close-on-backdrop="true">
      <!-- <productSelectModalQuo ref="productSelectedCom" :mainProductSelected="rowSelected.data" :del-list="JSON.parse(JSON.stringify(delList))"/> -->
       <!-- <label>{{ addImageKey }}</label> -->
      <!-- <b-form-group label-for="canvas-custom-modal" label="เครื่องมือ">
        <b-button :variant="eraser2 ? 'primary' : 'none'" class="mr-1 mb-2"
          :class="eraser2 ? '' : 'iq-bg-primary'" size="sm" @click="ChangeDraw2"><i
            class="ri-ball-pen-fill m-0" /></b-button>
        <b-button :variant="eraser2 ? 'none' : 'primary'" class="ml-1 mr-1 mb-2"
          :class="eraser2 ? 'iq-bg-primary' : ''" size="sm" @click="ChangeEraser2"><i
            class="ri-eraser-fill m-0" /></b-button>
      </b-form-group> -->
      <!-- <div style="z-index: 9999;">
        <div class="popup-config-canvas" :style="{ position: 'fixed', top: '50%', left: isLockToggle ? '0px' : '-300px', zIndex: '9999' }">
          <div style="display: flex; align-items: center; background-color: #565656; border-radius: 0px 10px 10px 0px;">
            <b-button variant="primary" class="ml-2  mr-2" size="sm" style="border-radius: 10px" @click="isEraser = !isEraser" :disabled="canvasDetails[addImageKey].initial_img_draw.length === 0">{{ isEraser ? 'Pen' : 'Eraser' }}</b-button>
            <b-button variant="primary" class=" mr-2" size="sm" style="border-radius: 10px" @click="undoLine" :disabled="canvasDetails[addImageKey].initial_img_draw.length === 0">Undo</b-button>
            <b-button variant="danger" class=" mr-2" size="sm" style="border-radius: 10px" @click="resetLine" :disabled="canvasDetails[addImageKey].initial_img_draw.length === 0">Reset</b-button>
            <b-button :variant="isLock ? 'warning' : 'danger'" class="" size="sm" style="border-radius: 10px" @click="isLock = !isLock">{{ isLock ? 'Unlock Draw' : 'Lock Draw' }}</b-button>
            <p style="margin: 0px;"
              @click="isLockToggle = !isLockToggle"
            >
              <i style="font-size: 28px; color: #fff" :class="isLockToggle ? 'ri-arrow-left-s-fill' : 'ri-arrow-right-s-fill'"></i>
            </p>
          </div>
        </div>
      </div> -->
      <div style="overflow-x: scroll; min-height: 700px; display: flex; align-items: center; justify-content: center;">
        <div id="canvas-custom-modal-main" class="canvas-custom-modal-total">
          <div>
            <b-button variant="primary" class="mb-2 mr-2" size="sm" style="border-radius: 10px" @click="isEraser = !isEraser" :disabled="disableCheck">{{ isEraser ? 'Pen' : 'Eraser' }}</b-button>
            <b-button variant="primary" class="mb-2 mr-2" size="sm" style="border-radius: 10px" @click="undoLine" :disabled="canvasDetails[addImageKey].initial_img_draw?.length === 0 || disableCheck">Undo</b-button>
            <b-button variant="danger" class="mb-2 mr-2" size="sm" style="border-radius: 10px" @click="resetLine" :disabled="canvasDetails[addImageKey].initial_img_draw?.length === 0 || disableCheck">Reset</b-button>
            <b-button :variant="isLock ? 'warning' : 'danger'" class="mb-2" size="sm" style="border-radius: 10px" @click="isLock = !isLock" :disabled="disableCheck">{{ isLock ? 'Unlock Draw' : 'Lock Draw' }}</b-button>
            <v-stage
              ref="stage"
              :config="configKonva"
              @mousedown="startDrawing"
              @mousemove="draw"
              @mouseup="endDrawing"
              @touchstart="startDrawing"
              @touchmove="draw"
              @touchend="endDrawing"
            >
              <v-layer ref="backgroundLayer">
                <!-- <v-rect :config="{ x: 0, y: 0, width: 800, height: 600, fill: 'lightblue' }" /> -->
                <v-image :config="{ ...(isLock || disableCheck ? dataLock : {}), x: 0, y: 0, image: backgroundImageKonva, width: 800, height: 600 }" />
              </v-layer>
              <v-layer ref="layer">
                <v-line
                  v-for="(line, index) in canvasDetails[addImageKey].initial_img_draw"
                  :key="index"
                  :config="{ ...(isLock || disableCheck ? dataLock : {}), ...line.config }"
                />
                <!-- <v-circle :config="configCircle"></v-circle> -->
              </v-layer>
              <v-layer ref="borderLayer">
                <v-rect :config="{ ...(isLock || disableCheck ? dataLock : {}), x: 0, y: 0, width: 800, height: 600, stroke: 'black', strokeWidth: 5 }" />
              </v-layer>
            </v-stage>
          </div>

          <!-- <div>
            <div v-if="canvasDetails[addImageKey].is_show_draw">
            <vue-drawing-canvas class="unselectable" canvasId="VueCanvasDrawing_man_pic_draw" ref="VueCanvasDrawing_man_pic_draw" :image="canvasDetails[addImageKey].initial_img_draw"
              :width="canvasModalWidth" :height="canvasModalWidth - 200.001" :lineJoin="'round'" :eraser="eraser2" :lineWidth="eraser2 ? 10 : 1"
              :initialImage="canvasDetails[addImageKey].initial_img_draw" :backgroundImage="canvasDetails[addImageKey].bg_img_draw"
              :styles="{ border: '1px solid black', display: addImageKey === 'man_pic' ? undefined : 'none' }" />
            </div>
            <div v-if="canvasDetails[addImageKey].is_show_draw">
            <vue-drawing-canvas class="unselectable" canvasId="VueCanvasDrawing_wom_pic_draw" ref="VueCanvasDrawing_wom_pic_draw" :image="canvasDetails[addImageKey].initial_img_draw"
              :width="canvasModalWidth" :height="canvasModalWidth - 200.0001" :lineJoin="'round'" :eraser="eraser2" :lineWidth="eraser2 ? 10 : 1"
              :initialImage="canvasDetails[addImageKey].initial_img_draw" :backgroundImage="canvasDetails[addImageKey].bg_img_draw"
              :styles="{ border: '1px solid black', display: addImageKey === 'wom_pic' ? undefined : 'none' }" />
            </div>
            <div v-if="canvasDetails[addImageKey].is_show_draw">
            <vue-drawing-canvas class="unselectable" canvasId="VueCanvasDrawing_lip_pic_draw" ref="VueCanvasDrawing_lip_pic_draw" :image="canvasDetails[addImageKey].initial_img_draw"
              :width="canvasModalWidth" :height="canvasModalWidth - 200.00001" :lineJoin="'round'" :eraser="eraser2" :lineWidth="eraser2 ? 10 : 1"
              :initialImage="canvasDetails[addImageKey].initial_img_draw" :backgroundImage="canvasDetails[addImageKey].bg_img_draw"
              :styles="{ border: '1px solid black', display: addImageKey === 'lip_pic' ? undefined : 'none' }" />
            </div>
          </div> -->
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
          <b-button
            style="border-radius: 0.5rem; padding: 0 1.1rem !important;"
            variant="primary"
            class="btn-min-size"
            size="sm"
            @click="onSubmitCanvasModal"
          >
          <i class="save-data_ct"/>บันทึก
          </b-button>
          <b-button
            variant="danger"
            class="btn-min-size btn-cancel-border"
            style="border-radius: 0.5rem;"
            size="sm"
            @click="onCloseCanvasModal"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal v-model="modalAdd1" no-close-on-backdrop centered @close="clickCancelModal">
      <template #modal-title>
        <span v-if="modalMode === false" style="font-size: 18px; color: black;">สินค้า (เพิ่ม)</span>
        <span v-else style="font-size: 18px; color: black;">สินค้า (แก้ไข)</span>
      </template>

      <b-container fluid>
        <b-form-group label="รายการสินค้า" label-for="exampleInputText1">
          <v-select id="exampleInputText1" v-model="dataModal1.product" :options="productListDD"></v-select>
        </b-form-group>
        <b-form-group label="จำนวน" label-for="exampleInputText2">
          <CurrencyInputCom id="exampleInputText1" v-model="dataModal1.amountAddModal" :disabled="disableCheck" :options="optionCurrencyNoDecimal"></CurrencyInputCom>
        </b-form-group>
      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <b-button variant="none" size="md" style="border-radius: 0.5rem;" class="float-right ml-2 iq-bg-danger btn-cancel-border" @click="clickCancelModal">
            ยกเลิก
          </b-button>
          <b-button variant="primary" size="md" style="border-radius: 0.5rem;" class="float-right mr-2" :disabled="CanSaveAddModal('product')" @click="clickOkModal(modalMode ? 'edit' : 'add')">
            <i class="save-data_ct" />บันทึก
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal v-model="modalAdd2" no-close-on-backdrop centered @close="clickCancelModal">
      <template #modal-title>
        <span v-if="modalMode === false" style="font-size: 18px; color: black;">บริการ (เพิ่ม)</span>
        <span v-else style="font-size: 18px; color: black;">บริการ (แก้ไข)</span>
      </template>

      <b-container fluid>
        <b-form-group label="หมวดหมู่" label-for="exampleInputText1">
          <v-select id="exampleInputText1" v-model="dataModal2.category" :options="categoryListDD"></v-select>
        </b-form-group>
        <b-form-group label="ประเภทการใช้งาน" label-for="exampleInputText2">
          <b-form-input id="exampleInputText3" v-model="dataModal2.typeUsege" type="text" placeholder="" />
        </b-form-group>
        <b-form-group label="จำนวน" label-for="exampleInputText3">
          <CurrencyInputCom id="exampleInputText1" v-model="dataModal2.amountAddModal" :disabled="disableCheck" :options="optionCurrencyNoDecimal"></CurrencyInputCom>
        </b-form-group>
        <span style="font-size: 20px; color: #000000;">วัตถุดิบ</span>
        <b-button variant="primary" size="md" class="ml-2 mb-2" @click="dataModal2.rawmat.push({ brand: null, amount: null })">
          <i class="circle-plus_ct"/>เพิ่ม
        </b-button>
        <b-row v-for="(item, index) in dataModal2.rawmat" :key="index">
          <b-col class="pr-2">
            <b-form-group label="Brand" label-for="exampleInputText4">
              <v-select id="exampleInputText1" v-model="item.brand" :options="brandListDD"></v-select>
            </b-form-group>
          </b-col>
          <b-col class="pl-2">
            <b-form-group label="ปริมาณ" label-for="exampleInputText5">
              <CurrencyInputCom id="exampleInputText1" v-model="item.amount" :disabled="disableCheck" :options="optionCurrencyNoDecimal"></CurrencyInputCom>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <b-button variant="none" size="md" style="border-radius: 0.5rem;" class="float-right ml-2 iq-bg-danger btn-cancel-border" @click="clickCancelModal">
            ยกเลิก
          </b-button>
          <b-button variant="primary" size="md" style="border-radius: 0.5rem;" class="float-right mr-2" :disabled="CanSaveAddModal('service')" @click="clickOkModal(modalMode ? 'edit' : 'add')">
            <i class="save-data_ct"/>บันทึก
          </b-button>
      </div>
    </template>
  </b-modal>
</b-container></template>

<style scoped>
.popup-config-canvas {
  transition: 0.5s ease-in;
}

.layoutDisplay {
  display: flex;
  flex-direction: column;
}

.canvas-display-serviceusage::-webkit-scrollbar {
  width: 20px;
  height: 20px;
  z-index: 1001;
}

.full-width-modal .modal-dialog {
  max-width: 95% !important;
  /* min-width: 1024px !important; */
  width: 95vw !important;
  margin: 20px auto !important;
  padding: 0 !important;
}

/* Ensure the modal content takes the full width */
.full-width-modal .modal-content {
  width: 95vw !important;
}

/* Optional: Adjust modal body padding */
.full-width-modal .modal-body {
  padding: 1rem !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.canvas-custom-modal-total {
  animation: fadeIn 2.5s ease-in-out; /* Adjust the duration as needed */
}

@media (max-width: 991px) {
  .layoutDisplay {
    flex-direction: row;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import datePicker from 'vue-bootstrap-datetimepicker'
// import vueDrawingCanvas from 'vue-drawing-canvas'
// import picture from '../../assets/images/testImage.jpg'
import manPicture from '../../assets/images/66F_รูปหน้าคน-ผู้ชาย.png'
import lipPicture from '../../assets/images/66F_รูปหน้าคน-ผู้หญิง_lip.png'
import womenPicture from '../../assets/images/66F_รูปหน้าคน-ผู้หญิง.png'
import Vue from 'vue'
import vSelect from 'vue-select'
import moment from 'moment'
import CurrencyInputCom from './CurrencyInputCom.vue'
import axios from 'axios'
import { configAPI } from '../../configBase'

export default {
  name: 'ServiceUsage',
  components: {
    datePicker,
    // vueDrawingCanvas,
    vSelect,
    CurrencyInputCom
  },
  data () {
    return {
      dataLock: { preventDefault: false },
      isLockToggle: false,
      isLock: false,
      isEraser: false,
      isEraserSign: false,
      backgroundImageKonva: null,
      isDrawing: false,
      drawLinesSign: [],
      configKonva: {
        width: 800,
        height: 600,
        draggable: false
      },
      configCircle: {
        x: 100,
        y: 100,
        radius: 70,
        fill: 'red',
        stroke: 'black',
        strokeWidth: 4
      },
      canvasModalWidth: 500,
      inputAssistDoctor: null,
      disableCheck: false,
      mouseDown: null,
      mouseUp: null,
      yetDrawSign: true,
      testData: null,
      dataModal1: {
        id: null,
        amountAddModal: null,
        product: null,
        index: null
      },
      dataModal2: {
        id: null,
        category: null,
        typeUsege: null,
        amountAddModal: null,
        rawmat: [],
        index: null
      },
      searchData: null,
      canSearch: false,
      doctorDD: [],
      dataDelList: [],
      assistDoctorDD: [],
      productListDD: [],
      categoryListDD: [],
      brandListDD: [],
      imgCanvas: null,
      customerDD: [],
      cln_id: null,
      user_id: null,
      phoneSearch: null,
      dataForSave: {
        OPD_no: null,
        fat: 25,
        weight: null,
        height: null,
        body_temp: 36.8,
        blood_pressure: 100,
        pulse_rate: 90,
        breathing_rate: 16,
        muscle: 24,
        img_treat_name: null,
        img_treat_path: null,
        img_treat_path2: null,
        img_treat_file: null,
        img_other_name: null,
        img_other_path: null,
        img_other_path2: null,
        img_other_file: null,
        img_file_name: null,
        img_file_path: null,
        img_file_file: null
      },
      items: [],
      date: null,
      profile_image: require('../../assets/images/user/1.jpg'),
      showModal: false,
      imageUrl: null,
      imageUrl2: null,
      activeTab: 'tab1',
      activeTab2: '',
      optionCurrency: {
        currency: 'EUR',
        currencyDisplay: 'hidden',
        valueRange: {
          min: 0,
          max: 1e+53
        },
        precision: 2,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: true,
        accountingSign: false
      },
      optionCurrencyNoDecimal: {
        currency: 'EUR',
        currencyDisplay: 'hidden',
        valueRange: {
          min: 0,
          max: 1e+53
        },
        precision: 0,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: true,
        accountingSign: false
      },
      historyTreat: [
        { hn_no: 'HNxxxxxxxx-xxxxxx', id: '12312131223' },
        { hn_no: 'HNxxxxxxxx-xxxxxx', id: '12312131223' },
        { hn_no: 'HNxxxxxxxx-xxxxxx', id: '12312131223' }
      ],
      allowedTypes1: [
        'image/png',
        'image/jpeg',
        'text/csv',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ],
      allowedTypes2: [
        'image/png',
        'image/jpeg'
      ],
      options: {
        // https://momentjs.com/docs/#/displaying/
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        minDate: false,
        sideBySide: true,
        // stepping: 1,
        icons: {
          time: 'fas fa-clock',
          date: 'fas fa-calendar',
          up: 'fas fa-chevron-up',
          down: 'fas fa-chevron-down',
          previous: 'fas fa-chevron-left',
          next: 'fas fa-chevron-right',
          today: 'fas fa-calendar-day',
          clear: 'fas fa-trash-alt',
          close: 'fas fa-times'
        },
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'auto'
        }
      },
      columns1: [
        { label: 'รหัสสินค้า', key: 'variable1', class: 'text-center table-size-200-400 ellips' },
        { label: 'ชื่อยา', key: 'variable2', class: 'text-center table-size-200-400 ellips' },
        { label: 'สารประกอบ', key: 'variable3', class: 'text-center table-size-200-400 ellips' },
        { label: 'ชื่อการค้า', key: 'variable4', class: 'text-center table-size-200-400 ellips' },
        { label: 'QR', key: 'variable5', class: 'text-center table-size-200-400 ellips' },
        { label: 'หน่วยนับ', key: 'variable6', class: 'text-center table-size-200-400 ellips' },
        // { label: 'จำนวน', key: 'variable7', class: 'text-center table-size-200-400 ellips' },
        { label: 'จำนวนคงเหลือ', key: 'balance', class: 'text-center table-size-200-400 ellips' },
        { label: 'จำนวนที่ใช้', key: 'input_used', class: 'text-center table-size-200-400 ellips' },
        { label: 'จัดการ', key: 'variable8', class: 'text-center' }
        /* ,{ label: 'จัดการ', key: 'variable8', class: 'text-center' } */
      ],
      rows1: [],
      rows3: [
        {
          index: 1,
          id: '1',
          variable1: '0001',
          variable2: 'Botox',
          variable3: 'Nitus',
          variable4: 'Astra',
          variable5: 'xxxxxxxx',
          variable6: 'เม็ด. ขวด, แผง',
          variable7: '1'
        },
        {
          index: 2,
          id: '2',
          variable1: '0002',
          variable2: 'Botox2',
          variable3: 'Nitro',
          variable4: 'Fizer',
          variable5: 'xxxxxxxx',
          variable6: 'เม็ด. ขวด, แผง',
          variable7: '2'
        }
      ],
      columns2: [
        { label: 'หมวดหมู่', key: 'variable2', class: 'text-center table-size-200-400 ellips' },
        { label: 'ประเภทการใช้งาน', key: 'variable3', class: 'text-center table-size-200-400 ellips' },
        // { label: 'จำนวน', key: 'variable4', class: 'text-center table-size-200-400 ellips' },
        { label: 'วัตถุดิบ', key: 'variable5', class: 'text-center table-size-200-400 ellips' },
        { label: 'ปริมาณ', key: 'variable6', class: 'text-center table-size-200-400 ellips' },
        { label: 'จำนวนคงเหลือ', key: 'balance', class: 'text-center table-size-200-400 ellips' },
        { label: 'จำนวนที่ใช้', key: 'input_used', class: 'text-center table-size-200-400 ellips' },
        { label: 'จัดการ', key: 'variable8', class: 'text-center' }
        /* ,
        { label: 'จัดการ', key: 'variable7', class: 'text-center table-size-200-400' } */
      ],
      rows2: [],
      rows4: [],
      currentPage: 1,
      perPage: 10,
      from: '',
      mode: '',
      id: '',
      disabled: false,
      statusButton1: true,
      statusButton2: false,
      image1: '',
      initialImage1: [],
      eraser1: false,
      image2: '',
      initialImage2: [],
      eraser2: false,
      dataSelect: [],
      serviceBillList: [
        /* { is_prodcut: true, no: '000001', date: '17/10/2023', name: 'ฉีดโบท็อก1', id: '000001', check: false, arrList: { productList: dataTestProduct, serviceList: dataTestService } },
        { is_prodcut: true, no: '000002', date: '17/10/2023', name: 'ฉีดโบท็อก2', id: '000002', check: false, arrList: { productList: [], serviceList: [] } },
        { is_prodcut: true, no: '000003', date: '17/10/2023', name: 'ฉีดโบท็อก3', id: '000003', check: false, arrList: { productList: [], serviceList: [] } } */
      ],
      addImage: false,
      addImageKey: 'man_pic',
      addImageObj: {
        man_pic: false,
        man_pic_draw: [],
        wom_pic: false,
        wom_pic_draw: [],
        lip_pic: false,
        lip_pic_draw: []
      },
      backgroundImageTemp: null,
      arrChanged: ['man_pic'],
      canvasDetails: {
        man_pic: {
          is_show: true,
          is_show_draw: true,
          initial_img: [],
          bg_img: manPicture,
          initial_img_draw: [],
          bg_img_draw: manPicture
        },
        wom_pic: {
          is_show: true,
          is_show_draw: true,
          initial_img: [],
          bg_img: womenPicture,
          initial_img_draw: [],
          bg_img_draw: womenPicture
        },
        lip_pic: {
          is_show: true,
          is_show_draw: true,
          initial_img: [],
          bg_img: lipPicture,
          initial_img_draw: [],
          bg_img_draw: lipPicture
        }
      },
      showCanvas2: true,
      showModalCanvas: false,
      inTab3First: false,
      inTab3Second: false,
      backgroundImageDisplay: null,
      backgroundImageDrawTemp: null,
      backgroundImage: null,
      mode1: true,
      mode2: true,
      modalAdd1: false,
      modalAdd2: false,
      modalMode: false
    }
  },
  methods: {
    getPointerPosition () {
      const stage = this.$refs.stage.getNode()
      const pointerPosition = stage.getPointerPosition()
      return pointerPosition ? { x: pointerPosition.x, y: pointerPosition.y } : { x: 0, y: 0 }
    },
    startDrawing (event) {
      if (this.isLock || this.disableCheck) {
        event.preventDefault()
        return
      }
      event.evt.preventDefault()
      this.isDrawing = true
      const pos = this.getPointerPosition()
      this.canvasDetails[this.addImageKey].initial_img_draw.push({
        config: {
          points: [pos.x, pos.y],
          stroke: 'black',
          globalCompositeOperation: !this.isEraser ? 'source-over' : 'destination-out',
          strokeWidth: !this.isEraser ? 2 : 15,
          lineCap: 'round',
          lineJoin: 'round'
        }
      })
    },
    draw (event) {
      if (this.isLock || this.disableCheck) {
        event.preventDefault()
        return
      }
      event.evt.preventDefault()
      if (!this.isDrawing) return

      const pos = this.getPointerPosition()
      const lastLine = this.canvasDetails[this.addImageKey].initial_img_draw[this.canvasDetails[this.addImageKey].initial_img_draw.length - 1]
      lastLine.config.points = lastLine.config.points.concat([pos.x, pos.y])
      this.$refs.layer.getNode().batchDraw()
    },
    endDrawing (event) {
      if (this.isLock || this.disableCheck) {
        event.preventDefault()
        return
      }
      event.evt.preventDefault()
      this.isDrawing = false
    },
    undoLine () {
      if (this.canvasDetails[this.addImageKey].initial_img_draw.length > 0) {
        this.canvasDetails[this.addImageKey].initial_img_draw.pop()
        this.$refs.layer.getNode().batchDraw() // Redraw the layer
      }
    },
    resetLine () {
      if (this.canvasDetails[this.addImageKey].initial_img_draw.length > 0) {
        this.canvasDetails[this.addImageKey].initial_img_draw = []
        this.$refs.layer.getNode().batchDraw() // Redraw the layer
      }
    },

    getPointerPositionSign () {
      const stage = this.$refs.stageSign.getNode()
      const pointerPosition = stage.getPointerPosition()
      return pointerPosition ? { x: pointerPosition.x, y: pointerPosition.y } : { x: 0, y: 0 }
    },
    startDrawingSign (event) {
      if (this.disableCheck) {
        event.preventDefault()
        return
      }
      event.evt.preventDefault()
      this.isDrawing = true
      const pos = this.getPointerPositionSign()
      this.drawLinesSign.push({
        config: {
          points: [pos.x, pos.y],
          stroke: 'black',
          globalCompositeOperation: !this.isEraserSign ? 'source-over' : 'destination-out',
          strokeWidth: !this.isEraserSign ? 2 : 15,
          lineCap: 'round',
          lineJoin: 'round'
        }
      })
    },
    drawSign (event) {
      if (this.disableCheck) {
        event.preventDefault()
        return
      }
      event.evt.preventDefault()
      if (!this.isDrawing) return

      const pos = this.getPointerPositionSign()
      const lastLine = this.drawLinesSign[this.drawLinesSign.length - 1]
      lastLine.config.points = lastLine.config.points.concat([pos.x, pos.y])
      this.$refs.layerSign.getNode().batchDraw()
    },
    endDrawingSign (event) {
      if (this.disableCheck) {
        event.preventDefault()
        return
      }
      event.evt.preventDefault()
      this.isDrawing = false
    },
    undoLineSign () {
      if (this.drawLinesSign.length > 0) {
        this.drawLinesSign.pop()
        this.$refs.layerSign.getNode().batchDraw() // Redraw the layer
      }
    },
    resetLineSign () {
      if (this.drawLinesSign.length > 0) {
        this.drawLinesSign = []
        this.$refs.layerSign.getNode().batchDraw() // Redraw the layer
      }
    },
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.searchPhoneCustomer(true)
      }
    },
    copyStrokesCanvas (ref1, ref2, delay = 0) {
      setTimeout(() => {
        // console.log('this.$refs[ref1] :>> ', this.$refs[ref1]);
        // console.log('this.$refs[ref2] :>> ', this.$refs[ref2]);
        const canvas1 = this.$refs[ref1].$el
        const canvas2 = this.$refs[ref2].$el
        if (canvas1 && canvas2) {
          if (!delay) {
            const strokes = this.$refs[ref1].getAllStrokes()
            this.addImageObj[`${this.addImageKey}_draw`] = strokes
            this.canvasDetails[this.addImageKey].initial_img = strokes
            // console.log('strokes :>> ', strokes)
          }
          const context2 = canvas2.getContext('2d')
          context2.clearRect(0, 0, canvas2.width, canvas2.height)
          /* if (this.backgroundImageTemp) {
            console.log('this.backgroundImageTemp :>> ', this.backgroundImageTemp)
            context2.drawImage(this.backgroundImageTemp, 0, 0, canvas2.width, canvas2.height)
          } */
          // console.log('canvas1 :>> ', canvas1);
          context2.drawImage(canvas1, 0, 0, canvas2.width, canvas2.height)
        }
      }, delay)
    },
    onSubmitCanvasModal () {
      this.addImageObj[this.addImageKey] = false
      // console.log('canvas1 :>> ', this.$refs.VueCanvasDrawing2.$el.getContext('2d'))
      // this.copyStrokesCanvas(`VueCanvasDrawing_${this.addImageKey}_draw`, `VueCanvasDrawing_${this.addImageKey}`)
      this.addImageObj[`${this.addImageKey}_draw`] = JSON.parse(JSON.stringify(this.canvasDetails[this.addImageKey].initial_img_draw))
      this.showModalCanvas = false
    },
    onCloseCanvasModal () {
      this.addImageObj[this.addImageKey] = false
      this.showModalCanvas = false
    },
    redo (item) {
      Vue.swal.fire(this.GetTextConfirmSwal('คุณมั่นใจว่าจะยกเลิกการแก้ไขใช่หรือไม่', 500))
        .then(async (result) => {
          if (result.isConfirmed) {
            item.input_used_sup = item.input_used
            item.editable = false
          }
        })
    },
    submit (item) {
      Vue.swal.fire(this.GetTextConfirmSwal('คุณมั่นใจว่าจะยืนยันการแก้ไขใช่หรือไม่', 500))
        .then(async (result) => {
          if (result.isConfirmed) {
            if (!item.isNew) {
              item.status = 'update'
            }
            item.input_used = item.input_used_sup
            item.editable = false
          }
        })
    },
    GetTextConfirmSwal (text, width) {
      return {
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${text}</span>`,
        // html: '<span>สินค้าและวัตถุดิบที่คุณเลือกจะถูกล้าง</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        showCancelButton: true,
        width: width,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }
    },
    onChangeActiveBillList (ID) {
      for (let item of this.serviceBillList) {
        if (ID === item.id) {
          item.check = true
          this.rows1 = item.arrList.productList
          this.rows2 = item.arrList.serviceList
        } else {
          item.check = false
        }
      }
    },
    async GetCustomerHistory () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/serviceUsage/${this.cln_id}/${this.id}/HistoryCustomer`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            const data = response.data.data.length > 0 ? response.data.data[0] : {}
            await this.searchPhoneCustomer(null, data.hc_cus_id)
            this.billHistoryList = data.billHistoryList || []
            // console.log(data.hc_follow_date)
            this.dataForSave = {
              OPD_no: data.hc_opd_no || null,
              fat: this.numberFormatWithComma(data.hc_fat || null),
              weight: this.numberFormatWithComma(data.hc_weight || null),
              height: this.numberFormatWithComma(data.hc_hight || null),
              body_temp: this.numberFormatWithComma(data.hc_body_temperature || null),
              blood_pressure: data.hc_pressure || null, // this.numberFormatWithComma(data.hc_pressure || null),
              pulse_rate: this.numberFormatWithComma(data.hc_pulse_rate || null),
              breathing_rate: this.numberFormatWithComma(data.hc_breathing_rate || null),
              muscle: this.numberFormatWithComma(data.hc_muscle || null),
              img_treat_name: data.hc_img_name || null,
              img_treat_path: data.hc_img_path || null,
              img_treat_path2: null,
              img_treat_file: null,
              img_other_name: data.hc_img_other_name || null,
              img_other_path: data.hc_img_other_path || null,
              img_other_path2: null,
              img_other_file: null,
              img_file_name: data.hc_upload_file_name || null,
              img_file_path: data.hc_upload_file_path || null,
              img_file_file: null,
              note: data.hc_note || null,
              is_close: data.hc_is_close || false,
              assistDoctor: data.hc_assistant_id ? this.assistDoctorDD.find(el => el.value === data.hc_assistant_id) : { value: 'other', label: 'อื่น ๆ' }, // this.GetValueDisplayDD(data.hc_doctor_id),
              doctor: data.hc_doctor_id ? this.doctorDD.find(el => el.value === data.hc_doctor_id) : null, // this.GetValueDisplayDD(data.hc_assistant_id),
              rangeFollow: data.hc_follow_date ? moment(data.hc_follow_date).format('DD/MM/YYYY HH:mm') : null
            }

            // this.$refs.VueCanvasDrawing2 = JSON.parse(data.hc_area_remark)
            this.drawLinesSign = data.hc_sign ? JSON.parse(data.hc_sign)?.data : []
            // this.initialImage2 = data.hc_area_remark ? JSON.parse(data.hc_area_remark)?.data : []
            const manPicImg = data.hc_male ? JSON.parse(data.hc_male)?.data : []
            const womPicImg = data.hc_female ? JSON.parse(data.hc_female)?.data : []
            const lipPicImg = data.hc_lip ? JSON.parse(data.hc_lip)?.data : []
            this.addImageObj[`man_pic_draw`] = manPicImg
            this.addImageObj[`wom_pic_draw`] = womPicImg
            this.addImageObj[`lip_pic_draw`] = lipPicImg
            /* this.addImageObj.man_pic_draw = manPicImg
            this.addImageObj.wom_pic_draw = womPicImg
            this.addImageObj.lip_pic_draw = lipPicImg
            this.canvasDetails.man_pic.initial_img = manPicImg
            this.canvasDetails.man_pic.initial_img_draw = manPicImg
            this.canvasDetails.wom_pic.initial_img = womPicImg
            this.canvasDetails.wom_pic.initial_img_draw = womPicImg
            this.canvasDetails.lip_pic.initial_img = lipPicImg
            this.canvasDetails.lip_pic.initial_img_draw = lipPicImg */
            this.inputAssistDoctor = data.hc_assistant_name
            this.addImageKey = data.hc_on_image || 'man_pic'
            this.loadBackgroundImage(this.canvasDetails[this.addImageKey].bg_img_draw, 'backgroundImageKonva')
            // this.loadWatermarkImage(manPicture)
            // console.log('this.initialImage2 :>> ', this.initialImage2);
            this.testData = {
              sing: data.hc_sign ? JSON.parse(data.hc_sign)?.data : [],
              area_remark: data.hc_area_remark ? JSON.parse(data.hc_area_remark)?.data : []
            }
            if (data.hc_area_remark && JSON.parse(data.hc_area_remark).hasImg) {
              this.addImage = true
              // this.addImageObj = JSON.parse(data.hc_area_remark).hasImg
            }
            // this.$refs.VueCanvasDrawing1 = JSON.parse(data.hc_sign)
          }
        }).catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    loadWatermarkImage (path) {
      const image = new Image()
      image.src = path // Replace with your actual watermark image path
      image.onload = () => {
        this.imgCanvas = image
      }
    },
    numberFormatWithComma (value) {
      return !value
        ? null
        : value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    async downloadFile (items) {
      await axios({
        url: this.getFullImagePath(items.img_file_path),
        method: 'GET',
        responseType: 'blob'
      })
        .then((response) => {
          let fileUrl = window.URL.createObjectURL(new Blob([response.data]))
          let fileLink = document.createElement('a')
          fileLink.href = fileUrl

          fileLink.setAttribute('download', items.img_file_name)
          document.body.appendChild(fileLink)

          fileLink.click()
        })
        .catch(() => {
          this.SwalError('ไม่พบไฟล์นี้ในฐานข้อมูล', 'ดาวน์โหลดเอกสารไม่สำเร็จ')
        })
    },
    refAddImageClick () {
      if (this.addImage) {
        // this.$refs.addImageRef.click()
        this.ChangeBack(true)
      }
      if (this.addImageObj.lip_pic || this.addImageObj.man_pic || this.addImageObj.wom_pic) {
        this.ChangeBack(true)
      }
    },
    async GetSaleOrderCustomer () {
      if (!this.searchData) return
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/serviceUsage/${this.cln_id}/${this.searchData.id}/GetSaleOrder/${this.id}/${this.mode/*  === 'view' ? true : '' */}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status && response.data.data.length > 0) {
            this.serviceBillList = response.data.data
            if (this.mode !== 'add') {
              for (let item of this.serviceBillList) {
                if (item.is_select) {
                  this.dataSelect.push(item.id)
                  if (this.dataSelect.length === 1) {
                    this.rows1 = item.arrList.productList
                    this.rows2 = item.arrList.serviceList
                  }
                }
              }
            }
          } else {
            this.serviceBillList = []
          }
        }).catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async searchPhoneCustomer (fromSearch, haveId) {
      if (fromSearch) {
        this.canSearch = !(this.phoneSearch && this.phoneSearch?.toString().replace(/-/g, '').length === 10)
        if (!(this.phoneSearch && this.phoneSearch?.toString().replace(/-/g, '').length === 10)) {
          return
        }
      }
      await axios.request({
        method: 'get',
        url: !haveId
          ? `${configAPI.MA_PORT}/api/serviceUsage/${this.cln_id}/Patient/?phone=${this.phoneSearch?.toString().replace(/-/g, '') || ''}`
          : `${configAPI.MA_PORT}/api/serviceUsage/${this.cln_id}/Patient/get/${haveId}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status && response.data.data.length > 0) {
            const data = response.data.data[0]
            this.searchData = {
              id: data.cus_id,
              image: data.cus_img_path,
              fullname: (data.cus_firstname + ' ' + data.cus_lastname),
              nickname: data.cus_nickname,
              id_card: data.cus_citizen_id,
              hn_no: data.cus_hn,
              tel: data.cus_tel,
              birthday: data.cus_birth_day ? moment(data.cus_birth_day).format('DD/MM/YYYY') : null,
              gender: data.cus_gender,
              blood: data.cus_blood,
              drug_allergy: data.cus_drug_allergy,
              food_allergy: data.cus_food_allergy
            }
            this.historyTreat = data.history_list?.map(el => {
              return { id: el.hc_id, hn_no: el.hc_opd_no, is_close: el.hc_is_close }
            })
            this.dataForSave.weight = data.cus_weight
            this.dataForSave.height = data.cus_height
            this.dataForSave.body_temp = data.cus_body_temperature
            this.dataForSave.blood_pressure = data.cus_pressure
            this.dataForSave.pulse_rate = this.dataForSave.pulse_rate
            this.dataForSave.breathing_rate = this.dataForSave.breathing_rate
            this.dataForSave.fat = data.cus_fat
            this.dataForSave.muscle = data.cus_muscle
          }
        }).catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async getServiceGroup () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MD_PORT}/api/serviceGroup`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          this.categoryListDD = response.data.data?.map(option => ({ value: option.id, label: option.name }))
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async getProduct () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/product?search=`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status) {
            this.productListDD = response.data.data.map(el => {
              return {
                value: el.id,
                label: (el.no/*  + ' - ' + el.name */),
                data: el
              }
            })
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async GetBrand () {
      await axios.request({
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/materialBrand`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          let data = response.data.data
          let temp = []
          data.forEach((element) => {
            temp.push({
              value: element.id,
              label: element.name
            })
          })
          this.brandListDD = temp
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async GetEmployeeDoctor () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/serviceUsage/${this.cln_id}/GetEmployeeDoctor`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status) {
            const tempList = response.data.data.map(el => {
              return { value: el.id, label: (el.name + ' ' + el.lastname) }
            })
            this.assistDoctorDD = [ ...tempList, { value: 'other', label: 'อื่น ๆ' } ]
            this.doctorDD = [ ...tempList ]
          }
        }).catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    getFullImagePath (relativePath) {
      if (relativePath) {
        relativePath = relativePath.substring(1)
        const baseURL = `${configAPI.MA_PORT}`
        return baseURL + relativePath
      } else {
        return 'https://www.w3schools.com/howto/img_avatar.png'
      }
    },
    handleImageUpload (event, from) {
      const file = event.target.files[0]
      if ((from === 'treat' || from === 'other') && !this.allowedTypes2.includes(file.type)) {
        this.SwalError('ไฟล์ที่อัปโหลดต้องเป็นรูปภาพเท่านั้น', 'อัปโหลดไม่สำเร็จ')
        file.name = null
        return
      }
      const reader = new FileReader()
      this.dataForSave[`img_${from}_file`] = file
      this.dataForSave[`img_${from}_name`] = file.name
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.dataForSave[`img_${from}_path2`] = reader.result
      }
    },
    closeService () {
      if (this.dataForSave.is_close) return
      this.SwalAlertConfirm('คุณมั่นใจว่าคุณจะปิดการให้บริการใช่หรือไม่', 'warning')
        .then(async (result) => {
          if (result.isConfirmed) {
            const errorList = await this.checkError(true)
            if (errorList.length > 0) {
              let errMessage = ''
              for (let e = 0; e < errorList.length; e++) {
                errMessage += '<br/>' + errorList[e]
              }
              this.SwalError(errMessage, 'ปิดการให้บริการไม่สำเร็จ')
              return
            }
            this.dataForSave.is_close = true
            await this.SaveDataPatientService()
            /* Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ปิดการให้บริการสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              router.go(-1)
            }) */
          }
        })
    },
    async viewPatientById (id, canClick, viewOnly) {
      if (canClick) {
        return
      }
      // const pathcurrent = window.location.href?.split('/')
      if (this.from === 'Patient') {
        router.push(`/Patient`)
      } else if (this.from === 'Service') {
        router.push(`/HistoryService/view/${this.searchData.id}`)
      } else if (this.from === 'Services') {
        router.push(`/HistoryService`)
      } else if (this.from === 'SaleOrder') {
        router.push(`/SaleOrderTable`)
      } else {
        router.push('/ServiceUsage/Patient/add')
      }
      // if ()
      this.dataSelect = []
      router.push(`/ServiceUsage/Patient/History/${viewOnly ? 'view' : 'edit'}/${id}`)
      if (this.mode !== 'add') {
        try {
          Vue.swal.fire({
            title: '<span class="loading-icon"><i class="fas fa-spinner fa-spin"></i></span>',
            text: 'กำลังดึงข้อมูล...',
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
              Vue.swal.getHtmlContainer().querySelector('.loading-icon').style.fontSize = '40px'
            }
          })
          this.mode = viewOnly ? 'view' : 'edit'
          this.disableCheck = viewOnly
          this.setId()
          this.activeTab = 'tab1'
          await this.GetCustomerHistory()
          await this.GetSaleOrderCustomer()
          Vue.swal.close()
        } catch (error) {
          Vue.swal.close()
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        }
      }
      // window.location.reload()
    },
    getFormatBirthday (dateString) {
      if (!dateString) {
        return '-'
      }

      const [day, month, year] = dateString.split('/').map(Number)
      const birthDate = moment([year, month - 1, day])
      const today = moment()
      const thisYear = today.year()
      const years = today.diff(birthDate, 'years')

      const birthDateThisYear = moment([thisYear, month - 1, day])
      const startOfYear = moment([thisYear - 1, month - 1, day])

      const isLeapYear = today.isLeapYear()
      const totalDaysInYear = isLeapYear ? 366 : 365

      let daysInYear = null
      daysInYear = birthDateThisYear.diff(startOfYear, 'days')
      if (daysInYear === totalDaysInYear && (thisYear.toString().substring(0, 2) + years) === thisYear.toString()) {
        daysInYear = 0
      } else {
        --daysInYear
      }
      return `${day} ${this.getMonthThai(month)} ${year + 543} (${years}Y ${daysInYear}D)`
    },
    getMonthThai (month) {
      const monthsThai = [
        'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.',
        'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'
      ]

      return monthsThai[month - 1]
    },
    onClickDatePicker () {
      const datepicker = document.getElementById('datepicker-custom')
      datepicker.focus()
    },
    /* handleImageUpload (event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.imageUrl = reader.result
      }
    }, */
    handleImageUpload2 (event) {
      const file2 = event.target.files[0]
      const reader2 = new FileReader()
      reader2.readAsDataURL(file2)
      reader2.onload = () => {
        this.imageUrl2 = reader2.result
      }
    },
    handleClick () {
      router.push('/AddSupply')
    },
    onPagination (page) {
      this.currentPage = page
    },
    setFrom () {
      this.from = this.$route.params.from
    },
    setPage () {
      this.mode = this.$route.params.setPage
      if (this.mode === 'view') {
        this.disableCheck = true
      }
    },
    setId () {
      this.id = this.$route.params.id
    },
    ChangeStatus1 () {
      this.statusButton1 = true
      this.statusButton2 = false
    },
    ChangeStatus2 () {
      this.statusButton2 = true
      this.statusButton1 = false
    },
    chagecanvas () {
      // JSON.stringify(this.$refs.VueCanvasDrawing.getAllStrokes())
      // JSON.parse(window.localStorage.getItem('vue-drawing-canvas'))
      this.initialImage1 = this.$refs.VueCanvasDrawing1?.getAllStrokes() || []
      this.initialImage2 = this.$refs.VueCanvasDrawing2?.getAllStrokes() || []
    },
    ChangeDraw1 () {
      this.eraser1 = false
    },
    ChangeEraser1 () {
      this.eraser1 = true
    },
    ChangeDraw2 () {
      this.eraser2 = false
    },
    ChangeEraser2 () {
      this.eraser2 = true
    },
    UpdateStatusPrdSer (status, indexBill) {
      for (let item of this.serviceBillList[indexBill].arrList.productList) {
        if (!item.isNew) {
          item.status = status

          if (status === 'delete') {
            const _find = this.dataDelList.find(el => el.soo_id === item.soo_id && item.soo_id)
            if (!_find) {
              this.dataDelList.push(item)
            }
          } else if (status === 'update') {
            const _findIndex = this.dataDelList.findIndex(el => el.soo_id === item.soo_id && item.soo_id)
            if (_findIndex !== -1) {
              this.dataDelList.splice(_findIndex, 1)
            }
          }
        }
      }
      for (let item of this.serviceBillList[indexBill].arrList.serviceList) {
        if (!item.isNew) {
          item.status = status

          if (status === 'delete') {
            const _find = this.dataDelList.find(el => el.soo_id === item.soo_id && item.soo_id)
            if (!_find) {
              this.dataDelList.push(item)
            }
          } else if (status === 'update') {
            const _findIndex = this.dataDelList.findIndex(el => el.soo_id === item.soo_id && item.soo_id)
            if (_findIndex !== -1) {
              this.dataDelList.splice(_findIndex, 1)
            }
          }
        }
      }
    },
    SelectBill (id, tab, indexBill) {
      if (this.dataSelect.includes(id)) {
        const _index = this.dataSelect.indexOf(id)
        if (_index !== -1) {
          this.dataSelect.splice(_index, 1)
        }

        this.serviceBillList[indexBill].is_select = false
        // this.serviceBillList[indexBill].check = false
        this.UpdateStatusPrdSer('delete', indexBill)
        this.activeTab2 = this.dataSelect[0] === 1 ? 'tab1' : 'tab2'
      } else {
        this.serviceBillList[indexBill].is_select = true
        // this.serviceBillList[indexBill].check = true
        this.UpdateStatusPrdSer('update', indexBill)

        this.dataSelect.push(id)
        if (this.dataSelect.length === 1) {
          this.activeTab2 = tab
          this.onChangeActiveBillList(id)
          /* this.serviceBillList[0].check = true
          this.rows1 = this.serviceBillList[0].arrList.productList
          this.rows2 = this.serviceBillList[0].arrList.serviceList */
        }
      }
    },
    loadBackgroundImage (path, keySet) {
      const image = new Image()
      image.src = path // Replace with your actual image path
      image.onload = () => {
        // this.backgroundImage = image
        if (keySet) {
          this[keySet] = image
        } else {
          this.backgroundImageTemp = image // Store the loaded image
        }
      }
    },
    async recreateCanvasTemp (addImageKey, key) {
      this.canvasDetails[addImageKey][key] = false
      this.$nextTick(() => {
        setTimeout(() => {
          this.canvasDetails[addImageKey][key] = true
        }, 0)
      })
    },
    async ChangeBack (formRef, fromButton, key) {
      if (!formRef && fromButton && !key) {
        this.addImage = !this.addImage
      } else if (!formRef && fromButton && key) {
        this.addImageKey = key
        this.addImageObj[key] = !this.addImageObj[key]
      }

      this.isLockToggle = false
      this.isLock = false
      this.isEraser = false
      this.loadBackgroundImage(this.canvasDetails[this.addImageKey].bg_img_draw, 'backgroundImageKonva')
      this.canvasDetails[this.addImageKey].initial_img_draw = JSON.parse(JSON.stringify(this.addImageObj[`${this.addImageKey}_draw`])) || []
      this.showModalCanvas = true
      setTimeout(() => {
        if (this.$refs.layer) {
          this.$refs.layer.getNode().batchDraw()
        }
      }, 500)
    },
    clickCancelModal (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.SwalAlertConfirm('คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่', 'warning')
        .then((result) => {
          if (result.isConfirmed) {
            this.modalAdd1 = false
            this.modalAdd2 = false
            this.modalMode = false
            // this.modalEdit = false
            // router.push('/EmployeeGroup')
          }
        })
    },
    GetDataAddOfModal (item, mode) {
      if (item.is_prodcut) {
        return {
          index: mode === 'edit' ? this.dataModal1.index : (item.arrList['productList'].length),
          id: this.dataModal1.id || null,
          p_id: this.dataModal1.product.data.id,
          variable1: this.dataModal1.product.data.no,
          variable2: this.dataModal1.product.data.name,
          variable3: this.dataModal1.product.data.compound,
          variable4: this.dataModal1.product.data.brand_name,
          variable5: this.dataModal1.product.data.img_path,
          variable6: this.dataModal1.product.data.unit_name,
          variable7: this.dataModal1.amountAddModal,
          isNew: !this.dataModal1.id,
          status: (mode === 'edit' && this.dataModal1.id) ? 'edit' : 'add'
        }
      } else {
        const temp = {
          brand: [],
          amount: []
        }
        for (let tl of this.dataModal2.rawmat) {
          if (!tl.brand || !tl.amount) {
            continue
          }
          temp.brand.push(tl.brand.label)
          temp.amount.push(tl.amount)
        }
        return {
          index: (item.arrList['serviceList'].length),
          id: this.dataModal2.id || null,
          ser_id: this.dataModal2.category.value,
          variable1: this.dataModal2.category.label,
          variable2: this.dataModal2.category.label,
          variable3: this.dataModal2.typeUsege,
          variable4: this.dataModal2.amountAddModal,
          variable5: temp.brand.join(', '),
          variable6: temp.amount.join(', '),
          rawmat: this.dataModal2.rawmat,
          isNew: !this.dataModal2.id,
          status: (mode === 'edit' && this.dataModal1.id) ? 'edit' : 'add'
        }
      }
    },
    clickOkModal (mode) {
      this.SwalAlertConfirm(`คุณมั่นใจว่าคุณจะ${mode === 'edit' ? 'แก้ไขข้อมูลใช่หรือไม่' : 'บันทึกข้อมูลใช่หรือไม่'}`, 'warning')
        .then((result) => {
          if (result.isConfirmed) {
            this.rows1 = []
            this.rows2 = []
            let temp = null
            for (let item of this.serviceBillList) {
              if (item.check) {
                const tempPush = this.GetDataAddOfModal(item, mode)
                const objList = {
                  arrListOf: item.is_prodcut ? 'productList' : 'serviceList',
                  indexEdit: this[`dataModal${item.is_prodcut ? '1' : '2'}`].index || 0
                }

                if (mode === 'edit') {
                  item.arrList[objList.arrListOf][objList.indexEdit] = tempPush
                } else {
                  item.arrList[objList.arrListOf].push(tempPush)
                }

                temp = item
              }
            }

            this.rows1 = [ ...temp.arrList.productList ]
            this.rows2 = [ ...temp.arrList.serviceList ]
            this.modalAdd1 = false
            this.modalAdd2 = false
            this.modalMode = false
            // this.modalEdit = false
            // router.push('/EmployeeGroup')
          }
        })
    },
    ClickEditModal (dataEdit, isProduct) {
      if (isProduct) {
        this.dataModal1 = {
          id: dataEdit.id,
          amountAddModal: dataEdit.variable7,
          product: { value: dataEdit.p_id, label: dataEdit.variable1 },
          index: dataEdit.index
        }
        this.modalAdd1 = true
      } else {
        this.dataModal2 = {
          id: dataEdit.id,
          category: { value: dataEdit.ser_id, label: dataEdit.variable1 },
          typeUsege: dataEdit.variable3,
          amountAddModal: dataEdit.variable4,
          rawmat: dataEdit.rawmat,
          index: dataEdit.index
        }
        this.modalAdd2 = true
      }
      this.modalMode = true
    },
    CanSaveAddModal (from) {
      let canSave = true
      if (from === 'product') {
        canSave = (!this.dataModal1.amountAddModal)
      } else if (from === 'service') {
        canSave = (!this.dataModal2.amountAddModal)
      }
      return canSave
    },
    viewProfile (mode, id) {
      this.SwalAlertConfirm('คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่', 'warning')
        .then((result) => {
          if (result.isConfirmed) {
            router.push(`/Patient/${mode}/${id}`)
          }
        })
    },
    historyService (mode, id) {
      this.SwalAlertConfirm('คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่', 'warning')
        .then((result) => {
          if (result.isConfirmed) {
            router.push(`/HistoryService/${mode}/${id}`)
          }
        })
    },
    backPage () {
      if (this.mode === 'add' || this.mode === 'edit') {
        this.SwalAlertConfirm('คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่', 'warning')
          .then((result) => {
            if (result.isConfirmed) {
              router.go(-1)
            }
          })
      } else {
        router.go(-1)
      }
    },
    getPositionMouseDown (e) {
      this.mouseDown = {
        screenX: e.screenX,
        screenY: e.screenY,
        clientX: e.clientX,
        clientY: e.clientY
      }
    },
    getPositionMouseUp (e) {
      this.mouseUp = {
        screenX: e.screenX,
        screenY: e.screenY,
        clientX: e.clientX,
        clientY: e.clientY
      }
      this.checkDrawSign()
    },
    checkDrawSign () {
      if (this.mouseUp && this.mouseDown) {
        if (
          this.mouseUp.screenX !== this.mouseDown.screenX ||
          this.mouseUp.screenY !== this.mouseDown.screenY ||
          this.mouseUp.clientX !== this.mouseDown.clientX ||
          this.mouseUp.clientY !== this.mouseDown.clientY
        ) {
          this.yetDrawSign = false
        } else {
          this.yetDrawSign = true
        }
      }
    },
    async checkError (isClose) {
      let error = []

      if (!this.searchData) {
        error.push('กรุณาเลือก Customer')
      }

      if (!this.dataForSave.weight) {
        error.push('กรุณากรอกน้ำหนัก')
      }

      if (!this.dataForSave.height) {
        error.push('กรุณากรอกส่วนสูง')
      }

      if (!this.dataForSave.doctor) {
        error.push('กรุณาเลือกแพทย์')
      }

      if (!this.dataForSave.assistDoctor) {
        error.push('กรุณาเลือกผู้ช่วยแพทย์')
      }

      if (this.drawLinesSign.length === 0) {
        error.push('กรุณาเซ็นลายเซ็น')
      }

      if (isClose && !this.dataForSave.rangeFollow) {
        error.push('กรุณาเลือกวันระยะเวลาติดตามผล')
      }

      return error
    },
    clickSave () {
      this.SwalAlertConfirm(`คุณมั่นใจว่าคุณจะ${this.mode === 'edit' ? 'แก้ไขข้อมูลใช่หรือไม่' : 'บันทึกข้อมูลใช่หรือไม่'}`, 'warning')
        .then(async (result) => {
          if (result.isConfirmed) {
            const errorList = await this.checkError()
            if (errorList.length > 0) {
              let errMessage = ''
              for (let e = 0; e < errorList.length; e++) {
                errMessage += '<br/>' + errorList[e]
              }
              this.SwalError(errMessage, 'บันทึกข้อมูลไม่สำเร็จ')
              return
            }
            await this.SaveDataPatientService()
            /* Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              router.go(-1)
            }) */
          }
        })
    },
    async SaveDataPatientService () {
      Vue.swal.fire({
        title: '<span class="loading-icon"><i class="fas fa-spinner fa-spin"></i></span>',
        text: 'กำลังบันทึก...',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          Vue.swal.getHtmlContainer().querySelector('.loading-icon').style.fontSize = '40px'
        }
      })
      const temp = this.FormatDataForSave()
      // console.log('temp', temp)
      // Vue.swal.close()
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/serviceUsage/${this.cln_id}/${this.mode === 'edit' ? (this.id + '/') : ''}Patient`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: temp
      })
        .then(async (response) => {
          Vue.swal.close()
          if (response.data.status) {
            if (this.dataForSave.img_treat_file && response.data.data.id) {
              await this.SaveImage(response.data.data.id, this.dataForSave.img_treat_file, '/treat/img')
            }
            if (this.dataForSave.img_other_file && response.data.data.id) {
              await this.SaveImage(response.data.data.id, this.dataForSave.img_other_file, '/other/img')
            }
            if (this.dataForSave.img_file_file && response.data.data.id) {
              await this.SaveImage(response.data.data.id, this.dataForSave.img_file_file, '/file/doc')
            }
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              router.go(-1)
              // window.location.reload()
            })
          } else if (temp.hc_is_close) {
            this.SwalError(response.data.error, 'ปิดการให้บริการไม่สำเร็จ')
          }
        })
        .catch((error) => {
          Vue.swal.close()
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async SaveImage (ID, file, pathBack) {
      let data = new FormData()
      data.append('file', file)
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/serviceUsage/${ID}/upload${pathBack}`, // img
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
        .then(async (response) => {
        })
        .catch((error) => {
          this.SwalError(error.message, 'บันทึกรูปภาพไม่สำเร็จ')
        })
    },
    async SaveOtherImage (ID) {
      let data = new FormData()
      data.append('file', this.dataForSave.img_other_file)
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/serviceUsage/${ID}/upload/other/img`, // doc
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
        .then(async (response) => {
        })
        .catch((error) => {
          this.SwalError(error.message, 'บันทึกรูปภาพไม่สำเร็จ')
        })
    },
    async SaveFile (ID) {
      let data = new FormData()
      data.append('file', this.dataForSave.img_file_file)
      await axios.request({
        method: 'post',
        url: `${configAPI.MA_PORT}/api/serviceUsage/${ID}/upload/file/doc`, // doc
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
        .then(async (response) => {
        })
        .catch((error) => {
          this.SwalError(error.message, 'บันทึกไฟล์ไม่สำเร็จ')
        })
    },
    FormatDataForSave () {
      try {
        return {
          hc_opd_no: this.dataForSave.OPD_no,
          hc_cus_id: this.searchData.id,
          hc_weight: this.convertStringToNumber(this.dataForSave.weight || null),
          hc_hight: this.convertStringToNumber(this.dataForSave.height || null),
          hc_body_temperature: this.convertStringToNumber(this.dataForSave.body_temp || null),
          hc_pressure: this.dataForSave.blood_pressure || null, // this.convertStringToNumber(this.dataForSave.blood_pressure || null),
          hc_pulse_rate: this.convertStringToNumber(this.dataForSave.pulse_rate || null),
          hc_breathing_rate: this.convertStringToNumber(this.dataForSave.breathing_rate || null),
          hc_fat: this.convertStringToNumber(this.dataForSave.fat || null),
          hc_muscle: this.convertStringToNumber(this.dataForSave.muscle || null),
          hc_doctor_id: this.dataForSave?.doctor?.value || null,
          hc_assistant_id: this.dataForSave?.assistDoctor?.value === 'other' ? null : this.dataForSave?.assistDoctor?.value || null,
          hc_note: this.dataForSave.note || null,
          hc_follow_date: this.dataForSave.rangeFollow ? moment(this.dataForSave.rangeFollow, 'DD/MM/YYYY HH:mm') : null,
          hc_is_close: this.dataForSave.is_close,
          hc_area_remark: JSON.stringify({ data: this.$refs.VueCanvasDrawing2?.getAllStrokes() || [], hasImg: this.addImageObj }),
          hc_sign: JSON.stringify({ data: this.drawLinesSign || [] }),
          hc_so_list: this.serviceBillList,
          user_id: this.user_id,
          cln_id: this.cln_id,
          hn_no_cus: this.searchData.hn_no,
          del_so_list: this.dataDelList,
          hc_male: JSON.stringify({ data: this.addImageObj.man_pic_draw || [] }),
          hc_female: JSON.stringify({ data: this.addImageObj.wom_pic_draw || [] }),
          hc_lip: JSON.stringify({ data: this.addImageObj.lip_pic_draw || [] }),
          hc_assistant_name: this.inputAssistDoctor?.trim() || null,
          hc_on_image: this.addImageKey
        }
      } catch (error) {
        this.SwalError(error.message, 'เกิดข้อผิดพลาดในการจัดข้อมูลก่อน save')
      }
    },
    convertStringToNumber (value) {
      return value
        ? Number(value?.toString().replace(/,/g, '') || 0) || null
        : null
    },
    remove1 (item) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการลบข้อมูลใช่หรือไม่</span>',
        html: '<span class="" style="font-size: 14px; color: black; text-align: center">ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let index = this.rows1.indexOf(item)
          if (!item.isNew) {
            item.status = 'delete'
            this.dataDelList.push(item)
          }
          this.rows1.splice(index, 1)
          // Vue.swal({
          //   title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
          //   icon: 'success',
          //   allowEscapeKey: false,
          //   allowOutsideClick: false,
          //   showConfirmButton: false,
          //   timer: 2000
          // })
        }
      })
    },
    remove2 (item) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการลบข้อมูลใช่หรือไม่</span>',
        html: '<span class="" style="font-size: 14px; color: black; text-align: center">ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let index = this.rows2.indexOf(item)
          if (!item.isNew) {
            item.status = 'delete'
            this.dataDelList.push(item)
          }
          this.rows2.splice(index, 1)
          // Vue.swal({
          //   title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
          //   icon: 'success',
          //   allowEscapeKey: false,
          //   allowOutsideClick: false,
          //   showConfirmButton: false,
          //   timer: 2000
          // })
        }
      })
    },
    remove3 (item) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการลบข้อมูลใช่หรือไม่</span>',
        html: '<span class="" style="font-size: 14px; color: black; text-align: center">ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let index = this.rows3.indexOf(item)
          this.rows3.splice(index, 1)
          // Vue.swal({
          //   title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
          //   icon: 'success',
          //   allowEscapeKey: false,
          //   allowOutsideClick: false,
          //   showConfirmButton: false,
          //   timer: 2000
          // })
        }
      })
    },
    remove4 (item) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการลบข้อมูลใช่หรือไม่</span>',
        html: '<span class="" style="font-size: 14px; color: black; text-align: center">ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let index = this.rows4.indexOf(item)
          this.rows4.splice(index, 1)
          // Vue.swal({
          //   title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
          //   icon: 'success',
          //   allowEscapeKey: false,
          //   allowOutsideClick: false,
          //   showConfirmButton: false,
          //   timer: 2000
          // })
        }
      })
    },
    SwalAlertConfirm (textTitle, iconText) {
      return Vue.swal({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${textTitle}</span>`,
        icon: iconText,
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    }
  },
  created () {
    this.setFrom()
    this.setPage()
    this.setId()
  },
  async mounted () {
    const data = JSON.parse(sessionStorage.getItem('user'))
    this.cln_id = data.cln_id
    this.user_id = data.usr_id
    await this.GetEmployeeDoctor()
    await this.getProduct()
    await this.getServiceGroup()
    await this.GetBrand()
    if (this.mode !== 'add') {
      if (this.mode !== 'view') {
        this.options.minDate = new Date()
      }
      await this.GetCustomerHistory()
    } else {
      await this.searchPhoneCustomer()
    }
    await this.GetSaleOrderCustomer()
    const widthDocument = document.documentElement.scrollWidth
    this.canvasModalWidth = widthDocument > 800 ? 800 : widthDocument - 100
    // this.loadBackgroundImage(manPicture, 'backgroundImageKonva')
    xray.index()
  },
  watch: {
    'isLock': function (newVal, oldVal) {
      this.isLockToggle = false
    },
    activeTab: function (newVal, oldVal) {
      if (newVal === 'tab3') {
        setTimeout(() => {
          this.$refs.layerSign.getNode().batchDraw()
          this.$refs.layer_konva_view.getNode().batchDraw()
        }, 200)
      }
    },
    'dataForSave.doctor': function (newVal, oldVal) {
      this.assistDoctorDD = this.assistDoctorDD.filter(el => el.value !== newVal?.value)
    },
    'dataForSave.assistDoctor': function (newVal, oldVal) {
      if (newVal.value && newVal.value === 'other' && oldVal.value !== 'other') {
        this.inputAssistDoctor = ''
      }
      this.doctorDD = this.doctorDD.filter(el => el.value !== newVal?.value)
    }
    // 'serviceBillList': {
    //   handler: function (newVal, oldVal) {
    //     console.log('newVal', newVal)
    //   },
    //   deep: true
    // }
  }
}
</script>
