<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>

          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">

              <div class="col-auto">
                <span class="unselectable" style="font-size: 25px; color: black;">ใบรายการขายสินค้า/บริการ</span>
              </div>

              <div class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button @click="clickPath('add')" type="button" variant="primary-custom" class="w-100 btn-min-size">
                  <i class="circle-plus_ct"></i>
                  เพิ่มใบเสนอราคา
                </b-button>
              </div>

            </div>
          </template>

          <template v-slot:body>
            <div class="mx-3">
              <b-row style="align-items: flex-end;">

                <b-col cols="12" lg="3" class="pb-3">
                  <div class="iq-email-search d-flex">
                    <div style="width: 100%;">
                      <div class="form-group mb-0" style="height: 45px;">
                        <input v-model="search" @keydown="enterSearch" class="form-control" id="exampleInputEmail1" placeholder="Search"
                          style="width: 100%; height: 45px;">
                        <!-- <a class="search-link" @click="getQuotationBySale"><i class="ri-search-line" /></a> -->
                        <i style="color: #676767; top: 4px" class="search-link ri-search-line" />
                      </div>
                    </div>
                  </div>
                </b-col>

                <b-col cols="6" lg="2" class="pb-3">
                  <b-text style="color: black; white-space: nowrap">วันที่ใบเสนอราคา (เริ่มต้น)</b-text>
                  <div style="position: relative; margin-top: .4rem;" class="w-res-attendance time-picker-attendance">
                    <date-picker v-if="searchEndDate" placeholder="เลือกวันที่" id="datepicker-custom-1"
                      :config="optionsDatepickerStart" v-model="searchStartDate"></date-picker>
                    <date-picker v-else placeholder="เลือกวันที่" id="datepicker-custom-1"
                      :config="optionsDatepickerDefault" v-model="searchStartDate"></date-picker>
                    <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(1)"
                      style="cursor: pointer;"></i>
                  </div>
                </b-col>

                <b-col cols="6" lg="2" class="pb-3">
                  <b-text style="color: black; white-space: nowrap">วันที่ใบเสนอราคา (สิ้นสุด)</b-text>
                  <div style="position: relative; margin-top: .4rem;" class="w-res-attendance time-picker-attendance">
                    <date-picker v-if="searchStartDate" placeholder="เลือกวันที่" id="datepicker-custom-2"
                      :config="optionsDatepickerEnd" v-model="searchEndDate"></date-picker>
                    <date-picker v-else placeholder="เลือกวันที่" id="datepicker-custom-2"
                      :config="optionsDatepickerDefault" v-model="searchEndDate"></date-picker>
                    <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(2)"
                      style="cursor: pointer;"></i>
                  </div>
                </b-col>

                <b-col cols="12" lg="auto" class="pb-3">
                  <b-button @click="getQuotationBySale" type="button" variant="primary-custom" class="w-100 btn-min-size"
                    style="height: 45px;">
                    <i class="ri-search-line"></i>
                    ค้นหา
                  </b-button>
                </b-col>

                <b-col cols="auto" class="pb-3">
                  <div style="display: flex; align-items: center; height: 45px;">
                    <span class="mx-2 font-size-14 text-primary-custom">
                      {{ rows_quotation.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage *
                        currentPage) > rows_quotation?.length) ? rows_quotation.length : (perPage * currentPage)
                      ) + ' of ' + rows_quotation_length : '0 of ' + rows_quotation_length }} รายการ
                    </span>
                  </div>
                </b-col>

              </b-row>
            </div>

            <b-col cols="12">
              <div class="table-responsive">
                <b-table bordered hover :items="rows_quotation" :fields="columns_quotation" :current-page="currentPage"
                  :per-page="perPage" @pagination="onPagination" :tbody-tr-class="rowClass"
                  thead-class="table-header-color">

                  <template v-slot:cell(no)="data">
                    <span>{{ ((currentPage - 1) * 10) + data.index + 1 }}</span>
                  </template>

                  <template v-slot:cell(quotation_no)="data">
                    <span>{{ data.item.quo_no || '-' }}</span>
                    <!-- <span v-if="data.item.quo_count === '0'">{{ data.item.quo_no || '-' }}</span>
                      <a v-else style="color: #479CAC; cursor: pointer;" @click="ShowModalVersionQuo(data.item)"><span>{{ data.item.quo_no || '-' }}</span></a> -->
                  </template>

                  <template v-slot:cell(customer_name)="data">
                    <span>{{ data.item.quo_cus_name || '-' }}</span>
                  </template>

                  <template v-slot:cell(quotation_date)="data">
                    <span>{{ formatDate(data.item.quo_date) || '-' }}</span>
                  </template>

                  <template v-slot:cell(price)="data">
                    <span>{{ formatNumber(data.item.quo_total_price) || '-' }}</span>
                  </template>

                  <template v-slot:cell(status)="data">
                    <b-button v-if="data.item.quo_qs_id === 'd450c15f-5cd2-4fdd-9736-c60e73be6fbb'"
                      variant=" iq-bg-warning-custom btn-warning-border" style="min-width: 100px; cursor:default;">{{
                        data.item.qs_name }}</b-button>

                    <b-button v-else-if="data.item.quo_qs_id === '91c37841-61cf-4208-8fcf-3002d46e0cd7'"
                      variant=" iq-bg-success-custom btn-success-border" style="min-width: 100px; cursor:default;">{{
                        data.item.qs_name }}</b-button>

                    <b-button v-else-if="data.item.quo_qs_id === 'b3848d1a-cc46-4744-8158-58a6f8908869'"
                      variant=" iq-bg-info-custom btn-info-border" style="min-width: 100px; cursor:default;">{{
                        data.item.qs_name }}</b-button>

                    <b-button v-else-if="data.item.quo_qs_id === 'c24fdbfb-6222-4842-8fd1-98b23839ccae'"
                      variant=" bg-danger-custom" style="min-width: 100px;"
                      @click.prevent="reasonModal = true, reasonTextShow = data.item.quo_reason_not_approve">{{
                        data.item.qs_name }}</b-button>

                    <span v-else>{{ '-' }}</span>
                  </template>

                  <template v-slot:cell(approve_name)="data">
                    <span>{{ data.item.quo_approve_name || '-' }}</span>
                  </template>

                  <template v-slot:cell(management)="data">
                    <div style="display: flex;">
                      <b-button variant=" iq-bg-report-custom btn-report-border mx-1" size="sm"
                        style="cursor: not-allowed;">
                        <i class="ri-article-line m-0"></i>
                      </b-button>

                      <b-button variant=" iq-bg-eye-custom btn-eye-border mx-1" size="sm"
                        @click="clickPath('view', data.item.quo_id)">
                        <i class="ri-eye-fill m-0"></i>
                      </b-button>

                      <b-button v-if="data.item.quo_qs_id !== 'b3848d1a-cc46-4744-8158-58a6f8908869'"
                        variant=" iq-bg-pencil-custom btn-pencil-border mx-1" size="sm"
                        @click="clickPath('edit', data.item.quo_id)">
                        <i class="ri-pencil-fill m-0"></i>
                      </b-button>

                      <b-button v-if="data.item.quo_qs_id !== 'b3848d1a-cc46-4744-8158-58a6f8908869'"
                        variant=" iq-bg-bin-custom btn-bin-border mx-1" size="sm"
                        @click="deleteQuotation(data.item.quo_id)">
                        <i class="ri-delete-bin-line m-0"></i>
                      </b-button>

                      <b-button
                        v-if="data.item.quo_qs_id === '91c37841-61cf-4208-8fcf-3002d46e0cd7' || data.item.quo_qs_id === 'b3848d1a-cc46-4744-8158-58a6f8908869'"
                        variant=" iq-bg-genso-custom btn-genso-border mx-1" size="sm" @click="openGenSOModal(data.item)">
                        SO
                      </b-button>
                    </div>
                  </template>

                </b-table>
              </div>
            </b-col>

            <b-pagination v-model="currentPage" :total-rows="rows_quotation?.length" :per-page="perPage" align="center" />

          </template>
        </iq-card>
      </b-col>
    </b-row>

    <!-- GEN SO Modal -->
    <b-modal v-model="genSOModal" title="สร้างคำสั่งซื้อ" :size="'lg'" @close.prevent="genSOModal = false"
      no-close-on-backdrop>
      <div>
        <b-row v-if="files.quo_qs_id === '91c37841-61cf-4208-8fcf-3002d46e0cd7'">
          <b-col lg="6" md="8" sm="12" class="d-flex custom-flex-abs">
            <span style="color: black">อัปโหลดหลักฐานการโอน / ไฟล์ที่เกี่ยวข้อง</span>
            <b-form-file class="custom-file-style" ref="imageInputRef" id="imageInput" accept="image/*, application/pdf"
              multiple v-model="files.fileSelect" @change="handleImageUpload"
              :placeholder="files.fileLastest ? files.fileLastest : ''" browse-text="เลือกไฟล์"></b-form-file>
          </b-col>
          <b-col lg="3" md="4" sm="12" class="mt-4" style="display: flex; height:45px">
            <b-button variant="primary-custom" class="btn-min-size" style="border-radius: 0.5rem;" @click="GenerateSO">
              Generate SO
            </b-button>
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col cols="12" class="table-responsive">
            <b-table bordered hover :items="rows_upload" :fields="columns_upload" :current-page="currentPageModal"
              :per-page="perPageModal" @pagination="onPaginationModal" :tbody-tr-class="rowClass"
              thead-class="table-header-color">
              <template v-slot:cell(no)="data">
                <span>{{ data.index + 1 }}</span>
              </template>
              <template v-slot:cell(files)="data">
                <span>{{ data.item.file_name }}</span>
              </template>
              <template v-slot:cell(management)="data">
                <div style="display: flex; justify-content: center;">
                  <b-button variant=" iq-bg-info mr-1" size="sm" @click="downloadfile(data.item)">
                    <i class="ri-download-fill m-0"></i>
                  </b-button>
                  <b-button v-if="files.quo_qs_id === '91c37841-61cf-4208-8fcf-3002d46e0cd7'" variant=" iq-bg-danger mr-1"
                    size="sm" @click="deletefile(data.index)">
                    <i class="ri-delete-bin-line m-0"></i>
                  </b-button>
                </div>
              </template>
            </b-table>
            <br>
            <b-pagination v-model="currentPageModal" :total-rows="rows_quo_version.length" :per-page="perPageModal"
              align="center" />
          </b-col>
        </b-row>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <p class="float-left"></p>
          <b-button variant="none" size="sm" class="float-right iq-bg-danger btn-cancel-border btn-min-size"
            style="border-radius: 0.5rem;" @click="genSOModal = false">
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Reason Modal  -->
    <b-modal v-model="reasonModal" title="ไม่ผ่านการอนุมัติ" size='lg' centered @close.prevent="reasonModal = false"
      hide-footer>
      <div style="margin: 10px 30px;">
        <span style="word-break: break-word; color: black;">{{ reasonTextShow }}</span>
      </div>
    </b-modal>

  </b-container>
</template>

<style scoped>
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import Axios from 'axios'
import moment from 'moment'
import { configAPI } from '../../configBase'

const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}

export default {
  name: 'QuotationTable',

  data () {
    return {
      clnId: null,
      userId: null,
      search: '',
      searchStartDate: null,
      searchEndDate: null,
      screenWidth: 0,
      selectOption: { label: 'Table', code: 'Table' },
      options: [
        { label: 'Design', code: 'Design' },
        { label: 'Table', code: 'Table' }
      ],
      mode: 'showAll',
      detailsMoveCus: {
        cus_id: null,
        cus_name: null,
        cus_sale: null,
        cus_date: null
      },
      sales: [],
      columns_quotation: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        { label: 'เลขที่ใบเสนอราคา', key: 'quotation_no', class: 'text-center table-size-200-400' },
        { label: 'ชื่อลูกค้า', key: 'customer_name', class: 'text-center table-size-200-400' },
        { label: 'วันที่ใบเสนอราคา', key: 'quotation_date', class: 'text-center table-size-200-400' },
        { label: 'ราคา', key: 'price', class: 'text-center table-size-200-400' },
        { label: 'สถานะ', key: 'status', class: 'text-center table-size-200-400' },
        { label: 'ชื่อผู้อนุมัติ', key: 'approve_name', class: 'text-center table-size-200-400' },
        { label: 'Management', key: 'management', class: 'text-center table-size-240-440' }
      ],
      columns_quo_version: [
        { label: 'No', key: 'no', class: 'text-center' },
        { label: 'Quotation No.', key: 'quotation_no', class: 'text-center table-size-200-400' },
        { label: 'Version', key: 'quo_version', class: 'text-center' },
        { label: 'Status', key: 'status', class: 'text-center' },
        { label: 'Management', key: 'management', class: 'text-center' }
      ],
      columns_upload: [
        { label: 'ลำดับ', key: 'no', class: 'text-center' },
        { label: 'เอกสาร', key: 'files', class: 'text-center table-size-200-400 ellips' },
        { label: 'จัดการ', key: 'management', class: 'text-center' }
      ],
      rows_quotation: [],
      rows_quotation_length: null,
      rows_quo_version: [],
      rows_upload: [],
      historyServiceData: [],
      currentPage: 1,
      perPage: 10,
      currentPageModal: 1,
      perPageModal: 10,
      AllDataLength: null,
      transferModal: false,
      quotationVersionModal: false,
      genSOModal: false,
      reasonModal: false,
      reasonTextShow: '',
      files: {
        fileCollection: [],
        fileSelect: [],
        fileLastest: null,
        quo_id: null,
        quo_qs_id: null
      },
      optionsDatepickerDefault: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: false,
        minDate: false,
        maxDate: false,
        icons: iconDate
      },
      optionsDatepickerStart: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: false,
        minDate: false,
        maxDate: false,
        icons: iconDate
      },
      optionsDatepickerEnd: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: false,
        minDate: false,
        maxDate: false,
        icons: iconDate
      }
    }
  },

  computed: {
  },

  watch: {
    'searchStartDate': function (newDate) {
      this.optionsDatepickerEnd.minDate = newDate
    },
    'searchEndDate': function (newDate) {
      this.optionsDatepickerStart.maxDate = newDate
    }
  },

  async mounted () {
    this.clnId = JSON.parse(sessionStorage.getItem('user')).cln_id || null
    this.userId = JSON.parse(sessionStorage.getItem('user')).usr_id || null

    await this.getQuotationBySale()

    xray.index()
  },

  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getQuotationBySale()
      }
    },
    formatDate (date) {
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      } else {
        return '-'
      }
    },

    formatNumber (number) {
      if (number) {
        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } else {
        return '-'
      }
    },

    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },

    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.no % 2 === 1) return 'table-body-color'
    },

    // async enterSearch (e) {
    //   if (e.key === 'Enter') {
    //     await this.getQuotationBySale()
    //   }
    // },

    onClickDatePicker (number) {
      let datePicker = null
      datePicker = document.getElementById(`datepicker-custom-${number}`)
      datePicker.focus()
    },

    onPagination (page) {
      this.currentPage = page
    },

    onPaginationModal (page) {
      this.currentPageModal = page
    },

    clickPath (e, data) {
      const setPage = e
      if (setPage === 'add') {
        router.push(`/Quotation/mgt/${setPage}`)
      } else if (setPage === 'view') {
        router.push(`/Quotation/mgt/${setPage}/${data}`)
      } else if (setPage === 'edit') {
        router.push(`/Quotation/mgt/${setPage}/${data}`)
      } else if (setPage === 'history') {
        router.push(`/Quotation/history/${data}`)
      }
    },

    async getQuotationBySale () {
      const searchData = {
        search: this.search.trim() || null,
        searchStartDate: this.searchStartDate
          ? moment(this.searchStartDate, 'DD/MM/YYYY').startOf('day')
          : null,
        searchEndDate: this.searchEndDate
          ? moment(this.searchEndDate, 'DD/MM/YYYY').endOf('day')
          : null
      }

      await Axios.request({
        method: 'post',
        url: `${configAPI.SALE_PORT}/api/clinic/${this.clnId}/user/${this.userId}/getQuotationBySale`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: searchData
      }).then((response) => {
        this.rows_quotation = response.data.data
        this.rows_quotation_length = response.data.dataCount
      }).catch((error) => {
        this.SwalError(error.message, 'เกิดข้อผิดพลาดในดึงข้อมูล')
      })
    },

    async deleteQuotation (quoId) {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะลบข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Axios.request({
            method: 'POST',
            url: `${configAPI.SALE_PORT}/api/clinic/${this.clnId}/deleteQuotation/${quoId}`,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              timer: 1500
            }).then(async () => {
              window.location.reload()
            })
          }).catch((err) => {
            this.SwalError(err.message, 'ลบข้อมูลไม่สำเร็จ')
          })
        }
      })
    },

    async openGenSOModal (item) {
      if (item.quo_qs_id === '91c37841-61cf-4208-8fcf-3002d46e0cd7') {
        this.files.fileCollection = []
        this.files.fileLastest = null
        this.files.quo_id = item.quo_id
        this.files.quo_qs_id = item.quo_qs_id
        this.rows_upload = []
        this.genSOModal = !this.genSOModal
      } else {
        // await to axios to get filelist
        this.files.fileCollection = []
        this.files.fileLastest = null
        this.files.quo_id = item.quo_id
        this.files.quo_qs_id = item.quo_qs_id
        await this.getFileByRefId(item.quo_id)
        this.genSOModal = !this.genSOModal
      }
    },

    handleImageUpload (event) {
      this.files.fileSelect = []
      let inputfiles = event.target.files
      let filesCurrent = this.files.fileCollection
      let rowsCurrent = JSON.parse(JSON.stringify(this.rows_upload))
      let tempfiles = [] // for push to this.files.fileCollection
      let tempRows = [] // for push to this.rows_upload
      let datatemp = {
        file_name: null,
        file_type: null,
        file: null
      }
      this.files.fileCollection = []
      this.rows_upload = []
      for (let index = 0; index < inputfiles.length; index++) {
        // check same name in currentfiles if same not push
        let checkSameName = rowsCurrent.findIndex((item) => {
          return item.file_name === inputfiles[index].name
        })
        if (checkSameName === -1) {
          datatemp.file_name = inputfiles[index].name
          datatemp.file_type = inputfiles[index].type
          datatemp.file = inputfiles[index]
          tempfiles.push(inputfiles[index])
          tempRows.push(datatemp)
          datatemp = {
            file_name: null,
            file_type: null,
            file: null
          }
        }
      }
      this.files.fileCollection = filesCurrent.concat(tempfiles)
      this.rows_upload = rowsCurrent.concat(tempRows)
    },

    async checkfiles () {
      let error = []
      if (this.files.fileCollection.length === 0) {
        error.push('กรุณาเลือกไฟล์ที่ต้องการอัพโหลด')
      }
      return error
    },

    async GenerateSO () {
      let error = await this.checkfiles()
      if (error.length > 0) {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
          html: '<span>' + error + '</span>',
          icon: 'warning',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1'
          }
        })
      } else {
        // check confirm swal
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการสร้างคำสั่งซื้อใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            let key = 'quotation'
            let data = new FormData()
            let files = this.files.fileCollection
            for (let i = 0; i < files.length; i++) {
              data.append('file', files[i])
            }
            data.append('user_id', this.userId)
            data.append('key', key)
            let generateSaleOrderAPI = {
              method: 'post',
              url: `${configAPI.SALE_PORT}/api/clinic/${this.clnId}/quotation/${this.files.quo_id}/generateSaleOrder`, // /clinic/:cln_id/quotation/:quo_id/generateSaleOrder
              headers: {
                'Content-Type': 'application/json'
              },
              data: data
            }
            await Axios.request(generateSaleOrderAPI)
              .then((response) => {
                if (response.status) {
                  Vue.swal.fire({
                    title: '<span class="" style="font-size: 25px; color: black; text-align: center">สร้างคำสั่งซื้อสำเร็จ</span>',
                    icon: 'success',
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    timer: 1500
                  }).then(() => {
                    this.genSOModal = false
                    this.getQuotationBySale()
                  })
                }
              })
              .catch((error) => {
                Vue.swal.fire({
                  title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
                  html: '<span>' + error + '</span>',
                  icon: 'warning',
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: true,
                  confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
                  confirmButtonColor: '#089bab',
                  customClass: {
                    confirmButton: 'custom-confirm-button-class mx-1'
                  }
                })
              })
          }
        })
      }
    },

    async downloadfile (fileitem) {
      if (this.files.quo_qs_id === '91c37841-61cf-4208-8fcf-3002d46e0cd7') {
        const blob = new Blob([fileitem.file], { type: fileitem.file_type })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileitem.file_name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        await Axios.request({
          method: 'get',
          url: this.getFullImagePath(fileitem.file_path),
          responseType: 'blob'
        }).then(response => {
          let fileUrl = window.URL.createObjectURL(new Blob([response.data]))
          let fileLink = document.createElement('a')
          fileLink.href = fileUrl
          fileLink.setAttribute('download', fileitem.file_name)
          document.body.appendChild(fileLink)
          fileLink.click()
        }).catch(() => {
          this.SwalError('ไม่สามารถดาวน์โหลดรูปได้', 'เกิดข้อผิดพลาดในดึงข้อมูล')
        })
      }
    },

    deletefile (idx) {
      this.rows_upload.splice(idx, 1)
      this.files.fileCollection.splice(idx, 1)
      this.files.fileSelect = []
      this.files.fileLastest = null
    },

    getFullImagePath (relativePath) {
      if (relativePath) {
        relativePath = relativePath.substring(1)
      }
      const baseURL = `${configAPI.SALE_PORT}`
      return baseURL + relativePath
    },
    async getFileByRefId (id) {
      await Axios.request({
        method: 'get',
        url: `${configAPI.SALE_PORT}/upload/getFileByRefId/${id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        this.rows_upload = response.data.data
      }).catch((error) => {
        this.SwalError(error.message, 'เกิดข้อผิดพลาดในดึงข้อมูล')
      })
    }
  }
}
</script>
