<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="table-responsive">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span v-if="setPage() === 'add'" style="font-size: 25px; color: black;">วัตถุดิบ
                  (เพิ่ม)</span>
                <span v-else-if="setPage() === 'edit'"
                  style="font-size: 25px; color: black;">วัตถุดิบ (แก้ไข)</span>
                <span v-else style="font-size: 25px; color: black;">วัตถุดิบ (ดูรายละเอียด)</span>
              </div>
              <div v-if="setPage() === 'add' || setPage() === 'edit'" class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-6 pl-3 pr-2 my-1 my-sm-0">
                    <b-button type="submit" variant="primary" @click="handleClick(true)" class="w-100 btn-min-size"><i class="save-data_ct"/>บันทึก</b-button>
                  </div>
                  <div class="col-6 pl-2 pr-3 my-1 my-sm-0">
                    <b-button type="submit" variant="none" @click="handleClick(false)" class="w-100 btn-min-size iq-bg-danger btn-cancel-border">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
              <div v-else class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="submit" variant="none" @click="cancelClick()" class="w-100 btn-min-size iq-bg-danger btn-cancel-border">ยกเลิก</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div>
              <div class="mx-3">
                <b class="mr-5" style="white-space: nowrap; cursor: pointer;" @click="activeTab = 'tab1'"
                        :style="{ color: activeTab === 'tab1' ? 'rgb(8, 159, 175)' : 'black' }">ข้อมูลพื้นฐาน</b>
                <b class="mr-5" style="white-space: nowrap; cursor: pointer;" @click="activeTab = 'tab2'"
                        :style="{ color: activeTab === 'tab2' ? 'rgb(8, 159, 175)' : 'black' }">ซัพพลายเออร์</b>
              </div>
              <hr>
              <div class="">
                <div v-if="activeTab === 'tab1'">
                  <b-row>
                    <b-col class="" lg="6" sm="12">
                      <b-form class="mx-3">
                        <b-form-group class="unselectable" style="color: black;" label="หมวดหมู่">
                          <template v-if="materialGroupOption === null || materialGroupOption === ''" v-slot:label>
                            หมวดหมู่<span class="text-danger"> *</span>
                          </template>
                          <v-select v-model="materialGroupOption" :disabled="setPage() === 'view' ? true : false" :options="materialGroupDD" ></v-select>
                        </b-form-group>
                        <b-form-group class="unselectable" style="color: black;" label="แบรนด์">
                          <template v-if="brandOption === null || brandOption === ''" v-slot:label>
                            แบรนด์<span class="text-danger"> *</span>
                          </template>
                          <v-select v-model="brandOption" :disabled="(setPage() === 'view' ? true : false)"  :options="brandDD"></v-select>
                        </b-form-group>
                        <b-form-group class="unselectable" style="color: black;" label="ประเภท">
                          <template v-if="materialData.data[0].mt_id === null || materialData.data[0].mt_id === ''" v-slot:label>
                            ประเภท<span class="text-danger"> *</span>
                          </template>
                          <v-select v-model="typeOption" :disabled="(setPage() === 'view' ? true : false)" :options="typeDD"></v-select>
                        </b-form-group>
                        <b-form-group class="unselectable" style="color: black;" label="หน่วยนับ">
                          <template v-if="materialData.data[0].unit_id === null || materialData.data[0].unit_id === ''" v-slot:label>
                            หน่วยนับ<span class="text-danger"> *</span>
                          </template>
                          <v-select v-model="unitOption" :disabled="setPage() === 'view' ? true : false" :options="unitDD" ></v-select>
                        </b-form-group>
                        <b-form-group class="unselectable" style="color: black;" label="หน่วยนับรอง">
                          <template v-if="materialData.data[0].unit_supply_id === null || materialData.data[0].unit_supply_id === ''" v-slot:label>
                            หน่วยนับรอง<!-- <span class="text-danger"> *</span> -->
                          </template>
                          <v-select v-model="unitSupplyOption" :disabled="setPage() === 'view' ? true : false" :options="unitDD" ></v-select>
                        </b-form-group>
                        <b-form-group class="unselectable" style="color: black;" label="สต๊อกขั้นต่ำ" label-for="exampleInputdate">
                          <b-form-input id="exampleInputdate" :disabled="setPage() === 'view' ? true : false" v-model="materialData.data[0].min_stock"
                            type="text" @input="isNumberComma1($event, 'stock')"
                            @keypress="isNumberCheck($event, true)"></b-form-input>
                        </b-form-group>
                        <b-text class="my-2 " style="color: black; text-align: center; white-space: nowrap;">ประเภท
                          อย.</b-text>
                        <b-input v-if="setPage() === 'add'" v-model="materialData.data[0].fda_type" class="my-2"></b-input>
                        <b-input v-else-if="setPage() === 'view'" disabled v-model="materialData.data[0].fda_type" class="my-2"></b-input>
                        <b-input v-else v-model="materialData.data[0].fda_type" class="my-2"></b-input>
                        <b-text class="my-2 "
                          style="color: black; text-align: center; white-space: nowrap;">เลขทะเบียน</b-text>
                        <b-input v-if="setPage() === 'add'" v-model="materialData.data[0].license" class="my-2"></b-input>
                        <b-input v-else-if="setPage() === 'view'" disabled v-model="materialData.data[0].license" class="my-2"></b-input>
                        <b-input v-else v-model="materialData.data[0].license" class="my-2"></b-input>
                        <b-text class="my-2 "
                          style="color: black; text-align: center; white-space: nowrap;">บาร์โค้ด</b-text>
                        <b-input v-if="setPage() === 'add'" v-model="materialData.data[0].barcode" type="number" @keypress="preventDecimalAndNegative($event)" @input="preventDecimalAndNegative" class="my-2"></b-input>
                        <b-input v-else-if="setPage() === 'view'" disabled v-model="materialData.data[0].barcode" type="number" @keypress="preventDecimalAndNegative($event)" @input="preventDecimalAndNegative" class="my-2"></b-input>
                        <b-input v-else v-model="materialData.data[0].barcode" type="number" @keypress="preventDecimalAndNegative($event)" @input="preventDecimalAndNegative" class="my-2"></b-input>
                        <b-text class="my-2 "
                          style="color: black; text-align: center; white-space: nowrap;">หมายเหตุ</b-text>
                        <b-textarea v-if="setPage() === 'add'" v-model="materialData.data[0].remark" class="my-2"></b-textarea>
                        <b-input v-else-if="setPage() === 'view'" disabled v-model="materialData.data[0].remark" class="my-2"></b-input>
                        <b-textarea v-else v-model="materialData.data[0].remark" class="my-2"></b-textarea>
                      </b-form>
                    </b-col>

                    <b-col class="px-4" lg="6" sm="12">
                      <b-form class="mx-2">
                        <template>
                          <div>
                            <b-form-group style="margin-top: 0.1rem;" label-for="imageInput" label="อัปโหลดรูปภาพ" class="custom-file-style">
                              <b-form-file v-if="setPage() === 'add'" id="imageInput" accept="image/*" @change="handleImageUpload" :placeholder="materialFiles.imageName ? materialFiles.imageName : 'Choose a file...'"></b-form-file>
                              <b-form-file v-else-if="setPage() === 'view'" v-model="materialData.data[0].img_path" id="imageInput" accept="image/*" @change="handleImageUpload" disabled :placeholder="materialFiles.imageName ? materialFiles.imageName : 'Choose a file...'"></b-form-file>
                              <b-form-file v-else-if="setPage() === 'edit'" v-model="materialData.data[0].img_path" id="imageInput" accept="image/*" @change="handleImageUpload" :placeholder="materialFiles.imageName ? materialFiles.imageName : materialData.data[0].img_name ? materialData.data[0].img_name : 'Choose a file...'"></b-form-file>
                            </b-form-group>
                            <div v-if="materialData.data[0].img_path !== null && materialData.data[0].img_path !== undefined && (materialFiles.imagefile === null || materialFiles.imagefile === undefined)" style="text-align: center;">
                              <img :src="getFullImagePath(materialData.data[0].img_path ? materialData.data[0].img_path : null)" alt="Uploaded Image" width="350px" height="300px" style="object-fit: contain;" />
                            </div>
                            <div v-else-if="materialFiles.imagefile !== null && materialFiles.imagefile !== undefined" style="text-align: center;">
                              <img :src="imageUrl" alt="Uploaded Image" width="350px" height="300px" style="object-fit: contain;" />
                            </div>
                          </div>
                        </template>
                        <template>
                          <div>
                            <b-form-group label-for="imageInput2" label="อัปโหลดบาร์โค้ด" class="custom-file-style">
                              <b-form-file v-if="setPage() === 'add'" id="imageInput2" accept="image/*" @change="handleImageUpload2" :placeholder="materialFiles.barcodeName ? materialFiles.barcodeName : 'Choose a file...'"></b-form-file>
                              <b-form-file v-else-if="setPage() === 'view'" v-model="materialData.data[0].barcode_path" id="imageInput2" accept="image/*" @change="handleImageUpload2" disabled :placeholder="materialFiles.barcodeName ? materialFiles.barcodeName : 'Choose a file...'"></b-form-file>
                              <b-form-file v-else-if="setPage() === 'edit'" v-model="materialData.data[0].barcode_path" id="imageInput2" accept="image/*" @change="handleImageUpload2" :placeholder="materialFiles.barcodeName ? materialFiles.barcodeName : materialData.data[0].barrcode_name ? materialData.data[0].barrcode_name : 'Choose a file...'"></b-form-file>
                            </b-form-group>
                            <div v-if="materialData.data[0].barcode_path !== null && materialData.data[0].barcode_path !== undefined && (materialFiles.barcodefile === null || materialFiles.barcodefile === undefined)" style="text-align: center;">
                              <img :src="getFullImagePath(materialData.data[0].barcode_path ? materialData.data[0].barcode_path : null)" alt="Uploaded Barcode" width="350px" height="300px" style="object-fit: contain;" />
                            </div>
                            <div v-else-if="materialFiles.barcodefile !== null && materialFiles.barcodefile !== undefined" style="text-align: center;">
                              <img :src="barcodeUrl" alt="Uploaded Barcode" width="350px" height="300px" style="object-fit: contain;" />
                            </div>
                          </div>
                        </template>
                      </b-form>
                    </b-col>
                  </b-row>

                </div>

                <div v-if="activeTab === 'tab2'">
                  <div class="row response-select-supplier" style="align-items: center; text-align: center; justify-content: space-between; padding: 0 1rem;">
                  <h5 class="my-1 response-select-supplier-text">เลือกซัพพลายเออร์</h5>
                  <b-button v-if="setPage() === 'view'" :disabled="true" @click="showModal = true, checkmodal = 'add', supplier= {}, supplier= supplier" type="submit" variant="primary" class="d-flex response-select-supplier-btn"
                      style="display: flex; text-align: center; white-space: nowrap; justify-content: center;"><i class="circle-plus_ct"></i>เพิ่มซัพพลายเออร์</b-button>
                    <b-button v-else @click="checkmodal = 'add', resetSupplier()" type="submit" variant="primary" class="d-flex response-select-supplier-btn"
                      style="display: flex; text-align: center; white-space: nowrap; justify-content: center;"><i class="circle-plus_ct"></i>เพิ่มซัพพลายเออร์</b-button>
                      <template  v-if="checkmodal === 'add'">
                        <b-modal v-model="showModal" title="เพิ่มซัพพลายเออร์" cancel-title="เพิ่ม" ok-title="ยกเลิก" cancel-variant="primary" ok-variant="danger"
                        @cancel="addSupplier" :cancel-disabled="!supplier.var_supplier1 || !supplier.var_supplier2 ? true : false"
                        @close.prevent="closeModal" :no-close-on-esc="true" :no-close-on-backdrop="true">
                      <b-form-group style="color: black;" label="ซัพพลายเออร์">
                        <template v-if="supplier.var_supplier1 === '' || supplier.var_supplier1 === null" v-slot:label>
                          ซัพพลายเออร์<span class="text-danger"> *</span>
                        </template>
                        <v-select v-model="supplierOption" :options="supplierDD"></v-select>
                      </b-form-group>
                      <b-form-group label="ราคาซื้อต่อชิ้น" style="color: black;">
                        <template v-if="supplier.var_supplier2 === '' || supplier.var_supplier2 === null" v-slot:label>
                          ราคาซื้อต่อชิ้น<span class="text-danger"> *</span>
                        </template>
                        <b-form-input id="" v-model="supplier.var_supplier2" type="text" @input="isNumberComma1($event, '2')" @keypress="isNumberCheck($event, false)"></b-form-input>
                      </b-form-group>
                      <b-form-group label="ราคาสั่งขั้นต่ำ" style="color: black;">
                        <template v-if="supplier.var_supplier3 === '' || supplier.var_supplier3 === null" v-slot:label>
                          ราคาสั่งขั้นต่ำ<span class="text-danger"> *</span>
                        </template>
                        <b-form-input id="" v-model="supplier.var_supplier3" type="text" @input="isNumberComma1($event, '3')" @keypress="isNumberCheck($event, true)"></b-form-input>
                      </b-form-group>
                      <b-form-group label="เวลาในการจัดส่ง(วัน)" style="color: black;">
                        <template v-if="supplier.var_supplier4 === '' || supplier.var_supplier4 === null" v-slot:label>
                          เวลาในการจัดส่ง(วัน)<span class="text-danger"> *</span>
                        </template>
                        <b-form-input v-model="supplier.var_supplier4" type="text" @input="isNumberComma1($event, '4')" @keypress="isNumberCheck($event, true)"></b-form-input>
                      </b-form-group>
                      <b-form-group label="ข้อคิดเห็น" style="color: black;">
                        <b-form-textarea id="" v-model="supplier.var_supplier5" type="text"></b-form-textarea>
                      </b-form-group>
                      <b-form-group label="มาตรฐานรองรับ อย." style="color: black;">
                        <template v-if="supplier.var_supplier6 === null" v-slot:label>
                          มาตรฐานรองรับ อย.<span class="text-danger"> *</span>
                        </template>
                        <b-row class="" style="text-align: center; justify-content: center;">
                          <b-form-radio v-model="supplier.var_supplier6" :value=true class="mr-3">อย. เท่านั้น</b-form-radio>
                          <b-form-radio b-form-radio v-model="supplier.var_supplier6" :value=false class="ml-3">อื่น ๆ</b-form-radio>
                        </b-row>
                      </b-form-group>
                      <template #modal-footer>
                        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                          <b-button
                            style="border-radius: 0.5rem; padding: 0 1rem;"
                            variant="primary"
                            class="btn-min-size"
                            size="sm"
                            @click="addSupplier"
                          >
                          <i class="save-data_ct"/>บันทึก
                          </b-button>
                          <b-button
                            variant="none"
                            class="iq-bg-danger btn-cancel-border btn-min-size"
                            style="border-radius: 0.5rem;"
                            size="sm"
                            @click="closeModal"
                          >
                            ยกเลิก
                          </b-button>
                        </div>
                      </template>
                      </b-modal>
                    </template>
                    <template  v-else>
                      <b-modal v-model="showModal" title="แก้ไขซัพพลายเออร์" cancel-title="แก้ไข" ok-title="ยกเลิก" cancel-variant="primary" ok-variant="danger" @cancel="editSupplier"
                      :cancel-disabled="!supplier.var_supplier1 || !supplier.var_supplier2 ? true : false"
                      @close.prevent="closeModal" :no-close-on-esc="true" :no-close-on-backdrop="true">
                      <b-form-group style="color: black;" label="ซัพพลายเออร์">
                        <template v-if="supplier.var_supplier1 === '' || supplier.var_supplier1 === null" v-slot:label>
                          ซัพพลายเออร์<span class="text-danger"> *</span>
                        </template>
                        <v-select v-model="supplierOption" :options="supplierDD"></v-select>
                      </b-form-group>
                      <b-form-group label="ราคาซื้อต่อชิ้น" style="color: black;">
                        <template v-if="supplier.var_supplier2 === '' || supplier.var_supplier2 === null" v-slot:label>
                          ราคาซื้อต่อชิ้น<span class="text-danger"> *</span>
                        </template>
                        <b-form-input id="" v-model="supplier.var_supplier2" type="text" @input="isNumberComma1($event, '2')" @keypress="isNumberCheck($event, false)"></b-form-input>
                      </b-form-group>
                      <b-form-group label="ราคาสั่งขั้นต่ำ" style="color: black;">
                        <template v-if="supplier.var_supplier3 === '' || supplier.var_supplier3 === null" v-slot:label>
                          ราคาสั่งขั้นต่ำ<span class="text-danger"> *</span>
                        </template>
                        <b-form-input id="" v-model="supplier.var_supplier3" type="text" @input="isNumberComma1($event, '3')" @keypress="isNumberCheck($event, true)"></b-form-input>
                      </b-form-group>
                      <b-form-group label="เวลาในการจัดส่ง(วัน)" style="color: black;">
                        <template v-if="supplier.var_supplier4 === '' || supplier.var_supplier4 === null" v-slot:label>
                          เวลาในการจัดส่ง(วัน)<span class="text-danger"> *</span>
                        </template>
                        <b-form-input v-model="supplier.var_supplier4" type="text" @input="isNumberComma1($event, '4')" @keypress="isNumberCheck($event, true)"></b-form-input>
                      </b-form-group>
                      <b-form-group label="ข้อคิดเห็น" style="color: black;">
                        <b-form-textarea id="" v-model="supplier.var_supplier5" type="text"></b-form-textarea>
                      </b-form-group>
                      <b-form-group label="มาตรฐานรองรับ อย." style="color: black;">
                        <template v-if="supplier.var_supplier6 === null" v-slot:label>
                          มาตรฐานรองรับ อย.<span class="text-danger"> *</span>
                        </template>
                        <b-row class="" style="text-align: center; justify-content: center;">
                          <b-form-radio v-model="supplier.var_supplier6" :value=true class="mr-3">อย. เท่านั้น</b-form-radio>
                          <b-form-radio v-model="supplier.var_supplier6" :value=false class="ml-3">อื่น ๆ</b-form-radio>
                        </b-row>
                      </b-form-group>
                      <template #modal-footer>
                        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                          <b-button
                            style="border-radius: 0.5rem; padding: 0 .7rem;"
                            variant="primary"
                            class="btn-min-size"
                            size="sm"
                            @click="editSupplier"
                          >
                            <i class="save-data_ct"/>บันทึก
                          </b-button>
                          <b-button
                            variant="none"
                            class="iq-bg-danger btn-cancel-border btn-min-size"
                            style="border-radius: 0.5rem;"
                            size="sm"
                            @click="closeModal"
                          >
                            ยกเลิก
                          </b-button>
                        </div>
                      </template>
                      </b-modal>
                    </template>
                  </div>
                  <hr>
                  <b-col md="12" class="table-responsive">
                      <b-table bordered hover :items="materialData.data[0].supplier_list" :fields="columns" :current-page="currentPage" :per-page="perPage" @pagination="onPagination" style="white-space: nowrap;" :tbody-tr-class="rowClass" thead-class="table-header-color">
                      <template v-slot:cell(variable1)="data">
                        <span>{{ data.index + 1 }}</span>
                      </template>
                      <template v-slot:cell(variable2)="data">
                        <span>{{ materialData.data[0].supplier_list[((currentPage - 1) * 10) + data.index].sup_name }}</span>
                      </template>
                      <template v-slot:cell(variable3)="data">
                        <span>{{ thousandSeperate(materialData.data[0].supplier_list[((currentPage - 1) * 10) + data.index].buy_price) }}</span>
                      </template>
                      <template v-slot:cell(variable4)="data">
                        <span>{{ thousandSeperate(materialData.data[0].supplier_list[((currentPage - 1) * 10) + data.index].minimum_order) }}</span>
                      </template>
                      <template v-slot:cell(variable5)="data">
                        <span>{{ thousandSeperate(materialData.data[0].supplier_list[((currentPage - 1) * 10) + data.index].lead_time) }}</span>
                      </template>
                      <template v-slot:cell(variable6)="data">
                        <span>{{ materialData.data[0].supplier_list[((currentPage - 1) * 10) + data.index].remark }}</span>
                      </template>
                      <template v-slot:cell(variable7)="data">
                        <span>{{ formatdate(materialData.data[0].supplier_list[((currentPage - 1) * 10) + data.index].updated_date) }}</span>
                      </template>
                      <template v-slot:cell(variable8)="data">
                        <span>{{ materialData.data[0].supplier_list[((currentPage - 1) * 10) + data.index].is_true ? 'อย.' : 'อื่นๆ' }}</span>
                      </template>
                      <template v-slot:cell(variable9)="data">
                        <div style="display: flex; justify-content: center;">
                          <b-button variant=" iq-bg-success mx-1" size="sm" @click="showModal = true, checkmodal='edit', page='edit', setEditSupplier(data.item, data.index)" :disabled="setPage() === 'view' ? true : false"><i class="ri-ball-pen-fill m-0"></i></b-button>
                          <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)" :disabled="setPage() === 'view' ? true : false"><i class="ri-delete-bin-line m-0"></i></b-button>
                        </div>
                      </template>
                    </b-table>
                    <br>
                  </b-col>
                <b-pagination v-model="currentPage" :total-rows="materialData.data[0].supplier_list.length" :per-page="perPage" align="center" />
              </div>
            </div>
            </div>
            <b-row>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.custom-modal .modal-footer {
  display: flex;
  flex-direction: row-reverse;
}
@media (max-width: 600px) {
  .t-button {
    width: 100%;
  }
  .response-1button-header {
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }
  .response-2button-header {
    gap: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 300px) {
  .response-select-supplier {
    flex-direction: column;
    align-items: start !important;
    widows: 100% !important;
    padding: 0 2rem !important;
  }
  .response-select-supplier-text {
    margin: 0;
  }
  .response-select-supplier-btn {
    margin-top: 0.5rem !important;
    width: 100%;
    justify-content: center;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import moment from 'moment'
import axios from 'axios'
import { configAPI } from '../../configBase'

export default {
  data () {
    return {
      cln_id: null,
      materialData: {
        data: [
          {
            mg_id: null,
            mb_id: null,
            mt_id: null,
            unit_id: null,
            unit_supply_id: null,
            fda_type: null,
            license: null,
            remark: null,
            barcode: null,
            min_stock: null,
            supplier_list: []
          }
        ]
      },
      materialGroupDD: [],
      materialGroupOption: null,
      brandDD: [],
      brandOption: null,
      typeDD: [],
      typeOption: null,
      unitDD: [],
      unitOption: null,
      unitSupplyOption: null,
      supplierDD: [],
      supplierOption: null,
      materialFiles: {
        imageName: null,
        imagefile: null,
        imagePath: null,
        barcodeName: null,
        barcodefile: null,
        barcodePath: null
      },
      supplier: {
        var_supplier1: null,
        var_supplier2: null,
        var_supplier3: null,
        var_supplier4: null,
        var_supplier5: null,
        var_supplier6: null,
        var_supplier7: null
      },
      imageUrl: null,
      barcodeUrl: null,
      page: 'add',
      showModal: false,
      activeTab: 'tab1',
      columns: [
        { label: 'ลำดับ', key: 'variable1', class: 'text-center' },
        { label: 'ซัพพลายเออร์', key: 'variable2', class: 'text-center' },
        { label: 'ราคาซื้อต่อชิ้น', key: 'variable3', class: 'text-center' },
        { label: 'ราคาสั่งขั้นต่ำ', key: 'variable4', class: 'text-center' },
        { label: 'เวลาในการจัดส่ง(วัน)', key: 'variable5', class: 'text-center' },
        { label: 'ข้อคิดเห็น', key: 'variable6', class: 'text-center' },
        { label: 'วันที่อัปเดต', key: 'variable7', class: 'text-center' },
        { label: 'มาตรฐานรองรับ อย.', key: 'variable8', class: 'text-center' },
        { label: 'จัดการ', key: 'variable9', class: 'text-center' }
      ],
      currentPage: 1,
      perPage: 10
    }
  },
  async mounted () {
    xray.index()
    const data = JSON.parse(sessionStorage.getItem('user'))
    this.cln_id = data.cln_id
    await this.getMaterialGroupDD()
    await this.getBrandDD()
    await this.getTypeDD()
    await this.getSupplierDD()
    await this.getUnitDD()
    if (this.setPage() === 'edit' || this.setPage() === 'view') {
      await this.getMaterialData()
    }
  },
  computed: {
  },
  watch: {
    materialGroupOption (newValue) {
      if (newValue !== null) {
        this.materialData.data[0].mg_id = newValue.value
      } else {
        this.materialData.data[0].mg_id = null
      }
    },
    brandOption (newValue) {
      if (newValue !== null) {
        this.materialData.data[0].mb_id = newValue.value
      } else {
        this.materialData.data[0].mb_id = null
      }
    },
    typeOption (newValue) {
      if (newValue !== null) {
        this.materialData.data[0].mt_id = newValue.value
      } else {
        this.materialData.data[0].mt_id = null
      }
    },
    supplierOption (newValue) {
      if (newValue === null) {
        this.supplier.var_supplier1 = null
        this.supplier.var_supplier2 = null
      } else {
        this.supplier.var_supplier1 = newValue.value
        this.supplier.var_supplier7 = newValue.label
      }
    },
    unitOption (newValue) {
      if (newValue !== null) {
        this.materialData.data[0].unit_id = newValue.value
      } else {
        this.materialData.data[0].unit_id = null
      }
    },
    unitSupplyOption (newValue) {
      if (newValue !== null) {
        this.materialData.data[0].unit_supply_id = newValue.value
      } else {
        this.materialData.data[0].unit_supply_id = null
      }
    }
  },
  methods: {
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    setPage () {
      return this.$route.params.setPage
    },
    closeModal () {
      if (this.setPage() === 'add' || this.setPage() === 'edit') {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะยกเลิกใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.resetSupplier()
            this.supplierOption = null
            this.showModal = false
          }
        })
      } else {
        this.resetSupplier()
        this.supplierOption = null
        this.showModal = false
      }
    },
    handleImageUpload (event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      this.materialFiles.imagefile = file
      this.materialFiles.imageName = file.name
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.imageUrl = reader.result
      }
    },
    handleImageUpload2 (event) {
      const file2 = event.target.files[0]
      const reader2 = new FileReader()
      this.materialFiles.barcodefile = file2
      this.materialFiles.barcodeName = file2.name
      reader2.readAsDataURL(file2)
      reader2.onload = () => {
        this.barcodeUrl = reader2.result
      }
    },
    remove (item) {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะลบข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let index = this.materialData.data[0].supplier_list.indexOf(item)
          this.materialData.data[0].supplier_list.splice(index, 1)
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
            icon: 'success',
            showConfirmButton: false,
            showCancelButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            timer: 2000
          })
        }
      })
    },
    async getMaterialData () {
      let id = this.$route.params.id
      let getMaterialDataAPI = {
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/material/${id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      axios.request(getMaterialDataAPI)
        .then((response) => {
          response.data.data[0].min_stock = response.data.data[0].min_stock?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          this.materialData.data = response.data.data
          this.materialGroupOption = {
            value: this.materialData.data[0].mat_mg_id,
            label: this.materialData.data[0].mg_name
          }
          this.brandOption = {
            value: this.materialData.data[0].mat_mb_id,
            label: this.materialData.data[0].mb_name
          }
          this.typeOption = {
            value: this.materialData.data[0].mt_id,
            label: this.materialData.data[0].mt_name
          }
          this.unitOption = {
            value: this.materialData.data[0].unit_id,
            label: this.materialData.data[0].unit_name
          }
          this.unitSupplyOption = {
            value: this.materialData.data[0].unit_supply_id,
            label: this.materialData.data[0].unit_supply_name
          }
          this.materialFiles.imageName = this.materialData.data[0].img_name
          this.materialFiles.imagePath = this.materialData.data[0].img_path
          this.materialFiles.barcodeName = this.materialData.data[0].barrcode_name
          this.materialFiles.barcodePath = this.materialData.data[0].barcode_path
        })
        .catch((error) => {
          Vue.swal.fire({
            icon: 'error',
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
            html: '<span>' + error + '</span>',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async handleClick (status) {
      if (status && this.setPage() === 'add') {
        let errorList = await this.checkError()
        if (errorList.length > 0) {
          Vue.swal.fire({
            icon: 'warning',
            html: `<p style="font-size: 18px; color: black; text-align: center">${errorList.join('<br>')}</p>`,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
          return
        }
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะบันทึกข้อมูลใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            let dataForAdd = {
              mg_id: this.materialData.data[0].mg_id,
              mb_id: this.materialData.data[0].mb_id,
              mt_id: this.materialData.data[0].mt_id,
              unit_id: this.materialData.data[0].unit_id,
              unit_supply_id: this.materialData.data[0].unit_supply_id,
              fda_type: this.materialData.data[0].fda_type,
              license: this.materialData.data[0].license,
              remark: this.materialData.data[0].remark,
              barcode: this.materialData.data[0].barcode,
              supplier_list: this.materialData.data[0].supplier_list,
              min_stock: this.materialData.data[0].min_stock?.replaceAll(/,/g, '')
            }
            const addAPI = {
              method: 'POST',
              url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/material`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: dataForAdd
            }
            axios.request(addAPI)
              .then(async (response) => {
                if (this.materialFiles.imagefile) {
                  await this.uploadImage(response.data.data.id)
                }
                if (this.materialFiles.barcodefile) {
                  await this.uploadBarcode(response.data.data.id)
                }
                Vue.swal.fire({
                  icon: 'success',
                  title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
                  showConfirmButton: false,
                  showCancelButton: false,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  timer: 2000
                }).then((result) => {
                  router.push('/Material')
                })
              })
          }
        })
      } else if (status && this.setPage() === 'edit') {
        let errorList = await this.checkError()
        if (errorList.length > 0) {
          Vue.swal.fire({
            icon: 'warning',
            html: `<p style="font-size: 18px; color: black; text-align: center">${errorList.join('<br>')}</p>`,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
          return
        }
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะแก้ไขข้อมูลใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            let id = this.$route.params.id
            let dataForEdit = {
              mg_id: this.materialData.data[0].mg_id,
              mb_id: this.materialData.data[0].mb_id,
              mt_id: this.materialData.data[0].mt_id,
              unit_id: this.materialData.data[0].unit_id,
              unit_supply_id: this.materialData.data[0].unit_supply_id,
              fda_type: this.materialData.data[0].fda_type,
              license: this.materialData.data[0].license,
              remark: this.materialData.data[0].remark,
              barcode: this.materialData.data[0].barcode,
              supplier_list: this.materialData.data[0].supplier_list,
              min_stock: this.materialData.data[0].min_stock?.replaceAll(/,/g, '')
            }
            let editAPI = {
              method: 'PUT',
              url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/material/${id}`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: dataForEdit
            }
            axios.request(editAPI)
              .then(async (response) => {
                if (this.materialFiles.imagefile) {
                  await this.uploadImage(id)
                }
                if (this.materialFiles.barcodefile) {
                  await this.uploadBarcode(id)
                }
                Vue.swal.fire({
                  icon: 'success',
                  html: '<span class="" style="font-size: 25px; color: black; text-align: center">แก้ไขข้อมูลสำเร็จ</span>',
                  showConfirmButton: false,
                  showCancelButton: false,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  timer: 2000
                }).then((result) => {
                  router.push('/Material')
                })
              })
          }
        })
      } else {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะยกเลิกใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            router.push('/Material')
          }
        })
      }
    },
    cancelClick () {
      router.push('/Material')
    },
    onPagination (page) {
      this.currentPage = page
    },
    async addSupplier () {
      let errorList = await this.checkErrorSupplier()
      if (errorList.length > 0) {
        Vue.swal.fire({
          icon: 'warning',
          html: `<p style="font-size: 18px; color: black; text-align: center">${errorList.join('<br>')}</p>`,
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1'
          }
        })
        return
      }
      let temp = {
        sup_id: this.supplier.var_supplier1,
        sup_name: this.supplier.var_supplier7,
        buy_price: parseInt(this.supplier.var_supplier2.toString().replaceAll(/,/g, '')),
        minimum_order: parseInt(this.supplier.var_supplier3.toString().replaceAll(/,/g, '')),
        lead_time: parseInt(this.supplier.var_supplier4.toString().replaceAll(/,/g, '')),
        remark: this.supplier.var_supplier5,
        is_true: this.supplier.var_supplier6
      }
      this.materialData.data[0].supplier_list.unshift(temp)
      this.resetSupplier()
      this.supplierOption = null
      this.showModal = false
    },
    setEditSupplier (data, index) {
      this.supplierOption = {
        value: data.sup_id,
        label: data.sup_name
      }
      this.supplier.var_supplier1 = data.sup_id
      this.supplier.var_supplier2 = data.buy_price.toString().replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      this.supplier.var_supplier3 = data.minimum_order.toString().replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      this.supplier.var_supplier4 = data.lead_time.toString().replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      this.supplier.var_supplier5 = data.remark
      this.supplier.var_supplier6 = data.is_true
      this.supplier.var_supplier7 = data.sup_name
      this.indexTableEdit = index
    },
    resetSupplier () {
      this.supplierOption = null
      this.supplier.var_supplier1 = null
      this.supplier.var_supplier2 = null
      this.supplier.var_supplier3 = null
      this.supplier.var_supplier4 = null
      this.supplier.var_supplier5 = null
      this.supplier.var_supplier6 = null
      this.supplier.var_supplier7 = null
      this.showModal = true
    },
    async editSupplier () {
      let errorList = await this.checkErrorSupplier(true)
      if (errorList.length > 0) {
        Vue.swal.fire({
          icon: 'warning',
          html: `<p style="font-size: 18px; color: black; text-align: center">${errorList.join('<br>')}</p>`,
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1'
          }
        })
        return
      }
      this.materialData.data[0].supplier_list[this.indexTableEdit].sup_id = this.supplier.var_supplier1
      this.materialData.data[0].supplier_list[this.indexTableEdit].buy_price = parseInt(this.supplier.var_supplier2.toString().replaceAll(/,/g, ''))
      this.materialData.data[0].supplier_list[this.indexTableEdit].minimum_order = parseInt(this.supplier.var_supplier3.toString().replaceAll(/,/g, ''))
      this.materialData.data[0].supplier_list[this.indexTableEdit].lead_time = parseInt(this.supplier.var_supplier4.toString().replaceAll(/,/g, ''))
      this.materialData.data[0].supplier_list[this.indexTableEdit].remark = this.supplier.var_supplier5
      this.materialData.data[0].supplier_list[this.indexTableEdit].is_true = this.supplier.var_supplier6
      this.materialData.data[0].supplier_list[this.indexTableEdit].sup_name = this.supplier.var_supplier7
      this.supplierOption = {
        value: null,
        label: null
      }
      this.resetSupplier()
      this.showModal = false
    },
    preventDecimalAndNegative (event) {
      const disallowedKeys = ['-', '.', 'e', 'E', '+', '=', '*']
      const maxLength = 13

      if (disallowedKeys.includes(event.key)) {
        event.preventDefault()
      }

      if (event.target.value.length >= maxLength) {
        event.preventDefault()
      }
    },
    isNumberComma1 (event, variable) {
      if (variable === '2') {
        this.supplier.var_supplier2 = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === '3') {
        this.supplier.var_supplier3 = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === '4') {
        this.supplier.var_supplier4 = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === 'stock') {
        this.materialData.data[0].min_stock = event.replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
      }
    },
    isNumberCheck (keyPressed, status) {
      let useData = keyPressed.target.value

      let keyCode = (keyPressed.keyCode ? keyPressed.keyCode : keyPressed.which)

      if (status) {
        if (keyCode === 46 || useData.indexOf('.') !== -1) {
          keyPressed.preventDefault()
        }
        if (keyCode < 48 || keyCode > 57) {
          keyPressed.preventDefault()
        }
      } else {
        if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || useData.indexOf('.') !== -1)) {
          keyPressed.preventDefault()
        }
      }
      if (useData !== null && useData.indexOf('.') > -1 && (useData.split('.')[1].length > 1)) {
        keyPressed.preventDefault()
      }

      if ((useData === null || useData === '') && keyCode === 46) {
        keyPressed.preventDefault()
      }
    },
    async getMaterialGroupDD () {
      let materialAPI = {
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/materialGroup`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(materialAPI)
        .then((response) => {
          let data = response.data.data
          let temp = []
          data.forEach((element) => {
            temp.push({
              value: element.id,
              label: element.name
            })
          })
          this.materialGroupDD = temp
        })
        .catch((error) => {
          Vue.swal.fire({
            icon: 'error',
            html: '<span>' + error + '</span>',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async getBrandDD (mgId) {
      let BrandAPI = {
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/materialBrand`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(BrandAPI)
        .then((response) => {
          let data = response.data.data
          let temp = []
          data.forEach((element) => {
            temp.push({
              value: element.id,
              label: element.name
            })
          })
          this.brandDD = temp
        })
        .catch((error) => {
          Vue.swal.fire({
            icon: 'error',
            html: '<span>' + error + '</span>',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async getTypeDD (mbId) {
      let typeAPI = {
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/materialType`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(typeAPI)
        .then((response) => {
          let data = response.data.data
          let temp = []
          data.forEach((element) => {
            temp.push({
              value: element.id,
              label: element.name
            })
          })
          this.typeDD = temp
        })
        .catch((error) => {
          Vue.swal.fire({
            icon: 'error',
            html: '<span>' + error + '</span>',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async getUnitDD () {
      let unitAPI = {
        method: 'GET',
        url: `${configAPI.MD_PORT}/api/unit`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      axios.request(unitAPI)
        .then((response) => {
          let data = response.data.data
          let temp = []
          data.forEach((element) => {
            temp.push({
              value: element.id,
              label: element.name
            })
          })
          this.unitDD = temp
        })
        .catch((error) => {
          Vue.swal.fire({
            icon: 'error',
            html: '<span>' + error + '</span>',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async getSupplierDD () {
      let supplierAPI = {
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/supplier`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(supplierAPI)
        .then((response) => {
          let data = response.data.data
          let temp = []
          data.forEach((element) => {
            temp.push({
              value: element.id,
              label: element.name
            })
          })
          this.supplierDD = temp
        })
        .catch((error) => {
          Vue.swal.fire({
            icon: 'error',
            html: '<span>' + error + '</span>',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    formatdate (date) {
      return moment(date).format('DD/MM/YYYY')
    },
    async uploadImage (id) {
      let dataImg = new FormData()
      dataImg.append('file', this.materialFiles.imagefile)
      let ImgAPI = {
        method: 'POST',
        url: `${configAPI.MA_PORT}/api/material/${id}/upload/img`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataImg
      }
      axios.request(ImgAPI)
    },
    async uploadBarcode (id) {
      let dataBarcode = new FormData()
      dataBarcode.append('file', this.materialFiles.barcodefile)
      let BarcodeAPI = {
        method: 'POST',
        url: `${configAPI.MA_PORT}/api/material/${id}/upload/barcode`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataBarcode
      }
      axios.request(BarcodeAPI)
    },
    async checkError () {
      let errorList = []
      if (this.materialData.data[0].mg_id === null) {
        errorList.push('กรุณาเลือก หมวดหมู่')
      }
      if (this.materialData.data[0].mb_id === null) {
        errorList.push('กรุณาเลือก แบรนด์')
      }
      if (this.materialData.data[0].mt_id === null) {
        errorList.push('กรุณาเลือก ประเภทวัสดุ')
      }
      if (this.materialData.data[0].unit_id === null) {
        errorList.push('กรุณาเลือก หน่วยนับ')
      }
      return errorList
    },
    async checkErrorSupplier (isEdit) {
      let errorList = []

      if (this.supplier.var_supplier1 === null) {
        errorList.push('กรุณาเลือก ซัพพลายเออร์')
      }
      if (this.supplier.var_supplier2 === null || this.supplier.var_supplier2?.trim() === '') {
        errorList.push('กรุณากรอกข้อมูล ราคาซื้อต่อชิ้น')
      }
      if (this.supplier.var_supplier3 === null || this.supplier.var_supplier3?.trim() === '') {
        errorList.push('กรุณากรอกข้อมูล ราคาสั่งขั้นต่ำ')
      }
      if (this.supplier.var_supplier4 === null || this.supplier.var_supplier4?.trim() === '') {
        errorList.push('กรุณากรอกข้อมูล เวลาในการจัดส่ง(วัน)')
      }
      if (this.supplier.var_supplier6 === null) {
        errorList.push('กรุณาเลือก มาตรฐานรองรับ อย.')
      }
      if (this.materialData.data[0].supplier_list.find(
        (el, index) => el.sup_id === this.supplier.var_supplier1 && (isEdit ? this.indexTableEdit !== index : true)
      )) {
        errorList.push('มีซัพพลายเออร์นี้ ในวัตถุดิบนี้อยู่แล้ว')
      }
      return errorList
    },
    thousandSeperate (number) {
      return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
    },
    getFullImagePath (relativePath) {
      let path = relativePath !== null ? relativePath.slice(1) : ''
      const baseURL = `${configAPI.MA_PORT}` + path
      return baseURL
    }
  }
}
</script>
