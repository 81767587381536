<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">ระดับการบริหาร</span>
              </div>
              <div class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="submit" variant="primary" @click="resetValue(), showModal = true, checkmodal = 'add'"
                  class="w-100 btn-min-size"><i class="circle-plus_ct"></i>เพิ่ม</b-button>
                <template v-if="checkmodal === 'add'">
                  <b-modal v-model="showModal" title="ระดับการบริหาร (เพิ่ม)" cancel-title="เพิ่ม" ok-title="ยกเลิก"
                    cancel-variant="primary" ok-variant="danger" @cancel="addRole" @ok="handleClick()"
                    :cancel-disabled="!roles.role1 ? true : false" @close.prevent="handleClick()"
                    :no-close-on-esc="true" :no-close-on-backdrop="true">
                    <b-form-group label="ชื่อระดับการบริหาร" style="color: black;">
                      <template v-if="roles.role1 === '' || roles.role1 === null || !roles.role1?.trim()" v-slot:label>
                        ชื่อระดับการบริหาร<span class="text-danger"> *</span>
                      </template>
                      <b-form-input id="" v-model="roles.role1" type="text"></b-form-input>
                    </b-form-group>
                    <template #modal-footer>
                        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                          <b-button
                            style="border-radius: 0.5rem; padding: 0 .7rem;"
                            variant="primary"
                            class="btn-min-size"
                            size="sm"
                            @click="addRole"
                            :disabled="roles.role1?.trim() ? false : true"
                            :hidden="checkmodal === 'view' ? true : false"
                          >
                            <i class="save-data_ct"/>บันทึก
                          </b-button>
                          <b-button
                            variant="none"
                            class="iq-bg-danger btn-cancel-border btn-min-size"
                            style="border-radius: 0.5rem;"
                            size="sm"
                            @click="handleClick()"
                          >
                            ยกเลิก
                          </b-button>
                        </div>
                      </template>
                  </b-modal>
                </template>
                <template v-else>
                  <b-modal v-model="showModal"
                    :title="`${checkmodal === 'view' ? 'ระดับการบริหาร (ดูรายละเอียด)' : 'ระดับการบริหาร (แก้ไข)'}`"
                    cancel-title="แก้ไข" ok-title="ยกเลิก" cancel-variant="primary" ok-variant="danger" @cancel="editRole"
                    @ok="checkmodal === 'view' ? showModal=false : handleClick()" :cancel-disabled="!roles.role1 ? true : false"
                    @close.prevent="checkmodal === 'view' ? showModal=false : handleClick()" :no-close-on-esc="true" :no-close-on-backdrop="true">
                    <b-form-group label="ชื่อระดับการบริหาร" style="color: black;">
                      <template v-if="roles.role1 === '' || roles.role1 === null || !roles.role1?.trim()" v-slot:label>
                        ชื่อระดับการบริหาร<span class="text-danger"> *</span>
                      </template>
                      <b-form-input :disabled="checkmodal === 'view' ? true : false" id="" v-model="roles.role1"
                        type="text"></b-form-input>
                    </b-form-group>
                    <template #modal-footer>
                        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                          <b-button
                            style="border-radius: 0.5rem; padding: 0 .7rem;"
                            variant="primary"
                            class="btn-min-size"
                            size="sm"
                            @click="editRole(roles.id)"
                            :disabled="roles.role1?.trim() ? false : true"
                            :hidden="checkmodal === 'view' ? true : false"
                          >
                          <i class="save-data_ct"/>บันทึก
                          </b-button>
                          <b-button
                            variant="none"
                            class="iq-bg-danger btn-cancel-border btn-min-size"
                            style="border-radius: 0.5rem;"
                            size="sm"
                            @click="checkmodal === 'view' ? showModal=false : handleClick()"
                          >
                            ยกเลิก
                          </b-button>
                        </div>
                      </template>
                  </b-modal>
                </template>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" md="7" lg="5" class="mb-3">
                  <b-row>
                    <b-col sm="12" md class="mb-3">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input class="form-control" id="exampleInputEmail1" placeholder="Search" style="width: 100%;"
                              v-model="searchTerm"  @keydown="enterSearch">
                            <a class="search-link" v-on:click="getRoleGroup"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                      <span class="mx-2 font-size-14 text-primary unselectable">
                        {{ rolegroup.data?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > rolegroup.data?.length) ? rolegroup.data?.length : (perPage * currentPage) ) + ' of ' + rolegroupLength : '0 of ' + rolegroupLength }} รายการ
                        <!-- {{ rolegroup.data?.length > 0 ? "1 - " + rolegroup.data?.length + " of " + rolegroup.data?.length
                          : "0 - 0 of 0" }} -->
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <b-col md="12" class="table-responsive">
              <b-table bordered hover :items="rolegroup.data" :fields="columns" :current-page="currentPage"
                :per-page="perPage" @pagination="onPagination" :tbody-tr-class="rowClass"
                thead-class="table-header-color">
                <template v-slot:cell(variable1)="data">
                  <span v-if="!data.item.editable">{{ ((currentPage - 1) * 10) + data.index + 1 }}</span>
                </template>
                <template v-slot:cell(variable2)="data">
                  <span v-if="!data.item.editable">{{ rolegroup.data[((currentPage - 1) * 10) + data.index].name }}</span>
                </template>
                <template v-slot:cell(variable3)="data">
                  <div style="display: flex; justify-content: center;">
                    <b-button variant=" iq-bg-info mr-1" size="sm"
                      @click="showModal = true, checkmodal = 'view', setEditRole(data.item, ((currentPage - 1) * 10) + data.index)"><i
                        class="ri-eye-fill m-0"></i></b-button>
                    <!-- <a v-if="((currentPage - 1) * 10) + data.index + 6 <= 5 " :disabled="((currentPage - 1) * 10) + data.index + 6 <= 5 ? true : false" size="sm" @click="showModal = true, checkmodal = 'edit', setEditRole(data.item, ((currentPage - 1) * 10) + data.index)" class="p-3" style="color: white !important;"><i :hidden="((currentPage - 1) * 10) + data.index + 6 <= 5 ? true : false" class="ri-delete-bin-line m-0"></i></a> -->
                    <b-button variant=" iq-bg-success mr-1" size="sm"
                      @click="showModal = true, checkmodal = 'edit', setEditRole(data.item, ((currentPage - 1) * 10) + data.index)"><i
                        class="ri-ball-pen-fill m-0"></i></b-button>
                    <!-- <a v-if="((currentPage - 1) * 10) + data.index + 6 <= 5 " :disabled="((currentPage - 1) * 10) + data.index + 6 <= 5 ? true : false" size="sm" @click="this.remove(rolegroup.data[((currentPage - 1) * 10) + data.index].id)" class="p-3" style="color: white !important;"><i :hidden="((currentPage - 1) * 10) + data.index + 6 <= 5 ? true : false" class="ri-delete-bin-line m-0"></i></a> -->
                    <b-button variant=" iq-bg-danger" size="sm"
                      @click="remove(rolegroup.data[((currentPage - 1) * 10) + data.index].id)"><i
                        class="ri-delete-bin-line m-0"></i></b-button>
                  </div>
                </template>
              </b-table>
              <br>
              <b-pagination v-model="currentPage" :total-rows="rolegroup.data?.length" :per-page="perPage"
                align="center" />
            </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped>
.width-label{
  width: 200px !important;
}
</style>

<script>
import { xray } from '../../config/pluginInit'
// import router from '@/router'
import axios from 'axios'
import Vue from 'vue'
import { configAPI } from '../../configBase'

export default {
  name: 'ManageLevel',
  async mounted () {
    xray.index()
    await this.getRoleGroup()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getRoleGroup()
      }
    },
    async getRoleGroup () {
      let search = this.searchTerm.trim().length > 0 ? this.searchTerm.trim() : ''
      let roleAPI = {
        method: 'get',
        url: `${configAPI.MD_PORT}/api/role?search=` + search,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(roleAPI)
        .then((response) => {
          this.rolegroup = response.data
          this.rolegroupLength = response.data.count || 0
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    convertToJson () {
      let dataList = {}
      dataList = {
        name: this.roles.role1?.trim()
      }
      return dataList
    },
    add () {
      let obj = this.default()
      this.rows.push(obj)
    },
    default () {
      return {
        id: this.rows.length,
        variable1: '',
        variable2: '',
        variable3: '',
        variable4: '',
        variable5: '2011/04/25',
        variable6: '$0',
        editable: false
      }
    },
    resetValue () {
      this.roles.role1 = null
      this.roles.role2 = null
    },
    edit (item) {
      item.editable = true
    },
    submit (item) {
      item.editable = false
    },
    remove (id) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะลบข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let DelroleAPI = {
            method: 'post',
            url: `${configAPI.MD_PORT}/api/role/${id}/delete`,
            headers: {
              'Content-Type': 'application/json'
            }
          }
          axios.request(DelroleAPI).then(() => {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
              text: '',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000,
              // confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
              customClass: {
                confirmButton: 'custom-confirm-button-class'
              },
              confirmButtonColor: '#099FB0'
            }).then(() => {
              this.showModal = false
              this.getRoleGroup()
            })
          }).catch(() => {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลไม่สำเร็จ</span>',
              text: '',
              icon: 'error',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000,
              // confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
              customClass: {
                confirmButton: 'custom-confirm-button-class'
              },
              confirmButtonColor: '#099FB0'
            }).then(() => {
              this.showModal = false
              this.getRoleGroup()
            })
          })
        }
      })
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะยกเลิกใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.showModal = false
        }
      })
    },
    addRole () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะบันทึกข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let AddroleAPI = {
            method: 'post',
            url: `${configAPI.MD_PORT}/api/role`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: this.convertToJson()
          }
          await axios.request(AddroleAPI).then((response) => {
            if (response.data.status) {
              Vue.swal({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">เพิ่มข้อมูลสำเร็จ</span>',
                text: '',
                icon: 'success',
                timer: 2000,
                allowEscapeKey: false,
                allowOutsideClick: false,
                // confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
                customClass: {
                  confirmButton: 'custom-confirm-button-class'
                },
                confirmButtonColor: '#099FB0'
              }).then(() => {
                this.showModal = false
                this.getRoleGroup()
              })
            } else {
              this.SwalError(response.data.error === 'name duplicate' ? 'ระดับการบริหารนี้ซ้ำกับในระบบ กรุณาลองใหม่' : response.data.error, 'บันทึกข้อมูลไม่สำเร็จ')
            }
          }).catch((error) => {
            this.SwalError(error.message, 'เพิ่มข้อมูลไม่สำเร็จ')
          })
        }
      })
    },
    setEditRole (data, index) {
      this.roles.id = data.id
      this.roles.index = data.index
      this.roles.role1 = data.name?.trim()
    },
    resetRole () {
      this.roles.id = null
      this.roles.index = null
      this.roles.role1 = null
      this.showModal = true
      // this.indexTableEdit = index
    },
    editRole (id) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะแก้ไขข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let EditroleAPI = {
            method: 'put',
            url: `${configAPI.MD_PORT}/api/role/` + id,
            headers: {
              'Content-Type': 'application/json'
            },
            data: this.convertToJson()
          }
          await axios.request(EditroleAPI).then((response) => {
            if (response.data.status) {
              Vue.swal({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">แก้ไขข้อมูลสำเร็จ</span>',
                text: '',
                icon: 'success',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000,
                // confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
                customClass: {
                  confirmButton: 'custom-confirm-button-class'
                },
                confirmButtonColor: '#099FB0'
              }).then(() => {
                this.showModal = false
                this.getRoleGroup()
              })
            } else {
              this.SwalError(response.data.error === 'name duplicate' ? 'ระดับการบริหารนี้ซ้ำกับในระบบ กรุณาลองใหม่' : response.data.error, 'แก้ไขข้อมูลไม่สำเร็จ')
            }
          }).catch((error) => {
            this.SwalError(error.message, 'แก้ไขข้อมูลไม่สำเร็จ')
          })
        }
      })
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return ''
      const index = this.rolegroup.data.indexOf(item) + 1
      if (index % 2 === 1) return 'table-body-color'
    }
  },
  data () {
    return {
      searchTerm: '',
      rolegroup: [],
      rolegroupLength: 0,
      showModal: false,
      columns: [
        { label: 'ลำดับ', key: 'variable1', class: 'text-center table-size-100-300' }, /* รหัสระดับบริหาร */
        { label: 'ระดับการบริหาร', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'variable3', class: 'text-center table-size-200-400' }
      ],
      roles: {
        role1: null,
        role2: null,
        role3: null,
        role4: null
      },
      currentPage: 1,
      perPage: 10
    }
  }
}
</script>
