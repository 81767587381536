<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">กลุ่มพนักงาน</span>
              </div>
              <div class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="submit" variant="primary" @click="resetValue(), showModal = true, checkmodal = 'add'" class="w-100 btn-min-size"><i class="circle-plus_ct"></i>เพิ่ม</b-button>
                <template v-if="checkmodal === 'add'">
                  <b-modal v-model="showModal" title="กลุ่มพนักงาน (เพิ่ม)" cancel-title="เพิ่ม" ok-title="ยกเลิก"
                    cancel-variant="primary" ok-variant="danger" @cancel="addEmployeeGroup" @ok="handleClick()" :cancel-disabled="!employeeGroup.emp1 ? true : false"
                    @close.prevent="handleClick()" :no-close-on-esc="true" :no-close-on-backdrop="true">
                    <b-form-group label="ชื่อกลุ่มพนักงาน" style="color: black;">
                      <template v-if="employeeGroup.emp1 === '' || employeeGroup.emp1 === null" v-slot:label>
                        ชื่อกลุ่มพนักงาน <span style="color: red;">*</span>
                      </template>
                      <b-form-input id="" v-model="employeeGroup.emp1" type="text"></b-form-input>
                    </b-form-group>
                    <template #modal-footer>
                        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                          <b-button
                            style="border-radius: 0.5rem; padding: 0 .7rem;"
                            variant="primary"
                            class="btn-min-size"
                            size="sm"
                            @click="addEmployeeGroup"
                            :disabled="employeeGroup.emp1?.trim().length > 0 ? false : true"
                            :hidden="checkmodal === 'view' ? true : false"
                          >
                            <i class="save-data_ct"/>บันทึก
                          </b-button>
                          <b-button
                            variant="none"
                            class="iq-bg-danger btn-cancel-border btn-min-size"
                            style="border-radius: 0.5rem;"
                            size="sm"
                            @click="handleClick()"
                          >
                            ยกเลิก
                          </b-button>
                        </div>
                      </template>
                  </b-modal>
                </template>
                <template v-else>
                  <b-modal v-model="showModal" :title="`${checkmodal === 'view' ? 'กลุ่มพนักงาน (ดูรายละเอียด)' : 'กลุ่มพนักงาน (แก้ไข)'}`" cancel-title="แก้ไข" ok-title="ยกเลิก"
                    cancel-variant="primary" ok-variant="danger" @cancel="editEmployeeGroup" @ok="handleClick()" :cancel-disabled="!employeeGroup.emp1 ? true : false"
                    @close.prevent="handleClick()" :no-close-on-esc="true" :no-close-on-backdrop="true">
                    <b-form-group label="ชื่อกลุ่มพนักงาน" style="color: black;">
                      <template v-if="employeeGroup.emp1 === '' || employeeGroup.emp1 === null" v-slot:label>
                        ชื่อกลุ่มพนักงาน <span style="color: red;">*</span>
                      </template>
                      <b-form-input :disabled="checkmodal === 'view' ? true : false" id="" v-model="employeeGroup.emp1" type="text"></b-form-input>
                    </b-form-group>
                    <template #modal-footer>
                        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                          <b-button
                            style="border-radius: 0.5rem; padding: 0 .7rem;"
                            variant="primary"
                            class="btn-min-size"
                            size="sm"
                            @click="editEmployeeGroup(employeeGroup.id)"
                            :disabled="employeeGroup.emp1?.trim().length > 0 ? false : true"
                            :hidden="checkmodal === 'view' ? true : false"
                          >
                            <i class="save-data_ct"/>บันทึก
                          </b-button>
                          <b-button
                            variant="none"
                            class="iq-bg-danger btn-cancel-border btn-min-size"
                            style="border-radius: 0.5rem;"
                            size="sm"
                            @click="checkmodal === 'view' ? showModal=false : handleClick()"
                          >
                            ยกเลิก
                          </b-button>
                        </div>
                      </template>
                  </b-modal>
                </template>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" md="7" lg="5" class="mb-3">
                  <b-row>
                    <b-col sm="12" md class="mb-3">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input v-model="searchData" @keydown="enterSearch" type="text" class="form-control" id="exampleInputEmail1" placeholder="Search" style="width: 100%;">
                            <a class="search-link" @click="getEmployeeGroup()"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                      <span class="mx-2 font-size-14 text-primary">
                        <!-- employeegroupLength -->
                        {{ employeegroup.data?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > employeegroup.data?.length) ? employeegroup.data?.length : (perPage * currentPage) ) + ' of ' + employeegroupLength : '0 of ' + employeegroupLength }} รายการ
                        <!-- {{ employeegroup.data?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > employeegroup.data?.length) ? employeegroup.data?.length : (perPage * currentPage) ) + ' of ' + employeegroup.data?.length : 0 }} รายการ -->
                      </span></b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <b-col md="12" class="table-responsive">
              <b-table bordered hover :items="employeegroup.data" :fields="columns"
                :current-page="currentPage"
                :per-page="perPage"
                @pagination="onPagination"
                :tbody-tr-class="rowClass" thead-class="table-header-color"
                >
                <template v-slot:cell(variable1)="data">
                  <span v-if="!data.item.editable">{{ ((currentPage - 1) * 10) + data.index + 1 }}</span>
                </template>
                <template v-slot:cell(variable2)="data">
                  <span v-if="!data.item.editable">{{ employeegroup.data[((currentPage - 1) * 10) + data.index].name }}</span>
                </template>
                <template v-slot:cell(variable3)="data">
                  <div style="display: flex; justify-content: center;">
                    <b-button variant=" iq-bg-primary mr-1" size="sm" @click="managePermission(employeegroup.data[((currentPage - 1) * 10) + data.index].id)"><i class="ri-list-settings-line m-0"/></b-button>
                    <b-button variant=" iq-bg-success mr-1" size="sm" @click="showModal = true, checkmodal = 'edit', setEditEmployeeGroup(data.item, ((currentPage - 1) * 10) + data.index)" ><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" size="sm" @click="remove(employeegroup.data[((currentPage - 1) * 10) + data.index].id)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </div>
                </template>
              </b-table>
              <br>
              <b-pagination v-model="currentPage" :total-rows="employeegroup.data?.length" :per-page="perPage" align="center" />
            </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.width-label{
  width: 200px !important;
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import axios from 'axios'
import Vue from 'vue'
import { configAPI } from '../../configBase'

export default {
  name: 'UiDataTable',
  async mounted () {
    xray.index()
    await this.getEmployeeGroup()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getEmployeeGroup()
      }
    },
    async getEmployeeGroup () {
      let employeeGroup = {
        method: 'get',
        url: `${configAPI.MD_PORT}/api/employeeGroup?search=` + this.searchData,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      axios.request(employeeGroup)
        .then((response) => {
          this.employeegroup = response.data
          this.employeegroupLength = response.data.count || 0
        })
    },
    add () {
      let obj = this.default()
      this.rows.push(obj)
    },
    default () {
      return {
        id: this.rows.length,
        variable1: '',
        variable2: '',
        variable3: '',
        variable4: '',
        variable5: '2011/04/25',
        variable6: '$0',
        editable: false
      }
    },
    resetValue () {
      this.employeeGroup.emp1 = null
      this.employeeGroup.emp2 = null
    },
    edit (item) {
      item.editable = true
    },
    submit (item) {
      item.editable = false
    },
    remove (id) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะลบข้อมูลใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let DelemployeeGroup = {
            method: 'delete',
            url: `${configAPI.MD_PORT}/api/employeeGroup/` + id,
            headers: {
              'Content-Type': 'application/json'
            }
          }
          axios.request(DelemployeeGroup)
            .then(async () => {
              Vue.swal({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
                icon: 'success',
                showConfirmButton: false,
                showCancelButton: false,
                allowEscapeKey: false,
                allowOutsideClick: false,
                timer: 2000
              })
              this.searchData = ''
              await this.getEmployeeGroup()
            })
        }
      })
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะยกเลิกใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.showModal = false
        }
      })
    },
    addEmployeeGroup () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะบันทึกข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          let temp = {
            name: this.employeeGroup.emp1?.trim()
          }
          let AddemployeeGroup = {
            method: 'post',
            url: `${configAPI.MD_PORT}/api/employeeGroup`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: temp
          }
          await axios.request(AddemployeeGroup)
            .then(async (response) => {
              if (response.data.status === false) {
                this.SwalError(response.data.error === 'name duplicate' ? 'กลุ่มพนักงานนี้ซ้ำกับในระบบ กรุณาลองใหม่' : response.data.error, 'บันทึกข้อมูลไม่สำเร็จ')
              } else {
                Vue.swal({
                  title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
                  icon: 'success',
                  showCancelButton: false,
                  showConfirmButton: false,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  timer: 2000
                }).then(() => {
                  this.showModal = false
                })
                this.searchData = ''
                await this.getEmployeeGroup()
              }
            }).catch((error) => {
              this.SwalError(error.message, 'บันทึกข้อมูลไม่สำเร็จ')
            })
        }
      })
    },
    setEditEmployeeGroup (data, index) {
      this.employeeGroup.id = data.id
      this.employeeGroup.index = data.index
      this.employeeGroup.emp1 = data.name
    },
    resetEmployeeGroup () {
      this.employeeGroup.id = null
      this.employeeGroup.index = null
      this.employeeGroup.emp1 = null
      this.showModal = true
    },
    editEmployeeGroup (id) {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะแก้ไขข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let temp = {
            name: this.employeeGroup.emp1?.trim()
          }
          let EditemployeeGroup = {
            method: 'put',
            url: `${configAPI.MD_PORT}/api/employeeGroup/` + id,
            headers: {
              'Content-Type': 'application/json'
            },
            data: temp
          }
          axios.request(EditemployeeGroup)
            .then(async (response) => {
              if (response.data.status === false) {
                this.SwalError(response.data.error === 'name duplicate' ? 'กลุ่มพนักงานนี้ซ้ำกับในระบบ กรุณาลองใหม่' : response.data.error, 'แก้ไขข้อมูลไม่สำเร็จ')
              } else {
                Vue.swal({
                  title: '<span class="" style="font-size: 25px; color: black; text-align: center">แก้ไขข้อมูลสำเร็จ</span>',
                  icon: 'success',
                  showCancelButton: false,
                  showConfirmButton: false,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  timer: 2000
                }).then(() => {
                  this.showModal = false
                })
                this.searchData = ''
                await this.getEmployeeGroup()
              }
            }).catch((error) => {
              this.SwalError(error.message, 'แก้ไขข้อมูลไม่สำเร็จ')
            })
        }
      })
    },
    managePermission (id) {
      router.push('EmployeeGroup/Permission/' + id)
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return ''
      const index = this.employeegroup.data.indexOf(item) + 1
      if (index % 2 === 1) return 'table-body-color'
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    }
  },
  data () {
    return {
      searchData: '',
      employeegroup: [],
      employeegroupLength: 0,
      showModal: false,
      columns: [
        { label: 'ลำดับ', key: 'variable1', class: 'text-center table-size-100-300' }, /* รหัสกลุ่มพนักงาน */
        { label: 'กลุ่มพนักงาน', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'variable3', class: 'text-center table-size-200-400' }
      ],
      employeeGroup: {
        emp1: null,
        emp2: null,
        emp3: null
      },
      currentPage: 1,
      perPage: 10
    }
  }
}
</script>
