<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" lg="3">
        <iq-card class="calender-small">
          <template v-slot:body>
            <div class="response-flat-piker-apointment">
              <div class="input-group">
                <flat-pickr :config="config" v-model="dateFlat.day" class="d-none" data-input ref="flatpickr" id="flatpickrID"/>
              </div>
            </div>
          </template>
        </iq-card>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title ">สถานะคิวลูกค้า</h4>
          </template>
          <template v-slot:headerAction>
            <!-- <a href="#"><i class="fa fa-plus  mr-0" aria-hidden="true" /></a> -->
          </template>
          <template v-slot:body>
            <ul class="m-0 p-0 job-classification">
              <li class="d-flex" style="cursor: pointer;" @click="filterStatusClick('รอการนัดหมาย')">
                <i class="ri-checkbox-blank-circle-fill text-danger" />
                <span v-if="statusStage.status1">รอการนัดหมาย</span>
                <span v-else><del>รอการนัดหมาย</del></span>
              </li>
              <li class="d-flex" style="cursor: pointer;" @click="filterStatusClick('ยืนยันเข้ารับบริการ')">
                <i class="ri-checkbox-blank-circle-fill text-success" />
                <span v-if="statusStage.status2">ยืนยันเข้ารับบริการ</span>
                <span v-else><del>ยืนยันเข้ารับบริการ</del></span>
              </li>
              <li class="d-flex" style="cursor: pointer;" @click="filterStatusClick('เข้าใช้บริการ')">
                <i class="ri-checkbox-blank-circle-fill text-warning" />
                <span v-if="statusStage.status3">เข้าใช้บริการ</span>
                <span v-else><del>เข้าใช้บริการ</del></span>
              </li>
              <li class="d-flex" style="cursor: pointer;" @click="filterStatusClick('ยกเลิก / ไม่มาใช้บริการ')">
                <i class="ri-checkbox-blank-circle-fill text-info" />
                <span v-if="statusStage.status4">ยกเลิก / ไม่มาใช้บริการ</span>
                <span v-else><del>ยกเลิก / ไม่มาใช้บริการ</del></span>
              </li>
            </ul>
          </template>
        </iq-card>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">ตารางรอการยืนยัน <!-- : {{ dateTableWaitingConfirm }} --></h4>
          </template>
          <template v-slot:body>
            <ul class="m-0 p-0 today-schedule format-scroll-appointment">
              <li v-for="(item, index) in tableConfirms" :key="index" class="d-flex" style="border-bottom: 1px solid rgb(197, 197, 197); padding-bottom: .5rem;">
                <div class="schedule-icon"><i class="ri-checkbox-blank-circle-fill text-success" /></div>
                <div class="schedule-text">
                  <span>เวลา : {{ item.date }}</span>
                  <span>คุณ : {{ item.cca_cus_name }}</span>
                  <span>เบอร์โทร : {{ item.tel }}</span>
                  <span>รายละเอียดเพิ่มเติม : {{ item.details }}</span>
                </div>
              </li>
              <!--
              <li class="d-flex">
                <div class="schedule-icon"><i class="ri-checkbox-blank-circle-fill text-primary" /></div>
                <div class="schedule-text">
                  <span>12:30 to 13:00</span>
                  <span>สุภเดช วงศ์วัฒนาพันธุ์ HN002443 | Jack Klongboonjit | DIODE2(UP)</span>
                  <span>หมายเหตุ : ขนน้องชายกับรักแร้</span>
                </div>
              </li>
              <hr>
              <li class="d-flex">
                <div class="schedule-icon"><i class="ri-checkbox-blank-circle-fill text-primary" /></div>
                <div class="schedule-text">
                  <span>12:30 to 13:00</span>
                  <span>สุภเดช วงศ์วัฒนาพันธุ์ HN002443 | Jack Klongboonjit | DIODE2(UP)</span>
                  <span>หมายเหตุ : ขนน้องชายกับรักแร้</span>
                </div>
              </li>
              <hr>
              <li class="d-flex">
                <div class="schedule-icon"><i class="ri-checkbox-blank-circle-fill text-primary" /></div>
                <div class="schedule-text">
                  <span>12:30 to 13:00</span>
                  <span>สุภเดช วงศ์วัฒนาพันธุ์ HN002443 | Jack Klongboonjit | DIODE2(UP)</span>
                  <span>หมายเหตุ : ขนน้องชายกับรักแร้</span>
                </div>
              </li>
              <hr>
              <li class="d-flex">
                <div class="schedule-icon"><i class="ri-checkbox-blank-circle-fill text-primary" /></div>
                <div class="schedule-text">
                  <span>12:30 to 13:00</span>
                  <span>สุภเดช วงศ์วัฒนาพันธุ์ HN002443 | Jack Klongboonjit | DIODE2(UP)</span>
                  <span>หมายเหตุ : ขนน้องชายกับรักแร้</span>
                </div>
              </li>
              <hr> -->
            </ul>
          </template>
        </iq-card>
      </b-col>
      <b-col md="12" lg="9">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-start py-4" style="flex-direction: column; gap: 1rem;">
              <div class="col-lg-4 col-md-5 col-sm-5">
                <b-span class="" style="font-size: 25px; color: black;">จัดการคิวลูกค้า</b-span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 w-100 d-flex" style="align-items: center; gap: .5rem;">
                <!-- <b-form-input type="text" v-model="searchData" ></b-form-input> -->
                <b-form-group label-for="exampleFormControlSelect1" class="mb-0 w-100" label="">
                  <v-select v-model="searchData" :options="calendarDD" :get-option-label="(option) => option.shortLabel">
                    <template #option="item">
                      <span style="font-size: 1rem;"><b>ชื่อ:</b>{{ ` ${item.shortLabel}` }}</span>
                      <br />
                      <span style="opacity: 0.9;"><b>วันนัด:</b>{{ ` ${item.dateTime}` }}</span>
                      <br />
                      <span style="opacity: 0.9;"><b>รายละเอียด:</b>{{ ` ${item.details}` }}</span>
                      <br />
                      <span style="opacity: 0.9;"><b> สถานะ: </b> <i :class="'ri-checkbox-blank-circle-fill ' + item.color" /> {{ ` ${item.status}` }}</span>
                      <hr class="mb-0" />
                    </template>
                  </v-select>
                </b-form-group>
                <!-- <i class="ri-search-line" style="font-size: 1.5rem; color: rgb(8, 155, 171);" @click="searchCalendarEvent"></i> -->
              </div>
              <!-- <div style="position: relative;">
                <b-form-input v-model="searchCalendar" @focus="onFocus = true" @blur="onFocus = false" type="text" ></b-form-input>
                <div class="card-appointment-parent">
                  <div :hidden="!onFocus" v-for="(item, index) in calendarListSearch" :key="index" >
                    <div class="item-card-appointment-parent">
                      <p style="color: black; margin-bottom: 0;">{{ item.title }}</p>
                      <p style="color: black; margin-bottom: 0;">{{ item.additionalData.dateTime }}</p>
                      <p style="color: black; margin-bottom: 0;">{{ item.additionalData.customer.label ? item.additionalData.customer.label : item.additionalData.customer }}</p>
                      <p style="color: black; margin-bottom: 0;">{{ item.additionalData.status.label }}</p>
                    </div>
                    <p class="line-item-card-appointment-parent mb-0"></p>
                  </div>
                </div>
              </div> -->

              <div class="col-lg-6 col-md-6 col-sm-12   col-12 mt-3 mt-md-0">
                <div class="d-flex justify-content-end">
                  <b-button type="button" @click="setAddEventCalendar(true)" variant="primary" class="w-100 text-center" style="white-space: nowrap;"><i class="add-cus_ct"/>เพิ่มนัดลูกค้าใหม่</b-button>
                  <b-button type="button" @click="setAddEventCalendar(false)" variant="primary" class="w-100 text-center ml-2" style="white-space: nowrap;"><i class="meet-sch_ct"/>นัดลูกค้าเก่า</b-button>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="demo-app">
              <!-- <div class="demo-app-top">
                <button @click="toggleWeekends">toggle weekends</button>
                <button @click="gotoPast">go to a date in the past</button>
                (also, click a date/time to add an event)
              </div> -->
              <!-- scrollTime='00:00' -->
              <FullCalendar
                class="demo-app-calendar"
                ref="fullCalendar"
                defaultView="dayGridMonth"
                :header="{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                }"
                locale='en-GB'
                :plugins="calendarPlugins"
                :weekends="calendarWeekends"
                :events="calendarEvents"
                :custom-buttons="customButtons"
                @dateClick="handleDateClick"
                @eventClick="handleEventClick"
                @eventMouseEnter="handleEventMouseEnter"
                @eventChange="handleEventChange"
                :eventRender="eventRenderCallback"
                :eventTimeFormat="{ hour: '2-digit', minute: '2-digit', hour12: false }"
                :slotDuration="'00:10:00'"
                :slotLabelFormat="{
                  hour: '2-digit',
                  minute: '2-digit',
                  omitZeroMinute: false, // Display '00' for zero minutes
                  hour12: false // Use 24-hour format
                }"
              />
            </div>
          </template>
          <b-modal v-model="showModalSelectDay" centered :title="getNameModal" size="md" no-close-on-backdrop>
            <b-row>
              <b-col lg="12" sm="12">
                <b-text style="color: black;">ช่วงเวลา <span v-if="!appointMent.dateTime" style="color: red;">*</span></b-text>
                <div style="position: relative;">
                  <date-picker placeholder="DD/MM/YYYY HH:mm" id="datepicker-custom" name="date" v-model="appointMent.dateTime" :config="options"></date-picker>
                  <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker" style="cursor: pointer;"></i>
                </div>
              </b-col>
            </b-row>
            <br>
            <b-row>
              <b-col lg="12" sm="12">
                <div v-if="customerisNew === true">
                  <b-text style="color: black;">ชื่อลูกค้า <span v-if="!appointMent.customer" style="color: red;">*</span></b-text>
                  <b-form-input type="text" v-model="appointMent.customer" placeholder=""></b-form-input>
                </div>
                <div v-else label-for="exampleFormControlSelect1" label="ชื่อลูกค้า">
                  <b-form-group label-for="exampleFormControlSelect1" label="" style="color: black; margin-bottom: 0%;">
                    <b-text style="color: black;">ชื่อลูกค้า <span v-if="!appointMent.customer" style="color: red;">*</span></b-text>
                    <v-select v-model="appointMent.customer" :options="items2"></v-select>
                  </b-form-group>
                  <!-- <label class="typo__label" style="margin: 0;">ชื่อลูกค้า *</label>
                  <multiselect :disabled="mode === 'add' ? false : true" v-model="appointMent.customer" :options="items2" placeholder="Select" label="name" selectLabel="" track-by="name"></multiselect> -->
                </div>
                <!-- <b-form-group class="unselectable" :disabled="mode === 'add' ? false : true" style="color: black;" label="ชื่อลูกค้า">
                <b-form-select plain v-model="appointMent.customer" :options="items2" size="lg">
                    <template v-slot:first>
                    </template>
                  </b-form-select>
                </b-form-group> -->
              </b-col>
            </b-row>
            <br>
            <b-row>
              <b-col lg="12" sm="12">
                <div>
                  <b-text style="color: black;">เบอร์โทร <span v-if="!appointMent.phone" style="color: red;">*</span></b-text>
                  <the-mask mask="###-###-####" class="form-control" v-model="appointMent.phone" type="tel" :masked="false" placeholder="xxx-xxx-xxxx"></the-mask>
                </div>
              </b-col>
            </b-row>
            <br>
            <b-row>
              <b-col lg="12" sm="12">
                <b-text style="color: black;">รายละเอียดเพิ่มเติม <span v-if="!appointMent.details" style="color: red;">*</span></b-text>
                <b-form-textarea type="text" v-model="appointMent.details" :disabled="isDisable" placeholder="" style="height: 100px;"></b-form-textarea>
              </b-col>
            </b-row>
            <br>
            <b-row>
              <b-col lg="12" sm="12">
                <b-form-group class="unselectable" style="color: black;" label="">
                  <b-text style="color: black;">สถานะ <span v-if="!appointMent.status" style="color: red;">*</span></b-text>
                  <v-select v-model="appointMent.status" :options="items1"></v-select>
                <!-- <b-form-select plain v-model="appointMent.status" :options="items1" size="lg">
                    <template v-slot:first>
                    </template>
                  </b-form-select> -->
                </b-form-group>
              </b-col>
            </b-row>
            <template #modal-footer>
              <div v-if="mode !== 'add'" class="w-100" style="display: flex; justify-content: space-between; gap: 0.5rem;">
                <div>
                  <b-button
                      variant="danger"
                      style="border-radius: 0.5rem;"
                      size="sm"
                      @click="deleteEventCalendar"
                    >
                      ลบ
                  </b-button>
                </div>
                <div style="gap: 0.5rem; display: flex;">
                  <b-button
                      style="border-radius: 0.5rem;"
                      variant="primary"
                      class="btn-min-size"
                      size="sm"
                      @click="addEventCalendar"
                      :disabled="isDisable"
                    >
                    <i class="save-data_ct"/>บันทึก
                    </b-button>
                    <b-button
                      class="iq-bg-danger btn-min-size btn-cancel-border"
                      style="border-radius: 0.5rem; border: none;"
                      size="sm"
                      @click="showModalSelectDay = false"
                    >
                      ยกเลิก
                  </b-button>
                </div>
                </div>
                <div v-else class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
                  <b-button
                      style="border-radius: 0.5rem;"
                      variant="primary"
                      class="btn-min-size"
                      size="sm"
                      @click="addEventCalendar"
                      :disabled="isDisable"
                    >
                    <i class="save-data_ct"/>บันทึก
                    </b-button>
                    <b-button
                      class="iq-bg-danger btn-min-size btn-cancel-border"
                      style="border-radius: 0.5rem; border: none;"
                      size="sm"
                      @click="checkForCancle"
                    >
                      ยกเลิก
                  </b-button>
                </div>
            </template>
          </b-modal>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
/* Adjust the width of timeGridWeek view */

.fc-view-container {
    overflow: auto;
    white-space: nowrap;
}

.demo-app > .demo-app-calendar > .fc-view-container > .fc-timeGridWeek-view > table > .fc-head,
.demo-app > .demo-app-calendar > .fc-view-container > .fc-timeGridDay-view > table > .fc-head {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 999;
}
.demo-app > .demo-app-calendar > .fc-view-container > .fc-timeGridWeek-view > table > .fc-head > tr > .fc-head-container,
.demo-app > .demo-app-calendar > .fc-view-container > .fc-timeGridDay-view > table > .fc-head > tr > .fc-head-container {
  border-top-width: 0px;
}

.demo-app > .demo-app-calendar > .fc-view-container > .fc-timeGridWeek-view > table > .fc-head > tr > td > .fc-row,
.demo-app > .demo-app-calendar > .fc-view-container > .fc-timeGridDay-view > table > .fc-head > tr > td > .fc-row{
  border: 1.5px solid #e1e1e1 !important;
}

.demo-app > .demo-app-calendar > .fc-view-container > .fc-timeGridWeek-view {
    width: 80vw; /* Adjust the width as needed */
    height: 35vw;
}

.demo-app > .demo-app-calendar > .fc-view-container > .fc-timeGridDay-view {
    height: 35vw;
}
@media (max-width: 1583px) {
  .response-flat-piker-apointment > .input-group > .flatpickr-calendar > .flatpickr-months > .flatpickr-month {
    height: 60px;
  }

  .demo-app > .demo-app-calendar > .fc-view-container > .fc-timeGridWeek-view {
    width: 80vw; /* Adjust the width as needed */
    height: 45vw;
  }

  .demo-app > .demo-app-calendar > .fc-view-container > .fc-timeGridDay-view {
    height: 45vw;
  }
}

@media (max-width: 1250px) {
  .demo-app > .demo-app-calendar > .fc-view-container > .fc-timeGridWeek-view {
    width: 80vw; /* Adjust the width as needed */
    height: 55vw;
  }

  .demo-app > .demo-app-calendar > .fc-view-container > .fc-timeGridDay-view {
    height: 55vw;
  }
}

@media (max-width: 1000px) {
  .demo-app > .demo-app-calendar > .fc-view-container > .fc-timeGridWeek-view {
    width: 80vw; /* Adjust the width as needed */
    height: 60vh;
  }

  .demo-app > .demo-app-calendar > .fc-view-container > .fc-timeGridDay-view {
    height: 60vh;
  }
}

/* Apply an overlay effect */
.custom-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Adjust the width as needed */
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    color: white;
    text-align: center;
    z-index: 1; /* Make it appear above the calendar */
}

.fc-title {
  text-overflow: ellipsis !important;
  width: max-content !important;
  overflow: hidden !important;
}

.card-appointment-parent {
  margin-top: 0.1rem;
  z-index: 1999 !important;
  border-radius: 5px;
  width: 400px;
  max-height: 200px;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 4px 4px 9px -5px #555555;
  overflow-y: scroll;
}

.item-card-appointment-parent {
  padding: 1rem .5rem .5rem .5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.item-card-appointment-parent:hover {
  background-color: #479cac;
}

.line-item-card-appointment-parent {
  margin: 0 .5rem;
  width: 380px;
  background-color: rgb(112, 112, 112);
  height: 1px;
}
/* .response-flat-piker-apointment {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1000px) {
  .response-flat-piker-apointment {
    overflow-x: overlay;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
*/
.numInputWrapper > .arrowUp, .arrowDown {
  opacity: 1 !important;
}
.fc-axis {
    vertical-align: middle !important;
    /* padding: 0 2rem !important; */
    padding-right: 2.6rem !important;
    white-space: nowrap !important;
    padding-left: .5rem !important;
}

@media (max-width: 576px) {
  .fc-header-toolbar {
    flex-direction: column;
    gap: 1rem;
  }
  .response-flat-piker-apointment {
    overflow-x: overlay;
    padding: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.response-flat-piker-apointment {
  overflow-x: overlay;
  padding: .5rem;
}
.flatpickr-calendar {
    width: 320px !important;
    height: 320px !important;
}

.fc-button-group > .fc-prev-button, .fc-next-button {
  display: flex;
  align-items: center;
}
.fc-button-group {
  gap: .5rem;
}
.flex-align-center-appointment {
  align-items: center;
}
.circle-color {
  padding: 7px;
  border-radius: 50px;
  display: inline-flex;
  height: 100%;
}
.green-coler-bullet > .fc-content > .bullet-calendar {
  background-color: #27b345 !important;
}
.red-coler-bullet > .fc-content > .bullet-calendar {
  background-color: #f14336 !important;
}
.orange-coler-bullet > .fc-content > .bullet-calendar {
  background-color: #fbc647 !important;
}
.blue-coler-bullet > .fc-content > .bullet-calendar {
  background-color: #6ce6f4 !important;
}
.icon-calendar-edit {
  position: absolute;
  height: 100%;
  font-size: 1.2rem;
  padding-right: 1rem;
  font-weight: 100;
  display: flex;
  color: rgb(100, 100, 100);
  top: 0px;
  right: 0px;
  align-items: center;
}
.selected-date {
  background-color: rgb(227, 251, 254);
}
.format-scroll-appointment {
  max-height: 10.5rem;
  overflow: overlay;
}
.fc-event-container a {
  background: rgb(66, 104, 240);
  border: none !important;
}
.demo-app {
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

.demo-app-top {
  margin: 0 0 3em;
}
/*
.demo-app-calendar {
  margin: 0 auto;
  max-height: 900px;
  height: 100%;
} */
</style>

<script>
import { xray } from '../../config/pluginInit'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import Vue from 'vue'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
// must manually include stylesheets for each plugin
import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import { configAPI } from '../../configBase'
import axios from 'axios'
import flatpickr from 'flatpickr'
// import Multiselect from 'vue-multiselect'

const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}
/* const testDataTest = [
  {
    dateTime: '31/06/2023 10:00',
    customer: 'นาย ใหม่ ลูกค้าใหม่',
    details: 'ฉีดโบทอกซ์',
    status: { code: 'Supplier4', label: 'ยกเลิก / ไม่มาใช้บริการ' }
  },
  {
    dateTime: '31/06/2023 11:00',
    customer: { code: 'option1', label: 'นาย สมชาย  ใจดี1' },
    details: 'ผ่าตัด',
    status: { code: 'Supplier1', label: 'รอการนัดหมาย' }
  },
  {
    dateTime: '31/06/2023 11:00',
    customer: 'นาย ใหม่ ลูกค้าใหม่',
    details: 'วัดความดัน',
    status: { code: 'Supplier3', label: 'เข้าใช้บริการ' }
  },
  {
    dateTime: '28/06/2023 13:00',
    customer: 'นาย ใหม่ ลูกค้าใหม่2',
    details: 'ผ่าตัด',
    status: { code: 'Supplier2', label: 'ยืนยันเข้ารับบริการ' }
  },
  {
    dateTime: '17/06/2023 14:00',
    customer: { code: 'option3', label: 'นาย สมชาย  ใจดี3' },
    details: 'ฉีดโบทอกซ์',
    status: { code: 'Supplier1', label: 'รอการนัดหมาย' }

  }
] */

/* const calendarE = [
  { id: '12345', title: 'ฉีดโบทอกซ์', start: '2023-06-31T10:12:34', classNames: ['blue-coler-bullet'], additionalData: testDataTest[0], backgroundColor: '#f8de7e !important', textColor: '#242124 !important' },
  { id: '12346', title: 'ผ่าตัด', start: '2023-06-31T11:12:34', classNames: ['red-coler-bullet'], additionalData: testDataTest[1], backgroundColor: '#ffe4c4 !important', textColor: '#242124 !important' },
  { id: '12348', title: 'วัดความดัน', start: '2023-07-11T10:12:34', classNames: ['orange-coler-bullet'], additionalData: testDataTest[2], backgroundColor: '#fbceb1 !important', textColor: '#242124 !important' },
  { id: '12349', title: 'วัดความดัน2', start: '2023-07-12T10:12:34', classNames: ['green-coler-bullet'], additionalData: testDataTest[3], backgroundColor: '#fbce1a !important', textColor: '#242124 !important' }
] */

export default {
  name: 'GoogleCalendar',
  // calendarHeight: '1000px',
  components: {
    FullCalendar // make the <FullCalendar> tag available
    // Multiselect
  },
  data () {
    return {
      dateTableWaitingConfirm: moment().format('DD/MM/YYYY'),
      user_id: null,
      cln_id: null,
      searchCalendar: '',
      tableConfirms: [],
      calendarListSearch: [],
      searchData: null,
      onFocus: false,
      customerisNew: null,
      dateFlat: {
        year: new Date().getFullYear(),
        month: (new Date().getMonth() + 1).toString().padStart(2, '0'),
        day: '',
        date: null
      },
      isDisable: false,
      mode: 'add',
      editCalendarId: '',
      statusStage: {
        status1: true,
        status2: true,
        status3: true,
        status4: true
      },
      calendarEventAdd: {
        id: '',
        title: '',
        start: null,
        allDay: null,
        additionalData: null,
        classNames: []
      },
      appointMent: {
        id: '',
        dateTime: '05/07/2023 10:06',
        customer: '',
        details: '',
        status: null,
        phone: ''
      },
      resetAppointMent: {
        id: '',
        dateTime: '',
        customer: '',
        details: '',
        status: null,
        phone: ''
      },
      resetcalendarEventAdd: {
        id: '',
        title: '',
        start: null,
        allDay: null,
        additionalData: null,
        classNames: []
      },
      showModalSelectDay: false,
      calendarHeight: '100%',
      config: {
        dateFormat: 'Y-m-d',
        inline: true,
        onMonthChange: (selectedDates, dateStr, instance) => {
          this.dateFlat.month = (instance.currentMonth + 1).toString().padStart(2, '0')
        },
        onYearChange: (selectedDates, dateStr, instance) => {
          this.dateFlat.year = instance.currentYear
        },
        onChange: (selectedDates, dateStr, instance) => {
          const date = moment(selectedDates[0])/* .add('M', 1) */
          if (date) {
            this.dateFlat.year = date.get('y')
            this.dateFlat.month = (date.get('M') + 1).toString().padStart(2, '0')
          }
          this.dateFlat.date = moment(new Date(selectedDates)).format('DD/MM/YYYY HH:mm')
          this.getCalendarFilter()
          this.findDateAndSelect(moment(new Date(selectedDates)).format('YYYY-MM-DD'))
        }
      },
      items1: [
        /* { code: 'Supplier1', label: 'รอการนัดหมาย' },
        { code: 'Supplier2', label: 'ยืนยันเข้ารับบริการ' },
        { code: 'Supplier3', label: 'เข้าใช้บริการ' },
        { code: 'Supplier4', label: 'ยกเลิก / ไม่มาใช้บริการ' } */
      ],
      items2: [
        /* { code: 'option1', label: 'นาย สมชาย  ใจดี1' },
        { code: 'option2', label: 'นาย สมชาย  ใจดี2' },
        { code: 'option3', label: 'นาย สมชาย  ใจดี3' },
        { code: 'option4', label: 'นาย สมชาย  ใจดี4' } */
      ],
      options: {
        // https://momentjs.com/docs/#/displaying/
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        sideBySide: true,
        minDate: false,
        icons: iconDate,
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'bottom'
        }
      },
      events: [
        {
          title: 'All Day Event',
          start: '2023-06-27',
          color: '#fc9919'
        },
        {
          title: 'Long Event',
          start: '2019-12-07',
          end: '2019-12-10',
          color: '#ffc107' // override!
        },
        {
          groupId: '999',
          title: 'Repeating Event',
          start: '2019-12-09T16:00:00',
          color: '#17a2b8'
        },
        {
          groupId: '999',
          title: 'Repeating Event',
          start: '2019-12-16T16:00:00',
          color: '#17a2b8'
        },
        {
          title: 'Conference',
          start: '2019-12-11',
          end: '2019-12-13',
          color: '#27e3f4' // override!
        },
        {
          title: 'Meeting',
          start: '2019-12-12T10:30:00',
          end: '2019-12-12T12:30:00',
          color: '#0084ff'
        },
        {
          title: 'Lunch',
          start: '2019-12-12T12:00:00',
          color: '#777D74'
        },
        {
          title: 'Meeting',
          start: '2019-12-12T14:30:00',
          color: '#0084ff'
        },
        {
          title: 'Birthday Party',
          start: '2019-12-28T07:00:00',
          color: '#28a745'
        },
        {
          title: 'Meeting',
          start: '2020-01-12T14:30:00',
          color: '#0084ff'
        },
        {
          title: 'Birthday Party',
          start: '2020-01-02T07:00:00',
          color: '#28a745'
        },
        {
          title: 'Click for Google',
          url: 'http://google.com/',
          start: '2020-01-25'
        },
        {
          title: 'Birthday Party',
          start: '2020-01-13T07:00:00',
          color: '#28a745'
        },
        {
          title: 'Click for Google',
          url: 'http://google.com/',
          start: '2019-12-28'
        },
        {
          title: 'Meeting',
          start: '2020-01-12T14:30:00',
          color: '#0084ff'
        },
        {
          title: 'Birthday Party',
          start: '2020-01-13T07:00:00',
          color: '#28a745'
        },
        {
          title: 'Click for Google',
          url: 'http://google.com/',
          start: '2020-01-28'
        },
        {
          title: 'All Day Event',
          start: '2020-02-01',
          color: '#fc9919'
        },
        {
          title: 'Long Event',
          start: '2020-02-07',
          end: '2020-02-10',
          color: '#ffc107' // override!
        },
        {
          groupId: '999',
          title: 'Repeating Event',
          start: '2020-02-09T16:00:00',
          color: '#17a2b8'
        },
        {
          groupId: '999',
          title: 'Repeating Event',
          start: '2020-02-16T16:00:00',
          color: '#17a2b8'
        }
      ],
      calendarPlugins: [
        // plugins must be defined in the JS
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin // needed for dateClick
      ],
      calendarWeekends: true,
      calendarEvents: null,
      cloneCalendarEvents: null,
      customButtons: {
        prev: { // this overrides the prev button
          text: 'PREV',
          click: () => {
            let calendarApi = this.$refs.fullCalendar.getApi()
            calendarApi.prev()
            flatpickr('#flatpickrID', this.config).setDate(moment(calendarApi.state.currentDate).toDate())
            // document.getElementsByClassName('flatpickr-prev-month')[0].click()
          }
        },
        next: { // this overrides the next button
          text: 'Next',
          click: () => {
            let calendarApi = this.$refs.fullCalendar.getApi()
            calendarApi.next()
            flatpickr('#flatpickrID', this.config).setDate(moment(calendarApi.state.currentDate).toDate())
            // document.getElementsByClassName('flatpickr-next-month')[0].click()
          }
        },
        today: {
          text: 'Today',
          click: () => {
            let calendarApi = this.$refs.fullCalendar.getApi()
            calendarApi.today()
            flatpickr('#flatpickrID', this.config).setDate(moment(calendarApi.state.currentDate).toDate())
            // flatpickr('#flatpickrID', this.config).clear()
          }
        }
      }
    }
  },
  async mounted () {
    const data = JSON.parse(sessionStorage.getItem('user'))
    this.cln_id = data.cln_id
    this.user_id = data.usr_id
    await this.getCustomerCalStatus()
    await this.getCustomerOldDD()
    await this.getTableConfirms()
    await this.getEventCalendar()
    // this.calendarEvents = calendarE
    this.setFlatpickrWidth()
    this.$refs.fullCalendar.getApi().setOption('contentHeight', 850)
    // this.$refs.fullCalendar.getApi().setOption('aspectRatio', 5)
    setTimeout(() => {
      this.$refs.fullCalendar.getApi().setOption('contentHeight', 'auto')
    }, 800)

    xray.index()
    /* document.getElementsByClassName('vs__open-indicator')[0].setAttribute('viewBox', '0 0 14 10')
    document.getElementsByClassName('vs__open-indicator')[0].setAttribute('width', '10')
    document.getElementsByClassName('vs__open-indicator')[0].setAttribute('height', '6') */
    // console.log('this.calendarOptions :>> ', new Date().getMonth())
  },
  computed: {
    getNameModal () {
      if (this.mode === 'add') {
        return this.customerisNew === true ? 'เพิ่มนัดลูกค้าใหม่' : 'เพิ่มนัดลูกค้าเก่า'
      } else {
        return this.customerisNew === true ? 'แก้ไขนัดลูกค้าใหม่' : 'แก้ไขนัดลูกค้าเก่า'
      }
    }
  },
  watch: {
    'appointMent.customer': function (newVal, oldVal) {
      if (newVal && newVal.tel) {
        this.appointMent.phone = newVal.tel
      }
    },
    'dateTableWaitingConfirm': function (newVal, oldVal) {
      this.getTableConfirms(newVal)
    },
    'searchData': function (newVal, oldVal) {
      this.searchCalendarEvent()
    },
    'searchCalendar': function (newVal, oldVal) {
      this.calendarListSearch = this.calendarEvents.filter(el =>
        el.title?.includes(newVal)
      )
    },
    'appointMent.dateTime': function (newVal, oldVal) {
      // console.log('newV :>> ', newVal)
      this.appointMent.dateTime = newVal
    },
    'dateFlat.month': function (newVal, oldVal) {
      // console.log('newVal :>> ', newVal)
      this.getCalendarFilter()
    },
    'dateFlat.year': function (newVal, oldVal) {
      // console.log('newVal :>> ', newVal)
      this.getCalendarFilter()
    },
    async calendarEvents () {
      let temp = []
      for (let e of this.calendarEvents) {
      // this.calendarEvents.map((e) => {
        let code = e.additionalData.dateTime
        let label = e.additionalData.dateTime + ' ' + e.additionalData.details + ' ' + e.additionalData.status.label
        let shortLabel = e.additionalData.customer.label ? e.additionalData.customer.label : e.additionalData.customer
        temp.push({
          code: code,
          label: label,
          shortLabel: shortLabel,
          status: e.additionalData.status.label,
          dateTime: e.additionalData.dateTime,
          details: e.additionalData.details,
          phone: e.additionalData.phone || '-',
          color: this.colorPointCalendar(e.additionalData.status.label, true)
        })
        temp = temp.sort((a, b) => new Date(moment(a.dateTime, 'DD/MM/YYYY HH:mm')) - new Date(moment(b.dateTime, 'DD/MM/YYYY HH:mm')))
        this.calendarDD = temp
      }
    }
  },
  methods: {
    async getEventCalendar () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/appointment/calendar/eventAll`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          /*
            { id: '12345'
            title: 'ฉีดโบทอกซ์'
            start: '2023-06-31T10:12:34'
            classNames: ['blue-coler-bullet']
            additionalData: testDataTest[0]
            backgroundColor: '#f8de7e !important'
            textColor: '#242124 !important' }
          */
          if (response.data.status) {
            const temp = response.data.data?.map(el => {
              return {
                id: this.genId(),
                title: (' -' + (el.cca_detail?.toString().split('\n').length > 0 ? el.cca_detail?.toString().split('\n')[0] : el.cca_detail/* .length > 15 ? (el.cca_detail.substring(0, 15) + '...') : el.cca_detail */)),
                start: el.cca_date,
                classNames: [ this.colorPointCalendar(el.ccs_name, false) ],
                additionalData: {
                  id: el.cca_id,
                  dateTime: moment(el.cca_date).format('DD/MM/YYYY HH:mm'),
                  customer: el.cca_customer_type === 0 ? el.cca_cus_name : this.items2?.find(tl => tl.code === el.cca_cus_id) || el.cca_cus_name || null,
                  details: el.cca_detail,
                  status: this.items1.find(tl => tl.code === el.cca_ccs_id),
                  phone: el.cca_cus_tel || '-'
                },
                backgroundColor: 'rgb(174 229 255) !important',
                textColor: '#242124 !important'
              }
            })
            this.cloneCalendarEvents = [ ...temp ]
            this.calendarEvents = [ ...temp ]
          }
        }).catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    checkForCancle () {
      // console.log('this.appointMent :>> ', this.appointMent)
      if (this.appointMent.dateTime === '' && (this.appointMent.customer === '' || this.appointMent.customer === null) && this.appointMent.details === '' && this.appointMent.status === null) {
        this.showModalSelectDay = false
        return
      }
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่?</span>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.showModalSelectDay = false
        }
      })
    },
    deleteEventCalendar () {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการลบข้อมูลคิวนี้ใช่หรือไม่?</span>',
        text: 'ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.delCalendarEvent()
        }
      })
    },
    setFlatpickrWidth () {
      const flatpickrElement = this.$refs.flatpickr.$el
      const width = '1200px' // กำหนดความกว้างตามที่คุณต้องการ
      flatpickrElement.style.width = width
    },
    getCalendarFilter () {
      let calendarApi = this.$refs.fullCalendar.getApi() // from the ref="..."
      // console.log('this.dateFlat', moment(this.dateFlat.day).format('DD'))
      calendarApi.gotoDate(`${this.dateFlat.year}-${this.dateFlat.month}-${moment(this.dateFlat.day).format('DD') || '01'}`)
    },
    eventRenderCallback (info) {
      const eventTitle = info.el.querySelector('.fc-content')
      if (eventTitle) {
        eventTitle.style.display = 'flex'
        eventTitle.style.alignItems = 'center'

        const spanElement = document.createElement('span')
        spanElement.style.display = 'inline-flex'
        spanElement.style.padding = '8px'
        spanElement.style.margin = '3px 5px'
        spanElement.style.borderRadius = '50px'
        spanElement.style.backgroundColor = 'red'
        spanElement.className = 'bullet-calendar'
        const firstChild = eventTitle.firstChild
        eventTitle.insertBefore(spanElement, firstChild)
      }
    },
    onClickDatePicker () {
      const datePicker = document.getElementById('datepicker-custom')
      datePicker.focus()
    },
    toggleWeekends () {
      this.calendarWeekends = !this.calendarWeekends // update a property
    },
    gotoPast () {
      let calendarApi = this.$refs.fullCalendar.getApi() // from the ref="..."
      calendarApi.gotoDate('2000-01-01') // call a method on the Calendar object
    },
    genId () {
      return uuidv4()
    },
    handleDateClick (arg) {
      // console.log('arg :>> ', arg)
      this.dateTableWaitingConfirm = moment(arg.date).format('DD/MM/YYYY')
      this.showModalSelectDay = false
      const dateCells = document.getElementsByClassName('fc-day')
      for (let i = 0; i < dateCells.length; i++) {
        dateCells[i].classList.remove('selected-date')
      }
      arg.dayEl.classList.add('selected-date')
    },
    onChangePicker (selectedDates, dateStr, instance) {
      // console.log('check :>> ', selectedDates)
      this.dateFlat.date = moment(new Date(selectedDates)).format('DD/MM/YYYY HH:mm')
      this.getCalendarFilter()
      this.findDateAndSelect(moment(new Date(selectedDates)).format('YYYY-MM-DD'))
    },
    setAddEventCalendar (customer) {
      this.appointMent = { ...this.resetAppointMent }
      if (this.dateFlat.date) {
        // console.log('this.dateFlat.date', this.dateFlat.date)
        this.appointMent.dateTime = moment(this.dateFlat.date, 'DD/MM/YYYY HH:mm').set({
          hour: moment().hour(),
          minute: moment().minute(),
          second: moment().second()
        }).format('DD/MM/YYYY HH:mm')
        // this.$refs.flatpickr.value = null
        // this.dateFlat.day = null
        // this.dateFlat.date = null
      }
      this.options.minDate = new Date(moment().subtract(5, 'minutes'))
      this.mode = 'add'
      this.customerisNew = customer
      this.appointMent.customer = customer === false ? null : ''
      this.showModalSelectDay = true
      // const dataArg = { ...arg }
      this.calendarEventAdd.id = this.genId()
      this.calendarEventAdd.title = 'New Event'
      this.calendarEventAdd.start = ''
      this.calendarEventAdd.allDay = false
      this.calendarEventAdd.backgroundColor = 'rgb(174 229 255) !important'
      this.calendarEventAdd.textColor = '#242124 !important'
    },
    errorEventAdd () {
      let errorlist = []
      if (!this.appointMent.dateTime || this.appointMent.dateTime?.trim() === '') {
        errorlist.push('กรุณาเลือกวันที่และเวลา')
      }
      if (this.customerisNew === true) {
        if (!this.appointMent.customer || this.appointMent.customer?.trim() === '') {
          errorlist.push('กรุณากรอกชื่อลูกค้า')
          // errorlist.push(this.customerisNew === true ? 'กรุณากรอกชื่อลูกค้า' : 'กรุณาเลือกชื่อลูกค้า')
        }
      } else {
        if (this.appointMent.customer === null) {
          errorlist.push('กรุณาเลือกชื่อลูกค้า')
        }
      }
      if (!this.appointMent.details || this.appointMent.details?.trim() === '') {
        errorlist.push('กรุณากรอกรายละเอียดเพิ่มเติม')
      }

      if (this.appointMent.status === null) {
        errorlist.push('กรุณาเลือกสถานะการนัดคิว')
      }
      return errorlist
    },
    addEventCalendar () {
      const errorlist = this.errorEventAdd()
      if (errorlist.length > 0) {
        let errMessage = ''
        for (let e = 0; e < errorlist.length; e++) {
          errMessage += '<br/>' + errorlist[e]
        }
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลไม่สำเร็จ</span>',
          html: errMessage,
          icon: 'error',
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1'
          }
        })
        return
      }
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.mode === 'edit' ? 'คุณมั่นใจว่าจะแก้ไขรายการคิวใช่หรือไม่?' : 'คุณมั่นใจว่าจะเพิ่มรายการคิวใช่หรือไม่?'}</span>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        // console.log('moment(this.appointMent.dateTime, DD/MM/YYYY HH:mm).format(ddd MMM D YYYY HH:mm:ss [GMT]ZZ (Z)) :>> ', moment(this.appointMent.dateTime).format('YYYY-MM-DD'))
        if (result.isConfirmed) {
          let classAdd = this.colorPointCalendar(this.appointMent.status.label, false)
          await this.saveCalendarEvent(classAdd)
        }
      })
    },
    async saveCalendarEvent (classAdd) {
      const data = await this.FormatDataForSave(this.mode)
      await axios.request({
        method: this.mode === 'add' ? 'post' : 'put',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/appointment/customer/calendar`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
        .then(async (response) => {
          if (response.data.status) {
            await Vue.swal({
              title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.mode === 'edit' ? 'แก้ไขคิวลูกค้าสำเร็จ' : 'เพิ่มคิวลูกค้าสำเร็จ'}</span>`,
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 1000
            }).then(async () => {
              if (this.mode === 'add') {
                this.calendarEventAdd.title = (' -' + (this.appointMent.details
                  ? this.appointMent.details/* .length > 15 ? (this.appointMent.details.substring(0, 15) + '...') : this.appointMent.details */
                  : this.calendarEventAdd.title)/* ?.length > 15 ? (this.calendarEventAdd.title.substring(0, 15) + '...') : this.calendarEventAdd.title */)
                this.calendarEventAdd.additionalData = this.appointMent
                this.calendarEventAdd.start = moment(this.appointMent.dateTime, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss')
                this.calendarEventAdd.classNames.push(classAdd)
                this.calendarEvents.push(this.calendarEventAdd)
              } else {
                const index = this.calendarEvents.findIndex(el => el.id === this.editCalendarId)
                const temp = this.calendarEvents.find(el => el.id === this.editCalendarId)
                temp.title = (' -' + this.appointMent.details/* .length > 15 ? (this.appointMent.details.substring(0, 15) + '...') : this.appointMent.details */)
                temp.additionalData = this.appointMent
                temp.classNames = []
                temp.start = moment(this.appointMent.dateTime, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss')
                temp.classNames = classAdd
                this.calendarEvents[index] = temp
              }

              this.showModalSelectDay = false
            }).then(async () => {
              this.cloneCalendarEvents = [ ...this.calendarEvents ]
              await this.getTableConfirms()
            })
          } else {
            this.SwalError(response.data.error, `${this.mode === 'add' ? 'บันทึก' : 'แก้ไข'}ข้อมูลไม่สำเร็จ`)
          }
          await this.resetValueProcessEvent()
        }).catch(async (error) => {
          await this.resetValueProcessEvent()
          this.SwalError(error.message, `${this.mode === 'add' ? 'บันทึก' : 'แก้ไข'}ข้อมูลไม่สำเร็จ`)
        })
    },
    async FormatDataForSave (mode) {
      const isOld = (typeof this.appointMent.customer === 'object')
      return {
        cca_id: this.appointMent.id || null,
        cca_date: new Date(moment(this.appointMent.dateTime, 'DD/MM/YYYY HH:mm:00:00')),
        cca_cus_id: !isOld ? null : this.appointMent.customer.code,
        cca_cus_name: !isOld ? this.appointMent.customer : this.appointMent.customer.label,
        cca_detail: this.appointMent.details,
        cca_ccs_id: this.appointMent.status.code,
        cca_hc_id: null,
        cca_customer_type: isOld ? 1 : 0,
        cca_created_by: this.user_id,
        cca_updated_by: this.user_id,
        cca_cus_tel: this.appointMent.phone
      }
    },
    async delCalendarEvent () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/appointment/customer/delCalendar/${this.appointMent.id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบคิวนัดลูกค้าสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(async () => {
              const index = this.calendarEvents.findIndex(el => el.id === this.editCalendarId)
              this.calendarEvents.splice(index, 1)
              await this.getTableConfirms()
              await this.getEventCalendar()
            })
          } else {
            this.SwalError(response.data.error, `ลบข้อมูลไม่สำเร็จ`)
          }
          this.showModalSelectDay = false
          await this.resetValueProcessEvent()
        }).catch(async (error) => {
          await this.resetValueProcessEvent()
          this.SwalError(error.message, `ลบข้อมูลไม่สำเร็จ`)
        })
    },
    colorPointCalendar (code, isDropdown) {
      if (code === 'รอการนัดหมาย') {
        return isDropdown ? 'text-danger' : 'red-coler-bullet'
      } else if (code === 'ยืนยันเข้ารับบริการ') {
        return isDropdown ? 'text-success' : 'green-coler-bullet'
      } else if (code === 'เข้าใช้บริการ') {
        return isDropdown ? 'text-warning' : 'orange-coler-bullet'
      } else if (code === 'ยกเลิก / ไม่มาใช้บริการ') {
        return isDropdown ? 'text-primary' : 'blue-coler-bullet'
      }
    },
    async resetValueProcessEvent () {
      this.calendarEventAdd = { ...this.resetcalendarEventAdd }
      this.calendarEventAdd.classNames = []
      this.appointMent = { ...this.resetAppointMent }
      this.editCalendarId = ''
    },
    handleEventClick (info) {
      /* this.calendarEventAdd = { ...this.resetcalendarEventAdd }
      this.calendarEventAdd.classNames = []
      this.appointMent = { ...this.resetAppointMent }
      this.editCalendarId = '' */
      this.mode = 'edit'
      let dataInfo = { ...info.event._def.extendedProps.additionalData }
      // this.appointMent.dateTime = dataInfo.dateTime
      // console.log('info.event.start :>> ', typeof moment(new Date()).format('DD/MM/YYYY HH:mm'))
      this.appointMent.dateTime = moment(new Date(info.event.start)).format('DD/MM/YYYY HH:mm')
      this.options.minDate = new Date(moment(info.event.start).subtract(5, 'minutes'))
      /* if (dataInfo.dateTime === null) {
      } */
      let customer = null
      if (typeof dataInfo.customer === 'object') {
        this.customerisNew = false
        customer = { ...dataInfo.customer }
      } else {
        this.customerisNew = true
        customer = dataInfo.customer
      }
      // /* console.log('dataInfo.dateTime :>> ', { ...dataInfo.customer.__ob__.value })
      // console.log('this.customerisNew :>> ', customer)
      this.appointMent.customer = customer || ''
      this.appointMent.id = dataInfo.id || ''
      this.appointMent.details = dataInfo.details || ''
      this.appointMent.status = dataInfo.status || null
      this.appointMent.phone = dataInfo.phone || null
      this.editCalendarId = info.event.id
      this.showModalSelectDay = true
      // console.log('info Event :>> ', this.appointMent.dateTime)
      // console.log('this.appointMent :>> ', this.appointMent)
      /* const newTitle = prompt('กรุณากรอกหัวข้อใหม่:')
      if (newTitle) {
        const index = this.calendarEvents.findIndex(el => el.id === info.event.id)
        const temp = this.calendarEvents.find(el => el.id === info.event.id)
        temp.title = newTitle
        this.calendarEvents[index] = temp
        this.calendarEventAdd = { ...this.resetcalendarEventAdd }
        this.appointMent = { ...this.resetAppointMent }
      } else {
        this.calendarEventAdd = { ...this.resetcalendarEventAdd }
        this.appointMent = { ...this.resetAppointMent }
      } */
      // info.classList.add('selected-date')
    },
    handleEventMouseEnter (info) {
      // console.log('handleEventMouseEnter info :>> ', info.event.extendedProps.additionalData.customer)
      // info.classList.add('selected-date')
    },
    handleEventChange (info) {
      // console.log('เหตุการณ์ถูกเปลี่ยน:', info.event)
    },
    async filterStatusClick (value) {
      if (value === 'รอการนัดหมาย') {
        this.statusStage.status1 = !this.statusStage.status1
        await this.onChangeFilterStatus(this.statusStage.status1, value)
      } else if (value === 'ยืนยันเข้ารับบริการ') {
        this.statusStage.status2 = !this.statusStage.status2
        await this.onChangeFilterStatus(this.statusStage.status2, value)
      } else if (value === 'เข้าใช้บริการ') {
        this.statusStage.status3 = !this.statusStage.status3
        await this.onChangeFilterStatus(this.statusStage.status3, value)
      } else if (value === 'ยกเลิก / ไม่มาใช้บริการ') {
        this.statusStage.status4 = !this.statusStage.status4
        await this.onChangeFilterStatus(this.statusStage.status4, value)
      }
    },
    async callBackStatus (status) {
      for (let item of this.cloneCalendarEvents) {
        if (item.additionalData.status.label === `${status}`) {
          this.calendarEvents.push(item)
        }
      }
    },
    async onChangeFilterStatus (boolVal, dropdown) {
      if (boolVal === false) {
        this.calendarEvents = this.calendarEvents.filter(el => el.additionalData.status.label !== `${dropdown}`)
      } else {
        await this.callBackStatus(dropdown)
      }
    },
    async searchCalendarEvent () {
      if (!this.searchData) {
        return
      }
      let dateFormat = this.searchData.code.split(' ')[0]
      let dateArr = dateFormat.split('/')
      let calendarApi = this.$refs.fullCalendar.getApi()
      const dataDate = `${dateArr[2] + '-' + dateArr[1] + '-' + dateArr[0]}`

      await calendarApi.gotoDate(dataDate)
      this.findDateAndSelect(dataDate)
    },
    findDateAndSelect (dataDate) {
      const dateCells = document.querySelectorAll('.fc-day[data-date]')
      dateCells.forEach(cell => {
        if (cell.dataset.date === dataDate) {
          cell.classList.add('selected-date')
        } else {
          cell.classList.remove('selected-date')
        }
      })
    },
    async getCustomerCalStatus () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/appointment/customer/calendar/statusAll`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          this.items1 = response.data.data?.map(el => {
            return {
              code: el.ccs_id,
              label: el.ccs_name
            }
          })
        }).catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async getCustomerOldDD () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/appointment/customer/calendar/customerOldAll`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          this.items2 = response.data.data?.map(el => {
            return {
              code: el.cus_id,
              label: (el.cus_hn + '-' + el.cus_firstname + ' ' + el.cus_lastname),
              tel: el.cus_tel
            }
          })
        }).catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async getTableConfirms (date) {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/appointment/customer/calendar/?day=${date ?? moment().format('DD/MM/YYYY')}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status) {
            this.tableConfirms = response.data.data?.map(el => {
              return {
                date: moment(el.cca_date).format('DD/MM/YYYY HH:mm'),
                cca_cus_name: !el.cca_cus_id ? el.cca_cus_name : this.getNameCustomerOldById(el.cca_cus_id) || el.cca_cus_name,
                details: el.cca_detail,
                tel: el.cca_cus_tel || '-'
              }
            })
          }
        }).catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    getNameCustomerOldById (cusId) {
      const temp = this.items2.find(el => el.cus_id === cusId)
      if (temp) {
        return `${temp.cus_firstname} ${temp.cus_lastname} | ${temp.cus_hn}`
      } else {
        return null
      }
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    }
  }
}
</script>
