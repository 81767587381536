<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">ประกาศหางาน</span>
              </div>
              <div class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="button" variant="primary" @click="handleClickGoBack" class="w-100 btn-min-size"><i class="circle-plus_ct"></i>เพิ่ม</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" md="7" lg="5">
                  <b-row>
                    <b-col sm="12" md class="mb-3">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input class="form-control" id="exampleInputEmail1" placeholder="Search" style="width: 100%;" v-model="searchTerm" @keydown="enterSearch">
                            <a class="search-link" v-on:click="getPostJob"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                      <span class="mx-2 font-size-14 text-primary unselectable">
                        {{ postjob?.length > 0 ? (((currentPage - 1) * perPage) + 1) + ' - ' + (((perPage * currentPage) > postjob?.length) ? postjob?.length : (perPage * currentPage) ) + ' of ' + postjobLength : '0 of ' + postjobLength }} รายการ
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col sm="12" md="5" lg="7" class="mb-3" style="display: flex; justify-content: flex-end;">
                  <b-row>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                      <span class="mx-2 font-size-14 text-primary unselectable">แสดง</span>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                      <b-form-select v-model="perPage" :options="perPagePreset" class="w-100" @change="onPagination(1)" />
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                      <span class="mx-2 font-size-14 text-primary unselectable">รายการ</span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              </div>
              <br>
              <b-col md="12" class="table-responsive">
                <b-table bordered hover :items="postjob" :fields="columns"
                  :current-page="currentPage"
                  :per-page="perPage"
                  @pagination="onPagination"
                  :tbody-tr-class="rowClass" thead-class="table-header-color"
                >
                <template v-slot:cell(variable1)="data">
                  <span v-if="!data.item.editable">{{ postjob[((currentPage - 1) * 10) + data.index].cln_name }}</span>
                </template>
                <template v-slot:cell(variable2)="data">
                  <span v-if="!data.item.editable">{{ telFormat(postjob[((currentPage - 1) * 10) + data.index].cln_tel) }}</span>
                </template>
                <template v-slot:cell(variable3)="data">
                  <span v-if="!data.item.editable">{{ postjob[((currentPage - 1) * 10) + data.index].cln_address }}</span>
                </template>
                <template v-slot:cell(variable4)="data">
                  <span v-if="!data.item.editable">{{ postjob[((currentPage - 1) * 10) + data.index].topic }}</span>
                </template>
                <template v-slot:cell(variable5)="data">
                  <span v-if="!data.item.editable">{{ dateFormat(postjob[((currentPage - 1) * 10) + data.index].date || '') + ' - ' + dateFormat(postjob[((currentPage - 1) * 10) + data.index].end_date || '') }}</span>
                </template>
                <template v-slot:cell(variable6)="data">
                  <span v-if="!data.item.editable">{{ postjob[((currentPage - 1) * 10) + data.index].is_receive }}</span>
                </template>
                <template v-slot:cell(variable7)="data">
                  <span v-if="!data.item.editable">{{ postjob[((currentPage - 1) * 10) + data.index].standart_work }}</span>
                </template>
                <template v-slot:cell(variable8)="data">
                  <span v-if="!data.item.editable">{{ dateFormat(postjob[((currentPage - 1) * 10) + data.index].created_date || '') }}</span>
                </template>
                <template v-slot:cell(variable9)="data">
                  <div style="display: flex; justify-content: center;">
                    <b-button variant=" iq-bg-info mr-1" size="sm" @click="handleClick('view', postjob[((currentPage - 1) * 10) + data.index].id)"><i class="ri-eye-fill m-0"></i></b-button>
                  </div>
                </template>
              </b-table>
              <br>
            </b-col>
            <b-pagination
                v-model="currentPage"
                :total-rows="postjob.length"
                :per-page="perPage"
                align="center"
              />
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import axios from 'axios'
import moment from 'moment'
import Vue from 'vue'
import { configAPI } from '../../configBase'

const dataTestView = [
  {
    index: 1,
    id: 1,
    variable1: 'หมอเซ็ง',
    variable2: '082-922-2222',
    variable3: 'บางโพ MRT1',
    variable4: '27/06/2023 18:00:00',
    variable5: 'ทันตกรรม',
    variable6: 'อย.อื่นๆ',
    variable7: '26/06/2023'
  },
  {
    index: 2,
    id: 2,
    variable1: 'หมอเซ็ง2',
    variable2: '082-922-2222',
    variable3: 'บางโพ MRT2',
    variable4: '27/06/2023 18:00:00',
    variable5: 'ผ่าตัด',
    variable6: 'อย.อื่นๆ',
    variable7: '26/06/2023'
  },
  {
    index: 3,
    id: 3,
    variable1: 'หมอเซ็ง3',
    variable2: '082-922-2222',
    variable3: 'บางโพ MRT3',
    variable4: '27/06/2023 18:00:00',
    variable5: 'เสริมความงาม',
    variable6: 'อย.อื่นๆ',
    variable7: '26/06/2023'
  },
  {
    index: 4,
    id: 4,
    variable1: 'หมอเซ็ง4',
    variable2: '082-922-2222',
    variable3: 'บางโพ MRT4',
    variable4: '27/06/2023 18:00:00',
    variable5: 'ทันตกรรม',
    variable6: 'อย.อื่นๆ',
    variable7: '26/06/2023'
  }
]
document.getElementById(dataTestView)
export default {
  name: 'PostJobTableMaster',
  computed: {
    sharedData () {
      return this.$store.state.sharedData
    }
  },
  async mounted () {
    xray.index()
    this.cln_id = JSON.parse(sessionStorage.getItem('user')).cln_id
    await this.getPostJob()
    await this.getPostJobAll()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getPostJob()
      }
    },
    async getPostJob () {
      let search = this.searchTerm.trim().length > 0 ? this.searchTerm.trim() : ''
      let postjobAPI = {
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/job?search=` + search,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(postjobAPI)
        .then((response) => {
          this.postjob = response.data.data
        })/* .catch((error) => {
          console.error(error)
        }) */
    },
    async getPostJobAll () {
      let search = this.searchTerm.trim().length > 0 ? this.searchTerm.trim() : ''
      let postjobAPI = {
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/job?search=` + search,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(postjobAPI)
        .then((response) => {
          this.postjobLength = response.data.data.length
        })/* .catch((error) => {
          console.error(error)
        }) */
    },
    telFormat (number) {
      return number.slice(0, 3) + '-' + number.slice(3, 6) + '-' + number.slice(6, 10)
    },
    dateFormat (date) {
      if (date !== null && date !== undefined && date !== '') {
        return moment(date).format('DD/MM/YYYY')
      } else {
        return ''
      }
    },
    updateDataAsync () {
      // this.$store.commit('setSharedData', 'New data')
      this.$store.dispatch('updateSharedData', {
        name: 'dream',
        age: 24,
        live: 'Prathumtanee'
      }) // เรียกใช้งาน action เพื่อเปลี่ยนแปลงค่าใน store ในลักษณะ asynchronous
    },
    add () {
      let obj = this.default()
      this.rows.push(obj)
    },
    default () {
      return {
        id: this.rows.length,
        variable1: '',
        variable2: '',
        variable3: '',
        variable4: '',
        variable5: '',
        variable6: ''
      }
    },
    edit (item) {
      item.editable = true
    },
    submit (item) {
      item.editable = false
    },
    remove (item) {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการลบข้อมูลใช่หรือไม่?</span>',
        text: 'ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          let index = this.rows.indexOf(item)
          this.rows.splice(index, 1)
        }
      })
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick (e, data) {
      const setPage = e
      // this.updateDataAsync()
      if (setPage === 'view') {
        router.push(`/PostJob/${setPage}/${data}`)
      }
    },
    sendData () {
      const setPage = 'add'
      this.setData(setPage)
    },
    handleClickGoBack () {
      router.push('/PostJob/add')
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    }
  },
  data () {
    return {
      cln_id: null,
      postjob: [],
      postjobLength: 0,
      searchTerm: '',
      mode: 'showAll',
      selectedOption: null,
      showModal: false,
      columns: [
        { label: 'ชื่อคลินิก', key: 'variable1', class: 'text-center table-size-200-400' },
        { label: 'เบอร์ติดต่อ', key: 'variable2', class: 'text-center table-size-200-400' },
        { label: 'สถานที่', key: 'variable3', class: 'text-center table-size-200-400' },
        { label: 'หัวข้อการทำงาน', key: 'variable4', class: 'text-center table-size-200-400' },
        { label: 'วันที่ต้องการแพทย์', key: 'variable5', class: 'text-center table-size-200-400' },
        { label: 'ประเภทงานที่ต้องการ', key: 'variable6', class: 'text-center table-size-200-400' },
        { label: 'มาตราฐานยา', key: 'variable7', class: 'text-center table-size-200-400' },
        { label: 'วันที่ประกาศหางาน', key: 'variable8', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'variable9', class: 'text-center' }
        /* { label: 'วันที่เข้าใช้ครั้งแรก', key: 'variable8', class: 'text-center table-size-200-400' },
        { label: 'ประวัติลูกค้าล่าสุด', key: 'variable9', class: 'text-center table-size-200-400' } */
      ],
      rows: dataTestView,
      currentPage: 1,
      perPage: 10,
      perPagePreset: [
        {
          text: '10',
          value: 10
        },
        {
          text: '20',
          value: 20
        },
        {
          text: '50',
          value: 50
        },
        {
          text: '100',
          value: 100
        }
      ]
    }
  }
}
</script>
