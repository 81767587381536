import { render, staticRenderFns } from "./productSelectModalPR.vue?vue&type=template&id=46eb5daa&scoped=true&"
import script from "./productSelectModalPR.vue?vue&type=script&lang=js&"
export * from "./productSelectModalPR.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46eb5daa",
  null
  
)

export default component.exports