<template>
  <div class="layoutMain">
    <div class="layoutSub">
      <!-- <div class="layoutSum" style="display: flex; justify-content: space-between; max-width: 1500px; width: 100%;"> -->
      <div class="hideElement">
        <img src="../../assets/images/login/logo.png" class="logoImage-1" />
        <span class="text-1">WELCOME BACK TO</span>
        <span class="text-2">SMART CLINIC</span>
        <span class="text-3">“How the clinic smart and simplifying”</span>
        <span class="text-4">" พบกับระบบ Smart Clinic ที่จะพาคลินิกของคุณสู่แนวทางเทคโนโลยีใหม่ๆ ที่มีประสิทธิภาพ
          และฟีเจอร์ที่น่าทึ่ง เพื่อลดเวลาในการทำงานของคุณอย่างมีประสิทธิผล เอกสารทางด้านคลินิก
          เสริมความงามที่ผู้เชี่ยวชาญวิเคราะห์ได้พบว่าสามารถช่วยให้คลินิกของคุณเป็น "<span class="text-4-1">Smart
            Clinic</span>" ได้อย่างคล่องตัว "</span>
      </div>
      <div class="cardLogin">
        <div>
          <img src="../../assets/images/logo.png" class="logoImage-2" />
          <span class="text-5">SMART CLINIC</span>
          <!-- <span class="text-6">Sign in</span>
          <span class="text-7">Username</span>
          <input class="inputLogin" type="text" placeholder="Username" v-model="user.username" />
          <span class="text-7-1">Password</span>
          <input class="inputLogin" type="password" placeholder="Password" v-model="user.password" />
          <div class="layoutCheckbox">
            <input id="checkbox" type="checkbox" v-model="rememberMe" />
            <label for="checkbox">Remember Me</label>
          </div>
          <button class="loginButton" @click="checkSignIn">Sign in</button> -->
        </div>
        <div style="display: flex; flex-direction: column; align-items: center">
          <h3 :style="`color: ${this.dataResponse.color}; font-size: 2.5rem`" class="text-7">{{ this.dataResponse.title }}</h3>
          <h4 class="text-7" style="text-align: center;">{{ this.dataResponse.subTitle }}</h4>
        </div>
        <span class="text-9">“ How the clinic smart and simplifying ”</span>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<style scoped>
.layoutMain {
  height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
}
.layoutSub {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1500px;
  width: 100%;
}
.hideElement {
  margin: 40px;
}
@media (max-width: 909px) {
.layoutSub {
  justify-content: center;
}
.hideElement {
  display: none;
}
}
.logoImage-1 {
  /* width: 384px; */
  /* width: 20vw; */
  width: 284px;
  /* height: 20%; */
  /* max-width: 373px; */
  /* max-height: 373px; */
  aspect-ratio: 373 / 373;
  display: block;
}
.text-1 {
  font-family: 'DBHeaventRounded', sans-serif;
  font-weight: bold;
  font-style: normal;
  /* font-size: 2.2vw; */
  /* font-size: 42px; */
  font-size: 33px;
  color: #ffffff;
  line-height: normal;
  text-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);
  display: block;
  /* margin-top: 70px; */
  margin-top: 60px;
}
.text-2 {
  font-family: 'DBHeaventRounded', sans-serif;
  font-weight: bold;
  font-style: normal;
  /* font-size: 8.1vw; */
  /* font-size: 156px; */
  font-size: 118px;
  color: #13A6FD;
  line-height: normal;
  text-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);
  display: block;
  /* margin-top: -40px; */
  /* margin-bottom: 40px; */
  margin-top: -30px;
  margin-bottom: 20px;
}
.text-3 {
  font-family: 'DBHeaventRounded', sans-serif;
  font-weight: normal;
  font-style: normal;
  /* font-size: 3.5vw; */
  /* font-size: 68px; */
  font-size: 52px;
  color: #ffffff;
  line-height: normal;
  display: block;
  /* margin-top: 40px; */
  margin-top: 20px;
}
.text-4 {
  font-family: 'DBHeaventRounded', sans-serif;
  font-weight: normal;
  font-style: normal;
  /* font-size: 1.9vw; */
  /* font-size: 37px; */
  font-size: 27px;
  color: #EAEAEA;
  line-height: normal;
  display: block;
  word-break: normal;
  width: 100%;
  /* max-width: 920px; */
  max-width: 680px;
}
.text-4-1 {
  font-family: 'DBHeaventRounded', sans-serif;
  font-weight: bold;
  font-style: normal;
  /* font-size: 1.9vw; */
  /* font-size: 37px; */
  font-size: 27px;
  color: #EAEAEA;
  line-height: normal;
  white-space: nowrap;
}
@media (max-width: 1232px) {
  .logoImage-1 {
    width: 184px;
  }
  .text-1 {
    font-size: 21px;
    margin-top: 50px;
  }
  .text-2 {
    font-size: 77px;
    margin-top: -20px;
    margin-bottom: 10px;
  }
  .text-3 {
    font-size: 34px;
    margin-top: 10px;
  }
  .text-4 {
    font-size: 18px;
    max-width: 450px;
  }
  .text-4-1 {
    font-size: 18px;
  }
}
.cardLogin {
  background-color: #ffffff;
  max-width: 502px;
  max-height: 710px;
  /* min-width: 350px; */
  /* min-height: 735px; */
  min-height: 710px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);
  margin: 40px;
  /* padding: 2.5vw 3vw; */
  padding: 45px 55px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1332px) {
  .cardLogin {
    padding: 45px 55px;
    max-width: 402px;
  }
}
@media (max-width: 1009px) {
  .cardLogin {
    padding: 45px 30px;
    max-width: 302px;
  }
}
@media (max-width: 909px) {
  .cardLogin {
    padding: 45px 55px;
    max-width: 502px;
  }
}
@media (max-width: 496px) {
  .cardLogin {
    padding: 45px 30px;
  }
}
.logoImage-2 {
  width: 60px;
  /* width: 3vw; */
  /* height: 20%; */
  /* max-width: 373px; */
  /* max-height: 373px; */
  aspect-ratio: 171 / 249;
  display: block;
  /* margin-top: 45px; */
  margin: 0px auto;
}
.text-5 {
  font-family: 'DBHeaventRounded', sans-serif;
  font-weight: bold;
  font-style: normal;
  /* font-size: 1.4vw; */
  font-size: 30px;
  color: #013F8C;
  line-height: normal;
  display: block;
  text-align: center;
}
.text-6 {
  font-family: 'DBHeaventRounded', sans-serif;
  font-weight: bold;
  font-style: normal;
  /* font-size: 2.61vw; */
  font-size: 50px;
  color: #000000;
  line-height: normal;
  display: block;
  margin-top: 35px;
}
.text-7 {
  font-family: 'DBHeaventRounded', sans-serif;
  font-weight: 500;
  font-style: normal;
  /* font-size: 1.56vw; */
  font-size: 30px;
  color: #000000;
  line-height: normal;
  display: block;
  margin-top: -5px;
}
.text-7-1 {
  font-family: 'DBHeaventRounded', sans-serif;
  font-weight: 500;
  font-style: normal;
  /* font-size: 1.56vw; */
  font-size: 30px;
  color: #000000;
  line-height: normal;
  display: block;
  margin-top: 30px;
}
.inputLogin {
  width: 100%;
  border: 0px;
  border-bottom: 2px solid #B4B4B4;
  font-family: 'DBHeaventRounded', sans-serif;
  font-weight: 500;
  font-style: normal;
  /* font-size: 1.56vw; */
  font-size: 30px;
  color: #8B8B8B;
  line-height: normal;
  margin-top: 5px;
  padding: 0px 10px;
}
.inputLogin:focus::placeholder {
  color: transparent;
}
/* .inputLogin[type='password'] {
  letter-spacing: 3px;
} */
.layoutCheckbox {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.layoutCheckbox input[type="checkbox"] {
  appearance: none;
  background-color: #ffffff;
  margin: 0;
  width: 1.3em;
  height: 1.3em;
  border: #adb5bd solid 1px;
  border-radius: 0.25rem;
  display: grid;
  place-content: center;
  transition: all 0.2s ease-in-out;
  transform-origin: bottom left;
}
.layoutCheckbox input[type="checkbox"]:checked {
  background-color: #013F8C;
  border-color: #013F8C;
}
.layoutCheckbox input[type="checkbox"]::before {
  content: "";
  width: 0.7em;
  height: 0.7em;
  transform: scale(0);
  transition: all 0.2s ease-in-out;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  background-color: #ffffff;
}
.layoutCheckbox input[type="checkbox"]:checked::before {
  transform: scale(1);
}
.layoutCheckbox input[type="checkbox"]:focus  {
  outline: 1px solid #78b8dd;
  outline-offset: 1px;
}
.layoutCheckbox label {
  font-family: 'DBHeaventRounded', sans-serif;
  font-weight: 500;
  font-style: normal;
  /* font-size: 1.56vw; */
  font-size: 29px;
  color: #000000;
  line-height: 18px;
  display: block;
  margin: 1px 0px 0px 20px;
}
.loginButton {
  border-width: 0px;
  border-radius: 10px;
  background-color: #013F8C;
  font-family: 'DBHeaventRounded', sans-serif;
  font-weight: bold;
  font-style: normal;
  /* font-size: 1.56vw; */
  font-size: 29px;
  color: #ffffff;
  line-height: normal;
  width: 100%;
  margin-top: 30px;
}
.text-9 {
  font-family: 'DBHeaventRounded', sans-serif;
  font-weight: 500;
  font-style: normal;
  /* font-size: 1.3vw; */
  font-size: 25px;
  color: #13A6FD;
  line-height: normal;
  display: block;
  text-align: center;
}
</style>

<script>
import axios from 'axios'
import router from '@/router'
import image from '../../assets/images/login/bg.png'
import JWTDecode from 'jwt-decode'
import Vue from 'vue'
import { configAPI } from '../../configBase'

export default {
  name: 'Login',
  data () {
    return {
      dataResponse: null,
      user: {
        username: '',
        password: ''
      },
      rememberMe: false
    }
  },
  methods: {
    startSetting () {
      const html = document.documentElement
      // html.style.width = '100%'
      html.style.height = '100%'
      html.style.background = `url(${image}) no-repeat center center fixed`
      html.style.backgroundSize = 'cover'

      const body = document.body
      // body.style.width = '100%'
      body.style.height = '100%'
      body.style.background = 'transparent'
    },
    async checkSignIn () {
      const loginAPI = {
        method: 'post',
        url: `${configAPI.AUTH_PORT}/api/login`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          username: this.user.username || null,
          password: this.user.password || null
        }
      }

      await axios.request(loginAPI).then(async (response) => {
        if (response.data.status) {
          const userData = JWTDecode(response.data.data.access_token)

          sessionStorage.setItem('token', JSON.stringify(response.data.data.access_token))
          sessionStorage.setItem('user', JSON.stringify(userData))

          if (this.rememberMe) {
            localStorage.setItem('username', this.user.username)
          } else {
            localStorage.removeItem('username')
          }

          router.push({ name: 'dashboard.DashboardsCus' })
        } else {
          Vue.swal({
            title: `<span class="" style="font-size: 25px; color: black; text-align: center">Sign In Failed</span>`,
            text: `Username Or Password Incorrect`,
            icon: 'error',
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab'
          })
        }
      })
    },
    async updateIsConfirmHistoryJob () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/historyJob/${this.$route.params.hc_id}/${this.$route.params.statusAns}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            this.dataResponse = {
              color: '#28a745',
              title: 'ทำรายการสำเร็จ',
              subTitle: 'กรุณารอการตอบกลับจากทางเรา'
            }
          } else {
            this.dataResponse = {
              color: '#fc3131',
              title: 'ทำรายการไม่สำเร็จ',
              subTitle: response.data.error
            }
          }
        })
        .catch((error) => {
          this.dataResponse = {
            color: '#fc3131',
            title: 'ทำรายการไม่สำเร็จ',
            subTitle: 'กรุณาลองใหม่อีกครั้ง'
          }
          this.SwalError(error.message, 'บันทึกข้อมูลไม่สำเร็จ')
        })
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    }
  },
  computed: {},
  async created () {
    /* if (localStorage.getItem('username')) {
      this.rememberMe = true
      this.user.username = localStorage.getItem('username')
    } */
    this.startSetting()
    await this.updateIsConfirmHistoryJob()
  },
  mounted () {}
}
</script>
