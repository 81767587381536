<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span v-if="mode === 'add'" style="font-size: 25px; color: black;">ข้อมูลรายละเอียดหมอ
                  (เพิ่ม)</span>
                <span v-else-if="mode === 'edit'"
                  style="font-size: 25px; color: black;">ข้อมูลรายละเอียดหมอ (แก้ไข)</span>
                <span v-else style="font-size: 25px; color: black;">ดูข้อมูลรายละเอียดหมอ</span>
              </div>
              <div v-if="mode === 'add' || mode === 'edit'" class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-6 pl-3 pr-2 my-1 my-sm-0">
                    <b-button type="submit" variant="primary" @click="clickSave" class="w-100 btn-min-size">บันทึก</b-button>
                  </div>
                  <div class="col-6 pl-2 pr-3 my-1 my-sm-0">
                    <b-button type="submit" variant="none" @click="clickCancel" class="iq-bg-danger btn-cancel-border w-100 btn-min-size">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
              <div v-else class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-6 pl-3 pr-2 my-1 my-sm-0">
                    <b-button type="submit" variant="primary" @click="handleClick" style="white-space: nowrap; padding-right: .9rem;" class="w-100 btn-min-size">จ้างงาน</b-button>
                  </div>
                  <div class="col-6 pl-2 pr-3 my-1 my-sm-0">
                    <b-button type="submit" variant="none" @click="clickCancel" class="iq-bg-danger w-100 btn-cancel-border btn-min-size">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col lg="8" sm="12">
                  <b-row>
                    <b-col lg="8" sm="8">
                      <b-text style="color: black;">ชื่อ - นามสกุล</b-text>
                      <b-form-input type="text" v-model="dataDoctor.name" :disabled="isDisable" placeholder=""></b-form-input>
                    </b-col>
                    <!-- <b-col lg="4" sm="4">
                      <b-text style="color: black;">นามสกุล</b-text>
                      <b-form-input type="text" v-model="dataDoctor.lastName" :disabled="isDisable" placeholder=""></b-form-input>
                    </b-col> -->
                    <b-col lg="2" sm="2">
                      <b-text class="" style="color: black; text-align: center; white-space: nowrap;">เพศ</b-text>
                      <select class="form-control d-flex" v-model="dataDoctor.gender" :disabled="isDisable" id="validationDefault" required>
                        <option selected value="null">เพศ</option>
                        <option value="ชาย">ชาย</option>
                        <option value="หญิง">หญิง</option>
                        <option value="อื่นๆ">อื่นๆ</option>
                      </select>
                    </b-col>
                    <b-col lg="2" sm="2">
                      <b-text style="color: black;">อายุ</b-text>
                      <b-form-input type="number" max="120" min="0" v-model="dataDoctor.age" :disabled="isDisable" @keydown="preventDecimalAndNegative"></b-form-input>
                    </b-col>
                  </b-row>
                  <br>
                  <b-row>
                    <b-col lg="6" sm="6">
                      <b-text style="color: black;">เลขที่ใบประกอบวิชาชีพ</b-text>
                      <b-form-input type="number" v-model="dataDoctor.numberJob" :disabled="isDisable" placeholder=""></b-form-input>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-text style="color: black;">สาขาเฉพาะทาง</b-text>
                      <b-form-input type="text" v-model="dataDoctor.branchSpecail" :disabled="isDisable" placeholder=""></b-form-input>
                    </b-col>
                  </b-row>
                  <br>
                  <b-row>
                    <b-col lg="6" sm="6">
                      <b-text style="color: black;">เบอร์โทรศัพท์ติดต่อ</b-text>
                      <b-form-input type="tel" v-model="dataDoctor.phone" :disabled="isDisable" id="phone" placeholder=""></b-form-input>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-text style="color: black;">E-mail</b-text>
                      <b-form-input type="email" v-model="dataDoctor.email" :disabled="isDisable" id="email" placeholder=""></b-form-input>
                    </b-col>
                  </b-row>
                  <br>
                  <b-row>
                    <b-col lg="6" sm="6">
                      <b-text style="color: black;">พื้นที่สะดวกรับงาน</b-text>
                      <b-form-input type="email" v-model="dataDoctor.convenientPlace" :disabled="isDisable" id="email" placeholder=""></b-form-input>
                      <!-- <b-form-select :disabled="isDisable" style="padding: .7rem; margin-top: 0 !important;" class="my-1 form-control d-flex" plain :options="[9]" size="lg" v-model="dataDoctor.convenientPlace">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select</b-form-select-option>
                          <b-form-select-option :value="'กรุงเทพ และปริมณฑล'">กรุงเทพ และปริมณฑล</b-form-select-option>
                        </template>
                      </b-form-select> -->
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-text style="color: black;">เลขบัญชีสำหรับโอนเงิน</b-text>
                      <b-form-input v-model="dataDoctor.numberTransfer" :disabled="isDisable" type="text" placeholder=""></b-form-input>
                    </b-col>
                  </b-row>
                  <br>
                  <b-row>
                    <b-col lg="6" sm="6">
                      <b-text style="color: black;">ธนาคาร</b-text>
                      <b-form-select :disabled="isDisable" style="padding: .7rem; margin-top: 0 !important;" class="my-1 form-control d-flex" plain :options="[0]" size="lg" v-model="dataDoctor.bank">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select</b-form-select-option>
                          <b-form-select-option :value="dataDoctor.bank">{{dataDoctor.bank}}</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-col>
                    <!-- <b-col lg="6" sm="6">
                      <b-text class="" style="color: black; text-align: center; white-space: nowrap;">ธนาคาร</b-text>
                      <select class="form-control d-flex" v-model="dataDoctor.name" :disabled="isDisable" id="validationDefault" required>
                        <option selected value="">ธนาคาร</option>
                        <option value="option1">ธนาคารกรุงเทพ</option>
                        <option value="option2">ธนาคารกสิกรไทย</option>
                        <option value="option3">ธนาคารกรุงไทย</option>
                        <option value="option4">ธนาคารออมสิน</option>
                        <option value="option5">ธนาคารไทยพาณิชย์</option>
                        <option value="option6">ธนาคารกรุงศรีอยุธยา</option>
                      </select>
                    </b-col> -->
                    <b-col lg="6" sm="6">
                      <b-text style="color: black;">ชื่อบัญชี</b-text>
                      <b-form-input type="text" v-model="dataDoctor.nameAccount" :disabled="isDisable" placeholder=""></b-form-input>
                    </b-col>
                  </b-row>
                  <br>
                  <b-row>
                    <b-col lg="6" sm="6">
                      <b-text style="color: black;">ประเภทงานที่รับ</b-text>
                      <b-row class="my-3 res-justify" style="text-align: center; align-items: center;">
                        <div style="display: flex;">
                          <b-form-checkbox v-model="dataDoctor.checkBInjection" :disabled="isDisable" class="ml-3" type="text" placeholder=""></b-form-checkbox>
                          <b-text class="mr-5" style="color: black;">Injection</b-text>
                        </div>
                        <div style="display: flex;">
                          <b-form-checkbox v-model="dataDoctor.checkBSurgery" :disabled="isDisable" class="ml-3" type="text" placeholder=""></b-form-checkbox>
                          <b-text class="mr-5" style="color: black;">Surgery</b-text>
                        </div>
                      </b-row>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-text style="color: black;">มาตรฐานรับงาน</b-text>
                      <b-row class="my-3 res-justify" style="text-align: center; align-items: center;">
                        <div style="display: flex;">
                        <b-form-checkbox v-model="dataDoctor.standard" :disabled="isDisable" value="อย." class="ml-3" type="text" placeholder=""></b-form-checkbox>
                        <b-text class="mr-5" style="color: black;">อย. เท่านั้น</b-text>
                      </div>
                      <div style="display: flex;">
                        <b-form-checkbox v-model="dataDoctor.standard" :disabled="isDisable" value="อื่น ๆ" class="ml-3" type="text" placeholder=""></b-form-checkbox>
                        <b-text class="edit-v-emp-doctor" style="color: black;">อื่น ๆ</b-text>
                      </div>
                      </b-row>
                    </b-col>
                  </b-row>
                  <!-- <br> -->
                  <b-row>
                    <b-col lg="12" sm="12">
                      <b-text style="color: black;">ไม่รับทำบริเวณ</b-text>
                      <b-form-input type="text" v-model="dataDoctor.selectNotDo" :disabled="isDisable" placeholder=""></b-form-input>
                      <!-- <b-form-select :disabled="isDisable" style="padding: .7rem; margin-top: 0 !important;" class="my-1 form-control d-flex" plain :options="[5]" size="lg" v-model="dataDoctor.selectNotDo">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select</b-form-select-option>
                        </template>
                      </b-form-select> -->
                    </b-col>
                  </b-row>
                  <br>
                  <b-row>
                    <b-col lg="6" sm="6">
                      <b-text style="color: black;">ช่วงเวลาที่สะดวกรับงาน</b-text>
                      <b-form-input type="text" v-model="dataDoctor.convenientStartWork" :disabled="isDisable" placeholder=""></b-form-input>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-text style="color: black;">ค่าเวรขั้นต่ำที่รับ</b-text>
                      <b-form-input type="email" v-model="dataDoctor.salaryMin" :disabled="isDisable" id="email" placeholder=""></b-form-input>
                      <!-- <b-form-select :disabled="isDisable" style="padding: .7rem; margin-top: 0 !important;" class="my-1 form-control d-flex" plain :options="'2,000'" size="lg" v-model="dataDoctor.salaryMin">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select</b-form-select-option>
                          <b-form-select-option :value="'2,000'">2,000</b-form-select-option>
                        </template>
                      </b-form-select> -->
                    </b-col>
                  </b-row>
                  <br>
                </b-col>

                <b-col lg="4" sm="12" class="">
                 <b-form>
                  <template>
                     <div>
                       <b-form-group label-for="imageInput4" label="อัปโหลดรูปโปรไฟล์" class="text-file-viewDoctor">
                         <b-form-file
                          class="custom-file-style"
                           id="imageInput4"
                           :placeholder="dataDoctor.ImgPersonName ? dataDoctor.ImgPersonName : 'Choose a file'"
                           accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                           @change="handleImageUpload"
                           :disabled="isDisable"
                         ></b-form-file>
                       </b-form-group>
                       <div v-if="dataDoctor.ImgPersonPath" style="text-align: center; margin: 1rem auto;">
                         <img :src="getFullImagePath(dataDoctor.ImgPersonPath)" alt="Uploaded Barcode" width="200" height="200"/>
                       </div>
                     </div>
                   </template>

                  <template>
                     <div>
                       <b-form-group label-for="imageInput" label="อัปโหลดรูปบัตรประชาชน" class="text-file-viewDoctor">
                         <b-form-file
                          class="custom-file-style"
                           id="imageInput"
                           :placeholder="dataDoctor.ImgCitizenName ? dataDoctor.ImgCitizenName : 'Choose a file'"
                           accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                           @change="handleImageUpload"
                           :disabled="isDisable"
                         ></b-form-file>
                       </b-form-group>
                       <div v-if="dataDoctor.ImgAccoutPath" style="text-align: center; margin: 1rem auto;">
                         <img :src="getFullImagePath(dataDoctor.ImgAccoutPath)" alt="Uploaded Barcode" width="200" height="200"/>
                       </div>
                     </div>
                   </template>
                   <!-- <br> -->
                   <template>
                     <div>
                       <b-form-group label-for="imageInput" label="อัปโหลดรูปหน้าบัญชี" class="text-file-viewDoctor">
                         <b-form-file
                           id="imageInput"
                           class="custom-file-style"
                           :placeholder="dataDoctor.ImgAccoutName ? dataDoctor.ImgAccoutName : 'Choose a file'"
                           accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                           @change="handleImageUpload"
                           :disabled="isDisable"
                         ></b-form-file>
                       </b-form-group>
                       <div v-if="dataDoctor.ImgAccoutPath" style="text-align: center; margin: 1rem auto;">
                         <img :src="getFullImagePath(dataDoctor.ImgAccoutPath)" alt="Uploaded Barcode" width="200" height="200"/>
                       </div>
                     </div>
                   </template>
                   <!-- <br> -->
                   <template>
                     <div>
                       <b-form-group label-for="imageInput" label="อัปโหลดรูปใบประกอบอาชีพ" class="text-file-viewDoctor">
                         <b-form-file
                           id="imageInput"
                           class="custom-file-style"
                           :placeholder="dataDoctor.ImgCardJobName ? dataDoctor.ImgCardJobName : 'Choose a file'"
                           accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                           @change="handleImageUpload"
                           :disabled="isDisable"
                         ></b-form-file>
                       </b-form-group>
                       <div v-if="dataDoctor.ImgCardJobPath" style="text-align: center; margin: 1rem auto;">
                         <img :src="getFullImagePath(dataDoctor.ImgCardJobPath)" alt="Uploaded Barcode" width="200" height="200"/>
                       </div>
                     </div>
                   </template>
                   <br>
                 </b-form>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.text-file-viewDoctor label {
  margin-bottom: 0 !important;
}
.edit-v-emp-doctor {
  margin-right: 4.5rem !important;
}
[dir=ltr] .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #43bac7 !important;
}
.res-justify {
  justify-content: center;
}
@media (max-width: 651px) {
  .res-justify {
    justify-content: start;
    margin-left: 1rem !important;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import axios from 'axios'
import { configAPI } from '../../configBase'

const dataDoctorTest = {
  name: 'วรงค์',
  lastName: 'ประสงค์ศรี',
  gender: 'ชาย',
  age: '56',
  numberJob: '102141241241523',
  branchSpecail: 'ผ่าตัด',
  phone: '082-243-0009',
  email: 'walong@smartClinic.com',
  convenientPlace: 'กรุงเทพ และปริมณฑล',
  numberTransfer: '2344-23423-12312',
  bank: 'กสิกร',
  nameAccount: 'วรงค์  ประสงค์ศรี',
  checkBInjection: true,
  checkBSurgery: false,
  standard: 'อื่น ๆ',
  selectNotDo: null,
  convenientStartWork: 'เที่ยงวัน - เที่ยงคืน',
  salaryMin: '2,000',
  ImgCitizenName: 'Cat.jpg',
  ImgCitizenPath: 'https://i.natgeofe.com/n/548467d8-c5f1-4551-9f58-6817a8d2c45e/NationalGeographic_2572187_square.jpg',
  ImgAccoutName: 'Cat.jpg',
  ImgAccoutPath: 'https://i.natgeofe.com/n/548467d8-c5f1-4551-9f58-6817a8d2c45e/NationalGeographic_2572187_square.jpg',
  ImgCardJobName: 'Cat.jpg',
  ImgCardJobPath: 'https://i.natgeofe.com/n/548467d8-c5f1-4551-9f58-6817a8d2c45e/NationalGeographic_2572187_square.jpg'
}
document.getElementById(dataDoctorTest)
export default {
  name: 'UiDataTable',
  data () {
    return {
      mode: 'view',
      id: null,
      cln_id: null,
      isDisable: true,
      dataDoctor: {
        name: null,
        lastName: null,
        gender: null,
        age: '',
        numberJob: '',
        branchSpecail: null,
        phone: '',
        email: '',
        convenientPlace: null,
        numberTransfer: null,
        bank: null,
        nameAccount: null,
        checkBInjection: false,
        checkBSurgery: false,
        standard: null,
        selectNotDo: null,
        convenientStartWork: null,
        salaryMin: null,
        ImgCitizenName: null,
        ImgCitizenPath: null,
        ImgAccoutName: null,
        ImgAccoutPath: null,
        ImgCardJobName: null,
        ImgCardJobPath: null,
        ImgPersonName: null,
        ImgPersonPath: null
      },
      inputValue: 0,
      selectedOption: '',
      showModal: false,
      imageUrl: null,
      imageUrl2: null,
      imageUrl3: null,
      activeTab: 'tab1',
      currentPage: 1,
      perPage: 10
    }
  },
  methods: {
    preventDecimalAndNegative (event) {
      if (
        event.key === '.' ||
        event.key === '-' ||
        event.key === 'e' ||
        event.key === 'E'
      ) {
        event.preventDefault()
      }
    },
    handleImageUpload (event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.imageUrl = reader.result
      }
    },
    handleImageUpload2 (event) {
      const file2 = event.target.files[0]
      const reader2 = new FileReader()
      reader2.readAsDataURL(file2)
      reader2.onload = () => {
        this.imageUrl2 = reader2.result
      }
    },
    handleImageUpload3 (event) {
      const file3 = event.target.files[0]
      const reader3 = new FileReader()
      reader3.readAsDataURL(file3)
      reader3.onload = () => {
        this.imageUrl3 = reader3.result
      }
    },
    handleClick () {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${'คุณมั่นใจว่าคุณจะจ้างหมอท่านนี้ ใช่หรือไม่?'}</span>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          router.push('/RecruitDoctor/' + this.id)
          /* Vue.swal({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">ยืนยันการจ้างหมอสำเร็จ</span>',
            icon: 'success',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 2000
          }).then(() => {
          }) */
        }
      })
    },
    onPagination (page) {
      this.currentPage = page
    },
    clickCancel () {
      router.go(-1)
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },
    getFullImagePath (relativePath) {
      if (relativePath) {
        let path = relativePath !== null ? relativePath.slice(1) : ''
        const baseURL = `${configAPI.MA_PORT}` + path
        return baseURL
      } else {
        return null
      }
    },
    async getDetailsDoctorById () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/doctor/${this.id}/`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.data.status) {
            const data = response.data.data.length > 0 ? response.data.data[0] : {}
            this.dataDoctor = {
              name: data.name,
              lastName: null,
              gender: data.gender_name,
              age: data.age,
              numberJob: data.no,
              branchSpecail: data.specialized,
              phone: data.tel,
              email: data.email,
              convenientPlace: data.aw_name,
              numberTransfer: data.account_no,
              bank: data.bank_name,
              nameAccount: data.account_name,
              checkBInjection: data.is_receive?.toLowerCase() === 'injection',
              checkBSurgery: data.is_receive?.toLowerCase() === 'surgery',
              standard: data.standart_work,
              selectNotDo: data.no_area,
              convenientStartWork: data.work_range,
              salaryMin: data.mi_name,
              ImgCitizenName: data.citizen_img,
              ImgCitizenPath: data.citizen_path,
              ImgAccoutName: data.account_img,
              ImgAccoutPath: data.account_path,
              ImgCardJobName: data.specialized_img,
              ImgCardJobPath: data.specialized_path,
              ImgPersonName: data.person_img,
              ImgPersonPath: data.person_path
            }
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    }
  },
  async mounted () {
    const idParam = this.$route.params.id
    if (idParam) {
      this.id = idParam
      await this.getDetailsDoctorById()
      /* this.dataDoctor = dataDoctorTest
      document.getElementsByClassName(idParam) */
    }
    xray.index()
  }
}
</script>
