<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="table-responsive">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; white-space: nowrap; color: black;">ปฏิทินคลินิก</span>
              </div>
              <div class="col-12 col-sm-auto" style="padding-right: 0%;">
                <div class="row">
                  <div class="col-6 pl-3 pr-2 my-1 my-sm-0">
                    <b-button type="submit" variant="primary" class="w-100 btn-min-size" @click="handleSave(true)" style="white-space: nowrap; text-align: center;"><i class="save-data_ct"/>บันทึก</b-button>
                  </div>
                  <div class="col-6 pl-0 pr-4 my-1 my-sm-0">
                    <b-button type="submit" variant="danger" class="w-100 btn-min-size btn-cancel-border" @click="handleSave(false)" style="white-space: nowrap; text-align: center;">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <b-row class="mx-4">
              <b-col>
                <b-row>
                  <b-col lg="2" md="12" sm="12" style="display: flex; justify-content: start; align-items: center; white-space: nowrap;">
                    <b-text class="headText" style="margin-top: 1.5rem; color: black;">กำหนดตารางวัน<br class="headLine">(หน่วย:ชั่วโมง)</b-text>
                  </b-col>
                  <b-col lg="2" md="6" sm="12" style="white-space: nowrap;">
                    <label for="numTime1">ช่วงกลางวัน</label>
                    <input type="text" class="form-control numTim1" v-model="CalendarData.dayTime" @input="limitTime">
                  </b-col>
                  <b-col lg="2" md="6" sm="12" style="white-space: nowrap;">
                    <label for="numTime2">ช่วงกลางวัน[OT]</label>
                    <input  type="text" class="form-control numTim2" v-model="CalendarData.dayTimeOT" @input="limitTime">
                  </b-col>
                  <b-col lg="2" md="6" sm="12" style="white-space: nowrap;">
                    <label for="numTime3">ช่วงกลางคืน</label>
                    <input  type="text" class="form-control numTim3" v-model="CalendarData.nightTime" @input="limitTime">
                  </b-col>
                  <b-col lg="2" md="6" sm="12" style="white-space: nowrap;">
                    <label for="numTime4">ช่วงกลางคืน[OT]</label>
                    <input  type="text" class="form-control numTim4" v-model="CalendarData.nightTimeOT" @input="limitTime">
                  </b-col>
                  <br class="NewLine">
                  <b-col lg="2" md="12" sm="12" class="d-flex align-items-center justify-content-center" style="white-space: nowrap;">
                    <b-button type="submit" variant="primary" class="w-100 btn-min-size buttonEvent" @click="handleClick(true)" style="margin-top: 2rem;"><i class="calendar_ct"/>ลงเวลารายวัน</b-button>
                  </b-col>
                </b-row>
                <br>
                <b-row>
                  <b-col lg="2" md="4" sm="4" class="custom-control custom-radio type1" id="type1" style="display: flex; align-items: center; justify-content: start; white-space: nowrap; padding-left: 2.5rem;">
                    <input type="radio" value="setSelf" v-model="CalendarData.eventTypeset" id="customRadio1" name="customRadio-1" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio1">เวลาทำงาน</label>
                  </b-col>
                  <b-col lg="2" md="4" sm="4" class="custom-control custom-radio type2" id="type2" style="display: flex; align-items: center; justify-content: start; white-space: nowrap;  padding-left: 2.5rem;">
                    <input type="radio" value="setDayOff" v-model="CalendarData.eventTypeset" id="customRadio3" name="customRadio-1" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio3">วันหยุด</label>
                  </b-col>
                  <b-col lg="2" md="4" sm="4" class="custom-control custom-radio type3" id="type3" style="display: flex; align-items: center; justify-content: start; white-space: nowrap; padding-left: 2.5rem;">
                    <input type="radio" value="setHoliday" v-model="CalendarData.eventTypeset" id="customRadio4" name="customRadio-1" class="custom-control-input">
                    <label class="custom-control-label" for="customRadio4">วันหยุดนักขัตฤกษ์</label>
                  </b-col>
                  <b-col lg="4" md="12" sm="12" class="bg-selectDate" style="display: flex; align-items: center; justify-content: center;">
                    <b-text class="iq-bg-primary pl-3 pr-3 pt-2 pb-2 text-black" style="display: flex; justify-content: center; white-space: nowrap; border-radius: 10px; width: 100%;">วันที่เลือก :&nbsp;{{ onDateNow }}</b-text>
                  </b-col>
                  <br class="NewLine">
                  <b-col lg="2" md="12" sm="12" class="d-flex align-items-center justify-content-center" style="white-space: nowrap;">
                    <b-button type="submit" variant="primary" class="w-100 btn-min-size buttonEventAll" @click="handleClick(false)"><i class="calendar_ct"/>ลงเวลารายเดือน</b-button>
                  </b-col>
                </b-row>
                <br>
                <b-row>
                  <b-col lg="2" md="6" sm="12">
                    <label>ปี</label>
                    <v-select v-model="selectedYear" :options="years"></v-select>
                  </b-col>
                  <b-col lg="2" md="6" sm="12">
                    <label>เดือน</label>
                    <v-select v-model="selectedMonth" :options="months"></v-select>
                  </b-col>
                  <b-col lg="2" md="6" sm="12" class="d-flex align-items-center justify-content-center">
                    <b-button type="number" variant="primary" class="w-100 btn-min-size buttonSearch" @click="handleSearch" style="margin-top: 2rem;"><i class="search_ct"/>ค้นหา</b-button>
                  </b-col>
                  <b-col lg="2" md="6" sm="12" class="d-flex align-items-center justify-content-center">
                    <b-button type="number" variant="none" class="w-100 btn-min-size btn-reset_ct buttonSearch" @click="handleClear" style="margin-top: 2rem; background-color: #EBFBFE">ล้างข้อมูล</b-button>
                  </b-col>
                </b-row>
                <br>
                <br>
              </b-col>
            </b-row>
            <template>
              <div class="clinic-app">
                <FullCalendar
                  class="clinic-app-calendar"
                  ref="fullCalendar"
                  defaultView="dayGridMonth"
                  :header="{
                    left: '', // 'prev,next today',
                    center: 'title',
                    right: ''
                  }"
                  :plugins="calendarPlugins"
                  :weekends="calendarWeekends"
                  :events="calendarEvents"
                  @dateClick="handleDateClick"
                  @eventClick="handleEventClick"
                  @datesRender="handleDatesRender"
                />
              </div>
            </template>
            <b-modal v-model="showModalSelectDay" centered size="md" no-close-on-backdrop>
              <template #modal-title>
                <h1 class="mx-2" style="font-size: 25px;">ข้อมูลเวลาทำงาน</h1>
              </template>
              <div class="modal-body flex-fill text-center"
                style="color: gray; border-color: black; align-items-center">
                <p type="submit" variant="primary" style="font-size: 15px;">
                  <template>
                    <div style="font-size: 20px;">{{ eventData.title }}</div>
                    <div style="font-size: 16px;">{{ eventData.detailsPath1 }}</div>
                    <div style="font-size: 16px;">{{ eventData.detailsPath2 }}</div>
                  </template>
                </p>
              </div>
              <template #modal-footer>
                <div class="w-100 btn-min-size" style="display: flex; justify-content: flex-end;">
                  <b-button
                    class="iq-bg-danger btn-cancel-border"
                    variant="none"
                    style="border-radius: 0.5rem;"
                    size="md"
                    @click="showModalSelectDay = false"
                  >
                    ปิด
                  </b-button>
                </div>
              </template>
            </b-modal>
            <br>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import Vue from 'vue'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import dayjs from 'dayjs'
import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import Axios from 'axios'
import moment from 'moment'
import { configAPI } from '../../configBase'
const arrListyears = []
for (let i = 1; i < 12; i++) {
  let temp = { code: new Date().getFullYear() - 3 + i, label: new Date().getFullYear() - 3 + i }
  arrListyears.push(temp)
}

export default {
  name: 'GoogleCalendar',
  components: {
    FullCalendar
  },
  data () {
    return {
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      usrId: JSON.parse(sessionStorage.getItem('user')).usr_id || null,
      showModalSelectDay: false,
      CalendarData: {
        dayTime: '',
        dayTimeOT: '',
        nightTime: '',
        nightTimeOT: '',
        sumTime: '',
        eventTypeset: null
      },
      eventData: {
        title: '',
        details: ''
      },
      config: {
        dateFormat: 'Y-m-d',
        inline: true
      },
      onDateNow: null,
      onMonthNow: null,
      onYearNow: null,
      selectedDate: null,
      selectedYear: null,
      years: arrListyears,
      selectedMonth: '',
      months: [
        { label: 'มกราคม', code: 1 },
        { label: 'กุมภาพันธ์', code: 2 },
        { label: 'มีนาคม', code: 3 },
        { label: 'เมษายน', code: 4 },
        { label: 'พฤษภาคม', code: 5 },
        { label: 'มิถุนายน', code: 6 },
        { label: 'กรกฎาคม', code: 7 },
        { label: 'สิงหาคม', code: 8 },
        { label: 'กันยายน', code: 9 },
        { label: 'ตุลาคม', code: 10 },
        { label: 'พฤศจิกายน', code: 11 },
        { label: 'ธันวาคม', code: 12 }
      ],
      calendarPlugins: [
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin
      ],
      calendarWeekends: true,
      calendarEvents: [],
      calendarHeight: '1000px',
      oldData: null,
      oldDataAll: [],
      isChange: false
    }
  },
  async mounted () {
    this.$refs.fullCalendar.getApi().setOption('contentHeight', 850)
    setTimeout(() => {
      this.$refs.fullCalendar.getApi().setOption('contentHeight', 'auto')
    }, 1000)
    this.selectedDate = new Date()
    this.onDateNow = dayjs(this.selectedDate).format('DD-MM-YYYY')
    this.onMonthNow = dayjs(this.selectedDate).format('MM')
    this.onYearNow = dayjs(this.selectedDate).format('YYYY')
    this.selectedYear = this.years.find(el => el.code === new Date().getFullYear())
    this.selectedMonth = this.months.find(el => el.code === new Date().getMonth() + 1)
    await this.getCalender()
    xray.index()
  },
  methods: {
    async getCalender () {
      let CalenderAPI = {
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/calendar`, // ?year=${this.selectedYear.code}&month=${this.selectedMonth.code}
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await Axios.request(CalenderAPI)
        .then((response) => {
          let data = response.data.data
          let temp = []
          for (let item of data) {
            let tempData = {
              id: item.id,
              title: item.day_type === 'day_off' ? 'Day off' : item.day_type === 'holiday' ? 'Holiday' : 'Day: ' + item.day_shift + ' [OT]' + item.day_shift_ot + ' / Night: ' + item.night_shift + ' [OT]' + item.night_shift_ot,
              details: 'ช่วงกลางวัน: ' + item.day_shift + ' ชั่วโมง / ช่วงกลางวัน[OT]: ' + item.day_shift_ot + ' ชั่วโมง' + '\n' + 'ช่วงกลางคืน: ' + item.night_shift + ' ชั่วโมง / ช่วงกลางคืน[OT]: ' + item.night_shift_ot + ' ชั่วโมง',
              start: dayjs(item.date).format('YYYY-MM-DD'),
              allDay: true,
              color: item.day_type === 'day_off' ? '#d84a45' : item.day_type === 'holiday' ? '#fc9e5b' : '#089bab',
              day_shift: item.day_shift,
              day_shift_ot: item.day_shift_ot,
              night_shift: item.night_shift,
              night_shift_ot: item.night_shift_ot,
              day_type: item.day_type
            }
            temp.push(tempData)
          }
          this.calendarEvents = temp
        })
    },
    handleDateClick (arg) {
      let lastDayInMonth = new Date(this.onYearNow, this.onMonthNow, 0)
      let firstDayInMonth = new Date(this.onYearNow, this.onMonthNow - 1, 1)
      let argDateIsInCurrentMonth = arg.date >= firstDayInMonth && arg.date <= lastDayInMonth
      if (argDateIsInCurrentMonth) {
        const allDateCells = document.querySelectorAll('.fc-day')
        allDateCells.forEach((cell) => {
          cell.style.backgroundColor = ''
        })
        arg.dayEl.style.backgroundColor = 'rgb(227, 251, 254)'
        this.selectedDate = arg.date
        this.onDateNow = dayjs(arg.date).format('DD-MM-YYYY')
      } else {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณไม่สามารถเลือกวันที่นอกเหนือจากเดือนนี้ได้</span>',
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1'
          }
        })
      }
    },
    handleEventClick (arg) {
      const clickedEvent = arg.event
      this.showModalSelectDay = true
      if (clickedEvent.title === 'Holiday') {
        this.eventData.title = 'วันหยุดนักขัตฤกษ์'
      } else if (clickedEvent.title === 'Day off') {
        this.eventData.title = 'วันหยุด'
      } else {
        this.eventData.title = clickedEvent.title
      }
      this.eventData.details = clickedEvent.extendedProps.details
      const [day, night] = this.eventData.details.split('\n')
      this.eventData.detailsPath1 = day
      this.eventData.detailsPath2 = night
    },

    async handleSave (status) {
      if (status) {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะบันทึกข้อมูลใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.saveAndUpdateCalender(true)
          }
        })
      } else {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะยกเลิกลงเวลาทำงานใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        })
      }
    },
    async handleClick (status) {
      if (status) {
        const errorList = await this.checkErrorAdd()
        if (errorList.length > 0) {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลงเวลาทำงานรายวันไม่สำเร็จ</span>',
            html: `<span class="" style="font-size: 20px; color: black; text-align: center">${errorList.join('<br/>')}</span>`,
            icon: 'error',
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        } else {
          if (this.CalendarData.eventTypeset === 'setDayOff') {
            this.CalendarData.dayTime = ''
            this.CalendarData.dayTimeOT = ''
            this.CalendarData.nightTime = ''
            this.CalendarData.nightTimeOT = ''
          }
          if (this.CalendarData.eventTypeset === 'setHoliday') {
            this.CalendarData.dayTime = ''
            this.CalendarData.dayTimeOT = ''
            this.CalendarData.nightTime = ''
            this.CalendarData.nightTimeOT = ''
          }
          let findIndexOldData = this.calendarEvents.findIndex(el => dayjs(el.start).format('YYYY-MM-DD') === dayjs(this.selectedDate).format('YYYY-MM-DD'))
          if (findIndexOldData !== -1) {
            this.oldData = this.calendarEvents[findIndexOldData]
            this.calendarEvents.splice(findIndexOldData, 1)
          }
          await this.AddEvent()
          const properties = ['dayTime', 'dayTimeOT', 'nightTime', 'nightTimeOT']
          for (const property of properties) {
            if (this.CalendarData[property] === 0) {
              this.CalendarData[property] = ''
            }
          }
        }
      } else {
        const errorList = await this.checkErrorAddAll()
        if (errorList.length > 0) {
          let errMessage = ''
          for (let e = 0; e < errorList.length; e++) {
            errMessage += '<br/>' + errorList[e]
          }
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลงเวลาทำงานรายเดือนไม่สำเร็จ</span>',
            html: errMessage,
            icon: 'error',
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        } else {
          if (this.CalendarData.eventTypeset === 'setDayOff') {
            this.CalendarData.dayTime = ''
            this.CalendarData.dayTimeOT = ''
            this.CalendarData.nightTime = ''
            this.CalendarData.nightTimeOT = ''
          }
          if (this.CalendarData.eventTypeset === 'setHoliday') {
            this.CalendarData.dayTime = ''
            this.CalendarData.dayTimeOT = ''
            this.CalendarData.nightTime = ''
            this.CalendarData.nightTimeOT = ''
          }
          this.oldDataAll = JSON.parse(JSON.stringify(this.calendarEvents))
          let filterData = this.calendarEvents.filter(el => dayjs(el.start).format('YYYY-MM') === dayjs(this.selectedDate).format('YYYY-MM'))
          if (filterData.length > 0) {
            for (let i = 0; i < filterData.length; i++) {
              let findIndexOldData = this.calendarEvents.findIndex(el => dayjs(el.start).format('YYYY-MM-DD') === dayjs(filterData[i].start).format('YYYY-MM-DD'))
              if (findIndexOldData !== -1) {
                this.calendarEvents.splice(findIndexOldData, 1)
              }
            }
          }
          await this.AddEventAll()
          const properties = ['dayTime', 'dayTimeOT', 'nightTime', 'nightTimeOT']
          for (const property of properties) {
            if (this.CalendarData[property] === 0) {
              this.CalendarData[property] = ''
            }
          }
        }
      }
    },
    async handleSearch () {
      await this.callForSearch()
      // if (this.isChange) {
      //   Vue.swal({
      //     title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมีการเปลี่ยนแปลงข้อมูลที่ยังไม่ได้บันทึก</span>',
      //     text: 'คุณต้องการบันทึกข้อมูลก่อนเปลี่ยนเดือนหรือไม่?',
      //     icon: 'warning',
      //     showCancelButton: true,
      //     confirmButtonText: '<b-button style="font-size: 14px; text-align: center">บันทึก</b-button>',
      //     confirmButtonColor: '#089bab',
      //     cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่บันทึก</b-button>',
      //     cancelButtonColor: '#d33',
      //     customClass: {
      //       confirmButton: 'custom-confirm-button-class mx-1',
      //       cancelButton: 'custom-confirm-button-class mx-1'
      //     }
      //   }).then(async (result) => {
      //     if (result.isConfirmed) {
      //       await this.saveAndUpdateCalender(false)
      //     } else {
      //       await this.callForSearch()
      //     }
      //   })
      // } else {
      //   await this.callForSearch()
      // }
    },
    async handleClear () {
      const currentDate = new Date()
      let calendarApi = this.$refs.fullCalendar.getApi()
      this.selectedMonth = this.months.find(el => el.code === currentDate.getMonth() + 1)
      this.selectedYear = this.years.find(el => el.code === new Date().getFullYear())
      this.selectedDate = new Date()
      calendarApi.gotoDate(currentDate)
      this.onDateNow = dayjs(new Date()).format('DD-MM-YYYY')
      await this.getCalender()
    },
    async handleDatesRender (info) {
      // await this.handleSearch()
      const newMonth = info.view.title
      const [monthStr, yearStr] = newMonth.split(' ')
      if (newMonth !== dayjs(this.selectedDate).format('MMMM YYYY')) {
        const selectedYear = this.selectedYear.code
        const selectedMonth = this.selectedMonth.code
        const dateToday = moment(new Date()).format('DD')
        this.selectedDate = moment(`${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${dateToday}`).toDate()
        this.onDateNow = dayjs(this.selectedDate).format('DD-MM-YYYY')
        this.onYearNow = parseInt(yearStr)
        this.onMonthNow = new Date(Date.parse(monthStr + ' 1, 2000')).getMonth() + 1
        if (this.onMonthNow < 10) {
          this.onMonthNow = '0' + (new Date(Date.parse(monthStr + ' 1, 2000')).getMonth() + 1)
        }
      } else {
        this.selectedDate = new Date()
        this.onDateNow = dayjs(this.selectedDate).format('DD-MM-YYYY')
        this.onYearNow = parseInt(yearStr)
        this.onMonthNow = new Date(Date.parse(monthStr + ' 1, 2000')).getMonth() + 1
        if (this.onMonthNow < 10) {
          this.onMonthNow = '0' + (new Date(Date.parse(monthStr + ' 1, 2000')).getMonth() + 1)
        }
      }
      this.selectedMonth = this.months.find(el => el.code === parseInt(this.onMonthNow))
      this.selectedYear = this.years.find(el => el.code === parseInt(this.onYearNow))
      // this.CalendarData = {
      //   dayTime: '',
      //   dayTimeOT: '',
      //   nightTime: '',
      //   nightTimeOT: '',
      //   eventTypeset: null,
      //   sumTime: ''
      // }
    },
    async callForSearch () {
      this.isChange = false
      const selectedYear = this.selectedYear.code
      const selectedMonth = this.selectedMonth.code
      let calendarApi = this.$refs.fullCalendar.getApi()
      calendarApi.gotoDate(`${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-01`)
      // await this.getCalender()
    },
    async AddEvent () {
      if (this.CalendarData.eventTypeset === 'setSelf') {
        const properties = ['dayTime', 'dayTimeOT', 'nightTime', 'nightTimeOT']
        for (const property of properties) {
          if (this.CalendarData[property] === '') {
            this.CalendarData[property] = 0
          }
        }
        let workType = this.oldData !== null ? this.oldData.day_type : null
        if (workType !== 'work') {
          this.isChange = true
        }
        this.calendarEvents.push({
          id: this.oldData !== null ? this.oldData.id : null,
          title: 'Day: ' + this.CalendarData.dayTime + ' [OT]' + this.CalendarData.dayTimeOT + ' / Night: ' + this.CalendarData.nightTime + ' [OT]' + this.CalendarData.nightTimeOT,
          details: 'ช่วงกลางวัน: ' + this.CalendarData.dayTime + ' ชั่วโมง / ช่วงกลางวัน[OT]: ' + this.CalendarData.dayTimeOT + ' ชั่วโมง' + '\n' + 'ช่วงกลางคืน: ' + this.CalendarData.nightTime + ' ชั่วโมง / ช่วงกลางคืน[OT]: ' + this.CalendarData.nightTimeOT + ' ชั่วโมง',
          start: this.selectedDate,
          allDay: true,
          color: '#089bab',
          day_shift: this.CalendarData.dayTime,
          day_shift_ot: this.CalendarData.dayTimeOT,
          night_shift: this.CalendarData.nightTime,
          night_shift_ot: this.CalendarData.nightTimeOT,
          day_type: 'work'
        })
        this.oldData = null
      }
      if (this.CalendarData.eventTypeset === 'setDayOff') {
        let workType = this.oldData !== null ? this.oldData.day_type : null
        if (workType !== 'day_off') {
          this.isChange = true
        }
        this.calendarEvents.push({
          id: this.oldData !== null ? this.oldData.id : null,
          title: 'Day off',
          details: 'ช่วงกลางวัน: 0 ชั่วโมง / ช่วงกลางวัน[OT]: 0 ชั่วโมง' + '\n' + 'ช่วงกลางคืน: 0 ชั่วโมง / ช่วงกลางคืน[OT]: 0 ชั่วโมง',
          start: this.selectedDate,
          allDay: true,
          color: '#d84a45',
          day_shift: 0,
          day_shift_ot: 0,
          night_shift: 0,
          night_shift_ot: 0,
          day_type: 'day_off'
        })
        this.oldData = null
      }
      if (this.CalendarData.eventTypeset === 'setHoliday') {
        let workType = this.oldData !== null ? this.oldData.day_type : null
        if (workType !== 'holiday') {
          this.isChange = true
        }
        this.calendarEvents.push({
          id: this.oldData !== null ? this.oldData.id : null,
          title: 'Holiday',
          details: 'ช่วงกลางวัน: 0 ชั่วโมง / ช่วงกลางวัน[OT]: 0 ชั่วโมง' + '\n' + 'ช่วงกลางคืน: 0 ชั่วโมง / ช่วงกลางคืน[OT]: 0 ชั่วโมง',
          start: this.selectedDate,
          allDay: true,
          color: '#fc9e5b',
          day_shift: 0,
          day_shift_ot: 0,
          night_shift: 0,
          night_shift_ot: 0,
          day_type: 'holiday'
        })
        this.oldData = null
      }
    },
    async AddEventAll () {
      const selectedYear = this.onYearNow
      const selectedMonth = this.onMonthNow
      if (this.CalendarData.eventTypeset === 'setSelf') {
        const properties = ['dayTime', 'dayTimeOT', 'nightTime', 'nightTimeOT']
        for (const property of properties) {
          if (this.CalendarData[property] === '') {
            this.CalendarData[property] = 0
          }
        }
        const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate()
        for (let day = 1; day <= daysInMonth; day++) {
          let workType = this.oldDataAll.length > 0
            ? this.oldDataAll.find(el => dayjs(el.start).format('YYYY-MM-DD') === dayjs(`${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`).format('YYYY-MM-DD')) !== undefined
              ? this.oldDataAll.find(el => dayjs(el.start).format('YYYY-MM-DD') === dayjs(`${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`).format('YYYY-MM-DD')).day_type
              : null
            : null
          if (workType !== 'work') {
            this.isChange = true
          }
          this.calendarEvents.push({
            id: this.oldDataAll.length > 0
              ? this.oldDataAll.find(el => dayjs(el.start).format('YYYY-MM-DD') === dayjs(`${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`).format('YYYY-MM-DD')) !== undefined
                ? this.oldDataAll.find(el => dayjs(el.start).format('YYYY-MM-DD') === dayjs(`${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`).format('YYYY-MM-DD')).id
                : null
              : null,
            title: 'Day: ' + this.CalendarData.dayTime + ' [OT]' + this.CalendarData.dayTimeOT + ' / Night: ' + this.CalendarData.nightTime + ' [OT]' + this.CalendarData.nightTimeOT,
            details: 'ช่วงกลางวัน: ' + this.CalendarData.dayTime + ' ชั่วโมง / ช่วงกลางวัน[OT]: ' + this.CalendarData.dayTimeOT + ' ชั่วโมง' + '\n' + 'ช่วงกลางคืน: ' + this.CalendarData.nightTime + ' ชั่วโมง / ช่วงกลางคืน[OT]: ' + this.CalendarData.nightTimeOT + ' ชั่วโมง',
            start: `${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
            allDay: true,
            color: '#089bab',
            day_shift: this.CalendarData.dayTime,
            day_shift_ot: this.CalendarData.dayTimeOT,
            night_shift: this.CalendarData.nightTime,
            night_shift_ot: this.CalendarData.nightTimeOT,
            day_type: 'work'
          })
        }
      }
      if (this.CalendarData.eventTypeset === 'setDayOff') {
        const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate()
        for (let day = 1; day <= daysInMonth; day++) {
          let workType = this.oldDataAll.length > 0
            ? this.oldDataAll.find(el => dayjs(el.start).format('YYYY-MM-DD') === dayjs(`${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`).format('YYYY-MM-DD')) !== undefined
              ? this.oldDataAll.find(el => dayjs(el.start).format('YYYY-MM-DD') === dayjs(`${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`).format('YYYY-MM-DD')).day_type
              : null
            : null
          if (workType !== 'day_off') {
            this.isChange = true
          }
          this.calendarEvents.push({
            id: this.oldDataAll.length > 0
              ? this.oldDataAll.find(el => dayjs(el.start).format('YYYY-MM-DD') === dayjs(`${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`).format('YYYY-MM-DD')) !== undefined
                ? this.oldDataAll.find(el => dayjs(el.start).format('YYYY-MM-DD') === dayjs(`${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`).format('YYYY-MM-DD')).id
                : null
              : null,
            title: 'Day off',
            details: 'ช่วงกลางวัน: 0 ชั่วโมง / ช่วงกลางวัน[OT]: 0 ชั่วโมง' + '\n' + 'ช่วงกลางคืน: 0 ชั่วโมง / ช่วงกลางคืน[OT]: 0 ชั่วโมง',
            start: `${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
            allDay: true,
            color: '#d84a45',
            day_shift: 0,
            day_shift_ot: 0,
            night_shift: 0,
            night_shift_ot: 0,
            day_type: 'day_off'
          })
        }
      }
      if (this.CalendarData.eventTypeset === 'setHoliday') {
        const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate()
        for (let day = 1; day <= daysInMonth; day++) {
          let workType = this.oldDataAll.length > 0
            ? this.oldDataAll.find(el => dayjs(el.start).format('YYYY-MM-DD') === dayjs(`${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`).format('YYYY-MM-DD')) !== undefined
              ? this.oldDataAll.find(el => dayjs(el.start).format('YYYY-MM-DD') === dayjs(`${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`).format('YYYY-MM-DD')).day_type
              : null
            : null
          if (workType !== 'holiday') {
            this.isChange = true
          }
          this.calendarEvents.push({
            id: this.oldDataAll.length > 0
              ? this.oldDataAll.find(el => dayjs(el.start).format('YYYY-MM-DD') === dayjs(`${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`).format('YYYY-MM-DD')) !== undefined
                ? this.oldDataAll.find(el => dayjs(el.start).format('YYYY-MM-DD') === dayjs(`${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`).format('YYYY-MM-DD')).id
                : null
              : null,
            title: 'Holiday',
            details: 'ช่วงกลางวัน: 0 ชั่วโมง / ช่วงกลางวัน[OT]: 0 ชั่วโมง' + '\n' + 'ช่วงกลางคืน: 0 ชั่วโมง / ช่วงกลางคืน[OT]: 0 ชั่วโมง',
            start: `${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
            allDay: true,
            color: '#fc9e5b',
            day_shift: 0,
            day_shift_ot: 0,
            night_shift: 0,
            night_shift_ot: 0,
            day_type: 'holiday'
          })
          // this.logCalenderAE.push({
          //   title: 'Holiday',
          //   details: 'ช่วงกลางวัน: 0 ชั่วโมง / ช่วงกลางวัน[OT]: 0 ชั่วโมง' + '\n' + 'ช่วงกลางคืน: 0 ชั่วโมง / ช่วงกลางคืน[OT]: 0 ชั่วโมง',
          //   start: `${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
          //   allDay: true,
          //   color: '#fc9e5b',
          //   day_shift: 0,
          //   day_shift_ot: 0,
          //   night_shift: 0,
          //   night_shift_ot: 0,
          //   day_type: 'holiday'
          // })
        }
      }
      if (this.CalendarData.eventTypeset === null) {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลไม่สำเร็จ</span>',
          text: 'กรุณาเลือกประเภท Event ที่ต้องการ',
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1'
          }
        })
      }
    },
    limitTime () {
      const regex = /^\d+(\.\d{0,2})?$/
      const values = [
        this.CalendarData.dayTime,
        this.CalendarData.dayTimeOT,
        this.CalendarData.nightTime,
        this.CalendarData.nightTimeOT
      ]
      for (let i = 0; i < values.length; i++) {
        let value = values[i]
        if (!regex.test(value) || parseFloat(value) > 24.00) {
          value = value.slice(0, -1)
        }
        values[i] = value
      }
      this.CalendarData.dayTime = values[0]
      this.CalendarData.dayTimeOT = values[1]
      this.CalendarData.nightTime = values[2]
      this.CalendarData.nightTimeOT = values[3]
    },
    async checkErrorAdd (tab) {
      let error = []
      if (this.onDateNow === 'ยังไม่ได้เลือกวัน') {
        error.push('กรุณาเลือกวันที่ต้องการลงเวลาทำงาน')
      }
      if (this.CalendarData.eventTypeset === null) {
        error.push('กรุณาเลือกประเภทที่ต้องการลงเวลาทำงาน')
      }
      if (this.CalendarData.eventTypeset === 'setSelf') {
        const regex = /^\d+(\.\d{0,2})?$/
        const values = [
          this.CalendarData.dayTime,
          this.CalendarData.dayTimeOT,
          this.CalendarData.nightTime,
          this.CalendarData.nightTimeOT
        ]
        for (let i = 0; i < values.length; i++) {
          let value = values[i]
          if (!regex.test(value) || parseFloat(value) > 24.00) {
            value = value.slice(0, -1)
          }
          values[i] = parseFloat(value)
        }
        this.CalendarData.dayTime = values[0] || ''
        this.CalendarData.dayTimeOT = values[1] || ''
        this.CalendarData.nightTime = values[2] || ''
        this.CalendarData.nightTimeOT = values[3] || ''
        this.CalendarData.sumTime = (values[0] || 0) + (values[1] || 0) + (values[2] || 0) + (values[3] || 0)
        if (this.CalendarData.sumTime > 24) {
          error.push('กรุณากรอกช่วงเวลาทำงานทั้งหมดใหม่อีกครั้ง จำนวนชั่วโมงรวมต้องไม่เกิน 24 ชั่วโมง')
        } else if (this.CalendarData.sumTime === 0) {
          error.push('กรุณากรอกช่วงเวลาทำงาน')
        } return error
      }
      return error
    },
    async checkErrorAddAll (tab) {
      let error = []
      if (this.CalendarData.eventTypeset === null) {
        error.push('กรุณาเลือกประเภทที่ต้องการลงเวลาทำงาน')
      }
      if (this.CalendarData.eventTypeset === 'setSelf') {
        const regex = /^\d+(\.\d{0,2})?$/
        const values = [
          this.CalendarData.dayTime,
          this.CalendarData.dayTimeOT,
          this.CalendarData.nightTime,
          this.CalendarData.nightTimeOT
        ]
        for (let i = 0; i < values.length; i++) {
          let value = values[i]
          if (!regex.test(value) || parseFloat(value) > 24.00) {
            value = value.slice(0, -1)
          }
          values[i] = parseFloat(value)
        }
        this.CalendarData.dayTime = values[0] || ''
        this.CalendarData.dayTimeOT = values[1] || ''
        this.CalendarData.nightTime = values[2] || ''
        this.CalendarData.nightTimeOT = values[3] || ''
        this.CalendarData.sumTime = (values[0] || 0) + (values[1] || 0) + (values[2] || 0) + (values[3] || 0)
        if (this.CalendarData.sumTime > 24) {
          error.push('กรุณากรอกช่วงเวลาทำงานทั้งหมดใหม่อีกครั้ง จำนวนชั่วโมงรวมต้องไม่เกิน 24 ชั่วโมง')
        } else if (this.CalendarData.sumTime === 0) {
          error.push('กรุณากรอกช่วงเวลาทำงาน')
        } return error
      }
      return error
    },
    async saveCalender (addData, editData) {
      if (addData.length > 0) {
        await Axios.request({
          method: 'POST',
          url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/calendar`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: addData
        })
      }
      if (editData.length > 0) {
        await Axios.request({
          method: 'PUT',
          url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/calendar`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: editData
        })
      }
    },
    async saveAndUpdateCalender (isSubmit) {
      if (this.calendarEvents.length > 0) {
        let dataTempAdd = []
        let dataTempEdit = []
        for (let item of this.calendarEvents) {
          let id = item.id !== undefined ? item.id : null
          item.start = dayjs(item.start).format('YYYY-MM-DD')
          if (id === null) {
            let tempAdd = {
              date: new Date(item.start),
              day_shift: item.day_shift,
              day_shift_ot: item.day_shift_ot,
              night_shift: item.night_shift,
              night_shift_ot: item.night_shift_ot,
              day_type: item.day_type,
              remark: null,
              is_use: true,
              created_by: this.usrId
            }
            dataTempAdd.push(tempAdd)
          } else {
            let tempEdit = {
              cc_id: id,
              date: new Date(item.start),
              day_shift: item.day_shift,
              day_shift_ot: item.day_shift_ot,
              night_shift: item.night_shift,
              night_shift_ot: item.night_shift_ot,
              day_type: item.day_type,
              remark: null,
              is_use: true,
              updated_by: this.usrId
            }
            dataTempEdit.push(tempEdit)
          }
        }
        await this.saveCalender(dataTempAdd, dataTempEdit)
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
          icon: 'success',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
          timer: 2000
        }).then(async () => {
          if (isSubmit) {
            window.location.reload()
          } else {
            await this.callForSearch()
          }
        })
      } else {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่มีข้อมูลที่จะบันทึก</span>',
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload()
          }
        })
      }
    }
  }
}
</script>
<style scoped>
.clinic-app {
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}
.clinic-app-calendar {
  margin: 0 auto;
  max-height: 2000px;
  height: 100%;
}
.NewLine {
  visibility: hidden !important;
  display: none;
}
.headLine {
  visibility: visible !important;
  display: block;
}
.buttonEvent {
  min-width: 143px;
  display: flex;
}
.buttonEventAll {
  min-width: 143px;
  display: flex;
}
@media (max-width: 991.5px) {
  .bg-selectDate {
    margin-top: 24px !important;
    margin-bottom: 12px !important;
    height: 35px !important;
    /* margin-left: 1.5rem !important; */
  }
  .headLine {
    visibility: hidden !important;
    display: none;
  }
  .headText {
    margin-top: 0.5rem !important;
  }
  .buttonSearch {
    margin-top: 1rem !important;
  }
  .buttonEvent {
    margin-top: 1rem !important;
  }
  .buttonEventAll {
    margin-top: 0.5rem !important;
  }
  .type1 {
    justify-content: center !important;
    display: flex !important;
  }
  .type2 {
    justify-content: center !important;
    display: flex !important;
  }
  .type3 {
    justify-content: center !important;
    display: flex !important;
  }
}
@media (max-width: 767px) {
  .NewLine {
    visibility: visible !important;
    display: block;
  }
}
@media (max-width: 576px) {
  .type1 {
    display: flex !important;
    justify-content: start !important;
    margin-left: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .type2 {
    display: flex !important;
    justify-content: start !important;
    margin-left: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .type3 {
    display: flex !important;
    justify-content: start !important;
    margin-left: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
}
</style>
