<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">รายการสินค้ารอเบิก OPD</span>
              </div>
              <!-- <div class="col-12 col-sm-12 col-md-auto">
                <div class="row res-flex-header">
                  <div class="col-lg-5 col-md-5 col-sm-6 col-6 col-6-to-12 pl-2 pr-2 my-1 my-sm-0">
                    <b-button type="button" variant="primary" @click="handleClick('add')" class="w-100 btn-min-size"><i class="add-cus_ct"/>เพิ่มชื่อผู้ป่วย</b-button>
                  </div>
                  <div class="col-lg-7 col-md-7 col-sm-6 col-6 col-6-to-12 pl-2 pr-2 my-1 my-sm-0">
                    <b-button type="button" variant="primary" @click="handleClick('service')" style="white-space: nowrap;" class="w-100 padding-patient-header btn-min-size"><i class="add-doc_ct"/>เพิ่มประวัติการรับบริการ</b-button>
                  </div>
                </div>
              </div> -->
            </div>
          </template>
          <!--  <template v-slot:headerTitle>
            <div class="row align-items-center py-4">
              <div class="ml-4">
                <span class="mx-1" style="font-size: 25px; color: black;">ทะเบียนลูกค้า / ผู้ป่วย</span>
              </div>
              <div class="col-lg-1 col-md-1 col-sm-2 col-12">
                <div class="d-flex my-2" style="white-space: nowrap;">
                  <b-button  @click="handleClick('add')" type="submit" variant="primary" class="mx-1 flex-fill text-center" style="text-align: center;">เพิ่ม</b-button>
                </div>
              </div>
            </div>
          </template> -->
          <template v-slot:body>
            <div ref="scrollContainer" class="ml-3 response-tab-header-wd-opd" style="gap: 15px;">
              <div @click="AddDayToTab('prev')" style="display: flex; align-items: center; height: 50px; background-color: white;"> <!-- :class="{ 'hidden': !shouldHideScrollButton }" position: fixed; -->
                <b class=" unselectable next-day-btn-opd" style="white-space: nowrap; cursor: pointer; line-height: 20px; font-size: 2.5rem;">&lt;</b>
              </div>
              <div style="display: flex; gap: 5px;">
                <div v-for="(item, index) in TabDays" style="cursor: pointer;" :key="index" :class="ToDay === item.display ? 'button-tab-wd-opd-today' : item.isOld ? 'button-tab-wd-opd-old' : 'button-tab-wd-opd'" @click="activeTab = item.display, GetWithdrawOPDByDay(item)">
                  <u v-if="activeTab === item.display" variant="primary">
                    <b class=" unselectable" style="white-space: nowrap; cursor: pointer; line-height: 20px;" :style="{ color: activeTab === item.display ? 'rgb(8, 159, 175)' : 'black' }">{{ item.display }}</b>
                  </u>
                  <b v-else class=" unselectable" style="white-space: nowrap; cursor: pointer; line-height: 20px;" :style="{ color: activeTab === item.display ? 'rgb(8, 159, 175)' : 'black' }">{{ item.display }}</b>
                </div>
                <!-- <div style="display: flex; align-items: center; margin-left: 1rem; height: 50px;" @click="AddDayToTab">
                  <b class=" unselectable next-day-btn-opd" style="white-space: nowrap; cursor: pointer; line-height: 20px; font-size: 2.5rem;">></b>
                </div> -->
              </div>
              <div @click="AddDayToTab('next')" style="display: flex; align-items: center; height: 50px; background-color: white;"> <!-- :class="{ 'hidden': !shouldHideScrollButton }" position: fixed; -->
                <b class=" unselectable next-day-btn-opd" style="white-space: nowrap; cursor: pointer; line-height: 20px; font-size: 2.5rem;">></b>
              </div>
            </div>

            <hr>
            <br>
            <div class="mx-3">
              <b-row>
                <b-col sm="12" md="4" xl="4" class="mb-3">
                  <div class="iq-email-search d-flex">
                    <div style="width: 100%;">
                      <div class="form-group mb-0">
                        <input v-model="search" @keydown="enterSearch" class="form-control" id="exampleInputEmail1" placeholder="Search" style="width: 100%;">
                        <a class="search-link" @click="getPatientData"><i class="ri-search-line" /></a>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                  <span class="mx-2 font-size-14 text-primary">
                    {{ PatientData?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) > PatientData?.length) ? PatientData?.length : (perPage * currentPage) ) + ' of ' + AllDataLength : '0 of ' + AllDataLength }} รายการ
                  </span>
                </b-col>
                <b-col cols="auto">
                  <b-button :disabled="PatientData.length === 0" type="button" variant="primary-custom" style="height: 40px;" class="w-100 btn-min-size" @click="SetDataInModal"><!-- <i class="add-cus_ct"/> -->ตรวจทานสินค้าก่อนเบิก</b-button>
                </b-col>
              </b-row>
              </div>
              <br>
              <b-col md="12" class="table-responsive">
                <b-table bordered hover :items="PatientData" :fields="columns"
                  :current-page="currentPage"
                  :per-page="perPage"
                  @pagination="onPagination"
                  :tbody-tr-class="rowClass" thead-class="table-header-color"
                >
                <template v-slot:cell(time_in)="data">
                  <!-- <span style="white-space: pre-line;">{{ data.item.time_in || '-' }}</span> -->
                  <span v-for="(item1, index) in data.item.time_in" :key="index">
                    <br v-if="index !== 0">
                    {{ item1 }}
                  </span>
                </template>
                <template v-slot:cell(HN_no)="data">
                  <span>{{ data.item.HN_no }}</span>
                </template>
                <template v-slot:cell(fullname)="data">
                  <span>{{ data.item.fullname }}</span>
                </template>
                <template v-slot:cell(gender)="data">
                  <span>{{ data.item.gender }}</span>
                </template>
                <template v-slot:cell(tel)="data">
                  <span>{{ formatTel(data.item.tel) }}</span>
                </template>
                <!-- <template v-slot:cell(birthday)="data">
                  <span>{{ Formatbirthday(data.item.birthday) }}</span>
                </template> -->
                <template v-slot:cell(img_path)="data">
                  <b-img :src="getImagePath(data.item.img_path)" style="height: auto; width:100px;"></b-img>
                </template>
                <template v-slot:cell(nickname)="data">
                  <span>{{ data.item.nickname }}</span>
                </template>
                <template v-slot:cell(entry_date)="data">
                  <span>{{ data.item.entry_date }}</span>
                </template>
                <template v-slot:cell(history_service)="data">
                  <div style="max-height: 120px; overflow: auto;">
                    <span v-for="(item1, index) in data.item.history_service" :key="index">
                      <br v-if="index !== 0">
                      {{ item1 }}
                    </span>
                  </div>
                </template>
                <template v-slot:cell(management)="data">
                  <div style="display: flex; justify-content: center;">
                    <b-button variant=" iq-bg-primary ml-1" size="sm" @click="handleClick('viewHistory', data.item)"><i class="ri-database-2-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-info ml-1" size="sm" @click="handleClick('view', data.item)"><i class="ri-eye-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-success mx-1" size="sm" id="show-modal" @click="handleClick('edit', data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <!-- <b-button variant=" iq-bg-success mx-1" size="sm" @click="submit(data.item)" v-else>Ok</b-button> -->
                    <!-- <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button> -->
                  </div>
                </template>
              </b-table>
              <br>
            </b-col>
            <b-pagination
                v-model="currentPage"
                :total-rows="PatientData?.length"
                :per-page="perPage"
                align="center"
            />
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <!-- Modal -->
    <b-modal v-model="showModal" title="รายการบริการ" cancel-title="ยกเลิก" ok-title="เพิ่ม"
      @close="onCloseModal" size="md" :no-close-on-esc="true"
      :no-close-on-backdrop="true"
    >
      <template>
        <b-row>
          <b-col col="9">
            <label><b>ชื่อบริการ</b></label>
          </b-col>
          <b-col col="3">
            <label><b>จำนวน</b></label>
          </b-col>
        </b-row>
        <hr class="m-0 p-0 pb-3">
        <b-row v-for="(item, index) in ModalData" :key="index">
          <b-col col="9">
            <label>{{ item.ser_name }}</label>
          </b-col>
          <b-col col="3">
            <label>{{ item.ser_count}} ครั้ง</label>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
        <!-- <b-button style="border-radius: 0.5rem; padding: 0 1.1rem; !important" variant="primary"
            class="btn-min-size" size="sm" @click="onSubmitModal">
            <i class="save-data_ct" />บันทึก
        </b-button> -->
        <b-button variant="danger" class="btn-min-size btn-cancel-border" style="border-radius: 0.5rem;"
            size="sm" @click="onCloseModal">
            ยกเลิก
        </b-button>
        </div>
      </template>
    </b-modal>
  </b-container>
</template>

<style>
.button-tab-wd-opd {
  height: 50px;
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: rgb(223, 223, 223); */
  border-radius: 15px;
}

.button-tab-wd-opd-today {
  height: 50px;
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(250, 248, 136);
  border-radius: 15px;
}

.button-tab-wd-opd-old {
  height: 50px;
  min-width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(198, 198, 198);
  border-radius: 15px;
}

.multiselect__option--highlight {    background: #089bab !important;
    outline: none;
    color: #fff;
}
.padding-patient-header {
  padding-right: 1.3rem !important;
}

.response-tab-header-wd-opd {
  display: flex;
  justify-content: space-between;
  margin: auto auto;
  overflow: auto;
  padding-bottom: 10px;
}
.next-day-btn-opd {
  color: black;
}
.next-day-btn-opd:hover {
  color: #040f8e;
  transition: 0.2s ease-in;
}
.hidden {
  display: none !important;
}
/* @media (max-width: 614px) {
  .col-sm-5 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .col-sm-7 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
} */
@media (max-width: 1025px) {
  .response-tab-header-wd-opd {
    display: flex;
    justify-content: start;
    overflow: auto;
    padding-bottom: 10px;
  }
}
@media (max-width: 520px) {
  .padding-patient-header {
    padding-right: 1rem;
  }
}
@media (max-width: 420px) {
  .res-flex-header {
    display: flex;
    flex-direction: column;
  }
  .col-6-to-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import Axios from 'axios'
import moment from 'moment'
import { configAPI } from '../../configBase'

export default {
  name: 'WithdrawOPD',
  data () {
    return {
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      search: '',
      activeTab: '',
      showModal: false,
      shouldHideScrollButton: false,
      ToDay: moment().locale('th').format('DD / MMM / YYYY'),
      TabDays: [],
      ModalData: [],
      columns: [
        { label: 'เวลาเข้ารับบริการ', key: 'time_in', class: 'text-center table-size-200-400' },
        { label: 'HN', key: 'HN_no', class: 'text-center table-size-200-400' },
        { label: 'รูป', key: 'img_path', class: 'text-center table-size-200-400' },
        { label: 'ชื่อ - นามสกุล', key: 'fullname', class: 'text-center table-size-200-400' },
        { label: 'ชื่อเล่น', key: 'nickname', class: 'text-center table-size-200-400' },
        { label: 'เพศ', key: 'gender', class: 'text-center table-size-200-400' },
        // { label: 'วันเดือนปีเกิด', key: 'birthday', class: 'text-center table-size-200-400' },
        { label: 'เบอร์ติดต่อ', key: 'tel', class: 'text-center table-size-200-400' },
        { label: 'วันที่เข้าใช้ครั้งแรก', key: 'entry_date', class: 'text-center table-size-200-400' },
        { label: 'ประวัติลูกค้าล่าสุด', key: 'history_service', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'management', class: 'text-center' }
        /* { label: 'HN', key: 'HN_no', class: 'text-center table-size-200-400' },
        { label: 'ชื่อ - นามสกุล', key: 'fullname', class: 'text-center table-size-200-400' },
        { label: 'เพศ', key: 'gender', class: 'text-center table-size-200-400' },
        { label: 'เบอร์ติดต่อ', key: 'tel', class: 'text-center table-size-200-400' },
        { label: 'วันเดือนปีเกิด', key: 'birthday', class: 'text-center table-size-200-400' },
        { label: 'รูป', key: 'img_path', class: 'text-center table-size-200-400' },
        { label: 'ชื่อเล่น', key: 'nickname', class: 'text-center table-size-200-400' },
        { label: 'วันที่เข้าใช้ครั้งแรก', key: 'entry_date', class: 'text-center table-size-200-400' },
        { label: 'ประวัติลูกค้าล่าสุด', key: 'history_service', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'management', class: 'text-center' } */
      ],
      PatientData: [],
      currentPage: 1,
      perPage: 10,
      AllDataLength: 0
    }
  },
  async mounted () {
    const scrollContainer = this.$refs.scrollContainer
    scrollContainer.addEventListener('scroll', this.checkScrollPosition)
    this.onLoading()
    this.CreateTabHeader()
    await this.getPatientData()
    Vue.swal.close()
    xray.index()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getPatientData()
      }
    },
    onBackStartTab () {
      const scrollContainer = this.$refs.scrollContainer
      scrollContainer.scrollTo({ left: 0, behavior: 'smooth' })
    },
    checkScrollPosition () {
      const scrollContainer = this.$refs.scrollContainer
      const scrollPercentage = (scrollContainer.scrollLeft / (scrollContainer.scrollWidth - scrollContainer.clientWidth)) * 100

      this.shouldHideScrollButton = scrollPercentage > 10
    },
    SetDataInModal () {
      const arrTemp = []
      for (let item of this.PatientData) {
        // console.log('item', item)
        for (let item2 of item.service_list) {
          // console.log('item2', item2)
          const _index = arrTemp.findIndex(el => el.ser_name === item2)
          if (_index >= 0) {
            arrTemp[_index].ser_count += 1
          } else {
            arrTemp.push({
              ser_name: item2,
              ser_count: 1
            })
          }
        }
      }
      this.ModalData = arrTemp
      this.showModal = true
    },
    onCloseModal () {
      this.showModal = false
    },
    onLoading () {
      Vue.swal.fire({
        title: '<span class="loading-icon"><i class="fas fa-spinner fa-spin"></i></span>',
        text: 'กำลังดึงข้อมูล...',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          Vue.swal.getHtmlContainer().querySelector('.loading-icon').style.fontSize = '40px'
        }
      })
    },
    async GetWithdrawOPDByDay (tab) {
      this.PatientData = []
      this.onLoading()
      await this.getPatientData()
      Vue.swal.close()
    },
    AddDayToTab (mode) {
      const dateLast = this.TabDays[mode === 'prev' ? 0 : this.TabDays.length - 1].date
      for (let i = 0; i < 7; i++) {
        const date = new Date(dateLast[mode === 'prev' ? 'subtract' : 'add'](1, 'd'))
        this.TabDays[mode === 'prev' ? 'unshift' : 'push']({
          display: `${dateLast.format('DD / MMM / YYYY')}`,
          date: moment(date).startOf('D'),
          isOld: moment(this.ToDay, 'DD / MMM / YYYY').diff(date) > 0
        })
      }
    },
    CreateTabHeader () {
      const nowDay = moment().locale('th').subtract(3, 'd')
      this.activeTab = moment().locale('th').format('DD / MMM / YYYY')
      for (let i = 0; i < 12; i++) {
        const date = new Date(nowDay.add(1, 'd'))
        this.TabDays.push({
          display: `${nowDay.format('DD / MMM / YYYY')}`,
          date: moment(date).startOf('D'),
          isOld: moment(this.ToDay, 'DD / MMM / YYYY').diff(date) > 0
        })
      }
      // console.log('this.TabDays', this.TabDays)
    },
    async getPatientData () {
      let dataSearch = this.search.trim()
      const date = this.TabDays.find(el => el.display === this.activeTab)?.date
      await Axios.request({
        method: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/patient/withdrawOPD?search=${dataSearch}&&date=${new Date(date)}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        let temp = []
        response.data.data.map((item) => {
          let temp2 = {
            time_in: item.date_list?.map(el => moment(el).format('HH:mm'))/* .join('<br/>') */,
            id: item.id,
            HN_no: item.hn_no ? item.hn_no : '-',
            fullname: (item.firstname ? item.firstname : '-') + ' ' + (item.lastname ? item.lastname : '-'),
            gender: item.gender ? item.gender : '-',
            tel: item.phone ? item.phone : '-',
            birthday: item.birthday ? item.birthday : null,
            img_path: item.img_path ? item.img_path : null,
            nickname: item.nickname ? item.nickname : '-',
            entry_date: moment(item.created_date).format('DD/MM/YYYY'),
            history_service: item.history_customer,
            service_list: item.service_list
          }
          temp.push(temp2)
        })
        this.PatientData = temp
        this.AllDataLength = response.data.count
      }).catch((error) => {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่พบข้อมูล</span>',
          html: `${error.response.data.message}`,
          icon: 'warning',
          showConfirmButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab'
        })
      })
    },
    remove (item) {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะลบข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Axios.request({
            method: 'PUT',
            url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/patient/${item.id}/delete`,
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(async () => {
              await this.getAllPatientData()
              await this.getPatientData()
            })
          }).catch((error) => {
            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่สามารถลบข้อมูลได้</span>',
              html: `${error.response.data.message}`,
              icon: 'warning',
              showConfirmButton: true,
              confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
              confirmButtonColor: '#089bab'
            })
          })
        }
      })
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick (e, data) {
      const setPage = e
      // this.updateDataAsync()
      if (setPage === 'add') {
        router.push(`/Patient/${setPage}`)
      } else if (setPage === 'viewHistory') {
        router.push(`/HistoryService/view/${data.id}`)
      } else if (setPage === 'service') {
        router.push(`/ServiceUsage/Patient/add`)
      } else {
        router.push(`/Patient/${setPage}/${data.id}`)
      }
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    getImagePath (relativePath) {
      if (relativePath) {
        let path = relativePath !== null ? relativePath.slice(1) : ''
        const baseURL = configAPI.MA_PORT + path
        return baseURL
      } else {
        return 'https://www.w3schools.com/howto/img_avatar.png'
      }
    },
    Formatbirthday (date) {
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      } else {
        return '-'
      }
    },
    formatTel (tel) {
      if (tel) {
        let temp = tel.split('')
        let temp2 = ''
        temp.map((item, index) => {
          if (index === 2 || index === 5) {
            temp2 += item + '-'
          } else {
            temp2 += item
          }
        })
        return temp2
      } else {
        return '-'
      }
    }
  }
}
</script>
