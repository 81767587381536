import Vue from 'vue'
import VueRouter from 'vue-router'

/* Login */
import Login from '../views/Dashboards/Login'
import ConfirmMailPostJob from '../views/Dashboards/ConfirmMailPostJob.vue'
/* Layouts */
import Layout1 from '../layouts/Layout1'
import Layout2 from '../layouts/Layout2'
import Default from '../layouts/BlankLayout'
import AuthLayout1 from '../layouts/AuthLayouts/AuthLayout1'

/* Dashboards View */
import Dashboard2 from '../views/Dashboards/Dashboard2.vue'
import Dashboard1 from '../views/Dashboards/Dashboard1.vue'
import Dashboard3 from '../views/Dashboards/Dashboard3'
import Dashboard4 from '../views/Dashboards/Dashboard4'
import Dashboard5 from '../views/Dashboards/Dashboard5'

// Profile
import Profile from '../views/Dashboards/Profile'

// Module Clinic Master Data
import Product from '../views/Dashboards/Product'
import AddProduct from '../views/Dashboards/AddProduct'
import Service from '../views/Dashboards/Service'
import Material from '../views/Dashboards/Material'
import AddMaterial from '../views/Dashboards/AddMaterial'
import Supplier from '../views/Dashboards/Supplier'
import AddSupplierDetail from '../views/Dashboards/AddSupplierDetail'
import SupplierDetail from '../views/Dashboards/SupplierDetail'
import CaseTrack from '../views/Dashboards/CaseTrack'
import CaseTrackUpdate from '../views/Dashboards/CaseTrackUpdate'
import FollowUp from '../views/Dashboards/FollowUp'
import FollowUpCase from '../views/Dashboards/FollowUpCase'
import FollowUpUpdate from '../views/Dashboards/FollowUpUpdate'
import Setting from '../views/Dashboards/Setting'
import Cumulative from '../views/Dashboards/Cumulative'
// import AddCumulative from '../views/Dashboards/AddCumulative'
import Upload from '../views/Dashboards/Upload'
import User from '../views/Dashboards/User'
import AddUsers from '../views/Dashboards/AddUsers'
import AddUsersCopy from '../views/Dashboards/AddUsersCopy'
import Permission from '../views/Dashboards/Permission'
import Attendance from '../views/Dashboards/Attendance'
import AddAttendance from '../views/Dashboards/AddAttendance'
import ClinicCalendar from '../views/Dashboards/ClinicCalendar'

// ข้อมูลลูกค้า (CRM)
import Appointment from '../views/Dashboards/Appointment'
import Register from '../views/Dashboards/Register'
import Patient from '../views/Dashboards/Patient'
import ServiceData from '../views/Dashboards/ServiceData'
import AddUseService from '../views/Dashboards/AddUseService'
import ServiceUsage from '../views/Dashboards/ServiceUsage'
import ServiceUsageHistory from '../views/Dashboards/ServiceUsageHistory'
import HistoryService from '../views/Dashboards/HistoryService'
import HistoryServiceByCus from '../views/Dashboards/HistoryServiceByCus'
import Comment from '../views/Dashboards/Comment'
import CommentDetail from '../views/Dashboards/CommentDetail.vue'
import DashboardsCus from '../views/Dashboards/DashboardsCus.vue'

// ข้อมูลบุคลากร
import Employee from '../views/Dashboards/Employee'
import AddEmployee from '../views/Dashboards/AddEmployee'
import Salary from '../views/Dashboards/Salary'
import MonthCost from '../views/Dashboards/MonthCost'
import AddMonthCost from '../views/Dashboards/AddMonthCost'
import KPI from '../views/Dashboards/KPI'
import Absence from '../views/Dashboards/Absence'
import AbsenceTableMaster from '../views/Dashboards/AbsenceTableMaster'
import PartTime from '../views/Dashboards/PartTime'
import ViewEmployDoctor from '../views/Dashboards/ViewEmployDoctor'
import RecruitDoctor from '../views/Dashboards/RecruitDoctor'
import PostJob from '../views/Dashboards/PostJob'
import PostJobTableMaster from '../views/Dashboards/PostJobTableMaster'
import Accept from '../views/Dashboards/Accept'
import PerformanceHistory from '../views/Dashboards/PerformanceHistory'
import SalesData from '../views/Dashboards/SalesData'
import CommissionData from '../views/Dashboards/CommissionData'
import SaleOrderTable from '../views/Dashboards/SaleOrderTable'

// ส่วนผู้ดูแล Smart Clinic
// ภาพรวม Smart Clinic
import Manage from '../views/Dashboards/Manage'
import AddManage from '../views/Dashboards/AddManage'
import AddUserManage from '../views/Dashboards/AddUserManage'
import Credit from '../views/Dashboards/Credit'
import UploadSystem from '../views/Dashboards/UploadSystem'
import Approve from '../views/Dashboards/Approve'
import OpenLinkDoctor from '../views/Dashboards/OpenLinkDoctor'

// ข้อมูลระบบ Smart Clinic
import ProductGroup from '../views/Dashboards/ProductGroup'
import AddProductGroup from '../views/Dashboards/AddProductGroup'
import ServiceGroup from '../views/Dashboards/ServiceGroup'
import AddServiceGroup from '../views/Dashboards/AddServiceGroup'
import CaseTrackMaster from '../views/Dashboards/CaseTrackMaster'
import CaseTrackMasterUpdate from '../views/Dashboards/CaseTrackMasterUpdate'
import MaterialGroup from '../views/Dashboards/MaterialGroup'
import AddMaterialGroup from '../views/Dashboards/AddMaterialGroup'
import CostGroup from '../views/Dashboards/CostGroup'
import EmployeeGroup from '../views/Dashboards/EmployeeGroup'
import ManageLevel from '../views/Dashboards/ManageLevel'
import PermissionEmpGroup from '../views/Dashboards/PermissionEmpGroup'

// ระบบบริหารจัดการขาย
import PatientsInCare from '../views/Dashboards/PatientsInCare'
import PatientsInCareAdmin from '../views/Dashboards/PatientsInCareAdmin'
import QuotationTable from '../views/Dashboards/QuotationTable'
import QuotationMGT from '../views/Dashboards/QuotationMGT'
import QuotationApprove from '../views/Dashboards/QuotationApprove'
import PurchaseRequisitionTable from '../views/Dashboards/PurchaseRequisitionTable'
import PurchaseRequisitionMGT from '../views/Dashboards/PurchaseRequisitionMGT'
import PurchaseRequisitionApprove from '../views/Dashboards/PurchaseRequisitionApprove'
import SaleOrderApproveEdit from '../views/Dashboards/SaleOrderApproveEdit'
import PurchaseOrderTable from '../views/Dashboards/PurchaseOrderTable'
import PurchaseOrderMGT from '../views/Dashboards/PurchaseOrderMGT'
import PurchaseOrderApprove from '../views/Dashboards/PurchaseOrderApprove'

// คลังสินค้าคลินิก
import ReceiveByPO from '../views/Dashboards/ReceiveByPO'
import ReceiveByPOMGT from '../views/Dashboards/ReceiveByPOMGT'
import productWarehouse from '../views/Dashboards/productWarehouse'
import rawMaterialWarehouse from '../views/Dashboards/rawMaterialWarehouse'
import productWarehouseMgt from '../views/Dashboards/productWarehouseMgt'
import rawMaterialWarehouseMgt from '../views/Dashboards/rawMaterialWarehouseMgt'
import generalWarehouse from '../views/Dashboards/generalWarehouse'
import generalWarehouseMgt from '../views/Dashboards/generalWarehouseMgt'
import WithdrawOPD from '../views/Dashboards/WithdrawOPD'

/* UIElements View */
import UiAlerts from '../views/core/UiAlerts.vue'
import UiButtons from '../views/core/UiButtons.vue'
import UiBadges from '../views/core/UiBadges.vue'
import UiBreadcrumb from '../views/core/UiBreadcrumb.vue'
import UiColors from '../views/core/UiColors.vue'
import UiTypography from '../views/core/UiTypography.vue'
import UiCards from '../views/core/UiCards.vue'
import UiCarousel from '../views/core/UiCarousel.vue'
import UiEmbedVideo from '../views/core/UiEmbedVideo.vue'
import UiGrid from '../views/core/UiGrid.vue'
import UiModal from '../views/core/UiModal.vue'
import UiListGroup from '../views/core/UiListGroup.vue'
import UiImages from '../views/core/UiImages.vue'
import UiMediaObject from '../views/core/UiMediaObject.vue'
import UiTooltips from '../views/core/UiTooltips.vue'
import UiPopovers from '../views/core/UiPopovers.vue'
import UiNotifications from '../views/core/UiNotifications.vue'
import UiTabs from '../views/core/UiTabs.vue'
import UiPagination from '../views/core/UiPagination.vue'
import UiProgressBars from '../views/core/UiProgressBars.vue'

/* Authentic View */
import SignIn1 from '../views/AuthPages/Default/SignIn1'
import SignUp1 from '../views/AuthPages/Default/SignUp1'
import RecoverPassword1 from '../views/AuthPages/Default/RecoverPassword1'
import LockScreen1 from '../views/AuthPages/Default/LockScreen1'
import ConfirmMail1 from '../views/AuthPages/Default/ConfirmMail1'

/* Extra Pages */
import ErrorPage from '../views/Pages/ErrorPage'
import ComingSoon from '../views/Pages/ComingSoon'
import Maintenance from '../views/Pages/Maintenance'
import TimeLine from '../views/Pages/TimeLines'
import Pricing from '../views/Pages/Pricing'
import Pricing1 from '../views/Pages/Pricing1'
import BlankPage from '../views/Pages/BlankPage'
import FAQ from '../views/Pages/FAQ'
import Invoice from '../views/Pages/Invoice'

/* Apps Views */
import EmailListing from '../views/Apps/Email/EmailListing'
import EmailCompose from '../views/Apps/Email/EmailCompose'
import Calendar from '../views/Apps/Calendar/Calendar'
import ChatIndex from '../views/Apps/Chat/Index'

/* Icon Views */
import IconDripicons from '../views/Icons/IconDripicons'
import IconFontawesome5 from '../views/Icons/IconFontawesome5'
import IconLineAwesome from '../views/Icons/IconLineAwesome'
import IconRemixicon from '../views/Icons/IconRemixicon'
import IconUnicons from '../views/Icons/IconUnicons'

/* Tables Views */
import TablesBasic from '../views/Tables/TablesBasic'
import DataTable from '../views/Tables/DataTable'
import EditableTable from '../views/Tables/EditableTable'
import ApexCharts from '../views/Charts/ApexCharts'
import AmCharts from '../views/Charts/AmCharts'
import MorrisCharts from '../views/Charts/MorrisCharts'
import HighCharts from '../views/Charts/HighCharts'
import GoogleMaps from '../views/Maps/GoogleMaps'
import VectorMaps from '../views/Maps/VectorMaps'

/* Form View */
import FormLayout from '../views/Forms/FormLayout'
import FormValidates from '../views/Forms/FormValidates'
import FormSwitches from '../views/Forms/FormSwitches'
import FormRadios from '../views/Forms/FormRadios'
import FormCheckboxes from '../views/Forms/FormCheckboxes'

/* User View */
// import Profile from '../views/User/Profile'
import ProfileEdit from '../views/User/ProfileEdit'
import AddUser from '../views/User/AddUser'
import UserList from '../views/User/UserList'

/* Todo */
import Callback from '../views/AuthPages/Default/Callback'

/* Plugins Views */
import DatepickerDemo from '../views/Plugins/DatepickerDemo'
import SelectDemo from '../views/Plugins/SelectDemo'
import DragDropDemo from '../views/Plugins/DragDropDemo'
import AppTreeView from '../views/Plugins/AppTreeView'

Vue.use(VueRouter)

const ifAuthenticated = (to, from, next) => {
  if (sessionStorage.getItem('token')) {
    next()
    return
  }
  router.push({ name: 'login' })
}

const childRoutes = (prop, mode) => [
  {
    path: 'home-1',
    name: prop + '.home-1',
    meta: { dark: mode, auth: true, name: 'Home 1' },
    component: Dashboard1
  },
  {
    path: 'home-2',
    name: prop + '.home-2',
    meta: { dark: mode, auth: true, name: 'Home 2' },
    component: Dashboard2
  },
  {
    path: 'home-3',
    name: prop + '.home-3',
    meta: { dark: mode, auth: true, name: 'Home 3' },
    component: Dashboard3
  },
  {
    path: 'home-5',
    name: prop + '.home-5',
    meta: { dark: mode, auth: true, name: 'Home 5' },
    component: Dashboard5
  },
  {
    path: '/',
    name: prop + '.home-4',
    meta: { dark: mode, auth: true, name: 'Home 4' },
    component: Dashboard4
  },
  {
    path: 'Profile',
    name: prop + '.Profile',
    meta: { dark: mode, auth: true, name: 'Profile' },
    component: Profile
  },
  {
    path: 'Product',
    name: prop + '.Product',
    meta: { dark: mode, auth: true, name: 'Product' },
    component: Product
  },
  {
    path: 'AddProduct/:setPage',
    name: prop + '.Product',
    meta: { dark: mode, auth: true, name: 'Product' },
    component: AddProduct
  },
  {
    path: 'AddProduct/:setPage/:id',
    name: prop + '.Product',
    meta: { dark: mode, auth: true, name: 'Product' },
    component: AddProduct
  },
  {
    path: 'Service',
    name: prop + '.Service',
    meta: { dark: mode, auth: true, name: 'Service' },
    component: Service
  },
  {
    path: 'Material',
    name: prop + '.Material',
    meta: { dark: mode, auth: true, name: 'Material' },
    component: Material
  },
  {
    path: 'AddMaterial/:setPage',
    name: prop + '.Material',
    meta: { dark: mode, auth: true, name: 'Material' },
    component: AddMaterial
  },
  {
    path: 'AddMaterial/:setPage/:id',
    name: prop + '.Material',
    meta: { dark: mode, auth: true, name: 'Material' },
    component: AddMaterial
  },
  {
    path: 'Supplier',
    name: prop + '.Supplier',
    meta: { dark: mode, auth: true, name: 'Supplier' },
    component: Supplier
  },
  {
    path: 'AddSupplierDetail/:idSup',
    name: prop + '.Supplier',
    meta: { dark: mode, auth: true, name: 'Supplier' },
    component: AddSupplierDetail
  },
  {
    path: 'SupplierDetailEV/:setPage/:id/:idSup',
    name: prop + '.Supplier',
    meta: { dark: mode, auth: true, name: 'Supplier' },
    component: AddSupplierDetail
  },
  {
    path: 'SupplierDetail/:setPage/:id',
    name: prop + '.Supplier',
    meta: { dark: mode, auth: true, name: 'Supplier' },
    component: SupplierDetail
  },
  {
    path: 'SupplierDetail',
    name: prop + '.Supplier',
    meta: { dark: mode, auth: true, name: 'Supplier' },
    component: SupplierDetail
  },
  {
    path: 'CaseTrack',
    name: prop + '.CaseTrack',
    meta: { dark: mode, auth: true, name: 'CaseTrack' },
    component: CaseTrack
  },
  {
    path: 'CaseTrackUpdate/:setPage/:id',
    name: prop + '.CaseTrack',
    meta: { dark: mode, auth: true, name: 'CaseTrack' },
    component: CaseTrackUpdate
  },
  {
    path: 'FollowUp',
    name: prop + '.FollowUp',
    meta: { dark: mode, auth: true, name: 'FollowUp' },
    component: FollowUp
  },
  {
    path: 'FollowUpCase/:setPage/:id',
    name: prop + '.FollowUp',
    meta: { dark: mode, auth: true, name: 'FollowUp' },
    component: FollowUpCase
  },
  {
    path: 'FollowUpUpdate/:setPage/:id',
    name: prop + '.FollowUp',
    meta: { dark: mode, auth: true, name: 'FollowUp' },
    component: FollowUpUpdate
  },
  {
    path: 'Setting',
    name: prop + '.Setting',
    meta: { dark: mode, auth: true, name: 'Setting' },
    component: Setting
  },
  {
    path: 'Cumulative',
    name: prop + '.Cumulative',
    meta: { dark: mode, auth: true, name: 'Cumulative' },
    component: Cumulative
  },
  {
    path: 'AddCumulative',
    name: prop + '.Cumulative',
    meta: { dark: mode, auth: true, name: 'Cumulative' },
    component: AddSupplierDetail
  },
  {
    path: 'CumulativeEV/:setPage/:id',
    name: prop + '.Cumulative',
    meta: { dark: mode, auth: true, name: 'Supplier' },
    component: AddSupplierDetail
  },
  {
    path: 'Upload',
    name: prop + '.Upload',
    meta: { dark: mode, auth: true, name: 'Upload' },
    component: Upload
  },
  {
    path: 'Upload/:setPage',
    name: prop + '.Upload',
    meta: { dark: mode, auth: true, name: 'Upload' },
    component: Upload
  },
  {
    path: 'Employee/:mode?',
    name: prop + '.Employee',
    meta: { dark: mode, auth: true, name: 'Employee' },
    component: Employee
  },
  {
    path: 'AddEmployee/:from?/:setPage?/:id?',
    name: prop + '.Employee',
    meta: { dark: mode, auth: true, name: 'Employee' },
    component: AddEmployee
  },
  {
    path: 'Permission/:id?',
    name: prop + '.Permission',
    meta: { dark: mode, auth: true, name: 'Permission' },
    component: Permission
  },
  {
    path: 'Attendance',
    name: prop + '.Attendance',
    meta: { dark: mode, auth: true, name: 'Attendance' },
    component: Attendance
  },
  {
    path: 'AddAttendance',
    name: prop + '.Attendance',
    meta: { dark: mode, auth: true, name: 'Attendance' },
    component: AddAttendance
  },
  {
    path: 'AddAttendance/:setPage/:id',
    name: prop + '.Attendance',
    meta: { dark: mode, auth: true, name: 'Attendance' },
    component: AddAttendance
  },
  {
    path: 'ClinicCalendar',
    name: prop + '.ClinicCalendar',
    meta: { dark: mode, auth: true, name: 'ClinicCalendar' },
    component: ClinicCalendar
  },
  {
    path: 'Appointment',
    name: prop + '.Appointment',
    meta: { dark: mode, auth: true, name: 'Appointment' },
    component: Appointment
  },
  {
    path: 'ServiceData',
    name: prop + '.ServiceData',
    meta: { dark: mode, auth: true, name: 'ServiceData' },
    component: ServiceData
  },
  {
    path: 'Patient',
    name: prop + '.Register',
    meta: { dark: mode, auth: true, name: 'Register' },
    component: Patient
  },
  {
    path: 'Patient/:setPage',
    name: prop + '.Register',
    meta: { dark: mode, auth: true, name: 'Register' },
    component: Register
  },
  {
    path: 'Patient/:setPage/:id',
    name: prop + '.Register',
    meta: { dark: mode, auth: true, name: 'Register' },
    component: Register
  },
  {
    path: 'HistoryService',
    name: prop + '.History',
    meta: { dark: mode, auth: true, name: 'History' },
    component: HistoryService
  },
  {
    path: 'HistoryService/:setPage/:id?/:from?',
    name: prop + '.History',
    meta: { dark: mode, auth: true, name: 'History' },
    component: HistoryServiceByCus
  },
  {
    path: 'ServiceUsage/:from?/History/:setPage?/:id?',
    name: prop + '.Register',
    meta: { dark: mode, auth: true, name: 'Register' },
    component: ServiceUsageHistory
  },
  /* {
    path: 'ServiceUsage/Service/History/:setPage?/:id?',
    name: prop + '.History',
    meta: { dark: mode, auth: true, name: 'History' },
    component: ServiceUsageHistory
  },
  {
    path: 'ServiceUsage/Services/History/:setPage?/:id?',
    name: prop + '.History',
    meta: { dark: mode, auth: true, name: 'History' },
    component: ServiceUsageHistory
  }, */
  {
    path: 'ServiceUsage/:from?/:setPage?/:id?',
    name: prop + '.Register',
    meta: { dark: mode, auth: true, name: 'Register' },
    component: ServiceUsage
  },
  {
    path: 'comment',
    name: prop + '.comment',
    meta: { dark: mode, auth: true, name: 'Comment' },
    component: Comment
  },
  {
    path: 'commentDetail/:cc_id',
    name: prop + '.commentDetail',
    meta: { dark: mode, auth: true, name: 'Comment' },
    component: CommentDetail
  },
  {
    path: 'DashboardsCus',
    name: prop + '.DashboardsCus',
    meta: { dark: mode, auth: true, name: 'DashboardsCus' },
    component: DashboardsCus
  },
  {
    path: 'User',
    name: prop + '.User',
    meta: { dark: mode, auth: true, name: 'User' },
    component: User
  },
  {
    path: 'AddUsers',
    name: prop + '.User',
    meta: { dark: mode, auth: true, name: 'User' },
    component: AddUsers
  },
  {
    path: 'AddUsersTest',
    name: prop + '.User',
    meta: { dark: mode, auth: true, name: 'User' },
    component: AddUsersCopy
  },
  {
    path: 'ManageUsers/:setPage/:id',
    name: prop + '.User',
    meta: { dark: mode, auth: true, name: 'User' },
    component: AddUsers
  },
  {
    path: 'Salary',
    name: prop + '.Salary',
    meta: { dark: mode, auth: true, name: 'Salary' },
    component: Salary
  },
  {
    path: 'MonthCost',
    name: prop + '.MonthCost',
    meta: { dark: mode, auth: true, name: 'MonthCost' },
    component: MonthCost
  },
  {
    path: 'AddMonthCost/:setPage/:id',
    name: prop + '.MonthCost',
    meta: { dark: mode, auth: true, name: 'MonthCost' },
    component: AddMonthCost
  },
  {
    path: 'KPI',
    name: prop + '.KPI',
    meta: { dark: mode, auth: true, name: 'KPI' },
    component: KPI
  },
  {
    path: 'PerformanceHistory',
    name: prop + '.PerformanceHistory',
    meta: { dark: mode, auth: true, name: 'PerformanceHistory' },
    component: PerformanceHistory
  },
  {
    path: 'Absence',
    name: prop + '.Absence',
    meta: { dark: mode, auth: true, name: 'Absence' },
    component: AbsenceTableMaster
  },
  {
    path: 'Absence/:setPage/:id',
    name: prop + '.Absence',
    meta: { dark: mode, auth: true, name: 'Absence' },
    component: Absence
  },
  {
    path: 'PartTime',
    name: prop + '.PartTime',
    meta: { dark: mode, auth: true, name: 'PartTime' },
    component: PartTime
  },
  {
    path: 'ViewEmployDoctor/:id',
    name: prop + '.PartTime',
    meta: { dark: mode, auth: true, name: 'PartTime' },
    component: ViewEmployDoctor
  },
  {
    path: 'RecruitDoctor/:id',
    name: prop + '.PartTime',
    meta: { dark: mode, auth: true, name: 'PartTime' },
    component: RecruitDoctor
  },
  {
    path: 'PostJob',
    name: prop + '.PostJob',
    meta: { dark: mode, auth: true, name: 'PostJob' },
    component: PostJobTableMaster
  },
  {
    path: 'PostJob/:setPage/:id?',
    name: prop + '.PostJob',
    meta: { dark: mode, auth: true, name: 'PostJob' },
    component: PostJob
  },
  {
    path: 'Accept',
    name: prop + '.Accept',
    meta: { dark: mode, auth: true, name: 'Accept' },
    component: Accept
  },
  {
    path: 'Manage',
    name: prop + '.Manage',
    meta: { dark: mode, auth: true, name: 'Manage' },
    component: Manage
  },
  {
    path: 'AddManage',
    name: prop + '.Manage',
    meta: { dark: mode, auth: true, name: 'Manage' },
    component: AddManage
  },
  {
    path: 'AddManage/:setPage/:id',
    name: prop + '.Manage',
    meta: { dark: mode, auth: true, name: 'Manage' },
    component: AddManage
  },
  {
    path: 'AddUserManage/:clinic_id',
    name: prop + '.Manage',
    meta: { dark: mode, auth: true, name: 'Manage' },
    component: AddUserManage
  },
  {
    path: 'AddUserManage/:setPage/:clinic_id/:id',
    name: prop + '.Manage',
    meta: { dark: mode, auth: true, name: 'Manage' },
    component: AddUserManage
  },
  {
    path: 'Credit',
    name: prop + '.Credit',
    meta: { dark: mode, auth: true, name: 'Credit' },
    component: Credit
  },
  {
    path: 'AddCredit',
    name: prop + '.Credit',
    meta: { dark: mode, auth: true, name: 'Credit' },
    component: UploadSystem
  },
  {
    path: 'Approve',
    name: prop + '.Approve',
    meta: { dark: mode, auth: true, name: 'Approve' },
    component: Approve
  },
  {
    path: 'OpenLinkDoctor/:id?',
    name: prop + '.OpenLinkDoctor',
    meta: { dark: mode, auth: true, name: 'OpenLinkDoctor' },
    component: OpenLinkDoctor
  },
  {
    path: 'ProductGroup',
    name: prop + '.ProductGroup',
    meta: { dark: mode, auth: true, name: 'ProductGroup' },
    component: ProductGroup
  },
  {
    path: 'ProductGroup/:setPage/:id',
    name: prop + '.ProductGroup',
    meta: { dark: mode, auth: true, name: 'ProductGroup' },
    component: AddProductGroup
  },
  {
    path: 'AddProductGroup',
    name: prop + '.ProductGroup',
    meta: { dark: mode, auth: true, name: 'ProductGroup' },
    component: AddProductGroup
  },
  {
    path: 'ServiceGroup',
    name: prop + '.ServiceGroup',
    meta: { dark: mode, auth: true, name: 'ServiceGroup' },
    component: ServiceGroup
  },
  {
    path: 'AddServiceGroup/:setPage/:id?',
    name: prop + '.ServiceGroup',
    meta: { dark: mode, auth: true, name: 'ServiceGroup' },
    component: AddServiceGroup
  },
  // {
  //   path: 'AddServiceGroup/:setPage/:id',
  //   name: prop + '.AddServiceGroup',
  //   meta: { dark: mode, auth: true, name: 'AddServiceGroup' },
  //   component: AddServiceGroup
  // },
  {
    path: 'CaseTrackMaster',
    name: prop + '.CaseTrackMaster',
    meta: { dark: mode, auth: true, name: 'CaseTrackMaster' },
    component: CaseTrackMaster
  },
  {
    path: 'CaseTrackMasterUpdate/:setPage/:id',
    name: prop + '.CaseTrackMaster',
    meta: { dark: mode, auth: true, name: 'CaseTrackMaster' },
    component: CaseTrackMasterUpdate
  },
  {
    path: 'MaterialGroup',
    name: prop + '.MaterialGroup',
    meta: { dark: mode, auth: true, name: 'MaterialGroup' },
    component: MaterialGroup
  },
  {
    path: 'AddMaterialGroup/:setPage',
    name: prop + '.MaterialGroup',
    meta: { dark: mode, auth: true, name: 'MaterialGroup' },
    component: AddMaterialGroup
  },
  {
    path: 'CostGroup',
    name: prop + '.CostGroup',
    meta: { dark: mode, auth: true, name: 'CostGroup' },
    component: CostGroup
  },
  {
    path: 'EmployeeGroup',
    name: prop + '.EmployeeGroup',
    meta: { dark: mode, auth: true, name: 'EmployeeGroup' },
    component: EmployeeGroup
  },
  {
    path: 'EmployeeGroup/Permission/:id?',
    name: prop + '.EmployeeGroup',
    meta: { dark: mode, auth: true, name: 'EmployeeGroup' },
    component: PermissionEmpGroup
  },
  {
    path: 'ManageLevel',
    name: prop + '.ManageLevel',
    meta: { dark: mode, auth: true, name: 'ManageLevel' },
    component: ManageLevel
  },
  {
    path: 'PatientsInCare',
    name: prop + '.PatientsInCare',
    meta: { dark: mode, auth: true, name: 'PatientsInCare' },
    component: PatientsInCare
  },
  {
    path: 'PatientsInCareAdmin',
    name: prop + '.PatientsInCareAdmin',
    meta: { dark: mode, auth: true, name: 'PatientsInCareAdmin' },
    component: PatientsInCareAdmin
  },
  {
    path: 'QuotationTable',
    name: prop + '.QuotationTable',
    meta: { dark: mode, auth: true, name: 'QuotationTable' },
    component: QuotationTable
  },
  {
    path: 'Quotation/mgt/:setPage/:id?',
    name: prop + '.QuotationTable',
    meta: { dark: mode, auth: true, name: 'QuotationTableMGT' },
    component: QuotationMGT
  },
  {
    path: 'Quotation/so/mgt/:setPage/:id?',
    name: prop + '.QuotationTable',
    meta: { dark: mode, auth: true, name: 'QuotationTableMGT' },
    component: QuotationMGT
  },
  {
    path: 'Quotation/soAdmin/mgt/:setPage/:id?',
    name: prop + '.QuotationTable',
    meta: { dark: mode, auth: true, name: 'QuotationTableMGT' },
    component: QuotationMGT
  },
  {
    path: 'QuotationApprove',
    name: prop + '.QuotationApprove',
    meta: { dark: mode, auth: true, name: 'QuotationApprove' },
    component: QuotationApprove
  },
  {
    path: 'SalesData',
    name: prop + '.SalesData',
    meta: { dark: mode, auth: true, name: 'SalesData' },
    component: SalesData
  },
  {
    path: 'CommissionData',
    name: prop + '.CommissionData',
    meta: { dark: mode, auth: true, name: 'CommissionData' },
    component: CommissionData
  },
  {
    path: 'SaleOrderTable',
    name: prop + '.SaleOrderTable',
    meta: { dark: mode, auth: true, name: 'SaleOrderTable' },
    component: SaleOrderTable
  },
  {
    path: 'PurchaseRequisitionTable',
    name: prop + '.PurchaseRequisitionTable',
    meta: { dark: mode, auth: true, name: 'PurchaseRequisitionTable' },
    component: PurchaseRequisitionTable
  },
  {
    path: 'PurchaseRequisitionMGT/:setPage/:id?',
    name: prop + '.PurchaseRequisitionTable',
    meta: { dark: mode, auth: true, name: 'PurchaseRequisitionMGT' },
    component: PurchaseRequisitionMGT
  },
  {
    path: 'PurchaseRequisitionApprove',
    name: prop + '.PurchaseRequisitionApprove',
    meta: { dark: mode, auth: true, name: 'PurchaseRequisitionApprove' },
    component: PurchaseRequisitionApprove
  },
  {
    path: 'SaleOrderApproveEdit',
    name: prop + '.SaleOrderApproveEdit',
    meta: { dark: mode, auth: true, name: 'SaleOrderApproveEdit' },
    component: SaleOrderApproveEdit
  },
  {
    path: 'PurchaseOrderTable',
    name: prop + '.PurchaseOrderTable',
    meta: { dark: mode, auth: true, name: 'PurchaseOrderTable' },
    component: PurchaseOrderTable
  },
  {
    path: 'PurchaseOrder/mgt/:setPage/:id?',
    name: prop + '.PurchaseOrderTable',
    meta: { dark: mode, auth: true, name: 'PurchaseOrderTable' },
    component: PurchaseOrderMGT
  },
  {
    path: 'PurchaseOrderApprove',
    name: prop + '.PurchaseOrderApprove',
    meta: { dark: mode, auth: true, name: 'PurchaseOrderApprove' },
    component: PurchaseOrderApprove
  },
  {
    path: 'ReceiveByPO',
    name: prop + '.ReceiveByPO',
    meta: { dark: mode, auth: true, name: 'ReceiveByPO' },
    component: ReceiveByPO
  },
  {
    path: 'ReceiveByPOMGT/:setPage/:id?',
    name: prop + '.ReceiveByPO',
    meta: { dark: mode, auth: true, name: 'ReceiveByPO' },
    component: ReceiveByPOMGT
  },
  {
    path: 'productWarehouse',
    name: prop + '.productWarehouse',
    meta: { dark: mode, auth: true, name: 'productWarehouse' },
    component: productWarehouse
  },
  {
    path: 'rawMaterialWarehouse',
    name: prop + '.rawMaterialWarehouse',
    meta: { dark: mode, auth: true, name: 'rawMaterialWarehouse' },
    component: rawMaterialWarehouse
  },
  {
    path: 'generalWarehouse',
    name: prop + '.generalWarehouse',
    meta: { dark: mode, auth: true, name: 'generalWarehouse' },
    component: generalWarehouse
  },
  {
    path: 'productWarehouseMgt/:id',
    name: prop + '.productWarehouse',
    meta: { dark: mode, auth: true, name: 'productWarehouseMgt' },
    component: productWarehouseMgt
  },
  {
    path: 'rawMaterialWarehouseMgt/:id',
    name: prop + '.rawMaterialWarehouse',
    meta: { dark: mode, auth: true, name: 'rawMaterialWarehouseMgt' },
    component: rawMaterialWarehouseMgt
  },
  {
    path: 'generalWarehouseMgt/:id',
    name: prop + '.generalWarehouse',
    meta: { dark: mode, auth: true, name: 'generalWarehouseMgt' },
    component: generalWarehouseMgt
  },
  {
    path: 'WithdrawOPD',
    name: prop + '.WithdrawOPD',
    meta: { dark: mode, auth: true, name: 'WithdrawOPD' },
    component: WithdrawOPD
  }
]

const coreChildRoute = (prop, mode) => [
  {
    path: 'ui-colors',
    name: prop + '.color',
    meta: { dark: mode, auth: true, name: 'Colors' },
    component: UiColors
  },
  {
    path: 'ui-typography',
    name: prop + '.typography',
    meta: { dark: mode, auth: true, name: 'Typography' },
    component: UiTypography
  },
  {
    path: 'ui-alerts',
    name: prop + '.alert',
    meta: { dark: mode, auth: true, name: 'Alert' },
    component: UiAlerts
  },
  {
    path: 'ui-buttons',
    name: prop + '.button',
    meta: { dark: mode, auth: true, name: 'Button' },
    component: UiButtons
  },
  {
    path: 'ui-breadcrumb',
    name: prop + '.breadcrumb',
    meta: { dark: mode, auth: true, name: 'Breadcrumb' },
    component: UiBreadcrumb
  },
  {
    path: 'ui-badges',
    name: prop + '.badges',
    meta: { dark: mode, auth: true, name: 'Badges' },
    component: UiBadges
  },
  {
    path: 'ui-cards',
    name: prop + '.cards',
    meta: { dark: mode, auth: true, name: 'Card' },
    component: UiCards
  },
  {
    path: 'ui-carousel',
    name: prop + '.carousel',
    meta: { dark: mode, auth: true, name: 'Carousel' },
    component: UiCarousel
  },
  {
    path: 'ui-grid',
    name: prop + '.grid',
    meta: { dark: mode, auth: true, name: 'Grid' },
    component: UiGrid
  },
  {
    path: 'ui-embed-video',
    name: prop + '.embed-video',
    meta: { dark: mode, auth: true, name: 'Embed Video' },
    component: UiEmbedVideo
  },
  {
    path: 'ui-modal',
    name: prop + '.modal',
    meta: { dark: mode, auth: true, name: 'Model' },
    component: UiModal
  },
  {
    path: 'ui-listgroup',
    name: prop + '.listgroup',
    meta: { dark: mode, auth: true, name: 'List Group' },
    component: UiListGroup
  },
  {
    path: 'ui-images',
    name: prop + '.images',
    meta: { dark: mode, auth: true, name: 'Image' },
    component: UiImages
  },
  {
    path: 'ui-media-object',
    name: prop + '.media-object',
    meta: { dark: mode, auth: true, name: 'Media Object' },
    component: UiMediaObject
  },
  {
    path: 'ui-pagination',
    name: prop + '.pagination',
    meta: { dark: mode, auth: true, name: 'Paginations' },
    component: UiPagination
  },
  {
    path: 'ui-progressbars',
    name: prop + '.progressbars',
    meta: { dark: mode, auth: true, name: 'Progressbar' },
    component: UiProgressBars
  },
  {
    path: 'ui-tooltips',
    name: prop + '.tooltips',
    meta: { dark: mode, auth: true, name: 'Tooltip' },
    component: UiTooltips
  },
  {
    path: 'ui-popovers',
    name: prop + '.popovers',
    meta: { dark: mode, auth: true, name: 'Popover' },
    component: UiPopovers
  },
  {
    path: 'ui-notifications',
    name: prop + '.notifications',
    meta: { dark: mode, auth: true, name: 'Notification' },
    component: UiNotifications
  },
  {
    path: 'ui-tabs',
    name: prop + '.tabs',
    meta: { dark: mode, auth: true, name: 'Tabs' },
    component: UiTabs
  }
]
const formChildRoute = (prop, mode = false) => [
  {
    path: 'form-layout',
    name: prop + '.layout',
    meta: { dark: mode, auth: true, name: 'Layout' },
    component: FormLayout
  },
  {
    path: 'form-validate',
    name: prop + '.validate',
    meta: { dark: mode, auth: true, name: 'Validate' },
    component: FormValidates
  },
  {
    path: 'form-switches',
    name: prop + '.switch',
    meta: { dark: mode, auth: true, name: 'Switch' },
    component: FormSwitches
  },
  {
    path: 'form-radios',
    name: prop + '.radio',
    meta: { dark: mode, auth: true, name: 'Radio' },
    component: FormRadios
  },
  {
    path: 'form-checkboxes',
    name: prop + '.checkbox',
    meta: { dark: mode, auth: true, name: 'Checkbox' },
    component: FormCheckboxes
  }
]

const tableChildRoute = (prop, mode = false) => [
  {
    path: 'tables-basic',
    name: prop + '.basic',
    meta: { dark: mode, auth: true, name: 'Basic' },
    component: TablesBasic
  },
  {
    path: 'data-table',
    name: prop + '.dataTable',
    meta: { dark: mode, auth: true, name: 'Datatable' },
    component: DataTable
  },
  {
    path: 'editable',
    name: prop + '.editable',
    meta: { dark: mode, auth: true, name: 'Editable' },
    component: EditableTable
  }
]

const iconChildRoute = (prop, mode = false) => [
  {
    path: 'dripicons',
    name: prop + '.dripicons',
    meta: { dark: mode, auth: true, name: 'Dripicons' },
    component: IconDripicons
  },
  {
    path: 'fontawesome-5',
    name: prop + '.fontawesome-5',
    meta: { dark: mode, auth: true, name: 'Font Awsome' },
    component: IconFontawesome5
  },
  {
    path: 'lineawesome',
    name: prop + '.lineawesome',
    meta: { dark: mode, auth: true, name: 'Line Awsome' },
    component: IconLineAwesome
  },
  {
    path: 'remixicon',
    name: prop + '.remixicon',
    meta: { dark: mode, auth: true, name: 'Remixicon' },
    component: IconRemixicon
  },
  {
    path: 'unicons',
    name: prop + '.unicons',
    meta: { dark: mode, auth: true, name: 'Unicon' },
    component: IconUnicons
  }
]

const chartChildRoutes = (prop, mode = false) => [
  {
    path: 'apex-charts',
    name: prop + '.apex',
    meta: { dark: mode, auth: true, name: 'Apex Chat' },
    component: ApexCharts
  },
  {
    path: 'am-charts',
    name: prop + '.am',
    meta: { dark: mode, auth: true, name: 'Am Chart' },
    component: AmCharts
  },
  {
    path: 'high-charts',
    name: prop + '.high',
    meta: { dark: mode, auth: true, name: 'High Chart' },
    component: HighCharts
  },
  {
    path: 'morris-charts',
    name: prop + '.morris',
    meta: { dark: mode, auth: true, name: 'Mirris Chart' },
    component: MorrisCharts
  }
]

const appChildRoute = (prop, mode = false) => [
  {
    path: 'calendar',
    name: prop + '.calendar',
    meta: { dark: mode, auth: true, name: 'Calendar' },
    component: Calendar
  },
  {
    path: 'chat',
    name: prop + '.chat',
    meta: { dark: mode, auth: true, name: 'Chat' },
    component: ChatIndex
  },
  {
    path: 'email',
    name: prop + '.email',
    meta: { dark: mode, auth: true, name: 'Email' },
    component: EmailListing
  },
  {
    path: 'email-compose',
    name: prop + '.email.compose',
    meta: { dark: mode, auth: true, name: 'New Email' },
    component: EmailCompose
  }
]

const authChildRoutes = (prop, mode = false) => [
  {
    path: 'sign-in1',
    name: prop + '.sign-in1',
    meta: { dark: mode, auth: true },
    component: SignIn1
  },
  {
    path: 'sign-up1',
    name: prop + '.sign-up1',
    meta: { dark: mode, auth: true },
    component: SignUp1
  },
  {
    path: 'password-reset1',
    name: prop + '.password-reset1',
    meta: { dark: mode, auth: true },
    component: RecoverPassword1
  },
  {
    path: 'lock-screen1',
    name: prop + '.lock-screen1',
    meta: { dark: mode, auth: true },
    component: LockScreen1
  },
  {
    path: 'confirm-mail1',
    name: prop + '.confirm-mail1',
    meta: { dark: mode, auth: true },
    component: ConfirmMail1
  }
]

const mapChildRoute = (prop, mode = false) => [
  {
    path: 'google-maps',
    name: prop + '.google',
    meta: { dark: mode, auth: true, name: 'Google map' },
    component: GoogleMaps
  },
  {
    path: 'vector-maps',
    name: prop + '.vector',
    meta: { dark: mode, auth: true, name: 'Vector Detail' },
    component: VectorMaps
  }
]

const defaultlayout = (prop, mode = false) => [
  {
    path: 'timeline',
    name: prop + '.timeline',
    meta: { dark: mode, auth: true, name: 'Timeline' },
    component: TimeLine
  },
  {
    path: 'invoice',
    name: prop + '.invoice',
    meta: { dark: mode, auth: true, name: 'Invoice' },
    component: Invoice
  },
  {
    path: 'blank-page',
    name: prop + '.blank-page',
    meta: { dark: mode, auth: true, name: 'Blank Page' },
    component: BlankPage
  },
  {
    path: 'pricing',
    name: prop + '.pricing',
    meta: { dark: mode, auth: true, name: 'Pricing' },
    component: Pricing
  },
  {
    path: 'pricing-1',
    name: prop + '.pricing1',
    meta: { dark: mode, auth: true, name: 'Pricing 1' },
    component: Pricing1
  },
  {
    path: 'faq',
    name: prop + '.faq',
    meta: { dark: mode, auth: true, name: 'Faq' },
    component: FAQ
  }
]

const pagesChildRoutes = (prop, mode = false) => [
  {
    path: 'error/:code',
    name: prop + '.error',
    meta: { dark: mode, auth: true },
    component: ErrorPage
  },
  {
    path: 'coming-soon',
    name: prop + '.coming-soon',
    meta: { dark: mode, auth: true },
    component: ComingSoon
  },
  {
    path: 'maintenance',
    name: prop + '.maintenance',
    meta: { dark: mode, auth: true },
    component: Maintenance
  }
]
const userChildRoute = (prop, mode = false) => [
  {
    path: 'profile',
    name: prop + '.profile',
    meta: { dark: mode, auth: true, name: 'Profile' },
    component: Profile
  },
  {
    path: 'profile-edit',
    name: prop + '.edit',
    meta: { dark: mode, auth: true, name: 'Edit Profile' },
    component: ProfileEdit
  },
  {
    path: 'add-user',
    name: prop + '.add',
    meta: { dark: mode, auth: true, name: 'Add Profile' },
    component: AddUser
  },
  {
    path: 'user-list',
    name: prop + '.list',
    meta: { dark: mode, auth: true, name: 'User List' },
    component: UserList
  }
]

const pluginsChildRoute = (prop, mode = false) => [
  {
    path: 'datepicker',
    name: prop + '.datepicker',
    meta: { dark: mode, auth: true, name: 'Datepicker' },
    component: DatepickerDemo
  },
  {
    path: 'select',
    name: prop + '.select',
    meta: { dark: mode, auth: true, name: 'Select' },
    component: SelectDemo
  },
  {
    path: 'draggable',
    name: prop + '.draggable',
    meta: { dark: mode, auth: true, name: 'Draggable' },
    component: DragDropDemo
  },
  {
    path: 'treeview',
    name: prop + '.treeview',
    meta: { dark: mode, auth: true, name: 'Tree View' },
    component: AppTreeView
  }
]

const outChildRoutes = (prop, mode) => [
  {
    path: 'OpenLinkDoctorAdd',
    name: prop + '.OpenLinkDoctor',
    component: OpenLinkDoctor,
    meta: { dark: mode, auth: true, name: 'OpenLinkDoctor' }
  },
  {
    path: 'Register/:clinic/:phone',
    name: prop + '.Register',
    component: AddUseService,
    meta: { dark: mode, auth: true, name: 'Register' }
  },
  {
    path: 'Register/:clinic/:cus_id/:phone',
    name: prop + '.Register',
    component: AddUseService,
    meta: { dark: mode, auth: true, name: 'Register' }
  },
  {
    path: 'Register/:clinic/:cus_id/:phone',
    name: prop + '.Register',
    component: AddUseService,
    meta: { dark: mode, auth: true, name: 'Register' }
  },
  {
    path: 'comment/:cc_id',
    name: prop + '.comment',
    component: CommentDetail,
    meta: { dark: mode, auth: true, name: 'comment' }
  }
]

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { auth: true }
  },
  {
    path: '/historyJob/:hc_id/:statusAns',
    name: 'historyJob',
    component: ConfirmMailPostJob,
    meta: { auth: true }
  },
  {
    path: '/',
    name: 'dashboard',
    component: Layout1,
    meta: { auth: true },
    children: childRoutes('dashboard'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/',
    name: 'out',
    component: Layout2,
    meta: { auth: true },
    children: outChildRoutes('out')
  },
  {
    path: '/core',
    name: 'core',
    component: Layout1,
    meta: { auth: true },
    children: coreChildRoute('core'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/table',
    name: 'table',
    component: Layout1,
    meta: { auth: true },
    children: tableChildRoute('table'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/chart',
    name: 'chart',
    component: Layout1,
    meta: { auth: true },
    children: chartChildRoutes('chart'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/icon',
    name: 'icon',
    component: Layout1,
    meta: { auth: true },
    children: iconChildRoute('icon'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/auth',
    name: 'auth1',
    component: AuthLayout1,
    meta: { auth: true },
    children: authChildRoutes('auth1'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/pages',
    name: 'pages',
    component: Default,
    meta: { auth: true },
    children: pagesChildRoutes('default'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/extra-pages',
    name: 'extra-pages',
    component: Layout1,
    meta: { auth: true },
    children: defaultlayout('extra-pages'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/app',
    name: 'app',
    component: Layout1,
    meta: { auth: true },
    children: appChildRoute('app'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/doctor',
    name: 'doctor',
    component: Layout1,
    meta: { auth: true },
    children: userChildRoute('doctor'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/map',
    name: 'map',
    component: Layout1,
    meta: { auth: true },
    children: mapChildRoute('map'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/form',
    name: 'form',
    component: Layout1,
    meta: { auth: true },
    children: formChildRoute('form'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/plugins',
    name: 'plugins',
    component: Layout1,
    meta: { auth: true },
    children: pluginsChildRoute('plugins'),
    beforeEnter: ifAuthenticated
  },
  {
    path: '/callback',
    name: 'callback',
    meta: { auth: false },
    component: Callback,
    beforeEnter: ifAuthenticated
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

export default router
