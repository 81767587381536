<template>
  <b-container fluid>
    <a href="#" @click.prevent="getData">
      <span style="text-decoration: underline;">{{ label }}</span>
    </a>

    <b-modal v-model="openModal" title="ข้อมูลลูกค้า" cancel-title="เพิ่ม" ok-title="ยกเลิก" cancel-variant="primary"
      ok-variant="danger" @close.prevent="openModal = false" :no-close-on-esc="false" :no-close-on-backdrop="false"
      size="xl" hide-footer>
      <b-row>
        <b-col cols="12" md="4">
          <b-row style="align-items: center; margin: 0px;">
            <b-col cols="12" xl="auto" style="text-align: center; margin: 0 0 10px 0;">
              <img style="width: 100px; aspect-ratio: 1/1; border-radius: 100%; object-fit: contain;" :src="getFullImagePath(dataCustomer?.img_path)">
            </b-col>
            <b-col cols style="margin: 0 0 10px 0;">
              <span style="color: black; font-size: medium; font-weight: 500; word-break: break-word;">{{ GetFullNameCustomer() }}</span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12">
              <span style="display: block; margin: 10px 0 0 0;">ชื่อเล่น</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.nickname || '-' }}</span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12">
              <span style="display: block; margin: 10px 0 0 0;">เพศ</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.gender || '-' }}</span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12">
              <span style="display: block; margin: 10px 0 0 0;">วันเดือนปีเกิด</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ formatDate(dataCustomer?.birthday) }}</span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12">
              <span style="display: block; margin: 10px 0 0 0;">เลขบัตรประชาชน</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.citizen_id || '-' }}</span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12">
              <span style="display: block; margin: 10px 0 0 0;">เบอร์ติดต่อ</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.phone || '-' }}</span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12">
              <span style="display: block; margin: 10px 0 0 0;">ข้อมูล HN</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.hn_no || '-' }}</span>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12" md="auto">
          <hr class="separate_line" />
        </b-col>

        <b-col cols="12" md>
          <b-row style="margin: 0px;">
            <b-col cols="12" style="margin: 16px 0 25px 0;">
              <span style="color: black; font-size: medium; font-weight: 500; word-break: break-word;">ข้อมูลส่วนบุคคล</span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">จังหวัด</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.province || '-' }}</span>
            </b-col>
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">อำเภอ/เขต</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.district || '-' }}</span>
            </b-col>
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">ตำบล/แขวง</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.sub_district || '-' }}</span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">รหัสไปรษณีย์</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.postcode || '-' }}</span>
            </b-col>
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">Email</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.email || '-' }}</span>
            </b-col>
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">Line</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.line || '-' }}</span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12" lg="6" xl="3">
              <span style="display: block; margin: 10px 0 0 0;">น้ำหนัก</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.weight || '-' }}</span>
            </b-col>
            <b-col cols="12" lg="6" xl="3">
              <span style="display: block; margin: 10px 0 0 0;">ส่วนสูง</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.height || '-' }}</span>
            </b-col>
            <b-col cols="12" lg="6" xl="3">
              <span style="display: block; margin: 10px 0 0 0;">ศาสนา</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.religion || '-' }}</span>
            </b-col>
            <b-col cols="12" lg="6" xl="3">
              <span style="display: block; margin: 10px 0 0 0;">กรุ๊ปเลือด</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.blood_type || '-' }}</span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12" xl="6">
              <span style="display: block; margin: 10px 0 0 0;">ประวัติการแพ้ยา</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.drug_allergy || '-' }}</span>
            </b-col>
            <b-col cols="12" xl="6">
              <span style="display: block; margin: 10px 0 0 0;">ประวัติการแพ้อาหาร</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.food_allergy || '-' }}</span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">ความดัน(mmHg)</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.pressure || '-' }}</span>
            </b-col>
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">อุณหภูมิร่างกาย</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.body_temp || '-' }}</span>
            </b-col>
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">ไขมัน(%)</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.fat || '-' }}</span>
            </b-col>
          </b-row>
          <b-row style="margin: 0px;">
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">กล้ามเนื้อ(%)</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.muscle || '-' }}</span>
            </b-col>
            <b-col cols="12" lg="6" xl="4">
              <span style="display: block; margin: 10px 0 0 0;">ค่า BMI</span>
              <span style="color: black; display: block; word-break: break-word; margin: 0 0 10px 0;">{{ dataCustomer?.bmi || '-' }}</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>

<style scoped>
.separate_line {
  margin: 0 10px 0 10px;
  height: 100%;
  width: 1px;
  background-color: #a09e9e;
}

@media (max-width: 767px) {
  .separate_line {
    margin: 20px 0 10px 0;
    height: 1px;
    width: 100%;
    background-color: #a09e9e;
  }
}
</style>

<script>
import { xray } from '../../../config/pluginInit'
import { configAPI } from '../../../configBase'
// import router from '@/router'
import Vue from 'vue'
import Axios from 'axios'
import moment from 'moment'

export default {
  name: 'doctorModal',
  props: {
    id: { type: String },
    label: { type: String }
  },
  data () {
    return {
      openModal: false,
      clnId: JSON.parse(sessionStorage.getItem('user')).cln_id || null,
      dataCustomer: null
    }
  },
  computed: {},
  async mounted () {
    xray.index()
  },
  methods: {
    formatDate (data) {
      if (data) {
        return moment(data).add(543, 'year').format('DD/MM/YYYY')
      } else {
        return '-'
      }
    },
    getFullImagePath (relativePath) {
      if (relativePath !== null && relativePath !== '' && relativePath) {
        let path = relativePath !== null ? relativePath.slice(1) : ''
        const baseURL = configAPI.MA_PORT + path
        return baseURL
      } else {
        return 'https://www.w3schools.com/howto/img_avatar.png'
      }
    },
    GetFullNameCustomer () {
      return `${this.dataCustomer?.firstname || ''} ${this.dataCustomer?.lastname || ''}`
    },
    async getData () {
      await Axios.request({
        mothod: 'GET',
        url: `${configAPI.MA_PORT}/api/clinic/${this.clnId}/register/view/${this.id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        this.dataCustomer = res.data.data[0] || ''
      }).catch((err) => {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่สามารถดึงข้อมูลลูกค้าได้</span>',
          icon: 'error',
          html: `<span class="" style="font-size: 18px; color: black; text-align: center">${err}</span>`,
          showCancelButton: false,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
          confirmButtonColor: '#089bab'
        })
      })
      this.openModal = true
    }
  }
}
</script>
