<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span class="unselectable" style="font-size: 25px; color: black;">สินค้า</span>
              </div>
              <div class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button @click="handleClick('add', null)" type="button" variant="primary-custom" class="w-100 btn-min-size">
                  <i class="circle-plus_ct"></i>
                  เพิ่ม
                </b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" md="7" lg="5">
                  <b-row>
                    <b-col sm="12" md class="mb-3">
                      <div class="iq-email-search d-flex">
                        <div style="width: 100%;">
                          <div class="form-group mb-0">
                            <input class="form-control" id="exampleInputEmail1" placeholder="Search" style="width: 100%;"
                              v-model="searchTerm" @keydown="enterSearch">
                            <a class="search-link" v-on:click="getProduct"><i class="ri-search-line" /></a>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="auto" class="mb-3" style="display: flex; align-items: center;">
                      <span class="mx-2 font-size-14 text-primary unselectable">
                        {{ product?.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage * currentPage) >
                          product?.length) ? product?.length : (perPage * currentPage)) + ' of ' + productLength : '0 of ' + productLength }}
                        รายการ
                      </span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <br>
            <b-col md="12" class="table-responsive">
              <b-table bordered hover :items="product" :fields="columns" :current-page="currentPage" :per-page="perPage"
                @pagination="onPagination" :tbody-tr-class="rowClass" thead-class="table-header-color">
                <template v-slot:cell(variable0)="data">
                  <span v-if="!data.item.editable">{{ ((currentPage - 1) * 10) + data.index + 1 }}</span>
                </template>
                <template v-slot:cell(variable1)="data">
                  <span v-if="!data.item.editable">{{ product[((currentPage - 1) * 10) + data.index].no || '-' }}</span>
                </template>
                <template v-slot:cell(variable2)="data">
                  <span v-if="!data.item.editable">{{ product[((currentPage - 1) * 10) + data.index].name || '-' }}</span>
                </template>
                <template v-slot:cell(variable3)="data">
                  <span v-if="!data.item.editable">{{ product[((currentPage - 1) * 10) + data.index].compound || '-' }}</span>
                </template>
                <template v-slot:cell(variable4)="data">
                  <span v-if="!data.item.editable">{{ product[((currentPage - 1) * 10) + data.index].brand_name || '-' }}</span>
                </template>
                <template v-slot:cell(variable5)="data" style="object-fit: contain;">
                  <!-- <b-img v-if="product[((currentPage - 1) * 10) + data.index].qr_path" src="http://150.95.25.8:8112/uploads/qrcode" {{ product[((currentPage - 1) * 10) + data.index].qr_path }} style="height: 100px; width:100px;"></b-img>
                  <b-img v-else-if="product[((currentPage - 1) * 10) + data.index].qr_path === 'string'" src="http://150.95.25.8:8112/uploads/product/20230813154457_299.png" style="height: 100px; width:100px;"></b-img> -->
                  <b-img v-if="product[((currentPage - 1) * 10) + data.index].qr_path"
                    :src="getFullImagePath(product[((currentPage - 1) * 10) + data.index].qr_path)"
                    style="height: 50px; width: 200px; object-fit: contain;"></b-img>
                  <b-img v-else :src="require('../../assets/images/default.jpg')"
                    style="height: 50px; width: 200px; object-fit: contain;"></b-img>
                  <!-- <b-text>{{ getFullImagePath(product[((currentPage - 1) * 10) + data.index].qr_path) }}</b-text> -->
                </template>
                <template v-slot:cell(variable6)="data">
                  <span v-if="!data.item.editable">{{ product[((currentPage - 1) * 10) + data.index].unit_name || '-'}}</span>
                </template>
                <template v-slot:cell(variable7)="data">
                  <div style="display: flex; justify-content: center;">
                    <b-button variant=" iq-bg-info ml-1" size="sm"
                      @click="handleClick('view', product[((currentPage - 1) * 10) + data.index].id)"><i
                        class="ri-eye-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-success mx-1" size="sm" id="show-modal"
                      @click="handleClick('edit', product[((currentPage - 1) * 10) + data.index].id)"><i
                        class="ri-ball-pen-fill m-0"></i></b-button>
                    <!-- <b-button variant=" iq-bg-success mx-1" size="sm" @click="submit(product[((currentPage - 1) * 10) + data.index].id)" v-else>Ok</b-button> -->
                    <b-button variant=" iq-bg-danger" size="sm"
                      @click="remove(product[((currentPage - 1) * 10) + data.index].id)"><i
                        class="ri-delete-bin-line m-0"></i></b-button>
                  </div>
                </template>
              </b-table>
              <br>
            </b-col>
            <b-pagination v-model="currentPage" :total-rows="product.length" :per-page="perPage" align="center" />
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <template>
      <b-modal v-model="showModal" title="Edit Product" cancel-title="ยกเลิก" ok-title="เพิ่ม">
        <b-form-group label="รหัสสินค้า" style="color: black;">
          <b-form-input id="" type="text"></b-form-input>
        </b-form-group>
        <b-form-group label="ชื่อยา" style="color: black;">
          <b-form-input id="" type="text"></b-form-input>
        </b-form-group>
        <b-form-group label="สารประกอบ" style="color: black;">
          <b-form-input id="" type="text"></b-form-input>
        </b-form-group>
        <b-form-group label="ชื่อสินค้า" style="color: black;">
          <b-form-input id="" type="text"></b-form-input>
        </b-form-group>
        <b-form-group label="QR" style="color: black;">
          <!-- <b-form-input id="" type="text"></b-form-input> -->
          <b-form-file style="border-radius: 10px; color: black; border-color: black;" id="imageInput" accept="image/*"
            @change="handleImageUpload" placeholder="" browse-text="เลือกไฟล์"></b-form-file>
        </b-form-group>
        <b-form-group label="หน่วยนับ" style="color: black;">
          <b-form-select id="" type="text"></b-form-select>
        </b-form-group>
      </b-modal>
    </template>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
// import router from '@/router'
import axios from 'axios'
import Vue from 'vue'
import { configAPI } from '../../configBase'

export default {
  variable1: 'UiDataTable',
  computed: {
    sharedData () {
      return this.$store.state.sharedData
    }
  },
  async mounted () {
    xray.index()
    this.cln_id = JSON.parse(sessionStorage.getItem('user')).cln_id
    await this.getProduct()
    // await this.getProductAll()
  },
  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getProduct()
      }
    },
    async getProduct () {
      let search = this.searchTerm.trim().length > 0 ? this.searchTerm.trim() : ''
      let productAPI = {
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/product?search=` + search,
        headers: {
          'Content-Type': 'application/json'
        }
      }

      await axios.request(productAPI)
        .then((response) => {
          this.product = response.data.data
          this.productLength = response.data.count
        })
        // .catch((error) => {
        //   console.error('Error fetching product data:', error)
        // })
    },
    async getProductAll () {
      let search = this.searchTerm.trim().length > 0 ? this.searchTerm.trim() : ''
      let productAPI = {
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/product?search=` + search,
        headers: {
          'Content-Type': 'application/json'
        }
      }

      await axios.request(productAPI)
        .then((response) => {
          this.productLength = response.data.data.length
        })
        // .catch((error) => {
        //   console.error('Error fetching product data:', error)
        // })
    },
    // deleteProduct (id) {
    //   let delproductAPI = {
    //     method: 'post',
    //     url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/product/${id}/delete`,
    //     headers: {
    //       'Content-Type': 'application/json'
    //     }
    //   }

    //   axios.request(delproductAPI)
    //     .then((response) => {
    //       this.getProduct()
    //     })
    //     // .catch((error) => {
    //     //   console.error('Error deleting product:', error)
    //     // })
    // },
    getFullImagePath (relativePath) {
      if (relativePath) {
        relativePath = relativePath.substring(1)
      }
      const baseURL = `${configAPI.MA_PORT}`
      return baseURL + relativePath
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.index % 2 === 1) return 'table-body-color'
    },
    updateDataAsync () {
      // this.$store.commit('setSharedData', 'New data')
      this.$store.dispatch('updateSharedData', {
        name: 'dream',
        age: 24,
        live: 'Prathumtanee'
      }) // เรียกใช้งาน action เพื่อเปลี่ยนแปลงค่าใน store ในลักษณะ asynchronous
    },
    add () {
      let obj = this.default()
      this.rows.push(obj)
    },
    default () {
      return {
        id: this.product?.length,
        variable1: '',
        variable2: '',
        variable3: '',
        variable4: '',
        variable5: '',
        variable6: ''
      }
    },
    edit (item) {
      item.editable = true
    },
    submit (item) {
      item.editable = false
    },
    remove (id) {
      Vue.swal.fire({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณต้องการลบข้อมูลใช่หรือไม่?</span>',
        // text: 'ข้อมูลที่ถูกลบจะไม่สามารถนำกลับมาได้กรุณาตรวจสอบให้ชัดเจนก่อนลบข้อมูล',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          Vue.swal({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">ลบข้อมูลสำเร็จ</span>',
            icon: 'success',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 2000
          }).then(() => {
            // let index = this.rows.indexOf(item)
            // this.rows.splice(index, 1)
            let delproductAPI = {
              method: 'post',
              url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/product/${id}/delete`,
              headers: {
                'Content-Type': 'application/json'
              }
            }
            axios.request(delproductAPI)
              .then((response) => {
                this.getProduct()
              })
          })
        }
      })
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClick (e, data) {
      const setPage = e
      // this.updateDataAsync()
      const targetRoute = setPage === 'add' ? `/AddProduct/${setPage}` : `/AddProduct/${setPage}/${data}`
      this.$router.push(targetRoute)
      // if (setPage === 'add') {
      //   router.push(`/AddProduct/${setPage}`)
      //   console.log('1')
      // } else if (setPage === 'edit' || setPage === 'view') {
      //   router.push(`/AddProduct/${setPage}/${data}`)
      //   console.log('2')
      // }
    },
    sendData () {
      const setPage = 'add'
      this.setData(setPage)
    }
  },
  data () {
    return {
      cln_id: null,
      product: [],
      searchTerm: '',
      selectedOption: null,
      showModal: false,
      columns: [
        { label: 'ลำดับ', key: 'variable0', class: 'text-center table-size-100-300' },
        { label: 'รหัสสินค้า', key: 'variable1', class: 'text-center table-size-200-400 ellips' },
        { label: 'ชื่อยา', key: 'variable2', class: 'text-center table-size-200-400 ellips' },
        { label: 'สารประกอบ', key: 'variable3', class: 'text-center table-size-200-400 ellips' },
        { label: 'ชื่อการค้า', key: 'variable4', class: 'text-center table-size-200-400 ellips' },
        { label: 'บาร์โค้ด', key: 'variable5', class: 'text-center table-size-200-400' },
        { label: 'หน่วยนับ', key: 'variable6', class: 'text-center table-size-200-400' },
        { label: 'จัดการ', key: 'variable7', class: 'text-center' }
      ],
      currentPage: 1,
      perPage: 10,
      productLength: 0
    }
  }
}
</script>
