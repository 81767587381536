<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto" style="padding-left: 0;">
                <span v-if="mode === 'add'" style="font-size: 25px; color: black;" class="unselectable">ข้อมูลการทำงาน
                  (เพิ่ม)</span>
                <span v-else-if="mode === 'edit'"
                  style="font-size: 25px; color: black;" class="unselectable">ข้อมูลการทำงาน (แก้ไข)</span>
                <span v-else style="font-size: 25px; color: black;" class="unselectable">ข้อมูลการทำงาน (ดูรายละเอียด)</span>
              </div>
              <div v-if="mode === 'add' || mode === 'edit'" class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-6 pl-3 pr-2 my-1 my-sm-0">
                    <b-button type="submit" variant="primary" @click="handleClick(true)" class="w-100 btn-min-size"><i class="save-data_ct" />บันทึก</b-button>
                  </div>
                  <div class="col-6 pl-2 pr-3 my-1 my-sm-0">
                    <b-button type="submit" variant="none" @click="handleClickGoBack" class="iq-bg-danger w-100 btn-min-size btn-cancel-border">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
              <div v-else class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="submit" variant="none" @click="handleClickGoBack" class="iq-bg-danger w-100 btn-min-size btn-cancel-border">ยกเลิก</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="2" sm="2">
                <div  style="display: flex;align-items: center;">
                  <b-form-checkbox class="" :disabled="isDisable" v-model="isMainType" name="" switch></b-form-checkbox>
                  <b-text style="color: black; font-size: 18px; white-space: nowrap;">ประเภทหลัก</b-text>
                </div>
              </b-col>
            </b-row>
            <br>
            <b-row>
              <b-col lg="4" sm="4">
                <b-form-group class="unselectable" label-for="exampleInputurl">
                  <label style="color: black;">ชื่อช่วงงาน <span style="color: red;" :hidden="attendance.nameWork?.trim() ? true : false"> *</span></label>
                  <b-form-input id="exampleInputurl" :disabled="isDisable" v-model="attendance.nameWork" type="text"></b-form-input>
                </b-form-group>
                <!-- <b-form-input type="text" placeholder="ชื่อช่วงงาน *"></b-form-input> -->
              </b-col>
              <b-col lg="4" sm="4">
                <b-form-group class="unselectable mar-bot-select-attendance" style="color: black;">
                  <label style="color: black;">ประเภท <span style="color: red;" :hidden="attendance.type ? true : false"> *</span></label>
                  <v-select v-model="attendance.type" :disabled="isDisable" :options="supplierOptions1"></v-select>
                    <!-- <b-form-select plain v-model="attendance.type" class="format-dd-AddAtt" :disabled="isDisable" :options="supplierOptions1" size="lg">
                      <template v-slot:first>
                        <b-form-select-option :value="null">------ Select --------</b-form-select-option>
                      </template>
                    </b-form-select> -->
                  </b-form-group>
                <!-- <b-form-select type="text" placeholder="ประเภท" v-model="supplierName1" :options="supplierOptions1"></b-form-select> -->
              </b-col>
              <b-col lg="4" sm="4">
                <b-form-group v-if="isMainType === false" class="unselectable" label-for="exampleInputmonth">
                  <label style="color: black;">เงินบวกเพิ่ม <span style="color: red;" :hidden="attendance.moneyIncrease ? true : false"> *</span></label>
                  <b-form-input id="exampleInputmonth" :disabled="isDisable" v-model="attendance.moneyIncrease" type="text" @input="isNumberComma1($event, '1')" @keypress="isNumberCheck($event, false)"></b-form-input>
                </b-form-group>
                <b-form-group v-else class="unselectable" label-for="exampleInputmonth">
                  <label style="color: black;">ค่าเข้ากะ <span style="color: red;" :hidden="attendance.valueIn ? true : false"> *</span></label>
                  <b-form-input id="exampleInputmonth" :disabled="isDisable" v-model="attendance.valueIn" type="text" @input="isNumberComma1($event, '7')" @keypress="isNumberCheck($event, false)"></b-form-input>
                </b-form-group>
                <!-- <b-form-input type="number" placeholder="เงินบวกเพิ่ม *"></b-form-input> -->
              </b-col>
            </b-row>
            <!-- <br> -->
            <b-row :hidden="isMainType === true">
              <b-col lg="4" sm="4">
                <b-text style="color: black;">ชั่วโมงเริ่มต้น <span style="color: red;" :hidden="attendance.startHour ? true : false"> *</span></b-text>
                <div style="position: relative;" class="w-res-attendance time-picker-attendance">
                  <date-picker placeholder="HH:mm" :disabled="isDisable" id="datepicker-custom1" name="date" v-model="attendance.startHour" :config="options"></date-picker>
                  <i class="ri-calendar-line m-0 icon-calendar-edit" :disabled="isDisable" @click="onClickDatePicker(1)" style="cursor: pointer;"></i>
                </div>
                <!-- <b-text style="color: black; white-space: nowrap;">ชั่วโมงเริ่มต้น *</b-text> -->
               <!-- <b-form-timepicker class="form-input format-picker-attendance" :disabled="isDisable" v-model="attendance.startHour" placeholder="" locale="th"></b-form-timepicker> -->
              </b-col>
              <b-col lg="4" sm="4">
                <b-text style="color: black;">ชั่วโมงสุดท้าย <span style="color: red;" :hidden="attendance.endHour ? true : false"> *</span></b-text>
                <div style="position: relative;" class="w-res-attendance time-picker-attendance">
                  <date-picker placeholder="HH:mm" id="datepicker-custom2" :disabled="isDisable === false ? getstartHour() === null ? true : false : true" name="date" v-model="attendance.endHour" :config="options1"></date-picker>
                  <i class="ri-calendar-line m-0 icon-calendar-edit" :disabled="isDisable" @click="onClickDatePicker(2)" style="cursor: pointer;"></i>
                </div>
                <!-- <b-text style="color: black; white-space: nowrap;">ชั่วโมงสุดท้าย *</b-text> -->
               <!-- <b-form-timepicker class="form-input format-picker-attendance" :disabled="isDisable" v-model="attendance.endHour" placeholder="" locale="th"></b-form-timepicker> -->
              </b-col>
              <b-col lg="4" sm="4">
                <b-form-group class="unselectable" label-for="exampleInputmonth">
                  <label style="color: black;">สาย <span style="color: red;" :hidden="attendance.late ? true : false"> *</span></label>
                  <b-form-input id="exampleInputmonth" :disabled="isDisable" v-model="attendance.late" type="text" @input="isNumberComma1($event, '2')" @keypress="isNumberCheck($event, true)"></b-form-input>
                </b-form-group>
                <!-- <b-form-input type="number" placeholder="สาย *"></b-form-input> -->
              </b-col>
            </b-row>
            <!-- <br> -->
            <b-row v-if="isMainType === false">
              <b-col lg="4" sm="4">
                <b-form-group class="unselectable" label-for="exampleInputmonth">
                  <label style="color: black;">พัก(นาที) <span style="color: red;" :hidden="attendance.rest ? true : false"> *</span></label>
                  <b-form-input id="exampleInputmonth" :disabled="isDisable" v-model="attendance.rest" type="text" @input="isNumberComma1($event, '3')" @keypress="isNumberCheck($event, true)"></b-form-input>
                </b-form-group>
                <!-- <b-form-input type="text" placeholder="พัก(นาที) *"></b-form-input> -->
              </b-col>
              <b-col lg="4" sm="4">
                <b-form-group class="unselectable" label-for="exampleInputmonth">
                  <label style="color: black;">อัตราส่วน <span style="color: red;" :hidden="attendance.rate ? true : false"> *</span></label>
                  <b-form-input id="exampleInputmonth" :disabled="isDisable" v-model="attendance.rate" type="text" @input="isNumberComma1($event, '4')" @keypress="isNumberCheck($event, false)"></b-form-input>
                </b-form-group>
                <!-- <b-form-input type="text" placeholder="อัตราส่วน *"></b-form-input> -->
              </b-col>
              <b-col lg="4" sm="4">
                <b-form-group class="unselectable mar-bot-select-attendance" style="color: black;" >
                  <label style="color: black;">ประเภทหลัก <span style="color: red;" :hidden="attendance.mainType ? true : false"> *</span></label>
                  <v-select v-model="attendance.mainType" :disabled="isDisable" :options="supplierOptions2"></v-select>
                    <!-- <b-form-select plain v-model="attendance.mainType" class="format-dd-AddAtt" :disabled="isDisable" :options="supplierOptions2" size="lg">
                      <template v-slot:first>
                        <b-form-select-option :value="null">------ Select --------</b-form-select-option>
                      </template>
                    </b-form-select> -->
                  </b-form-group>
                <!-- <b-form-select type="number" v-model="supplierName2" :options="supplierOptions2" placeholder="ประเภทหลัก *"></b-form-select> -->
              </b-col>
            </b-row>
            <br>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.mar-bot-select-attendance .col-form-label{
  margin-bottom: 0.2rem !important;
}
.vue__time-picker {
    display: inline-block;
    position: relative;
    font-size: 1em;
    width: 50% !important;
    font-family: sans-serif;
    vertical-align: middle;
}

.vue__time-picker input.display-time {
    color: #9b9797;
    border: 1px solid #d2d2d2 !important;
    width: 100% !important;
    height: 45px !important;
    padding: .3em .5em !important;
    font-size: 1em !important;
    border-radius: 10px !important;
}
.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active, .vue__time-picker
.dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
    background: #0cafc2 !important;
    color: #fff !important;
}
.time-picker-attendance {
  width: 100% !important;
  margin-top: .6rem !important;
}
.format-dd-AddAtt {
  padding: .70rem !important;
  margin-top: .3rem !important;
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import axios from 'axios'
import Vue from 'vue'
import 'moment/locale/th' // นำเข้า locale ของภาษาไทย
import { configAPI } from '../../configBase'

export default {
  name: 'UiDataTable',
  components: {
  },
  watch: {
    'attendance.startHour': function (newVal, oldVal) {
      if (oldVal !== null) {
        this.attendance.endHour = null
      }
      this.options1.minDate = newVal
    }
  },
  async mounted () {
    const user = JSON.parse(sessionStorage.getItem('user'))
    this.cln_id = user?.cln_id
    this.user_id = user?.usr_id
    await this.getTimeWorking()
    await this.getPeriodTime()
    if (this.$route.params.id) {
      this.mode = 'edit'
      this.id = this.$route.params.id
      await this.getAttendance()
      // console.log('chekc')
      if (this.$route.params.setPage === 'view') {
        this.mode = 'view'
        this.isDisable = true
      }
      /* if (this.$route.params.id % 2 === 0) {
        this.isMainType = false
        this.attendance = dataTest
      } else {
        this.isMainType = true
        this.attendance = dataTest
      } */
    }
    xray.index()
  },
  methods: {
    async getPeriodTime () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/periodTime`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            let arrList = []
            for (let index = 0; index < response.data.data.length; index++) {
              const el = response.data.data[index]
              if (!el.parent_period_time_id) {
                arrList.push({
                  label: el.period_time_name,
                  code: el.id
                })
              }
            }
            this.supplierOptions2 = arrList
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async getTimeWorking () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/timeWorking`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            this.supplierOptions1 = response.data.data?.map(el => {
              return {
                code: el.id,
                label: el.name
              }
            })
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    getstartHour () {
      return this.attendance.startHour
    },
    add () {
      let obj = this.default()
      this.rows.push(obj)
    },
    default () {
      return {
        id: this.rows.length,
        variable1: '',
        variable2: '',
        variable3: '',
        variable4: '',
        variable5: '2011/04/25',
        variable6: '$0',
        editable: false
      }
    },
    edit (item) {
      item.editable = true
    },
    submit (item) {
      item.editable = false
    },
    remove (item) {
      let index = this.rows.indexOf(item)
      this.rows.splice(index, 1)
    },
    onPagination (page) {
      this.currentPage = page
    },
    handleClickGoBack () {
      if (this.mode === 'view') {
        router.go(-1)
      } else {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            router.go(-1)
          }
        })
      }
    },
    checkError (tab) {
      let error = []
      const regexNumber = /^[0-9]+$/
      if (!this.attendance.nameWork || this.attendance.nameWork?.trim() === '') {
        error.push('กรุณากรอก ชื่อช่วงงาน')
      }
      if (this.attendance.type === null || !this.attendance.type) {
        error.push('กรุณาเลือก ประเภท')
      }
      if (this.isMainType === true) {
        if (this.attendance.valueIn && !regexNumber.test(this.attendance.valueIn?.trim()?.replace(/,/g, ''))) {
          error.push('ช่องค่าเข้ากะ ต้องเป็นตัวเลขเท่านั้น')
        } else if (!this.attendance.valueIn || this.attendance.valueIn?.trim() === '') {
          error.push('กรุณากรอก ค่าเข้ากะ')
        }
      } else {
        if (this.attendance.moneyIncrease && !regexNumber.test(this.attendance.moneyIncrease?.trim()?.replace(/,/g, ''))) {
          error.push('ช่องเงินบวกเพิ่ม ต้องเป็นตัวเลขเท่านั้น')
        } else if (!this.attendance.moneyIncrease || this.attendance.moneyIncrease?.trim() === '') {
          error.push('กรุณากรอก เงินบวกเพิ่ม')
        }
        if (this.attendance.startHour === null || !this.attendance.startHour) {
          error.push('กรุณาเลือก ชั่วโมงเริ่มต้น')
        }
        if (this.attendance.endHour === null || !this.attendance.endHour) {
          error.push('กรุณาเลือก ชั่วโมงสุดท้าย')
        }
        if (this.attendance.late && !regexNumber.test(this.attendance.late?.trim()?.replace(/,/g, ''))) {
          error.push('ช่องสาย ต้องเป็นตัวเลขเท่านั้น')
        } else if (!this.attendance.late || this.attendance.late?.trim() === '') {
          error.push('กรุณากรอก สาย')
        }
        if (this.attendance.rest && !regexNumber.test(this.attendance.rest?.trim()?.replace(/,/g, ''))) {
          error.push('ช่องพัก(นาที) ต้องเป็นตัวเลขเท่านั้น')
        } else if (!this.attendance.rest || this.attendance.rest?.trim() === '') {
          error.push('กรุณากรอก พัก(นาที)')
        }
        if (this.attendance.rate && !regexNumber.test(this.attendance.rate?.trim()?.replace(/,/g, ''))) {
          error.push('ช่องอัตราส่วน ต้องเป็นตัวเลขเท่านั้น')
        } else if (!this.attendance.rate || this.attendance.rate?.trim() === '') {
          error.push('กรุณากรอก อัตราส่วน')
        }
        if (this.attendance.mainType === null || !this.attendance.mainType) {
          error.push('กรุณาเลือก ประเภทหลัก')
        }
      }
      return error
    },
    handleClick () {
      // console.log('this.attendance :>> ', this.attendance)
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.mode !== 'edit' ? 'คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่?' : 'คุณมั่นใจว่าคุณจะแก้ไขข้อมูลใช่หรือไม่?'}</span>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const errorList = this.checkError()
          if (errorList.length > 0) {
            let errMessage = ''
            for (let e = 0; e < errorList.length; e++) {
              errMessage += '<br/>' + errorList[e]
            }
            this.SwalError(errMessage, 'บันทึกข้อมูลไม่สำเร็จ')
            return
          }
          this.saveAttendance()
        }
      })
    },
    async getAttendance () {
      await axios.request({
        method: 'get',
        url: `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/periodTime/${this.id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            const data = response.data.data[0]
            this.isMainType = !data.parent_period_time_id
            this.attendance = {
              nameWork: data.period_time_name,
              type: data.mtw_id
                ? this.supplierOptions1?.find(el => el.code === data.mtw_id)
                : null,
              moneyIncrease: this.isMainType ? null : data.extra_budget,
              valueIn: this.isMainType ? data.extra_budget : null,
              startHour: data.begin_time,
              endHour: data.finish_time,
              late: data.time_to_late,
              rest: data.break_time_minue,
              rate: data.ratio,
              mainType: data.parent_period_time_id
                ? this.supplierOptions2?.find(el => el.code === data.parent_period_time_id)
                : null
            }
            /* const data = response.data.data[0]
            this.timeWorkLate = {
              lateWork: data.late_time,
              startWork: data.start_work ? moment(data.start_work, 'HH:mm:ss.SSSZ').format('HH:mm') : null
            } */
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    async saveAttendance () {
      await axios.request({
        method: this.mode === 'add' ? 'post' : 'put',
        url: this.mode === 'add'
          ? `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/periodTime`
          : `${configAPI.MA_PORT}/api/clinic/${this.cln_id}/periodTime/${this.id}`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          cln_id: this.cln_id,
          mtw_id: this.attendance.type.code,
          period_time_name: this.attendance.nameWork?.trim() || null,
          begin_time: this.isMainType ? null : this.attendance.startHour || null,
          finish_time: this.isMainType ? null : this.attendance.endHour || null,
          break_time_minue: this.isMainType ? null : this.attendance.rest?.replace(/,/g, '') || null,
          ratio: this.isMainType ? null : this.attendance.rate?.replace(/,/g, '') || null,
          extra_budget: this.isMainType ? this.attendance.valueIn?.replace(/,/g, '') || null : this.attendance.moneyIncrease?.replace(/,/g, '') || null,
          time_to_late: this.isMainType ? null : this.attendance.late?.replace(/,/g, '') || null,
          parent_period_time_id: this.isMainType ? null : this.attendance.mainType.code || null,
          sort: 1,
          is_use: true,
          is_active: true,
          created_by: this.user_id,
          updated_by: this.user_id
        }
      })
        .then(async (response) => {
          if (response.data.status) {
            Vue.swal({
              title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.mode === 'add' ? 'บันทึกข้อมูลสำเร็จ' : 'แก้ไขข้อมูลสำเร็จ'}</span>`,
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              router.go(-1)
            })
          }
        })
        .catch((error) => {
          this.SwalError(error.message, this.mode === 'add' ? 'บันทึกข้อมูลไม่สำเร็จ' : 'แก้ไขข้อมูลไม่สำเร็จ')
        })
    },
    isNumberCheck (keyPressed, status) {
      let useData = keyPressed.target.value

      let keyCode = (keyPressed.keyCode ? keyPressed.keyCode : keyPressed.which)

      if (status) {
        if (keyCode === 46 || useData.indexOf('.') !== -1) {
          keyPressed.preventDefault()
        }
        if (keyCode < 48 || keyCode > 57) {
          keyPressed.preventDefault()
        }
      } else {
        if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || useData.indexOf('.') !== -1)) {
          keyPressed.preventDefault()
        }
      }
      if (useData !== null && useData.indexOf('.') > -1 && (useData.split('.')[1].length > 1)) {
        keyPressed.preventDefault()
      }

      if ((useData === null || useData === '') && keyCode === 46) {
        keyPressed.preventDefault()
      }
    },
    isNumberComma1 (event, variable) {
      // let useData = event.target.value
      if (variable === '1') {
        this.attendance.moneyIncrease = event.toString().replace(/,/g, '')/* .replace(/[^.\d]/g, '') */.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === '2') {
        this.attendance.late = event.toString().replace(/,/g, '')/* .replace(/[^.\d]/g, '') */.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === '3') {
        this.attendance.rest = event.toString().replace(/,/g, '')/* .replace(/[^.\d]/g, '') */.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === '4') {
        this.attendance.rate = event.toString().replace(/,/g, '')/* .replace(/[^.\d]/g, '') */.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else if (variable === '7') {
        this.attendance.valueIn = event.toString().replace(/,/g, '')/* .replace(/[^.\d]/g, '') */.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
    },
    handleTimeChange (time, status) {
      if (time.HH && time.mm === '') {
        if (status) {
          this.attendance.startHour.mm = '00'
        } else {
          this.attendance.endHour.mm = '00'
        }
        time.mm = '00'
      }
    },
    onClickDatePicker (num) {
      let datePicker = null
      if (num === 1) {
        datePicker = document.getElementById('datepicker-custom1')
      } else {
        datePicker = document.getElementById('datepicker-custom2')
      }
      datePicker.focus()
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    }
  },
  data () {
    return {
      id: null,
      cln_id: null,
      user_id: null,
      mode: 'add',
      isMainType: false,
      isDisable: false,
      attendance: {
        nameWork: '',
        type: null,
        moneyIncrease: '',
        valueIn: '',
        startHour: null,
        endHour: null,
        late: '',
        rest: '',
        rate: '',
        mainType: null
      },
      supplier: {},
      products: {},
      supplierName1: 'ประเภท',
      options: {
        // https://momentjs.com/docs/#/displaying/
        format: 'HH:mm',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        sideBySide: true,
        icons: {
          time: 'fas fa-clock',
          date: 'fas fa-calendar',
          up: 'fas fa-chevron-up',
          down: 'fas fa-chevron-down',
          previous: 'fas fa-chevron-left',
          next: 'fas fa-chevron-right',
          today: 'fas fa-calendar-day',
          clear: 'fas fa-trash-alt',
          close: 'fas fa-times'
        },
        widgetPositioning: {
          horizontal: 'auto',
          vertical: 'bottom'
        }
      },
      options1: {
        // https://momentjs.com/docs/#/displaying/
        format: 'HH:mm',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: true,
        minDate: false
      },
      supplierOptions1: [
        /* { code: 'supplier1', label: 'Normal' },
        { code: 'supplier2', label: 'Late' },
        { code: 'supplier3', label: 'Lunch break' },
        { code: 'supplier4', label: 'OT' },
        { code: 'supplier5', label: 'Extra OT' },
        { code: 'supplier6', label: 'Other' } */
      ],
      supplierName2: 'ประเภทหลัก',
      supplierOptions2: [
        /* { code: 'supplier1', label: 'ปกติ' },
        { code: 'supplier2', label: 'WFH' } */
      ],
      columns: [
        { label: 'ประเภทการทำงาน', key: 'variable1', class: 'text-center' },
        { label: '01', key: 'variable2', class: 'text-center' },
        { label: '02', key: 'variable3', class: 'text-center' },
        { label: '03', key: 'variable4', class: 'text-center' },
        { label: '04', key: 'variable5', class: 'text-center' },
        { label: '05', key: 'variable6', class: 'text-center' },
        { label: '06', key: 'variable7', class: 'text-center' },
        { label: '07', key: 'variable8', class: 'text-center' },
        { label: '08', key: 'variable9', class: 'text-center' },
        { label: '09', key: 'variable10', class: 'text-center' },
        { label: '10', key: 'variable11', class: 'text-center' },
        { label: '11', key: 'variable12', class: 'text-center' },
        { label: '12', key: 'variable13', class: 'text-center' },
        { label: '13', key: 'variable14', class: 'text-center' },
        { label: '14', key: 'variable15', class: 'text-center' },
        { label: '15', key: 'variable16', class: 'text-center' },
        { label: '16', key: 'variable17', class: 'text-center' },
        { label: '17', key: 'variable18', class: 'text-center' },
        { label: '18', key: 'variable19', class: 'text-center' },
        { label: '19', key: 'variable20', class: 'text-center' },
        { label: '20', key: 'variable21', class: 'text-center' },
        { label: '21', key: 'variable22', class: 'text-center' },
        { label: '22', key: 'variable23', class: 'text-center' },
        { label: '23', key: 'variable24', class: 'text-center' }
      ],
      rows: [
        {
          variable1: 'ผลัดเช้า'
        },
        {
          variable1: 'ผลัดกลางคืน'
        },
        {
          variable1: 'งานขนส่ง'
        },
        {
          variable1: 'ผลัด OT พิเศษ'
        },
        {
          variable1: 'งานวันหยุด'
        },
        {
          variable1: 'ส่วน สนง'
        },
        {
          variable1: 'ปกติ รง'
        }
      ],
      currentPage: 1,
      perPage: 10
    }
  }
}
</script>
