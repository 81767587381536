<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="table-responsive">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span v-if="mode === 'add'" style="font-size: 25px; color: black;">กลุ่มสินค้า
                  (เพิ่ม)</span>
                <span v-else-if="mode === 'edit'"
                  style="font-size: 25px; color: black;">กลุ่มสินค้า (แก้ไข)</span>
                <span v-else style="font-size: 25px; color: black;">กลุ่มสินค้า (ดูรายละเอียด)</span>
              </div>
              <div v-if="mode === 'add' || mode === 'edit'" class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-6 pl-3 pr-2 my-1 my-sm-0 col-sm-auto">
                    <b-button type="submit" variant="primary" @click="handleClick" class="w-100 btn-min-size"><i class="save-data_ct"></i>บันทึก</b-button>
                  </div>
                  <div class="col-6 pl-2 pr-3 my-1 my-sm-0 col-sm-auto">
                    <b-button type="submit" variant="none" @click="handleClickGoBack" class="iq-bg-danger btn-cancel-border w-100 btn-min-size">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
              <div v-else class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="submit" variant="none" @click="handleClickGoBack" class="iq-bg-danger btn-cancel-border w-100 btn-min-size">ยกเลิก</b-button>
              </div>
            </div>
          </template>
         <!--  <template v-slot:headerTitle>
            <div v-if="mode !== 'view'" class="row py-4">
              <div class="" style="display: flex; align-items: center;">
                <span class="px-3 margin-text-header" style="font-size: 25px; color: black;">กลุ่มสินค้า {{ mode === 'edit' ? '(แก้ไข)' : mode === 'view' ? '(ดูรายละเอียด)' : '(เพิ่ม)'}}</span>
              </div>
              <div class="col-lg-1 col-md-1 col-sm-2 col-12" style="padding-left: 0;">
                <div class="d-flex my-2 mx-2" style="white-space: nowrap;">
                  <b-button @click="handleClick" type="submit" variant="primary" class="mx-1 flex-fill text-center" style="text-align: center;">บันทึก</b-button>
                  <b-button @click="handleClickGoBack" type="submit" variant="none" class="mx-2 flex-fill bg-danger text-center" style="text-align: center;">ยกเลิก</b-button>
                </div>
              </div>
            </div>
            <div v-else class="row py-4" style="display: flex; justify-content: flex-start;">
              <div class="col-lg-1 col-md-3 col-sm-2 col-12 apg-response-header"  style="display: flex; align-items: center;">
                <span class="Spadding-text-header" style="font-size: 25px; color: black; white-space: nowrap;">กลุ่มสินค้า {{ mode === 'edit' ? '(แก้ไข)' : mode === 'view' ? '(ดูรายละเอียด)' : '(เพิ่ม)'}}</span>
                <b-button @click="handleClickGoBack" type="submit" variant="none" class="mx-2 flex-fill bg-danger text-center apg-response-header-btn" style="text-align: center;">ยกเลิก</b-button>
              </div>
            </div>
          </template> -->
          <template v-slot:body>
            <div>
              <div class="ml-3">
                <b-text class="mr-5" style="white-space: nowrap; cursor: pointer;" @click="activeTab = 'tab1'" :style="{ color: activeTab === 'tab1' ? 'rgb(8, 159, 175)' : 'black' }">ชื่อกลุ่มสินค้าหลัก</b-text>
                <b-text class="mr-5" style="white-space: nowrap; cursor: pointer;" @click="activeTab = 'tab2'" :style="{ color: activeTab === 'tab2' ? 'rgb(8, 159, 175)' : 'black' }">ชื่อกลุ่มสินค้าย่อย</b-text>
              </div>
              <hr>

              <div v-if="activeTab === 'tab1'">
                <b-row>
                  <b-col class="">
                    <b-col lg="6" sm="6">
                        <b-text style="color: black;">รหัสกลุ่มสินค้าหลัก <span v-if="!productGroup1.code_product?.trim()" style="color: red;"> *</span></b-text>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-input type="text" :disabled="isDisable" v-model="productGroup1.code_product" placeholder=""></b-form-input>
                    </b-col>
                    <br>
                    <b-col lg="6" sm="6">
                        <b-text style="color: black;">ชื่อกลุ่มสินค้าหลัก <span v-if="!productGroup1.name_product?.trim()" style="color: red;"> *</span></b-text>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-input type="text" :disabled="isDisable" v-model="productGroup1.name_product" placeholder=""></b-form-input>
                    </b-col>
                    <br>
                    <!-- <div class="col-lg-3 col-md-3 col-sm-3 col-12 mt-3 mt-md-0">
                      <div class="d-flex justify-content-end">
                        <b-button @click="handleClick('tab1')" type="submit" variant="primary" class="w-100 text-center">บันทึก</b-button>
                        <b-button type="submit" @click="handleClickGoBack" variant="none" class="w-100 bg-danger text-center ml-2">ยกเลิก</b-button>
                      </div>
                    </div>
                    <br> -->
                  </b-col>
                </b-row>
              </div>

              <div v-if="activeTab === 'tab2'">
                <b-row>
                  <b-col class="">
                    <b-col lg="6" sm="6" class="">
                      <label class="pb-0 mb-0" style="color: black;">กลุ่มสินค้าหลัก <span v-if="!productGroup2.group_product_main" style="color: red;"> *</span></label>
                      <b-form-group style="color: black;" >
                      <v-select v-model="productGroup2.group_product_main" :disabled="isDisable" :options="optionsSelect"></v-select>
                      <!-- <b-form-select class="height-select" :disabled="isDisable" v-model="productGroup2.group_product_main" plain :options="optionsSelect" size="lg">
                          <template v-slot:first>
                            <b-form-select-option :value="null">------ Select --------</b-form-select-option>
                          </template>
                        </b-form-select> -->
                      </b-form-group>
                  </b-col>
                    <b-col lg="6" sm="6">
                        <b-text style="color: black;">รหัสกลุ่มสินค้าย่อย <span v-if="!productGroup2.group_supply_code?.trim()" style="color: red;"> *</span></b-text>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-input type="text" :disabled="isDisable" v-model="productGroup2.group_supply_code" placeholder=""></b-form-input>
                    </b-col>
                    <br>
                    <b-col lg="6" sm="6">
                        <b-text style="color: black;">ชื่อกลุ่มสินค้าย่อย <span v-if="!productGroup2.group_supply_name?.trim()" style="color: red;"> *</span></b-text>
                    </b-col>
                    <b-col lg="6" sm="6">
                      <b-form-input type="text" :disabled="isDisable" v-model="productGroup2.group_supply_name" placeholder=""></b-form-input>
                    </b-col>
                    <br>
                    <!-- <div class="col-lg-3 col-md-3 col-sm-3 col-12 mt-3 mt-md-0">
                      <div  v-if="mode !== 'view'" class="d-flex justify-content-end">
                        <b-button @click="handleClick('tab2')" type="submit" variant="primary" class="w-100 text-center">บันทึก</b-button>
                        <b-button type="submit" @click="handleClickGoBack" variant="none" class="w-100 bg-danger text-center ml-2">ยกเลิก</b-button>
                      </div>
                    </div>
                    <br> -->
                  </b-col>
                </b-row>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
.margin-text-header {
  margin-left: 1rem;
}
.Spadding-text-header {
  margin-left: 1rem;
  margin-right: 1rem;
}
@media (max-width: 560px) {
  .margin-text-header {
    margin-left: 0 !important;
  }
  .Spadding-text-header {
    margin-left: 0.5rem !important;
    margin-right: 0 !important;
  }
  .apg-response-header {
    display: flex;
    align-items: flex-start !important;
    flex-direction: column;
  }
  .apg-response-header-btn {
    width: 100% !important;
  }
  .iq-header-title {
    width: 100% !important;
  }
}
</style>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue from 'vue'
import axios from 'axios'
import { configAPI } from '../../configBase'

const dataTest = [
  {
    id: 1,
    variable1: 'Tiger Nixon',
    variable2: 'System Architect',
    variable3: 'Edinburgh',
    variable4: '61',
    variable5: '2011/04/25',
    variable6: '$320,800',
    editable: false
  },
  {
    id: 2,
    variable1: 'Garrett Winters',
    variable2: 'Accountant',
    variable3: 'Tokyo',
    variable4: '63',
    variable5: '2011/06/19',
    variable6: '$200,600',
    editable: false
  },
  {
    id: 3,
    variable1: 'Ashton Cox',
    variable2: 'Junior Technical Author',
    variable3: 'San Francisco',
    variable4: '69',
    variable5: '2011/01/20',
    variable6: '$140,500',
    editable: false
  },
  {
    id: 4,
    variable1: 'Cedric Kelly',
    variable2: 'Senior Javascript Developer',
    variable3: 'Edinburgh',
    variable4: '42',
    variable5: '2011/02/02',
    variable6: '$360,500',
    editable: false
  },
  {
    id: 5,
    variable1: 'Airi Satou',
    variable2: 'Accountant',
    variable3: 'Tokyo',
    variable4: '39',
    variable5: '2011/08/11',
    variable6: '$170,800',
    editable: false
  },
  {
    id: 6,
    variable1: 'Tiger Nixon',
    variable2: 'System Architect',
    variable3: 'Edinburgh',
    variable4: '61',
    variable5: '2011/04/25',
    variable6: '$320,800',
    editable: false
  },

  {
    id: 7,
    variable1: 'Airi Satou',
    variable2: 'Accountant',
    variable3: 'Tokyo',
    variable4: '39',
    variable5: '2011/08/11',
    variable6: '$170,800',
    editable: false
  },
  {
    id: 8,
    variable1: 'Tiger Nixon',
    variable2: 'System Architect',
    variable3: 'Edinburgh',
    variable4: '61',
    variable5: '2011/04/25',
    variable6: '$320,800',
    editable: false
  }
]
export default {
  name: 'UiDataTable',
  data () {
    return {
      mode: 'add',
      isDisable: false,
      productGroup1: {
        id: null,
        code_product: null,
        name_product: null
      },
      productGroup2: {
        group_product_main: null,
        group_supply_code: null,
        group_supply_name: null
      },
      showModal: false,
      imageUrl: null,
      imageUrl2: null,
      activeTab: 'tab1',
      currentPage: 1,
      perPage: 10,
      optionsSelect: [
        { code: 'System Architect', label: 'System Architect' },
        { code: 'Accountant', label: 'Accountant' },
        { code: 'Junior Technical Author', label: 'Junior Technical Author' },
        { code: 'Senior Javascript Developer', label: 'Senior Javascript Developer' },
        { code: 'Accountant1', label: 'Accountant1' },
        { code: 'System Architect', label: 'System Architect' },
        { code: 'Accountant2', label: 'Accountant2' },
        { code: 'Accountant1', label: 'Accountant1' }
      ]
    }
  },
  methods: {
    handleImageUpload (event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.imageUrl = reader.result
      }
    },
    handleImageUpload2 (event) {
      const file2 = event.target.files[0]
      const reader2 = new FileReader()
      reader2.readAsDataURL(file2)
      reader2.onload = () => {
        this.imageUrl2 = reader2.result
      }
    },
    handleClickGoBack () {
      if (this.mode === 'view') {
        router.go(-1)
      } else {
        Vue.swal.fire({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะออกจากหน้านี้ใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            router.go(-1)
          }
        })
      }
    },
    async saveProductGroupTab1 () {

    },
    async saveProductGroupTab2 () {

    },
    handleClick () {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${this.mode !== 'edit' ? 'คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่?' : 'คุณมั่นใจว่าคุณจะแก้ไขข้อมูลใช่หรือไม่?'}</span>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          const errorList = this.checkError(this.activeTab)
          if (errorList.length > 0) {
            let errMessage = ''
            for (let e = 0; e < errorList.length; e++) {
              errMessage += '<br/>' + errorList[e]
            }
            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลไม่สำเร็จ</span>',
              html: errMessage,
              icon: 'error',
              showConfirmButton: true,
              confirmButtonText: '<b-button style="font-size: 14px; text-align: center">Ok</b-button>',
              confirmButtonColor: '#089bab',
              customClass: {
                confirmButton: 'custom-confirm-button-class mx-1'
              }
            })
            return
          }
          if (this.activeTab === 'tab1') {
            await this.saveProductGroupTab1()
            if (this.mode !== 'edit') {
              const data = {
                no: '6',
                name: 'ยาแก้',
                is_use: true
              }
              await axios.post(`${configAPI.MD_PORT}/api/productGroup`, data, {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                }
              })
              /* .then(response => {
                  // console.log(response.data)
                })
                .catch(error => {
                  // console.error(error)
                }) */
              // console.log('this.productGroup1 add', this.productGroup1)
            } else {
              // console.log('this.productGroup1 edit', this.productGroup1)
            }
          } else {
            if (this.mode !== 'edit') {
              // console.log('this.productGroup2 add', this.productGroup2)
            } else {
              // console.log('this.productGroup2 edit', this.productGroup2)
            }
          }
          Vue.swal({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
            icon: 'success',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 2000
          }).then(() => {
            router.push('/ProductGroup')
            // router.go(-1)
          })
        }
      })
    },
    checkError (tab) {
      let error = []
      if (tab === 'tab1') {
        if (!this.productGroup1.code_product || this.productGroup1.code_product?.trim() === '') {
          error.push('กรุณากรอกรหัสกลุ่มสินค้าหลัก')
        }
        if (!this.productGroup1.name_product || this.productGroup1.name_product?.trim() === '') {
          error.push('กรุณากรอกชื่อกลุ่มสินค้าหลัก')
        }
      } else {
        if (!this.productGroup2.group_product_main || this.productGroup2.code_product === null) {
          error.push('กรุณาเลือก กลุ่มสินค้าหลัก')
        }
        if (!this.productGroup2.group_supply_code || this.productGroup2.group_supply_code?.trim() === '') {
          error.push('กรุณากรอกรหัสกลุ่มสินค้าย่อย')
        }
        if (!this.productGroup2.group_supply_name || this.productGroup2.group_supply_name?.trim() === '') {
          error.push('กรุณากรอกชื่อกลุ่มสินค้าย่อย')
        }
      }
      return error
    },
    onPagination (page) {
      router.go(-1)
      this.currentPage = page
    }
  },
  mounted () {
    const id = this.$route.params.id
    if (id) {
      this.mode = this.$route.params.setPage
    }
    if (this.mode === 'edit' || this.mode === 'view') {
      if (this.mode === 'view') {
        this.isDisable = true
      }
      const dataEdit = dataTest.find(el => el.id.toString() === id.toString())
      if (dataEdit) {
        // console.log('dataEdit', dataEdit)
        this.productGroup1.id = dataEdit.id
        this.productGroup1.code_product = dataEdit.variable1
        this.productGroup1.name_product = dataEdit.variable2
        this.productGroup2.group_product_main = { code: 'System Architect', label: 'System Architect' }
        this.productGroup2.group_supply_code = null
        this.productGroup2.group_supply_name = null
        if (dataEdit.variable3 || dataEdit.variable4) {
          this.productGroup2.group_supply_code = dataEdit.variable3
          this.productGroup2.group_supply_name = dataEdit.variable4
        }
      }
    }
    xray.index()
  }
}
</script>
