<template>
  <div>
    <div id="map"></div>
  </div>
</template>

<script>
export default {
  props: ['receiveValueLat', 'receiveValueLng'],
  data () {
    return {
      resultLocation: null,
      map: null,
      marker: null,
      geocoder: null,
      response: null
    }
  },
  mounted () {
    if (typeof google !== 'undefined') {
      // console.log('google')
      this.initMap()
    } else {
      // console.log('window.initMap')
      window.initMap = this.initMap()
    }
    // console.log('test :>> ', this.$store.state.getLatLngData.receiveValueLat)
    if (this.$store.state.getLatLngData.receiveValueLat && this.$store.state.getLatLngData.receiveValueLng) {
      // console.log('getLatLngFromInput')
      this.getLatLngFromInput()
    }
  },
  methods: {
    initMap () {
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(document.getElementById('map'), {
        zoom: 12,
        center: { lat: 13.75691706812609, lng: 100.50439121267011 },
        mapTypeControl: false
      })
      // eslint-disable-next-line no-undef
      this.geocoder = new google.maps.Geocoder()

      const inputText = document.createElement('input')
      inputText.type = 'text'
      inputText.className = 'S-location'
      inputText.placeholder = 'Enter a location'
      inputText.value = ''

      const submitButton = document.createElement('input')
      submitButton.type = 'button'
      submitButton.className = 'Search'
      submitButton.value = 'ค้นหา'
      submitButton.classList.add('button', 'button-primary')

      const clearButton = document.createElement('input')
      clearButton.type = 'button'
      clearButton.className = 'Del'
      clearButton.value = 'ลบ'
      clearButton.classList.add('button', 'button-secondary')

      this.response = document.createElement('pre')
      this.response.id = 'response'
      // response.innerText = ''
      // responseDiv = document.createElement('div')
      // responseDiv.id = 'response-container'
      // responseDiv.appendChild(response)
      // console.log('response', response)

      const instructionsElement = document.createElement('p')
      instructionsElement.id = 'instructions'
      instructionsElement.innerHTML =
      // eslint-disable-next-line no-undef
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(inputText)
      // eslint-disable-next-line no-undef
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(submitButton)
      // eslint-disable-next-line no-undef
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(clearButton)
      // eslint-disable-next-line no-undef
      this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(instructionsElement)
      // eslint-disable-next-line no-undef
      this.marker = new google.maps.Marker({
        this: this.map
      })

      this.map.addListener('click', (e) => {
        // console.log('this.geocode')
        this.geocode({ location: e.latLng })
      })

      submitButton.addEventListener('click', () => {
        this.geocode({ address: inputText.value })
      })

      clearButton.addEventListener('click', () => {
        // console.log('check')
        inputText.value = ''
        this.getLatLngFromInput()
        // this.clear()
      })

      // submitButton.addEventListener('click', () => {
      //   this.getLatLngFromInput()
      // })

      this.clear()
    },
    clear () {
      this.$store.dispatch('updateLatLngData', { lat: '', lng: '' })
      this.marker.setMap(null)
    },
    geocode (request) {
      // console.log('request', request)
      this.clear()
      this.geocoder
        .geocode(request)
        .then((result) => {
          const { results } = result
          // console.log('results', results[0])
          this.map.setCenter(results[0].geometry.location)
          this.marker.setPosition(results[0].geometry.location)
          this.marker.setMap(this.map)
          this.response.innerText = JSON.stringify(results[0], null, 2)
          let str = JSON.stringify(results[0], null, 2)
          str = '' + str
          let index = str.indexOf('location')
          str = str.substring(index)
          let index2 = str.indexOf('{')
          let index3 = str.indexOf('}')
          str = str.substring(index2, index3 + 1)
          // eslint-disable-next-line no-unused-vars
          // console.log('str', str)
          let resultLocation = JSON.parse(str)
          // console.log('resultLocation', resultLocation)
          this.updateSelectedLocation(resultLocation)
          this.updateSelectedLocationAddress(results[0].formatted_address)
          this.updateSelectedLocationAddressComponent(results[0].address_components)
          this.map.setZoom(14)

          return results
        })
        // .catch((e) => {
        //   alert('Geocode was not successful for the following reason: ' + e)
        // })
    },
    updateSelectedLocation (resultLocation) {
      // this.selectedLocation = `${selectedLocation.lat}, ${selectedLocation.lng}`
      this.$store.dispatch('updateLatLngData', resultLocation)
    },
    updateSelectedLocationAddress (address) {
      // this.selectedLocation = `${selectedLocation.lat}, ${selectedLocation.lng}`
      this.$store.dispatch('updateAddress', address)
    },
    updateSelectedLocationAddressComponent (addressComponent) {
      // this.selectedLocation = `${selectedLocation.lat}, ${selectedLocation.lng}`
      this.$store.dispatch('updateAddressComponent', addressComponent)
    },
    getLatLngFromInput () {
      // console.log('getLatLngFromInput')
      const receiveLat = this.$store.state.getLatLngData.receiveValueLat
      const receiveLng = this.$store.state.getLatLngData.receiveValueLng
      // const receiveAddress = this.$store.state
      // console.log('receiveAddress', receiveAddress)
      const lat = parseFloat(receiveLat)
      const lng = parseFloat(receiveLng)
      if (receiveLat && receiveLng) {
        // eslint-disable-next-line no-undef
        const location = new google.maps.LatLng(lat, lng)
        this.geocode({ location })
        this.map.setZoom(14)
      }
      // if (!isNaN(lat) && !isNaN(lng)) {
      //   // eslint-disable-next-line no-undef
      //   const location = new google.maps.LatLng(lat, lng)
      //   this.geocode({ location })
      //   this.map.setZoom(16)
      // }
    }
  }
}
</script>

<style>
#map {
  height: 100%;
}
input[type='text'].S-location {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
input[type='button'].Search {
  padding: 8px 16px;
  background-color: #089bab;
  color: white;
  border: none;
  border-radius: 5px;
  margin-left: 6px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
input[type='button'].Del {
  padding: 8px 16px;
  background-color: #e64141;
  color: white;
  border: none;
  border-radius: 5px;
  margin-left: 4px;
  cursor: pointer;
  width: 60px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
@media (max-width: 570px) {
  .S-location {
  width: 120px !important;
  }
}
@media (max-width: 320px) {
  .S-location {
  width: 120px !important;
  }
}
</style>
