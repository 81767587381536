<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4">
              <div class="col-auto">
                <span style="font-size: 25px; color: black;">ข้อมูลยอดขาย</span>
              </div>
              <div class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-12 pl-sm-3 pr-sm-1 my-1 my-sm-0">
                    <b-button type="submit" variant="primary" @click="saveSalesData" class="w-100 btn-min-size"><i
                        class="save-data_ct"></i>บันทึก</b-button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <b-row class="d-flex align-items-center mb-4">
              <b-col lg="3" md="4" sm="6" class="my-1 my-sm-0">
                <b-text style="color: black;">วันที่เริ่มต้น</b-text>
                  <div style="position: relative;">
                    <date-picker placeholder="MM/YYYY" id="datepicker-custom1" name="date" :config="optionsStart" v-model="filter.start_date"></date-picker>
                    <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(1)" style="cursor: pointer;"></i>
                  </div>
              </b-col>
              <b-col lg="3" md="4" sm="6" class="my-1 my-sm-0">
                <b-text style="color: black;">วันที่สิ้นสุด</b-text>
                  <div style="position: relative;">
                    <date-picker placeholder="MM/YYYY" id="datepicker-custom2" name="date" :config="options" v-model="filter.end_date"></date-picker>
                    <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(2)" style="cursor: pointer;"></i>
                  </div>
              </b-col>
              <b-col lg="2" md="2" sm="12" class="d-flex align-items-center">
                <b-button @click="filterSalesData" type="submit" variant="primary" class="buttonwidth mt-4" style="min-width: 100px;"><i class="search_ct"></i>ค้นหา</b-button>
              </b-col>
            </b-row>
            <div class="mx-3">
              <b-row>
                <b-col cols="12" class="table-responsive" style="padding: 0px;">
                  <b-table bordered hover :items="rows" :fields="columns" :current-page="currentPage" :per-page="perPage"
                    @pagination="onPagination" :tbody-tr-class="rowClass" thead-class="table-header-color">
                    <template v-for="(column, columnIndex) in columns" v-slot:[`cell(${column.key})`]="data">
                      <template v-if="column.key === 'variable1'">
                        <span :key="column.key">{{ data.item.variable1 }}</span>
                      </template>
                      <template v-if="column.key === 'variable2'">
                        <span :key="column.key">{{ data.item.variable2 }}</span>
                      </template>
                      <template v-if="column.key !== 'variable1' && column.key !== 'variable2' && column.key !== 'sale_id'">
                        <CurrencyInputCom :key="column.key + columnIndex + currentPage" :id="columnIndex" :options="optionCurrency" v-model="data.item[column.key].target_values"/>
                        <div v-if="data.item[column.key].total_current !== null" :key="column.key">
                          <span v-if="(parseFloat(data.item[column.key].target_values?.toString().replace(/,/g, '')) || 0) <= parseFloat(data.item[column.key].total_current?.replace(/,/g, ''))"
                            style="display: flex; justify-content: flex-end; color: #0DC268"
                            :key="column.key"
                          >
                            {{data.item[column.key].total_current}}
                          </span>
                          <span v-if="(parseFloat(data.item[column.key].target_values?.toString().replace(/,/g, '')) || 0) > parseFloat(data.item[column.key].total_current?.replace(/,/g, ''))"
                            style="display: flex; justify-content: flex-end; color:#E03C3C"
                            :key="column.key"
                          >
                            {{data.item[column.key].total_current}}
                          </span>
                        </div>
                        <!-- <div v-if="data.item[`total_current/${column.key}`] === undefined" :key="column.key">
                          <span style="display: flex; justify-content: flex-end; color: #000000"
                            :key="column.key"
                          >
                            0.00
                          </span>
                        </div> -->
                      </template>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row style="display: flex; justify-content: center;">
                <b-pagination v-model="currentPage" :total-rows="rows.length" :per-page="perPage" />
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
import Vue from 'vue'
import axios from 'axios'
import { configAPI } from '../../configBase'
import CurrencyInputCom from './CurrencyInputCom.vue'
import moment from 'moment'
moment.locale('th')

const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}

export default {
  name: 'SalesData',
  components: {
    CurrencyInputCom
  },
  data () {
    return {
      cln_id: null,
      usr_id: null,
      optionCurrency: {
        currency: 'EUR',
        currencyDisplay: 'hidden',
        valueRange: {
          min: 0,
          max: 1e+53
        },
        precision: 0,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: false,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        useGrouping: true,
        accountingSign: false
      },
      dataForSave: [],
      columns: [
        { label: 'ชื่อ-นามสกุล', key: 'variable1', class: 'text-center table-size-200-400' },
        { label: 'ชื่อเล่น', key: 'variable2', class: 'text-center table-size-200-400' }
      ],
      rows: [],
      currentPage: 1,
      perPage: 10,
      monthList: [],
      optionsStart: {
        format: 'MM/YYYY',
        useCurrent: false,
        showClear: false,
        showClose: true,
        useStrict: true,
        sideBySide: true,
        maxDate: false,
        icons: iconDate
      },
      options: {
        format: 'MM/YYYY',
        useCurrent: false,
        showClear: false,
        showClose: true,
        useStrict: true,
        sideBySide: true,
        minDate: false,
        icons: iconDate
      },
      filter: {
        start_date: moment(new Date()).subtract(1, 'M').format('MM/YYYY'),
        end_date: moment(new Date()).add(2, 'M').format('MM/YYYY')
      }
    }
  },
  async mounted () {
    this.cln_id = JSON.parse(sessionStorage.getItem('user')).cln_id
    this.usr_id = JSON.parse(sessionStorage.getItem('user')).usr_id
    await this.filterSalesData()
    xray.index()
  },
  watch: {
    'filter.start_date' (val) {
      if (val === null) {
        this.filter.end_date = null
      } else {
        this.options.minDate = val
      }
    },
    'filter.end_date' (val) {
      if (val === null) {
        this.filter.start_date = null
      } else {
        this.optionsStart.maxDate = val
      }
    }
  },
  methods: {
    async filterSalesData () {
      let dataTemp = {
        sale_id: this.usr_id,
        start_date: this.filter.start_date ? moment(this.filter.start_date, 'MM/YYYY').format('YYYY-MM-DD') : null,
        end_date: this.filter.end_date ? moment(this.filter.end_date, 'MM/YYYY').endOf('month').format('YYYY-MM-DD') : null
      }
      // console.log('dataTemp', dataTemp)
      await axios.request({
        method: 'post',
        url: `${configAPI.MD_PORT}/api/clinic/${this.cln_id}/filterSalesData`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataTemp
      })
        .then((response) => {
          if (response.data.status) {
            let tempData = response.data.data
            // console.log('tempData', tempData)
            // columns section
            // reset columns
            this.columns = [
              { label: 'ชื่อ-นามสกุล', key: 'variable1', class: 'text-center table-size-200-400' },
              { label: 'ชื่อเล่น', key: 'variable2', class: 'text-center table-size-200-400' }
            ]
            // insert columns
            let numberMonth = moment(this.filter.end_date, 'MM/YYYY').diff(moment(this.filter.start_date, 'MM/YYYY'), 'months')
            // console.log('numberMonth', numberMonth)
            for (let i = 0; i <= numberMonth; i++) {
              this.columns.push(
                { label: moment(this.filter.start_date, 'MM/YYYY').add(i, 'M').format('MMM YY'), key: moment(this.filter.start_date, 'MM/YYYY').add(i, 'M').format('YYYY_M'), class: 'text-center table-size-200-400' }
              )
            }
            // rows section
            // reset rows
            this.rows = []
            // insert rows
            if (tempData.length > 0) {
              tempData.map((item) => {
                let tempRow = {
                  sale_id: item.sale_id,
                  variable1: item.sale_fullname,
                  variable2: item.sale_nickname
                }
                for (let i = 0; i <= numberMonth; i++) {
                  let month = moment(this.filter.start_date, 'MM/YYYY').add(i, 'M').format('YYYY_M')
                  // console.log('month', month)
                  let targetMonth = item.sale_target.find((item2) => {
                    // console.log('YYYY_MM ==> ', (item2.year).toString() + '_' + (item2.month).toString())
                    return ((item2.year).toString() + '_' + (item2.month).toString()) === month
                  })
                  // console.log('targetMonth', targetMonth)
                  if (targetMonth) {
                    let tempTarget = {
                      target_id: targetMonth?.target_id,
                      target_values: targetMonth?.target_values ? this.isNumberComma(targetMonth.target_values) : null,
                      total_current: targetMonth?.total_current ? this.isNumberComma(parseFloat(targetMonth.total_current).toFixed(2)) : '0.00'
                    }
                    tempRow[month] = tempTarget
                  } else {
                    let tempTarget = {
                      target_id: null,
                      target_values: null,
                      total_current: '0.00'
                    }
                    tempRow[month] = tempTarget
                  }
                }
                // console.log('tempRow', tempRow)
                this.rows.push(tempRow)
                // console.log('this.rows', this.rows[0]['2023_10']['target_values'])
              })
            }
          }
        })
        .catch((error) => {
          this.SwalError(error.message, 'เกิดข้อผิดพลาดในการดึงข้อมูล')
        })
    },
    onPagination (page) {
      this.currentPage = page
    },
    isNumberComma (value) {
      return value?.toString().replace(/[^.\d]/g, '').replace(/\B(?<!\.\d+)(?=(\d{3})+(?!\d))/g, ',')
    },
    async saveSalesData () {
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าคุณจะบันทึกข้อมูลใช่หรือไม่</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        // console.log('saveSalaryEmployee')
        if (result.isConfirmed) {
          let columnLength = this.columns.length
          let dataTemp = []
          this.rows.map((item) => {
            for (let i = 0; i < columnLength; i++) {
              let month = this.columns[i].key
              if (month !== 'variable1' && month !== 'variable2') {
                // console.log('month', month)
                let tempData = {
                  sale_id: item.sale_id,
                  target_id: item[month].target_id,
                  target_values: item[month].target_values ? item[month].target_values?.toString().replace(/,/g, '') : null,
                  year: month.split('_')[0],
                  month: month.split('_')[1],
                  usr_id: this.usr_id
                }
                dataTemp.push(tempData)
              }
            }
          })
          // console.log('dataTemp', dataTemp)
          await axios.request({
            method: 'post',
            url: `${configAPI.MD_PORT}/api/clinic/${this.cln_id}/saveSalesData`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: dataTemp
          }).then(async (response) => {
            if (response.data.status) {
              Vue.swal({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
                icon: 'success',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000
              }).then(async () => {
                await this.filterSalesData()
              })
            }
          }).catch((error) => {
            this.SwalError(error.message, 'บันทึกข้อมูลไม่สำเร็จ')
          })
        }
      })
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.id % 2 === 1) return 'table-body-color'
    },
    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    }
  }
}
</script>
