<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">
              <div class="col-auto">
                <span v-if="!id" style="font-size: 25px; color: black;">Open Link Doctor</span>
                <span v-else style="font-size: 25px; color: black;">Open Link Doctor (ดูรายละเอียด)</span>
              </div>
              <div v-if="!id" class="col-12 col-sm-auto">
                <div class="row">
                  <div class="col-6 pl-3 pr-2 my-1 my-sm-0 col-sm-auto">
                    <b-button type="submit" variant="primary" @click="clickSave" class="w-100 btn-min-size"><i
                        class="save-data_ct"></i>บันทึก</b-button>
                  </div>
                  <div class="col-6 pl-2 pr-3 my-1 my-sm-0 col-sm-auto">
                    <b-button type="submit" variant="none" @click="clickCancel"
                      class="iq-bg-danger w-100 btn-min-size btn-cancel-border">ยกเลิก</b-button>
                  </div>
                </div>
              </div>
              <div v-else class="col-12 col-sm-auto my-1 my-sm-0">
                <b-button type="submit" variant="none" @click="clickCancel"
                  class="iq-bg-danger w-100 btn-min-size btn-cancel-border">ยกเลิก</b-button>
              </div>
            </div>
          </template>
          <template v-slot:body>
            <div class="mx-3">
              <b-row>
                <b-col sm="12" lg="8">
                  <b-row>
                    <b-col cols="12" md="6" xl="4">
                      <b-form-group label="ชื่อ" label-for="exampleInputText1">
                        <template v-if="OLDData.data[0].name === '' || OLDData.data[0].name === null || OLDData.data[0].name === undefined" v-slot:label>
                          ชื่อ<span class="text-danger"> *</span>
                        </template>
                        <b-form-input v-model="OLDData.data[0].name" id="exampleInputText1" type="text" placeholder=""
                          :disabled="disableCheck" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" xl="4">
                      <b-form-group label="นามสกุล" label-for="exampleInputText1">
                        <template v-if="OLDData.data[0].surname === '' || OLDData.data[0].surname === null  || OLDData.data[0].surname === undefined" v-slot:label>
                          นามสกุล<span class="text-danger"> *</span>
                        </template>
                        <b-form-input v-model="OLDData.data[0].surname" id="exampleInputText1" type="text" placeholder=""
                          :disabled="disableCheck" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" xl="2">
                      <b-form-group label-for="exampleFormControlSelect1" label="เพศ">
                        <template v-if="OLDData.data[0].gender_id === '' || OLDData.data[0].gender_id === null  || OLDData.data[0].gender_id === undefined" v-slot:label>
                          เพศ<span class="text-danger"> *</span>
                        </template>
                        <v-select v-if="OLDData.data[0].gender_name" v-model="OLDData.data[0].gender_name" :options="genderDD" :disabled="disableCheck"></v-select>
                        <v-select v-else v-model="genderOption" :options="genderDD" :disabled="disableCheck"></v-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" xl="2">
                      <b-form-group label="อายุ" label-for="exampleInputText1">
                        <template v-if="OLDData.data[0].age === '' || OLDData.data[0].age === null  || OLDData.data[0].age === undefined" v-slot:label>
                          อายุ<span class="text-danger"> *</span>
                        </template>
                        <b-form-input v-model="OLDData.data[0].age" id="exampleInputText1" type="text" placeholder=""
                          :disabled="disableCheck" @keypress="isNumberCheck($event)" @input="validateAge($event)" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group label="เลขที่ใบประกอบวิชาชีพ" label-for="exampleInputText1">
                        <template v-if="OLDData.data[0].no === '' || OLDData.data[0].no === null  || OLDData.data[0].no === undefined" v-slot:label>
                          เลขที่ใบประกอบวิชาชีพ<span class="text-danger"> *</span>
                        </template>
                        <b-form-input v-model="OLDData.data[0].no" id="exampleInputText1" type="text" placeholder=""
                          :disabled="disableCheck" @keypress="isNumberCheck($event)" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="สาขาเฉพาะทาง" label-for="exampleInputText1">
                        <template v-if="OLDData.data[0].specialized === '' || OLDData.data[0].specialized === null  || OLDData.data[0].specialized === undefined" v-slot:label>
                          สาขาเฉพาะทาง<span class="text-danger"> *</span>
                        </template>
                        <b-form-input v-model="OLDData.data[0].specialized" id="exampleInputText1" type="text"
                          placeholder="" :disabled="disableCheck" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group label="เบอร์โทรศัพท์ติดต่อ" label-for="exampleInputText1">
                        <template v-if="OLDData.data[0].tel === '' || OLDData.data[0].tel === null  || OLDData.data[0].tel === undefined || OLDData.data[0].tel?.length < 12" v-slot:label>
                          เบอร์โทรศัพท์ติดต่อ<span class="text-danger"> *</span>
                        </template>
                        <b-form-input v-model="OLDData.data[0].tel" id="exampleInputText1" type="text" placeholder=""
                          :disabled="disableCheck" @keypress="isPhoneCheck($event)" @input="formatContact($event)" v-mask="'###-###-####'" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="E-mail" label-for="exampleInputText1">
                        <template v-if="OLDData.data[0].email === '' || OLDData.data[0].email === null  || OLDData.data[0].email === undefined" v-slot:label>
                          E-mail<span class="text-danger"> *</span>
                        </template>
                        <b-form-input v-model="OLDData.data[0].email" id="exampleInputText1" type="email" placeholder=""
                          :disabled="disableCheck" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group label-for="exampleFormControlSelect1" label="พื้นที่สะดวกรับงาน">
                        <template v-if="OLDData.data[0].aw_id === '' || OLDData.data[0].aw_id === null  || OLDData.data[0].aw_id === undefined" v-slot:label>
                          พื้นที่สะดวกรับงาน<span class="text-danger"> *</span>
                        </template>
                        <v-select v-if="OLDData.data[0].aw_name" v-model="OLDData.data[0].aw_name" :options="genderDD" :disabled="disableCheck"></v-select>
                        <v-select v-else v-model="areaworkOption" :options="areaworkDD" :disabled="disableCheck"></v-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="เลขบัญชีสำหรับโอนเงิน" label-for="exampleInputText1">
                        <template v-if="OLDData.data[0].account_no === '' || OLDData.data[0].account_no === null  || OLDData.data[0].account_no === undefined" v-slot:label>
                          เลขบัญชีสำหรับโอนเงิน<span class="text-danger"> *</span>
                        </template>
                        <b-form-input v-model="OLDData.data[0].account_no" id="exampleInputText1" type="text"
                          placeholder="" :disabled="disableCheck" @keypress="isNumberCheck($event)" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group label-for="exampleFormControlSelect1" label="ธนาคาร">
                        <template v-if="OLDData.data[0].bank_id === '' || OLDData.data[0].bank_id === null  || OLDData.data[0].bank_id === undefined" v-slot:label>
                          ธนาคาร<span class="text-danger"> *</span>
                        </template>
                        <v-select v-if="OLDData.data[0].bank_name" v-model="OLDData.data[0].bank_name" :options="bankDD" :disabled="disableCheck"></v-select>
                        <v-select v-else v-model="bankOption" :options="bankDD" :disabled="disableCheck"></v-select>
                        <!-- <v-select :disabled="disableCheck" :options="bank"></v-select> -->
                        <!-- <b-form-select plain size="lg" id="exampleFormControlSelect1" :disabled="disableCheck">
                          <template>
                          </template>
                        </b-form-select> -->
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="ชื่อบัญชี" label-for="exampleInputText1">
                        <template v-if="OLDData.data[0].account_name === '' || OLDData.data[0].account_name === null  || OLDData.data[0].account_name === undefined" v-slot:label>
                          ชื่อบัญชี<span class="text-danger"> *</span>
                        </template>
                        <b-form-input v-model="OLDData.data[0].account_name" id="exampleInputText1" type="text"
                          placeholder="" :disabled="disableCheck" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group label="ประเภทงานที่รับ" label-for="exampleCheckbox1">
                        <template v-if="!OLDData.data[0].is_receive" v-slot:label>
                          ประเภทงานที่รับ<span class="text-danger"> *</span>
                        </template>
                        <!-- <template v-if="OLDData.data[0].is_receive === '' || OLDData.data[0].is_receive === null  || OLDData.data[0].is_receive === undefined" v-slot:label>
                          ประเภทงานที่รับ<span class="text-danger"> *</span>
                        </template> -->
                        <b-row>
                          <b-col>
                            <b-form-checkbox v-model="OLDData.data[0].is_receive" class="mx-2 mb-1"
                              style="white-space: nowrap;" value="Injection"
                              :disabled="disableCheck">Injection</b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox v-model="OLDData.data[0].is_receive" class="mx-2"
                              style="white-space: nowrap;" value="Surgery"
                              :disabled="disableCheck">Surgery</b-form-checkbox>
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="มาตรฐานรับงาน" label-for="exampleCheckbox2">
                        <template v-if="!OLDData.data[0].standart_work" v-slot:label>
                          มาตรฐานรับงาน<span class="text-danger"> *</span>
                        </template>
                        <b-row>
                          <b-col>
                            <b-form-checkbox v-model="OLDData.data[0].standart_work" class="mx-2 mb-1"
                              style="white-space: nowrap;" value="อย." :disabled="disableCheck">อย.
                              เท่านั้น</b-form-checkbox>
                          </b-col>
                          <b-col>
                            <b-form-checkbox v-model="OLDData.data[0].standart_work" class="mx-2"
                              style="white-space: nowrap;" value="อื่นๆ" :disabled="disableCheck">อื่นๆ</b-form-checkbox>
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group label="ไม่รับทำบริเวณ" label-for="exampleInputText1">
                        <template v-if="OLDData.data[0].no_area === '' || OLDData.data[0].no_area === null  || OLDData.data[0].no_area === undefined" v-slot:label>
                          ไม่รับทำบริเวณ<span class="text-danger"> *</span>
                        </template>
                        <b-form-input v-model="OLDData.data[0].no_area" id="exampleInputText1" type="text" placeholder=""
                          :disabled="disableCheck" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="ช่วงเวลาที่สะดวกรับงาน" label-for="exampleInputText1">
                        <template v-if="OLDData.data[0].work_range === '' || OLDData.data[0].work_range === null  || OLDData.data[0].work_range === undefined" v-slot:label>
                          ช่วงเวลาที่สะดวกรับงาน<span class="text-danger"> *</span>
                        </template>
                        <b-form-input v-model="OLDData.data[0].work_range" id="exampleInputText1" type="text"
                          placeholder="" :disabled="disableCheck" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group label-for="exampleFormControlSelect1" label="ค่าเวรขั้นต่ำที่รับ">
                        <template v-if="OLDData.data[0].mi_id === '' || OLDData.data[0].mi_id === null  || OLDData.data[0].mi_id === undefined" v-slot:label>
                          ค่าเวรขั้นต่ำที่รับ<span class="text-danger"> *</span>
                        </template>
                        <v-select v-if="OLDData.data[0].mi_name" v-model="OLDData.data[0].mi_name" :options="genderDD" :disabled="disableCheck"></v-select>
                        <v-select v-else v-model="minincomeOption" :options="minincomeDD" :disabled="disableCheck"></v-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                       <b-form-group label="เลขบัตรประชาชน" label-for="exampleInputText1">
                        <template v-if="OLDData.data[0].citizen_id === '' || OLDData.data[0].citizen_id === null  || OLDData.data[0].citizen_id === undefined || OLDData.data[0].citizen_id?.length < 17" v-slot:label>
                          เลขบัตรประชาชน<span class="text-danger"> *</span>
                        </template>
                        <b-form-input
                          v-model="OLDData.data[0].citizen_id"
                          id="exampleInputText1"
                          type="text"
                          placeholder=""
                          :disabled="disableCheck"
                          v-mask="'#-####-#####-##-#'"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col sm="12" lg="4">
                  <b-form-group label-for="imageInput4" label="อัปโหลดรูปโปรไฟล์">
                    <template v-if="OLDData.data[0].profile_img === '' || OLDData.data[0].profile_img === null  || OLDData.data[0].profile_img === undefined" v-slot:label>
                      อัปโหลดรูปโปรไฟล์<span :hidden="OLD.file4" class="text-danger"> *</span>
                    </template>
                    <b-form-file v-if="OLDData.data[0].profile_img" class="custom-file-style" id="imageInput4"
                      :placeholder="OLDData.data[0].profile_img" accept="image/*"
                      @change="handleImageUpload4" :disabled="disableCheck"></b-form-file>
                    <b-form-file v-else class="custom-file-style" id="imageInput4" accept="image/*" ref="fileInput"
                      @change="handleImageUpload4" :disabled="disableCheck"></b-form-file>
                  </b-form-group>
                  <b-form-group label-for="imageInput" label="อัปโหลดรูปบัตรประชาชน">
                    <template v-if="OLDData.data[0].citizen_img === '' || OLDData.data[0].citizen_img === null  || OLDData.data[0].citizen_img === undefined" v-slot:label>
                      อัปโหลดรูปบัตรประชาชน<span :hidden="OLD.file1" class="text-danger"> *</span>
                    </template>
                    <b-form-file v-if="OLDData.data[0].citizen_img" class="custom-file-style" id="imageInput"
                      :placeholder="OLDData.data[0].citizen_img" accept="image/*"
                      @change="handleImageUpload" :disabled="disableCheck"></b-form-file>
                    <b-form-file v-else class="custom-file-style" id="imageInput" accept="image/*" ref="fileInput"
                      @change="handleImageUpload" :disabled="disableCheck"></b-form-file>
                  </b-form-group>
                  <b-form-group label-for="imageInput2" label="อัปโหลดรูปหน้าบัญชี">
                    <template v-if="OLDData.data[0].account_img === '' || OLDData.data[0].account_img === null  || OLDData.data[0].account_img === undefined" v-slot:label>
                      อัปโหลดรูปหน้าบัญชี<span :hidden="OLD.file2" class="text-danger"> *</span>
                    </template>
                    <b-form-file v-if="OLDData.data[0].account_img" class="custom-file-style" id="imageInput2" accept="image/*"
                      :placeholder="OLDData.data[0].account_img"
                      @change="handleImageUpload2" :disabled="disableCheck"></b-form-file>
                    <b-form-file v-else class="custom-file-style" id="imageInput2" accept="image/*" ref="fileInput2"
                      @change="handleImageUpload2" :disabled="disableCheck"></b-form-file>
                  </b-form-group>
                  <b-form-group label-for="imageInput3" label="อัปโหลดรูปใบประกอบอาชีพ">
                    <template v-if="OLDData.data[0].specialized_img === '' || OLDData.data[0].specialized_img === null  || OLDData.data[0].specialized_img === undefined" v-slot:label>
                      อัปโหลดรูปใบประกอบอาชีพ<span :hidden="OLD.file3" class="text-danger"> *</span>
                    </template>
                    <b-form-file v-if="OLDData.data[0].specialized_img" class="custom-file-style" id="imageInput3" accept="image/*"
                      :placeholder="OLDData.data[0].specialized_img"
                      @change="handleImageUpload3" :disabled="disableCheck"></b-form-file>
                    <b-form-file v-else class="custom-file-style" id="imageInput3" accept="image/*" ref="fileInput3"
                      @change="handleImageUpload3" :disabled="disableCheck"></b-form-file>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
import router from '@/router'
import Vue, { watch } from 'vue'
import axios from 'axios'
import vSelect from 'vue-select'
import { configAPI } from '../../configBase'

export default {
  name: 'UiDataTable',
  components: { vSelect },
  data () {
    return {
      OLDData: {
        data: [
          {
            name: null,
            age: null,
            no: null,
            specialized: null,
            tel: null,
            email: null,
            aw_id: null,
            account_no: null,
            bank_id: null,
            bank_name: null,
            account_name: null,
            is_receive: null,
            no_area: null,
            work_range: null,
            mi_id: null,
            standart_work: null,
            gender_id: null,
            gender_name: null,
            citizen_id: null,
            citizen_img: null
          }
        ]
      },
      OLD: {
        file1: null,
        file2: null,
        file3: null,
        file4: null,
        imageName: null,
        imageUrl: null,
        imageName2: null,
        imageUrl2: null,
        imageName3: null,
        imageUrl3: null,
        imageName4: null,
        imageUrl4: null
      },
      areaworkDD: [],
      areaworkOption: null,
      minincomeDD: [],
      minincomeOption: null,
      genderDD: [],
      genderOption: null,
      bankDD: [],
      bankOption: null,
      inputValue: 0,
      selectedOption: '',
      showModal: false,
      // imageUrl: null,
      // imageUrl2: null,
      // imageUrl3: null,
      activeTab: 'tab1',
      currentPage: 1,
      perPage: 10,
      id: '',
      allowedTypes: [
        'image/png',
        'image/jpeg'
        // 'text/csv',
        // 'application/msword',
        // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        // 'application/pdf',
        // 'application/vnd.ms-excel',
        // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ],
      inputData: {
        phoneNumber: ''
      },
      disableCheck: false,
      selectValue: '',
      option: [
        { name: 'test', value: 'option1' }
      ]
    }
  },
  methods: {
    getApproveData () {
      let id = this.$route.params.id
      let approveDataAPI = {
        method: 'get',
        url: `${configAPI.MA_PORT}/api/doctor/` + id,
        headers: {
          'Content-Type': 'application/json'
        }
      }

      axios.request(approveDataAPI)
        .then((response) => {
          this.OLDData.data[0] = response.data.data[0]
          const fullName = response.data.data[0].name
          const nameParts = fullName.split(' ')
          if (nameParts.length === 2) {
            const firstName = nameParts[0]
            const lastName = nameParts[1]
            this.OLDData.data[0].name = firstName
            this.OLDData.data[0].surname = lastName
          }
        })
    },
    convertToJson () {
      let dataList = {}
      dataList = {
        name: this.OLDData.data[0].name + ' ' + this.OLDData.data[0].surname || null,
        age: this.OLDData.data[0].age,
        cer_no: this.OLDData.data[0].no,
        specialized: this.OLDData.data[0].specialized,
        tel: this.OLDData.data[0].tel,
        email: this.OLDData.data[0].email,
        aw_id: this.OLDData.data[0].aw_id,
        account_no: this.OLDData.data[0].account_no,
        // bank_id: this.OLDData.data[0].bank_id,
        bank_id: this.OLDData.data[0].bank_id,
        account_name: this.OLDData.data[0].account_name,
        is_receive: this.OLDData.data[0].is_receive,
        no_area: this.OLDData.data[0].no_area,
        work_range: this.OLDData.data[0].work_range,
        mi_id: this.OLDData.data[0].mi_id,
        standart_work: this.OLDData.data[0].standart_work,
        gender_id: this.OLDData.data[0].gender_id,
        citizen_id: this.OLDData.data[0].citizen_id
      }
      return dataList
    },
    async checkError () {
      let errorList = []
      // const regex = /[ก-๙]/
      const regexNumber = /^[0-9]+$/
      // const regex1 = /[A-Za-z]/
      if (this.OLDData.data[0].name?.trim() === null || this.OLDData.data[0].name?.trim() === '' || this.OLDData.data[0].name?.trim() === undefined) {
        errorList.push('กรุณากรอกชื่อ')
      }
      if (this.OLDData.data[0].surname?.trim() === null || this.OLDData.data[0].surname?.trim() === '' || this.OLDData.data[0].surname?.trim() === undefined) {
        errorList.push('กรุณากรอกนามสกุล')
      }
      if (this.OLDData.data[0].gender_id?.trim() === null || this.OLDData.data[0].gender_id?.trim() === '' || this.OLDData.data[0].gender_id?.trim() === undefined) {
        errorList.push('กรุณาเลือกเพศ')
      }
      if (this.OLDData.data[0].age?.trim() === null || this.OLDData.data[0].age?.trim() === '' || this.OLDData.data[0].age?.trim() === undefined) {
        errorList.push('กรุณากรอกอายุ')
      }
      if (this.OLDData.data[0].age?.length !== 0 && this.OLDData.data[0].age && !regexNumber.test(this.OLDData.data[0].age)) {
        errorList.push('อายุ ต้องเป็นตัวเลขเท่านั้น')
      }
      if (this.OLDData.data[0].no?.trim() === null || this.OLDData.data[0].no?.trim() === '' || this.OLDData.data[0].no?.trim() === undefined) {
        errorList.push('กรุณากรอกเลขที่ใบประกอบวิชาชีพ')
      }
      if (this.OLDData.data[0].no?.length !== 0 && this.OLDData.data[0].no && !regexNumber.test(this.OLDData.data[0].no)) {
        errorList.push('เลขที่ใบประกอบวิชาชีพ ต้องเป็นตัวเลขเท่านั้น')
      }
      if (this.OLDData.data[0].specialized?.trim() === null || this.OLDData.data[0].specialized?.trim() === '' || this.OLDData.data[0].specialized?.trim() === undefined) {
        errorList.push('กรุณากรอกสาขาเฉพาะทาง')
      }
      if (this.OLDData.data[0].tel?.trim() === null || this.OLDData.data[0].tel?.trim() === '' || this.OLDData.data[0].tel?.trim() === undefined || this.OLDData.data[0].tel?.length === 0) {
        errorList.push('กรุณากรอกเบอร์โทรศัพท์ติดต่อ')
      }
      if (this.OLDData.data[0].tel?.length < 12 && this.OLDData.data[0].tel?.length !== 0 && (this.OLDData.data[0].tel?.trim() !== null || this.OLDData.data[0].tel?.trim() !== '' || this.OLDData.data[0].tel?.trim() !== undefined)) {
        errorList.push('กรุณากรอกเบอร์โทรศัพท์ติดต่อให้ครบ 10 หลัก')
      }
      // if (this.OLDData.data[0].tel?.length !== 0 && this.OLDData.data[0].tel && !regexNumber.test(this.OLDData.data[0].tel)) {
      //   errorList.push('เบอร์โทรศัพท์ ต้องเป็นตัวเลขเท่านั้น')
      // }
      if (this.OLDData.data[0].email?.trim() === null || this.OLDData.data[0].email?.trim() === '' || this.OLDData.data[0].email?.trim() === undefined) {
        errorList.push('กรุณากรอก E-mail')
      }
      if (this.OLDData.data[0].aw_id?.trim() === null || this.OLDData.data[0].aw_id?.trim() === '' || this.OLDData.data[0].aw_id?.trim() === undefined) {
        errorList.push('กรุณาเลือกพื้นที่สะดวกรับงาน')
      }
      if (this.OLDData.data[0].account_no?.trim() === null || this.OLDData.data[0].account_no?.trim() === '' || this.OLDData.data[0].account_no?.trim() === undefined) {
        errorList.push('กรุณากรอกเลขบัญชีสำหรับโอนเงิน')
      }
      if (this.OLDData.data[0].account_no?.length !== 0 && this.OLDData.data[0].account_no && !regexNumber.test(this.OLDData.data[0].account_no)) {
        errorList.push('เลขบัญชีสำหรับโอนเงิน ต้องเป็นตัวเลขเท่านั้น')
      }
      if (this.OLDData.data[0].bank_id?.trim() === null || this.OLDData.data[0].bank_id?.trim() === '' || this.OLDData.data[0].bank_id?.trim() === undefined) {
        errorList.push('กรุณาเลือกธนาคาร')
      }
      if (this.OLDData.data[0].account_name?.trim() === null || this.OLDData.data[0].account_name?.trim() === '' || this.OLDData.data[0].account_name?.trim() === undefined) {
        errorList.push('กรุณากรอกชื่อบัญชี')
      }
      if (!this.OLDData.data[0].is_receive) {
        errorList.push('กรุณาเลือกประเภทงานที่รับ')
      }
      if (!this.OLDData.data[0].standart_work) {
        errorList.push('กรุณาเลือกมาตรฐานรับงาน')
      }
      if (this.OLDData.data[0].no_area?.trim() === null || this.OLDData.data[0].no_area?.trim() === '' || this.OLDData.data[0].no_area?.trim() === undefined) {
        errorList.push('กรุณากรอกไม่รับทำบริเวณ')
      }
      if (this.OLDData.data[0].work_range?.trim() === null || this.OLDData.data[0].work_range?.trim() === '' || this.OLDData.data[0].work_range?.trim() === undefined) {
        errorList.push('กรุณากรอกช่วงเวลาที่สะดวกรับงาน')
      }
      if (this.OLDData.data[0].mi_id?.trim() === null || this.OLDData.data[0].mi_id?.trim() === '' || this.OLDData.data[0].mi_id?.trim() === undefined) {
        errorList.push('กรุณาเลือกค่าเวรขั้นต่ำที่รับ')
      }
      if (this.OLDData.data[0].citizen_id?.trim() === null || this.OLDData.data[0].citizen_id?.trim() === '' || this.OLDData.data[0].citizen_id?.trim() === undefined) {
        errorList.push('กรุณากรอกเลขบัตรประชาชน')
      }
      if (this.OLDData.data[0].citizen_id?.length < 17 && this.OLDData.data[0].citizen_id?.length !== 0 && (this.OLDData.data[0].citizen_id?.trim() !== null || this.OLDData.data[0].citizen_id?.trim() !== '' || this.OLDData.data[0].citizen_id?.trim() !== undefined)) {
        errorList.push('กรุณากรอกเลขบัตรประชาชนให้ครบ 13 หลัก')
      }
      // if (this.OLDData.data[0].citizen_id?.length !== 0 && this.OLDData.data[0].citizen_id && !regexNumber.test(this.OLDData.data[0].citizen_id)) {
      //   errorList.push('เลขบัตรประชาชน ต้องเป็นตัวเลขเท่านั้น')
      // }
      if (!this.OLD.file4) {
        errorList.push('กรุณาอัปโหลดรูปโปรไฟล์')
      }
      if (!this.OLD.file1) {
        errorList.push('กรุณาอัปโหลดรูปบัตรประชาชน')
      }
      if (!this.OLD.file2) {
        errorList.push('กรุณาอัปโหลดรูปหน้าบัญชี')
      }
      if (!this.OLD.file3) {
        errorList.push('กรุณาอัปโหลดรูปใบประกอบอาชีพ')
      }
      return errorList
    },
    async addOpenlink () {
      let addOpenlinkAPI = {
        method: 'post',
        url: `${configAPI.MA_PORT}/api/doctor`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: this.convertToJson()
      }
      await axios.request(addOpenlinkAPI)
        .then(async (response) => {
          if (response.data.status) {
            if (this.OLD.file1) {
              await this.uploadCitizen(response.data.data.id)
            }
            if (this.OLD.file2) {
              await this.uploadAccount(response.data.data.id)
            }
            if (this.OLD.file3) {
              await this.uploadSpecialized(response.data.data.id)
            }
            if (this.OLD.file4) {
              await this.uploadProfile(response.data.data.id)
            }
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลสำเร็จ</span>',
              icon: 'success',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 2000
            }).then(() => {
              window.location.reload()
            })
          } else {
            Vue.swal({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">บันทึกข้อมูลไม่สำเร็จ</span>',
              text: 'มีเลขบัตรประชาชนนี้อยู่ในระบบแล้ว',
              icon: 'error',
              timer: 2000,
              allowEscapeKey: false,
              allowOutsideClick: false,
              // confirmButtonText: '<b-button style="font-size: 14px; text-align: center">OK</b-button>', // Adjust the confirm button font size
              customClass: {
                confirmButton: 'custom-confirm-button-class'
              },
              confirmButtonColor: '#099FB0'
            })
          }
        })
    },
    async uploadCitizen (id) {
      let data = new FormData()
      data.append('file', this.OLD.file1)
      let uploadCitizenAPI = {
        method: 'post',
        url: `${configAPI.MA_PORT}/api/doctor/${id}/upload/citizen_img`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      await axios.request(uploadCitizenAPI)
        .then((response) => {})
        .catch((error) => {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
            html: '<span>' + error + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async uploadAccount (id) {
      let data = new FormData()
      data.append('file', this.OLD.file2)
      let uploadAccountAPI = {
        method: 'post',
        url: `${configAPI.MA_PORT}/api/doctor/${id}/upload/account_img`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      await axios.request(uploadAccountAPI)
        .then((response) => {})
        .catch((error) => {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
            html: '<span>' + error + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async uploadSpecialized (id) {
      let data = new FormData()
      data.append('file', this.OLD.file3)
      let uploadSpecializedAPI = {
        method: 'post',
        url: `${configAPI.MA_PORT}/api/doctor/${id}/upload/specialized_img`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      await axios.request(uploadSpecializedAPI)
        .then((response) => {})
        .catch((error) => {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
            html: '<span>' + error + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async uploadProfile (id) {
      let data = new FormData()
      data.append('file', this.OLD.file4)
      let uploadProfileAPI = {
        method: 'post',
        url: `${configAPI.MA_PORT}/api/doctor/${id}/upload/person_img`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      await axios.request(uploadProfileAPI)
        .then((response) => {})
        .catch((error) => {
          Vue.swal.fire({
            title: '<span class="" style="font-size: 25px; color: black; text-align: center">เกิดข้อผิดพลาด</span>',
            html: '<span>' + error + '</span>',
            icon: 'warning',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
            confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
            confirmButtonColor: '#089bab',
            customClass: {
              confirmButton: 'custom-confirm-button-class mx-1'
            }
          })
        })
    },
    async getAreaWorkDD () {
      let getAreaWorkDDAPI = {
        method: 'get',
        url: `${configAPI.MD_PORT}/api/areaWork`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      await axios.request(getAreaWorkDDAPI)
        .then((response) => {
          this.areaworkDD = response.data.data?.map(el => {
            return {
              value: el.id,
              label: el.name
            }
          })
          let same = this.areaworkDD?.filter(item => item.id === this.OLDData.data[0].aw_id)
          if (same.length > 0) {
            this.areaworkOption = { value: same[0].id, label: same[0].name }
          } else {
            this.areaworkOption = null
          }
        })
    },
    async getMinIncomeDD () {
      let getMinIncomeDDAPI = {
        method: 'get',
        url: `${configAPI.MD_PORT}/api/minIncome`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      axios.request(getMinIncomeDDAPI)
        .then((response) => {
          this.minincomeDD = response.data.data?.map(el => {
            return {
              value: el.id,
              label: el.name
            }
          })
          let same = this.minincomeDD?.filter(item => item.id === this.OLDData.data[0].mi_id)
          if (same.length > 0) {
            this.minincomeOption = { value: same[0].id, label: same[0].name }
          } else {
            this.minincomeOption = null
          }
        })
    },
    async getGenderDD () {
      let getGenderDDAPI = {
        method: 'get',
        url: `${configAPI.MD_PORT}/api/gender`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      axios.request(getGenderDDAPI).then((response) => {
        this.genderDD = response.data.data?.map(el => {
          return {
            value: el.id,
            label: el.name
          }
        })
        let same = this.genderDD?.filter(item => item.id === this.OLDData.data[0].gender_id)
        if (same.length > 0) {
          this.genderOption = { value: same[0].id, label: same[0].name }
        } else {
          this.genderOption = null
        }
      })
    },
    async getBankDD () {
      let getBankDDAPI = {
        method: 'get',
        url: `${configAPI.MD_PORT}/api/bank`,
        headers: {
          'Content-Type': 'application/json'
        }
      }
      axios.request(getBankDDAPI)
        .then((response) => {
          this.bankDD = response.data.data?.map(el => {
            return {
              value: el.id,
              label: el.name
            }
          })
          let same = this.bankDD?.filter(item => item.id === this.OLDData.data[0].bank_id)
          if (same.length > 0) {
            this.bankOption = { value: same[0].id, label: same[0].name }
          } else {
            this.bankOption = null
          }
        })
    },
    async clickSave () {
      let errorList = await this.checkError()
      Vue.swal({
        title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะบันทึกข้อมูลใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#089bab',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#d33',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (errorList.length > 0) {
            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">กรุณากรอกข้อมูลให้ครบถ้วน</span>',
              html: '<span>' + errorList.join('<br>') + '</span>',
              icon: 'warning',
              allowEscapeKey: false,
              allowOutsideClick: false,
              showConfirmButton: true,
              confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
              confirmButtonColor: '#089bab',
              customClass: {
                confirmButton: 'custom-confirm-button-class mx-1'
              }
            })
            return
          }
          await this.addOpenlink()
        }
      })
    },
    clickCancel () {
      if (this.id) {
        router.push('/Approve')
      } else {
        Vue.swal({
          title: '<span class="" style="font-size: 25px; color: black; text-align: center">คุณมั่นใจว่าจะยกเลิกการบันทึกข้อมูลใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#089bab',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#d33',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            this.clearData()
            // router.push('/OpenLinkDoctor')
          }
        })
      }
    },
    clearData () {
      this.OLDData.data[0].name = null
      this.OLDData.data[0].age = null
      this.OLDData.data[0].no = null
      this.OLDData.data[0].specialized = null
      this.OLDData.data[0].tel = null
      this.OLDData.data[0].email = null
      this.OLDData.data[0].aw_id = null
      this.areaworkOption = null
      this.OLDData.data[0].account_no = null
      this.OLDData.data[0].bank_id = null
      this.OLDData.data[0].bank_name = null
      this.bankOption = null
      this.OLDData.data[0].account_name = null
      this.OLDData.data[0].is_receive = null
      this.OLDData.data[0].no_area = null
      this.OLDData.data[0].work_range = null
      this.OLDData.data[0].mi_id = null
      this.minincomeOption = null
      this.OLDData.data[0].standart_work = null
      this.OLDData.data[0].gender_id = null
      this.genderOption = null
      this.OLDData.data[0].citizen_id = null
      this.OLDData.data[0].citizen_img = null
      const fileInput = this.$refs.fileInput
      if (fileInput) {
        fileInput.value = ''
      }
      this.OLDData.data[0].account_img = null
      const fileInput2 = this.$refs.fileInput2
      if (fileInput2) {
        fileInput2.value = ''
      }
      this.OLDData.data[0].specialized_img = null
      const fileInput3 = this.$refs.fileInput3
      if (fileInput3) {
        fileInput3.value = ''
      }
      this.OLD.file1 = null
      this.OLD.file2 = null
      this.OLD.file3 = null
      this.OLD.imageName = null
      this.OLD.imageUrl = null
      this.OLD.imageName2 = null
      this.OLD.imageUrl2 = null
      this.OLD.imageName3 = null
      this.OLD.imageUrl3 = null
    },
    formatIDcard (event) {
      let idCardNumber = event.replace(/\D/g, '')
      // idCardNumber = idCardNumber.replace(/\D/g, '')
      if (idCardNumber.length === 13) {
        idCardNumber = `${idCardNumber.charAt(0)}-${idCardNumber.substring(1, 5)}-${idCardNumber.substring(5, 10)}-${idCardNumber.substring(10, 12)}-${idCardNumber.charAt(12)}`
      }
      this.OLDData.data[0].citizen_id = idCardNumber
      return this.OLDData.data[0].citizen_id
    },
    formatContact (event) {
      let x = event.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.inputData.phoneNumber = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '')
    },
    isNumberCheck (keyPressed) {
      let keyCode = (keyPressed.keyCode ? keyPressed.keyCode : keyPressed.which)

      if (keyCode < 48 || keyCode > 57) {
        keyPressed.preventDefault()
      }
    },
    isPhoneCheck (keyPressed) {
      let keyCode = (keyPressed.keyCode ? keyPressed.keyCode : keyPressed.which)

      if ((keyCode < 48 || keyCode > 57) || this.inputData.phoneNumber.length >= 12) {
        keyPressed.preventDefault()
      }
    },
    preventDecimalAndNegative (event) {
      if (
        event.key === '.' ||
        event.key === '-' ||
        event.key === 'e' ||
        event.key === 'E'
      ) {
        event.preventDefault()
      }
    },
    validateAge (age) {
      if (isNaN(age) || age < 0 || age > 999) {
        this.OLDData.data[0].age = null
      }
    },
    handleImageUpload (event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      this.OLD.file1 = file
      reader.readAsDataURL(file)
      this.OLD.imageName = file.name
      reader.onload = () => {
        this.OLD.imageUrl = reader.result
      }
    },
    handleImageUpload2 (event) {
      const file2 = event.target.files[0]
      const reader2 = new FileReader()
      this.OLD.file2 = file2
      reader2.readAsDataURL(file2)
      this.OLD.imageName2 = file2.name
      reader2.onload = () => {
        this.OLD.imageUrl2 = reader2.result
      }
    },
    handleImageUpload3 (event) {
      const file3 = event.target.files[0]
      const reader3 = new FileReader()
      this.OLD.file3 = file3
      reader3.readAsDataURL(file3)
      this.OLD.imageName3 = file3.name
      reader3.onload = () => {
        this.OLD.imageUrl3 = reader3.result
      }
    },
    handleImageUpload4 (event) {
      const file4 = event.target.files[0]
      const reader4 = new FileReader()
      this.OLD.file4 = file4
      reader4.readAsDataURL(file4)
      this.OLD.imageName4 = file4.name
      reader4.onload = () => {
        this.OLD.imageUrl4 = reader4.result
      }
    },
    handleClick () {
      router.push('/AddSupply')
    },
    onPagination (page) {
      this.currentPage = page
    },
    setId () {
      this.id = this.$route.params.id
      if (this.$route.params.id) {
        this.disableCheck = true
      } else {
        this.disableCheck = false
      }
    }
  },
  created () {
    watch(
      () => this.setId()
    )
  },
  watch: {
    areaworkOption (newValue) {
      this.OLDData.data[0].aw_id = newValue !== null ? newValue.value : null
      // this.OLDData.data[0].gender_id = newValue.label
    },
    minincomeOption (newValue) {
      this.OLDData.data[0].mi_id = newValue !== null ? newValue.value : null
      // this.OLDData.data[0].gender_id = newValue.label
    },
    genderOption (newValue) {
      this.OLDData.data[0].gender_id = newValue !== null ? newValue.value : null
      // this.OLDData.data[0].gender_id = newValue.label
    },
    bankOption (newValue) {
      this.OLDData.data[0].bank_id = newValue !== null ? newValue.value : null
      // this.OLDData.data[0].bank_name = newValue.label
    }
  },
  async mounted () {
    xray.index()
    if (this.$route.params.id) {
      this.getApproveData()
    }
    document.getElementsByClassName('vs__open-indicator')[0].setAttribute('viewBox', '0 0 14 10')
    document.getElementsByClassName('vs__open-indicator')[0].setAttribute('width', '10')
    document.getElementsByClassName('vs__open-indicator')[0].setAttribute('height', '6')
    await this.getAreaWorkDD()
    await this.getMinIncomeDD()
    await this.getGenderDD()
    await this.getBankDD()
  },
  updated () {
  }
}
</script>
