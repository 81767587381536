<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>

          <template v-slot:headerTitle>
            <div class="row align-items-center py-4 mx-0">

              <div class="col-auto">
                <span class="unselectable" style="font-size: 25px; color: black;">อนุมัติใบเสนอราคา</span>
              </div>

            </div>
          </template>

          <template v-slot:body>
            <div class="mx-3">
              <b-row style="align-items: flex-end;">

                <b-col cols="12" lg="3" class="pb-3">
                  <div class="iq-email-search d-flex">
                    <div style="width: 100%;">
                      <div class="form-group mb-0" style="height: 45px;">
                        <input v-model="search"  @keydown="enterSearch" class="form-control" id="exampleInputEmail1" placeholder="Search"
                          style="width: 100%; height: 45px;">
                        <!-- <a class="search-link" @click="getQuotationByClinic"><i class="ri-search-line" /></a> -->
                        <i style="color: #676767; top: 4px" class="search-link ri-search-line" />
                      </div>
                    </div>
                  </div>
                </b-col>

                <b-col cols="6" lg="2" class="pb-3">
                  <b-text style="color: black; white-space: nowrap">วันที่ใบเสนอราคา (เริ่มต้น)</b-text>
                  <div style="position: relative; margin-top: .4rem;" class="w-res-attendance time-picker-attendance">
                    <date-picker v-if="searchEndDate" placeholder="เลือกวันที่" id="datepicker-custom-1"
                      :config="optionsDatepickerStart" v-model="searchStartDate"></date-picker>
                    <date-picker v-else placeholder="เลือกวันที่" id="datepicker-custom-1"
                      :config="optionsDatepickerDefault" v-model="searchStartDate"></date-picker>
                    <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(1)"
                      style="cursor: pointer;"></i>
                  </div>
                </b-col>

                <b-col cols="6" lg="2" class="pb-3">
                  <b-text style="color: black; white-space: nowrap">วันที่ใบเสนอราคา (สิ้นสุด)</b-text>
                  <div style="position: relative; margin-top: .4rem;" class="w-res-attendance time-picker-attendance">
                    <date-picker v-if="searchStartDate" placeholder="เลือกวันที่" id="datepicker-custom-2"
                      :config="optionsDatepickerEnd" v-model="searchEndDate"></date-picker>
                    <date-picker v-else placeholder="เลือกวันที่" id="datepicker-custom-2"
                      :config="optionsDatepickerDefault" v-model="searchEndDate"></date-picker>
                    <i class="ri-calendar-line m-0 icon-calendar-edit" @click="onClickDatePicker(2)"
                      style="cursor: pointer;"></i>
                  </div>
                </b-col>

                <b-col cols="12" lg="auto" class="pb-3">
                  <b-button @click="getQuotationByClinic" type="button" variant="primary-custom"
                    class="w-100 btn-min-size" style="height: 45px;">
                    <i class="ri-search-line"></i>
                    ค้นหา
                  </b-button>
                </b-col>

                <b-col cols="auto" class="pb-3">
                  <div style="display: flex; align-items: center; height: 45px;">
                    <span class="mx-2 font-size-14 text-primary-custom">
                      {{ rows_quotation.length > 0 ? (((currentPage - 1) * 10) + 1) + ' - ' + (((perPage *
                        currentPage) > rows_quotation?.length) ? rows_quotation.length : (perPage * currentPage)
                      ) + ' of ' + rows_quotation_length : '0 of ' + rows_quotation_length }} รายการ
                    </span>
                  </div>
                </b-col>

              </b-row>
            </div>

            <b-col cols="12">
              <div class="table-responsive">
                <b-table bordered hover :items="rows_quotation" :fields="columns_quotation" :current-page="currentPage"
                  :per-page="perPage" @pagination="onPagination" :tbody-tr-class="rowClass"
                  thead-class="table-header-color">

                  <template v-slot:cell(no)="data">
                    <span>{{ ((currentPage - 1) * 10) + data.index + 1 }}</span>
                  </template>

                  <template v-slot:cell(quotation_no)="data">
                    <a @click.prevent="getQuotationById(data.item.quo_id)"
                      style="color: #0085FF; cursor: pointer;"><span>{{
                        data.item.quo_no ||
                        '-' }}</span></a>
                    <!-- <span v-if="data.item.quo_count === '0'">{{ data.item.quo_no || '-' }}</span>
                      <a v-else style="color: #479CAC; cursor: pointer;" @click="ShowModalVersionQuo(data.item)"><span>{{ data.item.quo_no || '-' }}</span></a> -->
                  </template>

                  <template v-slot:cell(customer_name)="data">
                    <span>{{ data.item.quo_cus_name || '-' }}</span>
                  </template>

                  <template v-slot:cell(quotation_date)="data">
                    <span>{{ formatDate(data.item.quo_date) || '-' }}</span>
                  </template>

                  <template v-slot:cell(price)="data">
                    <span>{{ formatNumber(data.item.quo_total_price) || '-' }}</span>
                  </template>

                  <template v-slot:cell(status)="data">
                    <b-button v-if="data.item.quo_qs_id === 'd450c15f-5cd2-4fdd-9736-c60e73be6fbb'"
                      variant=" iq-bg-warning-custom btn-warning-border" style="min-width: 100px; cursor:default;">{{
                        data.item.qs_name }}</b-button>

                    <b-button v-else-if="data.item.quo_qs_id === '91c37841-61cf-4208-8fcf-3002d46e0cd7'"
                      variant=" iq-bg-success-custom btn-success-border" style="min-width: 100px; cursor:default;">{{
                        data.item.qs_name }}</b-button>

                    <b-button v-else-if="data.item.quo_qs_id === 'b3848d1a-cc46-4744-8158-58a6f8908869'"
                      variant=" iq-bg-info-custom btn-info-border" style="min-width: 100px; cursor:default;">{{
                        data.item.qs_name }}</b-button>

                    <b-button v-else-if="data.item.quo_qs_id === 'c24fdbfb-6222-4842-8fd1-98b23839ccae'"
                      variant=" bg-danger-custom" style="min-width: 100px;"
                      @click.prevent="reasonModal = true, reasonTextShow = data.item.quo_reason_not_approve">{{
                        data.item.qs_name }}</b-button>

                    <span v-else>{{ '-' }}</span>
                  </template>

                  <template v-slot:cell(approve_name)="data">
                    <span>{{ data.item.quo_approve_name || '-' }}</span>
                  </template>

                  <template v-slot:cell(management)="data">
                    <div style="display: flex;">
                      <b-button variant=" iq-bg-report-custom btn-report-border mx-1" size="sm"
                        style="cursor: not-allowed;">
                        <i class="ri-article-line m-0"></i>
                      </b-button>

                      <b-button v-if="data.item.quo_qs_id === 'd450c15f-5cd2-4fdd-9736-c60e73be6fbb'"
                        variant=" bg-success-custom mx-1" style="width: 100%;"
                        @click.prevent="quotationApprove(data.item.quo_id)">อนุมัติ
                      </b-button>
                      <b-button v-else variant=" bg-dark-custom mx-1" style="width: 100%; cursor: not-allowed;">อนุมัติ
                      </b-button>

                      <b-button v-if="data.item.quo_qs_id === 'd450c15f-5cd2-4fdd-9736-c60e73be6fbb'"
                        variant=" bg-danger-custom mx-1" style="width: 100%;"
                        @click.prevent="reasonModalAnswer = true, reasonTextSave = '', quo_id_save = data.item.quo_id">ไม่อนุมัติ
                      </b-button>
                      <b-button v-else variant=" bg-dark-custom mx-1" style="width: 100%; cursor: not-allowed;">ไม่อนุมัติ
                      </b-button>

                    </div>
                  </template>

                </b-table>
              </div>
            </b-col>

            <b-pagination v-model="currentPage" :total-rows="rows_quotation?.length" :per-page="perPage" align="center" />

          </template>
        </iq-card>
      </b-col>
    </b-row>

    <!-- Reason Modal  -->
    <b-modal v-model="reasonModal" title="ไม่ผ่านการอนุมัติ" size='lg' centered @close.prevent="reasonModal = false"
      hide-footer>
      <div style="margin: 10px 30px;">
        <span style="word-break: break-word; color: black;">{{ reasonTextShow }}</span>
      </div>
    </b-modal>

    <!-- Reason Modal Answer  -->
    <b-modal v-model="reasonModalAnswer" title="เหตุผลที่ไม่อนุมัติ" size='lg' centered
      @close.prevent="reasonModalAnswer = false" no-close-on-backdrop>
      <template #modal-title>
        เหตุผลที่ไม่อนุมัติ <span style="color: red;">*</span>
      </template>

      <b-form-textarea type="text" v-model="reasonTextSave" placeholder="ข้อคิดเห็น"
        style="padding-right: 1rem; height: 100px; justify-items: flex-start;"></b-form-textarea>

      <template #modal-footer>
        <div class="w-100" style="display: flex; justify-content: flex-end; gap: 0.5rem;">
          <b-button style="border-radius: 0.5rem; padding: 0 1.1rem !important;" variant="none"
            class="btn-min-size bg-save-custom" size="sm" @click.prevent="quotationNotApprove">
            <i class="save-data_ct" />บันทึก
          </b-button>

          <b-button variant="none" class="btn-min-size bg-danger-custom" style="border-radius: 0.5rem;" size="sm"
            @click.prevent="reasonModalAnswer = false">
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Detail Quotation Modal  -->
    <b-modal v-model="detailQuotationModal" title="รายละเอียดใบเสนอราคา" size='xl' centered
      @close.prevent="detailQuotationModal = false" hide-footer>
      <b-row>
        <b-col cols="12" lg="6" xl style="padding-top: 5px; padding-bottom: 5px;">
          <b-text style="color: black; word-break: break-word;">เลขที่ใบเสนอราคา : {{ quotationDetail.quo_no }}</b-text>
        </b-col>

        <b-col cols="12" lg="6" xl style="padding-top: 5px; padding-bottom: 5px;">
          <b-text style="color: black; word-break: break-word;">วันที่เสนอราคา : {{ quotationDetail.quo_date }}</b-text>
        </b-col>

        <b-col cols="12" lg="6" xl style="padding-top: 5px; padding-bottom: 5px;">
          <b-text style="color: black; word-break: break-word;">วันที่ยืนราคา : {{ quotationDetail.quo_confirm_price
          }}</b-text>
        </b-col>
      </b-row>

      <hr style="width: calc(100% + 32px); height: 1px; color: #CCC; margin-left: -16px;" />

      <b-row>
        <b-col cols="12" lg="6" xl style="padding-top: 5px; padding-bottom: 5px;">
          <b-text style="color: black; word-break: break-word;">ชื่อ-นามสกุล : {{ quotationDetail.quo_cus_name || '-'
          }}</b-text>
        </b-col>
        <b-col cols="12" lg="6" xl style="padding-top: 5px; padding-bottom: 5px;">
          <b-text style="color: black; word-break: break-word;">ชื่อเล่น : {{ quotationDetail.quo_cus_nickname || '-'
          }}</b-text>
        </b-col>
        <b-col cols="12" lg="6" xl style="padding-top: 5px; padding-bottom: 5px;">
          <b-text style="color: black; word-break: break-word;">เบอร์โทรศัพท์ : {{
            formatTel(quotationDetail.quo_cus_contact_tel || '') }}</b-text>
        </b-col>
      </b-row>

      <hr style="width: calc(100% + 32px); height: 1px; color: #CCC; margin-left: -16px;" />

      <b-row class="mb-2">
        <b-col cols="12">
          <div class="table-responsive">
            <b-table bordered hover :items="quotationItem" :fields="columns_quotationItem" :tbody-tr-class="rowClass"
              thead-class="table-header-color" id="tableModalRefQT"> <!-- foot-clone -->

              <template v-for="(column) in columns_quotationItem" v-slot:[`cell(${column.key})`]="data">
                <template v-if="column.key === 'no'">
                  <span :key="column.key">{{ data.index + 1 }}</span>
                </template>

                <template v-if="column.key === 'type_proser'">
                  <span :key="column.key">{{ data.item.type || '-' }}</span>
                </template>

                <template v-if="column.key === 'code_proser'">
                  <span :key="column.key">{{ data.item.no || '-' }}</span>
                </template>

                <template v-if="column.key === 'name_proser'">
                  <span :key="column.key">{{ data.item.name || data.item.st_name || '-' }}</span>
                </template>

                <template v-if="column.key === 'quantity'">
                  <span :key="column.key">{{ formatNumber(data.item.quantity) }}</span>
                </template>

                <template v-if="column.key === 'lp'">
                  <span :key="column.key">{{ formatNumber(data.item.lp) }}</span>
                </template>

                <template v-if="column.key === 'discount'">
                  <span :key="column.key">{{ formatNumber(data.item.discount) }}</span>
                </template>

                <template v-if="column.key === 'total_price'">
                  <span :key="column.key">{{ formatNumber(data.item.row_price) }}</span>
                </template>
              </template>

              <!-- Footer Table -->
              <!-- <template colspan="10" #foot()="data">
                <th colspan="10"><span></span></th>
              </template> -->
              <!-- <template  #foot(discount1)="data">
                <span>{{ 'สรุปรวมราคา' }}</span>
              </template>

              <template #foot(total_price1)="data">
                <span>{{ formatNumber(quotationAllItemSum) }}</span>
              </template> -->

              <!-- <template #foot(discount)="data">
                <span>{{ 'สรุปรวมราคา' }}</span>
              </template>

              <template #foot(total_price)="data">
                <span>{{ formatNumber(quotationAllItemSum) }}</span>
              </template> -->
              <!-- Footer Table -->

            </b-table>
            <div :style="'display: flex; justify-content: flex-end; min-width: ' + '1501px'" class="mt-2">
              <b-col style="text-align: end;" lg="2" md="2" sm="6">
                <label><b>{{ 'Vat 7%' }}</b></label>
              </b-col>
              <b-col style="text-align: center;" lg="2" md="2" sm="6">
                <label><b style="margin-left: 3rem;">{{ formatNumber(quotationAllVatSum) }}</b></label>
              </b-col>
            </div>
            <div :style="'display: flex; justify-content: flex-end; min-width: ' + '1501px'" class="">
              <b-col style="text-align: end;" lg="2" md="2" sm="6">
                <label><b>{{ 'สรุปรวมราคา' }}</b></label>
              </b-col>
              <b-col style="text-align: center;" lg="2" md="2" sm="6">
                <label><b style="margin-left: 3rem;">{{ formatNumber(quotationAllItemSum) }}</b></label>
              </b-col>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>

  </b-container>
</template>

<style scoped>
</style>

<script>
import { xray } from '../../config/pluginInit'
// import router from '@/router'
import Vue from 'vue'
import Axios from 'axios'
import moment from 'moment'
import { configAPI } from '../../configBase'

const iconDate = {
  time: 'fas fa-clock',
  date: 'fas fa-calendar',
  up: 'fas fa-chevron-up',
  down: 'fas fa-chevron-down',
  previous: 'fas fa-chevron-left',
  next: 'fas fa-chevron-right',
  today: 'fas fa-calendar-day',
  clear: 'fas fa-trash-alt',
  close: 'fas fa-times'
}

export default {
  name: 'QuotationApprove',

  components: {
  },

  data () {
    return {
      clnId: null,
      userId: null,
      search: '',
      searchStartDate: null,
      searchEndDate: null,
      columns_quotation: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        { label: 'เลขที่ใบเสนอราคา', key: 'quotation_no', class: 'text-center table-size-200-400' },
        { label: 'ชื่อลูกค้า', key: 'customer_name', class: 'text-center table-size-200-400' },
        { label: 'วันที่ใบเสนอราคา', key: 'quotation_date', class: 'text-center table-size-200-400' },
        { label: 'ราคา', key: 'price', class: 'text-center table-size-200-400' },
        { label: 'สถานะ', key: 'status', class: 'text-center table-size-200-400' },
        { label: 'ชื่อผู้อนุมัติ', key: 'approve_name', class: 'text-center table-size-200-400' },
        { label: 'Management', key: 'management', class: 'text-center table-size-300-500' }
      ],
      rows_quotation: [],
      rows_quotation_length: 0,
      currentPage: 1,
      perPage: 10,
      reasonModal: false,
      reasonModalAnswer: false,
      reasonTextShow: '',
      reasonTextSave: '',
      quo_id_save: null,
      optionsDatepickerDefault: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: false,
        minDate: false,
        maxDate: false,
        icons: iconDate
      },
      optionsDatepickerStart: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: false,
        minDate: false,
        maxDate: false,
        icons: iconDate
      },
      optionsDatepickerEnd: {
        format: 'DD/MM/YYYY',
        useCurrent: false,
        showClear: true,
        showClose: true,
        useStrict: false,
        minDate: false,
        maxDate: false,
        icons: iconDate
      },
      detailQuotationModal: false,
      quotationAllItemSum: 0,
      quotationAllVatSum: 0,
      quotationDetail: {},
      quotationItem: [],
      columns_quotationItem: [
        { label: 'ลำดับ', key: 'no', class: 'text-center table-size-100-300' },
        { label: 'ประเภท', key: 'type_proser', class: 'text-center table-size-200-400' },
        { label: 'รหัสสินค้า/บริการ', key: 'code_proser', class: 'text-center table-size-200-400 ellips' },
        { label: 'ชื่อสินค้า/บริการ', key: 'name_proser', class: 'text-center table-size-200-400 ellips' },
        { label: 'จำนวน', key: 'quantity', class: 'text-center table-size-200-400' },
        { label: 'ราคา', key: 'lp', class: 'text-center table-size-200-400' },
        { label: 'ส่วนลด', key: 'discount', class: 'text-center table-size-200-400' },
        { label: 'ราคารวม', key: 'total_price', class: 'text-center table-size-200-400' }
      ]
    }
  },

  computed: {
  },

  watch: {
    'searchStartDate': function (newDate) {
      this.optionsDatepickerEnd.minDate = newDate
    },
    'searchEndDate': function (newDate) {
      this.optionsDatepickerStart.maxDate = newDate
    }
  },

  async mounted () {
    this.clnId = JSON.parse(sessionStorage.getItem('user')).cln_id || null
    this.userId = JSON.parse(sessionStorage.getItem('user')).usr_id || null

    await this.getQuotationByClinic()

    xray.index()
  },

  methods: {
    async enterSearch (e) {
      if (e.key === 'Enter') {
        await this.getQuotationByClinic()
      }
    },
    formatDate (date) {
      if (date) {
        return moment(date).format('DD/MM/YYYY')
      } else {
        return '-'
      }
    },

    formatNumber (number) {
      if (number) {
        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } else {
        return '-'
      }
    },

    formatTel (tel) {
      if (tel) {
        let formatTel = tel.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        return formatTel
      } else {
        return ''
      }
    },

    SwalError (error, message) {
      Vue.swal.fire({
        title: `<span class="" style="font-size: 25px; color: black; text-align: center">${message}</span>`,
        html: '<span>' + error + '</span>',
        icon: 'warning',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ตกลง</b-button>',
        confirmButtonColor: '#089bab',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1'
        }
      })
    },

    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.no % 2 === 1) return 'table-body-color'
    },

    // async enterSearch (e) {
    //   if (e.key === 'Enter') {
    //     await this.getQuotationByClinic()
    //   }
    // },

    onClickDatePicker (number) {
      let datePicker = null
      datePicker = document.getElementById(`datepicker-custom-${number}`)
      datePicker.focus()
    },

    onPagination (page) {
      this.currentPage = page
    },

    async getQuotationByClinic () {
      const searchData = {
        search: this.search.trim() || null,
        searchStartDate: this.searchStartDate
          ? moment(this.searchStartDate, 'DD/MM/YYYY').startOf('day')
          : null,
        searchEndDate: this.searchEndDate
          ? moment(this.searchEndDate, 'DD/MM/YYYY').endOf('day')
          : null
      }

      await Axios.request({
        method: 'post',
        url: `${configAPI.SALE_PORT}/api/clinic/${this.clnId}/getQuotationByClinic`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: searchData
      }).then((response) => {
        this.rows_quotation = response.data.data
        this.rows_quotation_length = response.data.dataCount
      }).catch((error) => {
        this.SwalError(error.message, 'เกิดข้อผิดพลาดในดึงข้อมูล')
      })
    },

    async quotationApprove (quoId) {
      const temp = {
        user_id: this.userId
      }

      Vue.swal.fire({
        title: '<span class="" style="font-size: 23px; color: black; text-align: center">คุณมั่นใจว่าจะอนุมัติใบเสนอราคานี้ใช่หรือไม่?</span>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
        confirmButtonColor: '#0DC268',
        cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
        cancelButtonColor: '#E03C3C',
        customClass: {
          confirmButton: 'custom-confirm-button-class mx-1',
          cancelButton: 'custom-confirm-button-class mx-1'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await Axios.request({
            method: 'POST',
            url: `${configAPI.SALE_PORT}/api/clinic/${this.clnId}/approveQuotation/${quoId}`,
            headers: {
              'Content-Type': 'application/json'
            },
            data: temp
          }).then((response) => {
            Vue.swal.fire({
              title: '<span class="" style="font-size: 25px; color: black; text-align: center">อนุมัติสำเร็จ</span>',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              timer: 1500
            }).then(async () => {
              window.location.reload()
            })
          }).catch((err) => {
            this.SwalError(err.message, 'ลบข้อมูลไม่สำเร็จ')
          })
        }
      })
    },

    async quotationNotApprove () {
      if (this.reasonTextSave === '' || this.reasonTextSave === null) {
        this.SwalError('', 'กรุณากรอกเหตุผลที่ไม่อนุมัติ')
      } else {
        const temp = {
          user_id: this.userId,
          reasonTextSave: this.reasonTextSave
        }

        Vue.swal.fire({
          title: '<span class="" style="font-size: 22px; color: black; text-align: center">คุณมั่นใจว่าจะไม่อนุมัติใบเสนอราคานี้ใช่หรือไม่?</span>',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<b-button style="font-size: 14px; text-align: center">ใช่</b-button>',
          confirmButtonColor: '#0DC268',
          cancelButtonText: '<b-button style="font-size: 14px; text-align: center">ไม่</b-button>',
          cancelButtonColor: '#E03C3C',
          customClass: {
            confirmButton: 'custom-confirm-button-class mx-1',
            cancelButton: 'custom-confirm-button-class mx-1'
          }
        }).then(async (result) => {
          if (result.isConfirmed) {
            await Axios.request({
              method: 'POST',
              url: `${configAPI.SALE_PORT}/api/clinic/${this.clnId}/notApproveQuotation/${this.quo_id_save}`,
              headers: {
                'Content-Type': 'application/json'
              },
              data: temp
            }).then((response) => {
              Vue.swal.fire({
                title: '<span class="" style="font-size: 25px; color: black; text-align: center">ไม่อนุมัติสำเร็จ</span>',
                icon: 'success',
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                timer: 1500
              }).then(async () => {
                window.location.reload()
              })
            }).catch((err) => {
              this.SwalError(err.message, 'ลบข้อมูลไม่สำเร็จ')
            })
          }
        })
      }
    },

    async getQuotationById (quoId) {
      await Axios.request({
        method: 'GET',
        url: `${configAPI.SALE_PORT}/api/clinic/${this.clnId}/quotation/${quoId}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        const tempData = res.data.data[0]
        this.quotationAllItemSum = 0
        this.quotationDetail = {
          quo_no: tempData?.quo_no || null,
          quo_date: tempData?.quo_date ? moment(tempData?.quo_date).format('DD/MM/YYYY') : '',
          quo_confirm_price: tempData?.quo_confirm_price ? moment(tempData?.quo_confirm_price).format('DD/MM/YYYY') : '',
          quo_cus_contact_tel: tempData?.quo_cus_contact_tel || null,
          quo_cus_name: tempData?.quo_cus_name || null,
          quo_cus_nickname: tempData?.quo_cus_nickname || null,
          quo_cus_gender: tempData?.quo_cus_gender || null,
          quo_cus_birth_day: tempData?.quo_cus_birth_day ? moment(tempData?.quo_cus_birth_day).format('DD/MM/YYYY') : '',
          quo_cus_citizen_id: tempData?.quo_cus_citizen_id || null,
          quo_hn: tempData?.quo_hn || null,
          quo_cus_remark: tempData?.quo_cus_remark || null,
          // quo_discount: tempData?.quo_discount || null,
          quo_total_price: tempData?.quo_total_price || null,
          quo_approve_date: tempData?.quo_approve_date ? moment(tempData?.quo_approve_date).format('DD/MM/YYYY') : '',
          quo_is_incl_vat: tempData?.quo_is_incl_vat
        }

        // this.options.minDate = new Date(moment(this.QuoDetail.quo_date, 'DD/MM/YYYY'))

        if (tempData.pro_ser?.length > 0) {
          let sum = 0
          for (let item of tempData?.pro_ser) {
            if (item.qp_prd_id) {
              item.id = item.qp_prd_id
              item.type = 'Product'
              // item.is_select = true
              item.quantity = item.qp_amount
              item.lp = item.qp_price
              item.discount = item.qp_discount
              item.row_price = item.qp_total_price
              // this.productSelect.push(item)
            } else if (item.qp_ser_id) {
              item.id = item.qp_ser_id
              item.type = 'Service'
              // item.is_select = true
              item.quantity = item.qp_amount
              item.lp = item.qp_price
              item.discount = item.qp_discount
              item.row_price = item.qp_total_price
              // this.serviceSelect.push(item)
            }
            sum += Number(item.qp_total_price || 0)
          }
          if (tempData?.quo_is_incl_vat) {
            this.quotationAllVatSum = (Number(tempData?.quo_total_price) - sum).toFixed(2)
          }
          this.quotationAllItemSum = Number(tempData?.quo_total_price).toFixed(2)
        }

        this.quotationItem = tempData?.pro_ser || []
        // this.rowSelected.oldData = JSON.parse(JSON.stringify(tempData?.pro_ser || []))

        this.detailQuotationModal = true
      }).catch((err) => {
        this.SwalError(err, 'Error')
      })
    }
  }
}
</script>
