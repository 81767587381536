export const configAPI = {
  AUTH_PORT: 'http://203.151.56.27:6002',
  MA_PORT: 'http://203.151.56.27:6003',
  MD_PORT: 'http://203.151.56.27:6004',
  SALE_PORT: 'http://203.151.56.27:6005'
}

/*
  Local : {
    AUTH_PORT: 'http://localhost:6002',
    MA_PORT: 'http://localhost:6003',
    MD_PORT: 'http://localhost:6004',
    SALE_PORT: 'http://localhost:6005'
  }

  Dev : {
    AUTH_PORT: 'http://150.95.25.8:8111',
    MA_PORT: 'http://150.95.25.8:8112',
    MD_PORT: 'http://150.95.25.8:8113',
    SALE_PORT: 'http://150.95.25.8:8114'
  }

  Prod : {
    AUTH_PORT: 'http://203.151.56.27:6002',
    MA_PORT: 'http://203.151.56.27:6003',
    MD_PORT: 'http://203.151.56.27:6004',
    SALE_PORT: 'http://203.151.56.27:6005'
  }

  http://localhost:6002 -> http://150.95.25.8:8111 -> http://203.151.56.27:6002
  http://localhost:6003 -> http://150.95.25.8:8112 -> http://203.151.56.27:6003
  http://localhost:6004 -> http://150.95.25.8:8113 -> http://203.151.56.27:6004
  http://localhost:6005 -> http://150.95.25.8:8114 -> http://203.151.56.27:6005
*/
